// where all required static values are stored.

const pickerStyle = {
    placeholder: {
        color: '#b4b4b4'
    },
    inputAndroid: {
        color: '#b4b4b4',
    }
};

const stockType = [
    { label: "Normal", value: "normal" },
    { label: "Bonus", value: "bonus" },
    { label: "Split", value: "split" },
    { label: "Demerger", value: "demerger" },
    { label: "Merger", value: "merger" },
    { label: "Conversion", value: "conversion" }
]

const companyList = [
    { label: "Aditya birla pvt.ltd", value: 1 },
    { label: "Aditya capital", value: 2 },
    { label: "Reliance digital", value: 3 },
    { label: "Motilal oswal", value: 4 }
]

const sellCompanyList = [
    { label: "Tesla", value: 1 },
    { label: "Amazon", value: 2 },
    { label: "Google", value: 3 },
    { label: "Aarti Industries", value: 4 },
    { label: "Bajaj Finserve", value: 5 },
    { label: "L & T", value: 6 },
    { label: "Tata Motors", value: 7 },
    { label: "Reliance Industries", value: 8 }

]

const cumulativeNonCumulativeList = [
    { label: "Non Cumulative", value: "Non Cumulative" },
    { label: "Cumulative", value: "Cumulative" }
]

const redeemableOrConvertibleList = [
    { label: "Redeemable", value: "Redeemable" },
    { label: "Convertible", value: "Convertible" }
]

const dividendfrequencyList = [
    { label: "Monthly", value: "Monthly " },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Bi-Annual", value: "Bi-Annual" },
    { label: "Annual", value: "Annual" },
]

const periodList = [
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" }
]

const stockValuationType = [
    { label: "FIFO", value: 1 },
    { label: "Average", value: 2 },
    { label: "Selection", value: 3 }
]

const MFValuationType = [
    { label: "FIFO", value: 1 },
    { label: "Selection", value: 3 }
]
const BullionValuationType = [
    { label: "Selection", value: "selection" }
]

const InvestmentType = [
    { label: "Time Deposits", value: 1 },
    { label: "Monthly Income", value: 2 },
    { label: "NSC", value: 3 },
    { label: "KVP", value: 4 },
    { label: "Recurring Deposit", value: 5 },
    { label: "Sukanya Samriddhi", value: 6 },
    { label: "PPF", value: 7 },
    { label: "Senior Citizen", value: 8 }
]

const AmountInvestedType = [
    { label: "Statutory", value: 1 },
    { label: "Recognised", value: 2 },
    { label: "Unrecognised", value: 3 },
    { label: "Public Provident Fund", value: 4 },
]

const ContractStockType = [
    { label: "Listed", value: 1 },
    { label: "Unlisted", value: 2 }
]

const ActionsList = [
    { label: "Buy", value: 1 },
    { label: "Sell", value: 2 },
    { label: "Split", value: 3 },
    { label: "Interest", value: 4 },
    { label: "Matured", value: 5 },
    { label: "Redeemed", value: 6 },
    { label: "Bonus", value: 7 },
    { label: "Installment", value: 8 },
    { label: "Final", value: 9 },
    { label: "IPO", value: 10 },
    { label: "DeMerger", value: 11 },
    { label: "Merger", value: 12 },
    { label: "BuyBack", value: 13 },
    { label: "Rights", value: 14 },
    { label: "Converted", value: 15 },
    { label: "SIP Fund", value: 16 },
    { label: "Lumpsum Fund", value: 17 },
    { label: "Repay", value: 18 },
]

const TypeList = [
    { label: "Listed", value: 1 },
    { label: "Unlisted", value: 2 },
    { label: "Listed Preference", value: 3 },
    { label: "Unlisted Preference", value: 4 },
]

const TypeListFD = [
    { label: "Listed Debenture", value: 1 },
    { label: "Unlisted Debenture", value: 2 },
    { label: "Listed Bonds", value: 3 },
    { label: "Unlisted Bonds", value: 4 },
    { label: "FD", value: 5 },
    { label: "RD", value: 6 },
    { label: "Post Office", value: 7 },
    { label: "PPF", value: 8 }
]

const typeOfInvest = [
    { label: "Loans and Advances to related parties", value: 33 },
    { label: "Security Deposits / Balances with Government Authorities", value: 34 },
    { label: "Other Loans and Advances", value: 35 },
]
const securitytype = [
    { label: "Secured Loan Personal", value: 36 },
    { label: "Unsecured Loan Personal", value: 37 },
    { label: "Home Loan", value: 38 },
    { label: "Vehicle Loan", value: 39 },
    { label: "Education Loan", value: 40 }
]

const payment = [
    { label: "Installment", value: "Installment" },
    { label: "Partial Payment", value: "Partial Payment" },
    { label: "Closure", value: "Closure" }
]

const activeInactiveList = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 }
]

const PolicyType = [
    { label: "Life", value: 1 },
    { label: "Non Life", value: 2 }
]

const subPolicyTypeLife = [
    { label: "Term Plan", value: "3" },
    { label: "Endowment", value: "4" },
    { label: "Money Back", value: "5" },
    { label: "Whole Life", value: "6" },
    { label: "Child Plan", value: "7" },
    { label: "Retirement Plan", value: "8" }
]
const subPolicyTypeNonLife = [
    { label: "Health Insurance/Mediclaim", value: "9" },
    { label: "Vehicle", value: "10" },
    { label: "Keymen Insurance", value: "11" }
]

const TypeListMF = [
    // { label: "SIP", value: 1 },
    { label: "Lumpsum", value: 2 },
    { label: "ULIP", value: 3 }
]

const LivePriceType = [
    { label: "Stock", value: 1 },
    { label: "Fixed Income", value: 3 },
    { label: "Mutual Fund", value: 2 },
]

const bullionUnit = [
    { label: "Grams", value: 1 },
    { label: "Kilograms", value: 2 },

]

const bullionUnit2 = [
    { label: "Carat", value: 3 },
]

const bullionUnit3 = [
    { label: "Pieces", value: 4 },
]

const BullionCompany = [
    { label: "Gold", value: 1 },
    { label: "Silver", value: 2 },
    { label: "Platinum", value: 3 },
    { label: "Precios Stones", value: 4 },
    { label: "Jwellery", value: 5 },

]
const TypeofInvestPA = [
    { label: "Residential", value: 6 },
    { label: "Commercial", value: 7 },
    { label: "Land", value: 8 },
    { label: "Others", value: 9 }
]
const Measurement = [
    { label: "Sq. Ft.", value: "Sq. Ft." },
    { label: "Mts.", value: "Mts." },
    { label: "Yards", value: "Yards" },

]
const AreaMesurementType = [
    { label: "Carpet", value: "Carpet" },
    { label: "Built up", value: "Built up" },
    { label: "Super built up", value: "Super built up" },
    { label: "Saleable", value: "Saleable" }
]

const typeAcDetail = [
    { label: "Margin Account", value: 1 },
    { label: "Client Account", value: 2 }
]

const buysellList = [
    { label: "Buy", value: 1 },
    { label: "Sell", value: 2 }
]

const Country = [
    { label: "India", value: "India" },
    { label: "America", value: "America" }
]
const States = [
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Bihar", value: "Bihar" }

]
const Cities = [
    { label: "Sindhudurg", value: "Sindhudurg" },
    { label: "Ratnagiri", value: "Ratnagiri" },
    { label: "Mumbai", value: "Mumbai" },
    { label: "delhi", value: "delhi" }

]
const StockTypes = [
    { value: "normal", label: "Normal" },
    { value: "bonus", label: "Bonus", },
    { value: "rights", label: "Rights" },
]

const changePeriodList = [
    { value: 1, label: "Current Financial Year" },
    { value: 2, label: "Previous financial Year" },
    // { value: 3, label: "Previous to Last Financial Year" },
    { value: 4, label: "All transactions " },
    { value: 5, label: "For the period" },
]

export {
    stockValuationType, pickerStyle, stockType, companyList, sellCompanyList, cumulativeNonCumulativeList, redeemableOrConvertibleList, dividendfrequencyList, TypeList, TypeListFD, TypeListMF,
    periodList, InvestmentType, AmountInvestedType, ContractStockType, ActionsList, typeOfInvest, securitytype, payment, activeInactiveList, PolicyType, subPolicyTypeLife, subPolicyTypeNonLife, MFValuationType, LivePriceType,
    BullionValuationType, bullionUnit, BullionCompany, TypeofInvestPA, Measurement, AreaMesurementType, typeAcDetail, buysellList, Country, States, Cities, bullionUnit2, bullionUnit3, StockTypes, changePeriodList
};
