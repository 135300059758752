import React from 'react'
import './loder.css'


export default function index(props) {
  return (

    <div className="ui active inverted dimmer" >
      <div className="ui loader" id='loader-1'>

      </div>
    </div>

  )
}
