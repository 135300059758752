//import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index'
const userID = Auth.getsubcriberId();
let LoanLiveUrl = "http://5.189.157.40:10026/api";
// https://localhost:44375/api


const FetchLoanData = async () => {
    return axios({
        method: "GET",
        url: `${LoanLiveUrl}/LoansDepositsManagement/FetchLoanFamilyOuterGrid?s_id=${userID}`
    })
}

const EntityWiseLoanList = async (subId) => {
    return axios({
        method: 'GET',
        url: `${LoanLiveUrl}/LoansDepositsManagement/FetchLoanIndividualOuterGrid?sub_id=${subId}`
    })
}

const GetBuySellLoanGrid = async (subId, Instu_id) => {
    return axios({
        method: 'GET',
        url: `${LoanLiveUrl}/LoansDepositsManagement/FetchLoansDepositsEntityInfo?sub_id=${subId}&insTypeId=${Instu_id}`
    })
}

const GetBuySellGridLoanEntity = async (Instu_id, depositeId) => {
    return axios({
        method: 'GET',
        url: `${LoanLiveUrl}/LoansDepositsManagement/FetchLoansDepositsIndividualEntityInfo?insTypeId=${Instu_id}&depositTo_ID=${depositeId}`
    })
}

const GetLoanTransaction = async (LoanId) => {
    return axios({
        method: 'GET',
        url: `${LoanLiveUrl}/LoansDepositsManagement/GetLoansDepById?loanId=${LoanId}`
    })
}

const deleteLoanTrans = async (loanId) => {
    return axios({
        method: 'DELETE',
        url: `${settings.API_URL}/LoansDepositsManagement/RemoveLoanDetails?loanId=${loanId}`,
    })
}

const AddLoans = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/LoansDepositsManagement/AddLoansDeposits`,
        data: body
    })
}

const updateLoans = async (body) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/LoansDepositsManagement/UpdateLoansDeposits`,
        data: body
    })
}
const AddLoansRepay = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/LoansDepositsManagement/AddLoanDepMaturity`,
        data: body
    })
}

const UpdateLoansRepay = async (body) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/LoansDepositsManagement/UpdateLoanDepMaturity`,
        data: body
    })
}
const GetLoansInterestInstallmentRepayinfo = async (LonasId, TranStockType) => {
    return axios({
        method: 'GET',
        url: `${LoanLiveUrl}/LoansDepositsManagement/GetIntInsMatDetails?loanId=${LonasId}&transactStockType=${TranStockType}`
    })
}

const PostLoansInterest_Insta = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/LoansDepositsManagement/AddIntInsRepay`,
        data: data
    })
}

const UpdateLoansInterest_Insta = async (data) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/LoansDepositsManagement/UpdateInsIntRepay`,
        data: data
    })
}

const DeleteLoansRepay_Interest_Insta = async (LoanSellId) => {
    return axios({
        method: 'DELETE',
        url: `${settings.API_URL}/LoansDepositsManagement/DeleteInsIntRepay?LoanSellId=${LoanSellId}`
    })
}

export {
    FetchLoanData,
    EntityWiseLoanList,
    GetBuySellLoanGrid,
    GetLoanTransaction,
    deleteLoanTrans,
    AddLoans,
    updateLoans,
    GetBuySellGridLoanEntity,
    AddLoansRepay,
    UpdateLoansRepay,
    GetLoansInterestInstallmentRepayinfo,
    PostLoansInterest_Insta,
    UpdateLoansInterest_Insta,
    DeleteLoansRepay_Interest_Insta
}


