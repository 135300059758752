/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  FetchBrokerDropDownData,
  FetchCompanyDropDownData,
  EntityList,
  PostBuyBack,
  updateBuyBack,
  FetchDate,
  AvilableQuantity
} from "../../../pages/Stocks/StocksAPI";
import {
  Form,
  Button,
  Row,
  Modal,
  Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const BuyBack = (props) => {
  const { showBuy, onHideBuy, stockHeaderId, stockTransactionBuy, stockId, showbuybackpreview } = props;
  const [stockDataNew, setstockDataNew] = useState([]);
  const [AQtyBuyback, setAQtyBuyBack] = useState(0);
  const [amount, setAmount] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [selScrip, setSelScrip] = useState([]);
  const [BrokerList, setBrokerList] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [Value, setValue] = useState({ quantity: null })
  const [Price, setPrice] = useState({ price: null })
  const [broker, setbroker] = useState([]);
  const [entityValue, setEntityVAlue] = useState([])
  const [buyDate, setBuyDate] = useState(null)
  const [error, setError] = useState('');
  const [ratio, setRatio] = useState('')
  const [avlQuantity, setavlQuantity] = useState(0)

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  // seperation by comma
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  const errHandel = () => {
    let formErr = {};

    let isValid = true;
    if (selScrip.length < 1) {
      isValid = false;
      formErr["selScrip"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }

    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }
    if (!Value.quantity || Value.quantity === 0) {
      isValid = false;
      formErr["quantity"] = "Please Fill No Of Share!";
    }
    if (Value.quantity) {
      if (AQtyBuyback < Value.quantity) {
        isValid = false;
        formErr["quantity"] = "Enter Shares Are More Than Available Shares!";
      }
    }
    // if (Value.quantity) {
    //   let qvalue = parseFloat(Value.quantity); 
    //   if (Number.isInteger(qvalue) == false) {
    //     isValid = false;
    //     formErr["quantity"] = "Please enter only integer value!";
    //   }
    // }
    if (!Price.price || Price.price === 0) {
      isValid = false;
      formErr["price"] = "Please Fill This Price!";
    }
    // if (!ratio) {
    //   isValid = false;
    //   formErr["ratio"] = "Please enter ratio !";
    // }

    setFormErr(formErr);
    return isValid;
  };

  useEffect(() => {
    if (showBuy) {
      getEntityListt()

    }

  }, [showBuy]);
  useEffect(() => {
    if (showBuy) {
      (async () => {
        await getCompanyDropDownData()
        await getBrokerDropDownData()
      })();
    }
  }, [selEntity])

  // useEnterKeyListener({
  //   querySelectorToExecuteClick: "#submitButton"
  // });
  useEffect(() => {
    let companyId = selScrip.value;
    let brokerId = selBroker.value;
    let subAccountId = selEntity.value;
    if (showBuy || showbuybackpreview) {
      if (companyId && brokerId && subAccountId) {
        getStockDetail(brokerId, subAccountId, companyId);
      }
    }
  }, [selScrip, selBroker, selEntity, showBuy, showbuybackpreview]);

  // edit bonus data
  useEffect(() => {

    if (stockHeaderId !== 0) {

      if (stockTransactionBuy !== undefined) {
        setstockDataNew(stockTransactionBuy)
        setSelBroker({
          value: stockTransactionBuy.brokerId,
          label: stockTransactionBuy.broker.brokerName,
        });
        setbroker(stockTransactionBuy.brokerId);

        setSelEntity({
          value: stockTransactionBuy.subAccountId,
          label: stockTransactionBuy.subAccount.subAccountName,
        });

        setEntityVAlue(stockTransactionBuy.subAccountId);
        setPDate(new Date(stockTransactionBuy.date));
        stockTransactionBuy.stockDetails && stockTransactionBuy.stockDetails.map((t) => {
          setAmount(t.amount)
          setValue({ quantity: t.quantity })
          setPrice({ price: t.pricePerShare })
          setSelScrip(t.company, { companyType: t.instrumentTypeId })
          setRatio(t.ratio)
          setavlQuantity(t.availableQuantity)
        })
      }

    }
  }, [stockTransactionBuy])
  // for buy date
  useEffect(() => {
    if (showBuy) {
      if (stockHeaderId === 0 || stockHeaderId === undefined) {
        setPDate(null)
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
      if (stockHeaderId !== 0 && stockTransactionBuy !== undefined) {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
    }
  }, [selScrip, selBroker, selEntity, showBuy]);

  useEffect(() => {
    if (Value.quantity > 0 && Price.price > 0) {
      handleAmount()
    } else {
      setAmount(0)
    }
  }, [Value, Price]);
  const handleAmount = () => {
    const total = Number(Value.quantity) * Number(Price.price);
    setAmount(total);
  }
  //getbrockerlist
  const getBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchBrokerDropDownData(sub_Id)
        .then((res) => {
          setLoading(false)
          // console.log(res, "brokerlisy");
          if (res.data._response.status === 1) {
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setLoading(false);
            setBrokerList(brokersData);
          } else {
            setBrokerList([]);
            setLoading(false)
            //setError(res.data.message);
          }
        })
        .catch((e) => {
          // setError(e.message);
          setBrokerList([]);
          setLoading(false);
          console.log(e, "brokerlist error");
        });
    }
  };
  //handel disable listed companies
  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }


  // get dropDown Company Data
  const getCompanyDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchCompanyDropDownData(sub_Id)
        .then((res) => {
          // console.log(res,'company list');
          if (res.data._response.status === 1) {
            let UnlistedCompany = [];
            let ListedCompany = [];

            let company = res.data._response.data.companyName;
            company &&
              company.filter(data => data.instrumentTypeId === 1).map(i => {
                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });
            company &&
              company.filter(data => data.instrumentTypeId === 10).map(i => {
                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });

            const groupedOptions = [
              {
                label: 'Listed Companies',
                options: ListedCompany,
              },
              {
                label: 'UnListed Companies',
                options: UnlistedCompany,
              },
            ];
            setLoading(false);
            setCompanyList(groupedOptions);
          } else {
            setCompanyList([]);
            setLoading(false);
            // setError(res.data.message);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
          // setError(e.message);
        });
    }
  };

  //getEntityList
  const getEntityListt = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {

        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                return (entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                }))
              });
            setEntity(entityData);
            setLoading(false)
          }
        } else {
          setError(res.data.message);
          setLoading(false)
        }
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false)
      });
  };
  //   available quantity
  const getStockDetail = async (brokerId, subAccountId, companyId) => {
    setLoading(true)

    await AvilableQuantity(brokerId, subAccountId, companyId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setAQtyBuyBack(res.data._response.data.remainingQuantity);
            setLoading(false)
          } else {
            setAQtyBuyBack(0)
            setLoading(false)
          }

        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false)
      });
    if (stockHeaderId !== 0 && stockTransactionBuy !== undefined && Value.quantity) {
      setAQtyBuyBack(stockTransactionBuy.stockDetails[0].availableQuantity + Value.quantity);
    }
  };
  // get buy date
  const getBuyDate = async (sub_id, co_id, bro_id) => {
    setLoading(true)
    await FetchDate(sub_id, co_id, bro_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false)
            setBuyDate(res.data._response.data.fetchDate)
          }
          else {
            setLoading(false)
            setError(res.data.message);
          }
        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message);
      });
  };
  const handleClose = (val) => {
    onHideBuy(val);
    handleReset();
    setSuccess(false)
  };
  //handlechange
  const handleInput = (selected) => {
    setSelScrip(selected);
    setFormErr({ ...formErr, selScrip: "" });
  };
  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityVAlue(selected.value)
    setSelScrip([]);
    setSelBroker([]);
    setFormErr({ ...formErr, selEntity: "" });
  };
  const handleOption = (selected) => {
    setSelBroker(selected);
    setbroker(selected.value)
    setSelScrip([])
    setFormErr({ ...formErr, selBroker: "" });
  };
  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: "" });
  };
  // handlevalue
  const handleChangeQ = (e) => {
    const { name, rawValue } = e.target
    setValue({ ...Value, [name]: rawValue });
    setFormErr({ ...formErr, quantity: '' })
  }
  const handleChangePrice = (e) => {
    const { name, rawValue } = e.target
    setPrice({ ...Price, [name]: rawValue });
    setFormErr({ ...formErr, price: '' })
  }

  const handleRatio = (e) => {
    setRatio(e.target.value)
  }
  //submit form
  const handleSubmit = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        brokerId: selBroker.value,
        date: fixTimezoneOffset(pDate),
        //moment.tz(pDate, "Asia/Kolkata").format(),
        subAccountId: selEntity.value,
        stockDetails: [
          {
            pricePerShare: parseInt(Price.price),
            quantity: parseInt(Value.quantity),
            companyId: selScrip.value,
            totalCost: amount,
            ratio: ratio,
            instrumentTypeId: selScrip.companyType,
            transactStockType: "BuyBack",
          },
        ],
      };

      PostBuyBack(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);
              Swal.fire("Success!", "BuyBack Added Successfully", "success");
              handleReset();
              setValue({ ...Value, quantity: 0 })
              setPrice({ ...Price, price: 0 })
              //onRefresh();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in submit");
        });
    }
  }
  const handleUpdate = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        brokerId: selBroker.value,
        date: fixTimezoneOffset(pDate),
        subAccountId: selEntity.value,
        stockHeaderId: stockHeaderId,
        stockDetails: [
          {
            stockId: stockId,
            pricePerShare: parseInt(Price.price),
            quantity: parseInt(Value.quantity),
            companyId: selScrip.value,
            totalCost: amount,
            ratio: ratio,
            instrumentTypeId: selScrip.companyType,
            transactStockType: "BuyBack",

          },
        ],
      };

      updateBuyBack(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);
              Swal.fire("BuyBack Updated successfully!", "", "success");
              handleReset();
              //onRefresh();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }
  }

  //reset field
  const handleReset = () => {
    setCompanyList([]);
    setBrokerList([]);
    setSelEntity([]);
    setSelScrip([]);
    setSelBroker([]);
    setAQtyBuyBack(0);
    setAmount(0)
    setValue({ quantity: '' })
    setPrice({ price: '' })
    setPDate(null);
    setFormErr({});
    setRatio('')
    setBuyDate(null)
  };

  const disableButton = () => {
    return pDate && selEntity &&
      selScrip && selBroker && Price && Value && amount

  }

  const fixTimezoneOffset = (pDate) => {
    if (!pDate) return "";
    return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
  }

  return (
    <>
      <Modal show={showBuy} onHide={handleClose} size="lg" centered>
        <Modal.Header className="headerBlue" closeButton>
          {stockHeaderId === 0 || stockHeaderId === undefined ? (
            <Modal.Title className="mx-auto"><h5>Add BuyBack</h5></Modal.Title>
          ) : (
            <Modal.Title className="mx-auto"><h5>Update BuyBack</h5></Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
          ) : null}

          <div className="mt-2 p-6">
            <Form className="border border-1 rounded p-3">
              <Row className="form-set">
                {stockHeaderId === 0 || stockHeaderId === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isDisabled="required"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>}
                {stockHeaderId === 0 || stockHeaderId === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleOption}
                      options={BrokerList}
                      name="brokerId"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleOption}
                      options={BrokerList}
                      name="brokerId"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                }
                {stockHeaderId === 0 || stockHeaderId === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                    <Select
                      value={selScrip}
                      onChange={handleInput}
                      options={companyList}
                      name="companyId"
                      isOptionDisabled={handelDisableDD}
                      styles={{
                        option: (data) => ({
                          ...data,
                          margin: 0,
                          padding: '3px 12px',
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          flex: '1 1',
                          color: '#000',
                          margin: 0,
                          padding: '7px 2px',
                          // border: `2px solid `,
                          background: "#8bc3ff",
                          fontSize: 'small',
                          fontWeight: 500
                        }),
                      }}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                    <Select
                      value={selScrip}
                      onChange={handleInput}
                      options={companyList}
                      name="companyId"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                }
              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-3">
                  <Form.Label>No of Shares<span className="text-danger h6">*</span></Form.Label>
                  {/* <Form.Control
                    type="Number"
                    name="quantity"
                    min="0"
                    value={Math.round(Value.quantity)}
                    onChange={handleChangeQ}
                  /> */}
                  <Cleave
                    className='form-control'
                    name="quantity"
                    autoComplete="off"
                    placeholder='Enter Quantity'

                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={Math.round(Value.quantity)}
                    onChange={handleChangeQ} />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.quantity}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-3" >
                  <Form.Label>Price Per Share<span className="text-danger h6">*</span></Form.Label>
                  {/* <Form.Control type="Number" name="price" onChange={handleChangePrice} value={Price.price} min="0" placeholder="Enter price.." /> */}
                  <Cleave
                    className='form-control'
                    name="price"
                    autoComplete="off"
                    placeholder="Enter Price.."
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={Price.price}
                    onChange={handleChangePrice} />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.price}</div> : null}
                </Form.Group>
                <Form.Group className="col-md-3" >
                  <Form.Label>Amount </Form.Label>
                  <Form.Control type="text" name="amount" disabled
                    min="0" value={NumberSeperator(amount)} placeholder="Enter scrip name" />
                </Form.Group>
                <Form.Group className="col-md-3" >
                  <Form.Label> Ratio </Form.Label>
                  <Form.Control type="text" name="amount" min="0" placeholder="Enter Ratio" onChange={handleRatio} value={ratio} autoComplete="off" />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.ratio}</div> : null}
                </Form.Group>
              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-3">
                  <Form.Label>Available Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    name="QtyBeforeSplit"
                    disabled
                    min="0"
                    value={NumberOnlySeperator(AQtyBuyback)}
                  />
                </Form.Group>

                <Form.Group className="col-md-3">
                  <Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    maxDate={new Date()}
                    minDate={new Date(buyDate)}
                    onChange={handleDate}
                    dateFormat="dd-MM-yyyy"
                    name="date"
                    autoComplete="off"
                    selected={pDate}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.date}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              <Row>
                <Col md="12" className="text-center mt-3">
                  {stockHeaderId === 0 || stockHeaderId === undefined ? (

                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }}
                      disabled={!disableButton()}>
                      Save
                    </Button>
                  ) : (
                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }}
                      disabled={!disableButton()}>
                      Update
                    </Button>
                  )}
                  {stockHeaderId === 0 || stockHeaderId === undefined ?
                    <Button
                      variant="secondary"
                      className="mx-2 w100"
                      type="reset"
                      onClick={handleReset}
                    >
                      Clear
                    </Button> : null}
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>

      </Modal>
      {showbuybackpreview === true ?
        <Modal show={showbuybackpreview} onHide={() => handleClose(1)}>
          <Modal.Header closeButton className="headerBlue" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>BuyBack Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                <div className="transactionDataValue">{selScrip.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">No. of Shares : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(Value.quantity)}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Price per Share : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Price.price)}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Amount: </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount)}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio ? ratio : "-"}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Available Quantity : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(avlQuantity)}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Date : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  )
}

export default BuyBack
