import axios from "../../../modules/helpers/axiosInstance";

let UserLiveUrl = "http://5.189.157.40:10023/api";
let AddUserLiveUrl = "http://5.189.157.40:10025/api"



const UserActiveData = async (activeId, subcriberId) => {
    return axios({
        method: "PUT",
        url: `${UserLiveUrl}/UserManagment/ActiveDeactiveSubscriber?subcriberId=${subcriberId}&active=${activeId}`
    })
}

const UserListData = async () => {
    return axios({
        method: "Get",
        url: `${UserLiveUrl}/UserManagment/GetUserListData`
    })
}

const AddUserData = async (data) => {
    return axios({
        method: "POST",
        url: `${AddUserLiveUrl}/Subcriber/register`,
        data: data
    })
}

const UpdateUserListData = async (data) => {
    return axios({
        method: "PUT",
        url: `${UserLiveUrl}/UserManagment/UpdateSubscriber`,
        data: data
    })
}

const UpdateUserProfile = async (data) => {
    return axios({
        method: "PUT",
        url: `${UserLiveUrl}/UserManagment/UpdateSubscriber`,
        data: data
    })
}

const UserGetData = async (userId) => {
    return axios({
        method: "GET",
        url: `${UserLiveUrl}/UserManagment/GetUserList?subcriberId=${userId}`
        // https://localhost:44316/api/UserManagment/GetUserList?subcriberId=1
    })
}
const ProfileGetData = async (subcriberId, roleId) => {
    return axios({
        method: "GET",
        url: `${UserLiveUrl}/UserManagment/IsProfileCompleted?userId=${subcriberId}&roleId=${roleId}`
        // url: `https://localhost:5001/api/UserManagment/IsProfileCompleted?userId=${subcriberId}&roleId=${roleId}`
    })
}

const AddUserMenuRelData = async (data) => {
    return axios({
        method: "POST",
        url: `${UserLiveUrl}/UserManagment/AddOrUpdateUserMenuRelationship`,
        data: data
    })
}

const getAccessTableData = async (subcriberId) => {
    return axios({
        method: "GET",
        url: `${UserLiveUrl}/UserManagment/GetRoleMenuDetails`
    })
}
const ChannelPartnerListData = async (pageNumber,dataPerPage) => {
    return axios({
        method: "GET",
        // url: `https://localhost:5001/api/UserManagment/FetchChannelPartners?PageNo=${pageNumber}&PageSize=${dataPerPage}`
        url: `${UserLiveUrl}/UserManagment/FetchChannelPartners?PageNo=${pageNumber}&PageSize=${dataPerPage}`
    })
}
const CPActiveData = async (activeId, channelPartnerId,pageNumber,dataPerPage) => {
    return axios({
        method: "PUT",
        url:`${UserLiveUrl}/UserManagment/ActiveDeactiveSubscriber?subcriberId=0&CP_id=${channelPartnerId}&active=${activeId}&PageNo=${pageNumber}&PageSize=${dataPerPage}`,
        // https://localhost:44316/api/UserManagment/ActiveDeactiveSubscriber?subcriberId=210&CP_id=0&active=1&PageNo=2&PageSize=1

    })
}

const ChannelGetData = async (userId) => {
    return axios({
        method: "GET",
        url: `${UserLiveUrl}/UserManagment/GetUserList?subcriberId=${userId}`
    })
}
const UpdateChannelPartner = async (data) => {
    return axios({
        method: "PUT",
        url: `${UserLiveUrl}/UserManagment/UpdateSubscriber`,
        data: data
    })
}

const deleteChannelpartner = async(body)=>{
    return axios({
        method:'DELETE',
        url:`${UserLiveUrl}/UserManagment/DeleteSubscriber`,
        data:body


    })
}

export {
    UserActiveData,
    UserListData,
    AddUserData,
    UpdateUserListData,
    UserGetData,
    AddUserMenuRelData,
    getAccessTableData,
    UpdateUserProfile,
    ProfileGetData,
    ChannelPartnerListData,
    CPActiveData,
    ChannelGetData,
    UpdateChannelPartner,
    deleteChannelpartner
}
