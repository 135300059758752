import React, {  useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../loadingAnimation/index'
import { makeStyles } from "@material-ui/core/styles";
import {
    Container,
    Button,
    TableCell,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    
} from "@material-ui/core";
import moment from "moment";
import { IoArrowBackCircleSharp } from 'react-icons/io5';

import "./Reports.css"
import { Stack } from "@mui/material";
import { stickyfooter, stickyHeader, stickymainHeader } from "./sticky";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';

const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
}




function ReportTable() {
    
    const navigate = useNavigate();
    const pdfRef = useRef(null);
    const location = useLocation();
    const ReportData = location.state.report;
    const ReportId = location.state.reportValue;
    // const fromDate = location.state.fromDate;
    const toDate = location.state.toDate;



    // push unique comapnies into an array and add up qty and amount
    const company = [];
    const totalCalculations = [];
    let totalAmt = 0, totalQty = 0;
    ReportData.forEach((item, i, arr) => {

        item.companyList.forEach((d, ind) => {
            let checkData = company.some(el => el.companyId === d.companyId)
            if (!checkData) {
                company.push({
                    companyId: d.companyId,
                    companyName: d.companyName,
                    quantity: d.quantity,
                    closingBalance: d.closingBalance,
                });
            } else {
                const index = company.findIndex(a => {
                    return a.companyId === d.companyId;
                });
                company[index].quantity += d.quantity;
                company[index].closingBalance += d.closingBalance;
            }
        })
        totalAmt += item.closingBalanceTotal
        totalQty += item.quantityTotal

        //calculate footer totals
        arr.length - 1 === i ? totalCalculations.push({ Qty: item.quantityTotal, Amt: item.closingBalanceTotal }, { Qty: totalQty, Amt: totalAmt }) : totalCalculations.push({ Qty: item.quantityTotal, Amt: item.closingBalanceTotal })
    });

    // push absent companies into Report data companlist array 
    ReportData.forEach((item, i) => {
        company.forEach((comp, index) => {
            let checkPresent = item.companyList.some(el => el.companyId === comp.companyId)
            if (!checkPresent) {
                item.companyList.push({
                    companyId: comp.companyId,
                    companyName: comp.companyName,
                    quantity: 0,
                    closingBalance: 0
                });
            }
        })
    })

    // sort data alphabetically
    company.sort((a, b) => (a.companyName > b.companyName) ? 1 : -1)

  

    const useStyles = makeStyles({
        table: {
            //   minWidth: 650,
            "& .MuiTableCell-root": {
                border: "1px solid rgba(0, 0, 0, 0.3)"
            }
        },
        customTableContainer: {
            overflowX: 'revert'
        }
    });

    const classes = useStyles();
    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c['table']) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const rows = doc.querySelectorAll('tr');

            rows.forEach((val, i) => {
                if (i !== 0) {
                    val.firstElementChild.colSpan = 3
                    if (i === 1 || i === 2) {
                        val.childNodes.forEach((val, i) => {
                            val.bgColor = '#D9E5F2';
                        })
                        if (i === 1) {
                            //Total label
                            val.lastElementChild.colSpan = 4
                            val.lastElementChild.vAlign = 'middle';
                        } else if (i === 2) {
                            const childLength = val.childNodes.length
                            //TotalQuantiy And ToatalAmout label
                            val.childNodes[childLength - 1].colSpan = 2;
                            val.childNodes[childLength - 2].colSpan = 2;
                        }
                    } else {
                        val.childNodes.forEach((child, indx, arr) => {
                            if (indx !== 0) {
                                child.align = 'right'
                                //TotalQuantiy values And ToatalAmout Values
                                if (indx === arr.length - 1 || indx === arr.length - 2) {
                                    child.colSpan = 2;
                                }
                            }
                        })
                    }
                } else {
                    val.firstElementChild.colSpan = val.firstElementChild.colSpan + 4;
                    val.firstElementChild.bgColor = '#D9E5F2';
                    // val.firstElementChild.rowSpan = 3;

                }
            })
            c.table = doc.querySelector('table').outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    const pdfJsx = () => {
        return (
            <Table sx={{ minWidth: 400 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table}>
                {/* <TableHead classes={[{ root: classes.customTableContainer }, stickymainHeader]}>
                
            </TableHead> */}
                <TableHead>
                    <TableRow classes={[{ root: classes.customTableContainer }]}>
                        <TableCell align="center" colspan={(ReportData.length) * 2 + 3} >
                            {ReportId === 1 ? "Brokerwise scrip report" : 
                            ReportId === 5 ? "Entitywise scrip holding report":
                            ReportId === 4 ? "Brokerwise  scrip  holding report" 
                            : 'Entitywise Report  '}
                            {"  as on"}
                            {" " + moment(toDate).format("DD/MM/YYYY")}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align='center'>{ReportId === 1 || ReportId === 4 ? 'Broker' : 'Entity'}</TableCell>

                        {ReportData.map((d, i) => (
                            <>
                                <TableCell align="center" colSpan={2}>{ReportId === 1 || ReportId === 4 ? d.brokerName : d.subAccountName}</TableCell>
                            </>
                        ))}

                        <TableCell align="center" colSpan={2}>Total</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scrip</TableCell>
                        {
                            ReportData.map((a, i) => (
                                <>

                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Amount(₹)</TableCell>
                                </>
                            ))
                        }
                        <TableCell align="right">Total Quantity</TableCell>
                        <TableCell align="right">Total Amount(₹)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        company.length === 0 ? <Loader /> : company
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((comp, indx) => {
                                return (
                                    <TableRow>
                                        <TableCell >{comp.companyName}</TableCell>
                                        {ReportData.map((d, i) => {
                                            return (
                                                <>
                                                    {d.companyList.filter(d => d.companyId === comp.companyId).map((da, index) => {
                                                        if (comp.companyId === da.companyId) {
                                                            return (<><TableCell align="right">{NumberOnlySeperator(da.quantity)}</TableCell>
                                                                <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                                                    
                                                                    currency: 'INR', minimumFractionDigits: 2
                                                                }).format(isNaN(da.closingBalance) ? 0 : da.closingBalance)}</TableCell></>)
                                                        } else {
                                                            return (<TableCell align="right">0</TableCell>)
                                                        }
                                                    })}
                                                </>)
                                        })}

                                        <TableCell align="right">{NumberOnlySeperator(comp.quantity)}</TableCell>
                                        <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                            
                                            currency: 'INR', minimumFractionDigits: 2
                                        }).format(isNaN(comp.closingBalance) ? 0 : comp.closingBalance)}</TableCell>
                                    </TableRow>)
                            })}

                    <TableRow style={{ backgroundColor: '#D9E5F2' }}>
                        <TableCell align='left' style={{ fontWeight: 500 }}>Total</TableCell>
                        {totalCalculations.map((val, i) => (
                            <>
                                <TableCell align="right" style={{ fontWeight: 500 }}>{NumberOnlySeperator(val.Qty)}</TableCell>
                                <TableCell align="right" style={{ fontWeight: 500 }}>{new Intl.NumberFormat('en-IN', {
                                    
                                    currency: 'INR', minimumFractionDigits: 2
                                }).format(isNaN(val.Amt) ? 0 : val.Amt)}</TableCell>
                            </>
                        ))}
                    </TableRow>


                </TableBody>
            </Table>
        )
    }

    const printHandler = () => {

        const printElement = ReactDOMServer.renderToString(pdfJsx());
          var opt = {
            margin:       0.5,
            filename:     'Accompt.pdf',
            image:        { type: 'png', quality: 0.98 },
            html2canvas:  { scale: 2},
            jsPDF:        ReportId === 1 ? { unit: 'px', format:  [621, 1500], orientation: 'landscape' } 
                                         : { unit: 'px', format:  [621, 1500], orientation: 'landscape' }
                                        //  :{ unit: 'px', format:  [617, 2000], orientation: 'landscape' }
          };
          
        html2pdf().set(opt).from(printElement).save();
    }

    return (
        <Container

            maxWidth="xl"

        >

            <h3 className="text-center mt-4">{ReportId === 1 ? "Brokerwise scrip report" : 
                ReportId === 5 ? "Entitywise scrip holding report" :
                 ReportId === 4 ? "Brokerwise  scrip  holding report" : 
                                             'Entitywise Report'}</h3>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="space-between"
                mt={4}
                padding={2}
            >
                <Stack justifyContent="flex-start">
                    <Button variant="contained" onClick={() => navigate('/ReportCard')} color="primary" endIcon={<IoArrowBackCircleSharp />}>
                        Go Back
                    </Button>
                </Stack>
                <Stack justifyContent="flex-end" spacing={{ xs: 1, sm: 2, md: 4 }} direction={{ xs: 'column', sm: 'row' }}>
                    <Button variant="contained" onClick={printHandler} color="primary">
                        Export to PDF
                    </Button>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary"
                        table="example"
                        filename={ReportId === 1 ? "Brokerwise scrip report" : 
                            ReportId === 5 ? "Entitywise scrip holding report" :
                            ReportId === 4 ? "Brokerwise  scrip  holding report" : 
                        'Entitywise_Report'}
                        sheet="tablexls"
                        buttonText="EXPORT TO XLSX"

                    />
                    {/* <FileExport fileName={ReportId === 1 ? 'Brokerwise report' : 'Entitywise Report'} /> */}
                    <Button variant="contained" color="primary" id="btnPrint">
                        Print
                    </Button>
                </Stack>
            </Stack>
            <div className="reportTblFirstcolumn">
                <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report" align="center">
                    <TableContainer classes={{ root: classes.customTableContainer }} >

                        <Table ref={pdfRef} stickyHeader sx={{ minWidth: 400 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table}>
                            {/* <TableHead classes={[{ root: classes.customTableContainer }, stickymainHeader]}>
                                
                            </TableHead> */}
                            <TableHead className={stickymainHeader}>
                                <TableRow classes={[{ root: classes.customTableContainer }, stickyHeader]}>
                                    <TableCell align="center" colspan={(ReportData.length) * 2 + 3}>
                                        {ReportId === 1 ? "Brokerwise scrip report" : 
                                         ReportId === 5 ? "Entitywise scrip holding report" :
                                        ReportId === 4 ? "Brokerwise  scrip holding report" : 
                                                                    'Entitywise Report  '}
                                        {"  as on"}
                                        {" " + moment(toDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={stickyHeader}>
                                    <TableCell align='center'>{ReportId === 1  || ReportId === 4 ? 'Broker' : 'Entity'}</TableCell>

                                    {ReportData.map((d, i) => (
                                        <>
                                            <TableCell align="center" colSpan={2}>{ReportId === 1  || ReportId === 4 ? d.brokerName : d.subAccountName}</TableCell>
                                        </>
                                    ))}

                                    <TableCell align="center" colSpan={2}>Total</TableCell>
                                </TableRow>
                                <TableRow className={stickyHeader}>
                                    <TableCell>Scrip</TableCell>
                                    {
                                        ReportData.map((a, i) => (
                                            <>

                                                <TableCell align="right">Quantity</TableCell>
                                                <TableCell align="right">Amount(₹)</TableCell>
                                            </>
                                        ))
                                    }
                                    <TableCell align="right">Total Quantity</TableCell>
                                    <TableCell align="right">Total Amount(₹)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    company.length === 0 ? <Loader /> : company
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((comp, indx) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align="left">{comp.companyName}</TableCell>
                                                    {ReportData.map((d, i) => {
                                                        return (
                                                            <>
                                                                {d.companyList.filter(d => d.companyId === comp.companyId).map((da, index) => {
                                                                    if (comp.companyId === da.companyId) {
                                                                        return (<><TableCell align="right">{NumberOnlySeperator(da.quantity)}</TableCell>
                                                                            <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                                                                currency: 'INR', minimumFractionDigits: 2
                                                                            }).format(isNaN(da.closingBalance) ? 0 : da.closingBalance)}</TableCell></>)
                                                                    } else {
                                                                        return (<TableCell align="right">0</TableCell>)
                                                                    }
                                                                })}
                                                            </>)
                                                    })}

                                                    <TableCell align="right">{NumberOnlySeperator(comp.quantity)}</TableCell>
                                                    <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                                        currency: 'INR', minimumFractionDigits: 2
                                                    }).format(isNaN(comp.closingBalance) ? 0 : comp.closingBalance)}</TableCell>
                                                </TableRow>)
                                        })}

                                <TableRow className={stickyfooter} style={{ backgroundColor: '#D9E5F2' }}>
                                    <TableCell align='left' style={{ fontWeight: 500 }}>Total</TableCell>
                                    {totalCalculations.map((val, i) => (
                                        <>
                                            <TableCell align="right" style={{ fontWeight: 500 }}>{NumberOnlySeperator(val.Qty)}</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 500 }}>{new Intl.NumberFormat('en-IN', {
                                                
                                                currency: 'INR', minimumFractionDigits: 2
                                            }).format(isNaN(val.Amt) ? 0 : val.Amt)}</TableCell>
                                        </>
                                    ))}
                                </TableRow>


                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={company.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // style={{marginTop:"5px" ,paddingBottom:"0px"}}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                </Paper>
            </div>
        </Container>
    );
}
export default ReportTable;