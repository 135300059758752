/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Row, Form } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import { historyInfo } from "../ChannelMaster/channalMasterApi"
import moment from "moment";

function UserHistory(props) {
  const { isOpenModal, onHideModal, userCodeId, trialMembersCode } = props;

  const [userHistoryData, setUserHistoryData] = useState([])
  const [subscriptionHistory, setSubscriptionHistory] = useState([])

  useEffect(() => {
    if (isOpenModal) {
      getUserPlansHistory()
    }
  }, [isOpenModal])

  const getUserPlansHistory = () => {

    historyInfo(userCodeId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            
            let hisroryData = res.data._response.data.history[0]
            setUserHistoryData(hisroryData)

            let subscriptionDetails = hisroryData.subscriptionDetails
            setSubscriptionHistory(subscriptionDetails)
          
          }
        }
      })
      .catch((err) => {
        console.log(err, "error getting fetching history data")
      })
  }


  return (
    <>
      <Modal show={isOpenModal} onHide={onHideModal} size="l" backdrop="static">
        <Modal.Header closeButton className='headerBlue n'>
          <Modal.Title className="mx-auto"><h5> User History</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Paper className='p-3 container mt-3 h-50 border'>

            <Row className="form-set">
              <Form.Label className='col-md-6' >User Code :</Form.Label>
              <Form.Text className='col-md-6 accountText'><Link to='/CPLiveMembers' onClick={() => onHideModal()}>{userHistoryData.subscriberCodeNo} </Link></Form.Text>
            </Row>

            <Row className="form-set">
              <Form.Label className='col-md-6' >Channel Partner Code :</Form.Label>
              <Form.Text className='col-md-6 accountText'>{userHistoryData.subscriberCodeNo} </Form.Text>
            </Row>
            <Row className="form-set">
              <Form.Label className='col-md-6' >Date of joining trial :</Form.Label>
              <Form.Text className='col-md-6 accountText'>{moment(userHistoryData.registrationDate).format("DD/MM/YYYY")} </Form.Text>
            </Row>
            {trialMembersCode === 3 ? null : 
             <Row className="form-set">
              <Form.Label className='col-md-6' >Date of joining as user :</Form.Label>
              <Form.Text className='col-md-6 accountText'>{moment(userHistoryData.firstPaymentDate).format("DD/MM/YYYY")} </Form.Text>
            </Row>
            }

            {trialMembersCode === 3 ? null :
              subscriptionHistory.map((list, i) => {
                return (
                  <Row className="form-set">
                    <Form.Label className='col-md-6' >Date of renewal for FY 20-21 :</Form.Label>
                    <Form.Text className='col-md-6 accountText'>{moment(list.previousSubscriptionStartDate).format("DD/MM/YYYY")} </Form.Text>
                  </Row>
                )
              })
            }


          </Paper>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserHistory


