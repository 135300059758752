/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
import { EntityList, addUnlistedScrip } from '../../../pages/Stocks/StocksAPI'
import Loader from '../../loadingAnimation/index';
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
import { GetMutualFundCompanies, GetCompanyAndBroker, AddMFMerger, UpdateMFMerger, GetMutualFundSellCompanies, AvailableMFSellQuantity, GetMutualFundFolioNo } from '../../../pages/MutualFund/MutualFundAPI'

const MFundMerger = (props) => {
  const { showM, onHideM, mutualFHeaderIdM, mutualFTransactionM, mutualFIdM, showmergerPreview } = props;
  const [scrip, setScript] = useState({});
  const [selScripOld, setSelScripOld] = useState([]);
  const [selScripMerge, setSelScripMerge] = useState([]);
  const [broker, setBroker] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [allcompanyList, setAllCompanyList] = useState([]);
  const [data, setData] = useState({ quantity: "" });
  const [selfolioNumber, setSelFolioNumber] = useState([]);
  const [folioNumber, setFolioNumber] = useState([]);
  const [narration, setNarration] = useState({ narration: "" });
  const [formErr, setFormErr] = useState([])
  const [newShowC, setNewShowC] = useState(false);
  const [selNewMerge, setSelNewMerge] = useState([]);
  const [brokerValue, setBrokerValue] = useState([])
  const [loading, setLoading] = useState(false);
  const [TypeList, setTypeList] = useState([{
    label: 'SIP', value: 41
  }, { label: 'Lumpsum', value: 42 }]);
  const [selType, setSelType] = useState([]);
  // const [successMessage, setSuccessMessage] = useState({});
  // const [brokerValue, setBrokerValue] = useState([])
  const [entityValue, setEntityValue] = useState([])
  const [buyDate, setBuyDate] = useState(null)
  const [ratio, setRatio] = useState('')

  // unlisted new company add
  const [showModal, setShowModal] = useState(false);
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');



  // close model
  const handleClose = () => {
    onHideM();
    handleReset();
  }
  //modal handle
  const handleclose = () => {
    setNewShowC(false)
  }
  // const handleOpen = () => {
  //   setNewShowC(true)
  // }

  //handling error
  const errHandel = () => {
    let formErr = {};
    let isValid = true;
    if (selType.length < 1) {
      isValid = false;
      formErr["selType"] = "Please Select Type";
    }
    if (selScripOld.length < 1) {
      isValid = false;
      formErr["selScripOld"] = "Please Select Scrip!";
    }
    if (selScripMerge.length < 1) {
      isValid = false;
      formErr["selScripMerge"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }
    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }
    if (!data.quantity || data.quantity === 0) {
      isValid = false;
      formErr["quantity"] = "Please Fill This Field!";
    }
    if (data.quantity) {
      if (data.quantity < 0) {
        isValid = false;
        formErr["quantity"] = "Please Enter Positive Value !";
      }
    }
    if (selfolioNumber.length < 1) {
      isValid = false;
      formErr["selfolioNumber"] = "Please Select Folio Number!";
    }
    // if (data.quantity) {
    //   let qvalue = parseFloat(data.quantity); 
    //   if (Number.isInteger(qvalue) == false) {
    //     isValid = false;
    //     formErr["quantity"] = "Please enter only integer value !";
    //   }
    // }

    if (selScripOld === selScripMerge) {
      isValid = false;
      formErr["selScripMerge"] = "Merging Into Company Is Same As Merging Company!";
    }
    setFormErr(formErr);
    return isValid;
  };

  useEffect(() => {
    if (showM) {
      (async () => {
        if (mutualFHeaderIdM === 0 || mutualFHeaderIdM === undefined) {
          await getEntityListt();
          await getCompanyList();
        }
      })();
    }
  }, [showM]);

  useEffect(() => {
    if (showM) {
      (async () => {
        if (selEntity && mutualFHeaderIdM === 0 || mutualFHeaderIdM === undefined) {
          await getCompanyBrokerDropDownData();
        }
      })();
    }
  }, [selEntity]);

  // get companies list
  useEffect(() => {
    if (showM) {
      if (mutualFIdM === 0 || mutualFIdM === undefined) {
        if (selEntity.value && selBroker.value && selType.value) {
          getCompanyData(selEntity.value, selBroker.value, selType.value)
        }
      }
    }

  }, [selEntity, selBroker, selType, showM]);
  // get dropDown Company Data
  const getCompanyBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await GetCompanyAndBroker(sub_Id)
        .then((res) => {
          if (res.data._response.status === 1) {

            // broker
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setBroker(brokersData);
            setLoading(false);
          } else {
            setBroker([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setBroker([]);
          setLoading(false);
        });
    }
  };

  // get dropDown Company Data buy ins
  const getCompanyData = async () => {
    let sub_Id = selEntity.value;
    let Bro_Id = selBroker.value;
    let InstTypeID = selType.value;
    if (sub_Id !== undefined && Bro_Id !== undefined && InstTypeID !== undefined) {
      setLoading(true);
      await GetMutualFundSellCompanies(sub_Id, Bro_Id, InstTypeID)
        .then((res) => {
          if (res.data._response.status === 1) {
            // company
            let Company = [];
            let company = res.data._response.data.fetchCompanyBySub;
            company && company.map((i) => {
              return (Company.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId }))
            })

            setCompanyList(Company);
            setLoading(false);
          } else {
            setCompanyList([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
        });
    }
  };

  //getcompanylist by group
  const getCompanyList = async (ListedCompanyParams) => {
    setLoading(true)
    await GetMutualFundCompanies()
      .then((res) => {
        if (res.data._response.status === 1) {
          //
          setLoading(false)
          let SipCompany = [];
          let company = res.data._response.data.mutualFundCompanies;

          company &&
            company.map(i => {
              return (SipCompany.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId, companyType: i.instrumentTypeId }))
            });


          // if (ListedCompanyParams === 1) {

          //   let lastElement = UnlistedCompany[UnlistedCompany.length - 1];

          //   setSelScripMerge(lastElement)

          // }
          setAllCompanyList(SipCompany);
          setLoading(false)

        } else {
          setLoading(false)
          setAllCompanyList([]);
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setAllCompanyList([]);
        console.log(e, 'error in companyList');
        // setError(e.message);
      });
  };
  //getfolionumber
  const getFolioNumberDropDownData = async () => {
    let companyId = selScripOld.value;
    let brokerId = selBroker.value;
    let subAccountId = selEntity.value;
    let insType_id = selType.value;
    if (companyId !== undefined && brokerId !== undefined && subAccountId !== undefined && insType_id !== undefined) {
      setLoading(true);
      await GetMutualFundFolioNo(subAccountId, companyId, insType_id, brokerId)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              let data = res.data._response.data.folioNumbers;

              let arr = [];
              data && data.map((d, i) => {
                arr.push({ label: d, value: d });
              })
              setFolioNumber(arr);
            } else {
              setFolioNumber([]);
            }
          }

        }).catch(e => {
          console.log(e, 'error in getFolioNo Api');
          setLoading(false);
        })
    }
  }

  const getBuyDate = async (sub_id, co_id, bro_id, insType_id) => {
    setLoading(true)
    await AvailableMFSellQuantity(sub_id, co_id, bro_id, insType_id)
      .then((res) => {
        setLoading(false)
        if (res.data._response.status === 1) {
          setBuyDate(res.data._response.data.minimum_Date)
        }
      })
      .catch((e) => {
        setLoading(false)
      });
  };

  //getEntityList
  const getEntityListt = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          //
          let entity = res.data._response.data.subaccountDetails;
          let entityData = [];
          entity &&
            entity.map((data) => {
              return (entityData.push({
                value: data.subAccountId,
                label: data.subAccountName,
                disabled: data.active
              }))
            });
          setEntity(entityData);
          setLoading(false)
        } else {
          setLoading(false);
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });


  };
  // unlisted modal new company
  const handleOpenModal = () => setShowModal(!showModal);

  const handleCloseModal = () => {
    setShowModal(false);
    setUnlistedComp('');
    setUnlistedCompErr('')
  }

  //handling unlisted modal 
  const handleUnlisted = (e) => {
    const { value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }

  //submit unlisted modal 
  const submitUnlisted = (e) => {
    e.preventDefault();

    if (unlistedComp !== '') {
      let data = {
        "companyName": unlistedComp,
        instrumentTypeId: 10
      }
      setLoading(true);
      addUnlistedScrip(data)
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              // console.log(res, "submit data ");
              const lastEle = 1
              getCompanyList(lastEle);
              setUnlistedComp('');
              setUnlistedCompErr('');
              handleCloseModal()
              // swal("Added successfully!", "", 'success');
            } else {
              Swal.fire(res.data._response.message, '', 'info')
              setLoading(false)
            }
          } else {
            setLoading(false)
          }
        })
        .catch(e => {
          setLoading(false)
          console.log(e, "error in unlisted");
        })
    } else {
      setUnlistedCompErr("Company name required!")
    }
  }

  const Control = ({ children, ...props }) => (

    <components.Control {...props}>
      <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )

  useEffect(() => {
    if (mutualFHeaderIdM !== 0 && mutualFHeaderIdM !== undefined) {
      if (Object.keys(mutualFTransactionM).length !== 0) {
        setLoading(true)
        setSelBroker({
          value: mutualFTransactionM.brokerId,
          label: mutualFTransactionM.broker.brokerName,
        });
        setBrokerValue(mutualFTransactionM.brokerId);

        setSelEntity({
          value: mutualFTransactionM.subAccountId,
          label: mutualFTransactionM.subAccount.subAccountName,
        });
        setEntityValue(mutualFTransactionM.subAccountId);
        setPDate(new Date(mutualFTransactionM.date));
        setNarration({ narration: mutualFTransactionM.narration })

        mutualFTransactionM.mutualFundDetails && mutualFTransactionM.mutualFundDetails.map((t) => {
          setSelFolioNumber({
            value: t.certificateFolioNo,
            label: t.certificateFolioNo,
          })
          setData({ quantity: t.units });
          setRatio(t.ratio);
          setSelScripMerge(t.mutualFundCompany);
          const c = t.fromCompany;
          setSelScripOld({ label: c.mutualFundCompanyName, value: t.fromCompanyId });
          t.instrumentTypeId === 41 ? setSelType({ value: 41, label: "SIP" }) : setSelType({ value: 42, label: "Lumpsum" })
          setLoading(false);
        })
      }
    }
  }, [mutualFTransactionM])

  //handlechange
  const handleInputOld = (selected) => {
    if (selScripMerge !== selected) {
      setSelScripOld(selected);
      setFormErr({ ...formErr, selScripOld: '' });
    }
    else {
      setSelScripOld([])
      formErr["selScripOld"] = "Same Company Selected....";
      setFormErr(formErr)

    }

  }


  //handel disable listed companies
  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }

  const handleInputMerge = (selected) => {
    if (selScripOld !== selected) {
      setSelScripMerge(selected);
      setFormErr({ ...formErr, selScripMerge: '' });
    } else {
      setSelScripMerge([])
      formErr["selScripMerge"] = "Same Company Selected....";
      setFormErr(formErr)
    }
  }

  const handleInputNewMerge = (selected) => {
    setSelNewMerge(selected);
  }

  const handleEntity = (selected) => {
    setEntityValue(selected.value)
    setSelEntity(selected);
    setSelScripOld([]);
    setSelBroker([]);
    setFormErr({ ...formErr, selEntity: '' });
  }

  const handleOption = (selected) => {
    setBrokerValue(selected.value);
    setSelBroker(selected);
    setSelScripOld([])
    setSelScripMerge([])
    setFormErr({ ...formErr, selBroker: '' })
  }

  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: '' })
  }

  const handleFolioNumber = (selected) => {
    setSelFolioNumber(selected);
    setFormErr({ ...formErr, selfolioNumber: "" });
  };
  const handleValueQ = (e) => {
    const { name, rawValue } = e.target;
    setData({ ...data, [name]: rawValue });
    setFormErr({ ...data, quantity: "" });
  };

  const handleValueN = (e) => {
    const { name, value } = e.target;
    setNarration({ ...narration, [name]: value });
    setFormErr({ ...narration, narration: "" });
  };

  const handleRatio = (e) => {
    setRatio(e.target.value.replace(/[^0-9:]/ig, ''))
  }

  //reset form
  const handleReset = () => {
    setCompanyList([]);
    setBroker([])
    setSelBroker([]);
    setSelScripOld([]);
    setSelScripMerge([]);
    setSelFolioNumber([]);
    setSelBroker([]);
    setSelEntity([]);
    setData({ quantity: '' });
    setNarration({ narration: "" });
    setPDate(null);
    setFormErr({});
    setRatio('');
    setSelType([]);
  }

  // for buy date
  useEffect(() => {
    if (showM) {
      let companyId = selScripOld.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      let insType_id = selType.value;
      let folioNumber = selfolioNumber.value;

      if (companyId && brokerId && subAccountId && insType_id && folioNumber) {
        getBuyDate(subAccountId, companyId, brokerId, insType_id, folioNumber);
      }
      if (companyId && brokerId && subAccountId && insType_id) {
        getFolioNumberDropDownData(subAccountId, brokerId, insType_id, companyId)
      }
    }
  }, [selScripOld, selBroker, selEntity, showM, selfolioNumber]);

  //get buy folionumber
  useEffect(() => {
    if (showM) {
      let companyId = selScripOld.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      let insType_id = selType.value;
      if (companyId && brokerId && subAccountId && insType_id) {
        getFolioNumberDropDownData(subAccountId, brokerId, insType_id, companyId)
      }

    }
  }, [selScripOld, selBroker, selEntity, showM])

  //submit form
  const handleSubmit = (e) => {
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      let Sdata = {
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        date: fixTimezoneOffset(pDate),
        narration: narration.narration,
        fromCompanyId: selScripOld.value,
        mutualFundCompanyId: selScripMerge.value,
        certificateFolioNo: selfolioNumber.value,
        units: Number(data.quantity),
        ratio: ratio,
        instrumentTypeId: selType.value
      };

      AddMFMerger(Sdata).then((res) => {
        setLoading(false);
        if (res.data._response.status === 1) {
          Swal.fire("Added successfully!", "", "success");
          handleReset();
        }
        else {
          Swal.fire("No Quantities Available For Merging!", "", "warning");
          console.log("eror while data added with respnose");
        }

      }).catch((error) => {
        setLoading(false);
        console.log(error, "error while submitting data in stock merger");
      })

    }

  }
  const handleUpdate = (e) => {
    // e.preventDefault();

    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      let Sdata = {
        mutualFundId: mutualFIdM,
        mutualFundHeaderId: mutualFHeaderIdM,
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        certificateFolioNo: selfolioNumber.value,
        date: fixTimezoneOffset(pDate),
        narration: narration.narration,
        fromCompanyId: selScripOld.value,
        mutualFundCompanyId: selScripMerge.value,
        units: Number(data.quantity),
        ratio: ratio,
        instrumentTypeId: selType.value

      };
      UpdateMFMerger(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.data._response.status === 1) {
            Swal.fire("Updated successfully!", "", "success");
          }
          else {
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  //submit new company to merge
  const AddCompany = (e) => {
    e.preventDefault();
    let cData = {
      "newMerge": selNewMerge.value,
    }
    setScript(cData);
    setNewShowC(false)

  }

  const disableButton = () => {
    return data && narration && pDate && selBroker && selEntity && selScripOld && selScripMerge
  }

  const fixTimezoneOffset = (pDate) => {
    if (!pDate) return "";
    return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
  }

  return (
    <>
      <Modal show={showM} onHide={handleClose} size="lg" centered backdrop="static">
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto"><h5>Mutual Funds - Merger</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mt-2 p-6">
              {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
              <Form className="border border-1 rounded p-3">

                <Row className="form-set">
                  <Form.Group className="col-md-4">
                    <Form.Label>Type<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selType}
                      onChange={(sel) => setSelType(sel)}
                      options={TypeList}
                      name="InstrumentTypeID"
                      isDisabled={mutualFIdM}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selType}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleEntity}
                      options={entity}
                      name="subAccountId"
                      isOptionDisabled={(option) => option.disabled === 0}
                      isDisabled={mutualFIdM !== 0 && mutualFIdM !== undefined}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-md-4" >
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleOption}
                      options={broker}
                      name="brokerId"
                      isDisabled={mutualFIdM !== 0 && mutualFIdM !== undefined}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>

                </Row>
                <Row className="form-set">

                  <Form.Group className="col-md-6" >
                    <Form.Label>Select Scrip of Merging company <span className="text-danger h6">*</span></Form.Label>

                    <Select
                      value={selScripOld}
                      onChange={handleInputOld}
                      options={companyList}
                      name="fromCompanyId"
                      isOptionDisabled={handelDisableDD}
                      isDisabled={mutualFIdM !== 0 && mutualFIdM !== undefined}
                      styles={{
                        option: (data) => ({
                          ...data,
                          margin: 0,
                          padding: '3px 12px',
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          flex: '1 1',
                          color: '#000',
                          margin: 0,
                          padding: '7px 2px',
                          // border: `2px solid `,
                          background: "#8bc3ff",
                          fontSize: 'small',
                          fontWeight: 500
                        }),
                      }}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selScripOld}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-md-6" >
                    <Form.Label>Select Scrip of merging into company <span className="text-danger h6">*</span></Form.Label>

                    <Select
                      components={selBroker.value === 1 ? { Control } : null}
                      value={selScripMerge}
                      onChange={handleInputMerge}
                      options={allcompanyList}
                      name="companyId"
                      isOptionDisabled={handelDisableDD}
                      isDisabled={mutualFIdM !== 0 && mutualFIdM !== undefined}
                      styles={{
                        option: (data) => ({
                          ...data,
                          margin: 0,
                          padding: '3px 12px',
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          flex: '1 1',
                          color: '#000',
                          margin: 0,
                          padding: '7px 2px',
                          // border: `2px solid `,
                          background: "#8bc3ff",
                          fontSize: 'small',
                          fontWeight: 500
                        }),
                      }}
                    // onBlur={CompareCompany}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selScripMerge}
                      </div>
                    ) : null}
                  </Form.Group>

                </Row>

                <Row className='form-set'>
                  <Form.Group className="col-md-4">
                    <Form.Label>Folio Number<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selfolioNumber}
                      onChange={handleFolioNumber}
                      options={folioNumber}
                      name="folioNumber"
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selfolioNumber}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-md-4" >
                    <Form.Label>Balance Unit after merging<span className="text-danger h6">*</span></Form.Label>
                    {/* <Form.Control type="Number" name="quantity" min="0" value={data.quantity} onChange={handleValueQ} /> */}
                    <Cleave
                      className='form-control'
                      name="quantity"
                      autoComplete="off"
                      options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                      value={data.quantity}
                      onChange={handleValueQ}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.quantity}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <Form.Label>Date of purchase<span className="text-danger h6">*</span></Form.Label>
                    <DatePicker className="form-control"
                      placeholderText="Select Start Date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      minDate={new Date(buyDate)}
                      onChange={handleDate}
                      dateFormat="dd-MM-yyyy"
                      name="date"
                      autoComplete='off'
                      selected={pDate}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.date}
                      </div>) : null}

                  </Form.Group>
                </Row>
                <Row className="form-set">
                  <Form.Group className="col-md-4" >
                    <Form.Label> Ratio </Form.Label>
                    <Form.Control type="text" name="amount" min="0" placeholder="Enter Ratio" autoComplete="off" onChange={handleRatio} value={ratio} />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.ratio}</div> : null}
                  </Form.Group>

                  <Form.Group className="col-md-8 mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Comments/Remarks</Form.Label>
                    <Form.Control as="textarea" name="narration" value={narration.narration} rows={2} onChange={handleValueN} />
                    {/* {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.narration}
                      </div>
                    ) : null} */}
                  </Form.Group>
                </Row>
                <Row>
                  <Col md="12" className="text-center mt-3">
                    {mutualFHeaderIdM === 0 || mutualFHeaderIdM === undefined ? (

                      <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={handleSubmit} disabled={!disableButton()}>
                        Save
                      </Button>
                    ) : (
                      <Button variant="primary" className="mx-2 w100" id = "submitButton" onClick={handleUpdate} disabled={!disableButton()}>
                        Update
                      </Button>
                    )}
                    {mutualFHeaderIdM === 0 || mutualFHeaderIdM === undefined ?
                      <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>
                        Clear
                      </Button> : null}
                  </Col>
                </Row>

              </Form>


            </div>
            <div >
              <Modal show={newShowC} onHide={handleclose} size="sm">
                <Modal.Header closeButton>
                  <Modal.Title>Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="col-12" >
                      <Form.Label>Select new Scrip of merging into company </Form.Label>

                      <Select
                        value={selNewMerge}
                        onChange={handleInputNewMerge}
                        options={companyList}
                        name="newMerge"
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleclose}>
                    Close
                  </Button>
                  <Button variant="primary" type='submit' onClick={AddCompany}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>


            </div>
          </>
        </Modal.Body>

      </Modal>

      {/* to company added unlisted company */}
      <>
        <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" centered>
          <Modal.Header closeButton>
            <Modal.Title>Unlisted Scrip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form >
              <div className="form-group mt-2 mb-2">
                <label for="inputUserName">Company Name</label>
                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp.companyName} />
                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
              Save
            </Button>

          </Modal.Footer>
        </Modal>
      </>
      {showmergerPreview === true ?
        <Modal show={showmergerPreview} onHide={() => handleClose()}>
          <Modal.Header closeButton className="headerBlue" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Stock Merger Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Select Scrip of Merging Company : </div>
                <div className="transactionDataValue">{selScripOld.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Select Scrip of Merging into Company  : </div>
                <div className="transactionDataValue">{selScripMerge.label}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Folio Number : </div>
                <div className="transactionDataValue">{selfolioNumber.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Balance shares after merging : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(data.quantity)}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Date of Purchase : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Comments/Remarks : </div>
                <div className="transactionDataValue">{narration.narration}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  )
}

export default MFundMerger;
