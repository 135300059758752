/* eslint-disable import/no-anonymous-default-export */
import { SIDEBAR_DATA, CLEAR_SIDEBAR_DATA, OPEN_CLOSE_SIDEBAR } from "../action/sidebarAction"

const initialState = {
    sidebardata:[],
    openclosesidebar: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_DATA:
            return {
                ...state,
                sidebardata: action.payload
            }
        case CLEAR_SIDEBAR_DATA:
            return {
                ...state,
                sidebardata: []
            }
        case OPEN_CLOSE_SIDEBAR:
            return {
                ...state,
                openclosesidebar: !state.openclosesidebar
            }
        default:
            return state
    }
}