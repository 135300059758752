import React from "react";
import { Container } from "react-bootstrap";
import ChangePass from '../../components/Login/ChangePass';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const ChangePassword = ()=>{
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return(
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <Container className="pt-5">
                        <ChangePass/>
                    </Container>
                </div>
            </div>
        </div>
        
    )
}

export default ChangePassword;