import React from 'react';
import Main from '../../components/Dashboard/Main';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Dashboard() {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
        {sidebarOpen && <Sidebar />}
      <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
          <Navbar />
        <div>
          <Main />
        </div>
      </div>
    </div>
  )
}

export default Dashboard;


