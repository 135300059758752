
import React from 'react'

function NotFound() {
  const styles = {
    errorTemplate: { padding: "40px 15px", textAlign: "center" },
    errorActions: { marginTop: "15px", marginBottom: "15px" },
    errorActionsBtn: { marginRight: "10px" },
    verticalCenters: {
      // 'minHeight': '80%', 
      'minHeight': '80vh',
      'display': 'flex',
      'alignItems': 'center'
    },
    backGround: {
      position: 'absolute',
      left: '40%',
      top: '30%',
      zIndex: -1,
      fontSize: '162px',
      fontWeight: 900,
      color: '#ececec'
    }
  }
  return (
    <div className="container">
      <div className="row" style={styles.verticalCenters}>
        <div className="col-md-12">
          <div style={styles.errorTemplate}>
            <h1>
              Oops!</h1>
            <h2 style={styles.backGround}>
              404 </h2>
            <div className="error-details">
              Sorry, an error has occured, Requested page not found!
            </div>
            <div className={styles.errorActions}>
              <p className="btn btn-primary btn-lg errorActionsBtn"><span className="glyphicon glyphicon-home"></span>
                Take Me Home </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default NotFound