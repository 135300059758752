/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import StocksOpenBal from './forms/StocksOpenBal';
import FDopeningBal from './forms/FDopeningBal';
import MFOpeningBalance from './forms/MFOpeningBalance';
import BullionOpnBal from './forms/BullionOpnBal';
import { Modal, Button, Col, Form, Stack, FormGroup } from 'react-bootstrap';
import Select from "react-select";
import { EntityList, Addbroker } from '../../pages/Stocks/StocksAPI';
import Swal from "sweetalert2";
import { TypeList, TypeListFD, TypeListMF } from '../../modules/helpers/constant';

import Auth from '../../modules/Auth/index';

const userID = Auth.getsubcriberId();

function OpeningBalance() {
    const [switchOp, setSwitchOp] = useState('stock');
    const [selFamily, setSelFamily] = useState([]);
    // const [selFamilyFD, setSelFamilyFD] = useState([]);
    // const [selFamilyMF, setSelFamilyMF] = useState([]);

    const [type, setType] = useState(TypeList);
    const [selType, setSelType] = useState(TypeList[0]);
    const [typeFD, setTypeFD] = useState(TypeListFD);
    const [selTypeFD, setSelTypeFD] = useState(TypeListFD[0]);
    const [typeMF, setTypeMF] = useState(TypeListMF);
    const [selTypeMF, setSelTypeMF] = useState(TypeListMF[0]);

    const [disableSelect, setDisableSelect] = useState(true)
    const [disableSel, setDisableSel] = useState(false)

    const [loading, setLoading] = useState(false)
    const [Entity, setEntity] = useState([]);
    const [brokershow, setBrokershow] = useState(false)
    const [addbrokers, setAddbrokers] = useState('');
    const [brokersCompErr, setBrokersErr] = useState('');
    const [error, setError] = useState('');
    const [errorBroker, setErrorBroker] = useState('');
    const [brokerCall, setBrokerCall] = useState(false);

    const commonStyle = { minWidth: "16rem !important" }

    const childFunc = React.useRef(null)
    const [typeFilter, setTypeFilter] = useState(null);
    const [typeFilterFD, setTypeFilterFD] = useState(null);
    const [typeFilterMF, setTypeFilterMF] = useState(null);

    useEffect(() => {
        (async () => {
            if (userID) { await getEntityListt(); }
        })();
    }, []);

    useEffect(() => {
        setTypeFilter(selType.value)
        setTypeFilterFD(selTypeFD.value)
        setTypeFilterMF(selTypeMF.value)
    }, [selType, selTypeFD, selTypeMF])

    //getEntityList
    const getEntityListt = async () => {
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                } else {
                    setLoading(false);
                    setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.message);
            });
    };
    const handleFamily = (selected) => {
        setSelFamily(selected);
        setError('');
        setErrorBroker('')
        childFunc.current()
        // setFormErr({ ...formErr, selFamily: '' });
    }
    // const handleFamilyFD = (selected) => {
    //     setSelFamilyFD(selected);
    //     setErrorBroker('')
    //     childFunc.current()
    //     // setFormErr({ ...formErr, selFamily: '' });
    // }
    // const handleFamilyMF = (selected) => {
    //     setSelFamilyMF(selected);
    //     setErrorBroker('')
    //     childFunc.current()
    //     // setFormErr({ ...formErr, selFamily: '' });
    // }
    const handleType = (selected) => {
        setSelType(selected);
        childFunc.current()
        // setFormErr({ ...formErr, selFamily: '' });
    }
    const handleTypeFD = (selected) => {
        setSelTypeFD(selected)
        childFunc.current()
    }
    const handleTypeMF = (selected) => {
        setSelTypeMF(selected)
        childFunc.current()
    }
    const callback = useCallback((val, data) => {
        if (data) {
            setDisableSelect(val);
        } else {
            setDisableSelect(val);
            setSelFamily([])
            setSelType(TypeList[0]);
        }
    }, []);

    const lock = useCallback((val, data) => {
        if (data) {
            setDisableSel(val)
        } else {
            setSelFamily([])
            setSelType(TypeList[0]);
        }
    }, []);

    const callbackFD = useCallback((val, data) => {
        if (data) {
            setDisableSelect(val);
        } else {
            setDisableSelect(val);
            // setSelFamilyFD([])
            setSelFamily([])
            setSelTypeFD(TypeListFD[0]);
        }
    }, []);

    const callbackMF = useCallback((val, data) => {
        if (data) {
            setDisableSelect(val);
        } else {
            setDisableSelect(val);
            // setSelFamilyMF([]);
            setSelFamily([]);
            setSelTypeMF(TypeListMF[0]);
        }
    }, []);

    const callbackBullion = useCallback((val, data) => {
        if (data) {
            setDisableSelect(val);
        } else {
            setDisableSelect(val);
            setSelFamily([]);
        }
    }, []);

    const handleForms = (e) => {
        const { value } = e.target;
        setSwitchOp(value)
        setSelFamily([])
        // setSelFamilyFD([])
        // setSelFamilyMF([])
        setSelType(TypeList[0])
        setSelTypeFD(TypeListFD[0])
        setSelTypeMF(TypeListMF[0])
        setErrorBroker('')
    }
    const handleaddbrokershow = () => {
        if (selFamily !== '') {
            // if(selFamily != '' ||selFamilyFD !='' || selFamilyMF != ''){
            setBrokershow(!brokershow);
        } else {
            setErrorBroker("*Please Select Family")
        }
    }
    const handleaddbroker = (e) => {
        const { name, value } = e.target;
        setAddbrokers(value.replace(/[^a-zA-Z0-9@_" "]/ig, ''));
        setBrokersErr('')
    }
    const submitbroker = () => {
        if (addbrokers !== '') {
            setLoading(true);
            let data = {
                brokerName: addbrokers,
                subAccountId: selFamily.value
            }
            Addbroker(data)
                .then(async (res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        setAddbrokers('');
                        setBrokersErr('');
                        setBrokerCall(true);
                        Swal.fire("Broker Added Successfully!", "", 'success');
                        // let t = res.data._response.data.companyName
                        // let v = res.data._response.data.companyId
                        // let comp = { label: t, value: v }
                        // unlistedCompany.unshift(comp)
                        handleCloseModal();
                        //  getUnlistedComapy(Id);
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setLoading(false)
                })
        } else {
            setBrokersErr("Broker name required!")
        }
    }
    const handleCloseModal = () => {
        setBrokershow(false)
        setAddbrokers('');
        setBrokersErr('')
    }
    return (
        <div>
            <div className="mt-3 ">
                <h4 className="text-center py-3">Opening Balance</h4>
                {/* <div className="d-flex"> */}
                <Stack direction="horizontal" gap={3}>
                    <div style={{ paddingTop: "20px", marginLeft: "20px", paddingBottom: "10px" }} >
                        <Col>
                            <Form.Group className="text-center mt-2" key="formGridRadio">
                                <Form.Label>
                                    <Form.Check id="formGridRadio" type="radio" name="switchOP" value="stock" label="Stock" onChange={handleForms} checked={switchOp === "stock"} disabled={disableSel} />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </div>
                    <div style={{ paddingTop: "20px", paddingBottom: "10px" }} >
                        <Col>
                            <Form.Group className="text-center mt-2" key="formGridRadio1">
                                <Form.Label>
                                    <Form.Check id="formGridRadio1" type="radio" name="switchOP" value="FD" label="Fixed Income" onChange={handleForms} checked={switchOp === "FD"} disabled={disableSel} />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </div>
                    <div style={{ paddingTop: "20px", paddingBottom: "10px" }} >
                        <Col>
                            <Form.Group className="text-center mt-2" key="formGridRadio2">
                                <Form.Label>
                                    <Form.Check id="formGridRadio2" type="radio" name="switchOP" value="MF" label="Mutual Funds" onChange={handleForms} checked={switchOp === "MF"} disabled={disableSel} />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </div>
                    <div style={{ paddingTop: "20px", paddingBottom: "10px" }} >
                        <Col>
                            <Form.Group className="text-center mt-2" key="formGridRadio3">
                                <Form.Label>
                                    <Form.Check id="formGridRadio3" type="radio" name="switchOP" value="Bullion" label="Bullion" onChange={handleForms} checked={switchOp === "Bullion"} disabled={disableSel} />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </div>
                    <div className='ms-auto'></div>
                    <div className="col-2" style={{ alignContent: "right" }}>
                        <Form.Group style={{ paddingBottom: "10px" }} >
                            <Form.Label>Family</Form.Label><span className="text-danger h6">*</span>
                            <Select
                                value={selFamily}
                                onChange={handleFamily}
                                // options={Family}
                                options={Entity}
                                name="subAccountId"
                                styles={commonStyle}
                                isOptionDisabled={(option) => option.disabled === 0}
                            // isDisabled={disableSel}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-2">
                        {switchOp !== "Bullion" && disableSelect ?
                            <Form.Group style={{ paddingBottom: "10px" }} >
                                <Form.Label>Type</Form.Label>
                                <Select
                                    value={switchOp === "stock" && disableSelect ? selType : switchOp === "FD" && disableSelect ? selTypeFD : switchOp === "MF" && disableSelect ? selTypeMF : null}
                                    onChange={switchOp === "stock" && disableSelect ? handleType : switchOp === "FD" && disableSelect ? handleTypeFD : switchOp === "MF" && disableSelect ? handleTypeMF : null}
                                    // options={Family}
                                    options={switchOp === "stock" && disableSelect ? type : switchOp === "FD" && disableSelect ? typeFD : switchOp === "MF" && disableSelect ? typeMF : null}
                                    name="subAccountId"
                                    isOptionDisabled={(option) => option.disabled === 0}
                                    isDisabled={disableSel}
                                />
                            </Form.Group> : null}
                        {/* : switchOp === "FD" && disableSelect ? */}
                        {/* <Form.Group style={{paddingBottom:"10px"}} >
                        <Form.Label>Type</Form.Label>
                        <Select
                            value={selTypeFD}
                            onChange={handleTypeFD}
                            // options={Family}
                            options={typeFD}
                            name="subAccountId"
                            isOptionDisabled={(option) => option.disabled == 0}
                            isDisabled={disableSel}
                        />
                    </Form.Group>
                    : switchOp === "MF" && disableSelect ?
                    <Form.Group style={{paddingBottom:"10px"}} >
                        <Form.Label>Type</Form.Label>
                        <Select
                            value={selTypeMF}
                            onChange={handleTypeMF}
                            // options={Family}
                            options={typeMF}
                            name="subAccountId"
                            // isOptionDisabled={(option) => option.disabled == 0}
                            // isDisabled={disableSel}
                        />
                    </Form.Group> : null
                    } */}
                    </div>

                    <div>
                        {switchOp === "stock" && disableSelect && typeFilter === 1 || typeFilter === 3 || switchOp === "FD" && disableSelect && typeFilterFD === 1 || typeFilterFD === 3 || switchOp === "MF" && typeFilterMF === 1 || typeFilterMF === 2 || typeFilterMF === 3 ?
                            <Form.Group style={{ paddingTop: "20px", marginRight: "40px", paddingBottom: "10px" }}>
                                <Button onClick={handleaddbrokershow} >Add Broker</Button>
                                {errorBroker ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{errorBroker}</div> : null}
                            </Form.Group>
                            : <FormGroup style={{ paddingTop: "20px", marginRight: "40px", paddingBottom: "10px" }}></FormGroup>}
                    </div>
                </Stack>
                <>
                    <Modal show={brokershow} onHide={handleCloseModal} animation={false} size="sm" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Broker</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form >
                                <div className="form-group mt-2 mb-2">
                                    <label htmlFor="inputUserName">Broker Name</label>
                                    <input className="form-control mt-2" placeholder="Enter Broker name.." type="text" autoComplete="off" name="brokerName" onChange={handleaddbroker} value={addbrokers} />
                                    {brokersCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{brokersCompErr}</div> : null}
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center">
                            <Button variant="primary" className="mx-2" onClick={submitbroker}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                {/* {switchOp == "MF" ? <MFOpeningBalance /> : <StocksOpenBal family={selFamily} parentCallback={callback} />} */}
                {switchOp === "MF" ? <MFOpeningBalance familyMF={selFamily} parentCallback={callbackMF} typeMF={typeFilterMF} lock={lock} childFunc={childFunc} brokerCall={brokerCall} /> : switchOp === "FD" ? <FDopeningBal familyFD={selFamily} parentCallback={callbackFD} newFilterTypeFD={typeFilterFD} lock={lock} childFunc={childFunc} brokerCall={brokerCall} /> : switchOp === "Bullion" ? <BullionOpnBal familyB={selFamily} parentCallback={callbackBullion} /> : <StocksOpenBal family={selFamily} parentCallback={callback} newFilterType={typeFilter} typelist={selType} childFunc={childFunc} lock={lock} brokerCall={brokerCall} />}
            </div>
        </div>
    )
}
export default OpeningBalance