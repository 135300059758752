/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import MaterialTable, { MTableEditField } from "material-table";
import Select from "react-select";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import Swal from 'sweetalert2';
import { Stack } from "@mui/material";
import { Alert, AlertTitle } from '@material-ui/lab';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavefocusedTxtField,cleavedisabledField } from '../../StocksDetail/ClevTablCss';
import Cleave from 'cleave.js/react';
import { bullionUnit, bullionUnit2, bullionUnit3 } from '../../../modules/helpers/constant';
import { FetchBulionsInvestmentType, AddBulionsOpeningBal } from '../../../pages/Bullion/BullionApi';
import useEnterKeyListener from '../../ShortcutKey/useKeyListener';
import moment from 'moment';

const BullionOpnBal = (props) => {
  const { familyB } = props;
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  // const [showUnlisted, setShowUnlisted] = useState(false);
  // const [tenure, setTenure] = useState('');
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState('');
  const [investmentType, setInvestmentType] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEffect(() => {
    getInvestmentTypes()
  }, [])
  //shortcutkey
  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  //get investment type
  const getInvestmentTypes = async () => {
    await FetchBulionsInvestmentType().then(res => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let investment = res.data._response.data.investmentDetails;
          let investmentData = [];
          investment && investment.map(data => {
         return  (  investmentData.push({ value: data.typeOfInvestmentId, label: data.investmentName }))
          })
          setInvestmentType(investmentData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
    })
  }

  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);

  // const fixTimezoneOffset = (date) => {
  //   if (!date) return "";
  //   return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  // }

  // const handleShow = () => setShow(true);

  // const handleCloseModalPopUp = () => {
  //   // setShowUnlisted(false);
  //   // setTenure('');
  //   // setFormErr('');
  // }

  const handelRowAdd = async (newData, resolve, reject, a) => {
    let companyData = newData.typeOfInvestmentId;
    let unitType = newData.investmentUnit;
    let errorList = []
    if (familyB.value === undefined || familyB.value === 0 || !familyB) {
      errorList.push("Please Select Family Field")
    }
    if (!newData.typeOfInvestmentId) {
      errorList.push("Please Enter Investment Type Field")
    }
    if (!newData.investmentUnit) {
      errorList.push("Please Enter Unit Field");
    }
    if (!newData.dateOfInvestment) {
      errorList.push("Please Enter Date Of Investment Field")
    }
    if (!newData.quantity) {
      errorList.push("Please Enter Quantity Field");
    }
    if (!newData.rate) {
      errorList.push("Please Enter Rate Field");
    }
    // if (!newData.amountOfInvestment) {
    //   errorList.push("Please Enter Investment Amount Field");
    // }
    if (!newData.amountOfInvestment && !newData.currentMarketPrice) {
      errorList.push("Please Enter Current Market Price Field");
    }

    if (errorList.length < 1) {

      newData.typeOfInvestmentId = newData.typeOfInvestmentId.label;
      newData.investmentName = companyData;

      newData.investmentUnit = newData.investmentUnit.label;
      newData.unitId = unitType;
      newData.currentMarketPrice = newData.currentMarketPrice === undefined ? newData.amountOfInvestment : newData.currentMarketPrice;
      let newUserdata = [...allEntry1];
      newData.instrumentTypeId = 44;
      newUserdata.push(newData);
      var result = newUserdata.map(function (o) {
        if (o.bullionId === undefined) {
          o.transactType = "Buy";
          o.stockValuationId = 4;
        }
        return o;
      })
      setAllEntry1(result);
      resolve();
      setErrorMessages([]);
      setIserror(false);
    }
    else {
      setErrorMessages(errorList);
      setIserror(true)
      reject()
    }
    setLoading(false)
  }
  const handelRowUpdate = (newData, oldData, resolve, reject) => {
    let cpy = newData.investmentName.label;
    let companyData = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.investmentName : newData.typeOfInvestmentId;

    let unt = newData.unitId.label;
    let unitData = unt === newData.investmentUnit && newData.investmentUnit.label === undefined ? newData.unitId : newData.investmentUnit;
    // let companyData = newData.typeOfInvestmentId;
    // let unitType = newData.investmentUnit;
    let errorList = [];
    if (familyB.value === undefined || familyB.value === 0 || !familyB) {
      errorList.push("Please Select Family Field")
    }
    if (!newData.typeOfInvestmentId) {
      errorList.push("Please Enter Investment Type Field")
    }
    if (!newData.investmentUnit) {
      errorList.push("Please Enter Unit Field");
    }
    if (!newData.dateOfInvestment) {
      errorList.push("Please Enter Date Of Investment Field")
    }
    if (!newData.quantity) {
      errorList.push("Please Enter Quantity Field");
    }
    if (!newData.rate) {
      errorList.push("Please Enter Rate Field");
    }
    if (!newData.amountOfInvestment) {
      errorList.push("Please Enter Investment Amount Field");
    }
    if (!newData.amountOfInvestment && !newData.currentMarketPrice) {
      errorList.push("Please Enter Current Market Price Field");
    }
    if (errorList.length < 1) {
      const updateBullion = [...allEntry1]
      const index = oldData.tableData.id;

      newData.typeOfInvestmentId = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.typeOfInvestmentId : newData.typeOfInvestmentId.label !== undefined ? newData.typeOfInvestmentId.label : newData.typeOfInvestmentId.label;
      newData.investmentName = companyData;

      newData.investmentUnit = unt === newData.investmentUnit && newData.investmentUnit.label === undefined ? newData.investmentUnit : newData.investmentUnit.label !== undefined ? newData.investmentUnit.label : newData.investmentUnit.label;
      newData.unitId = unitData;
      newData.currentMarketPrice = isNaN(newData.currentMarketPrice) || newData.currentMarketPrice === undefined ? newData.amountOfInvestment : newData.currentMarketPrice;
      newData.instrumentTypeId = 44;
      updateBullion[index] = newData;
      setAllEntry1([...updateBullion]);
      resolve();
      setErrorMessages([]);
      setIserror(false);
    }
    else {
      setErrorMessages(errorList);
      setIserror(true)
      reject()
    }
  }
  const handelRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = [...allEntry1]
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    await setAllEntry1([...dataDelete]);
    resolve()
    // props.parentCallback(true);
    // props.lock(false, 1)
  }
  const handelSubmitTable = async () => {
    setLoading(true);
    if (allEntry1.length >= 1) {
      let resultDataBuy = allEntry1
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultOpenBal = deepCloneBuy.map(function (o) {
        o.typeOfInvestmentId = o.investmentName.value;
        o.investmentName = o.investmentName.label;
        o.investmentUnit = o.unitId.label;
        o.instrumentId = 9;
        o.instrumentTypeId = 44;
        o.date = o.dateOfInvestment;
        o.subAccountId = props.familyB.value;
        o.type = "Normal";
        return o;
      })
      if (allEntry1.length >= 1) {
        await AddBulionsOpeningBal(resultOpenBal).then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              Swal.fire("Bullion Details Added Successfully!", "", 'success');
              setAllEntry1([]);
              setErrorMessages([])
              setIserror(false)
              props.parentCallback(true);
              // props.lock(false, 1)
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong !", "", 'error');
            setLoading(false)
          }
        }).catch(e => {
          Swal.fire("Something went wrong !", "", 'error');
          setError(e.message)
          setLoading(false)
        })
      }
      else {
        Swal.fire("Please fill required fields!", "", 'info');
        setLoading(false)
      }
    }
    else {
      Swal.fire("Please fill required information!", "", 'info');
      setLoading(false)
    }
  }
  const handelResetTable = () => {
    setAllEntry1([]);
    setErrorMessages([]);
    setIserror(false)
    props.parentCallback(true);
    // props.lock(false, 1);
    setLoading(false);
  }

  const disbtn = () => {
    // let info = newFilterTypeFD === 1 ? data : null;
    return familyB
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const handleChangeInvestType = (selected, props) => {
    props.rowData.investmentUnit = undefined;
    props.rowData.count = 1;
    props.onChange(selected)


    let unitOptions = selected.value === 1 || selected.value === 2 || selected.value === 3 ? bullionUnit
      : selected.value === 4 ? bullionUnit2 : bullionUnit3

    setUnitOptions(unitOptions)

  }

  const handleChangeUnitType = (selected, props) => {
    props.onChange(selected)

  }

  const columnsB = [
    {
      title: 'Type of Investment', field: 'typeOfInvestmentId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.investmentName.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.investmentName
                }
              }
              else {
                ddValue = props.rowData.investmentName
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => handleChangeInvestType(selected, props)}
            options={investmentType}
            name="typeOfInvestmentId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          // isDisabled={mutualFHeaderId != 0 ? true : false}
          />
        )
      },
    },
    {
      title: "Unit", field: 'investmentUnit', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        let investmentValue = props.rowData.typeOfInvestmentId ? props.rowData.typeOfInvestmentId.value : 0;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.value !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.unitId.label) {
                    ddValue = props.value
                  }
                  else {
                    ddValue = props.rowData.unitId
                  }
                }
                else {
                  ddValue = props.rowData.unitId
                }
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => handleChangeUnitType(selected, props)}
            options={unitOptions}
            name="investmentUnit"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          // isDisabled={mutualFHeaderId != 0 ? true : false}
          />
        )
      }
    },
    {
      title: 'Date of Investment', field: 'dateOfInvestment', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.dateOfInvestment).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Investment Desc.', field: 'investmentDescription', width: "20%", type: 'text',

      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Quantity', field: 'quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Amount Of Investment', field: 'amountOfInvestment', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let amt = !isNaN(props.rowData.amountOfInvestment) ? props.rowData.amountOfInvestment :0;
        props.rowData.amountOfInvestment = amt;
        props.value = amt;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Amount Of Investment'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.amountOfInvestment?rowData.amountOfInvestment: 0)}
        </span>
      ),
    },
    {
      title: 'Rate', field: 'rate', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let rate = 0;
        rate = ((props.rowData.amountOfInvestment ?props.rowData.amountOfInvestment : 0) / props.rowData.quantity).toFixed(2);
        props.value = rate;
        props.rowData.rate = rate;
        return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Rate'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} disabled={true} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.rate)}
        </span>
      ),
    },
    {
      title: 'Current Market Price', field: 'currentMarketPrice', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        // let currentMarketPrice = 0;
        // currentMarketPrice = props.rowData.amountOfInvestment;
        // props.value = currentMarketPrice;
        // props.rowData.currentMarketPrice = currentMarketPrice;
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Current Market Price'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {rowData.currentMarketPrice ? NumberSeperator(rowData.currentMarketPrice) : rowData.currentMarketPrice}
        </span>
      ),
    },
  ]

  return (
    <div>
      <Container fluid className="openingBalanceStockBullionTbl">
        <Row>
          <MaterialTable
            title="Bullion"
            data={allEntry1}
            isLoading={loading}
            columns={columnsB}
            options={{
              actionsColumnIndex: -1,
              search: false,
              addRowPosition: "first",
              paging: false
            }}
            minRows={0}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  handelRowAdd(newData, resolve, reject)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  handelRowUpdate(newData, oldData, resolve, reject)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  handelRowDelete(oldData, resolve)
                  // props.parentCallback(true);
                }),
            }}
          />

        </Row>
        <div className="mt-5 d-flex justify-content-center">
          <Stack spacing={4} direction="row" >
            <Button variant="contained" color="primary" className="btn btn-primary" id ="submitButton" onClick={handelSubmitTable} disabled={!disbtn()} style={{width:"5rem"}}>
              Save
            </Button>
            <Button variant="contained" color="primary" className="btn btn-secondary" onClick={handelResetTable}>
              Cancel
            </Button>
          </Stack>
        </div>
        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
      </Container>
    </div>
  )
}

export default BullionOpnBal