import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import "./style.css"
import Auth from "../../modules/Auth";
import {  ListEntity } from "./FamilyManagerapi"
import { Link } from "react-router-dom";


const MyAcoount = (props) => {

    const { showAccount, hideAccount } = props;

    const [count , setCount] = useState(0)

    const userName = Auth.getUserName();
    const emailId = Auth.getEmail();
    const userID = Auth.getsubcriberId();
    const userRole = Auth.getUserRoleID();                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

    const handleCloseAccount = () => {
        hideAccount();
    }

    useEffect(() => {
        if (userID && showAccount) {
            GetListOfEnity()
        }

    }, [userID, showAccount])

    const GetListOfEnity = () => {
        // setLoading(true)
        ListEntity()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                      let result =res.data._response.data.subaccountDetails
                      let valueResult = result.length
                        setCount(valueResult)
                        // setLoading(false)
                        // setsubAccountId(res.data._response.data.subaccountDetails.)
                    }
                } else {
                    console.log("error");
                    // setLoading(false)

                }

                // console.log(res);
            }).catch((err) => {
                console.log(err)
                // setLoading(false)

            })
    }
    const changePassword = ()=>{
        handleCloseAccount()
    }
    const updateProfile = () => {
        handleCloseAccount()
    }
    return (
        <div>
            <Modal show={showAccount} onHide={handleCloseAccount} size="l" backdrop="static">
                <Modal.Header className='headerBlue' closeButton>
                    <Modal.Title className="mx-auto"><h5>My Account</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3">
                            <Row className="form-set">

                                <Form.Label className='col-md-6'>Email:</Form.Label>

                                <Form.Text className='col-md-6 accountText'>{emailId}</Form.Text>
                            </Row>

                            <Row className="form-set">

                                <Form.Label className='col-md-6'>Display Name:</Form.Label>

                                <Form.Text className='col-md-6 accountText'>
                                    {userName}
                                </Form.Text>

                            </Row>

                            {
                                userRole === 1 || userRole === 3 ? null :
                                <Row className="form-set">
                                    <Form.Label className='col-md-6' >Current Portfolio Count:</Form.Label>

                                    <Form.Text className='col-md-6 accountText'>{count} </Form.Text>
                                </Row>
                            }

                             <Row className="form-set">
                                <Form.Label className='col-md-6'>Change Password:</Form.Label>
                                <Col md="3" className="text-center">
                                <Link to="/ChangePassword"> <Button variant="primary" className="mx-2 w100" onClick={changePassword}>Click Here</Button></Link></Col>
                             </Row>
                             <Row className="form-set">
                                <Form.Label className='col-md-6'>Update Profile:</Form.Label>
                                <Col md="3" className="text-center">
                                    <Link to="/profile"> <Button variant="primary" className="mx-2 w100" onClick={updateProfile}>Click Here</Button></Link></Col>
                            </Row>
                           {/* <Row className="form-set">
                                <Form.Label className='col-md-6'>Enable 2-Factor Authentication:</Form.Label>

                                <Col md="3" className="text-center">
                                    <Button variant="primary" className="mx-2 w100">Click Here</Button></Col>
                            </Row>
                            <Row className="form-set">
                                <Form.Label className='col-md-6'>Disconnect Mobile Device:</Form.Label>

                                <Col md="3" className="text-center">
                                    <Button variant="primary" className="mx-2 w100">Click Here</Button></Col>
                            </Row>
                            <Row className="form-set">
                                <Form.Label className='col-md-6'>Delete Account:</Form.Label>

                                <Col md="3" className="text-center">
                                    <Button variant="primary" className="mx-2 w100">Click Here</Button></Col>
                            </Row> */}

                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default MyAcoount