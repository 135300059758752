/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Stack, Table } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Auth from "../../modules/Auth";
import { AddNewEntity, EditNewEntity, ListEntity, GetEntityByID, DeleteEntity } from "./FamilyManagerapi"
import Swal from 'sweetalert2';
import Select from "react-select"
import Loader from '../loadingAnimation/index'
import { activeInactiveList } from '../../modules/helpers/constant';

const ManageFamily = (props) => {

    const { showFamily, hideFamily } = props;

    const [memberName, setMemberName] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [AllEntities, setAllEntities] = useState([]);
    const [subAccountId, setsubAccountId] = useState(0);
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(activeInactiveList);
    const [selTypeFD, setSelTypeFD] = useState([{ label: "Active", value: 1 }]);

    const userName = Auth.getUserName();
    const userID = Auth.getsubcriberId();

    useEffect(() => {
        if (userID && showFamily) {
            GetListOfEnity()
        }

    }, [userID, showFamily])

    const GetEntityData = (e, a) => {
        // console.log(a);
        setShowForm(true);
        GetEntityByID(a.subAccountId).then((res) => {
            if (res.status === 200) {
                let resultData = res.data._response.data.subaccountDetails;

                setSelTypeFD({ label: resultData.active === 1 ? "Active" : "Inactive", value: resultData.active === 1 ? 1 : 0 })
                setMemberName(resultData.subAccountName)
                setsubAccountId(a.subAccountId)
            }
        }).catch((err) => {
            console.log(err);
        })

    }

    const DeleteFamilyMember = (e, a) => {
        setLoading(true)
        DeleteEntity(a.subAccountId).then((res) => {
            
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    Swal.fire('Entity Deleted Successfully', '', 'success');
                }
                if (res.data._response.status === 2) {
                    Swal.fire('Please Delete All Entries Related To This Entity And Try Again!', '', 'error');
                }
                GetListOfEnity();
            }
            else {
                setLoading(false);
                Swal.fire('Error in delete entity', '', 'error');
            }
        })
            .catch((e) => {
                setLoading(false);
                console.log(e, "error in delete entity")
            })
    }

    const GetListOfEnity = () => {
        setLoading(true)
        ListEntity()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        setAllEntities(res.data._response.data.subaccountDetails);
                        // setsubAccountId(res.data._response.data.subaccountDetails.)
                    }
                } else {
                    console.log("error");
                    setLoading(false)

                }

                // console.log(res);
            }).catch((err) => {
                console.log(err)
                setLoading(false)

            })
    }

    const handleCloseFamily = () => {
        hideFamily();
        setShowForm(false);
        handleReset()
        setsubAccountId([])
        refreshEntity();
    }
    const handleFamily = (selected) => {

        setSelTypeFD(selected);
    }
    const handleReset = () => {
        setMemberName([]);
        setShowForm(false)
        setsubAccountId([])
        setSelTypeFD([]);
    }

    const refreshEntity = () => {
        window.location.reload();
    }

    const handleSubmit = () => {

        setLoading(true)

        let entityData = {
            subcriberId: userID,
            subAccountName: memberName,
            active: selTypeFD.value,
            subAccountType: 1,
        }


        AddNewEntity(entityData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        Swal.fire("Success!", "Entity Added Successfully", "success");
                        setShowForm(false);
                        handleReset();
                        setLoading(false)
                        GetListOfEnity();
                    }else{
                        Swal.fire(res.data._response.message,'', 'warning');
                        setLoading(false)
                    }
                } else {
                    console.log("error");
                    setLoading(false)

                }
            }).catch((err) => {
                console.log(err, "error in Submitting Entity");
                setLoading(false)

            })

    }

    const handleUpdate = () => {

        let entityData = {
            subAccountId: subAccountId,
            subcriberId: userID,
            subAccountName: memberName,
            active: selTypeFD.value,
            subAccountType: 1,
        }


        EditNewEntity(entityData)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        Swal.fire("Success!", "Entity Updated Successfully", "success");
                        setShowForm(false);
                        handleReset();
                        GetListOfEnity();
                    }
                } else {
                    console.log("error");
                }
            }).catch((err) => {
                console.log(err, "error in Submitting Entity");
            })

    }

    const disableButton = () => {
        return memberName;
    }

    const handleMember = (e) => {
        // const { name, value } = e.target;
        // setMemberName({ ...memberName, [name]: value })
        setMemberName(e.target.value.trimStart().replace(/[^a-zA-Z" "]/ig, ''));
    }

    const openAddEntity = (e) => {
        // e.preventDefault();
        return (
            <div className="mt-4 p-6 d-flex justify-content-center">
                <Form >
                    <Row className="align-items-center">
                        <Stack direction="vertical" gap={2}>
                            {/* <Col xs="auto"> */}
                            <Form.Label htmlFor="inlineFormInput">
                                Name
                            </Form.Label>
                            <Form.Control
                                className="mb-2"
                                placeholder="Enter Entity Name"
                                value={memberName}
                                onChange={handleMember}
                                type="text"
                                autoComplete='off'
                            />
                            <Form.Label>Status</Form.Label>
                            <Select
                                value={selTypeFD}
                                onChange={handleFamily}
                                // options={Family}
                                options={type}
                                name="active"
                                placeholder="Select Status"
                            />
                            {/* </Col> */}
                        </Stack>

                        {/* <Col xs="auto"> */}
                        <Row className="form-set">
                            <Col md="12" className="text-center mt-3">
                                {
                                    subAccountId === 0 ?
                                        <>
                                            <Button type="submit" className="mx-2" id="submitButton" disabled={!disableButton()}
                                                onClick={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                Add
                                            </Button>
                                            <Button type="submit" className="mx-2" id="submitButton"
                                                onClick={() => { setShowForm(false) }}>
                                                Cancle
                                            </Button>
                                        </>

                                        :
                                        <Button type="submit" className="mx-2" id="submitButton" disabled={!disableButton()}
                                            onClick={(e) => { e.preventDefault(); handleUpdate() }}>
                                            update
                                        </Button>
                                }
                            </Col>
                        </Row>
                        {/* </Col> */}

                    </Row>
                </Form>
            </div>
        )

    }



    return (
        <div>
            <Modal show={showFamily} onHide={handleCloseFamily} size="l" backdrop="static">
                <Modal.Header className='headerBlue' closeButton>
                    <Modal.Title className="mx-auto"><h5>Family Manager</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Loader />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3">
                            <Row className="form-set">
                                <div className='d-flex flex-row-reverse'>

                                    <Button variant="light" className="mx-2" size="sm"
                                        onClick={() => { setShowForm(true); setsubAccountId(0); setMemberName([]); setSelTypeFD({ label: "Active", value: 1 }) }}
                                    >
                                        <AddIcon fontSize="small" />
                                        Add Entity
                                    </Button>
                                </div>
                                {
                                    showForm ? openAddEntity() : null
                                }
                            </Row>
                            <Row className="form-set text-center mt-4">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Family</th>
                                            <th>Entity</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            AllEntities.map((a) => (
                                                <tr>
                                                    <td>{userName}</td>
                                                    <td>{a.subAccountName}</td>
                                                    <td>{a.active === 0 ? "Inactive" : "Active"}</td>
                                                    <td>


                                                        <EditIcon fontSize="small" onClick={(e) => {
                                                            GetEntityData(e, a)
                                                        }} />
                                                        &nbsp;&nbsp;&nbsp;
                                                        <DeleteIcon fontSize="small" onClick={(e) => {
                                                            DeleteFamilyMember(e, a)
                                                        }} />
                                                    </td>

                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                </Table>
                            </Row>

                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ManageFamily