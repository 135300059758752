import React from 'react'
import Card from '../CommonComponent/Card';
import { Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { HiArrowCircleRight } from 'react-icons/hi';
function ReportsForm() {
    const navigate = useNavigate();

    return (

        <div className='mb-4'>
            <div className='mb-3 p-1'>
                <h2 className='text-center'>Report's</h2>
            </div>


            <div className='mt-4 pt-3'>
                <Row>
                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'  >
                        <Card title="Brokerwise scrip report" goPage={() => navigate('/Reports', { state: 1 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>
                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Entitywise scrip report" goPage={() => navigate('/Reports', { state: 2 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>
                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Brokerwise/Entitywise scrip report" goPage={() => navigate('/Reports', { state: 3 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>
                </Row>
            </div>

{/* holding */}
            <div className='mb-3 p-1'>
                <h2 className='text-center'>Holding Report's</h2>
            </div>
            <div className='mt-4 pt-3'>
                <Row>
                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Brokerwise scrip holding report" goPage={() => navigate('/HoldingReports', { state: 4})} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>

                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Entitywise scrip holding report" goPage={() => navigate('/HoldingReports', { state: 5 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>

                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Brokerwise/Entitywise scrip holding report" goPage={() => navigate('/HoldingReports', { state: 6 })} link={ReportsForm} icons={<HiArrowCircleRight />}  />
                    </Col>

                
                </Row>
            </div>

            {/* Profitability  */}
            <div className='mb-3 p-1'>
                <h2 className='text-center'>Profitability Report's</h2>
            </div> 
             <div className='mt-4 pt-3'>
                <Row>
                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Profit loss base report" goPage={() => navigate('/StockProfitReport', { state: 7 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>

                    <Col className='col-xl-4 col-sm-6 mt-2 col-md-6 col-lg-4'>
                        <Card title="Profit loss final report" goPage={() => navigate('/StockProfitReport', { state: 8 })} link={ReportsForm} icons={<HiArrowCircleRight />} />
                    </Col>
                </Row>
                </div>
        </div>
    )
}

export default ReportsForm