/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import moment from "moment";

const PreviewMuFund = (props) => {
    const { showPreview, hidePreview, MFTransaction, previewTransaction } = props;
    // console.log("previewTransaction",previewTransaction);
    const [instTYID, setinstTYID] = useState(null); 
    const [date, setDate] = useState("");

    const [units, setUnits] = useState("");
    const [sellUnit, setSellUnit] = useState("");
    const [nav, setNav] = useState("");
    const [invetmentAmt, setInvestmentAmt] = useState("");
    const [folioNumber, setFolioNumber] = useState("");
    const [lockinperiod, setLockInPeriod] = useState("");
    const [estperiod, setEstPeriod] = useState("");
    const [sDate, setSDate] = useState("");
    const [SIPAmount, setSIPAmount] = useState("");

    const [noInstallments, setNoInstallments] = useState("");
    const [mDate, setMDate] = useState("");
    const [recommended, setRecommended] = useState("");
    const [mproceeds, setMProceeds] = useState("");
    const [recTDS, setRecTDS] = useState("");
    const [ActualRec, setActualRec] = useState("");

    const [transactopnType, setTransactopnType] = useState("")
    //ulip
    const [insuranceCompany, setInsuranceCompany] = useState("")
    const [insuredName, setInsuredName] = useState("")
    const [policyNumber, setPolicyNumber] = useState("");
    const [sumAssured, setSumAssured] = useState("")
    const [currentMarket, setCurrentMarket] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [nominees, setNominees] = useState("");
    const [bonusAccrued, setBonusAccrued] = useState("")

    const [goal, setGoal] = useState("")
    const [tenure, setTenure] = useState("")
    const [mValue, setMValue] = useState("")


    // useEffect(() => {
    //     if (mutualFHeaderId != 0) {
    //         debugger
    //         if (MFTransaction != undefined) {
    //             setNarration(MFTransaction.narration)
    //             setDate(MFTransaction.date);

    //             MFTransaction.mutualFundDetails && MFTransaction.mutualFundDetails.map((data) => {
    //                 setinstTYID(data.instrumentTypeId);
    //                 setBroker(data.broker.brokerName)
    //                 setSchemeName(data.mutualFundCompany.label)
    //                 setUnits(data.units);
    //                 setNav(data.nav)
    //                 setInvestmentAmt(data.closingBalance);
    //                 setFolioNumber(data.certificateFolioNo);
    //                 setLockInPeriod(data.lockInPeriod)
    //                 setEstPeriod(data.estHoldingPeriod)
    //                 setSDate(data.sipDate)
    //                 setSIPAmount(data.sipAmt)
    //                 setSIPFrequency(data.frequency.frequencyName)
    //                 setNoInstallments(data.numberOfInstalments)
    //                 setMDate(data.maturityDate)
    //                 setRecommended(data.recommendedBy)
    //                 setMProceeds(data.actualReceivable)
    //                 setRecTDS(data.tds)
    //                 setActualRec(data.receivable)
    //                 setStockValType(data.stockValuation.stockValuationName)
    //                 setTransactopnType(data.transactionType)
    //                 setInsuranceCompany(data.insuranceCompany)
    //                 setInsuredName(data.nameOfInsured)
    //                 setPolicyNumber(data.policyNumber)
    //                 setSumAssured(data.sumAssured)
    //                 setCurrentMarket(data.currentMarketValue)
    //                 setLastDate(data.dateOfLastPremium)
    //                 setNominees(data.nominees)
    //                 setBonusAccrued(data.bonusAccrued)
    //                 setPremiumFre(data.frequency.frequencyName)
    //                 setGoal(data.goal)
    //                 setTenure(data.tenure)
    //                 setMValue(data.maturityValue)

    //             })
    //         }
    //     }
    // }, [MFTransaction]);

    useEffect(() => {
        // if (mutualFHeaderId != 0) {
        // setNarration(previewTransaction.narration)
        setDate(previewTransaction.dateOfInvestment);
        setinstTYID(previewTransaction.instrumentTypeId);
        // setBroker(previewTransaction.broker.brokerName)
        // setSchemeName(previewTransaction.mutualFundCompany.name)
        if(previewTransaction.transactionType === "Buy") {
            setUnits(previewTransaction.totalUnits);
        } else if (previewTransaction.transactionType === "Sell") {
            setUnits(previewTransaction.units);
        }
        setNav(previewTransaction.nav)
        setInvestmentAmt(previewTransaction.totalInvestmentAmt);
        setFolioNumber(previewTransaction.certificateFolioNo);
        setLockInPeriod(previewTransaction.lockInPeriod)
        setEstPeriod(previewTransaction.estHoldingPeriod)
        setSDate(previewTransaction.sipDate)
        setSIPAmount(previewTransaction.sipAmt)
        // setSIPFrequency(previewTransaction.frequency.frequencyName)
        setNoInstallments(previewTransaction.numberOfInstalments)
        setMDate(previewTransaction.maturityDate)
        setRecommended(previewTransaction.recommendedBy)
        setMProceeds(previewTransaction.actualReceivable)
        setRecTDS(previewTransaction.tds)
        setActualRec(previewTransaction.receivable)
        // setStockValType(previewTransaction.stockValuation.stockValuationName)
        setTransactopnType(previewTransaction.transactionType)
        setInsuranceCompany(previewTransaction.insuranceCompany)
        setInsuredName(previewTransaction.nameOfInsured)
        setPolicyNumber(previewTransaction.policyNumber)
        setSumAssured(previewTransaction.sumAssured)
        setCurrentMarket(previewTransaction.currentMarketValue)
        setLastDate(previewTransaction.dateOfLastPremium)
        setNominees(previewTransaction.nominees)
        setBonusAccrued(previewTransaction.bonusAccrued)
        // setPremiumFre(previewTransaction.frequency.frequencyName)
        setGoal(previewTransaction.goal)
        setTenure(previewTransaction.tenure)
        setMValue(previewTransaction.maturityValue)
        // }
    }, [MFTransaction])


    const handleClosePreview = () => {
        hidePreview()
    }
    return (
        <div tabIndex={3}>
            <Modal show={showPreview} onHide={handleClosePreview} size="md" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto">{previewTransaction.transactionType === "Buy" ? <h5>BUY</h5> : <h5>Sell</h5>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* SIP /Lumpsum */}
                    {instTYID === 41 || instTYID === 42 || instTYID === 43 ?
                        <>
                            <div className="transactionData "><div className="transactionDataTitle">Date: </div>
                                <div className="transactionDataValue">{moment(date).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{previewTransaction.broker.brokerName === null ? "-" : previewTransaction.broker.brokerName}</div></div>

                        </>
                        : null}
                    {/* SIP /lampsum Buy sell*/}
                    {instTYID === 41 || instTYID === 42 || instTYID === 43 ?
                        <>
                            <div className="transactionData "><div className="transactionDataTitle">Scheme Name: </div>
                                <div className="transactionDataValue">{previewTransaction.mutualFundCompany.mutualFundCompanyName === null ? "-" : previewTransaction.mutualFundCompany.mutualFundCompanyName}</div></div>

                            {previewTransaction.transactionType !== "Sell" ? <div className="transactionData odd"><div className="transactionDataTitle">Units : </div>
                                <div className="transactionDataValue">{units === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(units)}</div></div> : null}
                            {instTYID === 43 ? null :
                                <>
                                    <div className="transactionData "><div className="transactionDataTitle">Nav/ Purchased price: </div>
                                        <div className="transactionDataValue">{nav === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(nav)}</div></div></>}
                        </>
                        : null}
                    {/* SIP /lampsum Buy */}
                    {previewTransaction.transactionType === "Buy" ?
                        <>
                            {instTYID === 43 ? null :
                                <div className="transactionData odd"><div className="transactionDataTitle">Amount Invested: </div>
                                    <div className="transactionDataValue">{invetmentAmt === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(invetmentAmt)}</div></div>}
                            {instTYID === 43 ? null :
                                <div className="transactionData "><div className="transactionDataTitle">Folio Number: </div>
                                    <div className="transactionDataValue">{folioNumber === null || folioNumber === " " ? "-" : folioNumber}</div></div>}

                            <div className="transactionData odd"><div className="transactionDataTitle">Lock in period : </div>
                                <div className="transactionDataValue">{lockinperiod === null ? "-" : lockinperiod}</div></div>
                        </>
                        : null}
                    {/* lampsum */}
                    {instTYID === 42 ?
                        <div className="transactionData "><div className="transactionDataTitle">Estimated Holding Period : </div>
                            <div className="transactionDataValue">{estperiod === null ? "-" : estperiod}</div></div> : null}
                    {/* SIP buy */}
                    {previewTransaction.instrumentTypeId === 41 && previewTransaction.transactionType === "Buy" ?
                        <>
                            <div className="transactionData "><div className="transactionDataTitle">SIP Date: </div>
                                <div className="transactionDataValue">{sDate ? moment(sDate).format("DD/MM/YYYY") : '-'}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">SIP Amount: </div>
                                <div className="transactionDataValue">{SIPAmount === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(SIPAmount)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">SIP Frequency: </div>
                                <div className="transactionDataValue">{previewTransaction.frequency.frequencyName === null ? "-" : previewTransaction.frequency.frequencyName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">No. Of Installments : </div>
                                <div className="transactionDataValue">{noInstallments === null ? "-" : new Intl.NumberFormat('en-IN').format(noInstallments)}</div></div>

                            <div className="transactionData "><div className="transactionDataTitle">Maturity Date: </div>
                                <div className="transactionDataValue">{mDate ? moment(mDate).format("DD/MM/YYYY") : "-"}</div></div>
                            {instTYID === 43 ? null :
                                <div className="transactionData odd"><div className="transactionDataTitle">Recommended By: </div>
                                    <div className="transactionDataValue">{recommended === null ? "-" : recommended}</div></div>}
                        </> : null}

                    {/* ULIP buy */}
                    {previewTransaction.instrumentTypeId === 43 && previewTransaction.transactionType === "Buy" ?
                        <>
                            <div className="transactionData "><div className="transactionDataTitle">Insurance Company: </div>
                                <div className="transactionDataValue">{insuranceCompany === null ? "-" : insuranceCompany}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Name of Insured: </div>
                                <div className="transactionDataValue">{insuredName === null ? "-" : insuredName}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Policy Number: </div>
                                <div className="transactionDataValue">{policyNumber === null ? "-" : policyNumber}</div></div>

                            <div className="transactionData odd"><div className="transactionDataTitle">Sum Assured: </div>
                                <div className="transactionDataValue">{sumAssured === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(sumAssured)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Premium Amount: </div>
                                <div className="transactionDataValue">{!invetmentAmt ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(invetmentAmt)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">current market value : </div>
                                <div className="transactionDataValue">{!currentMarket ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMarket)}</div></div>

                            <div className="transactionData "><div className="transactionDataTitle">Date of Last premium: </div>
                                <div className="transactionDataValue">{lastDate ? moment(lastDate).format("DD/MM/YYYY") : "-"}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Nominees : </div>
                                <div className="transactionDataValue">{!nominees ? "-" : nominees}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Bonus Accured : </div>
                                <div className="transactionDataValue">{!bonusAccrued ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMarket)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Premium Frequency : </div>
                                <div className="transactionDataValue">{previewTransaction.frequency.frequencyName === null ? "-" : previewTransaction.frequency.frequencyName}</div>
                            </div>
                            <div className="transactionData "><div className="transactionDataTitle">Goal : </div>
                                <div className="transactionDataValue">{!goal ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(goal)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Maturity Date: </div>
                                <div className="transactionDataValue">{mDate ? moment(mDate).format("DD/MM/YYYY") : '-'}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Maturity Value : </div>
                                <div className="transactionDataValue">{!mValue ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(mValue)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Tenure : </div>
                                <div className="transactionDataValue">{!tenure ? "-" : tenure}</div></div>
                        </> : null}


                    {/* SIP/Lampsum sell */}
                    {previewTransaction.transactionType === "Sell" ?
                        <>
                            <div className="transactionData odd"><div className="transactionDataTitle">Units : </div>
                                <div className="transactionDataValue">{units == null ? units : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(units)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Maturity Proceeds Recieved: </div>
                                <div className="transactionDataValue">{mproceeds === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(mproceeds)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Recievable TDS: </div>
                                <div className="transactionDataValue">{recTDS === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(recTDS)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Actual Recievable: </div>
                                <div className="transactionDataValue">{ActualRec === null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(ActualRec)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Stock Valuation Type: </div>
                                <div className="transactionDataValue">{previewTransaction.stockValuation.stockValuationName === null ? "-" : previewTransaction.stockValuation.stockValuationName}</div></div>
                            {previewTransaction.instrumentTypeId === 43 ?
                                <div className="transactionData "><div className="transactionDataTitle">Policy Number: </div>
                                    <div className="transactionDataValue">{policyNumber === null ? "-" : policyNumber}</div></div>
                                :
                                <div className="transactionData "><div className="transactionDataTitle">Folio Number: </div>
                                    <div className="transactionDataValue">{folioNumber === null || folioNumber === " " ? "-" : folioNumber}</div></div>
                            }
                        </>
                        : null}
                    {instTYID === 41 || instTYID === 42 || instTYID === 43 ?
                        <div className="transactionData "><div className="transactionDataTitle">Narration: </div>
                            <div className="transactionDataValue">{previewTransaction.mutualFundHeader.narration === null || previewTransaction.mutualFundHeader.narration === '' ? "-" : previewTransaction.mutualFundHeader.narration}</div></div>
                        : null}

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PreviewMuFund