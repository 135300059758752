/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useNavigate , Link } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loader from '../loadingAnimation/index';
import Container from '@mui/material/Container';
import Swal from "sweetalert2"
import axios from 'axios';
import {
    FormControl,
} from "@material-ui/core";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from "../../assets/logo.png";
import {

    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
} from '@mui/material';


const theme = createTheme();

export default function TrialUserSign() {
    const navigate = useNavigate();

    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);

    const [username, setUserName] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newCPass, setNewCPass] = useState("");
    const [pincode, setPincode] = useState("");

    const [password, setPassword] = useState(false);
    const [cpassword, setCPassword] = useState(false);

    //handlechange
    const handleusername = (e) => {
        setUserName(e.target.value);
        setFormErr({ ...formErr, username: '' })
    }
    const handlephone = (e) => {
        setPhoneno(e.target.value);
        setFormErr({ ...formErr, phoneno: '' })
    }
    const handleemail = (e) => {
        setNewEmail(e.target.value)
        setFormErr({ ...formErr, newEmail: '' })
    }
    const handlepassword = (e) => {
        setNewPass(e.target.value)
        setFormErr({ ...formErr, newPass: '' })
    }
    const handlecpassword = (e) => {
        setNewCPass(e.target.value)
        setFormErr({ ...formErr, newCPass: '' })
    }
    const handlepincode = (e) => {
        setPincode(e.target.value)
        setFormErr({ ...formErr, pincode: '' })
    }

    const validation = () => {
        const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
        const formErr = {};
        let isValid = true;
        if (username === "") {
            isValid = false;
            formErr["username"] = "Please enter username";
        }
        if (phoneno === "" || phoneno === null) {
            formErr["phoneno"] = "Please enter phone";
            isValid = false;
        } else if (phoneregex.test(phoneno) === false) {
            formErr["phoneno"] = "Please enter valid Phone";
            isValid = false;
        }
        if (newEmail === "" || newEmail === null) {
            formErr["newEmail"] = "Please enter email address";
            isValid = false;
        }
        else if (emailregex.test(newEmail) === false) {
            formErr["newEmail"] = "Please enter valid email";
            isValid = false;
        }
        if (newPass === "" || newPass === null) {
            formErr["newPass"] = "Please enter password";
            isValid = false;
        } else if (passregex.test(newPass) === false) {
            formErr["newPass"] = "Please enter valid password";
            isValid = false;
        }
        if (newCPass === "" || newCPass === null) {
            formErr["newCPass"] = "Please enter confirm password";
            isValid = false;
        }
        if(newCPass){
        if (newPass != newCPass) {
            isValid = false;
            formErr["newCPass"] = "The Password And Confirm Password do not match";
        }
       }
        if (pincode === "" || pincode === null) {
            formErr["pincode"] = "Please enter pincode";
            isValid = false;
        } else if (pincoderegex.test(pincode) === false) {
            formErr["pincode"] = "Please enter valid pincode";
            isValid = false;
        }

        setFormErr(formErr);
        return isValid;

    }

    const handleReset = () => {
        setUserName("");
        setPhoneno("");
        setNewEmail("");
        setNewPass("");
        setNewCPass("");
        setPincode("");
    }
    const handleSubmit = () => {
        const isValid = validation();
        if (isValid === true) {
            let usersign = {
                roleId: 2,
                acceptTerms: true,
                subcriberName: username,
                subcriberPh1: phoneno,
                subcriberEmail: newEmail,
                subcriberPassword: newPass,
                confirmPassword: newCPass,
                pinCode: Number(pincode),
            }
            let headers = {
                headers: {
                    'Content-Type': "application/json"
                }
            }
            setLoading(true);
            axios.post(`http://5.189.157.40:10025/api/Subcriber/register`, usersign, headers)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            Swal.fire(res.data._response.message, "Please check your email for verifying email before login", "success")
                            handleReset()
                            navigate("/Dashboard")
                        } else {
                            Swal.fire(res.data._response.message, "", "info");
                            setLoading(false);
                        }
                    }
                }).catch((err) => {
                    setLoading(false)
                    if (err.response.data._response.message === "Already Registered") {
                        Swal.fire(err.response.data._response.message, "", "info");
                    } else {
                        Swal.fire("Invalid registration details", "", "info");
                    }

                })

        }

    };
    const handleClickShowPassword = () => {
        setPassword(!password);
    };

    const handleClickShowCPassword = () => {
        setCPassword(!cpassword)

    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs" className='loginstyle mt-4'>
                {/* {loading ? <Loader /> : null} */}
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="sidebar__img">
                        <img src={logo} alt="logo" />
                        {/* <h1>Accompt Fintech</h1> */}
                    </div>
                    <h3 variant="h1" className='mt-1'>Get 7 days Free Trial</h3>
                    <Box component="form" noValidate >
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="username"
                                        label="Name"
                                        name="username"
                                        autoComplete="username"
                                        onChange={handleusername}
                                        value={username}
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.username}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="phone"
                                        label="Phone Number"
                                        name="phone"
                                        autoComplete="phone"
                                        onChange={handlephone}
                                        value={phoneno}
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.phoneno}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete='email'
                                        onChange={handleemail}
                                        value={newEmail}
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.newEmail}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>

                                    <InputLabel htmlFor="outlined-adornment-password" color="primary">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        variant="outlined"
                                        required
                                        onChange={handlepassword}
                                        value={newPass}
                                        name='password'
                                        color='primary'
                                        type={password ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {password ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.newPass}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>

                                    <InputLabel htmlFor="outlined-adornment-password" color="primary">
                                        Confirm Password
                                    </InputLabel>
                                    <OutlinedInput
                                        variant="outlined"
                                        required
                                        onChange={handlecpassword}
                                        value={newCPass}
                                        name='cpassword'
                                        color='primary'
                                        type={cpassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowCPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {cpassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.newCPass}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="pincode"
                                        label="Pincode"
                                        name="pincode"
                                        autoComplete="pincode"
                                        onChange={handlepincode}
                                        value={pincode}
                                    />
                                    {formErr ? (
                                        <div
                                            className='mt-0'
                                            style={{ fontSize: 10, color: "red" }}>
                                            {formErr.pincode}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                             <Grid item xs={12} md={12}>
                            <FormControlLabel
                                sx={{
                                    // my: 3,
                                }}
                                control={<Checkbox value="remember" color="primary" />}
                                label="I have read & accepted the T&C"
                            />
                            </Grid>
                        </Grid>
                        <Box
                            m={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                        <Button
                            className='button-71'
                            size="medium"
                            variant="contained"
                            color="primary"
                            // fullWidth
                            onClick={() => handleSubmit()}
                            sx={{ mt: 3, mb: 2, p: 2, width: 200 }}
                            // sx={{ mt: 3, mb: 2 }}
                        >
                         {loading && <span style={{ marginRight: "8rem", position:'absolute'}}> <span className="spinner-border spinner-border-sm p-2 pt-2 letter-spacing: 0.027em;" role="status" aria-hidden="true" style={{ paddingRight: 0}}></span></span>}
                            Sign In
                        </Button>
                        </Box>

                        <Grid item>
                            <Link className="text-decoration-none" to="/Login">
                                {"Already have an account ?     Sign In  "}
                            </Link>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
       
    );
}