import React from 'react';
import FixedIncomesFolio from '../../components/FIncomeFolio/FIncomeFolio';
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { useSelector } from 'react-redux';

function Fixedincome () {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
        return (
            <div>
                    {sidebarOpen && <Sidebar />}
                <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                        <Navbar />
                    <div>
                        <FixedIncomesFolio/>
                    </div>
                </div>
            </div>
        );
}

export default Fixedincome;


