//import axios from 'axios';
import axios from '../../../modules/helpers/axiosInstance';
import settings from '../../../modules/config/settings';
import Auth from '../../../modules/Auth/index';

const userID = Auth.getsubcriberId();
const dashboardURL = "http://5.189.157.40:10022/api/DashboardManagement"
let BulionLiveUrl = "http://5.189.157.40:10026/api";

// Family Member
const EntityList = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/GetSubaccountList?subId=${userID}`,
    });
}

// Action


// Select Investment Type
const InvestmentTypeList = async () => {
    return axios({
        method: "GET",
        // url: `${settings.API_URL}/UserManagment/GetInstrumentList`,
        url: `http://5.189.157.40:10023/api/UserManagment/GetInstrumentList`
    });
}

// Investment Name
const InvestmentList = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/GetCompanyList`
    })
}
const GetMutualFundCompanies = async () => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetMutualFundCompanies`,
    });
}
const FetchBulionsInvestmentType = async () => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchBullionInvestmentType`
    })
}
const GetFICompanies = async () => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetCompanyList`
    })
}

// Broker
const brokerList = async () => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/UserManagment/GetBrokerList`
    })
}

const recentTransactionAPI = async ( pageNumber, dataPerPage) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/FixedIncomeManagement/RecentTransactionHistory?subscriberid=${userID}&PageNo=${pageNumber}&PageSize=${dataPerPage}`
    })
}

const searchTransaction = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/FixedIncomeManagement/FilterTransactionDetails`,
        data: data
    })
}
const propertySellGet = async (propertyid) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}//PropertyManagement/GetPropertyDetailById?propertyid=${propertyid}`
    })
}

const DashboardFamily = async () => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchMenuInvestmentGridBySubscriberId?s_id=${userID}`
    })
}

const DashboardEntity = async (sub_id) => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchMenuInvestmentGridBySubAccountId?sub_id=${sub_id}`
    })
}

const DashboardCardsData = async () => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchTilesDashboardBySubscriberId?s_id=${userID}`
    })
}

const DashboardInvestments = async () => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchYourInvestmentGridBySubscriberId?s_id=${userID}`
    })
}

const DashboardEntityPie = async (sub_id) => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchPieChartBySubAccountId?sub_id=${sub_id}`
    })
}

const DashboardFamilyPie = async () => {
    return axios({
        method: 'GET',
        url: `${dashboardURL}/FetchPieChartBySubscriberId?s_id=${userID}`
    })
}


export {
    EntityList,
    InvestmentTypeList,
    InvestmentList,
    GetMutualFundCompanies,
    FetchBulionsInvestmentType,
    GetFICompanies,
    brokerList,
    recentTransactionAPI,
    searchTransaction,
    propertySellGet,
    DashboardFamily,
    DashboardEntity,
    DashboardCardsData,
    DashboardInvestments,
    DashboardEntityPie,
    DashboardFamilyPie

}