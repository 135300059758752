export const setUser = (details) => dispatch => {
    dispatch({
      type:SET_DETAILS,
      payload:details
    })
}

export const setIsTwoFAEnabled = (isEnable) => dispatch => {
  dispatch({
    type: SET_ISTWOFACTOR_ENABLED,
    payload:isEnable
  })
}

//used in access table & sidebar
export const setIsAccessApiCall = (value) => dispatch => {
  dispatch({
    type: SET_ISACESSAPICALL,
    payload: value
  })
}

export const SET_DETAILS = 'SET_DETAILS';
export const SET_ISTWOFACTOR_ENABLED = 'SET_ISTWOFACTOR_ENABLED';
export const SET_ISACESSAPICALL = 'SET_ISACESSAPICALL';