import React from "react";
import { Container } from "react-bootstrap";
import LoginAuthentication from "../../components/Login/LoginAuthentication";

const LoginAuth = () =>{
    return(
        <Container className="pt-5">
            <LoginAuthentication/>
        </Container>
    )
}

export default LoginAuth;