import React from "react"
import { Modal} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Auth from "../../modules/Auth";
import trialimage from "../../assets/trialimage.png"
import "./Loginstyle.css";
import moment from 'moment';

const trialDays = Auth.getUserTrialDays();

const LoginPopup = (props) => {
    const { show, onhide ,id} = props
    const navigate = useNavigate();

    const handleSubscribe = ()=>{
      onhide()
      navigate("/plans");
    }
    const handleskip = ()=>{
        // navigate("/Dashboard");
        onhide()
    }
    const trialdate = new Date();
    trialdate.setDate(trialdate.getDate() + (7 - trialDays));
    
    const secondary_pri = {
        backgroundColor:"#318ce7",
        width:"8rem",
        height:"3rem"
     }
    return (
        <>
            <Modal show={show} onHide={onhide} id = {id} size="md" style={{paddingTop:"10rem",paddingLeft:"10rem"}}>
                {/* <div class="parent-wrappers">
                    <span class="close-btn glyphicon glyphicon-remove"></span>
                    <div class="subscribe-wrappers">
                        <h4 style={{color:"red"}}>GO TO SUBSCRIPTION</h4>
                        <h6>Your trial subcription will end in {7- trialDays} days. To continue using the application please subscribe. </h6>
                    
                        <div className="submit-btnn">SUBSCRIBE</div>
                        <div className="submit-btn" onClick={() => { handleskip(); }}>SKIP</div>
                    </div>
                </div> */}

              
                        <div class="modal-dialog  modal-dialog-centered justify-content-center " role="document">
                            <div class="modal-content  border-0 mx-3">
                                <div class="modal-body  p-0">
                                    <div class="card text-center">
                                       
                                      
                                <img src={trialimage} alt="subscribe" class="img-fluid mx-auto d-block" style={{height : "200px" ,width : "200px",alignContent:"center"}}/> 
                                    {trialDays === 7 ? <h3>Your trial has ended </h3> : 
                                    <h3>Your trial will end on <span style={{color:"red"}}> {moment(trialdate).format("DD/MM/YYYY")} </span></h3> } 
                                        <div class="card-body px-sm-5 mb-5">
                                            <p class="text-muted mb-0">To edit and access your account, you'll nedd to extend your trial or subscribe to plan.  <span class="touch"> Get in touch </span></p>
                                            <div class="row justify-content-center mt-4 px-sm-5  ">
                                                <div class="col">
                                             <button type="submit" onClick={() => { handleSubscribe(); }} class="btn btn-secondary-pri btn-block  font-weight-bold text-dark text-uppercase" style={secondary_pri}>
                                                <span class="planbtn">SUBSCRIBE</span></button></div>
                                                <div class="col">
                                            <button type="button" onClick={() => { handleskip(); }} class="btn btn-secondary-pri btn-block  text-dark font-weight-bold text-uppercase" style={secondary_pri}>
                                                <span class="planbtn">SKIP</span></button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  
            </Modal>
        </>
    )

}

export default LoginPopup;