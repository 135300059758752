/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TablePagination } from "@material-ui/core";
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip'
import Edit from '@mui/icons-material/Edit'
import { EntityList } from '../../../pages/Stocks/StocksAPI'
import IPOShare from './IPOShare';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { AlloatedIpoLists, getAlloatedIpoData, deleteAlloatedIpo } from '../../../pages/Stocks/StocksAPI'
import Loader from '../../loadingAnimation/index'
import Swal from 'sweetalert2'
const IPOAlloted = (props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rowDataFilter, setRowDataFilter] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [stockHeaderId, setStockHeaderId] = useState(0);
  const [stockTransaction, setStockTransaction] = useState({});
  const [stockId, setStockId] = useState(null);
  const [ipoopen, setipoopen] = useState(false);

  // const userID = Auth.getUserId();

  useEffect(async () => {
    // console.log(userID,"this is usreId");
    // if (userID) {
    await getEntityList()
    await getTableData();
    // }
  }, []);

  const handleclose = async () => {
    setipoopen(false);
  }
  //refresh
  const tableRefresh = async () => {
    await getTableData();
  }
  //getTableData
  const getTableData = async () => {
    setLoading(true);
    await AlloatedIpoLists().then((res) => {
      if (res.status === 200) {
        let list = res.data._response.data.unListedIPODetails
        // console.log(list,"data");
        setRowData(list);
        setRowDataFilter(list);
        setPage(0);
        setLoading(false);
      }
    })
      .catch((e) => {
        console.log(e, 'error in ipoAlloatment');
        setLoading(false);
      })
  }

  //getEntityList
  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          let entity = res.data._response.data.subaccountDetails;
          setEntityRadio(entity);
          setLoading(false)
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //radio button code
  const handleChangeEntity = async (e) => {
    setPage(0);
    const selected = e.target.value
    await setEntity(e.target.value);
    let sorted = selected === "Family" ? rowDataFilter : rowDataFilter.filter((a) => a.subAccountName === selected)
    setRowData(sorted)
  };

  //pagination code
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const updateIPO = (e, row) => {
    let id = row.id;
    setLoading(true)
    getAlloatedIpoData(id).then((res) => {
      if (res.status === 200) {
        //  setStockHeaderId(row.stockHeaderId);
        if (res.data._response.status === 1) {

          setStockId(row.id);
          const result = res.data._response.data.unListedIPODetails
          //  console.log(result,'thsi is');
          setStockTransaction(result[0]);
          setipoopen(true);
          setLoading(false);
        } else {
          Swal.fire(res.data._response.message, "", "info");
          setLoading(false);
        }

      }
    }).catch(e => {
      setLoading(false);
      console.log(e, 'error in IPOAlloated');
    })
  }
  const deleterow = (e, row) => {
    Swal.fire({
      title: "Are you sure, you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((del) => {
      if (del) {

        let id = row.id
        setLoading(true);
        deleteAlloatedIpo(id).then(async (res) => {
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              let del = rowData.filter(i => i.id !== id);
              setRowData(del);
              setRowDataFilter(del);
              Swal.fire(res.data._response.message, "", "success");
              // await getTableData()
              setLoading(false)
            } else {
              Swal.fire(res.data._response.message, "", "info");
              setLoading(false)
            }
          }
        })
          .catch(e => {
            setLoading(false)
            console.log(e, 'error in Delete ipo row');
          })
      }
    })
  }
  return (
    <>
      <h4 class="text-center py-3">Allotted IPOs</h4>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} className="p-3 pb-0">
        {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
        <FormControl className="mt-4">

          <RadioGroup
            row
            color="primary"
            defaultValue="start"
            aria-label="Entity"
            name="row-radio-buttons-group"
            value={entity}
            onChange={handleChangeEntity}
            style={{ marginTop: "-3rem" }}
          >
            <FormControlLabel
              value="Family"
              control={<Radio />}
              label="Family"
            />
            {entityRadio.map((a) => (
              <FormControlLabel
                value={a.subAccountName}
                control={<Radio />}
                label={a.subAccountName}
                disabled={a.active === 0 ? true : false}
              />
            ))}

          </RadioGroup>
        </FormControl>

        <TableContainer sx={{ maxHeight: 440, minHeight: 100 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* {columns.map((column) => ( */}
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Entity</TableCell>
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Scrip</TableCell>
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Quantity</TableCell>
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Avg Purchase Price</TableCell>
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Amount Invested</TableCell>
                <TableCell style={{ minWidth: 170, textAlign: 'center' }} >Action</TableCell>
                {/* ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.length > 0 ? rowData && rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                      <TableCell align="center">{row.subAccountName}</TableCell>
                      <TableCell align="center">{row.companyName}</TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('en-IN').format(row.quantity)}</TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(row.pricePerShare)}</TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(row.totalCost)}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Edit"><IconButton><Edit fontSize="small" onClick={(e) => updateIPO(e, row)} /></IconButton></Tooltip>
                        <Tooltip title="Delete"><IconButton><Delete fontSize="small" onClick={(e) => deleterow(e, row)} /></IconButton></Tooltip>
                      </TableCell>


                    </TableRow>
                  );
                })
                : <><TableRow align='center'><TableCell style={{ border: 'none' }}></TableCell><TableCell style={{ border: 'none' }}></TableCell><TableCell style={{ border: 'none', height: 100 }}><h6 className='text-center '>No data found</h6></TableCell><TableCell style={{ border: 'none' }}></TableCell><TableCell style={{ border: 'none' }}></TableCell></TableRow></>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50, 100]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // style={{marginTop:"5px" ,paddingBottom:"0px"}}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <IPOShare
        showI={ipoopen}
        onHideI={handleclose}
        stockHeaderId={stockHeaderId}
        stockTransactionI={stockTransaction}
        stockId={stockId}
        onRefresh={tableRefresh}
      />
    </>
  )
};

export default IPOAlloted;
