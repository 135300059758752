import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from "moment";

function BorrowingsPreview(props) {
    const { showBorrowingsPreview, onHideBorrowingsPreview, BorrowingsTransactionPreview } = props
    //console.log(props,'borrowings preview');
    let d = BorrowingsTransactionPreview;
    let value = d.tenure;
    let splitData = value ? value.split(/([0-9]+)/) : null;
    let time = splitData ? splitData[1] : "-";
    let period = splitData ? splitData[2] : "";
    return (
        <div tabIndex={3}>
            <Modal isOpen={showBorrowingsPreview} toggle={onHideBorrowingsPreview} backdrop="static">
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => onHideBorrowingsPreview()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Borrowings Preview</h5>
                </ModalHeader>
                <ModalBody>
                    {showBorrowingsPreview === true ?
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData odd"><div className="transactionDataTitle">Security : </div>
                                <div className="transactionDataValue">{d.securityName === null ? '-' : d.securityName}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Security Detail: </div>
                                <div className="transactionDataValue">{d.securityDetail.securityDetailName === null ? '-' : d.securityDetail.securityDetailName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Account No. : </div>
                                <div className="transactionDataValue">{d.folioNumber === null ? '-' : d.folioNumber}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Lender : </div>
                                <div className="transactionDataValue">{d.lender === null ? '-' : d.lender}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount Of Loan Taken : </div>
                                <div className="transactionDataValue">{d.amtOfLoan === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.amtOfLoan)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Sanctioned Amount: </div>
                                <div className="transactionDataValue">{d.sanctionedAmt === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.sanctionedAmt)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Disbursed Amount: </div>
                                <div className="transactionDataValue">{d.disbursedAmt === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.disbursedAmt)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Date of Disbursement : </div>
                                <div className="transactionDataValue">{d.dateOfDisbursement === null ? '-' : moment(d.dateOfDisbursement).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Opening Balance: </div>
                                <div className="transactionDataValue">{d.openingBalance === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.openingBalance)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Interest % : </div>
                                <div className="transactionDataValue">{!(d.interestPerc) ? '-' : d.interestPerc + '%'}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Monthly EMI : </div>
                                <div className="transactionDataValue">{d.monthlyEmi === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.monthlyEmi)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Interest Frequency : </div>
                                <div className="transactionDataValue">{d.frequency === null ? '-' : d.frequency.frequencyName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Outstanding Amount : </div>
                                <div className="transactionDataValue">{d.outstandingAmt === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.outstandingAmt)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Narration : </div>
                                <div className="transactionDataValue">{d.narration === null ? '-' : d.narration}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Number of EMI's : </div>
                                <div className="transactionDataValue">{d.numberOfEmi === null ? '-' : new Intl.NumberFormat('en-IN').format(d.numberOfEmi)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Date of Opening Balance: </div>
                                <div className="transactionDataValue">{d.dateOfOpeningBalance === null ? '-' : moment(d.dateOfOpeningBalance).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Tenure : </div>
                                <div className="transactionDataValue">{d.tenure === null ? '-' : `${time ? time : '-'}  ${period ? period : ""}`}</div></div>
                        </div>
                        : null}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default BorrowingsPreview