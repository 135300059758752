import React from "react";

import {Navigate,Outlet} from 'react-router-dom'

import Auth from '../../modules/Auth/index'


const TwoFAPrivateRoute = ()=>{
    const token = Auth.getToken();
    const TwoFA = Auth.getTwoFA();
    const TwoFAAuth = Auth.getTwoFAAuth();
    const userRole = Auth.getUserRoleID();
    
    return token && TwoFA === TwoFAAuth && userRole !== 1 ? <Outlet/>:<Navigate to="/Auth"/>
}



export default TwoFAPrivateRoute;