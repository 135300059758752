/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Avatar, TextField } from "@mui/material";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loader from '../loadingAnimation/index';
import {AiFillEye , AiFillEyeInvisible} from 'react-icons/ai'


const ResetPass = () => {

    const [password, setPassword] = useState('');
    const [comPass, setComPass] = useState('');
    const [token, setToken] = useState('');

    const [errPassword, setErrPasword] = useState('');
    const [errComPass, setErrComPass] = useState('');
    const [loading, setLoading] = useState(false);

  const [confPassVisible,setConfPassVisible] = useState(false);
  const [passVisible,setPassVisible] = useState(false);



    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();

    useEffect(() => {
        const user = searchParams.get("user")
        setToken(user)
    }, [])

    const Validate = () => {
        let Valid = true;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!password) {
            setErrPasword('Please Enter Pssword');
            Valid = false;
        } else if (!passregex.test(password)) {
            setErrPasword('Please Enter Strong Password');
            Valid = false;
        } else {
            setErrPasword('');
        }

        if (!comPass) {
            setErrComPass('Please Enter Confirm Password');
            Valid = false;
        } else if (password !== comPass) {
            setErrComPass(`Password Dosen't Match`);
            Valid = false;
        } else {
            setErrComPass('');
        }

        return Valid
    }

    const onReset = (e) => {
        e.preventDefault();
        const isValid = Validate();
        if (isValid) {
            let body = {
                token: token,
                password: password,
                confirmPassword: comPass,
            };
            let headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            setLoading(true);
            axios.post(`http://5.189.157.40:10025/api/Subcriber/reset-password`, body, headers)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        const data = res.data._response;
                        if (res.data._response.status === 1) {
                            resetState();
                            navigate('/Login');
                            Swal.fire("Password reset Successfully", "", "success");
                        }
                        else {
                            setLoading(false);
                            Swal.fire("Something went wrong", "", "info");
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    Swal.fire("Something went wrong", "", "info");
                    console.log("err in reset password", err)
                })
        }
    }

    const resetState = () => {
        setPassword('')
        setComPass('')
        setToken('')
        setLoading(false)
    }
    return (
        <Container fluid="sm" className="col-md-4 shadow p-3 mb-5 bg-white rounded">
            {loading ? <Loader /> : null}
            <div className="p-3">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Avatar className="avatarStyleLoginReg"><LockOutlinedIcon /></Avatar>
                    </Col>
                </Row>
                <br />
                <h2 className="text-center">Reset Password</h2>
                <br />
                <Row>
                    <TextField type={passVisible ? "text" :"password"} label="Password" name="password" onChange={(e) => setPassword(e.target.value)} fullWidth />
                    <i onClick={()=>setPassVisible(!passVisible)} className='passVisibilityReset'>{passVisible ? <AiFillEyeInvisible size={20}/> :<AiFillEye size={20}/>}</i>

                    {errPassword !== '' ?
                        (<div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                            {errPassword}
                        </div>
                        ) : null}
                </Row>
                <br />
                <Row>
                    <TextField type={confPassVisible ? "text" :"password"} label="Confirm Password" name="comPass" onChange={(e) => setComPass(e.target.value)} fullWidth />
                    <i onClick={()=>setConfPassVisible(!confPassVisible)} className='passVisibilityReset'>{confPassVisible ? <AiFillEyeInvisible size={20}/> :<AiFillEye size={20}/>}</i>

                    {errComPass !== '' ?
                        (<div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                            {errComPass}
                        </div>
                        ) : null}
                </Row>
                <br />
                <Row>
                    <Button className="btn-lg" onClick={onReset}>Reset</Button>
                </Row>
            </div>
        </Container>
    )
}

export default ResetPass