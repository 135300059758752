import axios from '../../modules/helpers/axiosInstance';
import Auth from '../../modules/Auth/index';
import settings from '../../modules/config/settings';

let reportLiveUrl = "http://5.189.157.40:10021/api";
let AccomptServiceUrl = "http://5.189.157.40:10028/api/AccomptCommonServices"

// let localreportUrl = "https://localhost:5001/api/AccomptCommonServices"
const userID = Auth.getsubcriberId();

const getCompanyListBySubscriberId = () => {
    return axios({
        method: 'GET',
        url:`${reportLiveUrl}/StockDetails/GetCompanyBySubscriberId?sub_id=${userID}`,
    })
}
const generateReport = (data) => {
    return axios({
        method: 'POST',
        url: `${AccomptServiceUrl}/StockReportBySubscriberId`,
        data: data
    })
}

const EntityList = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/GetSubaccountList?subId=${userID}`,
    });
}

// const generateHoldingReport = (data) =>{
//     return axios({
//         method: 'POST',
//         url: `https://localhost:5001/api/StockDetails/HoldingReports`,
//         data: data
//     })
// }

export { generateReport, getCompanyListBySubscriberId, EntityList }