/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Loader from "../loadingAnimation/index";
import { Form, Button, Row, Modal, Col, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Cleave from 'cleave.js/react';
import { AddPropertySell, updatePropertySell, getPropertyTransaction, } from '../../pages/Property/PropertyAPI';

const Property_Sell = (props) => {
    const { show1, onHideSell, propertyHeaderId, propertySellTransaction, recentPropHeaderId, handleCloseInner } = props;

    const [sDate, setSDate] = useState(null);
    const [salesProceeds, setSalesProceeds] = useState('');
    const [brokerage, setBrokerage] = useState('');
    const [GST, setGST] = useState('');
    const [transferCharges, setTransferCharges] = useState('');
    const [otherExpenses, setOtherExpenses] = useState('');
    const [netSales, setNetSales] = useState('');
    const [shareSales, setShareSales] = useState('');
    const [typeOfInvestmentId, setTypeOfInvestmentId] = useState(0)
    const [propertyAssetId, setPropertyAssetId] = useState(0)
    const [subId, setSubId] = useState(0)
    const [sharesInProp, setSharesInProp] = useState(0)

    const [formErr, setFormErr] = useState({});
    const [loading, setLoading] = useState(false);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
        }

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }
    const getUpdateTrasactionData = () => {
        setLoading(true);
        getPropertyTransaction(recentPropHeaderId).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    const result = res.data._response.data.propertyTransactionDetails;

                    setSharesInProp(result.shareInProperty)
                    setSDate(new Date(result.dateOfSale))
                    setSalesProceeds(result.salesProceeds);
                    setBrokerage(result.brokerage);
                    setGST(result.gst);
                    setOtherExpenses(result.otherExpenses);
                    setTransferCharges(result.transferCharges);
                    setNetSales(result.netSalesProceeds);
                    setSubId(result.subAccountId);
                    setPropertyAssetId(result.propertyAssetId);
                    setTypeOfInvestmentId(result.typeOfInvestmentId);
                }
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e, "error in property sell");
        });
    }

    useEffect(() => {
        if (show1 && recentPropHeaderId !== undefined) {
            getUpdateTrasactionData();
        }
    }, [show1]);

    useEffect(() => {

        calculateSharesSalesAndNetSales()

    }, [salesProceeds, brokerage, GST, transferCharges, otherExpenses])

    const calculateSharesSalesAndNetSales = (value) => {
        // Share in Sales Proceeds = ( netSaleProcedd / 100) * no of shares
        if (propertyHeaderId !== 0 && propertyHeaderId !== undefined) {
            let shareSale = (((Number(salesProceeds ? salesProceeds : 0)) - Number(brokerage !== "" ? brokerage : 0) - Number(GST !== "" ? GST : 0) - Number(transferCharges !== "" ? transferCharges : 0) - Number(otherExpenses !== "" ? otherExpenses : 0)) /100)* propertySellTransaction.shareInProperty
            setShareSales(shareSale ? shareSale.toFixed(2) : 0);
        }

        let netSale = (salesProceeds ? Number(salesProceeds) : 0) - Number(brokerage !== "" ? brokerage : 0) - Number(GST !== "" ? GST : 0) - Number(transferCharges !== "" ? transferCharges : 0) - Number(otherExpenses !== "" ? otherExpenses : 0);
        setNetSales(netSale.toFixed(2));
    }

    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (!salesProceeds) {
            isValid = false;
            formErr["salesProceed"] = "Please Enter Sales Proceeds!"
        }

        if (!sDate) {
            isValid = false;
            formErr["sdate"] = "Please Select Date!"
        }

        setFormErr(formErr);
        return isValid;
    }

    const disableButton = () => {
        return sDate
        //   && salesProceeds
    }

    const handleReset = () => {
        setSDate(null);
        setSalesProceeds('');
        setBrokerage('');
        setGST('');
        setTransferCharges('');
        setOtherExpenses('');
        setNetSales('');
        setShareSales('');
        setFormErr({});
    }
    //onChange handler
    const handleSaleDate = (sDate) => {
        setSDate(sDate);
        setFormErr({ ...formErr, sdate: "" })
    }
    const handleSalesProceeds = (e) => {
        const { name, rawValue } = e.target;
        setSalesProceeds(rawValue);
        setNetSales(rawValue)
        let shareSaleBrokerage = (rawValue ? Number(rawValue) : 0) / 10;
        // setShareSales(shareSaleBrokerage.toFixed(2));
        setFormErr({ ...salesProceeds, salesProceed: "" });
    }
    const handleBrokerage = (e) => {
        setBrokerage(e.target.rawValue);
    }
    const handleGST = (e) => {
        setGST(e.target.rawValue);
    }
    const handleTransferCharges = (e) => {
        setTransferCharges(e.target.rawValue);
    }
    const handleOtherExpenses = (e) => {
        setOtherExpenses(e.target.rawValue);
    }
    // const handleNetSales = (e) => {
    //     const {name,value} = e.target;
    //     setNetSales({...netSales,[name]:value})
    // }
    // const handleShareSales = (e) => {
    //    setShareSales(e.target.value);

    // }

    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const PropertySell = {
                subcriberId: propertySellTransaction.subcriberId,
                inActiveId: propertySellTransaction.propertyId,
                propertyAssetId: propertySellTransaction.propertyAssetId,
                subAccountId: propertySellTransaction.subAccountId,
                typeOfInvestmentId: propertySellTransaction.typeOfInvestmentId,
                transactType: "Sell",
                dateOfSale: fixTimezoneOffset(sDate),
                salesProceeds: Number(salesProceeds),
                brokerage: brokerage ? Number(brokerage) : 0,
                gst: GST ? Number(GST) : 0,
                transferCharges: transferCharges ? Number(transferCharges) : 0,
                otherExpenses: otherExpenses ? Number(otherExpenses) : 0,
                netSalesProceeds: Number(netSales),
                shareInSalesProceeds: Number(shareSales),
            };
            AddPropertySell(PropertySell).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    Swal.fire(response.data._response.message, "", "success");
                    handleClose();
                    handleCloseInner();
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                })
        }
    }
    // //get property sell data
    //  useEffect(()=>{
    //     if(propertyHeaderId != 0){
    //         if(propertySellTransaction != undefined){
    //             setSDate(new Date(propertySellTransaction.dateOfSale))
    //             setSalesProceeds(propertySellTransaction.salesProceeds)
    //             setBrokerage(propertySellTransaction.brokerage)
    //             setGST(propertySellTransaction.gst)
    //             setTransferCharges(propertySellTransaction.transferCharges)
    //             setOtherExpenses(propertySellTransaction.otherExpenses)
    //             setNetSales(propertySellTransaction.netSalesProceeds)
    //             setShareSales(propertySellTransaction.shareInSalesProceeds)

    //         }
    //     }
    //  },[propertySellTransaction])

    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)

            const PropertySell = {
                propertyId: recentPropHeaderId,
                propertyAssetId: propertyAssetId,
                subAccountId: subId,
                typeOfInvestmentId: typeOfInvestmentId,
                dateOfSale: fixTimezoneOffset(sDate),
                salesProceeds: Number(salesProceeds),
                brokerage: Number(brokerage),
                gst: Number(GST),
                transferCharges: Number(transferCharges),
                otherExpenses: Number(otherExpenses),
                netSalesProceeds: Number(netSales),
                // shareInSalesProceeds:Number(shareSales),
            };
            updatePropertySell(PropertySell).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        Swal.fire("Property Sell Update successfully!", "", "success");
                        handleReset();
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                })
        }
    };
    const handleClose = () => {
        onHideSell();
        handleReset()
    }
    return (
        <>
            {show1 === true ?
                <div>
                    <Modal dialogClassName="custom-dialog addStock" show={show1}
                        size="xl"
                        id="modal2"
                        onHide={() => handleClose()}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >

                        <Modal.Header className="headerBlue" closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg" className="m-auto"><h5 className="text-center mb-0">Property Transaction Sell</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Container fluid>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "12rem" }} />
                                ) : null}

                                <div className="mt-2 p-6" tabIndex={2}>
                                    <Form className=" rounded p-3">
                                        <Row className="form-set">
                                            <Form.Group className="col-md-3">
                                                <Form.Label>Date of Sale<span className="text-danger h6">*</span></Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Sale Date"
                                                    name="sdate"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    onChange={handleSaleDate}
                                                    dateFormat="dd-MM-yyyy"
                                                    autoComplete="off"
                                                    selected={sDate}
                                                    maxDate={new Date()}
                                                    popperPlacement="right-start"
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.sdate}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>Sales Proceeds(100%)<span className="text-danger h6">*</span></Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="salesProceed"
                                                    min="0"
                                                    value={salesProceeds}
                                                    onChange={handleSalesProceeds}
                                                /> */}
                                                <Cleave
                                                className='form-control'
                                                name="salesProceed"
                                                autoComplete='off'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={salesProceeds}
                                                onChange={handleSalesProceeds}
                                               />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.salesProceed}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>Brokerage</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="brokerage"
                                                    min="0"
                                                    value={brokerage}
                                                    onChange={handleBrokerage}
                                                /> */}
                                                 <Cleave
                                                className='form-control'
                                                name="brokerage"
                                                autoComplete='off'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={brokerage}
                                                onChange={handleBrokerage}
                                                />
                                                
                                            </Form.Group>

                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>GST</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="GST"
                                                    min="0"
                                                    value={GST}
                                                    onChange={handleGST}
                                                /> */}
                                                 <Cleave
                                                className='form-control'
                                                name="GST"
                                                autoComplete='off'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={GST}
                                                onChange={handleGST}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>Transfer Charges</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="transferCharges"
                                                    min="0"
                                                    value={transferCharges}
                                                    onChange={handleTransferCharges}
                                                /> */}
                                                 <Cleave
                                                className='form-control'
                                                name="transferCharges"
                                                autoComplete='off'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={transferCharges}
                                                onChange={handleTransferCharges}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>Other Expenses</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="otherExpenses"
                                                    min="0"
                                                    value={otherExpenses}
                                                    onChange={handleOtherExpenses}
                                                /> */}
                                                 <Cleave
                                                className='form-control'
                                                name="otherExpenses"
                                                autoComplete='off'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={otherExpenses}
                                                onChange={handleOtherExpenses}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-3">
                                                <Form.Label>Net Sales Proceeds(100%)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="netSales"
                                                    min="0"
                                                    value={NumberSeperator(netSales)}
                                                    // onChange={handleNetSales}
                                                    disabled={true}
                                                />
                                            </Form.Group>
                                            {/* property */}
                                            {recentPropHeaderId === undefined ?
                                                <Form.Group className="mb-6 col-md-3">
                                                    <Form.Label>Share In Sales Proceeds</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="shareSales"
                                                        min="0"
                                                        value={NumberSeperator(shareSales)}
                                                        // onChange={handleShareSales}
                                                        disabled={true}
                                                    />
                                                </Form.Group>
                                                : null}
                                        </Row>
                                        <Row>
                                            <Col md="12" className="text-center mt-4">
                                                {recentPropHeaderId === undefined ?
                                                    <>
                                                        <Button variant="primary" type="submit"
                                                            id ="submitButton"
                                                            className="mx-2 w100" onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                                            disabled={!disableButton()}
                                                        >Submit</Button>
                                                        <Button
                                                            variant="secondary"
                                                            className="mx-2 w100"
                                                            type="reset"
                                                            onClick={handleReset}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </>
                                                    : null}
                                                {recentPropHeaderId !== undefined ?
                                                    <Button variant="primary" className="mx-2 w100"
                                                        type="submit"
                                                        id="submitButton"
                                                        onClick={(e) => { e.preventDefault(); handleUpdate(e) }}
                                                        disabled={!disableButton()}
                                                    >Update</Button>
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </div>

                : null}
        </>
    )

}
export default Property_Sell;