import React from "react";
import { Container } from "react-bootstrap";
import Content from "../../components/2FA/Content";
import Navbar from '../../components/navbar/Navbar';


const TwoFA = () =>{
    return(
        <div>
            <div className="contentWrap1">
                    <Navbar />
                <div>
                    <Container className="pt-3">
                        <Content />
                    </Container>
                </div>
            </div>
        </div>
        
    )
}

export default TwoFA;