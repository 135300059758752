import React from 'react'
function Card({ title, goPage, icons }) {

    return (
        <div className='reportCard'>
            <div className="card  shadow  mb-5 bg-white rounded" onClick={() => goPage()}>
                <div className="card-body">
                    <h4 className="card-title" style={{ fontSize: "1.2rem" }} >{title}</h4>

                    <h3 ><i title='Go to page' style={{ color: '#00629F' }}>{icons}</i></h3>
                </div>
            </div>
        </div>
    )
}

export default Card