import React from 'react'
import OpeningBalance from '../../components/OpeningBalance/OpeningBalance'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const OpenBal = () => {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
  return (
    <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <OpeningBalance />
                </div>
            </div>
        </div>
  )
}

export default OpenBal