/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React , {useEffect , useState} from 'react'
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import Loader from '../../../loadingAnimation/index'
import {UpdateSubscriber } from '../../../../pages/RoleMasterPages/ChannelPartnerPages/ChannelPartnerApi'
import swal from "sweetalert";

const UpdateUserTable = (props) => {
    const { showUser, userTypeId,subcriberId,userAllData,userId , onHideuser , getAllSubscriber } = props;
    const [userData , setUserData] = useState({});
    const [formErr, setFormErr] = useState({})
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        onHideuser();
    }

    useEffect(()=>{
        if(showUser){
            setUserData(userAllData)
        }
    },[userAllData])

    const handleValue = (e) => {
        // debugger
        const {name,value} = e.target;
        setUserData({...userData , [name]:value});
    }

    const validation = () => {
        const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

        let newUserErr = {};
        let isValid = true;
        if (userData.name === "" || userData.name === null) {
            newUserErr["name"] = "Please enter name!";
            isValid = false;
        }
        // else if (spaceAndLetter.test(data.username) === false) {
        //   newUserErr["username"] = "Please enter alphabetic characters and no spaces";
        //   isValid = false;
        // }
        if (userData.email === "" || userData.email === null) {
            newUserErr["email"] = "Please enter email!";
            isValid = false;
        } else if (emailregex.test(userData.email) === false) {
            newUserErr["email"] = "please enter valid email"
            isValid = false;
        }
        if (userData.mobileNumber === "" || userData.mobileNumber === null) {
            newUserErr["mobileNumber"] = "Please enter mobile number!";
            isValid = false;
        } else if (phoneregex.test(userData.mobileNumber) === false) {
            newUserErr["mobileNumber"] = "Please enter valid phone!";
            isValid = false;
        }
        if (userData.address === "" || userData.address === null) {
            newUserErr["address"] = "Please enter address!";
            isValid = false;
        }
        if (userData.pincode === "" || userData.pincode === null) {
            newUserErr["pincode"] = "Please enter address!";
            isValid = false;
        } else if (pincoderegex.test(userData.pincode) === false) {
            formErr["pincode"] = "Please enter valid pincode";
            isValid = false;
        }
        if (userData.contactPerson === "" || userData.contactPerson === null) {
            newUserErr["contactPerson"] = "Please enter contact person!";
            isValid = false;
        }
        setFormErr(newUserErr);
        return isValid;
    }

    const handleReset = () => {
        setFormErr({});
        setLoading(false);
    } 

    const handleUpdate = () => {
        const isValid = validation();
        if(isValid === true){
            setLoading(true);
            let Udata = {
                subcriberId : Number(subcriberId),
                UserTypeId : Number(userTypeId),
                subcriberName:userData.name,
                subcriberEmail:userData.email,
                subcriberPh1:userData.mobileNumber,
                contactPerson:userData.contactPerson,
                pinCode: Number(userData.pincode),
                subcriberAdd1:userData.address,
                userId: Number(userId)
            };
            UpdateSubscriber(Udata).then((res) => {
                
                if(res.status === 200 ){
                    // debugger
                    setLoading(false);
                    if (res.data._response.status === 1){
                        swal(res.data._response.message, "", "success");
                        handleCloseModal();
                    getAllSubscriber()
                    } 
                    else{
                        swal(res.data._response.message, "", "warning");
                    }
                } else {
                    setLoading(false)
                    console.log("error in update");
                }
                handleReset()
            }).catch((err)=>{
                handleReset()
            });
        }
    }

    return (
        <div>
            <Modal show={showUser} onHide={() => handleCloseModal()} size="md" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form  >
                            <Row>
                            <Form.Group className="col-md-6">
                                    <Form.Label>Name<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={userData.name}
                                        onChange={handleValue}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.name}
                                        </div>
                                    ) : null}
                                </Form.Group>


                                <Form.Group className="col-md-6">
                                    <Form.Label>Contact Person<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="contactPerson"
                                        value={userData.contactPerson}
                                        onChange={handleValue}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.contactPerson}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className=" col-md-6">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="mobileNumber"
                                        min="0"
                                        value={userData.mobileNumber}
                                        onChange={handleValue}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mobileNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className=" col-md-6">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={userData.email}
                                        onChange={handleValue}
                                        disabled
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.email}
                                        </div>
                                    ) : null}
                                </Form.Group>

                             
                            </Row>
                            <Row>
                                <Form.Group className=" col-md-6">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="pincode"
                                        min="0"
                                        value={userData.pincode}
                                        onChange={handleValue}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.pincode}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className=" col-md-6">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={userData.address}
                                        onChange={handleValue}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.address}
                                        </div>
                                    ) : null}
                                </Form.Group>

                             
                            </Row>
                            <Row className="form-set">
                                <Col md="12" className="text-center mt-3">
                                        <Button variant="primary" className="mx-2 w100"  onClick={handleUpdate}>Update</Button> 
                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UpdateUserTable