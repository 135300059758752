/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form } from "react-bootstrap";
import { MultiSelect } from '../CommonComponent/MultiSelect';
import FormErrComponet from '../CommonComponent/ErrorComponet';
import Loader from '../loadingAnimation/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateReport, getCompanyListBySubscriberId } from '../../pages/Report/ReportApi'
import Auth from '../../modules/Auth';
import Swal from 'sweetalert2';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import { changePeriodList } from "../../modules/helpers/constant"

function Reports() {
    const userID = Auth.getsubcriberId();
    const registrationDate = Auth.getRegistrationDate()

    const navigate = useNavigate();
    const location = useLocation();
    const reportValue = location.state;

    const [loading, setLoading] = useState(true);
    const [formErr, setFormErr] = useState({});

    const [companySel, setCompanySel] = useState([]);
    const [company, setCompany] = useState([]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [changePeriodSel, setChangePeriodSel] = useState([])
    // const [brokerSel, setBrokerSel] = useState([]);
    // const [broker, setBroker] = useState([]);

    useEffect(() => {

        (async () => {
            await getCompanyDropDownData()
        
            //   reportValue === 1 ? getBrokerDropDownData() : getEntityList();
        })();
    }, [])

    




    const getCompanyDropDownData = async () => {
        setLoading(true);
        await getCompanyListBySubscriberId()
            .then((res) => {
                setLoading(false)
                if (res.data._response.status === 1) {
                    let company = res.data._response.data.companyName;
                    let companyData = [];
                    company &&
                        company.map((data) => {
                            // check duplicate companies in companyData array
                            let checkPresent = companyData.some(el => el.value === data.companyId)
                            if (!checkPresent) {
                                return (companyData.push({
                                    value: data.companyId,
                                    label: data.companyName,
                                }))
                            }
                        });
                    setCompany(companyData);
                    setCompanySel(companyData)
                    setLoading(false);
                } else {
                    setCompany([]);
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e, "company list error");
            });
    };



    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (companySel.length <= 0) {
            isValid = false;
            formErr["scrip"] = "Please select company!";
        }

        if (changePeriodSel.length <= 0){
            isValid = false;
            formErr["period"] = "Please select change period!";
        }
        
        setFormErr(formErr);
        return isValid;
    }


    const getCurrentFinancialYear = (strDocDate) => {

        var startYear = "";
        var endYear = "";
        var docDate = new Date(strDocDate);
        if ((docDate.getMonth() + 1) <= 3) {

            startYear = docDate.getFullYear() - 1;
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear();
            setToDate(new Date("31-Mar" + endYear))

        } else {
            startYear = docDate.getFullYear();
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear() + 1;
            setToDate(new Date("31-Mar" + endYear))
        }

        // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
    }

    const getLastFinancialYear = (strDocDate) => {

        var startYear = "";
        var endYear = "";
        var docDate = new Date(strDocDate);
        if ((docDate.getMonth() + 1) <= 3) {

            startYear = docDate.getFullYear() - 1;
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear();
            setToDate(new Date("31-Mar" + endYear))

        } else {
            startYear = docDate.getFullYear() - 1;
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear();
            setToDate(new Date("31-Mar" + endYear))
        }

        // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
    }

    const getPrvsToLastFinancialYear = (strDocDate) => {

        var startYear = "";
        var endYear = "";
        var docDate = new Date(strDocDate);
        if ((docDate.getMonth() + 1) <= 3) {

            startYear = docDate.getFullYear() - 2;
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear() - 1;
            setToDate(new Date("31-Mar" + endYear))

        } else {
            startYear = docDate.getFullYear() - 2;
            setFromDate(new Date("01-Apr" + startYear))

            endYear = docDate.getFullYear() - 1;
            setToDate(new Date("31-Mar" + endYear))
        }
        // console.log({ startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear }, "date")
        // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
    }

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }
    // normal report
    const handleSubmit = () => {

        let validate = errHandel();
        if (validate) {
            // const brokerId = [];
            const companyId = [];
            // const entityId = [];

           
            companySel.length > 0 && companySel.forEach((data, index) => {
                companyId.push(data.value);
            });

            const data = {
                // brokerId: reportValue === 1 ? brokerId : undefined,
                companyId: companyId,
                reportId: reportValue,
                instrumentId: 1,
                subscriberId: userID,
                startDate: fromDate,
                endDate: fixTimezoneOffset(toDate)

            }

            setLoading(true);
            generateReport(data).then(res => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        const report = reportValue === 3 ? res.data._response.data.brokerSubAccountWiseReport : res.data._response.data.brokerWiseReport;
                        let Reports = {
                            report, reportValue, fromDate, toDate
                        }

                        reportValue === 3 ? navigate('/ReportsTable', { state: Reports }) : navigate('/ReportTable', { state: Reports })
                    } else {
                        Swal.fire("There is no data to display", "", 'info');
                    }
                }
            }).catch(e => {
                setLoading(false);
                console.log(e, 'error in genrate report api');
            })

        }
    }



    const handleClear = () => {
        setChangePeriodSel([])
        setFormErr({});
        // setEntitySel([]);
        setCompanySel([]);
        // setBrokerSel([]);
        setFromDate(null)
        setToDate(null)
    }

    const handleChangePeriod = (selected) => {
        setChangePeriodSel(selected)
        setFromDate(null)
        setToDate(null)

        if (selected.value === 1) {

            getCurrentFinancialYear(new Date())

        } else if (selected.value === 2) {

            getLastFinancialYear(new Date())

        } else if (selected.value === 3) {

            getPrvsToLastFinancialYear(new Date())

        } else if (selected.value === 4) {

            let lastYear = new Date().getFullYear() + 1;
            let startYear = new Date(registrationDate)

            setToDate(new Date("31-Mar" + lastYear))
            setFromDate(startYear)
        }
    }

    return (
        // <Container fluid="lg" className=" shadow p-3 mb-5 pt-5 bg-white rounded ">
        <div className='mt-5 pt-3'>


            <div className='container border p-5 shadow mt-0 mb-0 bg-white rounded col-md-5 col-sm-12 h-50'>
                <div className='mb-3 p-1'>
                    <h3 className='text-center'>{reportValue === 1 ? "Brokerwise scrip report" :
                        reportValue === 2 ? "Entitywise scrip report" :
                            'Brokerwise - Entitywise scrip report'}</h3>
                </div>
                {/* {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
                <Form className='mt-2'>
                    <Row className="form-set row justify-content-md-center">


                        <>
                            <Form.Group className="mt-4 mb-2 col-md-10 col-sm-12">

                                <Form.Label>Company Name</Form.Label>
                                <MultiSelect options={company} value={companySel} onChange={setCompanySel} />
                                {formErr ? <FormErrComponet formErr={formErr.scrip} /> : null}
                            </Form.Group>

                        </>






                        {/*stating of date filter  */}
                        {

                            <>
                                <Form.Group className="mt-4  col-md-4 col-sm-4">
                                    <Form.Label>Change Period</Form.Label>
                                    <Select options={changePeriodList} value={changePeriodSel} onChange={handleChangePeriod} />
                                    {formErr ? <FormErrComponet formErr={formErr.period} /> : null}
                                </Form.Group>
                                {
                                    changePeriodSel.value === 5 ?
                                        <>
                                            <Form.Group className="mt-4  col-md-4 col-sm-4">
                                                <Form.Label>From</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    onChange={(date) => setFromDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={fromDate}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-4  col-md-4 col-sm-4">
                                                <Form.Label>To</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    minDate={fromDate}
                                                    onChange={(date) => setToDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={toDate}
                                                />
                                            </Form.Group>
                                        </> :
                                        <>
                                            <Form.Group className="mt-4  col-md-4 col-sm-4">
                                                <Form.Label>From</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    // onChange={(date) => setFromDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={(fromDate)}
                                                    disabled
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-4  col-md-4 col-sm-4">
                                                <Form.Label>To</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    // onChange={(date) => setToDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={toDate}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </>
                                }
                            </>
                        }



                    </Row>
                    <Row >
                        <Col md="12" className="text-center mt-2">
                            {/* <Button variant="primary" className='btn btn-sm' id="submitButton" onClick={() => { handleSubmit(); }}>Generate Report</Button> */}
                            <Button style={{ width: "12rem" }} variant="primary" className='btn btn-sm ' id="submitButton" onClick={() => { handleSubmit(); }} disabled={loading}>
                                {loading && <span style={{ marginLeft: "-2rem", position: 'absolute' }}> <span className="spinner-border spinner-border-sm p-2 letter-spacing: 0.02857em;" role="status" aria-hidden="true" style={{ paddingLeft: 5 }}></span></span>}
                                Generate Report
                            </Button>
                            <Button variant="secondary" className='btn btn-sm w100 mx-2' id="submitButton" onClick={() => { handleClear(); }}>Clear</Button>
                        </Col>
                    </Row>
                </Form>
            </div>




        </div>
        // </Container>
    )
}

export default Reports