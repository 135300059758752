import React from 'react'
import { Container } from "react-bootstrap";
import ReportsCard from '../../components/Report/ReportsCard';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Report() {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return (
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <Container className="pt-3">
                        <ReportsCard />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Report