import React, { useState, useEffect } from 'react';
import Auth from '../../modules/Auth';
import Loader from "../../components/loadingAnimation/index";
import './style.css';
import Button from '@mui/material/Button';
import { getAllPlans, addPayment, addPlans, afterPay, userPlans } from '../../pages/Subscription/SubscriptionAPI';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from "moment";
import { useDispatch } from 'react-redux';
import { ClearSidebarData } from '../../redux/action/sidebarAction';

const Subscription = () => {
 
    const [loading, setLoading] = useState(false)
    const [subscriptionList, setSubscriptionsPlans] = useState([]);
    const [plansAllData, setAllPlansData] = useState([])
    const [planStart , setPlansStart] = useState(null)
    const [planEnd, setPlansEnd] = useState(null)


    const userID = Auth.getsubcriberId();
    const userName = Auth.getUserName();
    const email = Auth.getEmail();
    const alreadySubscribe = Auth.getUserIsPaid()
    const mobileNo = Auth.getMobileNumber();

    const dispatch = useDispatch();

    var ThirtyFirstMarch = new Date(new Date().getFullYear() +1, 2, 31);

    useEffect(() => {
        getAllPlansDataList();
        subscribePlans()
    }, [])

    const submitSubcription = (plan) =>{
        setLoading(true);
        let a = {
            userId: userID,
            planId: plan.planId,
            planTitle: plan.planName,
            planAmount: plan.planAmount,
            transactionDate: new Date(),
            startDate: new Date(),
            endDate: ThirtyFirstMarch,
        }
        addPlans(a)
            .then((res) => {
                if(res.status === 200){
                    if(res.data._response.status === 1){
                        PayPlans(plan);
                        // Swal.fire({
                        //     title: 'Successfull',
                        //     text: 'successfully added',
                        //     icon: 'success',
                        //     confirmButtonText: 'Ok'
                        // })
                        setLoading(false);
                    }else{
                        setLoading(false);
                        Swal.fire({
                            title: 'Already subscribed',
                            text: `${res.data._response.message}`,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        })
                        console.log("err in post subscription plan data")
                    }
                }else{
                    setLoading(false);
                    console.log("error in add subscription plan");
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    // load all plans cards
    const getAllPlansDataList = () => {
        setLoading(true)
        getAllPlans()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let AllplansData = res.data._response.data.planFeatureDetails
                        setAllPlansData(AllplansData);
                        setLoading(false);
                    }
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    const PayPlans = (plan) => {
        setLoading(true)
        let paymentData = {
            name: userName,
            mobile: mobileNo,
            email: email,
            amount: parseInt(2),
            userId: userID,
        }
        addPayment(paymentData)
            .then((res) => {
                setLoading(false)
                const a = plan.planAmount;
                const b = plan.planId;
                const c = plan.planName;
                var options = {
                    key: res.data._response.data.key,
                    amount: (paymentData.amount) * 100,
                    currency: "INR",
                    name: "ACCOMPT",
                    description: "for testing purpose",
                    order_id: res.data._response.data.orderId,
                    handler: function (response) {
                        // console.log(response,"response in razor ");
                        const data = {
                            userId: userID,
                            orderId: response.razorpay_order_id,
                            paymentId: response.razorpay_payment_id,
                            signatureId: response.razorpay_signature,
                            paymentStatus: response.razorpay_payment_id ? "Paid" : "Failed",
                            startDate: new Date(),
                            planId: b,
                            planTitle: c,
                            planAmount: a,
                        };
                        afterPay(data)
                            .then((res) => {
                                setLoading(false)
                                if (res.status === 200) {
                                    if (res.data._response.status === 1) {
                                        // Swal.fire({
                                        //     title: 'Done!',
                                        //     text: 'Payment Successfull',
                                        //     icon: 'success',
                                        //     confirmButtonText: 'Ok'
                                        // })
                                        Auth.logout();
                                        window.location.href = "/Thankyou";
                                        dispatch(ClearSidebarData());
                                        // navigate("/Thankyou");
                                      
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'Payment Failed!',
                                            confirmButtonText: 'Ok'
                                        })
                                    }
                                }
                            }).catch((err) =>
                                console.log(err, "err in afterpayment")
                            )
                        setLoading(false)
                    },
                    "prefill": {
                        "name": userName,
                        "email": email,
                        "contact": mobileNo
                    },
                    theme: {
                        color: "#3399cc"
                    }
                }
                var pay = new window.Razorpay(options);
                pay.on('payment.failed', function (response) {
                    // console.log(response);
                    if (response.error.code === "BAD_REQUEST_ERROR"){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Payment Failed!',
                            confirmButtonText: 'Ok'
                        })
                    }
                  
                });
                pay.open();
            }).catch((err) => {
                console.log(err, " err in payment");
            })
        
    }

    // subscribe users plans 
    const subscribePlans = () => {
        setLoading(true);
        userPlans()
            .then((res) =>{
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let AllplansData = res.data._response.data.planDetails
                        setSubscriptionsPlans(AllplansData);
                        setPlansStart(res.data._response.data.startDate)
                        setPlansEnd(res.data._response.data.endDate)
                        setLoading(false);
                    }
                }
            }).catch((err) =>{
                console.log(err);
                setLoading(false);
            })
    }

    return (
        <>
            {/* new trial user block start here */}
            {alreadySubscribe !== 1 ?
                <div className='main'>
                    <div className='container'>
                        <div className='row'>
                            {loading ? (
                                <Loader />
                            ) : null}
                            {plansAllData && plansAllData.map((plan, index) => {
                                return (
                                    <div className="col-md-3" key={index}>
                                        <div className="tableplan basic">
                                            <div className="price-section">
                                                <div className={plan.planName === "Silver" ? "price-area1" : plan.planName === "Gold" ? "price-area" : plan.planName === "Bronze" ? "price-area2" : "price-area"}>
                                                    <div className={plan.planName === "Silver" ? "inner-area1" : plan.planName === "Gold" ? "inner-area" : plan.planName === "Bronze" ? "inner-area2" : "inner-area"}>
                                                        <span className="price">₹{plan.planAmount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='planhr'></hr>
                                            <div><p className='planName text-center'>{plan.planName}</p></div>
                                            <hr className='planhr'></hr>

                                            <ul className="features">
                                                {plan.featureDetails && plan.featureDetails.map((featureList, i) => {
                                                    return (
                                                        <li>
                                                            <span className="list-name" key={i}>{featureList.featureName}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <hr className='planhr'></hr>
                                            <div>
                                                <p className={plan.planName === "Silver" ? "month mt-0 m-2" : plan.planName === "Gold" ? "month mt-0 m-2" : plan.planName === "Bronze" ? "month mt-0 m-1" : "plan"}> Plan Started on : {moment(planStart).format("DD/MM/YYYY")}   </p>
                                                <p className="month m-2 text-danger"> Valid till : {moment(planEnd).format("DD/MM/YYYY")} </p>
                                            </div>
                                            <hr className='planhr'></hr>
                                            <div className={plan.planName === "Silver" ? "btn1" : plan.planName === "Gold" ? "btn" : plan.planName === "Bronze" ? "btn2" : "btn"}>
                                                <button
                                                    onClick={() => submitSubcription(plan)}>
                                                    Add to cart
                                                </button>
                                            </div>
                                        </div>
                                    </div>



                                )
                            })}
                        </div>
                    </div>
                </div>

                :
                <div className='main'>
                    <div id='container'>
                        {loading ? (
                            <Loader />
                        ) : null}
                        {subscriptionList && subscriptionList.map((plan, index) => {
                            return (
                                <div className="planwrapper mt-2 p-2">
                                    <div className="tableplan basic">
                                        <div className="price-section">
                                            <div className={plan.planName === "Silver" ? "price-area1" : plan.planName === "Gold" ? "price-area" : plan.planName === "Bronze" ? "price-area2" : "price-area"}>
                                                <div className={plan.planName === "Silver" ? "inner-area1" : plan.planName === "Gold" ? "inner-area" : plan.planName === "Bronze" ? "inner-area2" : "inner-area"}>
                                                    {plan.title === "Free Trial" ?
                                                        <>
                                                            <span className="price">₹{plan.planAmount}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="price">₹{new Intl.NumberFormat('en-IN').format(plan.planAmount)}</span>
                                                        </>


                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='planhr'></hr>
                                        <div><p className='planName'>{plan.planName}</p></div>
                                        <hr className='planhr'></hr>
                                        <ul className="features">
                                            {plan.featureDetails && plan.featureDetails.map((featureList) => {
                                                return (
                                                    <li>
                                                        <span className="list-name">{featureList.featureName}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <hr className='planhr'></hr>
                                        <div>
                                            <p className={plan.planName === "Silver" ? "month mt-0 m-2" : plan.planName === "Gold" ? "month mt-4 m-2" : plan.planName === "Bronze" ? "month mt-0 m-1" : "plan"}> Plan Started on : {moment(planStart).format("DD/MM/YYYY")}</p>
                                            <p className="month m-2 text-danger"> Valid till : {moment(planEnd).format("DD/MM/YYYY")} </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            }
        </>
    )
}

export default Subscription