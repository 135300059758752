/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Table,Stack } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import Loader from "../../loadingAnimation/index";

import { liveSubscribers } from '../ChannelMaster/channalMasterApi'

import UserHistory from './UserHistory';

function LiveMembersTable(props) {
  const { dataAllCount } = props;

  const [liveSubcribesData, setLiveSubscibersData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [dataCount, setDataCount] = useState(0)

  // pagination
  const [pageCount, setPageCount] = useState(1)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 10;

  const [userHistoryModal, setUserHistoryModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [userCodeId,setUserCodeId] = useState(0)

  const columns = [
    {
      align: 'center',
      height: 40,
      colName: 'Sr No.'
    },
    {
      align: 'center',
      height: 40,
      colName: 'User code no'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Date of credit to Accompt Account'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Date of next renewal'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Type of Payment'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Bank Name/ credit card company'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Bank transfer Ref No / credit card no / cheque no'
    },
  ]


  useEffect(() => {
    getLiveSubscribersList(1, 10)
  }, [])

  const getLiveSubscribersList = (pageNumber, dataPerPage) => {
    setLoading(true)
    liveSubscribers(pageNumber, dataPerPage)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {

            let userData = res.data._response.data.liveMembers
            setLiveSubscibersData(userData)
            setLoading(false)
             // pagination
            let pageCount = Math.ceil(userData.length / dataPerPage)
            setPageCount(pageCount)

            let dataCount = res.data._response.data.totalCount;
            // console.log("dataCount",dataCount);
            setDataCount(dataCount)
            dataAllCount(dataCount)
          }
        }
      })
      .catch((err) => {
        console.log(err, "error while fetching Live Members");
        setLoading(false)
      })
  }

  const changePage = (e, a) => {
    const selectedPage = e.selected;
   
  }

  

  //close history pop up
  const handleCloseModal = () => {
    setUserHistoryModal(false);
    setRowData([]);
  }

  //handle table row click
  const handleRowClick = (rowData) => {
  
    setRowData(rowData);
    setUserHistoryModal(true);
    setUserCodeId(rowData.userId)

  }
  return (
    <>
    <Paper className='p-3 container mt-3 h-50'>
      <h4 className='text-center mb-4'>Live members</h4>
      <TableContainer component={Paper} elevation={4}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
          <TableHead>
            <TableRow sx={{ fontSize: 14 }}>
              {columns.map((col, i) => (
                <TableCell align={col.align} style={{ height: col.height }} key={i}>{col.colName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ?
                  <Loader /> :  null
            }
            {
                liveSubcribesData.length === 0 ? <>
                  <TableRow>
                    <TableCell colspan="7">No data found</TableCell>
                  </TableRow>

                </> :
              
              liveSubcribesData.map((row, i) => (
              <TableRow
                key={i}
              >
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="right" onClick={() => handleRowClick(row)}>{row.subscriberCodeNo}</TableCell>
                <TableCell align="right"> {moment(row.startDate).format("DD/MM/YYYY")}</TableCell>
                <TableCell align="right">01/04/2023</TableCell>
                <TableCell align="left">Card</TableCell>
                <TableCell align="left">VISA</TableCell>
                <TableCell align="right">4111 1111 1111 1111</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end"
        mt={1} mb={1}>
        <Pagination
          count={pageCount}
          page={pageNumber}
          onChange={changePage}
          variant="outlined"
          color="primary"
        />
      </Stack>
    </Paper>

     <div>
        <UserHistory isOpenModal={userHistoryModal} onHideModal={handleCloseModal} tableData={rowData} userCodeId = {userCodeId}/>
      </div>

      </>
  )
}

export default LiveMembersTable