/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
import { FetchBrokerDropDownData, FetchCompanyDropDownData, CompanyList, EntityList, addUnlistedScrip, addstockMerger, updateMerger, FetchDate } from '../../../pages/Stocks/StocksAPI'
import Loader from '../../loadingAnimation/index';
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const StockMergermodal = (props) => {
  const { showM, onHideM, stockHeaderIdM, stockTransactionM, stockIdM, showPreviewStockMerger } = props;
  const [scrip, setScript] = useState({});
  const [selScripOld, setSelScripOld] = useState([]);
  const [selScripMerge, setSelScripMerge] = useState([]);
  const [broker, setBroker] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [allcompanyList, setAllCompanyList] = useState([]);
  const [data, setData] = useState({ quantity: "" });
  const [narration, setNarration] = useState({ narration: "" });
  const [formErr, setFormErr] = useState([])
  const [newShowC, setNewShowC] = useState(false);
  const [selNewMerge, setSelNewMerge] = useState([]);
  const [error, setError] = useState([]);
  const [brokerValue, setBrokerValue] = useState([])
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [successMessage, setSuccessMessage] = useState({});
  // const [brokerValue, setBrokerValue] = useState([])
  const [entityValue, setEntityValue] = useState([])
  const [buyDate, setBuyDate] = useState(null)
  const [ratio, setRatio] = useState('')

  // unlisted new company add
  const [showModal, setShowModal] = useState(false);
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');

  // for buy date
  useEffect(() => {
    if (showM) {
      if (stockHeaderIdM === 0 || stockHeaderIdM === undefined) {
        setPDate(null)
        let companyId = selScripOld.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
      if (stockHeaderIdM !== 0 && stockTransactionM !== undefined) {
        let companyId = selScripOld.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
    }
  }, [selScripOld, selBroker, selEntity, showM]);

  // close model
  const handleClose = (val) => {
    onHideM(val);
    handleReset();
  }
  //modal handle
  const handleclose = () => {
    setNewShowC(false)
  }
  const handleOpen = () => {
    setNewShowC(true)
  }
  // useEnterKeyListener({
  //   querySelectorToExecuteClick: "#submitButton"
  // });
  //handling error
  const errHandel = () => {
    let formErr = {};
    let isValid = true;
    if (selScripOld.length < 1) {
      isValid = false;
      formErr["selScripOld"] = "Please Select Scrip!";
    }
    if (selScripMerge.length < 1) {
      isValid = false;
      formErr["selScripMerge"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }
    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }
    if (!data.quantity || data.quantity === 0) {
      isValid = false;
      formErr["quantity"] = "Please Fill This Field!";
    }
    if (data.quantity) {
      if (data.quantity < 0) {
        isValid = false;
        formErr["quantity"] = "Please Enter Positive Value !";
      }
    }
    // if (data.quantity) {
    //   let qvalue = parseFloat(data.quantity); 
    //   if (Number.isInteger(qvalue) == false) {
    //     isValid = false;
    //     formErr["quantity"] = "Please enter only integer value !";
    //   }
    // }

    if (selScripOld === selScripMerge) {
      isValid = false;
      formErr["selScripMerge"] = "Merging Into Company Is Same As Merging Company!";
    }



    setFormErr(formErr);
    return isValid;
  };

  useEffect(() => {
    if (showM) {
      (async () => {
        await getEntityListt();
        await getCompanyList();
      })();
    }
  }, [showM]);
  useEffect(() => {
    if (showM) {
      (async () => {
        await getCompanyDropDownData()
        await getBrokerDropDownData()
      })();
    }
  }, [selEntity])
  // get dropDown company Data
  const getBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchBrokerDropDownData(sub_Id)
        .then((res) => {
          setLoading(false)
          // console.log(res, "brokerlisy");
          if (res.data._response.status === 1) {
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setLoading(false);
            setBroker(brokersData);
          } else {
            setBroker([]);
            setLoading(false)
            //setError(res.data.message);
          }
        })
        .catch((e) => {
          // setError(e.message);
          setBroker([]);
          setLoading(false);
          console.log(e, "brokerlist error");
        });
    }
  };


  // get dropDown Company Data
  const getCompanyDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchCompanyDropDownData(sub_Id)
        .then((res) => {
          // console.log(res,'company list');
          if (res.data._response.status === 1) {
            let UnlistedCompany = [];
            let ListedCompany = [];

            let company = res.data._response.data.companyName;
            company &&
              company.filter(data => data.instrumentTypeId === 1).map(i => {
                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });
            company &&
              company.filter(data => data.instrumentTypeId === 10).map(i => {
                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });

            const groupedOptions = [
              {
                label: 'Listed Companies',
                options: ListedCompany,
              },
              {
                label: 'UnListed Companies',
                options: UnlistedCompany,
              },
            ];
            setLoading(false);
            setCompanyList(groupedOptions);
          } else {
            setCompanyList([]);
            setLoading(false);
            // setError(res.data.message);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
          // setError(e.message);
        });
    }
  };
  //getcompanylist by group
  const getCompanyList = async (ListedCompanyParams) => {
    setLoading(true)
    await CompanyList()
      .then((res) => {
        if (res.data._response.status === 1) {
          //
          setLoading(false)
          let UnlistedCompany = [];
          let ListedCompany = [];

          let company = res.data._response.data.companyDetails;

          company &&
            company.filter(data => data.instrumentTypeId === 1).map(i => {
              return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
            });
          company &&
            company.filter(data => data.instrumentTypeId === 10).map(i => {
              return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
            });

          const groupedOptions = [
            {
              label: 'Listed Companies',
              options: ListedCompany,
            },
            {
              label: 'UnListed Companies',
              options: UnlistedCompany,
            },
          ];
          if (ListedCompanyParams === 1) {

            let lastElement = UnlistedCompany[UnlistedCompany.length - 1];

            setSelScripMerge(lastElement)

          }
          setAllCompanyList(groupedOptions);
          setLoading(false)

        } else {
          setLoading(false)
          setAllCompanyList([]);
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setAllCompanyList([]);
        console.log(e, 'error in companyList');
        // setError(e.message);
      });
  };
  const getBuyDate = async (sub_id, co_id, bro_id) => {
    setLoading(true)
    await FetchDate(sub_id, co_id, bro_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false)
            setBuyDate(res.data._response.data.fetchDate)
          }
          else {
            setLoading(false)
            setError(res.data.message);
          }
        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message);
      });
  };

  //getEntityList
  const getEntityListt = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          //
          let entity = res.data._response.data.subaccountDetails;
          let entityData = [];
          entity &&
            entity.map((data) => {
              return (entityData.push({
                value: data.subAccountId,
                label: data.subAccountName,
                disabled: data.active
              }))
            });
          setEntity(entityData);
          setLoading(false)
        } else {
          setLoading(false);
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });


  };
  // unlisted modal new company
  const handleOpenModal = () => setShowModal(!showModal);

  const handleCloseModal = () => {
    setShowModal(false);
    setUnlistedComp('');
    setUnlistedCompErr('')
  }

  //handling unlisted modal 
  const handleUnlisted = (e) => {
    const { value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }

  //submit unlisted modal 
  const submitUnlisted = (e) => {
    e.preventDefault();

    if (unlistedComp !== '') {
      let data = {
        "companyName": unlistedComp,
        instrumentTypeId: 10
      }
      setLoading(true);
      addUnlistedScrip(data)
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              // console.log(res, "submit data ");
              const lastEle = 1
              getCompanyList(lastEle);
              setUnlistedComp('');
              setUnlistedCompErr('');
              handleCloseModal()
              // swal("Added successfully!", "", 'success');
            } else {
              Swal.fire(res.data._response.message, '', 'info')
              setLoading(false)
            }
          } else {
            setLoading(false)
          }
        })
        .catch(e => {
          setLoading(false)
          console.log(e, "error in unlisted");
        })
    } else {
      setUnlistedCompErr("Company name required!")
    }
  }

  const Control = ({ children, ...props }) => (

    <components.Control {...props}>
      <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )

  useEffect(() => {

    if (stockHeaderIdM !== 0) {
      setLoading(true)
      if (stockTransactionM !== undefined) {

        setSelBroker({
          value: stockTransactionM.brokerId,
          label: stockTransactionM.broker.brokerName,
        });
        setBrokerValue(stockTransactionM.brokerId);

        setSelEntity({
          value: stockTransactionM.subAccountId,
          label: stockTransactionM.subAccount.subAccountName,
        });
        setEntityValue(stockTransactionM.subAccountId);

        setNarration({ narration: stockTransactionM.narration })

        stockTransactionM.stockDetails && stockTransactionM.stockDetails.map((t) => {
          setData({ quantity: t.quantity })
          setPDate(new Date(t.date));
          setRatio(t.ratio)
          setSelScripMerge(t.company, { companyType: t.instrumentTypeId })

          const c = t.fromCompany
          setSelScripOld({ label: c.companyName, value: t.fromCompanyId })
          setLoading(false)



        })
      }

    }

  }, [stockTransactionM])

  //handlechange
  const handleInputOld = (selected) => {
    if (selScripMerge !== selected) {
      setSelScripOld(selected);
      setFormErr({ ...formErr, selScripOld: '' });
    }
    else {
      setSelScripOld('')
      formErr["selScripOld"] = "Same Company Selected....";
      setFormErr(formErr)

    }

  }


  //handel disable listed companies
  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }

  const handleInputMerge = (selected) => {
    if (selScripOld !== selected) {
      setSelScripMerge(selected);
      setFormErr({ ...formErr, selScripMerge: '' });
    } else {
      setSelScripMerge('')
      formErr["selScripMerge"] = "Same Company Selected....";
      setFormErr(formErr)

    }


  }

  const handleInputNewMerge = (selected) => {
    setSelNewMerge(selected);
  }

  const handleEntity = (selected) => {
    setEntityValue(selected.value)
    setSelEntity(selected);
    setSelScripOld([]);
    setSelBroker([]);
    setFormErr({ ...formErr, selEntity: '' });
  }

  const handleOption = (selected) => {
    setBrokerValue(selected.value);
    setSelBroker(selected);
    setSelScripOld([])
    setSelScripMerge([])
    setFormErr({ ...formErr, selBroker: '' })
  }

  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: '' })
  }

  const handleValueQ = (e) => {
    const { name, rawValue } = e.target;
    setData({ ...data, [name]: rawValue });
    setFormErr({ ...data, quantity: "" });
  };

  const handleValueN = (e) => {
    const { name, value } = e.target;
    setNarration({ ...narration, [name]: value });
    setFormErr({ ...narration, narration: "" });
  };

  const handleRatio = (e) => {
    setRatio(e.target.value.replace(/[^0-9:]/ig, ''))
  }

  //reset form
  const handleReset = () => {
    setCompanyList([]);
    setBroker([])
    setSelBroker([]);
    setSelScripOld([]);
    setSelScripMerge([]);
    setSelBroker([]);
    setSelEntity([]);
    setData({ quantity: '' });
    setNarration({ narration: "" });
    setPDate(null);
    setFormErr({});
    setRatio('');
  }



  //submit form
  const handleSubmit = (e) => {

    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);

      let Sdata = {
        narration: narration.narration,
        fromCompanyId: selScripOld.value,
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        companyId: selScripMerge.value,
        date: fixTimezoneOffset(pDate),
        quantity: parseInt(data.quantity),
        instrumentId: 1,
        ratio: ratio,
        instrumentTypeId: selScripMerge.companyType
      };

      addstockMerger(Sdata)
        .then((res) => {


          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              Swal.fire("Stock Merger Added successfully!", "", "success");

              setSuccess(true);
              handleReset();
              // onRefresh();
            }
          } else {
            setLoading(false);
            console.log("eror while data added with respnose");
          }

        }).catch((error) => {
          setLoading(false);
          Swal.fire("No Quantities Available For Both The Companies!", "", "warning");
          console.log(error, "error while submitting data in stock merger");
        })

    }

  }
  const handleUpdate = (e) => {
    // e.preventDefault();

    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      let Sdata = {
        stockId: stockIdM,
        stockHeaderId: stockHeaderIdM,
        narration: narration.narration,
        fromCompanyId: selScripOld.value,
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        companyId: selScripMerge.value,
        date: new Date(pDate).toISOString(),
        quantity: parseInt(data.quantity),
        instrumentId: 1,
        ratio: ratio,
        instrumentTypeId: selScripMerge.companyType

      };
      updateMerger(Sdata)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            if (response.data._response.status === 1) {
              setSuccess(true);

              Swal.fire("Stock Merger Updated successfully!", "", "success");
              handleReset();
              // onRefresh();
              // setChange(new Date())
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  //submit new company to merge
  const AddCompany = (e) => {
    e.preventDefault();
    let cData = {
      "newMerge": selNewMerge.value,
    }
    setScript(cData);
    setNewShowC(false)

  }

  const disableButton = () => {
    return data && narration && pDate && selBroker && selEntity && selScripOld && selScripMerge
  }

  const fixTimezoneOffset = (pDate) => {
    if (!pDate) return "";
    return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
  }

  return (
    <>
      <Modal show={showM} onHide={handleClose} size="lg" centered backdrop="static">
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto"><h5>Stock Merger</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mt-2 p-6">
              {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
              <Form className="border border-1 rounded p-3" onSubmit={handleSubmit}>

                <Row className="form-set">
                  {stockHeaderIdM === 0 || stockHeaderIdM === undefined ?
                    <Form.Group className="col-md-6">
                      <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                      <Select
                        value={selEntity}
                        onChange={handleEntity}
                        options={entity}
                        name="subAccountId"
                        isOptionDisabled={(option) => option.disabled === 0}
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selEntity}
                        </div>
                      ) : null}
                    </Form.Group>
                    :
                    <Form.Group className="col-md-6">
                      <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                      <Select
                        value={selEntity}
                        onChange={handleEntity}
                        options={entity}
                        name="subAccountId"
                        isDisabled="required"
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selEntity}
                        </div>
                      ) : null}
                    </Form.Group>
                  }
                  {stockHeaderIdM === 0 || stockHeaderIdM === undefined ?
                    <Form.Group className="col-md-6" >
                      <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                      <Select
                        value={selBroker}
                        onChange={handleOption}
                        options={broker}
                        name="brokerId"
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selBroker}
                        </div>
                      ) : null}
                    </Form.Group>
                    :
                    <Form.Group className="col-md-6" >
                      <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                      <Select
                        value={selBroker}
                        onChange={handleOption}
                        options={broker}
                        name="brokerId"
                        isDisabled="required"
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selBroker}
                        </div>
                      ) : null}
                    </Form.Group>
                  }
                </Row>
                <Row className="form-set">
                  {stockHeaderIdM === 0 || stockHeaderIdM === undefined ?
                    <Form.Group className="col-md-6" >
                      <Form.Label>Select Scrip of Merging company <span className="text-danger h6">*</span></Form.Label>

                      <Select

                        value={selScripOld}
                        onChange={handleInputOld}
                        options={companyList}
                        name="fromCompanyId"
                        // isOptionDisabled={handelDisableDD}
                        styles={{
                          option: (data) => ({
                            ...data,
                            margin: 0,
                            padding: '3px 12px',
                          }),
                          groupHeading: (base) => ({
                            ...base,
                            flex: '1 1',
                            color: '#000',
                            margin: 0,
                            padding: '7px 2px',
                            // border: `2px solid `,
                            background: "#8bc3ff",
                            fontSize: 'small',
                            fontWeight: 500
                          }),
                        }}
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selScripOld}
                        </div>
                      ) : null}
                    </Form.Group>
                    :
                    <Form.Group className="col-md-6" >
                      <Form.Label>Select Scrip of Merging company<span className="text-danger h6">*</span> </Form.Label>

                      <Select

                        value={selScripOld}
                        onChange={handleInputOld}
                        options={companyList}
                        name="fromCompanyId"
                        isDisabled="required"
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selScripOld}
                        </div>
                      ) : null}
                    </Form.Group>
                  }
                  {stockHeaderIdM === 0 || stockHeaderIdM === undefined ?
                    <Form.Group className="col-md-6" >
                      <Form.Label>Select Scrip of merging into company <span className="text-danger h6">*</span></Form.Label>

                      <Select
                        components={selBroker.value === 1 ? { Control } : null}
                        value={selScripMerge}
                        onChange={handleInputMerge}
                        options={allcompanyList}
                        name="companyId"
                        // isOptionDisabled={handelDisableDD}
                        styles={{
                          option: (data) => ({
                            ...data,
                            margin: 0,
                            padding: '3px 12px',
                          }),
                          groupHeading: (base) => ({
                            ...base,
                            flex: '1 1',
                            color: '#000',
                            margin: 0,
                            padding: '7px 2px',
                            // border: `2px solid `,
                            background: "#8bc3ff",
                            fontSize: 'small',
                            fontWeight: 500
                          }),
                        }}
                      // onBlur={CompareCompany}
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selScripMerge}
                        </div>
                      ) : null}
                    </Form.Group>
                    :
                    <Form.Group className="col-md-6" >
                      <Form.Label>Select Scrip of merging into company<span className="text-danger h6">*</span> </Form.Label>

                      <Select

                        value={selScripMerge}
                        onChange={handleInputMerge}
                        options={allcompanyList}
                        name="companyId"
                        isDisabled="required"
                      />
                      {formErr ? (
                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                          {formErr.selScripMerge}
                        </div>
                      ) : null}
                    </Form.Group>
                  }

                </Row>
                <Row className="form-set">
                  <Form.Group className="col-md-4" >
                    <Form.Label>Balance Shares after merging<span className="text-danger h6">*</span></Form.Label>
                    {/* <Form.Control type="Number" name="quantity" min="0" value={Math.round(data.quantity)} onChange={handleValueQ} /> */}
                    <Cleave
                      className='form-control'
                      name="quantity"
                      autoComplete="off"
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      value={Math.round(data.quantity)}
                      onChange={handleValueQ}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.quantity}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="col-md-4" >
                    <Form.Label> Ratio </Form.Label>
                    <Form.Control type="text" name="amount" min="0" placeholder="Enter Ratio" autoComplete="off" onChange={handleRatio} value={ratio} />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.ratio}</div> : null}
                  </Form.Group>

                  <Form.Group className="col-md-4">
                    <Form.Label>Date of purchase<span className="text-danger h6">*</span></Form.Label>
                    <DatePicker className="form-control"
                      placeholderText="Select Start Date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      maxDate={new Date()}
                      minDate={new Date(buyDate)}
                      onChange={handleDate}

                      dateFormat="dd-MM-yyyy"
                      name="date"
                      autoComplete='off'
                      selected={pDate}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.date}
                      </div>) : null}

                  </Form.Group>
                </Row>
                <Row className="form-set">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Comments/Remarks</Form.Label>
                    <Form.Control as="textarea" name="narration" value={narration.narration} rows={2} onChange={handleValueN} />
                    {/* {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.narration}
                      </div>
                    ) : null} */}
                  </Form.Group>
                </Row>
                <Row>
                  <Col md="12" className="text-center mt-3">
                    {stockHeaderIdM === 0 || stockHeaderIdM === undefined ? (

                      <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }} disabled={!disableButton()}>
                        Save
                      </Button>
                    ) : (
                      <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()}>
                        Update
                      </Button>
                    )}
                    {stockHeaderIdM === 0 || stockHeaderIdM === undefined ?
                      <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>
                        Clear
                      </Button> : null}
                  </Col>
                </Row>

              </Form>


            </div>
            <div >
              <Modal show={newShowC} onHide={handleclose} size="sm">
                <Modal.Header closeButton>
                  <Modal.Title>Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group className="col-12" >
                      <Form.Label>Select new Scrip of merging into company </Form.Label>

                      <Select
                        value={selNewMerge}
                        onChange={handleInputNewMerge}
                        options={companyList}
                        name="newMerge"
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleclose}>
                    Close
                  </Button>
                  <Button variant="primary" type='submit' onClick={AddCompany}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>


            </div>
          </>
        </Modal.Body>

      </Modal>

      {/* to company added unlisted company */}
      <>
        <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" centered>
          <Modal.Header closeButton>
            <Modal.Title>Unlisted Scrip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form >
              <div className="form-group mt-2 mb-2">
                <label for="inputUserName">Company Name</label>
                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp.companyName} />
                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
              Save
            </Button>

          </Modal.Footer>
        </Modal>
      </>
      {showPreviewStockMerger === true ?
        <Modal show={showPreviewStockMerger} onHide={() => handleClose(1)}>
          <Modal.Header closeButton className="headerBlue" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Stock Merger Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Select Scrip of Merging Company : </div>
                <div className="transactionDataValue">{selScripOld.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Select Scrip of Merging into Company  : </div>
                <div className="transactionDataValue">{selScripMerge.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Balance shares after merging : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(data.quantity)}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Date of Purchase : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Comments/Remarks : </div>
                <div className="transactionDataValue">{narration.narration}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  )
}

export default StockMergermodal;
