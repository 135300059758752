/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';

import {
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TableCell,
    TableContainer,
    IconButton,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableFooter,
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Tooltip from '@mui/material/Tooltip';
//import Select, { createFilter } from 'react-select';
import { Stack } from "@mui/material";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Edit, Visibility, Delete } from "@material-ui/icons";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from "@mui/icons-material/Add";
import $ from "jquery";
import moment from "moment";
import _ from "lodash";
import { GlobalInfo } from '../Content/Content';
import Loader from "../loadingAnimation/index";
import FDotherTrsansaction from "../sidebar/FDotherTransaction";
import FixedIncomeDetail from "../FixedIncomeDetails/FIDetails"
import { FetchFDData, EntityWiseFIList, GetBuySellGrid, GetFDTransaction, GetBuySellGridEntity, deleteFixedDeposite, DeleteFIRedeemConvert } from "../FIncomeFolio/FDAPI"
import BondsConvertibles from './other_transactions/BondsConvertibles';
import BondsRedeem from './other_transactions/BondsRedeem';
import DebenturesConvertibles from './other_transactions/DebenturesConvertibles';
import DebenturesRedeem from './other_transactions/DebenturesRedeem';
import FD_RD_PO_Preview from './other_transactions/FD_RD_PO_Preview';
import Swal from 'sweetalert2';
import { EntityList } from '../../pages/Stocks/StocksAPI'
import Auth from '../../modules/Auth'

import SearchBar from "material-ui-search-bar";
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom'

function FixedIncomes() {
    const { closemodal } = useContext(GlobalInfo);
    const [showFD, setShowFD] = useState(false);
    const [entity, setEntity] = useState("Family");
    const [script, setScript] = useState("All");
    const [FIData, setFIData] = useState([])
    const [entityRadio, setEntityRadio] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [iconsTest, setIconTest] = useState(AddIcon);
    const [entityId, setEntityId] = useState(null);
    const [filteredEntiryData, setFilteredEntiryData] = useState([]);
    const [entityData, setEntityData] = useState([])

    const [subId, setSubId] = useState(null);
    const [subIdApi, setSubIdApi] = useState(null);
    const [subNameEnt, setSubNameEnt] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [livePrice, setLivePrice] = useState(null);
    const [fdCurrentValue, setFdCurrentValue] = useState(null);
    const [activeC, setActive] = useState({})

    const [gridDetail, setGridDetail] = useState({});
    const [FDHeaderId, setFDHeaderId] = useState(0);
    const [FDTransaction, setFDTransaction] = useState({});

    const [loading, setLoading] = useState(false);
    const [TransactionType, setTransactionType] = useState([]);
    const [FixDID, setFixDID] = useState([]);
    const [previewFdopen, setpreviewFdopen] = useState(false);
    const [previewDebRedeemableOpen, setpreviewDebRedeemableOpen] = useState(false);
    const [previewBondRedeemableOpen, setpreviewBondRedeemableOpen] = useState(false);
    const [previewDebConvertibleOpen, setpreviewDebConvertibleOpen] = useState(false);
    const [previewBondConvertibleOpen, setpreviewBondConvertibleOpen] = useState(false);
    const [previewNewFD_RD_PO, setPreviewNewFD_RD_PO] = useState(false);


    const [showInner, setShowInner] = useState(false);
    // modal transaction
    const [bondscon, setbondscon] = useState(false);
    const [bondsredee, setbondsredee] = useState(false);
    const [debenturecon, setdebenturecon] = useState(false);
    const [debentureredee, setdebentureredee] = useState(false);
    // const [fdMature, setfdMature] = useState(false);
    const [instTYID, setinstTYID] = useState([])
    const [sell, setSell] = useState([]);
    const [entitySell, setEntitySell] = useState([]);


    const userID = Auth.getsubcriberId();
    const [searched, setSearched] = useState("");
    const location = useLocation();
    const { state } = location;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if(state){
            setCompanyId(state.companyId)
            setSubIdApi(state.subIdApi)
            setSubNameEnt(state.subAccountName)
            let companyId = state.companyId;
            let companyName = state.companyName;
            let livePrice = state.livePrice;
            let instrumentTypeId = state.instrumentTypeId;
            let subIdApi = state.subIdApi;
            getBuyGetData(subIdApi, companyId, companyName, livePrice, instrumentTypeId);
            setShowInner(true);
        }
    }, [state,subIdApi])

    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
       });


    useEffect(() => {
        closeallmodalformaturity()
    }, [closemodal])


    const closeallmodalformaturity = async () => {
        setShowFD(false);
        setShowInner(false);
        // setEntity("Family")
        getFIData();
    }

    useEffect(
        async () => {

            await setLoading(true);
            getFIData();
            if (userID) {
                // getSignalR();
                getEntityList()
            }
        }, [userID]);


    //all income  family data
    const getFIData = () => {
        FetchFDData().then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    setLoading(false);
                    let FixedIncome = res.data._response.data.fixedIncomeOuterGrid;
                    setFIData(FixedIncome);
                    GetGridData(FixedIncome);
                    $(".MuiTableRow-root").each(function () {
                        let val = $('.material-icons ', this).text()
                        if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                    });
                } else {
                    setLoading(false);
                    setFilteredData([]);
                    setFIData([])

                }
            } else {
                setLoading(false);

            }
        })
            .catch((e) => {
                setLoading(false);
                console.log(e.message);
            });
    };

    // all fixed income entity wise data 
    const getFIEntityData = async (entityId) => {
        await EntityWiseFIList(entityId)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        //+ button code
                        $(".MuiTableRow-root").each(function () {
                            let val = $('.material-icons ', this).text()
                            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                        });

                        let EntityStock = res.data._response.data.fixedIncomeIndividualEntity;
                        setEntityData(EntityStock);
                        setLoading(false)

                        GetEntityGridData(EntityStock)

                        setScript("All")
                        setSearched("")
                    } else {
                        setFilteredEntiryData([]);
                        setEntityData([])
                        setLoading(false)
                    }
                }
            })
            .catch((e) => {
                console.log("error");
                setLoading(false)
            });
    }

    //filter family grid
    const GetGridData = (FixedIncome) => {
        let check = [];
        FixedIncome.forEach((item, i) => {
            if (check.indexOf(item.instrumentTypeName) === -1) {
                check.push(item.instrumentTypeName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                maturityValue: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };
            // console.log(FixedIncome);
            FixedIncome.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    let maturityValue = mainData.maturityValue + item.maturityValue;
                    // console.log(mainData.maturityValue,"1");
                    // console.log(item.maturityValue,"2");
                    // console.log(maturityValue,"1+2");

                    mainData = {
                        livePrice: livePrice,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        companyName: item.instrumentTypeName,
                        closingBalance: closingBalance,
                        currentValue: currentValue,
                        maturityValue: cost === 0 ? 0 : maturityValue,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        companyName: item.subAccountName,
                        parentId: index,
                        id: index + 1,
                        company: item.subAccountName,
                        maturityValue: item.cost === 0 ? 0 : item.maturityValue,
                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredData([...parentData, ...childData]);
    };

    //filter entity grid
    const GetEntityGridData = (EntityStock) => {
        let check = [];
        EntityStock.forEach((item, i) => {
            if (check.indexOf(item.instrumentTypeName) === -1) {
                check.push(item.instrumentTypeName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                maturityValue: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };

            EntityStock.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    let maturityValue = mainData.maturityValue + item.maturityValue;

                    mainData = {
                        livePrice: livePrice,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        closingBalance: closingBalance,
                        companyName: item.instrumentTypeName,
                        currentValue: currentValue,
                        maturityValue: cost === 0 ? 0 : maturityValue,
                        id: index
                    };

                    let normalData = {
                        ...item,
                        companyName: item.companyName,
                        maturityValue: item.cost === 0 ? 0 : item.maturityValue,
                        parentId: index,
                        id: index + 1,
                        company: item.companyName,
                    };

                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredEntiryData([...parentData, ...childData]);
    };

    const handleclose = async (val) => {
        setbondscon(false);
        setbondsredee(false);
        setdebenturecon(false);
        setdebentureredee(false)
        // setfdMature(false);
        setpreviewBondRedeemableOpen(false);
        setpreviewDebRedeemableOpen(false);
        setpreviewDebConvertibleOpen(false);
        setpreviewBondConvertibleOpen(false);
        setpreviewFdopen(false)
        setPreviewNewFD_RD_PO(false);
        // setEntity('Family');
        if (val !== 1) await onRefreshFD();
    };

    //modal close
    const handleClose = async (val) => {
        setShowFD(false)
        setFDTransaction({});
        setFDHeaderId(0);
        setpreviewFdopen(false)
        setpreviewBondRedeemableOpen(false);
        setpreviewDebRedeemableOpen(false);
        setpreviewDebConvertibleOpen(false);
        setpreviewBondConvertibleOpen(false);
        setPreviewNewFD_RD_PO(false);
        // await setEntity('Family');
        // await setEntityId(null);
        if (val !== 1) {
            await onRefreshFD();
            await onTransactionGridRefresh();
        }
    }

    const handleCloseInner = async () => {
        setShowInner(false);
        // setEntity('Family');
        handleReset();
        // setSubIdApi(null)
        await onRefreshFD();
    }

    //getEntityList
    const getEntityList = async () => {
        setLoading(true)

        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let entity = res.data._response.data.subaccountDetails;
                        setEntityRadio(entity);
                        setLoading(false)
                    }

                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false)
                // setError(e.message);
            });


    };

    //handle snapshot
    const handleView = (selectedRow, a) => {

        GetFDTransaction(a.fdHeaderId).then((res) => {
            setFDHeaderId(a.fdHeaderId);
            setFixDID(a.fdId);
            const result = res.data._response.data.fixedincomeTrnsactionDetails;
            setFDTransaction(result);
            if (a.transactStockType === "Redeemed" && (result.instrumentTypeId === 21 || result.instrumentTypeId === 12)) {
                setpreviewDebRedeemableOpen(true);
            } else if (a.transactStockType === "Converted" && (result.instrumentTypeId === 21 || result.instrumentTypeId === 12)) {
                setpreviewDebConvertibleOpen(true)
            } else if (a.transactStockType === "Redeemed" && (result.instrumentTypeId === 13 || result.instrumentTypeId === 22)) {
                setpreviewBondRedeemableOpen(true);
            } else if (a.transactStockType === "Converted" && (result.instrumentTypeId === 13 || result.instrumentTypeId === 22)) {
                setpreviewBondConvertibleOpen(true);
            } else if (a.transactStockType === "Buy" && (a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32)) {
                setPreviewNewFD_RD_PO(true);
            } else {
                setpreviewFdopen(true);
            }
        })
    }
    const handleFidelete = (selectedRow, a) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willdelete) => {
            if (willdelete) {
                setLoading(true);
                DeleteFIRedeemConvert(a.fdHeaderId, a.fdId).then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            Swal.fire(res.data._response.message, {
                                title: "Deleted",
                                icon: "success",
                            });
                            onTransactionGridRefresh();
                        }
                    }
                    else {
                        setLoading(false);
                        onTransactionGridRefresh();
                    }
                })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    })
            } else {
                Swal.fire("Cancelled", "Your  data is safe ", "error");
            }
        }).catch((err) => {
            console.log("err in delete")
        })
    }
    const handleDelete = (selectedRow, a) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setLoading(true);
                    deleteFixedDeposite(a.fdId).then((res) => {
                        if (res.status === 200) {
                            if (res.data._response.status === 1) {
                                setLoading(false);
                                Swal.fire(res.data._response.message, {
                                    title: "Deleted",
                                    icon: "success",
                                });
                                // const del = sell.filter(sells => a.fdId !== sells.fdId)
                                // setSell(del);
                                // const delEntity = entitySell.filter(Esells => a.fdId !== Esells.fdId)
                                // setEntitySell(delEntity)
                                // setGridDetail({

                                //     finalQuantity: 0,
                                //     currentValue: 0,
                                //     finalCost: 0,
                                //     livePrice: 0
                                // });
                                onTransactionGridRefresh()
                                handleCloseInner()
                                setFdCurrentValue(0)
                                // onRefreshFD();
                            }
                            else {
                                setLoading(false);
                            }
                        }
                    })
                        .catch(err => {
                            console.log(err);
                            setLoading(false);
                        })
                } else {
                    Swal.fire("Cancelled", "Your  data is safe ", "error");
                }
            });
    }

    //entity chnaged
    const handleChangeEntity = async (e) => {
        setLoading(true)
        await setEntity(e.target.value);
        if (e.target.value === "Family") {
            setEntityId(null);
            setSubIdApi(null);
            setSubNameEnt("")
            getFIData();
            setScript("All");
            setSearched("")
            //+ button code
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                let button = $("button", this);
                $(button).html("<span class='material-icons'>add</span>");
            });
        } else {
            let data = _.find(entityRadio, function (o) {
                return o.subAccountName == e.target.value;
            });
            setSubNameEnt(e.target.value)
            await setEntityId(data.subAccountId);
            await setSubIdApi(data.subAccountId);
            await getFIEntityData(data.subAccountId)
        }
    };

    //open FDDetils Modal
    const openTransaction = async () => {
        // await setEntity('Family');
        setShowFD(true);
    };

    const columns = [
        { title: "Type of Investment", field: "companyName" },

        {
            title: "Amount Invested",
            field: "cost",
            type: "numeric",
            render: (rowData) => (
                <span>
                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.cost)/*Tanmay-25/01/2022*/}
                </span>
            ),
        },

        {
            title: "Current Value",
            field: "currentValue",
            type: "numeric",
            render: (rowData) => (
                <span>
                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentValue)/*Tanmay-25/01/2022*/}
                </span>
            ),
        },
        {
            title: "Maturity Value",
            field: "maturityValue",
            type: "numeric",
            render: (rowData) => (
                <span>

                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.maturityValue)/*Tanmay-25/01/2022*/}
                </span>
            ),
        },

    ];

    //details pannel icon parent child
    const handleIcon = (row, rows) => {
        let index = row.tableData.path[0];
        if (rows) {
            // setIconTest(RemoveIcon);
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>maximize</span>");
                    $(button).css({
                        transform: "rotate(180deg)",
                    });
                }
            });
        } else {
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>add</span>");
                }
            });
        }
    };
    const handleReset = () => {
        setCompanyId(null);
        setCompanyName(null);
        setSubId(null);
        setLivePrice(null);
    }

    // buysell Inner GRID
    const getBuyGetData = async (
        subAccountId,
        companyId,
        companyName,
        livePrice,
        instrumentTypeId,
        currentValue,
        index
    ) => {
        setSubId(subAccountId);
        setCompanyId(companyId);
        setCompanyName(companyName);
        setLivePrice(livePrice);
        setinstTYID(instrumentTypeId);
        // setFdCurrentValue(currentValue);
        setLoading(true);
        // family
        if (subAccountId && instrumentTypeId && state === null) {

            await GetBuySellGrid(subAccountId, instrumentTypeId)
                .then((res) => {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        let data = res.data._response.data.fetchFixedIncomeEntityInfo;
                        if (instrumentTypeId === 29 || instrumentTypeId === 30 || instrumentTypeId === 31 || instrumentTypeId === 32) {
                            let dataF = data.filter((a) => a.active !== 0)
                            setSell(dataF);
                            onRefreshFD();
                        } else {
                            setSell(data);
                        }
                        let quantity = _.last(data);
                        let buyAmt = 0;
                        let sellAmt = 0;

                        data &&
                            data.map((f) => {


                                if (f.transactStockType !== "Sell" && f.transactStockType !== "Redeemed") {


                                    buyAmt += f.closingBalance;


                                }

                                if (f.transactStockType === "Redeemed") {
                                    sellAmt += f.totalCost;
                                }
                                setTransactionType(f.transactStockType)
                            });
                        setGridDetail({
                            companyId: companyId,
                            companyName: companyName,
                            livePrice: livePrice,
                            finalQuantity: quantity.balanceQuantity,
                            currentValue: livePrice * quantity.balanceQuantity,
                            finalCost: buyAmt - sellAmt,
                        });

                        setShowInner(true);
                        //+/- code
                        var arr = [];
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            let a = /[,\\/]/g;
                            let b = a.test(path)
                            if (b) {
                                let c = path.charAt(0)
                                if (!arr.includes(c)) {
                                    arr.push(c)
                                }
                            }
                        });
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            if (arr.includes(path)) {
                                let button = $("button", this);
                                $(button).html("<span class='material-icons'>maximize</span>");
                                $(button).css({
                                    transform: "rotate(180deg)",
                                });
                            }
                        });
                    } else {
                        setSell([]);
                        setGridDetail({});
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else if (instrumentTypeId && companyId && subIdApi) {

            // entity

            await GetBuySellGridEntity(instrumentTypeId, companyId, subIdApi)
                .then(async (res) => {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        let data = res.data._response.data.fetchFixedIncomeEntityInfo;
                        setEntitySell(data);
                        let quantity = _.last(data);
                        let buyAmt = 0;
                        let sellAmt = 0;
                        let buyqty = 0;
                        let sellqty = 0
                        data &&
                            data.map((f) => {
                                // debugger
                                setActive(f.active)
                                f.transactStockType === "Buy" && f.active === 0 ?
                                    setFdCurrentValue(0) :
                                    setFdCurrentValue(f.currentValue)

                                if (f.transactStockType !== "Sell" && f.transactStockType !== "Redeemed" && f.transactStockType !== "Converted") {
                                    buyAmt += f.totalCost;
                                    buyqty += f.quantity

                                }
                                if (f.transactStockType === "Sell") {
                                    sellqty += f.quantity
                                    sellAmt += f.totalCost;
                                }
                                // if (f.transactStockType == "Buy" && f.active == 0) {

                                //     setFdCurrentValue(0)

                                // }
                                if (f.transactStockType === "Redeemed" || f.transactStockType === "Converted") {
                                    sellAmt += f.totalCost;
                                    sellqty += f.quantity

                                }
                                setTransactionType(f.transactStockType)
                            });

                        setGridDetail({
                            companyId: companyId,
                            companyName: companyName,
                            livePrice: livePrice,
                            // finalQuantity: quantity.balanceQuantity,
                            currentValue: livePrice * quantity.balanceQuantity,
                            // currentValue: fdCurrentValue,
                            finalCost: buyAmt - sellAmt,
                            finalQuantity: buyqty - sellqty
                        })

                        // //+/- code
                        // var arr = [];
                        // $(".MuiTableRow-root").each(function () {
                        //     let path = this.getAttribute("path");
                        //     let a = /[,\/]/g;
                        //     let b = a.test(path)
                        //     if (b) {
                        //         let c = path.charAt(0)
                        //         if (!arr.includes(c)) {
                        //             arr.push(c)
                        //         }
                        //     }
                        // });
                        // $(".MuiTableRow-root").each(function () {
                        //     let path = this.getAttribute("path");
                        //     if (arr.includes(path)) {
                        //         let button = $("button", this);
                        //         $(button).html("<span class='material-icons'>maximize</span>");
                        //         $(button).css({
                        //             transform: "rotate(180deg)",
                        //         });
                        //     }
                        // });

                        setShowInner(true);
                        setShowLoader(true);
                    } else {
                        setLoading(false);
                        setEntitySell([]);
                        setGridDetail({});
                    }

                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        }
    };

    //open transactions
    const alertMyRow = (selectedRow, a) => {
        setLoading(true);
        GetFDTransaction(a.fdHeaderId).then((res) => {
            setLoading(false);
            setFDHeaderId(a.fdHeaderId);
            setFixDID(a.fdId);
            const result = res.data._response.data.fixedincomeTrnsactionDetails;
            setFDTransaction(result);

            if (a.transactStockType === "Converted" && result.instrumentId === 2) {
                setbondscon(true);
            } else if (a.transactStockType === "Redeemed" && result.instrumentId === 2) {
                setbondsredee(true)
            } else if (a.transactStockType === "Converted" && result.instrumentId === 3) {
                setdebenturecon(true);
            } else if (a.transactStockType === "Redeemed" && result.instrumentId === 3) {
                setdebentureredee(true)
            }
            //  else if (a.transactStockType === "Matured" && result.instrumentId === 4) {
            //     setfdMature(true)
            // } 
            else {
                setShowFD(true);
            }

        })
            .catch(e => {
                setLoading(false);
                console.log(e);
            });
    };

    const onRefreshFD = () => {

        // setEntity("Family");
        getFIData();

        if (entityId) getFIEntityData(entityId);
        //setLoading(true);
    }

    const onTransactionGridRefresh = () => {
        setShowFD(false);
        getBuyGetData(
            subId,
            companyId,
            companyName,
            livePrice,
            instTYID,
            subIdApi
        );
    }

    //filter stocks
    const handleScripData = (e) => {

        let selected = e.target.value;
        setScript(selected)
        setSearched("")
        // family data filter
        let sorted = selected === "All" ? FIData : FIData.filter((a) => a.instrumentTypeId === parseInt(selected))

        let check = [];
        sorted.forEach((item, i) => {
            if (check.indexOf(item.instrumentTypeName) === -1) {
                check.push(item.instrumentTypeName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                maturityValue: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };
            sorted.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let maturityValue = mainData.maturityValue + item.maturityValue;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    mainData = {
                        livePrice: livePrice,
                        maturityValue: maturityValue,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        companyName: item.instrumentTypeName,
                        closingBalance: closingBalance,
                        currentValue: currentValue,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        companyName: item.subAccountName,
                        parentId: index,
                        id: index + 1,
                        company: item.subAccountName,
                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredData([...parentData, ...childData]);

        //entity data  filter
        let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.instrumentTypeId === parseInt(selected)))
        let checkEntity = [];
        sortedEntity.forEach((item, i) => {
            if (checkEntity.indexOf(item.instrumentTypeName) === -1) {
                checkEntity.push(item.instrumentTypeName);
            }
        });
        let parentEntityData = [];
        let childEntityData = [];
        checkEntity.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                maturityValue: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };
            sortedEntity.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let maturityValue = mainData.maturityValue + item.maturityValue;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    mainData = {
                        livePrice: livePrice,
                        maturityValue: maturityValue,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        closingBalance: closingBalance,
                        companyName: item.instrumentTypeName,
                        currentValue: currentValue,
                        id: index
                    };

                    let normalData = {
                        ...item,
                        companyName: item.companyName,
                        parentId: index,
                        id: index + 1,
                        company: item.companyName,
                    };
                    childEntityData.push(normalData);
                }
            });
            parentEntityData.push(mainData);
        });
        setFilteredEntiryData([...parentEntityData, ...childEntityData]);

    }

    // function for 1st captilized letter
    const UpperFirst = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // search function
    const requestSearch = (searchedVal) => {

        setSearched(searchedVal)
        const filteredRows = FIData.filter((row) => {
            return row.instrumentTypeName.includes(searchedVal.toUpperCase()) || row.instrumentTypeName.includes(searchedVal.toLowerCase()) || row.instrumentTypeName.includes(UpperFirst(searchedVal));
        });


        //family wise
        let check = [];
        filteredRows.forEach((item, i) => {
            if (check.indexOf(item.instrumentTypeName) === -1) {
                check.push(item.instrumentTypeName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                maturityValue: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };
            // console.log(FixedIncome);
            filteredRows.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    let maturityValue = mainData.maturityValue + item.maturityValue;
                    // console.log(mainData.maturityValue,"1");
                    // console.log(item.maturityValue,"2");
                    // console.log(maturityValue,"1+2");

                    mainData = {
                        livePrice: livePrice,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        companyName: item.instrumentTypeName,
                        closingBalance: closingBalance,
                        currentValue: currentValue,
                        maturityValue: cost === 0 ? 0 : maturityValue,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        companyName: item.subAccountName,
                        parentId: index,
                        id: index + 1,
                        company: item.subAccountName,
                        maturityValue: item.cost === 0 ? 0 : item.maturityValue,
                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredData([...parentData, ...childData]);

        // entitywise
        const forentity = filteredRows.filter(a => a.subAccountId === entityId)

        let checkEntity = [];
        forentity.forEach((item, i) => {
            if (checkEntity.indexOf(item.instrumentTypeName) === -1) {
                checkEntity.push(item.instrumentTypeName);
            }
        });
        let parentDataEntity = [];
        let childDataEntity = [];
        checkEntity.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                maturityValue: 0,
                prevLivePrice: 0,
                quantity: 0,
                cost: 0,
                name: "",
                closingBalance: 0,
                currentValue: 0
            };

            forentity.forEach((item, i) => {
                if (checkdata === item.instrumentTypeName) {
                    let livePrice = item.livePrice;
                    let prevLivePrice =
                        mainData.prevLivePrice + item.prevLivePrice;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.cost + item.cost;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    let currentValue = mainData.currentValue + item.currentValue;
                    let maturityValue = mainData.maturityValue + item.maturityValue;

                    mainData = {
                        livePrice: livePrice,
                        prevLivePrice: prevLivePrice,
                        quantity: quantity,
                        cost: cost,
                        closingBalance: closingBalance,
                        companyName: item.instrumentTypeName,
                        currentValue: currentValue,
                        maturityValue: cost === 0 ? 0 : maturityValue,
                        id: index
                    };

                    let normalData = {
                        ...item,
                        companyName: item.companyName,
                        maturityValue: item.cost === 0 ? 0 : item.maturityValue,
                        parentId: index,
                        id: index + 1,
                        company: item.companyName,
                    };

                    childDataEntity.push(normalData);
                }
            });
            parentDataEntity.push(mainData);
        });
        setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


    };

    // cancle search
    const cancelSearch = () => {
        setSearched("");
        // requestSearch(searched);
        if (entityId) {
            getFIEntityData(entityId)
        } else {
            getFIData()
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4 className="text-center py-3">Fixed Income Folio</h4>
                </Col>
            </Row>

            <FormControl className="mt-4">

                <RadioGroup
                    row
                    color="primary"
                    defaultValue="start"
                    aria-label="Entity"
                    name="row-radio-buttons-group"
                    value={entity}
                    onChange={handleChangeEntity}
                    style={{ marginTop: "-3rem" }}


                >
                    <FormControlLabel
                        value="Family"
                        control={<Radio />}
                        label="Family"
                    />
                    {entityRadio.map((a) => (
                        <FormControlLabel
                            key={a.subAccountName}
                            value={a.subAccountName}
                            control={<Radio />}
                            label={a.subAccountName}
                            disabled={a.active === 0 ? true : false}
                        />
                    ))}

                </RadioGroup>
            </FormControl>
            <Row>
                <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="flex-end"
                    alignItems="flex-start" mb={3} >
                    {entityId == null ? null :
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => openTransaction()}
                                style={{ height: "2rem" }}
                            >
                                Transactions
                            </Button>
                        </>

                    }
                    <Button variant="contained" color="primary" style={{ height: "2rem" }}>
                        <FDotherTrsansaction className="stockdrop" onRefreshFD={onRefreshFD} />
                    </Button>

                    {/* <Button variant="contained" color="primary" style={{ height: "2rem" }}>
                        Upload Contract Note
                    </Button> */}

                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={script}
                        label="Type"
                        onChange={handleScripData}
                        style={{ minWidth: 120, paddingLeft: "12px" }}

                    >
                        <MenuItem disabled >
                            <em>Fixed Income Type</em>
                        </MenuItem>
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"29"}>Fixed Deposit</MenuItem>
                        <MenuItem value={"30"}>Recurring Deposit</MenuItem>
                        <MenuItem value={"31"}>
                            PPF / Provident Fund
                        </MenuItem>
                        <MenuItem value={"32"}>
                            Post Office
                        </MenuItem>
                        <MenuItem value={"18"}>
                            Traded Bonds - Convertible
                        </MenuItem>
                        <MenuItem value={"19"}>
                            Traded Bonds - Non-Convertible
                        </MenuItem>
                        <MenuItem value={"27"}>
                            Non-Traded Bonds - Convertible
                        </MenuItem>
                        <MenuItem value={"28"}>
                            Non-Traded Bonds - Non-Convertible
                        </MenuItem>
                        <MenuItem value={"16"}>
                            Traded Debentures - Convertible
                        </MenuItem>
                        <MenuItem value={"17"}>
                            Traded Debentures - Non-Convertible
                        </MenuItem>
                        <MenuItem value={"25"}>
                            Non-Traded Debentures - Convertible
                        </MenuItem>
                        <MenuItem value={"26"}>
                            Non-Traded Debentures - Non-Convertible
                        </MenuItem>
                    </Select>
                </Stack>
            </Row>
            <Row>
                <Box sx={{ flexGrow: 1 }} mb={2}>
                    <Grid container spacing={2} columns={16}
                        direction="row"
                        justifyContent="end"
                        alignItems="flex-end">
                        <Grid item xs="auto">
                            <SearchBar
                                value={searched}
                                onChange={(searchVal) => { requestSearch(searchVal) }}
                                onCancelSearch={() => cancelSearch()}
                                style={{
                                    // margin: "0 auto",
                                    maxWidth: 250,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Row>
            <Row>
                <Col md="12">
                    <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">
                        {/* {loading ? (
                            <Loader />
                        ) : (
                            <> */}
                        <MaterialTable
                            title=""
                            data={
                                entity === "Family" ? filteredData : filteredEntiryData
                            }
                            columns={columns}

                            parentChildData={(row, rows) =>
                                rows.find((a) => a.id === row.parentId)
                            }
                            isLoading={loading}
                            options={{
                                headerStyle: {
                                    backgroundColor: "#01579b",
                                    color: "#FFF",
                                },
                                exportButton: false,
                                search: false,
                                toolbarButtonAlignment: "left",
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                                emptyRowsWhenPaging: false,
                                rowStyle: (rowData) => {
                                    return {
                                        pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                                        backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                                    };
                                },
                            }}

                            onTreeExpandChange={(row, rows) => {
                                handleIcon(row, rows);
                            }}
                            icons={{
                                DetailPanel: iconsTest,
                            }}
                            components={{
                                Body: (props) => {
                                    let totalData = {
                                        cost: 0,
                                        currentValue: 0,
                                        maturityValue: 0,
                                    }
                                    props.renderData.forEach((rowData) => {
                                        totalData.cost += rowData.cost;
                                        totalData.currentValue += rowData.currentValue;
                                        totalData.maturityValue += rowData.maturityValue;

                                    })
                                    return (
                                        <>
                                            <MTableBody {...props} />
                                            {
                                                script === "All" ?
                                                    <TableFooter style={{ backgroundColor: "#D9E5F2 " }} >
                                                        <TableRow>
                                                            <TableCell ></TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={0} align="left">Total</TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.cost)}</TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.currentValue)}</TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.maturityValue)}</TableCell>
                                                        </TableRow>
                                                    </TableFooter> : null
                                            }
                                        </>
                                    )
                                },
                                Toolbar: (props) => {
                                    let totalData = {
                                        cost: 0,
                                        currentValue: 0,
                                        maturityValue: 0,
                                    }
                                    props.renderData.forEach((rowData) => {
                                        totalData.cost += rowData.cost;
                                        totalData.currentValue += rowData.currentValue;
                                        totalData.maturityValue += rowData.maturityValue;
                                    })
                                    return (

                                        <>
                                            <MTableToolbar {...props} />
                                            {
                                                script !== "All" || script === "All" ?

                                                    <div className="row mb-2 p-2">
                                                        <div className="col-md-2 col-xl-3">
                                                        </div>
                                                        <div className="col-md-2 col-xl-2">
                                                            <div className="card  order-card">
                                                                <div className="card-block">
                                                                    <h3 className="text-right" style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.cost)}</h3>
                                                                    <p className="m-b-0">Invested Amount</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-xl-2">
                                                            <div className="card order-card">
                                                                <div className="card-block">
                                                                    <h3 className="text-right" style={totalData.currentValue < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                                                        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.currentValue)}</span></h3>
                                                                    <p className="m-b-0" >Current Value</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-xl-2">
                                                            <div className="card order-card">
                                                                <div className="card-block">
                                                                    <h3 className="text-right" style={totalData.maturityValue < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                                                        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.maturityValue)}</span></h3>
                                                                    <p className="m-b-0" >Maturity Value</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                        </>
                                    )

                                },
                            }}

                            onRowClick={(evt, selectedRow) => {
                                if (selectedRow.tableData.childRows == null) {
                                    getBuyGetData(
                                        selectedRow.subAccountId,
                                        selectedRow.companyId,
                                        selectedRow.company,
                                        selectedRow.livePrice,
                                        selectedRow.instrumentTypeId,
                                        selectedRow.currentValue,
                                        selectedRow.tableData.path[0]
                                    );
                                }
                            }}

                        />
                        {/* </>
                        )} */}
                    </div>

                    <FixedIncomeDetail
                        showFD={showFD}
                        onHideFD={handleClose}
                        FDTransaction={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        entityId={entityId}
                        showFdpreview={previewFdopen}
                        onRefreshFD={onRefreshFD}
                        onRefreshInnerGrid={onTransactionGridRefresh}
                        tabIndex={0}
                    />
                    <BondsConvertibles
                        showBC={bondscon}
                        onHideBC={handleclose}
                        FDTransactionBC={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FixDID={FixDID}
                        showBondConpreview={previewBondConvertibleOpen}
                    // onRefresh={onTransactionGridRefresh}
                    />
                    <BondsRedeem
                        showBR={bondsredee}
                        onHideBR={handleclose}
                        FDTransactionBR={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FixDID={FixDID}
                        showBondRedpreview={previewBondRedeemableOpen}
                    // onRefresh={onTransactionGridRefresh}
                    />
                    <DebenturesConvertibles
                        showDC={debenturecon}
                        onHideDC={handleclose}
                        FDTransactionDC={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FixDID={FixDID}
                        showDebConpreview={previewDebConvertibleOpen}
                    // onRefresh={onTransactionGridRefresh} 

                    />
                    <DebenturesRedeem
                        showDR={debentureredee}
                        onHideDR={handleclose}
                        FDTransactionDR={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FixDID={FixDID}
                        showDebRedpreview={previewDebRedeemableOpen}
                    // onRefresh={onTransactionGridRefresh} 
                    />
                    <FD_RD_PO_Preview
                        onHideFD_RD_PO={handleClose}
                        FDTransactionFD_RD_PO={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FixDID={FixDID}
                        showFD_RD_PO_Preview={previewNewFD_RD_PO}
                    // onRefresh={onTransactionGridRefresh} 
                    />
                    {/* <Maturity
                        showMaturity={fdMature}
                        FDTransactionFDM={FDTransaction}
                        FDHeaderId={FDHeaderId}
                        FDiD={FixDID}
                    /> */}
                    <Container
                        className="LoginContainer formWraper"
                        component="main"
                        maxWidth="xs"
                        tabIndex={-1}
                    >
                        <Modal
                            isOpen={showInner}
                            size="xl"
                            className="col-12 stockdetailmodalF"
                        >
                            <ModalHeader charCode="Y" toggle={() => handleCloseInner()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                                <h5 className="text-center">Fixed Income Details</h5>
                            </ModalHeader>
                            <ModalBody>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    {loading ? (
                                        <Loader />
                                    ) : null}
                                    { /* family wise data */}
                                    {subId && state === null ?
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <p> Portfolio: {gridDetail.companyName}</p>
                                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right" width="7%">
                                                            Investment Name
                                                        </TableCell>
                                                        {
                                                            instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null :
                                                                <TableCell align="right" width="7%">
                                                                    Broker
                                                                </TableCell>
                                                        }
                                                        <TableCell align="right" width="5%">
                                                            Trans.
                                                        </TableCell>
                                                        {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ?
                                                            <TableCell align="right" width="5%">
                                                                Folio Number
                                                            </TableCell>
                                                            : <>
                                                                <TableCell align="right" width="7%">
                                                                    Live Price
                                                                </TableCell>
                                                            </>}
                                                        <TableCell align="right" width="5%">
                                                            Date
                                                        </TableCell>

                                                        {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? <>
                                                            <TableCell align="right" width="7%">
                                                                Invested Amount
                                                            </TableCell>
                                                            {/* {instTYID == 30 ? null : <> */}
                                                            <TableCell align="right" width="7%">
                                                                Accured Interest
                                                            </TableCell>
                                                            {/* </>} */}
                                                            <TableCell align="right" width="7%">
                                                                Current Value
                                                            </TableCell>
                                                            <TableCell align="right" width="7%">
                                                                Maturity Date
                                                            </TableCell>
                                                            <TableCell align="right" width="7%">
                                                                Maturity Value
                                                            </TableCell>
                                                        </> :
                                                            <>
                                                                <TableCell align="right" width="7%">
                                                                    Quantity
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Price/Share
                                                                </TableCell>
                                                                {/* <TableCell align="right" width="7%">
                                                                    Brokerage/Share
                                                                </TableCell> */}
                                                                <TableCell align="right" width="7%">
                                                                    Total Brokerage
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Amount
                                                                </TableCell>
                                                            </>
                                                        }


                                                        {/* { instTYID == 29 ? <></> : <> */}
                                                        {/* <> */}

                                                        {/* <TableCell align="right" width="7%">
                                                            Current Value
                                                        </TableCell> */}
                                                        {/* </> */}
                                                        {/* </>} */}

                                                        <TableCell align="center" width="7%" style={{ textAlign: "center" }}>
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ overflow: "auto" }}>
                                                    {sell.map((a) => (
                                                        <TableRow
                                                            key={a.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                {a.companyName}
                                                            </TableCell>
                                                            {
                                                                instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null :
                                                                    <TableCell align="right">
                                                                        {a.brokerName}
                                                                    </TableCell>
                                                            }
                                                             <TableCell align="right">
                                                                {a.transactStockType}
                                                            </TableCell>
                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ?
                                                                <TableCell align="right">
                                                                    {a.folioNumber}
                                                                </TableCell>
                                                                :
                                                                <>
                                                                    <TableCell align="right">
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.livePrice ? a.livePrice : "0.00")/*Tanmay-25/01/2022*/}
                                                                    </TableCell>
                                                                </>}
                                                           
                                                            <TableCell align="right">
                                                                {moment(a.date).format("DD/MM/YYYY")}
                                                            </TableCell>

                                                            

                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ? <>
                                                                {/* <TableCell align="right">
                                                                {a.transactStockType == "Converted" && a.active == 0 ? "-" : a.transactStockType == "Redeemed" ? "-" : null}
                                                                {new Intl.NumberFormat('en-IN').format(a.interestCredited)}
                                                            </TableCell> */}
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Matured" ? <>
                                                                        {a.maturityValue == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.maturityValue)}
                                                                    </> : <>
                                                                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                        {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}
                                                                        </>
                                                                        }
                                                                    </>}
                                                                </TableCell>
                                                                {/* {a.instrumentTypeId == 30 ? null : <> */}

                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.investmentAmt == null ? 0 : a.investmentAmt)}
                                                                    </>
                                                                    }
                                                                </TableCell>
                                                                {/* </>} */}
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                        {a.currentValue == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentValue)}
                                                                    </>
                                                                    }
                                                                </TableCell>
                                                            </> : <>
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                    {new Intl.NumberFormat('en-IN').format(a.quantity)}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.pricePerShare == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.pricePerShare)}
                                                                </TableCell>
                                                                {/* <TableCell align="right">
                                                                    {a.brokeragePerShare == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.brokeragePerShare)/*Tanmay-25/01/2022*/}
                                                                {/* </TableCell> */}
                                                                <TableCell align="right">
                                                                    {a.totalBrokerage == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalBrokerage)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                            </>}
                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ? <>
                                                                <TableCell align="right">
                                                                    {moment(a.domConversion).format("DD/MM/YYYY")}
                                                                </TableCell>
                                                            </> : <>
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}
                                                                </TableCell>
                                                            </>}

                                                            {/* <TableCell align="right">
                                                                {a.transactStockType == "Buy"
                                                                    ? // (((gridDetail.livePrice * a.availableQuantity) - a.totalCost)/a.yearDifference).toFixed(2)
                                                                    ((
                                                                        ((a.livePrice * a.availableQuantity - a.closingBalance) /
                                                                            a.closingBalance) *
                                                                        100
                                                                    ) / (a.yearDifference)).toFixed(2)
                                                                    // ((gridDetail.livePrice * a.availableQuantity - a.totalCost)
                                                                    // / (a.yearDifference)).toFixed(2)
                                                                    //   ((gridDetail.livePrice *
                                                                    //     a.availableQuantity -
                                                                    //     a.totalCost) /
                                                                    //     (a.totalCost *
                                                                    //     100) /
                                                                    //   a.yearDifference
                                                                    // ).toFixed(2)
                                                                    : 0}
                                                            </TableCell> */}

                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ? <>
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Matured" ? <>{a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}</> : <>
                                                                        {a.transactStockType === "Buy" && a.active === 0 ? null : <>
                                                                            {a.maturityValue == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.maturityValue)}
                                                                        </>
                                                                        }
                                                                    </>}
                                                                </TableCell>
                                                            </> : <>
                                                                {/* <TableCell align="right" >
                                                                       {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentValue)}
                                                                    </TableCell> */}
                                                            </>}

                                                            {/* <TableCell
                                                                align="right"

                                                            >
                                                                {a.maturityValue ? <>{new Intl.NumberFormat('en-IN').format(a.maturityValue)} </> : 0}

                                                            </TableCell> */}
                                                            <TableCell align="center">
                                                               <Tooltip title="Preview">
                                                                    <IconButton>
                                                                        <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {a.transactStockType === "Buy" && a.active === 0 ? null : a.transactStockType === "Matured" ? null :
                                                                    <>
                                                                        {a.transactStockType === "Converted" || a.transactStockType === "Redeemed" ? null : <>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton>
                                                                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip></>}
                                                                        {
                                                                            a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ?
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton>
                                                                                        <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                                    </IconButton>
                                                                                </Tooltip> : null
                                                                        }
                                                                    </>
                                                                }
                                                               
                                                                {a.transactStockType === "Redeemed" || a.transactStockType === "Converted" ?
                                                                    <Tooltip title="Delete">
                                                                        <IconButton>
                                                                            <Delete fontSize="small" onClick={(e) => handleFidelete(e, a)} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> :
                                        /* entity wise data */
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <p> Portfolio: {subNameEnt}</p>
                                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right" width="10%">
                                                            Investment Name
                                                        </TableCell>
                                                        {
                                                            instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null :
                                                                <TableCell align="right" width="7%">
                                                                    Broker
                                                                </TableCell>
                                                        }
                                                        <TableCell align="right" width="6%">
                                                            Trans.
                                                        </TableCell>
                                                        {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ?
                                                            <TableCell align="right" width="6%">
                                                                Folio Number
                                                            </TableCell>
                                                            : <>
                                                                <TableCell align="right" width="6%">
                                                                    Live Price
                                                                </TableCell>
                                                            </>}
                                                        <TableCell align="right" width="7%">
                                                            Date
                                                        </TableCell>
                                                        {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? <>
                                                            {/* <TableCell align="right" width="7%">
                                                            Amount Invested
                                                        </TableCell> */}
                                                            <TableCell align="right" width="7%">
                                                                Invested Amount
                                                            </TableCell>
                                                            {/* {instTYID == 30 ? null : <> */}
                                                            <TableCell align="right" width="7%">
                                                                Accured Interest
                                                            </TableCell>
                                                            {/* </>} */}
                                                            <TableCell align="right" width="7%">
                                                                Current Value
                                                            </TableCell>
                                                            <TableCell align="right" width="7%">
                                                                Maturity Date
                                                            </TableCell>
                                                            <TableCell align="right" width="7%">
                                                                Maturity Value
                                                            </TableCell>
                                                        </>
                                                            : <>
                                                                <TableCell align="right" width="6%">
                                                                    Quantity
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Price/Share
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Brokerage/Share
                                                                </TableCell>

                                                                <TableCell align="right" width="7%">
                                                                    Total Brokerage
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Amount
                                                                </TableCell>
                                                            </>}


                                                        <TableCell align="center" width="7%">
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ overflow: "auto" }}>
                                                    {   showLoader === false ? <Loader /> :
                                                        entitySell.map((a) => (
                                                        <TableRow
                                                            key={a.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                {gridDetail.companyName}
                                                            </TableCell>
                                                            {
                                                                instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null :
                                                                    <TableCell align="right">
                                                                        {a.brokerName}
                                                                    </TableCell>
                                                            }
                                                             <TableCell align="right">
                                                                {a.transactStockType}
                                                            </TableCell>
                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ?
                                                                <TableCell align="right">
                                                                    {a.folioNumber}
                                                                </TableCell>
                                                                : <>
                                                                    <TableCell align="right">
                                                                        {gridDetail.livePrice ? gridDetail.livePrice : "0.00"}
                                                                    </TableCell></>}

                                                            <TableCell align="right">
                                                                {moment(a.date).format("DD/MM/YYYY")}
                                                            </TableCell>

                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ? <>
                                                                {/* <TableCell align="right">
                                                                {a.transactStockType == "Converted" && a.active == 0 ? "-" : a.transactStockType == "Redeemed" ? "-" : null}
                                                                {new Intl.NumberFormat('en-IN').format(a.interestCredited)}
                                                            </TableCell> */}
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Matured" ? <>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.maturityValue)}</> : <>
                                                                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                        {a.transactStockType === "Buy" && a.active === 0 ? null : <>
                                                                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}
                                                                        </>
                                                                        }
                                                                    </>}
                                                                </TableCell>
                                                                {/* {a.instrumentTypeId == 30 ? null : <> */}

                                                                {/* <TableCell align="right">
                                                                        {a.transactStockType == "Matured" ? "-" : null}
                                                                        {a.interestReinvested ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.interestReinvested) : 0}

                                                                    </TableCell> */}
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.investmentAmt == null ? 0 : a.investmentAmt)}
                                                                    </>
                                                                    }
                                                                </TableCell>
                                                                {/* </>} */}
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentValue)}
                                                                    </>
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.domConversion ? moment(a.domConversion).format("DD/MM/YYYY") : "-"}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Matured" ? <>
                                                                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Matured" ? "-" : null}
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}</> : <>
                                                                        {a.transactStockType === "Buy" && a.active === 0 ? "0" : <>
                                                                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.maturityValue)}
                                                                        </>
                                                                        }
                                                                    </>}
                                                                </TableCell>
                                                            </> : <>
                                                                <TableCell align="right">
                                                                    {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                    {new Intl.NumberFormat('en-IN').format(a.quantity)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.pricePerShare == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.pricePerShare)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.brokeragePerShare == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.brokeragePerShare)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {a.totalBrokerage == null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalBrokerage)/*Tanmay-25/01/2022*/}
                                                                </TableCell></>
                                                            }

                                                            {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ? null : <> <TableCell align="right">
                                                                {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : null}
                                                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)/*Tanmay-25/01/2022*/}
                                                            </TableCell></>}

                                                            <TableCell align="center">
                                                                <Tooltip title="Preview">
                                                                    <IconButton>
                                                                        <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {a.transactStockType === "Buy" && a.active === 0 ? null : a.transactStockType === "Matured" ? null :
                                                                    <>
                                                                        {a.transactStockType === "Converted" || a.transactStockType === "Redeemed" ? null : <>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton>
                                                                                    <Edit fontSize="small" toolTip="Edit" onClick={(e) => alertMyRow(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip></>}
                                                                        {a.instrumentTypeId === 29 || a.instrumentTypeId === 30 || a.instrumentTypeId === 31 || a.instrumentTypeId === 32 ?
                                                                            <Tooltip title="Delete">
                                                                                <IconButton>
                                                                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip> : null
                                                                        }

                                                                    </>
                                                                }
                                                                
                                                                {a.transactStockType === "Redeemed" || a.transactStockType === "Converted" ?
                                                                    <Tooltip title="Delete">
                                                                        <IconButton>
                                                                            <Delete fontSize="small" onClick={(e) => handleFidelete(e, a)} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    : null}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}


                                                    <TableRow
                                                        // key={row.name}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        <TableCell align="left" className="headerbgcolor">
                                                            Closing Balance
                                                        </TableCell>

                                                        <TableCell
                                                            align="right"
                                                            className="headerbgcolor"
                                                        ></TableCell>
        
                                                        <TableCell
                                                            align="right"
                                                            className="headerbgcolor"
                                                        ></TableCell>
                                                        {/* <TableCell
                                                            align="right"
                                                            className="headerbgcolor"
                                                            colSpan="3"
                                                        >
                                                            {new Intl.NumberFormat('en-IN').format(gridDetail.finalQuantity)}
                                                        </TableCell> */}
                                                        {/* <TableCell
                                                            align="right"
                                                            className="headerbgcolor"
                                                        ></TableCell> */}
                                                        {
                                                            instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ?
                                                                <>
                                                                    <TableCell
                                                                        align="right"
                                                                        colSpan="2"
                                                                        className="headerbgcolor"
                                                                    >
                                                                        {/* active */}
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.finalCost)}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        colSpan="6"
                                                                        className="headerbgcolor"
                                                                    ></TableCell></>
                                                                :
                                                                <>
                                                                 <TableCell
                                                                 align="right"
                                                                 className="headerbgcolor"
                                                                 ></TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    colSpan="2"
                                                                    className="headerbgcolor"
                                                                >
                                                                {/* active */}
                                                                    {new Intl.NumberFormat('en-IN').format(gridDetail.finalQuantity)}
                                                                </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        colSpan="4"
                                                                        className="headerbgcolor"
                                                                    >
                                                                        {/* active */}

                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.finalCost)}
                                                                    </TableCell>
                                                                </>


                                                        }

                                                        {instTYID === 29 || instTYID=== 30 || instTYID === 31 || instTYID === 32 ? null : <>
                                                            <TableCell align="right" className="headerbgcolor"></TableCell>
                                                        </>}

                                                    </TableRow>

                                                    <TableRow
                                                        // key={row.name}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >




                                                        {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null
                                                            : <>
                                                                <TableCell align="left">
                                                                    Current Price: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.livePrice)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                                <TableCell
                                                                 align="right"
                                                                 ></TableCell>
                                                                {instTYID === 29 || instTYID === 30 || instTYID === 31 || instTYID === 32 ? null : <> <TableCell></TableCell> </>}
                                                                <TableCell align="right" colSpan="9" >
                                                                    Current Value: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.finalQuantity * gridDetail.livePrice)/*Tanmay-25/01/2022*/}
                                                                </TableCell>
                                                            </>}


                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Paper>
                            </ModalBody>
                        </Modal>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default FixedIncomes;
