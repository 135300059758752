import React from 'react'
import { Container } from 'react-bootstrap'
import ThankPayment from '../../components/Subscriptions/ThankPayment'

const ThankyouPayment = () =>{
    return(
        <Container className='pt-3'>
            <ThankPayment/>
        </Container>
    )
}

export default ThankyouPayment;