/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import $ from "jquery";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";

import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip'

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TableCell,
    TableContainer,
    IconButton,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableFooter,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { Edit, Visibility, Delete } from "@material-ui/icons";
import Loader from '../loadingAnimation/index'
import Swal from 'sweetalert2';
import Auth from '../../modules/Auth'
import {
    EntityList, OuterGridFamilyList, OuterGridEntityList, InnerGridFamilyList, InnerGridEnityList, InsuranceTransaction, deleteInsurance
} from "../../pages/Insurance/InsuranceAPI";
import InsuranceDetails from '../InsuranceDetails/InsuranceDetails';
import InsurancePreview from "../InsuranceDetails/InsurancePreview";
import { GlobalInfo } from '../Content/Content';

import SearchBar from "material-ui-search-bar";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from "react-router-dom";

const InsuranceFolio = () => {
    const userID = Auth.getsubcriberId();
    const { closeInsmodal } = useContext(GlobalInfo);
    const [loading, setLoading] = useState(false);
    const [showInsuranceDetails, setshowInsuranceDetails] = useState(false)
    const [showInnerGrid, setshowInnerGrid] = useState(false)

    const [script, setScript] = useState("All");
    const [entity, setEntity] = useState("Family");
    const [iconsTest, setIconTest] = useState(AddIcon);
    const [entityRadio, setEntityRadio] = useState([]);
    const [entityId, setEntityId] = useState(null);

    const [InsuranceFamilyData, setInsuranceFamilyData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [InsuranceEntityData, setInsuranceEntityData] = useState([])
    const [filteredEntityData, setFilteredEntiryData] = useState([]);

    const [familyInnerGrid, setFamilyInnerGrid] = useState([])
    const [entityInnerGrid, setEntityInnergrid] = useState([])

    const [subID, setsubID] = useState(null);
    const [policy_ID, setpolicy_ID] = useState(null);
    const [policy_sub_ID, setpolicy_sub_ID] = useState(null);
    const [companyID, setcompanyID] = useState(null);

    const [insuranceTransaction, setInsuranceTransaction] = useState([]);
    const [insurance_ID, setinsurance_ID] = useState(0);
    const [insurancePreview, setinsurancePreview] = useState(false);
    const [instPreviewData, setInstPreviewData] = useState([]);

    const [searched, setSearched] = useState("");
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        if (state) {
            let policy_Type_Id = state.policy_Type_Id;
            let policy_SubType_Id = state.policy_SubType_Id;
            let subAccountId = state.subAccountId;
            let companyId = state.companyId;
            getBuyGetData(subAccountId, policy_Type_Id, policy_SubType_Id, companyId);
        }
    }, [state])


    useEffect(() => {
        closeallmodalformaturity()
    }, [closeInsmodal]);

    const closeallmodalformaturity = async () => {
        setshowInsuranceDetails(false);
        setshowInnerGrid(false);
        // setEntity("Family")
        getInsuranceFamilyData();
    }

    const columns = [
        { title: "Family Member Name", field: "subAccountName" },
        {
            title: "Sum Assured", field: "sumAssured", type: "numeric",
            render: (rowData) => (
                <span>
                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.sumAssured)}
                </span>
            ),


        },
        {
            title: "Annual Premium", field: "annual_Premium", type: "numeric",
            render: (rowData) => (
                <span>
                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.annual_Premium)}
                </span>
            ),
        },
        {
            title: "Current Value / Surrender Value", field: "surrender_Value", type: "numeric",
            render: (rowData) => (
                <span>
                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.surrender_Value)}
                </span>
            ),
        },

    ]

    useEffect(
        async () => {
            getInsuranceFamilyData();

            if (userID) {

                getEntityList();
            }
        }, [userID]);

    //getEntityList
    const getEntityList = () => {

        setLoading(true)
        EntityList()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let SubscriberName = res.data._response.data.subaccountDetails;
                        setEntityRadio(SubscriberName);
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false)
                // setError(e.message);
            });
    };

    // get all family grid data
    const getInsuranceFamilyData = () => {
        OuterGridFamilyList().then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    setLoading(false);
                    let insuranceData = res.data._response.data.insuranceFamilyOuterGrid;

                    setInsuranceFamilyData(insuranceData);
                    GetFamilyGridData(insuranceData);
                    $(".MuiTableRow-root").each(function () {
                        let val = $('.material-icons ', this).text()
                        if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                    });
                } else {
                    setInsuranceFamilyData([])
                    setFilteredData([])
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        })
            .catch((e) => {
                setLoading(false);
                console.log(e.message);
            });
    };

    //expandable family data
    const GetFamilyGridData = (insuranceData) => {
        let check = [];
        insuranceData.forEach((item, i) => {
            if (check.indexOf(item.subAccountName) === -1) {
                check.push(item.subAccountName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                sumAssured: 0,
                annual_Premium: 0,
                surrender_Value: 0,

            };

            insuranceData.forEach((item, i) => {
                if (checkdata === item.subAccountName) {
                    let sumAssured = mainData.sumAssured + item.sumAssured;
                    let annual_Premium =
                        mainData.annual_Premium + item.annual_Premium;
                    let surrender_Value = mainData.surrender_Value + item.surrender_Value;


                    mainData = {
                        sumAssured: sumAssured,
                        annual_Premium: annual_Premium,
                        surrender_Value: surrender_Value,
                        subAccountName: item.subAccountName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                        parentId: index,
                        id: index + 1,

                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredData([...parentData, ...childData]);
    }

    //get all entity grid data
    const getInsuranceEntityData = (id) => {
        setLoading(true);
        OuterGridEntityList(id).then((res) => {

            if (res.status === 200) {
                if (res.data._response.status === 1) {

                    //+ button code
                    $(".MuiTableRow-root").each(function () {
                        let val = $('.material-icons ', this).text()
                        if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                    });

                    let insuranceEntityData = res.data._response.data.insuranceIndividualOuterGrid;
                    setInsuranceEntityData(insuranceEntityData);
                    setScript("All")
                    GetEntityGridData(insuranceEntityData)
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    setFilteredEntiryData([])
                }
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    //expandable enity data
    const GetEntityGridData = (insuranceEntityData) => {
        let check = [];
        insuranceEntityData.forEach((item, i) => {
            if (check.indexOf(item.subAccountName) === -1) {
                check.push(item.subAccountName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                sumAssured: 0,
                annual_Premium: 0,
                surrender_Value: 0,
            };

            insuranceEntityData.forEach((item, i) => {
                if (checkdata === item.subAccountName) {
                    let sumAssured = mainData.sumAssured + item.sumAssured;
                    let annual_Premium =
                        mainData.annual_Premium + item.annual_Premium;
                    let surrender_Value = mainData.surrender_Value + item.surrender_Value;

                    mainData = {
                        sumAssured: sumAssured,
                        annual_Premium: annual_Premium,
                        surrender_Value: surrender_Value,
                        subAccountName: item.subAccountName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        ...item,
                        subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                        parentId: index,
                        id: index + 1,
                    };

                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredEntiryData([...parentData, ...childData]);
    }

    //open insurance details model
    const openTransaction = () => {
        setInsuranceTransaction([])
        setshowInsuranceDetails(true);
        setinsurance_ID(0);
    };

    //close insurance details
    const handleClose = async (val) => {
        setInsuranceTransaction([])
        setinsurancePreview(false)
        setshowInsuranceDetails(false);
        setinsurance_ID(0);
        if (val !== 1) {
            await refreshInsurance();
            onTransactionGridRefresh()
        }
    }

    //close innergrid
    const handleCloseModel = () => {
        setshowInnerGrid(false);
        handleClear()
        setinsurance_ID(0)
        refreshInsurance()
    }

    const handleChangeEntity = async (e) => {
        setLoading(true)
        await setEntity(e.target.value);
        if (e.target.value === "Family") {
            setEntityId(null);
            getInsuranceFamilyData();
            setScript("All");

            //+ button code
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                let button = $("button", this);
                $(button).html("<span class='material-icons'>add</span>");
            });
        } else {
            let data = _.find(entityRadio, function (o) {
                return o.subAccountName == e.target.value;
            });
            // setSubNameEnt(e.target.value)
            await setEntityId(data.subAccountId);

            // await setSubIdApi(data.subAccountId);
            await getInsuranceEntityData(data.subAccountId)
        }
    }

    // + button code
    const PlusButton = () => {
        $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
        });
    }

    const handleScripData = (e) => {
        let selected = e.target.value;
        setScript(selected);
        //family data filter
        if (entity === "Family") {
            let sorted = selected === "All" ? InsuranceFamilyData : InsuranceFamilyData.filter((a) => (a.transactStockType === selected))
            let check = []
            sorted.forEach((item, i) => {
                if (check.indexOf(item.subAccountName) === -1) {
                    check.push(item.subAccountName);
                }
            });
            let parentData = [];
            let childData = [];
            check.forEach((checkdata, index) => {
                let mainData = {
                    sumAssured: 0,
                    annual_Premium: 0,
                    surrender_Value: 0,
                };
                sorted.forEach((item, i) => {
                    if (checkdata === item.subAccountName) {
                        let sumAssured = mainData.sumAssured + item.sumAssured;
                        let annual_Premium =
                            mainData.annual_Premium + item.annual_Premium;
                        let surrender_Value = mainData.surrender_Value + item.surrender_Value;
                        mainData = {
                            sumAssured: sumAssured,
                            annual_Premium: annual_Premium,
                            surrender_Value: surrender_Value,
                            subAccountName: item.subAccountName,
                            id: index,
                        };

                        let normalData = {
                            ...item,
                            subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                            parentId: index,
                            id: index + 1,

                        }
                        childData.push(normalData);
                    }
                });
                parentData.push(mainData);
                PlusButton()
            });
            setFilteredData([...parentData, ...childData]);
        } else {
            //entity data filter
            let sortedEntity = selected === "All" ? InsuranceEntityData : InsuranceEntityData.filter((a) => (a.trasactStockType === selected))
            let checkEntity = [];
            sortedEntity.forEach((item, i) => {
                if (checkEntity.indexOf(item.subAccountName) === -1) {
                    checkEntity.push(item.subAccountName);
                }
            });
            let parentData = [];
            let childData = [];
            checkEntity.forEach((checkdata, index) => {
                let mainData = {
                    sumAssured: 0,
                    annual_Premium: 0,
                    surrender_Value: 0,
                }
                sortedEntity.forEach((item, i) => {
                    if (checkdata === item.subAccountName) {
                        let sumAssured = mainData.sumAssured + item.sumAssured;
                        let annual_Premium =
                            mainData.annual_Premium + item.annual_Premium;
                        let surrender_Value = mainData.surrender_Value + item.surrender_Value;

                        mainData = {
                            sumAssured: sumAssured,
                            annual_Premium: annual_Premium,
                            surrender_Value: surrender_Value,
                            subAccountName: item.subAccountName,
                            id: index,

                        }

                        let normalData = {
                            ...item,
                            ...item,
                            subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                            parentId: index,
                            id: index + 1,

                        };
                        childData.push(normalData);
                    }
                });
                parentData.push(mainData);
            });
            setFilteredEntiryData([...parentData, ...childData]);
            PlusButton()
        }
        setScript(selected)

    }

    //details pannel icon parent child
    const handleIcon = (row, rows) => {
        let index = row.tableData.path[0];
        if (rows) {
            // setIconTest(RemoveIcon);
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>maximize</span>");
                    $(button).css({
                        transform: "rotate(180deg)",
                    });
                }
            });
        } else {
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>add</span>");
                }
            });
        }
    };

    //handleReset for getbuysell params
    const handleClear = () => {
        setsubID(null);
        setpolicy_ID(null);
        setpolicy_sub_ID(null);
        setcompanyID(null);
    }

    //innerGrid data 
    const getBuyGetData = async (
        subAccountId,
        policy_Type_Id,
        policy_SubType_Id,
        companyId,
    ) => {

        setsubID(subAccountId);
        setpolicy_ID(policy_Type_Id);
        setpolicy_sub_ID(policy_SubType_Id);
        setcompanyID(companyId)
        setLoading(true);

        if (companyId === undefined && state === null) {

            await InnerGridFamilyList(policy_Type_Id, policy_SubType_Id, subAccountId)
                .then((res) => {

                    if (res.data._response.status === 1) {
                        let innerFamilyData = res.data._response.data.fetchInsuranceEntityInfo;
                        setFamilyInnerGrid(innerFamilyData)
                        setLoading(false);

                        setshowInnerGrid(true)

                        //+/- code
                        var arr = [];
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            let a = /[,\/]/g;
                            let b = a.test(path)
                            if (b) {
                                let c = path.charAt(0)
                                if (!arr.includes(c)) {
                                    arr.push(c)
                                }
                            }
                        });
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            if (arr.includes(path)) {
                                let button = $("button", this);
                                $(button).html("<span class='material-icons'>maximize</span>");
                                $(button).css({
                                    transform: "rotate(180deg)",
                                });
                            }
                        });
                    } else {
                        setFamilyInnerGrid([]);
                        // setGridDetail({});
                        setLoading(false);
                    }

                }).catch((e) => {
                    console.log(e.message);
                    setLoading(false);
                })

        } else {

            await InnerGridEnityList(policy_Type_Id, policy_SubType_Id, subAccountId, companyId)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        let innerEntityData = res.data._response.data.fetchInsuranceIndividualEntityInfo;
                        setEntityInnergrid(innerEntityData)
                        setLoading(false);
                        setshowInnerGrid(true)
                    } else {
                        setEntityInnergrid([])
                        setLoading(false);
                    }


                    setLoading(false);
                }).catch((e) => {
                    console.log(e.message);
                    setLoading(false);
                })
        }
    }

    //handle edit button
    const alertMyRow = (selectedRow, a) => {

        setLoading(true);
        InsuranceTransaction(a.insuranceId).then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {

                    setinsurance_ID(a.insuranceId)
                    const result = res.data._response.data.insuranceTransactionDetails;
                    setInsuranceTransaction(result)
                    setLoading(false)
                    setshowInsuranceDetails(true)
                } else {
                    setLoading(false)

                }

            }

        }).catch((e) => {
            console.log(e.message);
            setLoading(false)

        })

    }

    //preview modal
    const handlePreview = (selectedRow, a) => {
        setLoading(true);
        InsuranceTransaction(a.insuranceId).then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {

                    setinsurance_ID(a.insuranceId)
                    const result = res.data._response.data.insuranceTransactionDetails;
                    setInstPreviewData(result)
                    setLoading(false)
                    setinsurancePreview(true)
                } else {
                    setLoading(false)

                }

            }

        }).catch((e) => {
            console.log(e.message);
            setLoading(false)

        })
    }


    //delete transaction
    const handleDelete = (selectedRow, a) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            confirmButtonText: 'Yes, delete it!',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            buttons: true,
            dangerMode: true,
        })

            .then((value) => {
                if (value.isConfirmed) {
                    setLoading(true);
                    deleteInsurance(a.insuranceId).then((res) => {
                        if (res.data._response.status === 1) {
                            setLoading(false);
                            Swal.fire({
                                title: "Deleted",
                                text: res.data._response.message,
                                icon: "success",
                            });
                            const del = familyInnerGrid.filter(sells => a.insuranceId !== sells.insuranceId)
                            setFamilyInnerGrid(del);
                            const delEntity = entityInnerGrid.filter(Esells => a.insuranceId !== Esells.insuranceId)
                            setEntityInnergrid(delEntity)


                        }
                        else {
                            setLoading(false);
                            Swal.fire({
                                title: "Ooops!!",
                                text: "Something Wrong",
                                icon: "warning",
                            })
                        }
                    })
                        .catch(err => {
                            console.log(err);
                            setLoading(false);
                        })
                } else {
                    Swal.fire("Cancelled", "Your  data is safe ", "error");
                }
            });
    }

    //refresh outergrid
    const refreshInsurance = async () => {
        await setLoading(false);
        await getInsuranceFamilyData();
        if (entityId) await getInsuranceEntityData(entityId);
    }

    //refresh inner grid
    const onTransactionGridRefresh = () => {


        getBuyGetData(
            subID,
            policy_ID,
            policy_sub_ID,
            companyID
        );
    }

    // function for 1st captilized letter
    // const UpperFirst1 = (s) => {
    //     if (typeof s !== 'string') return ''
    //     return s.charAt(0).toUpperCase() + s.slice(1)
    // }

    function UpperFirst(str) {
        var pieces = str.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            var j = pieces[i].charAt(0).toUpperCase();
            pieces[i] = j + pieces[i].substr(1);
        }
        return pieces.join(" ");
    }

    // search function
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal)
        const filteredRows = InsuranceFamilyData.filter((row) => {
            return row.policy_TypeName.includes(searchedVal.toUpperCase()) || row.policy_TypeName.includes(searchedVal.toLowerCase()) || row.policy_TypeName.includes(UpperFirst(searchedVal));
        });


        //family wise
        let check = [];
        filteredRows.forEach((item, i) => {
            if (check.indexOf(item.subAccountName) === -1) {
                check.push(item.subAccountName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                sumAssured: 0,
                annual_Premium: 0,
                surrender_Value: 0,

            };

            filteredRows.forEach((item, i) => {
                if (checkdata === item.subAccountName) {
                    let sumAssured = mainData.sumAssured + item.sumAssured;
                    let annual_Premium =
                        mainData.annual_Premium + item.annual_Premium;
                    let surrender_Value = mainData.surrender_Value + item.surrender_Value;


                    mainData = {
                        sumAssured: sumAssured,
                        annual_Premium: annual_Premium,
                        surrender_Value: surrender_Value,
                        subAccountName: item.subAccountName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                        parentId: index,
                        id: index + 1,

                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });
        setFilteredData([...parentData, ...childData]);

        // entitywise
        const forentity = filteredRows.filter(a => a.subAccountId === entityId)

        let checkEntity = [];
        forentity.forEach((item, i) => {
            if (checkEntity.indexOf(item.subAccountName) === -1) {
                checkEntity.push(item.subAccountName);
            }
        });
        let parentDataEntity = [];
        let childDataEntity = [];
        checkEntity.forEach((checkdata, index) => {
            let mainData = {
                sumAssured: 0,
                annual_Premium: 0,
                surrender_Value: 0,
            };

            forentity.forEach((item, i) => {
                if (checkdata === item.subAccountName) {
                    let sumAssured = mainData.sumAssured + item.sumAssured;
                    let annual_Premium =
                        mainData.annual_Premium + item.annual_Premium;
                    let surrender_Value = mainData.surrender_Value + item.surrender_Value;

                    mainData = {
                        sumAssured: sumAssured,
                        annual_Premium: annual_Premium,
                        surrender_Value: surrender_Value,
                        subAccountName: item.subAccountName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        ...item,
                        subAccountName: item.policy_TypeName + "-" + item.policy_SubTypeName,
                        parentId: index,
                        id: index + 1,
                    };

                    childDataEntity.push(normalData);
                }
            });
            parentDataEntity.push(mainData);
        });
        setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);

    };

    // cancle search
    const cancelSearch = () => {
        setSearched("");
        // requestSearch(searched);
        if (entityId) {
            getInsuranceEntityData(entityId)
        } else {
            getInsuranceFamilyData()
        }
    };

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="text-center py-3">Insurance Folio</h4>
                    </Col>
                </Row>

                <FormControl className="mt-4">

                    <RadioGroup
                        row
                        color="primary"
                        defaultValue="start"
                        aria-label="Entity"
                        name="row-radio-buttons-group"
                        value={entity}
                        onChange={handleChangeEntity}
                        style={{ marginTop: "-3rem" }}
                    >
                        <FormControlLabel
                            value="Family"
                            control={<Radio />}
                            label="Family"
                        />
                        {entityRadio.map((a) => (
                            <FormControlLabel
                                key={a.subAccountName}
                                value={a.subAccountName}
                                control={<Radio />}
                                label={a.subAccountName}
                                disabled={a.active === 0 ? true : false}
                            />
                        ))}

                    </RadioGroup>
                </FormControl>

                <Row>
                    <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="flex-end"
                        alignItems="flex-start" mb={3} >
                        {entityId === null ? null :
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => openTransaction()}
                                    style={{ height: "2rem" }}
                                >
                                    Transactions
                                </Button>
                            </>

                        }

                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={script}
                            label="Type"
                            onChange={handleScripData}
                            style={{ minWidth: 130, paddingLeft: "6px" }}

                        >
                            <MenuItem disabled >
                                <em>Insurance Type</em>
                            </MenuItem>
                            <MenuItem value={"All"}>All</MenuItem>
                            {/* <MenuItem value={"Insurance"}>Insurance</MenuItem>
                            <MenuItem value={"ULIP"}>ULIP</MenuItem> */}

                        </Select>
                    </Stack>
                </Row>
                <Row>
                    <Box sx={{ flexGrow: 1 }} mb={2}>
                        <Grid container spacing={2} columns={16}
                            direction="row"
                            justifyContent="end"
                            alignItems="flex-end">
                            <Grid item xs="auto">
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => { requestSearch(searchVal) }}
                                    onCancelSearch={() => cancelSearch()}
                                    style={{
                                        // margin: "0 auto",
                                        maxWidth: 250,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Row>
                <Row>
                    <Col md="12">
                        <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">

                            <MaterialTable
                                title=""
                                data={
                                    entity === "Family" ? filteredData : filteredEntityData
                                }
                                columns={columns}

                                parentChildData={(row, rows) =>
                                    rows.find((a) => a.id === row.parentId)
                                }
                                onTreeExpandChange={(row, rows) => {
                                    handleIcon(row, rows);
                                }}
                                icons={{
                                    DetailPanel: iconsTest,
                                }}
                                options={{
                                    headerStyle: {
                                        backgroundColor: "#01579b",
                                        color: "#FFF",
                                    },
                                    exportButton: false,
                                    search: false,
                                    toolbarButtonAlignment: "left",
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50],
                                    emptyRowsWhenPaging: false,
                                    rowStyle: (rowData) => {
                                        return {
                                            pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                                            backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                                        };
                                    },

                                }}
                                isLoading={loading}
                                components={{
                                    Body: (props) => {
                                        let totalData = {
                                            sumAssured: 0,
                                            annual_Premium: 0,
                                            surrender_Value: 0,
                                        }
                                        props.renderData.forEach((rowData) => {
                                            totalData.sumAssured += rowData.sumAssured;
                                            totalData.annual_Premium += rowData.annual_Premium;
                                            totalData.surrender_Value += rowData.surrender_Value;
                                        })
                                        return (
                                            <>
                                                <MTableBody {...props} />
                                                {
                                                    script === "All" ?
                                                        <TableFooter style={{ backgroundColor: "#D9E5F2 " }} >
                                                            <TableRow>
                                                                <TableCell ></TableCell>
                                                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={0} align="left">Total</TableCell>
                                                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.sumAssured)}</TableCell>
                                                                <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.annual_Premium)}</TableCell>
                                                                <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.surrender_Value)}</TableCell>
                                                            </TableRow>
                                                        </TableFooter> : null
                                                }
                                            </>
                                        )
                                    },
                                    Toolbar: (props) => {
                                        let totalData = {
                                            sumAssured: 0,
                                            annual_Premium: 0,
                                            surrender_Value: 0,
                                        }
                                        props.renderData.forEach((rowData) => {
                                            totalData.sumAssured += rowData.sumAssured;
                                            totalData.annual_Premium += rowData.annual_Premium;
                                            totalData.surrender_Value += rowData.surrender_Value;
                                        })
                                        return (

                                            <>
                                                <MTableToolbar {...props} />
                                                {
                                                    script !== "All" || script === "All" ?

                                                        <div className="row mb-2 p-2">
                                                            <div className="col-md-2 col-xl-3">
                                                            </div>
                                                            <div className="col-md-2 col-xl-">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.sumAssured)}</h3>
                                                                        <p className="m-b-0">Sum Assured</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.annual_Premium < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                                                            <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.annual_Premium)}</span></h3>
                                                                        <p className="m-b-0" >Annual Premium</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.surrender_Value < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                                                            <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.surrender_Value)}</span></h3>
                                                                        <p className="m-b-0" >Current Value</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}

                                            </>
                                        )

                                    },
                                }}
                                onRowClick={(evt, selectedRow) => {

                                    if (selectedRow.tableData.childRows == null) {
                                        getBuyGetData(
                                            selectedRow.subAccountId,
                                            selectedRow.policy_Type_Id,
                                            selectedRow.policy_SubType_Id,
                                            selectedRow.companyId,
                                            selectedRow.tableData.path[0]
                                        );
                                    }
                                }}
                            />
                        </div>

                        <InsuranceDetails
                            showInsurance={showInsuranceDetails}
                            onHideInsurance={handleClose}
                            insuranceTransaction={insuranceTransaction}
                            entityId={entityId}
                            insuranceId={insurance_ID}
                            onInsTransactionGridRefresh={onTransactionGridRefresh}
                        />

                        <InsurancePreview
                            showInsPrev={insurancePreview}
                            onHideInsPrev={handleClose}
                            insurancePrvTransaction={instPreviewData}
                            entityId={entityId}
                            insuranceId={insurance_ID}
                        />


                        <Container
                            className="LoginContainer formWraper"
                            component="main"
                            maxWidth="xs"
                            tabIndex={-1}
                        >
                            <Modal
                                isOpen={showInnerGrid}

                                toggle={() => handleCloseModel()}
                                size="xl"
                                className="col-12 stockdetailmodalI"
                            >
                                <ModalHeader charCode="Y" toggle={() => handleCloseModel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                                    <h5 className="text-center">Insurance Detail</h5>
                                </ModalHeader>
                                <ModalBody>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        {loading ? (
                                            <Loader />
                                        ) : null}
                                        {/* family wise  */}
                                        {
                                            !companyID && state === null ?
                                                <TableContainer sx={{ maxHeight: 440 }}>
                                                    {/* <p> portfolio: {gridDetail.companyName}</p> */}
                                                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="right" width="14%">
                                                                    Company Name
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Frequency
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Policy Number
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Sum Assured
                                                                </TableCell>                                                 
                                                                <TableCell align="right" width="7%">
                                                                    Annual Premium
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Current Value
                                                                </TableCell>

                                                                <TableCell align="right" width="5%">
                                                                    Maturity Date
                                                                </TableCell>

                                                                <TableCell align="center" width="6%" style={{ textAlign: "center" }}>
                                                                    Actions
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody style={{ overflow: "auto" }}>
                                                            {familyInnerGrid.map((a) => (
                                                                <TableRow
                                                                    key={a.id}
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th": {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <TableCell align="right" width="14%">
                                                                        {a.policy_type_name + " - " + a.policy_subType_name + " - " + a.companyName}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="5%">
                                                                        {a.frequencyName}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="5%">
                                                                        {a.policyNumber}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">

                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.sumAssured)}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">

                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.annualPremium)}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.surrenderValue)}
                                                                    </TableCell>

                                                                    <TableCell align="right" width="5%">

                                                                        {a.maturityDate ? moment(a.maturityDate).format("DD/MM/YYYY") : "-"}
                                                                    </TableCell>

                                                                    <TableCell align="center" width="6%">
                                                                        <Tooltip title="Preview">
                                                                            <IconButton>
                                                                                <Visibility fontSize="small" onClick={(e) => handlePreview(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Edit">
                                                                            <IconButton>
                                                                                <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete">
                                                                            <IconButton>
                                                                                <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer> :

                                                <TableContainer sx={{ maxHeight: 440 }}>
                                                    {/* <p> portfolio: {gridDetail.companyName}</p> */}
                                                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="right" width="14%">
                                                                    Company Name
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Frequency
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Policy Number
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Sum Assured
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Annual Premium
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Current Value
                                                                </TableCell>

                                                                <TableCell align="right" width="5%">
                                                                    Maturity Date
                                                                </TableCell>

                                                                <TableCell align="center" width="6%">
                                                                    Actions
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody style={{ overflow: "auto" }}>
                                                            {entityInnerGrid.map((a) => (
                                                                <TableRow
                                                                    key={a.id}
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th": {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <TableCell align="right" width="14%">
                                                                        {a.policy_type_name + " - " + a.policy_subType_name + " - " + a.companyName}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="5%">
                                                                        {a.frequencyName}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="5%">
                                                                        {a.policyNumber}
                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">

                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.sumAssured)}

                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.annualPremium)}

                                                                    </TableCell>
                                                                    <TableCell align="right" width="7%">
                                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.surrenderValue)}

                                                                    </TableCell>

                                                                    <TableCell align="right" width="5%">

                                                                        {a.maturityDate ? moment(a.maturityDate).format("DD/MM/YYYY") : "-"}
                                                                    </TableCell>

                                                                    <TableCell align="center" width="6%">
                                                                        <Tooltip title="Preview">
                                                                            <IconButton>
                                                                                <Visibility fontSize="small" onClick={(e) => handlePreview(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Edit">
                                                                            <IconButton>
                                                                                <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete">
                                                                            <IconButton>
                                                                                <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                        }

                                    </Paper>
                                </ModalBody>
                            </Modal>
                        </Container>



                    </Col>
                </Row>
            </Container>



        </div>
    )
}

export default InsuranceFolio