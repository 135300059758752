import axios from '../../modules/helpers/axiosInstance';

import Auth from '../../modules/Auth/index';

let ArtUrl = "http://5.189.157.40:10028/api";


const userID = Auth.getsubcriberId();

const outerGridArtFamily = () => {
    return axios({
        method: 'GET',
        url: `${ArtUrl}/ArtManagement/FetchArtFamilyOuterGrid?s_id=${userID}`,
    });
}
const outerGridArtIndividual = (subId) => {
    return axios({
        method: 'GET',
        url: `${ArtUrl}/ArtManagement/FetchArtEntityOuterGrid?sub_id=${subId}`,
    });
}
const innerGridArtFamily = (subId,investID) => {
    return axios({
        method: 'GET',
        url: `${ArtUrl}/ArtManagement/FetchArtEntityInfo?sub_id=${subId}`,
    });
}
const innerGridArtIndividual = (subId,titleID) => {
    return axios({
        method: 'GET',
        url: `${ArtUrl}/ArtManagement/FetchArtIndividualEntityInfo?sub_id=${subId}&title_id=${titleID}`,
    });
}

const AddArtTransaction = async (body)=>{
    return axios({
        method:'POST',
        url:`${ArtUrl}/ArtManagement/BuyArtDetails`,
        data:body
    })
}

const getArtTransaction = (artId) => {
    return axios({
        method: 'GET',
        url: `${ArtUrl}/ArtManagement/GetArtDetailById?art_id=${artId}`,
    });
}

const addArtTitle = async (body)=>{
    return axios({
        method:'POST',
        url:`${ArtUrl}/ArtManagement/AddArtTitle`,
        data:body
    })
}

const getArtTitle = () => {
    return axios({
        method: 'GET',
        // url: `${ArtUrl}/ArtManagement/GetArtTitle?sub_id=${subId}`,
        url: `${ArtUrl}/ArtManagement/GetArtTitle`,
    });
}
const deleteArtTransaction = (artId) => {
    return axios({
        method: 'Delete',
        url: `${ArtUrl}/ArtManagement/DeleteArtTransactions?artId=${artId}`,
    });
}
const updateArtTransaction = async (body) => {
    return axios({
        method: 'PUT',
        url: `${ArtUrl}/ArtManagement/UpdateArtDetails`,
        data: body
    })
}
const AddArtSellTransaction = (data) => {
    return axios({
        method: 'POST',
        url: `${ArtUrl}/ArtManagement/AddArtSell`,
        data:data
    });
}
const UpdateArtSellTransaction = (data) => {
    return axios({
        method: 'PUT',
        url: `${ArtUrl}/ArtManagement/UpdateArtSell`,
        data:data
    });
}

export{
    outerGridArtFamily,
    outerGridArtIndividual,
    innerGridArtFamily,
    innerGridArtIndividual,
    AddArtTransaction,
    getArtTransaction,
    deleteArtTransaction,
    AddArtSellTransaction,
    UpdateArtSellTransaction,
    addArtTitle,
    getArtTitle,
    updateArtTransaction,
}
