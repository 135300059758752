/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';

import './RenewSubscription.css';

const RenewSubscription = (props) => {
    const { show, hide } = props;
    const navigate = useNavigate();
    const [first, setFirst] = useState(hide);
    
    const year = new Date().getFullYear() + 1;
    
    const handleRenew = () => {
        navigate('/plans')
        setFirst(hide)
    }
    const handleCloseModal = () => {
        setFirst(hide)
    }

  return (
    <div>
        <Modal isOpen={show} toggle={() => handleCloseModal()} size="md" backdrop="static" centered>
            <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                <h3 className="text-center">Renew Subscription</h3>
            </ModalHeader>
            <ModalBody>
                <div className="center">
                    <h5>Your membership will end soon.</h5>
                    <h5>Please renew your subscription before 10<sup>th</sup> April {year}.</h5>
                    <h6> your subscription will be expire on 31<sup>st</sup> March {year} </h6>
                    <Button onClick={() => handleRenew()}>Renew</Button>
                </div>
            </ModalBody>
        </Modal>
    </div>
  )
}

export default RenewSubscription

