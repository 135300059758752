/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Dropdown from '../CommonComponent/Dropdown';
import DatePicker from 'react-datepicker';
import { cumulativeNonCumulativeList, redeemableOrConvertibleList, periodList } from '../../modules/helpers/constant';
import { brokerList, CompanyList, ValuationList, PostUnlistedScripBySubId, GetUnlistedScripBySubId, GetUnlistedSellingCompany, GetDividendFrequency } from '../../pages/Stocks/StocksAPI';
import { addFixedIncome, updateFixedIncome, SelectionList, updateSelectionList, AvilableQuantity, SellingCompanies, deleteFixedIncome, GetFolioNoUnlisted } from '../FIncomeFolio/FDAPI'
import MaterialTable, { MTableToolbar, MTableEditField } from "material-table";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import Tooltip from '@material-ui/core/Tooltip';
import _ , {toNumber} from "lodash";
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from "moment";
import Loader from "../../components/loadingAnimation/index";
import FD from '../FIncomeFolio/other_transactions/FD'
import RD from '../FIncomeFolio/other_transactions/RD';
import PostOffice from '../FIncomeFolio/other_transactions/PostOffice';
import PPFMain from '../FIncomeFolio/other_transactions/PPF';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavedisabledField } from '../StocksDetail/ClevTablCss';
import Cleave from 'cleave.js/react';
import './fixedincomeCss.css'

function FIDetails(props) {
    const { showFD, onHideFD, FDHeaderId, FDTransaction, entityId, showFdpreview, onRefreshInnerGrid } = props;
    // console.log(showFdpreview);

    //columns for listed debenture

    // const [columnsB1, setColumnsB1] = useState([]);
    // //columns for unlisted debenture
    // const [columnsB2, setColumnsB2] = useState([]);
    // //columns for listed Bonds
    // const [columnsB3, setColumnsB3] = useState([]);
    // //columns for unlisted debenture
    // const [columnsB4, setColumnsB4] = useState([]);

    const [date, setDate] = useState(new Date());
    const [call, setcall] = useState(null)
    const [formErr, setFormErr] = useState({});
    const [avilableQty, setAvilableQty] = useState(0);
    const [avilableSellQty, setAvilableSellQty] = useState(0);
    const [certificateorfolinumber, setCertificateorfolinumber] = useState(null);
    const [lockinperiod, setLockinperiod] = useState("");
    const [redeemableorconvertible, setRedeemableorconvertible] = useState([{ label: "Redeemable", value: "Redeemable" }]);
    const [convertType, setConvertType] = useState("Redeemable");
    const [tenure, setTenure] = useState("");
    const [dividend, setDividend] = useState(0);
    const [dateofmaturityorconversion, setDateofmaturityorconversion] = useState(new Date());
    const [dividendfrequency, setDividendfrequency] = useState([]);
    const [frequencyValue, setFrequencyValue] = useState([]);
    const [maturityvalue, setMaturityvalue] = useState(0);
    const [cumulativeornoncumulative, setCumulativeornoncumulative] = useState([{ label: "Non Cumulative", value: "Non Cumulative" }]);
    const [cumulativeType, setCumulativeType] = useState("Non Cumulative");
    const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
    const [timeValue, setTimeValue] = useState("Month");
    const [cost, setCost] = useState(0);
    const [stt, setSTT] = useState(0);
    const [gst, setGST] = useState(0);
    const [stampDuty, setStampDuty] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);
    const [narration, setNarration] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [toolTip, setTooltip] = useState(false);
    const [headerId, setHeaderId] = useState(0);
    const [allEntry1, setAllEntry1] = useState([]);
    const [allEntry2, setAllEntry2] = useState([]);
    const [allEntry3, setAllEntry3] = useState([]);
    const [allEntry4, setAllEntry4] = useState([]);
    const [iserror, setIserror] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [errorMessagesSell, setErrorMessagesSell] = useState([]);
    const [errorMessagesQntity, setErrorMessagesQntity] = useState([]);
    const [brokerValue, setBrokerValue] = useState([]);
    const [brokerOptions, setBrokerOptions] = useState([]);
    const [brokerOptionsListed, setBrokerOptionsListed] = useState([]);
    const [typeValue, setTypeValue] = useState([{ label: "Normal", value: "Normal" }]);
    const [value, setValue] = useState(null);
    const [typeStock, setTypeStock] = useState('Normal');
    const [selection, setSelection] = useState(false);
    const [selectionQty, setSelectionQty] = useState([]);
    const [totalAmountDue, setTotalAmountDue] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    const [sellingCompanyList, setSellingCompanyList] = useState([]);
    const [stockValuationType, setStockValuationType] = useState([]);
    const [updatedStockVaualtion, setUpdatedValuationType] = useState([]);
    const [subAccountId, setSubAccountId] = useState(null);

    const [folioNoList, setFolioNoList] = useState([]);

    const tableRef1 = useRef();
    const tableRef2 = useRef();
    const tableRef3 = useRef();
    const tableRef4 = useRef();
    const tableRefSell1 = useRef();
    const tableRefSell2 = useRef();
    const tableRefSell3 = useRef();
    const tableRefSell4 = useRef();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [data, setdata] = useState([]);
    const [data1, setdata1] = useState([]);
    const [data2, setdata2] = useState([]);
    const [data3, setdata3] = useState([]);

    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);


    const [success, setSuccess] = useState(false);
    const [Mtooltip, setMtooltip] = useState(false);
    const [enabledAdisabled, setEnabledAdisabled] = useState(false)
    const [showUnlisted, setShowUnlisted] = useState(false);
    const [showlistedDeb, setShowlistedDeb] = useState(false);

    const [unlistedComp, setUnlistedComp] = useState('');
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    const [unlistedCompany, setUnlistedCompanyList] = useState([]);
    const [unlistedSellingCompany, setUnlistedSellingCompanyList] = useState([]);
    const [selection2, setSelection2] = useState([]);

    // preview modal
    const [totalBroker, setTotalbroker] = useState(0)
    const [companyvalue, setCompanyValue] = useState([])
    const [pricePerShare, setPricePerShare] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [brokerpershare, setbrokerpershare] = useState(0)
    const [currentMP, setCurrentMP] = useState(0)
    const [currentMV, setCurrentMV] = useState(0)
    const [target, setTarget] = useState(0)
    const [stopLoss, setStopLoss] = useState(0)
    const [estHldP, setestHlP] = useState({})
    const [folioNumber, setfolioNumber] = useState(0)
    const [trastype, settrastype] = useState({})
    const [qty, setqty] = useState(0)
    const [stocksSeletion, setstockSelection] = useState({})
    const [recommendBy, setRecommendBy] = useState("")
    const [brokerpre, setBrokerPre] = useState("")
    const [typepre, setTypePre] = useState("")
    const [datepre, setDatePre] = useState("")
    const [STTpre, setSTTPre] = useState("")
    const [GSTpre, setGSTPre] = useState("")
    const [otherChargesPre, setOtherChargesPre] = useState("")
    const [totalAmtDuePre, setTotalAmtDuePre] = useState("")
    const [narrationPre, setNarrationPre] = useState("")
    const [redeemconvertPre, setredeemconvertPre] = useState("")

    const [columnsS1, setcolumnsS1] = useState([]);
    const [columnsS2, setcolumnsS2] = useState([]);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }

   

    // useEffect(() => {
    //     if (show1 && unlistedCompany) {
    //         setColumnsB1(
    //             [
    //                 {
    //                     title: 'Company Name', field: 'companyId', width: 250,
    //                     editComponent: (props) => {
    //                         let ddValue = props.value;
    //                         if (props.rowData.tableData != undefined) {
    //                             if (props.rowData.tableData.editing != undefined) {
    //                                 if (props.rowData.tableData.editing == 'update') {
    //                                     if (props.value.label != undefined) {
    //                                         if (props.value.label != props.rowData.company.label) {
    //                                             ddValue = props.value
    //                                         }
    //                                         else {
    //                                             ddValue = props.rowData.company
    //                                         }
    //                                     }
    //                                     else {
    //                                         ddValue = props.rowData.company
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                         return (
    //                             <Select
    //                                 components={{ Control }}
    //                                 value={ddValue || null}
    //                                 onChange={(selected) => props.onChange(selected)}
    //                                 // options={Scrip}
    //                                 options={unlistedCompany}
    //                                 name="companyId"
    //                                 isDisabled={headerId != 0 ? true : false}
    //                                 onKeyDown={e => handleKey(e)}
    //                                 menuPosition="fixed"
    //                                 menuShouldBlockScroll="true"
    //                             />
    //                         )
    //                     },
    //                 },
    //                 {
    //                     field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         // handleAddRowBuy();
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'pricePerShare', title: 'Price/Debenture', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.pricePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'brokeragePerShare', title: 'Brokerage/Debenture', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.brokeragePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalBrokrage = 0;
    //                         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //                         props.value = totalBrokrage.toFixed(2);
    //                         props.rowData.totalBrokerage = totalBrokrage;
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalBrokerage)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalcost = 0;
    //                         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
    //                         props.value = totalcost.toFixed(2);
    //                         props.rowData.totalCost = totalcost;
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalCost)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'target', title: 'Target', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'stopLoss', title: 'Stop Loss', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 { field: 'recommendedBy', title: 'Recommended by', width: 130 },
    //                 {
    //                     title: "Addtional Data",
    //                     field: "internal_action",
    //                     // editable: false,
    //                     editComponent: ({ event, ...props }) => {
    //                         // console.log(props);
    //                         let t = props.rowData
    //                         return <span onClick={(e) => {
    //                             e.preventDefault()
    //                             // if (headerId == 0) {
    //                             if (t.interestPerc !== undefined) {
    //                                 setDividend(t.interestPerc);
    //                             }
    //                             if (t.frequencyId !== undefined) {
    //                                 // setDividendfrequency(t.frequency)
    //                                 setFrequencyValue(t.frequencyI);
    //                             }
    //                             if (t.redeemConvertible !== undefined) {
    //                                 setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
    //                             }
    //                             if (t.lockInPeriod !== undefined) {
    //                                 let d = t.lockInPeriod
    //                                 let splitData = d.split(/([0-9]+)/);
    //                                 setTimeValue(splitData[2])
    //                                 setTime({ label: splitData[2], value: splitData[2] })
    //                                 setLockinperiod(splitData[1])
    //                             }
    //                             if (t.cumulativeNonCumulative !== undefined) {
    //                                 setCumulativeType(t.cumulativeNonCumulative);
    //                                 setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
    //                             }
    //                             if (t.folioNumber !== undefined) {
    //                                 setCertificateorfolinumber(t.folioNumber);
    //                             }
    //                             if (t.maturityValue !== undefined) {
    //                                 setMaturityvalue(t.maturityValue);
    //                             }
    //                             if (t.domConversion !== undefined) {
    //                                 setDateofmaturityorconversion(new Date(t.domConversion));
    //                             }
    //                             // }
    //                             setCost(t.totalCost)
    //                             setShowlistedDeb(true)
    //                         }}
    //                             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
    //                     }
    //                 }
    //             ])
    //     }
    //     else if (show2 && unlistedCompany) {
    //         setColumnsB2(
    //             [
    //             {
    //                 title: 'Company Name', field: 'companyId', width: 250,
    //                 editComponent: (props) => {
    //                     let ddValue = props.value;
    //                     if (props.rowData.tableData != undefined) {
    //                         if (props.rowData.tableData.editing != undefined) {
    //                             if (props.rowData.tableData.editing == 'update') {
    //                                 if (props.value.label != undefined) {
    //                                     if (props.value.label != props.rowData.company.label) {
    //                                         ddValue = props.value
    //                                     }
    //                                     else {
    //                                         ddValue = props.rowData.company
    //                                     }
    //                                 }
    //                                 else {
    //                                     ddValue = props.rowData.company
    //                                 }
    //                             }
    //                         }
    //                     }
    //                     return (
    //                         <Select
    //                             components={{ Control }}
    //                             value={ddValue || null}
    //                             onChange={(selected) => props.onChange(selected)}
    //                             // options={Scrip}
    //                             options={unlistedCompany}
    //                             name="companyId"
    //                             isDisabled={headerId != 0 ? true : false}
    //                             onKeyDown={e => handleKey(e)}
    //                             menuPosition="fixed"
    //                             menuShouldBlockScroll="true"
    //                         />
    //                     )
    //                 },
    //             },
    //             {
    //                 field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
    //                 editComponent: ({ ...props }) => {
    //                     // handleAddRowBuy();
    //                     return <MTableEditField {...props} />;
    //                 },

    //             },
    //             {
    //                 field: 'pricePerShare', title: 'Price/Debenture', width: 130, type: 'numeric',
    //                 editComponent: ({ ...props }) => {
    //                     return <MTableEditField {...props} />;
    //                 },
    //                 render: (rowData) => (
    //                     <span>
    //                         {NumberSeperator(rowData.pricePerShare)}
    //                     </span>
    //                 ),
    //             },
    //             {
    //                 field: 'brokeragePerShare', title: 'Brokerage/Debenture', width: 130, type: 'numeric',
    //                 editComponent: ({ ...props }) => {
    //                     return <MTableEditField  {...props} />;
    //                 },
    //                 render: (rowData) => (
    //                     <span>
    //                         {NumberSeperator(rowData.brokeragePerShare)}
    //                     </span>
    //                 ),
    //             },
    //             {
    //                 field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //                 editComponent: ({ ...props }) => {
    //                     let totalBrokrage = 0;
    //                     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //                     props.value = totalBrokrage.toFixed(2);
    //                     props.rowData.totalBrokerage = totalBrokrage;
    //                     return <MTableEditField  {...props} />;
    //                 },
    //                 render: (rowData) => (
    //                     <span>
    //                         {NumberSeperator(rowData.totalBrokerage)}
    //                     </span>
    //                 ),
    //             },
    //             {
    //                 field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
    //                 editComponent: ({ ...props }) => {
    //                     let totalcost = 0;
    //                     totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
    //                     props.value = totalcost.toFixed(2);
    //                     props.rowData.totalCost = totalcost;
    //                     return <MTableEditField {...props} />;
    //                 },
    //                 render: (rowData) => (
    //                     <span>
    //                         {NumberSeperator(rowData.totalCost)}
    //                     </span>
    //                 ),
    //             },
    //             {
    //                 title: "Addtional Data",
    //                 field: "internal_action",
    //                 // editable: false,
    //                 editComponent: ({ ...props }) => {
    //                     let t = props.rowData;
    //                     return <span onClick={(e) => {
    //                         // if (headerId == 0) {
    //                         e.preventDefault()
    //                         if (t.interestPerc !== undefined) {
    //                             setDividend(t.interestPerc);
    //                         }
    //                         if (t.frequencyId !== undefined) {
    //                             setFrequencyValue(t.frequencyI);
    //                         }
    //                         if (t.redeemConvertible !== undefined) {
    //                             setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
    //                         }
    //                         if (t.lockInPeriod !== undefined) {
    //                             let d = t.lockInPeriod
    //                             let splitData = d.split(/([0-9]+)/);
    //                             setTimeValue(splitData[2])
    //                             setTime({ label: splitData[2], value: splitData[2] })
    //                             setLockinperiod(splitData[1])
    //                         }
    //                         if (t.cumulativeNonCumulative !== undefined) {
    //                             setCumulativeType(t.cumulativeNonCumulative);
    //                             setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
    //                         }
    //                         if (t.folioNumber !== undefined) {
    //                             setCertificateorfolinumber(t.folioNumber);
    //                         }
    //                         if (t.maturityValue !== undefined) {
    //                             setMaturityvalue(t.maturityValue);
    //                         }
    //                         if (t.domConversion !== undefined) {
    //                             setDateofmaturityorconversion(new Date(t.domConversion));
    //                         }
    //                         // }
    //                         setCost(t.totalCost)
    //                         setShowlistedDeb(true)
    //                     }}
    //                         title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
    //                 }
    //             }
    //         ])
    //     } else if (show3 && unlistedCompany) {
    //         setColumnsB3(
    //             [
    //                 {
    //                     title: 'Company Name', field: 'companyId', width: 250,
    //                     editComponent: (props) => {
    //                         let ddValue = props.value;
    //                         if (props.rowData.tableData != undefined) {
    //                             if (props.rowData.tableData.editing != undefined) {
    //                                 if (props.rowData.tableData.editing == 'update') {
    //                                     if (props.value.label != undefined) {
    //                                         if (props.value.label != props.rowData.company.label) {
    //                                             ddValue = props.value
    //                                         }
    //                                         else {
    //                                             ddValue = props.rowData.company
    //                                         }
    //                                     }
    //                                     else {
    //                                         ddValue = props.rowData.company
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                         return (
    //                             <Select
    //                                 components={{ Control }}
    //                                 value={ddValue || null}
    //                                 onChange={(selected) => props.onChange(selected)}
    //                                 // options={Scrip}
    //                                 options={unlistedCompany}
    //                                 name="companyId"
    //                                 isDisabled={headerId != 0 ? true : false}
    //                                 onKeyDown={e => handleKey(e)}
    //                                 menuPosition="fixed"
    //                                 menuShouldBlockScroll="true"
    //                             />
    //                         )
    //                     },
    //                 },
    //                 {
    //                     field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         // handleAddRowBuy();
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'pricePerShare', title: 'Price/Bond', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.pricePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'brokeragePerShare', title: 'Brokerage/Bond', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.brokeragePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalBrokrage = 0;
    //                         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //                         props.value = totalBrokrage.toFixed(2);
    //                         props.rowData.totalBrokerage = totalBrokrage;
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalBrokerage)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalcost = 0;
    //                         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
    //                         props.value = totalcost.toFixed(2);
    //                         props.rowData.totalCost = totalcost;
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalCost)}
    //                         </span>
    //                     ),
    //                 },
    //                 // {
    //                 //     field: 'currentMp', title: 'Current MP', width: 130, type: 'numeric',
    //                 //     type: 'numeric',
    //                 //     editComponent: ({ ...props }) => {
    //                 //         return <MTableEditField  {...props} />;
    //                 //     },
    //                 // },
    //                 // {
    //                 //     field: 'currentMarketValue', title: 'Current Market Value', width: 130, type: 'numeric',
    //                 //     editComponent: ({ ...props }) => {
    //                 //         let CurrentMV = 0;
    //                 //         CurrentMV = props.rowData.quantity * props.rowData.currentMp;
    //                 //         props.value = CurrentMV;
    //                 //         props.rowData.currentMarketValue = CurrentMV;
    //                 //         return <MTableEditField {...props} />;
    //                 //     }
    //                 // },
    //                 {
    //                     field: 'target', title: 'Target', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'stopLoss', title: 'Stop Loss', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 { field: 'recommendedBy', title: 'Recommended by', width: 130 },
    //                 {
    //                     title: "Addtional Data",
    //                     field: "internal_action",
    //                     // editable: false,
    //                     editComponent: ({ ...props }) => {
    //                         // console.log(props);
    //                         let t = props.rowData
    //                         return <span onClick={(e) => {
    //                             // if (headerId == 0) {
    //                             e.preventDefault();
    //                             if (t.interestPerc !== undefined) {
    //                                 setDividend(t.interestPerc);
    //                             }
    //                             if (t.frequencyId !== undefined) {
    //                                 // setDividendfrequency(t.frequency)
    //                                 setFrequencyValue(t.frequencyI);
    //                             }
    //                             if (t.redeemConvertible !== undefined) {
    //                                 setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
    //                             }
    //                             if (t.lockInPeriod !== undefined) {
    //                                 let d = t.lockInPeriod
    //                                 let splitData = d.split(/([0-9]+)/);
    //                                 setTimeValue(splitData[2])
    //                                 setTime({ label: splitData[2], value: splitData[2] })
    //                                 setLockinperiod(splitData[1])
    //                             }
    //                             if (t.cumulativeNonCumulative !== undefined) {
    //                                 setCumulativeType(t.cumulativeNonCumulative);
    //                                 setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
    //                             }
    //                             if (t.folioNumber !== undefined) {
    //                                 setCertificateorfolinumber(t.folioNumber);
    //                             }
    //                             if (t.maturityValue !== undefined) {
    //                                 setMaturityvalue(t.maturityValue);
    //                             }
    //                             if (t.domConversion !== undefined) {
    //                                 setDateofmaturityorconversion(new Date(t.domConversion));
    //                             }
    //                             // }
    //                             setCost(t.totalCost)
    //                             setShowlistedDeb(true)
    //                         }}
    //                             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
    //                     }
    //                 }
    //             ])
    //     } else {
    //         setColumnsB4(
    //             [
    //                 {
    //                     title: 'Company Name', field: 'companyId', width: 250,
    //                     editComponent: (props) => {
    //                         let ddValue = props.value;
    //                         if (props.rowData.tableData != undefined) {
    //                             if (props.rowData.tableData.editing != undefined) {
    //                                 if (props.rowData.tableData.editing == 'update') {
    //                                     if (props.value.label != undefined) {
    //                                         if (props.value.label != props.rowData.company.label) {
    //                                             ddValue = props.value
    //                                         }
    //                                         else {
    //                                             ddValue = props.rowData.company
    //                                         }
    //                                     }
    //                                     else {
    //                                         ddValue = props.rowData.company
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                         return (
    //                             <Select
    //                                 components={{ Control }}
    //                                 value={ddValue || null}
    //                                 onChange={(selected) => props.onChange(selected)}
    //                                 // options={Scrip}
    //                                 options={unlistedCompany}
    //                                 name="companyId"
    //                                 isDisabled={headerId != 0 ? true : false}
    //                                 onKeyDown={e => handleKey(e)}
    //                                 menuPosition="fixed"
    //                                 menuShouldBlockScroll="true"
    //                             />
    //                         )
    //                     },
    //                 },
    //                 {
    //                     field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         // handleAddRowBuy();
    //                         return <MTableEditField {...props} />;
    //                     },
    //                 },
    //                 {
    //                     field: 'pricePerShare', title: 'Price/Bond', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.pricePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'brokeragePerShare', title: 'Brokerage/Bond', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.brokeragePerShare)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalBrokrage = 0;
    //                         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //                         props.value = totalBrokrage.toFixed(2);
    //                         props.rowData.totalBrokerage = totalBrokrage;
    //                         return <MTableEditField  {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalBrokerage)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
    //                     editComponent: ({ ...props }) => {
    //                         let totalcost = 0;
    //                         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
    //                         props.value = totalcost.toFixed(2);
    //                         props.rowData.totalCost = totalcost;
    //                         return <MTableEditField {...props} />;
    //                     },
    //                     render: (rowData) => (
    //                         <span>
    //                             {NumberSeperator(rowData.totalCost)}
    //                         </span>
    //                     ),
    //                 },
    //                 {
    //                     title: "Addtional Data",
    //                     field: "internal_action",
    //                     // editable: false,
    //                     editComponent: ({ ...props }) => {
    //                         let t = props.rowData;
    //                         return <span onClick={(e) => {
    //                             e.preventDefault();
    //                             // if (headerId == 0) {

    //                             if (t.interestPerc !== undefined) {
    //                                 setDividend(t.interestPerc);
    //                             }
    //                             if (t.frequencyId !== undefined) {
    //                                 setFrequencyValue(t.frequencyI);
    //                             }
    //                             if (t.redeemConvertible !== undefined) {
    //                                 setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
    //                             }
    //                             if (t.lockInPeriod !== undefined) {
    //                                 let d = t.lockInPeriod
    //                                 let splitData = d.split(/([0-9]+)/);
    //                                 setTimeValue(splitData[2])
    //                                 setTime({ label: splitData[2], value: splitData[2] })
    //                                 setLockinperiod(splitData[1])
    //                             }
    //                             if (t.cumulativeNonCumulative !== undefined) {
    //                                 setCumulativeType(t.cumulativeNonCumulative);
    //                                 setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
    //                             }
    //                             if (t.folioNumber !== undefined) {
    //                                 setCertificateorfolinumber(t.folioNumber);
    //                             }
    //                             if (t.maturityValue !== undefined) {
    //                                 setMaturityvalue(t.maturityValue);
    //                             }
    //                             if (t.domConversion !== undefined) {
    //                                 setDateofmaturityorconversion(new Date(t.domConversion));
    //                             }
    //                             // }
    //                             setCost(t.totalCost);
    //                             setShowlistedDeb(true);
    //                         }}
    //                             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
    //                     }
    //                 }
    //             ])
    //     }

    // }, [show1, show2, show3, show4, unlistedCompany])





    useEffect(() => {

    }, [show4])

    useEffect(() => {

        getCompanyList()
        if (showFD) {
            getBrokerList();
        }
        getStockValuation();
        getDividedFrequencyList();
    }, [showFD])

    useEffect(() => {
        // setLoading(true);
        if (showFD) {
            setSubAccountId(entityId == null ? subAccountId : entityId);
            // getSellingCompany(entityId == null ? subAccountId : entityId);
            getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);
        }

    }, [value, allEntry1, show1, show2, show3, show4], showFD)

    useEffect(() => {
        // setLoading(true);
        if (FDHeaderId !== 0) {
            if (showFD) {
                setSubAccountId(entityId == null ? subAccountId : entityId);
                // getSellingCompany(entityId == null ? subAccountId : entityId);
                getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);
            }
        }
    }, [call], showFD)

    //handle unlisted companies
    useEffect(() => {
        if (showFD) {
            getUnlistedComapy(entityId == null ? subAccountId : entityId);
            getUnlistedSellingCompany(entityId == null ? subAccountId : entityId);
        }
    }, [show2 === true, show4 === true, show1 === true, show4 === true, showFD])
    //set update values
    useEffect(() => {
        (async () => {
            await setShow2(false);
            await setShow1(true);
            await setShow3(false);
            await setShow4(false);
            await setShow5(false)
            await setShow6(false);
            await setShow7(false);
            await setShow8(false);
            await setSubAccountId(entityId == null ? subAccountId : entityId);
            if (FDHeaderId !== 0) {

                if (Object.keys(FDTransaction).length !== 0) {
                    setHeaderId(FDHeaderId);
                    if (FDTransaction.instrumentTypeId !== 10) {
                        setTypeValue({ label: FDTransaction.type, value: FDTransaction.type });
                        setTypeStock(FDTransaction.type);
                        setSTT(FDTransaction.sst == null ? 0 : FDTransaction.sst);
                    }

                    // if (FDTransaction.instrumentTypeId == 12 || FDTransaction.instrumentTypeId == 11) {
                    FDTransaction.fdDetails && FDTransaction.fdDetails.map((t) => {
                        if (t.transactStockType !== "Sell") {
                            let d = t.lockInPeriod
                            let splitData = d ? d.split(/([0-9]+)/) : ["", "0", "Month"];
                            setTimeValue(splitData[2])
                            setTime({ label: splitData[2], value: splitData[2] })
                            setLockinperiod(splitData[1])
                            setCertificateorfolinumber(t.folioNumber);
                            setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                            setDividend(t.interestPerc);
                            setDateofmaturityorconversion(new Date(t.domConversion));
                            setFrequencyValue({ label: t.frequency.frequencyName, value: t.frequencyId });
                            setMaturityvalue(t.maturityValue);
                            t.frequencyI = { label: t.frequency.frequencyName, value: t.frequencyId };
                            // setTenure(t.tenure)
                            setCumulativeType(t.cumulativeNonCumulative);
                            setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
                        } else {
                            setSelectionQty(JSON.parse(t.selection))
                        }
                    })
                    // }
                    setBrokerValue({ value: FDTransaction.brokerId, label: FDTransaction.broker.brokerName });
                    setValue(FDTransaction.brokerId);
                    if (FDTransaction.instrumentTypeId === 21) {
                        await setShow2(true);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(false);
                        await setShow8(false);
                    }
                    else if (FDTransaction.instrumentTypeId === 13) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(true);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(false);
                        await setShow8(false);
                    } else if (FDTransaction.instrumentTypeId === 22) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(true);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(false);
                        await setShow8(false);
                    } else if (FDTransaction.instrumentTypeId === 29) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(true);
                        await setShow6(false)
                        await setShow7(false);
                        await setShow8(false);
                    } else if (FDTransaction.instrumentTypeId === 30) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(true);
                        await setShow7(false);
                        await setShow8(false);
                    } else if (FDTransaction.instrumentTypeId === 31) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(false);
                        await setShow8(true);
                    }
                    else if (FDTransaction.instrumentTypeId === 32) {
                        await setShow2(false);
                        await setShow1(false);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(true);
                        await setShow8(false);
                    } else {
                        await setShow2(false);
                        await setShow1(true);
                        await setShow3(false);
                        await setShow4(false);
                        await setShow5(false);
                        await setShow6(false);
                        await setShow7(false);
                        await setShow8(false);
                    }

                    setDate(new Date(FDTransaction.date));
                    setStampDuty(FDTransaction.stampDuty == null ? 0 : FDTransaction.stampDuty);
                    setGST(FDTransaction.gst == null ? 0 : FDTransaction.gst);
                    setOtherCharges(FDTransaction.otherCharges == null ? 0 : FDTransaction.otherCharges);
                    setTotalAmountDue(FDTransaction.totalAmountDue == null ? 0 : FDTransaction.totalAmountDue);
                    setSubAccountId(FDTransaction.subAccountId);
                    setNarration(FDTransaction.narration)
                    let Buy = [];
                    let Sell = [];
                    let newFdDetail = FDTransaction.fdDetails && FDTransaction.fdDetails.map((t) => {
                        return {
                            ...t,
                            stockValuation: { 'value': t.stockValuationId, 'label': t.stockValuation.stockValuationName },
                            company: ({ label: t.company.companyName, value: t.companyId }),
                            companyId: t.company.companyName,
                            stockValuationId: t.stockValuation.stockValuationName,
                            stockQty: t.quantity,
                            unlistedFolio: { 'value': t.folioNumber, 'label': t.folioNumber }
                        }
                    })
                    newFdDetail && newFdDetail.map((s) => {
                        if (s.transactStockType === 'Sell') {
                            Sell.push(s);
                        }
                        if (s.transactStockType === 'Buy') {
                            if (s.brokeragePerShare == null) {
                                s.brokeragePerShare = 0;
                                s.totalBrokerage = 0;
                            }
                            Buy.push(s);
                        }
                    })
                    if (FDTransaction.instrumentTypeId === 13) {
                        setdata2(Sell)
                    } else if (FDTransaction.instrumentTypeId === 22) {
                        setdata3(Sell)
                    } else if (FDTransaction.instrumentTypeId === 21) {
                        setdata1(Sell)
                    } else {
                        setdata(Sell)
                    }
                    if (FDTransaction.instrumentTypeId === 13) {
                        setAllEntry3(Buy)
                    } else if (FDTransaction.instrumentTypeId === 22) {
                        setAllEntry4(Buy)
                    } else if (FDTransaction.instrumentTypeId === 21) {
                        setAllEntry2(Buy)
                    } else {
                        setAllEntry1(Buy)
                    }
                }
                else {
                    clearForm();
                }
            }
            else {
                clearForm();
            }
        })();
    }, [FDTransaction])

    //snapshot preview 
    useEffect(() => {
        if (FDHeaderId !== 0) {
            if (Object.keys(FDTransaction).length !== 0) {
                setDatePre(FDTransaction.date)
                setTypePre(FDTransaction.type)
                setBrokerPre(FDTransaction.broker.brokerName)
                setCertificateorfolinumber(FDTransaction.certificateFolioNo)
                setSTTPre(FDTransaction.sst)
                setGSTPre(FDTransaction.gst)
                setOtherChargesPre(FDTransaction.otherCharges)
                setTotalAmtDuePre(FDTransaction.totalAmountDue)
                setNarrationPre(FDTransaction.narration)
                FDTransaction.fdDetails && FDTransaction.fdDetails.map((t) => {
                    setTotalbroker(t.totalBrokerage)
                    setCompanyValue(t.company.companyName)
                    setPricePerShare(t.pricePerShare)
                    setTotalCost(t.totalCost)
                    setbrokerpershare(t.brokeragePerShare)
                    // setCurrentMP(t.currentMp)
                    // setCurrentMV(t.currentMarketValue)
                    setTarget(t.target)
                    setStopLoss(t.stopLoss)
                    setestHlP(t.estHoldingPeriod)
                    setstockSelection(t.stockValuation.stockValuationName)
                    setfolioNumber(t.folioNumber)
                    settrastype(t.transactStockType)
                    setqty(t.quantity)
                    setRecommendBy(t.recommendedBy)
                    setredeemconvertPre(t.redeemConvertible);
                })
            }
        }

    }, [FDTransaction])

    //brokerlist
    const getBrokerList = async () => {
        let EntityID = entityId == null ? subAccountId : entityId
        setLoading(true)
        await brokerList(EntityID).then(res => {

            if (res.status === 200) {
                let brokers = res.data._response.data.brokerDetails;
                let brokersData = [];
                let dataBrk = [];
                brokers && brokers.map(data => {
                    brokersData.push({ value: 1, label: "N/A" })
                    dataBrk.push({ value: data.brokerId, label: data.brokerName })
                })
                // dataBrk.shift();
                setBrokerOptions(brokersData);
                setBrokerOptionsListed(dataBrk)
                setLoading(false)
            } else {
                setLoading(false)
                // setError(res.data.message)
            }
        }).catch(e => {
            // setError(e.message)
            setLoading(false)
        })
    }

    //Broker Validation on selling company selection
    const brokerSelect = () => {
        setMtooltip(false)
        if (brokerValue.length < 1 || brokerValue.length === 0 ) {
            setMtooltip(true)
            setEnabledAdisabled(true);
        }
        if (sellingCompanyList.length < 1) {
            setEnabledAdisabled(true);
        }
        else {
            setEnabledAdisabled(false);
        }
    }
    // companylist
    const getCompanyList = async () => {
        setLoading(true)
        await CompanyList().then(res => {
            if (res.status === 200) {
                let company = res.data._response.data.companyDetails;
                let companyData = [];
                company && company.map(data => {
                    return (companyData.push({ value: data.companyId, label: data.companyName }))
                })
                setCompanyList(companyData);
                setLoading(false)
            } else {
                // setError(res.data.message)
                setLoading(false)
            }
        }).catch(e => {
            // setError(e.message)
            setLoading(false)
        })
    }


    //instrument type wise sell companies
    const getSellingCompanyByInsType = async (entityId) => {
        if (entityId != null) {
            let insTypIdC
            if (show1) {
                insTypIdC = 12;
            } else if (show2) {
                insTypIdC = 21;
            } else if (show3) {
                insTypIdC = 13;
            } else {
                insTypIdC = 22;
            }
            if (entityId !== null || value !== null || insTypIdC !== null) {
                setLoading(true);
                await SellingCompanies(entityId, value, insTypIdC).then(res => {
                    setLoading(false);
                    if (res.status === 200) {
                        if(res.data._response.status === 1){
                            let company = res.data._response.data.fetchFixedIncomeCompanyByInsTypeId;

                            let companyData = [];
                            company && company.map(data => {
                                return (companyData.push({ value: data.companyId, label: data.companyName }))
                            })
                            let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
                            let newCompany = [];
                            resultDataBuy && resultDataBuy.map((p) => {
                                return (newCompany.push(p.company))
                            })

                            let result = _.unionBy(companyData, newCompany, 'label');
                            setSellingCompanyList(result);
                        } else{
                            setSellingCompanyList([]); 
                        }
                    } else {
                        setError(res.data.message)
                        setSellingCompanyList([]);
                    }
                }).catch(e => {
                    setLoading(false);
                    setError(e.message)
                    setSellingCompanyList([]);
                })
            }
        }
    }

    const CompanySelect = async (ddValue, rowData, FDID) => {
        if (ddValue !== undefined) {
            // setLoading(true);
            let resultBuy = show2 ? allEntry2 : allEntry4;
            let resultSell = show2 ? data1 : data3;
            let instTypeId = show2 ? 21 : 22;
            // setLoading(true);
            let Id = entityId == null ? subAccountId : entityId;
            let comapnyId = ddValue.value;
            let quantity = 0;
            let editQuantity = 0;

            resultBuy && resultBuy.map((r) => {
                if (r.company.value === comapnyId && r.fdId === undefined) {
                    quantity += r.quantity;
                }
            })
            resultSell && resultSell.map((r) => {
                if (r.company.value === comapnyId && r.fdId === undefined) {
                    quantity -= r.quantity;
                }
            })
            // handle changed qty of entry with stockId , @stockQty -old qty , @quantity- new changed qty
            resultSell && resultSell.map((r) => {
                if (r.company.value === comapnyId && r.quantity !== r.stockQty && r.fdId !== undefined) {
                    editQuantity = Number(r.quantity) > Number(r.stockQty) ? Number(r.quantity) - Number(r.stockQty) : -(r.stockQty - r.quantity)
                }
            })

            // get folio numbers
            if ((!FDID) && (Id && ddValue.value && instTypeId !== 0)) {
                // setLoading(true);
                await GetFolioNoUnlisted(Id, ddValue.value, instTypeId).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            let data = res.data._response.data.folioNumbers;
                            let arr = [];
                            data && data.map((d, i) => {
                                return (arr.push({ label: d, value: d }))
                            })
                            setFolioNoList(arr);
                        } else {
                            setFolioNoList([]);
                        }
                    }
                }).catch(e => {
                    console.log(e, 'error in getFolioNo Api ');
                    setLoading(false);
                })
            }
            if (showFD) {
                await AvilableQuantity(Id, ddValue.value, 1).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {

                            let AvlQuantity = 0;
                            AvlQuantity = res.data._response.data.remainingQuantity;
                            setAvilableQty(AvlQuantity + quantity - editQuantity);
                            setAvilableSellQty(AvlQuantity);
                            setLoading(false)
                        } else {
                            setAvilableQty(0 + quantity);
                        }
                    }
                })
                    .catch((err) => {
                        setError(err.message)
                        setLoading(false)
                    })
            }

        }
    }

    const getStockValuation = async () => {
        await ValuationList().then(res => {
            if (res.status === 200) {
                let valuation = res.data._response.data.stockvaluationtDetails;
                let vaulationData = [];
                valuation && valuation.map(data => {
                    if (data.stockValuationId === 3 && data.stockValuationName === "Selection") {
                        vaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
                    }

                })

                let updatedVaulationData = [];
                valuation && valuation.map(data => {
                    if (data.stockValuationId === 3) {
                        updatedVaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
                    }

                })
                setUpdatedValuationType(updatedVaulationData);

                setStockValuationType(vaulationData);
            } else {
                setError(res.data.message)
            }
        }).catch(e => {
            setError(e.message)
        })
    }

    //getdividend list
    const getDividedFrequencyList = async () => {
        await GetDividendFrequency()
            .then((res) => {

                if (res.status === 200) {
                    //
                    let dividend = res.data._response.data.dividendfrequencyDetails;
                    let dividendData = [];
                    dividend &&
                        dividend.map((data) => {
                            return (dividendData.push({
                                value: data.frequencyId,
                                label: data.frequencyName,
                            }))
                        });
                    setDividendfrequency(dividendData);
                    if (headerId === 0) {
                        setFrequencyValue(dividendData[1])
                    }
                } else {
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
            });
    };

    //close listed deb modal
    const handlecloseDescModal = () => {
        setShowlistedDeb(false);
        // setShowlistedBonds(false);
        // handleCloseModalPopUp()
        setFormErr('')
        // onTransactionGridRefresh();
    }


    //handle broker
    const HandleChange = (event) => {
        setValue(event.value);
        setBrokerValue(event);
        setMtooltip(false)
        setFormErr([]);
        setMtooltip(false);
    }

    // handle Cumulativeornoncumulative
    const handleChangeCumulative = (e) => {
        setCumulativeornoncumulative(e);
        setCumulativeType(e.value);
    }

    // handlereddemand convert
    const handleChangeConvert = (e) => {
        setRedeemableorconvertible(e);
        setConvertType(e.value);
    }

    // frequency
    const handleChangeFrequency = (e) => {
        // setDividendfrequency(e);
        setFrequencyValue(e);
    }

    //locking period
    const HandleChangeTime = (e) => {
        setTime(e);
        setTimeValue(e.value);
    }

    const handleOtherCharges = (value) => {
        let otherchr = value === '' ? 0 : value;
        setOtherCharges(parseFloat(otherchr));
        let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
        var sumSellStock = 0;
        var sumBuyStock = 0;
        for (let i = 0; i < StockDetail.length; i++) {
            if (StockDetail[i].transactStockType === "Sell") {
                sumSellStock += StockDetail[i].totalCost;
            }
            else if (StockDetail[i].transactStockType === "Buy") {
                sumBuyStock += StockDetail[i].totalCost;
            }
        }
        let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(stt) + parseFloat(gst) + parseFloat(value === '' ? 0 : value) + parseFloat(stampDuty));
        setTotalAmountDue(TotalAmtDueToFromBroker);
    }

    const handleGst = async (value) => {
        let gst = value === '' ? 0 : value;
        setGST(parseFloat(gst));
        let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
        var sumSellStock = 0;
        var sumBuyStock = 0;
        for (let i = 0; i < StockDetail.length; i++) {
            if (StockDetail[i].transactStockType === "Sell") {
                sumSellStock += StockDetail[i].totalCost;
            }
            else if (StockDetail[i].transactStockType === "Buy") {
                sumBuyStock += StockDetail[i].totalCost;
            }
        }
        let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(stt) + parseFloat(value === '' ? 0 : value) + parseFloat(otherCharges) + parseFloat(stampDuty));
        await setTotalAmountDue(TotalAmtDueToFromBroker);
    }

    const handleStt = async (value) => {
        let stt = value === '' ? 0 : value;
        setSTT(parseFloat(stt));
        let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
        var sumSellStock = 0;
        var sumBuyStock = 0;
        for (let i = 0; i < StockDetail.length; i++) {
            if (StockDetail[i].transactStockType === "Sell") {
                sumSellStock += StockDetail[i].totalCost;
            }
            else if (StockDetail[i].transactStockType === "Buy") {
                sumBuyStock += StockDetail[i].totalCost;
            }
        }
        let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(value === '' ? 0 : value) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stampDuty));
        await setTotalAmountDue(TotalAmtDueToFromBroker);
    }

    const handleStampDuty = async (value) => {
        let stampduty = value === '' ? 0 : value;
        setStampDuty(parseFloat(stampduty));
        let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
        var sumSellStock = 0;
        var sumBuyStock = 0;
        for (let i = 0; i < StockDetail.length; i++) {
            if (StockDetail[i].transactStockType === "Sell") {
                sumSellStock += StockDetail[i].totalCost;
            }
            else if (StockDetail[i].transactStockType === "Buy") {
                sumBuyStock += StockDetail[i].totalCost;
            }
        }
        let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(value === '' ? 0 : value) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stt));
        await setTotalAmountDue(TotalAmtDueToFromBroker);
    }

    // + icon in company dd for unlisted
    const handleShow = () => setShowUnlisted(!showUnlisted);
    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <span onMouseDown={handleShow} className="m-0" title="Add unlisted company">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i>
            </span>
            {children}
        </components.Control>
    )

    const getUnlistedSellingCompany = (entityId) => {
        if (entityId != null || entityId !== undefined) {
            GetUnlistedSellingCompany(entityId).then(res => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let company = res.data._response.data.fetchSelectionCompany;
                        let companyData = [];
                        company && company.map(data => {
                            return (companyData.push({ value: data.companyId, label: data.companyName }))
                        })
                        setUnlistedSellingCompanyList(companyData);
                    }
                } else {
                    setError(res.data.message)
                }
            }).catch(e => {
                setError(e.message)
            })
        }
    }
    // get unlisted companies
    const getUnlistedComapy = (entityId) => {
        let id

        if (show2) {
            id = 21
        }
        if (show1) {
            id = 12
        }
        if (show3) {
            id = 13
        }
        if (show4) {
            id = 22
        }
        GetUnlistedScripBySubId(entityId, id).then(res => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let company = res.data._response.data.fetchUnlistedCompany;
                    let companyData = [];
                    company && company.map(data => {
                        return (companyData.push({ value: data.companyId, label: data.companyName }))
                    })
                    setUnlistedCompanyList(companyData);
                }
            } else {
                setError(res.data.message)
            }
        }).catch(e => {
            setError(e.message)
        })
    }

    const handleUnlisted = (e) => {
        const { name, value } = e.target;
        setUnlistedComp(value);
        setUnlistedCompErr('')
    }

    //unlisted modal handle
    const handleCloseModal = () => {
        setShowUnlisted(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }
    //unlisted modal submit
    const submitUnlisted = (e) => {
        e.preventDefault();
        if (unlistedComp !== '') {
            let Id = entityId == null ? subAccountId : entityId;
            let comId

            if (show1) {
                comId = 12
            } else if (show2) {
                comId = 21
            } else if (show3) {
                comId = 13
            } else if (show4) {
                comId = 22
            }

            let data = {
                companyName: unlistedComp,
                instrumentTypeId: comId,
                subAccountId: Id
            }
            setLoading(true);

            PostUnlistedScripBySubId(data)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            setLoading(false);
                            setUnlistedComp('');
                            setUnlistedCompErr('');
                            Swal.fire(
                                res.data._response.message,
                                '',
                                'success'
                            )
                            // getUnlistedComapy(entityId == null ? subAccountId : entityId);
                            let t = res.data._response.data.companyName
                            let v = res.data._response.data.companyId
                            let comp = { label: t, value: v }

                            // if (show1 || show3) {
                            //     companyList.unshift(comp)
                            //     unlistedCompany.unshift(comp)
                            // } else {
                            unlistedCompany.unshift(comp)
                            // }


                            handleCloseModal();
                            // getUnlistedComapy(Id);
                        } else {
                            Swal.fire(res.data._response.message, '', 'info')
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setError(e.message)
                    setLoading(false)
                })
        } else {
            setUnlistedCompErr("Company name required!")
        }
    }


    //function for adding a new row to the table
    const handleRowAdd = async (newData, resolve, reject, a) => {
        let companyData = newData.companyId;
        let errorList = []

        if (show1 || show3 === true) {
            if (!newData.companyId) {
                errorList.push("Please Select Company Name Field")
            }
            if (!newData.quantity) {
                errorList.push("Please Enter Quantity Field")
            } else if (!newData.quantity && newData.quantity > avilableQty) {
                errorList.push("Please Enter Quantity In Range Of Available Quantity");
            } else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
                errorList.push("Please Enter Brokerage Per Share Field")
            }
            // if (!newData.currentMp) {
            //     errorList.push("please enter Current Market Price field")
            // }
            if (!newData.pricePerShare) {
                errorList.push("Please Enter Price Per Share Field")
            }
            // if (!newData.estHoldingPeriod) {
            //     errorList.push("please enter EstHoldingPeriod field")
            // }
            if ((dividend === 0 && maturityvalue === 0) || (cost === 0)) {
                errorList.push("Please Fill Addtional Information Field")
            }

        }

        if (show2 || show4 === true) {
            if (!newData.companyId) {
                errorList.push("Please Select Company Name Field")
            }
            if (!newData.quantity) {
                errorList.push("Please Enter Quantity Field")
            } else if (!newData.quantity && newData.quantity > avilableQty) {
                errorList.push("please enter quantity in range of available quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }

            // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
            //     errorList.push("Please Enter Brokerage Per Share Field")
            // }

            if (!newData.pricePerShare) {
                errorList.push("Please Enter Price Per Share Field")
            }
            if ((dividend === 0 && maturityvalue === 0) || (cost === 0)) {
                errorList.push("Please Fill Addtional Information Field")
            }
        }

        if (errorList.length < 1) {
            let newUserdata = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];
            newData.companyId = newData.companyId.label;
            newData.company = companyData;
            // if (dividend) {
            // if (show1 || show2 || show4) {
            newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
            //moment.tz(dateofmaturityorconversion, "Asia/Kolkata").format();
            newData.redeemConvertible = convertType;
            // newData.redeemConvertible = convertType;
            newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
            if (show2 || show4) {
                newData.folioNumber = certificateorfolinumber;
            }
            newData.interestPerc = dividend;
            newData.frequencyId = frequencyValue.value ? parseInt(frequencyValue.value) : parseInt(frequencyValue[0].value);
            newData.frequencyI = frequencyValue;
            newData.cumulativeNonCumulative = cumulativeType;
            // newData.maturityValue = parseInt(maturityvalue);
            if (newData.cumulativeNonCumulative === "Cumulative") {
                newData.maturityValue = parseInt(maturityvalue);
            }
            if (newData.cumulativeNonCumulative === "Non Cumulative") {
                newData.maturityValue = cost;
            }

            let instType
            if (show1) {
                if (convertType === "Convertible") {
                    instType = 16
                } else {
                    instType = 17
                }
            } else if (show2) {
                if (convertType === "Convertible") {
                    instType = 25
                } else {
                    instType = 26
                }
            }
            else if (show3) {
                if (convertType === "Convertible") {
                    instType = 18
                } else {
                    instType = 19
                }
            } else {
                if (convertType === "Convertible") {
                    instType = 27
                } else {
                    instType = 28
                }
            }
            newData.instrumentTypeId = instType;

            // newData.tenure = tenure;

            // }
            // }
            newUserdata.push(newData);
            var result = newUserdata.map(function (o) {
                if (o.stockId === undefined) {
                    o.transactStockType = "Buy";
                }
                return o;
            })
            await show1 === true ? setAllEntry1(result) : show2 === true ? setAllEntry2(result) : show3 === true ? setAllEntry3(result) : setAllEntry4(result);
            resolve()
            let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
            handleTotalAmt(result, sell);
            setErrorMessages([])
            setIserror(false)
            handleCloseModalPopUp()
        }
        else {
            setErrorMessages(errorList)
            setIserror(true)
            reject()
        }
    }
    //function for updating the existing row details
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        // 
        let cpy = newData.company.label;
        let companyData = cpy === newData.companyId && newData.companyId.label === undefined ? newData.company : newData.companyId;
        let errorList = [];

        if (show1 || show3 === true) {
            if (!newData.companyId) {
                errorList.push("Please Select Company Name Field")
            }
            if (!newData.quantity) {
                errorList.push("Please Enter Quantity Field")
            }
            else if (!newData.quantity && newData.quantity > avilableQty) {
                errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
                errorList.push("Please Enter Brokerage Per Share Field")
            }

            if (!newData.pricePerShare) {
                errorList.push("please enter Current Market Price field")
            }

        }
        if (show2 || show4 === true) {
            if (!newData.companyId) {
                errorList.push("Please Select Company Name Field")
            }
            if (!newData.quantity) {
                errorList.push("Please Enter Quantity Field")
            }
            else if (!newData.quantity && newData.quantity > avilableQty) {
                errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
            //     errorList.push("Please Enter Brokerage Per Share Field")
            // }

            if (!newData.pricePerShare) {
                errorList.push("Please Enter Price Per Share Field")
            }
            // if(!(newData.lockInPeriod && newData.dividendPerc && newData.maturityValue)){
            //     errorList.push("Please Fill Addtional Information Field")
            //   }
        }

        if (errorList.length < 1) {
            const updateStock = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];
            const index = oldData.tableData.id;
            newData.companyId = cpy === newData.companyId && newData.companyId.label === undefined ? newData.companyId : newData.companyId.label !== undefined ? newData.companyId.label : newData.companyId.label;
            newData.company = companyData;
            if (lockinperiod !== "" || dividend !== 0) {
                newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
                //moment.tz(dateofmaturityorconversion, "Asia/Kolkata").format();
                newData.redeemConvertible = convertType;
                newData.lockInPeriod = lockinperiod === "" ? "0" + timeValue : lockinperiod + timeValue;
                newData.folioNumber = certificateorfolinumber;
                newData.interestPerc = dividend;
                newData.frequencyId = frequencyValue.value ? parseInt(frequencyValue.value) : parseInt(frequencyValue[0].value);;
                newData.frequencyI = frequencyValue;
                newData.cumulativeNonCumulative = cumulativeType;
                // newData.maturityValue = parseInt(maturityvalue);
                if (newData.cumulativeNonCumulative === "Cumulative") {
                    newData.maturityValue = parseInt(maturityvalue);
                }
                if (newData.cumulativeNonCumulative === "Non Cumulative") {
                    newData.maturityValue = cost;
                }
                // newData.tenure = tenure;

            } else {
                if (newData.cumulativeNonCumulative === "Non Cumulative") {
                    newData.maturityValue = newData.totalCost;
                }
            }
            let instType
            if (show1) {
                if (convertType === "Convertible") {
                    instType = 16
                } else {
                    instType = 17
                }
            } else if (show2) {
                if (convertType === "Convertible") {
                    instType = 25
                } else {
                    instType = 26
                }
            }
            else if (show3) {
                if (convertType === "Convertible") {
                    instType = 18
                } else {
                    instType = 19
                }
            } else {
                if (convertType === "Convertible") {
                    instType = 27
                } else {
                    instType = 28
                }
            }
            newData.instrumentTypeId = instType;

            updateStock[index] = newData;
            show1 === true ? setAllEntry1([...updateStock]) : show2 === true ? setAllEntry2([...updateStock]) : show3 === true ? setAllEntry3([...updateStock]) : setAllEntry4([...updateStock]);
            resolve()
            setIserror(false)
            let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
            handleTotalAmt([...updateStock], sell);
            setErrorMessages([])
            handleCloseModalPopUp()
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            reject()
        }
    }
    //function for deleting a row
    const handleRowDelete = async (oldData, resolve, reject) => {
        const dataDelete = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];
        if (oldData.fdId === undefined) {
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : show3 === true ? setAllEntry3([...dataDelete]) : setAllEntry4([...dataDelete]);
            let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
            handleTotalAmt([...dataDelete], sell);
            // onTransactionGridRefresh()
            resolve()
        }
        else {
            deleteFixedIncome(oldData.fdId).then(async (res) => {
                if (res.data._response.status === 1) {
                    Swal.fire(res.data._response.message, '', 'success');
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : show3 === true ? setAllEntry3([...dataDelete]) : setAllEntry4([...dataDelete]);
                    let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
                    handleTotalAmt([...dataDelete], sell);
                    resolve()
                }
                else {
                    Swal.fire(res.data._response.message, '', 'info');
                    reject()
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    //function for updating the existing row details
    const handleRowUpdateSell = (newData, oldData, resolve, reject) => {
        let cpy = newData.company.label;
        let companyData = cpy === newData.companyId ? newData.company : newData.companyId;
        let stockE = newData.stockValuation.label;
        let stockValuation = stockE === newData.stockValuationId ? newData.stockValuation : newData.stockValuationId;
        let folioNo = null;
        let folioData = null;
        if (show2 || show4) {
            folioNo = newData.unlistedFolio.label;
            folioData = folioNo === newData.folioNumber ? newData.unlistedFolio : newData.folioNumber;
        }
        let sumSellStock = 0;
        for (let i = 0; i < selectionQty.length; i++) {
            sumSellStock += selectionQty[i].sellQuantity;
        }
        let errorsList = [];
        if (show1 || show4 || show3) {
            if (!newData.quantity) {
                errorsList.push("Please Enter Quantity Field")
            }
            if (newData.quantity > avilableQty) {
                errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorsList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (!newData.pricePerShare) {
                errorsList.push("Please Enter Price Per Share Field")
            }
            if (!newData.stockValuationId) {
                errorsList.push("Please Select Stock Valuation Type Field")
            }
            if (newData.stockValuationId !== undefined) {
                if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
                    errorsList.push('Please Select Stock Quantity For Selection Calculation')
                }
                if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
                    errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
                }
            }
        }
        if (show2 === true) {
            if (!newData.companyId) {
                errorsList.push("Please Select Company Name Field")
            }
            if (!newData.folioNumber) {
                errorsList.push("please enter Folio Number field")
            }
            if (!newData.quantity) {
                errorsList.push("Please Enter Quantity Field")
            }
            if (newData.quantity > avilableQty) {
                errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorsList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (!newData.pricePerShare) {
                errorsList.push("Please Enter Price Per Share Field")
            }
            // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
            //     errorsList.push("Please Enter Brokerage Per Share Field")
            // }
            if (newData.stockValuationId !== undefined) {
                if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
                    errorsList.push('Please Select Stock Quantity For Selection Calculation')
                }
                if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
                    errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
                }
            }
        }
        if (errorsList.length < 1) {
            const updateStock = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];
            const index = oldData.tableData.id;
            newData.companyId = cpy === newData.companyId ? newData.companyId : newData.companyId.label;
            newData.stockValuationId = stockE === newData.stockValuationId ? newData.stockValuationId : newData.stockValuationId.label;
            newData.company = companyData;
            newData.stockValuation = stockValuation;
            if (show2 || show4) {
                newData.folioNumber = folioNo === newData.folioNumber ? newData.folioNumber : newData.folioNumber.label;
                newData.unlistedFolio = folioData;
            }
            newData.selection = JSON.stringify(selectionQty);
            updateStock[index] = newData;
            show1 === true ? setdata([...updateStock]) : show2 === true ? setdata1([...updateStock]) : show3 === true ? setdata2([...updateStock]) : setdata3([...updateStock]);
            resolve()
            let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            handleTotalAmt(buy, [...updateStock]);
            setErrorMessagesSell([])
            setIsError(false)
        } else {
            setErrorMessagesSell(errorsList)
            setIsError(true)
            reject();
        }
    }
    //function for deleting a row
    const handleRowDeleteSell = async (oldData, resolve, reject) => {
        const dataDelete = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];
        if (oldData.fdId === undefined) {
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            await show1 === true ? setdata([...dataDelete]) : show2 === true ? setdata1([...dataDelete]) : show3 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
            let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            handleTotalAmt(buy, [...dataDelete]);
            resolve()
        }
        else {
            deleteFixedIncome(oldData.fdId).then(async (res) => {
                if (res.data._response.status === 1) {
                    Swal.fire(res.data._response.message, '', 'success');

                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    await show1 === true ? setdata([...dataDelete]) : show2 === true ? setdata1([...dataDelete]) : show3 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
                    let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
                    handleTotalAmt(buy, [...dataDelete]);
                    resolve()
                }
                else {
                    Swal.fire(res.data._response.message, '', 'info');
                    reject()
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    //function for adding a new row to the table
    const handleRowAddSell = async (newData, resolve, reject) => {

        let quantityN = Number(newData.quantity)
        let resultBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
        let qty = undefined;
        let company = newData.companyId;
        let stockValuation = newData.stockValuationId;
        let unlistedFolio = show2 || show4 ? newData.folioNumber : null
        let sumSellStock = 0;
        for (let i = 0; i < selectionQty.length; i++) {
            sumSellStock += selectionQty[i].sellQuantity;
        }
        let errorsList = []
        if (show1 || show4 || show3) {
            if (!newData.companyId) {
                errorsList.push("Please Select Company Name Field")
            }
            if (!newData.quantity) {
                errorsList.push("Please Enter Quantity Field")
            }
            else if (newData.quantity > avilableQty) {
                errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorsList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (!newData.pricePerShare) {
                errorsList.push("Please Enter Price Per Share Field")
            }
            // if (!newData.brokeragePerShare) {
            //   errorsList.push("Please Enter Brokerage Per Share Field")
            // }
            if (!newData.stockValuationId) {
                errorsList.push("Please Select Stock Valuation Type Field")
            }
            if (newData.stockValuationId !== undefined) {
                if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
                    errorsList.push('Please Select Stock Quantity For Selection Calculation')
                }
                if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
                    errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
                }
            }
            // if(newData.quantity < )
        }
        if (show2 === true) {
            if (!newData.companyId) {
                errorsList.push("Please Select Company Name Field")
            }
            // if (!newData.folioNumber) {
            //     errorsList.push("please enter Folio Number field")
            // }
            if (!newData.quantity) {
                errorsList.push("Please Enter Quantity Field")
            }
            else if (!newData.quantity && newData.quantity > avilableQty) {
                errorsList.push("please enter quantity in range of available quantity");
            }
            else if (newData.quantity) {
                let qvalue = parseFloat(newData.quantity);
                if (Number.isInteger(qvalue) === false) {
                    errorsList.push("Please Don't Enter Decimal Value In Quantity Field!");
                }
            }
            if (!newData.pricePerShare) {
                errorsList.push("Please Enter Price Per Share Field")
            }
            // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
            //     errorsList.push("Please Enter Brokerage Per Share Field")
            // }
            if (!newData.stockValuationId) {
                errorsList.push("Please Select Stock Valuation Type Field")
            }
            if (newData.stockValuationId !== undefined) {
                if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
                    errorsList.push('Please Select Stock Quantity For Selection Calculation')
                }
                if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
                    errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
                }
            }
        }


        function filterByCheck(obj) {
            if (obj.tableData.checked !== undefined) {
                // setSelection2({...selection2,obj});
                return true
            }
            return false;
        }

        let arrayById = selectionQty.filter(filterByCheck).map(i => {
            // console.log(i);
            setSelection2({ ...selection2, i });
        })
        // setSelection2({...selection2 ,arrayById})
        if (errorsList.length < 1) {
            let newUserdata = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];

            newData.selection = JSON.stringify(selectionQty);

            if (show2 || show4) {
                newData.folioNumber = newData.folioNumber.label;
                newData.unlistedFolio = unlistedFolio;
            }
            newData.companyId = newData.companyId.label;
            newData.stockValuationId = newData.stockValuationId.label;
            newData.company = company;
            newData.quantity = quantityN
            newData.stockValuation = stockValuation;
            newData.availableQuantity = avilableQty;
            newData.availableSellQuantity = avilableSellQty;
            // newData.selection =  JSON.stringify(selectionQty);
            newUserdata.push(newData);
            var result = newUserdata.map(function (o) {
                if (o.stockId === undefined) {
                    o.transactStockType = "Sell";
                }
                return o;
            })

            var newResult = result.map(function (o) {
                if (o.stockId === undefined && o.stockValuation.value === 3) {
                    o.transactStockType = "Sell";
                }
                return o;
            })
            // console.log("finalData", newResult);
            await show1 === true ? setdata(newResult) : show2 === true ? setdata1(newResult) : show3 === true ? setdata2(newResult) : setdata3(newResult);
            resolve()
            let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            handleTotalAmt(buy, result);
            setErrorMessagesSell([])
            setIsError(false);
            //setAvilableQty(0);
        }
        else {
            setErrorMessagesSell(errorsList)
            setIsError(true)
            reject()
        }
    }

    const editComponent = (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                    if (props.value.label !== undefined) {
                        if (props.value.label !== props.rowData.stockValuation.label) {
                            ddValue = props.value;

                        }
                        else {
                            ddValue = props.rowData.stockValuation;

                        }
                    }
                    else {
                        ddValue = props.rowData.stockValuation;

                        if (ddValue.value === 3) {
                            //setSelectionQty(JSON.parse(props.rowData.selection));
                            //getSelection(props, ddValue.value);
                        }
                    }
                }
            }
        }
        return (
            <Select
                value={ddValue || null}
                //  onFocus={() => SelectionSelect(props, ddValue)}
                onChange={(selected) => {
                    props.onChange(selected)
                    if (selected.label === "Selection") {
                        getSelection(props, selected.value);
                    }
                }}
                isOptionDisabled={handleDisableOption}
                options={props.rowData.fdId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 5) ? updatedStockVaualtion : stockValuationType}
                name="stockValuationId"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.fdId !== undefined && (props.rowData.stockValuation.value === 1 || props.rowData.stockValuation.value === 2 || props.rowData.stockValuation.value === 5) ? true : props.rowData.companyId === undefined ? true : false}
            />
        )
    };

    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleCloseModalPopUp = () => {
        setShowUnlisted(false);
        // setCertificateorfolinumber(null);
        setLockinperiod('');
        setTenure('');
        // setTime([]);
        setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
        setDividend(0);
        setDateofmaturityorconversion(new Date());
        // setDividendfrequency([]);
        setMaturityvalue(0);
        setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
        setFormErr('');
        setIsError(false)
        // setCost(0)
    }
    const handleCloseModalPopUpAdd = (t) => {
        setCertificateorfolinumber('');
        setLockinperiod('');
        setTenure('');
        // setDate(new Date())
        setTime([{ label: "Month", value: "Month" }])
        setFrequencyValue([{ label: "Monthly", value: 2 }])
        setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
        setDividend(0);
        setDateofmaturityorconversion(new Date());
        setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
        setFormErr('');
        setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
        setIsError(false);
        setMaturityvalue(0)
    }
    const handleDisableOption = (option) => {
        if (option.value === 1 || option.value === 2) {
            return true
        }
    }
    const getSelection = (row, stockValId) => {
        let fi_id = row.rowData.fdId;
        let selection = row.rowData.selection;
        let Id = entityId == null ? subAccountId : entityId;
        let emptyArr = row.rowData.selection;
        let editing = row.rowData.tableData === undefined ? undefined : row.rowData.tableData.editing;
        if (stockValId === 3 && Id != null && (row.rowData.companyId !== undefined || row.rowData.company !== undefined)) {
            let companyId = row.rowData.companyId.value === undefined ? row.rowData.company.value : row.rowData.companyId.value;
            setSelection(true);
            setLoading(true);

            if (selection === undefined || emptyArr === [] || editing === "update") {
                SelectionList(Id, value, companyId).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            let AvlQuantity = res.data._response.data.fetchSelectionCompany;
                            
                            let selectionQtys = [];
                            AvlQuantity && AvlQuantity.map((r, index) => {
                                // return { id: index, FDId: r.fdId,sellQuantity: 0.00, DatePurchase: r.date, AvailableQuantity: r.availableQuantity, Price: r.pricePerShare }
                                // debugger
                                if (selectionQty.length !== 0) {
                                    selectionQty && selectionQty.map((s, i) => {
                                        if (i === index) {
                                            let a = r;
                                            let totalSellQuantity = s.sellQuantity <= s.totalSellQuantity ? (s.totalSellQuantity + s.sellQuantity) : s.sellQuantity;
                                            let selectionminusquantity = r.availableQuantity - (s.sellQuantity + (s.totalSellQuantity ? s.totalSellQuantity : 0.00))
                                            // (s.sellQuantity <=  s.totalSellQuantity ? s.totalSellQuantity : s.sellQuantity);
                                            selectionQtys.push({ id: index, fdId: r.fdId, sellQuantity: 0.00, totalSellQuantity: totalSellQuantity, DatePurchase: r.date, AvailableQuantity: selectionminusquantity, Price: r.pricePerShare })
                                        }
                                    })
                                } else {
                                    selectionQtys.push({ id: index, fdId: r.fdId, sellQuantity: 0.00, totalSellQuantity: 0.00, DatePurchase: r.date, AvailableQuantity: r.availableQuantity, Price: r.pricePerShare })
                                }
                            })
                            setSelectionQty(selectionQtys);
                            setSelectedRows([]);
                        }
                        else {
                            setSelectionQty([]);
                            setSelectedRows([]);
                        }
                    } else {
                        setLoading(false);
                        setError(res.data.message)
                    }
                }).catch(e => {
                    setError(e.message)
                    setLoading(false);
                })
            } else {

                // let parseData = JSON.parse(row.rowData.selectionAll);
                let parseData = JSON.parse(row.rowData.selection);
                setSelectionQty(parseData);
                setLoading(false);
            }
        }

        if(fi_id){
            updateSelectionList(fi_id).then(res =>{
                if(res.status === 200){
                    setLoading(false);
                    if(res.data._response.status === 1){
                        // debugger
                        let s = res.data._response.data.selectionDataForFixedIncomeUpdate;
                        let selectionQtys = s && s.map((r,index) => {
                            return{
                                id: index,
                                fdId: r.fdId,
                                sellQuantity: 0.00,
                                AvailableQuantity: r.quantity,
                                DatePurchase: r.dateOfInvestment,
                                Price: r.pricePerShare,
                                sellfdId: r.sellfdId
                            }
                        })
                        setSelectionQty(selectionQtys);
                    } else {
                        setLoading(false);
                    }
                }
            }).catch(e => {
                setError(e.message);
                setLoading(false);
                console.log(e, "error in fixed income selection update")
            })
        }
    }
    // amount total 
    const handleTotalAmt = (buy, sell) => {
        let StockDetail = [...buy, ...sell];
        var sumSellStock = 0;
        var sumBuyStock = 0;
        for (let i = 0; i < StockDetail.length; i++) {
            if (StockDetail[i].transactStockType === "Sell") {
                sumSellStock += StockDetail[i].totalCost;
            }
            else if (StockDetail[i].transactStockType === "Buy") {
                sumBuyStock += StockDetail[i].totalCost;
            }
        }
        let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(stt) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stampDuty));
        setTotalAmountDue(TotalAmtDueToFromBroker);
    }

    const handleCancel = () => {
        setErrorMessages([]);
        setIserror(false);
        setFormErr({});
        clearForm();
        onHideFD();
        setShow5(false)
        setShow6(false);
        setShow7(false);
        setShow8(false);
    }

    const clearForm = () => {
        handleCloseModalPopUp();
        setDate(new Date());
        setTypeValue([{ label: "Normal", value: "Normal" }]);
        if(show1 || show3){
            setBrokerValue([]);
        } else {
            setBrokerValue(brokerOptions[0])
        }
        setNarration('');
        setSTT(0);
        setGST(0);
        setOtherCharges(0);
        setTotalAmountDue(0);
        setSellingCompanyList([]);
        setSubAccountId(null);
        setAllEntry1([]);
        setAllEntry2([]);
        setAllEntry3([]);
        setAllEntry4([]);
        setdata([]);
        setdata1([]);
        setdata2([]);
        setdata3([]);
        setHeaderId(0);
        setMtooltip(false);
        setStampDuty(0);
        setUnlistedSellingCompanyList([]);
        // setUnlistedCompanyList([]);
        setValue(null);
        setSelectionQty([]);
        setcall(null);
        setFolioNoList([]);
    }

    // selection modal close
    const selectionHide = () => {
        setSelection(false);
        setErrorMessagesQntity([])
    }

    const ListedCompanySelect = (ddValue, currentQty) => {
        // debugger
        if (brokerValue.length < 1 || brokerValue.length === 0 ) {
            setMtooltip(true)
        }
        else {
            setMtooltip(false);
            if (ddValue !== undefined) {
                let resultBuy = show1 ? allEntry1 : allEntry3;
                let resultSell = show1 ? data : data2;
                // setLoading(true);
                let comapnyId = ddValue.value;
                //comapnyId = row.rowData.companyId.value;
                let quantity = 0;
                let editQuantity = 0;

                resultBuy && resultBuy.map((r) => {
                    if (r.company.value === comapnyId && r.fdId === undefined) {
                        quantity += r.quantity;
                    }
                })
                resultSell && resultSell.map((r) => {
                    if (r.company.value === comapnyId && r.fdId === undefined) {
                        quantity -= r.quantity;
                    }
                })
                // handle changed qty of entry with stockId , @stockQty -old qty , @quantity- new changed qty
                resultSell && resultSell.map((r) => {
                    if (r.company.value === comapnyId && r.quantity !== r.stockQty && r.fdId !== undefined) {
                        // editQuantity = Number(r.quantity) > Number(r.stockQty) ? Number(r.quantity) - Number(r.stockQty) : -(Number(r.stockQty) - Number(r.quantity))

                        editQuantity = Number(r.quantity) > Number(r.stockQty) ? (Number(r.quantity) - Number(r.stockQty)) : -(r.stockQty - r.quantity)
                    }
                })
                let Id = entityId == null ? subAccountId : entityId;


                AvilableQuantity(Id, comapnyId, value).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 2) {
                            setAvilableQty(0 + quantity);
                        }
                        else {
                            let AvlQuantity = res.data._response.data.remainingQuantity;
                            // setAvilableQty(AvlQuantity + quantity);
                            setAvilableQty(AvlQuantity + quantity - editQuantity);
                            setAvilableSellQty(AvlQuantity);
                        }
                    } else {
                        setError(res.data.message)
                        setAvilableQty(0 + quantity);
                        setLoading(false);
                    }
                }).catch(e => {
                    setError(e.message)
                    setLoading(false);
                })

            }
        }
    }
    const AvaliableSellQty = (d) => {
        let brkId = d.brokerId;
        let compId = d.company.value
        let rowId = d.tableData.id;
        let Id = entityId == null ? subAccountId : entityId;

        //current transaction quantity
        let curreQty = d.stockQty;

        let quantity = 0;
        let editQuantity = 0;

        let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : show3 ? allEntry3 : allEntry4;
        let resultSell = show1 ? data : show2 ? data1 : show3 ? data2 : data3;

        resultBuy && resultBuy.map((r) => {
            if (r.company.value === compId && r.fdId === undefined) {
                quantity += r.quantity;
            }
        })
        resultSell && resultSell.map((r) => {
            if (r.company.value === compId && r.fdId === undefined) {
                quantity -= r.quantity;
            }
        })

        AvilableQuantity(Id, compId, brkId).then(res => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 2) {
                    setAvilableQty(0 + curreQty + quantity);
                }
                else {
                    let AvlQuantity = res.data._response.data.remainingQuantity;
                    setAvilableQty(AvlQuantity + curreQty + quantity);
                }
            } else {
                setError(res.data.message)
                setAvilableQty(0 + curreQty + quantity);
                setLoading(false);
            }
        }).catch(e => {
            setError(e.message)
            setLoading(false);
        })
    }

    const AvailableSellingQtyForUpdate = (d) => {
        let compId = d.company.value;
        let rowId = d.tableData.id;
        let remainingQty = d.availableSellQuantity;

        let quantity = 0;
        let editQuantity = 0;

        let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : show3 ? allEntry3 : allEntry4;
        let resultSell = show1 ? data : show2 ? data1 : show3 ? data2 : data3;

        resultBuy && resultBuy.map((r) => {
            if (r.company.value === compId && r.fdId === undefined) {
                quantity += r.quantity;
            }
        })
        resultSell && resultSell.map((r) => {
            if (r.company.value === compId && r.fdId === undefined && r.tableData.id !== rowId) {
                quantity -= r.quantity;
            }
        })
        // handle changed qty of entry with fdId , @stockQty -old qty , @quantity- new changed qty
        resultSell && resultSell.map((r) => {
            if (r.company.value === compId && r.tableData.id !== rowId && r.quantity !== r.stockQty && r.fdId !== undefined) {
                editQuantity = Number(r.quantity) > Number(r.stockQty) ? Number(r.quantity) - Number(r.stockQty) : -(r.stockQty - r.quantity)
            }
        })
        setAvilableQty(remainingQty + quantity - editQuantity);

    }


    //submit additional info modal 
    const submitPreModal = () => {
        let formErr = []
        let isValid = true;

        // if (show3) {
        if (show1 || show3) {
            // if (lockinperiod == "" || lockinperiod == null) {
            //     formErr["lockinperiod"] = "Please fill Lock In Period field";
            //     isValid = false;
            // }
            if (cumulativeornoncumulative.value === "Cumulative") {
                if (!maturityvalue || maturityvalue <= 0) {
                    formErr["maturityValue"] = "Please fill valid values!";
                    isValid = false;
                } else {
                    if (!cost || cost <= 0) {
                        formErr["maturityValue"] = "Please fill valid values!";
                        isValid = false;
                    }
                }
            }
            if (!dividend || dividend <= 0) {
                formErr["dividend"] = "Please fill valid values!!";
                isValid = false;
            }
            if (dateofmaturityorconversion && date) {
                let trandate = moment(fixTimezoneOffset(date)).format('L');
                let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
                if (maturityDate === trandate) {
                    formErr["mdate"] = "Maturity Date not same as Investment date!";
                    isValid = false;
                }
            }
        }
        if (show2 || show4) {
            if (certificateorfolinumber === "" || certificateorfolinumber == null) {
                formErr["certificateorfolinumber"] = "Please fill Certificate/Folio Number field";
                isValid = false;
            }
            // if (lockinperiod == "" || lockinperiod == null) {
            //     formErr["lockinperiod"] = "Please fill Lock In Period field";
            //     isValid = false;
            // }
            if (cumulativeornoncumulative.value === "Cumulative") {
                if (!maturityvalue || maturityvalue <= 0) {
                    formErr["maturityValue"] = "Please fill valid values!";
                    isValid = false;
                } else {
                    if (!cost || cost <= 0) {
                        formErr["maturityValue"] = "Please fill valid values!";
                        isValid = false;
                    }
                }
            }
            if (!dividend || dividend <= 0) {
                formErr["dividend"] = "Please fill valid values!!";
                isValid = false;
            }
            if (dateofmaturityorconversion && date) {
                let trandate = moment(fixTimezoneOffset(date)).format('L');
                let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
                if (maturityDate === trandate) {
                    formErr["mdate"] = "Maturity Date not same as Investment date!";
                    isValid = false;
                }
            }

        }
        if (isValid === true) {
            handlecloseDescModal();
        }
        setFormErr(formErr);
        return isValid;

        // console.log(dateofmaturityorconversion, convertType, lockinperiod + timeValue, certificateorfolinumber, dividend, frequencyValue, maturityvalue, cumulativeType)

    }

    // error handleing
    const errHandle = () => {

        let formErr = {};
        let isValid = true;
        let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
        let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
        if (brokerValue.length < 1) {
            isValid = false;
            formErr["broker"] = "Please select Broker!";
        }
        if (resultDataBuy.length <= 0 && resultDataSell.length <= 0) {
            isValid = false;
            Swal.fire('Please fill Buy/Sell Data!!', "", "warning");
        }
        setFormErr(formErr)
        return isValid
    }

    const handleCloseMod = () => {
        setFormErr({})
        setErrorMessages([])
        setIsError(false)
        setIserror(false)
        clearForm();
        // onHide();
    }
    // Add fixed transaction
    const AddFixedStransaction = async () => {
        let isValid = errHandle()
        let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
        let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
        if (isValid === true) {
            setLoading(true);
            var deepCloneBuy = _.cloneDeep(resultDataBuy);
            var resultBuy = deepCloneBuy.map(function (o) {
                o.companyId = o.company.value;
                return o;
            })
            var deepCloneSell = _.cloneDeep(resultDataSell);
            var resultSell = deepCloneSell.map(function (o) {
                o.companyId = o.company.value;
                o.stockValuationId = o.stockValuation.value;
                return o;
            })

            let listed = {};
            listed = {
                instrumentId: show1 || show2 ? 2 : 3,
                instrumentTypeId: show1 === true ? 12 : show2 === true ? 21 : show3 === true ? 13 : 22,
                // instrumentTypeId: IdInst,
                date: fixTimezoneOffset(date),
                subAccountId: subAccountId,
                // subAccountId: subAccountId,
                Type: typeStock,
                brokerId: value,
                fdDetails: [...resultBuy, ...resultSell],
                stampDuty: stampDuty,
                sst: stt,
                gst: gst,
                otherCharges: otherCharges,
                narration: narration,
                totalAmountDue: totalAmountDue
            }
            // console.log(listed);
            await addFixedIncome(listed).then(res => {
                if (res.status === 200) {
                    if(res.data._response.status === 1){
                    setLoading(false)
                    Swal.fire(res.data._response.message, "", 'success');
                    setSuccess(true);
                    handleCloseMod();
                    clearForm();
                    // onTransactionGridRefresh()
                    }
                } else {
                    setLoading(false);
                }
            }).catch(e => {
                setError(e.message)
                setLoading(false)
            })
        } else {
            console.log(formErr)
        }
    }

    // update Stock transaction
    const UpdateFixedtransaction = async () => {
        // 
        let isValid = errHandle()
        if (isValid === true) {
            setLoading(true);
            let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
            var deepCloneBuy = _.cloneDeep(resultDataBuy);
            var resultBuy = deepCloneBuy.map(function (o) {
                if (o.company !== undefined) {
                    o.companyId = o.company.value;
                    o.stockValuationId = o.fdId !== undefined ? o.stockValuation.value : 4;
                }
                return o;
            })
            var deepCloneSell = _.cloneDeep(resultDataSell);
            var resultSell = deepCloneSell.map(function (o) {
                if (o.company !== undefined) {
                    o.companyId = o.company.value;
                }
                if (o.stockValuation !== undefined) {
                    o.stockValuationId = o.stockValuation.value;
                }
                return o;
            })
            let listed = {};
            listed = {
                FDHeaderId: headerId,
                instrumentId: show1 || show2 ? 2 : 3,
                instrumentTypeId: show1 === true ? 12 : show2 === true ? 21 : show3 === true ? 13 : 22,
                date: fixTimezoneOffset(date),
                subAccountId: subAccountId,
                Type: typeStock,
                brokerId: value,
                fdDetails: [...resultBuy, ...resultSell],
                stampDuty: stampDuty,
                sst: stt,
                gst: gst,
                otherCharges: otherCharges,
                narration: narration,
                totalAmountDue: totalAmountDue
            }
            await updateFixedIncome(listed).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("Changes done successfully!", "", 'success');
                        setIsError(false)
                        onRefreshInnerGrid()
                        // onTransactionGridRefresh();
                    } else {
                        Swal.fire("Something went wrong!", "", 'info');
                    }
                } else {
                    setLoading(false)
                }
            }).catch(e => {
                setError(e.message)
                setLoading(false)
            })
        }
    }

    //delete FD transaction
    const DeleteFDtransaction = () => {
        // swal({
        //   title: "Are you sure?",
        //   text: "Once deleted, you will not be able to recover this Data!",
        //   icon: "warning",
        //   buttons: true,
        //   dangerMode: true,
        // })
        //   .then((willDelete) => {
        //     if (willDelete) {
        //       DeleteFDTransactions(headerId).then((response) =>{
        //         if(response.status == 200){
        //           console.log(response, "delete response");
        //           swal({
        //             text: response.data._response.message,
        //             icon: "success",
        //           })
        //   onTransactionGridRefresh();
        //         }else{
        //           swal({
        //             title: "Ooops!!",
        //             text: "Somthing Wrong",
        //             icon: "warning",
        //           })
        //         }

        //       })
        //       .catch((error) =>{
        //         console.log(error,"error in delete transaction")
        //       })

        //     }
        //   });
    }







    //for sell columns
    useEffect(() => {

        //listed sell
        setcolumnsS1([
            {
                title: 'Company Name', field: 'companyId',
                width: 250,
                editComponent: (props) => {
                    let ddValue = props.value;
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.company.label) {
                                        ddValue = props.value;
                                    }
                                    else {
                                        ddValue = props.rowData.company;
                                    }
                                }
                                else {
                                    ddValue = props.rowData.company;
                                    // ListedCompanySelect(ddValue);
                                }
                            }
                        }
                    }
                    return (
                        <>

                            <Select
                                value={sellingCompanyList.length === 0 ? null : ddValue || null}
                                onChange={(selected) => {
                                    props.onChange(selected)
                                    ListedCompanySelect(selected, 0)
                                }}
                                options={sellingCompanyList}
                                name="companyId"
                                onFocus={() => { setcall(1); brokerSelect() }}
                                searchable={true}
                                clearable={true}
                                noResultsText={"No result"}
                                placeholder="Select Company.."
                                onKeyDown={e => handleKey(e)}
                                menuPosition="fixed"
                                menuShouldBlockScroll="true"
                            />{Mtooltip === true ? <span style={{ color: "red" }}>Please select Broker!</span>
                                : brokerValue.value !== undefined && sellingCompanyList.length === 0 ? <span style={{ color: "red" }}>There are no companies under this broker!</span> : null}
                        </>
                    )
                },
            },
            {
                field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
                render: (rowData) => (
                    <span>
                        {NumberOnlySeperator(rowData.quantity)}
                    </span>
                ),
                // validate: (rowData) =>
                //     rowData.quantity === 0
                //         ? { isValid: false, helperText: 'quantity cannot be empty' }
                //         : rowData.quantity > avilableQty ? { isValid: false, helperText: 'please enter quantity in range of available quantity' } : true,
                editComponent: ({ ...props }) => {

                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {

                                if (props.rowData.fdId !== undefined) {
                                    AvaliableSellQty(props.rowData);
                                } else {
                                    AvailableSellingQtyForUpdate(props.rowData);
                                    // setAvilableQty(props.rowData.availableQuantity + props.rowData.quantity)
                                }

                            }
                        }
                    }
                    return (
                        <Tooltip title={`The Available quantity with this broker is:${avilableQty}`} arrow open={avilableQty >= 0 && props.rowData.companyId !== undefined ? true : avilableQty === undefined ? false : false}>
                            {/* <MTableEditField {...props} placeholder={'quantity'} disabled={enabledAdisabled} /> */}
                            <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                                <Cleave
                                    className="MuiInputBase-input MuiInput-input"
                                    placeholder='Quantity'
                                    autoComplete='off'
                                    options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                                    value={props.value}
                                    onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                            </div>
                            </div>
                        </Tooltip>
                    )
                },
            },
            {
                field: 'pricePerShare', title: show1 ? 'Price/Debenture' : 'Price/Bonds', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder={show1 ? 'Price/Debenture' : 'Price/Bonds'}
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled={enabledAdisabled}
                            onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                    </div>
                    </div>
                    //  <MTableEditField {...props} disabled={enabledAdisabled} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.pricePerShare)}
                    </span>
                ),
            },
            {
                field: 'brokeragePerShare', title: show1 ? 'Brokerage/Debenture' : 'Brokerage/Bonds', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    let brkShare = props.rowData.brokeragePerShare ? props.rowData.brokeragePerShare : 0
                    props.rowData.brokeragePerShare = brkShare;
                    props.value = brkShare;
                    return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder={show1 ? 'Brokerage/Debenture' : 'Brokerage/Bonds'}
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled={enabledAdisabled}
                            onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                    </div>
                    </div>
                    // <MTableEditField  {...props} disabled={enabledAdisabled} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.brokeragePerShare)}
                    </span>
                ),
            },
            {
                field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    let totalBrokrage = 0;
                    totalBrokrage = props.rowData.brokeragePerShare ? props.rowData.quantity * props.rowData.brokeragePerShare : 0;
                    props.value = totalBrokrage.toFixed(2);
                    props.rowData.totalBrokerage = totalBrokrage;
                    return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                    <Cleave
                      className="MuiInputBase-input MuiInput-input"
                      placeholder='Total Brokerage'
                      autoComplete='off'
                      options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                      value={props.value}
                      onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                  </div>
                  </div>
                    //  <MTableEditField  {...props} disabled={true} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.totalBrokerage)}
                    </span>
                ),
            },
            {
                field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    let totalcost = 0;
                    totalcost = (props.rowData.quantity * props.rowData.pricePerShare) - (props.rowData.totalBrokerage);
                    props.value = totalcost.toFixed(2);
                    props.rowData.totalCost = totalcost;
                    return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                    <Cleave
                      className="MuiInputBase-input MuiInput-input"
                      placeholder='Total Cost'
                      autoComplete='off'
                      options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                      value={props.value}
                      onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                  </div>
                  </div>
                    // <MTableEditField {...props} disabled={true} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.totalCost)}
                    </span>
                ),
            },
            {
                title: 'Stock Valuation Type', field: 'stockValuationId',
                validate: (rowData) =>
                    rowData.companyId === undefined
                        ? { isValid: false, helperText: 'first select company and quantity!' } : true,
                editComponent,
            },
        ])
        //columns for unlisted sell

        setcolumnsS2([
            {
                title: 'Company Name', field: 'companyId', width: 250,
                editComponent: (props) => {
                    let ddValue = props.value;
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.company.label) {
                                        ddValue = props.value;
                                    }
                                    else {
                                        ddValue = props.rowData.company;
                                    }
                                }
                                else {
                                    ddValue = props.rowData.company;
                                    //  CompanySelect(ddValue)
                                }
                            }
                        }
                    }
                    return (
                        <>
                            <Select
                                value={ddValue || null}
                                onChange={(selected) => {
                                    props.onChange(selected)
                                    CompanySelect(selected, 0)
                                }}
                                onFocus={() => setcall(1)}
                                options={sellingCompanyList}
                                // options={unlistedSellingCompany}
                                name="companyId"
                                onKeyDown={e => handleKey(e)}
                                menuPosition="fixed"
                                menuShouldBlockScroll="true"
                            />
                        </>
                    )
                },
            },
            {
                field: 'folioNumber', title: 'Folio Number ', width: 130,
                editComponent: (props) => {
                    let ddValue = props.value;
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.unlistedFolio.label) {
                                        ddValue = props.value;
                                    }
                                    else {
                                        ddValue = props.rowData.unlistedFolio;
                                    }
                                }
                                else {
                                    ddValue = props.rowData.unlistedFolio;
                                    //  CompanySelect(ddValue)
                                }
                            }
                        }
                    }
                    return (
                        <>
                            <Select
                                value={ddValue || null}
                                onChange={(selected) => {
                                    props.onChange(selected)
                                }}
                                // onFocus={() => setcall(1)}
                                options={folioNoList}
                                searchable={true}
                                clearable={true}
                                noResultsText={"No result"}
                                placeholder="Select folio.."
                                name="folio"
                                onKeyDown={e => handleKey(e)}
                                menuPosition="fixed"
                                menuShouldBlockScroll="true"
                                isDisabled={props.rowData.stockId !== undefined ? true : false}
                            />
                        </>
                    )
                },
            },
            {
                field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
                render: (rowData) => (
                    <span>
                        {NumberOnlySeperator(rowData.quantity)}
                    </span>
                ),
                // validate: (rowData) =>
                //     rowData.quantity === 0
                //         ? { isValid: false, helperText: 'quantity cannot be empty' }
                //         : rowData.quantity > avilableQty ? { isValid: false, helperText: 'please enter quantity in range of available quantity' } : true,
                editComponent: ({ ...props }) => {
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.rowData.fdId !== undefined) {
                                    AvaliableSellQty(props.rowData)
                                    // CompanySelect(props.rowData.company, props.rowData, props.rowData.fdId)
                                } else {
                                    AvailableSellingQtyForUpdate(props.rowData);
                                    // setAvilableQty(props.rowData.availableQuantity)
                                }
                            }
                        }
                    }
                    return (
                        <Tooltip title={`The Available quantity with this company is:${avilableQty}`} arrow open={avilableQty >= 0 && props.rowData.companyId !== undefined ? true : false}>
                            {/* <MTableEditField {...props} placeholder={'quantity'}
                            // onFocus={setTooltip(false)}
                            /> */}
                        <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder='Quantity'
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled={enabledAdisabled}
                            onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                        </div>
                       </div>
                        </Tooltip>
                    )
                    // return <MTableEditField {...props} />;
                },
            },
            {
                field: 'pricePerShare', title: show2 ? 'Price/Debenture' : 'Price/Bond', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder={show2 ? 'Price/Debenture' : 'Price/Bond'}
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled={enabledAdisabled}
                            onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                    </div>
                    </div>
                    //  <MTableEditField {...props} onFocus={setTooltip(false)} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.pricePerShare)}
                    </span>
                ),
            },
            // {
            //     field: 'brokeragePerShare', title: show2 ? 'Brokerage/Debenture' : 'Brokerage/Bond', width: 130, type: 'numeric',
            //     editComponent: ({ ...props }) => {
            //         let brkShare =  props.rowData.brokeragePerShare ? props.rowData.brokeragePerShare : 0
            //         props.rowData.brokeragePerShare = brkShare;
            //         props.value = brkShare ; 
            //         return <MTableEditField  {...props} />;
            //     },
            //     render: (rowData) => (
            //         <span>
            //             {NumberSeperator(rowData.brokeragePerShare)}
            //         </span>
            //     ),
            // },
            // {
            //     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
            //     editComponent: ({ ...props }) => {
            //         let totalBrokrage = 0;
            //         totalBrokrage = props.rowData.brokeragePerShare ?  props.rowData.quantity * props.rowData.brokeragePerShare : 0;
            //         props.value = totalBrokrage.toFixed(2);
            //         props.rowData.totalBrokerage = totalBrokrage;
            //         return <MTableEditField  {...props} disabled={true} />;
            //     },
            //     render: (rowData) => (
            //         <span>
            //             {NumberSeperator(rowData.totalBrokerage)}
            //         </span>
            //     ),
            // },
            {
                field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
                editComponent: ({ ...props }) => {
                    let totalcost = 0;
                    totalcost = (props.rowData.quantity * props.rowData.pricePerShare) - (props.rowData.totalBrokerage);
                    props.value = totalcost.toFixed(2);
                    props.rowData.totalCost = totalcost;
                    props.rowData.brokeragePerShare = 0;
                    props.rowData.totalBrokerage = 0;
                    return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                    <Cleave
                      className="MuiInputBase-input MuiInput-input"
                      placeholder='Total Cost'
                      autoComplete='off'
                      options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                      value={props.value}
                      onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                  </div>
                  </div>
                    // <MTableEditField {...props} disabled={true} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.totalCost)}
                    </span>
                ),
            },
            {
                title: 'Stock Valuation Type', field: 'stockValuationId',
                validate: (rowData) =>
                    rowData.quantity === undefined
                        ? { isValid: false, helperText: 'first select company and quantity!' } : true,
                editComponent,
            },
        ])

    }, [avilableQty, folioNoList, sellingCompanyList, show1, show2, showFD, subAccountId,call,Mtooltip,stockValuationType, data, data1, data2, data3])



    const handleKey = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation()
        }
    }

    const columnsB1 = [
        {
            title: 'Company Name', field: 'companyId', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.rowData.tableData.editing === 'update') {
                            if (props.value.label !== undefined) {
                                if (props.value.label !== props.rowData.company.label) {
                                    ddValue = props.value
                                }
                                else {
                                    ddValue = props.rowData.company
                                }
                            }
                            else {
                                ddValue = props.rowData.company
                            }
                        }
                    }
                }
                return (
                    <Select
                        components={{ Control }}
                        value={ddValue || null}
                        onChange={(selected) => props.onChange(selected)}
                        // options={Scrip}
                        options={unlistedCompany}
                        name="companyId"
                        isDisabled={headerId !== 0 ? true : false}
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                    />
                )
            },
        },
        {
            field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
            render: (rowData) => (
                <span>
                    {NumberOnlySeperator(rowData.quantity)}
                </span>
            ),
            editComponent: ({ ...props }) => {
                // handleAddRowBuy();
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Quantity'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
        },
        {
            field: 'pricePerShare', title: 'Price/ Debenture', width: 70, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Price/Debenture'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.pricePerShare)}
                </span>
            ),
        },
        {
            field: 'brokeragePerShare', title: 'Brokerage/ Debenture', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Brokerage/Debenture'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField  {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.brokeragePerShare)}
                </span>
            ),
        },
        {
            field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalBrokrage = 0;
                totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
                props.value = totalBrokrage.toFixed(2);
                props.rowData.totalBrokerage = totalBrokrage;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Brokerage/Debenture'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        disabled
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                //  <MTableEditField  {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalBrokerage)}
                </span>
            ),
        },
        {
            field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalcost = 0;
                totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
                props.value = totalcost.toFixed(2);
                props.rowData.totalCost = totalcost;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Total Cost'
                        autoComplete='off'
                        disabled
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalCost)}
                </span>
            ),
        },
        {
            field: 'target', title: 'Target', width: 130,
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Target'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {rowData.target ? NumberSeperator(rowData.target) : null}
                </span>
            ),
        },
        {
            field: 'stopLoss', title: 'Stop Loss', width: 130,
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Stop Loss'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {rowData.stopLoss ? NumberSeperator(rowData.stopLoss) : null}
                </span>
            ),
        },
        {
            field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
            editComponent: ({ ...props }) => {
                return <MTableEditField {...props} />;
            },
        },
        { field: 'recommendedBy', title: 'Recommended by', width: 130 },
        {
            title: "Addtional Data",
            field: "internal_action",
            // editable: false,
            editComponent: ({ event, ...props }) => {
                // console.log(props);
                let t = props.rowData
                return <span onClick={(e) => {
                    e.preventDefault()
                    if (t.tableData !== undefined) {
                        if (t.interestPerc !== undefined) {
                            setDividend(t.interestPerc);
                        }
                        if (t.frequencyId !== undefined) {
                            // setDividendfrequency(t.frequency)
                            setFrequencyValue(t.frequencyI);
                        }
                        if (t.redeemConvertible !== undefined) {
                            setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                        }
                        if (t.lockInPeriod !== undefined) {
                            let d = t.lockInPeriod
                            let splitData = d.split(/([0-9]+)/);
                            setTimeValue(splitData[2])
                            setTime({ label: splitData[2], value: splitData[2] })
                            setLockinperiod(splitData[1])
                        }
                        if (t.cumulativeNonCumulative !== undefined) {
                            setCumulativeType(t.cumulativeNonCumulative);
                            setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
                        }
                        if (t.folioNumber !== undefined) {
                            setCertificateorfolinumber(t.folioNumber);
                        }
                        if (t.maturityValue !== undefined) {
                            setMaturityvalue(t.maturityValue);
                        }
                        if (t.domConversion !== undefined) {
                            setDateofmaturityorconversion(new Date(t.domConversion));
                        }
                        setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
                    } else {
                        handleCloseModalPopUpAdd(t);
                    }
                    setShowlistedDeb(true)
                }}
                    title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
            }
        }
    ];
    const columnsB2 = [
        {
            title: 'Company Name', field: 'companyId', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.rowData.tableData.editing === 'update') {
                            if (props.value.label !== undefined) {
                                if (props.value.label !== props.rowData.company.label) {
                                    ddValue = props.value
                                }
                                else {
                                    ddValue = props.rowData.company
                                }
                            }
                            else {
                                ddValue = props.rowData.company
                            }
                        }
                    }
                }
                return (
                    <Select
                        components={{ Control }}
                        value={ddValue || null}
                        onChange={(selected) => props.onChange(selected)}
                        // options={Scrip}
                        options={unlistedCompany}
                        name="companyId"
                        isDisabled={headerId !== 0 ? true : false}
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                    />
                )
            },
        },
        {
            field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
            render: (rowData) => (
                <span>
                    {NumberOnlySeperator(rowData.quantity)}
                </span>
            ),
            editComponent: ({ ...props }) => {
                // handleAddRowBuy();
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Quantity'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },

        },
        {
            field: 'pricePerShare', title: 'Price/Debenture', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Price/Debenture'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.pricePerShare)}
                </span>
            ),
        },
        // {
        //     field: 'brokeragePerShare', title: 'Brokerage/Debenture', width: 130, type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         return <MTableEditField  {...props} />;
        //     },
        //     render: (rowData) => (
        //         <span>
        //             {NumberSeperator(rowData.brokeragePerShare)}
        //         </span>
        //     ),
        // },
        // {
        //     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         let totalBrokrage = 0;
        //         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        //         props.value = totalBrokrage.toFixed(2);
        //         props.rowData.totalBrokerage = totalBrokrage;
        //         return <MTableEditField  {...props} />;
        //     },
        //     render: (rowData) => (
        //         <span>
        //             {NumberSeperator(rowData.totalBrokerage)}
        //         </span>
        //     ),
        // },
        {
            field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalcost = 0;
                totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
                props.value = totalcost.toFixed(2);
                props.rowData.totalCost = totalcost;
                props.rowData.brokeragePerShare = 0;
                props.rowData.totalBrokerage = 0;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Total Cost'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        disabled
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalCost)}
                </span>
            ),
        },
        {
            title: "Addtional Data",
            field: "internal_action",
            // editable: false,
            editComponent: ({ ...props }) => {
                let t = props.rowData;
                return <span onClick={(e) => {
                    if (t.tableData !== undefined) {
                        e.preventDefault()
                        if (t.interestPerc !== undefined) {
                            setDividend(t.interestPerc);
                        }
                        if (t.frequencyId !== undefined) {
                            setFrequencyValue(t.frequencyI);
                        }
                        if (t.redeemConvertible !== undefined) {
                            setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                        }
                        if (t.lockInPeriod !== undefined) {
                            let d = t.lockInPeriod
                            let splitData = d.split(/([0-9]+)/);
                            setTimeValue(splitData[2])
                            setTime({ label: splitData[2], value: splitData[2] })
                            setLockinperiod(splitData[1])
                        }
                        if (t.cumulativeNonCumulative !== undefined) {
                            setCumulativeType(t.cumulativeNonCumulative);
                            setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
                        }
                        if (t.folioNumber !== undefined) {
                            setCertificateorfolinumber(t.folioNumber);
                        }
                        if (t.maturityValue !== undefined) {
                            setMaturityvalue(t.maturityValue);
                        }
                        if (t.domConversion !== undefined) {
                            setDateofmaturityorconversion(new Date(t.domConversion));
                        }
                        setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
                    } else {
                        handleCloseModalPopUpAdd(t);
                    }
                    setShowlistedDeb(true)
                }}
                    title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
            }
        }
    ];
    const columnsB3 = [
        {
            title: 'Company Name', field: 'companyId', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.rowData.tableData.editing === 'update') {
                            if (props.value.label !== undefined) {
                                if (props.value.label !== props.rowData.company.label) {
                                    ddValue = props.value
                                }
                                else {
                                    ddValue = props.rowData.company
                                }
                            }
                            else {
                                ddValue = props.rowData.company
                            }
                        }
                    }
                }
                return (
                    <Select
                        components={{ Control }}
                        value={ddValue || null}
                        onChange={(selected) => props.onChange(selected)}
                        // options={Scrip}
                        options={unlistedCompany}
                        name="companyId"
                        isDisabled={headerId !== 0 ? true : false}
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                    />
                )
            },
        },
        {
            field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
            render: (rowData) => (
                <span>
                    {NumberOnlySeperator(rowData.quantity)}
                </span>
            ),
            editComponent: ({ ...props }) => {
                // handleAddRowBuy();
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Quantity'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
        },
        {
            field: 'pricePerShare', title: 'Price/Bond', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Price/Bond'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange((toNumber(e.target.rawValue)))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.pricePerShare)}
                </span>
            ),
        },
        {
            field: 'brokeragePerShare', title: 'Brokerage/Bond', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Brokerage/Bond'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField  {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.brokeragePerShare)}
                </span>
            ),
        },
        {
            field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalBrokrage = 0;
                totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
                props.value = totalBrokrage.toFixed(2);
                props.rowData.totalBrokerage = totalBrokrage;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Total Brokerage'
                        autoComplete='off'
                        disabled
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                //  <MTableEditField  {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalBrokerage)}
                </span>
            ),
        },
        {
            field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalcost = 0;
                totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
                props.value = totalcost.toFixed(2);
                props.rowData.totalCost = totalcost;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Total Cost'
                        autoComplete='off'
                        disabled
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalCost)}
                </span>
            ),
        },
        // {
        //     field: 'currentMp', title: 'Current MP', width: 130, type: 'numeric',
        //     type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         return <MTableEditField  {...props} />;
        //     },
        // },
        // {
        //     field: 'currentMarketValue', title: 'Current Market Value', width: 130, type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         let CurrentMV = 0;
        //         CurrentMV = props.rowData.quantity * props.rowData.currentMp;
        //         props.value = CurrentMV;
        //         props.rowData.currentMarketValue = CurrentMV;
        //         return <MTableEditField {...props} />;
        //     }
        // },
        {
            field: 'target', title: 'Target', width: 130,
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Target'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(e.target.rawValue)} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {rowData.target ? NumberSeperator(rowData.target) : null}
                </span>
            ),
        },
        {
            field: 'stopLoss', title: 'Stop Loss', width: 130,
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Stop Loss'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(e.target.rawValue)} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {rowData.stopLoss ? NumberSeperator(rowData.stopLoss) : null}
                </span>
            ),
        },
        {
            field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
            editComponent: ({ ...props }) => {
                return <MTableEditField {...props} />;
            },
        },
        { field: 'recommendedBy', title: 'Recommended by', width: 130 },
        {
            title: "Addtional Data",
            field: "internal_action",
            // editable: false,
            editComponent: ({ ...props }) => {
                // console.log(props);
                let t = props.rowData
                return <span onClick={(e) => {
                    if (t.tableData !== undefined) {
                        e.preventDefault();
                        if (t.interestPerc !== undefined) {
                            setDividend(t.interestPerc);
                        }
                        if (t.frequencyId !== undefined) {
                            // setDividendfrequency(t.frequency)
                            setFrequencyValue(t.frequencyI);
                        }
                        if (t.redeemConvertible !== undefined) {
                            setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                        }
                        if (t.lockInPeriod !== undefined) {
                            let d = t.lockInPeriod
                            let splitData = d.split(/([0-9]+)/);
                            setTimeValue(splitData[2])
                            setTime({ label: splitData[2], value: splitData[2] })
                            setLockinperiod(splitData[1])
                        }
                        if (t.cumulativeNonCumulative !== undefined) {
                            setCumulativeType(t.cumulativeNonCumulative);
                            setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
                        }
                        if (t.folioNumber !== undefined) {
                            setCertificateorfolinumber(t.folioNumber);
                        }
                        if (t.maturityValue !== undefined) {
                            setMaturityvalue(t.maturityValue);
                        }
                        if (t.domConversion !== undefined) {
                            setDateofmaturityorconversion(new Date(t.domConversion));
                        }
                        setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
                    } else {
                        handleCloseModalPopUpAdd(t);
                    }
                    setShowlistedDeb(true)
                }}
                    title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
            }
        }
    ];
    const columnsB4 = [
        {
            title: 'Company Name', field: 'companyId', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.rowData.tableData.editing === 'update') {
                            if (props.value.label !== undefined) {
                                if (props.value.label !== props.rowData.company.label) {
                                    ddValue = props.value
                                }
                                else {
                                    ddValue = props.rowData.company
                                }
                            }
                            else {
                                ddValue = props.rowData.company
                            }
                        }
                    }
                }
                return (
                    <Select
                        components={{ Control }}
                        value={ddValue || null}
                        onChange={(selected) => props.onChange(selected)}
                        // options={Scrip}
                        options={unlistedCompany}
                        name="companyId"
                        isDisabled={headerId !== 0 ? true : false}
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                    />
                )
            },
        },
        {
            field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
            render: (rowData) => (
                <span>
                    {NumberOnlySeperator(rowData.quantity)}
                </span>
            ),
            editComponent: ({ ...props }) => {
                // handleAddRowBuy();
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Quantity'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(e.target.rawValue)} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
        },
        {
            field: 'pricePerShare', title: 'Price/Bond', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Price/Bond'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.pricePerShare)}
                </span>
            ),
        },
        // {
        //     field: 'brokeragePerShare', title: 'Brokerage/Bond', width: 130, type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         return <MTableEditField  {...props} />;
        //     },
        //     render: (rowData) => (
        //         <span>
        //             {NumberSeperator(rowData.brokeragePerShare)}
        //         </span>
        //     ),
        // },
        // {
        //     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
        //     editComponent: ({ ...props }) => {
        //         let totalBrokrage = 0;
        //         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        //         props.value = totalBrokrage.toFixed(2);
        //         props.rowData.totalBrokerage = totalBrokrage;
        //         return <MTableEditField  {...props} />;
        //     },
        //     render: (rowData) => (
        //         <span>
        //             {NumberSeperator(rowData.totalBrokerage)}
        //         </span>
        //     ),
        // },
        {
            field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let totalcost = 0;
                totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
                props.value = totalcost.toFixed(2);
                props.rowData.totalCost = totalcost;
                props.rowData.brokeragePerShare = 0;
                props.rowData.totalBrokerage = 0;
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Total Cost'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.totalCost)}
                </span>
            ),
        },
        {
            title: "Addtional Data",
            field: "internal_action",
            // editable: false,
            editComponent: ({ ...props }) => {
                let t = props.rowData;
                return <span onClick={(e) => {
                    e.preventDefault();
                    if (t.tableData !== undefined) {

                        if (t.interestPerc !== undefined) {
                            setDividend(t.interestPerc);
                        }
                        if (t.frequencyId !== undefined) {
                            setFrequencyValue(t.frequencyI);
                        }
                        if (t.redeemConvertible !== undefined) {
                            setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                        }
                        if (t.lockInPeriod !== undefined) {
                            let d = t.lockInPeriod
                            let splitData = d.split(/([0-9]+)/);
                            setTimeValue(splitData[2])
                            setTime({ label: splitData[2], value: splitData[2] })
                            setLockinperiod(splitData[1])
                        }
                        if (t.cumulativeNonCumulative !== undefined) {
                            setCumulativeType(t.cumulativeNonCumulative);
                            setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
                        }
                        if (t.folioNumber !== undefined) {
                            setCertificateorfolinumber(t.folioNumber);
                        }
                        if (t.maturityValue !== undefined) {
                            setMaturityvalue(t.maturityValue);
                        }
                        if (t.domConversion !== undefined) {
                            setDateofmaturityorconversion(new Date(t.domConversion));
                        }
                        setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
                    } else {
                        handleCloseModalPopUpAdd(t);
                    }
                    setShowlistedDeb(true);
                }}
                    title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
            }
        }
    ];
    const handleClearOnRadioBtn = () => {
        setDate(new Date());
        setNarration('');
        setSTT(0);
        setGST(0);
        setOtherCharges(0);
        setTotalAmountDue(0);
        setSellingCompanyList([]);
        setAllEntry1([]);
        setAllEntry2([]);
        setAllEntry3([]);
        setAllEntry4([]);
        setdata([]);
        setdata1([]);
        setdata2([]);
        setdata3([]);
        setMtooltip(false);
        setStampDuty(0);
        setBrokerValue([])
        // setSelectionQty([]);
        // setFolioNoList([]);
    }
    return (
        <>
            <div tabIndex={1}>
                <Modal className="custom-dialog addStock"
                    isOpen={showFD}
                    toggle={onHideFD}
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <ModalHeader charCode="Y" toggle={() => onHideFD()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Fixed Income Transaction</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Container fluid>
                            <form>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                                ) : null}
                                <fieldset>
                                    <Row>
                                        <Col md="12" className="mb-2">
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ? <>
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Listed Debenture' checked={show1}
                                                        // onClick={() => { setShow1(true); setShow2(false); setShow3(false); setShow4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); }}
                                                        disabled
                                                    />
                                                    <Form.Label className="form-check-label" htmlFor="inlineRadio1">Listed Debenture</Form.Label>
                                                </>
                                                    : <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Listed Debenture' checked={show1}
                                                            onClick={() => { setShow1(true); setShow2(false); setShow3(false); setShow4(false); setShow5(false); setShow6(false); setShow7(false); setShow8(false); setFormErr({}); setIsError(false); setErrorMessages([]); setUnlistedCompanyList([]); handleCloseModalPopUp(); handleClearOnRadioBtn(); }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio1">Listed Debenture</Form.Label>
                                                    </>}
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='Unlisted Debenture' checked={show2}
                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio2">Unlisted Debenture</Form.Label>
                                                    </>
                                                    : <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='Unlisted Debenture' checked={show2}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(true); setShow3(false); setShow4(false); setShow5(false); setShow6(false); setShow7(false); setShow8(false); setFormErr({}); setIsError(false); setErrorMessages([]); setUnlistedCompanyList([]); handleCloseModalPopUp(); handleClearOnRadioBtn();
                                                                if (brokerOptions.length !== 0) { setBrokerValue(brokerOptions[0]); setValue(brokerOptions[0].value) }
                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio2">Unlisted Debenture</Form.Label>
                                                    </>}
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value='Listed Bonds' checked={show3}
                                                            // onClick={() => { setShow1(false); setShow2(false); setShow3(true); setShow4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); }}
                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio3">Listed Bonds</Form.Label>
                                                    </>
                                                    : <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value='Listed Bonds' checked={show3}
                                                            onClick={() => { setShow1(false); setShow2(false); setShow3(true); setShow4(false); setShow5(false); setShow6(false); setShow7(false); setShow8(false); setFormErr({}); setIserror(false); setErrorMessages([]); setUnlistedCompanyList([]); handleCloseModalPopUp(); handleClearOnRadioBtn(); }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio3">Listed Bonds</Form.Label>
                                                    </>}
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value='Unlisted Bonds' checked={show4}

                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio4">Unlisted Bonds</Form.Label>
                                                    </> :
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value='Unlisted Bonds' checked={show4}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(false); setShow3(false); setShow4(true); setShow5(false); setShow6(false); setShow7(false); setShow8(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); handleCloseModalPopUp();
                                                                setUnlistedCompanyList([]); handleClearOnRadioBtn(); if (brokerOptions.length !== 0) { setBrokerValue(brokerOptions[0]); setValue(brokerOptions[0].value) }
                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio4">Unlisted Bonds</Form.Label>
                                                    </>}
                                            </div>
                                            {/* fd  */}
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value='FD' checked={show5}

                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio5">FD</Form.Label>
                                                    </> :
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value='FD' checked={show5}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(false); setShow3(false); setShow4(false); setShow5(true); setShow6(false); setShow7(false); setShow8(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); setUnlistedCompanyList([]);

                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio5">FD</Form.Label>
                                                    </>}
                                            </div>
                                            {/* Rd  */}
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6" value='RD' checked={show6}

                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio6">RD</Form.Label>
                                                    </> :
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6" value='RD' checked={show6}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false); setShow6(true); setShow7(false); setShow8(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]);

                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio6">RD</Form.Label>
                                                    </>}
                                            </div>

                                            {/* post office  */}
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio7" value='postOffice' checked={show7}

                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio7">Post Office</Form.Label>
                                                    </> :
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio7" value='postOffice' checked={show7}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false); setShow6(false); setShow7(true); setShow8(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]);

                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio7">Post Office</Form.Label>
                                                    </>}
                                            </div>

                                            {/* PPF  */}
                                            <div className="form-check form-check-inline">
                                                {FDHeaderId !== 0 ?
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio8" value='ppf' checked={show8}

                                                            disabled
                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio8">PPF / Provident Fund</Form.Label>
                                                    </> :
                                                    <>
                                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio8" value='ppf' checked={show8}
                                                            onClick={() => {
                                                                setShow1(false); setShow2(false); setShow3(false); setShow4(false); setShow5(false); setShow6(false); setShow7(false); setShow8(true); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]);

                                                            }}

                                                        />
                                                        <Form.Label className="form-check-label" htmlFor="inlineRadio8">PPF / Provident Fund</Form.Label>
                                                    </>}
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>

                                        {
                                            show1 || show2 || show3 || show4 ?
                                                <Col md="4" className="mb-2">
                                                    <><Form.Label>Date <span className="text-danger h6">*</span></Form.Label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={date}
                                                            dateFormat="dd-MM-yyyy"
                                                            onChange={(date) => setDate(date)}
                                                            maxDate={new Date()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={50}
                                                            disabled={headerId !== 0 ? true : false}
                                                        />
                                                    </>
                                                </Col>
                                                : null
                                        }

                                        {/* {
                                            show1 || show3 ?
                                                <Col md="4" className="mb-2">
                                                    <>
                                                        <Form.Label>Type</Form.Label>
                                                        <Dropdown
                                                            value={typeValue}
                                                            name='Type'
                                                            options={stockType}
                                                            onChange={HandleChangeType}
                                                            className="SelectClass"
                                                            searchable={true}
                                                            clearable={true}
                                                            noResultsText={"No result"}
                                                            // onInputChange={HandleInput}
                                                            placeholder="Select broker.."
                                                            // isDisabled={headerId != 0 ? true : false}
                                                            isDisabled
                                                        />
                                                    </>
                                                </Col> : null
                                        } */}
                                        {
                                            show1 || show2 || show3 || show4 ?

                                                <Col md="4" className="mb-2">
                                                    <>
                                                        {show1 || show3 ?
                                                            <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label> :
                                                            <Form.Label>Broker</Form.Label>
                                                        }
                                                        <Dropdown
                                                            value={brokerValue}
                                                            name='Broker'
                                                            options={show1 || show3 ? brokerOptionsListed : brokerOptions}
                                                            onChange={HandleChange}
                                                            className="SelectClass"
                                                            searchable={true}
                                                            clearable={true}
                                                            noResultsText={"No result"}
                                                            // onInputChange={HandleInput}
                                                            placeholder="Select broker.."
                                                            isDisabled={headerId !== 0 ? true : show2 || show4 ? true : false}
                                                        />
                                                    </>
                                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.broker}</div> : null}
                                                </Col> : null
                                        }

                                    </Row>
                                </fieldset>

                                <fieldset>
                                    {/* listed debenture  */}
                                    {show1 === true ? <Row>
                                        <Col md="12" className="BuyTbl table1">
                                            <MaterialTable
                                                title="Buy"
                                                data={allEntry1}
                                                columns={columnsB1}
                                                tableRef={tableRef3}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    search: false,
                                                    paging: false
                                                }}
                                                //  actions={actions}
                                                components={{
                                                    Toolbar: props => (
                                                        <MTableToolbar
                                                            {...props}
                                                            actions={props.actions.filter(a => a.tooltip === "Add")}
                                                        />
                                                    )
                                                }}
                                                editable={{
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowUpdate(newData, oldData, resolve, reject);
                                                        }),
                                                    onRowAdd: (newData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowAdd(newData, resolve, reject)
                                                        }),
                                                    onRowDelete: (oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowDelete(oldData, resolve, reject)
                                                        }),
                                                    onRowAddCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    },
                                                    onRowUpdateCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <div>
                                            {iserror &&
                                                <Alert severity="error">
                                                    <AlertTitle>ERROR</AlertTitle>
                                                    {errorMessages.map((msg, i) => {
                                                        return <div key={i}>{msg}</div>
                                                    })}
                                                </Alert>
                                            }
                                        </div>
                                    </Row> : null}

                                    {/* unlisted debenture */}
                                    {show2 === true ? <Row>
                                        <Col md="12" className="BuyTbl">
                                            <MaterialTable
                                                title="Buy"
                                                data={allEntry2}
                                                columns={columnsB2}
                                                tableRef={tableRef4}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    search: false,
                                                    paging: false
                                                }}
                                                //   actions={actions}
                                                components={{
                                                    // Cell: (props) => (
                                                    //   <Tooltip title={props.value ? props.value : ''}>
                                                    //     <MTableCell {...props} />
                                                    //   </Tooltip>
                                                    // ),
                                                    Toolbar: props => (
                                                        <MTableToolbar
                                                            {...props}
                                                            actions={props.actions.filter(a => a.tooltip === "Add")}
                                                        />
                                                    )
                                                }}
                                                editable={{
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowUpdate(newData, oldData, resolve, reject);
                                                        }),
                                                    onRowAdd: (newData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowAdd(newData, resolve, reject)
                                                        }),
                                                    onRowDelete: (oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowDelete(oldData, resolve, reject)
                                                        }),
                                                    onRowAddCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    },
                                                    onRowUpdateCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <div>
                                            {iserror &&
                                                <Alert severity="error">
                                                    <AlertTitle>ERROR</AlertTitle>
                                                    {errorMessages.map((msg, i) => {
                                                        return <div key={i}>{msg}</div>
                                                    })}
                                                </Alert>
                                            }
                                        </div>
                                    </Row> : null}

                                    {/* listed Bonds  */}
                                    {show3 === true ? <Row>
                                        <Col md="12" className="BuyTbl">
                                            <MaterialTable
                                                title="Buy"
                                                data={allEntry3}
                                                columns={columnsB3}
                                                tableRef={tableRef1}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    search: false,
                                                    paging: false
                                                }}
                                                //  actions={actions}
                                                components={{
                                                    Toolbar: props => (
                                                        <MTableToolbar
                                                            {...props}
                                                            actions={props.actions.filter(a => a.tooltip === "Add")}
                                                        />
                                                    )
                                                }}
                                                editable={{
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowUpdate(newData, oldData, resolve, reject);
                                                        }),
                                                    onRowAdd: (newData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowAdd(newData, resolve, reject)
                                                        }),
                                                    onRowDelete: (oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowDelete(oldData, resolve, reject)
                                                        }),
                                                    onRowAddCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    },
                                                    onRowUpdateCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <div>
                                            {iserror &&
                                                <Alert severity="error">
                                                    <AlertTitle>ERROR</AlertTitle>
                                                    {errorMessages.map((msg, i) => {
                                                        return <div key={i}>{msg}</div>
                                                    })}
                                                </Alert>
                                            }
                                        </div>
                                    </Row> : null}

                                    {/* unlisted bonds  */}
                                    {show4 === true ? <Row>
                                        <Col md="12" className="BuyTbl">
                                            <MaterialTable
                                                title="Buy"
                                                data={allEntry4}
                                                columns={columnsB4}
                                                tableRef={tableRef2}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    search: false,
                                                    paging: false
                                                }}
                                                //  actions={actions}
                                                components={{
                                                    Toolbar: props => (
                                                        <MTableToolbar
                                                            {...props}
                                                            actions={props.actions.filter(a => a.tooltip === "Add")}
                                                        />
                                                    )
                                                }}
                                                editable={{
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowUpdate(newData, oldData, resolve, reject);
                                                        }),
                                                    onRowAdd: (newData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowAdd(newData, resolve, reject)
                                                        }),
                                                    onRowDelete: (oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            handleRowDelete(oldData, resolve, reject)
                                                        }),
                                                    onRowAddCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    },
                                                    onRowUpdateCancelled: rowData => {
                                                        setIserror(false)
                                                        setErrorMessages([])
                                                    }

                                                }}
                                            />
                                        </Col>
                                        <div>
                                            {iserror &&
                                                <Alert severity="error">
                                                    <AlertTitle>ERROR</AlertTitle>
                                                    {errorMessages.map((msg, i) => {
                                                        return <div key={i}>{msg}</div>
                                                    })}
                                                </Alert>
                                            }
                                        </div>
                                    </Row> : null}

                                    {/* fb material table */}
                                    {show5 === true ?
                                        <FD show5={show5} Fdentity={entityId == null ? subAccountId : entityId} FixDTransaction={FDTransaction} FDHeaderId={FDHeaderId} /> : null}

                                    {/* Rd material table */}
                                    {show6 === true ?
                                        <RD show6={show6} Rdentity={entityId == null ? subAccountId : entityId} RDTransaction={FDTransaction} FDHeaderId={FDHeaderId} /> : null}

                                    {/* Post office material table */}
                                    {show7 === true ?
                                        <PostOffice show7={show7} POentity={entityId == null ? subAccountId : entityId} POTransaction={FDTransaction} FDHeaderIdPO={FDHeaderId} /> : null}

                                    {/* PPF material table */}
                                    {show8 === true ?
                                        <PPFMain show8={show8} PPFentity={entityId == null ? subAccountId : entityId} PPFTransaction={FDTransaction} FDHeaderIdPPF={FDHeaderId} /> : null}

                                    {show1 === true ?
                                        <Row>
                                            <Col md="12" className="SellTbl mt-3">
                                                <MaterialTable
                                                    title="Sell"
                                                    data={data}
                                                    columns={columnsS1}
                                                    //  actions={actionsSell}
                                                    // actions={[
                                                    //   {
                                                    //      icon: "add",
                                                    //      tooltip: "Add User",
                                                    //      hidden: true,
                                                    //      isFreeAction: true,
                                                    //      handleRowAddSell: (event, rowData) => {
                                                    //       // Perform add operation
                                                    //      }
                                                    //   }]}
                                                    tableRef={tableRefSell1}
                                                    editable={{
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowUpdateSell(newData, oldData, resolve, reject);
                                                            }),
                                                        onRowAdd: (newData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowAddSell(newData, resolve, reject)
                                                            }),
                                                        onRowDelete: (oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowDeleteSell(oldData, resolve, reject)
                                                            }),
                                                        onRowAddCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        },
                                                        onRowUpdateCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        }
                                                    }}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        search: false,
                                                        paging: false
                                                    }}
                                                // components={{
                                                //   Cell: (props) => (
                                                //     <Tooltip title={props.value ? props.value : ''}>
                                                //       <MTableCell {...props} />
                                                //     </Tooltip>
                                                //   )
                                                // }}
                                                />
                                            </Col>
                                            <div>
                                                {isError &&
                                                    <Alert severity="error">
                                                        <AlertTitle>ERROR</AlertTitle>
                                                        {errorMessagesSell.map((msg, i) => {
                                                            return <div key={i}>{msg}</div>
                                                        })}
                                                    </Alert>
                                                }
                                            </div>
                                        </Row>
                                        : null}
                                    {show3 === true ?
                                        <Row>
                                            <Col md="12" className="SellTbl mt-3">
                                                <MaterialTable
                                                    title="Sell"
                                                    data={data2}
                                                    columns={columnsS1}
                                                    //actions={actionsSell}
                                                    tableRef={tableRefSell3}
                                                    editable={{
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowUpdateSell(newData, oldData, resolve, reject);
                                                            }),
                                                        onRowAdd: (newData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowAddSell(newData, resolve, reject)
                                                            }),
                                                        onRowDelete: (oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowDeleteSell(oldData, resolve, reject)
                                                            }),
                                                        onRowAddCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        },
                                                        onRowUpdateCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        }
                                                    }}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        search: false,
                                                        paging: false
                                                    }}
                                                />
                                            </Col>
                                            <div>
                                                {isError &&
                                                    <Alert severity="error">
                                                        <AlertTitle>ERROR</AlertTitle>
                                                        {errorMessagesSell.map((msg, i) => {
                                                            return <div key={i}>{msg}</div>
                                                        })}
                                                    </Alert>
                                                }
                                            </div>
                                        </Row>
                                        : null}

                                    {show4 === true ?
                                        <Row>
                                            <Col md="12" className="SellTbl unlisted mt-3">
                                                <MaterialTable
                                                    title="Sell"
                                                    data={data3}
                                                    columns={columnsS2}
                                                    //  actions={actionsSell}
                                                    tableRef={tableRefSell4}
                                                    editable={{
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowUpdateSell(newData, oldData, resolve, reject);
                                                            }),
                                                        onRowAdd: (newData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowAddSell(newData, resolve, reject)
                                                            }),
                                                        onRowDelete: (oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowDeleteSell(oldData, resolve, reject)
                                                            }),
                                                        onRowAddCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        },
                                                        onRowUpdateCancelled: rowData => {
                                                            setErrorMessagesSell([])
                                                            setIsError(false);
                                                        }
                                                    }}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        search: false,
                                                        paging: false
                                                    }}
                                                />
                                            </Col>
                                            {isError &&
                                                <Alert severity="error">
                                                    <AlertTitle>ERROR</AlertTitle>
                                                    {errorMessagesSell.map((msg, i) => {
                                                        return <div key={i}>{msg}</div>
                                                    })}
                                                </Alert>
                                            }
                                        </Row>
                                        : null}

                                    {show2 === true ?
                                        <Row>
                                            <Col md="12" className="SellTbl unlisted mt-3">
                                                <MaterialTable
                                                    title="Sell"
                                                    data={data1}
                                                    columns={columnsS2}
                                                    // actions={actionsSell}
                                                    tableRef={tableRefSell2}
                                                    editable={{
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowUpdateSell(newData, oldData, resolve, reject);
                                                            }),
                                                        onRowAdd: (newData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowAddSell(newData, resolve, reject)
                                                            }),
                                                        onRowDelete: (oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                handleRowDeleteSell(oldData, resolve, reject)
                                                            }),
                                                    }}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        search: false,
                                                        paging: false
                                                    }}
                                                />
                                            </Col>
                                            <div>
                                                {isError &&
                                                    <Alert severity="error">
                                                        <AlertTitle>ERROR</AlertTitle>
                                                        {errorMessagesSell.map((msg, i) => {
                                                            return <div key={i}>{msg}</div>
                                                        })}
                                                    </Alert>
                                                }
                                            </div>
                                        </Row>
                                        : null}



                                </fieldset>
                                <filedset>
                                    <Row>
                                        {
                                            show1 || show2 || show3 || show4 ?
                                                <Col md="1">
                                                    <Form.Label>STT </Form.Label>
                                                    {/* <Form.Control type="Number" onChange={(e) => handleStt(e.target.value)} autoComplete="off"
                                                        name="stt" id="stt" value={stt} /> */}
                                                    <Cleave
                                                        className='form-control'
                                                        autoComplete="off"
                                                        id="stt"
                                                        name="stt"
                                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                                                        value={stt}
                                                        onChange={(e) => handleStt(toNumber(e.target.rawValue))} />
                                                </Col>
                                                : null
                                        }
                                        {/* {
                                            show2  || show4 ?
                                                <Col md="3">
                                                    <Form.Label>Stamp Duty</Form.Label>
                                                    <Form.Control type="text" onChange={(e) => handleStampDuty(e.target.value)} autoComplete="off"
                                                        name="stampDuty" id="stampDuty" value={stampDuty == null ? 0 : stampDuty} />
                                                </Col>
                                                : null} */}
                                        {
                                            show1 || show2 || show3 || show4 ?
                                                <>
                                                    <Col md="1">
                                                        <Form.Label>GST</Form.Label>
                                                        {/* <Form.Control type="Number" onChange={(e) => handleGst(e.target.value)} autoComplete="off"
                                                            name="gst" id="gst" value={gst == null ? 0 : gst} /> */}
                                                        <Cleave
                                                            className='form-control'
                                                            autoComplete="off"
                                                            id="gst"
                                                            name="gst"
                                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                                                            value={gst == null ? 0 : gst}
                                                            onChange={(e) => handleGst(toNumber(e.target.rawValue))} />
                                                    </Col>
                                                    <Col md="2">
                                                        <Form.Label>Other charges</Form.Label>
                                                        {/* <Form.Control type="Number" min="0" onChange={(e) => handleOtherCharges(e.target.value)} autoComplete="off"
                                                            name="otherCharges" id="otherCharges" value={otherCharges == null ? 0 : otherCharges} /> */}
                                                        <Cleave
                                                            className='form-control'
                                                            autoComplete="off"
                                                            id="otherCharges"
                                                            name="otherCharges"
                                                            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                                                            value={otherCharges == null ? 0 : otherCharges}
                                                            onChange={(e) => handleOtherCharges(toNumber(e.target.rawValue))} />
                                                    </Col>
                                                </> : null
                                        }
                                        {
                                            show1 || show2 || show3 || show4 ?
                                                <Col md="3">
                                                    <Form.Label>Total Amount Due to / Due from Broker</Form.Label>
                                                    <Form.Control type="text" autoComplete="off"
                                                        name="stampDuty" id="stampDuty" disabled value={totalAmountDue == null ? 0 : NumberSeperator(totalAmountDue)} />
                                                </Col> : null}
                                        {
                                            show1 || show2 || show3 || show4 ?
                                                <Col md="5">
                                                    <Form.Label>Narration if any</Form.Label>
                                                    <Form.Control type="text" autoComplete="off"
                                                        name="narration" id="narration" value={narration} onChange={(e) => setNarration(e.target.value)} />
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </filedset>
                                {show1 || show2 || show3 || show4 ?
                                    <Row>
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.Transactionrequired}
                                            </div>
                                        ) : null}
                                        <Col md="12" className="text-center mt-3">
                                            {headerId !== 0 ? <>
                                                <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { UpdateFixedtransaction() }}>Update</Button>
                                                {/* <Button variant="danger" className="mx-2 w100" onClick={() => { DeleteFDtransaction() }}>Delete</Button> */}
                                            </>
                                                // : show3 || show4 ? <Button variant="primary" className="mx-2 w100" onClick={() => { handleSubmitListedPre() }}>Saved</Button> :
                                                :
                                                <Button variant="primary" className="mx-2 w100" id="submitButton"
                                                    onClick={() => { AddFixedStransaction() }}
                                                >
                                                    Save</Button>
                                            }
                                            <Button variant="secondary" className="mx-2 w100" onClick={() => { handleCancel() }}>Cancel</Button>
                                        </Col>
                                    </Row> : null
                                }

                            </form>
                        </Container>
                    </ModalBody>
                </Modal>

                {/* selection modal */}
                <Modal className="custom-dialog selection" isOpen={selection}
                    id="modal1"
                    size="lg"
                    backdrop="static"
                    //  backdrop={false}
                    toggle={selectionHide}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <ModalHeader charCode="Y" toggle={() => selectionHide()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">{show1 ? "Select Available Listed Debenture Quantity": show2 ? "Select Available Unlisted Debenture Quantity": show3 ? "Select Available Listed Bonds Quantity": show4 ? "Select Available Unlisted Bonds Quantity": "Select Available Fixed Income Quantity"}</h5>
                    </ModalHeader>
                    <ModalBody style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                    }}>
                        <div style={{ height: 200, width: '100%' }}>
                            <MaterialTable
                                title={false}
                                //  title="Cell Editable Preview"
                                columns={[
                                    {
                                        title: 'Date', field: 'DatePurchase', type: 'date', editable: 'never',
                                        render: (rowData) =>
                                        (
                                            moment(rowData.DatePurchase).format('DD/MM/YYYY')
                                        )
                                    },
                                    {
                                        title: 'Quantity', field: 'AvailableQuantity', type: 'numeric', editable: 'never',
                                        render: (rowData) => (
                                            <span>
                                                {NumberOnlySeperator(rowData.AvailableQuantity)}
                                            </span>
                                        ),
                                    },
                                    {
                                        title: 'Price', field: 'Price', type: 'numeric', editable: 'never',
                                        render: (rowData) => (
                                            <span>
                                                {NumberSeperator(rowData.Price)}
                                            </span>
                                        ),
                                    },
                                    { title: 'Sell Quantity', field: 'sellQuantity', width: 200, maxWidth: 200, type: 'numeric' },
                                ]}
                                data={selectionQty}
                                options={{
                                    actionsColumnIndex: -1,
                                    addRowPosition: "first",
                                    search: false,
                                    paging: false,
                                    selection: true,
                                    showTextRowsSelected: false,

                                }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            let errorsList = [];
                                            let updateSelection = [...selectionQty];
                                            const index = rowData.tableData.id;
                                            if (rowData.AvailableQuantity < parseInt(newValue)) {
                                                errorsList.push("Quantity exceed then avilable quantity !");
                                            }
                                            if (errorsList.length < 1) {
                                                updateSelection[index].sellQuantity = parseInt(newValue);
                                                setSelectionQty([...updateSelection])
                                                setErrorMessagesQntity([])
                                                setTimeout(resolve, 1000);
                                            }
                                            else {
                                                setErrorMessagesQntity(errorsList);
                                                setTimeout(reject, 1000);
                                            }
                                        });
                                    }
                                }}
                            />
                            <div>
                                {errorMessagesQntity.length > 0 &&
                                    <Alert severity="error">
                                        <AlertTitle>ERROR</AlertTitle>
                                        {errorMessagesQntity.map((msg, i) => {
                                            return <div key={i}>{msg}</div>
                                        })}
                                    </Alert>
                                }
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

            {/* additional infomative debtures */}
            <Modal isOpen={showlistedDeb} toggle={handlecloseDescModal} animation={true} size="lg" backdrop="static">
                <ModalHeader charCode="Y" toggle={() => handlecloseDescModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5 className="text-center">{show1 ? "Listed Debenture" : show2 ? "Unlisted Debenture" : show3 ? "Listed Bonds" : "Unlisted Bonds"}</h5>
                </ModalHeader>
                <ModalBody>
                    <form >
                        <Row>
                            {show2 || show4 ?
                                <Col md="6" className="mb-2">
                                    <>
                                        <Form.Label>Certificate/Folio Number<span className="text-danger h6">*</span></Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                setCertificateorfolinumber(e.target.value);
                                                setFormErr({ ...formErr, certificateorfolinumber: '' })
                                            }} autoComplete="off"
                                            name="certificateorfolinumber" id="certificateorfolinumber" value={certificateorfolinumber} style={{ textTransform: "uppercase" }} />
                                    </>
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.certificateorfolinumber}</div> : null}
                                </Col> : null}

                            <Col md="6" className="mb-2">
                                <>
                                    <Form.Label className="d-block">Lock in Period</Form.Label>
                                    <Form.Control type="text"
                                        onChange={(e) => setLockinperiod(e.target.value)} autoComplete="off"
                                        name="lockinperiod" id="lockinperiod" value={lockinperiod} className="lockinperiodno" />
                                    <Dropdown
                                        value={time}
                                        name='period'
                                        options={periodList}
                                        onChange={HandleChangeTime}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                    // onInputChange={HandleInput}
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                                </>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="mb-2">
                                <Form.Label>Redeemable/Convertible</Form.Label>
                                <Dropdown
                                    value={redeemableorconvertible}
                                    name='RedeemableOrConvertible'
                                    options={redeemableOrConvertibleList}
                                    onChange={handleChangeConvert}
                                    className="SelectClass"
                                    searchable={true}
                                    clearable={true}
                                    noResultsText={"No result"}
                                    isDisabled={headerId !== 0 ? true : false}
                                // onInputChange={HandleInput}
                                />

                            </Col>
                            <Col md="6" className="mb-2">
                                <Form.Label>Cumulative/Non Cumulative</Form.Label>
                                <Dropdown
                                    value={cumulativeornoncumulative}
                                    name='CumulativeOrNoncumulative'
                                    options={cumulativeNonCumulativeList}
                                    onChange={handleChangeCumulative}
                                    className="SelectClass"
                                    searchable={true}
                                    clearable={true}
                                    noResultsText={"No result"}
                                // onInputChange={HandleInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="mb-2">
                                <Form.Label>Interest %<span className="text-danger h6">*</span></Form.Label>
                                <Form.Control type="number"
                                    onChange={(e) => {
                                        setDividend(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)
                                        setFormErr({ ...formErr, dividend: "" })
                                    }} autoComplete="off"
                                    name="dividend" id="dividend" value={dividend} />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.dividend}</div> : null}

                            </Col>
                            <Col md="6" className="mb-2">
                                <Form.Label>Date of Maturity/Conversion </Form.Label>
                                <DatePicker className="form-control"
                                    selected={dateofmaturityorconversion}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => setDateofmaturityorconversion(date)}
                                    minDate={date}
                                    disabled={headerId !== 0 ? true : false}
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.mdate}</div> : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" className="mb-2">
                                <Form.Label>Interest Frequency</Form.Label>
                                <Dropdown
                                    value={frequencyValue}
                                    name='dividendFrequency'
                                    options={dividendfrequency}
                                    onChange={handleChangeFrequency}
                                    className="SelectClass"
                                    searchable={true}
                                    clearable={true}
                                    noResultsText={"No result"}
                                />
                                {/* <input type="text"
                        onChange={(e) => setDividendfrequency(e.target.value)} autoComplete="off"
                        name="dividendfrequency" id="dividendfrequency" value={dividendfrequency} /> */}
                            </Col>
                            <Col md="6" className="mb-2">
                                <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                                {/* <Form.Control type="text"
                                    onChange={(e) => {
                                        setMaturityvalue(e.target.value);
                                        setFormErr({ ...formErr, maturityvalue: '' })
                                    }} autoComplete="off"
                                    name="maturityvalue" id="maturityvalue" value={maturityvalue} /> */}
                                {
                                    cumulativeornoncumulative.value === "Cumulative" ?
                                        <Cleave
                                            className='form-control'
                                            autoComplete='off'
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            onChange={(e) => {
                                                setMaturityvalue(toNumber(e.target.rawValue));
                                                setFormErr({ ...formErr, maturityvalue: '' })
                                            }}
                                            name="maturityvalue" id="maturityvalue" value={maturityvalue} />
                                        :
                                        <Cleave
                                            className='form-control'
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            onChange={(e) => {
                                                setFormErr({ ...formErr, maturityvalue: '' })
                                            }}
                                            name="maturityvalue" id="maturityvalue" value={(cost).toFixed(2)}
                                            disabled />
                                }
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityValue}</div> : null}
                            </Col>


                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button variant="primary" className="mx-2 w100"
                        onClick={submitPreModal}
                    >
                        Save
                    </Button><Button variant="secondary" className="mx-2 w100" onClick={handleCloseModalPopUp} >
                        Clear
                    </Button>
                </ModalFooter>
            </Modal>








            <>
                <Modal isOpen={showUnlisted} toggle={handleCloseModal} animation={false} size="sm" backdrop="static" centered>
                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5>
                            {show1 === true ? "New Listed Debenture Scrip" :
                                show2 === true ? "New Unlisted Debenture Scrip" :
                                    show3 === true ? "New Listed Bonds Scrip" : "New Unlisted Bonds Scrip"}</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label htmlFor="inputUserName">Company Name</label>
                                <input className="form-control mt-2" placeholder="Enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
            <div tabIndex={3}>
                {/* snapShot modal */}
                {showFdpreview === true ?
                    <Modal isOpen={showFdpreview} toggle={onHideFD}>
                        <ModalHeader className="headerBlue" charCode="Y" toggle={() => onHideFD(1)} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                            <h5> {FDTransaction.instrumentId === 2 ? "Debenture" : FDTransaction.instrumentId === 3 ? "Bond" : null} {trastype} {""} details</h5>
                        </ModalHeader>
                        <ModalBody>

                            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                                <div className="transactionData odd"><div className="transactionDataTitle">Date : </div>
                                    <div className="transactionDataValue">{moment(datepre).format("DD/MM/YYYY")}</div></div>
                                {FDTransaction.instrumentTypeId === 21 ? null :
                                    <>
                                        {/* <div className="transactionData "><div className="transactionDataTitle">Type : </div>
                                            <div className="transactionDataValue">{typepre === null ? "Normal" : typepre}</div></div> */}
                                        <div className="transactionData "><div className="transactionDataTitle">Broker Name : </div>
                                            <div className="transactionDataValue">{brokerpre}</div></div>
                                    </>}
                                <div className="transactionData odd"><div className="transactionDataTitle">Company Name : </div>
                                    <div className="transactionDataValue">{companyvalue}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">Quantity : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(qty)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">{FDTransaction.instrumentId === 2 ? " Price/Debenture" : "Price/Bonds"}: </div>
                                    <div className="transactionDataValue">{pricePerShare ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(pricePerShare) : 0}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">{FDTransaction.instrumentId === 2 ? " Brokerage/Debenture" : "Brokerage/Bonds"} : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(brokerpershare)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Total Brokerage : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalBroker)}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">Total Cost : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost)}</div></div>
                                {FDTransaction.instrumentTypeId === 21 || 22 ? null :
                                    <>
                                        <div className="transactionData odd"><div className="transactionDataTitle">Current Market Price : </div>
                                            <div className="transactionDataValue">{currentMP == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMP)}</div></div>
                                        <div className="transactionData "><div className="transactionDataTitle">Current Market Value : </div>
                                            <div className="transactionDataValue">{currentMV == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMV)}</div></div>
                                        <div className="transactionData odd"><div className="transactionDataTitle">Target : </div>
                                            <div className="transactionDataValue">{target == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(target)}</div></div>
                                        <div className="transactionData "><div className="transactionDataTitle">Stop Loss : </div>
                                            <div className="transactionDataValue">{stopLoss == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(stopLoss)}</div></div>
                                        <div className="transactionData odd"><div className="transactionDataTitle">Est Hldg Period : </div>
                                            <div className="transactionDataValue">{estHldP == null ? "-" : estHldP}</div></div>
                                        <div className="transactionData "><div className="transactionDataTitle">Recommended By : </div>
                                            <div className="transactionDataValue">{recommendBy == null ? "-" : recommendBy}</div></div>
                                    </>
                                }

                                {
                                    trastype === "Sell" ?
                                        <div className="transactionData odd"><div className="transactionDataTitle">Stock Valuation Type : </div>
                                            <div className="transactionDataValue">{stocksSeletion}</div></div> : null
                                }
                                {
                                    FDTransaction.instrumentTypeId !== 10 && FDTransaction.instrumentTypeId !== 1 ?
                                        <>


                                            {trastype !== "Sell" ?
                                                <>
                                                    <div className="transactionData odd"><div className="transactionDataTitle">Lock In Period : </div>
                                                        <div className="transactionDataValue">{lockinperiod ? `${lockinperiod ? lockinperiod : ""} ${timeValue ? timeValue : "-"} ` : "0" + timeValue ? timeValue : "-"}</div></div>
                                                    <div className="transactionData "><div className="transactionDataTitle">Redeemable/Convertible : </div>
                                                        <div className="transactionDataValue ">{redeemconvertPre}</div></div>
                                                    <div className="transactionData odd"><div className="transactionDataTitle">Cumulative/Non Cumulative : </div>
                                                        <div className="transactionDataValue">{cumulativeType}</div></div>
                                                    <div className="transactionData "><div className="transactionDataTitle">Dividend % : </div>
                                                        <div className="transactionDataValue">{dividend ? dividend : 0}</div></div>
                                                    <div className="transactionData odd"><div className="transactionDataTitle">Date of Maturity/Conversion : </div>
                                                        <div className="transactionDataValue">{moment(dateofmaturityorconversion).format("DD/MM/YYYY")}</div></div>
                                                    <div className="transactionData "><div className="transactionDataTitle">Dividend Frequency : </div>
                                                        <div className="transactionDataValue">{frequencyValue.label == null ? "-" : frequencyValue.label}</div></div>
                                                    <div className="transactionData odd"><div className="transactionDataTitle">Maturity Value : </div>
                                                        <div className="transactionDataValue">{cumulativeType !== "Cumulative" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(maturityvalue)}</div></div></> : null}



                                        </> : null
                                    //  <div className="transactionData odd"><div className="transactionDataTitle">Folio Number : </div>
                                    //     <div className="transactionDataValue">{folioNumber ? folioNumber : 0}</div></div>

                                }


                                {
                                    FDTransaction.instrumentTypeId === 13 || FDTransaction.instrumentTypeId === 12 || trastype !== "Buy" ? null :
                                        <div className="transactionData"><div className="transactionDataTitle">Certificate/Folio Number :</div>
                                            <div className="transactionDataValue">{certificateorfolinumber ? certificateorfolinumber : "-"}</div></div>

                                }

                                {
                                    FDTransaction.instrumentTypeId === 13 || FDTransaction.instrumentTypeId === 12 || trastype !== "Sell" ? null :
                                        <div className="transactionData odd"><div className="transactionDataTitle">Folio Number : </div>
                                            <div className="transactionDataValue">{folioNumber == null ? "0" : folioNumber}</div></div>

                                }

                                <div className="transactionData "><div className="transactionDataTitle">SST : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(STTpre)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">GST : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(GSTpre)}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">Other Charges : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(otherChargesPre)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Total Amount Due To : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalAmtDuePre)}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">Narration : </div>
                                    <div className="transactionDataValue">{narrationPre === null ? "-" : narrationPre}</div></div>
                            </div>

                        </ModalBody>
                    </Modal> : null}
            </div>
        </>


    )
}

export default FIDetails;
