/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import {
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TableCell,
    TableContainer,
    IconButton,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableFooter,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import Loader from '../loadingAnimation/index';
import Swal from 'sweetalert2';

import { EntityList } from '../../pages/Stocks/StocksAPI';
import {
    outerGridMutualFamily, outerGridMutualEntity, innerGridMutualFamily, innerGridMutualEntity, mutualFundTransaction, deleteMutualFundOtherTransaction, getSingleTransactionMFPreview,
    getFamilyTotals,
    getEntityTotals,
    filterByInstFamily,
    filterByInstEntity
} from '../../pages/MutualFund/MutualFundAPI';

import { DashboardFamily, DashboardEntity } from "../Dashboard/RecentTranAPI/RT_API"
import Auth from '../../modules/Auth';

import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import MFundDropdown from "../sidebar/MFundDropdown";

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip'
import { Edit, Visibility, Delete } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddFund from "./AddFund";
import PreviewMuFund from "./PreviewMuFund";
import MFundBonus from "./OtherTransaction/MFundBonus";
import MFSplitmodal from "./OtherTransaction/MFundSplit";
import MFundDemerger from "./OtherTransaction/MFundDemerger";
import MFundMerger from "./OtherTransaction/MFundMerger";
import MutualFundDetail from "../MutualFundDetail/MutualFundDetail";

import SearchBar from "material-ui-search-bar";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from "react-router-dom";

const MutualFundTable = () => {
    const userIDs = Auth.getsubcriberId();

    const [entityRadio, setEntityRadio] = useState([]);
    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState("Family")
    const [entityId, setEntityId] = useState(null);
    const [script, setScript] = useState("All");
    const [iconsTest, setIconTest] = useState(AddIcon);

    const [familyOGdata, setFamilyOGdata] = useState([]);
    const [entityOGdata, setEntityOGdata] = useState([]);
    const [familyIGdata, setFamilyIGdata] = useState([]);
    const [enityIGdata, setEnityIGdata] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [filteredEntiryData, setFilteredEntiryData] = useState([]);
    const [gridDetails, setGridDetails] = useState({})

    const [subAccount_ID, setSubAccount_ID] = useState(null);
    const [scheme_ID, setScheme_ID] = useState(null);
    const [livePrice, setLivePrice] = useState(null);
    const [instumnet_ID, setInstumnet_ID] = useState(null)
    const [transaction, setTransaction] = useState(null)

    const [MFTransaction, setMFTransaction] = useState({});
    const [MFTransactionPreview, setMFTransactionPreview] = useState({});
    const [demgerData, setDemergerData] = useState({});
    const [mFHeader_ID, setmFHeader_ID] = useState(0);
    const [mfund_ID, setmfund_ID] = useState(0);
    const [mfund_ID1, setmfund_ID1] = useState(0)
    //other transaction modal
    const [fundbonusopen, setFundBonusopen] = useState(false);
    const [fundsplitopen, setFundSplitopen] = useState(false);
    const [fundmergeropen, setFundMergeropen] = useState(false);
    const [funddemergeropen, setFundDemergeropen] = useState(false)

    // other transaction preview open
    const [fundBonusPreview, setFundBonusPreview] = useState(false);
    const [fundMergerPreview, setFundMergerPreview] = useState(false);
    const [fundDemergerPreview, setFundDemergerPreview] = useState(false);
    const [fundSplitPreview, setFundSplitPreview] = useState(false);


    const [showMutualFund, setshowMutualFund] = useState(false)
    const [showInnerGrid, setShowInnerGrid] = useState(false);
    const [showAddFund, setShowAddFund] = useState(false);
    const [showPreview, setShowPreview] = useState(false)
    const [AddFundData, setAddFundData] = useState({});

    const [MFCompanyName, setMFCompanyName] = useState({});
    const [transactionType, setTransactionType] = useState([])

    const [searched, setSearched] = useState("");
    const [nonZero, setnonZero] = useState(1);

    const location = useLocation();
    const state = location.state;
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (state) {
            let instrumentTypeId = state.instrumentTypeId;
            let mutualFundCompanyId = state.mutualFundCompanyId;
            let livePrice = state.livePrice;
            let subAccountId = state.subAccountId;
            let company = state.company;
            let transactionType = state.transactionType;
            mutualFundIGdata(subAccountId, mutualFundCompanyId, livePrice, instrumentTypeId, company, transactionType);
            setShowInnerGrid(true);
        }
    }, [state])

    //all header
    const [filteredFamilyData, setFilteredFamilyData] = useState({
        prevLivePrice: 0,
        livePrice: 0,
        quantity: 0,
        totalInvestment: 0,
        daygain: 0,
        daysgainper: 0,
        overallgain: 0,
        overallgainper: 0,
        currentValue: 0
    });

    const [filterEntityData, setfilterEntityData] = useState({
        prevLivePricec: 0,
        livePricec: 0,
        quantityc: 0,
        totalInvestmentc: 0,
        daysgainc: 0,
        daysgainperc: 0,
        overallgainc: 0,
        overallgainperc: 0,
        currentValuec: 0
    });


    // total calculation outergrid
    const [totalValues, setTotalValues] = useState({
        instrumentTypeId: 0,
        instrumentTypeName: "",
        livePrice: 0,
        prevLivePrice: 0,
        quantity: 0,
        subcriberId: 0,
        totalInvestements: 0,
        daysgain: 0,
        daysgainperc: 0,
        overallgain: 0,
        overallgainperc: 0,
        currentValue: 0
    });
    const [totalData, setTotalData] = useState({
        daysGainSum: 0,
        daysGainSumPer: 0,
        overAllGainSum: 0,
        overAllGainSumPer: 0,
        CurrentValue: 0,
        totalInvestements: 0
    });

    useEffect(() => {

        getEntityList();
        getFamilyData();
    }, []);

    // //header value all
    const getFamilyData = () => {
        if (entity === "Family") {
            DashboardFamily().then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {

                        setLoading(false);
                        const familyData = res.data._response.data.fetchMenuInvestmentGridBySubscriberId
                        const found = familyData.find(element =>
                            element.instrumentName === "Mutual Fund")

                        setFilteredFamilyData({
                            totalInvestment: found.totalInvestment,
                            daygain: found.daysGain,
                            daysgainper: found.daysGainPerc,
                            overallgain: found.overallGain,
                            overallgainper: found.overallGainPerc,
                            currentValue: found.currentValue
                        })


                    } else {
                        setLoading(false);
                        setFilteredFamilyData({

                            livePrice: 0,
                            prevLivePrice: 0,
                            quantity: 0,
                            subcriberId: 0,
                            totalInvestment: 0,
                            daygain: 0,
                            daysgainper: 0,
                            overallgain: 0,
                            overallgainper: 0,
                            currentValue: 0,
                        })
                    }

                } else {
                    setLoading(false);
                    setFilteredFamilyData({

                        livePrice: 0,
                        prevLivePrice: 0,
                        quantity: 0,
                        subcriberId: 0,
                        totalInvestment: 0,
                        daygain: 0,
                        daysgainper: 0,
                        overallgain: 0,
                        overallgainper: 0,
                        currentValue: 0,
                    })

                }

            }).catch((e) => {
                setLoading(false);
                console.log(e.message);
            })
        }
    }

    const getEntityData = (id) => {
        DashboardEntity(id)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        const EntityStock = res.data._response.data.fetchMenuInvestmentGridBySubAccountId
                        const found = EntityStock.find(element => element.instrumentName === "Mutual Fund");
                        setfilterEntityData({
                            totalInvestmentc: found.totalInvestment,
                            daysgainc: found.daysGain,
                            daysgainperc: found.daysGainPerc,
                            overallgainc: found.overallGain,
                            overallgainperc: found.overallGainPerc,
                            currentValuec: found.currentValue
                        })
                        setLoading(false);
                    } else if (res.data._response.status === 2) {
                        setLoading(false);
                        setfilterEntityData({
                            livePrice: 0,
                            prevLivePricec: 0,
                            quantityc: 0,
                            totalInvestmentc: 0,
                            daysgainc: 0,
                            daysgainperc: 0,
                            overallgainc: 0,
                            overallgainperc: 0,
                            currentValuec: 0
                        });
                    }
                } else {
                    setfilterEntityData({
                        livePrice: 0,
                        prevLivePricec: 0,
                        quantityc: 0,
                        totalInvestmentc: 0,
                        daysgainc: 0,
                        daysgainperc: 0,
                        overallgainc: 0,
                        overallgainperc: 0,
                        currentValuec: 0
                    });
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e.message, "error");
            });

    }

    //only two decimal places
    const convertTOdecimal = (num) => {

        return (Math.round(num * 100) / 100).toFixed(2);
    }

    const columns = [
        { title: "Scheme", field: "mutualFundCompanyName" },
        {
            title: "Live Price / NAV",
            field: "livePrice",
            type: "numeric",

            render: (rowData) => (
                <span>{Intl.NumberFormat('en-IN').format(convertTOdecimal(rowData.livePrice))}</span>
                // <span>15.00</span>

            ),
        },
        {
            title: "Change",
            field: "change",
            type: "numeric",

            render: (rowData) => (
                (rowData.livePrice - rowData.previousLivePrice) >= 0 ? <span style={{ color: "#008000" }} >{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.livePrice - rowData.previousLivePrice))}</span>
                    : <span style={{ color: "#ff0000" }} >{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.livePrice - rowData.previousLivePrice))}</span>
            ),
        },
        {
            title: "Units",
            field: "availableUnit",
            type: "numeric",
            width: "10%",
            render: (rowData) => (
                <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(rowData.availableUnit)}</span>
            ),
        },
        {
            title: "Avg Purchase Price",
            field: "aPurchasePrice",
            type: "numeric",
            width: "2%",
            render: (rowData) => (
                rowData.availableUnit ?
                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.closingBalance / (rowData.availableUnit)))}</span>
                    : <span>0.00</span>
            ),
        },
        {
            title: "Amount Invested",
            field: "closingBalance",
            type: "numeric",

            render: (rowData) => (
                <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.closingBalance)}</span>
            ),
        },
        {
            title: "Day's Gain",
            field: "daysGain",
            type: "numeric",

            render: (rowData) => (
                ((rowData.livePrice - rowData.previousLivePrice) * rowData.availableUnit) >= 0 ?
                    <span style={{ color: "#008000" }}>
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                            convertTOdecimal((rowData.livePrice - rowData.previousLivePrice) *
                                rowData.availableUnit)
                        )}
                    </span>
                    : <span style={{ color: "#ff0000" }}>
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                            convertTOdecimal((rowData.livePrice - rowData.previousLivePrice) *
                                rowData.availableUnit)
                        )}
                    </span>
            ),
        },
        {
            title: "Day's Gain(%)",
            field: "pdaysGain",
            type: "numeric",

            render: (rowData) => (

                (((rowData.livePrice - rowData.previousLivePrice) / rowData.previousLivePrice)* 100) ?
                    ((((rowData.livePrice - rowData.previousLivePrice) / rowData.previousLivePrice) * 100) >= 0 ?


                        <span style={{ color: "#008000" }}>
                            {

                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((rowData.livePrice - rowData.previousLivePrice) /
                                rowData.previousLivePrice * 100)))}%
                        </span>
                        :
                        <span style={{ color: "#ff0000" }}>
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((rowData.livePrice - rowData.previousLivePrice) /
                                 rowData.previousLivePrice * 100)))}%
                        </span>) : <span style={{ color: "#008000" }}>0.00%</span>
            ),
        },
        {
            title: "Overallgain",
            field: "overallgain",
            type: "numeric",

            render: (rowData) => (
                rowData.livePrice ?
                    (rowData.livePrice * rowData.availableUnit - rowData.closingBalance) >= 0 ?

                        <span style={{ color: "#008000" }}>
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.livePrice * rowData.availableUnit - rowData.closingBalance))}
                        </span>
                        : <span style={{ color: "#ff0000" }}>
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.livePrice * rowData.availableUnit - rowData.closingBalance))}
                        </span>

                    : <span style={{ color: "#008000" }}>0.00%</span>
            ),
        },
        {
            title: "Overallgain(%)",
            field: "poverallgain",
            type: "numeric",

            render: (rowData) => (
                (
                    ((rowData.livePrice * rowData.availableUnit - rowData.closingBalance) /
                        rowData.closingBalance) *
                    100
                ) >= 0 ?



                    rowData.livePrice ?

                        (isNaN(
                            ((rowData.livePrice * rowData.availableUnit - rowData.closingBalance) /
                                rowData.closingBalance) *
                            100
                        ) ?
                            <span style={{ color: "#008000" }}> 0.00%</span> :
                            <span style={{ color: "#008000" }}>
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound
                                    (((rowData.livePrice * rowData.availableUnit - rowData.closingBalance) /
                                        rowData.closingBalance) *
                                        100
                                    ))
                                }%
                            </span>

                        )

                        : <span style={{ color: "#008000" }}> 0.00%</span>

                    :

                    rowData.livePrice ?

                        (isNaN(
                            ((rowData.livePrice * rowData.availableUnit - rowData.closingBalance) /
                                rowData.closingBalance) *
                            100
                        ) ?
                            <span style={{ color: "#008000" }}> 0.00%</span>
                            :
                            <span style={{ color: "#ff0000" }}>
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound
                                    (((rowData.livePrice * rowData.availableUnit - rowData.closingBalance) /
                                        rowData.closingBalance) *
                                        100)
                                )
                                }%
                            </span>

                        )

                        : <span style={{ color: "#008000" }}> 0.00%</span>
            ),

        },
        {
            title: "Current Value",
            field: "currentvalue",
            type: "numeric",
            width: "6%",
            render: (rowData) => (
                <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.livePrice * rowData.availableUnit))}</span>
            ),
        },

    ];

    function preciseMathDotRound(value, precision = 2) {
        let valueData = isFinite(value)

        if (valueData) {

            return parseFloat(value).toFixed(precision);
        } else {
            return 0;
        }
    }

    useEffect(() => {
        (async () => {
            await getMFFamilyOuterGrid(nonZero, script);
            if (userIDs) {
                getEntityList()
            }
        })();
    }, [nonZero,script])

    useEffect(() => {

        const interval = setInterval(() => {
            //getSignalR();
            getMFFamilyOuterGrid();
        }, 500000)
        return () => clearInterval(interval)

    }, [])

    //getEntityList
    const getEntityList = () => {
        setLoading(true)
        EntityList()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let SubscriberName = res.data._response.data.subaccountDetails;
                        setEntityRadio(SubscriberName);
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false)
                // setError(e.message);
            });
    };

    // all family outer grid data 
    const getMFFamilyOuterGrid = async (selectZero,script) => {
        
        let scriptnew = script === "All" ? 0 : script
        setLoading(true)
        await outerGridMutualFamily(selectZero,scriptnew).then(async (res) => {

            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    
                    setLoading(false);
                    let mutualFund = res.data._response.data.fetchMutualFundFamilyOuterGrid;

                    await setFamilyOGdata(mutualFund);
                    await familyMutualOuterGrid(mutualFund);
                    $(".MuiTableRow-root").each(function () {
                        let val = $('.material-icons ', this).text()
                        if (val == "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                    });
                }
                else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        })
            .catch((e) => {

                setLoading(false);
                console.log(e.message);
            });

    };

    // expandable family data
    const familyMutualOuterGrid = async (mutualFund) => {

        let check = [];
        mutualFund.forEach((item, i) => {
            if (check.indexOf(item.mutualFundCompanyName) === -1) {
                check.push(item.mutualFundCompanyName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                previousLivePrice: 0,
                availableUnit: 0,
                closingBalance: 0,
                name: "",
            };
            mutualFund.forEach((item, i) => {
                if (checkdata === item.mutualFundCompanyName) {
                    let livePrice = item.livePrice;
                    let previousLivePrice = item.previousLivePrice;
                    let availableUnit = mainData.availableUnit + item.availableUnit;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    // let instrumentToken = item.instrumentToken
                    mainData = {
                        livePrice: livePrice,
                        previousLivePrice: previousLivePrice,
                        availableUnit: availableUnit,
                        closingBalance: closingBalance,
                        mutualFundCompanyName: item.mutualFundCompanyName,
                        id: index,
                        // instrumentToken: item.instrumentToken
                    };
                    let normalData = {
                        ...item,
                        mutualFundCompanyName: [item.subAccountName, " - ", item.instrumentTypeName],
                        parentId: index,
                        id: index + 1,
                        company: item.mutualFundCompanyName,
                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });

        setFilteredData([...parentData, ...childData]);
    }

    //handle radio buttons
    const handleChangeEntity = async (e) => {
        let selected = e.target.value
        await setEntity(selected);
        setLoading(true)
        if (selected === "Family") {
            setEntityId(null);
            getMFFamilyOuterGrid(nonZero,script);
            getFamilyData()
            setScript("All");
            setSearched("")


        } else {
            let data = _.find(entityRadio, function (o) {
                return o.subAccountName === selected;
            });
            await setEntityId(data.subAccountId);
            await getMFEntityOuterGrid(data.subAccountId, nonZero,script)
            await getEntityData(data.subAccountId);
            setScript("All");
            setSearched("")

            setfilterEntityData({
                prevLivePricec: 0,
                livePricec: 0,
                quantityc: 0,
                totalInvestmentc: 0,
                daysgainc: 0,
                daysgainperc: 0,
                overallgainc: 0,
                overallgainperc: 0,
                currentValuec: 0
            });


        }

    }

    //handle all entity outer grid data
    const getMFEntityOuterGrid = (id, nonZero,script) => {
        
        let scriptnew = script === "All" ? 0 : script
        setLoading(true);
        outerGridMutualEntity(id, nonZero, scriptnew).then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {

                    //+ button code
                    $(".MuiTableRow-root").each(function () {
                        let val = $('.material-icons ', this).text()
                        if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
                    });

                    let maturityEntity = res.data._response.data.fetchMutualFundIndividualOuterGrid;



                    setEntityOGdata(maturityEntity);
                    setScript("All")
                    entityMutualOuterGrid(maturityEntity)
                    setLoading(false);
                }
                else {
                    setFilteredEntiryData([])
                    setEntityOGdata([]);
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                setFilteredEntiryData([])
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    //handle expandable enity  outer grid data
    const entityMutualOuterGrid = (maturityEntity) => {

        let checkEntity = [];
        maturityEntity.forEach((item, i) => {
            if (checkEntity.indexOf(item.mutualFundCompanyName) === -1) {
                checkEntity.push(item.mutualFundCompanyName);
            }
        });
        let parentDataEntity = [];
        let childDataEntity = [];
        checkEntity.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                previousLivePrice: 0,
                availableUnit: 0,
                closingBalance: 0,
                name: "",
            };

            maturityEntity.forEach((item, i) => {
                if (checkdata === item.mutualFundCompanyName) {
                    let livePrice = item.livePrice;
                    let previousLivePrice = item.previousLivePrice;
                    let availableUnit = mainData.availableUnit + item.availableUnit;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    mainData = {
                        livePrice: livePrice,
                        previousLivePrice: previousLivePrice,
                        availableUnit: availableUnit,
                        closingBalance: closingBalance,
                        mutualFundCompanyName: item.mutualFundCompanyName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        mutualFundCompanyName: item.transactionType,
                        parentId: index,
                        id: index + 1,
                    };

                    childDataEntity.push(normalData);
                }
            });
            parentDataEntity.push(mainData);
        });
        setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);
    }

    // handle inner grid family and entity wise
    const mutualFundIGdata = async (
        subAccountId,
        mutualFundCompanyId,
        livePrice,
        instrumentTypeId,
        company,
        transactionType
    ) => {

        setLoading(true);
        setSubAccount_ID(subAccountId);
        setScheme_ID(mutualFundCompanyId);
        setLivePrice(livePrice);
        setInstumnet_ID(instrumentTypeId)
        setMFCompanyName(company)
        if (entityId === null && state === null) {

            await innerGridMutualFamily(mutualFundCompanyId, subAccountId, instrumentTypeId)
                .then((res) => {

                    if (res.data._response.status === 1) {
                        let innerFamilyData = res.data._response.data.fetchMutualFundFamilyInnerGrid;
                        let newData = innerFamilyData.filter((a) => a.transactionType !== "SIP Fund" && a.transactionType !== "Lumpsum Fund")
                        setFamilyIGdata(newData)

                        let quantity = _.last(newData);
                        let buyAmt = 0;
                        let sellAmt = 0;
                        let buyUnits = 0;
                        let sellUnits = 0;

                        innerFamilyData &&
                            innerFamilyData.map((f) => {
                                if (f.transactionType !== "Sell" && f.transactionType !== "Split" && f.transactionType !== "SIP Fund" && f.transactionType !== "Lumpsum Fund" && f.show === 1) {
                                    //  buyAmt += f.closingBalance;
                                    buyUnits += f.totalUnits;
                                }

                                if (f.transactionType !== "Sell" && f.transactionType !== "Split" && f.show === 1) {
                                    buyAmt += f.closingBalance;
                                    //buyUnits += f.totalUnits;
                                }

                                // // debugger
                                if (f.transactionType === "Sell" && f.show === 1) {
                                    sellUnits += f.units;
                                }

                                if (f.transactionType === "Sell" && f.show === 0 && f.active === 1) {
                                    sellUnits += f.units;

                                }

                                if (f.transactionType === "Sell" && f.show === 1) {
                                    sellAmt += f.closingBalance;
                                }


                                if (f.transactionType === "Split" && f.show === 1) {
                                    buyAmt += f.closingBalance;
                                    buyUnits += f.totalUnits;
                                }
                                setTransactionType(f.instrumentTypeId)
                            });

                        setShowInnerGrid(true)
                        setLoading(false);
                        setGridDetails({
                            mutualFundCompanyId: mutualFundCompanyId,
                            // mutualFundCompanyName: company ,
                            mutualFundCompanyName: entityId == null ? company : MFCompanyName,
                            livePrice: livePrice,
                            finalUnits: buyUnits - sellUnits, //quantity.balanceQuantity,
                            currentValue: livePrice * quantity.balanceUnit,
                            finalCost: buyAmt - sellAmt,

                        });

                        //+/- code
                        var arr = [];
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            let a = /[,\/]/g;
                            let b = a.test(path)
                            if (b) {
                                let c = path.charAt(0)
                                if (!arr.includes(c)) {
                                    arr.push(c)
                                }
                            }
                        });
                        $(".MuiTableRow-root").each(function () {
                            let path = this.getAttribute("path");
                            if (arr.includes(path)) {
                                let button = $("button", this);
                                $(button).html("<span class='material-icons'>maximize</span>");
                                $(button).css({
                                    transform: "rotate(180deg)",
                                });
                            }
                        });
                    } else {
                        setFamilyIGdata([]);
                        // setShowInnerGrid({});
                        setLoading(false);
                    }

                }).catch((e) => {
                    console.log(e.message);
                    setLoading(false);
                })

        } else {

            await innerGridMutualEntity(mutualFundCompanyId, instrumentTypeId, subAccountId)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        let innerEntityData = res.data._response.data.fetchMutualFundIndividualInnerGrid;
                        let newData = innerEntityData.filter((a) => a.transactionType !== "SIP Fund" && a.transactionType !== "Lumpsum Fund")
                        setEnityIGdata(newData)
                        setLoading(false);
                        let MFCompNames = '';
                        let quantity = _.last(newData);
                        let buyAmt = 0;
                        let sellAmt = 0;
                        let buyUnits = 0;
                        let sellUnits = 0;
                        let buyfolionumber = '';
                        let sellfolionumber = '';
                        innerEntityData &&
                            innerEntityData.map((f) => {
                                if (f.transactionType !== "Sell" && f.transactionType !== "Split" && f.transactionType !== "SIP Fund" && f.transactionType !== "Lumpsum Fund" && f.show === 1) {
                                    //  buyAmt += f.closingBalance;
                                    buyUnits += f.totalUnits;
                                }

                                if (f.transactionType !== "Sell" && f.transactionType !== "Split" && f.show === 1) {
                                    buyAmt += f.closingBalance;
                                    //buyUnits += f.totalUnits;
                                }

                                
                                if (f.transactionType === "Sell" && f.show === 1) {
                                    sellUnits += f.units;
                                }

                                if (f.transactionType === "Sell" && f.show === 0 && f.active === 1) {
                                    sellUnits += f.units;

                                }

                                if (f.transactionType === "Sell" && f.show === 1) {
                                    sellAmt += f.closingBalance;
                                }


                                if (f.transactionType === "Split" && f.show === 1) {
                                    buyAmt += f.closingBalance;
                                    buyUnits += f.totalUnits;
                                }
                                setTransactionType(f.instrumentTypeId)
                                MFCompNames = f.mutualFundCompanyName;
                            });


                        setShowInnerGrid(true)
                        setGridDetails({
                            mutualFundCompanyId: mutualFundCompanyId,
                            mutualFundCompanyName: MFCompNames,
                            livePrice: livePrice,
                            finalUnits: buyUnits - sellUnits, //quantity.balanceQuantity,
                            currentValue: livePrice * quantity.balanceUnit,
                            finalCost: buyAmt - sellAmt,

                        });
                        setShowLoader(true);
                    } else {
                        setEnityIGdata([])
                        setLoading(false);
                    }


                    setLoading(false);
                }).catch((e) => {
                    console.log(e.message);
                    setLoading(false);
                })
        }

    }
    //open transaction mutual fund details
    const openTransaction = () => {
        setshowMutualFund(true)
    }

    //close  transaction mutual fund details , preview  and  add fund
    const handleCloseFund = () => {
        setmFHeader_ID(0);
        setmfund_ID(0);
        setmfund_ID1(0);
        setMFTransaction({});
        setDemergerData({});
        setshowMutualFund(false);
        setShowAddFund(false);
        setShowPreview(false);
        setAddFundData({ instrumentTypeId: '', mutualFundId: '', transactionType: '', brokerId: '', mutualFundCompanyId: '', stockValuation: '', subAccountId: '', dateOfInvestment: '' });
        onTransactionGridRefresh();
        refreshMutualFund()
    }
    const handleCloseFundPreview = () => {
        setmFHeader_ID(0);
        setmfund_ID(0);
        setmfund_ID1(0);
        setMFTransaction({});
        setDemergerData({});
        setshowMutualFund(false);
        setShowAddFund(false);
        setShowPreview(false);
        setAddFundData({ instrumentTypeId: '', mutualFundId: '', transactionType: '', brokerId: '', mutualFundCompanyId: '', stockValuation: '', subAccountId: '', dateOfInvestment: '' });

    }

    // clear buysell grid values params
    const handleClear = () => {
        setSubAccount_ID(null);
        setScheme_ID(null);
        setLivePrice(null);
        setInstumnet_ID(null)
        setTransaction(null)
    }

    //handle filter menus
    const handleScripData = (e) => {
        let selected = e.target.value
        setScript(selected)

        setSearched("")

        //sordted Family

        if (selected === "All") {
            getMFFamilyOuterGrid(nonZero ,selected)
            entityId &&  getMFEntityOuterGrid(entityId ,nonZero,selected)
        } else {

            sortFamilyByInst(selected, nonZero)
            entityId &&  sortEntityByInst(entityId,selected,nonZero)
        }

        FamilyWiseStockCalculations(selected)
        setTotalData({
            daysGainSum: 0,
            daysGainSumPer: 0,
            overAllGainSum: 0,
            overAllGainSumPer: 0,
            CurrentValue: 0,
            totalInvestements: 0
        });
    }


    //  filter table by instrument id in Family
    const sortFamilyByInst = (scripData, nonZero) => {

        let scripdata = Number(scripData)
        filterByInstFamily(scripdata, nonZero)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let sortedFamily = res.data._response.data.filterMutualFundByInstrumentId;
                        let check = [];
                        sortedFamily.forEach((item, i) => {
                            if (check.indexOf(item.mutualFundCompanyName) === -1) {
                                check.push(item.mutualFundCompanyName);
                            }
                        });
                        let parentData = [];
                        let childData = [];
                        check.forEach((checkdata, index) => {
                            let mainData = {
                                livePrice: 0,
                                previousLivePrice: 0,
                                availableUnit: 0,
                                closingBalance: 0,
                                name: "",
                            };
                            sortedFamily.forEach((item, i) => {
                                if (checkdata === item.mutualFundCompanyName) {
                                    let livePrice = item.livePrice;
                                    let previousLivePrice = item.previousLivePrice;
                                    let availableUnit = mainData.availableUnit + item.availableUnit;
                                    let closingBalance = mainData.closingBalance + item.closingBalance;
                                    // let instrumentToken = item.instrumentToken
                                    mainData = {
                                        livePrice: livePrice,
                                        previousLivePrice: previousLivePrice,
                                        availableUnit: availableUnit,
                                        closingBalance: closingBalance,
                                        mutualFundCompanyName: item.mutualFundCompanyName,
                                        id: index,
                                        // instrumentToken: item.instrumentToken
                                    };
                                    let normalData = {
                                        ...item,
                                        mutualFundCompanyName: [item.subAccountName, " - ", item.instrumentTypeName],
                                        parentId: index,
                                        id: index + 1,
                                        company: item.mutualFundCompanyName,
                                    };
                                    childData.push(normalData);
                                }
                            });
                            parentData.push(mainData);
                        });

                        setFilteredData([...parentData, ...childData]);
                    }
                    else {
                        setFilteredData([])
                        setLoading(false);
                    }
                }
             

            })
            .catch((err) => {
                console.log(err);
            })
    }

    // filter table by instrument id in Entity
    const sortEntityByInst = (entityId ,scripData, nonZero) =>{
        
        let scripdata = Number(scripData)
        filterByInstEntity(entityId, scripdata, nonZero)
        .then((res) =>{
            
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let sortedEntity = res.data._response.data.filterMutualFundByInstrumentId;
                    // console.log(sortedEntity, "sortedEntity");
                    let checkEntity = [];
                    sortedEntity.forEach((item, i) => {
                        if (checkEntity.indexOf(item.mutualFundCompanyName) === -1) {
                            checkEntity.push(item.mutualFundCompanyName);
                        }
                    });
                    let parentDataEntity = [];
                    let childDataEntity = [];
                    checkEntity.forEach((checkdata, index) => {
                        let mainData = {
                            livePrice: 0,
                            previousLivePrice: 0,
                            availableUnit: 0,
                            closingBalance: 0,
                            name: "",
                        };

                        sortedEntity.forEach((item, i) => {
                            if (checkdata === item.mutualFundCompanyName) {
                                let livePrice = item.livePrice;
                                let previousLivePrice = item.previousLivePrice;
                                let availableUnit = mainData.availableUnit + item.availableUnit;
                                let closingBalance = mainData.closingBalance + item.closingBalance;
                                mainData = {
                                    livePrice: livePrice,
                                    previousLivePrice: previousLivePrice,
                                    availableUnit: availableUnit,
                                    closingBalance: closingBalance,
                                    mutualFundCompanyName: item.mutualFundCompanyName,
                                    id: index,
                                };

                                let normalData = {
                                    ...item,
                                    mutualFundCompanyName: item.transactionType,
                                    parentId: index,
                                    id: index + 1,
                                };

                                childDataEntity.push(normalData);
                            }
                        });
                        parentDataEntity.push(mainData);
                    });
                    setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);
                } else {
                    setFilteredEntiryData([])
                    setLoading(false);
                }
            }
         
        })
        .catch((err) => {
            console.log(err);   
        })
     
    }
    //close inner grid
    const handleCloseModel = () => {
        setMFTransaction({});
        setDemergerData({});
        setShowInnerGrid(false);
        refreshMutualFund()
        handleClear()
    };

    // handle open add fund form
    const handleAddFund = (e, selectedRow) => {
        let data = {
            mutualFundId: Number(selectedRow.mutualFundId),
            instrumentTypeId: Number(selectedRow.instrumentTypeId),
            transactionType: selectedRow.instrumentTypeId === 41 ? "SIP Fund" : "Lumpsum Fund",
            brokerId: Number(selectedRow.brokerId),
            mutualFundCompanyId: Number(selectedRow.mutualFundCompanyId),
            stockValuation: Number(selectedRow.stockValuationId), // buy 
            subAccountId: Number(selectedRow.subAccountId),
            dateOfInvestment: new Date(selectedRow.dateOfInvestment),
            certificateFolioNo: selectedRow.certificateFolioNo
        }

        setAddFundData(data);
        setShowAddFund(true)
    }

    //handle edit 
    const alertMyRow = (selectedRow, a) => {
        setLoading(true);
        mutualFundTransaction(a.mutualFundHeaderId).then((res) => {
            setmFHeader_ID(a.mutualFundHeaderId);
            setmfund_ID(a.mutualFundId);
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    const result = res.data._response.data.mutualFundTrnsactionDetails;
                    setMFTransaction(result);
                    if (a.transactionType === "Merger") {
                        setFundMergeropen(true);
                        setmFHeader_ID(a.mutualFundHeaderId);

                    } else if (a.transactionType === "Bonus") {
                        setFundBonusopen(true);

                    } else if (a.transactionType === "Split") {
                        setFundSplitopen(true);
                    }
                    else if (a.transactionType === "DeMerger") {
                        const demerge = res.data._response.data.mutualFundTrnsactionDetails;
                        setDemergerData(demerge);
                        setmfund_ID(demerge.mutualFundDetails[0].mutualFundId);
                        setmfund_ID1(demerge.mutualFundDetails[1].mutualFundId);
                        setFundDemergeropen(true);
                    } else {
                        setshowMutualFund(true);
                    }

                }
            }
            else {
                setLoading(false);
            }
        })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    };

    //handle close other transactions
    const handleclose = async () => {
        setmFHeader_ID(0);
        setmfund_ID(0);
        setmfund_ID1(0);
        setMFTransaction({});
        setDemergerData({});
        setFundBonusopen(false);
        setFundSplitopen(false);
        setFundMergeropen(false);
        setFundDemergeropen(false);
        setFundBonusPreview(false)
        setFundSplitPreview(false);
        setFundMergerPreview(false);
        setFundDemergerPreview(false);
        onTransactionGridRefresh();
    };

    //preview mutual fund
    const handlePreview = (selectedRow, a) => {

        setLoading(true);

        if (a.transactionType === "Buy" || a.transactionType === "Sell") {
            let mutualFund_id = a.mutualFundId
            getSingleTransactionMFPreview(mutualFund_id).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        setmFHeader_ID(a.mutualFundHeaderId);
                        setmfund_ID(a.mutualFundId);
                        const result = res.data._response.data.fetchMutualFundsPreviewDetails;
                        setMFTransactionPreview(result);
                        setShowPreview(true);
                    }
                }
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            })
        } else {
            mutualFundTransaction(a.mutualFundHeaderId).then((res) => {
                setmFHeader_ID(a.mutualFundHeaderId);

                setmfund_ID(a.mutualFundId);
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        const result = res.data._response.data.mutualFundTrnsactionDetails;
                        setMFTransaction(result);

                        if (a.transactionType === "Merger") {
                            setFundMergerPreview(true);
                            setmFHeader_ID(a.mutualFundHeaderId);

                        } else if (a.transactionType === "Bonus") {
                            setFundBonusPreview(true);

                        } else if (a.transactionType === "Split") {
                            setFundSplitPreview(true);
                        }
                        else if (a.transactionType === "DeMerger") {
                            setFundDemergerPreview(true)

                        }
                        // else {
                        //     setShowPreview(true);
                        // }

                    }
                }
                else {
                    setLoading(false);
                }
            })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
            // setFundBonusPreview(true);
        }
    }

    const handleDelete = (e, a) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willdelete) => {
            if (willdelete) {
                setLoading(true);
                deleteMutualFundOtherTransaction(a.mutualFundHeaderId, a.mutualFundId).then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            Swal.fire(res.data._response.message, {
                                title: "Deleted",
                                icon: "success",
                            });

                            onTransactionGridRefresh();
                            handleCloseModel()
                        }
                    }
                    else {
                        setLoading(false);
                        onTransactionGridRefresh();
                    }
                })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    })
            } else {
                Swal.fire("Cancelled", "Your  data is safe ", "error");
            }
        }).catch((err) => {
            console.log("err in delete")
        })

    }

    //handle icon
    const handleIcon = (row, rows) => {
        let index = row.tableData.path[0];
        if (rows) {
            // setIconTest(RemoveIcon);
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>maximize</span>");
                    $(button).css({
                        transform: "rotate(180deg)",
                    });
                }
            });
        } else {
            $(".MuiTableRow-root").each(function () {
                let path = this.getAttribute("path");
                if (path == index) {
                    let button = $("button", this);
                    $(button).html("<span class='material-icons'>add</span>");
                }
            });
        }
    };

    // handle refresh
    const refreshMutualFund = async () => {
        await setLoading(false);
        await getMFFamilyOuterGrid(nonZero,script)
        await getFamilyData()
        if (entityId) {
            getMFEntityOuterGrid(entityId,nonZero,script)
            getEntityData(entityId)
        }
    }

    const onTransactionGridRefresh = async () => {
        setshowMutualFund(false);
        setMFTransaction({});
        setmFHeader_ID(0);
        await mutualFundIGdata(
            subAccount_ID,
            scheme_ID,
            livePrice,
            instumnet_ID,
            MFCompanyName
        )
    }

    //footer calculation by SubscriberId 
    const FamilyWiseStockCalculations = (instuid) => {

        if (entity === "Family") {
            getFamilyTotals(instuid)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            let instrumentid = Number(instuid)
                            let MFFamilyOuterGrid = res.data._response.data.getMutualFundsTotalCalculationBySubscriberId;

                            // group common companies data
                            let check = [];
                            MFFamilyOuterGrid.forEach((item, i) => {
                                if (check.indexOf(item.companyName) === -1) {
                                    check.push(item.companyName);
                                }
                            });
                            var parentData = [];
                            check.forEach((checkdata, index) => {
                                let mainData = {
                                    livePrice: 0,
                                    previousLivePrice: 0,
                                    quantity: 0,
                                    closingBalance: 0,
                                    name: "",
                                };
                                MFFamilyOuterGrid.forEach((item, i) => {
                                    if (checkdata === item.companyName) {
                                        let livePrice = item.livePrice ? item.livePrice : 0;
                                        let previousLivePrice = item.prevLivePrice ? item.prevLivePrice : 0;
                                        let quantity = mainData.quantity + item.quantity;
                                        let closingBalance = mainData.closingBalance + item.totalInvestment;
                                        mainData = {
                                            livePrice: livePrice,
                                            previousLivePrice: previousLivePrice,
                                            quantity: quantity,
                                            closingBalance: closingBalance,
                                            companyName: item.companyName,
                                            id: index
                                        };
                                    }
                                });
                                parentData.push(mainData);
                            })
                            //common obj for calculation
                            let calculationTotals = {
                                daysGainSum: 0,
                                daysGainSumPer: 0,
                                overAllGainSum: 0,
                                overAllGainSumPer: 0,
                                CurrentValue: 0,
                                totalInvestements: 0
                            }
                            // 
                            // calculate day'sgain , overallGAin etc. for individual company 
                            parentData.forEach((d, i) => {
                                let a = ((d.livePrice - d.previousLivePrice) * d.quantity).toFixed(2);
                                let b = (d.livePrice * d.quantity - d.closingBalance).toFixed(2);
                                let c = (d.quantity * d.livePrice).toFixed(2);
                                // let e = (((d.livePrice - d.previousLivePrice) * d.quantity) / (d.previousLivePrice * 100) * d.quantity)
                                d.daysGainSum = Number(a);
                                d.currentValue = Number(c);
                                // d.daysGainSumPer = Number(e);
                                d.overAllGainSum = Number(b);
                                // d.overAllGainSumPer = preciseMathDotRound((d.overAllGainSum / d.closingBalance) * 100);

                                //push the calculation  data in common object
                                calculationTotals.overAllGainSum += d.overAllGainSum;
                                // calculationTotals.overAllGainSumPer += d.overAllGainSumPer;
                                calculationTotals.daysGainSum += d.daysGainSum;
                                // calculationTotals.daysGainSumPer += isNaN(d.daysGainSumPer) ? 0 : d.daysGainSumPer;
                                calculationTotals.totalInvestements += d.closingBalance;
                                calculationTotals.CurrentValue += d.currentValue;

                            })

                            calculationTotals.overAllGainSumPer = (calculationTotals.overAllGainSum / calculationTotals.totalInvestements) * 100
                            calculationTotals.daysGainSumPer = (calculationTotals.daysGainSum / calculationTotals.totalInvestements) * 100

                            // setTotalData(calculationTotals)
                            setTotalData(calculationTotals)
                            setLoading(false);

                        } else {
                            setLoading(false);
                            setTotalValues([]);
                        }
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log("error");
                });

        } else {
            if (instuid && entityId) {
                getEntityTotals(entityId, instuid)
                    .then((res) => {
                        if (res.status === 200) {
                            if (res.data._response.status === 1) {
                                let MFEntityOuterGrid = res.data._response.data.getMutualFundsTotalCalculationBySubAccountId;

                                // group common companies data 
                                let check = [];
                                MFEntityOuterGrid.forEach((item, i) => {
                                    if (check.indexOf(item.companyName) === -1) {
                                        check.push(item.companyName);
                                    }
                                });
                                var parentData = [];
                                check.forEach((checkdata, index) => {
                                    let mainData = {
                                        livePrice: 0,
                                        previousLivePrice: 0,
                                        quantity: 0,
                                        closingBalance: 0,
                                        name: "",
                                    };
                                    MFEntityOuterGrid.forEach((item, i) => {
                                        if (checkdata === item.companyName) {
                                            let livePrice = item.livePrice ? item.livePrice : 0;
                                            let previousLivePrice = item.prevLivePrice ? item.prevLivePrice : 0;
                                            let quantity = mainData.quantity + item.quantity;
                                            let closingBalance = mainData.closingBalance + item.totalInvestment;
                                            mainData = {
                                                livePrice: livePrice,
                                                previousLivePrice: previousLivePrice,
                                                quantity: quantity,
                                                closingBalance: closingBalance,
                                                companyName: item.companyName,
                                                id: index
                                            };
                                        }
                                    });
                                    parentData.push(mainData);
                                })
                                //common obj for calculation
                                let calculationTotals = {
                                    daysGainSum: 0,
                                    daysGainSumPer: 0,
                                    overAllGainSum: 0,
                                    overAllGainSumPer: 0,
                                    CurrentValue: 0,
                                    totalInvestements: 0
                                }

                                // calculate day'sgain , overallGAin etc. for individual company 
                                parentData.forEach((d, i) => {
                                    let a = ((d.livePrice - d.previousLivePrice) * d.quantity).toFixed(2);
                                    let b = (d.livePrice * d.quantity - d.closingBalance).toFixed(2);
                                    let c = (d.quantity * d.livePrice).toFixed(2);
                                    d.daysGainSum = Number(a);
                                    d.currentValue = Number(c);
                                    d.overAllGainSum = Number(b);

                                    //push the calculation  data in common object
                                    calculationTotals.overAllGainSum += d.overAllGainSum;
                                    calculationTotals.daysGainSum += d.daysGainSum;
                                    calculationTotals.totalInvestements += d.closingBalance;
                                    calculationTotals.CurrentValue += d.currentValue;

                                })
                                calculationTotals.overAllGainSumPer = (calculationTotals.overAllGainSum / calculationTotals.totalInvestements) * 100
                                calculationTotals.daysGainSumPer = (calculationTotals.daysGainSum / calculationTotals.totalInvestements) * 100
                                // setTotalData(calculationTotals)
                                setTotalData(calculationTotals)
                                setLoading(false);

                            } else {
                                setLoading(false);
                                setTotalValues([]);
                            }
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.log("error");
                    });
            }
        }
    }

    // function for 1st captilized letter
    const UpperFirst = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // search function
    const requestSearch = (searchedVal) => {

        setSearched(searchedVal)
        const filteredRows = familyOGdata.filter((row) => {
            return row.mutualFundCompanyName.includes(searchedVal.toUpperCase()) || row.mutualFundCompanyName.includes(searchedVal.toLowerCase()) || row.mutualFundCompanyName.includes(UpperFirst(searchedVal));
        });


        //family wise
        let check = [];
        filteredRows.forEach((item, i) => {
            if (check.indexOf(item.mutualFundCompanyName) === -1) {
                check.push(item.mutualFundCompanyName);
            }
        });
        let parentData = [];
        let childData = [];
        check.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                previousLivePrice: 0,
                availableUnit: 0,
                closingBalance: 0,
                name: "",
            };
            filteredRows.forEach((item, i) => {
                if (checkdata === item.mutualFundCompanyName) {
                    let livePrice = item.livePrice;
                    let previousLivePrice = item.previousLivePrice;
                    let availableUnit = mainData.availableUnit + item.availableUnit;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    // let instrumentToken = item.instrumentToken
                    mainData = {
                        livePrice: livePrice,
                        previousLivePrice: previousLivePrice,
                        availableUnit: availableUnit,
                        closingBalance: closingBalance,
                        mutualFundCompanyName: item.mutualFundCompanyName,
                        id: index,
                        // instrumentToken: item.instrumentToken
                    };
                    let normalData = {
                        ...item,
                        mutualFundCompanyName: [item.subAccountName, " - ", item.instrumentTypeName],
                        parentId: index,
                        id: index + 1,
                        company: item.mutualFundCompanyName,
                    };
                    childData.push(normalData);
                }
            });
            parentData.push(mainData);
        });

        setFilteredData([...parentData, ...childData]);

        // entitywise
        const forentity = filteredRows.filter(a => a.subAccountId === entityId)

        let checkEntity = [];
        forentity.forEach((item, i) => {
            if (checkEntity.indexOf(item.mutualFundCompanyName) === -1) {
                checkEntity.push(item.mutualFundCompanyName);
            }
        });
        let parentDataEntity = [];
        let childDataEntity = [];
        checkEntity.forEach((checkdata, index) => {
            let mainData = {
                livePrice: 0,
                previousLivePrice: 0,
                availableUnit: 0,
                closingBalance: 0,
                name: "",
            };

            forentity.forEach((item, i) => {
                if (checkdata === item.mutualFundCompanyName) {
                    let livePrice = item.livePrice;
                    let previousLivePrice = item.previousLivePrice;
                    let availableUnit = mainData.availableUnit + item.availableUnit;
                    let closingBalance = mainData.closingBalance + item.closingBalance;
                    mainData = {
                        livePrice: livePrice,
                        previousLivePrice: previousLivePrice,
                        availableUnit: availableUnit,
                        closingBalance: closingBalance,
                        mutualFundCompanyName: item.mutualFundCompanyName,
                        id: index,
                    };

                    let normalData = {
                        ...item,
                        mutualFundCompanyName: item.transactionType,
                        parentId: index,
                        id: index + 1,
                    };

                    childDataEntity.push(normalData);
                }
            });
            parentDataEntity.push(mainData);
        });
        setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


    };

    // cancle search
    const cancelSearch = () => {
        setSearched("");
        // requestSearch(searched);
        if (entityId) {
            getMFEntityOuterGrid(entityId, nonZero,script)
        } else {
            getMFFamilyOuterGrid(nonZero,script)
        }
    };

    // non zero values
    const handleChangeValues = (e) => {
        const selected = Number(e.target.value)
        setnonZero(selected)
        // setScript(1)
        setSearched("");

        getMFFamilyOuterGrid(selected , script)
        getMFEntityOuterGrid(entityId, selected, script)
    }

    return (
        <div>
            <Container fluid>

                <Row>
                    <Col>
                        <h4 className="text-center py-3">Mutual Funds Folio</h4>
                    </Col>
                </Row>

                <FormControl className="mt-4">

                    <RadioGroup
                        row
                        color="primary"
                        defaultValue="start"
                        aria-label="Entity"
                        name="row-radio-buttons-group"
                        value={entity}
                        onChange={handleChangeEntity}
                        style={{ marginTop: "-3rem" }}
                    >
                        <FormControlLabel
                            value="Family"
                            control={<Radio />}
                            label="Family"
                        />
                        {entityRadio.map((a) => (
                            <FormControlLabel
                                key={a.subAccountName}
                                value={a.subAccountName}
                                control={<Radio />}
                                label={a.subAccountName}
                                disabled={a.active === 0 ? true : false}
                            />
                        ))}

                    </RadioGroup>
                </FormControl>

                <Row>
                    <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="flex-end"
                        alignItems="flex-start" mb={3} >
                        {entityId === null ? null :
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => openTransaction()}
                                    style={{ height: "2rem" }}
                                >
                                    Transactions
                                </Button>

                                {/* <Button variant="contained" color="primary" style={{ height: "2rem" }}>
                                    <MFundDropdown
                                        onRefresh={refreshMutualFund}
                                        className="stockdrop" />
                                </Button> */}
                            </>

                        }


                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={script}
                            label="Type"
                            onChange={handleScripData}
                            style={{ minWidth: 130, paddingLeft: "10px" }}
                            title="Transaction Type"

                        >
                            <MenuItem disabled >
                                <em>Mutual Fund Type</em>
                            </MenuItem>
                            <MenuItem value={"All"}>All </MenuItem>
                            <MenuItem value={41}>SIP</MenuItem>
                            <MenuItem value={42}>LUMPSUM</MenuItem>
                            <MenuItem value={43}>ULIP</MenuItem>

                        </Select>
                    </Stack>
                </Row>
                <Row >
                    <Box sx={{ flexGrow: 1 }} mb={2}>
                        <Grid container spacing={2} columns={16}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end">
                            <Grid item xs={6}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        color="primary"
                                        defaultValue="start"
                                        // aria-label="Entity"
                                        name="row-radio-buttons-group"
                                        value={nonZero}
                                        onChange={handleChangeValues}
                                    >
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label="Exclude Zero Assets"

                                        />
                                        <FormControlLabel
                                            value={0}
                                            control={<Radio />}
                                            label="Include Zero Assets"
                                        />



                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs="auto">
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => { requestSearch(searchVal) }}
                                    onCancelSearch={() => cancelSearch()}
                                    style={{
                                        // margin: "0 auto",
                                        maxwidth: 250,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>



                </Row>

                <Row>
                    <Col md="12">
                        <div style={{ maxwidth: "100%" }} className="tblHeaderLeft mutualftbl">
                            <MaterialTable
                                title=""
                                data={
                                    entity === "Family" ? filteredData : filteredEntiryData
                                }
                                columns={columns}

                                parentChildData={(row, rows) =>
                                    rows.find((a) => a.id === row.parentId)
                                }

                                components={{
                                    Body: (props) => {
                                        let totalDataS = {
                                            investmentCost: 0,
                                            overAllGainSum: 0,
                                            overAllGainSumPer: 0,
                                            daysGainSum: 0,
                                            daysGainSumPer: 0,
                                            marketPrice: 0,

                                        }
                                        props.renderData.forEach((rowData) => {
                                            totalDataS.investmentCost += rowData.closingBalance;
                                            totalDataS.daysGainSum += ((rowData.livePrice - rowData.previousLivePrice) * rowData.availableUnit);
                                            totalDataS.daysGainSumPer = (totalDataS.daysGainSum / totalDataS.investmentCost) * 100;
                                            totalDataS.overAllGainSum += rowData.livePrice ? ((rowData.livePrice - rowData.previousLivePrice)/ rowData.previousLivePrice) *100 : 0.00;
                                            totalDataS.overAllGainSumPer = (totalDataS.overAllGainSum / totalDataS.investmentCost) * 100;
                                            totalDataS.marketPrice += rowData.livePrice * rowData.availableUnit;
                                        })

                                        return (
                                            <>
                                                <MTableBody {...props} />
                                                {



                                                    <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                                                        <TableRow>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={2} align="right">Total</TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={5} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.investmentCost)}</TableCell>
                                                            <TableCell style={totalDataS.daysGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.daysGainSum))}</TableCell>
                                                            <TableCell style={totalDataS.daysGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.daysGainSumPer))}%</TableCell>
                                                            <TableCell style={totalDataS.overAllGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.overAllGainSum))}</TableCell>
                                                            <TableCell style={totalDataS.overAllGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.overAllGainSumPer))}%</TableCell>
                                                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.marketPrice))}</TableCell>
                                                        </TableRow>
                                                    </TableFooter>

                                                }
                                            </>
                                        )

                                    },
                                    // Body: (props) => {

                                    //     return (
                                    //         <>
                                    //             <MTableBody {...props} />
                                    //             {
                                    //                 script != "All" ?
                                    //                     <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                                    //                         <TableRow>
                                    //                             <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={2} align="right">Total</TableCell>
                                    //                             {/* <TableCell style={{color:"black",fontWeight:"500"}} colSpan={3} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalObj.qtySum)}</TableCell> */}
                                    //                             <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={5} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.totalInvestements)}</TableCell>
                                    //                             <TableCell style={totalData.daysGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.daysGainSum)}</TableCell>
                                    //                             <TableCell style={totalData.daysGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{preciseMathDotRound(totalData.daysGainSumPer)}%</TableCell>
                                    //                             <TableCell style={totalData.overAllGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.overAllGainSum)}</TableCell>
                                    //                             <TableCell style={totalData.overAllGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalData.overAllGainSumPer))}%</TableCell>
                                    //                             {/* <TableCell colSpan={4} /> */}
                                    //                             <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.CurrentValue)}</TableCell>
                                    //                         </TableRow>
                                    //                     </TableFooter> :
                                    //                     null
                                    //             }
                                    //         </>
                                    //     )

                                    // },
                                    Toolbar: (props) => {

                                        //calculate all header family
                                        let totalCost = filteredFamilyData.totalInvestment;
                                        // let dayGain = ((filteredFamilyData.livePrice - filteredFamilyData.prevLivePrice) * filteredFamilyData.quantity);
                                        let dayGain = filteredFamilyData.daygain
                                        let dayGainpert = (dayGain / totalCost) * 100.;
                                        // let overallGain = (filteredFamilyData.livePrice * filteredFamilyData.quantity - filteredFamilyData.totalInvestment);
                                        let overallGain = filteredFamilyData.overallgain
                                        let overallGainpert = (overallGain / totalCost) * 100;
                                        // let overallGainpert =  filteredFamilyData.overallgainper
                                        let currValue = filteredFamilyData.currentValue

                                        //calculate all header entity
                                        let totalCostE = filterEntityData.totalInvestmentc
                                        // let dayGainE = ((filterEntityData.livePricec - filterEntityData.prevLivePricec) * filterEntityData.quantityc);
                                        let dayGainE = filterEntityData.daysgainc
                                        let dayGainpertE = (dayGainE / totalCostE) * 100;
                                        // let overallGainE = (filterEntityData.livePricec * filterEntityData.quantityc - filterEntityData.totalInvestmentc)
                                        let overallGainE = filterEntityData.overallgainc
                                        let overallGainpertE = (overallGainE / totalCostE) * 100;
                                        let currValueE = filterEntityData.currentValuec;
                                        return (

                                            <>

                                                <MTableToolbar {...props} />
                                                {
                                                    script !== "All" ?

                                                        <div className="row mb-2 p-2">
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={{ fontSize: "1.4vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.totalInvestements) ? 0 : totalData.totalInvestements)}</h3>
                                                                        <p className="m-b-0">Amount Investment</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.daysGainSum < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }}>
                                                                            <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.daysGainSum) ? 0 : totalData.daysGainSum)}</span></h3>
                                                                        <p className="m-b-0" >Day's Gain</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.daysGainSumPer < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.5vw" }}
                                                                        >
                                                                            <span >{preciseMathDotRound(isNaN(totalData.daysGainSumPer) ? 0 : totalData.daysGainSumPer)}%</span></h3>
                                                                        <p className="m-b-0 font-weight-bold">Day's Gain(%)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.overAllGainSum < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }}>
                                                                            <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.overAllGainSum) ? 0 : totalData.overAllGainSum)}</span></h3>
                                                                        <p className="m-b-0">Overallgain</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={totalData.overAllGainSumPer < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }}>
                                                                            <span>{preciseMathDotRound(isNaN(totalData.overAllGainSumPer) ? 0 : totalData.overAllGainSumPer)}%</span></h3>
                                                                        <p className="m-b-0">Overallgain(%)</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={{ fontSize: "1.4vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.CurrentValue) ? 0 : totalData.CurrentValue)}</h3>
                                                                        <p className="m-b-0">Current Value</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :


                                                        <div className="row mb-2 p-2">
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={{ fontSize: "1.4vw" }}>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(filteredFamilyData.totalInvestment) ? 0 : (filteredFamilyData.totalInvestment).toFixed(2)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(filterEntityData.totalInvestmentc) ? 0 : (filterEntityData.totalInvestmentc).toFixed(2))}</h3>
                                                                        <p className="m-b-0">Amount Investment</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={entity === "Family" ? (dayGain < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }) : (dayGainE < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" })}>
                                                                            <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(dayGain) ? 0 : (dayGain).toFixed(2)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(dayGainE) ? 0 : (dayGainE).toFixed(2))}</span></h3>
                                                                        <p className="m-b-0" >Day's Gain</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={entity === "Family" ? (dayGainpert < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }) : (dayGainpertE < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" })}
                                                                        >
                                                                            <span >{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(dayGainpert) ? 0 : (dayGainpert.toFixed(2)))) : preciseMathDotRound(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(dayGainpertE) ? 0 : (dayGainpertE).toFixed(2)))}%</span></h3>
                                                                        <p className="m-b-0 font-weight-bold">Day's Gain(%)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={entity === "Family" ? (overallGain < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }) : (overallGainE < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" })}>
                                                                            <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(overallGain) ? 0 : overallGain.toFixed(2)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(overallGainE) ? 0 : overallGainE.toFixed(2))}</span></h3>
                                                                        <p className="m-b-0">Overallgain</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card  order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={entity === "Family" ? (overallGainpert < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" }) : (overallGainpertE < 0 ? { color: "#ff0000", fontSize: "1.4vw" } : { color: "#008000", fontSize: "1.4vw" })}>
                                                                            <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(overallGainpert) ? 0 : overallGainpert.toFixed(2))) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(overallGainpertE) ? 0 : overallGainpertE.toFixed(2)))}%</span></h3>
                                                                        <p className="m-b-0">Overallgain(%)</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-2 col-xl-2">
                                                                <div className="card order-card">
                                                                    <div className="card-block">
                                                                        <h3 className="text-right" style={{ fontSize: "1.4vw" }}>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(currValue) ? 0 : currValue.toFixed(2)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(currValueE) ? 0 : currValueE.toFixed(2))}</h3>
                                                                        <p className="m-b-0">Current Value</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}



                                            </>



                                        )

                                    },

                                }}
                                options={{
                                    headerStyle: {
                                        backgroundColor: "#01579b",
                                        color: "#FFF",
                                    },
                                    exportButton: false,
                                    search: false,
                                    toolbarButtonAlignment: "left",
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50],
                                    emptyRowsWhenPaging: false,
                                    owStyle: (rowData) => {
                                        return {
                                            pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                                            backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                                        };
                                    },
                                }}

                                onRowClick={(evt, selectedRow) => {
                                    if (selectedRow.tableData.childRows === null) {
                                        mutualFundIGdata(
                                            selectedRow.subAccountId,
                                            selectedRow.mutualFundCompanyId,
                                            selectedRow.livePrice,
                                            selectedRow.instrumentTypeId,
                                            selectedRow.company,
                                            selectedRow.transactionType
                                        );
                                    }
                                }}

                                onTreeExpandChange={(row, rows) => {
                                    handleIcon(row, rows);
                                }}
                                icons={{
                                    DetailPanel: iconsTest,
                                }}
                                isLoading={loading}
                            />
                        </div>

                        <MutualFundDetail
                            showMutualFund={showMutualFund}
                            hideMutualFund={handleCloseFund}
                            mutualFTransaction={MFTransaction}
                            mutualFHeaderId={mFHeader_ID}
                            mutualFId={mfund_ID}
                            entityId={entityId}
                            onMFTransactionGridRefresh={onTransactionGridRefresh}
                            handleCloseModel={handleCloseModel}

                        />

                        <MFundBonus
                            showB={fundbonusopen}
                            onHideB={handleclose}
                            mutualFHeaderIdB={mFHeader_ID}
                            MFTransactionB={MFTransaction}
                            MFIdBonus={mfund_ID}
                            showbonusPreview={fundBonusPreview}
                        />
                        <MFSplitmodal
                            showS={fundsplitopen}
                            onHideS={handleclose}
                            mutualFHeaderIdS={mFHeader_ID}
                            mutualFTransactionS={MFTransaction}
                            mutualFIdS={mfund_ID}
                            showsplitPreview={fundSplitPreview}
                        />

                        <MFundMerger
                            showM={fundmergeropen}
                            onHideM={handleclose}
                            mutualFHeaderIdM={mFHeader_ID}
                            mutualFTransactionM={MFTransaction}
                            mutualFIdM={mfund_ID}
                            showmergerPreview={fundMergerPreview}
                        />


                        <MFundDemerger
                            showD={funddemergeropen}
                            onHideD={handleclose}
                            mutualFHeaderIdDM={mFHeader_ID}
                            mutualFTransactionDM={demgerData}
                            mutualFIdDe1={mfund_ID}
                            mutualFIdDe2={mfund_ID1}
                            showdemergerPreview={fundDemergerPreview}
                        />

                        <AddFund
                            showFund={showAddFund}
                            hideFund={handleCloseFund}
                            Header={'Add Fund'}
                            FundData={AddFundData}
                            onMFTransactionGridRefresh={onTransactionGridRefresh}
                        />

                        <PreviewMuFund
                            showPreview={showPreview}
                            hidePreview={handleCloseFundPreview}
                            mutualFHeaderId={mFHeader_ID}
                            MFTransaction={MFTransactionPreview}
                            previewTransaction={MFTransactionPreview}
                        />

                        {/* inner grid */}
                        <Container
                            className="LoginContainer formWraper"
                            component="main"
                            maxwidth="xs"
                            tabIndex={-1}
                        >
                            <Modal
                                isOpen={showInnerGrid}
                                toggle={() => handleCloseModel()}
                                size="xl"
                                className="col-12 stockdetailmodalB"
                            >
                                <ModalHeader charcode="Y" toggle={() => handleCloseModel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                                    <> <h5 className="text-center">Mutual Fund Detail</h5> </>  
                                </ModalHeader>
                                <ModalBody>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }} >
                                        {loading ? (
                                            <Loader />
                                        ) : null}
                                        {
                                            entityId === null && state === null ?
                                                <TableContainer sx={{ maxHeight: 440 }} >
                                                    <p> Portfolio: {MFCompanyName}</p>
                                                    {/* family wise */}
                                                    <Table sx={{ minWidth: 450 }} stickyHeader aria-label="sticky table " >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="right" width="5%">
                                                                    Trans.
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Broker
                                                                </TableCell>
                                                                <TableCell align="right" width="6%">
                                                                    Date
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Folio Number
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Units
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Amount
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Sale Value
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Profit/Loss
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Balance Unit
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Actions
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                familyIGdata && familyIGdata.map((a) => (
                                                                    <TableRow
                                                                        key={a.id}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}>
                                                                        <TableCell align="right" width="5%">
                                                                            {a.transactionType}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.brokerName}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="6%">
                                                                            {moment(a.dateOfInvestment).format("DD/MM/YYYY")}

                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.certificateFolioNo}
                                                                        </TableCell>

                                                                        <TableCell align="right" width="7%">
                                                                            {a.transactionType === "Buy" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.totalUnits) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.units)}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">

                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.closingBalance) :
                                                                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalInvestmentAmt)}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">

                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.actualReceivable) :
                                                                                // new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.actualReceivable)
                                                                                "0.00"}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">

                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss) :
                                                                                // new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss)
                                                                                "0.00"}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.balanceUnit != null ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.balanceUnit).replace(/(\.0*|(?<=(\..*))0*)$/, '') :
                                                                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(0).replace(/(\.0*|(?<=(\..*))0*)$/, '')}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="5%">

                                                                            {a.transactionType === "Buy" && a.instrumentTypeId !== 43 ?
                                                                                <Tooltip title="Add Fund">
                                                                                    <IconButton disabled={a.show === 0}>
                                                                                        <AddRoundedIcon fontSize="small" onClick={(e) => handleAddFund(e, a)} />
                                                                                    </IconButton>
                                                                                </Tooltip> : null}

                                                                            <Tooltip title="View">
                                                                                <IconButton>
                                                                                    <Visibility fontSize="small" onClick={(e) => handlePreview(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton>
                                                                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {a.transactionType === "Bonus" || a.transactionType === "Split" || a.transactionType === "Merger" || a.transactionType === "DeMerger" ?
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton>
                                                                                        <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                                    </IconButton>
                                                                                </Tooltip> : null}

                                                                        </TableCell>
                                                                    </TableRow>

                                                                ))
                                                            }
                                                            {/*  closing balance  */}
                                                            <TableRow
                                                                // key={row.name}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": {
                                                                        border: 0,
                                                                    },
                                                                }}>
                                                                <TableCell align="left" className="headerbgcolor">
                                                                    Closing Balance
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className="headerbgcolor"
                                                                    colSpan="4"
                                                                >
                                                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(gridDetails.finalUnits).replace(/(\.0*|(?<=(\..*))0*)$/, '')}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className="headerbgcolor"

                                                                >
                                                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetails.finalCost)}
                                                                </TableCell>
                                                                <TableCell align="left" colSpan="4" className="headerbgcolor">

                                                                </TableCell>
                                                            </TableRow>

                                                            {
                                                                transactionType === 43 || transactionType === 41 || transactionType === 42 ? null :
                                                                    <TableRow
                                                                        // key={row.name}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}>
                                                                        <TableCell align="left" >
                                                                            Current Price: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(gridDetails.livePrice))}

                                                                        </TableCell>

                                                                        <TableCell align="right" colSpan="6" >
                                                                            Current Value: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(gridDetails.currentValue))}

                                                                        </TableCell>

                                                                    </TableRow>
                                                            }
                                                        </TableBody>

                                                    </Table>
                                                </TableContainer> :


                                                <TableContainer sx={{ maxHeight: 440 }} >
                                                    <p> Portfolio: {gridDetails.mutualFundCompanyName}</p>
                                                    {/* entity wise */}
                                                    <Table sx={{ minWidth: 450 }} stickyHeader aria-label="sticky table " >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="right" width="5%">
                                                                    Trans.
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Broker
                                                                </TableCell>
                                                                <TableCell align="right" width="6%">
                                                                    Date
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Folio Number
                                                                </TableCell>

                                                                <TableCell align="right" width="7%">
                                                                    Units
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Amount
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Sale Value
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Profit/Loss
                                                                </TableCell>
                                                                <TableCell align="right" width="7%">
                                                                    Balance Unit
                                                                </TableCell>
                                                                <TableCell align="right" width="5%">
                                                                    Actions
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                showLoader === false ? <Loader /> :
                                                                    enityIGdata && enityIGdata.map((a) => (
                                                                        <TableRow
                                                                            key={a.id}
                                                                            sx={{
                                                                                "&:last-child td, &:last-child th": {
                                                                                    border: 0,
                                                                                },
                                                                            }}>
                                                                            <TableCell align="right" width="5%">
                                                                                {a.transactionType}
                                                                            </TableCell>
                                                                            <TableCell align="right" width="7%">
                                                                                {a.brokerName}
                                                                            </TableCell>
                                                                            <TableCell align="right" width="6%">
                                                                                {moment(a.dateOfInvestment).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                            <TableCell align="right" width="7%">
                                                                                {a.certificateFolioNo}
                                                                            </TableCell>
                                                                            <TableCell align="right" width="7%">
                                                                                {a.transactionType === "Buy" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.totalUnits) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.units)}
                                                                            </TableCell>

                                                                        <TableCell align="right" width="7%">
                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.closingBalance) :
                                                                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalInvestmentAmt)}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.actualReceivable) :
                                                                                // new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.actualReceivable)
                                                                                "0.00"}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.transactionType === "Sell" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss) :
                                                                                // new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss)
                                                                                "0.00"}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="7%">
                                                                            {a.balanceUnit != null ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.balanceUnit).replace(/(\.0*|(?<=(\..*))0*)$/, '') :
                                                                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(0).replace(/(\.0*|(?<=(\..*))0*)$/, '')}
                                                                        </TableCell>
                                                                        <TableCell align="right" width="5%">

                                                                            {a.transactionType === "Buy" && a.instrumentTypeId !== 43 ?
                                                                                <Tooltip title="Add Fund">
                                                                                    <IconButton disabled={a.show === 0}>
                                                                                        <AddRoundedIcon fontSize="small" onClick={(e) => handleAddFund(e, a)} />
                                                                                    </IconButton>
                                                                                </Tooltip> : null}

                                                                            <Tooltip title="View">
                                                                                <IconButton>
                                                                                    <Visibility fontSize="small" onClick={(e) => handlePreview(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <Tooltip title="Edit">
                                                                                <IconButton>
                                                                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {a.transactionType === "Bonus" || a.transactionType === "Split" || a.transactionType === "Merger" || a.transactionType === "DeMerger" ?
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton>
                                                                                        <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                                                                    </IconButton>
                                                                                </Tooltip> : null}



                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }

                                                            <TableRow
                                                                // key={row.name}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": {
                                                                        border: 0,
                                                                    },
                                                                }}>
                                                                <TableCell align="left" className="headerbgcolor">
                                                                    Closing Balance
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className="headerbgcolor"
                                                                    colSpan="1"
                                                                ></TableCell>

                                                                <TableCell
                                                                    align="right"
                                                                    className="headerbgcolor"
                                                                    colSpan="3"
                                                                >
                                                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(gridDetails.finalUnits)}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className="headerbgcolor"

                                                                >
                                                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetails.finalCost)}
                                                                </TableCell>
                                                                <TableCell align="left" colSpan="4" className="headerbgcolor">

                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                transactionType === 43 ? null :
                                                                    <TableRow
                                                                        // key={row.name}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}>
                                                                        <TableCell align="left" >
                                                                            Current Price: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(gridDetails.livePrice))}

                                                                        </TableCell>

                                                                        <TableCell align="right" colSpan="9" >
                                                                            Current Value: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(gridDetails.currentValue))}

                                                                        </TableCell>

                                                                    </TableRow>
                                                            }


                                                        </TableBody>

                                                    </Table>
                                                </TableContainer>

                                        }

                                    </Paper>
                                </ModalBody>
                            </Modal>
                        </Container>


                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MutualFundTable