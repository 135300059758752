/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Modal, Col, Form } from 'react-bootstrap'
import Select, { components } from "react-select"
import MaterialTable, { MTableEditField } from "material-table";
import { Stack } from "@mui/material";
import { Alert, AlertTitle } from '@material-ui/lab';
import { brokerList, GetDividendFrequency } from '../../../pages/Stocks/StocksAPI';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GetMutualFundCompanies, AddMutualFundCompanies, MutualFundOpeningBalance } from '../../../pages/MutualFund/MutualFundAPI';
import Swal from 'sweetalert2';
import _ from "lodash";
import DatePicker from 'react-datepicker';
import Dropdown from '../../CommonComponent/Dropdown';
import { periodList } from '../../../modules/helpers/constant'
import moment from 'moment';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavefocusedTxtField,cleavedisabledField } from '../../StocksDetail/ClevTablCss';
import useEnterKeyListener from '../../ShortcutKey/useKeyListener';
import Cleave from 'cleave.js/react';

function MFOpeningBalance(props) {
  const { typeMF, familyMF, entityId, brokerCall } = props

  // const [columnsB3, setColumnsB3] = useState([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [frequencyValue, setFrequencyValue] = useState([]);
  const [dividendFrequency, setDividendfrequency] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [brokerData, setBrokerList] = useState([]);
  const [error, setError] = useState('');
  const [brokerUnlisted, setBrokerUnlisted] = useState([]);
  const [headerId, setHeaderId] = useState(0);
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');
  const [date, setDate] = useState(new Date());
  const [divFreqValueULIP, setDivFreqValueULIP] = useState([]);
  const [divFreqValueULIPOption, setDivFreqValueULIPOption] = useState([]);
  const [mutualFHeaderId, setMutualFHeaderId] = useState(0);

  //addition modal states
  const [showUlipAdd, setShowUlipAdd] = useState(false);
  const [maturityDateUlip, setMaturityDateUlip] = useState(null);

  const [lastPremiumDate, setLastPremiumDate] = useState(null);
  const [ulipBonus, setUlipBonus] = useState("");
  const [ulipGoal, setUlipGoal] = useState("");
  const [ulipMaturityValue, setUlipMaturityValue] = useState("");
  const [ulipNominees, setNominees] = useState("");
  const [lockIn, setLockIn] = useState([{ label: "Month", value: "Month" }]);
  const [lockInValue, setLockInValue] = useState("Month");
  const [lockinperiod, setLockinperiod] = useState("");
  const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
  const [timeValue, setTimeValue] = useState("Month");
  const [tenurePeriod, setTenurePeriod] = useState("");

  const [formErr, setFormErr] = useState({});
  const [scheme, setScheme] = useState([]);
  const [schemeErr, setSchemeErr] = useState('');

  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);
  const [allEntry2, setAllEntry2] = useState([]);
  const [allEntry3, setAllEntry3] = useState([]);

  function replaceNonNumeric(numStr) {
    return String(numStr).replace(/[^0-9]/g, '')
  }

  function commarize(numStr) {
    return Number(replaceNonNumeric(numStr)).toLocaleString()
  }

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(input)
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  // useEffect(() => {
  //   if (typeMF == 3) {
  //     setColumnsB3(
  //       [
  //       {
  //         title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData != undefined) {
  //             if (props.rowData.tableData.editing != undefined) {
  //               if (props.rowData.tableData.editing == 'update') {
  //                 if (props.value.label != undefined) {
  //                   if (props.value.label != props.rowData.mutualFundCompany.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.mutualFundCompany
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.mutualFundCompany
  //                 }
  //               }
  //             }
  //           }
  //           return (
  //             <Select
  //               components={{ Control }}
  //               value={ddValue || null}
  //               onChange={(selected) => props.onChange(selected)}
  //               options={companyList}
  //               name="companyId"
  //               onKeyDown={e => handleKey(e)}
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             // isDisabled={mutualFHeaderId != 0 ? true : false}
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Broker', field: 'brokerId', width: 25,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData !== undefined) {
  //             if (props.rowData.tableData.editing !== undefined) {
  //               if (props.rowData.tableData.editing === 'update') {
  //                 if (props.value.label !== undefined) {
  //                   if (props.value.label !== props.rowData.brokerDetails.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.brokerDetails
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.brokerDetails
  //                 }
  //               }
  //             }
  //           }
  //           return (
  //             <Select
  //               value={ddValue || null}
  //               onChange={(selected) => props.onChange(selected)}
  //               // options={Broker}
  //               options={brokerData}
  //               name="brokerId"
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Date', field: 'dateOfPremium', type: 'date',
  //         editComponent: props => {
  //           return (
  //             <MuiPickersUtilsProvider utils={DateFnsUtils}
  //               locale={props.dateTimePickerLocalization}>
  //               <DatePicker
  //                 format="dd/MM/yyyy"
  //                 value={props.value || null}
  //                 onChange={props.onChange}
  //                 clearable
  //                 maxDate={Date.now()}
  //               />
  //             </MuiPickersUtilsProvider>
  //           )
  //         }
  //       },
  //       {
  //         title: 'Insurance Company', field: 'insuranceCompany', width: 190, type: 'text',
  //         editComponent: ({ ...props }) => {
  //           // handleAddRowBuy();
  //           return <MTableEditField {...props} />;
  //         },
  //       },
  //       {
  //         title: 'Insured Name', field: 'nameofInsured', width: 130, type: 'text',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField {...props} />;
  //         },
  //       },
  //       { title: 'Policy Number', field: 'policyNumber', width: 50 },
  //       {
  //         title: 'Units', field: 'units', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField {...props} />;
  //         },
  //       },
  //       {
  //         title: 'Sum Assured', field: 'sumAssured', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField  {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.sumAssured)}
  //           </span>
  //         ),
  //       },
  //       {
  //         title: 'Premium Amount', field: 'investmentAmt', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField  {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.investmentAmt)}
  //           </span>
  //         ),
  //       },
  //       {
  //         title: 'Current Market Value', field: 'currentMarketValue', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField  {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.currentMarketValue)}
  //           </span>
  //         ),
  //       },
  //       {
  //         title: "Addtional Data", field: "internal_action",
  //         // editable: false,
  //         editComponent: ({ ...props }) => {
  //           let t = props.rowData
  //           return <span onClick={() => {
  //             // if (mutualFHeaderId == 0) {

  //             // }
  //             // setCost(t.totalCost)
  //             setShowUlipAdd(true)
  //           }}
  //             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
  //         }
  //       }
  //     ])
  //   }
  // }, [companyList, brokerData, typeMF == 3])

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getMFCompanyList();
      await getDividedFrequencyList();
    })();
  }, [])

  useEffect(() => {
    (async () => {
      if(familyMF.value || brokerCall === true){
        await getBrokerList();
      }
    })();
  }, [familyMF, brokerCall])

  const getMFCompanyList = async () => {
    setLoading(true);
    await GetMutualFundCompanies()
      .then((res) => {
        if (res.status === 200) {
          let company = res.data._response.data.mutualFundCompanies;
          let companyData = [];
          company &&
            company.map((data) => {
              return (companyData.push({
                value: data.mutualFundCompanyId,
                label: data.mutualFundCompanyName,
                companyType: data.instrumentTypeId
              }))
            });
          setCompanyList(companyData);
          setLoading(false);
          // if (unListedParam === 1) {
          //   let lastElement = companyData[companyData.length - 1];
          //   setSelScrip(lastElement)
          // }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        // setError(e.message);
      });
  };
  const getBrokerList = async () => {
    let id = familyMF.value;
    setLoading(true)
    await brokerList(id).then(res => {
      setLoading(false)
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let brokers = res.data._response.data.brokerDetails;
          let brokersData = [];
          let brokerUnlist = [];
          brokers && brokers.map(data => {
            brokerUnlist.push({ value: 1, label: "N/A" })
            brokersData.push({ value: data.brokerId, label: data.brokerName })
          })
          setBrokerList(brokersData);
          setBrokerUnlisted(brokerUnlist)
        }
      } else {
        setLoading(false)
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false)
    })
  }
  const getDividedFrequencyList = async () => {
    setLoading(true);
    await GetDividendFrequency()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
                return (dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                }))
              });
            setDividendfrequency(dividendData);
            if (headerId === 0) {
              setFrequencyValue(dividendData[1])
            }
            if (mutualFHeaderId === 0) {
              setDivFreqValueULIP(dividendData[1])
              setDivFreqValueULIPOption(dividendData)
            }
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });
  };

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const handleShow = () => setShow(true);

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <span onMouseDown={handleShow} className="m-0" title="Add unlisted scrip">
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )

  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  const handleClose = () => {
    setShow(false);
    setScheme([]);
    setSchemeErr('')
  }

  //close ulip addition modal
  const handlecloseDescModal = () => {
    setShowUlipAdd(false);
    setFormErr('');
    setLoading(false);
  }

  // Ulip buy addtional modal onChange
  const handleAddtiUlipData = (e) => {
    const { name, rawValue,value } = e.target;
    if (name === "nominees") {
      setNominees(value);
    } else if (name === "bonus") {
      setUlipBonus(rawValue);
    } else if (name === "goal") {
      setUlipGoal(rawValue)
    } else {
      setUlipMaturityValue(rawValue);
    }

  }

  const handleChangeFrequency = (e) => {
    setDivFreqValueULIP(e);
  }

  const HandleChangePeriod = (e) => {
    setTime(e);
    setTimeValue(e.value);
  }

  const HandleChangeLockIn = (e) => {
    setLockIn(e);
    setLockInValue(e.value);
  }

  //submit ulip addtional modal
  const submitPreModal = () => {
    handlecloseDescModal();
  }

  //reset ulip addition modal
  const handleResetModalPopUp = () => {
    setLastPremiumDate(null);
    setTenurePeriod("");
    setMaturityDateUlip(null);
    if(typeMF !== 1){
      setDividendfrequency([]);
    }
    setLockinperiod("");
    setNominees("");
    setUlipBonus("");
    setUlipGoal("");
    setUlipMaturityValue("");
    setLockIn([{ label: "Month", value: "Month" }])
    setTime([{ label: "Month", value: "Month" }])
  }

  const handleScheme = (e) => {
    // const { value } = e.target;
    // setScheme(value);
    // setSchemeErr('')
    const { value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }
  //submit unlisted companies
  const submitUnlisted = (e) => {
    e.preventDefault();
    if (unlistedComp !== '') {
      let subAccountId = props.familyMF.value
      let Id = entityId == null ? subAccountId : entityId;
      // let comId = newFilterTypeFD === 2 ? 10 : 20;
      let instIds;
      if (typeMF === 1) {
        instIds = 41
      }
      if (typeMF === 2) {
        instIds = 42
      }

      let data = {
        mutualFundCompanyName: unlistedComp.trim(),
        // instrumentTypeId: newFilterTypeFD === 2 ? 10 : 20,
        instrumentTypeId: instIds,
        subAccountId: Id
      }
      // let data = { 
      //   "companyName": unlistedComp
      // }
      // addUnlistedScrip(data)
      AddMutualFundCompanies(data)
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              // const lastEle = 1
              // getMFCompanyList(lastEle);
              setUnlistedComp('');
              setUnlistedCompErr('');
              handleClose()
              Swal.fire("Scrip Added successfully!", "", 'success');
              let t = res.data._response.data.newCompanyDetail.mutualFundCompanyName
              let v = res.data._response.data.newCompanyDetail.mutualFundCompanyId
              let comp = { label: t, value: v }
              companyList.unshift(comp)
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong!", "", 'info');
            setLoading(false)
          }
        })
        .catch(e => {
          setError(e.message)
          setLoading(false)
        })
    } else {
      setUnlistedCompErr("Scheme name required!")
    }
  }

  //function for adding a new row to the table
  const handleRowAdd = async (newData, resolve, reject) => {
    let errorList = []
    if (familyMF.value === 0 || familyMF.value === undefined || familyMF.length < 1 || familyMF.length === 0) {
      errorList.push("Please Select Family Field");
    }
    if (typeMF === undefined) {
      errorList.push("Please Select Type Field");
    }
    if (typeMF === 1) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Field");
      }
      if (!newData.brokerId) {
        errorList.push("Please Select Broker Field");
      }
      if (!newData.date) {
        errorList.push("Please Select Date Of Investment Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (!newData.frequencyId) {
        errorList.push("Please Select Sip Frequncy Field");
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Certificate/Folio No. Field")
      }
    }
    if (typeMF === 2) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Field");
      }
      if (!newData.brokerId) {
        errorList.push("Please Select Broker Field");
      }
      if (!newData.date) {
        errorList.push("Please Select Date Of Investment Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Certificate/Folio No. Field")
      }
    }
    if (typeMF === 3) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.brokerId) {
        errorList.push("Please Select Broker Name Field")
      }
      if (!newData.date) {
        errorList.push("Please Enter in Date Field")
      }
      // if (!newData.insuranceCompany) {
      //   errorList.push("Please Enter Insurance Company Field")
      // }
      if (!newData.nameofInsured) {
        errorList.push("Please Enter Insured Name Field")
      }
      if (!newData.policyNumber) {
        errorList.push("Please Enter Policy No. Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.sumAssured || newData.sumAssured < 0 || isNaN(newData.sumAssured)) {
        errorList.push("Please Enter Sum Assured Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Premium Amount Field")
      }
      // if (!newData.currentMarketValue || newData.currentMarketValue < 0 || isNaN(newData.currentMarketValue)) {
      //   errorList.push("Please Enter Current Market Value Field")
      // }
    }

    if (errorList.length < 1) {
      let companyData = newData.mutualFundCompanyId;
      newData.mutualFundCompanyId = newData.mutualFundCompanyId.label;
      newData.mutualFundCompany = companyData;

      let brokerData = newData.brokerId;
      newData.brokerId = newData.brokerId.label;
      newData.brokerDetails = brokerData;

      if (typeMF === 1) {
        let frequencyData = newData.frequencyId;
        newData.frequencyId = newData.frequencyId.label;
        newData.frequency = frequencyData;
      }

      if (typeMF === 3) {
        // newData.dateOfPremium = fixTimezoneOffset(date);
        newData.dateOfLastPremium = lastPremiumDate ? fixTimezoneOffset(lastPremiumDate) : null;
        newData.nominees = ulipNominees;
        newData.bonusAccrued = Number(ulipBonus);
        newData.frequencyId = divFreqValueULIP.value;
        newData.goal = ulipGoal;
        newData.tenure = tenurePeriod === "" ? "0" + timeValue : tenurePeriod + timeValue;
        newData.maturityDate = maturityDateUlip ? fixTimezoneOffset(maturityDateUlip) : maturityDateUlip;
        newData.maturityValue = Number(ulipMaturityValue);
        newData.lockInPeriod = lockinperiod === "" ? "0" + lockInValue : lockinperiod + lockInValue;
      }

      let newUserdata = typeMF === 1 ? [...allEntry1] : typeMF === 2 ? [...allEntry2] : [...allEntry3];
      newData.instrumentTypeId = typeMF === 1 ? 41 : typeMF === 2 ? 42 : 43;
      newUserdata.push(newData);
      var result = newUserdata.map(function (o) {
        if (o.mutualFId === undefined) {
          o.transactionType = "Buy";
          o.stockValuationId = 4;
        }
        return o;
      })
      await typeMF === 1 ? setAllEntry1(result) : typeMF === 2 ? setAllEntry2(result) : setAllEntry3(result);
      resolve();
      setErrorMessages([]);
      setIserror(false);
      setLoading(false);
      handleResetModalPopUp();
      resolve();
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      reject();
      setLoading(false)
    }
    setLoading(false)
  }
  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    let cpy = newData.mutualFundCompany.label;
    let companyData = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompany : newData.mutualFundCompanyId;

    let brk = newData.brokerDetails.label;
    let brkData = brk === newData.brokerId && newData.brokerId.label === undefined ? newData.brokerDetails : newData.brokerId
    if (typeMF === 1) {
      var fpy = newData.frequency.label;
      var frequencyData = fpy === newData.frequencyId && newData.frequencyId.label === undefined ? newData.frequency : newData.frequencyId;
    }

    let errorList = [];
    if (typeMF === 1) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Company Name Field");
      }
      if (!newData.brokerId) {
        errorList.push("Please Select Broker Field");
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (!newData.frequencyId) {
        errorList.push("Please Select Sip Frequncy Field");
      }
    }
    if (typeMF === 2) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Enter Company Name Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Folio Number Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
    }
    if (typeMF === 3) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.brokerId) {
        errorList.push("Please Select Broker Name Field")
      }
      if (!newData.date) {
        errorList.push("Please Enter in Date Field")
      }
      if (!newData.insuranceCompany) {
        errorList.push("Please Enter Insurance Company Field")
      }
      if (!newData.nameofInsured) {
        errorList.push("Please Enter Insured Name Field")
      }
      if (!newData.policyNumber) {
        errorList.push("Please Enter Policy No. Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.sumAssured || newData.sumAssured < 0 || isNaN(newData.sumAssured)) {
        errorList.push("Please Enter Sum Assured Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Premium Amount Field")
      }
      // if (!newData.currentMarketValue || newData.currentMarketValue < 0 || isNaN(newData.currentMarketValue)) {
      //   errorList.push("Please Enter Current Market Value Field")
      // }
    }

    if (errorList.length < 1) {
      const updateStock = typeMF === 1 ? [...allEntry1] : typeMF === 2 ? [...allEntry2] : [...allEntry3];
      const index = oldData.tableData.id;
      newData.mutualFundCompanyId = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompanyId : newData.mutualFundCompanyId.label !== undefined ? newData.mutualFundCompanyId.label : newData.mutualFundCompanyId.label;
      newData.mutualFundCompany = companyData;

      // let brokerData = newData.brokerId;
      // newData.brokerId = newData.brokerId.label;
      newData.brokerId = brk === newData.brokerId && newData.brokerId.label === undefined ? newData.brokerId : newData.brokerId.label !== undefined ? newData.brokerId.label : newData.brokerId.label;
      newData.brokerDetails = brkData;

      if (typeMF === 1) {
        newData.frequencyId = fpy === newData.frequencyId && newData.frequencyId.label === undefined ? newData.frequencyId : newData.frequencyId.label !== undefined ? newData.frequencyId.label : newData.frequencyId.label;
        newData.frequency = frequencyData;
      }
      let checkIsChange = (lastPremiumDate !== null || ulipNominees !== "" || ulipBonus !== "" || ulipGoal !== "" || tenurePeriod !== "" || timeValue !== "Month" || maturityDateUlip !== null || ulipMaturityValue !== "" || lockinperiod !== "" || lockInValue !== "Month")
      if (typeMF === 3 && checkIsChange) {
        // newData.dateOfPremium = fixTimezoneOffset(date);
        newData.dateOfLastPremium = lastPremiumDate ? fixTimezoneOffset(lastPremiumDate) : null;
        newData.nominees = ulipNominees;
        newData.bonusAccrued = Number(ulipBonus);
        newData.frequencyId = divFreqValueULIP.value;
        newData.goal = ulipGoal;
        newData.tenure = tenurePeriod === "" ? "0" + timeValue : tenurePeriod + timeValue;
        newData.maturityDate = maturityDateUlip ? fixTimezoneOffset(maturityDateUlip) : null;
        newData.maturityValue = Number(ulipMaturityValue);
        newData.lockInPeriod = lockinperiod === "" ? "0" + lockInValue : lockinperiod + lockInValue;
      }
      newData.instrumentTypeId = typeMF === 1 ? 41 : typeMF === 2 ? 42 : 43;

      updateStock[index] = newData;
      typeMF === 1 ? setAllEntry1([...updateStock]) : typeMF === 2 ? setAllEntry2([...updateStock]) : setAllEntry3([...updateStock]);
      resolve();
      setErrorMessages([]);
      setIserror(false);
      handleResetModalPopUp();
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      reject();
      handleResetModalPopUp();
    }
  }
  //function for deleting a row
  const handleRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = typeMF === 1 ? [...allEntry1] : typeMF === 2 ? [...allEntry2] : [...allEntry3];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    await typeMF === 1 ? setAllEntry1([...dataDelete]) : typeMF === 2 ? setAllEntry2([...dataDelete]) : setAllEntry3([...dataDelete]);
    resolve()
    // props.parentCallback(true);
    // props.lock(false, 1)
  }

  const handelSubmitTable = async () => {
    setLoading(true);
    if (allEntry1.length >= 1 || allEntry2.length >= 1 || allEntry3.length >= 1) {
      let resultDataBuy = typeMF === 1 ? allEntry1 : typeMF === 2 ? allEntry2 : allEntry3
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultOpenBal = deepCloneBuy.map(function (o) {
        // var resultBuy = resultDataBuy.map(function (o) {
        o.mutualFundCompanyId = o.mutualFundCompany.value;
        o.brokerId = o.brokerDetails.value;
        o.instrumentId = 8;
        o.subAccountId = props.familyMF.value;
        if (typeMF === 1) {
          o.frequencyId = o.frequency.value;
        }
        o.type = "Normal";
        return o;
      })
      if (allEntry1 || allEntry2 || allEntry3) {
        await MutualFundOpeningBalance(resultOpenBal).then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              Swal.fire("Mutual Fund Details Added Successfully!", "", 'success');
              setAllEntry1([]);
              setAllEntry2([]);
              setAllEntry3([]);
              setErrorMessages([])
              setIserror(false)
              props.parentCallback(true);
              // props.lock(false, 1);
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong!", "", 'error');
            setLoading(false)
          }
        }).catch(e => {
          Swal.fire("Something went wrong!", "", 'error');
          setError(e.message)
          setLoading(false)
        })
      }
      else {
        Swal.fire("please fill required fields!", "", 'info');
        setLoading(false)
      }
    }
    else {
      Swal.fire("please fill required information!", "", 'info');
      setLoading(false)
    }
    handleResetModalPopUp();
  }

  const handelResetTable = () => {
    setAllEntry1([]);
    setAllEntry2([]);
    setErrorMessages([]);
    setIserror(false);
    props.parentCallback(true);
  }

  const disbtn = () => {
    return familyMF && typeMF
  }

  // state clear for all family change
  const clearAll = () => {
    setAllEntry1([]);
    setAllEntry2([]);
    setAllEntry3([]);
    setErrorMessages([]);
    setIserror(false);
  }
  // state clear for all family change
  props.childFunc.current = clearAll

  const columnsB1 = [
    {
      title: 'Scheme', field: 'mutualFundCompanyId', width: "100%",
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="mutualFundCompanyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.brokerDetails.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.brokerDetails
                }
              }
              else {
                ddValue = props.rowData.brokerDetails
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of Investment', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Units', field: 'units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Units'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Amount Invested', field: 'investmentAmt', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      title: 'NAV / Purchase Price', field: 'nav', width: 130, type: 'numeric',
      // render: (rowData) => (
      //   <span>
      //     {NumberOnlySeperator(rowData.nav)}
      //   </span>
      // ),
      editComponent: ({ ...props }) => {
        let calculatedNAV = 0;
        if (props.rowData.investmentAmt && props.rowData.units) {
          calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
          props.value = calculatedNAV;
          props.rowData.nav = calculatedNAV;
        } else {
          props.value = 0;
          props.rowData.nav = 0;
        }
        return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='NAV / Purchase Price'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={true} />;
      },
    },
    {
      title: 'SIP Date', field: 'sipDate', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            // maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.sipDate).format('DD/MM/YYYY')
      )
    },
    {
      title: 'SIP Amount', field: 'sipAmt', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.sipAmt ? NumberSeperator(rowData.sipAmt) : rowData.sipAmt}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='SIP Amount'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField  {...props} />;
      },
    },
    {
      title: 'SIP Frequency', field: 'frequencyId', width: 130,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.frequency.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.frequency
                }
              }
              else {
                ddValue = props.rowData.frequency
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={dividendFrequency}
            name="frequencyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'No. of Installments', field: 'numberOfInstalments', width: 70, type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.numberOfInstalments ? new Intl.NumberFormat('en-IN').format(rowData.numberOfInstalments) : rowData.numberOfInstalments}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='No. of Installments'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'maturityDate', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            // maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.maturityDate).format('DD/MM/YYYY')
      )
    },
    { title: 'Certificate / Folio No.', field: 'certificateFolioNo', width: 50 },
    // { title: 'Lock in Period', field: 'lockInPeriod', width: 130 },
    // { field: 'recommendedBy', title: 'Recommended by', width: 130 },
  ];

  const columnsB2 = [
    {
      title: 'Scheme Name', field: 'mutualFundCompanyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.brokerDetails.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.brokerDetails
                }
              }
              else {
                ddValue = props.rowData.brokerDetails
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of Investment', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      field: 'units', title: 'Units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Units'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'investmentAmt', title: 'Amount Invested', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField  {...props} />;
      },
    },
    {
      field: 'nav', title: 'NAV / Purchase Price', width: 130, type: 'numeric',
      // render: (rowData) => (
      //   <span>
      //     {rowData.nav ? NumberSeperator(rowData.nav) : rowData.nav}
      //   </span>
      // ),
      editComponent: ({ ...props }) => {
        let calculatedNAV = 0;
        if (props.rowData.investmentAmt && props.rowData.units) {
          calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
          props.value = calculatedNAV;
          props.rowData.nav = calculatedNAV;
        }
        else {
          props.value = 0;
          props.rowData.nav = 0;
        }
        return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='NAV / Purchase Price'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField {...props} disabled={true} />;
      },
    },
    {
      field: 'certificateFolioNo', title: 'Certificate / Folio No.', width: 70,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    // { field: 'Lock in Period', title: 'Lock in Period', width: 130 },
    // { field: 'estHoldingPeriod', title: 'Estimated Holding period', width: 130 },
  ];

  const columnsB3 = [
    {
      title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          // isDisabled={mutualFHeaderId != 0 ? true : false}
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.brokerDetails.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.brokerDetails
                }
              }
              else {
                ddValue = props.rowData.brokerDetails
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Insurance Company', field: 'insuranceCompany', width: 190, type: 'text',
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Insured Name', field: 'nameofInsured', width: 130, type: 'text',
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    { title: 'Policy Number', field: 'policyNumber', width: 50 },
    {
      title: 'Units', field: 'units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Units'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Sum Assured', field: 'sumAssured', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Sum Assured'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.sumAssured)}
        </span>
      ),
    },
    {
      title: 'Premium Amount', field: 'investmentAmt', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Premium Amount'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
    },
    {
      title: 'Current Market Value', field: 'currentMarketValue', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let value = props.rowData.currentMarketValue ? props.rowData.currentMarketValue : 0
        props.rowData.currentMarketValue = value;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Current Market Value'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.currentMarketValue)}
        </span>
      ),
    },
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.dateOfLastPremium !== undefined && t.dateOfLastPremium !== "") {
              setLastPremiumDate(t.dateOfLastPremium ? new Date(t.dateOfLastPremium) : null);
            }
            if (t.maturityDate !== undefined && t.maturityDate !== "") {
              setMaturityDateUlip(t.maturityDate ? new Date(t.maturityDate) : null);
            }
            if (t.bonusAccrued !== undefined) {
              setUlipBonus(t.bonusAccrued)
            }
            if (t.goal !== undefined) {
              setUlipGoal(t.goal);
            }
            if (t.nominees !== undefined) {
              setNominees(t.nominees);
            }
            if (t.maturityValue !== undefined) {
              setUlipMaturityValue(t.maturityValue);
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setLockInValue(splitData[2])
              setLockIn({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.tenure !== undefined) {
              let d = t.tenure
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setTenurePeriod(splitData[1])
            }
          } else {
            handleResetModalPopUp()
          }
          setShowUlipAdd(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }
  ];

  return (
    <div>
      <Container fluid>
        {/* {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
        {typeMF === 1 ?
          <Row className="openingBalanceMFSIPTbl">
            <Col md="12" className="BuyTbl">
              <MaterialTable
                title="SIP (Mututal Fund)"
                data={allEntry1}
                columns={columnsB1}
                isLoading={loading}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                  paging: false,
                  tableLayout: 'auto'
                }}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      handleRowUpdate(newData, oldData, resolve, reject);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      handleRowAdd(newData, resolve, reject)
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      handleRowDelete(oldData, resolve, reject)
                    }),
                  onRowAddCancelled: rowData => {
                    setIserror(false)
                    setErrorMessages([])
                  },
                  onRowUpdateCancelled: rowData => {
                    setIserror(false)
                    setErrorMessages([])
                  }
                }}
              />
            </Col>
          </Row>
          :
          typeMF === 2 ?
            <Row className="openingBalanceStockUnlTbl">
              <MaterialTable
                title="Lumpsum (Mutual Fund)"
                data={allEntry2}
                isLoading={loading}
                columns={columnsB2}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                  paging: false
                }}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      handleRowAdd(newData, resolve, reject)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      handleRowUpdate(newData, oldData, resolve, reject);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      handleRowDelete(oldData, resolve, reject)
                    }),
                }}
              />
            </Row>
            :
            typeMF === 3 ?
              <Row className="openingBalanceMFULIPTbl">
                <MaterialTable
                  title="ULIP (Mutual Fund)"
                  data={allEntry3}
                  isLoading={loading}
                  columns={columnsB3}
                  options={{
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                    search: false,
                    paging: false
                  }}
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        handleRowAdd(newData, resolve, reject)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        handleRowUpdate(newData, oldData, resolve, reject);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        handleRowDelete(oldData, resolve, reject)
                      }),
                  }}
                />
              </Row> : null
        }
        <div className="mt-5 d-flex justify-content-center">
          <Stack spacing={4} direction="row" >
            <Button variant="contained" color="primary" id = "submitButton" className="btn btn-primary" onClick={handelSubmitTable} disabled={!disbtn()} style={{width:"5rem"}}>
              Save
            </Button>
            <Button variant="contained" color="primary" className="btn btn-secondary" onClick={handelResetTable}>
              Cancel
            </Button>
          </Stack>
        </div>
        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
      </Container>

      {/* modal for add new scheme */}
      <Container>
        <Modal show={show} onHide={handleClose} animation={false} size="sm" tabIndex={2} centered>
          <Modal.Header closeButton>
            <Modal.Title>{typeMF === 1 ? "SIP Scheme" : typeMF === 2 ? "Lumpsum Scheme" : "ULIP Scheme"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form >
              <div className="form-group mt-2 mb-2">
                <label htmlFor="inputUserName">Scheme Name</label>
                <input className="form-control mt-2" placeholder="Enter Scheme Name" type="text" autoComplete="off" name="schemeName" onChange={handleScheme} />
                {/* {schemeErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{schemeErr}</div> : null} */}
                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>

      {/* additional infomative Ulip */}
      <Modal show={showUlipAdd} onHide={handlecloseDescModal} animation={true} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="m-auto " ><h5 className='text-center'>ULIP Addtional Data</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >
            <Row>
              <Col md="4" className="mb-2">
                <><Form.Label>Date of Last Premium</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={lastPremiumDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setLastPremiumDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    placeholderText='Enter last permium date'
                    minDate={date}
                    disabled={mutualFHeaderId !== 0 ? true : false}
                  />
                </>
              </Col>
              <Col md="4" className="mb-2">
                <Form.Label>Nominees</Form.Label>
                <Form.Control type="text"
                  onChange={handleAddtiUlipData} autoComplete="off"
                  name="nominees"
                  id="nominees"
                  value={ulipNominees}
                  placeholder="Enter nominees"
                />
              </Col>

              <Col md="4" className="mb-2">
                <Form.Label>Bonus Accrued</Form.Label>
                {/* <Form.Control type="text"
                  onChange={handleAddtiUlipData} autoComplete="off"
                  name="bonus"
                  id="bonus"
                  value={commarize(ulipBonus)}
                  placeholder="Enter bonus accured"
                /> */}

                <Cleave
                  autoComplete='off'
                  placeholder="Enter bonus accured"
                  className='form-control'
                  name="bonus"
                  id="bonus"
                  options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                  value={ulipBonus}
                  onChange={handleAddtiUlipData}
                />
              </Col>

            </Row>
            <Row>
              <Col md="4" className="mb-2">
                <Form.Label>Premium Frequency</Form.Label>
                <Dropdown
                  value={divFreqValueULIP}
                  name='dividendFrequency'
                  options={divFreqValueULIPOption}
                  onChange={handleChangeFrequency}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                />
              </Col>
              <Col md="4" className="mb-2">
                <Form.Label>Goal</Form.Label>
                {/* <Form.Control
                  type="text"
                  onChange={handleAddtiUlipData} autoComplete="off"
                  name="goal"
                  id="goal"
                  value={commarize(ulipGoal)}
                  placeholder="Enter goal"

                /> */}
                <Cleave
                  placeholder="Enter goal"
                  className='form-control'
                  name="goal"
                  autoComplete='off'
                  id="goal"
                  options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                  value={ulipGoal}
                  onChange={handleAddtiUlipData}
                />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.goal}</div> : null}
              </Col>


              <Col md="4" className="mb-2">
                <>
                  <Form.Label className="d-block">Tenure</Form.Label>
                  <Form.Control type="number"
                    onChange={(e) => setTenurePeriod(e.target.value)}
                    autoComplete="off"
                    value={tenurePeriod}
                    name="tenure" id="tenure" className="lockinperiodno" />
                  <Dropdown
                    value={time}
                    name='tenure'
                    options={periodList}
                    onChange={HandleChangePeriod}
                    className="SelectClass lockinperiodlist"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                  />
                </>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mb-2">
                <><Form.Label>Maturity Date</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={maturityDateUlip}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setMaturityDateUlip(date)}
                    showMonthDropdown
                    minDate={date}
                    autoComplete="off"
                    name='maturityDate'
                    placeholderText='Enter maturity date'
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    disabled={mutualFHeaderId !== 0 ? true : false}
                  />
                </>
              </Col>
              <Col md="4" className="mb-2">
                <Form.Label>Maturity Value</Form.Label>
                {/* <Form.Control
                  type="text"
                  onChange={handleAddtiUlipData} autoComplete="off"
                  name="maturityValue"
                  id="maturityValue"
                  value={commarize(ulipMaturityValue)}
                  placeholder="Enter maturity value"
                /> */}

                <Cleave
                  placeholder="Enter maturity value"
                  className='form-control'
                  name="maturityValue"
                  id="maturityValue"
                  autoComplete='off'
                  options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                  value={ulipMaturityValue}
                  onChange={handleAddtiUlipData}
                />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityVal}</div> : null}

              </Col>
              <Col md="4" className="mb-2">
                <>
                  <Form.Label className="d-block">Lock in Period</Form.Label>
                  <Form.Control type="number"
                    onChange={(e) => setLockinperiod(e.target.value)}
                    autoComplete="off"
                    name="lockinperiod"
                    id="lockinperiod"
                    value={lockinperiod}
                    className="lockinperiodno" />
                  <Dropdown
                    value={lockIn}
                    name='period'
                    options={periodList}
                    onChange={HandleChangeLockIn}
                    className="SelectClass lockinperiodlist"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                  />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                </>
              </Col>

            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" className="mx-2 w100"
            onClick={submitPreModal}
          >
            Save
          </Button><Button variant="secondary" className="mx-2 w100"
            onClick={handleResetModalPopUp}
          >
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MFOpeningBalance;