/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../loadingAnimation/index'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    Container,
    Button,
    TableCell,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import moment from "moment";
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { stickyfooter, stickyHeader, stickymainHeader } from "./sticky";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';



function EntityBrokerReportTable() {
    const navigate = useNavigate();
    const pdfRef = useRef(null);

    //pagination states
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const location = useLocation();
    const ReportData = location.state.report;
    let fromDate = location.state.fromDate
    let toDate = location.state.toDate;
    // const ReportId = location.state.reportValue;
   
    let date = new Date();
    let newDate = date.toDateString();
    
    // push unique comapnies into an array
    const company = [];
    const totalCalculations = [];
    let totalAmt=0 ,totalQty = 0;


    ReportData.forEach((bro, i, array) => {
        bro.subAccount.forEach((sub, ind) => {
            sub.companyList.forEach((comp, index) => {
                let checkData = company.some(el => el.companyId === comp.companyId)
                if (!checkData) {
                    company.push({
                        companyId: comp.companyId,
                        companyName: comp.companyName,
                        quantity: comp.quantity,
                        closingBalance: comp.closingBalance,
                        brokerId: bro.brokerId,
                        subAccountId: sub.subAccountId
                    });
                } else {
                    const index = company.findIndex(a => {
                        return a.companyId === comp.companyId;
                        // return a.companyId === comp.companyId && a.brokerId === bro.brokerId && a.subAccountId === sub.subAccountId;
                    });
                    company[index].quantity += comp.quantity;
                    company[index].closingBalance += comp.closingBalance;
                }
            })
                totalAmt += sub.closingBalanceTotal 
                totalQty += sub.quantityTotal 
        
                //calculate footer totals
                totalCalculations.push({Qty:sub.quantityTotal,Amt:sub.closingBalanceTotal})
        })
        array.length-1 === i && totalCalculations.push({Qty:totalQty,Amt:totalAmt})
    })
    // sort data alphabetically
    company.sort((a,b) => (a.companyName > b.companyName) ? 1 : -1 )

    // push absent companies into Report data companlist array 
    let subAccountCount = 0;
    ReportData.forEach((item, i) => {
        item.subAccount.map((sub, ind, arr) => {
            company.forEach((comp, indx) => {
                let checkPresent = sub.companyList.some(el => el.companyId === comp.companyId)
                if (!checkPresent) {
                    sub.companyList.push({
                        companyId: comp.companyId,
                        companyName: comp.companyName,
                        quantity: 0,
                        closingBalance: 0
                    });
                }
            })
            //brokerName colspan handle  in table
            item.subAccountLength = arr.length * 2
        })
        subAccountCount += item.subAccount.length;
    })


    

    const pdfJsx = () => {
        return (
            <Table ref={pdfRef} sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
            <TableHead>
             <TableRow classes={[{ root: classes.customTableContainer }]}>
                    <TableCell align="center" colspan={subAccountCount * 2 + 3} >
                        {'Brokerwise/Entitywise Report '}
                    
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Broker</TableCell>

                    {ReportData.map((d, i) => (
                        <>
                            <TableCell align="center" colSpan={d.subAccountLength}>{d.brokerName}</TableCell>
                        </>
                    ))}

                    <TableCell align="center" colSpan={2} rowSpan={2} >Total</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Entity</TableCell>
                    {ReportData.map((d, i) => (
                        d.subAccount.map((sub, ind) => (
                            <>
                                <TableCell align="center" colSpan={2}>{sub.subAccountName}</TableCell>

                            </>
                        ))
                    ))}

                </TableRow>
                <TableRow>
                    <TableCell>Company Name</TableCell>
                    {ReportData.map((d, i) => (
                        d.subAccount.map((sub, ind) => (
                            <>
                                <TableCell align="right" >Quantity</TableCell>
                                <TableCell align="right" >Amount(₹)</TableCell>

                            </>
                        ))
                    ))}

                    <TableCell align="right" >Total Quantity</TableCell>
                    <TableCell align="right" >Total Amount(₹)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    company.length === 0 ? <Loader /> : company
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((comp, indx) => {
                            return (
                                <TableRow>
                                    <TableCell >{comp.companyName}</TableCell>
                                    {ReportData.map((brk, i) => (
                                        brk.subAccount.map((sub, ind) => (
                                            sub.companyList.filter(d => d.companyId === comp.companyId).map((val, index) => {
                                                if (comp.companyId === val.companyId) {
                                                    return (
                                                        <>
                                                            <TableCell align="right">{NumberOnlySeperator(val.quantity)}</TableCell>
                                                            <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                                                
                                                                currency: 'INR', minimumFractionDigits: 2
                                                            }).format(isNaN(val.closingBalance) ? 0 : val.closingBalance)}</TableCell>
                                                        </>
                                                    )
                                                } else {
                                                    return (<TableCell align="right">0</TableCell>)
                                                }

                                            })
                                        ))
                                    ))}
                                    <TableCell align="right">{NumberOnlySeperator(comp.quantity)}</TableCell>
                                    <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                        
                                        currency: 'INR', minimumFractionDigits: 2
                                    }).format(isNaN(comp.closingBalance) ? 0 : comp.closingBalance)}</TableCell>
                                </TableRow>
                                )
                        })}
                        <TableRow style={{ backgroundColor: '#D9E5F2'}}>
                        <TableCell align='left' style={{fontWeight:500}}>Total</TableCell>
                        {totalCalculations.map((val, i) => (
                            <>
                                <TableCell align="right" style={{fontWeight:500}}>{NumberOnlySeperator(val.Qty)}</TableCell>
                                <TableCell align="right" style={{fontWeight:500}}>{new Intl.NumberFormat('en-IN', {
                                    
                                    currency: 'INR', minimumFractionDigits: 2
                                }).format(isNaN(val.Amt) ? 0 : val.Amt)}</TableCell>
                            </>
                        ))}
                            </TableRow>
            </TableBody>
        </Table>
        )
    }

    const printHandler = () => {

        const printElement = ReactDOMServer.renderToString(pdfJsx());
          var opt = {
            margin:       0.2 ,
            filename:     'Accompt.pdf',
            image:        { type: 'png', quality: 0.98 },
            html2canvas:  { scale: 2},
            jsPDF:        { unit: 'px', format: [800, 2000], orientation: 'landscape' }
          };
          
        html2pdf().set(opt).from(printElement).save();
    }

    const useStyles = makeStyles({
        table: {
            //   minWidth: 650,
            "& .MuiTableCell-root": {
                border: "1px solid rgba(0, 0, 0, 0.3)"
            }
        },
        customTableContainer: {
            overflowX: 'revert'
        }
    });

    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c['table']) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const rows = doc.querySelectorAll('tr');

            rows.forEach((val, i) => {
                if (i !== 0) {
                    val.firstElementChild.colSpan = 3
                    if (i === 1 || i === 2 || i === 3) {
                        val.childNodes.forEach((val, i) => {
                            val.bgColor = '#D9E5F2';
                        })
                        if (i === 1) {
                            //Total label
                            val.lastElementChild.colSpan = 4
                            val.lastElementChild.vAlign = 'middle';
                        } else if (i === 3) {
                            const childLength = val.childNodes.length
                            //TotalQuantiy And ToatalAmout label
                            val.childNodes[childLength - 1].colSpan = 2;
                            val.childNodes[childLength - 2].colSpan = 2;
                        }
                    } else {
                        val.childNodes.forEach((child, indx, arr) => {
                            if (indx !== 0) {
                                child.align = 'right'
                                //TotalQuantiy values And ToatalAmout Values
                                if (indx === arr.length - 1 || indx === arr.length - 2) {
                                    child.colSpan = 2;
                                }
                            }
                        })
                    }
                } else {
                    //header
                    val.firstElementChild.colSpan = val.firstElementChild.colSpan + 4;
                    val.firstElementChild.bgColor = '#D9E5F2';
                    // val.firstElementChild.rowSpan = 2;
                    val.firstElementChild.vAlign = 'middle';

                }
            })

            c.table = doc.querySelector('table').outerHTML;
        }
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    const classes = useStyles();

    // seperation by comma
    //   const NumberSeperator = (input) => {
    //     return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    //   }

    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }
    return (
        <Container maxWidth="xl">
            <h3 className="text-center mt-4">{'Brokerwise - Entitywise report'}</h3>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="space-between"
                mt={4}
                padding={2}
            >
                <Stack justifyContent="flex-start">
                    <Button variant="contained" onClick={() => navigate('/ReportCard')} color="primary" endIcon={<IoArrowBackCircleSharp />}>
                        Go Back
                    </Button>
                </Stack>
                {/* <Button variant="contained" onclick="Export()" color="primary" id="btnExport" endIcon={<FaFilePdf />}>
                        Export to PDF
                    </Button> */}
                <Stack justifyContent="flex-end" spacing={{ xs: 1, sm: 2, md: 4 }} direction={{ xs: 'column', sm: 'row' }}>
                    <Button variant="contained" onClick={printHandler} color="primary">
                        Export to PDF
                    </Button>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary"
                        table="example"
                        filename='Brokerwise_Entitywise_Report'
                        sheet="tablexls"
                        buttonText="EXPORT TO XLSX"

                    />
                    {/* <FileExport fileName={'Brokerwise/Entitywise report'} /> */}
                    <Button variant="contained" color="primary" id="btnPrint" >
                        Print
                    </Button>
                </Stack>
            </Stack>
            <div className="reportTblFirstcolumn">
            <Paper sx={{ width: '100%' }} id="report">
                <TableContainer classes={{ root: classes.customTableContainer }} >
                    <Table stickyHeader ref={pdfRef} sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                        <TableHead className={stickymainHeader}>
                         <TableRow classes={[{ root: classes.customTableContainer }, stickyHeader]}>
                                {/* <TableCell  align="center" colspan={(ReportData.length)*2}> */}
                                <TableCell align="center" colspan={subAccountCount * 2 + 3} >
                                    {'Brokerwise/Entitywise Report '}
                                        {"as on"}
                                        { " " + moment(toDate).format("DD/MM/YYYY")}
                                </TableCell>
                            </TableRow>
                            <TableRow className={stickyHeader}>
                                <TableCell>Broker</TableCell>

                                {ReportData.map((d, i) => (
                                    <>
                                        <TableCell align="center" colSpan={d.subAccountLength}>{d.brokerName}</TableCell>
                                    </>
                                ))}

                                <TableCell align="center" colSpan={2} rowSpan={2} >Total</TableCell>
                            </TableRow>
                            <TableRow  className={stickyHeader}>
                                <TableCell>Entity</TableCell>
                                {ReportData.map((d, i) => (
                                    d.subAccount.map((sub, ind) => (
                                        <>
                                            <TableCell align="center" colSpan={2}>{sub.subAccountName}</TableCell>

                                        </>
                                    ))
                                ))}

                                {/* <TableCell colSpan={2}></TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>Company Name</TableCell>
                                {ReportData.map((d, i) => (
                                    d.subAccount.map((sub, ind) => (
                                        <>
                                            <TableCell align="right" >Quantity</TableCell>
                                            <TableCell align="right" >Amount(₹)</TableCell>

                                        </>
                                    ))
                                ))}

                                <TableCell align="right" >Total Quantity</TableCell>
                                <TableCell align="right" >Total Amount(₹)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                company.length === 0 ? <Loader /> : company
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((comp, indx) => {
                                        return (
                                            <TableRow>
                                                <TableCell >{comp.companyName}</TableCell>
                                                {ReportData.map((brk, i) => (
                                                    brk.subAccount.map((sub, ind) => (
                                                        sub.companyList.filter(d => d.companyId === comp.companyId).map((val, index) => {
                                                            if (comp.companyId === val.companyId) {
                                                                return (
                                                                    <>
                                                                        <TableCell align="right">{NumberOnlySeperator(val.quantity)}</TableCell>
                                                                        <TableCell align="right">{new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2}).format(isNaN(val.closingBalance) ? 0 : val.closingBalance)}</TableCell>
                                                                    </>
                                                                )
                                                            } else {
                                                                return (<TableCell align="right">0</TableCell>)
                                                            }

                                                        })
                                                    ))
                                                ))}
                                                <TableCell align="right">{NumberOnlySeperator(comp.quantity)}</TableCell>
                                                <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                                                    
                                                    currency: 'INR', minimumFractionDigits: 2
                                                }).format(isNaN(comp.closingBalance) ? 0 : comp.closingBalance)}</TableCell>
                                            </TableRow>)
                                    })}
                                    <TableRow className={stickyfooter} style={{ backgroundColor: '#D9E5F2'}}>
                                    <TableCell align='left' style={{fontWeight:500}}>Total</TableCell>
                                    {totalCalculations.map((val, i) => (
                                        <>
                                            <TableCell align="right" style={{fontWeight:500}}>{NumberOnlySeperator(val.Qty)}</TableCell>
                                            <TableCell align="right" style={{fontWeight:500}}>{new Intl.NumberFormat('en-IN', {
                                                
                                                currency: 'INR', minimumFractionDigits: 2
                                            }).format(isNaN(val.Amt) ? 0 : val.Amt)}</TableCell>
                                        </>
                                    ))}
                                    </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
              
            </Paper>
            </div>
        </Container>
    );
}
export default EntityBrokerReportTable;