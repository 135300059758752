import React, { useEffect, useState } from "react";

import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from '../../modules/Auth/Captcha';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Avatar, TextField } from "@mui/material";
import { Button, Col, Container, Row } from "react-bootstrap";

import Auth from '../../modules/Auth/index';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';
import Swal from 'sweetalert2';

const LoginAuthentication = () => {

    const userID = Auth.getUserId();

    const [code, setCode] = useState('');

    const [errCode, setErrCode] = useState('');
    const [errCaptcha, setErrCaptcha] = useState('');

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    const Validate = () => {
        let Valid = true;

        // if(!otp){
        //     setErrOtp('Please Enter OTP');
        //     Valid = false;
        // }else{
        //     setErrOtp('');
        // }

        if (!code) {
            setErrCode('Please Enter Code');
            Valid = false;
        }
        else {
            setErrCode('');
        }

        return Valid;
    }

    const onVerify = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;
        const isValid = Validate();
        if (isValid) {
            if (validateCaptcha(user_captcha) === true) {
                // alert('Success');
                axios.post(`${settings.API_URL}/TwoFactorAuthentication/2FAAuthenticate/${code}/${userID}`)
                    .then((res) => {

                        //   setLoader(false);
                        if (res.status === 200) {
                            if (res.data._response.status === 1) {
                                Swal.fire(res.data._response.message, '', 'success')
                                    .then(res => {
                                        let userData = JSON.parse(localStorage.getItem("user_data"));
                                        userData["twoFaAuth"] = true
                                        localStorage.setItem("user_data", JSON.stringify(userData));
                                        // const loginDetails = JSON.stringify(userDetails);

                                        // localStorage.setItem('user_data', loginDetails);
                                        window.location.href = '/';
                                    })
                            }
                            else {
                                Swal.fire("Verification Failed.", '', 'warning');
                            }
                        }
                    }).catch((e) => {
                        console.log(e, 'error in Authenticator');
                        //   setLoader(false);
                    })
                // //API Call Here or Entry Here 
                // swal('Verified','','success')
                // .then(res=>{
                //     window.location.href = '/';
                // })
            }
            else {
                // alert('Invalid');
                setErrCaptcha('Please Enter Valid Captcha');
            }
        }
    }

    return (
        <Container fluid="sm" className="col-md-4 shadow p-3 mb-5 bg-white rounded">
            <div className="p-3">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Avatar className="avatarStyleLoginReg"><LockOutlinedIcon /></Avatar>
                    </Col>
                </Row>
                <br />
                <h2 className="text-center">Verification</h2>
                <br />
                {/* <Row>
                    <TextField label="OTP" name="otp" onChange={(e)=>setOtp(e.target.value)} fullWidth />
                    {errOtp != '' ? 
                    (<div className="mt-2"style={{ fontSize: 12, color: "red" }}>
                        {errOtp}
                    </div>
                    ) : null}
                </Row> */}
                <br />
                <Row>
                    <TextField label="Secret Code" name="code" onChange={(e) => setCode(e.target.value)} value={code} fullWidth />
                    {errCode !== '' ?
                        (<div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                            {errCode}
                        </div>
                        ) : null}
                </Row>
                <br />
                <Row>
                    <LoadCanvasTemplate />
                    <Col>
                        <TextField id="user_captcha_input" placeholder="Enter Captcha" />
                        {errCaptcha !== '' ?
                            (<div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                {errCaptcha}
                            </div>
                            ) : null}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Button className="btn-lg" onClick={onVerify}>Verify</Button>
                </Row>
            </div>
        </Container>
    )
}

export default LoginAuthentication;