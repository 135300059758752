/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import TransactionsTable from "./TransactionsTable";
import { EntityList, InvestmentTypeList, InvestmentList, brokerList, searchTransaction, GetMutualFundCompanies, FetchBulionsInvestmentType,GetFICompanies } from "./RecentTranAPI/RT_API";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { ActionsList } from "../../modules/helpers/constant";
import swal from "sweetalert";
import Loader from "../loadingAnimation/index";
import Auth from "../../modules/Auth";
const userID = Auth.getsubcriberId();

class RecentTransactions extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMembers = this.handleChangeMembers.bind(this)
    this.fixTimezoneOffset = this.fixTimezoneOffset.bind(this)
    //this.searchdata = this.searchdata.bind(this);
    this.state = {
      Loading: false,
      Members: [],
      selMember: null,
      Actions: [],
      selAction: null,
      InvestmentType: [],
      selInvestmentType: null,
      Investment: [],
      selInvestment: null,
      Broker: [],
      selbroker: null,
      startDate: null,
      endDate: new Date(),
      filterData: [],
      resetFilter: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      pageCount: 1,
      checkApi: false,
      submitCheck: false,
      StockCompany: [],
      MutualFundCompany: [],
      BullionCompany: [],
      FICompany: [],
      InvestmentValue: null,
      // one: [],
      // Investment_Name: '',
      // Broker: '',
      // AddedData: "All",
      // MembersData: "All"
    };
  }

  componentDidMount() {
    if (userID) { this.getEntityListt() }
    // this.getAction();
    this.getInvestmentType();
    this.getInvestmentName();
    this.getMFCompnies();
    this.getBullionTypeOfInvestments();
    this.getFICompanies();
    this.getBrokerList();
  }




  // family members - entity names
  getEntityListt = async () => {
    this.setState({ Loading: true });
    if (userID) {


      await EntityList()
        .then((res) => {
          // console.log(res, "entities");
          if (res.status === 200) {
            //
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                });
              });
            this.setState({ Members: entityData });
            this.setState({ Loading: false });
          } else {
            // setError(res.data.message);
            this.setState({ Loading: false });
          }
        })
        .catch((e) => {
          this.setState({ Loading: false });
        });

    }
  };
  // action
  // getAction = async () => {
  // }

  // investment Type
  getInvestmentType = async () => {
    this.setState({ Loading: true });
    await InvestmentTypeList()
      .then((res) => {
        if (res.status === 200) {
          let InvmtType = res.data._response.data.instrumentDetails;
          let InvmtTypeData = [];
          InvmtType &&
            InvmtType.map((data) => {
              InvmtTypeData.push({
                value: data.instrumentId,
                label: data.instrumentName,
              });
            });
          this.setState({ InvestmentType: InvmtTypeData });
          this.setState({ Loading: false });
        } else {
          // setError(res.data.message);
          this.setState({ Loading: false });
        }
      })
      .catch((e) => {
        this.setState({ Loading: false });
      });
  }
  // investment name - companylist data
  getInvestmentName = async () => {
    this.setState({ Loading: true });
    // setLoading(true)
    await InvestmentList()
      .then((res) => {
        if (res.status === 200) {
          //
          let UnlistedCompany = [];
          let ListedCompany = [];
          let ListedPrefCompany = [];
          let UnlistedPrefCompany = [];

          let company = res.data._response.data.companyDetails;
          // let companyData = [];
          company &&
            company.filter(data => data.instrumentTypeId === 1).map(i => {
              ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
            });
          company &&
            company.filter(data => data.instrumentTypeId === 10).map(i => {
              UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
            });
          company &&
            company.filter(data => data.instrumentTypeId === 11).map(i => {
              ListedPrefCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
            });
          company &&
            company.filter(data => data.instrumentTypeId === 20).map(i => {
              UnlistedPrefCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
            });

          const groupedOptions = [
            {
              label: 'Listed Companies',
              options: ListedCompany,
            },
            {
              label: 'Unlisted Companies',
              options: UnlistedCompany,
            },
            {
              label: 'Listed Preference Companies',
              options: ListedPrefCompany,
            },
            {
              label: 'Unlisted Preference Companies',
              options: UnlistedPrefCompany,
            },
          ];
          this.setState({ Investment: groupedOptions });
          // setLoading(false)
          this.setState({ Loading: false });

        } else {
          // setError(res.data.message);
          // setLoading(false)
          this.setState({ Loading: false });
        }
      })
      .catch((e) => {
        // setError(e.message);
        this.setState({ Loading: false });
      });
  };
  getMFCompnies = async () => {
    this.setState({ Loading: true });
    await GetMutualFundCompanies().then((res) => {
      let result = res.data._response.data.mutualFundCompanies;
      let CompanyData = [];
      result && result.map((x) => {
        CompanyData.push({
          value: x.mutualFundCompanyId,
          label: x.mutualFundCompanyName,
          CompanyType: x.instrumentTypeId
        })
      })
      this.setState({ MutualFundCompany: CompanyData});
      this.setState({ Loading: false });
    }).catch((err) => {
      console.log(err, "err in error");
      this.setState({ Loading: false });
    })
  }
  getBullionTypeOfInvestments = async () => {
    this.setState({ Loading: true });
    await FetchBulionsInvestmentType().then(res => {
        if (res.status === 200) {
            if (res.data._response.status === 1) {
                let investment = res.data._response.data.investmentDetails;
                let investmentData = [];
                investment && investment.map(data => {
                    investmentData.push({ value: data.typeOfInvestmentId, label: data.investmentName })
                })
                this.setState({ BullionCompany: investmentData })
            }
        } else {
          this.setState({ Loading: false });
          console.log("error in get bullion investment type")
        }
    }).catch(e => {
        this.setState({ Loading: false });
        console.log(e, "error in get bullion investment type")
    })
  }
  getFICompanies = async () => {
    this.setState({ Loading: true });
    await GetFICompanies().then(res => {
        if (res.status === 200) {
            let company = res.data._response.data.companyDetails;
            let ListedDebCompany = [];
            let UnlistedDebCompany = [];
            let ListedBondCompany = [];
            let UnlistedBondCompany = [];
            let FDCompany = [];
            let RDCompany = [];
            let POCompany = [];
            let PPFCompany = [];
            company && company.filter(data => data.instrumentTypeId === 12).map(data => {
              ListedDebCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 13).map(data => {
              ListedBondCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 21).map(data => {
              UnlistedDebCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 22).map(data => {
              UnlistedBondCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 29).map(data => {
              FDCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 30).map(data => {
              RDCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 31).map(data => {
              PPFCompany.push({ value: data.companyId, label: data.companyName })
            })
            company && company.filter(data => data.instrumentTypeId === 32).map(data => {
              POCompany.push({ value: data.companyId, label: data.companyName })
            })
            const groupedOptions = [
              {
                label: 'Listed Debenture Companies',
                options: ListedDebCompany,
              },
              {
                label: 'Unlisted Debenture Companies',
                options: UnlistedDebCompany,
              },
              {
                label: 'Listed Bonds Companies',
                options: ListedBondCompany,
              },
              {
                label: 'Unlisted Bonds Companies',
                options: UnlistedBondCompany,
              },
              {
                label: 'FD Companies',
                options: FDCompany,
              },
              {
                label: 'RD Companies',
                options: RDCompany,
              },
              {
                label: 'Post Office Companies',
                options: POCompany,
              },
              {
                label: 'PPF / Provident Fund Companies',
                options: PPFCompany,
              },
            ];
            this.setState({ FICompany: groupedOptions });
            this.setState({ Loading: false });
        } else {
          this.setState({ Loading: false });
        }
    }).catch(e => {
      this.setState({ Loading: false });
    })
  }

  // broker list data
  getBrokerList() {
    // this.state.setLoading(true)
    brokerList().then((res) => {
      if (res.status === 200) {
        let brokers = res.data._response.data.brokerDetails;
        let brokersData = [];
        brokers && brokers.map((data) => {
          brokersData.push({
            value: data.brokerId,
            label: data.brokerName,
          });
        });
        this.setState({ Broker: brokersData });
        // this.state.setLoading(false)
      } else {
        // this.state.setLoading(false)
        //setError(res.data.message);
        console.log("ELSE ERR");
      }
    })
      .catch((e) => {
        // setError(e.message);
        // this.state.setLoading(false);
        console.log(e, "brokerlist error");
      });
  };

  handleMember = (selected) => {
    this.setState({ selMember: selected });
  }

  handleAction = (selected) => {
    this.setState({ selAction: selected });
  }

  handleInvestType = (selected) => {
    this.setState({ selInvestmentType: selected });
    this.setState({ pageNo: 1 })
    let prevVal = ({...this.state.selInvestmentType});
    if(selected !== null && prevVal !== null){
      if(prevVal.value !== selected.value){
        this.setState({ selInvestment: null })
      }
    }
    if(selected === null){
      this.setState({ selInvestment: null })
    }
    if(selected !== null){
      this.setState({ InvestmentValue: selected.value })
    }
  }

  handleInvest = (selected) => {
    this.setState({ selInvestment: selected });
  }

  handleBroker = (selected) => {
    this.setState({ selbroker: selected });
  };

  handleStartDate = (selected) => {
    this.setState({ startDate: selected });
  };

  handleEndDate = (selected) => {
    this.setState({ endDate: selected });
  };

  handleChange(e) {
    this.setState({ Actions: e.target.value })
  }
  handleChangeMembers(e) {
    this.setState({ Members: e.target.value })
  }

  disableButton = () => {
    return this.state.selMember || this.state.selAction || this.state.selInvestment || this.state.selInvestmentType || this.state.selbroker || this.state.startDate && this.state.endDate
  }

  fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  submitData = (e, pageno) => {
    e.preventDefault();
    this.setState({ Loading: true })
    let fdata = {
      subscriberId: userID,
      brokerId: this.state.selbroker != null ? this.state.selbroker.value : 0,
      subAccountId: this.state.selMember != null ? this.state.selMember.value : 0,
      instrumentId: this.state.selInvestmentType != null ? this.state.selInvestmentType.value : 0,
      companyName: this.state.selInvestment != null ? this.state.selInvestment.label : null,
      startDate: this.state.startDate != null ? this.fixTimezoneOffset(this.state.startDate) : "2000-01-01",
      endDate: this.state.endDate != null ? this.fixTimezoneOffset(this.state.endDate) : "2099-01-01",
      transactStockType: this.state.selAction != null ? this.state.selAction.label : null,
      pageNo: pageno === undefined ? this.state.pageNo : pageno,
      pageSize: this.state.pageSize
    };
    searchTransaction(fdata).then((res) => {

      if (res.data._response.status === 1) {
        this.setState({ filterData: res.data._response.data.history })
        this.setState({ Loading: false })
        this.setState({ checkApi: true })
        this.setState({ pageNo: res.data._response.data.count })
        if (pageno === 1) {
          this.setState({ submitCheck: prevState => !prevState })
        }
      } else {
        this.setState({ filterData: [] })
        this.setState({ Loading: false })
        swal("", "No Data Found !!!", "warning")
      }
    })
      .catch((e) => {
        this.setState({ Loading: false })
        console.log(e, "error");
      });
    // console.log("FILTERED DATA FOUND")
    // this.getsearchTransaction();
    // console.log(this.state.Actions, "in submit");
    // this.setState({ AddedData: this.state.Actions, MembersData: this.state.Members })
  }

  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      selMember: null,
      selAction: '',
      selInvestmentType: null,
      selInvestment: null,
      selbroker: null,
      startDate: null,
      endDate: new Date(),
      resetFilter: this.state.resetFilter === false ? true : false,
      checkApi: false
    })
  }
  render() {
    return (
      <div style={{ maxWidth: "100%" }}>
        <Container fluid>
          <Row>
            <Col><h4 className="text-center py-3">Recent Transactions</h4></Col>
          </Row>
          <Row>
            <Col md="12">
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridFM">
                    <Form.Label>Family Member</Form.Label>
                    <Select
                      value={this.state.selMember}
                      onChange={this.handleMember}
                      options={this.state.Members}
                      name="subAccountId"
                      isClearable={true}
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridInvestmentType">
                    <Form.Label>Investment Type</Form.Label>
                    <Select
                      value={this.state.selInvestmentType}
                      onChange={this.handleInvestType}
                      options={this.state.InvestmentType}
                      name="investmentType"
                      isClearable={true}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAction">
                    <Form.Label>Transaction Type</Form.Label>
                    <Select
                      value={this.state.selAction}
                      onChange={this.handleAction}
                      options={ActionsList}
                      name="action"
                      isClearable={true}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridInvestmentName">
                    <Form.Label>Investment Name</Form.Label>
                      <Select
                        value={this.state.selInvestment}
                        onChange={this.handleInvest}
                        options={(this.state.InvestmentValue === 8 ? this.state.MutualFundCompany : this.state.InvestmentValue === 9 ? this.state.BullionCompany : this.state.InvestmentValue === 4 ? this.state.FICompany : this.state.Investment)}
                        name="subAccountId"
                        isClearable={true}
                        isDisabled={(this.state.InvestmentValue === 5 || this.state.InvestmentValue === 6 ||  this.state.InvestmentValue === 7 || this.state.InvestmentValue === 10 || this.state.InvestmentValue === 11 ? true : this.state.selInvestmentType === null ? false : false)}
                      />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridBroker">
                    <Form.Label>Broker</Form.Label>
                    <Select
                      value={this.state.selbroker}
                      onChange={this.handleBroker}
                      options={this.state.Broker}
                      name="brokerId"
                      isClearable={true}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridDateFrom">
                    <Form.Label>Date From</Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholderText="Select start Date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      maxDate={new Date()}
                      // minDate={new Date(startDate)}
                      onChange={this.handleStartDate}
                      dateFormat="dd-MM-yyyy"
                      name="startdate"
                      autoComplete="off"
                      selected={this.state.startDate}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridDateTo">
                    <Form.Label>Date To</Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholderText="Select end Date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      maxDate={new Date()}
                      minDate={this.state.startDate}
                      onChange={this.handleEndDate}
                      dateFormat="dd-MM-yyyy"
                      name="startdate"
                      autoComplete="off"
                      selected={this.state.endDate}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridButton" className="mt22">
                    <Button type="submit" variant="primary" size="md" className="mx-1" active
                      onClick={(e) => this.submitData(e, 1)}
                    // disabled={!this.disableButton()} 
                    >
                      Search
                    </Button>{" "}
                    <Button variant="primary" size="md" className="mx-1" active onClick={this.handleClear}>
                      Clear
                    </Button>{" "}
                  </Form.Group>
                </Row>
                {
                  this.state.Loading ?
                    <Loader /> : null
                }
                {<TransactionsTable Actions={this.state.Actions} Members={this.state.Members} Onedata={this.state.one} AddedData={this.state.AddedData} MembersData={this.state.MembersData} newEntryFilterData={this.state.filterData} handleReset={this.state.resetFilter} loading={this.state.loading} page={this.state.page} rowPerPage={this.state.rowPerPage} handleChangePage={this.handleChangePage} handleChangeRowsPerPage={this.handleChangeRowsPerPage} pageNo={this.state.pageNo} pageSize={this.state.pageSize} pageCount={this.state.pageCount} api={this.state.checkApi} searchData={this.submitData} submitCheck={this.state.submitCheck} selMember={this.state.selMember} selAction={this.state.selAction} selInvestmentType={this.state.selInvestmentType} selInvestment={this.state.selInvestment} selbroker={this.state.selbroker} startDate={this.state.startDate} endDate={this.state.endDate} />}
                {/* {this.state.loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
//     <div style={{ maxWidth: "100%" }}>
//       <Sidebar />
//       <div className="contentWrap">
//         <Navbar />
//         <div>
//           <Container fluid>
//             <MaterialTable
//               title="Recent Transactions"
//               data={filteredData}
//               columns={columns}
//               de
//               options={{
//                 headerStyle: {
//                   backgroundColor: "#01579b",
//                   color: "#FFF",
//                 },
//                 exportButton: false,
//                 search: false,
//               }}
//               actions={[
//                 {
//                   icon: () => (
//                     <FormControl>
//                       <RadioGroup
//                         row
//                         color="primary"
//                         defaultValue="start"
//                         aria-label="Entity"
//                         name="row-radio-buttons-group"
//                         value={script}
//                         onChange={handleChangeEntity}
//                       >
//                         <FormControlLabel
//                           value="All"
//                           control={<Radio />}
//                           label="All"
//                         />

//                         <FormControlLabel
//                           value="Type 1"
//                           control={<Radio />}
//                           label="Entity 1"
//                         />
//                         <FormControlLabel
//                           value="Type 2"
//                           control={<Radio />}
//                           label="Entity 2"
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   ),
//                   tooltip: "Entity",
//                   isFreeAction: true,
//                 },
//               ]}
//             />
//           </Container>
//         </div>
//       </div>

//     </div>
//   );
// }

export default RecentTransactions;
