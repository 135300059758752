/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Login from '../../pages/Login/Login';
import Registration from '../../pages/UserRegistration/Registration';
import PrivateRoute from './PrivateRoute';
import LoginAuth from '../../pages/Login/LoginAuth';
import ResetPassword from '../../pages/Login/ResetPassword';
import ChangePassword from '../../pages/Login/ChangePassword';
import EmailVerification from '../UserRegistration/EmailVerification';
import TwoFA from '../../pages/2FA/TwoFA';
import TwoFAPrivateRoute from './TwoFAPrivateRoute';
import AdminRoute from './AdminRoute';
import Auth from '../../modules/Auth/index';
import UserProfile from '../../pages/UserProfileInfo/UserProfile';
import ThankyouPayment from '../../pages/Subscription/ThankyouPayment';
import NotFound from '../CommonComponent/NotFoundPage/NotFound';
import SubscriptionPage from "../../pages/Subscription/SubscriptionPage"
// channnel partnaer routes
import ChannelPTRoute from './ChannelPTRoute';
import AfterLoginRoute from './AfterLoginRoute';


// CA routes
import CAPTRoute from './CAPTRoute';

//Trial user registration
import TrialUserSign from '../TrialUserForm/TrialUserSign'; 

//map routes form api
import AllRoutes from './RoutesData';
import Loader from '../loadingAnimation/index'

import { useSelector, useDispatch } from 'react-redux';
import { SidebarByRoleid } from '../../pages/Stocks/StocksAPI';
import { SidebarData, ClearSidebarData } from '../../redux/action/sidebarAction';

export const GlobalInfo = createContext();
const Content = (props) => {

	const token = Auth.getToken();
	const userRole = Auth.getUserRoleID();
    const TwoFactorAuthenticate = Auth.getTwoFA();
    const TwoFAAuth = Auth.getTwoFAAuth();


	const [closemodal, setcloseModal] = useState();
	const [closeInsmodal, setcloseInsModal] = useState();
	const [routesData, setRoutesData] = useState([]);
	const dispatch = useDispatch();
    const selector = useSelector(res=>res.sidebarReducer.sidebardata);

	const Getdata = (data) => {
		setcloseModal(data);
	};
	const GetInsdata = (data) => {
		setcloseInsModal(data);
	};

	React.useEffect(() => {
        window.scrollTo(0, 0);
        if ((token && TwoFactorAuthenticate === TwoFAAuth) && selector.length === 0) {
            sidebar();
        } else {
            setRoutesData(selector);
        }
    }, [userRole])

	// useEffect(() => {
	// 	if (userRoutes.length > 0) {
	// 		setRoutesData(userRoutes)
	// 	} else {
	// 		setRoutesData([]);
	// 	}
	// }, [UserRole, userRoutes])

	const sidebar = async () => {
        SidebarByRoleid(userRole).then((res) => {
            if (res.data._response.status === 1) {
                let result = res.data._response.data.sidebarDetails
                setRoutesData(result);
                dispatch(SidebarData(result));
            }
        }).catch((err) => {
            setRoutesData([]);
            dispatch(ClearSidebarData())
            console.log(err, "err in sidebar")
        })
    }

	return (
		<GlobalInfo.Provider
			value={{ Getdata: Getdata, closemodal: closemodal, GetInsdata: GetInsdata, closeInsmodal: closeInsmodal }}
		>
			{token && routesData.length === 0 ? <Loader /> : null}
			<Routes>
				<Route path="/Login" element={<Login />} />
				<Route path="/Registration" element={<Registration />} />
				<Route path="/Subcriber/verify-email" element={<EmailVerification />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/ChangePassword" element={<ChangePassword />} />
				<Route path="/NotFound" element={<NotFound />} />
				<Route path="/TrialUserSign" element={<TrialUserSign />} />
				<Route path="/profile" element={<UserProfile />} />
				<Route path="/Thankyou" element={<ThankyouPayment />} />
				<Route path="/plans" element={<SubscriptionPage />} />
			
				<Route element={<AfterLoginRoute />}>
					{token && userRole === 1 ? (
						//admin routes
						<Route>
							<Route element={<AdminRoute />}>
								{routesData && routesData.map((val, idn) => (
									AllRoutes.filter(el => el.routeName === val.menuName).map((data, i) => {
										return (<Route path={data.path} element={data.component} />)
									})
								))}
							</Route>
							{routesData.length === 0 ? null : <Route path="*" element={<Navigate to="/token" replace />} />}
						</Route>
					) : token && userRole === 2 ? (
						//user routes
						<Route>
							<Route element={<PrivateRoute />}>
								<Route path="/Auth" element={<LoginAuth />} />
								<Route element={<TwoFAPrivateRoute />}>
									<Route path="/2FA" element={<TwoFA />} />
									{routesData && routesData.map((val, idn) => (
										AllRoutes.filter(el => el.routeName === val.menuName).map((data, i) => {
											return (<Route path={data.path} element={data.component} />)
										})
									))}
								</Route>
							</Route>
							{routesData.length === 0 ? null : <Route path="*" element={<Navigate to="/Dashboard"  replace />} />}
						</Route>
					) : token && userRole === 3 ? (
						<Route>
							<Route element={<ChannelPTRoute />}>
								{/* channnel partnaer routes */}
								{/* <Route path="/ChannelDashboard" element={<ChannelDashboard />} /> */}
								{routesData && routesData.map((val, idn) => (
									AllRoutes.filter(el => el.routeName === val.menuName).map((data, i) => {
										return (<Route path={data.path} element={data.component} />)
									})
								))}
							</Route>
							{routesData.length === 0 ? null : <Route path="*" element={<Navigate to="/ChannelDashboard" replace />} />}
						</Route>
					) : (
						<Route>
							<Route element={<CAPTRoute />}>
								{/* CA  routes */}
								{/* <Route path="/CADashboard" element={<CADashboard />} /> */}
								{routesData && routesData.map((val, idn) => (
									AllRoutes.filter(el => el.routeName === val.menuName).map((data, i) => {
										return (<Route path={data.path} element={data.component} />)
									})
								))}
							</Route>
							{routesData.length === 0 ? null : <Route path="*" element={<Navigate to="/CADashboard" replace />} />}
						</Route>
					)}
				</Route>
				{token && routesData.length !== 0 ? <Route path="*" element={<Navigate to="/NotFound" replace />} />
					: !token && routesData.length === 0 ? <Route path="*" element={<Navigate to="/Login" replace />} /> : null}
			</Routes>
		</GlobalInfo.Provider>
	);
};
export default Content;
