//import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';
import Auth from '../../modules/Auth';
const userID = Auth.getsubcriberId();
let BorrowingsUrl = "http://5.189.157.40:10026/api"


const AddBorrowings = async (body) => {
    return axios({
        method: 'POST',
        url: `${BorrowingsUrl}/BorrorwingsManagement/AddBorrowingsDetail`,
        data: body
    })
}
const updateBorrowings = async (body) => {
    return axios({
        method: 'PUT',
        url: `${BorrowingsUrl}/BorrorwingsManagement/UpdateBorrowingsDetail`,
        data: body
    })
}

const AddIntInsRepay = async (body) => {
    return axios({
        method:'POST',
        url: `${settings.API_URL}/BorrorwingsManagement/AddIntInsRepay`,
        data: body
    })
}
const UpdateIntInsRepay = async (body) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/BorrorwingsManagement/UpdateIntInsRepay`,
        data: body
    })
}
const GetIntInsRepay = async (borro_Id,TranStockType) => {
    return axios({
        method:'GET',
        url: `http://5.189.157.40:10026/api/BorrorwingsManagement/GetIntInsRepayDetails?borrowing_Id=${borro_Id}&transactStockType=${TranStockType}`
    })
}
const DeleteIntInsRepay = async (Id) => {
    return axios({
        method:'DELETE',
        url: `${settings.API_URL}/BorrorwingsManagement/DeleteIntInsRepay?Id=${Id}`
    })
}
const FetchBorrowingsData = async () => {
    return axios({
        method: "GET",
        url: `${BorrowingsUrl}/BorrorwingsManagement/FetchBorrowingsFamilyOuterGrid?s_id=${userID}`
    })
}
const EntityWiseBorrowingsList = async (sub_id) => {
    return axios({
        method: 'GET',
        url: `${BorrowingsUrl}/BorrorwingsManagement/FetchBorrowingsIndividualOuterGrid?sub_id=${sub_id}`
    })
}
const GetBuySellBorrowingsGrid = async (subId, Instu_id) => {
    return axios({
        method: 'GET',
        url: `${BorrowingsUrl}/BorrorwingsManagement/FetchBorrowingsDepositsEntityInfo?sub_id=${subId}&insTypeId=${Instu_id}`
    })
}
const GetBuySellGridBorrowingsEntity = async (Instu_id, sec_detail_Id,sub_id) => {
    return axios({
        method: 'GET',
        url: `${BorrowingsUrl}/BorrorwingsManagement/FetchBorrowingsIndividualEntityInfo?insTypeId=${Instu_id}&sec_detail_Id=${sec_detail_Id}&sub_id=${sub_id}`
    })
}
const GetBorrowTransaction = async (borrowId) => {
    return axios({
        method: 'GET',
        url: `${BorrowingsUrl}/BorrorwingsManagement/GetBorrowingsDepById?borrowing_Id=${borrowId}`
    })
}
const DeleteBorrowTransaction = async (borrowId) => {
    return axios({
        method: 'DELETE',
        url: `${BorrowingsUrl}/BorrorwingsManagement/RemoveBorrowingsDetails?borrowing_Id=${borrowId}`
    })
}

// api/BullionManagement/GetBullionDetailByHeaderId/{headerId}
export{
    AddBorrowings,
    updateBorrowings,
    AddIntInsRepay,
    UpdateIntInsRepay,
    GetIntInsRepay,
    DeleteIntInsRepay,
    FetchBorrowingsData,
    EntityWiseBorrowingsList,
    GetBuySellBorrowingsGrid,
    GetBuySellGridBorrowingsEntity,
    GetBorrowTransaction,
    DeleteBorrowTransaction,
}
