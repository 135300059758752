/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { EntityList, CompanyList, addUnlistedScrip } from "../../../pages/Stocks/StocksAPI";
import { FetchBondDentureDetails, FetchCompanyDBData, FetchFixedIncomeBroker, PostBondsConvert } from "../FDAPI";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
//import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';

const BondsConvertibles = (props) => {
    const { showBC, onHideBC, FDHeaderId, FDTransactionBC, showBondConpreview, onRefreshFD } = props;
    const [seltransactionType, setSelTransactionType] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [selParentScrip, setselParentScrip] = useState([]);
    const [pcompanyList, setPCompanyList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [mDate, setMDate] = useState(null);
    const [cDate, setCDate] = useState(null);
    const [amount, setAmount] = useState([]);
    const [price, setPrice] = useState([]);
    const [quantity, setQuantity] = useState([]);
    const [cquantity, setCQuantity] = useState([]);
    const [formErr, setFormErr] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [broker, setbroker] = useState([]);
    const [entityValue, setEntityVAlue] = useState([]);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [unlistedComp, setUnlistedComp] = useState('');
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    const [fidId, setFidId] = useState(null);
    const [fidHeaderId, setFidHeaderId] = useState(null);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }

    useEffect(() => {
        (async () => {
            if (showBC) {
                getEntityListt();
                getCompanyList();
            }
        })();
    }, [showBC]);

    useEffect(() => {
        (async () => {
            if (showBC) {
                if (selEntity) {
                    await getCompanyDropDownData()
                    await getBrokerDropDownData()
                }
            }
        })();
    }, [selEntity, showBC]);


    //getEntityList
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {
                // console.log(res, "entities");
                if (res.status === 200) {
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            });
                        });
                    setEntity(entityData);
                    setLoading(false)

                } else {
                    // setError(res.data.message);
                    setLoading(false)

                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)

            });


    };
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please select scrip!";
        }
        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please select Entity!";
        }
        if (!cDate) {
            isValid = false;
            formErr["date"] = "Please select Date !";
        }


        if (!cquantity.cquantity) {
            isValid = false;
            formErr["cquantity"] = "Please fill this field!";
        }
        if (cquantity.cquantity) {
            let qvalue = parseFloat(cquantity.cquantity);
            if (Number.isInteger(qvalue) === false) {
                isValid = false;
                formErr["cquantity"] = "Please enter only integer value!";
            }
        }
        if (selParentScrip.length < 1) {
            isValid = false;
            formErr["prcompany"] = "Please select Parent Company!";
        }
        if (cquantity.cquantity) {
            if (cquantity.cquantity > quantity || cquantity.cquantity < quantity) {
                isValid = false;
                formErr["cquantity"] = "Convert quantity not equal to remaining quantity!";
            }
        }
        setFormErr(formErr);
        return isValid;
    };

    //handlechange
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: '' });
    };

    const handleFamily = (selected) => {
        setSelEntity(selected);
        setEntityVAlue(selected.value)
        setFormErr({ ...formErr, selEntity: "" });
    };
    const handleOption = (selected) => {
        setSelBroker(selected);
        setbroker(selected.value)
        setSelScrip([])
        setselParentScrip([])
        setFormErr({ ...formErr, selBroker: "" });
    };

    const handleDate = (cDate) => {
        setCDate(cDate);
        setFormErr({ ...formErr, date: '' })
    };
    const handleQuantity = (e) => {
        const { name, value } = e.target;
        setQuantity({ ...quantity, [name]: value });
        setFormErr({ ...formErr, quantity: '' })
    };
    const handlePrice = (e) => {
        const { name, value } = e.target;
        setPrice({ ...price, [name]: value });
        setFormErr({ ...formErr, price: '' })
    };
    const handleAmount = (e) => {
        const { name, value } = e.target;
        setAmount({ ...amount, [name]: value });
        setFormErr({ ...formErr, amount: '' })
    };
    const handleCQuantity = (e) => {
        const { name, rawValue } = e.target;
        setCQuantity({ ...cquantity, [name]: rawValue });
        setFormErr({ ...formErr, cquantity: '' })
    };
    // const handleMDate = (mDate) => {
    //     setMDate(mDate);
    //     setFormErr({ ...formErr, mdate: '' })
    // }

    // get dropDown Company Data
    const getCompanyDropDownData = async () => {
        let sub_Id = selEntity.value;
        let Instu_id = 3
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchCompanyDBData(sub_Id, Instu_id)
                .then((res) => {

                    if (res.status === 200) {
                        let UnlistedCompany = [];
                        let ListedCompany = [];
                        //
                        let company = res.data._response.data.companyName;
                        company &&
                            company.filter(data => data.instrumentTypeId === 13).map(i => {
                                ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
                            });
                        company &&
                            company.filter(data => data.instrumentTypeId === 22).map(i => {
                                UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
                            });

                        const groupedOptions = [
                            {
                                label: 'Listed Companies',
                                options: ListedCompany,
                            },
                            {
                                label: 'UnListed Companies',
                                options: UnlistedCompany,
                            },
                        ];
                        setLoading(false);
                        setCompanyList(groupedOptions);
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                        // setError(res.data.message);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                    // setError(e.message);
                });
        }
    };

    // get dropDown company Data
    const getBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        let Instu_id = 3
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchFixedIncomeBroker(sub_Id, Instu_id)
                .then((res) => {
                    setLoading(false)

                    if (res.status === 200) {
                        let brokers = res.data._response.data.brokerName;
                        let brokersData = [];
                        brokers &&
                            brokers.map((data) => {
                                brokersData.push({
                                    value: data.brokerId,
                                    label: data.brokerName,
                                });
                            });
                        setLoading(false);
                        setBrokerList(brokersData);
                    } else {
                        setBrokerList([]);
                        setLoading(false)
                        //setError(res.data.message);
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setBrokerList([]);
                    setLoading(false);
                    console.log(e, "brokerlist error");
                });
        }
    };

    useEffect(() => {
        let co_id = selScrip.value;
        let bro_id = selBroker.value;
        let sub_id = selEntity.value;
        let instu_id = 3
        if (sub_id && co_id && bro_id) {
            PreferanceDetails(sub_id, co_id, bro_id, instu_id)
            setCDate(null)
        }

    }, [selScrip, selBroker, selEntity])

    const PreferanceDetails = (sub_id, co_id, bro_id, instu_id) => {

        FetchBondDentureDetails(sub_id, co_id, bro_id, instu_id, "Convertible")
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.fetchPreferanceDetails

                        result && result.map((data) => {
                            if (data.redeemConvertible === "Convertible") {
                                setPDate(new Date(data.date));
                                setMDate(new Date(data.domConversion));
                                setAmount(data.availableCost)
                                setQuantity(data.availableQuantity)
                                setPrice(data.pricePerShare)
                                setFidId(data.fdId)
                                setFidHeaderId(data.fdHeaderId)
                            }else{
                                Swal.fire("This Company and Broker not available for Convertible!", "", "info");
                            }
                        });
                    } else {
                        Swal.fire("This Company and Broker not available for Convertible!", "", "info");
                        setPDate();
                        setMDate();
                        setAmount([]);
                        setQuantity([]);
                        setPrice([]);
                    }
                } else {
                    setPDate();
                    setMDate();
                    setAmount([]);
                    setQuantity([]);
                    setPrice([]);
                }


            })
            .catch((e) => {
                setPDate();
                setMDate();
                setAmount([]);
                setQuantity([]);
                setPrice([]);
                console.log(e, "error in FetchPreferanceDetails");
            })
    }

    const handleCloseModal = (val) => {
        handleReset();
        onHideBC(val);
    }

    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 22) {
                return true
            }
        }
    }
    //handling unlisted modal 
    const handleUnlisted = (e) => {
        const { value } = e.target;
        setUnlistedComp(value);
        setUnlistedCompErr('')
    }
    // handle parent scrip
    const handleParentScrip = (selected) => {
        setselParentScrip(selected);
        setFormErr({ ...formErr, prcompany: '' });
    }
    // for  convert date fix 
    const fixTimezonecOffset = (cDate) => {
        if (!cDate) return "";
        return new Date(cDate.getTime() - (cDate.getTimezoneOffset() * 60000)).toJSON();
    }

    // for  convert date fix 
    const fixTimezonemOffset = (mDate) => {
        if (!mDate) return "";
        return new Date(mDate.getTime() - (mDate.getTimezoneOffset() * 60000)).toJSON();
    }
    //submit form
    const handleSubmit = (e) => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const Sdata = {
                totalCost: parseInt(amount),
                date: fixTimezonecOffset(cDate),
                domConversion: fixTimezonemOffset(mDate),
                companyId: selScrip.value,
                FromCompanyId: selParentScrip.value,
                brokerId: selBroker.value,
                subAccountId: selEntity.value,
                quantity: parseInt(cquantity.cquantity),
                pricePerShare: parseInt(price),
                checkfdId: fidId,
                checkfdHeaderId: fidHeaderId

            };
            PostBondsConvert(Sdata).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        setSuccess(true);
                        Swal.fire("Bonds Converted Successfully!", "", "success");
                        onRefreshFD();
                        handleReset();
                    } else {
                        Swal.fire("", response.data._response.message, "warning");
                    }
                }
                else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };
    const handleOpenModal = () => { setShowModal(!showModal); setselParentScrip([]); }
    const handleCloseModaln = () => {
        setShowModal(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }
    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    )

    //getcompanylist by group
    const getCompanyList = async (ListedCompanyParams) => {
        setLoading(true)
        await CompanyList()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    let UnlistedCompany = [];
                    let ListedCompany = [];
                    let company = res.data._response.data.companyDetails;
                    // let companyData = [];
                    company &&
                        company.filter(data => data.instrumentTypeId === 1).map(i => {
                            ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
                        });
                    company &&
                        company.filter(data => data.instrumentTypeId === 22).map(i => {
                            UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId })
                        });
                    const groupedOptions = [
                        {
                            label: 'Listed Companies',
                            options: ListedCompany,
                        },
                        {
                            label: 'UnListed Companies',
                            options: UnlistedCompany,
                        },

                    ];
                    if (ListedCompanyParams === 1) {

                        let lastElement = UnlistedCompany[UnlistedCompany.length - 1];

                        setselParentScrip(lastElement)

                    }
                    setPCompanyList(groupedOptions);
                    setLoading(false)
                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, 'error in companyList');
                // setError(e.message);
            });
    };
    //submit unlisted modal 
    const submitUnlisted = (e) => {
        e.preventDefault();
        if (unlistedComp !== '') {
            let data = {
                "companyName": unlistedComp,
                instrumentTypeId: 22
            }
            setLoading(true);
            addUnlistedScrip(data)
                .then(res => {
                    setLoading(false)
                    if (res.status === 200) {

                        const lastEle = 1
                        getCompanyList(lastEle);
                        setUnlistedComp('');
                        setUnlistedCompErr('');
                        handleCloseModaln()
                        //  Swal.fire("Added successfully!", "", 'success');
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setLoading(false)
                    console.log(e, "error in unlisted");
                })
        } else {
            setUnlistedCompErr("Company name required!")
        }
    }

    //reset field
    const handleReset = () => {
        setSelTransactionType([]);
        setSelScrip([]);
        setselParentScrip([]);
        setCDate(null);
        setPDate(null);
        setMDate(null);
        setFormErr([]);
        setSelEntity([]);
        setSelBroker([]);
        setQuantity({ quantity: '' });
        setCQuantity({ cquantity: '' });
        setPrice({ price: '' });
        setAmount({ amount: 0 });

    }

    //getbyid
    useEffect(() => {

        if (FDHeaderId !== 0) {

            if (FDTransactionBC !== undefined) {


                setSelBroker({
                    value: FDTransactionBC.brokerId,
                    label: FDTransactionBC.broker.brokerName,
                });
                setbroker(FDTransactionBC.brokerId);

                setSelEntity({
                    value: FDTransactionBC.subAccountId,
                    label: FDTransactionBC.subAccount.subAccountName,
                });

                setEntityVAlue(FDTransactionBC.subAccountId);

                FDTransactionBC.fdDetails && FDTransactionBC.fdDetails.map((t) => {

                    setCDate(new Date(t.date));

                    const f = t.fromCompany
                    setSelScrip({ label: f.companyName, value: f.CompanyId })

                    setselParentScrip({ label: t.company.companyName, value: t.CompanyId })

                    setMDate(new Date(t.domConversion))

                    setQuantity(t.availableQuantity)
                    setCQuantity({ cquantity: t.quantity })
                    setAmount(t.totalCost)
                    setPrice(t.pricePerShare)
                })

            }

        }
    }, [FDTransactionBC])

    const disableButton = () => {
        return pDate && selEntity && cDate &&
            selScrip && selBroker && price
            && amount && quantity && cquantity
    }

    return (
        <>
            <Modal show={showBC} onHide={() => handleCloseModal()} size="xl" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Bonds Convert </h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3" >
                            <Row className="form-set">
                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selEntity}
                                            onChange={handleFamily}
                                            options={Entity}
                                            name="subAccountId"
                                            isOptionDisabled={(option) => option.disabled === 0}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selEntity}
                                            </div>
                                        ) : null}
                                    </Form.Group> :
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selEntity}
                                            onChange={handleFamily}
                                            options={Entity}
                                            name="subAccountId"
                                            isDisabled
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selEntity}
                                            </div>
                                        ) : null}
                                    </Form.Group>}

                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selBroker}
                                            onChange={handleOption}
                                            options={BrokerList}
                                            name="brokerId"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selBroker}
                                            </div>
                                        ) : null}
                                    </Form.Group> :
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selBroker}
                                            onChange={handleOption}
                                            options={BrokerList}
                                            name="brokerId"
                                            isDisabled
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selBroker}
                                            </div>
                                        ) : null}
                                    </Form.Group>}

                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selScrip}
                                            onChange={handleInput}
                                            options={companyList}
                                            name="companyId"
                                            isOptionDisabled={handelDisableDD}
                                            styles={{
                                                option: (data) => ({
                                                    ...data,
                                                    margin: 0,
                                                    padding: '3px 12px',
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    color: '#000',
                                                    margin: 0,
                                                    padding: '7px 2px',
                                                    // border: `2px solid `,
                                                    background: "#8bc3ff",
                                                    fontSize: 'small',
                                                    fontWeight: 500
                                                }),
                                            }}
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.selScrip}
                                            </div>
                                        ) : null}
                                    </Form.Group> :
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selScrip}
                                            onChange={handleInput}
                                            options={companyList}
                                            name="companyId"
                                            isOptionDisabled={handelDisableDD}
                                            isDisabled
                                            styles={{
                                                option: (data) => ({
                                                    ...data,
                                                    margin: 0,
                                                    padding: '3px 12px',
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    color: '#000',
                                                    margin: 0,
                                                    padding: '7px 2px',
                                                    // border: `2px solid `,
                                                    background: "#8bc3ff",
                                                    fontSize: 'small',
                                                    fontWeight: 500
                                                }),
                                            }}
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.selScrip}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                }

                            </Row>

                            <Row className="form-set">
                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Parent Company <span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            components={{ Control }}
                                            value={selParentScrip}
                                            onChange={handleParentScrip}
                                            options={pcompanyList}
                                            name="companyId"
                                            isOptionDisabled={handelDisableDD}
                                            styles={{
                                                option: (data) => ({
                                                    ...data,
                                                    margin: 0,
                                                    padding: '3px 12px',
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    color: '#000',
                                                    margin: 0,
                                                    padding: '7px 2px',
                                                    // border: `2px solid `,
                                                    background: "#8bc3ff",
                                                    fontSize: 'small',
                                                    fontWeight: 500
                                                }),
                                            }}
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.prcompany}
                                            </div>
                                        ) : null}
                                    </Form.Group> :
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Parent Company <span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            components={{ Control }}
                                            value={selParentScrip}
                                            onChange={handleParentScrip}
                                            options={pcompanyList}
                                            name="companyId"
                                            isOptionDisabled={handelDisableDD}
                                            isDisabled
                                            styles={{
                                                option: (data) => ({
                                                    ...data,
                                                    margin: 0,
                                                    padding: '3px 12px',
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    color: '#000',
                                                    margin: 0,
                                                    padding: '7px 2px',
                                                    // border: `2px solid `,
                                                    background: "#8bc3ff",
                                                    fontSize: 'small',
                                                    fontWeight: 500
                                                }),
                                            }}
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.prcompany}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                }
                                {FDHeaderId === 0 || FDHeaderId === undefined ?

                                    <Form.Group className="col-md-4">
                                        <Form.Label>Date of Purchase</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            placeholderText="Select start Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            dateFormat="dd-MM-yyyy"
                                            name="date"
                                            autoComplete="off"
                                            selected={pDate}
                                            disabled
                                        />

                                    </Form.Group> : <> </>}

                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Maturity</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={mDate}
                                        disabled
                                    />

                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-3">
                                    <Form.Label>No.of Shares Purchased</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        min="0"
                                        value={NumberOnlySeperator(isNaN(quantity) ? 0 : quantity)}
                                        onChange={handleQuantity}
                                        disabled
                                    />

                                </Form.Group>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Price per share</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        min="0"
                                        value={NumberSeperator(isNaN(price) ? 0 : price)}
                                        onChange={handlePrice}
                                        disabled
                                    />

                                </Form.Group>
                                <Form.Group className="col-md-2">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        min="0"
                                        value={NumberSeperator(isNaN(amount) ? 0 : price)}
                                        onChange={handleAmount}
                                        disabled
                                    />

                                </Form.Group>
                                <Form.Group className="col-md-2">
                                    <Form.Label> Converted on<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(mDate)}
                                        onChange={handleDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={cDate}

                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <Form.Label>No.of Converted Shares<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="cquantity"
                                        min="0"
                                        value={cquantity.cquantity}
                                        onChange={handleCQuantity}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="cquantity"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={cquantity.cquantity}
                                        onChange={handleCQuantity} />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.cquantity}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            {FDHeaderId === 0 || FDHeaderId === undefined ?
                                <Row>
                                    <Col md="12" className="text-center mt-3">
                                        <Button variant="primary" className="mx-2 w100" onClick={() => handleSubmit()}
                                            disabled={!disableButton()} >
                                            Convert
                                        </Button>

                                        <Button
                                            variant="secondary"
                                            className="mx-2 w100"
                                            type="reset"
                                            onClick={handleReset}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row> : <></>}
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            <>
                <Modal show={showModal} onHide={handleCloseModaln} animation={false} size="sm" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Listed Scrip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            {/* <Form.Group className="">
                                    <Form.Label>Scrip <span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selScrip}
                                        onChange={handleParentScrip}
                                        options={companyList}
                                        name="companyId"
                                        isOptionDisabled={handelDisableDD}
                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selScrip}
                                        </div>
                                    ) : null}
                                </Form.Group> */}
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Company Name <span className="text-danger h6">*</span></label>
                                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            {showBondConpreview === true ?
                <Modal show={showBondConpreview} onHide={() => handleCloseModal(1)}>
                    <Modal.Header closeButton className="text-center headerBlue" >
                        <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5> Bonds Convert Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Parent Company : </div>
                                <div className="transactionDataValue">{selParentScrip.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Date of Maturity : </div>
                                <div className="transactionDataValue">{moment(mDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">No. of Shares Purchased : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(quantity)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Price per Share : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(price)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Converted on : </div>
                                <div className="transactionDataValue">{moment(cDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">No. of Converted Shares : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(cquantity.cquantity)}</div></div>
                        </div>
                    </Modal.Body>
                </Modal>
                : null}
        </>


    )
}

export default BondsConvertibles
