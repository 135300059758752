/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Button, Paper } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { AddUserMenuRelData, getAccessTableData } from '../../../pages/RoleMasterPages/AdminsPages/UserdetailsApi';
import { Stack } from "@mui/material";
import Loader from '../../loadingAnimation/index'
const AccessTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [submitData, setSubmitData] = useState([]);

    const handleReset = () => {
        setMenuData([]);
        setMenuItems([]);
        setSubmitData([]);
    }
    useEffect(() => {
        changeMenuItem()
    }, [])

    const changeMenuItem = () => {
        setLoading(true);
        getAccessTableData().then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    const newData = res.data._response.data;
                    let rolesArr = [];

                    setMenuItems(newData.header)
                    newData.header.map((header, i) => {
                        newData.data.map((da, ind) => {
                            let isRolePresent = rolesArr.some((el) => el === da.role.roleId)
                            if (!isRolePresent) {
                                rolesArr.push(da.role.roleId);
                            }

                            // push not present companies into menuItem array with active 0 value
                            let isPresent = da.menuItem.some((el) => el.menuId === header.menuId)
                            if (!isPresent) {
                                let obj = {
                                    menuName: header.menuName,
                                    menuId: header.menuId,
                                    active: 0
                                };
                                da.menuItem.push(obj);
                            }
                        })
                    })
                    // push obj with role id into the submit state
                    let Arr = [];
                    rolesArr && rolesArr.forEach((role, ind) => {
                        let object = {
                            roleId: [role],
                            menuId: [],
                            id: role
                        }
                        Arr.push(object);
                    })

                    setSubmitData(Arr);
                    setMenuData(newData.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }

        }).catch((e) => {
            setLoading(false);
            console.log(e, 'err in getAccessTable data')
        })

    }

    const handleCheckbox = (roleId, menuId, isActive) => {
        let newSubmitData = [...submitData];

        // check menuId is present in array
        let indx = newSubmitData.findIndex(el => el.id === roleId);
        const isPresentMenuId = newSubmitData[indx].menuId.some(el => el === menuId);

        if (isPresentMenuId) {
            // remove menuId from the particular array
            let index = newSubmitData[indx].menuId.indexOf(menuId);
            if (index !== -1) {
                newSubmitData[indx].menuId.splice(index, 1);
                setSubmitData([...newSubmitData])

                //update table state
                let ind = menuData.findIndex(el => el.role.roleId === roleId)
                menuData[ind].menuItem.forEach(da => {
                    if (da.menuId === menuId) {
                        let val = da.active === 1 ? 0 : 1
                        da.active = val
                    }
                })
            }
        } else {
            // insert menuId in the particular array
            newSubmitData[indx].menuId.push(menuId);
            setSubmitData([...newSubmitData]);

            //update table state
            let ind = menuData.findIndex(el => el.role.roleId === roleId)
            menuData[ind].menuItem.forEach(da => {
                if (da.menuId === menuId) {
                    let val = da.active === 1 ? 0 : 1
                    da.active = val
                }
            })
        }
    }

    const handleSubmit = () => {
        // console.log(submitData, 'val');
        let roleCount = 0;
        let menuIdCount = 0;
        let adminChanges = false;
        submitData.forEach((val, ind) => {
            if (val.menuId.length === 0) {
                menuIdCount++;
            }
            if (val.id === 1 && val.menuId.length > 0) {
                adminChanges = true;
            }
            roleCount++;
        })
        if (roleCount !== menuIdCount) {
            setLoading(true);
            AddUserMenuRelData(submitData).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        Swal.fire("Success!", "Data Updated Successfully", 'success');
                        if (adminChanges === true) { window.location.reload('/AccessTable'); }
                        else {
                            handleReset();
                            changeMenuItem();
                        }

                    } else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        } else {
            Swal.fire('', "You haven't changed anything", 'info');
        }
    }
    return (
        <>
            <Container fluid>
                {loading ? <Loader /> : null}
                <Row>
                    <Col>
                        <h4 className="text-center py-3">Access Table</h4>
                    </Col>
                </Row>

                <div className="mt-2 p-0 pt-1  justify-content-center">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="UserdetWrap p-3 pb-0">
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">


                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" width="7%">{menuItems.length > 0 ? 'Role' : null}</TableCell>
                                        {menuItems && menuItems.map((d, i) => (
                                            <TableCell key={i} align="center" width="7%">{d.menuName}</TableCell>

                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {menuData && menuData.map((data, index) => {
                                        return (

                                            <TableRow key={index}>
                                                <TableCell align="center">{data.role.roleName}</TableCell>

                                                {menuItems && menuItems.map((header, indx) => {
                                                    return (data.menuItem.filter((men) => men.menuId === header.menuId).map((menu, index) => {
                                                        return (
                                                            <TableCell padding="checkbox" key={index}>
                                                                <Checkbox
                                                                    id="menu"
                                                                    name="menuId"
                                                                    style={{ marginLeft: "1rem" }}
                                                                    color="default"
                                                                    checked={menu.active === 1 ? true : false}
                                                                    onChange={() => handleCheckbox(data.role.roleId, menu.menuId, menu.active)}
                                                                    disabled={menu.menuName === 'Access Table' && data.role.roleId === 1}
                                                                />
                                                            </TableCell>
                                                        )
                                                    }))
                                                }
                                                )}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <br></br>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="UserdetWrap p-3 ">
                        <Stack direction="row" spacing={{ xs: 3, sm: 2, md: 4 }}
                            justifyContent="center"
                            alignItems="center" mb={2} >
                            <Button className="w100 btn btn-primary"
                                onClick={() => { handleSubmit(); }}
                            >
                                Submit
                            </Button>

                        </Stack>
                    </Paper>



                </div>
            </Container>
        </>
    )

}
export default AccessTable