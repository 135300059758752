/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Container, Row, Col } from "react-bootstrap";
import SecondDTable from "./SecondDTable";



import "../../pages/Dashboard/Dashboard.css";
import DashboardTable from "./DashboardTable";
import "react-tabs/style/react-tabs.css";

import { GiCash } from "react-icons/gi";
import DashboardIcon from '@mui/icons-material/Dashboard';




const Main = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showDash, setShowDash] = useState(false)



  return (
    <Container fluid align="right">
      <Row>
        <Col>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => { setTabIndex(tabIndex); { showDash === true ? setShowDash(false) : setShowDash(true) }; }}
          >
            <TabList className="dash">{!showDash ?
              <Tab title="go to investment"><GiCash className="Invmentkdj" /></Tab> :
              <Tab title="go to dashboard"><DashboardIcon className="dashboardk" /></Tab>}

            </TabList>{showDash ?
              <TabPanel><SecondDTable /></TabPanel> :
              <TabPanel><DashboardTable /></TabPanel>}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
