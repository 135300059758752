/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../loadingAnimation/index";
import { typeOfInvest, periodList } from '../../modules/helpers/constant'
import Dropdown from '../CommonComponent/Dropdown';
import { GetDividendFrequency } from "../../pages/Stocks/StocksAPI";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LoansInterest from './LoansInterest';
import LoansRepay from './LoansRepay';
import useEnterKeyListener from "../ShortcutKey/useKeyListener";
import LoansInstallment from './LoansInstallment'
import {
  AddLoans,
  updateLoans
} from '../../pages/Loans_Deposits/Loans_DepoAPI';
import Cleave from 'cleave.js/react';
import { Tooltip } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(57, 62, 70, 0.87)',
    color: 'rgba(254, 251, 246, 0.97)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function Loans_DepDetails(props) {

  const { showLoan, onHideLoan, entityId, LoanTransaction, LoanHeaderId, onRefreshForm, onRefreshInnerGrid } = props;

  const [tenurePeriod, setTenurePeriod] = useState("");
  const [tenure, setTenure] = useState([{ label: "Month", value: "Month" }]);
  const [tenureValue, setTenureValue] = useState("Month");

  const [lockInPeriod, setLockInPeriod] = useState("")
  const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
  const [lockPeriodValue, setLockPeriodValue] = useState("Month");

  const [dividendFrequency, setDividendFrequency] = useState([]);
  const [selFrequency, setSelFrequency] = useState([]);
  const [frequencyValue, setFrequencyValue] = useState([]);

  const [reinvestedRadio, setreinvestedRadio] = useState("no");
  const [interestAcc, setInterestAcc] = useState('')
  const [startDate, setStartDate] = useState(null);

  const [selInvFrequency, setSelInvFrequency] = useState([]);
  const [invFrequency, setInvFrequency] = useState(typeOfInvest);

  const [REFS_NO, setREFS_NO] = useState('');
  const [loanTo, setLoanTo] = useState({ loanto: "" });
  const [loanToId, setLoanToID] = useState('');
  const [iDate, setiDate] = useState(null);
  const [amount, setAmount] = useState('');
  const [mDate, setmDate] = useState(null);
  const [interestPer, setInterestPer] = useState('');
  const [amountOutstanding, setAmountOutstanding] = useState(0)
  const [narration, setNarration] = useState({ narration: "" });
  const [subAccountId, setSubAccountId] = useState(0)
  const [interestData, setInterestData] = useState({interestPer:'',
  reinvestedRadio:'no',interestAcc:'' ,startDate:null,selFrequency:{} })

  const [InterestAmount, setIntrestAmount] = useState('');

  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState([]);

  const [interestperc, setInterestPerc] = useState('');

  const [LoansInstall, setLoansInstall] = useState(false);
  const [loaninterest, setloaninterest] = useState(false)
  const [isInterest, setIsInterest] = useState(false)
  const [loansrepay, setLoansrepay] = useState(false)

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEnterKeyListener({
      querySelectorToExecuteClick: "#submitButton"
     });

  const handleCloseDetails = () => {
    onHideLoan();
    onRefreshInnerGrid()
    handleReset();
  }

  useEffect(() => {
    getDividedFrequencyList();
  }, [])

  //reference no
  const handleRefsNo = (e) => {
    // const { name, value } = e.target;
    // setREFS_NO({ ...REFS_NO, [name]: value })
    setREFS_NO(e.target.value)
    setFormErr({ ...formErr, REFS_NO: "" })
  }

  //loans to
  const handleLoansTo = (e) => {
    const { name, value } = e.target;
    setLoanTo({ ...loanTo, [name]: value })
  }

  //handleInvDate
  const handleInvDate = (selected) => {
    setiDate(selected)
  }

  //handleAmount
  const handleAmount = (e) => {
    // const { name, value } = e.target;
    // setAmount({ ...amount, [name]: value })
    setAmount(e.target.rawValue)
  }

  //handleTenure
  const HandleChangeTenure = (e) => {
    setTenure(e);
    setTenureValue(e.value);
    // setFormErr({ ...formErr, tenureValue: '' })
  }

  //handle Maturity Date
  const handleMatDate = (selected) => {
    setmDate(selected)
    // setFormErr({ ...formErr, mDate: "" })
  }

  //handle InterestAcc
  const handleInterestPer = (e) => {
    // const { name, value } = e.target;
    // setInterestPer({ ...interestAcc, [name]: value });
    // setFormErr({ ...formErr, interestAcc: "" })
    setInterestPer(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)

    if(Number(e.target.value) === 0 ){
          setInterestData({
            interestPer:e.target.value,
            reinvestedRadio,
            startDate,
            interestAcc,
            selFrequency:selFrequency
          })
          setSelFrequency([]);
          setreinvestedRadio('no');
          setStartDate(null);
          setInterestAcc('')
        }else{
          if(Object.keys(interestData.selFrequency).length !==0 ){
            setSelFrequency(interestData.selFrequency);
          }
          if(interestData.startDate !== null && interestData.interestAcc !== ""){
            setreinvestedRadio(interestData.reinvestedRadio);
            setStartDate(interestData.startDate);
            setInterestAcc(interestData.interestAcc)
          }
        }
        setFormErr({ ...formErr, interestPer: '' })
      }

  // handleInvFrequency
  const handleInvFrequency = (selected) => {
    setSelInvFrequency(selected)
  }


  // handleNarration
  const handleNarration = (e) => {
    const { name, value } = e.target;
    setNarration({ ...narration, [name]: value })
  }

  //handle lock in period
  const HandleChangePeriod = (e) => {
    setLockPeriod(e);
    setLockPeriodValue(e.value);
    // setFormErr({ ...formErr, lockPeriodValue: '' })
  }

  //handleinterstAcc
  const handleValueIntAcc = (e) => {
    setInterestAcc(e.target.rawValue);
    // const { name, value } = e.target;
    // setInterestAcc({ ...interestAcc, [name]: value });
    setFormErr({ ...formErr, interestAcc: "" })
  }
  //handlestartingdate
  const handleDateStart = (startDate) => {
    setStartDate(startDate);
    setFormErr({ ...formErr, startDate: "" })
  }

  //handleradio
  const handleRadio = (e) => {
    const { name, value } = e.target
    setreinvestedRadio(value);
    if (value === "no" && LoanHeaderId === 0) {
      setStartDate(null);
      setInterestAcc('')
      setInterestData({...interestData ,startDate:null,interestAcc:''})
    }else{
      setInterestData({...interestData ,reinvestedRadio:value,startDate:startDate,interestAcc:interestAcc})
    }

  }

  //handlefrequency
  const handleFrequency = (selected) => {
    setSelFrequency(selected);
    setFrequencyValue(selected.value)
    setInterestData({...interestData ,selFrequency:selected})
    // setFormErr({ ...formErr, selFrequency: "" })
  }

  useEffect(() => {
    if ((amount || interestAcc) && (LoanHeaderId === 0 || LoanHeaderId === undefined)) {

      let totalOUtstanding = Number(amount ? amount : 0) + Number(interestAcc ? interestAcc : 0)
      setAmountOutstanding(totalOUtstanding.toFixed(2))
    }
  }, [amount, interestAcc])

  //getdividend list
  const getDividedFrequencyList = async () => {
    setLoading(true)
    await GetDividendFrequency()
      .then((res) => {

        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
                return( dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                }))
              });
            setDividendFrequency(dividendData);
            // selFrequency(dividendData[1])
          }
          setLoading(false)
        } else {
          // setError(res.data.message);
          setLoading(false)

        }
      })
      .catch((e) => {
        // setError(e.message);
        setLoading(false)
      });
  };

  // for  date fix 
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  const errHandler = () => {
    let formErr = {};
    let isValid = true;

    if (selInvFrequency.length < 1) {
      isValid = false;
      formErr["selInvFrequency"] = "Please Select Investment!";
    }

    if (!loanTo.loanto) {
      isValid = false;
      formErr["loanto"] = "Please Enter Deposit With / Loan to!";
    }

    if (!iDate) {
      isValid = false;
      formErr["iDate"] = "Please Select Date!";
    }

    if (!amount) {
      isValid = false;
      formErr["amount"] = "Please Enter Amount!";
    }
    if(LoanHeaderId !== 0){
      if(interestperc > 0 && Number(interestPer) === 0){
          isValid = false;
          formErr["interestPer"] = "Please Add Interest % More Than 0";
  }
  }
    // if (!mDate) {
    //   isValid = false;
    //   formErr["mDate"] = "Please Enter Maturity Date!";
    // }

    if (interestAcc === "" && reinvestedRadio === "yes") {
      isValid = false;
      formErr["interestAcc"] = "Please Enter Interest Accrued !";
    }
    if ((!startDate) && (reinvestedRadio === "yes")) {
      isValid = false;
      formErr["startDate"] = "Please Select Date !";
    }
    setFormErr(formErr);
    return isValid;
  }

  const handleSubmit = () => {
    const isValid = errHandler();
    if (isValid === true) {
      setLoading(true)
      const loanData = {
        subAccountId: entityId,
        instrumentId: 5,
        instrumentTypeId: selInvFrequency.value,
        transactStockType: "Buy",
        referenceNo: REFS_NO,
        depositWithLoanTo: loanTo.loanto,
        // interestAmt: amount,
        typeOfInvestment: selInvFrequency.label,
        depositDate: fixTimezoneOffset(iDate),
        amtInvested: amount,
        lockInPeriod: lockInPeriod === "" ? "0" + lockPeriodValue : lockInPeriod + lockPeriodValue,
        tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
        maturityDate: mDate ? fixTimezoneOffset(mDate) : undefined,
        interestPerc: interestPer === "" ? 0 : interestPer,
        frequencyId:selFrequency && selFrequency.value,
        amtOutstanding: Number(amountOutstanding),
        narration: narration.narration,
        cumulativeNonCumulative: reinvestedRadio === "yes" ? true : false,
        reinvestmentPayout: reinvestedRadio === "yes" ? Number(interestAcc) : 0,
        interestDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,

      }
      AddLoans(loanData).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            Swal.fire("Success!", "Loans Added Successfully", "success");
            handleReset();
          }

        } else {
          setLoading(false);
          console.log("error");
        }
      })
        .catch((err) => {
          console.log(err);
          setLoading(false)

        })

    }
  }

  const handleUpdate = () => {
    const isValid = errHandler();
    if (isValid === true) {
      setLoading(true)
      // debugger
      const loanData = {
        loanId: LoanHeaderId,
        subAccountId: subAccountId,
        instrumentId: 5,
        instrumentTypeId: selInvFrequency.value,
        transactStockType: "Buy",
        referenceNo: REFS_NO,
        depositWithLoanTo: loanTo.loanto,
        depositWithLoanToId: parseInt(loanToId),
        // interestAmt: amount,
        typeOfInvestment: selInvFrequency.label,
        depositDate: fixTimezoneOffset(iDate),
        amtInvested: amount,
        lockInPeriod: lockInPeriod === "" ? "0" + lockPeriodValue : lockInPeriod + lockPeriodValue,
        tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
        maturityDate: mDate === null ? null : fixTimezoneOffset(mDate),
        interestPerc: interestPer === "" ? 0 : interestPer,
        frequencyId:selFrequency && selFrequency.value,
        amtOutstanding: amountOutstanding,
        narration: narration.narration,
        cumulativeNonCumulative: reinvestedRadio === "yes" ? true : false,
        reinvestmentPayout: reinvestedRadio === "yes" ? Number(interestAcc) : 0,
        interestDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,

      }
      updateLoans(loanData).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            Swal.fire("Success!", "Loans Updated Successfully", "success");
            onRefreshForm(LoanHeaderId);
          }

        } else {
          setLoading(false);
          console.log("error");
        }

      }).catch((err) => {
        console.log(err);
        setLoading(false)

      })

    }
  }

  // get by data for loans
  useEffect(() => {
    if (LoanHeaderId !== 0) {
      if (LoanTransaction !== undefined) {
        // console.log(LoanTransaction, "LoanTransaction");
        setNarration({ narration: LoanTransaction.narration })
        setSubAccountId(LoanTransaction.subAccountId)
        let l = LoanTransaction.lockInPeriod
        let splitDatalock = l.split(/([0-9]+)/);
        setLockInPeriod(splitDatalock[1]);
        setLockPeriodValue(splitDatalock[2]);
        setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });

        let d = LoanTransaction.tenure
        let splitData = d.split(/([0-9]+)/);
        setTenurePeriod(splitData[1]);
        setTenureValue(splitData[2]);
        setTenure({ label: splitData[2], value: splitData[2] });
        setreinvestedRadio(LoanTransaction.cumulativeNonCumulative === true ? "yes" : "no");
        if (LoanTransaction.cumulativeNonCumulative === true) {
          setInterestAcc(LoanTransaction.reinvestmentPayout);
          setStartDate(new Date(LoanTransaction.interestDate));
        }

        setAmountOutstanding(LoanTransaction.amtOutstanding)
        setREFS_NO(LoanTransaction.referenceNo);
        // setInterestAcc(LoanTransaction.reinvestmentPayout);
        setLoanToID(LoanTransaction.depositWithLoanToId)
        setLoanTo({ loanto: LoanTransaction.depositWithLoanTo.depositWithLoanToName });
        setAmount(LoanTransaction.firstAmtInvested);
        setmDate(LoanTransaction.maturityDate == null ? null : new Date(LoanTransaction.maturityDate) );
        setiDate(new Date(LoanTransaction.depositDate));
        setInterestPer(LoanTransaction.interestPerc);
        setInterestPerc(LoanTransaction.interestPerc);
        if (LoanTransaction.frequency !== null) {
          setSelFrequency({ label: LoanTransaction.frequency.frequencyName, value: LoanTransaction.frequencyId });
        }
        setSelInvFrequency({ label: LoanTransaction.instrumentType.instrumentTypeName, value: LoanTransaction.instrumentTypeId })
        setIntrestAmount(LoanTransaction.interestAmt)
      }
    }





  }, [LoanTransaction])


  const handleReset = () => {
    setSelFrequency([]);
    setreinvestedRadio("no");
    setInterestAcc('');
    setStartDate(null);
    setSelInvFrequency([]);
    setREFS_NO('');
    setLoanTo({ loanto: "" });
    setAmount('');
    setmDate(null);
    setiDate(null);
    setInterestPer('');
    setAmountOutstanding(0);
    setNarration({ narration: "" })
    setLockInPeriod("");
    setTenurePeriod("")
    setFormErr([]);
    setLoanToID('');
    setInterestData({interestPer:'',
    reinvestedRadio:'no',interestAcc:'' ,startDate:null,selFrequency:{}});
  }

  const disableButton = () => {
    return selInvFrequency && iDate && loanTo && amount //&& mDate
  }

  //installment
  const handleInstall = () => {
    setLoansInstall(true);
  }

  const handleCloseInstallMent = () => {
    onRefreshForm(LoanHeaderId);
    setLoansInstall(false);
  }

  // interest open modal
  const handleInterest = () => {
    setloaninterest(!loaninterest)
  }
  const handleCloseInterest = () => {
    onRefreshForm(LoanHeaderId);
    setloaninterest(false)
  }

  const handleRepay = () => {
    setLoansrepay(!loaninterest)
  }
  const handleCloseRepay = () => {
    onRefreshForm(LoanHeaderId);
    setLoansrepay(false)
  }
  const handledisableCheck = (i) => {
    if (i === 1) {
      setIsInterest(true);
    } else {
      setIsInterest(false);
    }
  }

  return (
    <>
      <div className="mt-2 p-6" tabIndex={1} >


        <Modal className="custom-dialog"
          isOpen={showLoan}
          toggle={handleCloseDetails}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg">

          <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseDetails()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
            {
              LoanHeaderId === 0 ?
                <h5 className="text-center">Add Loans</h5> :
                <h5 className="text-center">Update Loans</h5>

            }

          </ModalHeader>

          <ModalBody>
            <Container fluid>
              {loading ? (
                <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
              ) : null}

              <div className="mt-2 p-6">
                <Form className="border border-1 rounded p-3" >
                  <Row className="form-set">
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Reference Number</Form.Label>
                      <Form.Control
                        type="number"
                        // name="REF_NO"
                        placeholder='Enter Reference Number'
                        autoComplete="off"
                        min="0"
                        value={REFS_NO}
                        onChange={handleRefsNo}
                      />

                    </Form.Group>
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Type of Investment<span className="text-danger h6">*</span></Form.Label>
                      <Select
                        value={selInvFrequency}
                        onChange={handleInvFrequency}
                        options={invFrequency}
                        name="investId"
                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.selInvFrequency}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Deposit with / Loan to<span className="text-danger h6">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="loanto"
                        autoComplete="off"
                        value={loanTo.loanto}
                        onChange={handleLoansTo}
                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.loanto}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="col-md-3">
                      <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Start Date"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        maxDate={new Date()}
                        onChange={handleInvDate}
                        dateFormat="dd-MM-yyyy"
                        name="iDate"
                        autoComplete="off"
                        selected={iDate}

                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.iDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row className="form-set">
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                      {/* <Form.Control
                        type="number"
                        // name="amount"
                        min="0"
                        placeholder='Enter Amount Invested'
                        autoComplete="off"
                        value={amount}
                        onChange={handleAmount}
                      /> */}

                      <Cleave
                        placeholder='Enter Amount Invested'
                        className='form-control'
                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                        value={amount}
                        onChange={handleAmount}
                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.amount}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Col md="3" className="mb-3">

                      <Form.Label className="d-block">Tenure</Form.Label>
                      <Form.Control type="text"
                        onChange={(e) => setTenurePeriod(e.target.value)}
                        autoComplete="off"
                        value={tenurePeriod}
                        name="tenure" id="tenure" className="lockinperiodno" />
                      <Dropdown
                        value={tenure}
                        name='tenure'
                        options={periodList}
                        onChange={HandleChangeTenure}
                        className="SelectClass lockinperiodlist"
                        searchable={true}
                        clearable={true}
                        noResultsText={"No result"}
                        placeholder="Month"

                      />
                    </Col>
                    <Form.Group className="col-md-3">
                      <Form.Label>Date of Maturity</Form.Label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Maturity Date"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        minDate={new Date(iDate)}
                        onChange={handleMatDate}
                        dateFormat="dd-MM-yyyy"
                        name="mDate"
                        autoComplete="off"
                        selected={mDate}

                      />
                      {/* {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.mDate}
                        </div>
                      ) : null} */}
                    </Form.Group>
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Interest %</Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        name="interestPer"
                        placeholder='%'
                        autoComplete="off"
                        value={interestPer}
                        onChange={handleInterestPer}
                      />
                       {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.interestPer}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row className="form-set">
                    <LightTooltip title={Number(interestPer) === 0 ? "You must need add Interest % to enable interest frequency": ""}  > 
                    <Form.Group className="col-md-3">
                      <Form.Label>Interest Frequency</Form.Label>
                      <Select
                        value={selFrequency}
                        onChange={handleFrequency}
                        options={dividendFrequency}
                        name="frequencyId"
                        isDisabled={Number(interestPer) === 0 || interestPer === "" } 
                      />
                    </Form.Group>
                      </LightTooltip>
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Amount Outstanding</Form.Label>
                      {/* <Form.Control
                        type="text"
                        min="0"
                        // name="amountOut"
                        autoComplete="off"
                        value={NumberSeperator(amountOutstanding)}
                        disabled
                      // onChange={handleAmountOut}
                      /> */}

                      <Cleave
                        className='form-control'
                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                        value={NumberSeperator(amountOutstanding)}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-6 col-md-3">
                      <Form.Label>Narration</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="narration"
                        autoComplete="off"
                        rows={2}
                        value={narration.narration}
                        onChange={handleNarration}
                      />
                    </Form.Group>
                    <Col md="3" className="mb-3">
                      <Form.Label className="d-block">Lock in Period (if Any)</Form.Label>
                      <Form.Control type="text"
                        onChange={(e) => setLockInPeriod(e.target.value)}
                        autoComplete="off"
                        value={lockInPeriod}
                        name="lockinperiod" id="lockinperiod" className="lockinperiodno" />
                      <Dropdown
                        value={lockPeriod}
                        name='lockinperiod'
                        options={periodList}
                        onChange={HandleChangePeriod}
                        className="SelectClass lockinperiodlist"
                        searchable={true}
                        clearable={true}
                        noResultsText={"No result"}
                        placeholder="Month"

                      />
                    </Col>
                  </Row>
                {Number(interestPer) === 0 || interestPer === "" ? null :
                  <Row>
                    <Form.Group className="col-md-3">
                      <Form.Label>Interest reinvested</Form.Label>
                      <RadioGroup
                        row
                        color="primary"
                        defaultValue="start"
                        aria-label="Entity"
                        name="row-radio-buttons-group"
                        fontSize="100px"
                        value={reinvestedRadio}
                        onChange={handleRadio}
                        style={{ marginTop: "-2px" }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        // disabled={isInterest}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        // disabled={isInterest}
                        />
                      </RadioGroup>
                    </Form.Group>

                    {reinvestedRadio === "yes" ?
                      <>
                        <Form.Group className="col-md-3">
                          <Form.Label>Interest Receivables on above<span className="text-danger h6">*</span></Form.Label>
                          {/* <Form.Control
                            type="Number"
                            // name="interestReinvested"
                            min="0"
                            value={interestAcc}
                            onChange={handleValueIntAcc}
                          /> */}

                          <Cleave
                            className='form-control'
                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                            value={interestAcc}
                            onChange={handleValueIntAcc}
                          />
                          {formErr ? (
                            <div
                              className="mt-2"
                              style={{ fontSize: 12, color: "red" }}
                            >
                              {formErr.interestAcc}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group className="col-md-3">
                          <Form.Label>Date of Interest Receivable<span className="text-danger h6">*</span></Form.Label>
                          <DatePicker
                            className="form-control"
                            placeholderText="Select start Date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={50}
                            maxDate={new Date()}
                            // minDate={new Date(buyDate)} dateFormat="dd-MM-yyyy"
                            onChange={handleDateStart}
                            dateFormat="dd-MM-yyyy"
                            name="fyStartDate"
                            autoComplete="off"
                            selected={startDate}
                          />
                          {formErr ? (
                            <div
                              className="mt-2"
                              style={{ fontSize: 12, color: "red" }}
                            >
                              {formErr.startDate}
                            </div>
                          ) : null}
                        </Form.Group>
                      </> : null
                    }
                  </Row>
                }
                  <Row className="form-set">
                    <Col md="12" className="text-center mt-3">
                      {
                        LoanHeaderId === 0 ?
                          <>
                              <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                  <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                    <Button variant="primary" className="mx-2 w100"
                                      type="submit"
                                      id ="submitButton"
                                      onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                      disabled={!disableButton()}
                                    >Submit</Button>
                                  </span>
                              </Tooltip>
                            <Button
                              variant="secondary"
                              className="mx-2 w100"
                              type="reset"
                              onClick={handleReset}
                            >
                              Clear
                            </Button>
                          </> :
                          <>
                            <Button variant="primary" className="mx-2 w100"
                              onClick={handleUpdate}
                              id ="submitButton"
                              disabled={!disableButton()}
                            >Update</Button>

                            <Button variant="primary" className="mx-2"
                              onClick={handleInstall} >Additional Loans</Button>
                            <Button variant="primary" className="mx-2 w100"
                              onClick={handleInterest} disabled={reinvestedRadio === "no"}>Add Interest</Button>
                            <Button variant="primary" className="mx-2 w100"
                              onClick={handleRepay} >Repay</Button>
                          </>
                      }




                    </Col>
                  </Row>
                </Form>
              </div>
            </Container>

          </ModalBody>

        </Modal>
      </div>

      <LoansInterest showLoanI={loaninterest} hideLoanI={handleCloseInterest} LoanId={LoanHeaderId} InvDate={iDate} Iname={"Loans Interest"} disableCheck={(i) => handledisableCheck(i)} />
      <LoansRepay showLoanI={loansrepay} hideLoanI={handleCloseRepay} LoanId={LoanHeaderId} InvDate={iDate} Iname={"Repay"} reinvestedRadio={reinvestedRadio} intrestAmount={InterestAmount} />
      <LoansInstallment showLoansIn={LoansInstall} hideLoansIn={handleCloseInstallMent} LoanId={LoanHeaderId} InvtDate={iDate} Iname={"Additional Loans"} />
    </>

  )
}

export default Loans_DepDetails