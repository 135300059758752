/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
    Button,
    Paper,

} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { Stack } from "@mui/material";
import AddUser from './AddUser';
import MaterialTable from "material-table";
import { UserActiveData, UserListData } from '../../../pages/RoleMasterPages/AdminsPages/UserdetailsApi'
import { Edit } from "@material-ui/icons";

import { UserGetData } from '../../../pages/RoleMasterPages/AdminsPages/UserdetailsApi';

const UserDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [checked, setChecked] = useState(true);
    const [UserData, setUserData] = useState([])
    const [getAddUser, setGetAddUser] = useState([])
    const [update, setUpdate] = useState(false);
    const [userId, setUserId] = useState([]);



    const handleshow = (userId) => {
        UserGetData(userId).then((res) => {
            if (res.data._response.status === 1) {
                let result = res.data._response.data.subcriberDetails
                setGetAddUser(result)
                setShowAddUser(true)
                setUpdate(true)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })

    }


    const columns = [
        {
            field: "subcriberName", title: "Name", cellStyle: {
                width: "5px"
            }
        },
        {
            field: "subcriberPh1", title: "Contact", cellStyle: {
                width: "10px"
            }
        },
        {
            field: "subcriberEmail", title: "Email", cellStyle: {
                width: "10px"
            }
        },
        {
            field: "isTwoFaenabled", title: "Two Factor Authentication", cellStyle: {
                width: "10px"
            },
            render: (rowData) => (
                <span>{rowData.isTwoFaenabled === 1 ? "ON" : "OFF"}</span>
            )
        },

        {
            field: "active", title: "Status", cellStyle: {
                width: "10px"
            },
            render: (rowData) => (
                <span>{rowData.active === 1 ? "Active" : "Deactive"}</span>
            )
        },
        {
            field: "active/deactive", title: "Active/Deactive", cellStyle: {
                width: "5px"
            },
            render: (rowData) => {
                return <Switch
                    checked={rowData.active === 1 ? true : false}
                    onChange={(e) => handleChange(e, rowData.subcriberId)}
                    value={rowData.subcriberId}
                    style={{
                        position: "absolute",
                        top: "-5px",
                        left: "-15px",
                        width: "70px",
                        height: "45px"
                    }}
                />
            },
        },
        {
            field: "Edit", title: "Edit", cellStyle: {
                width: "10px"
            },
            render: (rowData) => {
                return <Edit fontSize="small"
                    onClick={(e) => handleshow(rowData.userId)}
                    value={rowData.userId}
                    data-dismiss="modal" />
            }
        },

    ]

    useEffect(() => {
        setLoading(true)
        UserListData().then((res) => {
            if (res.status === 200) {
                let userList = res.data._response.data.subcriberDetails;
                setUserData(userList);
                userList.map((a) => {
                    setUserId(a.userId)})
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }, [])


    const AddUsers = () => {
        setShowAddUser(true)
    }
    const handleClose = () => {
        setShowAddUser(false);
        setGetAddUser([]);
        setUpdate(false);
    }
    const handleChange = (event, subcriberId) => {
        setLoading(true)
        const { checked } = event.target
        const activeId = checked === true ? 1 : 0
        UserActiveData(activeId, subcriberId).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    const data = res.data._response.data.subcriberDetails;
                    setUserData(data);

                } else {
                    setChecked(false)
                }
            }
        }).catch((e) => {
            console.log(e, 'err in userMangement')

        })
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4 className="text-center py-3">User Details</h4>
                </Col>
            </Row>

            <Row className="mb-3">

                <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent="flex-end"
                    style={{ marginLeft: "-4rem" }}
                    alignItems="flex-start" mb={1} >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => AddUsers()}
                        style={{ height: "2rem", width: "10rem" }}
                    >
                        Add User
                    </Button>
                </Stack>

            </Row>

            <div className="mt-2 p-5 pt-1  justify-content-center">
                <Paper sx={{ width: '100%', overflow: 'hidden' }} className="UserdetWrap p-3 pb-0">
                    <MaterialTable
                        title=""
                        data={UserData}
                        columns={columns}
                        options={{
                            headerStyle: {
                                backgroundColor: "#01579b",
                                color: "#FFF",
                            },
                            exportButton: false,
                            search: true,
                            toolbarButtonAlignment: "right",
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            actionsColumnIndex: -1,

                        }}
                        isLoading={loading}
                    />
                </Paper>
            </div>
            <AddUser
                show={showAddUser}
                onHide={handleClose}
                userdata={getAddUser}
                userId={userId}
                update={update}
            />
        </Container>
    )
}

export default UserDetails