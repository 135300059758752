import React from "react";
import { Container } from "react-bootstrap";
import UserProfileInfo from '../../components/UserProfileInfo/UserProfileInfo'
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from 'react-redux';

const UserProfile = () =>{
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return( 
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar/>
                <div>
                    <Container className="pt-3">
                        <UserProfileInfo />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;