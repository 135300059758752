/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TableCell, TableContainer, IconButton, Table, Paper, TableHead, TableRow, TableBody, TableFooter
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility, Delete } from "@material-ui/icons";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import { Stack } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from "@mui/icons-material/Add";
import $ from "jquery";
import moment from "moment";
import Swal from 'sweetalert2';
import _ from "lodash";
import Loader from "../loadingAnimation/index";
import Auth from '../../modules/Auth'
import { EntityList } from '../../pages/Stocks/StocksAPI'
import Loans_DepDetails from '../LoansDepositsDetalis/LoansDepDetails';
import LoansDepoPreview from '../LoansDepositsDetalis/LoansDepoPreview';
import { FetchLoanData, EntityWiseLoanList, GetBuySellLoanGrid, GetLoanTransaction, GetBuySellGridLoanEntity, deleteLoanTrans } from '../../pages/Loans_Deposits/Loans_DepoAPI'

import SearchBar from "material-ui-search-bar";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

function Loans_DepoFolio() {
  const [loading, setLoading] = useState(false);
  const [showLoan, setShowLoan] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [entityId, setEntityId] = useState(null);
  const [script, setScript] = useState("All");
  const [LoanHeaderId, setLoanHeaderId] = useState(0);
  const [LoanTransaction, setLoanTransaction] = useState({});
  const [previewLoanopen, setpreviewLoanopen] = useState(false);
  const [instTYID, setinstTYID] = useState([])
  const [showInner, setShowInner] = useState(false);
  const [sell, setSell] = useState([]);
  const [entitySell, setEntitySell] = useState([]);
  const [subId, setSubId] = useState(null);
  const [subName, setSubName] = useState("");
  const [subNameEnt, setSubNameEnt] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [entityData, setEntityData] = useState([])
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [depositToId, setDepositToId] = useState(null);
  const [depositToName, setDepositToName] = useState('');


  const [iconsTest, setIconTest] = useState(AddIcon);

  const userID = Auth.getsubcriberId();

  const [searched, setSearched] = useState("");
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state) {
      let subAccountId = state.subAccountId;
      let instrumentTypeId = state.instrumentTypeId;
      let depositToId = state.depositToId;
      let depositToName = state.depositToName;
      let entityName = state.entityName;
      setSubNameEnt(entityName)
      getBuyGetData(subAccountId, instrumentTypeId, depositToId, depositToName, entityName);
    }
  }, [state])

  useEffect(() => {

    setLoading(true);
    getLoansData()

    if (userID) {
      getEntityList()
    }
  }, [userID]);

  const columns = [
    { title: "Type of Investment", field: "typeOfInvestment" },

    {
      title: "Amount Invested",
      field: "amtInvested",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.amtInvested)}
        </span>
      ),
    },

    {
      title: " Outstanding Interest Amount",
      field: "interestAmt",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.interestAmt)}
        </span>
      ),
    },
    {
      title: "Outstanding Amount",
      field: "amtOutstanding",
      type: "numeric",
      render: (rowData) => (
        <span>

          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.amtOutstanding)}
        </span>
      ),
    },

  ];

  //all Loans family data
  const getLoansData = () => {
    FetchLoanData().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          let Loans = res.data._response.data.loanFamilyOuterGrid;

          //for hide all paid user data from outer grid
          let allPaidLoans = Loans.filter(a => a.amtOutstanding !== 0)

          setLoanData(allPaidLoans);
          GetGridData(allPaidLoans);
          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });
        } else {
          setFilteredData([])
          setLoanData([]);
          console.log('errror in getLoansData api', res.data._response.message);
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  // all fixed income entity wise data 
  const getLoanEntityData = async (entityId) => {

    await EntityWiseLoanList(entityId)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res, 'entity data');
          if (res.data._response.status === 1) {
            //+ button code
            $(".MuiTableRow-root").each(function () {
              let val = $('.material-icons ', this).text()
              if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
            });

            let EntityStock = res.data._response.data.loanIndividualInnerGrid;

            //for hide all paid user data from outer grid
            let allPaidLoans = EntityStock.filter(a => a.amtOutstanding !== 0)

            setEntityData(allPaidLoans)
            setLoading(false)

            GetEntityGridData(allPaidLoans)

            setScript("All")
            setSearched("")
          } else {
            setFilteredEntiryData([]);
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log("error");
        setLoading(false)
      });
  }
  //getEntityList
  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            setEntityRadio(entity);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //handle snapshot
  const handleView = (selectedRow, a) => {
    //  console.log(a,"liste");
    GetLoanTransaction(a.loanId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setLoanHeaderId(a.loanId);
          const result = res.data._response.data.loanDepositTransactionDetails;
          setLoanTransaction(result);
          setpreviewLoanopen(true)
        }
      }
    });

  }

  const handleDelete = (selectedRow, a) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      // dangerMode: true,
    })
      .then((value) => {
        if (value.isConfirmed) {
          // setLoading(true)
          deleteLoanTrans(a.loanId).then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                setLoading(false)
                Swal.fire({
                  title: 'Deleted!',
                  text: res.data._response.message,
                  icon: 'success',
                });
                const del = sell.filter(sells => a.loanId !== sells.loanId)
                setSell(del)
                const delEntity = entitySell.filter(Esells => a.loanId !== Esells.loanId)
                setEntitySell(delEntity)
                handleCloseInner();
              }

            } else {
              setLoading(false)
              Swal.fire({
                title: "Ooops!!",
                text: "Something Wrong",
                icon: "warning",
              })
            }
          })
            .catch(err => {
              console.log(err);
              setLoading(false)
            })
        } else {
          Swal.fire("Cancelled", "Your  data is safe ", "error");
        }
      });
  }


  const onRefreshLoan = () => {

    getLoansData();
    // getLoanEntityData(subId)
    if (entityId) getLoanEntityData(entityId);
    setLoading(true);
  }

  const onTransactionGridRefresh = () => {
    getBuyGetData(
      subId,
      instTYID,
      depositToId,
      depositToName,
      subName
    );
  }
  //open LoanDetils Modal
  const openTransaction = async () => {
    // await setEntity('Family');
    setShowLoan(true)
  };

  // buysell Inner GRID
  const getBuyGetData = async (
    subAccountId,
    instrumentTypeId,
    depositToId,
    depositToName,
    entityName,
    index
  ) => {

    // console.log(entityName,"buysell");
    setSubId(subAccountId);
    setDepositToId(depositToId);
    setDepositToName(depositToName);
    setinstTYID(instrumentTypeId);
    setSubName(entityName)
    setLoading(true);
    // family
    if (subAccountId && instrumentTypeId && state === null) {
      await GetBuySellLoanGrid(subAccountId, instrumentTypeId)
        .then((res) => {
          setLoading(false);
          if (res.data._response.status === 1) {
            let data = res.data._response.data.fetchLoansDepositsEntityInfo;

            setSell(data);
            setShowInner(true);
          } else {
            setSell([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      // entity
      if (instrumentTypeId && depositToId) {
        await GetBuySellGridLoanEntity(instrumentTypeId, depositToId)
          .then(async (res) => {
            if (res.status === 200) {
              setLoading(false);
              if (res.data._response.status === 1) {
                let data = res.data._response.data.fetchLoansDepositsIndividualEntityInfo;
                setEntitySell(data);
                setShowInner(true);
              } else {
                setLoading(false);
                setEntitySell([]);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          })
      }
    }
    setLoading(false);

  };
  //refresh form data
  const refreshForm = (a) => {
    if (a !== undefined || a !== null) {
      GetLoanTransaction(a).then((res) => {
        // debugger
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            //  setShowBorrowings(true);
            setLoanHeaderId(a);
            const result = res.data._response.data.loanDepositTransactionDetails;
            setLoanTransaction(result);
          }
        }
      }).catch(e => {
        console.log(e, 'error in alertMyRow function');
        setLoading(false)
      });
    }
  }
  //open transactions
  const alertMyRow = (selectedRow, a) => {
    // console.log(a,"id");
    setLoading(true)
    GetLoanTransaction(a.loanId).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        if (res.data._response.status === 1) {
          setLoanHeaderId(a.loanId);
          const result = res.data._response.data.loanDepositTransactionDetails;
          setLoanTransaction(result);
          setShowLoan(true);
        }
      }
    }).catch(e => {
      console.log(e, 'error in alerMyRow function');
      setLoading(false)
    });
  };

  // + button code
  const PlusButton = () => {
    $(".MuiTableRow-root").each(function () {
      let val = $('.material-icons ', this).text()
      if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
    });
  }
  //filter stocks
  const handleScripData = (e) => {
    let selected = e.target.value;
    setScript(selected)
    setSearched("")
    // family data filter
    if (entity === "Family") {
      let sorted = selected === "All" ? loanData : loanData.filter((a) => a.instrumentTypeId === Number(selected))

      let check = [];
      sorted.forEach((item, i) => {
        if (check.indexOf(item.typeOfInvestment) === -1) {
          check.push(item.typeOfInvestment);
        }
      });
      let parentData = [];
      let childData = [];
      check.forEach((checkdata, index) => {
        let mainData = {
          amtInvested: 0,
          interestAmt: 0,
          amtOutstanding: 0,
          name: "",
          closingBalance: 0,
        };
        sorted.forEach((item, i) => {
          if (checkdata === item.typeOfInvestment) {
            let closingBalance = mainData.closingBalance + item.closingBalance;
            let currentValue = mainData.currentValue + item.currentValue;
            let amtInvested = mainData.amtInvested + item.amtInvested;
            let interestAmt = mainData.interestAmt + item.interestAmt;
            let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

            mainData = {
              typeOfInvestment: item.typeOfInvestment,
              closingBalance: closingBalance,
              currentValue: currentValue,
              id: index,
              amtInvested: amtInvested,
              interestAmt,
              amtOutstanding
            };

            let normalData = {
              ...item,
              typeOfInvestment: item.subAccountName,
              parentId: index,
              id: index + 1,
              company: item.subAccountName,
            };
            childData.push(normalData);
          }
        });
        parentData.push(mainData);

        PlusButton()
      });
      setFilteredData([...parentData, ...childData]);
    } else {
      //entity data  filter
      let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.instrumentTypeId === Number(selected)))
      let checkEntity = [];
      sortedEntity.forEach((item, i) => {
        if (checkEntity.indexOf(item.typeOfInvestment) === -1) {
          checkEntity.push(item.typeOfInvestment);
        }
      });
      let parentEntityData = [];
      let childEntityData = [];
      checkEntity.forEach((checkdata, index) => {
        let mainData = {
          amtInvested: 0,
          interestAmt: 0,
          quantity: 0,
          amtOutstanding: 0,
          name: "",
          closingBalance: 0,
          currentValue: 0
        };
        sortedEntity.forEach((item, i) => {
          if (checkdata === item.typeOfInvestment) {
            let quantity = mainData.quantity + item.quantity;
            let closingBalance = mainData.closingBalance + item.closingBalance;
            let currentValue = mainData.currentValue + item.currentValue;
            let amtInvested = mainData.amtInvested + item.amtInvested;
            let interestAmt = mainData.interestAmt + item.interestAmt;
            let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

            mainData = {
              quantity: quantity,
              typeOfInvestment: item.typeOfInvestment,
              closingBalance: closingBalance,
              currentValue: currentValue,
              id: index,
              amtInvested: amtInvested,
              interestAmt,
              amtOutstanding
            };

            let normalData = {
              ...item,
              typeOfInvestment: item.depositWithLoanTo,
              parentId: index,
              id: index + 1,
              company: item.typeOfInvestment,
            };
            childEntityData.push(normalData);
          }
        });
        parentEntityData.push(mainData);
      });
      setFilteredEntiryData([...parentEntityData, ...childEntityData]);
      PlusButton()
    }
  }

  //details pannel icon parent child
  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };

  const handleReset = () => {
    setDepositToId(null);
    setDepositToName(null);
    setSubId(null);
  }
  //modal close
  const handleClose = async () => {
    setShowLoan(false)
    setLoanTransaction({});
    setLoanHeaderId(0);

    // await setEntity('Family');
    // await setEntityId(null);
    await onRefreshLoan();
    // onTransactionGridRefresh();
  }

  const handleClosePreview = () => {
    setpreviewLoanopen(false)
  }
  const handleCloseInner = async () => {
    // await setEntity('Family');
    await onRefreshLoan();
    setSubName("");
    // setSubNameEnt("")
    handleReset();
    setShowInner(false);
  }

  //filter family grid
  const GetGridData = (LoansIncome) => {
    let check = [];
    LoansIncome.forEach((item, i) => {
      if (check.indexOf(item.typeOfInvestment) === -1) {
        check.push(item.typeOfInvestment);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amtInvested: 0,
        interestAmt: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(LoansIncome);
      LoansIncome.forEach((item, i) => {
        if (checkdata === item.typeOfInvestment) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;
          let amtInvested = mainData.amtInvested + item.amtInvested;
          let interestAmt = mainData.interestAmt + item.interestAmt;
          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfInvestment: item.typeOfInvestment,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amtInvested: amtInvested,
            interestAmt,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfInvestment: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,

          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);
  };

  //filter entity grid
  const GetEntityGridData = (EntityStock) => {

    let check = [];
    EntityStock.forEach((item, i) => {
      if (check.indexOf(item.typeOfInvestment) === -1) {
        check.push(item.typeOfInvestment);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amtInvested: 0,
        interestAmt: 0,
        quantity: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };

      EntityStock.forEach((item, i) => {
        if (checkdata === item.typeOfInvestment) {
          let quantity = mainData.quantity + item.quantity;
          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;
          let amtInvested = mainData.amtInvested + item.amtInvested;
          let interestAmt = mainData.interestAmt + item.interestAmt;
          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            quantity: quantity,
            typeOfInvestment: item.typeOfInvestment,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amtInvested: amtInvested,
            interestAmt,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfInvestment: item.depositWithLoanToName,
            parentId: index,
            id: index + 1,
            company: item.typeOfInvestment,
          };

          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredEntiryData([...parentData, ...childData]);
  };

  //entity chnaged
  const handleChangeEntity = async (e) => {
    setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      setEntityId(null);
      getLoansData();
      setScript("All");

      //+ button code
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        let button = $("button", this);
        $(button).html("<span class='material-icons'>add</span>");
      });
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName == e.target.value;
      });
      await setSubNameEnt(e.target.value);
      await setEntityId(data.subAccountId);
      await getLoanEntityData(data.subAccountId)
    }
    // console.log({ subAccountName: e.target.value }, "handle change");
  };

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // search function
  const requestSearch = (searchedVal) => {

    setSearched(searchedVal)
    const filteredRows = loanData.filter((row) => {
      return row.typeOfInvestment.includes(searchedVal.toUpperCase()) || row.typeOfInvestment.includes(searchedVal.toLowerCase()) || row.typeOfInvestment.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.typeOfInvestment) === -1) {
        check.push(item.typeOfInvestment);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amtInvested: 0,
        interestAmt: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(LoansIncome);
      filteredRows.forEach((item, i) => {
        if (checkdata === item.typeOfInvestment) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;
          let amtInvested = mainData.amtInvested + item.amtInvested;
          let interestAmt = mainData.interestAmt + item.interestAmt;
          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfInvestment: item.typeOfInvestment,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amtInvested: amtInvested,
            interestAmt,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfInvestment: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,

          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    let checkEntity = [];
    forentity.forEach((item, i) => {
      if (checkEntity.indexOf(item.typeOfInvestment) === -1) {
        checkEntity.push(item.typeOfInvestment);
      }
    });
    let parentDataEntity = [];
    let childDataEntity = [];
    checkEntity.forEach((checkdata, index) => {
      let mainData = {
        amtInvested: 0,
        interestAmt: 0,
        quantity: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };

      forentity.forEach((item, i) => {
        if (checkdata === item.typeOfInvestment) {
          let quantity = mainData.quantity + item.quantity;
          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;
          let amtInvested = mainData.amtInvested + item.amtInvested;
          let interestAmt = mainData.interestAmt + item.interestAmt;
          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            quantity: quantity,
            typeOfInvestment: item.typeOfInvestment,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amtInvested: amtInvested,
            interestAmt,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfInvestment: item.depositWithLoanToName,
            parentId: index,
            id: index + 1,
            company: item.typeOfInvestment,
          };

          childDataEntity.push(normalData);
        }
      });
      parentDataEntity.push(mainData);
    });
    setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      getLoanEntityData(entityId)
    } else {
      getLoansData()
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center py-3">Loans Given and Deposits Folio</h4>
        </Col>
      </Row>
      <FormControl className="mt-4 ">

        <RadioGroup
          row
          color="primary"
          defaultValue="start"
          aria-label="Entity"
          name="row-radio-buttons-group"
          value={entity}
          onChange={handleChangeEntity}
          style={{ marginTop: "-3rem" }}


        >
          <FormControlLabel
            value="Family"
            control={<Radio />}
            label="Family"
          />
          {entityRadio.map((a) => (
            <FormControlLabel
              key={a.subAccountName}
              value={a.subAccountName}
              control={<Radio />}
              label={a.subAccountName}
              disabled={a.active === 0 ? true : false}
            />
          ))}

        </RadioGroup>
      </FormControl>
      <Row >
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="flex-end"
          alignItems="flex-start" mb={3} >
          {entityId == null ? null :
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openTransaction()}
                style={{ height: "2rem" }}
              >
                Transactions
              </Button>
            </>

          }


          {/* <Button variant="contained" color="primary" style={{ height: "2rem" }}>
                        Upload Contract Note
                    </Button> */}

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={script}
            label="Type"
            onChange={handleScripData}
            style={{ minWidth: 120, paddingLeft: "12px" }}

          >
            <MenuItem disabled >
              <em>Loans Type</em>
            </MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"33"}>Loans and Advances to related parties</MenuItem>
            <MenuItem value={"34"}>Security Deposits / Balances with Government Authorities</MenuItem>
            <MenuItem value={"35"}>
              Other Loans and Advances
            </MenuItem>

          </Select>
        </Stack>
      </Row>
      <Row>
        <Box sx={{ flexGrow: 1 }} mb={2}>
          <Grid container spacing={2} columns={16}
            direction="row"
            justifyContent="end"
            alignItems="flex-end">
            <Grid item xs="auto">
              <SearchBar
                value={searched}
                onChange={(searchVal) => { requestSearch(searchVal) }}
                onCancelSearch={() => cancelSearch()}
                style={{
                  // margin: "0 auto",
                  maxWidth: 250,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Row>
      <Row>
        <Col md="12">
          <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">
            {/* {loading ? (
                            <Loader />
                        ) : (
                            <> */}
            <MaterialTable
              title=""
              data={
                entity === "Family" ? filteredData : filteredEntiryData
              }
              columns={columns}

              parentChildData={(row, rows) =>
                rows.find((a) => a.id === row.parentId)
              }
              isLoading={loading}
              options={{
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                },
                exportButton: false,
                search: false,
                toolbarButtonAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                emptyRowsWhenPaging: false,
                rowStyle: (rowData) => {
                  return {
                    pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                    backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                  };
                },
              }}

              onTreeExpandChange={(row, rows) => {
                handleIcon(row, rows);
              }}
              icons={{
                DetailPanel: iconsTest,
              }}

              onRowClick={(evt, selectedRow) => {
                if (selectedRow.tableData.childRows == null) {
                  // console.log(selectedRow)
                  getBuyGetData(
                    selectedRow.subAccountId,
                    selectedRow.instrumentTypeId,
                    selectedRow.depositWithLoanToId,
                    selectedRow.depositWithLoanToName,
                    selectedRow.company,
                    selectedRow.tableData.path[0]
                  );
                }
              }}

              components={{
                Body: (props) => {

                  let totalData = {
                    investmentCost: 0,
                    outsIntAmt: 0,
                    outsAmt: 0,
                  }

                  props.renderData.forEach((rowData) => {
                    totalData.investmentCost += rowData.amtInvested;
                    totalData.outsIntAmt += rowData.interestAmt;
                    totalData.outsAmt += rowData.amtOutstanding;
                  })

                  return (
                    <>
                      <MTableBody {...props} />
                      {
                        script === "All" ?
                          <TableFooter style={{ backgroundColor: "#D9E5F2 " }} >
                            <TableRow>
                              <TableCell ></TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="left">Total</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.outsIntAmt)}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.outsAmt)}</TableCell>

                            </TableRow>
                          </TableFooter> : null

                      }
                    </>
                  )

                },
                Toolbar: (props) => {
                  let totalData = {
                    investmentCost: 0,
                    outsIntAmt: 0,
                    outsAmt: 0,
                  }
                  props.renderData.forEach((rowData) => {
                    totalData.investmentCost += rowData.amtInvested;
                    totalData.outsIntAmt += rowData.interestAmt;
                    totalData.outsAmt += rowData.amtOutstanding;
                  })
                  return (

                    <>
                      <MTableToolbar {...props} />
                      {
                        script !== "All" || script === "All" ?

                          <div className="row mb-2 p-2">
                            <div className="col-md-2 col-xl-3">
                            </div>
                            <div className="col-md-2 col-xl-2">
                              <div className="card  order-card">
                                <div className="card-block">
                                  <h3 className="text-right"><span style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</span></h3>
                                  <p className="m-b-0"> Invested Amount</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.outsIntAmt < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.outsIntAmt)}</span></h3>
                                  <p className="m-b-0" >Outstanding Interest Amount</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.outsAmt < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.outsAmt)}</span></h3>
                                  <p className="m-b-0" >Outstanding Amount</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          : null}

                    </>
                  )

                },

              }}

            />
            {/* </>
                        )} */}
          </div>

          <Loans_DepDetails
            showLoan={showLoan}
            onHideLoan={handleClose}
            LoanTransaction={LoanTransaction}
            LoanHeaderId={LoanHeaderId}
            entityId={entityId}
            // showLoanpreview={previewLoanopen}
            onRefreshLoan={onRefreshLoan}
            onRefreshForm={(p) => refreshForm(p)}
            onRefreshInnerGrid={onTransactionGridRefresh}
          />
          <LoansDepoPreview showLoanPreview={previewLoanopen} onHideLoanPreview={handleClosePreview} LoanTransactionPreview={LoanTransaction} />
          <Container
            className="LoginContainer formWraper"
            component="main"
            maxWidth="xs"
            tabIndex={-1}
          >
            <Modal
              isOpen={showInner}
              toggle={() => handleCloseInner()}
              size="xl"
              className="col-12 stockdetailmodal"
            >
              <ModalHeader charCode="Y" toggle={() => handleCloseInner()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                <h5 className="text-center">Loans Given and Deposits Details</h5>
              </ModalHeader>
              <ModalBody>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  {loading ? (
                    <Loader />
                  ) : null}

                  {subId && state === null ?
                    /* family wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subName}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Name of the Deposit
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Date of Invested
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Additional Amount
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Repaid
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Outstanding Interest Amount
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Outstanding Amount
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Maturity Date
                            </TableCell>

                            <TableCell align="center" width="7%" style={{ textAlign: "center" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {sell.map((a) => (
                            <TableRow
                              key={a.loanId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.depositWithLoanToName}
                              </TableCell>
                              <TableCell align="right">
                                {moment(a.depositDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell align="right">
                                {a.firstAmtInvested === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.firstAmtInvested)}
                              </TableCell>
                              <TableCell align="right">
                                {a.depositAmt === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.depositAmt)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.repay === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.repay)}
                              </TableCell>
                              <TableCell align="right">
                                {a.interestAmt === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.interestAmt)}
                              </TableCell>
                              <TableCell align="right">
                                {a.amtOutstanding === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amtOutstanding)}
                              </TableCell>

                              <TableCell align="right">
                                {a.maturityDate ? moment(a.maturityDate).format("DD/MM/YYYY") : "-"}
                              </TableCell>


                              <TableCell align="center">
                              <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                                            
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer> :

                    /* entity wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subNameEnt}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Name of the Deposit
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Date of Invested
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Additional Amount
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Repaid
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Outstanding Interest Amount
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Outstanding Amount
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Maturity Date
                            </TableCell>

                            <TableCell align="center" width="7%">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {entitySell.map((a) => (
                            <TableRow
                              key={a.loanId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.depositWithLoanToName}
                              </TableCell>
                              {/* <TableCell align="left">
                                {a.typeOfInvestment}
                              </TableCell> */}
                              <TableCell align="right">
                                {moment(a.depositDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell align="right">
                                {a.firstAmtInvested === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.firstAmtInvested)}
                              </TableCell>
                              <TableCell align="right">
                                {a.depositAmt === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.depositAmt)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.repay === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.repay)}
                              </TableCell>
                              <TableCell align="right">
                                {a.interestAmt === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.interestAmt)}
                              </TableCell>
                              <TableCell align="right">
                                {a.amtOutstanding === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amtOutstanding)}
                              </TableCell>

                              <TableCell align="right">
                                {a.maturityDate ? moment(a.maturityDate).format("DD/MM/YYYY") : "-"}
                              </TableCell>

                              <TableCell align="center">
                              <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip> 

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                
                              </TableCell>

                            </TableRow>
                          ))}


                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >


                          </TableRow>


                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Paper>
              </ModalBody>
            </Modal>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default Loans_DepoFolio