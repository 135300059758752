import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import Auth from '../../modules/Auth/index';

const AfterLoginRoute = () => {
	const token = Auth.getToken();
	const userRole = Auth.getUserRoleID();

	return token && userRole ? <Outlet /> : <Navigate to="/Login" />;
};

export default AfterLoginRoute;
