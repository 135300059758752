/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from '../../loadingAnimation/index'
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

import { EntityList } from "../../../pages/Stocks/StocksAPI";

import { addMutualFundSplit, updateMutualFundSplit, GetCompanyAndBroker, GetMutualFundSellCompanies, AvailableMFSellQuantity, GetMutualFundFolioNo } from '../../../pages/MutualFund/MutualFundAPI'
const MFSplitmodal = (props) => {

  const { showS, onHideS, mutualFTransactionS, mutualFIdS, showsplitPreview } = props;

  const handleClose = () => {
    onHideS();
    handleReset();
  }

  const [companyList, setCompanyList] = useState([]);
  const [brokerData, setBrokerList] = useState([]);
  const [broker, setbroker] = useState([]);
  const [selScrip, setSelScrip] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [entityValue, setEntityValue] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [BQtySplit, setBQtySplit] = useState(0);
  const [AQtySplit, setAQtySplit] = useState({ quantity: '' });
  const [selfolioNumber, setSelFolioNumber] = useState([]);
  const [folioNumber, setFolioNumber] = useState([]);
  const [formErr, setFormErr] = useState({});
  const [loading, setLoading] = useState(false);
  const [buyDate, setBuyDate] = useState(null)
  const [ratio, setRatio] = useState({ ratio: "" });
  const [TypeList, setTypeList] = useState([{
    label: 'SIP', value: 41
  }, { label: 'Lumpsum', value: 42 }]);
  const [selType, setSelType] = useState([]);

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEffect(() => {
    if (showS) {
      (async () => {
        if (mutualFIdS === 0 || mutualFIdS === undefined) {
          await getEntityListt();
        }
      })();
    }
  }, [showS]);

  useEffect(() => {
    if (showS) {
      (async () => {
        await getCompanyBrokerDropDownData();
      })();
    }
  }, [selEntity])

  // get companies list
  useEffect(() => {
    if (showS) {
      if (mutualFIdS === 0 || mutualFIdS === undefined) {
        if (selEntity.value && selBroker.value && selType.value) {
          getCompanyData(selEntity.value, selBroker.value, selType.value)
        }
      }
    }

  }, [selEntity, selBroker, selType, showS]);
  // edit bonus data
  useEffect(() => {
    if (mutualFIdS !== 0 && mutualFIdS !== undefined) {

      if (Object.keys(mutualFTransactionS).length !== 0) {

        setSelBroker({
          value: mutualFTransactionS.brokerId,
          label: mutualFTransactionS.broker.brokerName,
        });
        setbroker(mutualFTransactionS.brokerId);

        setSelEntity({
          value: mutualFTransactionS.subAccountId,
          label: mutualFTransactionS.subAccount.subAccountName,
        });

        setEntityValue(mutualFTransactionS.subAccountId);

        mutualFTransactionS.mutualFundDetails && mutualFTransactionS.mutualFundDetails.map((t) => {
          setSelFolioNumber({
            value: t.certificateFolioNo,
            label: t.certificateFolioNo,
          })
          setBQtySplit(t.balanceUnit);
          setAQtySplit({ quantity: t.units });
          setPDate(new Date(t.dateOfInvestment));
          setSelScrip(t.mutualFundCompany)
          setRatio({ ratio: t.ratio })
          t.instrumentTypeId === 41 ? setSelType({ value: 41, label: "SIP" }) : setSelType({ value: 42, label: "Lumpsum" })
        })

      }

    }
  }, [mutualFTransactionS])

  // for buy date
  useEffect(() => {
    if (showS) {

      // setPDate(null)
      let companyId = selScrip.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      let InstrTypeId = selType.value;
      let folioNumber = selfolioNumber.value;
      if (companyId && brokerId && subAccountId && InstrTypeId && folioNumber) {
        getBuyDate(subAccountId, companyId, brokerId, InstrTypeId, folioNumber);
      }
    }
  }, [selScrip, selBroker, selEntity, showS, selfolioNumber]);


  //get buy folionumber
  useEffect(() => {
    if (showS) {
      let companyId = selScrip.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      let InstrTypeId = selType.value;
      if (companyId && brokerId && subAccountId && InstrTypeId) {
        getFolioNumberDropDownData(subAccountId, companyId, InstrTypeId, brokerId)
      }

    }
  }, [selScrip, selBroker, selEntity, showS])

  //getfolionumber
  const getFolioNumberDropDownData = async () => {
    let subAccountId = selEntity.value;
    let brokerId = selBroker.value;
    let InstTypeID = selType.value;
    let companyId = selScrip.value;
    if (subAccountId !== undefined && companyId !== undefined && brokerId !== undefined && InstTypeID !== undefined) {
      setLoading(true);
      await GetMutualFundFolioNo(subAccountId, companyId, InstTypeID, brokerId)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              let data = res.data._response.data.folioNumbers;
              let arr = [];
              data && data.map((d, i) => {
                arr.push({ label: d, value: d });
              })
              setFolioNumber(arr);
            } else {
              setFolioNumber([]);
            }
          }

        }).catch(e => {
          console.log(e, 'error in getFolioNo Api');
          setFolioNumber([]);
          setLoading(false);
        })
    }
  }


  // get dropDown Company Data
  const getCompanyBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await GetCompanyAndBroker(sub_Id)
        .then((res) => {
          if (res.data._response.status === 1) {

            // broker
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setBrokerList(brokersData);
            setLoading(false);
          } else {
            setBrokerList([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setBrokerList([]);
          setLoading(false);
        });
    }
  };

  // get dropDown Company Data
  const getCompanyData = async () => {
    let sub_Id = selEntity.value;
    let Bro_Id = selBroker.value;
    let InstTypeID = selType.value;
    if (sub_Id !== undefined && Bro_Id !== undefined && InstTypeID !== undefined) {
      setLoading(true);
      await GetMutualFundSellCompanies(sub_Id, Bro_Id, InstTypeID)
        .then((res) => {
          if (res.data._response.status === 1) {
            // company
            let Company = [];
            let company = res.data._response.data.fetchCompanyBySub;
            company && company.map((i) => {
              return (Company.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId }))
            })

            setCompanyList(Company);
            setLoading(false);
          } else {
            setCompanyList([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
        });
    }
  };


  //getEntityList
  const getEntityListt = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {

        if (res.status === 200) {
          setLoading(false);
          //
          let entity = res.data._response.data.subaccountDetails;
          let entityData = [];
          entity &&
            entity.map((data) => {
              return (entityData.push({
                value: data.subAccountId,
                label: data.subAccountName,
                disabled: data.active
              }))
            });
          setEntity(entityData);
          setLoading(false)

        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });


  };

  const handleScrip = (selected) => {
    setSelScrip(selected);
    setSelFolioNumber([])
    setFormErr({ ...formErr, selScrip: "" });
    setBQtySplit(0);
  };

  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityValue(selected.value)
    setSelScrip([]);
    setSelBroker([]);
    setSelFolioNumber([])
    setFormErr({ ...formErr, selEntity: "" });
  };

  const handleBroker = (selected) => {
    setSelBroker(selected);
    setbroker(selected.value)
    setSelScrip([])
    setSelFolioNumber([])
    setFormErr({ ...formErr, selBroker: "" });
    setBQtySplit(0);
  };

  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: "" });
  };

  const handleQtySplit = (e) => {
    const { name, rawValue } = e.target;
    setAQtySplit({ ...AQtySplit, [name]: rawValue });
    setFormErr({ ...formErr, quantity: "" });
  };
  const handleFolioNumber = (selected) => {
    setSelFolioNumber(selected);
    setFormErr({ ...formErr, selfolioNumber: "" });
    setBQtySplit(0);
  };

  const handleRatio = (e) => {
    const { name, value } = e.target;
    setRatio({ ...ratio, [name]: value.replace(/[^0-9:]/ig, '') });
    setFormErr({ ...formErr, ratio: '' })
  }

  const errHandel = () => {
    let formErr = {};
    let isValid = true;

    if (selType.length < 1) {
      isValid = false;
      formErr["selType"] = "Please Select Type";
    }
    if (selScrip.length < 1) {
      isValid = false;
      formErr["selScrip"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }
    if (selfolioNumber.length < 1) {
      isValid = false;
      formErr["selfolioNumber"] = "Please Select Folio Number!";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }
    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }
    if (!AQtySplit.quantity || AQtySplit.quantity === 0) {
      isValid = false;
      formErr["AQtySplit"] = "Please Fill This Field!";
    }
    if (AQtySplit.quantity) {
      if (AQtySplit.quantity < 0) {
        isValid = false;
        formErr["AQtySplit"] = "Please Enter Positive Value !";
      }
    }
    // if (AQtySplit.quantity) {
    //   let qvalue = parseFloat(AQtySplit.quantity); 
    //   if (Number.isInteger(qvalue) == false) {
    //     isValid = false;
    //     formErr["AQtySplit"] = "Please enter only integer value!";
    //   }
    // }
    // if (!ratio) {
    //   isValid = false;
    //   formErr["ratio"] = "Please enter ratio !";
    // }
    setFormErr(formErr);
    return isValid;
  };

  // const getStockDetail = async (brokerId, subAccountId, companyId) => {
  //   setLoading(true)
  //   await AvilableQuantity(brokerId, subAccountId, companyId)
  //     .then((res) => {
  //       if (res.data._response.status === 1) {
  //         setBQtySplit(res.data._response.data.remainingQuantity);
  //         setLoading(false)
  //       } else {
  //         setLoading(false)
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(false)
  //     });
  // };

  const getBuyDate = async (sub_id, co_id, bro_id, InstTypeID, folioNumber) => {
    setLoading(true);
    await AvailableMFSellQuantity(sub_id, co_id, bro_id, InstTypeID, folioNumber)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false);

            setBuyDate(res.data._response.data.minimum_Date);
            if (mutualFIdS === 0 || mutualFIdS === undefined) setBQtySplit(res.data._response.data.available_Unit)

          }
          else {
            setLoading(false);
          }

        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("error in buy date split ", e);
      });
  };

  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true)
      let data = {
        subAccountId: selEntity.value,
        brokerId: selBroker.value,
        date: fixTimezoneOffset(pDate),
        instrumentTypeId: selType.value,
        instrumentId: 8,
        certificateFolioNo: selfolioNumber.value,
        mutualFundCompanyId: selScrip.value,
        units: Number(AQtySplit.quantity),
        ratio: ratio.ratio,
        transactionType: 'Split',
        stockValuationId: 4
      };

      await addMutualFundSplit(data)
        .then((res) => {

          setLoading(false);
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              Swal.fire("Added successfully!", "", "success");
              handleReset();
              setAQtySplit({ ...AQtySplit, quantity: 0 });
            }
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, 'err in add split form');
        });
    }
  };

  const handleUpdate = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        mutualFundId: mutualFIdS,
        subAccountId: selEntity.value,
        brokerId: selBroker.value,
        date: fixTimezoneOffset(pDate),
        instrumentTypeId: selType.value,
        certificateFolioNo: selfolioNumber.value,
        instrumentId: 8,
        mutualFundCompanyId: selScrip.value,
        units: Number(AQtySplit.quantity),
        ratio: ratio.ratio,
        transactionType: 'Split'

      };

      updateMutualFundSplit(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              Swal.fire("Updated successfully!", "", "success");
              handleClose();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  const handleReset = () => {
    setCompanyList([]);
    setSelScrip([]);
    setSelBroker([]);
    setBrokerList([]);
    setSelFolioNumber([]);
    setFolioNumber([])
    setSelEntity([]);
    setBQtySplit(0);
    setAQtySplit({ quantity: 0 });
    setPDate(null);
    setFormErr({});
    setRatio({ ratio: "" });
    setSelType([]);
  };

  const disableButton = () => {
    return selEntity && selScrip && selEntity && selBroker && pDate && AQtySplit

  }

  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  return (
    <>
      <Modal show={showS} onHide={handleClose} size="lg" centered>
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto"><h5>Mutual Funds - Split</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
          <div className="mt-2 p-6">
            <Form className="border border-1 rounded p-3" >
              <Row className="form-set">
                <Form.Group className="col-md-4">
                  <Form.Label>Type<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selType}
                    onChange={(sel) => setSelType(sel)}
                    options={TypeList}
                    name="InstrumentTypeID"
                    isDisabled={mutualFIdS}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.selType}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selEntity}
                    onChange={handleFamily}
                    options={Entity}
                    name="subAccountId"
                    isOptionDisabled={(option) => option.disabled === 0}
                    isDisabled={mutualFIdS}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.selEntity}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-4">
                  <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selBroker}
                    onChange={handleBroker}
                    options={brokerData}
                    name="brokerId"
                    isDisabled={mutualFIdS}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.selBroker}
                    </div>
                  ) : null}
                </Form.Group>



              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-4">
                  <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selScrip}
                    onChange={handleScrip}
                    options={companyList}
                    name="scrip"
                    isOptionDisabled={handelDisableDD}
                    isDisabled={mutualFIdS}
                    styles={{
                      option: (data) => ({
                        ...data,
                        margin: 0,
                        padding: '3px 12px',
                      }),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: '#000',
                        margin: 0,
                        padding: '7px 2px',
                        // border: `2px solid `,
                        background: "#8bc3ff",
                        fontSize: 'small',
                        fontWeight: 500
                      }),
                    }}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.selScrip}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Folio Number<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selfolioNumber}
                    onChange={handleFolioNumber}
                    options={folioNumber}
                    name="folioNumber"
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.selfolioNumber}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-4">
                  <Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    // maxDate={new Date()}
                    minDate={new Date(buyDate)}
                    onChange={handleDate}
                    dateFormat="dd-MM-yyyy"
                    name="date"
                    autoComplete="off"
                    selected={pDate}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.date}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-4" >
                  <Form.Label> Ratio </Form.Label>
                  <Form.Control type="text" name="ratio" min="0" placeholder="Enter Ratio" onChange={handleRatio} value={ratio.ratio} autoComplete="off" />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Balance quantity before split </Form.Label>
                  {/* <Form.Control
                    type="text"
                    name="QtyBeforeSplit"
                    disabled
                    min="0"
                    value={NumberSeperator(BQtySplit)}
                  /> */}
                  <Cleave
                   type="text"
                    className='form-control'
                    name="QtyBeforeSplit"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralDecimalScale: 4 }}
                    value={BQtySplit === 0  ? "0" :BQtySplit}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Balance quantity after split<span className="text-danger h6">*</span></Form.Label>
                  {/* <Form.Control
                    type="Number"
                    name="quantity"
                    onChange={handleQtySplit}
                    value={AQtySplit.quantity}
                    autoComplete="off"
                    min="0"
                  /> */}
                  <Cleave
                    className='form-control'
                    name="quantity"
                    autoComplete="off"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralDecimalScale: 4 }}
                    onChange={handleQtySplit}
                    value={AQtySplit.quantity === 0  ? "0" :AQtySplit.quantity}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.AQtySplit}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              <Row>
                <Col md="12" className="text-center mt-3">
                  {mutualFIdS === 0 || mutualFIdS === undefined ? (

                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }} disabled={!disableButton()}>
                      Save
                    </Button>
                  ) : (
                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()} >
                      Update
                    </Button>
                  )}
                  {mutualFIdS === 0 || mutualFIdS === undefined ?
                    <Button
                      variant="secondary"
                      className="mx-2 w100"
                      type="reset"
                      onClick={handleReset}
                    >
                      Clear
                    </Button> : null}
                </Col>
              </Row>
            </Form>
          </div>




        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary">
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
      {showsplitPreview === true ?
        <Modal show={showsplitPreview} onHide={() => handleClose()}>
          <Modal.Header closeButton className="headerBlue" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Mutual Fund Split Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                <div className="transactionDataValue">{selScrip.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Folio Number : </div>
                <div className="transactionDataValue">{selfolioNumber.label}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Date : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio.ratio}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Balance quantity before split : </div>
                <div className="transactionDataValue">{BQtySplit ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(BQtySplit) : '-'}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Balance quantity after split : </div>
                <div className="transactionDataValue">{AQtySplit.quantity ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(AQtySplit.quantity) : "-"}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  )
}

export default MFSplitmodal;
