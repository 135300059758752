import Stock from '../../pages/Stocks/Stock';
import MutualFund from '../../pages/MutualFund/MutualFund';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Transaction from '../../pages/RecentTransaction/Transaction';
import SecondDTable from '../Dashboard/SecondDTable';
// import OpeningBalance from "../../pages/Stocks/OpeningBalance";
import OpenBal from '../../pages/OpeningBal/OpenBal';
import Fixedincome from '../../pages/FixedIncome/Fixedincome';
// import Token from '../liveproject/LivePriceLogin';
import LivePriceProject from '../../pages/LiveProject/LivePriceProject';
import IPOAllotedPage from '../../pages/Stocks/OtherTransactions/IPOAllotedPage';
import LoansFolio from '../../pages/Loans_Deposits/LoansDeposits';
import PropertFolio from '../../pages/Property/Property';
import ArtFolio from '../../pages/Art/Art';
import Insurance from '../../pages/Insurance/Insurance';
import Borrowing from '../../pages/Borrowings/Borrowings';
import UserProfile from '../../pages/UserProfileInfo/UserProfile';
import CompanyLivePrice from '../../pages/CompanyLivePrice/CompanyLivePrice';
import Bullion from '../../pages/Bullion/Bullion';
import FuturesFolio from '../FuturesFolio/FuturesFolio';
// import ReportTable from '../Report/ReportTable';

import Userdetail from '../../pages/RoleMasterPages/AdminsPages/Userdetail';
import AccessTable from '../../pages/RoleMasterPages/AdminsPages/AccessTable';
import ContractNote from '../contract_note/ContractNote';
// import EntityBrokerReportTable from '../Report/EntityBrokerReportTable';
import EntityBrokerPage from '../../pages/Report/EntityBrokerPage';
import ThankyouPayment from '../../pages/Subscription/ThankyouPayment';


import CADashboard from '../RoleMaster/CAMaster/CADashboard';

//channel partner routes
import AddChannelPartner from '../../pages/RoleMasterPages/AdminsPages/AddChannelPartner';
// import ChannelDashboard from '../RoleMaster/ChannelMaster/ChannelDashboard';
import ChannelpartnerDashboardPage from '../../pages/RoleMasterPages/ChannelPartnerPages/ChannelpartnerDashboardPage';

// import AddSubscriber from '../../pages/RoleMasterPages/AddSubscriber/AddSubscriber';
import AddSubscriber from '../../pages/RoleMasterPages/ChannelPartnerPages/AddSubscriber';
import NewPlans from '../../pages/Subscription/NewPlans';
import ChannelPartList from '../../pages/RoleMasterPages/AdminsPages/ChannelPartnerList';
import GetSubscriberPage from '../../pages/RoleMasterPages/ChannelPartnerPages/GetSubscriberPage';

// all reports
import ReportTablePage from '../../pages/Report/ReportTablePage';
import ReportCard from '../../pages/Report/Report';
import ReportsForm from '../../pages/Report/Reports';

import EntityBrokerHoldPage from '../../pages/Report/EntityBrokerHoldPage';
import StockProfitabilityReportPage from '../../pages/Report/Profitability/StockProfitabilityReportPage';
import ProfitabilityTablePage from '../../pages/Report/Profitability/ProfitabilityTablePage';
import ProfitabilityFinalTablePage from "../../pages/Report/Profitability/ProfitabilityFinalTablePage";
import HoldingReport from '../../pages/Report/HoldingReport/HoldingReport';

const AllRoutes = [
	// {
	// 	path: '/',
	// 	routeName: 'HomePage',
	// 	component: <LandingPage />
	// },
	{
		path: '/Userdetail',
		routeName: 'User Management',
		component: <Userdetail />
	},
	{
		path: '/Dashboard',
		routeName: 'Dashboard',
		component: <Dashboard />
	},
	{
		path: '/Stock',
		routeName: 'Stocks',
		component: <Stock />
	},
	{
		path: '/MutualFund',
		routeName: 'Mutual Funds',
		component: <MutualFund />
	},
	{
		path: '/Bullion',
		routeName: 'Bullion',
		component: <Bullion />
	},
	{
		path: '#',
		routeName: 'Futures',
		component: <FuturesFolio />
	},
	{
		path: '/fixedincome',
		routeName: 'Fixed Income',
		component: <Fixedincome />
	},
	{
		path: '/Property',
		routeName: 'Property',
		component: <PropertFolio />
	},
	{
		path: '/Art',
		routeName: 'Art',
		component: <ArtFolio />
	},
	{
		path: '/Loans',
		routeName: 'Loans / Deposits',
		component: <LoansFolio />
	},
	{
		path: '/Insurance',
		routeName: 'Insurance',
		component: <Insurance />
	},
	{
		path: '/Borrowings',
		routeName: 'Borrowings',
		component: <Borrowing />
	},
	{
		path: '/RecentTransactions',
		routeName: 'Transactions',
		component: <Transaction />
	},
	{
		path: '/ReportCard',
		routeName: 'Reports',
		component: <ReportCard />
	},
	{
		path: '/openingBalance',
		routeName: 'Opening Balance',
		component: <OpenBal />
	},
	{
		path: '/LivePrice',
		routeName: 'Live Price',
		component: <CompanyLivePrice />
	},
	{
		path: '/SecondTable',
		routeName: 'SecondD Table',
		component: <SecondDTable />
	},
	{
		path: '/AllotedIPOs',
		routeName: 'IPO Table',
		component: <IPOAllotedPage />
	},
	{
		path: '/ContractNote',
		routeName: 'Contract Note',
		component: <ContractNote />
	},
	{
		path: '/ReportTable',
		routeName: 'Report Table',
		component: <ReportTablePage />
	},
	{
		path: '/Reports',
		routeName: 'Reports Form',
		component: <ReportsForm />
	},
	{
		path: '/HoldingReports',
		routeName: 'Holding Reports Form',
		component: <HoldingReport />
	},
	{
		path: '/ReportsTable',
		routeName: 'EntityBroker ReportTable',
		component: <EntityBrokerPage />
	},
	{
		path: '/HoldReportsTables',
		routeName: 'EntityBrokerHolding ReportTable',
		component: <EntityBrokerHoldPage />
	},
	{
		path: '/ProfitabilityTable',
		routeName: 'Profitability ReportTable',
		component: <ProfitabilityTablePage />
	},
	{
		path: '/ProfitabilityfinalTable',
		routeName: 'Profitability Final ReportTable',
		component: <ProfitabilityFinalTablePage />
	},
	{
		path: '/StockProfitReport',
		routeName: 'Stocks Profitabilty Report',
		component: <StockProfitabilityReportPage />
	},
	{
		path: '/profile',
		routeName: 'profile',
		component: <UserProfile />
	},
	{
		path: '/thankyouPayment',
		routeName: 'thankyouPayment',
		component: <ThankyouPayment />
	},

	//adminRoutes
	{
		path: '/token',
		routeName: 'Live Feeds',
		component: <LivePriceProject />
	},

	{
		path: '/AccessTable',
		routeName: 'Access Table',
		component: <AccessTable />
	},
	{
		path: '/AddChannelPartner',
		routeName: 'Add Channel Partner',
		component: <AddChannelPartner />
	},
	{
		path: '/ChannelDashboard',
		routeName: 'CPDashboard',
		component: <ChannelpartnerDashboardPage />
	},
	{
		path: '/addSubscriber',
		routeName: 'AddSubscriber',
		component: <AddSubscriber />
	},
	{
		path: '/subscriberList',
		routeName: 'CPSubscriberList',
		component: <GetSubscriberPage />
	},
	{
		path: '/CADashboard',
		routeName: 'CADashboard',
		component: <CADashboard />
	},

	{
		path: '/channelPartnerList',
		routeName: 'Channel Partner',
		component: <ChannelPartList />
	},
	{
		path: '/addNewPlans',
		routeName: 'Plans',
		component: <NewPlans />
	},
];

export default AllRoutes
