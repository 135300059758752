/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Container, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Dropdown from '../CommonComponent/Dropdown';
import DatePicker from 'react-datepicker';
import Select, { components } from "react-select";
import Swal from "sweetalert2";
// import Tooltip from '@material-ui/core/Tooltip';
import Loader from "../loadingAnimation/index";
import { AddPropertyTransactions, GetPropertyAsset, PostPropertyAsset, FetchAvialabeShares, UpdatePropertyBuyTransac } from '../../pages/Property/PropertyAPI'
import { TypeofInvestPA, Measurement, periodList, AreaMesurementType } from '../../modules/helpers/constant'
import Auth from '../../modules/Auth/index';

import Cleave from 'cleave.js/react';

const Property_Details = (props) => {
    const { showProperty, onHideProperty, entityId, propertyHeaderId, PropertyTransaction } = props;
    const userID = Auth.getsubcriberId();
    const [loading, setLoading] = useState(false);
    const [subAccountId, setSubAccountId] = useState(null);
    const [AssetOption, setAssetOption] = useState([]);
    const [typeofInv, settypeofInv] = useState([]);
    const [assetDesc, setAssetDesc] = useState([]);
    const [location, setLocation] = useState('');
    const [date, setDate] = useState(new Date());
    const [measurement, setMeasurement] = useState([]);
    const [areaInNo, setAreaInNo] = useState('');
    const [area, setArea] = useState([]);
    const [purchasePrice, setPurchasePrice] = useState(null);
    const [brokerage, setBrokerage] = useState(null);
    const [stampDuty, setStampDuty] = useState(null);
    const [gst, setGst] = useState(null);
    const [transferCharges, setTransferCharges] = useState(null);
    const [otherExpenses, setotherExpenses] = useState(null);
    const [parkingCharges, setParkingCharges] = useState(null);
    const [shareInProperty, setShareInProperty] = useState(null);
    const [amtInvest, setAmtInvest] = useState(null);
    const [yourInvest, setYourInvest] = useState(null);
    const [data, setData] = useState();
    const [currMP, setCurrMP] = useState(null);
    const [marketPricePerShare, setMarketPricePerShare] = useState(null)
    const [lockinperiod, setLockinperiod] = useState('');
    const [lockPeriod, setLockPeriod] = useState({ label: "Month", value: "Month" });
    const [lockPeriodValue, setLockPeriodValue] = useState("Month");
    const [narration, setNarration] = useState('')
    const [shareLimit, setShareLimit] = useState('');
    const [freshAsset, setFreshAsset] = useState('');
    const [propertyID, setpropertyID] = useState(0);
    const [assetModal, setAssetModal] = useState(false);
    const [newAssetErr, setNewAssetErr] = useState('');
    const [formErr, setFormErr] = useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    
    // seperation by comma
    const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEffect(() => {
        calculateSharesBuy();
    }, [purchasePrice, brokerage, stampDuty, gst, transferCharges, otherExpenses, parkingCharges, shareInProperty])

    useEffect(() => {
        calculateCurrentMarketPrice()
    }, [currMP, shareInProperty])

    useEffect(() => {
        setSubAccountId(entityId == null ? subAccountId : entityId);
    }, [showProperty]);

    useEffect(() => {
        if (propertyHeaderId !== 0) {
            if (Object.keys(PropertyTransaction).length !== 0) {
                setIsDataReady(true);
                setSubAccountId(PropertyTransaction.subAccountId);
                setpropertyID(PropertyTransaction.propertyId);
                setAmtInvest(PropertyTransaction.amountInvested);
                setYourInvest(PropertyTransaction.amountInvestedPerShare);
                setArea({ value: PropertyTransaction.areaUnit, label: PropertyTransaction.areaUnit });
                setMeasurement({ value: PropertyTransaction.areaTypeUnit, label: PropertyTransaction.areaTypeUnit });
                setAreaInNo(PropertyTransaction.area);
                // setAssetDesc(PropertyTransaction.propertyAsset)
                setBrokerage(PropertyTransaction.brokerage);
                setCurrMP(PropertyTransaction.currentMarketPricePerc);
                setMarketPricePerShare(PropertyTransaction.currentMarketPricePerShare);
                setDate(new Date(PropertyTransaction.dateOfInvestment));
                setGst(PropertyTransaction.gst);
                setLocation(PropertyTransaction.location);
                if (PropertyTransaction.lockInPeriod !== null) {
                    let d = PropertyTransaction.lockInPeriod;
                    let splitData = d.split(/([0-9]+)/);
                    setLockPeriod({ label: splitData[2], value: splitData[2] });
                    setLockinperiod(splitData[1]);
                    setLockPeriodValue(splitData[2]);
                }
                setNarration(PropertyTransaction.narration);
                setotherExpenses(PropertyTransaction.otherExpenses);
                setParkingCharges(PropertyTransaction.parkingCharges);
                setAssetDesc({ value: PropertyTransaction.propertyAssetId, label: PropertyTransaction.propertyAsset.propertyAssetName });
                setShareInProperty(PropertyTransaction.shareInProperty);
                setPurchasePrice(PropertyTransaction.purchasePrice);
                setStampDuty(PropertyTransaction.stampDuty);
                setTransferCharges(PropertyTransaction.transferCharges);
                settypeofInv({ value: PropertyTransaction.typeOfInvestmentId, label: PropertyTransaction.typeOfInvestment.investmentName });
            }
        }
    }, [PropertyTransaction])

    useEffect(() => {

        if (typeofInv && assetDesc) {
            if (typeofInv.value !== undefined && assetDesc.value !== undefined) {
                FetchAvialabeSharesData();
            }
        }
    }, [typeofInv, assetDesc])

    useEffect(() => {
        if (showProperty) {
            getPropertyAsset();
        }
    }, [showProperty])

    const FetchAvialabeSharesData = () => {
        let propAssetId = propertyHeaderId === 0 ? assetDesc.value : PropertyTransaction.propertyAssetId;
        // let propAssetId = assetDesc.value;
        let invstId = propertyHeaderId === 0 ? typeofInv.value : PropertyTransaction.typeOfInvestmentId;
        if (invstId !== undefined && propAssetId !== undefined) {
            FetchAvialabeShares(propAssetId, invstId).then((response) => {
                if (response.status === 200) {
                    if (response.data._response.status === 1) {
                        propertyHeaderId === 0 ? setShareLimit(response.data._response.data.avilableShares)
                            : setShareLimit(PropertyTransaction.shareInProperty + response.data._response.data.avilableShares)
                    } else {
                        setShareLimit(0);
                    }
                }
            }).catch(e => console.log(e, 'error in available shares'))
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {`The available share limit is ${shareLimit}`}
        </Tooltip>
    );

    const getPropertyAsset = async () => {
        setLoading(true);
        GetPropertyAsset().then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    const data = res.data._response.data.getPropertyAsset;
                    let arr = [];
                    data && data.map((d) => {
                       return ( arr.push({ value: d.propertyAssetId, label: d.propertyAssetName }));
                    })
                    setAssetOption(arr);
                }
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e, 'error in getAsset api property');
        })
    }

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }
    

    const calculateSharesBuy = (value) => {
        let amtInst = ((Number(purchasePrice ? purchasePrice : 0)) + Number(brokerage !== "" ? brokerage : 0) + Number(stampDuty !== "" ? stampDuty : 0) + Number(gst !== "" ? gst : 0) + Number(transferCharges !== "" ? transferCharges : 0) + Number(otherExpenses !== "" ? otherExpenses : 0) + Number(parkingCharges !== "" ? parkingCharges : 0));
        setAmtInvest(amtInst.toFixed(2));
        !isDataReady && setCurrMP(amtInst.toFixed(2));
        let yourInv = amtInst * shareInProperty / 100;
        setYourInvest(yourInv.toFixed(2));
        !isDataReady && setMarketPricePerShare(yourInv.toFixed(2));
        setIsDataReady(false)
    }
    const calculateCurrentMarketPrice = (value) => {
        let crmp = currMP * shareInProperty / 100
        setMarketPricePerShare(crmp.toFixed(2))
    }
    const handleData = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    const handleShow = () => setAssetModal(!assetModal);
    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <span onMouseDownCapture={handleShow} className="m-0" title="Add new asset">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    )
    const errHandel = () => {
        let formErr = {};
        let perc = shareLimit;
        let isValid = true;
        if (typeofInv.length < 1) {
            isValid = false;
            formErr["typeOfInvestment"] = "Please select type of investment !";
        }
        if (assetDesc.length < 1) {
            isValid = false;
            formErr["assetDescription"] = "Please select asset description !";
        }
        if (!location) {
            isValid = false;
            formErr["location"] = "Please enter location !";
        }
        if (!date) {
            isValid = false;
            formErr["dateofinv"] = "Please select date of investment !";
        }
        if (!purchasePrice) {
            isValid = false;
            formErr["purchasePrice"] = "Please enter purchase price !";
        }
        if (!shareInProperty) {
            isValid = false;
            formErr["shareInProperty"] = "Please enter share in property !";
        }
        if (!currMP || currMP === 0) {
            isValid = false;
            formErr["currentMarketPrice"] = "Please enter current market price !";
        }
        if (Number(shareInProperty) > perc) {
            isValid = false;
            formErr["shareInProperty"] = "Please enter share in property within available share limit!";
        }
        setFormErr(formErr);
        return isValid;
    };
    const submitNewAsset = () => {
        if (!freshAsset || freshAsset === undefined) {
            setNewAssetErr("Please enter asset name !")
        } else {
            let data1 = {
                propertyAssetName: freshAsset,
                subAccountId: entityId
            }
            setLoading(true);
            PostPropertyAsset(data1).then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        const data = res.data._response.data
                        let options = { label: data.propertyAssetName, value: data.propertyAssetId }
                        AssetOption.unshift(options)
                        // setAssetOption(data)
                        Swal.fire("Success!", "New Asset Added Successfully", "success");
                        setFreshAsset('')
                        setNewAssetErr('')
                    } else {
                        Swal.fire("Info!", "Asset already exists", "info");
                    }
                }
            }).catch((e) => {
                setLoading(false);
                console.log(e, 'error in getAsset api property');
            })
        }
    }
    const handleNewAsset = (e) => {
        const { name, value } = e.target
        setFreshAsset(value.trimStart().replace(/[^\a-zA-Z0-9" "]/ig, ''))
        setNewAssetErr('')
    }
    const handleClose = () => {
        setAssetModal(false);
        setNewAssetErr('');
        // handleClear();
        setpropertyID(0)
    }
    const AddPropertyTransaction = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const data = {
                typeOfInvestmentId: typeofInv.value,
                assetDetails: assetDesc.label,
                propertyAssetId: assetDesc.value,
                propertyAssetName: assetDesc,
                propertyName: assetDesc.label,
                location: location,
                dateOfInvestment: fixTimezoneOffset(date),
                area: Number(areaInNo),
                areaUnit: area.value,
                areaTypeUnit: measurement.value,
                purchasePrice: Number(purchasePrice),
                brokerage: Number(brokerage),
                stampDuty: Number(stampDuty),
                gst: Number(gst),
                transferCharges: transferCharges ? Number(transferCharges) : 0,
                otherExpenses: otherExpenses ? Number(otherExpenses) : 0,
                parkingCharges: parkingCharges ? Number(parkingCharges) : 0,
                shareInProperty: shareInProperty ? Number(shareInProperty) : 0,
                amountInvested: Number(amtInvest),
                amountInvestedPerShare: yourInvest,
                currentMarketPricePerc: currMP ? Number(currMP) : Number(amtInvest),
                currentMarketPricePerShare: Number(marketPricePerShare) !== 0 ? marketPricePerShare : yourInvest,
                lockInPeriod: lockinperiod ? lockinperiod + lockPeriod.label : 0 + lockPeriod.label,
                narration: narration,
                subcriberId: userID,
                transactType: "Buy",
                subAccountId: subAccountId,
            }
            AddPropertyTransactions(data)
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            if (response.data._response.message === "Property Detail Already Present.") {
                                Swal.fire("Info!", "Property Details Already Present.", "info");
                            } else {
                                // setSuccess(true);
                                Swal.fire("Success!", "Property Added succesfully", "success");
                                handleClear();
                                //handleClose();
                            }
                        }
                    } if (response.data._response.status === 2) {
                        setLoading(false);
                        Swal.fire("Something went wrong !", "", 'error');
                    }
                })
                .catch((error) => {
                    // setLoading(false);
                    Swal.fire("Something went wrong !", "", 'error');
                    console.log(error, "error in submit");
                });
        }
    }
    const UpdatePropertyTransaction = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const PropertyData = {
                propertyId: propertyID,
                typeOfInvestmentId: typeofInv.value,
                assetDetails: assetDesc.label,
                propertyAssetId: assetDesc.value,
                propertyAssetName: assetDesc,
                propertyName: assetDesc.label,
                location: location,
                dateOfInvestment: fixTimezoneOffset(date),
                area: Number(areaInNo),
                areaUnit: area.value,
                areaTypeUnit: measurement.value,
                purchasePrice: Number(purchasePrice),
                brokerage: Number(brokerage),
                stampDuty: Number(stampDuty),
                gst: Number(gst),
                transferCharges: transferCharges ? Number(transferCharges) : 0,
                otherExpenses: otherExpenses ? Number(otherExpenses) : 0,
                parkingCharges: parkingCharges ? Number(parkingCharges) : 0,
                shareInProperty: shareInProperty ? Number(shareInProperty) : 0,
                amountInvested: Number(amtInvest),
                amountInvestedPerShare: yourInvest,
                currentMarketPricePerc: currMP ? Number(currMP) : Number(amtInvest),
                currentMarketPricePerShare: Number(marketPricePerShare) !== 0 ? marketPricePerShare : yourInvest,
                lockInPeriod: lockinperiod ? lockinperiod + lockPeriod.label : 0 + lockPeriod.label,
                narration: narration,
                subcriberId: userID,
                transactType: "Buy",
                subAccountId: subAccountId,
            }
            UpdatePropertyBuyTransac(PropertyData).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("Success!", "Property Details Updated Successfully", "success");
                        // onRefreshForm()
                        // handleClear();
                    }
                } else {
                    setLoading(false);
                    console.log("error");
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
    }
    const handleClear = () => {
        setpropertyID(0)
        settypeofInv([]);
        setAssetDesc([]);
        setLocation('');
        setDate(new Date());
        setMeasurement([]);
        setAreaInNo('');
        setArea([]);
        setPurchasePrice('');
        setBrokerage('');
        setStampDuty('');
        setGst('');
        setTransferCharges('');
        setotherExpenses('');
        setParkingCharges('');
        setShareInProperty('');
        setAmtInvest('');
        setYourInvest('');
        setCurrMP('');
        setMarketPricePerShare('');
        setLockinperiod('');
        setLockPeriod({ label: "Month", value: "Month" });
        setNarration('');
        setNewAssetErr('');
        setFormErr([]);
        setShareLimit('');
    }
    const handleclose = () => {
        onHideProperty();
        handleClear();
    }
    const HandleChangePeriod = (e) => {
        setLockPeriod(e)
        setLockPeriodValue(e.value);
        // setTimeValue(e.value);
    }


   
        function replaceNonNumeric(numStr) {
            return String(numStr).replace(/[^0-9]/g, '')
        }
        function commarize(numStr) {
            return Number(replaceNonNumeric(numStr)).toLocaleString()
        }

    return (
        <div>
            <div tabIndex={3}>
                <Modal className="custom-dialog my-1 mx-auto"
                    isOpen={showProperty}
                    toggle={() => handleclose()}
                    size="xl"
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                >
                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleclose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">{propertyHeaderId === 0 ? "Property Transaction" : "Update Property Transaction"}</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Container fluid>
                            <form>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                                ) : null}
                                <fieldset className="mb-1 mt-0 p-1">
                                    {/* <div className="mt-2 p-6" > */}
                                    <Form className="p-2" >
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Type of Investment<span className="text-danger h6">*</span></Form.Label>
                                                <Select
                                                    value={typeofInv}
                                                    onChange={(selected) => settypeofInv(selected)}
                                                    options={TypeofInvestPA}
                                                    name="typeOfInvestment"
                                                    placeholder="select type of investment"
                                                    isDisabled={propertyHeaderId !== 0}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.typeOfInvestment}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Asset Description<span className="text-danger h6">*</span></Form.Label>
                                                <Select
                                                    components={{ Control }}
                                                    value={assetDesc}
                                                    onChange={(selected) => setAssetDesc(selected)}
                                                    options={AssetOption}
                                                    name="assetDescription"
                                                    placeholder="select asset"
                                                    isDisabled={propertyHeaderId !== 0}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.assetDescription}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Location<span className="text-danger h6">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="location"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter location'
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.location}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    onChange={(date) => setDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={date}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.dateofinv}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Area (Sq. Ft. / Mts. / Yards)</Form.Label>
                                                <Select
                                                    value={measurement}
                                                    onChange={(selected) => setMeasurement(selected)}
                                                    options={Measurement}
                                                    name="measurement"
                                                    placeholder="select area unit"
                                                />
                                            </Form.Group>
                                            <Col md="4" className="mb-3">
                                                <Form.Label className="d-block">Area (Carpet / Built up / Super built up / Saleable)</Form.Label>
                                                {/* <Form.Control type="Number"
                                                    onChange={(e) => setAreaInNo(e.target.value)}
                                                    autoComplete="off"
                                                    value={areaInNo}
                                                    name="area" className='lockinperiodno'
                                                    id="area"
                                                    placeholder='enter value' /> */}
                                                    <Cleave
                                                    placeholder='enter value'
                                                    id="area"
                                                    name="area" 
                                                    className='form-control lockinperiodno'
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={areaInNo}
                                                    onChange={(e) => setAreaInNo(e.target.rawValue)}
                                                   />
                                                <Dropdown
                                                    value={area}
                                                    name='area'
                                                    options={AreaMesurementType}
                                                    onChange={(sel) => setArea(sel)}
                                                    className="SelectClass lockinperiodlist"
                                                    searchable={true}
                                                    clearable={true}
                                                    noResultsText={"No result"}
                                                    placeholder="select area type"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Purchase Price(100%)<span className="text-danger h6">*</span></Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="purchasePrice"
                                                    min="0"
                                                    value={purchasePrice}
                                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter purchase price'
                                                /> */}
                                                <Cleave
                                                    placeholder='enter purchase price'
                                                    className='form-control'
                                                    name="purchasePrice"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={purchasePrice}
                                                    onChange={(e) => setPurchasePrice(e.target.rawValue)}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.purchasePrice}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Brokerage</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="brokerage"
                                                    min="0"
                                                    value={brokerage}
                                                    onChange={(e) => setBrokerage(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter brokerage'
                                                /> */}

                                                <Cleave 
                                                className='form-control'
                                                name="brokerage"
                                                autoComplete='off'
                                                placeholder='enter brokerage'
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={brokerage}
                                                onChange={(e) =>  setBrokerage(e.target.rawValue) }/>
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Stamp Duty</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    name="stampDuty"
                                                    min="0"
                                                    value={stampDuty}
                                                    onChange={(e) => setStampDuty(commarize(e.target.value))}
                                                    autoComplete="off"
                                                    placeholder='enter stamp duty'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter stamp duty'
                                                    className='form-control'
                                                    name="stampDuty"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={stampDuty}
                                                    onChange={(e) => setStampDuty(e.target.rawValue)}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>GST</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="GST"
                                                    min="0"
                                                    value={gst}
                                                    onChange={(e) => setGst(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter gst'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter gst'
                                                    className='form-control'
                                                    name="GST"
                                                    autoComplete='off'
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={gst}
                                                    onChange={(e) => setGst(e.target.rawValue)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Transfer Charges</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="transferCharges"
                                                    min="0"
                                                    value={transferCharges}
                                                    onChange={(e) => setTransferCharges(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter transfer charges'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter transfer charges'
                                                    className='form-control'
                                                    name="transferCharges"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={transferCharges}
                                                    onChange={(e) => setTransferCharges(e.target.rawValue)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Other Expenses</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="OtherExpenses"
                                                    min="0"
                                                    value={otherExpenses}
                                                    onChange={(e) => setotherExpenses(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter other expenses'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter other expenses'
                                                    className='form-control'
                                                    name="OtherExpenses"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={otherExpenses}
                                                    onChange={(e) => setotherExpenses(e.target.rawValue)}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Parking Charges</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="ParkingCharges"
                                                    min="0"
                                                    value={parkingCharges}
                                                    onChange={(e) => setParkingCharges(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter parking charges'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter parking charges'
                                                    className='form-control'
                                                    name="ParkingCharges"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={parkingCharges}
                                                    onChange={(e) => setParkingCharges(e.target.rawValue)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Share in Property(100%)<span className="text-danger h6">*</span></Form.Label>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip} >
                                                    <Form.Control
                                                        type="Number"
                                                        name="shareInProperty"
                                                        min="0"
                                                        placeholder='enter share in property %'
                                                        value={shareInProperty}
                                                        onChange={(e) => setShareInProperty(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </OverlayTrigger>
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.shareInProperty}
                                                    </div>
                                                ) : null}
                                                {/* <Tooltip title={`The available share limiy is: ${shareLimit}`} ></Tooltip> */}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Amount Invested</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    name="AmountInvested"
                                                    min="0"
                                                    value={NumberSeperator(amtInvest)}
                                                    // value={AmtInvestTotal}
                                                    // onChange={handleValueC}
                                                    disabled={true}
                                                    autoComplete="off"
                                                /> */}

                                                <Cleave 
                                                    className='form-control'
                                                    name="AmountInvested"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={(amtInvest)}
                                                    disabled={true}
                                                  />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Your Investment</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="AmountInvested"
                                                    min="0"
                                                    value={NumberSeperator(yourInvest)}
                                                    // onChange={handleValueC}
                                                    disabled={true}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Current Market Price (100%)<span className="text-danger h6">*</span></Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    name="currentMarketPrice"
                                                    min="0"
                                                    value={currMP}
                                                    onChange={(e) => setCurrMP(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter current market price'
                                                /> */}

                                                <Cleave
                                                    placeholder='enter current market price'
                                                    className='form-control'
                                                    name="currentMarketPrice"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={currMP}
                                                    onChange={(e) => setCurrMP(Number(e.target.rawValue))}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.currentMarketPrice}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Current Market Price as per Share</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    name="CMPasperShare"
                                                    min="0"
                                                    value={NumberSeperator(marketPricePerShare)}
                                                    disabled={true}
                                                    autoComplete="off"
                                                /> */}

                                                <Cleave
                                                    className='form-control'
                                                    name="CMPasperShare"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={(marketPricePerShare)}
                                                    disabled={true}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Col md="4" className="mb-3">
                                                <Form.Label className="d-block">Lock in Period</Form.Label>
                                                <Form.Control type="Number"
                                                    onChange={(e) => setLockinperiod(e.target.value)}
                                                    autoComplete="off"
                                                    value={lockinperiod}
                                                    name="lockinperiod" id="lockinperiod" className="lockinperiodno" />
                                                <Dropdown
                                                    value={lockPeriod}
                                                    name='lockinperiod'
                                                    options={periodList}
                                                    onChange={HandleChangePeriod}
                                                    className="SelectClass lockinperiodlist"
                                                    searchable={true}
                                                    clearable={true}
                                                    noResultsText={"No result"}
                                                    placeholder="Month"
                                                />
                                            </Col>
                                            <Col md="8">
                                                <Form.Label>Narration</Form.Label>
                                                <Form.Control as="textarea" autoComplete="off"
                                                    name="narration"
                                                    id="narration"
                                                    value={narration}
                                                    onChange={(e) => setNarration(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-set">
                                            <Col md="12" className="text-center mt-3">
                                                {propertyHeaderId === 0 ?
                                                    <>
                                                        <Button variant="primary" className="mx-2 w100"
                                                        id = "submitButton"
                                                            onClick={AddPropertyTransaction}
                                                        //   disabled={!disableButton()}
                                                        >Submit</Button>
                                                        <Button variant="secondary" className="mx-2 w100"
                                                            onClick={handleClear}
                                                        //   disabled={!disableButton()}
                                                        >Clear</Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button variant="primary" className="mx-2 w100"
                                                        id = "submitButton"
                                                            onClick={UpdatePropertyTransaction}
                                                        // disabled={!disableButton()}
                                                        >Update</Button>
                                                    </>}

                                            </Col>
                                        </Row>
                                    </Form>
                                    {/* </div> */}
                                </fieldset>
                            </form>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
            {assetModal ? <div tabIndex={4}>
                <Modal isOpen={assetModal} toggle={handleClose} animation={false} size="sm" centered>
                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleClose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5>Asset Description</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form >
                            {loading ? (
                                <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                            ) : null}
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Asset Name</label>
                                <input className="form-control mt-2" placeholder='enter asset name' type="text" autoComplete="off" name="freshasset" onChange={handleNewAsset} value={freshAsset} />
                                {newAssetErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{newAssetErr}</div> : null}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitNewAsset} >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </div> : null}
        </div>
    )
}
export default Property_Details;