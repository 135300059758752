/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { Form, Row, Col, Container, Button } from 'react-bootstrap';
import Select from 'react-select';
import Loader from "../loadingAnimation/index";
import DatePicker from 'react-datepicker';
import { typeAcDetail } from '../../modules/helpers/constant';
import { CompanyList } from '../../pages/Stocks/StocksAPI';

const FuturesDetails = (props) => {
    const { showInsurance, onHideInsurance } = props;

    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState([]);
    const [companyoption, setCompanyoption] = useState([]);
    const [type, setType] = useState([]);
    const [typeoption, setTypeoption] = useState(typeAcDetail);
    const [date, setDate] = useState(new Date());

    const [quantity, setQuantity] = useState('');
    const [purchasePrice, setPurchasePrice] = useState(null);
    const [amtInvest, setAmtInvest] = useState(null);

    const [brokerage, setBrokerage] = useState(null);
    const [stampDuty, setStampDuty] = useState(null);
    const [stt, setStt] = useState([]);

    const [gst, setGst] = useState(null);
    const [otherExpenses, setotherExpenses] = useState(null);
    const [totalInvest, setTotalInvest] = useState(null);

    const [dateofExpiry, setdateofExpiry] = useState(new Date());
    const [target, setTarget] = useState(null);
    const [stopLoss, setStopLoss] = useState(null);

    const [broker, setBroker] = useState([]);
    const [exchange, setExchange] = useState([]);
    const [narration, setNarration] = useState('')

    const [formErr, setFormErr] = useState([]);

    useEffect(() => {
        getCompanyList();
    }, [])

    // companylist
    const getCompanyList = async () => {
        setLoading(true)
        await CompanyList().then(res => {
            if (res.status === 200) {
                let company = res.data._response.data.companyDetails;
                let companyData = [];
                company && company.map(data => {
                    companyData.push({ value: data.companyId, label: data.companyName })
                })
                setCompanyoption(companyData);
                setLoading(false)
            } else {
                // setError(res.data.message)
                setLoading(false)
            }
        }).catch(e => {
            // setError(e.message)
            setLoading(false)
        })
    }

    const handleclose = () => {
        onHideInsurance()
    }

    const errHandel = () => {
        let formErr = {};

        let isValid = true;
        if (company.value == null || company.value === undefined || company.label === '') {
            isValid = false;
            formErr["company"] = "Please select company !";
        }
        if (!date) {
            isValid = false;
            formErr["dateofinv"] = "Please select date !";
        }
        // if (!investedAmt) {
        //     isValid = false;
        //     formErr["investedAmt"] = "Please enter amount of invest !";
        // }
        // if (!currentPrice) {
        //     isValid = false;
        //     formErr["currentPrice"] = "Please enter current price !";
        // }
        setFormErr(formErr);
        return isValid;
    };

    const handleClear = () => {
        setCompany([]);
        setType([]);
        setDate(new Date());
        setQuantity('');
        setPurchasePrice('');
        setAmtInvest('');
        setBroker([]);
        setBrokerage('');
        setStampDuty('');
        setStt('');
        setGst('');
        setotherExpenses('');
        setTotalInvest('');
        setdateofExpiry(new Date());
        setTarget('');
        setStopLoss('');
        setBroker([]);
        setExchange([]);
        setNarration('');
    }

    return (
        <div>
            <div tabIndex={2}>
                <Modal className="custom-dialog"
                    isOpen={showInsurance}
                    toggle={() => handleclose()}
                    size="xl"
                    aria-labelledby="example-div-sizes-title-xl"
                    centered
                >
                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleclose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Futures Transaction</h5>
                    </ModalHeader>
                    <div>
                        <Container fluid>
                            <form>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                                ) : null}
                                <fieldset>
                                    <Form className="p-3" >
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Company<span className="text-danger h6">*</span></Form.Label>
                                                <Select
                                                    value={company}
                                                    onChange={(selected) => setCompany(selected)}
                                                    options={companyoption}
                                                    name="companyName"
                                                    placeholder="select company"
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.company}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Type<span className="text-danger h6">*</span></Form.Label>
                                                <Select
                                                    // components={{ Control }}
                                                    value={type}
                                                    onChange={(selected) => setType(selected)}
                                                    options={typeoption}
                                                    name="type"
                                                    placeholder="select type"
                                                // isDisabled={propertyHeaderId !== 0}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.type}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    onChange={(date) => setDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofinv"
                                                    autoComplete="off"
                                                    selected={date}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.dateofinv}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Quantity<span className="text-danger h6">*</span></Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    value={quantity}
                                                    onChange={(e) => setQuantity(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter quantity'
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.location}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Purchase Price(100%)<span className="text-danger h6">*</span></Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="purchasePrice"
                                                    min="0"
                                                    value={purchasePrice}
                                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter purchase price'
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.purchasePrice}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Amount Invested</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="AmountInvested"
                                                    min="0"
                                                    value={amtInvest}
                                                    onChange={(e) => setAmtInvest(e.target.value)}
                                                    // disabled={true}
                                                    autoComplete="off"
                                                    placeholder='enter amount invested'
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Brokerage</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="brokerage"
                                                    min="0"
                                                    value={brokerage}
                                                    onChange={(e) => setBrokerage(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter brokerage'

                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Stamp Duty</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="stampDuty"
                                                    min="0"
                                                    value={stampDuty}
                                                    onChange={(e) => setStampDuty(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter stamp duty'
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>STT</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    value={stt}
                                                    onChange={(e) => setStt(e.target.value)}
                                                    name="stt"
                                                    placeholder="enter stt"
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>GST</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="GST"
                                                    min="0"
                                                    value={gst}
                                                    onChange={(e) => setGst(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter gst'
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Other Expenses</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="OtherExpenses"
                                                    min="0"
                                                    value={otherExpenses}
                                                    onChange={(e) => setotherExpenses(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter other expenses'
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Total Invested</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="AmountInvested"
                                                    min="0"
                                                    value={totalInvest}
                                                    // onChange={handleValueC}
                                                    disabled={true}
                                                    autoComplete="off"
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Date of Expiry<span className="text-danger h6">*</span></Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    // maxDate={new Date()}
                                                    popperPlacement="bottom"
                                                    // minDate={}
                                                    onChange={(date) => setdateofExpiry(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    name="dateofexp"
                                                    autoComplete="off"
                                                    selected={dateofExpiry}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.dateofinv}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Target</Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="target"
                                                    min="0"
                                                    value={target}
                                                    onChange={(e) => setTarget(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='enter target value'
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Stop Loss<span className="text-danger h6">*</span></Form.Label>
                                                <Form.Control
                                                    type="Number"
                                                    name="stoploss"
                                                    min="0"
                                                    placeholder='enter stop loss'
                                                    value={stopLoss}
                                                    onChange={(e) => setStopLoss(e.target.value)}
                                                    autoComplete="off"
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.stopLoss}
                                                    </div>
                                                ) : null}
                                                {/* <Tooltip title={`The available share limiy is: ${shareLimit}`} ></Tooltip> */}
                                            </Form.Group>
                                        </Row>
                                        <Row className="form-set">
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Broker</Form.Label>
                                                <Select
                                                    name="broker"
                                                    min="0"
                                                    value={broker}
                                                    onChange={(e) => setBroker(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='select broker'
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-4">
                                                <Form.Label>Exchange<span className="text-danger h6">*</span></Form.Label>
                                                <Select
                                                    type="Number"
                                                    name="exchange"
                                                    min="0"
                                                    value={exchange}
                                                    onChange={(e) => setExchange(e.target.value)}
                                                    autoComplete="off"
                                                    placeholder='select exchange type'
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.currentMarketPrice}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Col md="4">
                                                <Form.Label>Narration</Form.Label>
                                                <Form.Control as="textarea" autoComplete="off"
                                                    name="narration"
                                                    id="narration"
                                                    value={narration}
                                                    onChange={(e) => setNarration(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="form-set">
                                            <Col md="12" className="text-center mt-4">
                                                {/* { propertyHeaderId == 0 ?  */}
                                                <>
                                                    <Button variant="primary" className="mx-2 w100"
                                                    // onClick={AddPropertyTransaction}
                                                    //   disabled={!disableButton()}
                                                    >Submit</Button>
                                                </>
                                                {/* : */}
                                                {/* <>
                                                <Button variant="primary" className="mx-2 w100"
                                                // onClick={UpdatePropertyTransaction}
                                                // disabled={!disableButton()}
                                                >Update</Button> */}
                                                {/* </>} */}
                                                <Button variant="secondary" className="mx-2 w100"
                                                    onClick={handleClear}
                                                //   disabled={!disableButton()}
                                                >Clear</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </fieldset>
                            </form>
                        </Container>
                    </div>

                </Modal>
            </div>
        </div>
    )
}

export default FuturesDetails
