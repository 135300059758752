/* eslint-disable no-self-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { brokerList, CompanyList, GetDividendFrequency, GetUnlistedScripBySubId, PostUnlistedScripBySubId } from '../../../pages/Stocks/StocksAPI';
import { AddFIForOpeningBalance } from '../../FIncomeFolio/FDAPI';
import { Container, Button, Modal, Row, Col, Form } from 'react-bootstrap'
import Select, { components } from "react-select"
import Swal from 'sweetalert2'
import MaterialTable, { MTableToolbar, MTableEditField } from "material-table";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Stack } from "@mui/material";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import Dropdown from '../../CommonComponent/Dropdown';
import { periodList, redeemableOrConvertibleList, cumulativeNonCumulativeList, InvestmentType } from '../../../modules/helpers/constant';
import moment from "moment";
import DatePicker from 'react-datepicker';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavefocusedTxtField,cleavedisabledField } from '../../StocksDetail/ClevTablCss';
import useEnterKeyListener from '../../ShortcutKey/useKeyListener';
import Cleave from 'cleave.js/react';

function FDopeningBal(props) {
  const { newFilterTypeFD, entityId, familyFD, brokerCall } = props;


  const [companyList, setCompanyList] = useState([]);
  const [brokerData, setBrokerList] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selScrip, setSelScrip] = useState({ value: '', label: '' });
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');
  const [brokerUnlisted, setBrokerUnlisted] = useState([]);


  const [formErr, setFormErr] = useState('');
  const [headerId, setHeaderId] = useState(0);
  const [dividend, setDividend] = useState(0);
  const [frequencyValue, setFrequencyValue] = useState([{ label: "Monthly", value: 2 }])
  const [redeemableorconvertible, setRedeemableorconvertible] = useState([{ label: "Redeemable", value: "Redeemable" }]);
  const [timeValue, setTimeValue] = useState("Month");
  const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
  const [lockinperiod, setLockinperiod] = useState("");
  const [cumulativeType, setCumulativeType] = useState("Non Cumulative");
  const [cumulativeornoncumulative, setCumulativeornoncumulative] = useState([{ label: "Non Cumulative", value: "Non Cumulative" }]);
  const [certificateorfolinumber, setCertificateorfolinumber] = useState("");
  const [maturityvalue, setMaturityvalue] = useState(0);
  const [dateofmaturityorconversion, setDateofmaturityorconversion] = useState(new Date());
  const [showlistedPref, setShowlistedPref] = useState(false);

  const [convertType, setConvertType] = useState("Redeemable");
  const [dividendfrequency, setDividendfrequency] = useState([]);
  const [date, setDate] = useState(null);
  const [showUnlisted, setShowUnlisted] = useState(false);
  const [cost, setCost] = useState(null);

  const [unlistedCompany, setUnlistedCompanyList] = useState([]);

  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);
  const [allEntry2, setAllEntry2] = useState([]);
  const [allEntry3, setAllEntry3] = useState([]);
  const [allEntry4, setAllEntry4] = useState([]);
  const [allEntry5, setAllEntry5] = useState([]);
  const [allEntry6, setAllEntry6] = useState([]);
  const [allEntry7, setAllEntry7] = useState([]);
  const [allEntry8, setAllEntry8] = useState([]);

  function replaceNonNumeric(numStr) {
    return String(numStr).replace(/[^0-9]/g, '')
  }

  function commarize(numStr) {
    return Number(replaceNonNumeric(numStr)).toLocaleString()
  }

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }
  //shrotcutkey
  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });


  useEffect(() => {
    (async () => {
      setLoading(true);
      await getCompanyList();
      await getDividedFrequencyList();
    })();
  }, [])

  useEffect(() => {
    (async () => {
      if(familyFD.value || brokerCall === true){
        await getBrokerList();
      }
    })();
  }, [familyFD, brokerCall])

  useEffect(() => {
    getUnlistedComapy(familyFD.value);
  }, [familyFD, newFilterTypeFD])

  useEffect(() => {
    if (data.length > 0) {
      props.parentCallback(false, data)
    }
  }, [data])

  const handleUnlisted = (e) => {
    const { value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <span onMouseDown={handleShow} className="m-0" title="Add unlisted scrip">
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )
  const handleClose = () => {
    setShow(false);
    setUnlistedComp('');
    setUnlistedCompErr('')
  }
  const handleShow = () => setShow(true);

  const handlecloseDescModal = () => {
    setShowlistedPref(false);
    // handleCloseModalPopUp()
    setFormErr('')
  }
  const HandleChangeTime = (e) => {
    setTime(e);
    setTimeValue(e.value);
  }
  const handleChangeConvert = (e) => {
    setRedeemableorconvertible(e);
    setConvertType(e.value);
  }
  const handleChangeCumulative = (e) => {
    setCumulativeornoncumulative(e);
    setCumulativeType(e.value);
  }
  const handleChangeFrequency = (e) => {
    // setDividendfrequency(e);
    setFrequencyValue(e);
  }
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  const submitPreModal = () => {
    let formErr = []
    let isValid = true;
    if (newFilterTypeFD === 3 || newFilterTypeFD === 1) {
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please fill valid values!";
          isValid = false;
        } else {
          if (!cost || cost <= 0) {
            formErr["maturityValue"] = "Please fill valid values!";
            isValid = false;
          }
        }
      }
      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please fill valid values!!";
        isValid = false;
      }
      if (dateofmaturityorconversion && new Date(date)) {
        let trandate = moment(fixTimezoneOffset(new Date(date))).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date not same as purchase date!";
          isValid = false;
        }
      }
    }
    if (newFilterTypeFD === 4 || newFilterTypeFD === 2) {
      if (certificateorfolinumber === "" || certificateorfolinumber == null) {
        formErr["certificateorfolinumber"] = "Please fill Certificate/Folio Number field";
        isValid = false;
      }
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please fill valid values!";
          isValid = false;
        } else {
          if (!cost || cost <= 0) {
            formErr["maturityValue"] = "Please fill valid values!";
            isValid = false;
          }
        }
      }
      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please fill valid values!!";
        isValid = false;
      }
      if (dateofmaturityorconversion && new Date(date)) {
        let trandate = moment(fixTimezoneOffset(new Date(date))).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date not same as Investment date!";
          isValid = false;
        }
      }
    }
    if (isValid === true) {
      handlecloseDescModal();
    }
    setFormErr(formErr);
    return isValid;
  }
  const handleCloseModalPopUp = () => {
    setShowUnlisted(false);
    setCertificateorfolinumber('');
    setLockinperiod("");
    setDate(new Date())
    setTime([{ label: "Month", value: "Month" }])
    // setTenure('');
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    setFrequencyValue([{ label: "Monthly", value: 2 }])
    setMaturityvalue(0);
    setFormErr('')
    setCost(null);
  }
  const handleCloseModalPopUpAdd = (t) => {
    setCertificateorfolinumber('');
    setLockinperiod('');
    setDate(t.date)
    setTime([{ label: "Month", value: "Month" }])
    setFrequencyValue([{ label: "Monthly", value: 2 }])
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
    setFormErr('')
    setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
    setMaturityvalue(0)
  }
  // const lockFamilyAndType = () => {
  //   if(props.familyFD !== undefined && props.newFilterTypeFD !== undefined){
  //     props.lock(true, 1);
  //   }
  // }

  const getBrokerList = async () => {
    let id = familyFD.value;
    await brokerList(id).then(res => {
      setLoading(false)
      if (res.status === 200) {
        if(res.data._response.status === 1){
          let brokers = res.data._response.data.brokerDetails;
          let brokersData = [];
          let brokerUnlist = [];
          brokers && brokers.map(data => {
            brokerUnlist.push({ value: 1, label: "N/A" })
            return (brokersData.push({ value: data.brokerId, label: data.brokerName }))
          })
          setBrokerList(brokersData);
          setBrokerUnlisted(brokerUnlist)
        }
      } else {
        setLoading(false)
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false)
    })
  }
  const getCompanyList = async (unListedParam) => {
    setLoading(true);
    await CompanyList()
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          let company = res.data._response.data.companyDetails;
          let companyData = [];
          company &&
            company.map((data) => {
              return (companyData.push({
                value: data.companyId,
                label: data.companyName,
                companyType: data.instrumentTypeId
              }))
            });
          setCompanyList(companyData);
          if (unListedParam === 1) {
            let lastElement = companyData[companyData.length - 1];
            setSelScrip(lastElement)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        // setError(e.message);
      });
  };
  const getDividedFrequencyList = async () => {
    setLoading(true);
    await GetDividendFrequency()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
             return  ( dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                }))
              });
            setDividendfrequency(dividendData);
            if (headerId === 0) {
              setFrequencyValue(dividendData[1])
            }
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });
  };
  const getUnlistedComapy = (entityId) => {
    let Companyid
    if (newFilterTypeFD === 2) {
      Companyid = 21
    }
    if (newFilterTypeFD === 1) {
      Companyid = 12
    }
    if (newFilterTypeFD === 3) {
      Companyid = 13
    }
    if (newFilterTypeFD === 4) {
      Companyid = 22
    }
    setLoading(true);
    GetUnlistedScripBySubId(entityId, Companyid).then(res => {
      setLoading(false);
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let company = res.data._response.data.fetchUnlistedCompany;
          let companyData = [];
          company && company.map(data => {
            return (companyData.push({ value: data.companyId, label: data.companyName }))
          })
          setUnlistedCompanyList(companyData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setLoading(false);
      setError(e.message)
    })
  }

  const handelRowAdd = async (newData, resolve, reject) => {
    let errorList = []
    let companyData = newData.companyId;
    let brokerData = newFilterTypeFD === 1 || newFilterTypeFD === 3 ? newData.brokerId : 0;
    let interVal = newData.interestAccrued ? newData.interestAccrued : 'no';
    let typeOfInvest = newData.type // ? newData.type.value : null
    // if(newFilterTypeFD == 1 || newFilterTypeFD == 3){
    //   let brokerData = newData.brokerId;
    // }
    if (familyFD.value <= 0) {
      errorList.push("Try Again, You Didn't Select The Family Field");
    }
    else if (newFilterTypeFD === undefined) {
      errorList.push("Try Again, You Didn't Select The Type Field");
    }
    else if (newFilterTypeFD) {
      if (newFilterTypeFD === 1 || newFilterTypeFD === 3) {
        if (familyFD.length < 1) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (newFilterTypeFD == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        if (!newData.brokerId) {
          errorList.push("Try Again, You Didn't Select The Broker Field")
        }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        if (!newData.pricePerShare) {
          errorList.push("Try Again, Price/Debenture Field Can't Be Blank")
        }
        if (isNaN(newData.brokeragePerShare) || newData.brokeragePerShare < 0) {
          errorList.push("Try Again, Enter Brokerage/Debenture Before Submitting")
        }
        if (!((dividend && maturityvalue) || cost)) {
          errorList.push("Try Again, You Didn't Fill The Addtional Information !!")
        }
      }
      if (newFilterTypeFD === 2 || newFilterTypeFD === 4) {
        if (props.familyFD.length < 1) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (props.newFilterTypeFD == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        // if (!newData.folioNumber) {
        //   errorList.push("please enter Folio Number field")
        // }
        else if (newData.quantity) {
          let qvalue = parseFloat(newData.quantity);
          if (Number.isInteger(qvalue) === false) {
            errorList.push("Quantity Doesn't Accept Decimal Value!!");
          }
        }
        if (!newData.pricePerShare) {
          errorList.push("Try Again, You Didn't Enter The Price/Debenture Field")
        }
        if (!((dividend && maturityvalue) || cost)) {
          errorList.push("Try Again, You Didn't Fill The Addtional Information !!")
        }
        // if (!newData.brokeragePerShare) {
        //   errorList.push("Try Again, You Didn't enter the Brokerage/Debenture field")
        // }
      }
      if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
        if (props.familyFD.value == null || !props.familyFD.value) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (props.newFilterTypeFD == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 8) {
          const reg = /\s/g;//To match a number
          if (reg.test(newData.certificateFolioNo) === true || newData.certificateFolioNo === "") {
            if (newData.certificateFolioNo === "" || newData.certificateFolioNo.trim() === "") {
              errorList.push("Try Again, Company Name / Bank Name Field Not Be Blank")
            }
          }
          if (!newData.certificateFolioNo) {
            errorList.push("Try Again, You Didn't Enter Company Name / Bank Name Field")
          }
        }
        if (newFilterTypeFD === 7) {
          const reg = /\s/g;//To match a number
          if (reg.test(newData.folioNumber) === true || newData.folioNumber === "") {
            if (newData.folioNumber === "" || newData.folioNumber.trim() === "") {
              errorList.push("Try Again, Certificate/Folio No. Field Not Be Blank")
            }
          }
          if (!newData.folioNumber) {
            errorList.push("Try Again, You Didn't Enter Certificate/Folio No. Field")
          }
          if (!newData.type) {
            errorList.push("Try Again, You Didn't Select Type Of Investment Field")
          }
        }
        if (!newData.date) {
          errorList.push("Try Again, You Didn't Select Date Of Investment Field")
        }
        if (newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
          if (!newData.availableCost) {
            errorList.push("Try Again, You Didn't Enter Amount Of Investment Field")
          }
        }
        if (newFilterTypeFD === 5) {
          if (!newData.totalCost) {
            errorList.push("Try Again, You Didn't Enter Amount Of Investment Field")
          }
        }
        if (!newData.domConversion) {
          errorList.push("Try Again, You Didn't Select Maturity Date Field")
        }
        if (!newData.maturityValue) {
          errorList.push("Try Again, You Didn't Enter Maturity Value Field")
        }
        if (interVal === 'yes') {
          if (!newData.interestReinvested) {
            errorList.push("Try Again, You Didn't Enter Interest Accrued Till Date Field")
          }
          if (!newData.fyStartDate) {
            errorList.push("Try Again, You Didn't Select Financial Year Starting With Field")
          }
        }
      }
    }
    else {
      Swal.fire("You do not enter required information!", "", 'error');
    }

    if (errorList.length < 1) {
      let dataToAdd = newFilterTypeFD === 1 ? [...allEntry1] : newFilterTypeFD === 2 ? [...allEntry2] : newFilterTypeFD === 3 ? [...allEntry3] : newFilterTypeFD === 4 ? [...allEntry4] : newFilterTypeFD === 5 ? [...allEntry5] : newFilterTypeFD === 6 ? [...allEntry6] : newFilterTypeFD === 7 ? [...allEntry7] : [...allEntry8];

      if (newFilterTypeFD === 1 || newFilterTypeFD === 2 || newFilterTypeFD === 3 || newFilterTypeFD === 4) {
        newData.companyId = newData.companyId.label;
        newData.company = companyData;
      }
      if (newFilterTypeFD === 1) {
        newData.brokerId = newData.brokerId.label;
        newData.broker = brokerData;
        newData.subAccountId = props.familyFD.value;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;;
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.interestPerc = parseInt(dividend);
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.frequencyId = frequencyValue.value || frequencyValue[0].value
        newData.frequencyI = frequencyValue
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
      }
      if (newFilterTypeFD === 2 || newFilterTypeFD === 4) {
        newData.brokerId = brokerUnlisted[0].label;
        newData.broker = { label: brokerUnlisted[0].label, value: brokerUnlisted[0].value };
        newData.brokeragePerShare = 0;
        newData.totalBrokerage = 0;
        newData.subAccountId = props.familyFD.value;
        newData.folioNumber = certificateorfolinumber;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.interestPerc = parseInt(dividend);
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.frequencyId = frequencyValue.value || frequencyValue[0].value
        newData.frequencyI = frequencyValue
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
      }
      if (newFilterTypeFD === 3) {
        newData.brokerId = newData.brokerId.label;
        newData.broker = brokerData;
        newData.subAccountId = props.familyFD.value;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.interestPerc = parseInt(dividend);
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.frequencyId = frequencyValue.value || frequencyValue[0].value
        newData.frequencyI = frequencyValue
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
      }

      let instId = newFilterTypeFD === 1 && newData.redeemConvertible === "Redeemable" ? 17 : newFilterTypeFD === 1 && newData.redeemConvertible === "Convertible" ? 16 : newFilterTypeFD === 3 && newData.redeemConvertible === "Redeemable" ? 19 : newFilterTypeFD === 3 && newData.redeemConvertible === "Convertible" ? 18 : newFilterTypeFD === 2 && newData.redeemConvertible === "Redeemable" ? 26 : newFilterTypeFD === 2 && newData.redeemConvertible === "Convertible" ? 25 : newFilterTypeFD === 4 && newData.redeemConvertible === "Redeemable" ? 28 : 27;

      let instIds = newFilterTypeFD === 5 ? 29 : newFilterTypeFD === 6 ? 30 : newFilterTypeFD === 7 ? 32 : 31

      if (newFilterTypeFD === 1 || newFilterTypeFD === 2 || newFilterTypeFD === 3 || newFilterTypeFD === 4) {
        newData.instrumentTypeId = instId;
      }
      if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
        newData.instrumentTypeId = instIds;
        newData.interestAccrued = interVal;
        if (newFilterTypeFD === 7) {
          newData.typeId = newData.type;
          newData.type = typeOfInvest.label;
        }
      }

      dataToAdd.push(newData);
      var result = dataToAdd.map(function (o) {
        o.transactStockType = "Buy";
        return o;
      })

      await newFilterTypeFD === 1 ? setAllEntry1(result) : newFilterTypeFD === 2 ? setAllEntry2(result) : newFilterTypeFD === 3 ? setAllEntry3(result) : newFilterTypeFD === 4 ? setAllEntry4(result) : newFilterTypeFD === 5 ? setAllEntry5(result) : newFilterTypeFD === 6 ? setAllEntry6(result) : newFilterTypeFD === 7 ? setAllEntry7(result) : setAllEntry8(result);
      resolve()
      setErrorMessages([]);
      handleCloseModalPopUp()
      // lockFamilyAndType();
      setIserror(false);
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
    }
  }
  const handelRowUpdate = (newData, oldData, resolve, reject) => {
    setLoading(false);
    let errorList = []
    let cpy;
    let brk;
    let matureVal;
    if (newFilterTypeFD === 1 || newFilterTypeFD === 3 || newFilterTypeFD === 2 || newFilterTypeFD === 4) {
      cpy = newData.company.label === undefined ? newData.companyId.label : newData.company.label;
      brk = newData.broker.label === undefined ? newData.brokerId.label : newData.broker.label;
    }

    let companyData;
    let brokerData;
    if (newFilterTypeFD === 1 || newFilterTypeFD === 3 || newFilterTypeFD === 2 || newFilterTypeFD === 4) {
      companyData = cpy === newData.companyId && newData.companyId.label === undefined ? newData.company : newData.companyId;
      brokerData = newFilterTypeFD === 1 || newFilterTypeFD === 3 ? brk === newData.brokerId && newData.brokerId.label === undefined ? newData.broker : newData.brokerId : { label: brokerUnlisted[0].label, value: brokerUnlisted[0].value };
    }
    let interVal = newData.interestAccrued ? newData.interestAccrued : 'no';
    let poTypeVal = null;
    let typeOfInvest = null;
    if (newFilterTypeFD === 7) {
      poTypeVal = newData.typeId.label === undefined ? newData.type.label : newData.typeId.label;
      typeOfInvest = poTypeVal === newData.type && newData.type.label === undefined ? newData.typeId : newData.type;
    }

    if (newFilterTypeFD === 1 || newFilterTypeFD === 3) {
      if (familyFD.length < 1) {
        errorList.push("Try Again, You Didn't Select The Family Field")
      }
      if (newFilterTypeFD == null) {
        errorList.push("Try Again, You Didn't Select The Type Field")
      }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Select The Scrip Field")
      }
      if (!newData.brokerId) {
        errorList.push("Try Again, You Didn't Select The Broker Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      if (!newData.pricePerShare) {
        errorList.push("Try Again, Price/Debenture Field Can't Be Blank")
      }
      if (isNaN(newData.brokeragePerShare) || newData.brokeragePerShare < 0) {
        errorList.push("Try Again, Enter Brokerage/Debenture Before Submitting")
      }
    }
    if (newFilterTypeFD === 2 || newFilterTypeFD === 4) {
      if (props.familyFD.length < 1) {
        errorList.push("Try Again, You Didn't Enter The Family Field")
      }
      if (props.newFilterTypeFD == null) {
        errorList.push("Try Again, You Didn't Enter The Type Field")
      }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Enter The Scrip Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      // if (!newData.folioNumber) {
      //   errorList.push("please enter Folio Number field")
      // }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Try Again, You Didn't Enter The Price/Debenture Field")
      }
      // if (!newData.brokeragePerShare) {
      //   errorList.push("Try Again, You didn't enter the Brokerage/Debenture field")
      // }
    }
    if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
      if (props.familyFD.value == null || !props.familyFD.value) {
        errorList.push("Try Again, You Didn't Select The Family Field")
      }
      if (props.newFilterTypeFD == null) {
        errorList.push("Try Again, You Didn't Select The Type Field")
      }
      if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 8) {
        const reg = /\s/g;//To match a number
        if (reg.test(newData.certificateFolioNo) === true || newData.certificateFolioNo === "") {
          if (newData.certificateFolioNo === "" || newData.certificateFolioNo.trim() === "") {
            errorList.push("Try Again, Company Name / Bank Name Field Not Be Blank")
          }
        }
        if (!newData.certificateFolioNo) {
          errorList.push("Try Again, You Didn't Enter Company Name / Bank Name Field")
        }
      }
      if (newFilterTypeFD === 7) {
        const reg = /\s/g;//To match a number
        if (reg.test(newData.folioNumber) === true || newData.folioNumber === "") {
          if (newData.folioNumber === "" || newData.folioNumber.trim() === "") {
            errorList.push("Try Again, Certificate/Folio No. Field Not Be Blank")
          }
        }
        if (!newData.folioNumber) {
          errorList.push("Try Again, You Didn't Enter Certificate/Folio No. Field")
        }
        if (!newData.type) {
          errorList.push("Try Again, You Didn't Select Type Of Investment Field")
        }
      }
      if (!newData.date) {
        errorList.push("Try Again, You Didn't Select Date Of Investment Field")
      }
      if (newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
        if (!newData.availableCost) {
          errorList.push("Try Again, You Didn't Enter Amount Of Investment Field")
        }
      }
      if (newFilterTypeFD === 5) {
        if (!newData.totalCost) {
          errorList.push("Try Again, You Didn't Enter Amount Of Investment Field")
        }
      }
      if (!newData.domConversion) {
        errorList.push("Try Again, You Didn't Select Maturity Date Field")
      }
      if (!newData.maturityValue) {
        errorList.push("Try Again, You Didn't Enter Maturity Value Field")
      }
      if (interVal === 'yes') {
        if (!newData.interestReinvested) {
          errorList.push("Try Again, You Didn't Enter Interest Accrued Till Date Field")
        }
        if (!newData.fyStartDate) {
          errorList.push("Try Again, You Didn't Select Financial Year Starting With Field")
        }
      }
    }

    if (errorList.length < 1) {
      let dataToUpdate = newFilterTypeFD === 1 ? [...allEntry1] : newFilterTypeFD === 2 ? [...allEntry2] : newFilterTypeFD === 3 ? [...allEntry3] : newFilterTypeFD === 4 ? [...allEntry4] : newFilterTypeFD === 5 ? [...allEntry5] : newFilterTypeFD === 6 ? [...allEntry6] : newFilterTypeFD === 7 ? [...allEntry7] : [...allEntry8];
      // dataToAdd.push(newData);
      const index = oldData.tableData.id;

      if (newFilterTypeFD === 1 || newFilterTypeFD === 2 || newFilterTypeFD === 3 || newFilterTypeFD === 4) {
        newData.companyId = cpy === newData.companyId && newData.companyId.label === undefined ? newData.companyId : newData.companyId.label !== undefined ? newData.companyId.label : newData.companyId.label;

        newData.brokerId = brk === newData.brokerId && newData.brokerId.label === undefined ? newData.brokerId : newData.brokerId.label !== undefined ? newData.brokerId.label : newData.brokerId.label;

      }
      // if(newFilterTypeFD == 2){
      // newData.companyId = companyData
      // }
      if (oldData.tableData.editing === "update" && (newFilterTypeFD === 1 || newFilterTypeFD === 2 || newFilterTypeFD === 3 || newFilterTypeFD === 4) && (lockinperiod !== "" || dividend !== 0)) {
        newData.company = companyData;
        newData.broker = brokerData;
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.interestPerc = parseInt(dividend) || dividend;
        newData.frequencyId = parseInt(frequencyValue.value) || frequencyValue.value;
        newData.frequencyI = frequencyValue;
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = parseInt(cost)
        }
        if (newFilterTypeFD === 2 || newFilterTypeFD === 4) {
          newData.folioNumber = certificateorfolinumber;
        }
        let instId = newFilterTypeFD === 1 && newData.redeemConvertible === "Redeemable" ? 17 : newFilterTypeFD === 1 && newData.redeemConvertible === "Convertible" ? 16 : newFilterTypeFD === 3 && newData.redeemConvertible === "Redeemable" ? 19 : newFilterTypeFD === 3 && newData.redeemConvertible === "Convertible" ? 18 : newFilterTypeFD === 2 && newData.redeemConvertible === "Redeemable" ? 26 : newFilterTypeFD === 2 && newData.redeemConvertible === "Convertible" ? 25 : newFilterTypeFD === 4 && newData.redeemConvertible === "Redeemable" ? 28 : 27;

        newData.instrumentTypeId = instId;
      } else {
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = newData.totalCost;
        }
      }
      let instIds = newFilterTypeFD === 5 ? 29 : newFilterTypeFD === 6 ? 30 : newFilterTypeFD === 7 ? 32 : 31;

      if (newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8) {
        newData.instrumentTypeId = instIds;
        newData.interestAccrued = interVal;
        if (newFilterTypeFD === 7) {
          newData.type = poTypeVal === newData.type && newData.type.label === undefined ? newData.type : newData.type.label !== undefined ? newData.type.label : newData.type.label;
          newData.typeId = typeOfInvest;
          // newData.type = typeOfInvest.label;
        }
      }

      dataToUpdate[index] = newData
      newFilterTypeFD === 1 ? setAllEntry1([...dataToUpdate]) : newFilterTypeFD === 2 ? setAllEntry2([...dataToUpdate]) : newFilterTypeFD === 3 ? setAllEntry3([...dataToUpdate]) : newFilterTypeFD === 4 ? setAllEntry4([...dataToUpdate]) : newFilterTypeFD === 5 ? setAllEntry5([...dataToUpdate]) : newFilterTypeFD === 6 ? setAllEntry6([...dataToUpdate]) : newFilterTypeFD === 7 ? setAllEntry7([...dataToUpdate]) : setAllEntry8([...dataToUpdate]);
      resolve()
      setErrorMessages([])
      setIserror(false)
      handleCloseModalPopUp();
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
    }
  }
  const handelRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = newFilterTypeFD === 1 ? [...allEntry1] : newFilterTypeFD === 2 ? [...allEntry2] : newFilterTypeFD === 3 ? [...allEntry3] : newFilterTypeFD === 4 ? [...allEntry4] : newFilterTypeFD === 5 ? [...allEntry5] : newFilterTypeFD === 6 ? [...allEntry6] : newFilterTypeFD === 7 ? [...allEntry7] : [...allEntry8];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    await newFilterTypeFD === 1 ? setAllEntry1([...dataDelete]) : newFilterTypeFD === 2 ? setAllEntry2([...dataDelete]) : newFilterTypeFD === 3 ? setAllEntry3([...dataDelete]) : newFilterTypeFD === 4 ? setAllEntry4([...dataDelete]) : newFilterTypeFD === 5 ? setAllEntry5([...dataDelete]) : newFilterTypeFD === 6 ? setAllEntry6([...dataDelete]) : newFilterTypeFD === 7 ? setAllEntry7([...dataDelete]) : setAllEntry8([...dataDelete]);
    resolve()
    // props.parentCallback(true);
    props.lock(false, 1)
  }
  const handelSubmitTable = async () => {
    setLoading(true);
    if (allEntry1.length >= 1 || allEntry2.length >= 1 || allEntry3.length >= 1 || allEntry4.length >= 1 || allEntry5.length >= 1 || allEntry6.length >= 1 || allEntry7.length >= 1 || allEntry8.length >= 1) {

      let resultDataBuy = newFilterTypeFD === 1 ? allEntry1 : newFilterTypeFD === 2 ? allEntry2 : newFilterTypeFD === 3 ? allEntry3 : newFilterTypeFD === 4 ? allEntry4 : newFilterTypeFD === 5 ? allEntry5 : newFilterTypeFD === 6 ? allEntry6 : newFilterTypeFD === 7 ? allEntry7 : allEntry8;

      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultOpenBal = deepCloneBuy.map(function (o) {
        if (!o.instrumentTypeId === 29 || !o.instrumentTypeId === 30 || !o.instrumentTypeId === 31 || !o.instrumentTypeId === 32) {
          o.companyId = o.company.value;
        }
        if (o.instrumentTypeId === 17 || o.instrumentTypeId === 16 || o.instrumentTypeId === 18 || o.instrumentTypeId === 19 || o.instrumentTypeId === 25 || o.instrumentTypeId === 26 || o.instrumentTypeId === 27 || o.instrumentTypeId === 28) {
          o.companyId = o.company.value;
        }
        o.brokerId = o.broker ? o.broker.value : 1;
        o.instrumentId = newFilterTypeFD === 1 || newFilterTypeFD === 2 ? 2 : newFilterTypeFD === 5 || newFilterTypeFD === 6 || newFilterTypeFD === 7 || newFilterTypeFD === 8 ? 4 : 3;
        o.subAccountId = props.familyFD.value;
        if (o.instrumentTypeId === 29 || o.instrumentTypeId === 30 || o.instrumentTypeId === 31 || o.instrumentTypeId === 32) {
          o.interestReinvested = o.interestAccrued === 'yes' ? o.interestReinvested ? Number(o.interestReinvested) : 0 : 0;
          o.maturityValue = o.interestAccrued === 'yes' ? o.maturityValue ? Number(o.maturityValue) : 0 : o.instrumentTypeId === 29 ? Number(o.totalCost) : Number(o.availableCost);
          o.fyStartDate = o.interestAccrued === 'yes' ? o.fyStartDate : null;
          o.cumulativeNonCumulative = o.interestAccrued === 'yes' ? "Cumulative" : "Non Cumulative";
          o.interestAccrued = o.interestAccrued === 'yes' ? true : false;
          if (newFilterTypeFD === 7) {
            o.type = o.type;
          }
        }
        return o;
      })
      if (allEntry1 || allEntry2 || allEntry3 || allEntry4 || allEntry5 || allEntry6 || allEntry7 || allEntry8) {
        await AddFIForOpeningBalance(resultOpenBal).then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              Swal.fire("Fixed Income Details Added Successfully!", "", 'success');
              setAllEntry1([]);
              setAllEntry2([]);
              setAllEntry3([]);
              setAllEntry4([]);
              setAllEntry5([]);
              setAllEntry6([]);
              setAllEntry7([]);
              setAllEntry8([]);
              setErrorMessages([])
              setIserror(false)
              props.parentCallback(true);
              props.lock(false, 1)
              handleCloseModalPopUp();
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong!", "", 'error');
            setLoading(false)
          }
        }).catch(e => {
          Swal.fire("Something went wrong!", "", 'error');
          setError(e.message)
          setLoading(false)
        })
      }
      else {
        Swal.fire("please fill required fields!", "", 'info');
        setLoading(false)
      }
    }
    else {
      Swal.fire("please fill required information!", "", 'info');
      setLoading(false)
    }
  }
  const handelResetTable = () => {
    setAllEntry1([]);
    setAllEntry2([]);
    setAllEntry3([]);
    setAllEntry4([]);
    setAllEntry5([]);
    setAllEntry6([]);
    setAllEntry7([]);
    setAllEntry8([]);
    setSelScrip([]);
    setErrorMessages([]);
    setIserror(false)
    props.parentCallback(true);
    props.lock(false, 1);
    setLoading(false);
  }
  //submit unlisted companies
  const submitUnlisted = (e) => {
    e.preventDefault();
    if (unlistedComp !== '') {
      let subAccountId = props.familyFD.value
      let Id = entityId == null ? subAccountId : entityId;
      // let comId = newFilterTypeFD === 2 ? 10 : 20;
      let newacid;
      if (newFilterTypeFD === 2) {
        newacid = 21
      }
      if (newFilterTypeFD === 4) {
        newacid = 22
      }
      if (newFilterTypeFD === 1) {
        newacid = 12
      }
      if (newFilterTypeFD === 3) {
        newacid = 13
      }
      let data = {
        companyName: unlistedComp.trim(),
        // instrumentTypeId: newFilterTypeFD === 2 ? 10 : 20,
        instrumentTypeId: newacid,
        subAccountId: Id
      }
      // let data = { 
      //   "companyName": unlistedComp
      // }
      // addUnlistedScrip(data)
      PostUnlistedScripBySubId(data)
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              // const lastEle = 1
              // getCompanyList(lastEle);
              setUnlistedComp('');
              setUnlistedCompErr('');
              handleClose()
              Swal.fire("Scrip Added Successfully!", "", 'success');
              let t = res.data._response.data.companyName
              let v = res.data._response.data.companyId
              let comp = { label: t, value: v }
              if (newFilterTypeFD === 2 || newFilterTypeFD === 4 || newFilterTypeFD === 3 || newFilterTypeFD === 1) {
                unlistedCompany.unshift(comp)
              }
              // if (newFilterTypeFD === 3 || newFilterTypeFD === 1) {
              //   companyList.unshift(comp)
              // }
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong!", "", 'error');
            setLoading(false)
          }
        })
        .catch(e => {
          setError(e.message)
          setLoading(false)
        })
    } else {
      setUnlistedCompErr("Company name required!")
    }
  }
  // state clear for all family change
  const clearAll = () => {
    setAllEntry1([])
    setAllEntry2([])
    setAllEntry3([])
    setAllEntry4([])
    setAllEntry5([])
    setAllEntry6([])
    setAllEntry7([])
    setAllEntry8([])
  }
  // state clear for all family change
  props.childFunc.current = clearAll

  const disbtn = () => {
    // let info = newFilterTypeFD === 1 ? data : null;
    return familyFD && newFilterTypeFD
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const columns = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      headerStyle: {
        // backgroundColor: "#039bc5",
      },
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={companyList}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric',
      width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Debenture', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Debenture'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Brokerage/Debenture', field: 'brokeragePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.brokeragePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Brokerage/Debenture'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(2);
        props.rowData.totalBrokerage = totalBrokrage;
        return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Brokerage'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props}
        // />
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalBrokerage)}
        </span>
      ),
    },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = totalCost;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    // { field: 'target', title: 'Target', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'stopLoss', title: 'Stop Loss', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'recommendedBy', title: 'Recommended by', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // }
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {

        let t = props.rowData
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.interestPerc !== undefined) {
              setDividend(t.interestPerc);
            }
            if (t.frequencyId !== undefined) {
              // setDividendfrequency(t.frequency)
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod ? t.lockInPeriod : "0Month";
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            // if (t.folioNumber !== undefined) {
            //   setCertificateorfolinumber(t.folioNumber);
            // }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            // if (t.frequencyValue){
            //   setFrequencyValue(t.frequencyValue);
            // }
            // }
            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }
  ];

  const columns2 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={brokerUnlisted[0]}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            isDisabled='true'
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    // { title: 'Folio No', field: 'folioNumber', type: 'text' },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric', width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Debenture', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Debenture'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare)  // + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = totalCost;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    // { title: 'Lock in Period', field: 'lockInPeriod', width: 130 }
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData;
        return <span onClick={() => {
          if (t.tableData !== undefined) {

            if (t.interestPerc !== undefined) {
              setDividend(t.interestPerc);
            }
            if (t.frequencyId !== undefined) {
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            if (t.folioNumber !== undefined) {
              setCertificateorfolinumber(t.folioNumber);
            }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            // }
            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
      }
    }
  ];

  const columns3 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      headerStyle: {
        // backgroundColor: "#039bc5",
      },
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={companyList}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric',
      width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props}) =>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Bond', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Bond'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Brokerage/Bond', field: 'brokeragePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.brokeragePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Brokerage/Bond'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(2);
        props.rowData.totalBrokerage = totalBrokrage;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Brokerage'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props}
        // />
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalBrokerage)}
        </span>
      ),
    },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = totalCost;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    // { field: 'target', title: 'Target', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'stopLoss', title: 'Stop Loss', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'recommendedBy', title: 'Recommended by', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // }
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.interestPerc !== undefined) {
              setDividend(t.interestPerc);
            }
            if (t.frequencyId !== undefined) {
              // setDividendfrequency(t.frequency)
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            // if (t.folioNumber !== undefined) {
            //   setCertificateorfolinumber(t.folioNumber);
            // }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            // if (t.frequencyValue){
            //   setFrequencyValue(t.frequencyValue);
            // }

            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }
  ];

  const columns4 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={brokerUnlisted[0]}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            isDisabled='true'
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    // { title: 'Folio No', field: 'folioNumber', type: 'text' },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric', width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Bond', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Bond'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare)  // + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = totalCost;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    // { title: 'Lock in Period', field: 'lockInPeriod', width: 130 }
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData;
        return <span onClick={() => {
          if (t.tableData !== undefined) {

            if (t.interestPerc !== undefined) {
              setDividend(t.interestPerc);
            }
            if (t.frequencyId !== undefined) {
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            if (t.folioNumber !== undefined) {
              setCertificateorfolinumber(t.folioNumber);
            }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }

            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
      }
    }
  ];

  const columns5 = [
    {
      title: 'Company/Bank Name', field: 'certificateFolioNo', width: 100, type: 'text',
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Certificate No./Folio No.', field: 'folioNumber', type: 'text',
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Date of Investment', field: 'date', width: 70, type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Amount Invested', field: 'totalCost', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'domConversion', width: 100, type: "date",
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.domConversion).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Maturity Value', field: 'maturityValue', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.maturityValue)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        if (y === 'no') {
          let maturityValue = 0;
          maturityValue = props.rowData.totalCost;
          props.value = maturityValue;
          props.rowData.maturityValue = maturityValue;
        }
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Maturity Value'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      },
    },
    {
      title: 'Interest reinvested', field: 'interestAccrued', width: 130,
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        return (
          <>
            <input type="radio" id="yes" name="yesno" value="yes" onChange={e => props.onChange(e.target.value)} checked={y === 'yes' ? true : false} /> <label for="yes">Yes</label> &nbsp;
            <input type="radio" id="no" name="yesno" value="no" onChange={e => props.onChange(e.target.value)} checked={y === 'no' ? true : false} />
            <label for="no">No</label>
          </>
        )
      },
    },
    {
      title: 'Interest Accrued till date', field: 'interestReinvested', type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.interestAccrued === "yes" ? NumberSeperator(rowData.interestReinvested) : rowData.interestReinvested}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.interestReinvested = y === "yes" ? props.rowData.interestReinvested : undefined
        props.value = props.rowData.interestReinvested === undefined ? 0 : props.rowData.interestReinvested
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Interest Accrued till date'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      }
    },
    {
      title: 'Financial Year starting with', field: 'fyStartDate', type: 'date',
      editComponent: props => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.fyStartDate = y === "yes" ? props.rowData.fyStartDate : undefined;
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.rowData.interestAccrued === "no" ? null : props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
              disabled={y === 'yes' ? false : true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        rowData.interestAccrued === "yes" ? moment(rowData.fyStartDate).format('DD/MM/YYYY') : null
      )
    },
  ]

  const columns6 = [
    {
      title: 'Company/Bank Name', field: 'certificateFolioNo', width: 100,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Certificate No./Folio No.', field: 'folioNumber', width: 100,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Date of Investment', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Amount Invested', field: 'availableCost', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.availableCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'domConversion', width: 100, type: "date",
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.domConversion).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Maturity Value', field: 'maturityValue', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.maturityValue)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        if (y === 'no') {
          let maturityValue = 0;
          maturityValue = props.rowData.availableCost;
          props.value = maturityValue;
          props.rowData.maturityValue = maturityValue;
        }
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Maturity Value'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      },
    },
    {
      title: 'Interest reinvested', field: 'interestAccrued', width: 130,

      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        return (
          <>
            <input type="radio" id="yes" name="yesno" value="yes" onChange={e => props.onChange(e.target.value)} checked={y === 'yes' ? true : false} /> <label for="yes">Yes</label> &nbsp;
            <input type="radio" id="no" name="yesno" value="no" onChange={e => props.onChange(e.target.value)} checked={y === 'no' ? true : false} />
            <label for="no">No</label>
          </>
        )
      },
    },
    {
      title: 'Interest Accrued till date', field: 'interestReinvested', type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.interestAccrued === "yes" ? NumberSeperator(rowData.interestReinvested) : rowData.interestReinvested}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.interestReinvested = y === "yes" ? props.rowData.interestReinvested : undefined
        props.value = props.rowData.interestReinvested === undefined ? 0 : props.rowData.interestReinvested
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Interest Accrued till date'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      }
    },
    {
      title: 'Financial Year starting with', field: 'fyStartDate', type: 'date',
      editComponent: props => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.fyStartDate = y === "yes" ? props.rowData.fyStartDate : undefined;
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.rowData.interestAccrued === "no" ? null : props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
              disabled={y === 'yes' ? false : true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        rowData.interestAccrued === "yes" ? moment(rowData.fyStartDate).format('DD/MM/YYYY') : null
      )
    },
  ]

  const columns7 = [
    {
      title: 'Certificate No./Folio No.', field: 'folioNumber', width: 100,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Type of Investment', field: 'type', width: 100,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.typeId.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.typeId
                }
              }
              else {
                ddValue = props.rowData.typeId
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // onChange={handleInvestType}
            options={InvestmentType}
            name="investTypeID"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of Investment', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Amount Invested', field: 'availableCost', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.availableCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'domConversion', width: 100, type: "date",
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.domConversion).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Maturity Value', field: 'maturityValue', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.maturityValue)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.fyStartDate = y === "yes" ? props.rowData.fyStartDate : undefined;
        if (y === 'no') {
          let maturityValue = 0;
          maturityValue = props.rowData.availableCost;
          props.value = maturityValue;
          props.rowData.maturityValue = maturityValue;
        }
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Maturity Value'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      },
    },
    {
      title: 'Interest reinvested', field: 'interestAccrued', width: 130,
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        return (
          <>
            <input type="radio" id="yes" name="yesno" value="yes" onChange={e => props.onChange(e.target.value)} checked={y === 'yes' ? true : false} /> <label for="yes">Yes</label> &nbsp;
            <input type="radio" id="no" name="yesno" value="no" onChange={e => props.onChange(e.target.value)} checked={y === 'no' ? true : false} />
            <label for="no">No</label>
          </>
        )
      },

    },
    {
      title: 'Interest Accrued till date', field: 'interestReinvested', type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.interestAccrued === "yes" ? NumberSeperator(rowData.interestReinvested) : rowData.interestReinvested}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.interestReinvested = y === "yes" ? props.rowData.interestReinvested : undefined
        props.value = props.rowData.interestReinvested === undefined ? 0 : props.rowData.interestReinvested
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Interest Accrued till date'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      }
    },
    {
      title: 'Financial Year starting with', field: 'fyStartDate', type: 'date',
      editComponent: props => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.rowData.interestAccrued === "no" ? null : props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
              disabled={y === 'yes' ? false : true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        rowData.interestAccrued === "yes" ? moment(rowData.fyStartDate).format('DD/MM/YYYY') : null
      )
    },
  ]

  const columns8 = [
    {
      title: 'Company/Bank Name', field: 'certificateFolioNo', width: 100,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Certificate No./Folio No.', field: 'folioNumber', width: 100,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Date of Investment', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Amount Invested', field: 'availableCost', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.availableCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Amount Invested'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'domConversion', width: 100, type: "date",
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.domConversion).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Maturity Value', field: 'maturityValue', width: 100, type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.maturityValue)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        if (y === 'no') {
          let maturityValue = 0;
          maturityValue = props.rowData.availableCost;
          props.value = maturityValue;
          props.rowData.maturityValue = maturityValue;
        }
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Maturity Value'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      },
    },
    {
      title: 'Interest reinvested', field: 'interestAccrued', width: 130,
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        return (
          <>
            <input type="radio" id="yes" name="yesno" value="yes" onChange={e => props.onChange(e.target.value)} checked={y === 'yes' ? true : false} /> <label for="yes">Yes</label> &nbsp;
            <input type="radio" id="no" name="yesno" value="no" onChange={e => props.onChange(e.target.value)} checked={y === 'no' ? true : false} />
            <label for="no">No</label>
          </>
        )
      },
    },
    {
      title: 'Interest Accrued till date', field: 'interestReinvested', type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.interestAccrued === "yes" ? NumberSeperator(rowData.interestReinvested) : rowData.interestReinvested}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.interestReinvested = y === "yes" ? props.rowData.interestReinvested : undefined
        props.value = props.rowData.interestReinvested === undefined ? 0 : props.rowData.interestReinvested
        return <div className={CleavTablFirstTxtField}><div className={y === 'yes' ? CleavTablSecondTxtField : cleavedisabledField} >
        <Cleave
          className= "MuiInputBase-input MuiInput-input"
          placeholder='Interest Accrued till date'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled={y === 'yes' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={y === 'yes' ? false : true} />;
      }
    },
    {
      title: 'Financial Year starting with', field: 'fyStartDate', type: 'date',
      editComponent: props => {
        let y = props.rowData.interestAccrued ? props.rowData.interestAccrued : 'no';
        props.rowData.fyStartDate = y === "yes" ? props.rowData.fyStartDate : undefined;
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.rowData.interestAccrued === "no" ? null : props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
              disabled={y === 'yes' ? false : true}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        rowData.interestAccrued === "yes" ? moment(rowData.fyStartDate).format('DD/MM/YYYY') : null
      )
    },
  ]

  return (
    <div>
      <Container fluid>
        {/* {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
        {newFilterTypeFD === 1 ?
          <Row className='openingBalanceStockListPTbl'>
            <MaterialTable
              title="Listed Debenture (Fixed Income)"
              data={allEntry1}
              isLoading={loading}
              columns={columns}
              options={{
                actionsColumnIndex: -1,
                search: false,
                addRowPosition: "first",
                paging: false
              }}
              minRows={0}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    handelRowAdd(newData, resolve, reject)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handelRowUpdate(newData, oldData, resolve, reject)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    handelRowDelete(oldData, resolve)
                    // props.parentCallback(true);
                  }),
              }}
            />
          </Row> :
          newFilterTypeFD === 2 ?
            <Row className='openingBalanceStockUnlPTbl'>
              <MaterialTable
                title="Unlisted Debenture (Fixed Income)"
                data={allEntry2}
                isLoading={loading}

                columns={columns2}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                  paging: false
                }}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      handelRowAdd(newData, resolve, reject)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      handelRowUpdate(newData, oldData, resolve, reject);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      handelRowDelete(oldData, resolve, reject)
                    }),
                }}
              />
            </Row> :
            newFilterTypeFD === 3 ?
              <Row className='openingBalanceStockListPTbl'>
                <Col md="12" className="BuyTbl">
                  <MaterialTable
                    title="Listed Bonds (Fixed Income)"
                    data={allEntry3}
                    isLoading={loading}
                    columns={columns3}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false
                    }}
                    //  actions={actions}
                    components={{
                      Toolbar: props => (
                        <MTableToolbar
                          {...props}
                          actions={props.actions.filter(a => a.tooltip === "Add")}
                        />
                      )
                    }}
                    editable={{
                      onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                          handelRowAdd(newData, resolve, reject)
                        }),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handelRowUpdate(newData, oldData, resolve, reject)
                        }),
                      onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                          handelRowDelete(oldData, resolve)
                          // props.parentCallback(true);
                        }),
                      // onRowAddCancelled : rowData => {
                      //   setIserror(false)
                      //   setErrorMessages([])
                      // },
                      // onRowUpdateCancelled : rowData => {
                      //   setIserror(false)
                      //   setErrorMessages([])
                      // }
                    }}
                  />
                </Col>
              </Row> :
              newFilterTypeFD === 4 ?
                <Row className='openingBalanceStockUnlPTbl'>
                  <Col md="12" className="BuyTbl">
                    <MaterialTable
                      title="Unlisted Bonds (Fixed Income)"
                      data={allEntry4}
                      isLoading={loading}
                      columns={columns4}
                      options={{
                        actionsColumnIndex: -1,
                        addRowPosition: "first",
                        search: false,
                        paging: false
                      }}
                      //   actions={actions}
                      components={{
                        // Cell: (props) => (
                        //   <Tooltip title={props.value ? props.value : ''}>
                        //     <MTableCell {...props} />
                        //   </Tooltip>
                        // ),
                        Toolbar: props => (
                          <MTableToolbar
                            {...props}
                            actions={props.actions.filter(a => a.tooltip === "Add")}
                          />
                        )
                      }}
                      editable={{
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            handelRowAdd(newData, resolve, reject)
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            handelRowUpdate(newData, oldData, resolve, reject)
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            handelRowDelete(oldData, resolve)
                            // props.parentCallback(true);
                          }),
                        // onRowAddCancelled : rowData => {
                        //   setIserror(false)
                        //   setErrorMessages([])
                        // },
                        // onRowUpdateCancelled : rowData => {
                        //   setIserror(false)
                        //   setErrorMessages([])
                        // }
                      }}
                    />
                  </Col>
                </Row> :
                newFilterTypeFD === 5 ?
                  <Row className='openingBalanceFDTbl'>
                    <Col md="12" className="BuyTbl">
                      <MaterialTable
                        title="FD (Fixed Income)"
                        data={allEntry5}
                        isLoading={loading}
                        columns={columns5}
                        options={{
                          actionsColumnIndex: -1,
                          addRowPosition: "first",
                          search: false,
                          paging: false
                        }}
                        //   actions={actions}
                        components={{
                          // Cell: (props) => (
                          //   <Tooltip title={props.value ? props.value : ''}>
                          //     <MTableCell {...props} />
                          //   </Tooltip>
                          // ),
                          Toolbar: props => (
                            <MTableToolbar
                              {...props}
                              actions={props.actions.filter(a => a.tooltip === "Add")}
                            />
                          )
                        }}
                        editable={{
                          onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                              handelRowAdd(newData, resolve, reject)
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              handelRowUpdate(newData, oldData, resolve, reject)
                            }),
                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              handelRowDelete(oldData, resolve)
                              // props.parentCallback(true);
                            }),
                          onRowAddCancelled: rowData => {
                            setIserror(false)
                            setErrorMessages([])
                          },
                          onRowUpdateCancelled: rowData => {
                            setIserror(false)
                            setErrorMessages([])
                          }
                        }}
                      />
                    </Col>
                  </Row> :
                  newFilterTypeFD === 6 ?
                    <Row className='openingBalanceFDTbl'>
                      <Col md="12" className="BuyTbl">
                        <MaterialTable
                          title="RD (Fixed Income)"
                          data={allEntry6}
                          isLoading={loading}
                          columns={columns6}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                          //   actions={actions}
                          components={{
                            // Cell: (props) => (
                            //   <Tooltip title={props.value ? props.value : ''}>
                            //     <MTableCell {...props} />
                            //   </Tooltip>
                            // ),
                            Toolbar: props => (
                              <MTableToolbar
                                {...props}
                                actions={props.actions.filter(a => a.tooltip === "Add")}
                              />
                            )
                          }}
                          editable={{
                            onRowAdd: newData =>
                              new Promise((resolve, reject) => {
                                handelRowAdd(newData, resolve, reject)
                              }),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handelRowUpdate(newData, oldData, resolve, reject)
                              }),
                            onRowDelete: oldData =>
                              new Promise((resolve, reject) => {
                                handelRowDelete(oldData, resolve)
                                // props.parentCallback(true);
                              }),
                            onRowAddCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            },
                            onRowUpdateCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            }
                          }}
                        />
                      </Col>
                    </Row> :
                    newFilterTypeFD === 7 ?
                      <Row className='openingBalanceFDTbl'>
                        <Col md="12" className="BuyTbl">
                          <MaterialTable
                            title="Post Office (Fixed Income)"
                            data={allEntry7}
                            isLoading={loading}
                            columns={columns7}
                            options={{
                              actionsColumnIndex: -1,
                              addRowPosition: "first",
                              search: false,
                              paging: false
                            }}
                            //   actions={actions}
                            components={{
                              // Cell: (props) => (
                              //   <Tooltip title={props.value ? props.value : ''}>
                              //     <MTableCell {...props} />
                              //   </Tooltip>
                              // ),
                              Toolbar: props => (
                                <MTableToolbar
                                  {...props}
                                  actions={props.actions.filter(a => a.tooltip === "Add")}
                                />
                              )
                            }}
                            editable={{
                              onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                  handelRowAdd(newData, resolve, reject)
                                }),
                              onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                  handelRowUpdate(newData, oldData, resolve, reject)
                                }),
                              onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                  handelRowDelete(oldData, resolve)
                                  // props.parentCallback(true);
                                }),
                              onRowAddCancelled: rowData => {
                                setIserror(false)
                                setErrorMessages([])
                              },
                              onRowUpdateCancelled: rowData => {
                                setIserror(false)
                                setErrorMessages([])
                              }
                            }}
                          />
                        </Col>
                      </Row> :
                      newFilterTypeFD === 8 ?
                        <Row className='openingBalanceFDTbl'>
                          <Col md="12" className="BuyTbl">
                            <MaterialTable
                              title="PPF (Fixed Income)"
                              data={allEntry8}
                              isLoading={loading}
                              columns={columns8}
                              options={{
                                actionsColumnIndex: -1,
                                addRowPosition: "first",
                                search: false,
                                paging: false
                              }}
                              //   actions={actions}
                              components={{
                                // Cell: (props) => (
                                //   <Tooltip title={props.value ? props.value : ''}>
                                //     <MTableCell {...props} />
                                //   </Tooltip>
                                // ),
                                Toolbar: props => (
                                  <MTableToolbar
                                    {...props}
                                    actions={props.actions.filter(a => a.tooltip === "Add")}
                                  />
                                )
                              }}
                              editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                    handelRowAdd(newData, resolve, reject)
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    handelRowUpdate(newData, oldData, resolve, reject)
                                  }),
                                onRowDelete: oldData =>
                                  new Promise((resolve, reject) => {
                                    handelRowDelete(oldData, resolve)
                                    // props.parentCallback(true);
                                  }),
                                onRowAddCancelled: rowData => {
                                  setIserror(false)
                                  setErrorMessages([])
                                },
                                onRowUpdateCancelled: rowData => {
                                  setIserror(false)
                                  setErrorMessages([])
                                }
                              }}
                            />
                          </Col>
                        </Row> :
                        null
        }
        <div className="mt-5 d-flex justify-content-center">
          <Stack spacing={4} direction="row" >
            <Button variant="contained" color="primary" id="submitButton" className="btn btn-primary" onClick={handelSubmitTable} disabled={!disbtn()} style={{ width: "5rem" }}>
              Save
            </Button>
            <Button variant="contained" color="primary" className="btn btn-secondary" onClick={handelResetTable}>
              Cancel
            </Button>
          </Stack>
        </div>
        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
      </Container>
      {/* unlisted scrip jsx */}
      <Container>
        <Modal show={show} onHide={handleClose} animation={false} size="sm" centered>
          <Modal.Header closeButton>
            <Modal.Title>{newFilterTypeFD === 1 ? "Listed Debenture Scrip" : newFilterTypeFD === 2 ? "Unlisted Debenture Scrip" : newFilterTypeFD === 3 ? "Listed Bonds Scrip" : "Unlisted Bonds Scrip"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form >
              <div className="form-group mt-2 mb-2">
                <label for="inputUserName">Company Name</label>
                <input className="form-control mt-2" placeholder={newFilterTypeFD === 1 ? "listed debenture scrip" : newFilterTypeFD === 2 ? "unlisted debenture scrip" : newFilterTypeFD === 3 ? "listed bonds scrip" : "unlisted bonds scrip"} type="text" autoComplete="off" name="unlistedCompName" onChange={handleUnlisted} />
                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      {/* additional data modal */}
      <Modal show={showlistedPref} onHide={handlecloseDescModal} animation={true} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="m-auto"><h5 className='text-center'>{newFilterTypeFD === 1 ? "Listed Debenture" : newFilterTypeFD === 2 ? "Unlisted Debenture" : newFilterTypeFD === 3 ? "Listed Bonds" : "Unlisted Bonds"}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >
            <Row>
              {newFilterTypeFD === 2 || newFilterTypeFD === 4 ?
                <Col md="6" className="mb-2">
                  <>
                    <Form.Label>Certificate/Folio Number<span className="text-danger h6">*</span></Form.Label>
                    <Form.Control type="text"
                      onChange={(e) => {
                        setCertificateorfolinumber(e.target.value);
                        setFormErr({ ...formErr, certificateorfolinumber: '' })
                      }} autoComplete="off"
                      name="certificateorfolinumber" id="certificateorfolinumber" value={certificateorfolinumber} style={{ textTransform: "uppercase" }} />
                  </>
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.certificateorfolinumber}</div> : null}
                </Col>
                : null}
              <Col md="6" className="mb-2">
                <>
                  <Form.Label className="d-block">Lock in Period</Form.Label>
                  <Form.Control type="text"
                    onChange={(e) => setLockinperiod(e.target.value)} autoComplete="off"
                    name="lockinperiod" id="lockinperiod" value={lockinperiod} className="lockinperiodno" />
                  <Dropdown
                    value={time}
                    name='period'
                    options={periodList}
                    onChange={HandleChangeTime}
                    className="SelectClass lockinperiodlist"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                  // onInputChange={HandleInput}
                  />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                </>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Redeemable/Convertible</Form.Label>
                <Dropdown
                  value={redeemableorconvertible}
                  name='RedeemableOrConvertible'
                  options={redeemableOrConvertibleList}
                  onChange={handleChangeConvert}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                  isDisabled={headerId !== 0 ? true : false}
                // onInputChange={HandleInput}
                />

              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Cumulative/Non Cumulative</Form.Label>
                <Dropdown
                  value={cumulativeornoncumulative}
                  name='CumulativeOrNoncumulative'
                  options={cumulativeNonCumulativeList}
                  onChange={handleChangeCumulative}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                // onInputChange={HandleInput}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Interest %<span className="text-danger h6">*</span></Form.Label>
                <Form.Control type="number"
                  onChange={(e) => {
                    // setDividend(e.target.value.replace(/[^0-9:]/ig,''))
                    setDividend(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)
                    setFormErr({ ...formErr, dividend: "" })
                  }} autoComplete="off"
                  name="dividend" id="dividend" value={dividend} />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.dividend}</div> : null}

              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Date of Maturity/Conversion </Form.Label>
                <DatePicker
                  className="form-control"
                  selected={dateofmaturityorconversion}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setDateofmaturityorconversion(date)}
                  minDate={date}
                  // maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  disabled={headerId !== 0 ? true : false}
                />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.mdate}</div> : null}
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Interest Frequency</Form.Label>
                <Dropdown
                  value={frequencyValue}
                  name='dividendFrequency'
                  options={dividendfrequency}
                  onChange={handleChangeFrequency}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                />
                {/* <input type="text"
                        onChange={(e) => setDividendfrequency(e.target.value)} autoComplete="off"
                        name="dividendfrequency" id="dividendfrequency" value={dividendfrequency} /> */}
              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                {
                  cumulativeornoncumulative.value === "Cumulative" ?

                    <Cleave
                      className='form-control'
                      autoComplete='off'
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      name="maturityvalue"
                      id="maturityvalue"
                      value={maturityvalue}
                      onChange={(e) => {
                        setMaturityvalue(e.target.rawValue);
                        setFormErr({ ...formErr, maturityvalue: '' })
                      }}
                    />
                    :
                    <Cleave 
                      className='form-control'
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      onChange={(e) => {
                        setFormErr({ ...formErr, maturityvalue: '' })
                      }} 
                      name="maturityvalue" 
                      id="maturityvalue" 
                      value={cost ? cost : 0}
                      disabled />
                }
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityValue}</div> : null}
              </Col>


            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" className="mx-2 w100" onClick={submitPreModal} >
            Save
          </Button><Button variant="secondary" className="mx-2 w100" onClick={handleCloseModalPopUp} >
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default FDopeningBal;