/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TableCell, TableContainer, IconButton, Table, Paper, TableHead, TableRow, TableBody, TableFooter,
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility, Delete } from "@material-ui/icons";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import { Stack } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import $ from "jquery";
import swal from 'sweetalert';
import _ from "lodash";
import Loader from "../loadingAnimation/index";
import Auth from '../../modules/Auth'
import { EntityList } from '../../pages/Stocks/StocksAPI'

import { outerGridArtFamily, outerGridArtIndividual, innerGridArtFamily, innerGridArtIndividual, getArtTransaction, deleteArtTransaction } from '../../pages/Art/ArtAPI'


import Art_Sell from '../ArtDetails/ArtSell';
import Art_Details from '../ArtDetails/Art_Details';
import ArtPreview from '../ArtDetails/ArtPreview';

import SearchBar from "material-ui-search-bar";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

const Art_Folio = () => {
  const [loading, setLoading] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [entityId, setEntityId] = useState(null);
  const [script, setScript] = useState("All");
  const [artHeaderId, setArtHeaderId] = useState(0);
  const [artTransaction, setArtTransaction] = useState({});
  const [artSellTransaction, setArtSellTransaction] = useState({});
  const [artPreviewTransaction, setArtPreviewTransaction] = useState({});
  const [showInner, setShowInner] = useState(false);
  const [sell, setSell] = useState([]);
  const [entitySell, setEntitySell] = useState([]);
  const [subId, setSubId] = useState(null);
  const [subName, setSubName] = useState("");
  const [subNameEnt, setSubNameEnt] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [proArtData, setProArtData] = useState([]);
  const [entityData, setEntityData] = useState([])
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [artTitleId, setArtTitleId] = useState(null);
  const [depositToName, setDepositToName] = useState('');


  const [showArt, setShowArt] = useState(false);
  const [showArtSell, setShowArtSell] = useState(false);
  const [showArtPreview, setShowArtPreview] = useState(false);

  const [iconsTest, setIconTest] = useState(AddIcon);

  const [searched, setSearched] = useState("");
  const userID = Auth.getsubcriberId();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if(state){
      setEntityId(state.subAccountId);
      setSubNameEnt(state.entityName)
      let titleId = state.titleId;
      let entityName = state.entityName
      getBuyGetData(entityId, titleId, entityName);
    }
  }, [state, entityId])

  useEffect(() => {

    setLoading(true);
    getArtFamilyOuterData()

    if (userID) {
      getEntityList()
    }
  }, [userID]);

  const columns = [
    { title: "Type of Art", field: "typeOfProperty" },

    {
      title: "Amount Invested",
      field: "amountInvested",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.amountInvested)}
        </span>
      ),
    },

    {
      title: "Current Market Price",
      field: "currentMarketPrice",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice)}
        </span>
      ),
    },
    {
      title: "Overall Gain",
      field: "overallGain",
      type: "numeric",
      render: (rowData) => (

        (rowData.currentMarketPrice - rowData.amountInvested) >= 0 ?

          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.amountInvested)}
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.amountInvested)}
          </span>
      ),
    },
    {
      title: "Overall Gain %",
      field: "overallGainPer",
      type: "numeric",
      render: (rowData) => (
        (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100) >= 0 ?

          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100))} %
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100)} %

          </span>
      ),
    },

  ];

  //round of percentage
  function preciseMathDotRound(value, precision = 2) {
    let valueData = isFinite(value)

    if (valueData) {

      return parseFloat(value.toFixed(precision));
    } else {
      return 0;
    }
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  //all art family data
  const getArtFamilyOuterData = () => {
    outerGridArtFamily().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          let FamilyArt = res.data._response.data.artFamilyOuterGrid;
          setProArtData(FamilyArt);
          GetGridData(FamilyArt);
          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });
        } else {
          setFilteredData([]);
          setProArtData([]);
          console.log('errror in getArtFamilyOuterData api', res.data._response.message);
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  // all fixed income entity wise data 
  const getPropertyEntityOuterData = async (entityId) => {
    await outerGridArtIndividual(entityId)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res, 'entity data');
          if (res.data._response.status === 1) {
            //+ button code
            $(".MuiTableRow-root").each(function () {
              let val = $('.material-icons ', this).text()
              if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
            });

            let EntityData = res.data._response.data.artEntityOuterGrid;
            setEntityData(EntityData)
            setLoading(false)

            GetEntityGridData(EntityData)

            setScript("All")
            setSearched("")
          } else {
            setFilteredEntiryData([]);
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log("error");
        setLoading(false)
      });
  }
  //getEntityList
  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            setEntityRadio(entity);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //handle snapshot
  const handleView = (selectedRow, a) => {
    //  console.log(a,"liste");
    getArtTransaction(a.artId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setArtHeaderId(a.artId);
          const result = res.data._response.data.artTransactionDetails;
          setArtPreviewTransaction(result);
          setShowArtPreview(true)
        }
      }
    });

  }

  const handleDelete = (selectedRow, a) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setLoading(true)
          deleteArtTransaction(a.artId).then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                setLoading(false)
                swal(res.data._response.message, {
                  title: "Deleted",
                  icon: "success",
                });

                const del = sell.filter(sells => a.artId !== sells.artId)
                setSell(del)
                const delEntity = entitySell.filter(Esells => a.artId !== Esells.artId)
                setEntitySell(delEntity)
                handleCloseInner();
              }
              else {

                setLoading(false)
              }
            }
          })
            .catch(err => {
              console.log(err);
              setLoading(false)
            })
        } else {
          swal("Cancelled", "Your  data is safe ", "error");
          setLoading(false)
        }
      });
  }


  const onRefreshArt = () => {

    getArtFamilyOuterData();
    // getPropertyEntityOuterData(subId)
    if (entityId) getPropertyEntityOuterData(entityId);
    // setLoading(true);
  }

  const onTransactionGridRefresh = () => {
    getBuyGetData(
      subId,
      artTitleId,
      subName,
      depositToName,
    );
  }
  //open LoanDetils Modal
  const openTransaction = async () => {
    // await setEntity('Family');
    setShowArt(true)
  };

  // buysell Inner GRID
  const getBuyGetData = async (
    subAccountId,
    titleId,
    entityName,
    index
  ) => {

    // console.log(entityName,"buysell");
    setSubId(subAccountId);
    setArtTitleId(titleId);

    setSubName(entityName)
    setLoading(true);
    // entity
    if (entityId && titleId) {
      await innerGridArtIndividual(entityId, titleId)
        .then(async (res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              let data = res.data._response.data.artIndividualEntityInfo;
              setEntitySell(data);
              setShowInner(true);
            } else {
              setLoading(false);
              setEntitySell([]);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })

    } else {
      // family
      if (subAccountId) {

        await innerGridArtFamily(subAccountId)
          .then((res) => {
            setLoading(false);
            if (res.data._response.status === 1) {
              let data = res.data._response.data.artEntityInfo;

              setSell(data);
              setShowInner(true);
            } else {
              setSell([]);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    }
    setLoading(false);

  };
  //refresh form data
  // const refreshForm = (a) => {
  //   if (a !== undefined || a !== null) {
  //     getArtTransaction(a).then((res) => {
  //       // debugger
  //       if (res.status === 200) {
  //         setLoading(false)
  //         if (res.data._response.status === 1) {
  //           //  setShowBorrowings(true);
  //           setArtHeaderId(a);
  //           const result = res.data._response.data.artTransactionDetails;
  //           setArtTransaction(result);
  //         }
  //       }
  //     }).catch(e => {
  //       console.log(e, 'error in alertMyRow function');
  //       setLoading(false)
  //     });
  //   }
  // }


  //open transactions
  const alertMyRow = (selectedRow, a) => {
    setLoading(true)
    getArtTransaction(a.artId).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        if (res.data._response.status === 1) {
          setArtHeaderId(a.artId);
          const result = res.data._response.data.artTransactionDetails;
          setArtTransaction(result);
          setShowArt(true);
        }
      }
    }).catch(e => {
      console.log(e, 'error in alerMyRow function');
      setLoading(false)
    });
  };


  //filter Art
  const handleScripData = (e) => {
    let selected = e.target.value;
    setScript(selected)
    setSearched("")
    // family data filter
    // if (entity === "Family") {
    //   let sorted = selected === "All" ? proArtData : proArtData.filter((a) => a.typeOfInvestmentId === Number(selected))

    // } else {
    //   //entity data  filter
    //   let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.typeOfInvestmentId === Number(selected)))

    // }
  }

  //details pannel icon parent child
  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };

  const handleReset = () => {
    setArtTitleId(null);
    setDepositToName(null);
    setSubId(null);
  }
  //modal close
  const handleClose = async () => {
    setShowArt(false)
    setArtTransaction({});
    setArtSellTransaction({})
    setArtHeaderId(0);
    setShowArtSell(false);

    // await setEntity('Family');
    // await setEntityId(null);
    await onRefreshArt();
    onTransactionGridRefresh();
  }
  const handleCloseSell = () => {
    setArtTransaction({});
    setArtSellTransaction({})
    setArtHeaderId(0);
    setShowArtSell(false);
  }
  const handleClosePreview = () => {
    setShowArtPreview(false);
    setArtPreviewTransaction({});
    setArtHeaderId(0);
  }
  const handleCloseInner = async () => {
    // await setEntity('Family');
    await onRefreshArt();
    setSubName("");
    handleReset();
    setShowInner(false);
  }

  //filter family grid
  const GetGridData = (propFamilyData) => {
    let check = [];
    propFamilyData.forEach((item, i) => {
      if (check.indexOf(item.instrument_Name) === -1) {
        check.push(item.instrument_Name);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(propFamilyData);
      propFamilyData.forEach((item, i) => {
        if (checkdata === item.instrument_Name) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfProperty: item.instrument_Name,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amountInvested: amountInvested,
            currentMarketPrice,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfProperty: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,


          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);
  };

  //filter entity outer grid 
  const GetEntityGridData = (EntityStock) => {

    let check = [];
    EntityStock.forEach((item, i) => {
      if (check.indexOf(item.instrument_Name) === -1) {
        check.push(item.instrument_Name);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        // quantity: 0,
        // amtOutstanding: 0,
        // typeOfProperty: "",
        // closingBalance: 0,
        // currentValue: 0
      };

      EntityStock.forEach((item, i) => {
        if (checkdata === item.instrument_Name) {

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;
          mainData = {
            amountInvested: amountInvested,
            currentMarketPrice: currentMarketPrice,
            typeOfProperty: item.instrument_Name,
            id: index,
          };

          let normalData = {
            ...item,
            typeOfProperty: item.artTitleName,
            parentId: index,
            id: index + 1,
            // company: item.typeOfInvestment,
          };

          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredEntiryData([...parentData, ...childData]);
  };

  //entity chnaged
  const handleChangeEntity = async (e) => {
    // setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      setEntityId(null);
      getPropertyEntityOuterData();
      setScript("All");
      setSearched("")
      setSubNameEnt("")
      //+ button code
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        let button = $("button", this);
        $(button).html("<span class='material-icons'>add</span>");
      });
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName == e.target.value;
      });
      await setSubNameEnt(e.target.value);
      await setEntityId(data.subAccountId);
      await getPropertyEntityOuterData(data.subAccountId)
    }
    // console.log({ subAccountName: e.target.value }, "handle change");
  };
  const handleSell = (e, a) => {
    getArtTransaction(a.artId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setArtHeaderId(a.artId);
          const result = res.data._response.data.artTransactionDetails;
          setArtSellTransaction(result);
          setShowArtSell(true);
        }
      }
    });
  }

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // search function
  const requestSearch = (searchedVal) => {

    setSearched(searchedVal)
    const filteredRows = proArtData.filter((row) => {
      return row.instrument_Name.includes(searchedVal.toUpperCase()) || row.instrument_Name.includes(searchedVal.toLowerCase()) || row.instrument_Name.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.instrument_Name) === -1) {
        check.push(item.instrument_Name);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(propFamilyData);
      filteredRows.forEach((item, i) => {
        if (checkdata === item.instrument_Name) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfProperty: item.instrument_Name,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amountInvested: amountInvested,
            currentMarketPrice,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfProperty: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    let checkEntity = [];
    forentity.forEach((item, i) => {
      if (checkEntity.indexOf(item.instrument_Name) === -1) {
        checkEntity.push(item.instrument_Name);
      }
    });
    let parentDataEntity = [];
    let childDataEntity = [];
    checkEntity.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        // quantity: 0,
        // amtOutstanding: 0,
        // typeOfProperty: "",
        // closingBalance: 0,
        // currentValue: 0
      };

      forentity.forEach((item, i) => {
        if (checkdata === item.instrument_Name) {

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;
          mainData = {
            amountInvested: amountInvested,
            currentMarketPrice: currentMarketPrice,
            typeOfProperty: item.instrument_Name,
            id: index,
          };

          let normalData = {
            ...item,
            typeOfProperty: item.artTitleName,
            parentId: index,
            id: index + 1,
            // company: item.typeOfInvestment,
          };

          childDataEntity.push(normalData);
        }
      });
      parentDataEntity.push(mainData);
    });
    setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      getPropertyEntityOuterData(entityId)
    } else {
      getArtFamilyOuterData()
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center py-3">Art Folio</h4>
        </Col>
      </Row>
      <FormControl className="mt-4 ">

        <RadioGroup
          row
          color="primary"
          defaultValue="start"
          aria-label="Entity"
          name="row-radio-buttons-group"
          value={entity}
          onChange={handleChangeEntity}
          style={{ marginTop: "-3rem" }}


        >
          <FormControlLabel
            value="Family"
            control={<Radio />}
            label="Family"
          />
          {entityRadio.map((a) => (
            <FormControlLabel
              key={a.subAccountName}
              value={a.subAccountName}
              control={<Radio />}
              label={a.subAccountName}
              disabled={a.active === 0 ? true : false}
            />
          ))}

        </RadioGroup>
      </FormControl>
      <Row >
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="flex-end"
          alignItems="flex-start" mb={3} >
          {entityId == null ? null :
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openTransaction()}
                style={{ height: "2rem" }}
              >
                Transactions
              </Button>
            </>

          }

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={script}
            label="Type"
            onChange={handleScripData}
            style={{ minWidth: 160, paddingLeft: "12px" }}

          >
            <MenuItem disabled >
              <em>Art Type</em>
            </MenuItem>
            <MenuItem value={"All"}>All</MenuItem>

          </Select>
        </Stack>
      </Row>

      <Row>
        <Box sx={{ flexGrow: 1 }} mb={2}>
          <Grid container spacing={2} columns={16}
            direction="row"
            justifyContent="end"
            alignItems="flex-end">
            <Grid item xs="auto">
              <SearchBar
                value={searched}
                onChange={(searchVal) => { requestSearch(searchVal) }}
                onCancelSearch={() => cancelSearch()}
                style={{
                  // margin: "0 auto",
                  maxWidth: 250,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Row>
      <Row>
        <Col md="12">
          <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">
            {/* {loading ? (
                            <Loader />
                        ) : (
                            <> */}
            <MaterialTable
              title=""
              data={
                entity === "Family" ? filteredData : filteredEntiryData
              }
              columns={columns}

              parentChildData={(row, rows) =>
                rows.find((a) => a.id === row.parentId)
              }
              isLoading={loading}
              options={{
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                },
                exportButton: false,
                search: false,
                toolbarButtonAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                emptyRowsWhenPaging: false,
                rowStyle: (rowData) => {
                  return {
                    pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                    backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                  };
                },
              }}

              onTreeExpandChange={(row, rows) => {
                handleIcon(row, rows);
              }}
              icons={{
                DetailPanel: iconsTest,
              }}

              components={{
                Body: (props) => {
                  let totalDataS = {
                    investmentCost: 0,
                    overAllGainSum: 0,
                    overAllGainSumPer: 0,
                    marketPrice: 0,

                  }
                  props.renderData.forEach((rowData) => {
                    totalDataS.investmentCost += rowData.amountInvested;
                    totalDataS.overAllGainSum += rowData.currentMarketPrice - rowData.amountInvested;
                    totalDataS.overAllGainSumPer = (totalDataS.overAllGainSum / totalDataS.investmentCost) * 100;
                    totalDataS.marketPrice += rowData.currentMarketPrice;
                  })

                  return (
                    <>
                      <MTableBody {...props} />
                      {
                        <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                          <TableRow>
                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={2} align="right">Total</TableCell>
                            <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.investmentCost)}</TableCell>
                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.marketPrice)}</TableCell>
                            <TableCell style={totalDataS.overAllGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.overAllGainSum)}</TableCell>
                            <TableCell style={totalDataS.overAllGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.overAllGainSumPer))}%</TableCell>
                            
                          </TableRow>
                        </TableFooter>

                      }
                    </>
                  )

                },
                Toolbar: (props) => {
                  let totalData = {
                    investmentCost: 0,
                    currentMarketPrice: 0,
                    overAllGain: 0,
                    overAllGainPer: 0
                  }
                  props.renderData.forEach((rowData) => {

                    // let e = (((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity) / (rowData.previousLivePrice * 100) * rowData.quantity)
                    totalData.investmentCost += rowData.amountInvested
                    totalData.currentMarketPrice += rowData.currentMarketPrice
                    totalData.overAllGain += (rowData.currentMarketPrice - rowData.amountInvested)
                    totalData.overAllGainPer += (((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100)
                  })
                  return (

                    <>
                      <MTableToolbar {...props} />
                      {
                        script != "All" || script === "All" ?

                          <div className="row mb-2 p-2">
                            <div className="col-md-2 col-xl-2">
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card  order-card">
                                <div className="card-block">
                                  <h3 className="text-right"><span style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</span></h3>
                                  <p className="m-b-0">Invested Amount</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.currentMarketPrice < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.currentMarketPrice).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Current Market Price</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.overAllGain < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.overAllGain).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Overall Gain</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.overAllGainPer < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.overAllGainPer).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Overall Gain %</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          : null}

                    </>
                  )
                }
              }}
              onRowClick={(evt, selectedRow) => {
                if (selectedRow.tableData.childRows == null) {
                  // console.log(selectedRow)
                  getBuyGetData(
                    selectedRow.subAccountId,
                    selectedRow.artTitleId,
                    selectedRow.subAccountName,
                    selectedRow.tableData.path[0]
                  );
                }
              }}

            />
            {/* </>
                        )} */}
          </div>

          <Art_Details
            showArt={showArt}
            onHideArt={handleClose}
            artHeaderId={artHeaderId}
            entityId={entityId}
            artTransaction={artTransaction}
          />

          <Art_Sell
            sellShow={showArtSell}
            onhideSell={handleCloseSell}
            artHeaderId={artHeaderId}
            artSellTransaction={artSellTransaction}
            handleCloseInner={handleCloseInner}
          />
          <ArtPreview
            artPreview={showArtPreview}
            onhideArtPreview={handleClosePreview}
            // artHeaderId={artHeaderId}
            artPreviewTransaction={artPreviewTransaction}
          />


          <Container
            className="LoginContainer formWraper"
            component="main"
            maxWidth="xs"
            tabIndex={-1}
          >
            <Modal
              isOpen={showInner}
              toggle={() => handleCloseInner()}
              size="xl"
              className="col-12 stockdetailmodal"
            >
              <ModalHeader charCode="Y" toggle={() => handleCloseInner()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                <h5 className="text-center">Art Detail</h5>
              </ModalHeader>
              <ModalBody>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  {loading ? (
                    <Loader />
                  ) : null}

                  {subId && state === null ?
                    /* family wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subName}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Title
                            </TableCell>


                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Current Market Price
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain %
                            </TableCell>

                            <TableCell align="center" width="7%" style={{ textAlign: "center" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {sell.filter((a) => a.show !== 0).map((a) => (
                            <TableRow
                              key={a.artId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.artTitleName}
                              </TableCell>

                              <TableCell align="right">
                                {a.amountInvested === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amountInvested)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.currentMarketPrice === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {(a.currentMarketPrice - a.amountInvested) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice - a.amountInvested)}
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice - a.amountInvested)}
                                  </span>
                                }
                              </TableCell>

                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100))} %
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100)} %

                                  </span>}
                              </TableCell>


                              <TableCell align="center">
                              <Tooltip title="Sell">
                                  <IconButton>
                                    <HorizontalRuleIcon fontSize="small" onClick={(e) => handleSell(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>
                               
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer> :

                    /* entity wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subNameEnt}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Title
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Current Market Price
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain %
                            </TableCell>

                            <TableCell align="center" width="7%">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {entitySell.filter((a) => a.show !== 0).map((a) => (
                            <TableRow
                              key={a.artId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.artTitleName}
                              </TableCell>

                              <TableCell align="right">
                                {a.amountInvested === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amountInvested)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.currentMarketPrice === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {(a.currentMarketPrice - a.amountInvested) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice - a.amountInvested)}
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPrice - a.amountInvested)}
                                  </span>
                                }
                              </TableCell>

                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100))} %
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPrice - a.amountInvested) / a.amountInvested) * 100)} %

                                  </span>}
                              </TableCell>


                              <TableCell align="center">
                              <Tooltip title="Sell">
                                  <IconButton>
                                    <HorizontalRuleIcon fontSize="small" onClick={(e) => handleSell(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>

                            </TableRow>
                          ))}


                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >


                          </TableRow>


                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Paper>
              </ModalBody>
            </Modal>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default Art_Folio