/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  EntityList,
  PDFupload,
  mapDataForContractNote,
  getContractNoteBroker,
  getPreviewOfContractNote
} from "../../pages/Stocks/StocksAPI";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import InfoIcon from '@mui/icons-material/Info';
import Loader from '../loadingAnimation/index';
import {
  TableCell,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import moment from "moment";

const ContractNote = (props) => {
  const { showContract, onHideContract } = props;
  const ref = useRef();
  const refpass = useRef();
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadbtn, setUploadbtn] = useState(false);
  const [selBroker, setSelBroker] = useState([]);
  const [selBrokerOption, setSelBrokerOption] = useState([]);
  const [previewNote, setPreviewNote] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [PDFData, setPDFData] = useState([]);
  const [openPreview, setOpenPreview] = useState(false)

  useEffect(() => {
    if (!showContract) {
      return;
    }
    getEntityList();
    getContractBroker();
    // if (previewNote === true){
    // }
  }, [showContract, previewNote])

  useEffect(() => {
    if (previewData && openPreview) {
      getContractPreview();
    }
  }, [previewData, openPreview])

  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {

          let entity = res.data._response.data.subaccountDetails;
          let entityData = [];
          entity &&
            entity.map((data) => {
              entityData.push({
                value: data.subAccountId,
                label: data.subAccountName,
                disabled: data.active
              });
            });
          setEntity(entityData);
          setLoading(false)
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };
  const getContractBroker = async () => {
    setLoading(true);
    await getContractNoteBroker().then((res) => {
      if (res.status === 200) {
        let contractBroker = res.data._response.data.contractNoteBrokers
        let contractBrokerData = [];
        contractBroker && contractBroker.map((c) => {
          return (contractBrokerData.push({
            value: c.brokerId,
            label: c.brokerName,
          }))
        });
        setSelBrokerOption(contractBrokerData)
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e, "contract note fetch broker error");
      });
  }
  const getContractPreview = async () => {
    setLoading(true);
    await getPreviewOfContractNote(previewData).then((res) => {
      if (res.status === 200) {
        setPreviewNote(true);
        let details = res.data._response.data.stockHeaderDetailsData;
        setPDFData(details);
        setLoading(false);
      }
    })
      .catch((e) => {
        console.log(e, "preview not found")
        setLoading(false);
      })
  }

  const handleFamily = (selected) => {
    setSelEntity(selected);
    setFormErr({ ...formErr, selEntity: "" });
  };
  const handleBroker = (selected) => {
    setSelBroker(selected)
    setFormErr({ ...formErr, selBroker: "" });
  };
  const handleFile = (e) => {
    setUploadFile(e.target.files[0]);
    let clearpass = refpass.current.value = "";
    setPassword(clearpass);
    setFormErr({ ...formErr, uploadFile: "" });
  }
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const errHandel = () => {
    let formErr = {};
    let isValid = true;
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please select Broker";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please select Entity!";
    }
    if (uploadFile.length < 1) {
      isValid = false;
      formErr["uploadFile"] = "Please select File";
    }
    setFormErr(formErr);
    return isValid;
  }
  const UploadpdfFile = async () => {
    let isValid = errHandel();

    // let bro_id = selBroker.value
    let bro_name = selBroker.label
    let sub_id = selEntity.value
    let pass = password ? password : ""

    const formData = new FormData();
    formData.append('pdf', uploadFile);
    // formData.append('brokerId',bro_id);
    formData.append('brokerName', bro_name);
    formData.append('subaccountId', sub_id);
    formData.append('password', pass)
    if (uploadFile.length < 1) {
      isValid = false;
      formErr["uploadFile"] = "Please select File";
    }
    if (isValid === true) {
      setLoading(true)
      await PDFupload(formData).then((res) => {
        if (res.data.statusCode === "3") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Cannot decrypt PDF, the password is incorrect!',
          })
          setLoading(false)
        } else if (res.data.statusCode === "2") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Password is required!',
          })
          setLoading(false)
        } else if (res.data.statusCode === "1") {
          // Mapdata(res.data.data);
          // setPDFData(res.data.data);
          setPreviewData(res.data.data)
          setOpenPreview(true);
          setLoading(false);
        } else if (res.data.statusCode === "4") {
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: 'Please select relevant contract note according to broker which you had selected!',
          })
          setLoading(false)
        }
        else {
          Swal("Something went wrong!", "", "info");
        }
      }).catch((err) => {
        setPreviewNote(false);
        console.log(err, "err in upload file")
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      })
    }
  }
  const Mapdata = async () => {
    const datas = PDFData;
    setLoading(true);
    await mapDataForContractNote(datas).then((res) => {
      if (res.data._response.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'PDF Upload Successfully',
        })
        setSelEntity([]);
        setSelBroker([]);
        setUploadFile([]);
        ref.current.value = "";
        setPassword("");
        refpass.current.value = "";
        setPreviewNote(false);
        setLoading(false);
      }
      else if (res.data._response.status === 2) {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'You are already upload this contract note!',
        })
        setLoading(false)
      }
      else {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
        setLoading(false)
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e, "error in data mapping")
      })
  }
  const handleReset = () => {
    setSelBroker([]);
    setSelEntity([])
    setFormErr({});
    setUploadFile([]);
    setUploadbtn(false);
    setPassword("")
  }
  const handleCloseModal = () => {
    handleReset();
    onHideContract();
  }

  // const handleConfirm = async (datas) => {
  //   await mapDataForContractNote(datas).then((res) => {
  //     debugger
  //     if (res.data._response.status === 1) {
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Success',
  //         text: 'PDF Upload Successfully',
  //       })
  //       setSelEntity([]);
  //       setSelBroker([]);
  //       setUploadFile([]);
  //       ref.current.value = "";
  //       setPassword("");
  //       refpass.current.value = "";
  //       setPreviewNote(false)
  //     }
  //   })
  //     .catch((e) => {
  //       setPreviewNote(false)
  //       console.log(e, "error in data mapping")
  //     })
  // }
  const handleCancel = () => {
    setPreviewNote(false)
  }
  return (
    <>
      <Modal isOpen={showContract} toggle={() => handleCloseModal()} size="lg" backdrop="static" centered>
        {
          loading === true ? <Loader /> : null
        }
        <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
          <h3 className="text-center">Upload Contract Note</h3>
        </ModalHeader>
        <ModalBody>
          <Form className="border border-1 rounded p-3" >
            <Row className="form-set">
              <Form.Group className="col-md-6">
                <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                <Select
                  value={selEntity}
                  onChange={handleFamily}
                  options={Entity}
                  name="entityId"
                  isOptionDisabled={(option) => option.disabled === 0}
                />
                {formErr ? (
                  <div
                    className="mt-2"
                    style={{ fontSize: 12, color: "red" }}
                  >
                    {formErr.selEntity}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>&nbsp;
                <Select
                  value={selBroker}
                  onChange={handleBroker}
                  options={selBrokerOption}
                  name="brokerId"
                  autoComplete="new-broker"
                />
                {formErr ? (
                  <div
                    className="mt-2"
                    style={{ fontSize: 12, color: "red" }}
                  >
                    {formErr.selBroker}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="form-set">
              <Form.Group className="col-md-6">
                <label for="formFileMultiple">Upload File<span className="text-danger h6">*</span></label>
                <input className="form-control"
                  ref={ref}
                  type="file"
                  id="formFileMultiple"
                  accept='application/pdf'
                  onChange={handleFile}
                  name="uploadFile"
                  disabled={uploadbtn === true ? true : false}
                />
                {formErr ? (
                  <div
                    className="mt-2"
                    style={{ fontSize: 12, color: "red" }}
                  >
                    {formErr.uploadFile}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  ref={refpass}
                  placeholder="Enter password"
                  type={passwordShown === true ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  autoComplete="new-password"
                />
                <Form.Check
                  type="checkbox" label={passwordShown === true ? "Hide Password" : "Show Password"} onChange={togglePassword}
                />
              </Form.Group>
            </Row>
            <Row className="form-set">
              <Col md="12" className="text-center mt-3">
                <Button variant="primary" className="mx-2 w100"
                  onClick={UploadpdfFile}
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  className="mx-2 w100"
                  type="reset"
                  onClick={handleReset}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <fieldset>
              <InfoIcon fontSize="medium" style={{ color: "#005488" }} /> <b style={{ fontSize: "14px" }}>Note</b>
              <p>If you couldn't find your broker then kindly email us <span><Link to='#' onClick={(e) => {
                window.open("mailto:accomptsolutions@gmail.com", "_blank");
                e.preventDefault();
              }}>accomptsolutions@gmail.com</Link></span>
              </p>
            </fieldset>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={previewNote} toggle={() => handleCancel()} size="xl" backdrop="static" centered>
        {
          loading === true ? <Loader /> : null
        }
        <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCancel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
          <h3 className="text-center">Contract Note Preview</h3>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader />
          ) : null}
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <p className="m0"> Entity : {selEntity.label}</p>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" width="9%">
                      Scrip
                    </TableCell>
                    <TableCell align="right" width="9%">
                      Trans.
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Date
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Broker
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Quantity
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Price/Share
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Total Brokerage
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Cost
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Sale Value
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Profit/Loss
                    </TableCell>
                    <TableCell align="right" width="7%">
                      Balance Quantity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflow: "auto" }}>
                  {PDFData.map((a) => (
                    <TableRow
                      key={a.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="left">
                        {a.companyName}
                      </TableCell>
                      <TableCell align="left">
                        {a.transactStockType}
                      </TableCell>
                      <TableCell align="right">
                        {moment(a.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="right">
                        {a.brokerName}
                      </TableCell>
                      <TableCell align="right">
                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                        {new Intl.NumberFormat('en-IN').format(a.quantity)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.pricePerShare)}
                      </TableCell>
                      {/* <TableCell align="right">
                                {a.brokeragePerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.brokeragePerShare)}
                              {/* </TableCell> */}
                      <TableCell align="right">
                        {a.totalBrokerage === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalBrokerage)}
                      </TableCell>

                      <TableCell align="right">
                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)}
                      </TableCell>
                      {/* //perfefct */}
                      <TableCell align="right">
                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                        {a.transactStockType === "Sell" || a.transactStockType === "BuyBack" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost) : "0.00"}
                      </TableCell>
                      <TableCell align="right">
                        {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss)}
                      </TableCell>

                      <TableCell align="right">
                        {new Intl.NumberFormat('en-IN').format(a.quantity)}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow
                    // key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: "#00629f", color: "white" }} onClick={() => Mapdata()}>Confirm</Button> &nbsp;&nbsp;
          <Button style={{ backgroundColor: "#00629f", color: "white" }} onClick={() => handleCancel()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
export default ContractNote;