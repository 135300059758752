import axios from '../../modules/helpers/axiosInstance';
import axiosa from 'axios';
import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index';

let StockLiveUrl = "http://5.189.157.40:10021/api";
let AccomptServiceUrl = "http://5.189.157.40:10028/api/AccomptCommonServices"
// let UserLiveUrl = "http://5.189.157.40:10023/api";
// let StockLiveUrlocal = "https://localhost:5001/api";
const userID = Auth.getsubcriberId();


const addStocks = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/StockDetails/AddStockDetails`,
        //url: `https://localhost:44394/api/StockDetails/AddStockDetails`,
        data: body
    });
}


const updateStock = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/StockDetails/UpdateStockDetails`,
        //url: `https://localhost:44394/api/StockDetails/UpdateStockDetails`,
        data: body
    });
}


const IntraDaycheck = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/StockDetails/FetchListForIntraDay`,
        data: body
    })
}

const deleteStock = async (stockId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/DeleteStocks?stockid=${stockId}`,
       // url: `https://localhost:44394/api/StockDetails/DeleteStocks?stockid=${stockId}`,
       
    })
}
const addOpeningStocks = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/DashboardManagement/AddStockForOpeningBalance`,
        data: data
    });
}
const GetStocksList = async (include) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/FetchStocksGrid?s_id=${userID}&include=${include}`,
        // url: `https://localhost:5001/api/StockDetails/FetchStocksGrid?s_id=${userID}&include=${include}`
    });
}

const getSignalRList = async () => {
    return axios({
        method: 'GET',
        url: `${StockLiveUrl}/KiteConnect/CallSignalIR?s_id=${userID}`
       //url: `https://localhost:44394/api/KiteConnect/CallSignalIR?s_id=${userID}`

    });
}

const CompanyListByInsTypeId = async (InsType) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10024/api/FixedIncomeManagement/FetchCompanyByInsTypeId?insTypeId=${InsType}`
        //    url: `https://localhost:44307/api/FixedIncomeManagement/FetchCompanyByInsTypeId?insTypeId=${InsType}`
    })
}
const EntityWiseStockList = async (subId,include) => {
    return axios({
        method: 'GET',
        url: `${StockLiveUrl}/StockDetails/FetchIndividualEntity?sub_id=${subId}&include=${include}`
        // url: `https://localhost:5001/api/StockDetails/FetchIndividualEntity?sub_id=${subId}&include=${include}`
    })
}

const GetBuySellGrid = async (subAccountId, companyId) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10022/api/DashboardManagement/FetchEntityInfo?sub_id=${subAccountId}&co_id=${companyId}`
    })
}
const GetFolioNoSell = async (subAccountId, companyId,InstTypeID) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/FetchStocksFolioNumbers?sub_id=${subAccountId}&co_id=${companyId}&insType_id=${InstTypeID}`
    })
}

const GetStockTransaction = async (headerId) => {
    return axios({
        method: 'GET',
        url: `${StockLiveUrl}/StockDetails/GetStockTransactionByID?headerid=${headerId}`
    })
}
const GetDMergerDetails = async (headerId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/DashboardManagement/GetDeMergerDetails?headerid=${headerId}`
    })
}
const brokerList = async (subAccountId) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetBrokerList?userid=${userID}`
    })
}
const CompanyList = async () => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetCompanyList`
    })
}
const SellingCompany = async (subId, brokerId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/SellingCompanyName?sub_id=${subId}&bro_id=${brokerId}`
    })
}
const CompanyListBySubId = async (subId) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/FetchCompaniesBySubAccountId?sub_id=${subId}`
        // url: `${settings.API_URL}/UserManagment/FetchCompaniesBySubAccountId?sub_id=${subId}`
    })
}
const updateCompany = async (data) => {
    return axios({
        method: "PUT",
        url: `http://5.189.157.40:10023/api/UserManagment/UpdateCompany`,
        // url: `${settings.API_URL}/UserManagment/UpdateCompany`,
        data: data,
    });
};
const updateCompanyList = async (data) => {
    return axios({
        method: "PUT",
        url: `http://5.189.157.40:10023/api/UserManagment/UpdateCompaniesList`,
        // url: `${settings.API_URL}/UserManagment/UpdateCompaniesList`,
        data: data,
    });
};
const AvilableQuantity = async (brokerId, subAccountId, companyId) => {
    return axios({
        method: 'GET',
        url: `${StockLiveUrl}/StockDetails/GetBrokerQuantity?b_id=${brokerId}&sub_id=${subAccountId}&c_id=${parseInt(companyId)}`
    })
}
const ValuationList = async () => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/GetStockValuationList`
    })
}

const addBonus = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/DashboardManagement/BonusShareAction`,
        data: data
    })
}
const addSplitStocks = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/SplitShareAction`,
        data: data,
    });
};

const addRightsStocks = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/AddRightsShares`,
        data: data,
    });
};
const addIpoStocks = async (data) => {
    return axios({
        method: "POST",
        // url: `https://localhost:44311/api/DashboardManagement/AddIPOShareAction`,
        url: `${settings.API_URL}/DashboardManagement/AddIPOShareAction`,
        data: data,
    });
};
const AlloatedIpoLists = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/GetAllUnlistedIPO?s_id=${userID}`,
        // url: `https://localhost:44311/api/DashboardManagement/GetAllUnlistedIPO`,
    });
}
const getAlloatedIpoData = async (id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/GetUnlistedIPODetails?Id=${id}`,
        // url: `https://localhost:44311/api/DashboardManagement/GetUnlistedIPODetails?Id=${id}`,
    });
}
const deleteAlloatedIpo = async (id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/RemoveAllotedIPO?Id=${id}`,
        // url: `https://localhost:44311/api/DashboardManagement/RemoveAllotedIPO?Id=${id}`,
    });
}
const EntityList = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/GetSubaccountList?subId=${userID}`,
    });
}

const SelectionList = async (sub_id, bro_id, co_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/StockDetails/FetchSelectionCompanyDetails?sub_id=${sub_id}&bro_id=${bro_id}&co_id=${co_id}`,
    });
}

const SelectionListUpdate = async (stock_id) => {
    return axios({
        method: "GET",
        url: `${StockLiveUrl}/StockDetails/SelectionDataForStocksUpdate?stock_id=${stock_id}`,
    });
}

const addstockMerger = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/DashboardManagement/AddMergerAPI`,
        data: data,
    })
}
const addUnlistedScrip = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/UserManagment/AddCompany`,
        data: data,
    });
};
const PostPartlyPaid = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/PartlyPaidAPI`,
        data: data,
    });
};


const GetDividendFrequency = async () => {
    return axios({
        method: "GET",
        url: `http://5.189.157.40:10023/api/UserManagment/GetDividendFrequency`

    });
}



const addDemerger = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/DeMergerAPI`,
        data: data,
    });
}

const updateBonus = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateBonusShares`,
        data: data,
    });
};

const updateSplit = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateSplitShares`,
        data: data,
    });
};
const updateRights = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateRightsShares`,
        data: data,
    });
};
const updateMerger = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateMergerShares`,
        data: data,
    });
};
const updateIPO = async (data) => {
    return axios({
        method: "POST",
        // url: `https://localhost:44311/api/DashboardManagement/UpdateIPOShares`,
        url: `${settings.API_URL}/DashboardManagement/UpdateIPOShares`,
        data: data,
    });
};

const GetUnlistedScripBySubId = async (subId, typeId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/FetchUnlistedCompany?sub_id=${subId}&instrumenttype_id=${typeId}`
    })
}

const PostUnlistedScripBySubId = async (data) => {
    return axios({
        method: "POST",
        url: `${StockLiveUrl}/StockDetails/AddUnListedCompany`,
        data: data,
    });
};
const updatePartlyPaid = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdatePartlyPaidAPI`,
        data: data,
    });
};
const FetchDate = async (sub_id, co_id, bro_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/FetchDateAPI?sub_id=${sub_id}&co_id=${co_id}&bro_id=${bro_id}`,
    });
};
const PostBuyBack = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/BuyBackAPI`,
        data: data,
    });
};
const updateBuyBack = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateBuyBack`,
        data: data,
    });
};
const FetchBrokerDropDownData = async (sub_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/GetBroker?sub_id=${sub_id}`,
    });
};
const updateDmerger = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/UpdateDeMergerAPI`,
        data: data,
    });
};


const FetchCompanyDropDownData = async (sub_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/GetCompany?sub_id=${sub_id}`,
    });
};

const GetQuantityUnlistedComapny = async (subId, cId) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/FetchRemainingQuantityForUnlistedCompany?sub_id=${subId}&c_id=${cId}`,
    });
};

const GetUnlistedSellingCompany = async (subId) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/GetUnlistedSellingCompany?sub_id=${subId}`,
    });
}

const PreferenceBuy = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/StockDetails/AddStockDetails`,
        data: data,
    });
}

const FetchPreferanceDetails = async (sub_id, co_id, bro_id,TransacStockType) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/FetchPreferanceDetails?sub_id=${sub_id}&co_id=${co_id}&bro_id=${bro_id}&con_red=${TransacStockType}`,
    });
}
const SellingCompanies = async (sub_id, bro_id, ins_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/DashboardManagement/FetchCompanyByInsTypeId?sub_id=${sub_id}&bro_id=${bro_id}&insType_id=${ins_id}`,
    });
}
const AddRedeem = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/RedeemableAPI`,
        data: data,
    });
}


const addConvertible = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/DashboardManagement/ConvertibleAPI`,
        data: data
    });
}

const DeleteStocksTransactions = async (headerId) => {
    return axios({
        method: "DELETE",
        url: `${settings.API_URL}/StockDetails/RemoveEntireStockTransactions?stockheaderId=${headerId}`,
    });
}
const Addbroker = async (data) => {
    return axios({
        method: "POST",
        url: `http://5.189.157.40:10023/api/UserManagment/addBroker`,
        data: data
    });
}
const FetchContract_data = async (sub_id,bro_id,com_id,instu_id,path) => {
    return axios({
        method: "GET",
        url: `https://localhost:44394/api/PDF/UploadPDF2?sub_id=${sub_id}&broker_id=${bro_id}&com_id=${com_id}&instrumentType_id=${instu_id}&path=${path}`,
    });
}
const AddContractData = async (data) =>{
    return axios({
        method:'POST',
        url:`https://localhost:44394/api/PDF/AddPDFData`,
        data:data
    })
}
const PDFupload = async (data) => {
    return axiosa({
        method: 'POST',
        url: `http://5.189.157.40:10029/pdf`,
        // url:`http://localhost:9010/pdf`,
        data: data
    })
}
const getPreviewOfContractNote = async (data) => {
    return axios({
        method: 'POST',
        url: `${AccomptServiceUrl}/SendPDFResponse`,
        data: data,
    })
}
const mapDataForContractNote = async (data) => {
    return axios({
        method: 'POST',
        url: `${AccomptServiceUrl}/PdfUpload`,
        data: data
    })
}
const getContractNoteBroker = async () => {

    return axios({

        method: 'GET',

        url: 'http://5.189.157.40:10021/api/StockDetails/FetchContractNoteBrokers'

    })

}

const DeleteOtherTransaction = async (StockHeaderid, StockId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/RemoveOtherTransaction?stockheaderid=${StockHeaderid}&stockid=${StockId}`
    })
}

const getSingleStockTransactionDataPreview = async (stockId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/FetchStockDetailsPreview?stock_id=${stockId}`
    })
}

const getOuterGridCalculationSubId = async (subID,InstTypeID) => {
    return axios({
        method: 'GET',
        url: `${AccomptServiceUrl}/GetTotalCalculationBySubAccountId?sub_id=${subID}&instrumentTypeId=${InstTypeID}`
        // url: `https://localhost:5001/api/StockDetails/GetTotalCalculationBySubAccountId?sub_id=${subID}&instrumentTypeId=${InstTypeID}`

    })
}
const getOuterGridCalculationBySubscriberId = async (InstTypeID) => {
    return axios({
        method: 'GET',
        url: `${AccomptServiceUrl}/GetTotalCalculationBySubscriberId?subs_id=${userID}&instrumentTypeId=${InstTypeID}`
        // url: `https://localhost:5001/api/StockDetails/GetTotalCalculationBySubscriberId?subs_id=${userID}&instrumentTypeId=${InstTypeID}`

    })
}
const SidebarByRoleid = async (userrole) => {
	return axios({
		method: 'GET',
		url: `${settings.API_URL}/UserManagment/SidebarByRoleId?roleId=${userrole}`
	});
};
const getTradingTransactionData = async () => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/StockDetails/FetchStockTragetStopLossById?Id=${userID}`
        // url:`https://localhost:5001/api/StockDetails/FetchStockTragetStopLossById?Id=${userID}`
    })
}



export {
    GetStocksList,
    brokerList,
    addStocks,
    CompanyList,
    EntityList,
    ValuationList,
    SellingCompany,
    AvilableQuantity,
    addBonus,
    addOpeningStocks,
    EntityWiseStockList,
    addSplitStocks,
    addRightsStocks,
    SelectionList,
    addstockMerger,
    addUnlistedScrip,
    addIpoStocks,
    GetBuySellGrid,
    GetStockTransaction,
    updateStock,
    addDemerger,
    updateBonus,
    updateSplit,
    updateRights,
    updateMerger,
    updateIPO,
    PostPartlyPaid,
    GetUnlistedScripBySubId,
    PostUnlistedScripBySubId,
    FetchDate,
    PostBuyBack,
    updateBuyBack,
    FetchBrokerDropDownData,
    FetchCompanyDropDownData,
    GetQuantityUnlistedComapny,
    GetUnlistedSellingCompany,
    updatePartlyPaid,
    GetDMergerDetails,
    updateDmerger,
    deleteStock,
    GetDividendFrequency,
    PreferenceBuy,
    FetchPreferanceDetails,
    AddRedeem,
    addConvertible,
    IntraDaycheck,
    SellingCompanies,
    DeleteStocksTransactions,
    CompanyListByInsTypeId,
    AlloatedIpoLists,
    getAlloatedIpoData,
    deleteAlloatedIpo,
    Addbroker,
    PDFupload,
    FetchContract_data,
    AddContractData,
    getSignalRList,
    DeleteOtherTransaction,
    CompanyListBySubId,
    updateCompany,
    updateCompanyList,
    GetFolioNoSell,
    getSingleStockTransactionDataPreview,
    getOuterGridCalculationSubId,
    getOuterGridCalculationBySubscriberId,
    mapDataForContractNote,
    SidebarByRoleid,
    getTradingTransactionData,
    getContractNoteBroker,
    getPreviewOfContractNote,
    SelectionListUpdate
};
    
