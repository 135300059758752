/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { GetDividendFrequency } from "../../../pages/Stocks/StocksAPI";
import { AddFixedDeposite, UpdateFixedDeposite, getInterestData } from "../FDAPI";
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import swal from "sweetalert";
import Loader from "../../loadingAnimation/index";
import Dropdown from '../../CommonComponent/Dropdown';
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { periodList } from '../../../modules/helpers/constant'
import Cleave from 'cleave.js/react';
import FDInterest from '../other_transactions/FDInterest';
import Maturity from "./Maturity";
import { Tooltip } from "@mui/material";

const FD = (props) => {
    const { FDHeaderId, FixDTransaction, onRefreshFD, show5, Fdentity } = props;

    const [fdi, setfdi] = useState(false);
    const [FDMaturity, setFDMaturity] = useState(false);

    const [fdId, setFdId] = useState(null)
    const [formErr, setFormErr] = useState([]);
    const [folioNumber, setFolioNumber] = useState({ folioNumber: "" });
    const [company, setCompany] = useState({ company: "" });
    const [iDate, setIDate] = useState(null);
    const [invAmount, setInvAmount] = useState({ totalCost: "" });
    const [interest, setInterest] = useState({ interestPerc: "" });
    const [selFrequency, setSelFrequency] = useState([]);
    const [frequencyValue, setFrequencyValue] = useState([]);
    const [tenurePeriod, setTenurePeriod] = useState("");
    const [tenure, setTenure] = useState([{ label: "Month", value: "Month" }]);
    const [tenureValue, setTenureValue] = useState("Month");
    const [mDate, setMDate] = useState(null);
    const [maturityVal, setMaturityVal] = useState({ MaturityValue: "" });
    const [lockinperiod, setLockinperiod] = useState("")
    const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
    const [lockPeriodValue, setLockPeriodValue] = useState("Month");
    const [loading, setLoading] = useState(false);
    const [reinvestedRadio, setreinvestedRadio] = useState("no");
    const [interestAcc, setInterestAcc] = useState({ interestReinvested: 0 })
    const [startDate, setStartDate] = useState(null);
    const [dividendfrequency, setDividendfrequency] = useState([]);
    const [isInterest, setIsInterest] = useState(false);
    const [interestdata, setInterestData] = useState([]);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEffect(() => {
        getDividedFrequencyList();
    }, [])

    //getdividend list
    const getDividedFrequencyList = async () => {
        await GetDividendFrequency()
            .then((res) => {

                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let dividend = res.data._response.data.dividendfrequencyDetails;
                        let dividendData = [];
                        dividend &&
                            dividend.map((data) => {
                                dividendData.push({
                                    value: data.frequencyId,
                                    label: data.frequencyName,
                                });
                            });
                        setDividendfrequency(dividendData);
                        if (FDHeaderId === 0) {
                            setSelFrequency(dividendData[1]);
                        }
                    }
                } else {
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
            });
    };

    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        const reg = /\s/g;//To match a number
        if (reg.test(company.company) === true || company.company === "") {
            if (company.company === "" || company.company.trim() === "") {
                isValid = false;
                formErr["company"] = "Please Enter Company!";
            }
        }
        if (!iDate) {
            isValid = false;
            formErr["iDate"] = "Please Select Date !";
        }
        if (!invAmount.totalCost) {
            isValid = false;
            formErr["totalCost"] = "Please Enter Invested Amount!";
        }
        if (!mDate) {
            isValid = false;
            formErr["date"] = "Please Select Maturity Date !";
        }
        // if(!folioNumber.folioNumber){
        //     isValid = false;
        //     formErr["folioNumber"] = "Please Enter Certificate/Folio number!";
        // }
        if (reg.test(folioNumber.folioNumber) === true || folioNumber.folioNumber === "") {
            if (folioNumber.folioNumber === "" || folioNumber.folioNumber.trim() === "") {
                isValid = false;
                formErr["folioNumber"] = "Please Enter Certificate/Folio number!";
            }

        }
        if (mDate && iDate) {
            if (fixTimezoneOffset(mDate) === fixTimezoneOffset(iDate)) {
                isValid = false;
                formErr["date"] = "Maturity Date Cannot Be Same As Investment Date!";
            }
        }
        // if (!maturityVal.MaturityValue) {
        //     isValid = false;
        //     formErr["MaturityValue"] = "Please enter maturity Value !";
        // }
        // if ((interestAcc.interestReinvested == "") && (reinvestedRadio == "yes")) {
        //     isValid = false;
        //     formErr["interestAcc"] = "Please enter interest Accrued !";
        // }
        if ((!startDate) && (reinvestedRadio === "yes")) {
            isValid = false;
            formErr["startDate"] = "Please Select Date !";
        }
        setFormErr(formErr);
        return isValid;
    };


    //handlefoliono
    const handleValueF = (e) => {
        const { name, value } = e.target;
        setFolioNumber({ ...folioNumber, [name]: value });
        setFormErr({ ...formErr, folioNumber: "" })
    }

    //handlecompnay/bank
    const handleValueC = (e) => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
        setFormErr({ ...formErr, company: "" })
    }
    //handleInvestmentDate
    const handleInvDate = (iDate) => {
        setIDate(iDate);
        setMDate(null)
        setFormErr({ ...formErr, iDate: "" })
    }

    function replaceNonNumeric(numStr) {

        return String(numStr).replace(/[^0-9]/g, '')

    }

    function commarize(numStr) {

        return Number(replaceNonNumeric(numStr)).toLocaleString()

    }
    //handleamountinvested
    const handleValueAI = (e) => {
        const { name, rawValue } = e.target;
        setInvAmount({ ...invAmount, [name]: rawValue });
        setFormErr({ ...formErr, totalCost: "" })
    }
    //handleinterest
    const handleValueInt = (e) => {
        const { name, rawValue } = e.target;
        setInterest({ ...interest, [name]: rawValue > 100 ? 100 : rawValue < 0 ? 0 : rawValue });
        setFormErr({ ...formErr, interest: "" })
    }
    //handlefrequency
    const handleFrequency = (selected) => {
        setSelFrequency(selected);
        setFrequencyValue(selected.value)
        setFormErr({ ...formErr, selFrequency: "" })
    }
    //handletenure
    const HandleChangeTenure = (e) => {
        setTenure(e);
        setTenureValue(e.value);
        setFormErr({ ...formErr, tenureValue: '' })
    }
    //handlematuritydate
    const handleDateM = (mDate) => {
        setMDate(mDate);
        setFormErr({ ...formErr, date: "" });
    }
    //handlematurityvalue
    const handleValueM = (e) => {
        const { name, rawValue } = e.target;
        setMaturityVal({ ...maturityVal, [name]: rawValue });
        setFormErr({ ...formErr, MaturityValue: "" })
    }
    //handlelockinperiod
    const HandleChangePeriod = (e) => {
        setLockPeriod(e);
        setLockPeriodValue(e.value);
        setFormErr({ ...formErr, lockPeriodValue: '' })
    }

    //handleradio
    const handleRadio = (e) => {
        const { value } = e.target
        setreinvestedRadio(value);
        if (value === "no") {
            setStartDate(null);
            setInterestAcc({ ...interestAcc, interestReinvested: 0 })
        }
    }
    //handleinterstAcc
    const handleValueIntAcc = (e) => {
        const { name, rawValue } = e.target;
        setInterestAcc({ ...interestAcc, [name]: rawValue });
        setFormErr({ ...formErr, interestAcc: "" })
    }

    //handlestartingdate
    const handleDateStart = (startDate) => {
        setStartDate(startDate);
        setFormErr({ ...formErr, startDate: "" })
    }
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            // debugger
            let FDData = {
                instrumentId: 4,
                instrumentTypeId: 29,
                subAccountId: Fdentity,
                date: fixTimezoneOffset(iDate),
                certificateFolioNo: company.company.trim(),
                fdDetails: [
                    {
                        transactStockType: "Buy",
                        interestAccrued: reinvestedRadio === "yes" ? true : false,
                        interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
                        totalCost: Number(invAmount.totalCost),
                        folioNumber: folioNumber.folioNumber.trim(),
                        lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                        domConversion: fixTimezoneOffset(mDate),
                        interestPerc: interest.interestPerc ? interest.interestPerc : 0,
                        MaturityValue: reinvestedRadio === "yes" ? maturityVal.MaturityValue ? Number(maturityVal.MaturityValue) : 0 : Number(invAmount.totalCost),
                        tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
                        frequencyId: selFrequency.value,
                        fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                        cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
                    }
                ]
            };
            AddFixedDeposite(FDData).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    swal("FD Added Successfully!", "", "success");
                    handleReset()

                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };
    // get data for update
    useEffect(() => {
        if (FDHeaderId !== 0) {
            if (FixDTransaction !== undefined) {
                setIDate(new Date(FixDTransaction.date))
                FixDTransaction.fdDetails && FixDTransaction.fdDetails.map((t) => {
                    setFdId(t.fdId);
                    setCompany({ company: t.company.companyName });
                    setFolioNumber({ folioNumber: t.folioNumber });
                    t.frequencyId ? setSelFrequency({ label: t.frequency.frequencyName, value: t.frequencyId }) : setSelFrequency([]);
                    setMaturityVal({ MaturityValue: t.maturityValue });
                    setInterest({ interestPerc: t.interestPerc });
                    setInvAmount({ totalCost: t.totalCost });
                    setMDate(new Date(t.domConversion));
                    let d = t.tenure
                    let splitData = d ? d.split(/([0-9]+)/) : ['', '0', 'Month'];
                    setTenurePeriod(splitData[1]);
                    setTenureValue(splitData[2]);
                    setTenure({ label: splitData[2], value: splitData[2] });
                    let l = t.lockInPeriod ? t.lockInPeriod : "0Month";
                    let splitDatalock = l ? l.split(/([0-9]+)/) : ['', '0', 'Month'];
                    setLockinperiod(splitDatalock[1]);
                    setLockPeriodValue(splitDatalock[2]);
                    setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });
                    setreinvestedRadio(t.interestAccrued === true ? "yes" : "no");
                    if (t.interestAccrued === true) {
                        setInterestAcc({ interestReinvested: t.interestReinvested });
                        setStartDate(new Date(t.fyStartDate));
                        // setIsInterest(true)
                        t.balanceQuantity > 0 ? setIsInterest(true) : setIsInterest(false);
                    }
                })
            }
        }
    }, [FixDTransaction])

    // HANDLE UPDATE
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            // debugger
            let a = lockinperiod;
            let b = tenurePeriod;
            let FDData = {
                fdHeaderId: FDHeaderId,
                instrumentId: 4,
                instrumentTypeId: 29,
                subAccountId: Fdentity,
                date: fixTimezoneOffset(iDate),
                certificateFolioNo: company.company,
                fdDetails: [
                    {
                        fdId: fdId,
                        transactStockType: "Buy",
                        interestAccrued: reinvestedRadio === "yes" ? true : false,
                        interestReinvested: reinvestedRadio === "yes" ? interestAcc.interestReinvested === "" ? 0 : Number(interestAcc.interestReinvested) : 0,
                        totalCost: invAmount.totalCost,
                        folioNumber: folioNumber.folioNumber,
                        lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                        domConversion: fixTimezoneOffset(mDate),
                        interestPerc: interest.interestPerc ? interest.interestPerc : 0,
                        MaturityValue: reinvestedRadio === "yes" ? maturityVal.MaturityValue ? Number(maturityVal.MaturityValue) : 0 : Number(invAmount.totalCost),
                        tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
                        frequencyId: selFrequency.value,
                        fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                        cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
                    }
                ]
            };
            UpdateFixedDeposite(FDData).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    swal("FD Updated Successfully!", "", "success");
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in update");
                });
        }
    };

    //handleReset
    const handleReset = () => {
        setFolioNumber({ folioNumber: "" });
        setCompany({ company: "" });
        setIDate(null);
        setInvAmount({ totalCost: "" });
        setInterest({ interestPerc: "" });
        setSelFrequency(dividendfrequency[1]);
        setTenure([]);
        setMDate(null);
        setMaturityVal({ MaturityValue: "" })
        setLockPeriod([]);
        setTenurePeriod("")
        setLockinperiod("")
        setreinvestedRadio("no")
        setInterestAcc({ interestReinvested: 0 })
        setStartDate(null)
        setFormErr([]);
        setIsInterest(false)

    }

    const disableButton = () => {
        return company && iDate && invAmount && mDate && maturityVal
    }

    const handleInterest = () => {
        getInterestDataList();
    }
    const handleMaturity = () => {
        setFDMaturity(true)
    }
    const handleCloseInterest = () => {
        setfdi(false)
    }
    const handleCloseMaturity = () => {
        setFDMaturity(false);
    }
    const handledisableCheck = (i) => {
        if (i === 1) {
            setIsInterest(true);
        } else {
            setIsInterest(false);
        }
    }


    const getInterestDataList = async () => {
        setLoading(true);
        await getInterestData(FDHeaderId, "Interest")
            .then(async (res) => {
                if (res.data._response.status === 1) {
                    let result = res.data._response.data.fixedDepositInterestList;
                    await setInterestData(result);
                    setLoading(false);
                    setfdi(!fdi);
                } else {
                    setInterestData([]);
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, "error in get fix income data");
            })
    }

    return (
        <>
            {show5 === true ?
                <>
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6" tabIndex={2}>
                        <Form className="p-3" >
                            <Row className="form-set">
                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="mb-6 col-md-3">
                                        <Form.Label>Company/Bank<span className="text-danger h6">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company"
                                            min="0"
                                            value={company.company}
                                            onChange={handleValueC}
                                            autoComplete="off"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.company}
                                            </div>
                                        ) : null}
                                    </Form.Group> :
                                    <Form.Group className="mb-6 col-md-3">
                                        <Form.Label>Company/Bank<span className="text-danger h6">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company"
                                            min="0"
                                            value={company.company}
                                            onChange={handleValueC}
                                            autoComplete="off"
                                            disabled
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.company}
                                            </div>
                                        ) : null}
                                    </Form.Group>}


                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Certificate No./Folio No.<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleValueF}
                                        name="folioNumber"
                                        min="0"
                                        value={folioNumber.folioNumber}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.folioNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        maxDate={new Date()}
                                        onChange={handleInvDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={iDate}

                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.iDate}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Col md="3" className="mb-3">

                                    <Form.Label className="d-block">Tenure</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setTenurePeriod(e.target.value)}
                                        autoComplete="off"
                                        value={tenurePeriod}
                                        name="tenure" id="tenure" className="lockinperiodno" />
                                    <Dropdown
                                        value={tenure}
                                        name='tenure'
                                        options={periodList}
                                        onChange={HandleChangeTenure}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"
                                    // onInputChange={HandleInput}
                                    />
                                </Col>

                            </Row>
                            <Row className="form-set">
                                <Col md="3" className="mb-3">
                                    <Form.Label className="d-block">Lock in Period</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setLockinperiod(e.target.value)}
                                        autoComplete="off"
                                        value={lockinperiod}
                                        name="lockinperiod" id="lockinperiod" className="lockinperiodno" />
                                    <Dropdown
                                        value={lockPeriod}
                                        name='lockinperiod'
                                        options={periodList}
                                        onChange={HandleChangePeriod}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"

                                    />
                                </Col>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="totalCost"
                                        min="0"
                                        value={commarize(invAmount.totalCost)}
                                        onChange={handleValueAI}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        placeholder="Enter Amount"
                                        name="totalCost"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={invAmount.totalCost}
                                        onChange={handleValueAI}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.totalCost}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Interest %</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="interestPerc"
                                        min="0"
                                        max={100}
                                        value={interest.interestPerc}
                                        onChange={handleValueInt}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Interest Frequency</Form.Label>
                                    <Select
                                        value={selFrequency}
                                        onChange={handleFrequency}
                                        options={dividendfrequency}
                                        name="frequencyId"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Maturity Date<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Maturity Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(iDate)}
                                        // dateFormat="dd-MM-yyyy"
                                        onChange={handleDateM}
                                        dateFormat="dd-MM-yyyy"
                                        name="domConversion"
                                        autoComplete="off"
                                        selected={mDate}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                                    {
                                        reinvestedRadio === "yes" ?
                                            // <Form.Control
                                            //     type="text"
                                            //     name="MaturityValue"
                                            //     min="0"
                                            //     value={commarize(maturityVal.MaturityValue)}
                                            //     onChange={handleValueM}
                                            // />
                                            <Cleave
                                            className='form-control'
                                            name="MaturityValue"
                                            autoComplete="off"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={maturityVal.MaturityValue}
                                            onChange={handleValueM}
                                            />
                                            :
                                            // <Form.Control
                                            //     type="text"
                                            //     name="MaturityValue"
                                            //     min="0"
                                            //     // value={maturityVal.MaturityValue}
                                            //     value={commarize(invAmount.totalCost)}
                                            //     // onChange={handleValueM}
                                            //     disabled
                                            // />
                                            <Cleave
                                            className='form-control'
                                            name="MaturityValue"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={invAmount.totalCost}
                                            disabled
                                            // onChange={handleValueM}
                                            />
                                    }
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.MaturityValue}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Interest reinvested</Form.Label>
                                    <RadioGroup
                                        row
                                        color="primary"
                                        defaultValue="start"
                                        aria-label="Entity"
                                        name="row-radio-buttons-group"
                                        fontSize="100px"
                                        value={reinvestedRadio}
                                        onChange={handleRadio}
                                        style={{ marginTop: "-2px" }}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                            disabled={isInterest}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                            disabled={isInterest}
                                        />
                                    </RadioGroup>
                                </Form.Group>


                                {reinvestedRadio === "yes" ?
                                    <>
                                        <Form.Group className="col-md-3">
                                            <Form.Label>Interest Accrued till date<span className="text-danger h6">*</span></Form.Label>
                                            {/* <Form.Control
                                                type="Number"
                                                name="interestReinvested"
                                                min="0"
                                                value={interestAcc.interestReinvested}
                                                onChange={handleValueIntAcc}
                                            /> */}
                                            <Cleave
                                            className='form-control'
                                            name="interestReinvested"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={interestAcc.interestReinvested}
                                            onChange={handleValueIntAcc}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.interestAcc}
                                                </div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-3">
                                            <Form.Label>Financial Year starting with<span className="text-danger h6">*</span></Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select Start Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                maxDate={new Date()}
                                                // minDate={new Date(buyDate)} dateFormat="dd-MM-yyyy"
                                                onChange={handleDateStart}
                                                dateFormat="dd-MM-yyyy"
                                                name="fyStartDate"
                                                autoComplete="off"
                                                selected={startDate}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.startDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </> : null
                                }
                            </Row>
                            <Row className='mt-2'>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {FDHeaderId === 0 || FDHeaderId === undefined ?
                                        <>
                                            <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                                <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                                    <Button variant="primary" className="mx-2 w100"
                                                        type="submit"
                                                        id="submitButton"
                                                        onClick={(e) => { e.preventDefault(); handleSubmit(e); }}
                                                        disabled={!disableButton()}
                                                    >Submit</Button>
                                                </span>
                                            </Tooltip>
                                            <Button
                                                variant="secondary"
                                                className="mx-2 w100"
                                                type="reset"
                                                onClick={handleReset}
                                            >
                                                Clear
                                            </Button>
                                        </>
                                        :
                                        <Button variant="primary" className="mx-2 w100"
                                            onClick={handleUpdate}
                                            id="submitButton"
                                        // disabled={!disableButton()}
                                        >Update</Button>
                                    }

                                    {FDHeaderId === 0 || FDHeaderId === undefined ?
                                        null :
                                        <>
                                            <Button variant="primary" className="mx-2 w100" onClick={handleInterest} disabled={reinvestedRadio === "no"}>Add Interest</Button>
                                            <Button
                                                variant="primary"
                                                className="mx-2 w100"
                                                type="reset"
                                                onClick={handleMaturity}
                                                style={{ width: '7rem', height: '2rem' }}
                                            >
                                                Add Maturity
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </div>


                    <FDInterest showFI={fdi} hideFI={handleCloseInterest} FDHeaderId={FDHeaderId} FDiD={fdId} InvDate={iDate} Mdate={mDate} disableCheck={(i) => handledisableCheck(i)} interestdata={interestdata} />
                    <Maturity showMaturity={FDMaturity} onHideMaturity={handleCloseMaturity} FDHeaderId={FDHeaderId} FDiD={fdId} InvDate={mDate} Fname={"FD Maturity"} onRefreshFD={() => onRefreshFD()} />

                </>
                : null}
        </>
    )

}

export default FD