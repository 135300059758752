/* eslint-disable no-lone-blocks */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavedisabledField } from './ClevTablCss';
import {
  IntraDaycheck, addStocks, deleteStock, updateStock, AvilableQuantity, brokerList, ValuationList,
  SelectionList, PostUnlistedScripBySubId, GetUnlistedScripBySubId, GetUnlistedSellingCompany,
  GetDividendFrequency, DeleteStocksTransactions, SellingCompanies,
  CompanyListByInsTypeId, Addbroker, SelectionListUpdate
} from '../../pages/Stocks/StocksAPI';
import { stockType, cumulativeNonCumulativeList, redeemableOrConvertibleList, periodList } from '../../modules/helpers/constant';

import './StocksDetail.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Dropdown from '../CommonComponent/Dropdown';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import { Alert, AlertTitle } from '@material-ui/lab';
import MaterialTable, { MTableToolbar, MTableEditField } from "material-table";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { components } from "react-select";
import Loader from "../../components/loadingAnimation/index";
import _, { toNumber } from "lodash";
import Swal from "sweetalert2";
import swal from 'sweetalert';
import moment from "moment";
import Cleave from 'cleave.js/react';
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { event } from 'jquery';

function StocksDetail(props) {

  const { show, onHide, stockHeaderId, stockTransaction, entityId, onTransactionGridRefresh, showSdetailspreview, previewTransaction } = props;

  const [date, setDate] = useState(new Date());
  const [call, setcall] = useState(null)
  const [formErr, setFormErr] = useState({});

  // const [columnsB1,setColumnsB1] = useState([]);
  // const [columnsB2,setColumnsB2] = useState([]);
  // const [columnsB3, setColumnsB3] = useState([]);
  // const [columnsB4, setColumnsB4] = useState([]);

  const [avilableQty, setAvilableQty] = useState(0);
  const [avilableSellQty, setAvilableSellQty] = useState(0);
  const [certificateorfolinumber, setCertificateorfolinumber] = useState("");
  const [lockinperiod, setLockinperiod] = useState("");
  const [redeemableorconvertible, setRedeemableorconvertible] = useState([{ label: "Redeemable", value: "Redeemable" }]);
  const [convertType, setConvertType] = useState("Redeemable");
  const [tenure, setTenure] = useState("");
  const [dividend, setDividend] = useState(0);
  const [dateofmaturityorconversion, setDateofmaturityorconversion] = useState(null);
  const [dividendfrequency, setDividendfrequency] = useState([]);
  const [frequencyValue, setFrequencyValue] = useState([]);
  const [maturityvalue, setMaturityvalue] = useState(0);
  const [cumulativeornoncumulative, setCumulativeornoncumulative] = useState({ label: "Cumulative", value: "Cumulative" });
  const [cumulativeType, setCumulativeType] = useState("Non Cumulative");
  const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
  const [timeValue, setTimeValue] = useState("Month");
  const [stt, setSTT] = useState(0);
  const [gst, setGST] = useState(0);
  const [stampDuty, setStampDuty] = useState(0);
  const [otherCharges, setOtherCharges] = useState(0);
  const [narration, setNarration] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [toolTip, setTooltip] = useState(false);
  const [headerId, setHeaderId] = useState(0);
  const [cost, setCost] = useState(0);

  const [iserror, setIserror] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessagesSell, setErrorMessagesSell] = useState([]);
  const [errorMessagesQntity, setErrorMessagesQntity] = useState([]);
  const [brokerValue, setBrokerValue] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [brokerOptionsListed, setBrokerOptionsListed] = useState([]);
  const [typeValue, setTypeValue] = useState([{ label: "Normal", value: "Normal" }]);
  const [value, setValue] = useState(null);
  const [typeStock, setTypeStock] = useState('Normal');
  const [selection, setSelection] = useState(false);
  const [selectionQty, setSelectionQty] = useState([]);
  const [totalAmountDue, setTotalAmountDue] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [companyListPre, setCompanyListPre] = useState([]);
  const [sellingCompanyList, setSellingCompanyList] = useState([]);
  const [stockValuationType, setStockValuationType] = useState([{ label: "FIFO", value: 1 }]);
  const [updatedStockVaualtion, setUpdatedValuationType] = useState([]);
  const [subAccountId, setSubAccountId] = useState(null);

  const [folioNoList, setFolioNoList] = useState([]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  const [columnsS1, setcolumnsS1] = useState([]);
  const [columnsS2, setcolumnsS2] = useState([])


  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);
  const [allEntry2, setAllEntry2] = useState([]);
  const [allEntry3, setAllEntry3] = useState([]);
  const [allEntry4, setAllEntry4] = useState([]);

  //sell Table Arrays
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  //radio button states
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  //buy Table Reference
  const tableRef1 = useRef();
  const tableRef2 = useRef();
  const tableRef3 = useRef();
  const tableRef4 = useRef();

  //sell Table Reference
  const tableRefSell1 = useRef();
  const tableRefSell2 = useRef();
  const tableRefSell3 = useRef();
  const tableRefSell4 = useRef();

  const [grid1, setGrid1] = useState(false);
  const [grid2, setGrid2] = useState(false);
  const [grid3, setGrid3] = useState(false);
  const [grid4, setGrid4] = useState(false);

  const [success, setSuccess] = useState(false);
  const [Mtooltip, setMtooltip] = useState(false);
  const [enabledAdisabled, setEnabledAdisabled] = useState(false)
  const [showUnlisted, setShowUnlisted] = useState(false);
  const [showlistedPref, setShowlistedPref] = useState(false);
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');
  const [unlistedCompany, setUnlistedCompanyList] = useState([]);
  const [unlistedSellingCompany, setUnlistedSellingCompanyList] = useState([]);
  const [intraDayQuantity, setIntraDayQuantity] = useState(0);

  // preview modal
  const [totalBroker, setTotalbroker] = useState(0)
  const [companyvalue, setCompanyValue] = useState([])
  const [pricePerShare, setPricePerShare] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [saleValue, setSaleValue] = useState(0);
  const [profitLoss, setProfitLoss] = useState(0);
  const [brokerpershare, setbrokerpershare] = useState(0)
  const [currentMP, setCurrentMP] = useState(0)
  const [currentMV, setCurrentMV] = useState(0)
  const [target, setTarget] = useState(0)
  const [stopLoss, setStopLoss] = useState(0)
  const [estHldP, setestHlP] = useState({})
  const [folioNumber, setfolioNumber] = useState(0)
  const [trastype, settrastype] = useState({})
  const [qty, setqty] = useState(0)
  const [stocksSeletion, setstockSelection] = useState({})
  const [recommendedBy, setRecommendedBy] = useState({})


  const [brokershow, setBrokershow] = useState(false)
  const [addbrokers, setAddbrokers] = useState([]);
  const [brokersCompErr, setBrokersErr] = useState('');
  const [brokerpre, setbrokerpre] = useState('')
  const [datepre, setdatepre] = useState('');
  const [lockperiodpre, setLockinperiodpre] = useState('');
  let brokers = [];

  // useEffect(() => {

  //   setColumnsB1([
  //     {
  //       title: 'Company Name', field: 'companyId', width: 250,
  //       editComponent: (props) => {
  //         let ddValue = props.value;
  //         if (props.rowData.tableData != undefined) {
  //           if (props.rowData.tableData.editing != undefined) {
  //             if (props.rowData.tableData.editing == 'update') {
  //               if (props.value.label != undefined) {
  //                 if (props.value.label != props.rowData.company.label) {
  //                   ddValue = props.value
  //                 }
  //                 else {
  //                   ddValue = props.rowData.company
  //                 }
  //               }
  //               else {
  //                 ddValue = props.rowData.company
  //               }
  //             }
  //           }
  //         }
  //         return (
  //           <Select
  //             value={ddValue || null}
  //             onChange={(selected) => props.onChange(selected)}
  //             options={companyList}
  //             name="companyId"
  //             onKeyDown={e => handleKey(e)}
  //             menuPosition="fixed"
  //             menuShouldBlockScroll="true"
  //             isDisabled={props.rowData.stockId != undefined ? true : false}
  //           />
  //         )
  //       },
  //     },
  //     {
  //       field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
  //       editComponent: ({ ...props }) => {
  //         // handleAddRowBuy();
  //         return <MTableEditField {...props} />;
  //       },
  //     },
  //     {
  //       field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
  //       editComponent: ({ ...props }) => {
  //         return <MTableEditField {...props} />;
  //       },
  //     },
  //     {
  //       field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
  //       editComponent: ({ ...props }) => {
  //         return <MTableEditField  {...props} />;
  //       },
  //     },
  //     {
  //       field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
  //       editComponent: ({ ...props }) => {
  //         let totalBrokrage = 0;
  //         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //         props.value = totalBrokrage.toFixed(2);
  //         props.rowData.totalBrokerage = totalBrokrage;
  //         return <MTableEditField  {...props} />;
  //       },
  //     },
  //     {
  //       field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
  //       editComponent: ({ ...props }) => {
  //         let totalcost = 0;
  //         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //         props.value = totalcost.toFixed(2);
  //         props.rowData.totalCost = totalcost;
  //         return <MTableEditField {...props} />;
  //       }
  //     },
  //     {
  //       field: 'target', title: 'Target', width: 130,
  //       editComponent: ({ ...props }) => {
  //         return <MTableEditField {...props} />;
  //       },
  //     },
  //     {
  //       field: 'stopLoss', title: 'Stop Loss', width: 130,
  //       editComponent: ({ ...props }) => {
  //         return <MTableEditField {...props} />;
  //       },
  //     },
  //     {
  //       field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
  //       editComponent: ({ ...props }) => {
  //         return <MTableEditField {...props} />;
  //       },
  //     },
  //     { field: 'recommendedBy', title: 'Recommended by', width: 130 }
  //   ])
  // }, [show1, companyList]);
  // useEffect(() => {
  //   // if(show2 && unlistedCompany){
  //   // setColumnsB2(
  //   //   [
  //   //     {
  //   //       title: 'Company Name', field: 'companyId', cellStyle: {
  //   //         width: 200,
  //   //         maxWidth: 200
  //   //       },
  //   //       headerStyle: {
  //   //         width: 200,
  //   //         maxWidth: 200
  //   //       },
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData != undefined) {
  //   //           if (props.rowData.tableData.editing != undefined) {
  //   //             if (props.rowData.tableData.editing == 'update') {
  //   //               if (props.value.label != undefined) {
  //   //                 if (props.value.label != props.rowData.company.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.company
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.company
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             components={{ Control }}
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             options={unlistedCompany}
  //   //             name="companyId"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //             isDisabled={props.rowData.stockId != undefined ? true : false}
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     { field: 'folioNumber', title: 'Folio Number', width: 130 },
  //   //     {
  //   //       field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField {...props} />;
  //   //       }
  //   //     },
  //   //     {
  //   //       field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let totalBrokrage = 0;
  //   //         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //   //         props.value = totalBrokrage.toFixed(2);
  //   //         props.rowData.totalBrokerage = totalBrokrage;
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let totalcost = 0;
  //   //         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //   //         props.value = totalcost.toFixed(2);
  //   //         props.rowData.totalCost = totalcost;
  //   //         return <MTableEditField {...props} />;
  //   //       }
  //   //     },
  //   //     { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
  //   //   ])
  //   // }
  //   // if (show4 && unlistedCompany) {
  //   //   setColumnsB4(
  //   //     [
  //   //     {
  //   //       title: 'Company Name', field: 'companyId', width: 250,
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData != undefined) {
  //   //           if (props.rowData.tableData.editing != undefined) {
  //   //             if (props.rowData.tableData.editing == 'update') {
  //   //               if (props.value.label != undefined) {
  //   //                 if (props.value.label != props.rowData.company.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.company
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.company
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             components={{ Control }}
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             // options={Scrip}
  //   //             options={unlistedCompany}
  //   //             name="companyId"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //             isDisabled={props.rowData.stockId != undefined ? true : false}
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let totalBrokrage = 0;
  //   //         totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //   //         props.value = totalBrokrage.toFixed(2);
  //   //         props.rowData.totalBrokerage = totalBrokrage;
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let totalcost = 0;
  //   //         totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //   //         props.value = totalcost.toFixed(2);
  //   //         props.rowData.totalCost = totalcost;
  //   //         return <MTableEditField {...props} />;
  //   //       }
  //   //     },
  //   //     {
  //   //       title: "Addtional Data",
  //   //       field: "internal_action",
  //   //       // editable: false,
  //   //       editComponent: ({ ...props }) => {
  //   //         let t = props.rowData;
  //   //         return <span onClick={() => {
  //   //           // if (headerId == 0) {

  //   //           if (t.dividendPerc !== undefined) {
  //   //             setDividend(t.dividendPerc);
  //   //           }
  //   //           if (t.frequencyId !== undefined) {
  //   //             // {lable:frequency.frequencyName,value:t.frequencyI}
  //   //             setFrequencyValue(t.frequencyI);
  //   //           }
  //   //           if (t.redeemConvertible !== undefined) {
  //   //             setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
  //   //           }
  //   //           if (t.lockInPeriod !== undefined) {
  //   //             let d = t.lockInPeriod
  //   //             let splitData = d.split(/([0-9]+)/);
  //   //             setTimeValue(splitData[2])
  //   //             setTime({ label: splitData[2], value: splitData[2] })
  //   //             setLockinperiod(splitData[1])
  //   //           }
  //   //           if (t.cumulativeNonCumulative !== undefined) {
  //   //             setCumulativeType(t.cumulativeNonCumulative);
  //   //             setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
  //   //           }
  //   //           if (t.folioNumber !== undefined) {
  //   //             setCertificateorfolinumber(t.folioNumber);
  //   //           }
  //   //           if (t.maturityValue !== undefined) {
  //   //             setMaturityvalue(t.maturityValue);
  //   //           }
  //   //           if (t.domConversion !== undefined) {
  //   //             setDateofmaturityorconversion(new Date(t.domConversion));
  //   //           }
  //   //           // }
  //   //           setCost(t.totalCost)
  //   //           setShowlistedPref(true)
  //   //         }}
  //   //           title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
  //   //       }
  //   //     }
  //   //   ])
  //   // }
  // }, [show2, show4, unlistedCompany]);

  // useEffect(() => {
  //   // setColumnsB3(
  //   //   [
  //   //   {
  //   //     title: 'Company Name', field: 'companyId', width: 250,
  //   //     editComponent: (props) => {
  //   //       let ddValue = props.value;
  //   //       if (props.rowData.tableData != undefined) {
  //   //         if (props.rowData.tableData.editing != undefined) {
  //   //           if (props.rowData.tableData.editing == 'update') {
  //   //             if (props.value.label != undefined) {
  //   //               if (props.value.label != props.rowData.company.label) {
  //   //                 ddValue = props.value
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.company
  //   //               }
  //   //             }
  //   //             else {
  //   //               ddValue = props.rowData.company
  //   //             }
  //   //           }
  //   //         }
  //   //       }
  //   //       return (
  //   //         <Select
  //   //           value={ddValue || null}
  //   //           onChange={(selected) => props.onChange(selected)}
  //   //           components={{ Control }}
  //   //           // options={Scrip}
  //   //           options={companyListPre}
  //   //           name="companyId"
  //   //           isDisabled={props.rowData.stockId != undefined ? true : false}
  //   //           onKeyDown={e => handleKey(e)}
  //   //           menuPosition="fixed"
  //   //           menuShouldBlockScroll="true"
  //   //         />
  //   //       )
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
  //   //     editComponent: ({ ...props }) => {
  //   //       // handleAddRowBuy();
  //   //       return <MTableEditField {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
  //   //     editComponent: ({ ...props }) => {
  //   //       return <MTableEditField {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
  //   //     editComponent: ({ ...props }) => {
  //   //       return <MTableEditField  {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
  //   //     editComponent: ({ ...props }) => {
  //   //       let totalBrokrage = 0;
  //   //       totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //   //       props.value = totalBrokrage.toFixed(2);
  //   //       props.rowData.totalBrokerage = totalBrokrage;
  //   //       return <MTableEditField  {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
  //   //     editComponent: ({ ...props }) => {
  //   //       let totalcost = 0;
  //   //       totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //   //       props.value = totalcost.toFixed(2);
  //   //       props.rowData.totalCost = totalcost;
  //   //       return <MTableEditField {...props} />;
  //   //     }
  //   //   },

  //   //   {
  //   //     field: 'target', title: 'Target', width: 130,
  //   //     editComponent: ({ ...props }) => {
  //   //       return <MTableEditField {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'stopLoss', title: 'Stop Loss', width: 130,
  //   //     editComponent: ({ ...props }) => {
  //   //       return <MTableEditField {...props} />;
  //   //     },
  //   //   },
  //   //   {
  //   //     field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
  //   //     editComponent: ({ ...props }) => {
  //   //       return <MTableEditField {...props} />;
  //   //     },
  //   //   },
  //   //   { field: 'recommendedBy', title: 'Recommended by', width: 130 },
  //   //   {
  //   //     title: "Addtional Data",
  //   //     field: "internal_action",
  //   //     // editable: false,

  //   //     editComponent: ({ ...props }) => {
  //   //       // console.log(props);
  //   //       let t = props.rowData
  //   //       return <span onClick={() => {
  //   //         debugger
  //   //         // if (headerId == 0) {

  //   //         if (t.dividendPerc !== undefined) {
  //   //           setDividend(t.dividendPerc);
  //   //         }
  //   //         if (t.frequencyId !== undefined) {
  //   //           // setDividendfrequency(t.frequencyI)
  //   //           setFrequencyValue(t.frequencyI);
  //   //         }
  //   //         if (t.redeemConvertible !== undefined) {
  //   //           setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
  //   //         }
  //   //         if (t.lockInPeriod !== undefined) {
  //   //           let d = t.lockInPeriod ? t.lockInPeriod : "0Month";
  //   //           let splitData = d.split(/([0-9]+)/);
  //   //           setTimeValue(splitData[2])
  //   //           setTime({ label: splitData[2], value: splitData[2] })
  //   //           setLockinperiod(splitData[1])
  //   //         }
  //   //         if (t.cumulativeNonCumulative !== undefined) {
  //   //           setCumulativeType(t.cumulativeNonCumulative);
  //   //           setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
  //   //         }
  //   //         if (t.folioNumber !== undefined) {
  //   //           setCertificateorfolinumber(t.folioNumber);
  //   //         }
  //   //         if (t.maturityValue !== undefined) {
  //   //           setMaturityvalue(t.maturityValue);
  //   //         }
  //   //         if (t.domConversion !== undefined) {
  //   //           setDateofmaturityorconversion(new Date(t.domConversion));
  //   //         }
  //   //         // }
  //   //         setCost(t.totalCost)
  //   //         setShowlistedPref(true)
  //   //       }}
  //   //         title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
  //   //     }
  //   //   }
  //   // ])
  // }, [show3, companyListPre]);

  // seperation by comma
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEffect(() => {
    (async () => {

      await setShow2(false);
      await setShow1(true);
      await setShow3(false);
      await setShow4(false);
      await setSubAccountId(entityId == null ? subAccountId : entityId);
      if (stockHeaderId !== 0) {
        if (Object.keys(stockTransaction).length !== 0) {
          setHeaderId(stockHeaderId);
          if (stockTransaction.instrumentTypeId !== 10) {
            setTypeValue({ label: stockTransaction.type, value: stockTransaction.type });
            setTypeStock(stockTransaction.type);
            setSTT(stockTransaction.sst == null ? 0 : stockTransaction.sst);

          }


          stockTransaction.stockDetails && stockTransaction.stockDetails.map((t) => {

            if (stockTransaction.instrumentTypeId === 20 || stockTransaction.instrumentTypeId === 11) {
              if (t.transactStockType !== "Sell") {

                let d = t.lockInPeriod
                let splitData = d.split(/([0-9]+)/);
                setTimeValue(splitData[2])
                setTime({ label: splitData[2], value: splitData[2] })
                setLockinperiod(splitData[1])
                setCertificateorfolinumber(t.folioNumber);
                setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
                setDividend(t.dividendPerc);
                setDateofmaturityorconversion(new Date(t.domConversion));
                setFrequencyValue({ label: t.frequency.frequencyName, value: t.frequencyId });
                setMaturityvalue(t.maturityValue);
                t.frequencyI = { label: t.frequency.frequencyName, value: t.frequencyId }
                // setTenure(t.tenure)
                setCumulativeType(t.cumulativeNonCumulative);
                setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
              } else {
                setSelectionQty(JSON.parse(t.selection))
              }
            }
          })

          setBrokerValue({ value: stockTransaction.brokerId, label: stockTransaction.broker.brokerName });
          setValue(stockTransaction.brokerId);
          if (stockTransaction.instrumentTypeId === 10) {
            await setShow2(true);
            await setShow1(false);
            await setShow3(false);
            await setShow4(false);
          }
          else if (stockTransaction.instrumentTypeId === 11) {
            await setShow2(false);
            await setShow1(false);
            await setShow3(true);
            await setShow4(false);
          } else if (stockTransaction.instrumentTypeId === 20) {
            await setShow2(false);
            await setShow1(false);
            await setShow3(false);
            await setShow4(true);
          } else {
            await setShow2(false);
            await setShow1(true);
            await setShow3(false);
            await setShow4(false);
          }
          setDate(new Date(stockTransaction.date));
          setStampDuty(stockTransaction.stampDuty == null ? 0 : stockTransaction.stampDuty);
          setGST(stockTransaction.gst == null ? 0 : stockTransaction.gst);
          setOtherCharges(stockTransaction.otherCharges == null ? 0 : stockTransaction.otherCharges);
          setTotalAmountDue(stockTransaction.totalAmountDue == null ? 0 : stockTransaction.totalAmountDue);
          setSubAccountId(stockTransaction.subAccountId);
          setNarration(stockTransaction.narration === "null" ? null : stockTransaction.narration)
          let Buy = [];
          let Sell = [];
          let newStockDetail = stockTransaction.stockDetails && stockTransaction.stockDetails.map((t) => {

            return {
              ...t,
              stockValuation: { 'value': t.stockValuationId, 'label': t.stockValuation.stockValuationName },
              companyId: t.company.label,
              stockValuationId: t.stockValuation.stockValuationName,
              stockQty: t.quantity,
              unlistedFolio: { 'value': t.folioNumber, 'label': t.folioNumber }
            }
          })
          newStockDetail && newStockDetail.map((s) => {
            if (s.transactStockType === 'Sell') {
              Sell.push(s);
            }
            if (s.transactStockType === 'Buy') {
              if (s.brokeragePerShare == null) {
                s.brokeragePerShare = 0;
                s.totalBrokerage = 0;
              }
              Buy.push(s);
            }
          })

          if (stockTransaction.instrumentTypeId === 11) {
            setdata2(Sell)
          } else if (stockTransaction.instrumentTypeId === 20) {
            setdata3(Sell)
          } else if (stockTransaction.instrumentTypeId === 10) {
            setdata1(Sell)
          } else {
            setdata(Sell)
          }
          if (stockTransaction.instrumentTypeId === 11) {
            setAllEntry3(Buy)
          } else if (stockTransaction.instrumentTypeId === 20) {
            setAllEntry4(Buy)
          } else if (stockTransaction.instrumentTypeId === 10) {
            setAllEntry2(Buy)
          } else {
            setAllEntry1(Buy)
          }
        }
        else {
          clearForm();
        }
      }
      else {
        clearForm();
      }
    })();
  }, [stockTransaction])

 useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  // for preview modal
  useEffect(() => {
    if (stockHeaderId !== 0) {
      if (Object.keys(stockTransaction).length !== 0) {

        setdatepre(stockTransaction.date)
        setbrokerpre(stockTransaction.broker.brokerName)
        setCertificateorfolinumber(stockTransaction.certificateFolioNo)
        stockTransaction.stockDetails && stockTransaction.stockDetails.map((t) => {
          setTotalbroker(t.totalBrokerage)
          setCompanyValue(t.company.companyName)
          setPricePerShare(t.pricePerShare)
          setTotalCost(t.totalCost)
          setbrokerpershare(t.brokeragePerShare)
          // setCurrentMP(t.currentMp)
          // setCurrentMV(t.currentMarketValue)
          setTarget(t.target)
          setStopLoss(t.stopLoss)
          setestHlP(t.estHoldingPeriod)
          setRecommendedBy(t.recommendedBy)
          setstockSelection(t.stockValuation.stockValuationName)
          setfolioNumber(t.folioNumber)
          settrastype(t.transactStockType)
          setqty(t.quantity)
          setLockinperiodpre(t.lockInPeriod)
        })
      }
    }

  }, [stockTransaction])

  //First render of page
  useEffect(() => {
    setSubAccountId(entityId == null ? subAccountId : entityId);
    // setHeaderId(0);
    setLoading(true);
    if (show) {
      getBrokerList();
      getCompanyList();
    }
    getStockValuation();
    getDividedFrequencyList();
  }, [subAccountId])

  useEffect(() => {
    if (show1 || show3) {
      getCompanyList();
    }
  }, [show1, show3])

  //bind unlisted buy sell comapny
  useEffect(() => {
    if (show) {
      getUnlistedComapy(entityId == null ? subAccountId : entityId);
      getUnlistedSellingCompany(entityId == null ? subAccountId : entityId);
    }
  }, [show2 === true, show4 === true], show)

  useEffect(() => {
    setLoading(true);
    if (show) {
      setSubAccountId(entityId == null ? subAccountId : entityId);
      // getSellingCompany(entityId == null ? subAccountId : entityId);
      setLoading(false);
      if (entityId !== null || value !== null) {

        getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);

      }
    }

  }, [value, allEntry1, show1, show2, show3, show, entityId])

  useEffect(() => {
    setLoading(true);
    if (show) {
      // if (stockHeaderId != 0 || stockHeaderId == 0) {
      setSubAccountId(entityId == null ? subAccountId : entityId);
      // getSellingCompany(entityId == null ? subAccountId : entityId);
      getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);
      // }
    }

  }, [call, show])

  useEffect(() => {
    if (stockHeaderId !== 0) {
      if (Object.keys(previewTransaction).length !== 0) {
        setdatepre(previewTransaction.date)
        setbrokerpre(previewTransaction.broker.brokerName)
        setCertificateorfolinumber(previewTransaction.folioNumber)
        setTotalbroker(previewTransaction.totalBrokerage)
        setCompanyValue(previewTransaction.company.companyName)
        setPricePerShare(previewTransaction.pricePerShare)
        setTotalCost(previewTransaction.totalCost)
        setSaleValue(previewTransaction.closingBalance)
        setProfitLoss(previewTransaction.profitLoss)
        setbrokerpershare(previewTransaction.brokeragePerShare)
        setCurrentMP(previewTransaction.currentMp)
        setCurrentMV(previewTransaction.currentMarketValue)
        setTarget(previewTransaction.target)
        setStopLoss(previewTransaction.stopLoss)
        setestHlP(previewTransaction.estHoldingPeriod)
        setRecommendedBy(previewTransaction.recommendedBy)
        setstockSelection(previewTransaction.stockValuation.stockValuationName)
        setfolioNumber(previewTransaction.folioNumber)
        settrastype(previewTransaction.transactStockType)
        setqty(previewTransaction.quantity)
        setLockinperiodpre(previewTransaction.lockInPeriod)
      }
    }
  }, [previewTransaction])

  // clear form method
  const clearForm = () => {
    handleCloseModalPopUp();
    setDate(new Date());
    setTypeValue([{ label: "Normal", value: "Normal" }]);
    setBrokerValue([]);
    setNarration('');
    setSTT(0);
    setGST(0);
    setOtherCharges(0);
    setTotalAmountDue(0);
    setSellingCompanyList([]);
    setSubAccountId(null);
    setAllEntry1([]);
    setAllEntry2([]);
    setAllEntry3([]);
    setAllEntry4([]);
    setdata([]);
    setdata1([]);
    setdata2([]);
    setdata3([]);
    setHeaderId(0);
    setMtooltip(false);
    setStampDuty(0);
    setUnlistedSellingCompanyList([]);
    setUnlistedCompanyList([]);
    setValue(null);
    setSelectionQty([]);
    setcall(null);
    setAvilableQty(0);
    setFolioNoList([])
  }


  const getBrokerList = async () => {
    let EntityID = entityId == null ? subAccountId : entityId
    await brokerList(EntityID).then(res => {
      setLoading(false)
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          brokers = res.data._response.data.brokerDetails;
          let brokersData = [];
          let dataBrk = [];
          brokers && brokers.map(data => {
            brokersData.push({ value: 1, label: "N/A" })
            dataBrk.push({ value: data.brokerId, label: data.brokerName })
          })
          setBrokerOptions(brokersData);
          // let dataBrker = dataBrk.shift();
          setBrokerOptionsListed(dataBrk)
        }
      } else {
        setLoading(false)
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false)
    })
  }

  const getCompanyList = async () => {
    let comId
    if (show1) {
      comId = 1
    } else if (show3) {
      comId = 11
      // comId = 1
    }
    if (comId !== null) {
      await CompanyListByInsTypeId(comId).then(res => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let company = res.data._response.data.companyId;
            let companyData = [];
            company && company.map(data => {
              companyData.push({ value: data.companyId, label: data.companyName })
            })
            show1 ? setCompanyList(companyData) : setCompanyListPre(companyData)
          }
        } else {
          setError(res.data.message)
        }
      }).catch(e => {
        setError(e.message)
      })
    }
  }

  // current time acception method
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  const submitbroker = () => {
    if (addbrokers !== '') {
      setLoading(true);
      let Id = entityId == null ? subAccountId : entityId;
      let data = {
        brokerName: addbrokers,
        subAccountId: Id
      }
      Addbroker(data)
        .then(async (res) => {
          if (res.status === 200) {
            setLoading(false);
            setAddbrokers('');
            setBrokersErr('');
            getBrokerList();
            Swal.fire("Broker Added successfully!", "", 'success');
            // let t = res.data._response.data.companyName
            // let v = res.data._response.data.companyId
            // let comp = { label: t, value: v }
            // unlistedCompany.unshift(comp)
            handleCloseModal();
            //  getUnlistedComapy(Id);
          } else {
            setLoading(false)
          }
        })
        .catch(e => {
          setError(e.message)
          setLoading(false)
        })
    } else {
      setBrokersErr("Broker Name Required")
    }
  }

  const submitUnlisted = (e) => {
    e.preventDefault();
    if (unlistedComp !== '') {
      let Id = entityId == null ? subAccountId : entityId;
      let comId = show2 ? 10 : show4 ? 20 : show3 ? 11 : 1;

      let data = {
        companyName: unlistedComp,
        instrumentTypeId: comId,
        subAccountId: Id
      }
      setLoading(true);
      PostUnlistedScripBySubId(data)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              setLoading(false);
              setUnlistedComp('');
              setUnlistedCompErr('');
              Swal.fire("Scrip Added successfully!", "", 'success');
              let t = res.data._response.data.companyName
              let v = res.data._response.data.companyId
              let comp = { label: t, value: v }
              show2 || show4 ? unlistedCompany.unshift(comp) : companyListPre.unshift(comp)
              handleCloseModal();
              //  getUnlistedComapy(Id);
            } else {
              Swal.fire(res.data._response.message, '', 'info')
              setLoading(false)
            }
          } else {
            setLoading(false)
          }
        })
        .catch(e => {
          setError(e.message)
          setLoading(false)
        })
    } else {
      setUnlistedCompErr("Company Name Required")
    }
  }

  const getUnlistedComapy = (entityId) => {
    let id = show2 ? 10 : 20;
    //     if(show2){
    //       id = 10
    //     }
    //      if(show4){
    // id = 20
    //     }
    GetUnlistedScripBySubId(entityId, id).then(res => {
      if (res.status === 200) {

        if (res.data._response.status === 1) {
          let company = res.data._response.data.fetchUnlistedCompany;
          let companyData = [];
          company && company.map(data => {
            companyData.push({ value: data.companyId, label: data.companyName })
          })
          setUnlistedCompanyList(companyData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
    })
  }
  const getUnlistedSellingCompany = (entityId) => {
    GetUnlistedSellingCompany(entityId).then(res => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let company = res.data._response.data.fetchSelectionCompany;
          let companyData = [];
          company && company.map(data => {
            companyData.push({ value: data.companyId, label: data.companyName })
          })
          setUnlistedSellingCompanyList(companyData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
    })
  }

  //getdividend list
  const getDividedFrequencyList = async () => {
    await GetDividendFrequency()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
                dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                });
              });
            setDividendfrequency(dividendData);
            if (headerId === 0) {
              setFrequencyValue(dividendData[0])
            }
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        // setError(e.message);
      });
  };


  //instrument type wise sell companies
  const getSellingCompanyByInsType = async (entityId) => {
    if (entityId != null && value != null) {
      let insTypId;
      // { show1 ? insTypId = 1 : show2 ? insTypId = 10 : show3 ? insTypId = 11 : insTypId = 20 }
      { show1 ? insTypId = 1 : show2 ? insTypId = 10 : show3 ? insTypId = 11 : insTypId = 20 }
      setLoading(true);
      await SellingCompanies(entityId, value, insTypId).then(res => {
        setLoading(false);
        if (res.status === 200) {
          // debugger
          if (res.data._response.status === 1) {
            let company = res.data._response.data.fetchCompanyBySub;
            let companyData = [];
            company && company.map(data => {
              companyData.push({ value: data.companyId, label: data.companyName })
            })
            let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            let newCompany = [];
            resultDataBuy && resultDataBuy.map((p) => {
              newCompany.push(p.company);
            })
            // 
            let result = _.unionBy(companyData, newCompany, 'label');
            setSellingCompanyList(result);
          } else {
            let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
            let newCompany = [];
            resultDataBuy && resultDataBuy.map((p) => {
              newCompany.push(p.company);
            })
            // 
            let result = _.unionBy(newCompany, 'label');
            setSellingCompanyList(result);
          }
        } else {
          setLoading(false);
          setError(res.data.message)
          setSellingCompanyList([]);
        }
      }).catch(e => {
        setLoading(false);
        setError(e.message)
        setSellingCompanyList([]);
      })
    }
    else {
      setLoading(false);
    }
  }
  const getStockValuation = async () => {
    await ValuationList().then(res => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let valuation = res.data._response.data.stockvaluationtDetails;
          let vaulationData = [];
          valuation && valuation.map(data => {
            vaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
          })

          let updatedVaulationData = [];
          valuation && valuation.map(data => {
            if (data.stockValuationId === 3) {
              updatedVaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
            }

          })

          setUpdatedValuationType(updatedVaulationData);

          setStockValuationType(vaulationData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
    })
  }
  const CompanySelect = async (ddValue, rowData, StockID) => {
    if (ddValue !== undefined) {
      let quantity = 0;
      let editQuantity = 0;

      let Id = entityId == null ? subAccountId : entityId;
      let instTypeId = show2 ? 10 : 20;
      let resultBuy = show2 ? allEntry2 : allEntry4;
      let resultSell = show2 ? data1 : data3;
      let comapnyId = ddValue.value;

      resultBuy && resultBuy.map((r) => {
        if (r.company.value === comapnyId && r.stockId === undefined) {
          quantity += Number(r.quantity);
        }
      })
      resultSell && resultSell.map((r) => {
        if (r.company.value === comapnyId && r.stockId === undefined) {
          quantity -= Number(r.quantity);
        }
      })
      // handle changed qty of entry with stockId , @stockQty -old qty , @quantity- new changed qty
      resultSell && resultSell.map((r) => {
        if (r.company.value === comapnyId && r.quantity !== r.stockQty && r.stockId !== undefined) {
          editQuantity = Number(r.quantity) > Number(r.stockQty) ? Number(r.quantity) - Number(r.stockQty) : -(r.stockQty - r.quantity)
        }
      })

      // if ((!StockID) && (Id && ddValue.value && instTypeId !== 0)) {
      // setLoading(true);
      // await GetFolioNoSell(Id, ddValue.value, instTypeId).then(res => {
      //   if (res.status === 200) {
      //     setLoading(false);
      //     if (res.data._response.status === 1) {
      //       let data = res.data._response.data.folioNumbers;
      //       let arr = [];
      //       data && data.map((d, i) => {
      //         arr.push({ label: d, value: d });
      //       })
      //       setFolioNoList(arr);
      //     } else {
      //       setFolioNoList([]);
      //     }
      //   }
      // }).catch(e => {
      //   console.log(e, 'error in getFolioNo Api ');
      //   setLoading(false);
      // })
      // }
      await AvilableQuantity(1, Id, ddValue.value).then(res => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 1) {
            let remQty = 0;
            remQty = res.data._response.data.remainingQuantity;
            setAvilableQty(remQty + quantity - editQuantity);
            setAvilableSellQty(remQty);
            setLoading(false);
          }
          else {
            setAvilableQty(0 + quantity);
          }
        }
        else {
          setError(res.data.message);
          setAvilableQty(0 + quantity);
          setLoading(false);
        }
      })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        })
    }
  }
  const handleDisableOption = (option) => {
    if (show3 || show4) {
      if (option.value === 1 || option.value === 2) {
        return true
      }
    }
  }

  // disable button 
  const disableButton = () => {
    return brokerValue
  }

  const ListedCompanySelect = (ddValue, rowData) => {
    if (brokerValue.length < 1) {
      setMtooltip(true)
    }
    else {
      setMtooltip(false);
      if (ddValue !== undefined) {
        let resultBuy = show1 ? allEntry1 : allEntry3;
        let resultSell = show1 ? data : data2;
        setLoading(true);
        let comapnyId = ddValue.value;
        let quantity = 0;
        let editQuantity = 0;

        resultBuy && resultBuy.map((r) => {
          if (r.company.value === comapnyId && r.stockId === undefined) {
            quantity += Number(r.quantity);

          }
        })

        resultSell && resultSell.map((r) => {
          if (r.company.value === comapnyId && r.stockId === undefined) {
            setIntraDayQuantity(r.quantity - intraDayQuantity);
            quantity -= Number(r.quantity);

          }
        })
        // handle changed qty of entry with stockId , @stockQty -old qty , @quantity- new changed qty
        resultSell && resultSell.map((r) => {
          if (r.company.value === comapnyId && r.quantity !== r.stockQty && r.stockId !== undefined) {
            // editQuantity = r.quantity > r.stockQty ? r.quantity - r.stockQty : -(r.stockQty - r.quantity)
            editQuantity = r.quantity > r.stockQty ? r.quantity - r.stockQty : -(r.stockQty - r.quantity)
          }
        })

        //  let CheckIntra = [...resultBuy, ...resultSell];
        let Id = entityId == null ? subAccountId : entityId;
        let IntraDay = {
          brokerId: value,
          subAccountId: Id,
          companyId: comapnyId,
          date: fixTimezoneOffset(date),
        }

        IntraDaycheck(IntraDay).then(res => {
          if (res.data._response.status === 1) {

            let IntraDayData = res.data._response.data.intraDayDetails;
            let IntraQnty = 0;
            IntraDayData && IntraDayData.map(q => {
              IntraQnty += Number(q.availableQuantity);
            })
            //alert(IntraQnty);
            setIntraDayQuantity(IntraQnty);
          }
          else {
            setIntraDayQuantity(0);
          }
        }).catch(e => {
          setError(e.message)
          setLoading(false);
        })

        AvilableQuantity(value, Id, comapnyId).then(res => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 2) {
              setAvilableQty(0 + quantity);
            }
            else {
              let AvlQuantity = res.data._response.data.remainingQuantity;
              let overAllQty = res.data._response.data.overallQuantity;
              setAvilableQty(AvlQuantity + quantity - editQuantity);
              setAvilableSellQty(AvlQuantity);
            }
          } else {
            setError(res.data.message)
            setAvilableQty(0 + quantity);
            setLoading(false);
          }
        }).catch(e => {
          setError(e.message)
          setLoading(false);
        })

      }
    }
  }

  const AvaliableSellQty = (d) => {

    let brkId = d.brokerId;
    let compId = d.company.value
    let Id = entityId == null ? subAccountId : entityId;

    //current transaction quantity
    let curreQty = d.stockQty;

    let quantity = 0;
    let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : show3 ? allEntry3 : allEntry4;
    let resultSell = show1 ? data : show2 ? data1 : show3 ? data2 : data3;

    resultBuy && resultBuy.map((r) => {
      if (r.company.value === compId && r.stockId === undefined) {
        quantity += Number(r.quantity);
      }
    })
    resultSell && resultSell.map((r) => {
      if (r.company.value === compId && r.stockId === undefined) {
        quantity -= Number(r.quantity);
      }
    })

    AvilableQuantity(brkId, Id, compId).then(res => {
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 2) {
          setAvilableQty(0 + curreQty + quantity);
        }
        else {
          let AvlQuantity = res.data._response.data.remainingQuantity;
          setAvilableQty(AvlQuantity + curreQty + quantity);
        }
      } else {
        setError(res.data.message)
        setAvilableQty(0 + curreQty + quantity);
        setLoading(false);
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false);
    })
  }

  const AvailableSellingQtyForUpdate = (d) => {
    let compId = d.company.value;
    let rowId = d.tableData.id;
    let remainingQty = d.availableSellQuantity;

    let quantity = 0;
    let editQuantity = 0;

    let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : show3 ? allEntry3 : allEntry4;
    let resultSell = show1 ? data : show2 ? data1 : show3 ? data2 : data3;

    resultBuy && resultBuy.map((r) => {
      if (r.company.value === compId && r.stockId === undefined) {
        quantity += Number(r.quantity);
      }
    })
    resultSell && resultSell.map((r) => {
      if (r.company.value === compId && r.stockId === undefined && r.tableData.id !== rowId) {
        quantity -= Number(r.quantity);
      }
    })
    // handle changed qty of entry with stockId , @stockQty -old qty , @quantity- new changed qty
    resultSell && resultSell.map((r) => {
      if (r.company.value === compId && r.tableData.id !== rowId && r.quantity !== r.stockQty && r.stockId !== undefined) {
        // editQuantity = r.quantity > r.stockQty ? r.quantity - r.stockQty : -(r.stockQty - r.quantity)
        editQuantity = r.quantity > r.stockQty ? r.quantity - r.stockQty : -(r.stockQty - r.quantity)
      }
    })
    setAvilableQty(remainingQty + quantity - editQuantity);

  }

  //for sell columns
  useEffect(() => {
    if (show1 || show3) {
      setcolumnsS1([
        {
          title: 'Company Name', field: 'companyId',
          width: 250,
          editComponent: (props) => {
            let ddValue = props.value;
            if (value === null) {
              ddValue = null;
            }
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.value.label !== undefined) {
                    if (props.value.label !== props.rowData.company.label) {
                      ddValue = props.value;
                    }
                    else {
                      ddValue = props.rowData.company;
                    }
                  }
                  else {
                    ddValue = props.rowData.company;
                    //ListedCompanySelect(ddValue);
                  }
                }
              }
            }
            return (
              <>
                <Select
                  value={sellingCompanyList.length === 0 ? null : ddValue || null}
                  onChange={(selected) => {
                    props.onChange(selected)
                    ListedCompanySelect(selected, props)
                  }}
                  options={sellingCompanyList}
                  name="companyId"
                  onFocus={() => { setcall(1); brokerSelect() }}
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                  placeholder="Select Company.."
                  onKeyDown={e => handleKey(e)}
                  menuPosition="fixed"
                  menuShouldBlockScroll="true"
                  isDisabled={props.rowData.stockId !== undefined ? true : false}
                />{Mtooltip === true ? <span style={{ color: "red" }}>Please Select Broker</span>
                  : brokerValue.value !== undefined && sellingCompanyList.length === 0 ? <span style={{ color: "red" }}>There are no companies under this broker!</span> : null}
              </>
            )
          },
        },
        {
          field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
          render: (rowData) => (
            <span>
              {NumberOnlySeperator(rowData.quantity)}
            </span>
          ),
          // validate: (rowData) =>
          //   rowData.quantity === 0
          //     ? { isValid: false, helperText: 'quantity cannot be empty' }
          //    :rowData.quantity > avilableQty ? { isValid: false, helperText: 'please enter quantity in range of available quantitys' }  : true,
          editComponent: ({ ...props }) => {
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.rowData.stockId !== undefined) {
                    AvaliableSellQty(props.rowData)
                  } else {
                    AvailableSellingQtyForUpdate(props.rowData);
                    // setAvilableQty(props.rowData.availableQuantity)
                  }
                }
              }
            }
            return (
              <Tooltip title={`The Available quantity with this broker is:${avilableQty}`} arrow open={avilableQty >= 0 && props.rowData.companyId !== undefined ? true : avilableQty === undefined ? false : false}>
                {/* <MTableEditField {...props} placeholder={'quantity'} disabled={enabledAdisabled} /> */}
                <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                  <Cleave
                    className="MuiInputBase-input MuiInput-input"
                    placeholder="Quantity"
                    autoComplete='off'
                    options={{ numeral: true, numeralDecimalScale: 0, numeralPositiveOnly: true }}
                    value={props.value}
                    disabled={enabledAdisabled}
                    onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
              </Tooltip>
            )
          },
        },
        {
          field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Price/Share'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                disabled={enabledAdisabled}
                onChange={e => props.onChange(toNumber(e.target.rawValue))} />
            </div>
            </div>
            // <MTableEditField {...props} disabled={enabledAdisabled} />;
          },
          render: (rowData) => (
            <span>
              {NumberSeperator(rowData.pricePerShare)}
            </span>
          ),
        },
        {
          field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            let brkShare = props.rowData.brokeragePerShare ? props.rowData.brokeragePerShare : 0
            props.rowData.brokeragePerShare = brkShare;
            props.value = brkShare;
            return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Brokerage/Share'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                disabled={enabledAdisabled}
                onChange={e => props.onChange((e.target.rawValue))} />
            </div>
            </div>
            // <MTableEditField  {...props} disabled={enabledAdisabled} />;
          },
          render: (rowData) => (
            <span>
              {Number4Seperator(rowData.brokeragePerShare)}
            </span>
          ),
        },
        {
          field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            let totalBrokrage = 0;
            totalBrokrage = props.rowData.brokeragePerShare ? props.rowData.quantity * props.rowData.brokeragePerShare : 0;
            props.value = totalBrokrage.toFixed(4);
            props.rowData.totalBrokerage = totalBrokrage;
            return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Total Brokerage'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                disabled={true}
                onChange={e => props.onChange((e.target.rawValue))} />
            </div>
            </div>
            // <MTableEditField  {...props} disabled={true} />;
          },
          render: (rowData) => (
            <span>
              {Number4Seperator(rowData.totalBrokerage)}
            </span>
          ),
        },
        {
          field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            let totalcost = 0;
            totalcost = (props.rowData.quantity * props.rowData.pricePerShare) - (props.rowData.totalBrokerage);
            props.value = totalcost.toFixed(2);
            props.rowData.totalCost = totalcost.toFixed(2);
            return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Total Cost'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                disabled={true}
                onChange={e => props.onChange((e.target.rawValue))} />
            </div>
            </div>
            // <MTableEditField {...props} disabled={true} />;
          },
          render: (rowData) => (
            <span>
              {NumberSeperator(rowData.totalCost)}
            </span>
          ),
        },
        {
          title: 'Stock Valuation Type', field: 'stockValuationId',
          editComponent: ({ ...props }) => {
            // let selectedValue =1;
            let ddValue = props.value;
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.value.label !== undefined) {
                    if (props.value.label !== props.rowData.stockValuation.label) {
                      ddValue = props.value;
                      console.log("Inner grid", ddValue);
                    }
                    else {
                      ddValue = props.rowData.stockValuation;
                      console.log("else grid", ddValue);
                    }
                  }
                  else {
                    ddValue = props.rowData.stockValuation;
                    console.log("outer grid", props.rowData.selection);
                    if (ddValue.value === 3) {

                      // setSelectionQty(JSON.parse(props.rowData.selection));
                      //getSelection(props, ddValue.value);
                    }
                  }
                }
              }
            }
            return (

              <Select
                value={ddValue ? ddValue : show3 ? null : [{ value: 1, label: "FIFO" }]}
                // value={stockValuationType.find(op => {
                //   // console.log(op)
                //   return op.value === selectedValue
                // })}
                //  onFocus={() => SelectionSelect(props, ddValue)}
                onChange={(selected) => {
                  props.onChange(selected)

                  if (selected.label === "Selection") {
                    getSelection(props, selected.value);
                  }
                }}
                // defaultValue={props.rowData.stockValuation.value}
                isOptionDisabled={handleDisableOption}
                options={props.rowData.stockId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 5) ? updatedStockVaualtion : stockValuationType}
                name="stockValuationId"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                // isDisabled={isNaN(props.rowData.totalCost) ? true : props.rowData.stockId != undefined ? true : false}
                isDisabled={props.rowData.stockId !== undefined && (props.rowData.stockValuation.value === 1 || props.rowData.stockValuation.value === 2 || props.rowData.stockValuation.value === 5) ? true : props.rowData.companyId === undefined ? true : false}
              />
            )
          },

        },
      ]);
    } else {
      setcolumnsS2([
        {
          title: 'Company Name', field: 'companyId', width: 250,
          editComponent: (props) => {
            let ddValue = props.value;
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.value.label !== undefined) {
                    if (props.value.label !== props.rowData.company.label) {
                      ddValue = props.value;
                    }
                    else {
                      ddValue = props.rowData.company;
                    }
                  }
                  else {
                    ddValue = props.rowData.company;
                    //  CompanySelect(ddValue)
                  }
                }
              }
            }
            return (
              <>
                <Select
                  value={ddValue || null}
                  onChange={(selected) => {
                    // console.log(props)
                    props.onChange(selected)
                    CompanySelect(selected, props)
                  }}
                  onFocus={() => setcall(1)}
                  options={sellingCompanyList}
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                  placeholder="Select Company.."
                  // options={unlistedSellingCompany}
                  name="companyId"
                  onKeyDown={e => handleKey(e)}
                  menuPosition="fixed"
                  menuShouldBlockScroll="true"
                  isDisabled={props.rowData.stockId !== undefined ? true : false}
                />
              </>
            )
          },
        },
        // {
        //   field: 'folioNumber', title: 'Folio Number ', width: 70,
        //   editComponent: (props) => {
        //     let ddValue = props.value;
        //     if (props.rowData.tableData != undefined) {
        //       if (props.rowData.tableData.editing != undefined) {
        //         if (props.rowData.tableData.editing == 'update') {
        //           if (props.value.label != undefined) {
        //             if (props.value.label != props.rowData.unlistedFolio.label) {
        //               ddValue = props.value;
        //             }
        //             else {
        //               ddValue = props.rowData.unlistedFolio;
        //             }
        //           }
        //           else {
        //             ddValue = props.rowData.unlistedFolio;
        //             //  CompanySelect(ddValue)
        //           }
        //         }
        //       }
        //     }
        //     return (
        //       <>
        //         <Select
        //           value={ddValue || null}
        //           onChange={(selected) => {
        //             props.onChange(selected)
        //           }}
        //           // onFocus={() => setcall(1)}
        //           options={folioNoList}
        //           searchable={true}
        //           clearable={true}
        //           noResultsText={"No result"}
        //           placeholder="Select folio.."
        //           name="folio"
        //           onKeyDown={e => handleKey(e)}
        //           menuPosition="fixed"
        //           menuShouldBlockScroll="true"
        //           isDisabled={props.rowData.stockId != undefined ? true : false}
        //         />
        //       </>
        //     )
        //   },
        // },
        {
          field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
          render: (rowData) => (
            <span>
              {NumberOnlySeperator(rowData.quantity)}
            </span>
          ),
          // validate: (rowData) =>
          //   rowData.quantity === 0
          //     ? { isValid: false, helperText: 'quantity cannot be empty' }
          //     : rowData.quantity > avilableQty ? { isValid: false, helperText: 'please enter quantity in range of available quantitysd' } : true,
          editComponent: ({ ...props }) => {
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.rowData.stockId !== undefined) {
                    if (date)
                      AvaliableSellQty(props.rowData)
                  } else {
                    // setAvilableQty(props.rowData.availableQuantity)
                    AvailableSellingQtyForUpdate(props.rowData);
                  }
                }
              }
            }
            return (
              <Tooltip title={`The Available quantity with this company is:${avilableQty}`} arrow open={avilableQty >= 0 && props.rowData.companyId !== undefined ? true : false}>
                {/* <MTableEditField {...props} placeholder={'quantity'}
                //  onFocus={setTooltip(false)}
                /> */}
                <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                  <Cleave
                    className="MuiInputBase-input MuiInput-input"
                    placeholder='Quantity'
                    autoComplete='off'
                    options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                    value={props.value}
                    onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>

              </Tooltip>
            )
            // return <MTableEditField {...props} />;
          },
        },
        {
          field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Price/Share'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                onFocus={setTooltip(false)}
                onChange={e => props.onChange(toNumber(e.target.rawValue))} />
            </div>
            </div>
            // <MTableEditField {...props} onFocus={setTooltip(false)} />;
          },
          render: (rowData) => (
            <span>
              {NumberSeperator(rowData.pricePerShare)}
            </span>
          ),
        },
        // {
        //   field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
        //   editComponent: ({ ...props }) => {
        //     let brkShare =  props.rowData.brokeragePerShare ? props.rowData.brokeragePerShare : 0
        //     props.rowData.brokeragePerShare = brkShare;
        //     props.value = brkShare ; 
        //     return <MTableEditField  {...props} />;
        //   },
        //   render: (rowData) => (
        //     <span>
        //       {NumberSeperator(rowData.brokeragePerShare)}
        //     </span>
        //   ),
        // },
        // {
        //   field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
        //   editComponent: ({ ...props }) => {
        //     let totalBrokrage = 0;
        //     totalBrokrage = props.rowData.brokeragePerShare ? props.rowData.quantity * props.rowData.brokeragePerShare : 0;
        //     props.value = totalBrokrage.toFixed(2);
        //     props.rowData.totalBrokerage = totalBrokrage;
        //     return <MTableEditField  {...props} disabled={true} />;
        //   },
        //   render: (rowData) => (
        //     <span>
        //       {NumberSeperator(rowData.totalBrokerage)}
        //     </span>
        //   ),
        // },
        {
          field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
          editComponent: ({ ...props }) => {
            let totalcost = 0;
            totalcost = (props.rowData.quantity * props.rowData.pricePerShare);
            // totalcost = (props.rowData.quantity * props.rowData.pricePerShare) - (props.rowData.totalBrokerage);
            props.value = totalcost.toFixed(2);
            props.rowData.totalCost = totalcost;
            props.rowData.brokeragePerShare = 0;
            props.rowData.totalBrokerage = 0;
            return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
              <Cleave
                className="MuiInputBase-input MuiInput-input"
                placeholder='Total Cost'
                autoComplete='off'
                options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                value={props.value}
                disabled={true}
                onChange={e => props.onChange(e.target.rawValue)} />
            </div>
            </div>
            // <MTableEditField {...props} disabled={true} />;
          },
          render: (rowData) => (
            <span>
              {NumberSeperator(rowData.totalCost)}
            </span>
          ),
        },
        {
          title: 'Stock Valuation Type', field: 'stockValuationId',
          validate: (rowData) =>
            rowData.quantity === undefined
              ? { isValid: false, helperText: 'first select company and quantity!' } : true,
          editComponent: ({ ...props }) => {
            let ddValue = props.value;
            if (props.rowData.tableData !== undefined) {
              if (props.rowData.tableData.editing !== undefined) {
                if (props.rowData.tableData.editing === 'update') {
                  if (props.value.label !== undefined) {
                    if (props.value.label !== props.rowData.stockValuation.label) {
                      ddValue = props.value;
                      console.log("Inner grid", ddValue);
                    }
                    else {
                      ddValue = props.rowData.stockValuation;
                      console.log("else grid", ddValue);
                    }
                  }
                  else {
                    ddValue = props.rowData.stockValuation;
                    console.log("outer grid", props.rowData.selection);
                    if (ddValue.value === 3) {
                      //setSelectionQty(JSON.parse(props.rowData.selection));
                      //getSelection(props, ddValue.value);
                    }
                  }
                }
              }
            }
            return (
              <Select
                value={ddValue || null}
                //  onFocus={() => SelectionSelect(props, ddValue)}
                onChange={(selected) => {
                  props.onChange(selected)
                  if (selected.label === "Selection") {
                    getSelection(props, selected.value);
                  }
                }}
                isOptionDisabled={handleDisableOption}
                options={props.rowData.stockId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 5) ? updatedStockVaualtion : stockValuationType}
                name="stockValuationId"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.stockId !== undefined && (props.rowData.stockValuation.value === 1 || props.rowData.stockValuation.value === 2 || props.rowData.stockValuation.value === 5) ? true : props.rowData.companyId === undefined ? true : false}
              />
            )
          }
        },
      ])
    }



  }, [show1, show2, show3, show4, show, stockValuationType, value, subAccountId, avilableQty, call,Mtooltip, sellingCompanyList, data, data1, data2, data3])


  // const onFocus = (e, props) => {
  //   getStockDetail(props);
  //   Mtooltip != true ? setTooltip(true) : setTooltip(false);
  // }
  // const onBlur = (e) => {
  //   Qdisabled();
  //   setTooltip(false);
  // }
  const handleShow = () => setShowUnlisted(!showUnlisted);
  const handleaddbrokershow = () => setBrokershow(!brokershow);

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <span onMouseDown={handleShow} className="m-0" title={show3 ? "Add company" : "Add unlisted company"}>
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )
  const handleCloseModal = () => {
    setShowUnlisted(false);
    setBrokershow(false)
    setUnlistedComp('');
    setUnlistedCompErr('')
    setAddbrokers('');
    setBrokersErr('')
  }


  const handleCloseModalPopUp = () => {
    setShowUnlisted(false);
    // setCertificateorfolinumber('');
    setLockinperiod('');
    setTenure('');
    // setTime([]);
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    // setDividendfrequency([]);
    setMaturityvalue(0);
    setCumulativeornoncumulative({ label: "Cumulative", value: "Cumulative" });
    setFormErr('');
    setCost(0)
  }
  const handleCloseModalPopUpAdd = (t) => {
    // setCertificateorfolinumber('');
    setLockinperiod('');
    setTime([{ label: "Month", value: "Month" }])
    setFrequencyValue([{ label: "Monthly", value: 1 }])
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    setCumulativeornoncumulative([{ label: "Cumulative", value: "Cumulative" }]);
    setFormErr('')
    setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
    setMaturityvalue(0)
  }
  const handlecloseDescModal = () => {
    setShowlistedPref(false);
    // handleCloseModalPopUp()
    setFormErr('')
  }
  const handleUnlisted = (e) => {
    const { name, value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }
  const handleaddbroker = (e) => {
    const { name, value } = e.target;
    setAddbrokers(value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
    setBrokersErr('')
    // setAddbrokers(e.target.value.replace(/[^a-zA-Z" "]/ig,''));
  }
  const handleCancel = () => {
    setErrorMessages([]);
    setIserror(false);
    setFormErr({});
    clearForm();
    onHide();
  }

  // Add Stock transaction
  const AddStockStransaction = async () => {
    let isValid = errHandle()
    let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
    let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
    if (isValid === true) {
      setLoading(true);
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultBuy = deepCloneBuy.map(function (o) {
        o.companyId = o.company.value;
        return o;
      })
      var deepCloneSell = _.cloneDeep(resultDataSell);
      var resultSell = deepCloneSell.map(function (o) {
        o.companyId = o.company.value;
        o.stockValuationId = o.stockValuation.value;
        return o;
      })
      let listed = {};
      listed = {
        instrumentId: 1,
        instrumentTypeId: show1 === true ? 1 : show2 === true ? 10 : show3 === true ? 11 : 20,
        date: fixTimezoneOffset(date),
        subAccountId: subAccountId,
        Type: show2 === true ? null : typeStock,
        brokerId: value,
        stockDetails: [...resultBuy, ...resultSell],
        stampDuty: stampDuty,
        sst: stt,
        gst: gst,
        otherCharges: otherCharges,
        narration: narration,
        totalAmountDue: totalAmountDue
      }
      await addStocks(listed).then(res => {
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            Swal.fire(res.data._response.message, "", 'success');
            setSuccess(true);
            // onTransactionGridRefresh();
            handleCloseModalPopUp();
            clearForm();
          } else {
            Swal.fire(res.data._response.message, "", 'info');
          }
        } else {
          setLoading(false);
        }
      }).catch(e => {
        setError(e.message)
        setLoading(false)
      })
    }
  }

  // update Stock transaction
  const UpdateStockStransaction = async () => {
    //
    let isValid = errHandle()
    if (isValid === true) {
      setLoading(true);
      let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
      let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultBuy = deepCloneBuy.map(function (o) {
        if (o.company !== undefined) {
          o.companyId = o.company.value;
          o.stockValuationId = o.stockId !== undefined ? o.stockValuation.value : 4;
        }
        return o;
      })
      var deepCloneSell = _.cloneDeep(resultDataSell);
      var resultSell = deepCloneSell.map(function (o) {
        if (o.company !== undefined) {
          o.companyId = o.company.value;
        }
        if (o.stockValuation !== undefined) {
          o.stockValuationId = o.stockValuation.value;
        }
        return o;
      })
      let listed = {};
      listed = {
        stockHeaderId: headerId,
        instrumentId: 1,
        instrumentTypeId: show1 === true ? 1 : show2 === true ? 10 : show3 === true ? 11 : 20,
        date: fixTimezoneOffset(date),
        subAccountId: subAccountId,
        Type: typeStock,
        brokerId: value,
        stockDetails: [...resultBuy, ...resultSell],
        stampDuty: stampDuty,
        sst: stt,
        gst: gst,
        otherCharges: otherCharges,
        narration: narration,
        totalAmountDue: totalAmountDue
      }
      // console.log(listed);
      await updateStock(listed).then(res => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 1) {
            Swal.fire(res.data._response.message, "", 'success');
            onTransactionGridRefresh();
          } else {
            Swal.fire(res.data._response.message, "", 'info');
          }
        } else {
          setLoading(false)
        }
      }).catch(e => {
        setError(e.message)
        setLoading(false)
      })
    }
  }

  //delete stocks transaction
  const DeleteStockStransaction = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          DeleteStocksTransactions(stockHeaderId).then((response) => {
            if (response.status === 200) {
              swal({
                title: "Deleted",
                text: response.data._response.message,
                icon: "success",
              });
              onTransactionGridRefresh();
            } else {
              swal({
                title: "Ooops!!",
                text: "Somthing Wrong",
                icon: "warning",
              })
            }

          })
            .catch((error) => {
              console.log(error, "error in delete transaction")
            })

        } else {
          swal("Cancelled", "Your  data  is safe ", "error");
        }
      });
  }

  //Broker Validation on selling company selection
  const brokerSelect = () => {
    setMtooltip(false)
    if (brokerValue.length < 1) {
      setMtooltip(true)
      setEnabledAdisabled(true);
    }
    if (sellingCompanyList.length < 1) {
      setEnabledAdisabled(true);
    }
    else {
      setEnabledAdisabled(false);
    }
  }


  // const SelectionSelect = async (props, ddData) => {
  //   if (ddData != undefined && ddData.value == 3) {
  //     await getSelection(props, ddData.value)
  //   }
  // }

  const HandleChange = (event) => {
    setValue(event.value);
    setMtooltip(false);
    setBrokerValue(event);
  }
  const HandleChangeType = (e) => {
    setTypeStock(e.value);
    setTypeValue(e);
    setFormErr({ ...formErr, typeStock: '' });
  }
  const handleChangeCumulative = (e) => {
    setCumulativeornoncumulative(e);
    setCumulativeType(e.value);
  }
  const handleChangeConvert = (e) => {
    setRedeemableorconvertible(e);
    setConvertType(e.value);
  }
  const handleChangeFrequency = (e) => {
    // setDividendfrequency(e);
    setFrequencyValue(e);
  }
  const HandleChangeTime = (e) => {
    setTime(e);
    setTimeValue(e.value);
  }
  //function for adding a new row to the table
  const handleRowAdd = async (newData, resolve, reject, a) => {
    let companyData = newData.companyId;
    let errorList = []
    if (show1 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (!newData.quantity && newData.quantity > avilableQty) {
        errorList.push("Please Enter Quantity In Range Of Available Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
        errorList.push("Please Enter Brokerage Per Share Field")
      }
      // if (!newData.target) {
      //   errorList.push("please enter target field")
      // }
      // if (!newData.stopLoss) {
      //   errorList.push("please enter stopLoss field")
      // }
      // if (!newData.estHoldingPeriod) {
      //   errorList.push("please enter Est Holding Period field")
      // }
      // if (!newData.recommendedBy) {
      //   errorList.push("please enter Recommended By field")
      // }
    }

    if (show3 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (!newData.quantity && newData.quantity > avilableQty) {
        errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
        errorList.push("Please Enter Brokerage Per Share Field")
      }

      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }

      if (!(dividend && maturityvalue || cost)) {
        errorList.push("Please Fill Addtional Information")
      }

    }
    if (show4 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (!newData.quantity && newData.quantity > avilableQty) {
        errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value");
        }
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("Please Enter Brokerage Per Share Field")
      // }

      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      if (!(dividend && maturityvalue || cost)) {
        errorList.push("Please Fill Addtional Information")
      }
    }
    if (show2 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      // if (!newData.folioNumber) {
      //   errorList.push("Please Enter Folio Number Field")
      // }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("Please Enter Brokerage Per Share Field")
      // }
      // if (!newData.lockInPeriod) {
      //   errorList.push("please enter Lock in period field")
      // }
    }
    if (errorList.length < 1) {
      let newUserdata = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];

      newData.companyId = newData.companyId.label;
      newData.company = companyData;
      if (show3 || show4) {
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        //moment.tz(dateofmaturityorconversion, "Asia/Kolkata").format();
        newData.redeemConvertible = convertType;
        newData.lockInPeriod = lockinperiod === "" ? "0" + timeValue : lockinperiod + timeValue;
        // newData.folioNumber = certificateorfolinumber;
        newData.dividendPerc = dividend;
        newData.frequencyId = Number(frequencyValue.value) || Number(frequencyValue[0].value);
        newData.frequencyI = frequencyValue;
        newData.cumulativeNonCumulative = cumulativeType;
        // newData.maturityValue = parseInt(maturityvalue);
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = Number(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
        newData.instrumentTypeId = convertType === "Redeemable" && show3 === true ? 15
          : convertType === "Convertible" && show3 === true ? 14 : convertType === "Redeemable" && show4 === true ? 24 :
            convertType === "Convertible" && show4 === true ? 23 : 0;
        // newData.tenure = tenure;

      }
      if (show2 || show1) {
        newData.instrumentTypeId = show2 === true ? 10 : 1;
      }

      newUserdata.push(newData);
      var result = newUserdata.map(function (o) {
        if (o.stockId === undefined) {
          o.transactStockType = "Buy";
        }
        return o;
      })
      await show1 === true ? setAllEntry1(result) : show2 === true ? setAllEntry2(result) : show3 === true ? setAllEntry3(result) : setAllEntry4(result);
      resolve()
      let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
      handleTotalAmt(result, sell);
      setErrorMessages([])
      setIserror(false)
      handleCloseModalPopUp()
    }
    else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
      handleCloseModalPopUp()
    }
  }
  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    let cpy = newData.company.label;
    let companyData = cpy === newData.companyId && newData.companyId.label === undefined ? newData.company : newData.companyId;
    let errorList = [];
    if (show1 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
        errorList.push("Please Enter Brokerage Per Share Field")
      }
      // if (!newData.target) {
      //   errorList.push("please enter target field")
      // }
      // if (!newData.stopLoss) {
      //   errorList.push("please enter stopLoss field")
      // }
      // if (!newData.estHoldingPeriod) {
      //   errorList.push("please enter Est Holding Period field")
      // }
      // if (!newData.recommendedBy) {
      //   errorList.push("please enter Recommended By field")
      // }
    }
    if (show3 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (!newData.quantity && newData.quantity > avilableQty) {
        errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
        errorList.push("Please Enter Brokerage Per Share Field")
      }
      if (!newData.pricePerShare) {
        errorList.push("Please Enter Current Market Price Field")
      }

    }

    if (show4 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (!newData.quantity && newData.quantity > avilableQty) {
        errorList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("Please Enter Brokerage Per Share Field")
      // }

      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      // if (!(newData.lockInPeriod && newData.dividendPerc && newData.maturityValue)) {
      //   errorList.push("please Fill Addtional Information !!")
      // }
    }
    if (show2 === true) {
      if (!newData.companyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorList.push("Please Enter Quantity Field")
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Please Enter Price Per Share Field")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("Please Enter Brokerage Per Share Field")
      // }

    }
    if (errorList.length < 1) {
      const updateStock = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];
      const index = oldData.tableData.id;
      newData.companyId = cpy === newData.companyId && newData.companyId.label === undefined ? newData.companyId : newData.companyId.label !== undefined ? newData.companyId.label : newData.companyId.label;
      newData.company = companyData;
      if ((show3 || show4) && (lockinperiod !== "" || dividend !== 0)) {
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        //moment.tz(dateofmaturityorconversion, "Asia/Kolkata").format();
        newData.redeemConvertible = convertType;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        // newData.folioNumber = certificateorfolinumber;
        newData.dividendPerc = dividend;
        newData.frequencyId = frequencyValue ? Number(frequencyValue.value) : 0;
        newData.frequencyI = frequencyValue;
        newData.cumulativeNonCumulative = cumulativeType;
        // newData.maturityValue = parseInt(maturityvalue);
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = Number(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
        newData.instrumentTypeId = convertType === "Redeemable" && show3 === true ? 15
          : convertType === "Convertible" && show3 === true ? 14 : convertType === "Redeemable" && show4 === true ? 24 :
            convertType === "Convertible" && show4 === true ? 23 : 0;
        // newData.tenure = tenure;

      } else {
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = newData.totalCost;
        }
      }
      if (show2 || show1) {
        newData.instrumentTypeId = show2 === true ? 10 : 1;
      }
      updateStock[index] = newData;
      show1 === true ? setAllEntry1([...updateStock]) : show2 === true ? setAllEntry2([...updateStock]) : show3 === true ? setAllEntry3([...updateStock]) : setAllEntry4([...updateStock]);
      resolve()
      setIserror(false)
      let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
      handleTotalAmt([...updateStock], sell);
      setErrorMessages([])
      handleCloseModalPopUp()
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
      handleCloseModalPopUp()
    }
  }
  //function for deleting a row
  const handleRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : show3 === true ? [...allEntry3] : [...allEntry4];
    if (oldData.stockId === undefined) {
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : show3 === true ? setAllEntry3([...dataDelete]) : setAllEntry4([...dataDelete]);
      let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
      handleTotalAmt([...dataDelete], sell);
      resolve()
      handleCloseModalPopUp();
    }
    else {
      deleteStock(oldData.stockId).then(async (res) => {
        if (res.data._response.status === 1) {
          Swal.fire(res.data._response.message, "", 'success');
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : show3 === true ? setAllEntry3([...dataDelete]) : setAllEntry4([...dataDelete]);
          let sell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
          handleTotalAmt([...dataDelete], sell);
          resolve()
          handleCloseModalPopUp();
          onTransactionGridRefresh();
        }
        else {
          Swal.fire(res.data._response.message, "", 'info');
          reject()
          handleCloseModalPopUp();
        }
      })
        .catch(err => {
          console.log(err);
        })
    }
  }
  //function for adding a new sell row to the table
  const handleRowAddSell = async (newData, resolve, reject) => {

    let quantityN = Number(newData.quantity)
    if (!newData.stockValuationId) {
      newData.stockValuationId = { value: 1, label: 'FIFO' }
    }
    let resultBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
    let qty = undefined;
    let company = newData.companyId;
    let stockValuation = newData.stockValuationId === undefined ? 1 : newData.stockValuationId;
    // let unlistedFolio = show2 || show4 ? newData.folioNumber : null
    let sumSellStock = 0;
    for (let i = 0; i < selectionQty.length; i++) {
      sumSellStock += selectionQty[i].sellQuantity;
    }
    let errorsList = []
    if (show1 || show3) {
      if (!newData.companyId) {
        errorsList.push("Please Select Company Name Field")
      }
      if (!newData.quantity) {
        errorsList.push("Please Enter Quantity Field")
      }
      else if (newData.quantity > avilableQty) {
        errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorsList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorsList.push("Please Enter Price Per Share Field")
      }
      // if (!newData.brokeragePerShare) {
      //   errorsList.push("please Enter Brokerage Per Share Field")
      // }
      if (!newData.stockValuationId) {
        errorsList.push("Please Select Stock Valuation Type Field")
      }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorsList.push('Please Select Stock Quantity For Selection Calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
          errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
        }
      }
      // if(newData.quantity < )
    }
    if (show2 === true || show4) {
      if (!newData.companyId) {
        errorsList.push("Please Select Company Name Field")
      }
      // if (!newData.folioNumber) {
      //   errorsList.push("Please Select Folio Number Field")
      // }
      if (!newData.quantity) {
        errorsList.push("Please Enter Quantity Field")
      }
      else if (newData.quantity > avilableQty) {
        errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorsList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorsList.push("Please Enter Price Per Share Field")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorsList.push("please Enter Brokerage Per Share Field")
      // }
      if (!newData.stockValuationId) {
        errorsList.push("Please Select Stock Valuation Type Field")
      }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorsList.push('Please Select stock Quantity For Selection Calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
          errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
        }
      }
    }
    let arrayById = selectionQty.filter(obj => obj.tableData.checked === true).map(i => {
      return { ...i };
    })

    if (errorsList.length < 1) {


      let newUserdata = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];

      //contains all selection data
      // newData.selectionAll =  JSON.stringify(selectionQty);
      newData.selection = JSON.stringify(selectionQty);

      //contains checked selection data
      // newData.selection =  JSON.stringify(selection2);

      // if (show2 || show4) {
      //   newData.folioNumber = newData.folioNumber.label;
      //   newData.unlistedFolio = unlistedFolio;
      // }
      newData.companyId = newData.companyId.label;
      newData.stockValuationId = newData.stockValuationId.label;
      newData.company = company;
      newData.quantity = quantityN
      newData.stockValuation = stockValuation;
      newData.availableQuantity = avilableQty;
      newData.availableSellQuantity = avilableSellQty;
      newData.transactStockType = 'Sell';
      // newData.selection =  JSON.stringify(selectionQty);
      let IntraDayData = [];
      IntraDayData.push(newData);

      resultBuy && resultBuy.map((r) => {
        if (r.company.label === IntraDayData[0].companyId && r.stockId === undefined) {
          qty = r.quantity + intraDayQuantity;
        }
        else if (intraDayQuantity > 0) {
          qty = intraDayQuantity;
        }
      })

      if (resultBuy.length === 0 && intraDayQuantity > 0) {
        qty = intraDayQuantity;
        setIntraDayQuantity(IntraDayData[0].quantity - intraDayQuantity);
      }
      if (IntraDayData[0].quantity <= qty) {

        const updatedObj = { ...IntraDayData[0], brokeragePerShare: 0, totalBrokerage: 0, totalCost: (IntraDayData[0].quantity * IntraDayData[0].pricePerShare), stockValuation: { label: 'IntraDay', value: 5 }, stockValuationId: 'IntraDay' };
        const updatedProjects = [
          ...IntraDayData.slice(0, 0),
          updatedObj,
          ...IntraDayData.slice(0 + 1),
        ];
        IntraDayData = updatedProjects;
      }
      else if (IntraDayData[0].quantity > qty) {

        const updatedObj = { ...IntraDayData[0], quantity: qty, brokeragePerShare: 0, totalBrokerage: 0, totalCost: qty * IntraDayData[0].pricePerShare, stockValuation: { label: 'IntraDay', value: 5 }, stockValuationId: 'IntraDay' };
        // make final new array of objects by combining updated object.
        const updatedProjects = [
          ...IntraDayData.slice(0, 0),
          updatedObj,
          ...IntraDayData.slice(0 + 1),
        ];
        const updatedObjData = { ...IntraDayData[0], totalBrokerage: IntraDayData[0].brokeragePerShare * (IntraDayData[0].quantity - qty), totalCost: ((IntraDayData[0].quantity - qty) * IntraDayData[0].pricePerShare) - (IntraDayData[0].brokeragePerShare * (IntraDayData[0].quantity - qty)), quantity: IntraDayData[0].quantity - qty, stockValuation: { label: 'IntraDay', value: 5 }, stockValuationId: 'IntraDay' };
        // make final new array of objects by combining updated object.
        const updatedProjectsData = [
          ...IntraDayData.slice(0, 0),
          updatedObjData,
          ...IntraDayData.slice(0 + 1),
        ];
        let finalData = [...updatedProjects, ...updatedProjectsData];
        IntraDayData = finalData;
      }
      var newResult = [...newUserdata, ...IntraDayData];
      // var newResult = result.map(function (o) {
      //   if (o.stockId == undefined && o.stockValuation.value == 3) {
      //     o.transactStockType = "Sell";
      //   }
      //   return o;
      // })
      await show1 === true ? setdata(newResult) : show2 === true ? setdata1(newResult) : show3 === true ? setdata2(newResult) : setdata3(newResult);
      resolve()
      let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
      handleTotalAmt(buy, newResult);
      setErrorMessagesSell([])
      setIsError(false);
    }
    else {
      setErrorMessagesSell(errorsList)
      setIsError(true)
      reject()
    }
  }
  //function for  updating the existing sell row details
  const handleRowUpdateSell = (newData, oldData, resolve, reject) => {

    let quantityN = Number(newData.quantity)

    let cpy = newData.company.label;
    let companyData = cpy === newData.companyId ? newData.company : newData.companyId;
    let stockE = newData.stockValuation.label;
    let stockValuation = stockE === newData.stockValuationId ? newData.stockValuation : newData.stockValuationId;
    // let folioNo = null;
    // let folioData = null;
    // if (show2 || show4) {
    //   folioNo = newData.unlistedFolio.label;
    //   folioData = folioNo === newData.folioNumber ? newData.unlistedFolio : newData.folioNumber;
    // }
    let sumSellStock = 0;
    if (selectionQty !== undefined || selectionQty !== null) {

      for (let i = 0; i < selectionQty.length; i++) {

        sumSellStock += selectionQty[i].sellQuantity;
      }
    }
    let errorsList = [];
    if (show1 || show4 || show3) {
      if (!newData.quantity) {
        errorsList.push("please Enter Quantity Field")
      }
      if (newData.quantity > avilableQty) {
        errorsList.push("please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorsList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorsList.push("please enter Price per share field")
      }
      if (!newData.stockValuationId) {
        errorsList.push("please select stock Valuation type field")
      }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorsList.push('please select stock quantity for selection calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
          errorsList.push('Sell Quantity of selection must be equal to sell Quantity');
        }
      }
    }
    if (show2 === true) {
      if (!newData.companyId) {
        errorsList.push("Please Select Company Name Field")
      }
      // if (!newData.folioNumber) {
      //   errorsList.push("Please Select Folio Number Field")
      // }
      if (!newData.quantity) {
        errorsList.push("Please Enter Quantity Field")
      }
      if (newData.quantity > avilableQty) {
        errorsList.push("Please Enter Quantity In Range Of Avilable Quantity");
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorsList.push("Quantity Doesn't Accept Decimal Value!");
        }
      }
      if (!newData.pricePerShare) {
        errorsList.push("Please Enter Price Per Share Field")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorsList.push("Please Enter Brokerage Per Share Field")
      // }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorsList.push('Please Select Stock Quantity For Selection Calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && Number(newData.quantity) !== sumSellStock) {
          errorsList.push('Sell Quantity Of Selection Must Be Equal To Sell Quantity');
        }
      }
    }
    if (errorsList.length < 1) {
      const updateStock = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];
      const index = oldData.tableData.id;
      newData.companyId = cpy === newData.companyId ? newData.companyId : newData.companyId.label;
      newData.stockValuationId = stockE === newData.stockValuationId ? newData.stockValuationId : newData.stockValuationId.label;
      newData.company = companyData;
      newData.stockValuation = stockValuation;
      newData.quantity = quantityN
      // if (show2 || show4) {
      //   newData.folioNumber = folioNo === newData.folioNumber ? newData.folioNumber : newData.folioNumber.label;
      //   newData.unlistedFolio = folioData;
      // }

      let arrayById = selectionQty.filter(obj => obj.tableData.checked === true).map(i => {
        return { ...i };
      })

      newData.selection = JSON.stringify(selectionQty);

      updateStock[index] = newData;
      show1 === true ? setdata([...updateStock]) : show2 === true ? setdata1([...updateStock]) : show3 === true ? setdata2([...updateStock]) : setdata3([...updateStock]);
      resolve()
      let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
      handleTotalAmt(buy, [...updateStock]);
      setErrorMessagesSell([])
      setIsError(false)
    } else {
      setErrorMessagesSell(errorsList)
      setIsError(true)
      reject();
    }
  }
  //function for deleting a sell row
  const handleRowDeleteSell = async (oldData, resolve, reject) => {
    const dataDelete = show1 === true ? [...data] : show2 === true ? [...data1] : show3 === true ? [...data2] : [...data3];
    if (oldData.stockId === undefined) {
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      await show1 === true ? setdata([...dataDelete]) : show2 === true ? setdata1([...dataDelete]) : show3 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
      let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
      handleTotalAmt(buy, [...dataDelete]);
      resolve()
    }
    else {
      deleteStock(oldData.stockId).then(async (res) => {
        if (res.data._response.status === 1) {
          Swal.fire(res.data._response.message, "", 'success');
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          await show1 === true ? setdata([...dataDelete]) : show2 === true ? setdata1([...dataDelete]) : show3 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
          let buy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
          handleTotalAmt(buy, [...dataDelete]);
          resolve();
          onTransactionGridRefresh();
        }
        else {
          Swal.fire(res.data._response.message, "", 'info');
          reject()
        }
      })
        .catch(err => {
          console.log(err);
        })
    }
  }

  const getSelection = (row, stockValId) => {
    let stockIDs = row.rowData.stockId
    let selection = row.rowData.selection;
    let Id = entityId == null ? subAccountId : entityId;
    let emptyArr = row.rowData.selection;
    let editing = row.rowData.tableData === undefined ? undefined : row.rowData.tableData.editing;


    if (stockValId === 3 && Id != null && (row.rowData.companyId !== undefined || row.rowData.company !== undefined)) {
      let companyId = row.rowData.companyId.value === undefined ? row.rowData.company.value : row.rowData.companyId.value;
      setSelection(true);
      setLoading(true);
      if (selection === undefined || emptyArr === [] || editing === "update") {

        SelectionList(Id, value, companyId).then(res => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 2) {
              setSelectionQty([]);
              setSelectedRows([]);
            }
            else {

              let AvlQuantity = res.data._response.data.fetchSelectionCompany;
              let selectionQtys = [];
              AvlQuantity && AvlQuantity.map((r, index) => {

                if (selectionQty.length !== 0) {
                  selectionQty && selectionQty.map((s, i) => {
                    if (i === index) {

                      let a = r;
                      let total = 0
                      let initialtotal = 0

                      let availableQuantitymain = 0;
                      data && data.map((t) => {
                        if (t.stockId !== undefined) {

                          initialtotal = initialtotal + t.quantity;
                        }
                        total = total + t.quantity;

                      })
                      availableQuantitymain = r.availableQuantity + initialtotal;
                      let totalSellQuantity = s.sellQuantity <= s.totalSellQuantity ? s.totalSellQuantity : s.sellQuantity;
                      let selectionminusquantity = availableQuantitymain - total;

                      // let selectionminusquantity = data.length === 1 ? r.availableQuantity : r.availableQuantity - (s.sellQuantity + s.totalSellQuantity) 
                      // (s.sellQuantity <=  s.totalSellQuantity ? s.totalSellQuantity : s.sellQuantity);
                      selectionQtys.push({ id: index, StockId: r.stockId, sellQuantity: 0.00, totalSellQuantity: totalSellQuantity, DatePurchase: r.date, AvailableQuantity: selectionminusquantity, Price: r.pricePerShare })
                    }
                  })
                } else {
                  selectionQtys.push({ id: index, StockId: r.stockId, sellQuantity: 0.00, totalSellQuantity: 0.00, DatePurchase: r.date, AvailableQuantity: r.availableQuantity, Price: r.pricePerShare })
                }
              })
              setSelectionQty(selectionQtys);
              setSelectedRows([]);
            }
          } else {
            setLoading(false);
            setError(res.data.message)
          }
        }).catch(e => {
          setError(e.message)
          setLoading(false);
        })
      } else {

        // let parseData = JSON.parse(row.rowData.selectionAll);
        let parseData = JSON.parse(row.rowData.selection);
        setSelectionQty(parseData);
        setLoading(false);
      }
    }

    if (stockIDs && editing === "update") {
      setSelectionQty([]);
      SelectionListUpdate(stockIDs)
        .then((res) => {
          if (res.status === 200) {
            if (res.data._response.status === 1) {

              let AvlQuantity = res.data._response.data.selectionDataForStocksUpdate;
              let selectionQty = AvlQuantity && AvlQuantity.map((r, index) => {

                return {
                  id: index, StockId: r.stockId, sellQuantity: 0.00, DatePurchase: r.date, AvailableQuantity: avilableQty, Price: r.pricePerShare, sellStockId: r.sellStockId
                }
              })
              setSelectionQty(selectionQty);
            }
          }
        })
        .catch((err) => {
          console.log(err, "error in getting selection update quantity")
        })
    }
  }

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  // seperation by comma
  const Number4Seperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(input)
  }

  const selectionHide = () => setSelection(false);
  const handleTotalAmt = (buy, sell) => {
    let StockDetail = [...buy, ...sell];
    var sumSellStock = 0;
    var sumBuyStock = 0;
    for (let i = 0; i < StockDetail.length; i++) {
      if (StockDetail[i].transactStockType === "Sell") {
        sumSellStock += Number(StockDetail[i].totalCost);
      }
      else if (StockDetail[i].transactStockType === "Buy") {
        sumBuyStock += Number(StockDetail[i].totalCost);
      }
    }

    let TotalAmtDueToFromBroker = (Number(sumBuyStock) - Number(sumSellStock)) + (parseFloat(stt) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stampDuty));

    setTotalAmountDue(TotalAmtDueToFromBroker);
  }

  const handleOtherCharges = (value) => {
    let otherchr = value === '' ? 0 : value;
    setOtherCharges(otherchr);
    let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
    var sumSellStock = 0;
    var sumBuyStock = 0;
    for (let i = 0; i < StockDetail.length; i++) {
      if (StockDetail[i].transactStockType === "Sell") {
        sumSellStock += Number(StockDetail[i].totalCost);
      }
      else if (StockDetail[i].transactStockType === "Buy") {
        sumBuyStock += Number(StockDetail[i].totalCost);
      }
    }
    let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(stt) + parseFloat(gst) + parseFloat(value === '' ? 0 : value) + parseFloat(stampDuty));

    setTotalAmountDue(TotalAmtDueToFromBroker);
  }
  const handleGst = async (value) => {
    let gst = value === '' ? 0 : value;
    setGST(parseFloat(gst));
    let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
    var sumSellStock = 0;
    var sumBuyStock = 0;
    for (let i = 0; i < StockDetail.length; i++) {
      if (StockDetail[i].transactStockType === "Sell") {
        sumSellStock += Number(StockDetail[i].totalCost);
      }
      else if (StockDetail[i].transactStockType === "Buy") {
        sumBuyStock += Number(StockDetail[i].totalCost);
      }
    }
    let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(stt) + parseFloat(value === '' ? 0 : value) + parseFloat(otherCharges) + parseFloat(stampDuty));

    await setTotalAmountDue(TotalAmtDueToFromBroker);
  }
  const handleStt = async (value) => {
    let stt = value === '' ? 0 : value;
    setSTT(parseFloat(stt));
    let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
    var sumSellStock = 0;
    var sumBuyStock = 0;
    for (let i = 0; i < StockDetail.length; i++) {
      if (StockDetail[i].transactStockType === "Sell") {
        sumSellStock += Number(StockDetail[i].totalCost);
      }
      else if (StockDetail[i].transactStockType === "Buy") {
        sumBuyStock += Number(StockDetail[i].totalCost);
      }
    }
    let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(value === '' ? 0 : value) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stampDuty));

    await setTotalAmountDue(TotalAmtDueToFromBroker);
  }
  const handleStampDuty = async (value) => {
    let stampduty = value === '' ? 0 : value;
    setStampDuty(parseFloat(stampduty));
    let StockDetail = show1 === true ? [...allEntry1, ...data] : show2 === true ? [...allEntry2, ...data1] : show3 === true ? [...allEntry3, ...data2] : [...allEntry4, ...data3];
    var sumSellStock = 0;
    var sumBuyStock = 0;
    for (let i = 0; i < StockDetail.length; i++) {
      if (StockDetail[i].transactStockType === "Sell") {
        sumSellStock += Number(StockDetail[i].totalCost);
      }
      else if (StockDetail[i].transactStockType === "Buy") {
        sumBuyStock += Number(StockDetail[i].totalCost);
      }
    }
    let TotalAmtDueToFromBroker = (sumBuyStock - sumSellStock) + (parseFloat(value === '' ? 0 : value) + parseFloat(gst) + parseFloat(otherCharges) + parseFloat(stt));

    await setTotalAmountDue(TotalAmtDueToFromBroker);
  }
  const errHandle = () => {
    let formErr = {};
    let isValid = true;
    let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : show3 === true ? allEntry3 : allEntry4;
    let resultDataSell = show1 === true ? data : show2 === true ? data1 : show3 === true ? data2 : data3;
    if (brokerValue.length < 1) {
      isValid = false;
      formErr["broker"] = "Please Select Broker";
    }
    if (resultDataBuy.length <= 0 && resultDataSell.length <= 0) {
      isValid = false;
      Swal.fire('Please fill Buy/Sell Data!!', "", "warning")
    }
    setFormErr(formErr)
    return isValid
  }
  const handleCloseMod = () => {
    setFormErr({})
    setErrorMessages([])
    setIsError(false)
    setIserror(false)
    clearForm();
    onHide();
  }
  const submitPreModal = () => {
    let formErr = []
    let isValid = true;
    if (show3) {
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        // if(cumulativeType === "Cumulative" ){
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please Fill Valid Values";
          isValid = false;
        }
      }
      // if (cumulativeornoncumulative.value === "Non Cumulative" || cumulativeornoncumulative[0].value === "Non Cumulative") {
      //   // if(cumulativeType !== "Cumulative" && cumulativeType === "Non Cumulative" ){
      //     debugger
      //     if (!cost || cost <= 0) {
      //       formErr["costValue"] = "Please fill valid values!";
      //       isValid = false;
      //     }
      //   }

      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please Fill Valid Values";
        isValid = false;
      }
      if (dateofmaturityorconversion && date) {
        let trandate = moment(fixTimezoneOffset(date)).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date Not Same As Investment Date";
          isValid = false;
        }
      }
    }
    if (show4) {
      // if (certificateorfolinumber == "" || certificateorfolinumber == null) {
      //   formErr["certificateorfolinumber"] = "Please Fill Certificate/Folio Number Field";
      //   isValid = false;
      // }
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please Fill Valid Values";
          isValid = false;
        }
      }

      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please Fill Valid Values";
        isValid = false;
      }
      if (dateofmaturityorconversion && date) {
        let trandate = moment(fixTimezoneOffset(date)).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date Not Same As Investment Date";
          isValid = false;
        }
      }
    }
    if (isValid === true) {
      handlecloseDescModal();
    }
    setFormErr(formErr);
    return isValid;

    // console.log(dateofmaturityorconversion, convertType, lockinperiod + timeValue, certificateorfolinumber, dividend, frequencyValue, maturityvalue, cumulativeType)
    // let a=1
    // new Promise((resolve, reject) => {
    //   handleRowAdd(newData, resolve, reject)
    // }), 
  }
  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const columnsB1 = [
    {
      title: 'Company Name', field: 'companyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.stockId !== undefined ? true : false}
          />
        )
      },
    },
    {
      field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        // return<MTableEditField {...props} 
        // />
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Quantity'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            // onClick={addClass() ? cleavefocusedTxtField.focus: cleavefocusedTxtField.blur}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>

      },

    },
    {
      field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Price/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
    },
    {
      field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Brokerage/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {Number4Seperator(rowData.brokeragePerShare)}
        </span>
      ),
    },
    {
      field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(4);
        props.rowData.totalBrokerage = totalBrokrage;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Brokerage'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        //  <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {Number4Seperator(rowData.totalBrokerage)}
        </span>
      ),
    },
    {
      field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalcost = 0;
        totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalcost.toFixed(2);
        props.rowData.totalCost = totalcost.toFixed(2);
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Cost'
            autoComplete='off'
            disabled
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    {
      field: 'target', title: 'Target', width: 130,
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Target'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        //  <MTableEditField {...props} />;
      },
    },
    {
      field: 'stopLoss', title: 'Stop Loss', width: 130,
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Stop Loss'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    { field: 'recommendedBy', title: 'Recommended by', width: 130 }
  ];
  const columnsB2 = [
    {
      title: 'Company Name', field: 'companyId', cellStyle: {
        width: 200,
        maxWidth: 200
      },
      headerStyle: {
        width: 200,
        maxWidth: 200
      },
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={unlistedCompany}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.stockId !== undefined ? true : false}
          />
        )
      },
    },
    // { field: 'folioNumber', title: 'Folio Number', width: 130 },
    {
      field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Quantity'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },

    },
    {
      field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Price/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
    },
    // {
    //   field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField  {...props} />;
    //   },
    //   render: (rowData) => (
    //     <span>
    //       {NumberSeperator(rowData.brokeragePerShare)}
    //     </span>
    //   ),
    // },
    // {
    //   field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     let totalBrokrage = 0;
    //     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //     props.value = totalBrokrage.toFixed(2);
    //     props.rowData.totalBrokerage = totalBrokrage;
    //     return <MTableEditField  {...props} />;
    //   },
    //   render: (rowData) => (
    //     <span>
    //       {NumberSeperator(rowData.totalBrokerage)}
    //     </span>
    //   ),
    // },
    {
      field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalcost = 0;
        totalcost = (props.rowData.quantity * props.rowData.pricePerShare);
        // totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalcost.toFixed(2);
        props.rowData.totalCost = totalcost;
        props.rowData.brokeragePerShare = 0;
        props.rowData.totalBrokerage = 0;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Cost'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
  ];
  const columnsB3 = [
    {
      title: 'Company Name', field: 'companyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            components={{ Control }}
            // options={Scrip}
            options={companyListPre}
            name="companyId"
            isDisabled={props.rowData.stockId !== undefined ? true : false}
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Quantity'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Price/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {Number4Seperator(rowData.brokeragePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Brokerage/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {Number4Seperator(rowData.totalBrokerage)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(4);
        props.rowData.totalBrokerage = totalBrokrage;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Brokerage'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        //  <MTableEditField  {...props} />;
      },
    },
    {
      field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalcost = 0;
        totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalcost.toFixed(2);
        props.rowData.totalCost = totalcost.toFixed(2);
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Cost'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        //  <MTableEditField {...props} />;
      }
    },

    {
      field: 'target', title: 'Target', width: 130,
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Target'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'stopLoss', title: 'Stop Loss', width: 130,
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Stop Loss'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    { field: 'recommendedBy', title: 'Recommended by', width: 130 },
    {
      title: "Addtional Data",
      field: "internal_action",
      // editable: false,

      editComponent: ({ ...props }) => {
        // console.log(props);
        let t = props.rowData
        return <span onClick={() => {
          // debugger
          if (t.tableData !== undefined) {

            if (t.dividendPerc !== undefined) {
              setDividend(t.dividendPerc);
            }
            if (t.frequencyId !== undefined) {
              // setDividendfrequency(t.frequencyI)
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod ? t.lockInPeriod : "0Month";
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            if (t.folioNumber !== undefined) {
              setCertificateorfolinumber(t.folioNumber);
            }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }
  ];
  const columnsB4 = [
    {
      title: 'Company Name', field: 'companyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={Scrip}
            options={unlistedCompany}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.stockId !== undefined ? true : false}
          />
        )
      },
    },
    {
      field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Quantity'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Price/Share'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        //  <MTableEditField {...props} />;
      },
    },
    // {
    //   field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
    //   render: (rowData) => (
    //     <span>
    //         {NumberSeperator(rowData.brokeragePerShare)}
    //     </span>
    // ),
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField  {...props} />;
    //   },
    // },
    // {
    //   field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
    //   render: (rowData) => (
    //     <span>
    //         {NumberSeperator(rowData.totalBrokerage)}
    //     </span>
    // ),
    //   editComponent: ({ ...props }) => {
    //     let totalBrokrage = 0;
    //     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //     props.value = totalBrokrage.toFixed(2);
    //     props.rowData.totalBrokerage = totalBrokrage;
    //     return <MTableEditField  {...props} />;
    //   },
    // },
    {
      field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalcost = 0;
        totalcost = (props.rowData.quantity * props.rowData.pricePerShare);
        // totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalcost.toFixed(2);
        props.rowData.totalCost = totalcost.toFixed(2);
        props.rowData.brokeragePerShare = 0;
        props.rowData.totalBrokerage = 0;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Total Cost'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(e.target.rawValue)} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      }
    },
    {
      title: "Addtional Data",
      field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData;
        return <span onClick={() => {
          if (t.tableData !== undefined) {

            if (t.dividendPerc !== undefined) {
              setDividend(t.dividendPerc);
            }
            if (t.frequencyId !== undefined) {
              // {lable:frequency.frequencyName,value:t.frequencyI}
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            if (t.folioNumber !== undefined) {
              setCertificateorfolinumber(t.folioNumber);
            }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            // }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
          }
          else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
      }
    }
  ];

  let valueLockin = lockperiodpre;
  let splitData1 = valueLockin ? valueLockin.split(/([0-9]+)/) : null;
  let time1 = splitData1 ? splitData1[1] : "-";
  let period1 = splitData1 ? splitData1[2] : "";


  function replaceNonNumeric(numStr) {

    return String(numStr).replace(/[^0-9]/g, '')

  }

  function commarize(numStr) {

    return Number(replaceNonNumeric(numStr)).toLocaleString()

  }

  return (
    <>
      <div>
        <div tabIndex={-1}>
          <Modal className="custom-dialog addStock" isOpen={show}
            size="lg"
            id="modal2"
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <ModalHeader charCode="Y" toggle={() => handleCloseMod()} cssModule={{ 'modal-title': 'w-100 text-center' }} >
              <h5 className="text-center mb-0">Stock Transaction</h5>
            </ModalHeader>
            <ModalBody >
              <Container fluid>
                <form>
                  {loading ? (
                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                  ) : null}
                  <fieldset>
                    <Row>
                      <Col md="12" className="mb-2">
                        <div className="form-check form-check-inline">
                          {stockHeaderId !== 0 ? <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Listed' checked={show1}
                              // onClick={() => { setShow1(true); setShow2(false); setShow3(false); setShow4(false); setGrid2(false); setGrid3(false); setGrid4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]) }} 
                              disabled />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio1">Listed</Form.Label>
                          </> : <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Listed' checked={show1}
                              onClick={() => { setShow1(true); setShow2(false); setShow3(false); setShow4(false); setGrid2(false); setGrid3(false); setGrid4(false); setAllEntry2([]); setAllEntry3([]); setAllEntry4([]); setdata1([]); setdata2([]); setdata3([]); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); setBrokerValue([]); setValue(null); setMtooltip(false); setSellingCompanyList([]); handleCloseModalPopUp(); setSelectionQty([]); setSTT(0); setGST(0); setOtherCharges(0); setTotalAmountDue(0); }} />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio1">Listed</Form.Label> </>}
                        </div>
                        <div className="form-check form-check-inline">
                          {stockHeaderId !== 0 ? <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='Unlisted' checked={show2}
                              // onClick={() => { setShow1(false); setShow2(true); setShow3(false); setShow4(false); setGrid1(false); setGrid3(false); setGrid4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]) }} 
                              disabled />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio2">Unlisted</Form.Label>
                          </> : <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='Unlisted' checked={show2}
                              onClick={() => { setShow1(false); setShow2(true); setShow3(false); setShow4(false); setGrid1(false); setGrid3(false); setGrid4(false); setAllEntry1([]); setAllEntry3([]); setAllEntry4([]); setdata([]); setdata2([]); setdata3([]); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); setMtooltip(false); setSellingCompanyList([]); setUnlistedCompanyList([]); handleCloseModalPopUp(); setSelectionQty([]); setSTT(0); setGST(0); setOtherCharges(0); setTotalAmountDue(0); if (brokerOptions.length !== 0) { setBrokerValue(brokerOptions[0]); setValue(brokerOptions[0].value) } }} />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio2">Unlisted</Form.Label> </>}
                        </div>
                        <div className="form-check form-check-inline">
                          {stockHeaderId !== 0 ? <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value='Listed Preference' checked={show3}
                              // onClick={() => { setShow1(false); setShow2(false); setShow3(true); setShow4(false); setGrid1(false); setGrid2(false); setGrid4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]) }} 
                              disabled />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio3">Listed Preference</Form.Label>
                          </> : <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value='Listed Preference' checked={show3}
                              onClick={() => { setShow1(false); setShow2(false); setShow3(true); setShow4(false); setGrid1(false); setGrid2(false); setGrid4(false); setAllEntry2([]); setAllEntry1([]); setAllEntry4([]); setdata1([]); setdata([]); setdata3([]); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); setBrokerValue([]); setValue(null); setMtooltip(false); setSellingCompanyList([]); handleCloseModalPopUp(); setSelectionQty([]); setSTT(0); setGST(0); setOtherCharges(0); setTotalAmountDue(0); }} />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio3">Listed Preference</Form.Label> </>}
                        </div>
                        <div className="form-check form-check-inline">
                          {stockHeaderId !== 0 ? <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value='Unlisted Preference' checked={show4}
                              // onClick={() => { setShow1(false); setShow2(false); setShow3(false); setShow4(true); setGrid1(false); setGrid2(false); setGrid3(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]) }} 
                              disabled />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio3">Unlisted Preference</Form.Label>
                          </> : <>
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value='Unlisted Preference' checked={show4}
                              onClick={() => { setShow1(false); setShow2(false); setShow3(false); setShow4(true); setGrid1(false); setGrid2(false); setGrid3(false); setAllEntry2([]); setAllEntry3([]); setAllEntry1([]); setdata1([]); setdata2([]); setdata([]); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); setMtooltip(false); setSellingCompanyList([]); setUnlistedCompanyList([]); handleCloseModalPopUp(); setSelectionQty([]); setSTT(0); setGST(0); setOtherCharges(0); setTotalAmountDue(0); if (brokerOptions.length !== 0) { setBrokerValue(brokerOptions[0]); setValue(brokerOptions[0].value) } }} />
                            <Form.Label className="form-check-label" htmlFor="inlineRadio4">Unlisted Preference</Form.Label> </>}
                        </div>
                        <div className="form-check form-check-inline">
                          {stockHeaderId !== 0 ? null : <>
                            <Button onClick={handleaddbrokershow}>ADD Broker</Button>
                          </>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {
                        show1 || show2 || show3 || show4 ?
                          <Col md="4" className="mb-2">
                            <><Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                              <DatePicker
                                className="form-control"
                                selected={date}
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => setDate(date)}
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                                disabled={headerId !== 0 ? true : false}
                              />
                            </>
                          </Col>
                          : null
                      }
                      {
                        show1 || show3 || show4 ?
                          <Col md="4" className="mb-2">
                            <>
                              <Form.Label>Type</Form.Label>
                              <Dropdown
                                value={typeValue}
                                name='Type'
                                options={stockType}
                                onChange={HandleChangeType}
                                className="SelectClass"
                                searchable={true}
                                clearable={true}
                                noResultsText={"No result"}
                                //  onInputChange={HandleInput}
                                placeholder="Select broker.."
                                // isDisabled={headerId != 0 ? true : false}
                                isDisabled
                              />
                            </>
                          </Col>
                          : null
                      }
                      {
                        <Col md="4" className="mb-2">
                          <>
                            {show1 || show3 ?
                              <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label> :
                              <Form.Label>Broker</Form.Label>
                            }

                            <Dropdown
                              value={brokerValue}
                              name='Broker'
                              options={show1 || show3 ? brokerOptionsListed : brokerOptions}
                              onChange={HandleChange}
                              className="SelectClass"
                              searchable={true}
                              clearable={true}
                              noResultsText={"No result"}
                              // onInputChange={HandleInput}
                              placeholder="Select broker.."
                              isDisabled={headerId !== 0 ? true : show2 || show4 ? true : false}
                            />
                          </>
                          {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.broker}</div> : null}
                        </Col>
                      }
                    </Row>
                  </fieldset>
                </form>
                <fieldset>
                  {show1 === true ?
                    <Row>
                      <Col md="12" className="BuyTbl">
                        <MaterialTable
                          title="Buy"
                          data={allEntry1}
                          columns={columnsB1}
                          tableRef={tableRef1}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false,
                            tableLayout: 'auto'
                          }}
                          //   actions={actions}
                          // components={{
                          //   Toolbar: props => (
                          //     <MTableToolbar
                          //       {...props}
                          //       actions={props.actions.filter(a => a.tooltip === "Add")}
                          //     />
                          //   )
                          // }}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            },
                            onRowUpdateCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            }
                          }}
                        />
                      </Col>
                      {/* <div> */}
                      {iserror &&
                        <Alert severity="error">
                          <AlertTitle>ERROR</AlertTitle>
                          {errorMessages.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                          })}
                        </Alert>
                      }
                      {/* </div> */}
                    </Row>
                    : null
                  }
                  {show2 === true ?
                    <Row>
                      <Col md="12" className="BuyTbl">
                        <MaterialTable
                          title="Buy"
                          data={allEntry2}
                          columns={columnsB2}
                          tableRef={tableRef2}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                          //  actions={actions}
                          components={{
                            Toolbar: props => (
                              <MTableToolbar
                                {...props}
                                actions={props.actions.filter(a => a.tooltip === "Add")}
                              />
                            )
                          }}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            },
                            onRowUpdateCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            }
                          }}
                        />
                      </Col>
                      <div>
                        {iserror &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessages.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row> : null
                  }
                  {show3 === true ?
                    <Row>
                      <Col md="12" className="BuyTbl">
                        <MaterialTable
                          title="Buy"
                          data={allEntry3}
                          columns={columnsB3}
                          tableRef={tableRef3}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                          //  actions={actions}
                          components={{
                            Toolbar: props => (
                              <MTableToolbar
                                {...props}
                                actions={props.actions.filter(a => a.tooltip === "Add")}
                              />
                            )
                          }}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            },
                            onRowUpdateCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            }
                          }}
                        />
                      </Col>
                      <div>
                        {iserror &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessages.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row> : null
                  }
                  {show4 === true ?
                    <Row>
                      <Col md="12" className="BuyTbl">
                        <MaterialTable
                          title="Buy"
                          data={allEntry4}
                          columns={columnsB4}
                          tableRef={tableRef4}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                          //   actions={actions}
                          components={{
                            // Cell: (props) => (
                            //   <Tooltip title={props.value ? props.value : ''}>
                            //     <MTableCell {...props} />
                            //   </Tooltip>
                            // ),
                            Toolbar: props => (
                              <MTableToolbar
                                {...props}
                                actions={props.actions.filter(a => a.tooltip === "Add")}
                              />
                            )
                          }}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            },
                            onRowUpdateCancelled: rowData => {
                              setIserror(false)
                              setErrorMessages([])
                            }
                          }}
                        />
                      </Col>
                      <div>
                        {iserror &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessages.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row> : null
                  }
                  {show1 === true ?
                    <Row>
                      <Col md="12" className="SellTbl mt-3">
                        <MaterialTable
                          title="Sell"
                          data={data}
                          columns={columnsS1}
                          //  actions={actionsSell}
                          // actions={[
                          //   {
                          //      icon: "add",
                          //      tooltip: "Add User",
                          //      hidden: true,
                          //      isFreeAction: true,
                          //      handleRowAddSell: (event, rowData) => {
                          //       // Perform add operation
                          //      }
                          //   }]}
                          tableRef={tableRefSell1}
                          editable={{
                            // isEditable: rowData => false,
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdateSell(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAddSell(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDeleteSell(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            },
                            onRowUpdateCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            }
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                        // components={{
                        //   Cell: (props) => (
                        //     <Tooltip title={props.value ? props.value : ''}>
                        //       <MTableCell {...props} />
                        //     </Tooltip>
                        //   )
                        // }}
                        />
                      </Col>
                      <div>
                        {isError &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessagesSell.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row>
                    : null}
                  {show3 === true ?
                    <Row>
                      <Col md="12" className="SellTbl mt-3">
                        <MaterialTable
                          title="Sell"
                          data={data2}
                          columns={columnsS1}
                          //actions={actionsSell}
                          tableRef={tableRefSell3}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdateSell(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAddSell(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDeleteSell(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            },
                            onRowUpdateCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            }
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false
                          }}
                        />
                      </Col>
                      <div>
                        {isError &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessagesSell.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row>
                    : null}
                  {show4 === true ?
                    <Row>
                      <Col md="12" className="SellTbl unlisted mt-3">
                        <MaterialTable
                          title="Sell"
                          data={data3}
                          columns={columnsS2}
                          //  actions={actionsSell}
                          tableRef={tableRefSell4}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdateSell(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAddSell(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDeleteSell(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            },
                            onRowUpdateCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            }
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false,
                            tableLayout: 'auto'
                          }}
                        />
                      </Col>
                      {isError &&
                        <Alert severity="error">
                          <AlertTitle>ERROR</AlertTitle>
                          {errorMessagesSell.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                          })}
                        </Alert>
                      }
                    </Row>
                    : null}
                  {show2 === true ?
                    <Row>
                      <Col md="12" className="SellTbl unlisted mt-3">
                        <MaterialTable
                          title="Sell"
                          data={data1}
                          columns={columnsS2}
                          // actions={actionsSell}
                          tableRef={tableRefSell2}
                          editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowUpdateSell(newData, oldData, resolve, reject);
                              }),
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                handleRowAddSell(newData, resolve, reject)
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                handleRowDeleteSell(oldData, resolve, reject)
                              }),
                            onRowAddCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            },
                            onRowUpdateCancelled: rowData => {
                              setErrorMessagesSell([])
                              setIsError(false);
                            }
                          }}
                          options={{
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                            search: false,
                            paging: false,
                            tableLayout: 'auto'
                          }}
                        />
                      </Col>
                      <div>
                        {isError &&
                          <Alert severity="error">
                            <AlertTitle>ERROR</AlertTitle>
                            {errorMessagesSell.map((msg, i) => {
                              return <div key={i}>{msg}</div>
                            })}
                          </Alert>
                        }
                      </div>
                    </Row>
                    : null}
                </fieldset>
                <filedset>
                  <Row>
                    {
                      show1 || show3 || show4 ?
                        <Col md="1">
                          <Form.Label>STT </Form.Label>
                          {/* <Form.Control type="Number" onChange={(e) => handleStt(e.target.value)} autoComplete="off"
                            name="stt" id="stt" value={stt} /> */}
                          <Cleave
                            className='form-control'
                            autoComplete='off'
                            id="stt"
                            name="stt"
                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={stt}
                            onChange={(e) => handleStt(e.target.rawValue)} />
                        </Col>
                        : null
                    }
                    {
                      show2 ?
                        <Col md="1">
                          <Form.Label>Stamp Duty</Form.Label>
                          <Cleave
                            className='form-control'
                            id="stampDuty"
                            autoComplete='off'
                            name="stampDuty"
                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={stampDuty == null ? 0 : stampDuty}
                            onChange={(e) => handleStampDuty(e.target.rawValue)} />
                          {/* <Form.Control type="text" onChange={(e) => handleStampDuty(e.target.value)} autoComplete="off"
                            name="stampDuty" id="stampDuty" value={stampDuty == null ? 0 : stampDuty} /> */}
                        </Col>
                        : null}
                    {
                      show1 || show2 || show3 || show4 ?
                        <>
                          <Col md="1">
                            <Form.Label>GST</Form.Label>
                            <Cleave
                              className='form-control'
                              id="gst"
                              autoComplete='off'
                              name="gst"
                              options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                              value={gst == null ? 0 : gst}
                              onChange={(e) => handleGst(e.target.rawValue)} />
                            {/* <Form.Control type="Number" onChange={(e) => handleGst(e.target.value)} autoComplete="off"
                              name="gst" id="gst" value={gst == null ? 0 : gst} /> */}
                          </Col>
                          <Col md="2">
                            <Form.Label>Other charges</Form.Label>
                            <Cleave
                              className='form-control'
                              id="otherCharges"
                              autoComplete='off'
                              name="otherCharges"

                              options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                              value={otherCharges == null ? 0 : otherCharges}
                              onChange={(e) => handleOtherCharges(e.target.rawValue)} />
                            {/* <Form.Control type="Number" min="0" onChange={(e) => handleOtherCharges(e.target.value)} autoComplete="off"
                              name="otherCharges" id="otherCharges" value={otherCharges == null ? 0 : otherCharges} /> */}
                          </Col>
                        </> : null
                    }
                    <Col md="3">
                      <Form.Label>{show4 ? "Total Amount " : "Total Amount Due to / Due from Broker"} </Form.Label>
                      {/* <Cleave
                        className='form-control'
                        id="stampDuty"
                        autoComplete='off'
                        name="stampDuty"
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={totalAmountDue == null ? 0 : totalAmountDue}
                        onChange={(e) => handleOtherCharges(e.target.rawValue)}
                        disabled /> */}
                      <Form.Control type="text" autoComplete="off"
                        name="stampDuty" id="stampDuty" disabled value={totalAmountDue == null ? 0 : NumberSeperator(totalAmountDue.toFixed(2))} />
                    </Col>
                    {
                      show1 || show2 || show3 || show4 ?
                        <Col md="5">
                          <Form.Label>Narration if any</Form.Label>
                          <Form.Control type="text" autoComplete="off"
                            name="narration" id="narration" value={narration} onChange={(e) => setNarration(e.target.value)} />
                        </Col>
                        : null
                    }
                  </Row>
                </filedset>
                <Row>
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.Transactionrequired}
                    </div>
                  ) : null}
                  <Col md="12" className="text-center mt-3">
                    {headerId !== 0 ?
                      <>
                        <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { UpdateStockStransaction() }}>Update</Button>
                        <Button variant="danger" className="mx-2 w100" onClick={() => { DeleteStockStransaction() }}>Delete</Button>
                      </>
                      // : show3 || show4 ? <Button variant="primary" className="mx-2 w100" onClick={() => { handleSubmitListedPre() }}>Saved</Button> :
                      : <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { AddStockStransaction() }}>Save</Button>}
                    <Button variant="secondary" className="mx-2 w100" onClick={() => { handleCancel() }}>Cancel</Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
        <div tabIndex={4}>
          <Modal
            isOpen={selection}
            backdrop="static"
            size="lg"
            className="custom-dialog selection"
          >
            <ModalHeader charCode="Y" toggle={selectionHide} cssModule={{ 'modal-title': 'w-100 text-center' }}>
              <h5 className="text-center">{show1 ? "Select Available Listed Stock Quantity" : show2 ? "Select Available Unlisted Stock Quantity" : show3 ? "Select Available Listed Preference Stock Quantity" : show4 ? "Select Available Unlisted Preference Stock Quantity" : null}</h5>
            </ModalHeader>
            <ModalBody>
              <div style={{ height: 200, width: '100%' }}>
                <MaterialTable
                  title=" "
                  columns={[
                    {
                      title: 'Date', field: 'DatePurchase', editable: 'never', type: 'date', render: (rowData) =>
                      (
                        moment(rowData.DatePurchase).format('DD/MM/YYYY')
                      )
                    },
                    { title: 'Quantity', field: 'AvailableQuantity', type: 'numeric', editable: 'never' },
                    { title: 'Price', field: 'Price', type: 'numeric', editable: 'never' },
                    { title: 'Sell Quantity', field: 'sellQuantity', width: 200, maxWidth: 200, type: 'numeric' },
                  ]}
                  data={selectionQty}
                  options={{
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                    search: false,
                    paging: false,
                    selection: true,
                    showTextRowsSelected: false,
                  }}
                  // onSelectionChange={(rows, d) => setAllData(rows.length, d)}
                  cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {

                      return new Promise((resolve, reject) => {
                        let errorsList = [];
                        let updateSelection = [...selectionQty];
                        const index = rowData.tableData.id;
                        if (rowData.AvailableQuantity < Number(newValue)) {
                          errorsList.push("Quantity exceed then avilable quantity !");
                        }
                        if (errorsList.length < 1) {
                          updateSelection[index].sellQuantity = Number(newValue);
                          setSelectionQty([...updateSelection])
                          setErrorMessagesQntity([])
                          setTimeout(resolve, 1000);
                        }
                        else {
                          setErrorMessagesQntity(errorsList);
                          setTimeout(reject, 1000);
                        }
                      });
                    }
                  }}
                />
                <div>
                  {errorMessagesQntity.length > 0 &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessagesQntity.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </div>

            </ModalBody>
          </Modal>
        </div>
        <div tabIndex={3}>
          <Modal isOpen={showUnlisted} backdrop="static" animation={false} size="sm" centered>
            <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
              <small>{show2 ? "Unlisted" : show3 ? "Listed Preference" : show4 ? "Unlisted Preference" : null} company</small>
            </ModalHeader>
            <ModalBody>
              <form >
                <div className="form-group mt-2 mb-2">
                  <label htmlFor="inputUserName">Company Name</label>
                  <input className="form-control mt-2" placeholder="Enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} />
                  {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <>
          <Modal isOpen={brokershow} backdrop="static" animation={false} size="sm" centered>
            <ModalHeader charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
              <h5>Add Broker</h5>
            </ModalHeader>
            <ModalBody>
              <form >
                <div className="form-group mt-2 mb-2">
                  <label htmlFor="inputUserName">Broker Name</label>
                  <input className="form-control mt-2" placeholder="Enter Broker name.." type="text" autoComplete="off" name="brokerName" onChange={handleaddbroker} value={addbrokers} />
                  {brokersCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{brokersCompErr}</div> : null}
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button variant="primary" className="mx-2" onClick={submitbroker}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          {/* <Modal show={showlistedPref} onHide={handleCloseModal} animation={true} size="lg" > */}
          <Modal isOpen={showlistedPref} backdrop="static" animation={true} size="lg" >
            <ModalHeader className="headerBlue" charCode="Y" toggle={() => handlecloseDescModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
              <h5>{show3 ? "Listed Preference" : "Unlisted Preference"}</h5>
            </ModalHeader>
            <ModalBody>
              <form >
                <Row>
                  {/* {show4 ?
                    <Col md="6" className="mb-2">
                      <>
                        <Form.Label>Certificate/Folio Number<span className="text-danger h6">*</span></Form.Label>
                        <Form.Control type="text"
                          onChange={(e) => {
                            setCertificateorfolinumber(e.target.value);
                            setFormErr({ ...formErr, certificateorfolinumber: '' })
                          }} autoComplete="off"
                          name="certificateorfolinumber" id="certificateorfolinumber" value={certificateorfolinumber} style={{ textTransform: "uppercase" }} />
                      </>
                      {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.certificateorfolinumber}</div> : null}
                    </Col>
                    : null} */}
                  <Col md="6" className="mb-2">
                    <>
                      <Form.Label className="d-block">Lock in Period</Form.Label>
                      <Form.Control type="text"
                        onChange={(e) => setLockinperiod(e.target.value)} autoComplete="off"
                        name="lockinperiod" id="lockinperiod" value={lockinperiod} className="lockinperiodno" />
                      <Dropdown
                        value={time}
                        name='period'
                        options={periodList}
                        onChange={HandleChangeTime}
                        className="SelectClass lockinperiodlist"
                        searchable={true}
                        clearable={true}
                        noResultsText={"No result"}
                      // onInputChange={HandleInput}
                      />
                      {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                    </>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mb-2">
                    <Form.Label>Redeemable/Convertible</Form.Label>
                    <Dropdown
                      value={redeemableorconvertible}
                      name='RedeemableOrConvertible'
                      options={redeemableOrConvertibleList}
                      onChange={handleChangeConvert}
                      className="SelectClass"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                      isDisabled={headerId !== 0 ? true : false}
                    // onInputChange={HandleInput}
                    />

                  </Col>
                  <Col md="6" className="mb-2">
                    <Form.Label>Cumulative/Non Cumulative</Form.Label>
                    <Dropdown
                      value={cumulativeornoncumulative}
                      name='CumulativeOrNoncumulative'
                      options={cumulativeNonCumulativeList}
                      onChange={handleChangeCumulative}
                      className="SelectClass"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                    // onInputChange={HandleInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mb-2">
                    <Form.Label>Dividend %<span className="text-danger h6">*</span></Form.Label>
                    <Form.Control type="text"
                      onChange={(e) => {
                        setDividend(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)
                        setFormErr({ ...formErr, dividend: "" })
                      }} autoComplete="off"
                      name="dividend" id="dividend" value={dividend} />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.dividend}</div> : null}

                  </Col>
                  <Col md="6" className="mb-2">
                    <Form.Label>Date of Maturity/Conversion </Form.Label>
                    <DatePicker className="form-control"
                      selected={dateofmaturityorconversion}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => setDateofmaturityorconversion(date)}
                      minDate={date}
                      // disabled={headerId != 0 ? true : false}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50} />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.mdate}</div> : null}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mb-2">
                    <Form.Label>Dividend Frequency</Form.Label>
                    <Dropdown
                      value={frequencyValue}
                      name='dividendFrequency'
                      options={dividendfrequency}
                      onChange={handleChangeFrequency}
                      className="SelectClass"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                    />
                    {/* <input type="text"
                        onChange={(e) => setDividendfrequency(e.target.value)} autoComplete="off"
                        name="dividendfrequency" id="dividendfrequency" value={dividendfrequency} /> */}
                  </Col>
                  <Col md="6" className="mb-2">
                    <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                    {/* <Form.Control type="text"
                    onChange={(e) => {
                      setMaturityvalue(e.target.value);
                      setFormErr({ ...formErr, maturityvalue: '' })
                    }} autoComplete="off"
                    name="maturityvalue" id="maturityvalue" value={maturityvalue} /> */}
                    {
                      cumulativeornoncumulative.value === "Non Cumulative" ? <>
                        <Form.Control type="text"
                          onChange={() => {
                            // setCost(cost)
                            setFormErr({ ...formErr, cost: '' })
                          }} autoComplete="off"
                          name="maturityvalue" id="maturityvalue" value={NumberSeperator(cost)} disabled />
                        {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.costValue}</div> : null}
                      </>
                        :
                        <>
                          <Cleave
                            className='form-control'
                            id="maturityvalue"
                            name="maturityvalue"
                            placeholder='Enter Maturity'
                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={maturityvalue}
                            onChange={(e) => {
                              setMaturityvalue((e.target.rawValue));
                              setFormErr({ ...formErr, maturityvalue: '' })
                            }} autoComplete="off" />
                          {/* <Form.Control type="text"
                            onChange={(e) => {
                              setMaturityvalue(commarize(e.target.value));
                              setFormErr({ ...formErr, maturityvalue: '' })
                            }} autoComplete="off"
                            name="maturityvalue" id="maturityvalue" value={maturityvalue} /> */}
                          {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityValue}</div> : null}

                        </>
                    }
                  </Col>


                </Row>
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button variant="primary" className="mx-2 w100" onClick={submitPreModal} >
                Save
              </Button><Button variant="secondary" className="mx-2 w100" onClick={handleCloseModalPopUp} >
                Clear
              </Button>
            </ModalFooter>
          </Modal>
        </>
        {showSdetailspreview === true ?
          <div tabIndex={3}>
            <Modal backdrop="static" isOpen={showSdetailspreview} >
              <ModalHeader charCode="Y" toggle={() => onHide(1)} cssModule={{ 'modal-title': 'w-100 text-center' }} className="headerBlue" >
                <h5>  {trastype} {""} details
                </h5>
              </ModalHeader>
              <ModalBody>

                <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                  {
                    trastype !== "Sell" ?
                      <>
                        <div className="transactionData"><div className="transactionDataTitle">Date : </div>
                          <div className="transactionDataValue">{moment(datepre).format("DD/MM/YYYY")}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                          <div className="transactionDataValue">{brokerpre}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Company Name : </div>
                          <div className="transactionDataValue">{companyvalue}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Quantity : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(qty)}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Price/Share : </div>
                          <div className="transactionDataValue">{pricePerShare ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(pricePerShare) : 0}</div></div>
                        {previewTransaction.instrumentTypeId !== 10 ?
                          <>
                            <div className="transactionData odd"><div className="transactionDataTitle">Brokerage/Share : </div>
                              <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(brokerpershare)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Total Brokerage : </div>
                              <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(totalBroker)}</div></div>
                          </> : null}
                        <div className="transactionData odd"><div className="transactionDataTitle">Cost : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost)}</div></div>

                        {previewTransaction.instrumentTypeId !== 1 && previewTransaction.instrumentTypeId !== 10 && previewTransaction.instrumentTypeId !== 20 && previewTransaction.instrumentTypeId !== 9 && previewTransaction.instrumentTypeId === 11 ?
                          <>
                            <div className="transactionData"><div className="transactionDataTitle">Current Market Price : </div>
                              <div className="transactionDataValue">{currentMP == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMP)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Current Market Value : </div>
                              <div className="transactionDataValue">{currentMV == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentMV)}</div></div>
                          </> : null}
                        {previewTransaction.instrumentTypeId !== 10 && previewTransaction.instrumentTypeId !== 20 ?
                          <>
                            <div className="transactionData"><div className="transactionDataTitle">Target : </div>
                              <div className="transactionDataValue">{target == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(target)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Stop Loss : </div>
                              <div className="transactionDataValue">{stopLoss == null ? "-" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(stopLoss)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Est Hldg Period : </div>
                              <div className="transactionDataValue">{estHldP == null ? "-" : estHldP}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Recommended By : </div>
                              <div className="transactionDataValue">{recommendedBy == null ? "-" : recommendedBy}</div></div> </> : null}
                      </> :
                      <>
                        <div className="transactionData"><div className="transactionDataTitle">Date : </div>
                          <div className="transactionDataValue">{moment(datepre).format("DD/MM/YYYY")}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                          <div className="transactionDataValue">{brokerpre}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Company Name : </div>
                          <div className="transactionDataValue">{companyvalue}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Quantity : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(qty)}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Price/Share : </div>
                          <div className="transactionDataValue">{pricePerShare ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(pricePerShare) : 0}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Brokerage/Share : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(brokerpershare)}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Total Brokerage : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(totalBroker)}</div></div>
                        <div className="transactionData odd"><div className="transactionDataTitle">Total Cost : </div>
                          <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(trastype === "Sell" || trastype === "BuyBack" ? saleValue : totalCost)}</div></div>
                        <div className="transactionData"><div className="transactionDataTitle">Stock Valuation Type : </div>
                          <div className="transactionDataValue">{stocksSeletion}</div></div>
                      </>
                  }
                  <div className="transactionData "><div className="transactionDataTitle">Sale Value: </div>
                    <div className="transactionDataValue">{trastype === "Buy" ? "0.00" : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost)}</div></div>

                  <div className="transactionData odd"><div className="transactionDataTitle">Profit/Loss : </div>
                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(profitLoss)}</div></div>
                  {
                    previewTransaction.instrumentTypeId !== 10 && previewTransaction.instrumentTypeId !== 1 && previewTransaction.instrumentTypeId !== 9 ?
                      <>
                        {/* {
                          previewTransaction.instrumentTypeId != 1 && trastype !== "Sell" && previewTransaction.instrumentTypeId === 11 ?
                            <div className="transactionData"><div className="transactionDataTitle">Certificate/Folio Number : </div>
                              <div className="transactionDataValue">{previewTransaction.folioNumber ? previewTransaction.folioNumber : "-"}</div></div>
                            : null
                        } */}
                        {trastype !== "Sell" && previewTransaction.instrumentTypeId !== 9 ? <>
                          <div className="transactionData odd"><div className="transactionDataTitle">Lock In Period : </div>
                            <div className="transactionDataValue">{lockperiodpre ? `${time1 ? time1 : '-'}  ${period1 ? period1 : ""}` : 0}</div></div>
                          <div className="transactionData"><div className="transactionDataTitle">Redeemable/Convertible : </div>
                            <div className="transactionDataValue">{previewTransaction.redeemConvertible ? previewTransaction.redeemConvertible : "-"}</div></div>
                          <div className="transactionData odd"><div className="transactionDataTitle">Cumulative/Non Cumulative : </div>
                            <div className="transactionDataValue">{previewTransaction.cumulativeNonCumulative ? previewTransaction.cumulativeNonCumulative : "-"}</div></div>
                          <div className="transactionData"><div className="transactionDataTitle">Dividend % : </div>
                            <div className="transactionDataValue">{previewTransaction.dividendPerc ? previewTransaction.dividendPerc : 0}</div></div>
                          <div className="transactionData odd"><div className="transactionDataTitle">Date of Maturity/Conversion : </div>
                            <div className="transactionDataValue">{previewTransaction.domConversion === null ? "-" : moment(previewTransaction.domConversion).format("DD/MM/YYYY")}</div></div>
                          <div className="transactionData"><div className="transactionDataTitle">Dividend Frequency : </div>
                            <div className="transactionDataValue">{previewTransaction.frequency.frequencyName ? previewTransaction.frequency.frequencyName : "-"}</div></div>
                          <div className="transactionData odd"><div className="transactionDataTitle">Maturity Value : </div>
                            <div className="transactionDataValue">{previewTransaction.cumulativeNonCumulative !== "Cumulative" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(previewTransaction.totalCost) : previewTransaction.maturityValue ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(previewTransaction.maturityValue) : "-"}</div></div></> : null}


                      </> :
                      <>
                        {previewTransaction.instrumentTypeId !== 1 && previewTransaction.instrumentTypeId !== 9 ?
                          <>
                            {/* <div className="transactionData"><div className="transactionDataTitle">Folio Number : </div>
                              <div className="transactionDataValue">{folioNumber == null ? "-" : folioNumber}</div></div> */}
                            <div className="transactionData odd"><div className="transactionDataTitle">Lock In Period : </div>
                              <div className="transactionDataValue">{lockperiodpre ? `${time1 ? time1 : '-'}  ${period1 ? period1 : ""}` : 0}</div></div></> : null}
                      </>
                  }
                </div>

              </ModalBody>
            </Modal>
          </div> : null}
      </div>
    </>
  );
}
export default StocksDetail;  