import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from "moment";

function InsurancePreview(props) {
    const { showInsPrev, onHideInsPrev, insurancePrvTransaction } = props

    let d = insurancePrvTransaction;
    let value = d.lockinPeriod;
    let splitData = value ? value.split(/([0-9]+)/) : null;
    let time = splitData ? splitData[1] : "-";
    let period = splitData ? splitData[2] : "";
    let value1 = d.tenure;
    let splitData1 = value1 ? value1.split(/([0-9]+)/) : null;
    let time1 = splitData1 ? splitData1[1] : "-";
    let period1 = splitData1 ? splitData1[2] : "";

    return (
        <div tabIndex={3}>
            <Modal isOpen={showInsPrev} toggle={onHideInsPrev} backdrop="static">
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => onHideInsPrev(1)} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Insurance Preview</h5>
                </ModalHeader>
                <ModalBody>
                    {showInsPrev === true ?
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData odd"><div className="transactionDataTitle">Insurance Company : </div>
                                <div className="transactionDataValue">{d.securityName === null ? '-' : d.company.companyName}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Type of Policy: </div>
                                <div className="transactionDataValue">{d.policyType.policyTypeName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Sub Type of Policy : </div>
                                <div className="transactionDataValue">{d.policySubtype.policyTypeName}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Scheme : </div>
                                <div className="transactionDataValue">{d.lender === null ? '-' : d.scheme}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Policy Number : </div>
                                <div className="transactionDataValue">{d.policyNumber}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Name of the Insured: </div>
                                <div className="transactionDataValue">{d.nameOfInsured}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Sum Assured: </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.sumAssured)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Premium Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.premiumAmount)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Premium Frequency: </div>
                                <div className="transactionDataValue">{d.frequency.frequencyName}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Tenure: </div>
                                <div className="transactionDataValue">{d.tenure === null ? '-' : `${time1 ? time1 : '-'}  ${period1 ? period1 : ""}`}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Start Date : </div>
                                <div className="transactionDataValue">{d.startDate === null ? '-' : moment(d.startDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Total Premium Paid: </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.totalPremiumPaid)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Bonus Accrued : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.bonusAccrued)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Surrender Value: </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.surrenderValue)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date of Last Premium: </div>
                                <div className="transactionDataValue">{d.lastPremiumDate === null ? '-' : moment(d.lastPremiumDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Maturity Date: </div>
                                <div className="transactionDataValue">{d.maturityDate === null ? '-' : moment(d.maturityDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Maturity Value : </div>
                                <div className="transactionDataValue">{d.maturityValue === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.maturityValue)}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Lock in Period : </div>
                                <div className="transactionDataValue">{d.lockinPeriod === null ? '-' : `${time ? time : '-'}  ${period ? period : ""}`}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Nominees : </div>
                                <div className="transactionDataValue">{d.nominees === null ? '-' : d.nominees}</div></div>
                            <div className="transactionData even"><div className="transactionDataTitle">Narration : </div>
                                <div className="transactionDataValue">{d.narration === null ? '-' : d.narration}</div></div>
                        </div>
                        : null}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default InsurancePreview