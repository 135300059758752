import React from 'react';
import PreferenceLivePrice from '../../components/CompanyLivePrice/ListedStocksLivePrice';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Stock () {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return (
        <div>
                {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <PreferenceLivePrice />
                </div>
            </div>
        </div>
    );
}

export default Stock;