import React from 'react';
import LivePriceLogin from '../../components/liveproject/LivePriceLogin';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const LivePriceProject = () => {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
            {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar />
            <div>
                <LivePriceLogin />
            </div>
        </div>
    </div>
  )
}

export default LivePriceProject
