import axios from '../../modules/helpers/axiosInstance';

// import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index';

let PropertyLiveUrl = "http://5.189.157.40:10020";
let propertyUrl = "http://5.189.157.40:10028/api";


const userID = Auth.getsubcriberId();

const outerGridPropertyFamily = () => {
    return axios({
        method: 'GET',
        url: `${propertyUrl}/PropertyManagement/FetchPropertyOuterGrid?s_id=${userID}`,
    });
}
const outerGridPropertyIndividual = (subId) => {
    return axios({
        method: 'GET',
        url: `${propertyUrl}/PropertyManagement/FetchPropertyEntityWiseOuterGrid?sub_id=${subId}`,
    });
}
const innerGridPropertyFamily = (subId,investID) => {
    return axios({
        method: 'GET',
        url: `${propertyUrl}/PropertyManagement/FetchPropertyEntityInfo?sub_id=${subId}&inv_id=${investID}`,
    });
}
const innerGridPropertyIndividual = (subId,investID,propAssetId) => {
    return axios({
        method: 'GET',
        url: `${propertyUrl}/PropertyManagement/FetchPropertyIndividualEntityInfo?sub_id=${subId}&inv_id=${investID}&property_asset_id=${propAssetId}`,
    });
}
const getPropertyTransaction = (propertyId) => {
    return axios({
        method: 'GET',
        url: `${propertyUrl}/PropertyManagement/GetPropertyDetailById?propertyid=${propertyId}`,
    });
}

const AddPropertyTransactions = (data) => {
    return axios({
        method: 'POST',
        url:`http://5.189.157.40:10028/api/PropertyManagement/BuyProperty`,
        data: data
    })
}
const AddPropertySell = async (body)=>{
    return axios({
        method:'POST',
        url:`${propertyUrl}/PropertyManagement/AddPropertySell`,
        data:body
    })
}
const updatePropertySell = async (body) => {
    return axios({
        method: 'PUT',
        url: `${propertyUrl}/PropertyManagement/UpdatePropertySell`,
        data: body
    })
}
const FetchAvialabeShares = async (PropertyAssetId, invstId)=>{
    return axios({
        method:'GET',
        url: `${PropertyLiveUrl}/PropertyManagement/FetchAvialabeShares/${PropertyAssetId}/${userID}/${invstId}`
    })
}
const GetProperyBuyTransaction = async (propertyId)=>{
    return axios({
        method:'GET',
        url:`http://5.189.157.40:10028/api/PropertyManagement/GetPropertyDetailById?propertyid=${propertyId}`
    })
}

const DeletePropertyBuyTrans = async (propertyId) => {
    return axios ({
        method: 'DELETE',
        url: `http://5.189.157.40:10028/api/PropertyManagement/DeletePropertyTransactions?propertyId=${propertyId}`
    })
}
const GetPropertyAsset = async ()=>{
    return axios({
        method:'GET',
        url:`${propertyUrl}/PropertyManagement/GetPropertyAsset`
    })
}
const PostPropertyAsset = async (data)=>{
    return axios({
        method:'POST',
        url:`${propertyUrl}/PropertyManagement/AddPropertyAsset`,
        data:data
    })
}
const UpdatePropertyBuyTransac = async (body) => {
    return axios({
        method: 'PUT',
        url: `${propertyUrl}/PropertyManagement/UpdateBuyPropery`,
        data: body
    })
}
export {
    AddPropertyTransactions,
    outerGridPropertyFamily,
    outerGridPropertyIndividual,
    innerGridPropertyFamily,
    innerGridPropertyIndividual,
    getPropertyTransaction,
    AddPropertySell,
    updatePropertySell,
    FetchAvialabeShares,
    GetProperyBuyTransaction,
    GetPropertyAsset,
    PostPropertyAsset,
    DeletePropertyBuyTrans,
    UpdatePropertyBuyTransac,
};