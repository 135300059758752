/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import { Modal } from 'react-bootstrap';
import Container from '@material-ui/core/Container';
import { DeleteMaturity } from "../FIncomeFolio/FDAPI";
import { DeleteInsuranceMaturity } from "../../pages/Insurance/InsuranceAPI";
import { DeletePropertyBuyTrans } from "../../pages/Property/PropertyAPI";
import { deleteArtTransaction } from "../../pages/Art/ArtAPI";
import { recentTransactionAPI } from "./RecentTranAPI/RT_API";
import Maturity from '../FIncomeFolio/other_transactions/Maturity'
import InsuranceMaturity from '../InsuranceFolio/other_transactions/InsuranceMaturity'
import Property_Sell from "../PropertyDetails/Property_Sell";
import Auth from "../../modules/Auth";
import swal from "sweetalert";
import Art_Sell from "../ArtDetails/ArtSell";
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import moment from "moment";
import { Pagination } from "@mui/material";
import Loader from "../loadingAnimation/index";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

const userID = Auth.getsubcriberId();


function TransactionsTable(props) {
  // console.log(props,"props");
  const filternewData = props.newEntryFilterData;
  const handleResetForm = props.handleReset;
  const loadingdata = props.loading;
  // const onestate = props.Onedata
  const [filtData, setfiltData] = useState([]);
  const [newEntryData, setNewEntryData] = useState([]);
  const [show, setShow] = useState(false);
  const [rowData, setSelectedRow] = useState({});
  const [Transaction, setTransaction] = useState({})
  const [fdId, setFdId] = useState();
  const [tableId, setTableId] = useState('');
  const [header_id, setHeader_id] = useState();
  const [buyfd_id, setBuyfd_id] = useState();
  const [showmaturity, setShowmaturity] = useState(false)
  const [showInsMaturity, setShowInsMaturity] = useState(false);
  const [showSellProp, setShowSellProp] = useState(false);
  const [propertyId, setPropertyId] = useState(0);
  const [showSellArt, setShowSellArt] = useState(false);
  const [artId, setArtId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonData, setButtonData] = useState("All")
  const navigate = useNavigate();

  // pagination states
  const [pageCount, setPageCount] = useState(1)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 10;

  useEffect(() => {
    if (props.submitCheck) {
      setPageNumber(1)
    }
  }, [props.submitCheck])
  useEffect(() => {
    const pageCount = Math.ceil(props.pageNo / dataPerPage)
    setPageCount(pageCount);
  }, [props.pageNo, props.searchData])

  const changePage = (e, a) => {
    setPageNumber(a);
    if (props.api === true) {
      props.searchData(e, a)
    } else {
      setLoader(false)
      recentTransactionDetails(a, dataPerPage)
    }
  }

  const handleClose = () => {
    setTableId('');
    setShow(false);
    setShowmaturity(false);
    setShowInsMaturity(false);
    setShowSellProp(false);
    setPropertyId(0);
    setShowSellArt(false);
    setArtId(0);
    onRefresh();
  }
  const onRefresh = () => {
    recentTransactionDetails(pageNumber, dataPerPage);
  }

  // console.log(handleResetForm, "call");
  const alertMyRow = (selectedRow, e, d) => {
    setFdId(e.tableId)
    setHeader_id(e.headerId)
    setBuyfd_id(e.availableQuantity)
    setTransaction(e)
    if (e.instrumentName === "Fixed Income" || e.instrumentName === "Fixed Income - FD" || e.instrumentName === "Fixed Income - RD" || e.instrumentName === "Fixed Income - PPF / Provident Fund" || e.instrumentName === "Fixed Income - Post Office" && e.transactStockType === "Matured" || e.transactStockType === "Pre-matured") {
      setShowmaturity(true)
    } else if (e.instrumentName === "Insurance" && e.transactStockType === "Matured") {
      setTableId(e.tableId)
      setShowInsMaturity(true);
    } else if (e.instrumentName === "Property" && e.transactStockType === "Sell") {
      setShowSellProp(true);
      setPropertyId(e.tableId)
    } else if (e.instrumentName === "Art" && e.transactStockType === "Sell") {
      setShowSellArt(true);
      setArtId(e.tableId)
    }
  }

  const deleteRow = (e, selectedRow) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((del) => {
      if (del) {
        if (selectedRow.instrumentName === "Fixed Income" && selectedRow.transactStockType === "Matured" || selectedRow.transactStockType === "Pre-matured") {
          setLoading(true)
          DeleteMaturity(selectedRow.tableId).then((res) => {
            if (res.data._response.status === 1) {
              swal("Maturity Deleted Successfully", "", "success");
              setLoading(false);
              onRefresh();
            }
            else {
              setLoading(false);
              onRefresh();
            }
          }).catch((err) => {
            setLoading(false);
            console.log(err, "error in delete maturity")
          })
        } else if (selectedRow.instrumentName === "Insurance" && selectedRow.transactStockType === "Matured") {
          setLoading(true)
          DeleteInsuranceMaturity(selectedRow.tableId).then((res) => {
            if (res.data._response.status === 1) {
              swal("Maturity Deleted Successfully", "", "success");
              setLoading(false);
              onRefresh();
            }
            else {
              setLoading(false);
              onRefresh();
            }
          }).catch((err) => {
            setLoading(false);
            console.log(err, "error in delete insurance maturity")
          })
        } else if (selectedRow.instrumentName === "Property" && selectedRow.transactStockType === "Sell") {
          setLoading(true);
          DeletePropertyBuyTrans(selectedRow.tableId).then((res) => {
            if (res.data._response.status === 1) {
              swal("Property Deleted Successfully", "", "success");
              setLoading(false);
              onRefresh();
            }
            else {
              setLoading(false);
              onRefresh();
            }
          }).catch((err) => {
            setLoading(false);
            console.log(err, "error in delete property sell")
          })
        }
        else if (selectedRow.instrumentName === "Art" && selectedRow.transactStockType === "Sell") {
          setLoading(true);
          deleteArtTransaction(selectedRow.tableId).then((res) => {
            setLoading(false);
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                swal("Art Deleted Successfully", "", "success");
                onRefresh();
              }
              else {
                setLoading(false);
                swal("Something went wrong", "", "info");
                onRefresh();
              }
            }
          }).catch((err) => {
            setLoading(false);
            console.log(err, "error in delete Art sell")
          })
        } else {
          swal("Delete Option not Available For this Transaction", "", "warning");
        }
      }
    })
  }

  useEffect(() => {
    setPageNumber(1)
    if (props.selMember == null && props.selAction == null && props.selInvestmentType == null && props.selInvestment == null && props.selbroker == null && props.startDate == null && props.endDate == null || props.api === false) {
      recentTransactionDetails(1, 10);
    }
  }, [userID, handleResetForm, props.selMember, props.selAction, props.selInvestmentType, props.selInvestment, props.selbroker, props.startDate, props.endDate, props.api]);

  const recentTransactionDetails = (pageNumber, dataPerPage) => {
    if (props.selMember == null || props.selAction == null || props.selInvestmentType == null || props.selInvestment == null || props.selbroker == null || props.startDate == null || props.endDate == null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    recentTransactionAPI(pageNumber, dataPerPage).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        if(res.data._response.status === 1){
          const newEntry = res.data._response.data.history
          let count = res.data._response.data.count;
          const pageCount = Math.ceil(count / dataPerPage)
          setPageCount(pageCount);
          setNewEntryData(newEntry);
          setButtonData("All");
        } else {
          console.log('error in recent transaction')
        }
      }
    })
      .catch((e) => {
        setLoading(false)
        setLoader(false)
        console.log(e, "error in Transaction");
      })
  }

  useEffect(() => {
    setNewEntryData(filternewData)
    // setPage(0);
  }, [props.newEntryFilterData])

  useEffect(() => {
    setLoading(loadingdata)
  }, [loadingdata])

  useMemo(() => {
    setfiltData(filternewData)
  }, [filternewData])

  const handleRedirect = (d) =>{
    if(d.instrumentId === 1){
      let s = { tableId: d.tableId, companyId: d.comapnyId, subAccountId: d.subAccountId, companyName: d.companyName, livePrice: d.livePrice }
      navigate("/Stock", { state: s})
    } else if (d.instrumentId === 4){
      let s = { subIdApi: d.subAccountId, companyId: d.comapnyId, instrumentTypeId: d.instrumentTypeId, companyName: d.companyName, subAccountName: d.subAccountName, livePrice: d.livePrice }
      navigate("/fixedincome", { state: s})
    } else if (d.instrumentId === 5){
      let s = { subAccountId: d.subAccountId, depositToId: d.netSalesProceeds, instrumentTypeId: d.instrumentTypeId, depositToName: d.companyName, entityName: d.subAccountName }
      navigate("/Loans", { state: s})
    } else if (d.instrumentId === 6){
      let s = { subAccountId: d.subAccountId, instrumentTypeId: d.instrumentTypeId, sec_detail_Id: d.netSalesProceeds, entityName: d.subAccountName, depositToName: d.companyName }
      navigate("/Borrowings", { state: s})
    } else if (d.instrumentId === 7){
      // local use d.companyId and for live check d.tableId
      let s = { subAccountId: d.subAccountId,policy_Type_Id: d.totalCost, policy_SubType_Id: d.netSalesProceeds, companyId: d.companyId
      }
      navigate("/Insurance", { state: s})
    } else if (d.instrumentId === 8){
      let s = { mutualFundCompanyId: d.comapnyId, instrumentTypeId: d.instrumentTypeId, subAccountId: d.subAccountId, company: d.companyName, transactionType: d.transactStockType, livePrice: d.livePrice }//d.instrumentTypeId === 43 ? "ULIP" : d.instrumentTypeId === 41 ? "SIP" : "LUMPSUM" }
      navigate("/MutualFund", { state: s})
    } else if (d.instrumentId === 9){
      let s = { subAccountId: d.subAccountId, typeOfInvestmentId: d.companyId }
      navigate("/Bullion", { state: s})
    } else if (d.instrumentId === 10){
      let s = { subAccountId: d.subAccountId, typeOfInvestmentId: d.companyName === "Residential" ? 6 : d.companyName === "Commercial" ? 7 : d.companyName === "Land" ? 8: 9, propertyAssetId:d.availableCost, depositToName: d.companyName, entityName: d.subAccountName }
      navigate("/Property", { state: s})
    } else if (d.instrumentId === 11){
      //local use d.companyId and for live check d.tableId
      let s = { subAccountId: d.subAccountId, titleId: d.companyId, entityName: d.subAccountName }
      navigate("/Art", { state: s})
    }
  }

  let TableData = buttonData === "All" ? newEntryData : (filtData ? handleResetForm === true ? newEntryData : filtData : newEntryData)
  return (
    <div style={{ maxWidth: "100%" ,marginBottom:"none" }}>
      {
        loading ?
          <Loader /> : null
      }
      <table class="table table-bordered">
        <thead className="headerblue2" style={{ textAlign: "right" , border:"1px solid black" }}>
          <tr style={{ textAlign: "center" }}>
            <td align="left"><th scope="col" style={{ width: 10,fontWeight : 500 }}>Family Member</th></td>
            <td align="left"><th scope="col" style={{ width: 10 ,fontWeight : 500 }}>Transaction Type</th></td>
            <td align="left"><th scope="col" style={{fontWeight : 500}}>Investment Type</th></td>
            <td align="left"><th scope="col" style={{fontWeight : 500}}>Investment Name</th></td>            
            <td align="right"><th scope="col" style={{fontWeight : 500}}>Transaction Date</th></td>
            <td align="right"><th scope="col" style={{fontWeight : 500}}>Quantity</th></td>     
            <td align="right"><th scope="col" style={{fontWeight : 500}}>Purchase Price</th></td>           
            <td align="right"><th scope="col" style={{fontWeight : 500}}>Sell Price</th></td>       
            <td align="left"><th scope="col" style={{fontWeight : 500}}>Broker</th></td>   
            {/* <td align="center"><th scope="col">Actions</th></td> */} 
          </tr>
        </thead>
        <tbody>
          {TableData
            .map((d) => {

              return (
                <>
                  <tr align="right" >
                    <td align="left" style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{d.subAccountName}</td>
                    <td align="left" style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}} >{
                      // d.instrumentName === "Mutual Fund" && d.instrumentTypeId === 41 ? <Link state={{ mutualFundCompanyId: d.mutualFundCompanyId || 5520, instrumentTypeId: d.instrumentTypeId, subAccountId: d.subAccountId }}className="instrumentLink" to="/MutualFund" > {d.transactStockType} - SIP</Link> :
                      // d.instrumentName === "Mutual Fund" && d.instrumentTypeId === 42 ? <Link state={{ showInnerGrid: true, tableId: d.tableId }}className="instrumentLink" to="/MutualFund" > {d.transactStockType} - LUMPSUM</Link> :
                      // d.instrumentName === "Mutual Fund" && d.instrumentTypeId === 43 ? <Link state={{ showInnerGrid: true, tableId: d.tableId }} className="instrumentLink" to="/MutualFund" > {d.transactStockType} - ULIP</Link> :
                      // <Link state={{ showInnerGrid: true, tableId: d.tableId }} className="instrumentLink" to="/Stock" > {d.transactStockType}</Link>
                      // <td className="custombtn">
                      d.instrumentTypeId === 41 ?
                      <button className="hyperlinkbtn" onClick={(e) => {e.preventDefault() ; handleRedirect(d)}} state={{ showInnerGrid: true, tableId: d.tableId }}> {d.transactStockType}-SIP</button> :
                      d.instrumentTypeId === 42 ?
                      <button className="hyperlinkbtn" onClick={(e) => {e.preventDefault() ; handleRedirect(d)}} state={{ showInnerGrid: true, tableId: d.tableId }}> {d.transactStockType}-LUMPSUM</button> :
                      d.instrumentTypeId === 43 ?
                      <button className="hyperlinkbtn" onClick={(e) => {e.preventDefault() ; handleRedirect(d)}} state={{ showInnerGrid: true, tableId: d.tableId }}> {d.transactStockType}-ULIP</button> :
                      ((d.instrumentName === "Property" || d.instrumentName === "Art") && d.transactStockType === "Sell") ||
                      (d.instrumentName === "Insurance" && d.transactStockType === "Matured") ||
                      (d.instrumentId === 4 && (d.instrumentTypeId === 29 || d.instrumentTypeId === 30 || d.instrumentTypeId === 31 || d.instrumentTypeId === 32) && d.transactStockType === "Matured" || d.transactStockType === "Pre-matured") ?
                      <td className="hp2"> {d.transactStockType}</td> :
                      <button className="hyperlinkbtn" onClick={(e) => {e.preventDefault() ; handleRedirect(d)}} state={{ showInnerGrid: true, tableId: d.tableId }}> {d.transactStockType}</button>
                      // </td>
                      
                    }</td>
                    <td align="left" style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 29 ? d.instrumentName + " - FD" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 30 ? d.instrumentName + " - RD" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 31 ? d.instrumentName + " - PPF / Provident Fund" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 32 ? d.instrumentName + " - Post Office" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 12 ? d.instrumentName + " - Listed Debenture" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 13 ? d.instrumentName + " - Listed Bond" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 16 ? d.instrumentName + " - Listed Debenture Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 17 ? d.instrumentName + " - Listed Debenture Non-Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 18 ? d.instrumentName + " - Listed Bond Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 19 ? d.instrumentName + " - Listed Bond Non-Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 21 ? d.instrumentName + " - Unlisted Debenture" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 22 ? d.instrumentName + " - Unlisted Bond" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 25 ? d.instrumentName + " - Unlisted Debenture Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 26 ? d.instrumentName + " - Unlisted Debenture Non-Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 27 ? d.instrumentName + " - Unlisted Bond Convertible" :
                      d.instrumentName === "Fixed Income" && d.instrumentTypeId === 28 ? d.instrumentName + " - UnListed Bond Non-Convertible" : d.instrumentName
                    }</td>
                    <td align="left" style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{d.companyName}</td>
                    <td style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{moment(d.date).format("DD/MM/YYYY")}</td>
                    <td style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>
                      {d.instrumentId === 4 && d.instrumentTypeId === 29 || d.instrumentTypeId === 30 || d.instrumentTypeId === 31 || d.instrumentTypeId === 32 ? "-" : d.instrumentId === 10 || d.instrumentId === 11 ? "-" : new Intl.NumberFormat('en-IN', d.instrumentName === "Mutual Fund" ? { minimumFractionDigits: 4 } : { minimumFractionDigits: 0 }).format(d.instrumentName === "Bullion" && d.transactStockType === "Buy" ? d.quantity : d.quantity)}
                    </td>
                    <td style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                      d.instrumentName === "Bullion" && d.transactStockType === "Sell" ? 0 :
                      d.instrumentName === "Bullion" && d.transactStockType === "Buy" ? d.totalCost :
                      d.instrumentId === 5 && d.transactStockType === "Buy" ? d.availableCost :
                      d.instrumentId === 11 && d.transactStockType === "Buy" ? d.totalCost :
                      d.instrumentName === "Debentures" && d.transactStockType === "Sell" ? 0 :
                      d.instrumentName === "Bullion" && d.transactStockType === "Buy" ? d.availableCost :
                      d.instrumentName === "Debentures" && d.transactStockType === "Buy" ? d.availableCost :
                      d.instrumentName === "Loans and Deposits" && d.transactStockType === "Interest" ? d.totalCost :
                      d.instrumentName === "Loans and Deposits" && d.transactStockType === "Installment" ? d.totalCost :
                      d.instrumentId === 4 && (d.instrumentTypeId === 12 || d.instrumentTypeId === 13 || d.instrumentTypeId === 16 || d.instrumentTypeId === 17 || d.instrumentTypeId === 18 || d.instrumentTypeId === 19 || d.instrumentTypeId === 21 || d.instrumentTypeId === 22 || d.instrumentTypeId === 25 || d.instrumentTypeId === 26 || d.instrumentTypeId === 27 || d.instrumentTypeId === 28) && d.transactStockType === "Sell" ? d.availableCost :
                      d.instrumentId === 4 && (d.instrumentTypeId === 29 || d.instrumentTypeId === 30 || d.instrumentTypeId === 31 || d.instrumentTypeId === 32) && d.transactStockType === "Buy" ? d.totalCost :
                      d.instrumentName === "Borrowings" && d.transactStockType === "Buy" ? d.totalCost :
                      d.instrumentName === "Mutual Fund" && (d.transactStockType === "Buy" || d.transactStockType === "SIP Fund") ? d.totalCost :
                      d.instrumentName === "Mutual Fund" && d.transactStockType === "Sell" ? d.availableCost :
                      d.pricePerShare)}</td>
                    <td style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                      d.instrumentName === "Property" && d.transactStockType === "Sell" ? d.netSalesProceeds :
                      d.instrumentId === 5 && d.transactStockType === "Repay" ? d.availableCost :
                      d.instrumentId === 11 && d.transactStockType === "Sell" ? d.netSalesProceeds :
                      d.instrumentName === "Mutual Fund" && d.transactStockType === "Sell" ? d.totalCost :
                      d.instrumentName === "Mutual Fund" && d.transactStockType === "SIP Fund" ? 0 :
                      d.instrumentName === "Debentures" && d.transactStockType === "Buy" ? 0 :
                      d.instrumentName === "Borrowings" && d.transactStockType === "Buy" ? 0 :
                      d.instrumentName === "Loans and Deposits" && (d.transactStockType === "Interest" || d.transactStockType === "Installment") ? 0 :
                      d.instrumentId === 4 && (d.instrumentTypeId === 12 || d.instrumentTypeId === 13 || d.instrumentTypeId === 16 || d.instrumentTypeId === 17 || d.instrumentTypeId === 18 || d.instrumentTypeId === 19 || d.instrumentTypeId === 21 || d.instrumentTypeId === 22 || d.instrumentTypeId === 25 || d.instrumentTypeId === 26 || d.instrumentTypeId === 27 || d.instrumentTypeId === 28) && d.transactStockType !== "Sell" ? 0 :
                      d.transactStockType === "Buy" || d.transactStockType !== "Matured" && (d.instrumentTypeId === 29 || d.instrumentTypeId === 30 || d.instrumentTypeId === 31 || d.instrumentTypeId === 32) ? 0 : d.totalCost)}</td>
                    <td align="left" style={{borderLeft: "1px solid #B2B2B2",borderRight: "1px solid #B2B2B2"}}>{d.brokerName}</td>
                    {/* <td align="center">
                      {
                        (d.instrumentId === 4 &&
                          (d.instrumentTypeId === 29 || d.instrumentTypeId === 30 || d.instrumentTypeId === 31 || d.instrumentTypeId === 32) &&
                          d.transactStockType === "Matured" || d.transactStockType === "Pre-matured") ||
                          (d.instrumentName === "Insurance" && d.transactStockType === "Matured") ||
                          (d.instrumentName === "Property" && d.transactStockType === "Sell") ||
                          (d.instrumentName === "Art" && d.transactStockType === "Sell") ? <>
                          <td><Tooltip title="edit"><IconButton><Edit fontSize="small" onClick={(e) => alertMyRow(e, d)} /></IconButton></Tooltip></td>
                          <td><Tooltip title="delete"><IconButton><Delete fontSize="small" onClick={(e) => deleteRow(e, d)} /></IconButton></Tooltip></td></> : null
                      }
                    </td> */}
                  </tr>
                </>
              )
            })}
        </tbody>
      </table>

      <div className="transaction-pagi" style={{ position: "relative", float: "right" }}>
        {/* {
        loader === false ?
        <Loader/> : null
      } */}
        <Pagination
          count={pageCount}
          page={pageNumber}
          onChange={changePage}
          variant="outlined" color="primary"
          className="transaction-pagi"
        />
      </div>

      <Maturity showMaturity={showmaturity} onHideMaturity={handleClose} Transactiondata={Transaction} Buyfdid={buyfd_id} UpdateheaderId={header_id} UpdateFDiD={fdId} Fname={"Update Maturity"} />
      <InsuranceMaturity showIMaturity={showInsMaturity} OnhideImaturity={handleClose} Fname={"Update Insurance Maturity"} updateTableId={tableId} />
      <Property_Sell
        show1={showSellProp}
        onHideSell={handleClose}
        recentPropHeaderId={propertyId}
      />
      <Art_Sell
        sellShow={showSellArt}
        onhideSell={handleClose}
        recentArtHeaderId={artId}
      />

      <Container className="LoginContainer formWraper" component="main" maxWidth="xs">
        <Modal show={show} onHide={() => handleClose()}>
          <Modal.Header closeButton className="text-center" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Transaction Detail</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CssBaseline />
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Family Member : </div><div className="transactionDataValue">{rowData.familyMember}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Action : </div><div className="transactionDataValue">{rowData.action}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Investment Type : </div><div className="transactionDataValue">{rowData.investmentType}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Investment Name : </div><div className="transactionDataValue">{rowData.investmentName}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Transaction Date : </div><div className="transactionDataValue">{rowData.transactionDate}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Quantity : </div><div className="transactionDataValue">{rowData.quantity}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Purchase or Sell Price : </div><div className="transactionDataValue">{rowData.purchaseSellPrice}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Purchase or Sell Value : </div><div className="transactionDataValue">{rowData.purchaseSellValue}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Broker : </div><div className="transactionDataValue">{rowData.broker}</div></div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

export default TransactionsTable;
