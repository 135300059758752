import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

function PropertyBuyPreview(props) {
    const { showPreview, onHidePreview, propertyTransactionPreview, } = props;
    let value = propertyTransactionPreview.lockInPeriod;
    let splitData = value ? value.split(/([0-9]+)/) : null;
    let time = splitData ? splitData[1] : "-";
    let period = splitData ? splitData[2] : "";

    return (
        <div>
            <Modal show={showPreview} onHide={onHidePreview} tabIndex={4}>
                <Modal.Header closeButton className="headerBlue">
                    <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }} ><h5>Property Preview</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showPreview === true ?
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData odd"><div className="transactionDataTitle">Type of Investment : </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.typeOfInvestment === null ? '-' : propertyTransactionPreview.typeOfInvestment.investmentName}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Asset Description : </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.propertyAsset === null ? '-' : propertyTransactionPreview.propertyAsset.propertyAssetName}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Location: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.location === null ? '-' : propertyTransactionPreview.location}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Date of Investment : </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.dateOfInvestment === null ? '-' : moment(propertyTransactionPreview.dateOfInvestment).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Area (Sq. Ft. / Mts. / Yards): </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.areaTypeUnit === null ? '-' : `${propertyTransactionPreview.areaTypeUnit}`}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Area (Carpet / Built up / Super built up / Saleable): </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.areaUnit === null ? '-' : `${propertyTransactionPreview.area} ${propertyTransactionPreview.areaUnit}`}
                                </div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Purchase Price(100%): </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.purchasePrice === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.purchasePrice)}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Brokerage: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.brokerage === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.brokerage)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Stamp Duty: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.stampDuty === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.stampDuty)}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">GST: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.gst === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.gst)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Transfer Charges: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.transferCharges === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.transferCharges)}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Other Charges: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.otherExpenses === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.otherExpenses)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Parking Charges: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.parkingCharges === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.parkingCharges)}</div>
                            </div>
                            <div className="transactionData "><div className="transactionDataTitle">Amount Invested: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.amountInvested === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.amountInvested)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Your Investment: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.amountInvestedPerShare === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.amountInvestedPerShare)}</div>
                            </div>
                            <div className="transactionData "><div className="transactionDataTitle">Current Market Price: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.currentMarketPricePerc === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.currentMarketPricePerc)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Current Market Price as per Share: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.currentMarketPricePerShare === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(propertyTransactionPreview.currentMarketPricePerShare)}</div>
                            </div>
                            <div className="transactionData "><div className="transactionDataTitle">Lock In Period: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.lockInPeriod === null ? '-' : `${time ? time : '-'}  ${period ? period : ""}`}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Narration: </div>
                                <div className="transactionDataValue">{propertyTransactionPreview.narration === null || propertyTransactionPreview.narration === "" ? '-' : propertyTransactionPreview.narration}</div>
                            </div>
                        </div> : null}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PropertyBuyPreview