/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import Loader from "../loadingAnimation/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Cleave from 'cleave.js/react';
import { AddIntInsRepay, UpdateIntInsRepay, GetIntInsRepay, DeleteIntInsRepay, } from '../../pages/Borrowings/BorrowingsAPI'

const BorrowingsInstallment = (props) => {
    const { showBorrowingsIn, hideBorrowingsIn, InvtDate, borrowingId, onRefreshBorrowings, sanamounttop } = props;
    const [date, setDate] = useState(null);
    const [installmentAmt, setInstallmentAmt] = useState(null);
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [idata, setiData] = useState([]);
    const [InstallmentId, setInstallmentId] = useState(null);
    const [update, setUpdate] = useState(false);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    useEffect(() => {
        if (showBorrowingsIn === true) {
            getInstallmentDataList();
        }
    }, [showBorrowingsIn])

    // validation
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (date === "" || date == null) {
            formErr["date"] = "Please fill date!";
            isValid = false;
        }
        if (installmentAmt === "" || installmentAmt == null) {
            formErr["Installment"] = "Please enter Installment amount!";
            isValid = false;
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    // topup validation
    useEffect(() => {
        if ((installmentAmt) > (sanamounttop)) {
            handleTopup()
        }
    }, [installmentAmt, sanamounttop])

    const checkDis = (val) => {

        let sumAmt = 0;
        if (idata) {
            idata && idata.filter((d) =>
                InstallmentId !== d.id
            ).map((index) => {
                sumAmt = sumAmt + index.totalAmount
            })
        }

        let a = Number(val)
        let c = sumAmt + a;
        let b = Number(sanamounttop)
        if (c > b) {
            alert(`Sanctioned Amount is ${sanamounttop}`);
            setInstallmentAmt('')
        }
    }
    const handleTopup = () => {
        let topup = (installmentAmt)
        let sumAmt = 0;
        if (idata) {
            idata && idata.map((index) => {
                sumAmt = sumAmt + index.totalAmount
            })
        }
        let c = sumAmt + topup
        if (c > (sanamounttop) && showBorrowingsIn === true) {
            alert(`Sanctioned Amount is  ${sanamounttop}`);
            setInstallmentAmt('')
        }

    }
    //onchange handler
    const handleDate = (selected) => {
        setDate(selected);
        setFormErr({ ...formErr, date: "" });
    }
    const handleInstallmentAmt = (e) => {
        const { rawValue ,value} = e.target;
        setInstallmentAmt(rawValue);
        checkDis(value);
        setFormErr({ installmentAmt: "" })
    }
    const handleCloseModal = () => {
        hideBorrowingsIn()
        handleReset()
        setUpdate(false)
    }
    const disableButton = () => {
        return date && installmentAmt
    }
    const handleReset = () => {
        setDate(null);
        setInstallmentAmt([]);
        setUpdate(false)
        setFormErr([])
    }
    const getInstallmentDataList = () => {
        setLoading(true)
        GetIntInsRepay(borrowingId, "Installment")
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.intInsRepayDetails
                        setiData(result);
                    }
                }
            }).catch((e) => {
                setLoading(false)
                console.log(e, "error in get Borrowings installment data");
            })
    }
    //handleSubmit
    const handleSubmit = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let BorrowingData = {
                transactStockType: "Installment",
                borrowingId: borrowingId,
                totalAmount: Number(installmentAmt ? installmentAmt : 0),
                saveDate: fixTimezoneOffset(date),
                // dateInvestment:fixTimezoneOffset(InvtDate)
            }
            // console.log(BorrowingData, `Borrowing submit`)
            AddIntInsRepay(BorrowingData).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        // handleCloseModal();
                        swal("Added successfully!", "", "success");
                        getInstallmentDataList();
                        handleReset();
                        // disableCheck(1);
                        onRefreshBorrowings();
                    } else {
                        swal(res.data._response.message, "", "info");
                    }
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error, ": error");
            })
        }
    }
    //handle single delete
    const deleterow = (selectedRow, a) => {
        setLoading(true)
        // console.log(`delete single row`);
        DeleteIntInsRepay(a.id).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    let del = idata.filter(i => i.id !== a.id);
                    setiData(del)

                    getInstallmentDataList();
                    swal("Delete successfully!", "", "success");
                } else {
                    swal("something went wrong", "", "info")
                }
            }
        }).catch((error) => {
            console.log(error, ": error in delete installment")
        })

    };
    //table functions
    const DeleteMultiple = (e) => {
        //setLoading(true)
        let a = JSON.stringify(selected)
        let interstpara = a.replace(/[\[\]']+/g, '');
        let ids = interstpara.replace(/,/g, '&&Id=');

        DeleteIntInsRepay(ids).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    getInstallmentDataList();
                    handleCloseModal();
                    swal("Delete successfully!", "", "success");
                    setSelected([])
                } else {
                    swal("something went wrong", "", "info");
                }
            }
        })
            .catch((error) => {
                setLoading(false)
                console.log(error, " : error in delete multiple")
            })

    }
    //handle multiple delete
    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }
        setSelected(newSelected);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = idata.map((n, index) => {
                if (index !== 0) {
                    return n.id;
                } else {
                    return 0;
                }
            });
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }
    //handle update
    const handleUpdate = () => {
        const isValid = errHandel()
        if (isValid) {
            //setLoading(true);
            let BorrowingsData = {
                transactStockType: "Installment",
                borrowingId: borrowingId,
                id: InstallmentId,
                totalAmount: Number(installmentAmt ? installmentAmt : 0),
                saveDate: fixTimezoneOffset(date),
                // dateInvestment:fixTimezoneOffset(InvtDate)
            };
            UpdateIntInsRepay(BorrowingsData).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        swal("Updated successfully!", "", "success");
                        // handleCloseModal();
                        getInstallmentDataList();
                        handleReset();
                        onRefreshBorrowings();
                    } else {
                        swal(res.data._response.message, "", "info");
                    }
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error, ": error");
            })
        }
    }
    //update installment
    const updateInstallment = (selectedRow, a) => {
        setUpdate(true)
        setDate(new Date(a.saveDate));
        setInstallmentAmt(a.totalAmount);
        setInstallmentId(a.id)
    }
    return (
        <>

            <Modal isOpen={showBorrowingsIn} toggle={() => handleCloseModal()} size="lg" backdrop="static" centered>
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Borrowings Topup</h5>
                </ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Date of Topup<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDate}
                                        popperPlacement="top-end"
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        minDate={new Date(InvtDate)}
                                        selected={date}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-6">
                                    <Form.Label>TopUp Amount<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder="Enter amount of TopUp"
                                        name="installmentAmt"
                                        min="0"
                                        value={installmentAmt}
                                        onChange={handleInstallmentAmt}
                                    /> */}

                                    <Cleave
                                        placeholder="Enter amount of TopUp"
                                        className='form-control'
                                        name="installmentAmt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={installmentAmt}
                                        onChange={handleInstallmentAmt}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.Installment}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {update === false ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                            disabled={!disableButton()}>Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100"
                                            onClick={handleUpdate}
                                        >Update</Button>}

                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div><br />

                    {/* table design */}
                    <TableContainer component={Paper}>
                        <Toolbar

                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }),
                            }}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitlel"
                                    component="div"
                                >
                                    {selected.length - 1} selected
                                </Typography>
                            ) : null}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton>
                                        <DeleteIcon
                                            onClick={(e) => DeleteMultiple(e)}
                                        ></DeleteIcon>
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Toolbar>

                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="1%">
                                        <Checkbox
                                            style={{ float: "left", marginLeft: "14px" }}
                                            color="primary"
                                            onClick={handleSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < idata.length}
                                            checked={idata.length > 0 && selected.length === idata.length ? "checked" : null}
                                            inputProps={{
                                                'area-label': 'select all desserts',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align='center' width="7%">Date of TopUp</TableCell>
                                    <TableCell align='center' width='7%'>Amount of TopUp</TableCell>
                                    <TableCell align='center' width='3%'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idata && idata.map((index, i) => {
                                    const isItemSelected = isSelected(index.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={idata.id}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disabled={i === 0 ? true : false}
                                                    style={{ marginLeft: "1rem" }}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, index.id)}
                                                    inputProps={{
                                                        'area-labelleadby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{moment(index.saveDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.totalAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton disabled={i === 0 ? true : false}>
                                                        <Edit fontSize='small'
                                                            onClick={(e) => updateInstallment(e, index)}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton disabled={i === 0 ? true : false}>
                                                        <DeleteIcon fontSize='small'
                                                            onClick={(e) => deleterow(e, index)}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </Modal>


        </>
    )
}
export default BorrowingsInstallment;