import axios from '../../modules/helpers/axiosInstance';
//import settings from '../../modules/config/settings';
//import Auth from '../../modules/Auth/index';

let reportLiveUrl = "http://5.189.157.40:10023/api";
//const userID = Auth.getsubcriberId();

const RoleList = () => {
    return axios({
        method: 'GET',
        url:`${reportLiveUrl}/UserManagment/RoleMasterDetails`,
    })
}

const UserProfile = (data) => {
    return axios({
        method: 'POST',
        url: `${reportLiveUrl}/UserManagment/completeUserProfile`,
        data: data
    })
}

export { RoleList, UserProfile }