/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {  Form, Button, Row, Col } from 'react-bootstrap';
import { MultiSelectPlans } from '../CommonComponent/MultiSelectPlans';
import Swal from "sweetalert2";
import Loader from "../loadingAnimation/index";
import Cleave from 'cleave.js/react';
import { Edit, Delete } from "@material-ui/icons";
import Tooltip from '@mui/material/Tooltip'
import {
    TableCell,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    IconButton,
} from "@material-ui/core";
import { getFeatures, addnewPlans, updatePlans, getPlans, getAllPlans, deletePlan } from '../../pages/Subscription/SubscriptionAPI'

const AddNewPlans = (props) => {

    const [planName, setPlanName] = useState('');
    const [planAmount, setPlanAmount] = useState('');
    const [selFeatures, setSelFeatures] = useState([]);
    const [FeatureList, setFeaturesList] = useState([]);
    const [planId, setPlanId] = useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [plansData, setPlansData] = useState([]);
    const [update, setUpdate] = useState(false);

    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePlanName = (e) => {
        setPlanName(e.target.value);
        setFormErr({ ...formErr, planName: "" })
    }
    const handlePlanAmount = (e) => {
        setPlanAmount(e.target.rawValue);
        setFormErr({ ...formErr, planAmount: "" })
    }
    // const handleFeatures = (selected) => {
    //     setSelFeatures(selected);
    //     setFormErr({ ...formErr, selFeatures: "" });
    // }
    //disablebutton
    const disableButton = () => {
        return planName && planAmount && selFeatures;
    }
    //handleReset

    const handleReset = () => {
        setPlanName('');
        setPlanAmount('');
        setSelFeatures('');
        setFormErr([]);
        setUpdate(false)
    }

    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (!planName || planName === '') {
            isValid = false;
            formErr['planName'] = "Please Enter Plan Name";
        }
        if (!planAmount || planAmount === 0 || planAmount === '') {
            isValid = false;
            formErr['planAmount'] = "Please Enter Plan Amount";
        }
        if (selFeatures.length < 1) {
            isValid = false;
            formErr["selFeatures"] = "Please Select Features";
        }

        setFormErr(formErr);
        return isValid;
    }

    useEffect(() => {
        (async () => {
            await getFeaturess()
            await getAllplansdata()
        })()


    }, [])
    //get features
    const getFeaturess = async () => {
        getFeatures()
            .then((res) => {
                setLoading(false)
                if (res.data._response.status === 1) {
                    let features = res.data._response.data.features
                    let featuresData = [];
                    features && features.map((data) => {
                        return (featuresData.push({
                            value: data.featureId,
                            label: data.featureName,
                        }))
                    });
                    setLoading(false);
                    setSelFeatures(featuresData);
                    setFeaturesList(featuresData);
                } else {
                    setFeaturesList([])
                    setLoading(false)
                }
            })
            .catch((e) => {
                setFeaturesList([]);
                setLoading(false);
            })
    }

    const getAllplansdata = () => {
        setLoading(true)
        getAllPlans().then((res) => {
            setLoading(false)
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let Allplans = res.data._response.data.planFeatureDetails
                    setAllPlans(Allplans)
                }
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    const updatePlan = (e, planId) => {
        getPlans(planId).then((res) => {
            if (res.data._response.status === 1) {
                let plansData = res.data._response.data.planFeatureDetails
                setPlansData(plansData)
                setUpdate(true)
            }
        }).catch((err)=>{
            console.log(err,"err in plans")
            setLoading(false);
        })
    }

    const 
    
    
    deletePlans = (e, planId) => {
        setLoading(true);
        deletePlan(planId).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    Swal.fire("", res.data._response.message, "error");
                    getAllplansdata()
                }
            }
        }).catch((error) => {
            console.log(error, ":error in delete plans");
        })
    }


    useEffect(() => {
        if (plansData !== undefined) {
            plansData.map((plansData) => {
                setPlanId(plansData.planId);
                setPlanName(plansData.planName);
                setPlanAmount(plansData.planAmount);
                const featuredata = [];
                (plansData.featureDetails).length > 0 && (plansData.featureDetails).forEach((data, index) => {
                    featuredata.push({ label: data.featureName, value: data.featureId });
                })
                setSelFeatures(featuredata)
            })

        }
    }, [plansData])

    const handleSubmit = (e) => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const featureId = [];
            selFeatures.length > 0 && selFeatures.forEach((data, index) => {
                featureId.push(data.value);
            });
            const PlanData = {
                featureId: featureId,
                planTitle: planName,
                planAmount: planAmount
            }
            addnewPlans(PlanData)
                .then((response) => {
                    setLoading(false)
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            Swal.fire("", response.data._response.message, 'success');
                            handleReset();
                            getAllplansdata()
                        } else {
                            Swal.fire("", "Plan Already Exist", 'error');
                        }
                    } else {
                        setLoading(false);
                    }

                }).catch((error) => {
                    setLoading(false);
                })
        }
    }

    const handleUpdate = (e) => {

        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const featureId = [];
            selFeatures.length > 0 && selFeatures.forEach((data, index) => {
                featureId.push(data.value);
            });
            const plansData = {
                planId: planId,
                featureId: featureId,
                planTitle: planName,
                planAmount: planAmount
            }
            updatePlans(plansData)
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            Swal.fire("Success!", response.data._response.message, 'success');
                            handleReset()
                            getAllplansdata()
                        }
                    } else {
                        setLoading(false);
                    }
                }).catch((error) => {
                    setLoading(false);
                })
        }
    }
    return (
        <>
            <div style={{ width: "60%", marginLeft: "20rem" }}>

                {/* <Container fluid style={{alignItems:"center" }}> */}
                <Row>
                    <Col><h4 className="text-center py-3">Add New Plans</h4></Col>
                </Row>
                <div className="mt-2 p-6">
                    <Form className="border border-1 rounded p-3">
                        {loading ? (
                            <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                        ) : null}
                        <Row className="form-set">
                            <Form.Group className="col-md-4">
                                <Form.Label>Plan Name<span className="text-danger h6">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="planName"
                                    autoComplete="off"
                                    placeholder="Enter Plan Name"
                                    onChange={handlePlanName}
                                    value={planName}
                                />
                                {formErr ? (
                                    <div
                                        className="mt-2"
                                        style={{ fontSize: 12, color: "red" }}
                                    >
                                        {formErr.planName}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="col-md-4">
                                <Form.Label>Plan Amount<span className="text-danger h6">*</span></Form.Label>
                                <Cleave
                                    placeholder='Enter Plan Amount'
                                    className='form-control'
                                    autoComplete='off'
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    value={planAmount}
                                    name="planAmount"
                                    onChange={handlePlanAmount}
                                />
                                {formErr ? (
                                    <div
                                        className="mt-2"
                                        style={{ fontSize: 12, color: "red" }}
                                    >
                                        {formErr.planAmount}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="col-md-4">
                                <Form.Label>Add Features<span className="text-danger h6">*</span></Form.Label>
                                <MultiSelectPlans
                                    placeholder='Select Features'
                                    name="features"
                                    value={selFeatures}
                                    onChange={setSelFeatures}
                                    options={FeatureList}
                                />
                                {formErr ? (
                                    <div
                                        className="mt-2"
                                        style={{ fontSize: 12, color: "red" }}
                                    >
                                        {formErr.selFeatures}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Row>
                        <Row className="form-set">
                            <Col md="12" className="text-center mt-3">
                                {update === false ?

                                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                                        onClick={() => { handleSubmit(); }}
                                        disabled={!disableButton()}
                                    >
                                        Save
                                    </Button>

                                    :
                                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                                        onClick={() => { handleUpdate(); }}
                                    // disabled={!disableButton()}
                                    >
                                        Update
                                    </Button>
                                }
                                <Button
                                    variant="secondary"
                                    className="mx-2 w100"
                                    type="reset"
                                    onClick={handleReset}
                                >
                                    Clear
                                </Button>

                            </Col>
                        </Row>

                    </Form>
                </div>
                {/* </Container> */}

            </div>

            <div className="mt-2 p-5 pt-5  justify-content-center" style={{ width: '100%',marginLeft:"7rem"}}>
                <Paper sx={{ width: '70%', overflow: 'hidden' }} className="stockdetailmodal p-3 pb-0">
                    <TableContainer >
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align="left">
                                        Plan Name
                                    </TableCell>
                                    <TableCell align="right">
                                        Plan Amount
                                    </TableCell>
                                    <TableCell align="center" colSpan={4}>
                                        Features
                                    </TableCell>
                                    <TableCell align="center">
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allPlans.map((planData) => (
                                    <TableRow>
                                        <TableCell width="5%" align="left">{planData.planName}</TableCell>
                                        <TableCell width="2%" align="right">{planData.planAmount}</TableCell>
                                       
                                            {planData.featureDetails && planData.featureDetails.map((feature, index) => (
                                                // <TableRow width="7%" align="left">
                                                   <TableCell width="7%" align="left" >
                                                     {feature.featureName}
                                                    </TableCell>
                                                // </TableRow>
                                            ))}
                                        
                                       { planData.featureId === "1,2" ?
                                       <>
                                       <TableCell>-</TableCell>
                                       <TableCell>-</TableCell>
                                        <TableCell width="2%" align="center">
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <Edit fontSize="small" onClick={(e) => updatePlan(e, planData.planId)} data-dismiss="modal" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton>
                                                    <Delete fontSize="small" onClick={(e) => deletePlans(e, planData.planId)} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        </>
                                        : planData.featureId === "1,2,3" ?
                                        <>
                                        <TableCell>-</TableCell>
                                        <TableCell width="2%" align="center">
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <Edit fontSize="small" onClick={(e) => updatePlan(e, planData.planId)} data-dismiss="modal" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton>
                                                    <Delete fontSize="small" onClick={(e) => deletePlans(e, planData.planId)} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        </>
                                       :
                                       <TableCell width="4%" align="center">
                                       <Tooltip title="Edit">
                                           <IconButton>
                                               <Edit fontSize="small" onClick={(e) => updatePlan(e, planData.planId)} data-dismiss="modal" />
                                           </IconButton>
                                       </Tooltip>
                                       <Tooltip title="Delete">
                                           <IconButton>
                                               <Delete fontSize="small" onClick={(e) => deletePlans(e, planData.planId)} />
                                           </IconButton>
                                       </Tooltip>
                                   </TableCell>
                                       }
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

        </>
    )
}

export default AddNewPlans;