//import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index'
const userID = Auth.getsubcriberId();

const AddNewEntity = async (body) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/UserManagment/AddSubaccount`,
        data: body
    })  
}

const EditNewEntity = async (body) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/UserManagment/EditSubaccount`,
        data: body
    })
}
const DeleteEntity = async (sub_Id) => {
    return axios({
        method: "DELETE",
        url: `${settings.API_URL}/UserManagment/DeleteSubaccount?sub_Id=${sub_Id}`,
    })
}
const ListEntity = async () => {
    return axios({
        method: "GET",
        url: `http://5.189.157.40:10023/api/UserManagment/GetSubaccountList?subId=${userID}`,
       
    })
}

const GetEntityByID = async (subAccId) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/FetchSubAccountDetails?subAccountId=${subAccId}`,

    })
}


export {
    AddNewEntity, EditNewEntity, ListEntity, GetEntityByID, DeleteEntity
}