import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import axios from '../../modules/helpers/axiosInstance';
import Auth from '../../modules/Auth/index';
import { Link } from "react-router-dom";
import Loader from "../loadingAnimation/index";

const ChangePassword = () => {
    const subcriberId = Auth.getsubcriberId();
    const [currentPass, setCurrentPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    const [passwordErr, setPasswordErr] = useState(false);
    const [loading, setLoading] = useState(false);

    const Validate = () => {
        let passwordErr = {};
        let isValid = true
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!currentPass) {
            isValid = false;
            passwordErr["currentPass"] = "Please Enter Current Password";
        }

        if (!newPass) {
            isValid = false;
            passwordErr["newPass"] = "Please Enter New Password";
        } else if (!passregex.test(newPass)) {
            isValid = false;
            passwordErr["newPass"] = "Please Enter Strong Password";
        } else if (currentPass === newPass) {
            isValid = false;
            passwordErr["newPass"] = "You Have Entered Same Password As Old Password"
        }

        if (!confirmPass) {
            isValid = false;
            passwordErr["confirmPass"] = "Please Enter Confirm New Password";
        } else if (!passregex.test(confirmPass)) {
            isValid = false;
            passwordErr["confirmPass"] = "Please Enter Strong Password";
        } else if (newPass !== confirmPass) {
            isValid = false;
            passwordErr["confirmPass"] = "The New Password And Confirm Password do not match";
        }

        setPasswordErr(passwordErr);
        return isValid;
    }
    //handlechange
    const handleCPassword = (e) => {
        setCurrentPass(e.target.value);
        setPasswordErr({ ...passwordErr, currentPass: "" });
    }
    const handleNPassword = (e) => {
        setNewPass(e.target.value);
        setPasswordErr({ ...passwordErr, newPass: "" });
    }
    const handleCNPassword = (e) => {
        setConfirmPass(e.target.value);
        setPasswordErr({ ...passwordErr, confirmPass: "" });
    }

    //handlesubmit
    const handleSubmit = (e) => {
        const isValid = Validate();
        if (isValid === true) {
            setLoading(true);
            let body = {
                oldPassword: currentPass,
                password: newPass,
                confirmPassword: confirmPass,
            };
            let headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            axios.post(`http://5.189.157.40:10020/Subcriber/change-password/${subcriberId}`, body, headers)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data.response.status === 1) {
                            disableButton()
                            Swal.fire("Success!", "Password Change successfully", "success");
                            handleReset()
                        } else {
                            setLoading(false);
                            Swal.fire(res.data.response.message, "", "info");
                            disableButton()
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    Swal.fire("Error!", "Something went wrong", "", "info");
                    console.log(err);
                })

        }
    }
    const handleReset = () => {
        setCurrentPass("")
        setNewPass("")
        setConfirmPass("")
        setPasswordErr("")
    }
    const disableButton = () => {
        return newPass && currentPass && confirmPass
    }

    return (
        <Container fluid="lg" className="col-md-4 shadow p-3 mb-5 bg-white rounded">
            {loading ? (
                <Loader />
            ) : null}
            <div className="p-3">
                <Row>
                    <Col className="d-flex justify-content-center" closeButton>
                        <h3 className="text-center">Change Password</h3>
                    </Col>
                </Row>
                <br />
                <Form className="border border-1 rounded p-4">
                    <Row>
                        <Form.Group>
                            <Form.Label className="h6">Current Password</Form.Label>
                            <TextField type="password" placeholder="Current Password" name="currentPass" value={currentPass} onChange={handleCPassword} fullWidth />
                            {passwordErr ? (
                                <div
                                    className="mt-2"
                                    style={{ fontSize: 12, color: "red" }}
                                >
                                    {passwordErr.currentPass}
                                </div>
                            ) : null}
                        </Form.Group>
                    </Row>
                    <br />
                    <Row>
                        <Form.Group>
                            <Form.Label className="h6">New Password</Form.Label>
                            <TextField type="password" placeholder="New Password" name="newPass" value={newPass} onChange={handleNPassword} fullWidth />
                            {passwordErr ? (
                                <div
                                    className="mt-2"
                                    style={{ fontSize: 12, color: "red" }}
                                >
                                    {passwordErr.newPass}
                                </div>
                            ) : null}
                        </Form.Group>
                    </Row>
                    <br />
                    <Row>
                        <Form.Group>
                            <Form.Label className="h6">Confirm New Password</Form.Label>
                            <TextField type="password" placeholder="Confirm New Password" name="confirmPass" value={confirmPass} onChange={handleCNPassword} fullWidth />
                            {passwordErr ? (
                                <div
                                    className="mt-2"
                                    style={{ fontSize: 12, color: "red" }}
                                >
                                    {passwordErr.confirmPass}
                                </div>
                            ) : null}
                        </Form.Group>
                    </Row>
                </Form>
                <Row className="form-set">
                    <Col md="12" className="text-center mt-3">
                        <Button variant="primary" className="mx-2 w100" id="submitButton"
                            onClick={() => { handleSubmit(); }}
                            disabled={!disableButton()}
                        >Save</Button>
                        <Link to="/dashboard"><Button
                            variant="secondary"
                            className="mx-2 w100"
                            type="reset"
                            onClick={handleReset}
                        > Cancel</Button></Link>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
export default ChangePassword;