import React from 'react';
 import StockFolio from '../../components/stocksFolio/StockFolio';
 import Navbar from '../../components/navbar/Navbar';
 import Sidebar from '../../components/sidebar/Sidebar';
 import { useSelector } from 'react-redux';
// import { Container } from 'react-bootstrap';

function Stock () {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return (
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar/>
                <div>
                    {/* <Container fluid> */}
                    <StockFolio />
                    {/* </Container> */}
                </div>
            </div>
        </div>
    );
}

export default Stock;


