import React from 'react';

import CustomCharts from '../../components/CommonComponent/charts/CustomCharts';



function Googlecharts(props) {
  const { donutChartData } = props;
  const options = {
    // title: "Cost vs. Market Investment comparison",
    sliceVisibilityThreshold: 0,
    pieHole: 0.4,
    chartArea: {
      bottom: 10,  // !!! works !!!
      top: 30,
      width: "100%",
      height: "80%"
    },

    is3D: false,
    colors: ['#ac92eb', '#4fc1e8', '#8bcb30', '#ffc809', '#ed5564', '#e0440e', '#e6693e'],


  };

  return (
    <CustomCharts data={[['Investment', 'Total Investment'], ...donutChartData]} options={options} chartType="PieChart" />
  );
}

export default Googlecharts;