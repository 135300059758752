/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import Select from "react-select";
import Swal from "sweetalert2";
import { AddUserData,  UpdateUserListData } from '../../../pages/RoleMasterPages/AdminsPages/UserdetailsApi';
import Loader from "../../loadingAnimation/index";
// import { Country, States, Cities } from '../../../modules/helpers/constant';
import { Country, State, City } from 'country-state-city';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'


const AddUser = (props) => {
  const { show, onHide, userdata,userId,update } = props;
  const [fullName, setFullName] = useState({ fullname: "" })
  const [accountNumber, setAccountNumber] = useState({ accountno: "" })
  const [bDate, setBDate] = useState(null)
  const [address, setAddress] = useState({ address: "" })
  const [selCity, setSelCity] = useState([])
  const [city, setCity] = useState([])
  const [selState, setSelState] = useState([])
  const [state, setState] = useState([])
  const [selCountry, setSelCountry] = useState({})
  // const [country, setCountry] = useState([])
  const [mobileNo, setMobileNo] = useState({ mobileno: "" })
  const [email, setEmail] = useState({ email: "" })
  const [password, setPassword] = useState({ password: "" });
  const [cpassword, setCPassword] = useState({ cpassword: "" });
  const [passVisible, setPassVisible] = useState(false);
  const [cpassVisible, setCPassVisible] = useState(false);
  

  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
  
    if (show) {
      let result = Country.getCountryByCode('IN');
      setSelCountry({ label: result.name, value: result.name, isoCode: result.isoCode });
      const getStates = () => {
        let States = State.getStatesOfCountry('IN');
        let result = States.map((f) => {
          return { label: f.name, value: f.name, isoCode: f.isoCode, countryCode: f.countryCode };
        });
        setState(result);
      };
      getStates();
    }
  }, [show]);



  const getCities = (countryCode, stateCode) => {
    let Cities = City.getCitiesOfState(countryCode, stateCode);
    let result = Cities.map((f) => {
      return { label: f.name, value: f.name, stateCode: f.stateCode, countryCode: f.countryCode };
    });
    setCity(result);
  };



  const handleFullName = (e) => {
    const { name, value } = e.target;
    setFullName({ ...fullName, [name]: value })
    setFormErr({ ...formErr, fullname: '' })
  }
  const handleAccountNo = (e) => {
    const { name, value } = e.target;
    setAccountNumber({ ...accountNumber, [name]: value });
    setFormErr({ ...formErr, accountno: '' })
  }
  // const handleDate = (bDate) => {
  //   setBDate(bDate);
  //   setFormErr({ ...formErr, date: '' })
  // }
  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    setFormErr({ ...formErr, address: '' })
  }

  // const handleCountry = (selected) => {
  //   setSelCountry(selected)
  //   getStates(selected.isoCode);
  //   setSelState([]);
  //   setSelCity([]);
  //   setFormErr({ ...formErr, selCountry: '' })
  // }
  const handleState = (selected) => {
    setSelState(selected);
    getCities(selected.countryCode, selected.isoCode);
    setSelCity([]);
    setFormErr({ ...formErr, selState: '' })
  }
  const handleCity = (selected) => {
    setSelCity(selected);
    setFormErr({ ...formErr, selCity: '' })
  };
  const handleMobileNo = (e) => {
    const { name, value } = e.target;
    setMobileNo({ ...mobileNo, [name]: value });
    setFormErr({ ...formErr, mobileno: '' })
  }
  const handleEmail = (e) => {
    const { name, value } = e.target;
    setEmail({ ...email, [name]: value });
    setFormErr({ ...formErr, email: '' })
  }
  const handlePassword = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value })
    setFormErr({ ...formErr, password: '' })
  }
  const handleCPassword = (e) => {
    const { name, value } = e.target;
    setCPassword({ ...cpassword, [name]: value })
    setFormErr({ ...formErr, cpassword: '' })
  }

  const handleReset = () => {
    setFullName({ fullname: "" })
    setAccountNumber({ accountno: "" })
    setBDate(null)
    setAddress({ address: "" })
    setSelCity([])
    setSelState([])
    // setSelCountry([])
    setMobileNo({ mobileno: "" })
    setEmail({ email: "" })
    setPassword({ password: "" })
    setCPassword({cpassword:"" })
    setFormErr([])
  }

  const disableButton = () => {
    return fullName.fullname && accountNumber.accountno
    &&address.address
    && mobileNo.mobileno && email.email && password.password
  }

  const errHandel = () => {
    const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    let formErr = {};
    let isValid = true;
    if (!fullName.fullname) {
      isValid = false;
      formErr["fullname"] = "Please Enter Fullname";
    }
    if (!accountNumber.accountno) {
      isValid = false;
      formErr["accountno"] = "Please Enter Account Number"
    }
    // if (!bDate) {
    //   isValid = false;
    //   formErr["date"] = "Please Select Date";
    // }
    if (!address.address) {
      isValid = false;
      formErr["address"] = "Please Enter Address";
    }
    if (selCity.length < 1) {
      isValid = false;
      formErr["selCity"] = "Please Select City";
    }
    if (selState.length < 1) {
      isValid = false;
      formErr["selState"] = "Please Select State";
    }
    // if (selCountry.length < 1) {
    //   isValid = false;
    //   formErr["selCountry"] = "Please Select Country";
    // }
    if (!mobileNo.mobileno) {
      isValid = false;
      formErr["mobileno"] = "Please Enter Mobile Number";
    }
    if (mobileNo.mobileno) {
      if (mobileNo.mobileno.length < 10 || mobileNo.mobileno.length > 10) {
        isValid = false;
        formErr["mobileno"] = "Please Enter Valid Mobile Number"
      }
    }
    
    if (!email.email) {
      isValid = false;
      formErr["email"] = "Please Enter Email";
    }
    if (email.email) {
      if (emailregex.test(email.email) === false) {
        isValid = false;
        formErr["email"] = "Please Enter Valid Email"
      }
    }
    if(!update){
    if (!password.password) {
      isValid = false;
      formErr["password"] = "Please Enter Password";
    }
    if (password.password) {
      if (passregex.test(password.password) === false) {
        isValid = false;
        formErr["password"] = "Please Enter Valid Password";
      }
    }
    if (cpassword.cpassword === "" || cpassword.cpassword === null) {
       isValid = false;
      formErr["cpassword"] = "Please enter password again!";
    }
  }
    setFormErr(formErr);
    return isValid;
  }

  const handleSubmit = () => {
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const userData = {
        SubcriberName: fullName.fullname,
        SubcriberAccountNo: accountNumber.accountno,
        SubcriberAdd1: address.address,
        SubcriberCity: selCity.value,
        SubcriberState: selState.value,
        SubcriberCountry: selCountry.value,
        SubcriberPh1: mobileNo.mobileno,
        SubcriberEmail: email.email,
        SubcriberPassword: password.password,
        confirmPassword: cpassword.cpassword,
        AcceptTerms:true

      }
      AddUserData(userData).then((res) => {
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            Swal.fire("Success!", "User Added Successfully", 'success');
            handleReset();
          }else {
            setLoading(false);
            Swal.fire(res.data._response.message, "", 'error');
          }
        }
      }).catch((err) => {
        console.log(err, "error in user adding ");
        setLoading(false)
      })

    }

  }

  //get user data
  useEffect(() => {
    if (userdata !== undefined) {
      userdata.map((u)=>{
        setFullName({ fullname: u.subcriberName })
        setAccountNumber({ accountno: u.subcriberAccountNo });
        setAddress({ address: u.subcriberAdd1 })
        setSelCountry({ label: u.subcriberCountry, value: u.subcriberCountry })
        setSelCity({ label: u.subcriberCity, value: u.subcriberCity })
        setSelState({ label: u.subcriberState, value: u.subcriberState })
        setMobileNo({ mobileno: u.subcriberPh1 })
        setEmail({ email: u.subcriberEmail })
        setPassword({ password: u.subcriberPassword })
        setCPassword({ cpassword: u.subcriberPassword })
      })
      
    }

  }, [userdata])

  const handleUpdate = () => {
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true)
      const userupdateData = {
        SubcriberId: userdata.userId,
        SubcriberName: fullName.fullname,
        SubcriberAccountNo: accountNumber.accountno,
        SubcriberAdd1: address.address,
        SubcriberCity: selCity.value,
        SubcriberState: selState.value,
        SubcriberCountry: selCountry.value,
        SubcriberPh1: mobileNo.mobileno,
        SubcriberEmail: email.email,
        SubcriberPassword: password.password,
        confirmPassword: cpassword.cpassword,
        userId : userId
        // AcceptTerms:false
      }
      UpdateUserListData(userupdateData).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 1) {
            Swal.fire("Success", "User Updated Successfully", "success");
            handleReset()
          } else {
            setLoading(false);
            Swal.fire(res.data._response.message, "", 'error');
          }
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      })
    }
  }



  return (
    <>

      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton className='headerBlue n'>
          <Modal.Title className="mx-auto"><h5>Add User</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : null}
          <div className="mt-2 p-6">
            <Form className="border border-1 rounded p-3">
              <Row className="form-set">
                <Form.Group className="col-md-6" >
                  <Form.Label> Full Name  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fullname"
                    min="0"
                    autoComplete='off'
                    onChange={handleFullName}
                    value={fullName.fullname}
                    placeholder="Enter Full Name" />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.fullname}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-6" >
                  <Form.Label> Account No.</Form.Label>
                  <Form.Control type="Number"
                    name="accountno"
                    min="0"
                    autoComplete='off'
                    placeholder="Enter Account Number"
                    onChange={handleAccountNo}
                    value={accountNumber.accountno}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.accountno}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-6" >
                  <Form.Label> Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={handleAddress}
                    value={address.address}
                    autoComplete="off"
                    name="address"
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.address}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-6" >
                  <Form.Label> Country  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    min="0"
                    autoComplete='off'
                    value={selCountry.value}
                    disabled
                    placeholder="Country"
                  />
                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.selCountry}
                    </div>
                  ) : null}
                </Form.Group>

              </Row>
              <Row className="form-set">
                <Form.Group className="col-md-6" >
                  <Form.Label> State</Form.Label>
                  <Select
                    value={selState.length === 0 ? '' : selState}
                    onChange={handleState}
                    options={state}
                    name="state"
                    isOptionDisabled={(option) => option.disabled === 0}
                  />
                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.selState}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-6" >
                  <Form.Label> City </Form.Label>
                  <Select
                    value={selCity.length === 0 ? '' : selCity}
                    onChange={handleCity}
                    options={city}
                    name="city"
                  // isOptionDisabled={(option) => option.disabled === 0}
                  />
                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.selCity}
                    </div>
                  ) : null}
                </Form.Group>

              </Row>
              <Row className="form-set">
           
                <Form.Group className="col-md-6" >
                  <Form.Label> Mobile Number</Form.Label>
                  <Form.Control
                    type="Number"
                    name="mobileno"
                    min="0"
                    onChange={handleMobileNo}
                    value={mobileNo.mobileno}
                    placeholder="Enter Mobile Number"
                    autoComplete='off'
                  />
                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.mobileno}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-6" >
                  <Form.Label> Email  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    min="0"
                    onChange={handleEmail}
                    value={email.email}
                    placeholder="Enter Email"
                  />
                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.email}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              {update === false ?
             <>
              <Row className="form-set">
                
             
                <Form.Group className="col-md-6" >
                  <Form.Label> Password</Form.Label>
                  <Form.Control
                    type={passVisible ? "text" : "password"}
                    name="password"
                    min="0"
                    autoComplete='off'
                    onChange={handlePassword}
                    value={password.password}
                    placeholder="Enter Password"
                  />
                  <i onClick={() => setPassVisible(!passVisible)} className='passVisibility'>{passVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}</i>

                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.password}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-6" >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type={cpassVisible ? "text" : "password"}
                    name="cpassword"
                    min="0"
                    autoComplete='off'
                    onChange={handleCPassword}
                    value={cpassword.cpassword}
                    placeholder="Enter Confirm Password"
                  />
                  <i onClick={() => setCPassVisible(!cpassVisible)} className='passVisibility'>{cpassVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}</i>

                  {formErr ? (
                    <div
                      className='mt-2'
                      style={{ fontSize: 12, color: "red" }}>
                      {formErr.password}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              </>
              :null}
              <Row className="form-set">
                <Col md="12" className="text-center mt-3">
                  {update === false ?

                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                      onClick={() => { handleSubmit(); }}
                      disabled={!disableButton()}
                    >
                      Save
                    </Button>
                    :
                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                      onClick={() => { handleUpdate(); }}
                      // disabled={!disableButton()}
                    >
                      Update
                    </Button>}

                  <Button
                    variant="secondary"
                    className="mx-2 w100"
                    type="reset"
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AddUser;