import React from 'react'
import RecentTransactions from '../../components/Dashboard/RecentTransactions';
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { useSelector } from 'react-redux';
const Transaction = () => {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
  return (
    <div>
          {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar />
            <div>
                <RecentTransactions />
            </div>
        </div>
    </div>
  )
}

export default Transaction
