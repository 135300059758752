/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from '../../loadingAnimation/index'
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

import {
  AvilableQuantity,
  FetchBrokerDropDownData,
  FetchCompanyDropDownData,
  addSplitStocks,
  EntityList,
  updateSplit,
  FetchDate
} from "../../../pages/Stocks/StocksAPI";

const Splitmodal = (props) => {

  const { showS, onHideS, stockHeaderIdS, stockTransactionS, stockIdS, showSplitPreview } = props;

  const handleClose = (val) => {
    onHideS(val);
    handleReset();
  }

  const [companyList, setCompanyList] = useState([]);
  const [brokerData, setBrokerList] = useState([]);
  const [broker, setbroker] = useState([]);
  const [selScrip, setSelScrip] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [entityValue, setEntityValue] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [BQtySplit, setBQtySplit] = useState(0);
  const [AQtySplit, setAQtySplit] = useState({ quantity: '' });
  const [formErr, setFormErr] = useState({});
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [successMessage, setSuccessMessage] = useState({});
  const [buyDate, setBuyDate] = useState(null)
  const [ratio, setRatio] = useState({ ratio: "" });

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEffect(() => {
    if (showS) {
      (async () => {
        if (stockHeaderIdS === 0 || stockHeaderIdS === undefined) {
          await getEntityListt();
        }
      })();
    }
  }, [showS, stockHeaderIdS]);

  useEffect(() => {
    if (showS) {
      (async () => {
        await getCompanyDropDownData()
        await getBrokerDropDownData()
      })();
    }
  }, [selEntity, showS])

  useEffect(() => {
    if (showS && stockHeaderIdS === 0 || stockHeaderIdS === undefined) {
      let companyId = selScrip.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      if (companyId !== undefined && brokerId !== undefined && subAccountId !== undefined) {
        getStockDetail(brokerId, subAccountId, companyId);
      }
    }
  }, [selScrip, selBroker, selEntity])
  // useEnterKeyListener({
  //   querySelectorToExecuteClick: "#submitButton"
  // });
  // edit bonus data
  useEffect(() => {
    // debugger
    if (stockHeaderIdS !== 0) {

      if (stockTransactionS !== undefined) {

        setSelBroker({
          value: stockTransactionS.brokerId,
          label: stockTransactionS.broker.brokerName,
        });
        setbroker(stockTransactionS.brokerId);

        setSelEntity({
          value: stockTransactionS.subAccountId,
          label: stockTransactionS.subAccount.subAccountName,
        });

        setEntityValue(stockTransactionS.subAccountId);

        stockTransactionS.stockDetails && stockTransactionS.stockDetails.map((t) => {
          setBQtySplit(t.dividendPerc);
          setAQtySplit({ quantity: t.quantity });
          setPDate(new Date(t.date));
          setSelScrip(t.company, { companyType: t.instrumentTypeId })
          setRatio({ ratio: t.ratio })

        })

      }

    }
  }, [stockTransactionS])

  // for buy date
  useEffect(() => {
    if (showS) {
      if (stockHeaderIdS === 0 || stockHeaderIdS === undefined) {
        setPDate(null)
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
      if (stockHeaderIdS !== 0 && stockTransactionS !== undefined) {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
    }
  }, [selScrip, selBroker, selEntity, showS]);


  // get dropDown Company Data
  const getCompanyDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchCompanyDropDownData(sub_Id)
        .then((res) => {
          // console.log(res,'company list');
          if (res.data._response.status === 1) {
            let UnlistedCompany = [];
            let ListedCompany = [];

            let company = res.data._response.data.companyName;
            company &&
              company.filter(data => data.instrumentTypeId === 1).map(i => {
                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });
            company &&
              company.filter(data => data.instrumentTypeId === 10).map(i => {
                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });

            const groupedOptions = [
              {
                label: 'Listed Companies',
                options: ListedCompany,
              },
              {
                label: 'UnListed Companies',
                options: UnlistedCompany,
              },
            ];
            setLoading(false);
            setCompanyList(groupedOptions);
          } else {
            setCompanyList([]);
            setLoading(false);
            // setError(res.data.message);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
          // setError(e.message);
        });
    }
  };


  //getbrockerlist
  const getBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchBrokerDropDownData(sub_Id)
        .then((res) => {
          setLoading(false)
          // console.log(res, "brokerlisy");
          if (res.data._response.status === 1) {
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setLoading(false);
            setBrokerList(brokersData);
          } else {
            setBrokerList([]);
            setLoading(false)
            //setError(res.data.message);
          }
        })
        .catch((e) => {
          // setError(e.message);
          setBrokerList([]);
          setLoading(false);
          console.log(e, "brokerlist error");
        });
    }
  };


  //getEntityList
  const getEntityListt = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {

        if (res.status === 200) {
          setLoading(false);
          //
          let entity = res.data._response.data.subaccountDetails;
          let entityData = [];
          entity &&
            entity.map((data) => {
              return (entityData.push({
                value: data.subAccountId,
                label: data.subAccountName,
                disabled: data.active
              }))
            });
          setEntity(entityData);
          setLoading(false)

        } else {
          setLoading(false);
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });


  };

  const handleScrip = (selected) => {
    setSelScrip(selected);
    setFormErr({ ...formErr, selScrip: "" });
    setBQtySplit(0);
  };

  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityValue(selected.value)
    setSelScrip([]);
    setSelBroker([]);
    setFormErr({ ...formErr, selEntity: "" });
  };

  const handleBroker = (selected) => {
    setSelBroker(selected);
    setbroker(selected.value)
    setSelScrip([])
    setFormErr({ ...formErr, selBroker: "" });
    setBQtySplit(0);
  };

  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: "" });
  };

  const handleQtySplit = (e) => {
    const { name, rawValue } = e.target;
    setAQtySplit({ ...AQtySplit, [name]: rawValue });
    setFormErr({ ...formErr, quantity: "" });
  };

  const handleRatio = (e) => {
    const { name, value } = e.target;
    setRatio({ ...ratio, [name]: value.replace(/[^0-9:]/ig, '') });
    setFormErr({ ...formErr, ratio: '' })

  }

  const errHandel = () => {
    let formErr = {};
    let isValid = true;

    if (selScrip.length < 1) {
      isValid = false;
      formErr["selScrip"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }
    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }
    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }
    if (!AQtySplit.quantity || AQtySplit.quantity === 0) {
      isValid = false;
      formErr["AQtySplit"] = "Please Fill This Field!";
    }
    if (AQtySplit.quantity) {
      if (AQtySplit.quantity < 0) {
        isValid = false;
        formErr["AQtySplit"] = "Please Enter Positive Value !";
      }
    }
    // if (AQtySplit.quantity) {
    //   let qvalue = parseFloat(AQtySplit.quantity); 
    //   if (Number.isInteger(qvalue) == false) {
    //     isValid = false;
    //     formErr["AQtySplit"] = "Please enter only integer value!";
    //   }
    // }
    // if (!ratio) {
    //   isValid = false;
    //   formErr["ratio"] = "Please enter ratio !";
    // }
    setFormErr(formErr);
    return isValid;
  };

  const getStockDetail = async (brokerId, subAccountId, companyId) => {
    setLoading(true)
    await AvilableQuantity(brokerId, subAccountId, companyId)
      .then((res) => {
        if (res.data._response.status === 1) {
          setBQtySplit(res.data._response.data.remainingQuantity);
          setLoading(false)
        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message);
      });
  };

  const getBuyDate = async (sub_id, co_id, bro_id) => {
    await FetchDate(sub_id, co_id, bro_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false)
            setBuyDate(res.data._response.data.fetchDate)
          }
          else {
            setLoading(false)
            setError(res.data.message);
          }

        } else {
          setError(res.data.message);

        }
      })
      .catch((e) => {
        setError(e.message);

      });
  };

  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true)
      let data = {
        subAccountId: selEntity.value,
        brokerId: selBroker.value,
        date: fixTimezoneOffset(pDate),
        stockDetails: [
          {
            companyId: selScrip.value,
            quantity: parseInt(AQtySplit.quantity),
            ratio: ratio.ratio,
            instrumentTypeId: selScrip.companyType
          },
        ],
        // QtyBeforeSplit: BQtySplit,
      };

      await addSplitStocks(data)
        .then((res) => {

          setLoading(false);
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              //setSuccessMessage(res.data._response.message)
              setSuccess(true);
              setForm(res);
              Swal.fire(res.data._response.message, "", "success");
              handleReset();
              // onHide({show:false})
              setAQtySplit({ ...AQtySplit, quantity: 0 });
            }else{
              Swal.fire("Something went wrong", "", "warning");
            }
            //onRefresh();
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  };

  const handleUpdate = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        brokerId: selBroker.value,
        stockHeaderId: stockHeaderIdS,
        date: fixTimezoneOffset(pDate),
        subAccountId: selEntity.value,
        stockDetails: [
          {
            stockId: stockIdS,
            quantity: parseInt(AQtySplit.quantity),
            companyId: selScrip.value,
            ratio: ratio.ratio,
            instrumentTypeId: selScrip.companyType
          },
        ],
      };

      updateSplit(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);

              Swal.fire("Split Updated Successfully!", "", "success");
              handleClose();
              // onRefresh();
            }else{
              Swal.fire("Something went wrong", "", "warning");
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  const handleReset = () => {
    setCompanyList([]);
    setSelScrip([]);
    setSelBroker([]);
    setBrokerList([]);
    setSelEntity([]);
    setBQtySplit(0);
    setAQtySplit({ quantity: 0 });
    setPDate(null);
    setForm([]);
    setFormErr({});
    setRatio({ ratio: "" });
  };

  const disableButton = () => {
    return selEntity && selScrip && selEntity && selBroker && pDate && AQtySplit

  }

  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  return (
    <>
      <Modal show={showS} onHide={handleClose} size="lg" centered>
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto"><h5>Stock Split</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
          <div className="mt-2 p-6">
            <Form className="border border-1 rounded p-3" >
              <Row className="form-set">
                {stockHeaderIdS === 0 || stockHeaderIdS === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>
                }
                {stockHeaderIdS === 0 || stockHeaderIdS === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleBroker}
                      options={brokerData}
                      name="brokerId"
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleBroker}
                      options={brokerData}
                      name="brokerId"
                      isDisabled="required"

                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                }
                {stockHeaderIdS === 0 || stockHeaderIdS === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selScrip}
                      onChange={handleScrip}
                      options={companyList}
                      name="scrip"
                      isOptionDisabled={handelDisableDD}
                      styles={{
                        option: (data) => ({
                          ...data,
                          margin: 0,
                          padding: '3px 12px',
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          flex: '1 1',
                          color: '#000',
                          margin: 0,
                          padding: '7px 2px',
                          // border: `2px solid `,
                          background: "#8bc3ff",
                          fontSize: 'small',
                          fontWeight: 500
                        }),
                      }}
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selScrip}
                      onChange={handleScrip}
                      options={companyList}
                      name="scrip"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                }
              </Row>
              <Row className="form-set">

                <Form.Group className="col-md-3">
                  <Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    // maxDate={new Date()}
                    minDate={new Date(buyDate)}
                    onChange={handleDate}
                    // onChange={(date)=>{  setFormErr({ ...formErr, dateofPurchase: '' }) ; setPDate(date)}}
                    dateFormat="dd-MM-yyyy"
                    name="date"
                    autoComplete="off"
                    selected={pDate}
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.date}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-3" >
                  <Form.Label> Ratio </Form.Label>
                  <Form.Control type="text" name="ratio" min="0" placeholder="Enter Ratio" onChange={handleRatio} value={ratio.ratio} autoComplete="off" />
                </Form.Group>
                <Form.Group className="col-md-3">
                  <Form.Label>Balance quantity before split </Form.Label>
                  <Form.Control
                    type="text"
                    name="QtyBeforeSplit"
                    disabled
                    min="0"
                    value={NumberSeperator(BQtySplit)}
                  />
                </Form.Group>
                <Form.Group className="col-md-3">
                  <Form.Label>Balance quantity after split<span className="text-danger h6">*</span></Form.Label>
                  {/* <Form.Control
                    type="Number" */}
                    <Cleave 
                    className='form-control'
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    name="quantity"
                    autoComplete="off"
                    onChange={handleQtySplit}
                    value={(AQtySplit.quantity)}
                    min="0"


                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.AQtySplit}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>
              <Row>
                <Col md="12" className="text-center mt-3">
                  {stockHeaderIdS === 0 || stockHeaderIdS === undefined ? (

                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }} disabled={!disableButton()}>
                      Save
                    </Button>
                  ) : (
                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()} >
                      Update
                    </Button>
                  )}
                  {stockHeaderIdS === 0 || stockHeaderIdS === undefined ?
                    <Button
                      variant="secondary"
                      className="mx-2 w100"
                      type="reset"
                      onClick={handleReset}
                    >
                      Clear
                    </Button> : null}
                </Col>
              </Row>
            </Form>
          </div>




        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary">
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
      {showSplitPreview === true ?
        <Modal show={showSplitPreview} onHide={() => handleClose(1)}>
          <Modal.Header closeButton className="headerBlue" >
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Stock Split Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                <div className="transactionDataValue">{selScrip.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Date : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio.ratio}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Balance quantity before split : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(BQtySplit)}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Balance quantity after split : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(AQtySplit.quantity)}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  )
}

export default Splitmodal;
