
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {

    TableCell,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";

const CADashboard = () => {
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="text-center py-3">CA Dashboard</h4>
                    </Col>
                </Row>


                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">

                        <TableHead>
                            <TableRow>
                                <TableCell>Sr.No.</TableCell>
                                <TableCell align="right">Subscriber Name</TableCell>
                                <TableCell align="right">Subscriber Email</TableCell>
                                <TableCell align="right">Active Plan</TableCell>
                                <TableCell align="right">Active /InActive Status</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell component="th" scope="row">
                                    1
                                </TableCell>
                                <TableCell align="right">Pichai</TableCell>
                                <TableCell align="right">test@gmail.com</TableCell>
                                <TableCell align="right">Gold Suprime</TableCell>
                                <TableCell align="right">Active</TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    2
                                </TableCell>
                                <TableCell align="right">Nilam</TableCell>
                                <TableCell align="right">mishra@gmail.com</TableCell>
                                <TableCell align="right">Gold Prime</TableCell>
                                <TableCell align="right">Inactive</TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>

    )
}

export default CADashboard