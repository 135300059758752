import React from 'react';
import Subscription from '../../components/Subscriptions/Subscription';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';
import Auth from '../../modules/Auth';
const SubscriptionPage = () => {
  const isPaid = Auth.getUserIsPaid();
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
        {(sidebarOpen && isPaid === 1) && <Sidebar />}
        <div className={sidebarOpen && isPaid === 1 ? "contentWrap" : "contentWrap1"}>
                <Navbar />
            <div>
                <Subscription />
            </div>
        </div>
    </div>
  )
}

export default SubscriptionPage