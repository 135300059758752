//import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index';

let FixedIncomeLiveUrl = "http://5.189.157.40:10024/api";

const userID = Auth.getsubcriberId();

const addFixedIncome = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/FixedIncomeManagement/AddFixedIncomeDetails`,
        data: body
    });
}
const updateFixedIncome = async (body) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateFixedIncomeDetails`,
        data: body
    });
}

const FetchFDData = async () => {
    return axios({
        method: "GET",
        url: `${FixedIncomeLiveUrl}/FixedIncomeManagement/FetchFixedIncomeGrid?s_id=${userID}`
    })
}

const EntityWiseFIList = async (subId) => {
    return axios({
        method: 'GET',
        url: `${FixedIncomeLiveUrl}/FixedIncomeManagement/FetchIndvidualFixedIncomeGrid?sub_id=${subId}`
    })
}

const GetBuySellGridEntity = async (Instu_id, companyId,subId) => {
    return axios({
        method: 'GET',
        url: `${FixedIncomeLiveUrl}/FixedIncomeManagement/FetchFixedIncomeIndividualEntityInfo?insType_id=${Instu_id}&co_id=${companyId}&sub_id=${subId}`
    })
}

const GetBuySellGrid = async (subId, Instu_id) => {
    return axios({
        method: 'GET',
        url: `${FixedIncomeLiveUrl}/FixedIncomeManagement/FetchFixedIncomeEntityInfo?sub_id=${subId}&insTypeId=${Instu_id}`
    })
}
const GetFDTransaction = async (fdHeaderId) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10024/api/FixedIncomeManagement/GetFixedIncomeTransactionByID?headerid=${fdHeaderId}`
    })
}


const FetchBondDentureDetails = async (sub_id, co_id, bro_id, Instu_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/FetchDBDetails?sub_id=${sub_id}&co_id=${co_id}&bro_id=${bro_id}&instrumentId=${Instu_id}`
    })
}

const FetchCompanyDBData = async (sub_id, Instu_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/GetFixedIncomeCompanies?sub_id=${sub_id}&ins_id=${Instu_id}`
    })
}

const FetchFixedIncomeBroker = async (sub_id, Instu_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/FetchFixedIncomeBroker?sub_id=${sub_id}&ins_id=${Instu_id}`
    })
}
const PostBondRedeem = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/BondRedeemableAPI`,
        data: data,
    });
}
const PostDebentureRedeem = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/DebentureRedeemableAPI`,
        data: data,
    });
}
const PostBondsConvert = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/BondConvertibleAPI`,
        data: data,
    });
}

const PostDebentureConvert = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/DebentureConvertibleAPI`,
        data: data,
    });
}
const PostFDInterest = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddFixedDepositInterest`,
        data: data,
    })
}
const AvilableQuantity = async (subAccountId, companyId, brokerId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/FixedIncomeManagement/FixedIncomeRemainingQuantity?sub_id=${subAccountId}&co_id=${companyId}&bro_id=${brokerId}`
    })
}
const SelectionList = async (sub_id, bro_id, co_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/FetchFixedIncomeSelectionCompanyDetails?sub_id=${sub_id}&co_id=${co_id}&bro_id=${bro_id}`,
    });
}
const updateSelectionList = async (fi_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/SelectionDataForFixedIncomeUpdate?fi_id=${fi_id}`,
    });
}
const SellingCompanies = async (sub_id, bro_id, ins_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/FetchFixedIncomeCompanyByInsTypeId?sub_id=${sub_id}&bro_id=${bro_id}&insType_id=${ins_id}`,
    });
}
const deleteFixedIncome = async (fdId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/FixedIncomeManagement/DeleteFixedIncome?fdid=${fdId}`,
    })
}

const AddFixedDeposite = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddFixedDeposit`,
        data: data,
    });
}

const UpdateFixedDeposite = async (data) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateFixedDeposit`,
        data: data,
    });
}

const deleteFixedDeposite = async (fdId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/FixedIncomeManagement/RemoveFDDetails?fdid=${fdId}`,
    })
}
const getInterestData = async (headerid,Stocktype) => {
    return axios({
        method: "GET",
        url: `${FixedIncomeLiveUrl}/FixedIncomeManagement/GetFixedDepositInterest?headerid=${headerid}&transactstocktype=${Stocktype}`,
       // url:  `https://localhost:44307/api/FixedIncomeManagement/GetFixedDepositInterest?headerid=${headerid}&transactstocktype=${Stocktype}`
    });
}

const AddFDMaturity = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddFixedDepositMaturity`,
        data: data
    })
}
const deleteFixinterest = async (InterestID) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/RemoveFixedIncomeInterest?FiInterestId=${InterestID}`,
    });
}
const UpdatefixInterest = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateFixedDepositeInterest`,
        data: data
    })
}

const AddRecurringDeposit = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddFixedIncomeRD`,
        data: data,
    });
}

const UpdateRecurringDeposit = async (data) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateFixedIncomeRD`,
        data: data,
    });
}

const PostRDInterest_Insta = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddRecurringDepositeInterest`,
        data: data,
    })
}
const updateRDInterest_Insta = async (data) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateRecurringDepositeInterest`,
        data: data,
    })
}

const deleteRDInterest_Insta = async (InterestID) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/RemoveRecurringDepositeInterest?FiInterestId=${InterestID}`,
    });
}


const AddPostOffice = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/FixedIncomeManagement/AddDFixedIncomePostOffice`,
        data: data,
    });
}

const UpdatePostOffice = async (data) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateFixedIncomePostOffice`,
        data: data,
    });
}
const getMaturityDetails = async (headerid,fdid) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/FixedIncomeManagement/FetchMaturityDetails?headerId=${headerid}&fdid=${fdid}`,
    });
}
const UpdateMaturity = async (data) =>{
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/FixedIncomeManagement/UpdateMaturityDetails`,
        data: data
    })
}

const DeleteMaturity = async (tableid) => {
    return axios({
        method:"GET",
        url:`${settings.API_URL}/FixedIncomeManagement/DeleteMaturityDetails?tableId=${tableid}`
    })
}
// http://5.189.157.40:10024/api
// https://localhost:44307/api

const AddFIForOpeningBalance = async (data) => {
    return axios({
        method: "POST",
        url: `http://5.189.157.40:10024/api/FixedIncomeManagement/AddFIForOpeningBalance`,
        data: data,
    })
}

const DeleteFIRedeemConvert = async (fdHeaderId,fdId) => {
    return axios({
        method:"DELETE",
        url:`http://5.189.157.40:10024/api/FixedIncomeManagement/DeleteFIReedeemConvert?headerId=${fdHeaderId}&fdid=${fdId}`
    })
}

const GetFolioNoUnlisted = async (subAccountId, companyId,InstTypeID) => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10024/api/FixedIncomeManagement/FetchFixedIncomeFolioNumber?sub_id=${subAccountId}&co_id=${companyId}&insType_id=${InstTypeID}`
    })
}
export {
    addFixedIncome,
    updateFixedIncome,
    FetchBondDentureDetails,
    FetchCompanyDBData,
    FetchFixedIncomeBroker,
    PostBondRedeem,
    PostDebentureRedeem,
    PostBondsConvert,
    PostDebentureConvert,
    PostFDInterest,
    FetchFDData,
    AvilableQuantity,
    SelectionList,
    updateSelectionList,
    SellingCompanies,
    EntityWiseFIList,
    GetBuySellGrid,
    GetFDTransaction,
    deleteFixedIncome,
    GetBuySellGridEntity,
    AddFixedDeposite,
    UpdateFixedDeposite,
    getInterestData,
    deleteFixedDeposite,
    AddFDMaturity,
    deleteFixinterest,
    UpdatefixInterest,
    AddRecurringDeposit,
    UpdateRecurringDeposit,
    PostRDInterest_Insta,
    updateRDInterest_Insta,
    deleteRDInterest_Insta,
    AddPostOffice,
    UpdatePostOffice,
    getMaturityDetails,
    UpdateMaturity,
    DeleteMaturity,
    AddFIForOpeningBalance,
    DeleteFIRedeemConvert,
    GetFolioNoUnlisted
};