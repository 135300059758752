/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { AddFDMaturity, getMaturityDetails, GetFDTransaction, UpdateMaturity } from "../FDAPI";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Loader from "../../loadingAnimation/index";
import { GlobalInfo } from "../../Content/Content"
import Select from 'react-select'
import Cleave from 'cleave.js/react';

const Maturity = (props) => {
    const { showMaturity, onHideMaturity, FDHeaderId, FDiD, Buyfdid, UpdateFDiD, UpdateheaderId, Fname, InvDate } = props;
    const { Getdata } = useContext(GlobalInfo);
    const [closeValue, setcloseValue] = useState(Math.random());
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    
    //maturity
    const [mDate, setMDate] = useState(null);
    const [recAmount, setRecAmount] = useState({ recAmount: null });
    const [principal, setPrincipal] = useState({ principal: null });
    const [interest, setInterest] = useState({ interest: null });
    const [TDS, setTDS] = useState({ TDS: null });
    const [investdateupdate, setInvestdateupdate] = useState(null);
    const [mtype, setMtype] = useState([{ label: "Matured", value: "Matured" }, { label: "Pre-matured", value: "Pre-matured" }]);
    const [selMtype, setselMtype] = useState({ label: "Matured", value: "Matured" });

    

    useEffect(() => {
        if (UpdateFDiD === 0 || UpdateFDiD === undefined) {
            getMaturityDetail()
        }
    }, [FDHeaderId, FDiD, showMaturity]);
    useEffect(() => {
        if (Buyfdid !== undefined && showMaturity) {
            getUpdatetimeMaturityDetail()
            fetchDetails()
        }
    }, [UpdateheaderId, UpdateFDiD, showMaturity]);

    // fetch amount received and date update time
    const fetchDetails = async () => {
        setLoading(true)
        await GetFDTransaction(UpdateheaderId).then((res) => {
            // console.log(res,"get maturity date")
            let result = res.data._response.data.fixedincomeTrnsactionDetails.fdDetails[0]
            setRecAmount({ recAmount: result.maturityAmt })
            setMDate(new Date(result.domConversion))
            setInvestdateupdate(new Date(result.dateOfInvestment))
            setselMtype({ label: result.transactStockType, value: result.transactStockType })
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err, 'err in update time maturity fetch details')
        })
    }
    //getmaturity update time list
    const getUpdatetimeMaturityDetail = async () => {
        if (Buyfdid !== undefined && showMaturity) {
            setLoading(true)
            await getMaturityDetails(0, Buyfdid)
                .then((res) => {
                    if (res.status === 200) {
                        let data = res.data._response.data;
                        setPrincipal({ principal: data.principal });
                        setInterest({ interest: data.amtOfInterest });
                        setTDS({ TDS: data.tds });
                        setLoading(false)
                    } else {
                        // setError(res.data.message);
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setLoading(false)
                    console.log(e, "error in getMaturity ");
                });
        }
    };
    //getdividend list
    const getMaturityDetail = async () => {
        if (FDHeaderId !== 0 && showMaturity) {
            setLoading(true)
            await getMaturityDetails(FDHeaderId, FDiD)
                .then((res) => {
                    if (res.status === 200) {

                        let data = res.data._response.data;
                        setPrincipal({ principal: data.principal });
                        setInterest({ interest: data.amtOfInterest });
                        setTDS({ TDS: data.tds });
                        setLoading(false)
                    } else {
                        // setError(res.data.message);
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setLoading(false)
                    console.log(e, "error in getMaturity ");
                });
        }
    };



    //handlematuritydate
    const handleDateM = (selected) => {
        setMDate(selected);
        setFormErr({ ...formErr, mDate: "" });
    }
    //handlecompnay/bank
    const handleValueA = (e) => {
        const { name, rawValue } = e.target;
        setRecAmount({ ...recAmount, [name]: rawValue });
        setFormErr({ ...formErr, recAmount: "" })
    }
    //handleamountinvested
    const handleValueP = (e) => {
        const { name, rawValue } = e.target;
        setPrincipal({ ...principal, [name]: rawValue });
        setFormErr({ ...formErr, principal: "" })
    }
    //handleinterest
    const handleValueInt = (e) => {
        const { name, rawValue } = e.target;
        setInterest({ ...interest, [name]: rawValue });
        setFormErr({ ...formErr, interest: "" })
    }
    //handleinterest
    const handleValueTDS = (e) => {
        const { name, rawValue } = e.target;
        setTDS({ ...TDS, [name]: rawValue });
        setFormErr({ ...formErr, TDS: "" })
    }
    // handle maturity type 
    const handleMtype = (selected) => {
        setLoading(true)
        setselMtype(selected);
        setFormErr({ ...formErr, selMtype: "" })
        setLoading(false)
        setMDate(null);
    }

    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (!mDate) {
            isValid = false;
            formErr["mDate"] = "Please Select Date !";
        }
        if ((UpdateFDiD === 0 || UpdateFDiD === undefined) &&( selMtype.value === "Matured")) {
            if ((Number(principal.principal) + Number(interest.interest)) - Number(TDS.TDS) !== Number(recAmount.recAmount)) {
                isValid = false;
                formErr["recAmount"] = "Received amount not matched, please check!";
            }
        }

        if (recAmount.recAmount == null || recAmount.recAmount === "") {
            isValid = false;
            formErr["recAmount"] = "Please Enter Amount!";
        }
        if (principal.principal <= 0) {
            isValid = false;
            formErr["principal"] = "Please Enter Principal!";
        }
        if (interest.interest < 0) {
            isValid = false;
            formErr["interest"] = "Please Enter Interest!";
        }
        if (selMtype === '') {
            isValid = false;
            formErr["selMtype"] = "please Select Type!"
        }
        if (TDS.TDS < 0) {
            isValid = false;
            formErr["TDS"] = "Please Enter TDS!";
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let Mdata = {
                checkfdHeaderId: FDHeaderId,
                checkfdId: FDiD,
                domConversion: fixTimezoneOffset(mDate),
                maturityAmt: Number(recAmount.recAmount),
                principal: Number(principal.principal),
                interestReinvested: Number(interest.interest),
                tdSs: Number(TDS.TDS),
                dateOfInvestment: fixTimezoneOffset(InvDate),
                transactStockType: selMtype.value
            };
            AddFDMaturity(Mdata).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.message === "Maturity Added Successfully") {
                        swal(response.data._response.message, "", "success");
                    } else {
                        swal(response.data._response.message, "", "warning");
                    }
                    if (response.data._response.status === 1) {
                        closeallmodals();
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let Mdata = {
                fdId: UpdateFDiD,
                fdHeaderId: UpdateheaderId,
                domConversion: fixTimezoneOffset(mDate),
                maturityAmt: Number(recAmount.recAmount),
                principal: Number(principal.principal),
                interestReinvested: Number(interest.interest),
                tdSs: Number(TDS.TDS),
                dateOfInvestment: fixTimezoneOffset(investdateupdate),
                transactStockType: selMtype.value
            };
            UpdateMaturity(Mdata).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.message === "Maturity Updated Successfully") {
                        swal(response.data._response.message, "", "success");
                    } else {
                        swal(response.data._response.message, "", "warning");
                    }
                    if (response.data._response.status === 1) {
                        handleCloseModal();
                    }
                } else {
                    setLoading(false)
                    console.log("error in update");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in update");
                });
        }
    };
    //handleReset
    const handleReset = () => {
        setMDate(null);
        setRecAmount({ recAmount: null });
        setPrincipal({ principal: null });
        setInterest({ interest: null });
        setTDS({ TDS: null });
        setFormErr({});
        setLoading(false);
        setselMtype({ label: "Matured", value: "Matured" });
    }

    const handleCloseModal = () => {
        handleReset();
        onHideMaturity();
    }
    const closeallmodals = () => {
        Getdata(closeValue)
    }
    const disableButton = () => {
        return recAmount && principal && interest && TDS
    }

    function replaceNonNumeric(numStr) {

        return String(numStr).replace(/[^0-9]/g, '')

    }

    function commarize(numStr) {

        return Number(replaceNonNumeric(numStr)).toLocaleString()

    }
    return (
        <>
            <Modal show={showMaturity} onHide={() => handleCloseModal()} size="md" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>{Fname}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form  >
                            <Row>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Mature Type<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        name="MatureType"
                                        value={selMtype}
                                        onChange={handleMtype}
                                        options={mtype}
                                        isDisabled={Fname === "Update Maturity"}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selMtype}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Maturity<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        // maxDate={new Date()}
                                        popperPlacement="right-end"
                                        minDate={selMtype.value === "Pre-matured" ? null : new Date(InvDate)}
                                        onChange={handleDateM}
                                        dateFormat="dd-MM-yyyy"
                                        name="dateofmaturity"
                                        autoComplete="off"
                                        selected={mDate}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mDate}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-4">
                                    <Form.Label>Amount received<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="recAmount"
                                        min="0"
                                        value={recAmount.recAmount}
                                        onChange={handleValueA}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="recAmount"
                                        placeholder="Enter Amount"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={recAmount.recAmount}
                                        onChange={handleValueA}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.recAmount}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className=" col-md-4">
                                    <Form.Label>Principal</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="principal"
                                        min="0"
                                        value={NumberSeperator(principal.principal)}
                                        onChange={handleValueP}
                                        disabled
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="principal"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={principal.principal}
                                        onChange={handleValueP}
                                        disabled
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.principal}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className=" col-md-4">
                                    <Form.Label>Interest</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="interest"
                                        min="0"
                                        value={NumberSeperator(interest.interest)}
                                        onChange={handleValueInt}
                                        disabled
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="interest"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={interest.interest}
                                        onChange={handleValueInt}
                                        disabled
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.interest}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className=" col-md-4">
                                    <Form.Label>TDS</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="TDS"
                                        min="0"
                                        value={NumberSeperator(TDS.TDS)}
                                        onChange={handleValueTDS}
                                        disabled
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="TDS"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={TDS.TDS}
                                        onChange={handleValueTDS}
                                        disabled
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.TDS}
                                        </div>
                                    ) : null}
                                </Form.Group>

                            </Row>
                            <Row className="form-set">
                                <Col md="12" className="text-center mt-3">
                                    {UpdateFDiD === 0 || UpdateFDiD === undefined ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                            disabled={!disableButton()}>Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                            disabled={!disableButton()}>Update</Button>
                                    }
                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default Maturity