import React from 'react';
import EntityBrokerReportTable from '../../components/Report/EntityBrokerReportTable'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const EntityBrokerPage = () => {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
        {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar />
            <div>
               <EntityBrokerReportTable />
            </div>
        </div>
    </div>
  )
}

export default EntityBrokerPage