import React, { useState, useEffect } from "react";
import { Row, Modal } from "react-bootstrap";

import Paper from '@mui/material/Paper';
import { getTradingTransactionData } from "../../pages/Stocks/StocksAPI"
import MaterialTable from "material-table";
import moment from 'moment';



const TradingData = (props) => {

    const { showTrading, HideTrading } = props;
    const [TradingData, setTradingDatas] = useState([])
    const [loading, setLoading] = useState(false);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    // seperation by comma
    //   const NumberOnlySeperator = (input) => {
    //     return new Intl.NumberFormat('en-IN').format(input)
    //   }


    useEffect(() => {
        if (showTrading) {
            getTradingData()
        }
    }, [showTrading])

    const getTradingData = () => {
        setLoading(true)
        getTradingTransactionData().then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let TradingDatas = res.data._response.data.stockTragetStopLoss;
                    //  const tradingdata = TradingDatas.filter((a) => a.target !== null)
                    setTradingDatas(TradingDatas)
                    setLoading(false)
                } else {
                    // Swal.fire(res.data._response.message,"","error");
                    setLoading(false)
                }
            }

        }).catch((err) => {
            console.log(err)
        })
    }



    const columns = [
        {
            field: "subAccountName", title: "Entity", cellStyle: {
                width: "10px"
            }
        },
        {
            field: "companyName", title: "Company Name", cellStyle: {
                width: "10rem"
            }
        },
        {
            field: "brokerName", title: "Broker", cellStyle: {
                width: "7rem"
            }
        },
        {
            field: "date", title: "Date of Purchase", cellStyle: {
                width: "5px",
            },
            render: (rowData) =>
            (
                moment(rowData.date).format('DD/MM/YYYY')
            )
        },
        {
            field: "pricePerShare", title: "Purchase Price", cellStyle: {
                width: "5px",

            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.pricePerShare)}
                </span>
            ),
        },
        {
            field: "target", title: "Target", cellStyle: {
                width: "5px"
            },
            render: (rowData) => (
                <span>
                    {rowData.target === null ? 0 : NumberSeperator(rowData.target)}
                </span>
            ),
        },
        {
            field: "stopLoss", title: "Stoploss", cellStyle: {
                width: "5px"
            },
            render: (rowData) => (
                <span>
                    {rowData.stopLoss === null ? 0 : NumberSeperator(rowData.stopLoss)}
                </span>
            ),
        },
    ]
    return (
        <>
            <Modal show={showTrading} onHide={HideTrading} size="xl"
                id="modal2"
                backdrop="static"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Trading Data</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="UserdetWrap p-3 pb-0">
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <MaterialTable
                                    title=""
                                    data={TradingData}
                                    columns={columns}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: "#01579b",
                                            color: "#FFF",
                                        },
                                        exportButton: false,
                                        search: true,
                                        toolbarButtonAlignment: "right",
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50],
                                        emptyRowsWhenPaging: false,
                                        actionsColumnIndex: -1,

                                    }}
                                    isLoading={loading}
                                />
                            </Paper>
                        </div>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default TradingData;