/* eslint-disable import/no-anonymous-default-export */
import {
    SET_DETAILS,
    SET_ISTWOFACTOR_ENABLED
} from '../action/userData'

const initialState = {
    userDetails: {},
    twofactorEnabled: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DETAILS:
            return {
                ...state,
                userDetails: action.payload
            }
        case SET_ISTWOFACTOR_ENABLED:
            return {
                ...state,
                twofactorEnabled: action.payload
            }

        default:
            return state
    }
}


