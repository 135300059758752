import React, { useRef } from "react";
import ReactSelect from "react-select";

export const MultiSelectPlans = props => {
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    const selectAllPlansOption = {
        value: "<SELECT_ALL>",
        label: "All Plans"
      };

      ///jksjkjskdf
  const isSelectAllPlansSelected = () =>
  valueRef.current.length === props.options.length;

const isOptionPlansSelected = option =>
  valueRef.current.some(({ value }) => value === option.value) ||
  isSelectAllPlansSelected();

const getPlansOptions = () => [selectAllPlansOption, ...props.options];

const getPlansValue = () =>
isSelectAllPlansSelected() ? [selectAllPlansOption] : props.value;

const onPlansChange = (newValue, actionMeta) => {
  const { action, option, removedValue } = actionMeta;

  if (action === "select-option" && option.value === selectAllPlansOption.value) {
    props.onChange(props.options, actionMeta);
  } else if (
    (action === "deselect-option" &&
      option.value === selectAllPlansOption.value) ||
    (action === "remove-value" &&
      removedValue.value === selectAllPlansOption.value)
  ) {
    props.onChange([], actionMeta);
  } else if (
    actionMeta.action === "deselect-option" &&
    isSelectAllPlansSelected()
  ) {
    props.onChange(
      props.options.filter(({ value }) => value !== option.value),
      actionMeta
    );
  } else {
    props.onChange(newValue || [], actionMeta);
  }
};

return(
    <ReactSelect
    isOptionSelected={isOptionPlansSelected}
    options={getPlansOptions()}
    value={getPlansValue()}
    onChange={onPlansChange}
    hideSelectedOptions={false}
    closeMenuOnSelect={false}
    isMulti
    styles={{
    //   control: base => ({
    //   ...base,
    //   width: 200,
    //   whiteSpace: "nowrap"
    // }),
    // menu: base => ({ ...base, width: 200 }),
    valueContainer: base => ({
      ...base,
      // overflowX: "hidden",
      // display: "inline-block",
      maxHeight:'100px',
      overflowY:'auto'
    }),
    // input: base => ({
    //   ...base,
    //   display: "inline-block"
    // })
    }}
  />
)
}
