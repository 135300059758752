import React from "react";
import UserDetails from "../../../components/RoleMaster/Admin/UserDetails"
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useSelector } from 'react-redux';

const Userdetail = ()=>{
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return(
  
<div>
        {sidebarOpen && <Sidebar />}
     <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar />
         <div>
         <UserDetails/>
         </div>
     </div>
 </div>
   
        
            
    )
}

export default Userdetail;