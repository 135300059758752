/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form } from "react-bootstrap";
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import FormErrComponet from '../../CommonComponent/ErrorComponet';
import Loader from '../../loadingAnimation/index';
import { useLocation, useNavigate } from 'react-router-dom';
import Auth from '../../../modules/Auth';
import Swal from 'sweetalert2';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import { getCompanyListBySubscriberId, EntityList, generateReport } from '../../../pages/Report/ReportApi'
import { changePeriodList } from "../../../modules/helpers/constant"

const StockProfitabilityReport = () => {
  const userID = Auth.getsubcriberId();
  const registrationDate = Auth.getRegistrationDate();

  const navigate = useNavigate();
  const location = useLocation();
  const reportValue = location.state;

  const [loading, setLoading] = useState(true);
  const [formErr, setFormErr] = useState({});

  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [entityValue, setEntityVAlue] = useState([])

  const [companySel, setCompanySel] = useState([]);
  const [company, setCompany] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [changePeriodSel, setChangePeriodSel] = useState([])

  useEffect(() => {

    (async () => {
      await getCompanyDropDownData()
      await getEntityList()
  
    })();
  }, [])

  //getEntityList
  const getEntityList = async () => {
    setLoading(true)

    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                });
              });
            setEntity(entityData);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });


  };


  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityVAlue(selected.value)

    setFormErr({ ...formErr, selEntity: "" });
    //disableButton()

  };

  const handleClear = () => {
    setChangePeriodSel([])
    setFormErr({});
    setSelEntity([]);
    setCompanySel([]);
    setFromDate(null)
    setToDate(null)
  }

  const getCompanyDropDownData = async () => {
    setLoading(true);
    await getCompanyListBySubscriberId()
      .then((res) => {
        setLoading(false)
        if (res.data._response.status === 1) {
          let company = res.data._response.data.companyName;
          let companyData = [];
          company &&
            company.map((data) => {
              // check duplicate companies in companyData array
              let checkPresent = companyData.some(el => el.value === data.companyId)
              if (!checkPresent) {
                return (companyData.push({
                  value: data.companyId,
                  label: data.companyName,
                }))
              }
            });
          setCompany(companyData);
          setCompanySel(companyData)
          setLoading(false);
        } else {
          setCompany([]);
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "company list error");
      });
  };

  const reportData = 
    [
      {
        "companyId": 3711,
        "sellCompanyId": 3711,
        "companyName": "21ST CENTURY MGMT SERVICE-NSE-21STCENMGM",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 150,
        "sellQuantity": 150,
        "availableQuantity": 150,
        "sellAvailableQuantity": 150,
        "closingBalance": 1500,
        "sellClosingBalance": 1500,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 4500,
        "totalCost": 1500,
        "profitLoss": 3000,
        "sellProfitLoss": null,
        "date": "2022-10-01T15:19:46",
        "sellDate": "2022-10-11T10:16:25",
        "pricePerShare": 10,
        "sellPricePerShare": 30,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 1534,
        "sellStockId": 1559,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 3711,
        "sellCompanyId": 3711,
        "companyName": "21ST CENTURY MGMT SERVICE-NSE-21STCENMGM",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 150,
        "sellQuantity": 150,
        "availableQuantity": 150,
        "sellAvailableQuantity": 150,
        "closingBalance": 4500,
        "sellClosingBalance": 4000,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 4500,
        "totalCost": 4500,
        "profitLoss": 500,
        "sellProfitLoss": null,
        "date": "2022-10-02T15:20:15",
        "sellDate": "2022-10-11T10:16:25",
        "pricePerShare": 20,
        "sellPricePerShare": 30,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 1535,
        "sellStockId": 1559,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 3712,
        "sellCompanyId": 3712,
        "companyName": "3I INFOTECH-NSE-3IINFOLTD",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 80,
        "sellQuantity": 80,
        "availableQuantity": 20,
        "sellAvailableQuantity": 80,
        "closingBalance": 160,
        "sellClosingBalance": 800,
        "transactStockType": "Split",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 160,
        "totalCost": 160,
        "profitLoss": -640,
        "sellProfitLoss": null,
        "date": "2023-01-02T00:00:00",
        "sellDate": "2023-01-03T11:21:06",
        "pricePerShare": 10,
        "sellPricePerShare": 2,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 3627,
        "sellStockId": 3628,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 4144,
        "sellCompanyId": 4144,
        "companyName": "DONEAR IND.-NSE-DONEAR",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 60,
        "sellQuantity": 60,
        "availableQuantity": 40,
        "sellAvailableQuantity": 60,
        "closingBalance": 1200,
        "sellClosingBalance": 600,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 1200,
        "totalCost": 1200,
        "profitLoss": 600,
        "sellProfitLoss": null,
        "date": "2022-12-22T11:19:22",
        "sellDate": "2022-12-26T11:20:41",
        "pricePerShare": 10,
        "sellPricePerShare": 20,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 3420,
        "sellStockId": 3421,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 4144,
        "sellCompanyId": 4144,
        "companyName": "DONEAR IND.-NSE-DONEAR",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 30,
        "sellQuantity": 30,
        "availableQuantity": 10,
        "sellAvailableQuantity": 30,
        "closingBalance": 1200,
        "sellClosingBalance": 300,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 1200,
        "totalCost": 1200,
        "profitLoss": 900,
        "sellProfitLoss": null,
        "date": "2022-12-22T11:19:22",
        "sellDate": "2022-12-27T11:48:10",
        "pricePerShare": 10,
        "sellPricePerShare": 40,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 3420,
        "sellStockId": 3443,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 4144,
        "sellCompanyId": 4144,
        "companyName": "DONEAR IND.-NSE-DONEAR",
        "brokerId": 2,
        "sellBrokerId": 2,
        "brokerName": "4A Securities Limited",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 10,
        "sellQuantity": 10,
        "availableQuantity": 0,
        "sellAvailableQuantity": 10,
        "closingBalance": 100,
        "sellClosingBalance": 100,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 100,
        "totalCost": 100,
        "profitLoss": 0,
        "sellProfitLoss": null,
        "date": "2022-12-22T11:19:22",
        "sellDate": "2022-12-28T11:54:31",
        "pricePerShare": 10,
        "sellPricePerShare": 10,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 3420,
        "sellStockId": 3445,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      },
      {
        "companyId": 4144,
        "sellCompanyId": 4144,
        "companyName": "DONEAR IND.-NSE-DONEAR",
        "brokerId": 7,
        "sellBrokerId": 7,
        "brokerName": "123 Capitals",
        "subAccountId": 121,
        "sellSubAccountId": 121,
        "quantity": 150,
        "sellQuantity": 150,
        "availableQuantity": 0,
        "sellAvailableQuantity": 150,
        "closingBalance": 3750,
        "sellClosingBalance": 3000,
        "transactStockType": "Buy",
        "sellTransactStockType": "Sell",
        "active": null,
        "sellActive": null,
        "show": null,
        "sellShow": null,
        "sellTotalCost": 3750,
        "totalCost": 3750,
        "profitLoss": 750,
        "sellProfitLoss": null,
        "date": "2022-12-17T12:01:11",
        "sellDate": "2022-12-28T12:04:19",
        "pricePerShare": 20,
        "sellPricePerShare": 25,
        "brokeragePerShare": null,
        "sellBrokeragePerShare": null,
        "keys": null,
        "stockId": 3451,
        "sellStockId": 3452,
        "stockValuationId": null,
        "sellStockValuationId": null,
        "delete": null,
        "sellDelete": null
      }
    ]
  
  const errHandel = () => {
    let formErr = {};
    let isValid = true;

    if (companySel.length <= 0) {
      isValid = false;
      formErr["scrip"] = "Please select company!";
    }
    // if (reportValue === 1 && brokerSel.length <= 0) {
    //     isValid = false;
    //     formErr["Broker"] = "Please select broker!";
    // }
    if (selEntity.length <= 0) {
        isValid = false;
        formErr["entity"] = "Please select entity!";
    }
    setFormErr(formErr);
    return isValid;
  }
  // const handleSubmit = () => {
  //   let validate = errHandel();
  //   if (validate) {
  //     let Reports = {
  //       reportData
  //     }
  //     navigate('/ProfitabilityTable' , {state : Reports}) 
  //   }
  // }

  // for  date fix 
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  // normal report
  const handleSubmit = () => {

    let validate = errHandel();
    if (validate) {
      // const brokerId = [];
      const companyId = [];
      // const entityId = [];


      companySel.length > 0 && companySel.forEach((data, index) => {
        companyId.push(data.value);
      });

      const data = {
        // brokerId: reportValue === 1 ? brokerId : undefined,
        companyId: companyId,
        reportId: reportValue,
        instrumentId: 1,
        subAccountId:  selEntity.value,
        startDate: fixTimezoneOffset(fromDate),
        endDate: fixTimezoneOffset(toDate)

      }

      setLoading(true);
      generateReport(data).then(res => {
        setLoading(false);
        if (res.status === 200) {
          
          if (res.data._response.status === 1) {
            const report = res.data._response.data.stocksProbabilityReport;
    
            let Reports = {
              report, fromDate, toDate
            }

            reportValue === 7 ? navigate('/ProfitabilityTable', { state: Reports }) : navigate('/ProfitabilityfinalTable', { state: Reports })
          } else {
            Swal.fire("There is no data to display", "", 'info');
          }
        }
      }).catch(e => {
        setLoading(false);
        console.log(e, 'error in genrate report api');
      })

    }
  }

  const getCurrentFinancialYear = (strDocDate) => {

    var startYear = "";
    var endYear = "";
    var docDate = new Date(strDocDate);
    if ((docDate.getMonth() + 1) <= 3) {

      startYear = docDate.getFullYear() - 1;
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear();
      setToDate(new Date("31-Mar" + endYear))

    } else {
      startYear = docDate.getFullYear();
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear() + 1;
      setToDate(new Date("31-Mar" + endYear))
    }

    // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
  }

  const getLastFinancialYear = (strDocDate) => {

    var startYear = "";
    var endYear = "";
    var docDate = new Date(strDocDate);
    if ((docDate.getMonth() + 1) <= 3) {

      startYear = docDate.getFullYear() - 1;
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear();
      setToDate(new Date("31-Mar" + endYear))

    } else {
      startYear = docDate.getFullYear() - 1;
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear();
      setToDate(new Date("31-Mar" + endYear))
    }

    // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
  }

  const getPrvsToLastFinancialYear = (strDocDate) => {

    var startYear = "";
    var endYear = "";
    var docDate = new Date(strDocDate);
    if ((docDate.getMonth() + 1) <= 3) {

      startYear = docDate.getFullYear() - 2;
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear() - 1;
      setToDate(new Date("31-Mar" + endYear))

    } else {
      startYear = docDate.getFullYear() - 2;
      setFromDate(new Date("01-Apr" + startYear))

      endYear = docDate.getFullYear() - 1;
      setToDate(new Date("31-Mar" + endYear))
    }
    // console.log({ startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear }, "date")
    // return { startDate: "01-Apr-" + startYear, endDate: "31-Mar-" + endYear };
  }

  const handleChangePeriod = (selected) => {
    setChangePeriodSel(selected)
    setFromDate(null)
    setToDate(null)

    if (selected.value === 1) {

      getCurrentFinancialYear(new Date())

    } else if (selected.value === 2) {

      getLastFinancialYear(new Date())

    } else if (selected.value === 3) {

      getPrvsToLastFinancialYear(new Date())

    } else if (selected.value === 4) {

      let lastYear = new Date().getFullYear() + 1;
      let startYear = new Date(registrationDate)

      setToDate(new Date("31-Mar" + lastYear))
      setFromDate(startYear)
    }
  }

  return (
    <div className='mt-5 pt-3'>
      <div className='container border p-5 shadow mt-0 mb-0 bg-white rounded col-md-5 col-sm-12 h-50'>
        <div className='mb-3 p-1'>
          <h3 className='text-center'>{reportValue === 7 ? "Stocks profit loss base report" : "Stocks profit loss final report"}</h3>
        </div>
     
        <Form className='mt-2'>
          {/* {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
          <Row className="form-set row justify-content-md-center">
            <Form.Group className="mt-4 mb-2 col-md-6 col-sm-10">

              <Form.Label>Company Name</Form.Label>
              <MultiSelect options={company} value={companySel} onChange={setCompanySel} />
              {formErr ? <FormErrComponet formErr={formErr.scrip} /> : null}
            </Form.Group>
            {
              reportValue === 7 ? 
              <Form.Group className="mt-4 mb-2 col-md-6 col-sm-10">
                <Form.Label>Entity</Form.Label>
                <Select
                  value={selEntity}
                  onChange={handleFamily}
                  options={Entity}
                  name="subAccountId"
                  isOptionDisabled={(option) => option.disabled === 0}
                />
                {formErr ? (
                  <div
                    className="mt-2"
                    style={{ fontSize: 12, color: "red" }}
                  >
                    {formErr.entity}
                  </div>
                ) : null}
              </Form.Group> : null
            }
          
            </Row>
            
          <Row className="form-set row">
            {

              <>
                <Form.Group className="mt-4  col-md-4 col-sm-4">
                  <Form.Label>Change Period</Form.Label>
                  <Select options={changePeriodList} value={changePeriodSel} onChange={handleChangePeriod} />
                  {formErr ? <FormErrComponet formErr={formErr.period} /> : null}
                </Form.Group>
                {
                  changePeriodSel.value === 5 ?
                    <>
                      <Form.Group className="mt-4  col-md-4 col-sm-4">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          // maxDate={new Date()}
                          popperPlacement="bottom"
                          // minDate={}
                          onChange={(date) => setFromDate(date)}
                          dateFormat="dd-MM-yyyy"
                          name="dateofinv"
                          autoComplete="off"
                          selected={fromDate}
                        />
                      </Form.Group>
                      <Form.Group className="mt-4  col-md-4 col-sm-4">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          // maxDate={new Date()}
                          popperPlacement="bottom"
                          minDate={fromDate}
                          onChange={(date) => setToDate(date)}
                          dateFormat="dd-MM-yyyy"
                          name="dateofinv"
                          autoComplete="off"
                          selected={toDate}
                        />
                      </Form.Group>
                    </> :
                    <>
                      <Form.Group className="mt-4  col-md-4 col-sm-4">
                        <Form.Label>From</Form.Label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          // maxDate={new Date()}
                          popperPlacement="bottom"
                          // minDate={}
                          // onChange={(date) => setFromDate(date)}
                          dateFormat="dd-MM-yyyy"
                          name="dateofinv"
                          autoComplete="off"
                          selected={(fromDate)}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mt-4  col-md-4 col-sm-4">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          // maxDate={new Date()}
                          popperPlacement="bottom"
                          // minDate={}
                          // onChange={(date) => setToDate(date)}
                          dateFormat="dd-MM-yyyy"
                          name="dateofinv"
                          autoComplete="off"
                          selected={toDate}
                          disabled
                        />
                      </Form.Group>
                    </>
                }
              </>
            }
          </Row>
          <Row >
            <Col md="12" className="text-center mt-2">
              <Button style={{ width : "12rem" }} variant="primary" className='btn btn-sm ' id="submitButton" onClick={() => { handleSubmit(); }} disabled={loading}>
                {loading && <span style={{ marginLeft: "-2rem", position: 'absolute' }}> <span className="spinner-border spinner-border-sm p-2 letter-spacing: 0.02857em;" role="status" aria-hidden="true" style={{ paddingLeft: 5 }}></span></span>}
                Generate Report
              </Button>
              {/* <Button variant="primary" className='btn btn-sm' id="submitButton" onClick={() => { handleSubmit(); }} disabled={loading}>Generate Report</Button> */}
              <Button variant="secondary" className='btn btn-sm w100 mx-2' id="submitButton" onClick={() => { handleClear(); }}>Clear</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default StockProfitabilityReport