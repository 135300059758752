import React from 'react'
import EntityBrokerHoldingReport from '../../components/Report/EntityBrokerHoldingReport';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const EntityBrokerHoldPage = () => {
    const sidebarOpen = useSelector(res => res.sidebarReducer.openclosesidebar);
  return (
      <div>
          {sidebarOpen && <Sidebar />}
          <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
              <Navbar />
              <div>
                  <EntityBrokerHoldingReport />
              </div>
          </div>
      </div>
  )
}

export default EntityBrokerHoldPage