/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { brokerList, CompanyList, EntityList, addIpoStocks, addUnlistedScrip, updateIPO } from '../../../pages/Stocks/StocksAPI';
import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
import Swal from 'sweetalert2'
import Loader from '../../loadingAnimation/index'
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';

const IPOShare = (props) => {
    const { showI, onHideI, stockHeaderId, stockTransactionI, stockId, showIPOSharePreview } = props;
    // console.log(stockHeaderId,stockId,"porops");
    const handleClose = (val) => {
        onHideI(val);
        // navigate("/Stock");
        handleReset();
    }

    const navigate = useNavigate();

    const [brokerData, setBrokerList] = useState([]);
    const [broker, setbroker] = useState([])
    const [companyList, setCompanyList] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [listDate, setListDate] = useState(null);
    const [amount, setAmount] = useState(0);
    const [formErr, setFormErr] = useState({});
    const [form, setForm] = useState({});
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false);
    // const [successMessage, setSuccessMessage] = useState({});
    const [Value, setValue] = useState({ quantity: null, price: null })
    const [showModal, setShowModal] = useState(false);
    const [unlistedComp, setUnlistedComp] = useState({ companyName: null });
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    const [entityValue, setEntityValue] = useState([])
    const [ratio, setRatio] = useState('')
    const [allotCheck, setAllotCheck] = useState(false)
    const [transactiontype, setTransactiontype] = useState('');

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEffect(() => {
        if (showI) {
            (async () => {
                await getBrokerList();
                await getCompanyList();

                await getEntityListt();
            })();
        }
    }, [showI])

    useEffect(() => {
        if (Value.quantity > 0 && Value.price > 0) {
            handleAmount()
        } else {
            setAmount(0)
        }
    }, [Value]);
    // edit bonus data
    useEffect(() => {

        if (stockId !== 0) {
            setLoading(true)
            if (stockTransactionI !== undefined) {
                setSelBroker({
                    value: stockTransactionI.brokerId,
                    label: stockTransactionI.brokerName,
                });
                setbroker(stockTransactionI.brokerId);
                setSelEntity({
                    value: stockTransactionI.subAccountId,
                    label: stockTransactionI.subAccountName,
                });
                setEntityValue(stockTransactionI.subAccountId);
                setValue({ quantity: stockTransactionI.quantity, price: stockTransactionI.pricePerShare })
                setPDate(new Date(stockTransactionI.date));
                setAmount({ totalCost: stockTransactionI.totalCost })
                setSelScrip({ label: stockTransactionI.companyName, value: stockTransactionI.companyId, companyType: stockTransactionI.instrumentTypeId })
                // setSelScrip(t.company, { companyType: t.instrumentTypeId })
                setRatio(stockTransactionI.ratio)
                setLoading(false)
            }

        }
        if ((stockHeaderId !== 0 && showI === true) || (showIPOSharePreview === true)) {
            setLoading(true)
            if (stockTransactionI !== undefined) {
                setSelBroker({
                    value: stockTransactionI.brokerId,
                    label: stockTransactionI.broker.brokerName,
                });
                setbroker(stockTransactionI.brokerId);

                setSelEntity({
                    value: stockTransactionI.subAccountId,
                    label: stockTransactionI.subAccount.subAccountName,
                });

                setEntityValue(stockTransactionI.subAccountId);

                stockTransactionI.stockDetails && stockTransactionI.stockDetails.map((t) => {
                    setValue({ quantity: t.quantity, price: t.pricePerShare })
                    setPDate(new Date(t.date));
                    setAmount({ totalCost: t.amount })
                    setSelScrip(t.company, { companyType: t.instrumentTypeId })
                    setRatio(t.ratio)
                    setTransactiontype(t.transactStockType)
                    setLoading(false)
                })

            }
        }
    }, [stockTransactionI, stockHeaderId, stockId, showI, showIPOSharePreview])

    const handleOpenModal = () => setShowModal(!showModal);

    const handleAmount = () => {
        const total = Number(Value.quantity) * Number(Value.price);
        setAmount(total);
    }
    const getBrokerList = async () => {
        setLoading(true)
        await brokerList().then(res => {
            // setLoading(false)
            if (res.status === 200) {
                let brokers = res.data._response.data.brokerDetails;
                let brokersData = [];
                brokers && brokers.map(data => {
                    return (brokersData.push({ value: data.brokerId, label: data.brokerName }))
                })
                setBrokerList(brokersData);
                setLoading(false)
            } else {
                // setLoading(false)
                setError(res.data.message)
                setLoading(false)
            }
        }).catch(e => {
            setError(e.message)
            setLoading(false)
        })
    }
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {

                if (res.status === 200) {
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                          return  ( entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                    setLoading(false)
                } else {
                    // setError(res.data.message);
                    setLoading(false)
                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)
            });


    };




    //getcompanylist by group
    const getCompanyList = async (unListedParam) => {
        setLoading(true)
        await CompanyList()
            .then((res) => {
                if (res.status === 200) {
                    //
                    setLoading(false)
                    let UnlistedCompany = [];
                    let ListedCompany = [];

                    let company = res.data._response.data.companyDetails;
                    company &&
                        company.filter(data => data.instrumentTypeId === 1).map(i => {
                            return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });
                    company &&
                        company.filter(data => data.instrumentTypeId === 10).map(i => {
                            return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });
                    if (unListedParam === 1) {
                        let lastElement = UnlistedCompany[UnlistedCompany.length - 1];
                        setSelScrip(lastElement)
                    }
                    const groupedOptions = [
                        {
                            label: 'Listed Companies',
                            options: ListedCompany,
                        },
                        {
                            label: 'UnListed Companies',
                            options: UnlistedCompany,
                        },
                    ];
                    setCompanyList(groupedOptions);
                    setLoading(false)

                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, 'error in companyList');
                // setError(e.message);
            });
    };

    const handlevalue = (e) => {
        let { name, rawValue, min, max } = e.target
        if (name === 'quantity' && rawValue !== null) {
            rawValue = Math.max(Math.min(Number(max), Number(rawValue)));
        }
        setValue({ ...Value, [name]: rawValue });
        if (name === "quantity" && rawValue !== null) {
            setFormErr({ ...formErr, quantity: '' })
        } else if (name === "price" && rawValue !== null) {
            setFormErr({ ...formErr, price: '' })
        }
    }

    const handleScrip = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: '' });
    }
    const handleFamily = (selected) => {
        setSelEntity(selected);
        setEntityValue(selected.value)
        setFormErr({ ...formErr, selEntity: '' });
    }
    const handleBroker = (selected) => {
        setSelBroker(selected);
        setbroker(selected.value)
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: '' });
    }
    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    }
    const handleDateOfListing = (date) => {
        setListDate(date);
        setFormErr({ ...formErr, dateList: '' });
    }

    const handleRatio = (e) => {
        setRatio(e.target.value.replace(/[^0-9:]/ig, ''))
    }

    const handleCheckbox = (e) => {
        setAllotCheck(e.target.checked);
        if (e.target.checked === false) setListDate(null);

    }

    //handel disable listed companies
    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select Scrip!";
        }
        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }
        if (!Value.price || Value.price === 0) {
            isValid = false;
            formErr["price"] = "Please Fill Price!";
        }
        if (!Value.quantity || Value.quantity === 0) {
            isValid = false;
            formErr["quantity"] = "Please Fill Quantity!";
        }
        // if (Value.quantity) {
        //     let qvalue = parseFloat(Value.quantity); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["quantity"] = "Please enter only integer value !";
        //     }
        //   }

        if (stockId && allotCheck) {
            if (listDate === null) {
                isValid = false;
                formErr["dateList"] = "Please Fill Date Of Listing!";
            }
        }
        setFormErr(formErr);
        return isValid;
    }
    const handleSubmit = async (e) => {
        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            let data = {
                date: fixTimezoneOffset(pDate),
                "BrokerId": selBroker.value,
                "SubAccountId": selEntity.value,
                "CompanyId": selScrip.value,
                "PricePerShare": parseInt(Value.price),
                "Quantity": parseInt(Value.quantity),
                "TotalCost": amount,
                ratio: ratio,
                instrumentTypeId: selScrip.companyType
            };
            await addIpoStocks(data).then(res => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {

                        setSuccess(true);
                        Swal.fire("IPO Share Added successfully!", "", 'success');
                        handleReset();
                        setRatio('');
                        setValue({ quantity: 0, price: 0 })
                        // onRefresh();
                        navigate(`/AllotedIPOs`);
                        handleClose();
                    } else {
                        Swal.fire("Something went wrong", "", "warning");
                    }

                } else {
                    setLoading(false)
                }
            }).catch(e => {
                console.log(e);

                setError(e.message)
                setLoading(false)
            })
        }
    }
    const handleUpdate = (e) => {
        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            let companyType
            ( allotCheck === true && selScrip.companyType === 10) ? companyType = 1 : companyType = selScrip.companyType;
            const Sdata = {
                //for stock table update
                stockHeaderId: stockHeaderId,
                stockId: stockId,
                //for unlisted ipo table update
                brokerId: selBroker.value,
                date: fixTimezoneOffset(pDate),
                subAccountId: selEntity.value,
                id: transactiontype === "IPO" ? 0 : stockId,
                quantity: parseInt(Value.quantity),
                companyId: selScrip.value,
                pricePerShare: parseInt(Value.price),
                totalCost: amount,
                ratio: ratio,
                instrumentTypeId: companyType,
                active: allotCheck === true ? 1 : 0,
                dateOfListing: allotCheck === true ? fixTimezoneOffset(listDate) : null
            };
            updateIPO(Sdata)
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            setSuccess(true);
                            Swal.fire(response.data._response.message, "", "success");
                            setRatio('');
                            handleClose();
                            //onRefresh();
                            if (allotCheck === true) navigate('/Stock');
                        } else {
                            Swal.fire(response.data._response.message, "", "warning");
                        }
                    } else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in update");
                });
        }

    };
    const handleReset = () => {
        setSelScrip({ companyName: "" })
        setSelBroker([])
        setSelEntity([])
        setAmount(0)
        setValue({ quantity: null, price: null })
        setPDate(null)
        setForm([]);
        setFormErr({});
        setRatio('');
        setListDate(null)
        setAllotCheck(false);
        setTransactiontype('');
    }

    const handleUnlisted = (e) => {
        const { name, value } = e.target;
        setUnlistedComp({ ...unlistedComp, [name]: value });
        setUnlistedCompErr('')
    }

    const submitUnlisted = (e) => {
        e.preventDefault();
        if (unlistedComp.companyName !== '' && unlistedComp.companyName !== null && unlistedComp.companyName !== undefined) {
            unlistedComp.companyName.trim()
            let result = unlistedComp.companyName.replace(/^\s+|\s+$/gm, '');
            let data = {
                "companyName": result,
                "InstrumentTypeId": 10
            }
            addUnlistedScrip(data)
                .then(res => {
                    setLoading(false)
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            const lastEle = 1
                            getCompanyList(lastEle);
                            setUnlistedComp('');
                            setUnlistedCompErr('');
                            handleCloseModal()
                            Swal.fire(res.data._response.message, "", 'success');
                        } else {
                            Swal.fire(res.data._response.message, '', 'info')
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setError(e.message)
                    setLoading(false)
                })

        } else {
            setUnlistedCompErr("Company Name Required!")
        }
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }
    const Control = ({ children, ...props }) => (

        <components.Control {...props}>
            <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    )

    const disableButton = () => {
        return pDate && selEntity &&
            selScrip && selBroker && Value && amount

    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }
    return (
        <>
            <Modal show={showI} onHide={handleClose} size="lg" backdrop="static" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>IPO</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
                    <Form className="border border-1 rounded p-3" >
                        <Row className="form-set">


                            <Form.Group className="col-md-4" >
                                <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                <Select
                                    value={selEntity}
                                    onChange={handleFamily}
                                    options={Entity}
                                    name="subAccountId"
                                    isDisabled={stockId}
                                    isOptionDisabled={(option) => option.disabled === 0}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selEntity}</div> : null}
                            </Form.Group>



                            <Form.Group className="col-md-4" >
                                <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                <Select
                                    value={selBroker}
                                    onChange={handleBroker}
                                    options={brokerData}
                                    name="brokerId"
                                    isDisabled={stockId}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selBroker}</div> : null}
                            </Form.Group>


                            <Form.Group className="col-md-4" >
                                <Form.Label>Scrip <span className="text-danger h6">*</span></Form.Label>

                                <Select
                                    components={stockId ? null : { Control }}
                                    value={selScrip}
                                    onChange={handleScrip}
                                    options={companyList}
                                    name="companyId"
                                    isDisabled={stockId}
                                    isOptionDisabled={handelDisableDD}
                                    styles={{
                                        option: (data) => ({
                                            ...data,
                                            margin: 0,
                                            padding: '3px 12px',
                                        }),
                                        groupHeading: (base) => ({
                                            ...base,
                                            flex: '1 1',
                                            color: '#000',
                                            margin: 0,
                                            padding: '7px 2px',
                                            // border: `2px solid `,
                                            background: "#8bc3ff",
                                            fontSize: 'small',
                                            fontWeight: 500
                                        }),
                                    }}
                                />
                                {formErr ? (
                                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                        {formErr.selScrip}
                                    </div>
                                ) : null}
                            </Form.Group>



                        </Row>

                        <Row className="form-set">

                            <Form.Group className="col-md-4">
                                <Form.Label>Date of {stockHeaderId === undefined && stockId === undefined ? 'Allotment' : stockHeaderId === 0 && stockId !== 0 ? 'Allotment' : 'Listing'}<span className="text-danger h6">*</span></Form.Label>
                                <DatePicker className="form-control"
                                    placeholderText="Select Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    // maxDate={new Date()}
                                    onChange={handleDate}
                                    dateFormat="dd-MM-yyyy"
                                    name="date"
                                    autoComplete='off'
                                    selected={pDate}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.date}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label> Ratio </Form.Label>
                                <Form.Control type="text" name="amount" min="0" placeholder="Enter Ratio" onChange={handleRatio} value={ratio} autoComplete="off" />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.ratio}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label>Quantity<span className="text-danger h6">*</span></Form.Label>
                                {/* <Form.Control type="Number" name="quantity" onChange={handlevalue} value={Math.round(Value.quantity)} min="0" max="10000" placeholder="Enter quantity" /> */}
                                <Cleave
                                    min="0" max="10000" 
                                    placeholder="Enter Quantity"
                                    className='form-control'
                                    name="quantity"
                                    autoComplete='off'
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    onChange={handlevalue} 
                                    value={Math.round(Value.quantity)}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.quantity}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label>Price<span className="text-danger h6">*</span></Form.Label>
                                {/* <Form.Control type="Number" name="price" onChange={handlevalue} value={Value.price} min="0" placeholder="Enter price" /> */}
                                <Cleave
                                    min="0" 
                                    placeholder="Enter Price"
                                    className='form-control'
                                    name="price"
                                    autoComplete='off'
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    onChange={handlevalue} 
                                    value={Value.price}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.price}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label>Amount </Form.Label>
                                {/* <Form.Control type="text" name="amount" disabled
                                    min="0" value={NumberSeperator(amount)} placeholder="Enter scrip name" /> */}
                                <Cleave
                                    className='form-control'
                                    name="amount"
                                    placeholder="Enter Amount"
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    value={NumberSeperator(amount)}
                                    disabled
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.amount}</div> : null}
                            </Form.Group>

                        </Row>
                        <hr></hr>
                        <Row className='mt-4'>
                            {
                                stockId === 0 || (stockId === undefined && stockHeaderId !== 0) ? null : stockId !== 0 && stockHeaderId !== 0 ? null : <>
                                    <Form.Group className="mt-4 col-md-4" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Allotment of Listing" value={allotCheck} onChange={handleCheckbox} />
                                    </Form.Group>

                                    <Form.Group className="col-md-4">
                                        <Form.Label>Date of Listing</Form.Label>
                                        <DatePicker className="form-control"
                                            placeholderText="Select Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            // maxDate={new Date()}
                                            minDate={pDate}
                                            onChange={handleDateOfListing}
                                            dateFormat="dd-MM-yyyy"
                                            name="date"
                                            autoComplete='off'
                                            selected={listDate}
                                            disabled={!allotCheck}
                                        />
                                        {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.dateList}</div> : null}
                                    </Form.Group>

                                </>
                            }
                        </Row>
                        <Row>
                            <Col md="12" className="text-center mt-3">
                                {stockId === 0 || stockId === undefined ? (

                                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }} disabled={!disableButton()}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()}>
                                        Update
                                    </Button>
                                )}
                                {stockId === 0 || stockId === undefined ?
                                    <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>Clear
                                    </Button> : null}
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <>
                <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Unlisted Scrip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Company Name</label>
                                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp.companyName} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
            {showIPOSharePreview === true ?
                <Modal show={showIPOSharePreview} onHide={() => handleClose(1)}>
                    <Modal.Header closeButton className="headerBlue" >
                        <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Stock IPO Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date : </div>
                                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Ratio : </div>
                                <div className="transactionDataValue">{ratio}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Quantity : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(Value.quantity)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Price : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Value.price)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount)}</div></div>
                        </div>
                    </Modal.Body>
                </Modal> : null}
        </>
    )
}
export default IPOShare;