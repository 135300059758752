/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { AddPremiumMaturity, UpdatePremiumMaturity, InsuranceTransaction } from "../../../pages/Insurance/InsuranceAPI";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import { GlobalInfo } from "../../Content/Content"
import Cleave from 'cleave.js/react';

const InsuranceMaturity = (props) => {
    const { showIMaturity, OnhideImaturity, Fname, InsuranceId, matDate, updateTableId } = props;
    const { GetInsdata } = useContext(GlobalInfo);
    const [closeValue, setcloseValue] = useState(Math.random());
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    //maturity
    const [mDate, setMDate] = useState(null);
    const [MaturityAmount, setMaturityAmount] = useState('');
    const [NetAmount, setNetAmount] = useState('');
    const [TDS, setTDS] = useState('');

    useEffect(() => {
        if (updateTableId !== undefined && updateTableId !== 0) {
            getUpdateDatabyID(updateTableId)
        }
    }, [updateTableId]);

    useEffect(() => {
        HandleAmount();
    }, [MaturityAmount, TDS])

    const HandleAmount = () => {
        let TotalAmount = Number(MaturityAmount).toFixed(2) - Number(TDS).toFixed(2)
        setNetAmount(TotalAmount.toFixed(2));
    }

    const getUpdateDatabyID = (id) => {
        setLoading(true);
        InsuranceTransaction(id).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let data = res.data._response.data.insuranceTransactionDetails
                    setMDate(new Date(data.maturityDate));
                    setMaturityAmount(data.maturityValue);
                    setNetAmount(data.netAmt);
                    setTDS(data.tds);
                }
            }
        }).catch((e) => {
            console.log(e, 'err in insurance maturity');
            setLoading(false);

        })
    }

    //handlematuritydate
    const handleDateM = (selected) => {
        setMDate(selected);
        setFormErr({ ...formErr, mDate: "" });
    }
    //handleMAturity amt
    const handleValueA = (e) => {
        const { rawValue } = e.target;
        setMaturityAmount(rawValue);
        setFormErr({ ...formErr, MaturityAmount: "" })
    }
    const handleValueNetA = (e) => {
        const { rawValue } = e.target;
        setNetAmount(rawValue);
        setFormErr({ ...formErr, NetAmount: "" })
    }

    //handleTDS
    const handleValueTDS = (e) => {
        const { rawValue } = e.target;
        setTDS(rawValue);
        setFormErr({ ...formErr, TDS: "" })
    }


    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (!mDate) {
            isValid = false;
            formErr["mDate"] = "Please select maturity Date !";
        }
        if (MaturityAmount === '') {
            isValid = false;
            formErr["MaturityAmount"] = "Please Enter Amount!";
        }
        if (Number(TDS) < 0 || TDS === '') {
            isValid = false;
            formErr["TDS"] = "Please Enter TDS!";
        }
        if (Number(NetAmount) < 0 || NetAmount === '') {
            isValid = false;
            formErr["NetAmount"] = "Please Enter Net Amount!";
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let Mdata = {
                refId: InsuranceId,
                netAmt: Number(NetAmount),
                tds: Number(TDS ? TDS : 0),
                maturityValue: Number(MaturityAmount),
                maturityDate: fixTimezoneOffset(mDate),
                transactStockType: "Matured"//matured
            };
            AddPremiumMaturity(Mdata).then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    if (response.data._response.status === 1) {
                        closeallmodals();
                        Swal.fire("Maturity Added Successfully", "", "success");
                    } else {
                        Swal.fire(response.data._response.message, "", "warning");
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let Mdata = {
                insuranceId: updateTableId,
                netAmt: Number(NetAmount),
                tds: Number(TDS ? TDS : 0),
                maturityValue: Number(MaturityAmount),
                maturityDate: fixTimezoneOffset(mDate),
                transactStockType: "Matured"
            };
            UpdatePremiumMaturity(Mdata).then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data._response.status === 1) {
                        Swal.fire("Maturity Updated Successfully", "", "success");
                    } else {
                        Swal.fire(response.data._response.message, "", "warning");
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in update");
                });
        }
    };
    //handleReset
    const handleReset = () => {
        setMDate(null);
        setMaturityAmount('');
        setNetAmount('');
        setTDS('');
        setFormErr({});
        setLoading(false);
    }

    const handleCloseModal = () => {
        handleReset();
        OnhideImaturity();
    }
    const closeallmodals = () => {
        GetInsdata(closeValue)
    }
    const disableButton = () => {
        return mDate && MaturityAmount
    }
    return (
        <>
            <Modal show={showIMaturity} onHide={() => handleCloseModal()} size="md" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>{Fname}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form  >
                            <Row>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Maturity<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        // maxDate={new Date()}
                                        popperPlacement="right-end"
                                        minDate={new Date(matDate)}
                                        onChange={handleDateM}
                                        dateFormat="dd-MM-yyyy"
                                        name="dateofmaturity"
                                        autoComplete="off"
                                        selected={mDate}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mDate}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-4">
                                    <Form.Label>Maturity Amount<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="MaturityAmount"
                                        min="0"
                                        value={MaturityAmount}
                                        onChange={handleValueA}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="MaturityAmount"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={MaturityAmount}
                                        onChange={handleValueA}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.MaturityAmount}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className=" col-md-4">
                                    <Form.Label>TDS<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="TDS"
                                        min="0"
                                        value={TDS}
                                        onChange={handleValueTDS}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="TDS"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={TDS}
                                        onChange={handleValueTDS}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.TDS}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Net Amount<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="NetAmount"
                                        min="0"
                                        value={NetAmount}
                                        onChange={handleValueNetA}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="NetAmount"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={NetAmount}
                                        onChange={handleValueNetA}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.NetAmount}
                                        </div>
                                    ) : null}
                                </Form.Group>

                            </Row>
                            <Row className="form-set">
                                <Col md="12" className="text-center mt-3">
                                    {updateTableId === 0 || updateTableId === undefined ? <>                                    <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                        disabled={!disableButton()}>Submit</Button>
                                        <Button
                                            variant="secondary"
                                            className="mx-2 w100"
                                            type="reset"
                                            onClick={handleReset}
                                        >
                                            Clear
                                        </Button>
                                    </>
                                        :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                            disabled={!disableButton()}>Update</Button>
                                    }

                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default InsuranceMaturity