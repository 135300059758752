import React from 'react'
import {Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Progressbar from 'react-js-progressbar';

const secondary_pri = {
   backgroundColor:"#318ce7",
   width:"8rem",
   height:"3rem"
}


const ProfileCompletion = (props)=>{
    const navigate = useNavigate();
    const { showprofile, onhide,profileValue} = props;


    const handleskip = () => {
        onhide()
        localStorage.setItem('profile_pop', 1);
        // navigate("/dashboard");
    }
    const handleSubscribe = () => {
        onhide()
        navigate("/profile");
        localStorage.setItem('profile_pop', 1);
        
    }
    
   
    return(
        <>
            <Modal show={showprofile} onHide={onhide} size="md" style={{ paddingTop: "10rem", paddingLeft: "10rem" }} backdrop="static">
                <div class="modal-dialog  modal-dialog-centered justify-content-center " role="document">
                    <div class="modal-content  border-0 mx-3">
                        <div class="modal-body  p-0">
                            <div class="card text-center">
                                <h3>Complete Your Profile</h3>
                                <div id='progressbarContainer' className="progressbarsize">
                                    <Progressbar
                                        input={profileValue}
                                        pathWidth={5}
                                        pathColor={['#56ab2f', '#a8e063']} // use an array for gradient color.
                                        trailWidth={10}
                                        trailColor='#363636' // use a string for solid color.
                                        textStyle={{ fill: 'red' }} // middle text style
                                    >
                                    </Progressbar>
                                </div>

                                {/* <img src={profileImg} alt="subscribe" class="img-fluid mx-auto d-block" style={{height : "200px" ,width : "200px",alignContent:"center"}}/>  */}
                                <h3 style={{ color: "Black" }}>Your Profile Completion is at {profileValue}%</h3>
                                
                                
                                <div class="card-body px-sm-5 mb-5">
                                    <p class="text-muted mb-0">In order complete your profile, Please provide us with the required information.</p>
                                    <div class="row justify-content-center mt-4 px-sm-5  ">
                                    
                                    {profileValue === 100 ? null:
                                     <>
                                        <div class="col">
                                            
                                            <button type="submit"
                                                onClick={() => { handleSubscribe(); }}
                                                class="btn btn-secondary-pri btn-block  font-weight-bold text-dark text-uppercase" style={secondary_pri} >
                                                <span className="planbtn">Profile</span></button></div>
                                        <div class="col">
                                            <button type="button"
                                                onClick={() => { handleskip(); }}
                                                class="btn btn-secondary_skip btn-block  text-dark font-weight-bold text-uppercase" style={secondary_pri}>
                                                <span className="planbtn">SKIP</span></button></div>
                                                 </>}
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ProfileCompletion