import React from "react";

import {Navigate,Outlet} from 'react-router-dom'

import Auth from '../../modules/Auth/index'


const PrivateComp = ()=>{
    const token = Auth.getToken();
    const userRole = Auth.getUserRoleID();
    return token && userRole === 2 ?<Outlet/>:<Navigate to="/Login"/>
}



export default PrivateComp;