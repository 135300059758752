import React, { useRef, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../loadingAnimation/index'
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Button,
  TableCell,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,

} from "@material-ui/core";
import moment from "moment";
import { IoArrowBackCircleSharp } from 'react-icons/io5';

// import "./Reports.css"
import { Stack } from "@mui/material";
import { stickyfooter, stickyHeader, stickymainHeader } from ".././sticky";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';
import { useEffect } from "react";

const ProfitabilityFinalTable = () => {
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const location = useLocation();
  const NewComapnyData = [];
  const [companyData, setCompanyData] = useState([])
  // const ReportData = location.state.report;
  // const ReportId = location.state.reportValue;


  NewComapnyData.push(
    {
      "companyId": 7368,
      "companyName": "63 MOONS TECHNOLOGIES-NSE Partly Paid",
      "subAccountId": 120,
      "subAccountName": "Ashish",
      "companyData": [
        {
          "profitLoss": 0,
          "date": "2023-01-01T12:52:28",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 7369,
      "companyName": "A-1 ACID -BSE-AAL Partly Paid",
      "subAccountId": 144,
      "subAccountName": "Bandekar",
      "companyData": [
        {
          "profitLoss": 0,
          "date": "2022-11-04T00:00:00",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2022-11-08T00:00:00",
          "transactStockType": "Installment",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2022-11-11T00:00:00",
          "transactStockType": "Installment",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2023-01-01T10:57:14",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2023-01-04T00:00:00",
          "transactStockType": "Installment",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2023-01-06T00:00:00",
          "transactStockType": "Installment",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 7369,
      "companyName": "A-1 ACID -BSE-AAL Partly Paid",
      "subAccountId": 145,
      "subAccountName": "Pallavi",
      "companyData": [
        {
          "profitLoss": 0,
          "date": "2022-11-04T00:00:00",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 4568,
      "companyName": "JINDAL DRILLING IND.-NSE-JINDRILL",
      "subAccountId": 144,
      "subAccountName": "Bandekar",
      "companyData": [
        {
          "profitLoss": 500,
          "date": "2022-11-01T15:17:43",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2022-11-09T15:07:06",
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 900,
          "date": "2022-11-05T14:47:27",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2022-11-09T15:07:06",
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2022-11-05T14:47:27",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2022-11-10T15:17:43",
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": -50,
          "date": "2022-11-05T14:47:27",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2023-01-13T16:48:28",
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 75,
          "date": "2022-11-05T14:47:27",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2023-01-15T11:04:29",
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 799.5,
          "date": "2023-01-13T11:03:17",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2023-01-15T11:04:29",
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 5737,
      "companyName": "MID INDIA INDUSTRIES.-BSE-MEGLON",
      "subAccountId": 145,
      "subAccountName": "Pallavi",
      "companyData": [
        {
          "profitLoss": 300,
          "date": "2023-01-05T16:53:24",
          "transactStockType": "Buy",
          "sellTransactStockType": "Sell",
          "sellDate": "2023-01-05T16:53:24",
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 2414,
      "companyName": "Zomato 10% Preference",
      "subAccountId": 143,
      "subAccountName": "Mayuri",
      "companyData": [
        {
          "profitLoss": 0,
          "date": "2022-11-01T10:39:55",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        }
      ]
    },
    {
      "companyId": 2414,
      "companyName": "Zomato 10% Preference",
      "subAccountId": 144,
      "subAccountName": "Bandekar",
      "companyData": [
        {
          "profitLoss": 0,
          "date": "2023-01-01T11:32:44",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2023-01-05T11:56:45",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        },
        {
          "profitLoss": 0,
          "date": "2023-01-07T11:58:52",
          "transactStockType": "Buy",
          "sellTransactStockType": null,
          "sellDate": null,
          "show": null,
          "sellShow": null
        }
      ]
    }


  )


  //  useEffect (()=>{
  //   NewComapnyData.map((d)=>{

  //     setCompanyData(d.companyData);
  //     d.companyData.map((item)=>{
  //       if(item.transactStockType !== "Buy" && item.transactStockType !== "Sell"){

  //       }

  //     })

  //   })
  //  },[NewComapnyData])



  const useStyles = makeStyles({
    table: {
      //   minWidth: 650,
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)"
      }
    },
    customTableContainer: {
      overflowX: 'revert'
    }
  });

  const classes = useStyles();
  ReactHTMLTableToExcel.format = (s, c) => {
    if (c && c['table']) {
      const html = c.table;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const rows = doc.querySelectorAll('tr');

      rows.forEach((val, i) => {
        if (i !== 0) {
          val.firstElementChild.colSpan = 3
          if (i === 1 || i === 2) {
            val.childNodes.forEach((val, i) => {
              val.bgColor = '#D9E5F2';
            })
            if (i === 1) {
              //Total label
              val.lastElementChild.colSpan = 4
              val.lastElementChild.vAlign = 'middle';
            } else if (i === 2) {
              const childLength = val.childNodes.length
              //TotalQuantiy And ToatalAmout label
              val.childNodes[childLength - 1].colSpan = 2;
              val.childNodes[childLength - 2].colSpan = 2;
            }
          } else {
            val.childNodes.forEach((child, indx, arr) => {
              if (indx !== 0) {
                child.align = 'right'
                //TotalQuantiy values And ToatalAmout Values
                if (indx === arr.length - 1 || indx === arr.length - 2) {
                  child.colSpan = 2;
                }
              }
            })
          }
        } else {
          val.firstElementChild.colSpan = val.firstElementChild.colSpan + 4;
          val.firstElementChild.bgColor = '#D9E5F2';
          // val.firstElementChild.rowSpan = 3;

        }
      })
      c.table = doc.querySelector('table').outerHTML;
    }

    return s.replace(/{(\w+)}/g, (m, p) => c[p]);
  };

  return (
    <Container maxWidth="xl">

      <h3 className="text-center mt-4">Stock profit loss final report</h3>

      <div className="reportTblFirstcolumn">
        <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report" align="center">
          <TableContainer classes={{ root: classes.customTableContainer }} >

            <Table ref={pdfRef} stickyHeader sx={{ minWidth: 400 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table}>
              {/* <TableHead classes={[{ root: classes.customTableContainer }, stickymainHeader]}>
                                
                            </TableHead> */}
              <TableHead className={stickymainHeader}>
                <TableRow className={stickyHeader}>
                  <TableCell align='left'> Entity</TableCell>
                  {NewComapnyData.map((data, i) => (
                    <>
                      <TableCell align="center" colSpan={3}>{data.subAccountName}</TableCell>
                    </>
                  ))}
                  <TableCell align="center" colSpan={3}>Total</TableCell>
                </TableRow>

                <TableRow className={stickyHeader}>
                  <TableCell rowSpan={2}>Scrip</TableCell>
                  {NewComapnyData.map((data, i) => (
                    <>
                      <TableCell align="center">Intraday</TableCell>
                      <TableCell align="center">STCG</TableCell>
                      <TableCell align="center">LTCG</TableCell>
                    </>

                  ))}
                  <TableCell align="center">Intraday</TableCell>
                  <TableCell align="center">STCG</TableCell>
                  <TableCell align="center">LTCG</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {NewComapnyData.map((data, i) => (
                  <TableRow>
                    <TableCell>{data.companyName}</TableCell>
                    {NewComapnyData.map((data, i) => (
                      <>
                        <TableCell align="center">200.52</TableCell>
                        <TableCell align="center">4.23</TableCell>
                        <TableCell align="center">100</TableCell>
                      </>
                    ))}
                    <TableCell align="center">1000.23</TableCell>
                    <TableCell align="center">400</TableCell>
                    <TableCell align="center">300.26</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Container>

  )
}

export default ProfitabilityFinalTable