import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../loadingAnimation/index'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  Container,
  Button,
  TableCell,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import moment from "moment";
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { stickyfooter, stickyHeader, stickymainHeader } from "../sticky";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import ReactDOMServer from 'react-dom/server';
import './PLreport.css'



const ProfitabilityTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ReportData = location.state.report;
  const ReportDataFinal = location.state.report;
  const companyData = [];
  const companyDatas = [];
  const NewComapnyData = [];



  ReportData.forEach((listData, index) => {
    if (listData.transactStockType !== "Buy" && listData.transactStockType !== "Sell") {
      NewComapnyData.push(
        {
          companyId: listData.companyId,
          companyName: listData.companyName,
          brokerId: listData.brokerId,
          brokerName: listData.brokerName,
          profitLoss: listData.profitLoss,
          dateDifferenceIntra: listData.dateDifference === 0 ? listData.profitLoss : "-" ,
          dateDifferenceST: listData.dateDifference < 365 && listData.dateDifference !== 0 ? listData.profitLoss : "-",
          dateDifferenceLT: listData.dateDifference > 365 ? listData.profitLoss : "-",
          opDate: listData.date,
          opQuantity: listData.quantity,
          opPricePShare: listData.pricePerShare,
          opTotalCost: listData.totalCost,
          buyDate: null,
          buyQuantity: null,
          buyPricePerShare: null,
          buyTotalCost: null,
          sellDate: listData.sellDate,
          sellQuantity: listData.sellQuantity,
          sellPricePerShare: listData.sellPricePerShare,
          sellTotalCost: listData.sellTotalCost
        })
    }
    else if (listData.transactStockType === "Buy" && (listData.sellQuantity === null)){
      NewComapnyData.push({
        companyId: listData.companyId,
        companyName: listData.companyName,
        brokerId: listData.brokerId,
        brokerName: listData.brokerName,
        profitLoss: listData.profitLoss,
        dateDifferenceIntra: listData.dateDifference === 0 ? listData.profitLoss : "-",
        dateDifferenceST: listData.dateDifference < 365 && listData.dateDifference !== 0 ? listData.profitLoss : "-",
        dateDifferenceLT: listData.dateDifference > 365 ? listData.profitLoss : "-",
        dateDifference: listData.dateDifference,
        opDate: listData.date,
        opQuantity: listData.quantity,
        opPricePShare: listData.pricePerShare,
        opTotalCost: listData.totalCost,
        buyDate: null,
        buyQuantity: null,
        buyPricePerShare: null,
        buyTotalCost: null,
        sellDate: listData.sellDate,
        sellQuantity: listData.sellQuantity,
        sellPricePerShare: listData.sellPricePerShare,
        sellTotalCost: listData.sellTotalCost
      })
    }else{
      NewComapnyData.push({
        companyId: listData.companyId,
        companyName: listData.companyName,
        brokerId: listData.brokerId,
        brokerName: listData.brokerName,
        profitLoss: listData.profitLoss,
        dateDifferenceIntra: listData.dateDifference === 0 ? listData.profitLoss : "-",
        dateDifferenceST: listData.dateDifference < 365 && listData.dateDifference !== 0 ? listData.profitLoss : "-",
        dateDifferenceLT: listData.dateDifference > 365 ? listData.profitLoss : "-",
        dateDifference: listData.dateDifference,
        opDate: null,
        opQuantity: null,
        opPricePShare: null,
        opTotalCost:null,
        buyDate: listData.date,
        buyQuantity: listData.quantity,
        buyPricePerShare: listData.pricePerShare,
        buyTotalCost: listData.totalCost,
        sellDate: listData.sellDate,
        sellQuantity: listData.sellQuantity,
        sellPricePerShare: listData.sellPricePerShare,
        sellTotalCost: listData.sellTotalCost
      })
    }

  })

  // console.log(NewComapnyData, "NewComapnyData");

  let newComny = ReportDataFinal.filter((item, index) => ReportDataFinal.findIndex(data => data.sellBrokerId === item.sellBrokerId) === index);
  companyData.push(newComny)

  let newComnys = ReportDataFinal.filter((item, index) => ReportDataFinal.findIndex(data => data.sellCompanyId === item.sellCompanyId) === index);
  companyDatas.push(newComnys)

  


  const useStyles = makeStyles({
    table: {
      //   minWidth: 650,
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)"
      }
    },
    customTableContainer: {
      overflowX: 'revert'
    }
  });

  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <h3 className="text-center mt-4">{'Scripwise + Entitywise profit report'}</h3>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        mt={4}
        padding={2}
      >
        <Stack justifyContent="flex-start">
          <Button variant="contained" onClick={() => navigate('/ReportCard')} color="primary" endIcon={<IoArrowBackCircleSharp />}>
            Go Back
          </Button>
        </Stack>
        {/* <Button variant="contained" onclick="Export()" color="primary" id="btnExport" endIcon={<FaFilePdf />}>
                        Export to PDF
                    </Button> */}
        <Stack justifyContent="flex-end" spacing={{ xs: 1, sm: 2, md: 4 }} direction={{ xs: 'column', sm: 'row' }}>
          <Button variant="contained" color="primary">
            Export to PDF
          </Button>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-primary"
            table="example"
            filename='Brokerwise_Entitywise_Report'
            sheet="tablexls"
            buttonText="EXPORT TO XLSX"

          />
          {/* <FileExport fileName={'Brokerwise/Entitywise report'} /> */}
          <Button variant="contained" color="primary" id="btnPrint" >
            Print
          </Button>
        </Stack>
      </Stack>

      <div className="reportTbPL">
        <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report" align="center">
          <TableContainer classes={{ root: classes.customTableContainer }} >
            <Table stickyHeader sx={{ minWidth: 400 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
              <TableHead className={stickymainHeader}>
                <TableRow>
                  <TableCell rowSpan={2}>Broker</TableCell>
                  <TableCell rowSpan={2}>Scrip</TableCell>
                  <TableCell colSpan={4} align="center">Opening Balance</TableCell>
                  <TableCell colSpan={4} align="center">Purchase</TableCell>
                  <TableCell colSpan={4} align="center">Sell</TableCell>
                  <TableCell rowSpan={2} align="center">Profit / Value </TableCell>
                  <TableCell rowSpan={2} align="center">Intraday</TableCell>
                  <TableCell rowSpan={2} align="center">STCG</TableCell>
                  <TableCell rowSpan={2} align="center">LTCG</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">PricePerShare</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">PricePerShare</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">PricePerShare</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>



              </TableHead>

              <TableBody>
                <>
                  <>
                    {
                      NewComapnyData.map((list, Lindex) => {
                        return (
                          <TableRow>

                            <TableCell >{list.brokerName}</TableCell>
                            <TableCell>{list.companyName}</TableCell>

                            <TableCell align="right">{list.opDate ? moment(list.opDate).format("DD/MM/YYYY") : "-"}</TableCell>
                            <TableCell align="right">{list.opQuantity ? list.opQuantity : " - "}</TableCell>
                            <TableCell align="right">{list.opPricePShare ? list.opPricePShare : " - "}</TableCell>
                            <TableCell align="right">{list.opTotalCost ? new Intl.NumberFormat('en-IN', {
                              currency: 'INR', minimumFractionDigits: 2
                            }).format(list.opTotalCost)  : " - "}</TableCell>

                            <TableCell align="right">{list.buyDate ? moment(list.buyDate).format("DD/MM/YYYY") :  "-"}</TableCell>
                            <TableCell align="right">{list.buyQuantity ? list.buyQuantity : " - "}</TableCell>
                            <TableCell align="right">{list.buyPricePerShare ? list.buyPricePerShare : " - "}</TableCell>
                            <TableCell align="right">{list.buyTotalCost ? new Intl.NumberFormat('en-IN', {
                              currency: 'INR', minimumFractionDigits: 2
                            }).format(list.buyTotalCost) : " - "}</TableCell>

                            <TableCell align="right">{list.sellDate ? moment(list.sellDate).format("DD/MM/YYYY") : " - "}</TableCell>
                            <TableCell align="right">{list.sellQuantity ? list.sellQuantity : " - "}</TableCell>
                            <TableCell align="right">{list.sellPricePerShare ? list.sellPricePerShare : " - "}</TableCell>
                            <TableCell align="right">{list.sellTotalCost ? new Intl.NumberFormat('en-IN', {
                              currency: 'INR', minimumFractionDigits: 2
                            }).format(list.sellTotalCost) : " - "}</TableCell>



                            <TableCell align="right">{new Intl.NumberFormat('en-IN', {
                              currency: 'INR', minimumFractionDigits: 2
                            }).format(list.profitLoss)}</TableCell>

                            <TableCell align="right">{list.dateDifferenceIntra !== "-"  ? new Intl.NumberFormat('en-IN', { currency: 'INR', minimumFractionDigits: 2 }).format(list.dateDifferenceIntra) : "-"} </TableCell>
                            <TableCell align="right">{list.dateDifferenceST !== "-"  ? new Intl.NumberFormat('en-IN', { currency: 'INR', minimumFractionDigits: 2 }).format(list.dateDifferenceST) : "-"}</TableCell>
                            <TableCell align="right">{list.dateDifferenceLT !== "-"  ? new Intl.NumberFormat('en-IN', { currency: 'INR', minimumFractionDigits: 2 }).format(list.dateDifferenceLT) : "-"}</TableCell>
                           


                          </TableRow>
                        )
                      })}

                  </>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

      </div>
    </Container>
  )
}

export default ProfitabilityTable