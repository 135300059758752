/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Loader from "../loadingAnimation/index";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import swal from "sweetalert2";
import Cleave from 'cleave.js/react';

import { AddArtSellTransaction, UpdateArtSellTransaction, getArtTransaction } from '../../pages/Art/ArtAPI'

const Art_Sell = (props) => {
    const { sellShow, onhideSell, artSellTransaction, recentArtHeaderId, handleCloseInner } = props
    const [formErr, setFormErr] = useState({});
    const [loading, setLoading] = useState(false);

    const [sellDate, setSellDate] = useState(null);
    const [buyDate, setBuyDate] = useState(null);
    const [sellProceed, setSellProceed] = useState(0);
    const [subId, setSubId] = useState(0);
    const [artTitleId, setArtTitleId] = useState(0);

  

    const getUpdateTrasactionData = () => {
        setLoading(true);
        getArtTransaction(recentArtHeaderId).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    const result = res.data._response.data.artTransactionDetails;

                    // setSharesInProp(result.shareInProperty)
                    setSellDate(new Date(result.dateOfSale))
                    setSellProceed(result.salesProceeds);
                    setSubId(result.subAccountId);
                    setArtTitleId(result.artTitleId);
                    setBuyDate(result.dateOfInvestment);
                }
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e, "error in art sell");
        });
    }

    useEffect(() => {
        if (sellShow && recentArtHeaderId !== undefined) {
            getUpdateTrasactionData();
        }
    }, [sellShow]);

    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (!sellProceed) {
            isValid = false;
            formErr["salesProceed"] = "Please Enter Sales Proceeds!"
        }

        if (!sellDate) {
            isValid = false;
            formErr["sdate"] = "Please Select Date!"
        }

        setFormErr(formErr);
        return isValid;
    }

    const handleReset = () => {
        setSellDate(null);
        setSellProceed(0);
        setFormErr({});
    }
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }
    const handleSubmit = () => {
        let isValid = errHandel();
        if (isValid) {
            setLoading(true)

            const artSell = {
                // subcriberId:propertySellTransaction.subcriberId,
                inActiveId: artSellTransaction.artId,
                artTitleId: artSellTransaction.artTitleId,
                subAccountId: artSellTransaction.subAccountId,
                dateOfSale: fixTimezoneOffset(sellDate),
                salesProceeds: Number(sellProceed),
            };
            AddArtSellTransaction(artSell).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    swal.fire(response.data._response.message, "", "success");
                    handleCloseModal()
                    handleCloseInner();
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                })
        }
    }

    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)

            const artSell = {
                artId: recentArtHeaderId,
                subAccountId: subId,
                artTitleId: artTitleId,
                dateOfSale: fixTimezoneOffset(sellDate),
                salesProceeds: Number(sellProceed),

            };

            UpdateArtSellTransaction(artSell).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        swal.fire("Record Updated successfully!", "", "success");
                        handleReset();
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in update");
                })
        }
    };

    const handleCloseModal = () => {
        onhideSell();
        handleReset();
    }
    return (
        <>
            {sellShow === true ?
                <>
                    <Modal ClassName="custom-dialog " isOpen={sellShow}
                        size="md"
                        id="modal2"
                        toggle={() => handleCloseModal()}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                            <h5 className="text-center mb-0">Art Sell Transaction</h5>
                        </ModalHeader>
                        <ModalBody >
                            <Container fluid>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "12rem" }} />
                                ) : null}
                                <div className="mt-2 p-6" tabIndex={2}>
                                    <Form className=" rounded p-3">
                                        <Row className="form-set">
                                            <Form.Group className="col-md-6">
                                                <Form.Label>Date of Sale<span className="text-danger h6">*</span></Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Select Sale Date"
                                                    name="selldate"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    onChange={(date) => setSellDate(date)}
                                                    dateFormat="dd-MM-yyyy"
                                                    autoComplete="off"
                                                    selected={sellDate}
                                                    minDate={recentArtHeaderId === undefined ? new Date(artSellTransaction.dateOfInvestment) : new Date(buyDate)}
                                                    // maxDate={new Date()}
                                                    popperPlacement="left-start"
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.sdate}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-6 col-md-6">
                                                <Form.Label>Sales Proceeds<span className="text-danger h6">*</span></Form.Label>
                                                {/* <NumberFormat
                                                    className="form-control"
                                                    thousandSeparator={","}
                                                    thousandsGroupStyle="lakh"
                                                    isNumericString={true}
                                                    name="salesProceed"
                                                    placeholder="Enter Sales Proceeds"
                                                    value={sellProceed}
                                                    onChange={(e) => setSellProceed(e.target.value)}
                                                /> */}

                                                <Cleave
                                                    placeholder="Enter Sales Proceeds"
                                                    className='form-control'
                                                    name="salesProceed"
                                                    autoComplete="off"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={sellProceed}
                                                    onChange={(e) => setSellProceed(e.target.rawValue)}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.salesProceed}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col md="12" className="text-center mt-4">
                                                {recentArtHeaderId === undefined ? <>
                                                    <Button variant="primary" type="submit"
                                                        className="mx-2 w100"
                                                        id="submitButton"
                                                        onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                                    // disabled={!disableButton()}
                                                    >Submit</Button>
                                                    <Button
                                                        variant="secondary"
                                                        className="mx-2 w100"
                                                        type="reset"
                                                        onClick={handleReset}
                                                    >
                                                        Reset
                                                    </Button></>
                                                    : null}
                                                {recentArtHeaderId !== undefined ?
                                                    <Button variant="primary" className="mx-2 w100"
                                                        type="submit"
                                                        id="submitButton"
                                                        onClick={(e) => { e.preventDefault(); handleUpdate(e) }}
                                                    // disabled={!disableButton()}
                                                    >Update</Button>
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Container>
                        </ModalBody>
                    </Modal>
                </> : null}

        </>
    )
}

export default Art_Sell