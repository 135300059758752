import axios from '../../../modules/helpers/axiosInstance';
import Auth from '../../../modules/Auth/index'

const subscriberId = Auth.getsubcriberId();

let channelpartnerLiveUrl = "http://5.189.157.40:10025/api";
// let channelPartnerLiveUrl = "https://localhost:5001/api";
// let channelpartnerUserLiveUrl = "https://localhost:5002/api";


const addSubscriber = async (data) => {
    return axios({
        method: 'POST',
        url: `${channelpartnerLiveUrl}/Subcriber/register`,
        data: data
    });
}

const GetAddSubscriber = async (PageNo,PageSize ) => {
    return axios({
        method: 'GET',
        url: `${channelpartnerLiveUrl}/Subcriber/FetchSubscribersByCP?CP_id=${subscriberId}&PageNo=${PageNo}&PageSize=${PageSize}`,
    });
}

const UpdateSubscriber = async (data) => {
    return axios({
        method: 'PUT',
        url: `${channelpartnerLiveUrl}/UserManagment/UpdateSubscriber`,
        data : data
    })
}

const DeleteSubscriber = async (data) => {
    return axios({
        method: 'DELETE',
        url: `${channelpartnerLiveUrl}/UserManagment/DeleteSubscriber`,
        data : data
    })
}

const activeDeactiveSubscriber = async (subcriberId,active,pageNumber,rowsPerPage) => {
    return axios({
        method: 'PUT',
        url: `${channelpartnerLiveUrl}/UserManagment/ActiveDeactiveSubscriber?subcriberId=${subcriberId}&CP_id=0&active=${active}&PageNo=${pageNumber}&PageSize=${rowsPerPage}`,
    })
}

export {
    addSubscriber,
    GetAddSubscriber,
    UpdateSubscriber,
    DeleteSubscriber,
    activeDeactiveSubscriber
}