import React from 'react'
import thankyou from '../../assets/paymentthankyou.jpg'
import { useNavigate } from 'react-router-dom'
import './style.css';

const ThankPayment = () => {
    const navigate = useNavigate()

    const LoginGo = () =>{
        navigate("/login")
    }
    return (
        <>
            <div>
                <div className="thankpayment">
                    <div className="thankyouSub">
                        <h1 className='ThankyouTxt'>Thank you !</h1>
                        <path className="ThankyouTxtscreen" d="M81 6H29.5L0 78H47L81 6Z" fill="url(#paint0_radial)" />
                        <img className='paymoneyicon'  src={thankyou} alt='paymentthanks' />
                        <p>Thanks for subscribing.</p>
                        <p>you should receive a confirmation email soon  </p>
                        <button className="go-Login" onClick={LoginGo}>
                            Login
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ThankPayment