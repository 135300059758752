/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { EntityList, PostPartlyPaid, FetchDate, FetchBrokerDropDownData, FetchCompanyDropDownData, updatePartlyPaid } from "../../../pages/Stocks/StocksAPI";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener' 

// const Scrip = [
//     {
//         label: "Tesla",
//         value: "Tesla",
//     },
//     {
//         label: "Amazon",
//         value: "Amazon",
//     },
//     {
//         label: "Google",
//         value: "Google",
//     },
// ];

const Partlypaid = (props) => {
    const { showP, onHideP, stockHeaderId, stockTransactionP, stockId, showPartlyPaidpreview } = props;
    const [transactionType] = useState([
        {
            value: "Installment",
            label: 'Installment'
        },
        {
            value: 'Final',
            label: 'Final'
        }]
    );

    const [seltransactionType, setSelTransactionType] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [amount, setAmount] = useState([]);
    const [formErr, setFormErr] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buyDate, setBuyDate] = useState(null)
    const [ratio, setRatio] = useState('');
    // const [quantity, setQuantity] = useState('')

    useEffect(() => {
        (async () => {
            // await getCompanyList();
            // await getBrokerList();
            if (showP) {
                await getEntityListt();
            }
        })();
    }, [showP]);

    // useEnterKeyListener({
    //     querySelectorToExecuteClick: "#submitButton"
    // });

    useEffect(() => {
        if (showP) {
            (async () => {
                await getCompanyDropDownData()
                await getBrokerDropDownData()
            })();
        }
    }, [selEntity, showP]);

    // for buy date
    useEffect(() => {
        if (showP) {
            let co_id = selScrip.value;
            let bro_id = selBroker.value;
            let sub_id = selEntity.value;
            if (co_id && bro_id && sub_id) {
                getBuyDate(sub_id, co_id, bro_id);
            }
        }
    }, [selScrip, selBroker, selEntity, showP]);

    // edit partlyPaid data
    useEffect(() => {
        if (stockHeaderId !== 0 && showP === true || showPartlyPaidpreview === true) {
            if (stockTransactionP !== undefined) {
                setSelBroker({
                    value: stockTransactionP.brokerId,
                    label: stockTransactionP.broker.brokerName,
                });
                setSelEntity({
                    value: stockTransactionP.subAccountId,
                    label: stockTransactionP.subAccount.subAccountName,
                });

                stockTransactionP.stockDetails && stockTransactionP.stockDetails.map((t) => {
                    //   setAQtySplit({ quantity: t.quantity })
                    if (t.transactStockType !== "Buy") {
                        setPDate(new Date(t.date));
                        setSelScrip(t.company, { companyType: t.instrumentTypeId });
                        setAmount({ amount: t.totalCost })
                        // setQuantity(t.quantity)
                        setSelTransactionType({
                            value: t.transactStockType,
                            label: t.transactStockType,
                        });
                    }
                })
            }
        }
    }, [stockTransactionP, stockHeaderId, showP, showPartlyPaidpreview])


    //getEntityList
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {
                // console.log(res, "entities");
                if (res.status === 200) {
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                    setLoading(false)

                } else {
                    // setError(res.data.message);
                    setLoading(false)

                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)

            });


    };

    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (seltransactionType.length < 1) {
            isValid = false;
            formErr["seltransactionType"] = "Please Select Transaction Type!";
        }
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select Scrip!";
        }
        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }
        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }
        if (!amount.amount) {
            isValid = false;
            formErr["amount"] = "Please Fill This Field!";
        }
        if (amount.amount) {
            if (amount.amount < 0) {
                isValid = false;
                formErr["amount"] = "Please Enter Positive Value !";
            }
        }
        setFormErr(formErr);
        return isValid;
    };

    //handlechange
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: '' });
    };
    const handleFamily = (selected) => {
        setSelEntity(selected);
        setSelBroker([])
        setBrokerList([])
        setFormErr({ ...formErr, selEntity: "" });
    };
    const handleOption = (selected) => {
        setSelBroker(selected);
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: "" });
    };
    const handleTansactionType = (selected) => {
        setSelTransactionType(selected);
        setFormErr({ ...formErr, seltransactionType: '' });
    };
    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    };
    const handleAmount = (e) => {
        const { name, rawValue } = e.target;
        setAmount({ ...amount, [name]: rawValue });
        setFormErr({ ...formErr, amount: '' })
    };

    // get buy date
    const getBuyDate = async (sub_id, co_id, bro_id) => {
        await FetchDate(sub_id, co_id, bro_id)
            .then((res) => {
                if (res.status === 200) {

                    if (res.data._response.status === 1) {
                        //setLoading(false)
                        setBuyDate(res.data._response.data.fetchDate)
                    }
                    else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e, 'error in partly paid')
            });
    };

    // get dropDown Company Data
    const getCompanyDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchCompanyDropDownData(sub_Id)
                .then((res) => {
                    // console.log(res,'company list');
                    if (res.status === 200) {
                        let UnlistedCompany = [];
                        let ListedCompany = [];
                        //
                        let company = res.data._response.data.companyName;
                        company &&
                            company.filter(data => data.instrumentTypeId === 1).map(i => {
                                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });
                        company &&
                            company.filter(data => data.instrumentTypeId === 10).map(i => {
                                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });

                        const groupedOptions = [
                            {
                                label: 'Listed Companies',
                                options: ListedCompany,
                            },
                            {
                                label: 'UnListed Companies',
                                options: UnlistedCompany,
                            },
                        ];
                        setLoading(false);
                        setCompanyList(groupedOptions);
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                        // setError(res.data.message);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                    // setError(e.message);
                });
        }
    };

    // get dropDown company Data
    const getBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchBrokerDropDownData(sub_Id)
                .then((res) => {
                    setLoading(false)
                    // console.log(res, "brokerlisy");
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            let brokers = res.data._response.data.brokerName;
                            let brokersData = [];
                            brokers &&
                                brokers.map((data) => {
                                    brokersData.push({
                                        value: data.brokerId,
                                        label: data.brokerName,
                                    });
                                });
                            setLoading(false);
                            setBrokerList(brokersData);
                        }
                    } else {
                        setBrokerList([]);
                        setLoading(false)
                        //setError(res.data.message);
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setBrokerList([]);
                    setLoading(false);
                    console.log(e, "brokerlist error");
                });
        }
    };


    const handleCloseModal = (val) => {
        handleReset();
        onHideP(val);
    }

    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }

    //submit form
    const handleSubmit = (e) => {

        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const Sdata = {
                totalCost: parseInt(amount.amount),
                date: fixTimezoneOffset(pDate),
                //moment.tz(pDate, "Asia/Kolkata").format(),
                companyId: selScrip.value,
                transactStockType: seltransactionType.value,
                brokerId: selBroker.value,
                subAccountId: selEntity.value,
                instrumentTypeId: selScrip.companyType
            };

            PostPartlyPaid(Sdata).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        Swal.fire(response.data._response.message, "", "success");
                        handleReset();
                        // onRefresh();
                    }else{
                        Swal.fire("This Transaction Is Not Allow", "", "warning");
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };

    const handleUpdate = (e) => {
        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                brokerId: selBroker.value,
                subAccountId: selEntity.value,
                date: new Date(pDate).toISOString(),
                stockHeaderId: stockHeaderId,
                stockId: stockId,
                companyId: selScrip.value,
                ratio: ratio.ratio,
                totalCost: parseInt(amount.amount),
                // quantity:quantity,
                instrumentTypeId: selScrip.companyType
            };
            updatePartlyPaid(Sdata)
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            Swal.fire(response.data._response.message, "", "success");
                            handleReset();
                            //  onRefresh();
                        }else{
                            Swal.fire(response.data._response.message, "", "warning");

                        }
                    } else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in update");
                });
        }
    };

    //reset field
    const handleReset = () => {
        setSelTransactionType([]);
        setSelScrip([]);
        setPDate(null);
        setFormErr([]);
        setSelEntity([]);
        setCompanyList([])
        setSelBroker([]);
        setBrokerList([])
        setAmount({ amount: 0 });
        setRatio({ ratio: "" })
    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }

    return (
        <>
            <Modal show={showP} onHide={() => handleCloseModal()} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Partly Paid</h5></Modal.Title>
                </Modal.Header>
                {/* {success ? (
                    <Alert
                        className="mt-2"
                        variant="success"
                        onClose={() => setSuccess(false)}
                        dismissible
                    >
                        <p>{successMessage}</p>
                    </Alert>
                ) : null} */}
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3" >
                            <Row className="form-set">
                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Form.Group className=" col-md-4">
                                        <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selEntity}
                                            onChange={handleFamily}
                                            options={Entity}
                                            name="subAccountId"
                                            isOptionDisabled={(option) => option.disabled === 0}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selEntity}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    : <Form.Group className="col-md-4">
                                        <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selEntity}
                                            onChange={handleFamily}
                                            options={Entity}
                                            name="subAccountId"
                                            isDisabled="required"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selEntity}
                                            </div>
                                        ) : null}
                                    </Form.Group>}

                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selBroker}
                                            onChange={handleOption}
                                            options={BrokerList}
                                            name="brokerId"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selBroker}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    : <Form.Group className="col-md-4">
                                        <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selBroker}
                                            onChange={handleOption}
                                            options={BrokerList}
                                            name="brokerId"
                                            isDisabled="required"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.selBroker}
                                            </div>
                                        ) : null}
                                    </Form.Group>}

                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selScrip}
                                            onChange={handleInput}
                                            options={companyList}
                                            name="companyId"
                                            isOptionDisabled={handelDisableDD}
                                            styles={{
                                                option: (data) => ({
                                                    ...data,
                                                    margin: 0,
                                                    padding: '3px 12px',
                                                }),
                                                groupHeading: (base) => ({
                                                    ...base,
                                                    flex: '1 1',
                                                    color: '#000',
                                                    margin: 0,
                                                    padding: '7px 2px',
                                                    // border: `2px solid `,
                                                    background: "#8bc3ff",
                                                    fontSize: 'small',
                                                    fontWeight: 500
                                                }),
                                            }}
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.selScrip}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    : <Form.Group className="col-md-4">
                                        <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={selScrip}
                                            onChange={handleInput}
                                            options={companyList}
                                            name="companyId"
                                            isDisabled="required"
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.selScrip}
                                            </div>
                                        ) : null}
                                    </Form.Group>}
                            </Row>
                            <Row className="form-set">
                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Transaction Type<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={seltransactionType}
                                            onChange={handleTansactionType}
                                            options={transactionType}
                                            name="transactionType"
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.seltransactionType}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    : <Form.Group className="col-md-4">
                                        <Form.Label>Transaction Type<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            value={seltransactionType}
                                            onChange={handleTansactionType}
                                            options={transactionType}
                                            name="transactionType"
                                            isDisabled="required"
                                        />
                                        {formErr ? (
                                            <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                {formErr.seltransactionType}
                                            </div>
                                        ) : null}
                                    </Form.Group>}
                                <Form.Group className="col-md-4">
                                    <Form.Label>Amount<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="amount"
                                        min="0"
                                        value={amount.amount}
                                        onChange={handleAmount}
                                        autoComplete="off"
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="amount"
                                        placeholder="Enter Amount"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={amount.amount}
                                        onChange={handleAmount}/>
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.amount}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Payment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        maxDate={new Date()}
                                        minDate={new Date(buyDate)}
                                        onChange={handleDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={pDate}
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {stockHeaderId === 0 || stockHeaderId === undefined ? (
                                        (pDate && selEntity &&
                                            selScrip && selBroker
                                            && amount && transactionType) ?
                                            <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }}>
                                                Save
                                            </Button> : <Button variant="primary" disabled className="mx-2 w100" onClick={() => { handleSubmit(); }} >Save </Button>)
                                        : (
                                            <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }}  >
                                                Update
                                            </Button>
                                        )}
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Button
                                            variant="secondary"
                                            className="mx-2 w100"
                                            type="reset"
                                            onClick={handleReset}
                                        >
                                            Clear
                                        </Button> : null}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            {showPartlyPaidpreview === true ?
                <Modal show={showPartlyPaidpreview} onHide={() => handleCloseModal(1)}>
                    <Modal.Header closeButton className="headerBlue" >
                        <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Partly Paid Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Transaction Type : </div>
                                <div className="transactionDataValue">{seltransactionType.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount.amount)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date of Payment : </div>
                                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
                        </div>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}
export default Partlypaid;