/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import { PostRDInterest_Insta, getInterestData, deleteRDInterest_Insta, updateRDInterest_Insta } from '../FDAPI';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import Loader from "../../loadingAnimation/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Cleave from 'cleave.js/react';
import moment from 'moment';

const RDInstallment = (props) => {
    const { showRDIn, hideRDIn, FDHeaderId, FDiD, InvtDate, Iname } = props;
    const [date, setDate] = useState(null);
    const [installmentAmt, setInstallmentAmt] = useState(null);
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idata, setiData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [InstallmentId, setInstallmentId] = useState(null);
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;


    useEffect(async () => {
        // if (FDHeaderId != null) {
        await getInterestDataList();
        // }
    }, [FDHeaderId, showRDIn]);



    // validation
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (date === "" || date == null) {
            formErr["date"] = "Please fill date!";
            isValid = false;
        }
        if (installmentAmt === "" || installmentAmt == null) {
            formErr["Installment"] = "Please enter Installment amount!";
            isValid = false;
        } else if (Number(installmentAmt) <= 0) {
            formErr["Installment"] = "Value must greater than zero!";
            isValid = false;
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const getInterestDataList = () => {
        setLoading(true)
        getInterestData(FDHeaderId, "Installment")
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data._response.data.fixedDepositInterestList
                    setiData(result);
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, "error in get RD installment data");
            })
    }
    const handleSubmit = () => {
        const isValid = errHandel()
        if (isValid) {

            setLoading(true);
            let fdata = {
                transactStockType: "Installment",
                fdHeaderId: FDHeaderId,
                checkfdId: FDiD,
                amtOfInstallment: Number(installmentAmt),
                dateOfInstallment: fixTimezoneOffset(date),
                dateInvestment: fixTimezoneOffset(InvtDate),
            };
            PostRDInterest_Insta(fdata).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        swal("Installment Added Successfully!", "", "success");
                        // handleCloseModal();
                        handleReset();
                        getInterestDataList();
                    }
                    else {
                        swal(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const handleReset = () => {
        setDate(null);
        setInstallmentAmt("");
        setUpdate(false)
        // setnetamt(null);
    }
    const handleCloseModal = () => {
        handleReset();
        hideRDIn();
        setUpdate(false)
    }
    const disableButton = () => {
        return date && installmentAmt
    }

   
  

    // onChange handler 
    const handleDate = (selected) => {
        setDate(selected);
        setFormErr({ ...formErr, date: "" })
    }
    const handleInstallmentAmt = (e) => {
        const { rawValue } = e.target;
        setInstallmentAmt(rawValue);
        setFormErr({ installmentAmt: "" })
    }

    // handle single delete
    const deleterow = (selectedRow, a) => {

        setLoading(true)
        deleteRDInterest_Insta(a.fiInterestId).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    getInterestDataList();
                    handleReset();
                    swal("Delete successfully!", "", "success");
                } else {
                    swal(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                console.log(error, " : error in delete installment");
            })
    };
    // delete multiple
    const deleteMultiple = (e) => {
        setLoading(true)
        let a = JSON.stringify(selected)
        let interstpara = a.replace(/[\[\]']+/g, '');
        let ids = interstpara.replace(/,/g, '&&FiInterestId=')

        deleteRDInterest_Insta(ids).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    getInterestDataList();
                    handleCloseModal();
                    setSelected([])
                    swal("Delete successfully!", "", "success");
                } else {
                    swal(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                setLoading(false)
                console.log(error, " : error in delete multiple");
            })
    };

    // handle multiple delete
    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = idata.map((n) => n.fiInterestId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // handle update
    const handleUpdate = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let fdata = {
                transactStockType: "Installment",
                fdHeaderId: FDHeaderId,
                checkfdId: FDiD,
                fiInterestId: InstallmentId,
                amtOfInstallment: Number(installmentAmt),
                dateOfInstallment: fixTimezoneOffset(date),
                dateInvestment: fixTimezoneOffset(InvtDate),
            };
            updateRDInterest_Insta(fdata).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    swal("Installment Updated Successfully!", "", "success");
                    // handleCloseModal();
                    // handleReset();
                    getInterestDataList();
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const updateinterest = (selectedRow, a) => {
        setUpdate(true)
        setDate(new Date(a.dateOfInstallment));
        setInstallmentAmt(a.amtOfInstallment);
        setInstallmentId(a.fiInterestId)
    }
    return (
        <>
            <Modal show={showRDIn} onHide={() => handleCloseModal()} size="lg" backdrop="static" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>{Iname}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Date of Installment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDate}
                                        popperPlacement="bottom-end"
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        minDate={new Date(InvtDate)}
                                        selected={date}
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Amount of Installment<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        placeholder="Enter amount of Installment"
                                        name="installmentAmt"
                                        min="0"
                                        value={installmentAmt}
                                        onChange={handleInstallmentAmt}
                                    /> */}
                                    <Cleave
                                         placeholder="Enter amount of Installment"
                                        className='form-control'
                                        name="installmentAmt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={installmentAmt}
                                        onChange={handleInstallmentAmt} />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.Installment}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {update === false ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                            disabled={!disableButton()}>Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                        >Update</Button>}

                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div><br />
                    {/* table design */}
                    <TableContainer component={Paper}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }),
                            }}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length} selected
                                </Typography>
                            ) : null}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <DeleteIcon onClick={(e) => deleteMultiple(e)} />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Toolbar>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableHead>
                                <TableRow>
                                    <TableCell width="3%">
                                        <Checkbox
                                            style={{ float: "left", marginLeft: "14px" }}
                                            color="primary"
                                            onClick={handleSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < idata.length}
                                            checked={idata.length > 0 && selected.length === idata.length ? "checked" : null}
                                            // onChange={onSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center" width="20%">Date of Installment</TableCell>
                                    <TableCell align="center" width="20%">Amount of Installment</TableCell>
                                    <TableCell align="center" width="10%">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idata && idata.map((index) => {
                                    const isItemSelected = isSelected(index.fiInterestId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={idata.fiInterestId}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{ marginLeft: "1rem" }}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, index.fiInterestId)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{moment(index.dateOfInstallment).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.amtOfInstallment)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <Edit fontSize="small" onClick={(e) => updateinterest(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton>
                                                        <DeleteIcon fontSize="small" onClick={(e) => deleterow(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RDInstallment;
