/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import Dropdown from '../CommonComponent/Dropdown'
import { RoleList, UserProfile } from '../../pages/UserProfileInfo/UserProfileAPI'
import Swal from 'sweetalert2'
import Loader from '../loadingAnimation/index'
import Auth from '../../modules/Auth';
import { useNavigate } from 'react-router-dom'
import { UserGetData, UpdateUserProfile } from '../../pages/RoleMasterPages/AdminsPages/UserdetailsApi';
const userId = Auth.getUserId();

function UserProfileInfo() {
    const navigate = useNavigate();
    const [data, setData] = useState({
        uname: '',
        Address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        email: '',
        mobnum: '',
        profession: '',
        PAN: '',
        GST: '',
        Contact: ''

    });
    const [userType, setUserType] = useState([]);
    const [userTypeValue, setUserTypeValue] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [errMsg, setErrMsg] = useState({});
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        RoleMaster();
    }, [])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
        cleanErrMsg(name)
    }

    const HandleChangeType = (selected) => {
        setUserType(selected.value);
        setUserTypeValue(selected.label);
        cleanErrMsg('UserType')
    }
    const cleanErrMsg = (field) => {
        switch (field) {
            case "uname": setErrMsg({ ...errMsg, uname: '' })
                break;
            case "Address": setErrMsg({ ...errMsg, Address: '' })
                break;
            case "city": setErrMsg({ ...errMsg, city: '' })
                break;
            case "state": setErrMsg({ ...errMsg, state: '' })
                break;
            case "country": setErrMsg({ ...errMsg, country: '' })
                break;
            case "pincode": setErrMsg({ ...errMsg, pincode: '' })
                break;
            case "email": setErrMsg({ ...errMsg, email: '' })
                break;
            case "mobnum": setErrMsg({ ...errMsg, mobnum: '' })
                break;
            case "profession": setErrMsg({ ...errMsg, profession: '' })
                break;
            case "PAN": setErrMsg({ ...errMsg, PAN: '' })
                break;
            case "GST": setErrMsg({ ...errMsg, GST: '' })
                break;
            case "Contact": setErrMsg({ ...errMsg, Contact: '' })
                break;
            case "UserType": setErrMsg({ ...errMsg, UserType: '' })
                break;

            default: setErrMsg({})
                break;
        }
    }
    const errorHandel = () => {
        let formErr = {};
        let isValid = true
        const panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
        const emailregex = /^(([^<>()[\]\.\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
        if (!data.uname) {
            formErr['name'] = "Please enter name!";
            isValid = false;
        }
        if (!data.Address) {
            formErr['Address'] = "Please enter Address!";
            isValid = false;
        }
        if (!data.city) {
            formErr['city'] = "Please enter city!";
            isValid = false;
        }
        if (!data.state) {
            formErr['state'] = "Please enter state!";
            isValid = false;
        }
        if (!data.country) {
            formErr['country'] = "Please enter country!";
            isValid = false;
        }
        if (!data.pincode) {
            formErr['pincode'] = "Please enter pincode!";
            isValid = false;
        } else if (pincoderegex.test(data.pincode) === false) {
            formErr["pincode"] = "Please enter valid pincode";
            isValid = false;
        }
        if (!data.email) {
            formErr['email'] = "Please enter email!";
            isValid = false;
        } else if (emailregex.test(data.email) === false) {
            formErr['email'] = "please enter valid email"
            isValid = false;
        }

        if (!data.mobnum) {
            formErr['mobnum'] = "Please enter mobile number!";
            isValid = false;
        } else if (phoneregex.test(data.mobnum) === false) {
            formErr['mobnum'] = "Please enter valid phone!";
            isValid = false;
        }
        if (!data.profession) {
            formErr['profession'] = "Please enter profession!";
            isValid = false;
        }
        if (!data.PAN) {
            formErr['PAN'] = "Please enter PAN!";
            isValid = false;
        } else if (panregex.test(data.PAN) === false) {
            formErr['PAN'] = "Please enter valid PAN No!";
            isValid = false;
        }
        if (!data.GST) {
            formErr['GST'] = "Please enter GST!";
            isValid = false;
        }
        if (!data.Contact) {
            formErr['Contact'] = "Please enter Contact!";
            isValid = false;
        }
        if (userTypeValue.length < 1) {
            isValid = false;
            formErr["UserType"] = "Please Select user type!";
        }
        setErrMsg(formErr);
        return isValid;
    }

    const RoleMaster = async () => {
        await RoleList().then(res => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let roles = res.data._response.data.roleMasterDetails;
                    let roleData = [];
                    roles && roles.map(data => {
                        roleData.push({ value: data.roleId, label: data.roleName })
                    })
                    const roleFilterData = roleData.filter((a) => a.value !== 1)

                    setUserRoles(roleFilterData);
                }
            } else {
                // setError(res.data.message)
            }
        }).catch(e => {
            console.log(e.message);
        })
    }

    useEffect(() => {
        userprofile();
    }, [])

    const handleUpdate = (e) => {
        e.preventDefault();
        // const isValid = errorHandel();
        // if(isValid){

        const { uname, Address, city, state, country, pincode, email, mobnum, profession, PAN, GST, Contact } = data;
        ;
        let profileData = {
            userId: userId,
            subcriberName: uname,
            subcriberAdd1: Address,
            gst: GST,
            pan: PAN,
            subcriberCity: city,
            subcriberState: state,
            subcriberCountry: country,
            pinCode: pincode,
            subcriberEmail: email,
            subcriberPh1: mobnum,
            profession: profession,
            // subcriberType: userTypeValue.value,
            userTypeId: userType,
            isPaid:1,
            // roleId: userType,
            // cP_Id: 0,
            contactPerson: Contact
        }
        setLoading(true)
        UpdateUserProfile(profileData).then(res => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    localStorage.setItem('profile_pop', 0);
                    // window.location.href = '/ProfileCompletion';
                    navigate("/Dashboard")
                    setLoading(false)
                }
                else {
                    Swal.fire(res.data._response.message, "", "info");
                    setLoading(false)
                }
            }
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }



    const userprofile = () => {
        setLoading(true)
        UserGetData(userId).then((res) => {
            if (res.data._response.status === 1) {
                let result = res.data._response.data.subcriberDetails
                result.map((a) => {
                    setData({
                        ...data,
                        uname: a.subcriberName,
                        Address: a.subcriberAdd1,
                        city: a.subcriberCity,
                        state: a.subcriberState,
                        country: a.subcriberCountry,
                        pincode: a.pinCode,
                        email: a.subcriberEmail,
                        mobnum: a.subcriberPh1,
                        profession: a.profession,
                        PAN: a.pan,
                        GST: a.gst,
                        Contact: a.contact
                    })
                    setUserTypeValue({ label: a.roleName, value: a.roleId })
                    setUserType(a.roleId)
                    // setUserType(a.roleId)
                })

                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const handelSubmit = (e) => {
        e.preventDefault();
        const isValid = errorHandel();
        if (isValid) {
            const { uname, Address, city, state, country, pincode, email, mobnum, profession, PAN, GST, Contact } = data;
            let profileData = {
                id: userId,
                uname: uname,
                address: Address,
                gst: GST,
                pan: PAN,
                city: city,
                state: state,
                country: country,
                pincode: pincode,
                email: email,
                mobileNo: mobnum,
                profession: profession,
                roleId: userType,
                cP_Id: 0,
                contact: Contact
            }
            UserProfile(profileData).then(res => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        Swal.fire(res.data._response.message, "", "info");
                        handleReset();
                    }
                    else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            }).catch(err => {
                handleReset();
                console.log(err);
            })
        }
    }

    const handleReset = () => {
        setData({
            uname: '',
            Address: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            email: '',
            mobnum: '',
            profession: '',
            PAN: '',
            GST: '',
            Contact: ''

        });
        setUserType('');
        setUserTypeValue([])
        setErrMsg({})
    };

    return (
        <div>
            {/* <Container className="LoginContainer formWraper"
                component="main"
                maxWidth="xs" > */}
            <Container>
                {loading ? <Loader /> : null}
                <div className='col-md-6 mx-auto border p-5 pt-3 shadow p-3 mb-5 bg-white rounded'>
                    <h3 className='text-center mb-4'>User Profile</h3>
                    <Form className=''>
                        <Form.Group className='mb-3 '>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" value={data.uname} name='uname' onChange={handleChange} autoComplete="off" />
                            {errMsg ? (
                                <div
                                    className=""
                                    style={{ fontSize: 12, color: "red" }}
                                >
                                    {errMsg.uname}
                                </div>
                            ) : null}
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter address here"
                                style={{ height: '50px' }}
                                name='Address'
                                onChange={handleChange}
                                value={data.Address}
                                autoComplete="off"
                            />
                            {errMsg ? (
                                <div
                                    className=""
                                    style={{ fontSize: 12, color: "red" }}
                                >
                                    {errMsg.Address}
                                </div>
                            ) : null}
                        </Form.Group>

                        <Row className="g-2 mt-2 " >
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="Enter City" name='city' value={data.city} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.city}</div>) : null}
                                </Form.Group>


                            </Col>
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State" name='state' value={data.state} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (
                                        <div
                                            className=""
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {errMsg.state}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Country" name='country' value={data.country} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.country}</div>) : null}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-2 mt-2">
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control type="number" placeholder="Pincode" name='pincode' value={data.pincode} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.pincode}</div>) : null}
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Email Id</Form.Label>
                                    <Form.Control type="email" placeholder="Email Id" name='email' value={data.email} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.email}</div>) : null}
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Mobile Number" name='mobnum' value={data.mobnum} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.mobnum}</div>) : null}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className='mb-3'>
                            <Form.Label>Profession</Form.Label>
                            <Form.Control type="text" placeholder="Enter Profession" value={data.profession} name='profession' onChange={handleChange} autoComplete="off" />
                            {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.profession}</div>) : null}
                        </Form.Group>
                        <Row className="g-2 mt-2">
                            <Col md={6} >
                                <Form.Group className='mb-3'>
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter PAN" name='PAN' value={data.PAN} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.PAN}</div>) : null}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GST" name='GST' value={data.GST} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.GST}</div>) : null}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="g-2 mt-2">
                            <Col md={6} >
                                <Form.Group className='mb-3'>
                                    <Form.Label>Contact Person</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Contact Person" name='Contact' value={data.Contact} onChange={handleChange} autoComplete="off" />
                                    {errMsg ? (<div className="" style={{ fontSize: 12, color: "red" }}>{errMsg.Contact}</div>) : null}
                                </Form.Group>
                            </Col>
                            <Col md={6} >
                                <div className="form-floating">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>User Role</Form.Label>

                                        <Dropdown
                                            value={userTypeValue}
                                            name='UserType'
                                            options={userRoles}
                                            onChange={HandleChangeType}
                                            className="SelectClass"
                                            searchable={true}
                                            clearable={true}
                                            noResultsText={"No result"}
                                            placeholder="Select User Role.."
                                            isDisabled={userId ? true : false}
                                        />



                                        {errMsg ? (<div className="my-1" style={{ fontSize: 12, color: "red" }}>{errMsg.rolename}</div>) : null}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col md="12" className="text-center mt-3">
                                {userId === undefined ?
                                    <Button variant="primary" className="mx-2 w100" onClick={handelSubmit} >
                                        Submit
                                    </Button>
                                    :
                                    <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}>
                                        Update
                                    </Button>
                                }
                                <Button variant="secondary" className="mx-2 w100" onClick={handleReset}>
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>

        </div>
    )
}

export default UserProfileInfo