export const SIDEBAR_DATA = "SIDEBAR_DATA"
export const CLEAR_SIDEBAR_DATA = "CLEAR_SIDEBAR_DATA"
export const OPEN_CLOSE_SIDEBAR = "OPEN_CLOSE_SIDEBAR"

export const SidebarData = (data) => {
    return dispatch => {
        dispatch({
            type: SIDEBAR_DATA,
            payload: data
        })
    };
};

export const ClearSidebarData = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_SIDEBAR_DATA,
        })
    };
};

export const openCloseSidebar = (data) => {
    return dispatch => {
        dispatch({
            type: OPEN_CLOSE_SIDEBAR,
            payload: data
        })
    };
};