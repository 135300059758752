import axios from "axios";
// import settings from "../../modules/config/settings";

// import Auth from "../../modules/Auth";

// const userToken = Auth.setUserToken();

const VerifyEmailData = async (data) => {
    return axios({
        method: "POST",
        url: `http://5.189.157.40:10025/api/Subcriber/verify-email`,
        data: data,
    });
}

export {
    VerifyEmailData
}