import React from 'react'
import ProfitabilityTable from '../../../components/Report/Profitability/ProfitabilityTable';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const ProfitabilityTablePage = () => {
  const sidebarOpen = useSelector(res => res.sidebarReducer.openclosesidebar);
  return (
    <div>
      {sidebarOpen && <Sidebar />}
      <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
        <Navbar />
        <div>
          <ProfitabilityTable />
        </div>
      </div>
    </div>
  )
}

export default ProfitabilityTablePage