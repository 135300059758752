import React from "react";
import { Container } from "react-bootstrap";
import ResetPass from "../../components/Login/ResetPass";

const ResetPassword = () =>{
    return (
        <Container className="pt-5">
            <ResetPass />
        </Container>
    )
}

export default ResetPassword;