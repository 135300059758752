/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import {
    TableCell,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    IconButton,

} from "@material-ui/core";
import ErrorComponent from '../../../components/CommonComponent/ErrorComponet'
import Swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip'

import { Edit, Delete } from "@material-ui/icons";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { Pagination } from "@mui/material";
import moment from 'moment';
import Loader from "../../loadingAnimation/index";
import { ChannelPartnerListData, CPActiveData, ChannelGetData, deleteChannelpartner, UpdateChannelPartner } from '../../../pages/RoleMasterPages/AdminsPages/UserdetailsApi'
import { useEffect } from 'react';

const ChannelPartnerList = (props) => {
    const [loading, setLoading] = useState(false);
    const [chaPartData, setChaPartData] = useState([]);
    const [checked, setChecked] = useState(true);
    const [getAddChannelPartner, setAddChannelPartner] = useState();
    const [update, setUpdate] = useState(false);
    // pagination states
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);
    const dataPerPage = 10;

    const [showChannelPartner, setShowChannelPartner] = useState(false);
    const [data, setData] = useState({
        username: '',
        address: '',
        contactPerson: '',
        email: '',
        phone: '',
        pan: '',
        tan: '',
        gst: '',
        profession: '',
        bankName: '',
        branch: '',
        AccountNumber: '',
        IFSC: '',
        Cpassword: '',
        password: ''
    });
    const [usernewId, setUsernewId] = useState([]);
    const [formErr, setFormErr] = useState({})

    const handleReset = () => {
        setData({
            username: '',
            address: '',
            contactPerson: '',
            phone: '',
            pan: '',
            tan: '',
            gst: '',
            profession: '',
            bankName: '',
            AccountNumber: '',
            IFSC: '',
            branch: '',
        });
        setFormErr({});
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
        if (value !== null) {
            setFormErr(name)
        }
    }

    useEffect(() => {
        const pageCount = Math.ceil(props.pageNo / dataPerPage)
        setPageCount(pageCount);
    }, [pageNumber])

    const changePage = (e, a) => {
        setPageNumber(a);
        channelParnterDetails(a, dataPerPage)
    }
    const handleCloseModal = () => {
        setShowChannelPartner(false)
    }

    useEffect(() => {
        setPageNumber(1)
        channelParnterDetails(1, 10)
    }, [])

    const channelParnterDetails = (pageNumber, dataPerPage) => {
        setLoading(true)
        ChannelPartnerListData(pageNumber, dataPerPage).then((res) => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    setLoading(false);
                    let dataList = res.data._response.data.channelPartners
                    let count = res.data._response.data.count;
                    const pageCount = Math.ceil(count / dataPerPage)
                    setPageCount(pageCount)
                    setChaPartData(dataList);
                }
            }
        }).catch((err) => {
            setLoading(false);
        })
    }

    const handleupdateChange = (e, channelPartnerId) => {
        setLoading(true);
        const { checked } = e.target;
        const activeId = checked === true ? 1 : 0
        CPActiveData(activeId, channelPartnerId, pageNumber, dataPerPage).then((res) => {

            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    const data = res.data._response.data.channelPartnerDetails;
                    // const chData = data.filter((a) => a.roleId === 3)
                    setChaPartData(data);
                    // let count = res.data._response.data.count;
                    // const pageCount = Math.ceil(count / dataPerPage)
                    // setPageCount(pageCount)

                    // channelParnterDetails(1, 10)

                } else {
                    setChecked(false)
                }
            }
        }).catch((e) => {
            setChecked(false)
            console.log(e, "err in channelpartner");
        })


    }

    //get channel partner data
    useEffect(() => {
        if (getAddChannelPartner !== undefined) {
            setUsernewId(getAddChannelPartner.userId)
            setData({
                username: getAddChannelPartner.channelPartnerName,
                address: getAddChannelPartner.address,
                contactPerson: getAddChannelPartner.contactPerson,
                email: getAddChannelPartner.channelPartnerEmail,
                phone: getAddChannelPartner.channelPartnerPh,
                pan: getAddChannelPartner.pan,
                tan: getAddChannelPartner.tan,
                gst: getAddChannelPartner.gst,
                profession: getAddChannelPartner.profession,
                bankName: getAddChannelPartner.nameOfBank,
                branch: getAddChannelPartner.branch,
                AccountNumber: getAddChannelPartner.accountNumber,
                IFSC: getAddChannelPartner.ifscCode,
                Cpassword: getAddChannelPartner.channelPartnerPassword,
                password: getAddChannelPartner.channelPartnerPassword
            })
        }
    }, [getAddChannelPartner])

    const updateChannelParnter = (e, userId) => {
        setShowChannelPartner(true)
        setLoading(true);
        ChannelGetData(userId).then((res) => {
            setLoading(false);
            if (res.data._response.status === 1) {
                let result = res.data._response.data.channelPartnerDetails
                setAddChannelPartner(result);
                setUpdate(true)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false);
        })
    }

    const handleUpdate = (e) => {
        const payloadUpdate = {
            userId: usernewId,
            subcriberName: data.username,
            subcriberEmail: data.email,
            contactPerson: data.contactPerson,
            channelPartnerPh: data.phone,
            acceptTerms: true,
            UserTypeId: 3,
            roleName: "rolename",
            nameOfBank: data.bankName,
            branch: data.branch,
            accountNumber: data.AccountNumber,
            ifscCode: data.IFSC,
            pan: data.pan,
            tan: data.tan,
            gst: data.gst,
            profession: data.profession,
            address: data.address,
            subcriberPassword: data.password,
            confirmPassword: data.Cpassword,
        }
        setLoading(true);
        UpdateChannelPartner(payloadUpdate)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data._response.status === 1) {
                        Swal.fire("Success!", response.data._response.message, 'success')
                        setLoading(false);
                        setShowChannelPartner(false)
                        channelParnterDetails(1, 10)
                    }
                } else {
                    setLoading(false);
                }

            }).catch((err) => {
                setLoading(false);
            })

    }

    const deleteChannelPartner = (e, channelPartnerId) => {
        setLoading(true);
        let data = {
            channelPartnerId: channelPartnerId
        }
        deleteChannelpartner(data).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    Swal.fire("", res.data._response.message, "error");
                    channelParnterDetails(1, 10)
                }

            }
        }).catch((error) => {
            console.log(error, "error in delete channelparter")
        })

    }

    return (
        <>
            <Modal show={showChannelPartner} onHide={() => handleCloseModal()} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Channel Partner</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row className="form-set">
                                <Form.Group className="col-md-4" >
                                    <Form.Label> Name  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        autoComplete='off'
                                        onChange={handleChange}
                                        value={data.username}
                                        placeholder="Enter Name" />
                                    {formErr ? <ErrorComponent formErr={formErr.username} /> : null}
                                </Form.Group>

                                <Form.Group className="col-md-4" >
                                    <Form.Label> Contact Person  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="contactPerson"
                                        min="0"
                                        autoComplete='off'
                                        onChange={handleChange}
                                        value={data.contactPerson}
                                        placeholder="Enter Contact Person" />
                                    {formErr ? <ErrorComponent formErr={formErr.contactPerson} /> : null}
                                </Form.Group>

                                <Form.Group className="col-md-4" >
                                    <Form.Label> Mobile Number</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="phone"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.phone}
                                        placeholder="Enter Mobile Number"
                                        autoComplete='off'
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.phone} /> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-12" >
                                    <Form.Label> Address  </Form.Label>
                                    <Form.Control
                                        as="textarea" rows={2}
                                        type="text"
                                        name="address"
                                        autoComplete='off'
                                        onChange={handleChange}
                                        value={data.address}
                                        placeholder="Enter Address" />
                                    {formErr ? <ErrorComponent formErr={formErr.address} /> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set ">
                                <Form.Group className="col-md-3" >
                                    <Form.Label> PAN  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pan"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.pan}
                                        placeholder="Enter PAN "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.pan} /> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label> TAN  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tan"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.tan}
                                        placeholder="Enter TAN "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.tan} /> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label> GST  </Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="gst"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.gst}
                                        placeholder="Enter GST "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.gst} /> : null}
                                </Form.Group>

                                <Form.Group className="col-md-3" >
                                    <Form.Label> Profession  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="profession"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.profession}
                                        placeholder="Enter Profession "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.profession} /> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">

                                <Form.Group className="col-md-3" >
                                    <Form.Label> Name of bank  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bankName"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.bankName}
                                        placeholder="Enter Bank Name "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.bankName} /> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label> Branch Name </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="branch"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.branch}
                                        placeholder="Enter Branch Name "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.branch} /> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label> Account Number  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="AccountNumber"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.AccountNumber}
                                        placeholder="Enter Account Number "
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.AccountNumber} /> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label> IFSC Code  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="IFSC"
                                        min="0"
                                        onChange={handleChange}
                                        value={data.IFSC}
                                        placeholder="Enter IFSC code"
                                        autoComplete="off"
                                    />
                                    {formErr ? <ErrorComponent formErr={formErr.IFSC} /> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Col md="12" className="text-center mt-3">
                                    <Button variant="primary" className="mx-2 w100"
                                        onClick={handleUpdate}
                                    >
                                        Update</Button>
                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="text-center py-3">Channel Partner List</h4>
                    </Col>
                </Row>
                <div className="mt-2 p-5 pt-1  justify-content-center">
                    {loading ? <Loader /> : null}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="UserdetWrap p-3 pb-0">

                        <TableContainer >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="10%">
                                            Channel Partner Id
                                        </TableCell>
                                        <TableCell width="12%">
                                            Channel Partner Name
                                        </TableCell>
                                        <TableCell width="15%">
                                            Email
                                        </TableCell>
                                        <TableCell width="10%" align="right">
                                            Date
                                        </TableCell>
                                        <TableCell width="10%" align="right">
                                            Contact
                                        </TableCell>
                                        <TableCell width="12%" align="right">
                                            Per(%) of Commission
                                        </TableCell>
                                        <TableCell width="10%" align="right">
                                            Active/Deactive
                                        </TableCell>
                                        <TableCell width="5%" align="right">
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {chaPartData.map((c, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell width="10%">
                                                    {c.channelPartnerId}
                                                </TableCell>
                                                <TableCell width="12%">
                                                    {c.channelPartnerName}
                                                </TableCell>
                                                <TableCell width="15%">
                                                    {c.channelPartnerEmail}
                                                </TableCell>
                                                <TableCell width="10%" align="right">
                                                    {moment(c.crDate).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell width="10%" align="right">
                                                    {c.channelPartnerPh === null ? "-" : c.channelPartnerPh}
                                                </TableCell>
                                                <TableCell width="12%" align="right">
                                                    {c.perCommission}
                                                </TableCell>
                                                <TableCell width="10%" align="right">
                                                    <Switch
                                                        checked={c.active === 1 ? true : false}
                                                        onChange={(e) => handleupdateChange(e, c.channelPartnerId)}
                                                        value={c.channelPartnerId}
                                                        style={{
                                                            position: "absolute",
                                                            top: "-5px",
                                                            left: "-15px",
                                                            width: "70px",
                                                            height: "45px"
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell width="5%" align="center">
                                                    <Tooltip title="Edit">
                                                        <IconButton>
                                                            <Edit fontSize="small"
                                                                onClick={(e) => updateChannelParnter(e, c.userId)}
                                                                data-dismiss="modal" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton>
                                                            <Delete fontSize="small"
                                                                onClick={(e) => deleteChannelPartner(e, c.channelPartnerId)}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>


                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <div style={{ position: "relative", float: "right" }}>

                                <Pagination
                                    count={pageCount}
                                    page={pageNumber}
                                    onChange={changePage}
                                    variant="outlined" color="primary"
                                />
                            </div>
                        </TableContainer>
                    </Paper>
                </div>
            </Container>
        </>
    )
}

export default ChannelPartnerList;
