/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Edit } from "@material-ui/icons";
import Tooltip from '@mui/material/Tooltip'
import { Stack, TableBody } from '@mui/material';
import { GetAddSubscriber, DeleteSubscriber ,activeDeactiveSubscriber } from '../../../../pages/RoleMasterPages/ChannelPartnerPages/ChannelPartnerApi';
import Loader from '../../../loadingAnimation/index'
import  Pagination  from "@mui/material/Pagination";
import UpdateUserTable from './UpdateUserTable';
import Delete from '@mui/icons-material/Delete';
import swal from "sweetalert";
import Switch from '@mui/material/Switch';



const SubscriberUserDetails = (props) => {


  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [showUser, setShowUser] = useState(false)
  const [subcriberId, setSubcriberId] = useState();
  const [userTypeId, setUserTypeId] = useState()
  const [userData, setUserData] = useState({})

  const [userId, setUserId] = useState(0)
  const [checked, setChecked] = useState(true);

  //  pagination states
  const [userDetailsCount, setUserDetailsCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getAllSubscriber();
    // childFunc.current = getAllSubscriber
  }, [pageNumber, rowsPerPage])

  const getAllSubscriber = () => {
    setLoading(true);
    GetAddSubscriber(pageNumber, rowsPerPage).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          const newData = res.data._response.data.subscribers
          const count = res.data._response.data.count
          setUserDetails(newData);
          setLoading(false);
          setUserDetailsCount(count)
          const pageCount = Math.ceil(count / rowsPerPage)
          setUserDetailsCount(pageCount)
        }
      }
    })
      .catch((e) => {
        setLoading(false)
        console.log(e, "error in subscriber");
      })
  }

  const changePage = (e, a) => {
    setPageNumber(a);
   
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPageNumber(0);
  // };

  const alertMyRow = (e, d) => {
    setShowUser(true)
    setSubcriberId(d.subcriberId)
    setUserTypeId(d.subcriberType)
    setUserId(d.userId)
    const userData = {
      name: d.subcriberName,
      contactPerson: d.contact,
      mobileNumber: d.subcriberPh1,
      email: d.subcriberEmail,
      pincode: d.pinCode,
      address: d.subcriberAdd1
    }
    setUserData(userData)

  }

  const deleteRow = (e, d) => {
    swal({
      title: "Are you sure, you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((del) => {
      // debugger
      setLoading(true)
      const subscriberIdData = {
        subcriberId:d.subcriberId 
        }
      DeleteSubscriber(subscriberIdData).then((res) => {
        if (res.data._response.status === 1) {
          setLoading(false);
          swal("Subscriber deleted successfully ", "", "success");
          getAllSubscriber()
        }
        else {
          setLoading(false);
          getAllSubscriber()
        }
      })
      .catch((err)=>{
        setLoading(false);
        console.log(err);
      })
    })
  }

  // const handleLimit = (e) => {
  //   let limit = e.target.value;
  //   setRowsPerPage(limit)
  // }

  const handleClose = () => {
    setShowUser(false);
  }

  const handleChangeSwitch = (e,subcriberId) => {
    setLoading(true)
    const { checked } = e.target
    const activeId = checked === true ? 1 : 0
    // console.log("checked",checked);
    // console.log("activeId",activeId);
    activeDeactiveSubscriber(subcriberId,activeId,pageNumber,rowsPerPage).then((res)=>{
      if(res.status === 200){
        setLoading(false)
        if (res.data._response.status === 1) {
          const data = res.data._response.data.subcriberDetails;
          setUserDetails(data);

      } else {
          setChecked(false)
      }
          // console.log(res);
      }
    }).catch((err)=>{
      // console.log(err);
      setChecked(false)

    })
  }

  return (
    <div className='container mt-5'>
      {loading ? (
        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
      ) : null}
      <Paper sx={{ width: '80%', overflow: 'hidden' ,height:'70%' }} >
        <h4 className='text-center mb-4'> Subscriber List</h4>
        <TableContainer sx={{ maxHeight: 440 }} >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                <TableCell align="right" width='9%'>
                  Name
                </TableCell>
                <TableCell align="right" width='9%'>
                  Contact Person
                </TableCell>
                <TableCell align="right" width='9%'>
                  Mobile Number
                </TableCell>
                <TableCell align="right" width='9%'>
                  Email
                </TableCell>

                <TableCell align="right" width='9%'>
                  Pincode
                </TableCell>
                <TableCell align="right" width='9%'>
                  Address
                </TableCell>
                <TableCell align="right" width='9%'>
                  Active / Deactive
                </TableCell>
                <TableCell align="right" width="6%">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "auto" }}>
              {userDetails.map((a) => (
                // console.log(a)
                <TableRow key={a.subcriberId} sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  }
                }}>
                  <TableCell align='left'>
                    {a.subcriberName}
                  </TableCell>
                  <TableCell align='left'>
                    {a.contact}
                  </TableCell>
                  <TableCell align='right'>
                    {a.subcriberPh1}
                  </TableCell>
                  <TableCell align='left'>
                    {a.subcriberEmail}
                  </TableCell>

                  <TableCell align='right'>
                    {a.pinCode}
                  </TableCell>
                  <TableCell align='left'>
                    {a.subcriberAdd1}
                  </TableCell>
                  <TableCell align='right'>
                  <Switch
                    checked={a.active === 1 ? true : false}
                    onChange={(e) => handleChangeSwitch(e,a.subcriberId)}
                    value={a.subcriberId}
                    size="small"
                    style={{
                        position: "absolute",
                        // top: "-5px",
                        // left: "-15px",
                        // width: "50px",
                        // height: "25px"
                    }}
                /> 
                  </TableCell> 
                  <TableCell align='right'>
                    <Tooltip title="Edit">
                      <IconButton>
                        <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} data-dismiss="modal" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton>
                        <IconButton><Delete fontSize="small" onClick={(e) => deleteRow(e, a)} /></IconButton>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className='form-group d-flex justify-content-end'>
            {/* <button type="button" className="btn   mx-2">
              Limit
              <select
                name="limit"
                id=""
                value={rowsPerPage}
                onChange={handleLimit}
                style={{
                  backgroundColor: "#e8e8e8",
                  border: "none",
                  marginLeft: "10px",
                  borderRadius: "3px",
                }}
                // disabled
              >
                <option>5</option>
                <option>10</option>
                <option>25</option>
              </select>
            </button> */}
            <Stack spacing={2} className='m-3' >
            <Pagination
              count={userDetailsCount}
              page={pageNumber}
              onChange={changePage}
              variant="outlined" color="primary"
              shape="rounded"  size="small" 
            />
            </Stack>
          </div>
        </TableContainer>
      </Paper>
      <UpdateUserTable showUser={showUser} userId={userId} userTypeId={userTypeId} subcriberId={subcriberId} userAllData={userData} onHideuser={handleClose} getAllSubscriber={getAllSubscriber} />
    </div>
  )
}

export default SubscriberUserDetails