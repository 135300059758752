/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// import { Button } from '@material-ui/core';

const PreviewBullion = (props) => {
    const { showPreview, hidePreview, bullionHeaderId, BullionTransaction } = props;

    const [previewDetails, setPreviewDetails] = useState({
        transactionType: '',
        TypeofInv: '',
        Unit: 0,
        InvDescri: '',
        Quantity: 0,
        AmtOFInv: 0,
        Rate: 0,
        CurrMarPrice: 0,
        SellValue: 0
    })

    useEffect(() => {

        if (showPreview === true) {
            if (bullionHeaderId !== 0) {
                if (Object.keys(BullionTransaction).length !== 0) {
                        setPreviewDetails({
                            ...previewDetails, transactionType: BullionTransaction.transactType,
                            TypeofInv: BullionTransaction.typeOfInvestment.investmentName,
                            Unit: BullionTransaction.investmentUnit,
                            InvDescri: BullionTransaction.investmentDescription,
                            Quantity: BullionTransaction.quantity,
                            AmtOFInv: BullionTransaction.amountOfInvestment,
                            Rate: BullionTransaction.rate,
                            CurrMarPrice: BullionTransaction.currentMarketPrice,
                            SellValue: BullionTransaction.saleValue
                        })
                }
            }

        }
    }, [showPreview]);
    const handleClosePreview = () => {
        hidePreview(1)
        setPreviewDetails({
            transactionType: '',
            TypeofInv: '',
            Unit: 0,
            InvDescri: '',
            Quantity: 0,
            AmtOFInv: 0,
            Rate: 0,
            CurrMarPrice: 0,
            SellValue: 0
        })
    }
    return (
        <div tabIndex={4}>
            <Modal isOpen={showPreview} toggle={handleClosePreview} size="md" >
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleClosePreview()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    {BullionTransaction.transactType === 'Buy' ? <h5>Bullion BUY</h5> : <h5>Bullion Sell</h5>}
                </ModalHeader>
                <ModalBody>
                    <div className="transactionData "><div className="transactionDataTitle">Type Of Investment: </div>
                        <div className="transactionDataValue">{previewDetails.TypeofInv}</div></div>
                    {BullionTransaction.transactType === 'Buy' ?
                        <div className="transactionData odd"><div className="transactionDataTitle">Unit: </div>
                            <div className="transactionDataValue">{BullionTransaction.investmentUnit ? BullionTransaction.investmentUnit : "-"}</div></div>
                        :
                        <div className="transactionData odd"><div className="transactionDataTitle">Stock Valution Type: </div>
                            <div className="transactionDataValue">{"Selection"}</div></div>
                    }
                    {BullionTransaction.transactType === 'Buy' ?
                        <div className="transactionData "><div className="transactionDataTitle">Investment Description: </div>
                            <div className="transactionDataValue">{BullionTransaction.investmentDescription}</div></div>
                        : null}
                    <div className={BullionTransaction.transactType === 'Buy' ? "transactionData odd" : "transactionData"}><div className="transactionDataTitle">Quantity: </div>
                        <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(BullionTransaction.quantity)}</div></div>

                    {BullionTransaction.transactType === 'Buy' ?
                        <div className="transactionData "><div className="transactionDataTitle">Amount Of Investment: </div>
                            <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(BullionTransaction.amountOfInvestment)}</div></div>
                        : null}

                    <div className="transactionData odd"><div className="transactionDataTitle">Rate: </div>
                        <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(BullionTransaction.rate)}</div></div>
                    {previewDetails.transactionType === "Buy" ?
                        <div className="transactionData "><div className="transactionDataTitle">Current Market Price: </div>
                            <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(BullionTransaction.currentMarketPrice)}</div></div>
                        :
                        <div className="transactionData "><div className="transactionDataTitle">Sell value: </div>
                            <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(BullionTransaction.saleValue)}</div></div>
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PreviewBullion;