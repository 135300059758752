import React from 'react'
import ChannelDashboard from '../../../components/RoleMaster/ChannelMaster/ChannelDashboard';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const ChannelpartnerDashboardPage = () => {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
        {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar/>
            <div>
                <ChannelDashboard />
            </div>
        </div>
    </div>
  )
}

export default ChannelpartnerDashboardPage