/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import $ from "jquery";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import AddIcon from "@mui/icons-material/Add";
import _, { identity } from "lodash";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip'
import {
  // JsonHubProtocol,
  // HubConnectionState,
  HubConnectionBuilder,
  //LogLevel
} from '@microsoft/signalr';
import Auth from '../../modules/Auth'

import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import SearchBar from "material-ui-search-bar";

import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TableContainer,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import StocksDetail from "../StocksDetail/StocksDetail";
import { Edit, Visibility, Delete } from "@material-ui/icons";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  GetStocksList,
  EntityWiseStockList,
  GetBuySellGrid,
  GetStockTransaction,
  GetDMergerDetails,
  EntityList,
  DeleteOtherTransaction,
  getSignalRList,
  getSingleStockTransactionDataPreview,
  getOuterGridCalculationBySubscriberId,
  getOuterGridCalculationSubId

} from "../../pages/Stocks/StocksAPI";
import { DashboardFamily, DashboardEntity } from "../Dashboard/RecentTranAPI/RT_API"
import Stockdropdown from "../sidebar/Stockdropdown";
import Loader from "../loadingAnimation/index";
import Bonusmodal from "./other_transactions/Bonusmodal";
import RightsShare from "./other_transactions/RightsShare";
import IPOShare from "./other_transactions/IPOShare";
import StockMergermodal from "./other_transactions/StockMergermodal";
import Splitmodal from "./other_transactions/Splitmodal";
import Demerger from "./other_transactions/Demerger";
import PartlyPaid from "./other_transactions/partlypaid";
import BuyBack from "./other_transactions/BuyBack";
import Convertible from "./other_transactions/Convertible";
import Reedemable from "./other_transactions/Reedemable";

// import ContractNote from "../contract_note/ContractUpload";
import ContractNote from "../contract_note/ContractNote";
import TradingData from "../StocksDetail/TradingData";
// import Swal from "sweetalert2";
import swal from 'sweetalert';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
//import { KiteTicker } from 'kiteconnect';
import { useLocation } from "react-router-dom";
const userID = Auth.getsubcriberId();


const StockFolio = (props) => {
  const [script, setScript] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [showStockDetail, setShowStockDetail] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [showInnerGrid, setShowInnerGrid] = useState(false);
  const [iconsTest, setIconTest] = useState(AddIcon);
  const [stockData, setStockData] = useState([]);
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [gridDetail, setGridDetail] = useState({});
  const [stockHeaderId, setStockHeaderId] = useState(0);
  const [stockTransaction, setStockTransaction] = useState({});
  const [sell, setSell] = useState([]);
  const [loading, setLoading] = useState(false);

  const [previewTransaction, setPreviewTransaction] = useState('');

  // modal transaction
  const [bonusopen, setbonusopen] = useState(false);
  const [splitopen, setsplitopen] = useState(false);
  const [mergeropen, setmergeropen] = useState(false);
  const [rightsopen, setrightsopen] = useState(false);
  const [ipoopen, setipoopen] = useState(false);
  const [demergeropen, setdemergeropen] = useState(false)
  const [buyBackopen, setbuyBackopen] = useState(false);
  const [partlyPaidOpen, setPartlyPaidOpen] = useState(false);
  const [convertibleOpen, setConvertibleOpen] = useState(false);
  const [redeemableOpen, setRedeemableOpen] = useState(false);

  const [isLivePriceApi, setIsLivePriceApi] = useState(false);

  // preview transaction
  const [previewstocksopen, setpreviewstocksopen] = useState(false);
  const [previewmergeropen, setpreviewmergeropen] = useState(false);
  const [previewbonusopen, setpreviewbonusopen] = useState(false);
  const [previewsplitopen, setpreviewsplitopen] = useState(false);
  const [previewrightsopen, setpreviewrightsopen] = useState(false);
  const [previewipoopen, setpreviewipoopen] = useState(false);
  const [previewdemergeropen, setpreviewdemergeropen] = useState(false);
  const [previewbuyBackopen, setpreviewbuyBackopen] = useState(false);
  const [previewPartlyPaidOpen, setpreviewPartlyPaidOpen] = useState(false);
  const [previewConvertibleOpen, setpreviewConvertibleOpen] = useState(false);
  const [previewRedeemableOpen, setpreviewRedeemableOpen] = useState(false);

  //modaldata
  const [TransactionType, setTransactionType] = useState([]);
  const [stockId, setStockId] = useState([]);
  const [stockIdD1, setstockId1] = useState([])
  const [stockIdD2, setstockId2] = useState([])

  const [demgerData, setDemergerData] = useState([])
  const [entityId, setEntityId] = useState(null);
  const [subId, setSubId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [livePrice, setLivePrice] = useState(null);

  const [nonZero, setnonZero] = useState(0);
  const [nonZeroData, setNonZeroData] = useState([])
  const [searched, setSearched] = useState("");
  //all header
  const [filteredFamilyData, setFilteredFamilyData] = useState({
    prevLivePrice: 0,
    livePrice: 0,
    quantity: 0,
    totalInvestment: 0,
    daygain: 0,
    daysgainper: 0,
    overallgain: 0,
    overallgainper: 0,
    currentValue: 0
  });

  const [filterEntityData, setfilterEntityData] = useState({
    prevLivePricec: 0,
    livePricec: 0,
    quantityc: 0,
    totalInvestmentc: 0,
    daysgainc: 0,
    daysgainperc: 0,
    overallgainc: 0,
    overallgainperc: 0,
    currentValuec: 0
  });


  // total calculation outergrid
  const [totalValues, setTotalValues] = useState({
    instrumentTypeId: 0,
    instrumentTypeName: "",
    livePrice: 0,
    prevLivePrice: 0,
    quantity: 0,
    subcriberId: 0,
    totalInvestements: 0,
    daysgain: 0,
    daysgainperc: 0,
    overallgain: 0,
    overallgainperc: 0,
    currentValue: 0
  });
  const [totalData, setTotalData] = useState({
    daysGainSum: 0,
    daysGainSumPer: 0,
    overAllGainSum: 0,
    overAllGainSumPer: 0,
    CurrentValue: 0,
    totalInvestements: 0
  });


  const [entityData, setEntityData] = useState([]);

  //contract note
  const [contractOpen, setContractOpen] = useState(false);
  const [previewContractOpen, setPreviewContractOpen] = useState(false);
  //treading data
  const [tradingDataOpen, setTradingDataOpen] = useState(false);

  const [connection, setConnection] = useState(null);
  const userIDs = Auth.getsubcriberId();
  const location = useLocation();
  const { state } = location;
  const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    if(state){
        let companyId = state.companyId;
        let subAccountId = state.subAccountId;
        let companyName = state.companyName;
        let livePrice = state.livePrice
        getBuyGetData(subAccountId,companyId,companyName,livePrice);
        setShowInnerGrid(true);
    }
  }, [state])




  useEffect(() => {
    if (userID) {
      getEntityList();
      getEntityData(userID)
    }
    getFamilyData();
  }, [userID]);

  // //header value all
  const getFamilyData = (disable) => {
    if (entity === "Family") {
      DashboardFamily().then((res) => {

        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false);
            const familyData = res.data._response.data.fetchMenuInvestmentGridBySubscriberId;
            const found = familyData.find(element => element.instrumentName === "Stocks");
            setFilteredFamilyData({
              // livePrice: found.livePrice,
              // prevLivePrice: found.prevLivePrice,
              // quantity: found.quantity,
              totalInvestment: found.totalInvestment,
              daygain: found.daysGain,
              daysgainper: found.daysGainPerc,
              overallgain: found.overallGain,
              overallgainper: found.overallGainPerc,
              currentValue: found.currentValue
            })

          } else {
            setLoading(false);
            setFilteredFamilyData({

              livePrice: 0,
              prevLivePrice: 0,
              quantity: 0,
              subcriberId: 0,
              totalInvestment: 0,
              daygain: 0,
              daysgainper: 0,
              overallgain: 0,
              overallgainper: 0,
              currentValue: 0,
            })
          }

        } else {
          setLoading(false);

        }

      }).catch((e) => {
        setLoading(false);
        console.log(e.message);
      })
    }

  }

  const getEntityData = (id) => {
    DashboardEntity(id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            const EntityStock = res.data._response.data.fetchMenuInvestmentGridBySubAccountId;
            const found = EntityStock.find(element => element.instrumentName === "Stocks");
            setfilterEntityData({
              totalInvestmentc: found.totalInvestment,
              daysgainc: found.daysGain,
              daysgainperc: found.daysGainPerc,
              overallgainc: found.overallGain,
              overallgainperc: found.overallGainPerc,
              currentValuec: found.currentValue
            })


            setLoading(false);
          } else if (res.data._response.status === 2) {
            setLoading(false);
            setfilterEntityData({
              livePrice: 0,
              prevLivePricec: 0,
              quantityc: 0,
              totalInvestmentc: 0,
              daysgainc: 0,
              daysgainperc: 0,
              overallgainc: 0,
              overallgainperc: 0,
              currentValuec: 0
            });
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e.message, "error");
      });

  }

  

  const handleChangeEntity = async (e) => {
    setLoading(true)
    await setEntity(e.target.value);

    if (e.target.value === "Family") {
      setEntityId(null);
      getStock();
      // FamilyWiseStockCalculations(script)
      // getFamilyData()
      setScript(1);
      setnonZero(0)
      setSearched("");
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName === e.target.value;
      });
      $('#table th:nth-child(1),#table td:nth-child(1)').remove();
      await setEntityId(data.subAccountId);
      await EntityWiseStockListGrid(data.subAccountId ,nonZero)
      await getEntityData(data.subAccountId);
      FamilyWiseStockCalculations(script, data.subAccountId)
      // setScript(1);
      setScript(1);
      setSearched("");
      setfilterEntityData({
        prevLivePricec: 0,
        livePricec: 0,
        quantityc: 0,
        totalInvestmentc: 0,
        daysgainc: 0,
        daysgainperc: 0,
        overallgainc: 0,
        overallgainperc: 0,
        currentValuec: 0
      });
      setTotalData({
        daysGainSum: 0,
        daysGainSumPer: 0,
        overAllGainSum: 0,
        overAllGainSumPer: 0,
        CurrentValue: 0,
        totalInvestements: 0
      })
    }
  };

  //footer calculation by SubscriberId and instrument type
  const FamilyWiseStockCalculations = (instuid, EntityID) => {
    let instrumentidnew = instuid === "All" ? 0 : instuid;
    // if (entity === "Family") {
    if (EntityID === undefined) {


      getOuterGridCalculationBySubscriberId(instrumentidnew)
        .then((res) => {
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              // let instrumentid = Number(instuid)
              let stockFamilyOuterGrid = res.data._response.data.stockFamilyOuterGrid;

              // group common companies data
              let check = [];
              stockFamilyOuterGrid.forEach((item, i) => {
                if (check.indexOf(item.companyName) === -1) {
                  check.push(item.companyName);
                }
              });
              var parentData = [];
              check.forEach((checkdata, index) => {
                let mainData = {
                  livePrice: 0,
                  previousLivePrice: 0,
                  quantity: 0,
                  closingBalance: 0,
                  name: "",
                };
                stockFamilyOuterGrid.forEach((item, i) => {
                  if (checkdata === item.companyName) {
                    let livePrice = item.livePrice ? item.livePrice : 0;
                    let previousLivePrice = item.prevLivePrice ? item.prevLivePrice : 0;
                    let quantity = mainData.quantity + item.quantity;
                    let cost = mainData.closingBalance + item.totalInvestment;
                    mainData = {
                      livePrice: livePrice,
                      previousLivePrice: previousLivePrice,
                      quantity: quantity,
                      closingBalance: cost,
                      companyName: item.companyName,
                      id: index
                    };
                  }
                });
                parentData.push(mainData);
              })
              //common obj for calculation
              let calculationTotals = {
                daysGainSum: 0,
                daysGainSumPer: 0,
                overAllGainSum: 0,
                overAllGainSumPer: 0,
                CurrentValue: 0,
                totalInvestements: 0
              }
              // calculate day'sgain , overallGAin etc. for individual company 
              parentData.forEach((d, i) => {
                let a = ((d.livePrice - d.previousLivePrice) * d.quantity).toFixed(2);
                let b = d.livePrice ? ((d.livePrice * d.quantity - d.closingBalance).toFixed(2)) : 0.00;
                let c = (d.quantity * d.livePrice).toFixed(2);
                let e = (((d.livePrice - d.previousLivePrice) ) / (d.previousLivePrice )) * 100
                d.daysGainSum = Number(a);
                d.currentValue = Number(c);
                d.daysGainSumPer = Number(e);
                d.overAllGainSum = Number(b);
                // d.overAllGainSumPer = preciseMathDotRound((d.overAllGainSum / d.closingBalance) * 100);

                //push the calculation  data in common object
                calculationTotals.overAllGainSum += d.overAllGainSum;
                // calculationTotals.overAllGainSumPer += d.overAllGainSumPer;
                calculationTotals.daysGainSum += d.daysGainSum;
                calculationTotals.daysGainSumPer += isNaN(d.daysGainSumPer) ? 0 : d.daysGainSumPer;
                calculationTotals.totalInvestements += d.closingBalance;
                calculationTotals.CurrentValue += Number(instuid) === 10 || Number(instuid) === 23 || Number(instuid) === 24 ? d.closingBalance : d.currentValue;

              })
              calculationTotals.overAllGainSumPer = (calculationTotals.overAllGainSum / calculationTotals.totalInvestements) * 100
              // calculationTotals.daysGainSumPer = (calculationTotals.daysGainSum / calculationTotals.totalInvestements) * 100
              // setTotalData(calculationTotals)
              setTotalData(calculationTotals)
              setLoading(false);

            } else {
              setLoading(false);
              setTotalValues([]);
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e,"error  in family totals");
        });

    } else {
      if (instuid && EntityID) {
        getOuterGridCalculationSubId(EntityID, instrumentidnew)
          .then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                let stockEntityOuterGrid = res.data._response.data.stockEntityOuterGrid;
                // group common companies data 
                let check = [];
                stockEntityOuterGrid.forEach((item, i) => {
                  if (check.indexOf(item.companyName) === -1) {
                    check.push(item.companyName);
                  }
                });
                var parentData = [];
                check.forEach((checkdata, index) => {
                  let mainData = {
                    livePrice: 0,
                    previousLivePrice: 0,
                    quantity: 0,
                    closingBalance: 0,
                    name: "",
                  };
                  stockEntityOuterGrid.forEach((item, i) => {
                    if (checkdata === item.companyName) {
                      let livePrice = item.livePrice ? item.livePrice : 0;
                      let previousLivePrice = item.prevLivePrice ? item.prevLivePrice : 0;
                      let quantity = mainData.quantity + item.quantity;
                      let cost = mainData.closingBalance + item.totalInvestment;
                      mainData = {
                        livePrice: livePrice,
                        previousLivePrice: previousLivePrice,
                        quantity: quantity,
                        closingBalance: cost,
                        companyName: item.companyName,
                        id: index
                      };
                    }
                  });
                  parentData.push(mainData);
                })
                //common obj for calculation
                let calculationTotals = {
                  daysGainSum: 0,
                  daysGainSumPer: 0,
                  overAllGainSum: 0,
                  overAllGainSumPer: 0,
                  CurrentValue: 0,
                  totalInvestements: 0
                }

                // calculate day'sgain , overallGAin etc. for individual company 
                parentData.forEach((d, i) => {
                  let a = ((d.livePrice - d.previousLivePrice) * d.quantity).toFixed(2);
                  let b = d.livePrice ? ((d.livePrice * d.quantity - d.closingBalance).toFixed(2)) : 0.00;
                  let c = (d.quantity * d.livePrice).toFixed(2);
                  let e = (((d.livePrice - d.previousLivePrice)) / (d.previousLivePrice)) * 100
                  d.daysGainSum = Number(a);
                  d.currentValue = Number(c);
                  d.daysGainSumPer = Number(e);
                  d.overAllGainSum = Number(b);
                  // d.overAllGainSumPer = preciseMathDotRound((d.overAllGainSum / d.closingBalance) * 100);

                  //push the calculation  data in common object
                  calculationTotals.overAllGainSum += d.overAllGainSum;
                  // calculationTotals.overAllGainSumPer += d.overAllGainSumPer;
                  calculationTotals.daysGainSum += d.daysGainSum;
                  calculationTotals.daysGainSumPer += isNaN(d.daysGainSumPer) ? 0 : d.daysGainSumPer;
                  calculationTotals.totalInvestements += d.closingBalance;
                  calculationTotals.CurrentValue += Number(instuid) === 10 || Number(instuid) === 23 || Number(instuid) === 24 ? d.closingBalance : d.currentValue;

                })
                calculationTotals.overAllGainSumPer = (calculationTotals.overAllGainSum / calculationTotals.totalInvestements) * 100
                // calculationTotals.daysGainSumPer = (calculationTotals.daysGainSum / calculationTotals.totalInvestements) * 100

                // setTotalData(calculationTotals)
                setTotalData(calculationTotals)
                setLoading(false);

              } else {
                setLoading(false);
                setTotalValues([]);
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e,"error  in entity totals");
          });
      }
    }


  }
  //entity grid
  const EntityWiseStockListGrid = (id, nonZero) => {
 let newEntityID = id == null ? entityId : id
    EntityWiseStockList(newEntityID, nonZero)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let EntityStock = res.data._response.data.individualEntity;
            setLoading(false);

            // include exclude zero
            // let sortedNonzero = nonZero === 0 ?
            //   EntityStock :
            //   EntityStock.filter((a) => a.quantity !== 0 && a.instrumentTypeId === script)


            // default listed
            let sortedEntity = EntityStock.filter((a) => (a.instrumentTypeId === 1))


            const entityStockList =
              sortedEntity &&
              sortedEntity.map((a, index) => {
                return { ...a, id: index + 1, company: a.companyName };
              });
            setFilteredEntiryData(entityStockList);
            setEntityData(EntityStock)
            getEntityData(id)

            //setLoading(false)
            setScript(1)
            // if (script == 1) {
            FamilyWiseStockCalculations(id)
            // }

          } else {
            setFilteredEntiryData([]);
            setEntityData([]);
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e,"error");
      });

  }


  const handleclose = async (val) => {

    setbonusopen(false);
    setsplitopen(false);
    setmergeropen(false);
    setipoopen(false);
    setbuyBackopen(false);
    setrightsopen(false);
    setdemergeropen(false);
    setPartlyPaidOpen(false);
    setConvertibleOpen(false)
    setRedeemableOpen(false);
    setpreviewbonusopen(false);
    setpreviewbuyBackopen(false);
    setpreviewConvertibleOpen(false);
    setpreviewdemergeropen(false);
    setpreviewipoopen(false);
    setpreviewPartlyPaidOpen(false);
    setpreviewRedeemableOpen(false);
    setpreviewrightsopen(false);
    setpreviewsplitopen(false);
    setpreviewmergeropen(false);
    setpreviewstocksopen(false);
    setShowStockDetail(false);

    if (val !== 1) onTransactionGridRefresh();
  };

  const alertMyRow = (selectedRow, a) => {
    setLoading(true);
    GetStockTransaction(a.stockHeaderId).then((res) => {
      setStockHeaderId(a.stockHeaderId);

      setStockId(a.stockId);
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          const result = res.data._response.data.stockTrnsactionDetails;
          setStockTransaction(result);
          //setbonusopen(true)
          if (a.transactStockType === "Merger") {
            setmergeropen(true);
            setStockHeaderId(a.stockHeaderId);
          } else if (a.transactStockType === "Bonus") {
            setbonusopen(true);
            // getStockTransactionDetails(stockHeaderId);
          } else if (a.transactStockType === "Split") {
            setsplitopen(true);
          } else if (a.transactStockType === "Stock Merger") {
            setmergeropen(true);
          } else if (a.transactStockType === "Rights") {
            setrightsopen(true);
          } else if (a.transactStockType === "IPO") {
            setipoopen(true);
          } else if (a.transactStockType === "DeMerger") {
            GetDMergerDetails(a.stockHeaderId)
              .then((res) => {
                if (res.data._response.status === 1) {
                  const demerge = res.data._response.data.stockTrnsactionDetails;
                  setDemergerData(demerge)
                  setstockId1(demerge.stockDetails[0].stockId)
                  setstockId2(demerge.stockDetails[1].stockId)
                  setdemergeropen(true);
                }
              }).catch(e => console.log(e, 'error in demerger'))

          } else if (a.transactStockType === "BuyBack" && userIDs) {
            setbuyBackopen(true);
          } else if (a.transactStockType === "Installment" || a.transactStockType === "Final") {
            setPartlyPaidOpen(true);
          } else if (a.transactStockType === "Converted") {
            setConvertibleOpen(true);
          } else if (a.transactStockType === "Redeemed") {
            setRedeemableOpen(true);
          } else {
            setShowStockDetail(true);
          }

        }
      }
      else {
        setLoading(false);
      }
    })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  };

  //filter stocks
  const handleScripData = (e) => {
    // reset values of footer calculation
    setTotalData({
      daysGainSum: 0,
      daysGainSumPer: 0,
      overAllGainSum: 0,
      overAllGainSumPer: 0,
      CurrentValue: 0,
      totalInvestements: 0
    })
    let selected = e.target.value;
    setScript(selected)

    // setnonZero("All")
    setSearched("");

    // let sorted = selected && nonZero === 0 ? stockData : stockData.filter
    //   ((a) => a.instrumentTypeId === parseInt(selected) && a.quantity !== 0)

    let check = [];
    stockData.forEach((item, i) => {
      if (check.indexOf(item.companyName) === -1) {
        check.push(item.companyName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        livePrice: 0,
        previousLivePrice: 0,
        quantity: 0,
        closingBalance: 0,
        name: "",
      };
      stockData.forEach((item, i) => {
        if (checkdata === item.companyName) {
          let livePrice = item.livePrice;
          let previousLivePrice = item.previousLivePrice;
          let quantity = mainData.quantity + item.quantity;
          let cost = mainData.closingBalance + item.closingBalance;
          mainData = {
            livePrice: livePrice,
            previousLivePrice: previousLivePrice,
            quantity: quantity,
            closingBalance: cost,
            companyName: item.companyName,
            id: index,
            instrumentToken: item.instrumentToken,
            instrumentTypeId: item.instrumentTypeId
          };
          let normalData = {
            ...item,
            companyName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.companyName,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    
    setFilteredData([...parentData, ...childData]);

    //entity filter

    let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.instrumentTypeId === parseInt(selected)) && (a.subAccountId === entityId))
    const sortData = sortedEntity &&
      sortedEntity.map((a, index) => {
        return { ...a, id: index + 1, company: a.companyName };
      });

    setFilteredEntiryData(sortData);
    const EntityID = entityId ? entityId : undefined;
    FamilyWiseStockCalculations(selected, EntityID)
    setTotalData({
      daysGainSum: 0,
      daysGainSumPer: 0,
      overAllGainSum: 0,
      overAllGainSumPer: 0,
      CurrentValue: 0,
      totalInvestements: 0
    })

  }
  //close stock details transaction
  const handleClose = async (val) => {
    setpreviewstocksopen(false);
    setStockTransaction({});
    setStockHeaderId(0);
    setShowStockDetail(false);
    // await setEntityId(null);
    if (val !== 1) onRefresh();
  };

  //close inner grid
  const handleCloseModel = () => {
    setStockHeaderId(0);
    livePriceApiDeActive()
    setShowInnerGrid(false);
    handleReset();
    //  setEntity('Family');
    // setEntityId(null);
    onRefresh();
  };

  //getEntityList
  const getEntityList = () => {
    setLoading(true)
    EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let SubscriberName = res.data._response.data.subaccountDetails;
            setEntityRadio(SubscriberName);
            setLoading(false)
          }
          else {
            setLoading(false)
            // setError(res.data.message);
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  const handleView = (selectedRow, a) => {
    setLoading(true);
    if (a.transactStockType === "Buy" || a.transactStockType === "Sell") {
      let stockId = a.stockId;
      getSingleStockTransactionDataPreview(stockId).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 1) {
            setStockHeaderId(a.stockHeaderId);
            setStockId(a.stockId);
            const result = res.data._response.data.fetchStockDetailsPreview;
            setPreviewTransaction(result);
            setpreviewstocksopen(true);
          }
        }
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    } else {
      GetStockTransaction(a.stockHeaderId).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 1) {
            setStockHeaderId(a.stockHeaderId);
            setStockId(a.stockId);
            const result = res.data._response.data.stockTrnsactionDetails;
            setStockTransaction(result);

            //setbonusopen(true)
            if (a.transactStockType === "Merger") {
              setpreviewmergeropen(true);
              setStockHeaderId(a.stockHeaderId);
            } else if (a.transactStockType === "Bonus") {
              setpreviewbonusopen(true);
              // getStockTransactionDetails(stockHeaderId);
            } else if (a.transactStockType === "Split") {
              setpreviewsplitopen(true);
            } else if (a.transactStockType === "Rights") {
              setpreviewrightsopen(true);
            } else if (a.transactStockType === "IPO") {
              setpreviewipoopen(true);
            } else if (a.transactStockType === "DeMerger") {
              GetDMergerDetails(a.stockHeaderId)
                .then((res) => {
                  if (res.data._response.status === 1) {
                    const demerge = res.data._response.data.stockTrnsactionDetails;
                    setDemergerData(demerge)
                    setstockId1(demerge.stockDetails[0].stockId)
                    setstockId2(demerge.stockDetails[1].stockId)
                    setpreviewdemergeropen(true);
                  }
                })

            } else if (a.transactStockType === "BuyBack") {
              setpreviewbuyBackopen(true);
            } else if (a.transactStockType === "Installment" || a.transactStockType === "Final") {
              setpreviewPartlyPaidOpen(true);
            } else if (a.transactStockType === "Converted") {
              setpreviewConvertibleOpen(true);
            } else if (a.transactStockType === "Redeemed") {
              setpreviewRedeemableOpen(true);
            }
            // else {
            //   setpreviewstocksopen(true);
            //   // setShowStockDetail(true);
            // }
          }
        }
        else {
          setLoading(false);
        }
      })
        .catch(err => {
          console.log(err);
          setLoading(false);
        })
    }
  }

  const handleDelete = (selectedRow, a) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willdelete) => {
      if (willdelete) {
        setLoading(true);
        DeleteOtherTransaction(a.stockHeaderId, a.stockId).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              swal(res.data._response.message, {
                title: "Deleted",
                icon: "success",
              });
              onTransactionGridRefresh();
            }else{
              swal('Something went wrong',"", 'warning')
            }
          }
          else {
            setLoading(false);
            onTransactionGridRefresh();
          }
        })
          .catch(err => {
            console.log(err);
            setLoading(false);
          })
      } else {
        swal("Cancelled", "Your  data is safe ", "error");
      }
    })


  }
  const GetGridData = (Stock) => {
    let check = [];

    // let sorted = Stock.filter
    //   ((a) => a.instrumentTypeId === 1)

    Stock.forEach((item, i) => {
      if (check.indexOf(item.companyName) === -1) {
        check.push(item.companyName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        livePrice: 0,
        previousLivePrice: 0,
        quantity: 0,
        closingBalance: 0,
        name: "",
      };
      Stock.forEach((item, i) => {
        if (checkdata === item.companyName) {
          let livePrice = item.livePrice;
          let previousLivePrice = item.previousLivePrice;
          let quantity = mainData.quantity + item.quantity;
          let cost = mainData.closingBalance + item.closingBalance;
          // let instrumentToken = item.instrumentToken
          mainData = {
            livePrice: livePrice,
            previousLivePrice: previousLivePrice,
            quantity: quantity,
            closingBalance: cost,
            companyName: item.companyName,
            id: index,
            instrumentToken: item.instrumentToken,
            instrumentTypeId: item.instrumentTypeId
          };
          let normalData = {
            ...item,
            companyName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.companyName,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    // let finalData = [...parentData,...childData];
    // console.log(parentData);
    //console.log(finalData);
    setFilteredData([...parentData, ...childData]);

  };


  //only two decimal places
  const convertTOdecimal = (num) => {

    if (num !== Infinity) {
      return (Math.round(num * 100) / 100).toFixed(2);
    } else {
      return 0.00;
    }


  }

  const columns = [
    { title: "Scrip", field: "companyName", width: "8%" },
    {
      title: "Live Price",
      field: "livePrice",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.livePrice)/*Tanmay-25/01/2022*/}</span>
      ),
    },
    {
      title: "Change",
      field: "change",
      type: "numeric",
      render: (rowData) => (
        // a = rowData.livePrice - rowData.previousLivePrice,
        (rowData.livePrice - rowData.previousLivePrice) >= 0 ? <span style={{ color: "#008000" }} >{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.livePrice - rowData.previousLivePrice)/*Tanmay-25/01/2022*/}</span>
          : <span style={{ color: "#ff0000" }} >{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.livePrice - rowData.previousLivePrice)/*Tanmay-25/01/2022*/}</span>


      ),
    },
    {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(rowData.quantity)/*Tanmay-25/01/2022*/}
        </span>
      ),
    },
    {
      title: "Avg Purchase Price",
      field: "aPurchasePrice",
      type: "numeric",
      render: (rowData) => (
        rowData.quantity ? <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.closingBalance / (rowData.quantity)))}</span>
          : <span>0.00</span>
      ),
    },
    {
      title: "Amount Invested",
      field: "closingBalance",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.closingBalance)/*Tanmay-25/01/2022*/}
        </span>
      ),
    },
    {
      title: "Day's Gain",
      field: "daysGain",
      type: "numeric",
      render: (rowData) => (
        (
          ((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity)
        ) >= 0 ?



          rowData.livePrice ?

            (isNaN(((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity)) ?
              <span style={{ color: "#008000" }}> 0.00</span> :
              <span style={{ color: "#008000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  preciseMathDotRound((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity))
                }
              </span>

            )

            : <span style={{ color: "#008000" }}> 0.00</span>

          :

          // rowData.livePrice ?

            (isNaN(((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity)) ?
              <span style={{ color: "#008000" }}> 0.00</span>
              :
              <span style={{ color: "#ff0000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  preciseMathDotRound(((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity)))
                }
              </span>

            )

            // : <span style={{ color: "#008000" }}> 0.00%</span>
      )
    },
    {
      
      title: "Day's Gain(%)",
      field: "pdaysGain",
      type: "numeric",
      render: (rowData) => {
        let calculationdays_gain = ((rowData.livePrice - rowData.previousLivePrice) / rowData.previousLivePrice)*100
    
        return(
          (calculationdays_gain) ?
            ((calculationdays_gain) >= 0 ?


              <span style={{ color: "#008000" }}>
                {

                  new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(calculationdays_gain))}%
              </span>
              :
              <span style={{ color: "#ff0000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(calculationdays_gain))}%
              </span>) : <span style={{ color: "#008000" }}>0.00%</span>
        )
      }
    },
    {
      title: "Overallgain",
      field: "overallgain",
      type: "numeric",
      render: (rowData) => (

        rowData.livePrice ?

          ((rowData.livePrice * rowData.quantity - rowData.closingBalance) >= 0 ?

            <span style={{ color: "#008000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.livePrice * rowData.quantity - rowData.closingBalance)}
            </span>
            : <span style={{ color: "#ff0000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.livePrice * rowData.quantity - rowData.closingBalance)}
            </span>)

          : <span style={{ color: "#008000" }}>0.00</span>

      ),
    },
    {
      title: "Overallgain(%)",
      field: "poverallgain",
      type: "numeric",
      render: (rowData) => (
        (
          ((rowData.livePrice * rowData.quantity - rowData.closingBalance) /
            rowData.closingBalance) *
          100
        ) >= 0 ?



          rowData.livePrice ?

            (isNaN((((rowData.livePrice * rowData.quantity - rowData.closingBalance) /
              rowData.closingBalance)) *
              100) ?
              <span style={{ color: "#008000" }}> 0.00%</span> :
              <span style={{ color: "#008000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  preciseMathDotRound(((rowData.livePrice * rowData.quantity - rowData.closingBalance) / rowData.closingBalance)) * 100)
                }%
              </span>

            )

            : <span style={{ color: "#008000" }}> 0.00%</span>

          :

          rowData.livePrice ?

            (isNaN((((rowData.livePrice * rowData.quantity - rowData.closingBalance) /
              rowData.closingBalance)) *
              100) ?
              <span style={{ color: "#008000" }}> 0.00%</span>
              :
              <span style={{ color: "#ff0000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  preciseMathDotRound(((rowData.livePrice * rowData.quantity - rowData.closingBalance) / rowData.closingBalance)) * 100)
                }%
              </span>

            )

            : <span style={{ color: "#008000" }}> 0.00%</span>
      ),
    },
    {
      title: "Current Value",
      field: "currentvalue",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.instrumentTypeId === 10 || rowData.instrumentTypeId === 23 || rowData.instrumentTypeId === 24 ? rowData.closingBalance : rowData.livePrice * rowData.quantity)}</span>
      ),
    },
    // {
    //   title: "Holding Period",
    //   field: "estHoldingPeriod",
    //   type: "numeric",
    // },
    // {
    //   title: "Target",
    //   field: "target",
    //   type: "numeric",
    // },
  ];


  useEffect(() => {
    (
      async () => {
        await getStock();
      

        getEntityList()
      })();
  }, [script,nonZero]);


  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl('http://5.189.157.40:10021/stocks')
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          console.log('Connected!');
          connection.invoke("JoinGroup", "liveFeeds").then((res) => {
            connection.on('NewCallReceived', message => {
              // // console.log(message, "message");
              //  Stock && Stock.map((i,index) => {
              //    if(  i.instrumentToken !== null && i.instrumentToken === message.instrumentToken){
              //      i.livePrice = message.lastPrice;
              //      i.previousLivePrice = message.close;
              //    }
              //    return i;
              //  })
              // // setStockData(Stock);
              //   GetGridData(Stock);
              //  // let data = {
              //  //   instrumentToken : message.instrumentToken,
              //  //   livePrice : message.lastPrice,
              //  //   previousLivePrice : message.close
              //  // }

              sendMessage(message);
            });
          }).catch((e) => {
            console.log(e.message);
          })
        }).catch((e) => {
          console.log(e.message);
        })
    }
  }, [connection]);

  const sendMessage = async (message) => {
    //debugger
    if (connection) await connection.send("NewCallReceived", message);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getSignalR();
  //     getStock();
  //   }, 30000)
  //   return () => clearInterval(interval)

  // }, [])


  // useEffect(() => {
  //   if (entityId) {
  //     const interval = setInterval(() => {
  //       getSignalR();
  //       EntityWiseStockListGrid(entityId);
  //     }, 30000)
  //     return () => clearInterval(interval)
  //   }
  // }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      
      if (entity === "Family" && (!showStockDetail && !isLivePriceApi && script === 1 && nonZero === 0 && searched === "")) {
        getSignalR();
        // console.log('signle r in family wise useEffect');
        let disable = 1
        getStock(disable);
        getFamilyData(disable)
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [entity, showStockDetail, isLivePriceApi, script, nonZero, searched])


  useEffect(() => {
    const interval = setInterval(() => {
      if (entity !== "Family" && (!showStockDetail && !isLivePriceApi && script ===1 && nonZero === 0 && searched === "")) {
        getSignalR();

        // console.log('signle r in entity wise useEffect');
        EntityWiseStockListGrid(entityId, nonZero);
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [entity, showStockDetail, isLivePriceApi, script, nonZero, searched])

  const getSignalR = () => {
    getSignalRList().then(res => {
      //  setnonZero("All")
    }).catch((e) => {
      setLoading(false);
      // console.log(e.message);
    });
  }

  const getStock = async (disable) => {

    await GetStocksList(nonZero).then(async (res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setLoading(false);

          let Stock = res.data._response.data.stockFolioGrid;

          //default listed
          let sorted = script === "All" ? Stock : Stock.filter
            ((a) => a.instrumentTypeId === script)

          await setStockData(sorted);
          await GetGridData(sorted);
          await setNonZeroData(sorted)

          let scriptnew = script === "All" ? 0 : script
          entity === "Family" ? FamilyWiseStockCalculations(scriptnew) : FamilyWiseStockCalculations(scriptnew, entityId)


          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val == "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });

        }
        else {
          setLoading(false);
          setFilteredData([])
          setFilteredFamilyData({
            prevLivePrice: 0,
            livePrice: 0,
            quantity: 0,
            totalInvestment: 0,
            daygain: 0,
            daysgainper: 0,
            overallgain: 0,
            overallgainper: 0,
            currentValue: 0
          })
          setStockData([])
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {

        setLoading(false);
        console.log(e.message);
      });
    if (disable !== 1) {
      getSignalR();
      // console.log('signle r in get Stock');
    }
  };

  const getBuyGetData = async (
    subAccountId,
    companyId,
    companyName,
    livePrice
  ) => {
    setSubId(subAccountId);
    setCompanyId(companyId);
    setCompanyName(companyName);
    setLivePrice(livePrice);
    if (subAccountId != null && companyId != null) {
      setLoading(true);
      await GetBuySellGrid(subAccountId, companyId)
        .then((res) => {
          setLoading(false);

          if (res.data._response.status === 1) {

            let data = res.data._response.data.fetchEntityInfo;


            if (data.transactStockType !== "Bonus" && data.transactStockType !== "BuyBack" && data.transactStockType !== "Split"
              && data.transactStockType !== "IPO" && data.transactStockType !== "Rights" && data.show === 0) {
              const dataNew = data.filter((a) => a.transactStockType !== "Bonus" && a.transactStockType !== "BuyBack" && a.transactStockType !== "Split"
                && a.transactStockType !== "IPO" && a.transactStockType !== "Rights")
              setSell(dataNew);

            } else {
              setSell(data)
            }

            let quantity = _.last(data);
            let buyAmt = 0;
            let sellAmt = 0;
            let buyQuantity = 0;
            let sellQuantity = 0;
            let instrumentTypeId = 0;
            data &&
              data.map((f) => {
                if (f.transactStockType !== "Sell" && f.transactStockType !== "BuyBack" && f.transactStockType !== "Redeemed" && f.transactStockType !== "Converted" && f.transactStockType !== "Split" && f.show === 1) {
                  buyAmt += f.closingBalance;
                  buyQuantity += f.quantity;
                }



                if (f.transactStockType === "Redeemed" && f.show === 1) {
                  sellAmt += f.totalCost;
                  sellQuantity += f.quantity;
                }

                if (f.transactStockType === "BuyBack" && f.show === 0) {
                  // sellAmt += f.totalCost;
                  sellQuantity += f.quantity;
                }

                if (f.transactStockType === "Converted" && f.active === 1 && f.show === 1) {
                  buyAmt += f.closingBalance;
                  buyQuantity += f.quantity;
                }

                if (f.transactStockType === "Converted" && f.active === 0 && f.show === 1) {
                  sellAmt += f.totalCost;
                  sellQuantity += f.quantity;
                }

                // debugger
                if (f.transactStockType === "Sell" && f.show === 1) {
                  //  sellAmt += f.closingBalance;
                  sellQuantity += f.quantity;
                }

                if (f.transactStockType === "Sell" && f.show === 0 && f.active === 1) {
                  sellQuantity += f.quantity;

                }

                if (f.transactStockType === "Sell" && f.show === 1) {
                  sellAmt += f.closingBalance;
                  // sellQuantity += f.quantity;
                }


                if (f.transactStockType === "Split" && f.show === 1) {
                  buyAmt += f.closingBalance;
                  buyQuantity += f.quantity;
                }

                instrumentTypeId = f.instrumentTypeId
                setTransactionType(f.transactStockType)
              });
            livePriceApiActive()
            setShowInnerGrid(true);
            handleModal();

            setGridDetail({
              companyId: companyId,
              companyName: companyName,
              livePrice: livePrice,
              finalQuantity: buyQuantity - sellQuantity, //quantity.balanceQuantity,
              currentValue: instrumentTypeId === 10 || instrumentTypeId === 23 || instrumentTypeId === 24 || instrumentTypeId === 20 ? buyAmt - sellAmt : livePrice * quantity.balanceQuantity,
              finalCost: buyAmt - sellAmt,

            });
            setShowLoader(true);

            //+/- code
            var arr = [];
            $(".MuiTableRow-root").each(function () {
              let path = this.getAttribute("path");
              let a = /[,\/]/g;
              let b = a.test(path)
              if (b) {
                let c = path.charAt(0)
                if (!arr.includes(c)) {
                  arr.push(c)
                }
              }
            });
            $(".MuiTableRow-root").each(function () {
              let path = this.getAttribute("path");
              if (arr.includes(path)) {
                let button = $("button", this);
                $(button).html("<span class='material-icons'>maximize</span>");
                $(button).css({
                  transform: "rotate(180deg)",
                });
              }
            });
          } else {
            setLoading(false);
            setSell([]);
            setGridDetail({});
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };



  const handleModal = () => {

    $(document).on('show.bs.modal', '.modal-content', function () {
      const zIndex = 1040 + 10 * $('.modal-content:visible').length;
      $(this).css('z-index', zIndex);
      setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
    });

  }

  const openTransaction = () => {
    setStockTransaction({});
    setStockHeaderId(0);
    setShowStockDetail(true);
  };

  const onRefresh = async () => {
    // await setEntity('Family');
    // await setEntityId(null);
    await setLoading(true);
    if (entityId) {
      await getSignalR();
      setTimeout(async () => {
        await EntityWiseStockListGrid(entityId,nonZero);
        await getEntityData(entityId)
      }, 20000);
    } else {
      await getStock();
      await getFamilyData();

    }
  }

  const onTransactionGridRefresh = () => {
    setShowStockDetail(false);
    getBuyGetData(
      subId,
      companyId,
      companyName,
      livePrice
    );
  }

  const handleReset = () => {
    setCompanyId(null);
    setCompanyName(null);
    setSubId(null);
    setLivePrice(null)
  }


  //contractnote
  const handleCloseContract = () => {
    setContractOpen(false);
    setPreviewContractOpen(false);
    livePriceApiDeActive();
  }

  const handleUploadContract = () => {
    setContractOpen(true)
    livePriceApiActive()
  }

  const handleTreadingData = () => {
    setTradingDataOpen(true)
  }

  const handleCloseTrading = () => {
    setTradingDataOpen(false)
  }

  // round numbers for percentage value
  function preciseMathDotRound(value, precision = 2) {
    let valueData = isFinite(value)

    if (valueData) {

      return parseFloat(value).toFixed(precision);
    } else {
      return 0;
    }
  }

  const livePriceApiActive = () => setIsLivePriceApi(true);
  const livePriceApiDeActive = () => setIsLivePriceApi(false);

  // include and exclude nonZero
  const handleChangeValues = (e) => {
    const selected = Number(e.target.value)
    setnonZero(selected)
    // setScript(1)
    setSearched("");


      EntityWiseStockListGrid(entityId,selected) 
    

    // let sorted = selected === 0 ?
    //   stockData :
    //   stockData.filter((a) => a.quantity !== 0)

    // setNonZeroData(sorted)

    // // familywise
    // let check = [];
    // sorted.forEach((item, i) => {
    //   if (check.indexOf(item.companyName) === -1) {
    //     check.push(item.companyName);
    //   }
    // });
    // let parentData = [];
    // let childData = [];
    // check.forEach((checkdata, index) => {
    //   let mainData = {
    //     livePrice: 0,
    //     previousLivePrice: 0,
    //     quantity: 0,
    //     closingBalance: 0,
    //     name: "",
    //   };
    //   sorted.forEach((item, i) => {
    //     if (checkdata === item.companyName) {
    //       let livePrice = item.livePrice;
    //       let previousLivePrice = item.previousLivePrice;
    //       let quantity = mainData.quantity + item.quantity;
    //       let cost = mainData.closingBalance + item.closingBalance;
    //       mainData = {
    //         livePrice: livePrice,
    //         previousLivePrice: previousLivePrice,
    //         quantity: quantity,
    //         closingBalance: cost,
    //         companyName: item.companyName,
    //         id: index,
    //         instrumentToken: item.instrumentToken,
    //         instrumentTypeId: item.instrumentTypeId
    //       };
    //       let normalData = {
    //         ...item,
    //         companyName: item.subAccountName,
    //         parentId: index,
    //         id: index + 1,
    //         company: item.companyName,
    //       };
    //       childData.push(normalData);
    //     }
    //   });
    //   parentData.push(mainData);
    // });

    // setFilteredData([...parentData, ...childData]);




    // // entitywise
    // let sortedQuantityEnity = sorted.filter
    //   ((a) => a.subAccountId === entityId)

    // const sortData = sortedQuantityEnity &&
    //   sortedQuantityEnity.map((a, index) => {
    //     return { ...a, id: index + 1, company: a.companyName };
    //   });


    // setFilteredEntiryData(sortData);

  }

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }


  // search function
  const requestSearch = (searchedVal) => {


    let sorted = nonZero === 0 ?
      stockData :
      stockData.filter((a) => a.quantity !== 0 && a.instrumentTypeId === script)

    setSearched(searchedVal)
    const filteredRows = sorted.filter((row) => {
      return row.companyName.includes(searchedVal.toUpperCase()) || row.companyName.includes(searchedVal.toLowerCase()) || row.companyName.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.companyName) === -1) {
        check.push(item.companyName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        livePrice: 0,
        previousLivePrice: 0,
        quantity: 0,
        closingBalance: 0,
        name: "",
      };
      filteredRows.forEach((item, i) => {
        if (checkdata === item.companyName) {
          let livePrice = item.livePrice;
          let previousLivePrice = item.previousLivePrice;
          let quantity = mainData.quantity + item.quantity;
          let cost = mainData.closingBalance + item.closingBalance;
          mainData = {
            livePrice: livePrice,
            previousLivePrice: previousLivePrice,
            quantity: quantity,
            closingBalance: cost,
            companyName: item.companyName,
            id: index,
            instrumentToken: item.instrumentToken,
            instrumentTypeId: item.instrumentTypeId
          };
          let normalData = {
            ...item,
            companyName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.companyName,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });

    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    const entityStockList =
      forentity &&
      forentity.map((a, index) => {
        return { ...a, id: index + 1, company: a.companyName };
      });
    setFilteredEntiryData(entityStockList);


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      EntityWiseStockListGrid(entityId,nonZero)
    } else {
      getStock()
    }
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="text-center py-3">Stocks Folio</h4>
          </Col>
        </Row>

        <FormControl className="mt-4">

          <RadioGroup
            row
            color="primary"
            defaultValue="start"
            aria-label="Entity"
            name="row-radio-buttons-group"
            value={entity}
            onChange={handleChangeEntity}
            style={{ marginTop: "-3rem" }}
          >
            <FormControlLabel
              value="Family"
              control={<Radio />}
              label="Family"
            />
            {entityRadio.map((a, i) => (
              <FormControlLabel
                value={a.subAccountName}
                control={<Radio />}
                key={a.subAccountName}
                label={a.subAccountName}
                disabled={a.active === 0 ? true : false}
              />
            ))}

          </RadioGroup>
        </FormControl>

        <Row>
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="flex-end"
            alignItems="flex-start" mb={3} >
            {entityId === null ? null :
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openTransaction()}
                  style={{ height: "2rem" }}
                  data-dismiss="modal"
                >
                  Transactions
                </Button>



                <Button variant="contained" color="primary" style={{ height: "2rem" }}>
                  <Stockdropdown onRefresh={onRefresh} className="stockdrop" liveApiActive={livePriceApiActive} liveApiDeActive={livePriceApiDeActive} />
                </Button>

                <Button variant="contained" color="primary" style={{ height: "2rem" }} onClick={handleUploadContract}>
                  Upload Contract Note
                </Button>

                <Button variant="contained" color="primary" style={{ height: "2rem" }} onClick={handleTreadingData}>
                  Trading Data
                </Button>
              </>
            }
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={script}
              label="Type"
              onChange={handleScripData}
              style={{ minWidth: 120, paddingLeft: "12px" }}

            >
              <MenuItem disabled >
                <em>Stocks Type</em>
              </MenuItem>
              <MenuItem value={"All"}>All Stocks</MenuItem>
              <MenuItem value={1}>Listed</MenuItem>
              <MenuItem value={10}>Unlisted</MenuItem>
              <MenuItem value={14}>
                Traded Preference Shares Convertible
              </MenuItem>
              <MenuItem value={15}>
                Traded Preference Shares Non-Convertible
              </MenuItem>
              <MenuItem value={23}>
                Non-Traded Preference Shares Convertible
              </MenuItem>
              <MenuItem value={24}>
                Non-Traded Preference Shares Non-Convertible
              </MenuItem>
            </Select>
          </Stack>


        </Row>
        <Row >
          <Box sx={{ flexGrow: 1 }} mb={2}>
            <Grid container spacing={2} columns={16}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end">
              <Grid item xs={6}>
                <FormControl>
                  <RadioGroup
                    row
                    color="primary"
                    defaultValue="start"
                    // aria-label="Entity"
                    name="row-radio-buttons-group"
                    value={nonZero}
                    onChange={handleChangeValues}
                  >
                    <FormControlLabel
                      value= {0}
                      control={<Radio />}
                      label="Exclude Zero Assets"

                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Include Zero Assets"
                    />

                  

                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs="auto">
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => { requestSearch(searchVal) }}
                  onCancelSearch={() => cancelSearch()}
                  style={{
                    // margin: "0 auto",
                    maxwidth: 250,
                  }}
                />
              </Grid>
            </Grid>
          </Box>



        </Row>
        <Row>
          <Col md="12" className="tblHeaderLeft">
            <div style={{ maxwidth: "100%" }}>
              {/* {loading ? (
                <Loader />
              ) :  */}

              <>
                <MaterialTable
                  title=""
                  data={
                    entity === "Family" ? filteredData : filteredEntiryData
                  }
                  columns={columns}
                  //  parentChildData={(row, rows) => rows.find((a) => a.companyName === row.subAccountName)}
                  parentChildData={(row, rows) =>
                    rows.find((a) => a.id === row.parentId)
                  }
                  components={{
                    Body: (props) => {
                      // console.log(props)
                      let totalDataS = {
                        investmentCost: 0,
                        overAllGainSum: 0,
                        overAllGainSumPer: 0,
                        daysGainSum: 0,
                        daysGainSumPer: 0,
                        marketPrice: 0,

                      }
                      props.renderData.forEach((rowData) => {
                        totalDataS.investmentCost += rowData.closingBalance;
                        totalDataS.daysGainSum += ((rowData.livePrice - rowData.previousLivePrice) * rowData.quantity);
                        totalDataS.daysGainSumPer +=  ((((rowData.livePrice - rowData.previousLivePrice) / rowData.previousLivePrice)*100) )   /*rowData.livePrice ? : 0.00*/ 
                        totalDataS.overAllGainSum += rowData.livePrice ? (rowData.livePrice * rowData.quantity - rowData.closingBalance) : 0.00;
                        totalDataS.overAllGainSumPer = (totalDataS.overAllGainSum / totalDataS.investmentCost) * 100;
                        totalDataS.marketPrice += rowData.instrumentTypeId === 10 || rowData.instrumentTypeId === 23 || rowData.instrumentTypeId === 24 ? rowData.closingBalance : rowData.livePrice * rowData.quantity;
                      })
                      return (
                        <>
                          <MTableBody {...props} />
                          {



                            <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                              <TableRow>
                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={2} align="right">Total</TableCell>
                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={5} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.investmentCost)}</TableCell>
                                <TableCell style={totalDataS.daysGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.daysGainSum)}</TableCell>
                                <TableCell style={totalDataS.daysGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.daysGainSumPer))}%</TableCell>
                                <TableCell style={totalDataS.overAllGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.overAllGainSum)}</TableCell>
                                <TableCell style={totalDataS.overAllGainSumPer < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalDataS.overAllGainSumPer))}%</TableCell>
                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalDataS.marketPrice)}</TableCell>
                              </TableRow>
                            </TableFooter>

                          }
                        </>
                      )

                    },
                    Toolbar: (props) => {
                      //calculate all header family
                      let totalCost = filteredFamilyData.totalInvestment;
                      let dayGain = filteredFamilyData.daygain
                      let dayGainpert = (dayGain / totalCost) * 100.;
                      let overallGain = filteredFamilyData.overallgain
                      let overallGainpert = (overallGain / totalCost) * 100;
                      let currValue = filteredFamilyData.currentValue

                      //calculate all header entity
                      let totalCostE = filterEntityData.totalInvestmentc
                      let dayGainE = filterEntityData.daysgainc
                      let dayGainpertE = (dayGainE / totalCostE) * 100;
                      let overallGainE = filterEntityData.overallgainc
                      let overallGainpertE = (overallGainE / totalCostE) * 100;
                      let currValueE = filterEntityData.currentValuec;
                      return (

                        <>
                          <MTableToolbar {...props} />
                          {
                            script !== "All" ?

                              <div className="row mb-2 p-2">
                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={{ fontSize: "1.3vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.totalInvestements) ? 0 : totalData.totalInvestements)}</h3>
                                      <p className="m-b-0">Amount Investment</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={totalData.daysGainSum < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }}>
                                        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.daysGainSum) ? 0 : totalData.daysGainSum)}</span></h3>
                                      <p className="m-b-0" >Day's Gain</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={totalData.daysGainSumPer < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }}>
                                        <span >{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(totalData.daysGainSumPer) ? 0.00 : totalData.daysGainSumPer))}%</span></h3>
                                      <p className="m-b-0 font-weight-bold">Day's Gain(%)</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={totalData.overAllGainSum < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }}>
                                        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.overAllGainSum) ? 0 : totalData.overAllGainSum)}</span></h3>
                                      <p className="m-b-0">Overallgain</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={totalData.overAllGainSumPer < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }}>
                                        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(totalData.overAllGainSumPer) ? 0 : totalData.overAllGainSumPer))}%</span></h3>
                                      <p className="m-b-0">Overallgain(%)</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={{ fontSize: "1.3vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(totalData.CurrentValue) ? 0 : totalData.CurrentValue)}</h3>
                                      <p className="m-b-0">Current Value</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :

                              <div className="row mb-2 p-2">
                                <div className="col-md-2 col-xl-2">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={{ fontSize: "1.3vw" }}>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(filteredFamilyData.totalInvestment) ? 0 : filteredFamilyData.totalInvestment) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(filterEntityData.totalInvestmentc) ? 0 : filterEntityData.totalInvestmentc)}</h3>
                                      <p className="m-b-0">Amount Investment</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={entity === "Family" ? (dayGain < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }) : (dayGainE < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" })} >
                                        <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(dayGain) ? 0 : dayGain) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(dayGainE) ? 0 : dayGainE)}</span></h3>
                                      <p className="m-b-0" >Day's Gain</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={entity === "Family" ? (dayGainpert < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }) : (dayGainpertE < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" })}>
                                        <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(dayGainpert) ? 0.00 : dayGainpert)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(dayGainpertE) ? 0.00 : dayGainpertE))}%</span></h3>
                                      <p className="m-b-0 font-weight-bold">Day's Gain(%)</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={entity === "Family" ? (overallGain < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }) : (overallGainE < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" })}>
                                        <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(overallGain) ? 0 : overallGain) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(overallGainE) ? 0 : overallGainE)}</span></h3>
                                      <p className="m-b-0">Overallgain</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card  order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={entity === "Family" ? (overallGainpert < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" }) : (overallGainpertE < 0 ? { color: "#ff0000", fontSize: "1.3vw" } : { color: "#008000", fontSize: "1.3vw" })}>
                                        <span>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(overallGainpert) ? 0 : overallGainpert)) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(isNaN(overallGainpertE) ? 0 : overallGainpertE))}%</span></h3>
                                      <p className="m-b-0">Overallgain(%)</p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-2 col-xl-2 ">
                                  <div className="card order-card">
                                    <div className="card-block">
                                      <h3 className="text-right" style={{ fontSize: "1.3vw" }}>{entity === "Family" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(currValue) ? 0 : currValue) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(isNaN(currValueE) ? 0 : currValueE)}</h3>
                                      <p className="m-b-0">Current Value</p>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                        </>

                      )

                    },

                  }}



                  onRowClick={(evt, selectedRow) => {
                    if (selectedRow.tableData.childRows === null) {
                      getBuyGetData(
                        selectedRow.subAccountId,
                        selectedRow.companyId,
                        selectedRow.company,
                        selectedRow.livePrice
                      );
                    }
                  }}
                  options={{
                    headerStyle: {
                      backgroundColor: "#01579b",
                      color: "#FFF",
                    },
                    exportButton: false,
                    search: false,
                    // filtering: true,
                    toolbarButtonAlignment: "left",
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    emptyRowsWhenPaging: false,
                    rowStyle: (rowData) => {
                      return {
                        pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                        backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                      };
                    },

                  }}
                  onTreeExpandChange={(row, rows) => {
                    handleIcon(row, rows);
                  }}
                  icons={{
                    DetailPanel: iconsTest,
                  }}
                  isLoading={loading}

                />
              </>
              {/* )} */}
            </div>
            <StocksDetail
              show={showStockDetail}
              onHide={handleClose}
              showSdetailspreview={previewstocksopen}
              stockTransaction={stockTransaction}
              stockHeaderId={stockHeaderId}
              entityId={entityId}
              onTransactionGridRefresh={onTransactionGridRefresh}
              previewTransaction={previewTransaction}

            />
            {/* buy sell modal */}
            {/* other transaction update modal */}
            <Bonusmodal
              showB={bonusopen}
              showbonusPreview={previewbonusopen}
              onHideB={handleclose}
              stockHeaderIdB={stockHeaderId}
              stockTransactionB={stockTransaction}
              stockIdB={stockId}


            //onRefresh={onRefresh}
            />
            <Splitmodal
              showS={splitopen}
              showSplitPreview={previewsplitopen}
              onHideS={handleclose}
              stockHeaderIdS={stockHeaderId}
              stockTransactionS={stockTransaction}
              stockIdS={stockId}

            //onRefresh={onRefresh}
            />
            <RightsShare
              showR={rightsopen}
              showrightsharepreview={previewrightsopen}
              onHideR={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionR={stockTransaction}
              stockId={stockId}


            //  onRefresh={onRefresh}
            />
            <StockMergermodal
              showM={mergeropen}
              showPreviewStockMerger={previewmergeropen}
              onHideM={handleclose}
              stockHeaderIdM={stockHeaderId}
              stockTransactionM={stockTransaction}
              stockIdM={stockId}


            //  onRefresh={onRefresh}
            />
            <Demerger
              showD={demergeropen}
              showDemergerPreview={previewdemergeropen}
              onHideD={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionD={demgerData}
              stockIdDe1={stockIdD1}
              stockIdDe2={stockIdD2}


            // onRefresh={onRefresh}
            />
            <IPOShare
              showI={ipoopen}
              showIPOSharePreview={previewipoopen}
              onHideI={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionI={stockTransaction}
              stockId={stockId}


            //  onRefresh={onRefresh}
            />
            <BuyBack
              showBuy={buyBackopen}
              showbuybackpreview={previewbuyBackopen}
              onHideBuy={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionBuy={stockTransaction}
              stockId={stockId}

            // onRefresh={onRefresh}
            />
            <PartlyPaid
              showP={partlyPaidOpen}
              showPartlyPaidpreview={previewPartlyPaidOpen}
              onHideP={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionP={stockTransaction}
              stockId={stockId}


            // onRefresh={onRefresh}
            />
            <Convertible
              showUPC={convertibleOpen}
              showConvertPreview={previewConvertibleOpen}
              onHideUPC={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionC={stockTransaction}
              stockId={stockId}
            // tabIndex={0}


            // onRefresh={onRefresh}
            />
            <Reedemable
              showUPR={redeemableOpen}
              showredeempreview={previewRedeemableOpen}
              onHideUPR={handleclose}
              stockHeaderId={stockHeaderId}
              stockTransactionUPR={stockTransaction}
              stockId={stockId}


            />
            {/* contract note */}
            <ContractNote
              showContract={contractOpen}
              showContractPreview={previewContractOpen}
              onHideContract={handleCloseContract}

            />
            <TradingData
              showTrading={tradingDataOpen}
              HideTrading={handleCloseTrading}

            />
            <Container
              // className="LoginContainer formWraper"
              component="main"
              maxwidth="xs"
              tabIndex={-1}
            >
              <Modal
                isOpen={showInnerGrid}
                // toggle={() => handleCloseModel()}
                size="xl"
                backdrop="static"
                className="col-12 stockdetailmodalS"
              >
                <ModalHeader charCode="Y" toggle={() => handleCloseModel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                  <h5 className="text-center">Stock Detail</h5>
                </ModalHeader>
                <ModalBody>
                  {loading ? (
                    <Loader />
                  ) : null}
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p className="m0"> Portfolio: {gridDetail.companyName}</p>

                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="9%">
                              Trans.
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Broker
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Date
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Quantity
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Price/Share
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Total Brokerage
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Cost
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Sale Value
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Profit/Loss
                            </TableCell>
                            {/* <TableCell align="right" width="7%">
                              Annualized Return
                            </TableCell> */}
                            <TableCell align="right" width="7%">
                              Balance Quantity
                            </TableCell>
                            <TableCell align="right" width="6%">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          { showLoader === false ? <Loader /> :
                            sell.map((a, index) => (
                            <TableRow
                              key={a.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}


                            >
                              <TableCell align="left">
                                {a.transactStockType}
                              </TableCell>
                              <TableCell align="right">
                                {a.brokerName}
                              </TableCell>
                              <TableCell align="right">
                                {moment(a.date).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell align="right">
                                {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                                {new Intl.NumberFormat('en-IN').format(a.quantity)/*Tanmay-25/01/2022*/}
                              </TableCell>
                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.pricePerShare)/*Tanmay-25/01/2022*/}
                              </TableCell>
                              {/* <TableCell align="right">
                                {a.brokeragePerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.brokeragePerShare)/*Tanmay-25/01/2022*/}
                              {/* </TableCell> */}
                              <TableCell align="right">
                                {a.totalBrokerage === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(a.totalBrokerage)/*Tanmay-25/01/2022*/}
                              </TableCell>

                              <TableCell align="right">
                                {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                                {a.transactStockType === "Sell" || a.transactStockType === "BuyBack" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.closingBalance) : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost)/*Tanmay-25/01/2022*/}
                              </TableCell>
                              {/* //perfefct */}
                              <TableCell align="right">
                                {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 ? "-" : null}
                                {a.transactStockType === "Sell" || a.transactStockType === "BuyBack" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.totalCost) : "0.00"/*Tanmay-25/01/2022*/}
                              </TableCell>
                              <TableCell align="right">
                                {a.transactStockType === "Converted" && a.active === 0 ? "-" : a.transactStockType === "Redeemed" ? "-" : a.transactStockType === "Sell" && a.active === 0 ? "-" : a.transactStockType === "BuyBack" && a.active === 1 && (a.profitLoss > 0)? "-" : null}
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.profitLoss)/*Tanmay-25/01/2022*/}
                              </TableCell>

                              {/* <TableCell align="right">
                                {a.transactStockType === "Buy"
                                  ? // (((gridDetail.livePrice * a.availableQuantity) - a.totalCost)/a.yearDifference).toFixed(2)
                                  ((
                                    ((gridDetail.livePrice * a.availableQuantity - a.closingBalance) /
                                      a.closingBalance) *
                                    100
                                  ) / (a.yearDifference)).toFixed(2)
                                  // ((gridDetail.livePrice * a.availableQuantity - a.totalCost)
                                  // / (a.yearDifference)).toFixed(2)
                                  //   ((gridDetail.livePrice *
                                  //     a.availableQuantity -
                                  //     a.totalCost) /
                                  //     (a.totalCost *
                                  //     100) /
                                  //   a.yearDifference
                                  // ).toFixed(2)
                                  : 0}
                              </TableCell> */}
                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN').format(a.balanceQuantity)}
                              </TableCell>
                              <TableCell align="right">
                              <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                {a.transactStockType === "Converted" || a.transactStockType === "Redeemed" ? null : <>
                                  <Tooltip title="Edit">
                                    <IconButton>
                                      <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} data-dismiss="modal" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                                }
                                                       
                                {a.transactStockType === "Bonus" || a.transactStockType === "Split" || a.transactStockType === "Merger" || a.transactStockType === "Rights" || a.transactStockType === "IPO" || a.transactStockType === "BuyBack" || a.transactStockType === "DeMerger" || a.transactStockType === "Installment" || a.transactStockType === "Final" || a.transactStockType === "Redeemed" || a.transactStockType === "Converted" ?
                                  <Tooltip title="Delete">
                                    <IconButton>
                                      <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                    </IconButton>
                                  </Tooltip>
                                  : null}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left" className="headerbgcolor">
                              Closing Balance
                            </TableCell>
                            {/* <TableCell
                              align="right"
                              className="headerbgcolor"
                            ></TableCell> */}
                            <TableCell
                              align="right"
                              className="headerbgcolor"
                              colSpan="3"
                            >
                              {new Intl.NumberFormat('en-IN').format(gridDetail.finalQuantity)/*Tanmay-25/01/2022*/}
                            </TableCell>
                            {/* <TableCell
                              align="right"
                              className="headerbgcolor"
                            ></TableCell> */}
                            <TableCell
                              align="right"
                              colSpan="3"
                              className="headerbgcolor"
                            >
                              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.finalCost)/*Tanmay-25/01/2022*/}
                            </TableCell>
                            {/* <TableCell
                              align="right"
                              className="headerbgcolor"
                            ></TableCell> */}


                            {/* <TableCell
                              align="right"
                              className="headerbgcolor"
                              colSpan="3"
                            ></TableCell>*/}
                            <TableCell
                              align="right"
                              className="headerbgcolor"
                              colSpan="6"
                            ></TableCell>
                          </TableRow>
                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            {
                              TransactionType === "IPO" ? " " :
                                <><TableCell align="left">
                                  Current Price: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.livePrice)/*Tanmay-25/01/2022*/}
                                </TableCell>
                                  {/* <TableCell align="right"></TableCell> */}
                                  <TableCell align="right" colSpan="12">
                                    Current Value: {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetail.currentValue)/*Tanmay-25/01/2022*/}
                                  </TableCell>
                                </>
                            }
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </ModalBody>
              </Modal>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default StockFolio;
