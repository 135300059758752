/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { addOpeningStocks, brokerList, GetDividendFrequency, GetUnlistedScripBySubId, PostUnlistedScripBySubId, CompanyListByInsTypeId } from '../../../pages/Stocks/StocksAPI';
import { Container, Button, Modal, Row, Col, Form } from 'react-bootstrap'
import Select, { components } from "react-select"
import Swal from 'sweetalert2'
import MaterialTable, { MTableToolbar, MTableEditField } from "material-table";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Stack } from "@mui/material";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import Dropdown from '../../CommonComponent/Dropdown';
import { periodList, redeemableOrConvertibleList, cumulativeNonCumulativeList, StockTypes } from '../../../modules/helpers/constant';
import moment from "moment";
import DatePicker from 'react-datepicker';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavedisabledField } from '../../StocksDetail/ClevTablCss';
import useEnterKeyListener from '../../ShortcutKey/useKeyListener';
import Cleave from 'cleave.js/react';

function StocksOpenBal(props) {
  const { newFilterType, entityId, family, brokerCall } = props;

  // const [columns3, setcolumns3] = useState([]);
  // const [columns4, setcolumns4] = useState([]);

  // const [mtdate, setmtdate] = useState(null)
  const [companyList, setCompanyList] = useState([]);
  const [brokerData, setBrokerList] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selScrip, setSelScrip] = useState({ value: '', label: '' });
  const [companyListPre, setCompanyListPre] = useState([]);
  const [unlistedComp, setUnlistedComp] = useState('');
  const [unlistedCompErr, setUnlistedCompErr] = useState('');
  const [brokerUnlisted, setBrokerUnlisted] = useState([]);

  const [formErr, setFormErr] = useState('');
  const [headerId, setHeaderId] = useState(0);
  const [dividend, setDividend] = useState(0);
  const [frequencyValue, setFrequencyValue] = useState([])
  const [redeemableorconvertible, setRedeemableorconvertible] = useState([{ label: "Redeemable", value: "Redeemable" }]);
  const [timeValue, setTimeValue] = useState("Month");
  const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
  const [lockinperiod, setLockinperiod] = useState("");
  const [cumulativeType, setCumulativeType] = useState("Non Cumulative");
  const [cumulativeornoncumulative, setCumulativeornoncumulative] = useState([{ label: "Non Cumulative", value: "Non Cumulative" }]);
  const [certificateorfolinumber, setCertificateorfolinumber] = useState("");
  const [maturityvalue, setMaturityvalue] = useState(0);
  const [dateofmaturityorconversion, setDateofmaturityorconversion] = useState(new Date());
  const [showlistedPref, setShowlistedPref] = useState(false);

  const [convertType, setConvertType] = useState("Redeemable");
  const [dividendfrequency, setDividendfrequency] = useState([]);
  const [date, setDate] = useState(null);
  const [showUnlisted, setShowUnlisted] = useState(false);
  const [cost, setCost] = useState(null);

  const [unlistedCompany, setUnlistedCompanyList] = useState([]);

  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);
  const [allEntry2, setAllEntry2] = useState([]);
  const [allEntry3, setAllEntry3] = useState([]);
  const [allEntry4, setAllEntry4] = useState([]);

  function replaceNonNumeric(numStr) {
    return String(numStr).replace(/[^0-9]/g, '')
  }
  function commarize(numStr) {
    return Number(replaceNonNumeric(numStr)).toLocaleString()
  }
  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  // useEffect(() => {
  //   if (newFilterType === 3) {
  //     setcolumns3(
  //       [
  //       {
  //         title: 'Scrip', field: 'companyId', width: 25,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData !== undefined) {
  //             if (props.rowData.tableData.editing !== undefined) {
  //               if (props.rowData.tableData.editing === 'update') {
  //                 if (props.value.label !== undefined) {
  //                   if (props.value.label !== props.rowData.company.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.company
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.company
  //                 }
  //               }
  //             }
  //           }
  //           const onScripChange = (selected) => {
  //             props.onChange(selected);

  //           }
  //           return (
  //             <Select
  //               components={{ Control }}
  //               // value={props.value || null}
  //               // // value={selScrip}
  //               // // onChange={handleInput}
  //               // onChange={(selected) => props.onChange(selected)}
  //               // options={Scrip}
  //               value={ddValue || null}
  //               onChange={(selected) => props.onChange(selected)}
  //               // options={companyList}
  //               options={companyListPre}
  //               name="companyId"
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Broker', field: 'brokerId', width: 25,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData !== undefined) {
  //             if (props.rowData.tableData.editing !== undefined) {
  //               if (props.rowData.tableData.editing === 'update') {
  //                 if (props.value.label !== undefined) {
  //                   if (props.value.label !== props.rowData.broker.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.broker
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.broker
  //                 }
  //               }
  //             }
  //           }
  //           return (
  //             <Select
  //               value={ddValue || null}
  //               // value={selScrip}
  //               // onChange={handleInput}
  //               onChange={(selected) => props.onChange(selected)}
  //               // options={Broker}
  //               options={brokerData}
  //               name="brokerId"
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Date of purchase', field: 'date', type: 'date',
  //         editComponent: props => {
  //           return (
  //             <MuiPickersUtilsProvider utils={DateFnsUtils}
  //               locale={props.dateTimePickerLocalization}>
  //               <DatePicker
  //                 format="dd/MM/yyyy"
  //                 value={props.value || null}
  //                 onChange={props.onChange}
  //                 clearable
  //                 maxDate={Date.now()}
  //               // InputProps={{
  //               //          style: {
  //               //               fontSize: 13,
  //               //          }
  //               // }}
  //               />
  //             </MuiPickersUtilsProvider>
  //           )
  //         }
  //       },
  //       {
  //         field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           // handleAddRowBuy();
  //           return <MTableEditField {...props} />;
  //         },
  //       },
  //       {
  //         field: 'pricePerShare', title: 'Price/Share', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.pricePerShare)}
  //           </span>
  //         ),
  //       },
  //       {
  //         field: 'brokeragePerShare', title: 'Brokerage/Share', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           return <MTableEditField  {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.brokeragePerShare)}
  //           </span>
  //         ),
  //       },
  //       {
  //         field: 'totalBrokerage', title: 'Total Brokerage', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           let totalBrokrage = 0;
  //           totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //           props.value = totalBrokrage.toFixed(2);
  //           props.rowData.totalBrokerage = totalBrokrage;
  //           return <MTableEditField  {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.totalBrokerage)}
  //           </span>
  //         ),
  //       },
  //       {
  //         field: 'totalCost', title: 'Total Cost', width: 130, type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           let totalcost = 0;
  //           totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //           props.value = totalcost.toFixed(2);
  //           props.rowData.totalCost = totalcost;
  //           return <MTableEditField {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.totalCost)}
  //           </span>
  //         ),
  //       },
  //       // { field: 'currentMp', title: 'Current MP', width: 130, type: 'numeric',
  //       //   type: 'numeric',
  //       //   // editComponent: ({ ...props }) => {
  //       //   //   return <MTableEditField  {...props} />;
  //       //   // },
  //       // },
  //       // { field: 'currentMarketValue', title: 'Current Market Value', width: 130, type: 'numeric',
  //       //   editComponent: ({ ...props }) => {
  //       //     let CurrentMV = 0;
  //       //     CurrentMV = props.rowData.quantity * props.rowData.currentMp;
  //       //     props.value = CurrentMV;
  //       //     props.rowData.currentMarketValue = CurrentMV;
  //       //     return <MTableEditField {...props} />;
  //       //   }
  //       // },
  //       // { field: 'target', title: 'Target', width: 130,
  //       //   // editComponent: ({ ...props }) => {
  //       //   //   return <MTableEditField {...props} />;
  //       //   // },
  //       // },
  //       // { field: 'stopLoss', title: 'Stop Loss', width: 130,
  //       //   // editComponent: ({ ...props }) => {
  //       //   //   return <MTableEditField {...props} />;
  //       //   // },
  //       // },
  //       // { field: 'estHldgPeriod', title: 'Est Hldg Period', width: 130,
  //       //   // editComponent: ({ ...props }) => {
  //       //   //   return <MTableEditField {...props} />;
  //       //   // },
  //       // },
  //       // { field: 'recommendedBy', title: 'Recommended by', width: 130 },
  //       {
  //         title: "Addtional Data", field: "internal_action",
  //         // editable: false,
  //         editComponent: ({ ...props }) => {

  //           let t = props.rowData
  //           return <span onClick={() => {
  //             // if (headerId == 0) {
  //             if (t.dividendPerc !== undefined) {
  //               setDividend(t.dividendPerc);
  //             }
  //             if (t.frequencyId !== undefined) {
  //               // setDividendfrequency(t.frequency)
  //               setFrequencyValue(t.frequencyI);
  //             }
  //             if (t.redeemConvertible !== undefined) {
  //               setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
  //             }
  //             if (t.lockInPeriod !== undefined) {
  //               let d = t.lockInPeriod
  //               let splitData = d.split(/([0-9]+)/);
  //               setTimeValue(splitData[2])
  //               setTime({ label: splitData[2], value: splitData[2] })
  //               setLockinperiod(splitData[1])
  //             }
  //             if (t.cumulativeNonCumulative !== undefined) {
  //               setCumulativeType(t.cumulativeNonCumulative);
  //               setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
  //             }
  //             // if (t.folioNumber !== undefined) {
  //             //   setCertificateorfolinumber(t.folioNumber);
  //             // }
  //             if (t.maturityValue !== undefined) {
  //               setMaturityvalue(t.maturityValue);
  //             }
  //             if (t.domConversion !== undefined) {
  //               setDateofmaturityorconversion(new Date(t.domConversion));
  //             }
  //             // if (t.frequencyValue){
  //             //   setFrequencyValue(t.frequencyValue);
  //             // }
  //             // }
  //             if (t.date !== undefined) {
  //               setDate(t.date);
  //             }
  //             setCost(t.totalCost)
  //             setShowlistedPref(true)
  //           }}
  //             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
  //         }
  //       }
  //     ])
  //   }
  //   if (newFilterType === 4) {
  //     setcolumns4(
  //       [
  //       {
  //         title: 'Scrip', field: 'companyId', width: 25,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData !== undefined) {
  //             if (props.rowData.tableData.editing !== undefined) {
  //               if (props.rowData.tableData.editing === 'update') {
  //                 if (props.value.label !== undefined) {
  //                   if (props.value.label !== props.rowData.company.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.company
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.company
  //                 }
  //               }
  //             }
  //           }
  //           return (
  //             <Select
  //               components={{ Control }}
  //               // value={props.value || null}
  //               // // value={selScrip}
  //               // // onChange={handleInput}
  //               // onChange={(selected) => props.onChange(selected)}
  //               // options={Scrip}
  //               value={ddValue || null}
  //               onChange={(selected) => props.onChange(selected)}
  //               options={unlistedCompany}
  //               name="companyId"
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Broker', field: 'brokerId', width: 25,
  //         editComponent: (props) => {
  //           let ddValue = props.value;
  //           if (props.rowData.tableData !== undefined) {
  //             if (props.rowData.tableData.editing !== undefined) {
  //               if (props.rowData.tableData.editing === 'update') {
  //                 if (props.value.label !== undefined) {
  //                   if (props.value.label !== props.rowData.broker.label) {
  //                     ddValue = props.value
  //                   }
  //                   else {
  //                     ddValue = props.rowData.broker
  //                   }
  //                 }
  //                 else {
  //                   ddValue = props.rowData.broker
  //                 }
  //               }
  //             }
  //           }
  //           return (
  //             <Select
  //               value={brokerUnlisted[0]}
  //               // value={selScrip}
  //               // onChange={handleInput}
  //               onChange={(selected) => props.onChange(selected)}
  //               // options={Broker}
  //               // placeholder="N/A"
  //               isDisabled='true'
  //               options={brokerData}
  //               name="brokerId"
  //               menuPosition="fixed"
  //               menuShouldBlockScroll="true"
  //             />
  //           )
  //         },
  //       },
  //       {
  //         title: 'Date of purchase', field: 'date', type: 'date',
  //         editComponent: props => {
  //           return (
  //             <MuiPickersUtilsProvider utils={DateFnsUtils}
  //               locale={props.dateTimePickerLocalization}>
  //               <DatePicker
  //                 format="dd/MM/yyyy"
  //                 value={props.value || null}
  //                 onChange={props.onChange}
  //                 clearable
  //                 maxDate={Date.now()}
  //               // InputProps={{
  //               //          style: {
  //               //               fontSize: 13,
  //               //          }
  //               // }}
  //               />
  //             </MuiPickersUtilsProvider>
  //           )
  //         }
  //       },
  //       {
  //         title: 'Quantity', field: 'quantity', type: 'numeric', width: 10,
  //         editComponent: ({ ...props }) => {
  //           let totalBrokrage = 0;
  //           totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //           props.value = totalBrokrage.toFixed(2);
  //           props.rowData.totalBrokerage = totalBrokrage;
  //           return <MTableEditField  {...props}
  //           />
  //           // handleAddRowBuy();
  //           return <MTableEditField {...props} />;
  //         },
  //       },
  //       {
  //         title: 'Price/Share', field: 'pricePerShare', type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           let totalCost = 0;
  //           totalCost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
  //           props.value = totalCost.toFixed(2);
  //           props.rowData.totalCost = totalCost;
  //           // handleAddRowBuy();
  //           return <MTableEditField {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.pricePerShare)}
  //           </span>
  //         ),
  //       },
  //       // { title: 'Brokerage/share', field: 'brokeragePerShare', type: 'numeric',
  //       //   editComponent: ({ ...props }) => {
  //       //     // handleAddRowBuy();
  //       //     return <MTableEditField {...props} />;
  //       //   },
  //       // },
  //       // { title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
  //       //   editComponent: ({ ...props }) => {
  //       //     let totalBrokrage = 0;
  //       //     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
  //       //     props.value = totalBrokrage;
  //       //     props.rowData.totalBrokerage = totalBrokrage;
  //       //     return <MTableEditField  {...props}
  //       //     />
  //       //   },
  //       // },
  //       {
  //         title: 'Total Cost', field: 'totalCost', type: 'numeric',
  //         editComponent: ({ ...props }) => {
  //           let totalCost = 0;
  //           totalCost = (props.rowData.quantity * props.rowData.pricePerShare); // + (props.rowData.totalBrokerage);
  //           props.value = totalCost.toFixed(2);
  //           props.rowData.totalCost = totalCost;
  //           return <MTableEditField {...props} />;
  //         },
  //         render: (rowData) => (
  //           <span>
  //             {NumberSeperator(rowData.totalCost)}
  //           </span>
  //         ),
  //       },
  //       {
  //         title: "Addtional Data", field: "internal_action",
  //         // editable: false,
  //         editComponent: ({ ...props }) => {
  //           let t = props.rowData;
  //           return <span onClick={() => {
  //             // if (headerId == 0) {
  //             if (t.dividendPerc !== undefined) {
  //               setDividend(t.dividendPerc);
  //             }
  //             if (t.frequencyId !== undefined) {
  //               setFrequencyValue(t.frequencyI);
  //             }
  //             if (t.redeemConvertible !== undefined) {
  //               setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
  //             }
  //             if (t.lockInPeriod !== undefined) {
  //               let d = t.lockInPeriod
  //               let splitData = d.split(/([0-9]+)/);
  //               setTimeValue(splitData[2])
  //               setTime({ label: splitData[2], value: splitData[2] })
  //               setLockinperiod(splitData[1])
  //             }
  //             if (t.cumulativeNonCumulative !== undefined) {
  //               setCumulativeType(t.cumulativeNonCumulative);
  //               setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
  //             }
  //             if (t.folioNumber !== undefined) {
  //               setCertificateorfolinumber(t.folioNumber);
  //             }
  //             if (t.maturityValue !== undefined) {
  //               setMaturityvalue(t.maturityValue);
  //             }
  //             if (t.domConversion !== undefined) {
  //               setDateofmaturityorconversion(new Date(t.domConversion));
  //             }
  //             // }
  //             if (t.date !== undefined) {
  //               setDate(t.date);
  //             }
  //             setCost(t.totalCost)
  //             setShowlistedPref(true)
  //           }}
  //             title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
  //         }
  //       }
  //     ])
  //   }
  // }, [companyListPre, brokerData, newFilterType, unlistedCompany])

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getDividedFrequencyList();
    })();
  }, [family.value])

  useEffect(() => {
    (async () => {
      if(family.value || brokerCall === true){
        await getBrokerList();
      }
    })();
  }, [family, brokerCall])

  useEffect(() => {
    if (newFilterType) {
      getCompanyList();
    }
  }, [newFilterType === 1, newFilterType === 3], newFilterType)

  useEffect(() => {
    // if(newFilterType === 2 || newFilterType === 4){
    getUnlistedComapy(family.value);
    // }
  }, [family, newFilterType])

  useEffect(() => {
    if (data.length > 0) {
      props.parentCallback(false, data)
    }
  }, [data])

  const handleUnlisted = (e) => {
    const { value } = e.target;
    setUnlistedComp(value);
    setUnlistedCompErr('')
  }
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <span onMouseDown={handleShow} className="m-0" title="Add unlisted scrip">
        <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
      {children}
    </components.Control>
  )
  const handleClose = () => {
    setShow(false);
    setUnlistedComp('');
    setUnlistedCompErr('')
  }
  const handleShow = () => setShow(true);
  const handlecloseDescModal = () => {
    setShowlistedPref(false);
    // handleCloseModalPopUp()
    setFormErr('')
  }
  const HandleChangeTime = (e) => {
    setTime(e);
    setTimeValue(e.value);
  }
  const handleChangeConvert = (e) => {
    setRedeemableorconvertible(e);
    setConvertType(e.value);
  }
  const handleChangeCumulative = (e) => {
    setCumulativeornoncumulative(e);
    setCumulativeType(e.value);
  }
  const handleChangeFrequency = (e) => {
    // setDividendfrequency(e);
    setFrequencyValue(e);
  }
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  const submitPreModal = () => {
    let formErr = []
    let isValid = true;
    if (newFilterType === 3) {
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please fill valid values!";
          isValid = false;
        } else {
          if (!cost || cost <= 0) {
            formErr["maturityValue"] = "Please fill valid values!";
            isValid = false;
          }
        }
      }
      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please fill valid values!!";
        isValid = false;
      }
      if (dateofmaturityorconversion && new Date(date)) {
        let trandate = moment(fixTimezoneOffset(new Date(date))).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date not same as Investment date!";
          isValid = false;
        }
      }
    }
    if (newFilterType === 4) {
      // if (certificateorfolinumber == "" || certificateorfolinumber == null) {
      //   formErr["certificateorfolinumber"] = "Please fill Certificate/Folio Number field";
      // }
      // if (lockinperiod == "" || lockinperiod == null) {
      //   formErr["lockinperiod"] = "Please fill Lock In Period field";
      //   isValid = false;
      // }
      if (cumulativeornoncumulative.value === "Cumulative") {
        if (!maturityvalue || maturityvalue <= 0) {
          formErr["maturityValue"] = "Please fill valid values!";
          isValid = false;
        } else {
          if (!cost || cost <= 0) {
            formErr["maturityValue"] = "Please fill valid values!";
            isValid = false;
          }
        }
      }
      if (!dividend || dividend <= 0) {
        formErr["dividend"] = "Please fill valid values!!";
        isValid = false;
      }
      if (dateofmaturityorconversion && new Date(date)) {
        let trandate = moment(fixTimezoneOffset(new Date(date))).format('L');
        let maturityDate = moment(fixTimezoneOffset(dateofmaturityorconversion)).format('L');
        if (maturityDate === trandate) {
          formErr["mdate"] = "Maturity Date not same as Investment date!";
          isValid = false;
        }
      }
    }
    if (isValid === true) {
      handlecloseDescModal();
    }
    setFormErr(formErr);
    return isValid;

    // let a=1
    // new Promise((resolve, reject) => {
    //   handleRowAdd(newData, resolve, reject)
    // }), 
  }
  const handleCloseModalPopUp = () => {
    setShowUnlisted(false);
    setCertificateorfolinumber('');
    setLockinperiod('');
    setDate(new Date())
    setTime([{ label: "Month", value: "Month" }])
    setFrequencyValue([{ label: "Monthly", value: 2 }])
    // setTenure('');
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    setMaturityvalue(0);
    setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
    setFormErr('')
    setCost(null)
  }
  const handleCloseModalPopUpAdd = (t) => {
    setCertificateorfolinumber('');
    setLockinperiod('');
    setDate(t.date)
    setTime([{ label: "Month", value: "Month" }])
    setFrequencyValue([{ label: "Monthly", value: 2 }])
    setRedeemableorconvertible([{ label: "Redeemable", value: "Redeemable" }]);
    setDividend(0);
    setDateofmaturityorconversion(new Date());
    setCumulativeornoncumulative([{ label: "Non Cumulative", value: "Non Cumulative" }]);
    setFormErr('')
    setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
    setMaturityvalue(0)
  }
  // const lockFamilyAndType = () => {
  //   if(props.family !== undefined && props.newFilterType !== undefined){
  //     props.lock(true, 1);
  //   }
  // }


  const getBrokerList = async () => {
    let id = family.value;
    await brokerList(id).then(res => {
      setLoading(false)
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let brokers = res.data._response.data.brokerDetails;
          let brokersData = [];
          let brokerUnlist = [];
          brokers && brokers.map(data => {
            brokerUnlist.push({ value: 1, label: "N/A" })
            brokersData.push({ value: data.brokerId, label: data.brokerName })
          })
          // brokersData.shift();
          setBrokerList(brokersData);
          setBrokerUnlisted(brokerUnlist)
        }
      } else {
        setLoading(false)
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false)
    })
  }
  const getCompanyList = async () => {
    let comId
    if (newFilterType === 1) {
      comId = 1
    } else if (newFilterType === 3) {
      comId = 11
      // comId = 1
    }
    if (comId !== null) {
      setLoading(true)
      await CompanyListByInsTypeId(comId).then(res => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let company = res.data._response.data.companyId;
            let companyData = [];
            company && company.map(data => {
             return  (companyData.push({ value: data.companyId, label: data.companyName }))
            })
            newFilterType === 1 ? setCompanyList(companyData) : setCompanyListPre(companyData)
          }
        } else {
          setError(res.data.message)
        }
      }).catch(e => {
        setLoading(false);
        setError(e.message)
      })
    }
  }

  const getDividedFrequencyList = async () => {
    await GetDividendFrequency()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
              return (  dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                }))
              });
            setDividendfrequency(dividendData);
            if (headerId === 0) {
              setFrequencyValue(dividendData[1])
            }
          }
        } else {
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };
  const getUnlistedComapy = (entityId) => {
    let Companyid
    if (newFilterType === 2) {
      Companyid = 10
    }
    if (newFilterType === 4) {
      Companyid = 20
    }
    setLoading(true);
    GetUnlistedScripBySubId(entityId, Companyid).then(res => {
      setLoading(false);
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let company = res.data._response.data.fetchUnlistedCompany;
          let companyData = [];
          company && company.map(data => {
            return (companyData.push({ value: data.companyId, label: data.companyName }))
          })
          setUnlistedCompanyList(companyData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setLoading(false);
      setError(e.message)
    })
  }

  const handelRowAdd = (newData, resolve, reject) => {
    setLoading(false);
    let errorList = []

    if (newFilterType === 1 || newFilterType === 2) newData.type = newData.type ? newData.type : { label: 'Normal', value: 'normal' };

    let companyData = newData.companyId;
    let brokerData = newData.brokerId;
    let transactionType = newData.type;

    if (newFilterType) {
      // if(family.length <= 0){
      //   errorList.push("Try Again, You didn't enter the Family field")
      // }
      if (props.newFilterType == null) {
        errorList.push("Try Again, You Didn't Select The Type Field")
      }
      if (newFilterType === 1) {
        if (props.family.length < 1) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (props.newFilterType == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        if (!newData.brokerId) {
          errorList.push("Try Again, You Didn't Select The Broker Field")
        }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        if (!newData.pricePerShare && newData.type.label !== "Bonus") {
          errorList.push("Try Again, You Didn't Enter Price/Share Field")
        }
        if ((isNaN(newData.pricePerShare) || newData.pricePerShare !== 0) && newData.type.label === "Bonus") {
          errorList.push("Price/Share value must be zero for bonus")
        }
        if ((isNaN(newData.brokeragePerShare) || newData.brokeragePerShare < 0) && newData.type.label !== "Bonus") {
          errorList.push("Try Again, Enter Brokerage/Share Before Submitting")
        }
        if ((isNaN(newData.brokeragePerShare) || newData.brokeragePerShare !== 0) && (newData.type.label === "Bonus" || newData.type.label === "Rights")) {
          errorList.push(`Brokerage/Share value must be zero for ${newData.type.label}`)
        }
      }
      if (newFilterType === 2) {
        if (props.family.length < 1) {
          errorList.push("Try Again, You didn't Select The Family Field")
        }
        if (props.newFilterType == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        // if (!newData.folioNumber) {
        //   errorList.push("Try Again, You Didn't Enter Folio Number Field")
        // }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        else if (newData.quantity) {
          let qvalue = parseFloat(newData.quantity);
          if (Number.isInteger(qvalue) === false) {
            errorList.push("Quantity Doesn't Accept Decimal Value!!");
          }
        }
        if (!newData.pricePerShare && newData.type.label !== "Bonus") {
          errorList.push("Try Again, You Didn't Enter The Price/Share Field")
        }
        if ((isNaN(newData.pricePerShare) || newData.pricePerShare !== 0) && newData.type.label === "Bonus") {
          errorList.push("Price/Share value must be zero for bonus")
        }
        // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
        //   errorList.push("please enter brokerage Per Share field")
        // }
        // if (!newData.lockInPeriod) {
        //   errorList.push("please enter Lock in period field")
        // }
      }
      if (newFilterType === 3) {
        if (props.family.length < 1) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (props.newFilterType == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        if (!newData.brokerId) {
          errorList.push("Try Again, You Didn't Select The Broker Field")
        }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        else if (newData.quantity) {
          let qvalue = parseFloat(newData.quantity);
          if (Number.isInteger(qvalue) === false) {
            errorList.push("Quantity Doesn't Accept Decimal Value!!");
          }
        }
        if (!newData.pricePerShare) {
          errorList.push("Try Again, You Didn't Enter The Price/Share Field")
        }
        if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
          errorList.push("Try Again, You Didn't Enter The Brokerage/Share Field")
        }
        if (!((dividend && maturityvalue) || cost)) {
          errorList.push("Try Again, You Didn't Fill The Addtional Information !!")
        }
      }
      if (newFilterType === 4) {
        if (props.family.length < 1) {
          errorList.push("Try Again, You Didn't Select The Family Field")
        }
        if (props.newFilterType == null) {
          errorList.push("Try Again, You Didn't Select The Type Field")
        }
        if (!newData.companyId) {
          errorList.push("Try Again, You Didn't Select The Scrip Field")
        }
        if (!newData.date) {
          errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
        }
        if (!newData.quantity) {
          errorList.push("Try Again, You Didn't Enter The Quantity Field")
        }
        else if (newData.quantity) {
          let qvalue = parseFloat(newData.quantity);
          if (Number.isInteger(qvalue) === false) {
            errorList.push("Quantity Doesn't Accept Decimal Value!!");
          }
        }
        if (!newData.pricePerShare) {
          errorList.push("Try Again, You Didn't Enter The Price/Share Field")
        }
        if (!((dividend && maturityvalue) || cost)) {
          errorList.push("Try Again, You Didn't Fill The Addtional Information !!")
        }
        // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
        //   errorList.push("please enter brokerage Per Share field")
        // }

      }
    }
    if (errorList.length < 1) {

      let dataToAdd = newFilterType === 1 ? [...allEntry1] : newFilterType === 2 ? [...allEntry2] : newFilterType === 3 ? [...allEntry3] : newFilterType === 4 ? [...allEntry4] : null;

      newData.companyId = newData.companyId.label;
      newData.company = companyData;

      if (newFilterType === 1) {
        newData.brokerId = newData.brokerId.label;
        newData.broker = brokerData;
        newData.subAccountId = props.family.value;

        //transaction type 
        newData.type = newData.type.label;
        newData.transactionType = transactionType;
      }
      if (newFilterType === 2) {
        newData.brokerId = brokerUnlisted[0].label;
        newData.broker = { label: brokerUnlisted[0].label, value: brokerUnlisted[0].value };
        newData.brokeragePerShare = 0;
        newData.totalBrokerage = 0;
        newData.subAccountId = props.family.value;

        //transaction type 
        newData.type = newData.type.label;
        newData.transactionType = transactionType;
      }
      if (newFilterType === 3) {
        newData.brokerId = newData.brokerId.label;
        newData.broker = brokerData;
        newData.subAccountId = props.family.value;
        newData.redeemConvertible = convertType;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.dividendPerc = parseInt(dividend);
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.frequencyId = frequencyValue.value || frequencyValue[0].value
        newData.frequencyI = frequencyValue
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
      }
      if (newFilterType === 4) {
        newData.brokerId = brokerUnlisted[0].label;
        newData.broker = { label: brokerUnlisted[0].label, value: brokerUnlisted[0].value };
        newData.subAccountId = props.family.value;
        newData.brokeragePerShare = 0;
        newData.totalBrokerage = 0;
        newData.redeemConvertible = convertType;
        // newData.folioNumber = certificateorfolinumber;
        newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
        newData.redeemConvertible = redeemableorconvertible.value || redeemableorconvertible[0].value;
        newData.cumulativeNonCumulative = cumulativeornoncumulative.value || cumulativeornoncumulative[0].value;
        newData.dividendPerc = parseInt(dividend);
        newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
        newData.frequencyId = frequencyValue.value || frequencyValue[0].value
        newData.frequencyI = frequencyValue
        if (newData.cumulativeNonCumulative === "Cumulative") {
          newData.maturityValue = parseInt(maturityvalue);
        }
        if (newData.cumulativeNonCumulative === "Non Cumulative") {
          newData.maturityValue = cost;
        }
      }
      if (newFilterType === 3 || newFilterType === 4) {
        let instrumentTypeId = convertType === "Redeemable" && newFilterType === 3 ? 15 : convertType === "Convertible" && newFilterType === 3 ? 14 : convertType === "Redeemable" && newFilterType === 4 ? 24 : 23
        newData.instrumentTypeId = instrumentTypeId;
      }
      if (newFilterType === 1 || newFilterType === 2) {
        newData.instrumentTypeId = newFilterType === 2 ? 10 : 1
      }

      dataToAdd.push(newData);
      var result = dataToAdd.map(function (o) {
        o.transactStockType = o.type && o.type !== "Normal" ? o.type : "Buy";
        return o;
      })
      newFilterType === 1 ? setAllEntry1(result) : newFilterType === 2 ? setAllEntry2(result) : newFilterType === 3 ? setAllEntry3(result) : setAllEntry4(result);
      resolve()
      setErrorMessages([]);
      handleCloseModalPopUp()
      // lockFamilyAndType();
      setIserror(false);
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
    }
  }
  const handelRowUpdate = (newData, oldData, resolve, reject) => {
    setLoading(false);
    let errorList = []
    let cpy = newData.company.label;
    let brk = newData.broker.label === undefined ? newData.brokerId.label : newData.broker.label;
    let transtype = null;
    let transactionType = null;
    if (newFilterType === 1 || newFilterType === 2) {
      transtype = newData.transactionType.label;
      transactionType = transtype === newData.type && newData.type.label === undefined ? newData.transactionType : newData.type;
    }
    let companyData = cpy === newData.companyId && newData.companyId.label === undefined ? newData.company : newData.companyId;
    let brokerData = newFilterType === 1 || newFilterType === 3 ? brk === newData.brokerId && newData.brokerId.label === undefined ? newData.broker : newData.brokerId : { label: brokerUnlisted[0].label, value: brokerUnlisted[0].value };
    if (newFilterType === 1) {
      if (props.family.length < 1) {
        errorList.push("Try Again, You Didn't Enter The Family Field")
      }
      if (props.newFilterType == null) {
        errorList.push("Try Again, You Didn't Enter The Type Field")
      }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Enter The Scrip Field")
      }
      if (!newData.brokerId) {
        errorList.push("Try Again, You Didn't Enter The Broker Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      if (!newData.pricePerShare && (transactionType.label !== "Bonus")) {
        errorList.push("Try Again, Price/Share Field Can't Be Blank")
      }
      if (isNaN((newData.pricePerShare) || newData.pricePerShare !== 0) && transactionType.label === "Bonus") {
        errorList.push("Price/Share value must be zero for bonus")
      }
      if ((isNaN(newData.brokeragePerShare) || newData.brokeragePerShare < 0) && transactionType.label !== "Bonus") {
        errorList.push("Try Again, Enter Brokerage/Share Before Submitting")
      }
      if ((isNaN(newData.brokeragePerShare) || newData.brokeragePerShare !== 0) && (transactionType.label === "Bonus" || transactionType.label === "Rights")) {
        errorList.push(`Brokerage/Share value must be zero for ${transactionType.label}`)
      }
    }
    if (newFilterType === 3) {
      if (props.family.length < 1) {
        errorList.push("Try Again, You Didn't Enter The Family Field")
      }
      if (props.newFilterType == null) {
        errorList.push("Try Again, You Didn't Enter The Type Field")
      }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Enter The Scrip Field")
      }
      if (!newData.brokerId) {
        errorList.push("Try Again, You Didn't Enter The Broker Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Try Again, You Didn't Enter The Price/Share Field")
      }
      if (newData.brokeragePerShare < 0 || newData.brokeragePerShare === undefined || isNaN(newData.brokeragePerShare)) {
        errorList.push("Try Again, You Didn't Enter The Brokerage/Share Field")
      }
    }

    if (newFilterType === 4) {
      if (props.family.length < 1) {
        errorList.push("Try Again, You Didn't Enter The Family Field")
      }
      if (props.newFilterType == null) {
        errorList.push("Try Again, You Didn't Enter The Type Field")
      }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Enter The Scrip Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!!");
        }
      }
      if (!newData.pricePerShare) {
        errorList.push("Try Again, You Didn't Enter The Price/Share Field")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("please enter brokerage Per Share field")
      // }
    }
    if (newFilterType === 2) {
      if (props.family.length < 1) {
        errorList.push("Try Again, You Didn't Enter The Family Field")
      }
      if (props.newFilterType == null) {
        errorList.push("Try Again, You Didn't Enter The Type Field")
      }
      // if (!newData.folioNumber) {
      //   errorList.push("Try Again, You Didn't Enter Folio Number Field")
      // }
      if (!newData.companyId) {
        errorList.push("Try Again, You Didn't Enter The Scrip Field")
      }
      if (!newData.date) {
        errorList.push("Try Again, Enter Date Of Purchase Before Submitting")
      }
      if (!newData.quantity) {
        errorList.push("Try Again, You Didn't Enter The Quantity Field")
      }
      // if (!newData.folioNumber) {
      //   errorList.push("please enter Folio Number Field")
      // }
      else if (newData.quantity) {
        let qvalue = parseFloat(newData.quantity);
        if (Number.isInteger(qvalue) === false) {
          errorList.push("Quantity Doesn't Accept Decimal Value!!");
        }
      }
      if (!newData.pricePerShare && (transactionType.label !== "Bonus")) {
        errorList.push("Try Again, You Didn't Enter The Price/Share Field")
      }
      if (isNaN(newData.pricePerShare) || newData.pricePerShare !== 0 && transactionType.label === "Bonus") {
        errorList.push("Price/Share value must be zero for bonus")
      }
      // if (newData.brokeragePerShare < 0 || newData.brokeragePerShare == undefined || isNaN(newData.brokeragePerShare)) {
      //   errorList.push("please enter brokerage Per Share field")
      // }
      // if (!newData.lockInPeriod) {
      //   errorList.push("please enter Lock in period field")
      // }
    }
    if (errorList.length < 1) {
      let dataToUpdate = newFilterType === 1 ? [...allEntry1] : newFilterType === 2 ? [...allEntry2] : newFilterType === 3 ? [...allEntry3] : [...allEntry4];
      // dataToAdd.push(newData);
      const index = oldData.tableData.id;
      newData.companyId = cpy === newData.companyId && newData.companyId.label === undefined ? newData.companyId : newData.companyId.label !== undefined ? newData.companyId.label : newData.companyId.label;

      newData.brokerId = brk === newData.brokerId && newData.brokerId.label === undefined ? newData.brokerId : newData.brokerId.label !== undefined ? newData.brokerId.label : newData.brokerId.label;

      if (newFilterType === 1 || newFilterType === 2) {
        newData.type = transtype === newData.type && newData.type.label === undefined ? newData.type : newData.type.label !== undefined ? newData.type.label : newData.type.label;
      }
      // if(newFilterType == 2){
      // newData.companyId = companyData
      // }
      if (oldData.tableData.editing === "update") {
        newData.company = companyData;
        newData.broker = brokerData;

        if ((newFilterType === 3 || newFilterType === 4) && (lockinperiod !== "" || dividend !== 0)) {
          newData.domConversion = fixTimezoneOffset(dateofmaturityorconversion);
          newData.redeemConvertible = convertType;
          newData.lockInPeriod = lockinperiod === "" ? 0 + timeValue : lockinperiod + timeValue;
          // if (newFilterType == 4) {
          //   newData.folioNumber = certificateorfolinumber;
          // }
          newData.dividendPerc = dividend;
          newData.frequencyId = frequencyValue.value || frequencyValue[0].value;
          newData.frequencyI = frequencyValue;
          newData.cumulativeNonCumulative = cumulativeType;
          if (newData.cumulativeNonCumulative === "Cumulative") {
            // newData.maturityValue = newData.maturityValue ;
            newData.maturityValue = parseInt(maturityvalue);
          }
          if (newData.cumulativeNonCumulative === "Non Cumulative") {
            newData.maturityValue = parseInt(cost); //newData.totalCost;
          }
          newData.instrumentTypeId = convertType === "Redeemable" && newFilterType === 3 ? 15
            : convertType === "Convertible" && newFilterType === 3 ? 14 : convertType === "Redeemable" && newFilterType === 4 ? 24 : 23;
          // newData.tenure = tenure;
        } else {
          if (newData.cumulativeNonCumulative === "Non Cumulative") {
            newData.maturityValue = newData.totalCost;
          }
        }
        if (newFilterType === 1 || newFilterType === 2) {
          newData.transactionType = transactionType;
          newData.instrumentTypeId = newFilterType === 2 ? 10 : 1;
          newData.transactStockType = newData.type !== "Normal" ? newData.type : "Buy"
        }
      }
      dataToUpdate[index] = newData
      newFilterType === 1 ? setAllEntry1([...dataToUpdate]) : newFilterType === 2 ? setAllEntry2([...dataToUpdate]) : newFilterType === 3 ? setAllEntry3([...dataToUpdate]) : setAllEntry4([...dataToUpdate]);
      resolve()
      setErrorMessages([])
      setIserror(false)
      handleCloseModalPopUp()
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      reject()
      handleCloseModalPopUp()
    }
  }
  const handelRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = newFilterType === 1 ? [...allEntry1] : newFilterType === 2 ? [...allEntry2] : newFilterType === 3 ? [...allEntry3] : [...allEntry4]
    // if(oldData.stockId === undefined) {
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    await newFilterType === 1 ? setAllEntry1([...dataDelete]) : newFilterType === 2 ? setAllEntry2([...dataDelete]) : newFilterType === 3 ? setAllEntry3([...dataDelete]) : setAllEntry4([...dataDelete]);
    // handleTotalAmt([...dataDelete], );
    resolve()
    // props.parentCallback(true);
    props.lock(false, 1)
  }
  const handelSubmitTable = async () => {
    setLoading(true);
    if (allEntry1.length >= 1 || allEntry2.length >= 1 || allEntry3.length >= 1 || allEntry4.length >= 1) {
      let resultDataBuy = newFilterType === 1 ? allEntry1 : newFilterType === 2 ? allEntry2 : newFilterType === 3 ? allEntry3 : allEntry4;;
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultOpenBal = deepCloneBuy.map(function (o) {
        // var resultBuy = resultDataBuy.map(function (o) {
        o.companyId = o.company.value;
        o.brokerId = o.broker == null ? 1 : o.broker.value;
        o.instrumentId = 1;
        o.subAccountId = props.family.value;
        // o.type = newFilterType == 2 ? null : "Normal";
        if (newFilterType === 3 || newFilterType === 4) o.type = "Normal";
        return o;
      })
      if (allEntry1 || allEntry2 || allEntry3 || allEntry4) {
        await addOpeningStocks(resultOpenBal).then(res => {
          setLoading(false)
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              Swal.fire(res.data._response.message, "", 'success');
              setAllEntry1([]);
              setAllEntry2([]);
              setAllEntry3([]);
              setAllEntry4([]);
              setErrorMessages([])
              setIserror(false)
              props.parentCallback(true);
              props.lock(false, 1);
            } else {
              Swal.fire(res.data._response.message, "", 'info');
            }
          } else {
            Swal.fire("Something went wrong!", "", 'error');
            setLoading(false)
          }
        }).catch(e => {
          Swal.fire("Something went wrong!", "", 'error');
          setError(e.message)
          setLoading(false)
        })
      }
      else {
        Swal.fire("Please fill required fields!", "", 'info');
        setLoading(false)
      }
    }
    else {
      Swal.fire("Please fill required information!", "", 'info');
      setLoading(false)
    }
  }
  const handelResetTable = () => {
    setAllEntry1([]);
    setAllEntry2([]);
    setAllEntry3([]);
    setAllEntry4([]);
    setSelScrip([]);
    setErrorMessages([]);
    setIserror(false)
    props.parentCallback(true);
    props.lock(false, 1)
    setLoading(false);
  }
  //submit unlisted companies
  const submitUnlisted = (e) => {
    e.preventDefault();

    if (unlistedComp !== '') {
      let subAccountId = props.family.value
      if (subAccountId === undefined) {
        setUnlistedCompErr("*Please Select Family!");
      } else {
        let Id = entityId == null ? subAccountId : entityId;
        let newacid;
        if (newFilterType === 2) {
          newacid = 10
        }
        if (newFilterType === 4) {
          newacid = 20
        }
        if (newFilterType === 3) {
          newacid = 11
        }
        if (newFilterType === 1) {
          newacid = 1
        }
        let data = {
          companyName: unlistedComp.trim(),
          instrumentTypeId: newacid,
          subAccountId: Id
        }
        PostUnlistedScripBySubId(data)
          .then(res => {
            setLoading(false)
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                const lastEle = 1
                // getCompanyList(lastEle);
                setUnlistedComp('');
                setUnlistedCompErr('');
                handleClose()
                Swal.fire("Scrip Added successfully!", "", 'success');
                let t = res.data._response.data.companyName
                let v = res.data._response.data.companyId
                let comp = { label: t, value: v }
                if (newFilterType === 2 || newFilterType === 4) {
                  unlistedCompany.unshift(comp)
                }
                if (newFilterType === 3) {
                  companyListPre.unshift(comp)
                }
              } else {
                Swal.fire(res.data._response.message, "", 'info');
              }
            } else {
              Swal.fire("Something went wrong!", "", 'error');
              setLoading(false)
            }
          })
          .catch(e => {
            setError(e.message)
            setLoading(false)
          })
      }
    } else {
      setUnlistedCompErr("Company name required!")
    }
  }
  // state clear for all family change
  const clearAll = () => {
    setAllEntry1([])
    setAllEntry2([])
    setAllEntry3([])
    setAllEntry4([])
  }
  // state clear for all family change
  props.childFunc.current = clearAll

  const disbtn = () => {
    // let info = newFilterType === 1 ? data : null;
    return family && newFilterType
  }

  const columns = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      headerStyle: {
        // backgroundColor: "#039bc5",
      },
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            // components={{ Control }}
            // value={props.value || null}
            // // value={selScrip}
            // // onChange={handleInput}
            // onChange={(selected) => props.onChange(selected)}
            // options={Scrip}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            // value={selScrip}
            // onChange={handleInput}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Transaction Type', field: 'type', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.transactionType.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.transactionType
                }
              }
              else {
                ddValue = props.rowData.transactionType
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || [{ label: "Normal", value: "normal" }]}
            onChange={(selected) => props.onChange(selected)}
            options={StockTypes}
            name="type"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            styles={{
              option: (base, state) => ({
                ...base,
                textAlign: "left",
              })
            }}

          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: "13px",
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric',
      width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props}) =>{
        return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Share', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let typeDDValue = props.rowData.type === undefined ? undefined : props.rowData.type.label || props.rowData.type;
        let price = typeDDValue === 'Bonus' ? 0 : props.rowData.pricePerShare;
        props.value = price;
        props.rowData.pricePerShare = Number(price);

        return <div className={CleavTablFirstTxtField}>  <div className={typeDDValue !== 'Bonus'? CleavTablSecondTxtField : cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={typeDDValue}
          disabled={typeDDValue !== 'Bonus' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} disabled={typeDDValue !== 'Bonus' ? false : true} />
      },
    },
    {
      title: 'Brokerage/share', field: 'brokeragePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.brokeragePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let typeDDValue = props.rowData.type === undefined ? undefined : props.rowData.type.label || props.rowData.type;
        let brokrage = typeDDValue === 'Normal' || typeDDValue === undefined ? props.rowData.brokeragePerShare : 0;
        props.value = brokrage;
        props.rowData.brokeragePerShare = Number(brokrage);

        return <div className={CleavTablFirstTxtField}><div className={typeDDValue === 'Normal' || typeDDValue === undefined ? CleavTablSecondTxtField : cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Brokerage/share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={typeDDValue}
          disabled={typeDDValue === 'Normal' || typeDDValue === undefined ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} disabled={typeDDValue === 'Normal' || typeDDValue === undefined ? false : true} />
      },
    },
    {
      title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalBrokerage)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(2);
        props.rowData.totalBrokerage = Number(totalBrokrage.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Brokerage'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // // <MTableEditField  {...props}
        // />
      },
    },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = Number(totalCost.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          disabled
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField {...props} />;
      }
    },
    // { field: 'target', title: 'Target', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'stopLoss', title: 'Stop Loss', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'estHoldingPeriod', title: 'Est Hldg Period', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { field: 'recommendedBy', title: 'Recommended by', width: 130,
    //   editComponent: ({ ...props }) => {
    //     return <MTableEditField {...props} />;
    //   },
    // }
  ];

  const columns2 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            // value={props.value || null}
            // // value={selScrip}
            // // onChange={handleInput}
            // onChange={(selected) => props.onChange(selected)}
            // options={Scrip}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={brokerUnlisted[0]}
            // value={selScrip}
            // onChange={handleInput}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            // placeholder="N/A"
            isDisabled='true'
            options={brokerUnlisted}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Transaction Type', field: 'type', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.transactionType.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.transactionType
                }
              }
              else {
                ddValue = props.rowData.transactionType
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || [{ label: "Normal", value: "normal" }]}
            onChange={(selected) => props.onChange(selected)}
            options={StockTypes}
            name="type"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            styles={{
              option: (base, state) => ({
                ...base,
                textAlign: "left",
              })
            }}

          />
        )
      },
    },
    // { field: 'folioNumber', title: "Folio Number", width: 130 },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    // { title: 'Folio No', field: 'folioNumber', type: 'text' },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric', width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent:({...props})=>{
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
      }
    },
    {
      title: 'Price/Share', field: 'pricePerShare', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let typeDDValue = props.rowData.type === undefined ? undefined : props.rowData.type.label || props.rowData.type;
        let price = typeDDValue === 'Bonus' ? 0 : props.rowData.pricePerShare;
        props.value = price;
        props.rowData.pricePerShare = Number(price);

        return <div className={CleavTablFirstTxtField}><div className={typeDDValue !== 'Bonus'? CleavTablSecondTxtField:cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={typeDDValue}
          disabled={typeDDValue !== 'Bonus' ? false : true}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} disabled={typeDDValue !== 'Bonus' ? false : true} />
      },
    },
    // { title: 'Brokerage/share', field: 'brokeragePerShare', type: 'numeric'},
    // { title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     let totalBrokrage = 0;
    //     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //     props.value = totalBrokrage;
    //     props.rowData.totalBrokerage = totalBrokrage;
    //     return <MTableEditField  {...props}
    //     />
    //   },
    // },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare); //+ (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = Number(totalCost.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        //  <MTableEditField {...props} />;
      }
    },
    // { title: 'Lock in Period', field: 'lockInPeriod', width: 130 }
  ];

  const columns3 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        const onScripChange = (selected) => {
          props.onChange(selected);

        }
        return (
          <Select
            components={{ Control }}
            // value={props.value || null}
            // // value={selScrip}
            // // onChange={handleInput}
            // onChange={(selected) => props.onChange(selected)}
            // options={Scrip}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            // options={companyList}
            options={companyListPre}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            // value={selScrip}
            // onChange={handleInput}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            options={brokerData}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Quantity', field: 'quantity', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Price/Share', field: 'pricePerShare', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
    },
    {
      title: 'Brokerage/Share', field: 'brokeragePerShare', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Brokerage/Share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.brokeragePerShare)}
        </span>
      ),
    },
    {
      title: 'Total Brokerage', field: 'totalBrokerage', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalBrokrage = 0;
        totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        props.value = totalBrokrage.toFixed(2);
        props.rowData.totalBrokerage = Number(totalBrokrage.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Brokerage'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalBrokerage)}
        </span>
      ),
    },
    {
      title: 'Total Cost', field: 'totalCost', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalcost = 0;
        totalcost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        props.value = totalcost.toFixed(2);
        props.rowData.totalCost = Number(totalcost.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    // { field: 'currentMp', title: 'Current MP', width: 130, type: 'numeric',
    //   type: 'numeric',
    //   // editComponent: ({ ...props }) => {
    //   //   return <MTableEditField  {...props} />;
    //   // },
    // },
    // { field: 'currentMarketValue', title: 'Current Market Value', width: 130, type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     let CurrentMV = 0;
    //     CurrentMV = props.rowData.quantity * props.rowData.currentMp;
    //     props.value = CurrentMV;
    //     props.rowData.currentMarketValue = CurrentMV;
    //     return <MTableEditField {...props} />;
    //   }
    // },
    // { field: 'target', title: 'Target', width: 130,
    //   // editComponent: ({ ...props }) => {
    //   //   return <MTableEditField {...props} />;
    //   // },
    // },
    // { field: 'stopLoss', title: 'Stop Loss', width: 130,
    //   // editComponent: ({ ...props }) => {
    //   //   return <MTableEditField {...props} />;
    //   // },
    // },
    // { field: 'estHldgPeriod', title: 'Est Hldg Period', width: 130,
    //   // editComponent: ({ ...props }) => {
    //   //   return <MTableEditField {...props} />;
    //   // },
    // },
    // { field: 'recommendedBy', title: 'Recommended by', width: 130 },
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {

        let t = props.rowData
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.dividendPerc !== undefined) {
              setDividend(t.dividendPerc);
            }
            if (t.frequencyId !== undefined) {
              // setDividendfrequency(t.frequency)
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            // if (t.folioNumber !== undefined) {
            //   setCertificateorfolinumber(t.folioNumber);
            // }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            // if (t.frequencyValue){
            //   setFrequencyValue(t.frequencyValue);
            // }
            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost);
          } else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i className="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }
  ];

  const columns4 = [
    {
      title: 'Scrip', field: 'companyId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.company.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.company
                }
              }
              else {
                ddValue = props.rowData.company
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            // value={props.value || null}
            // // value={selScrip}
            // // onChange={handleInput}
            // onChange={(selected) => props.onChange(selected)}
            // options={Scrip}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={unlistedCompany}
            name="companyId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Broker', field: 'brokerId', width: 25,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.broker.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.broker
                }
              }
              else {
                ddValue = props.rowData.broker
              }
            }
          }
        }
        return (
          <Select
            value={brokerUnlisted[0]}
            // value={selScrip}
            // onChange={handleInput}
            onChange={(selected) => props.onChange(selected)}
            // options={Broker}
            // placeholder="N/A"
            isDisabled='true'
            options={brokerUnlisted}
            name="brokerId"
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'Date of purchase', field: 'date', type: 'date',
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              autoOk={true}
              maxDate={Date.now()}
            // InputProps={{
            //          style: {
            //               fontSize: 13,
            //          }
            // }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      render: rowData => (
        moment(rowData.date).format('DD/MM/YYYY')
      )
    },
    {
      title: 'Quantity', field: 'quantity', type: 'numeric', width: 10,
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.quantity)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // let totalBrokrage = 0;
        // totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
        // props.value = totalBrokrage.toFixed(2);
        // props.rowData.totalBrokerage = totalBrokrage;
        // return <MTableEditField  {...props}
        // />
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Quantity'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Price/Share', field: 'pricePerShare', type: 'numeric',
      editComponent: ({ ...props }) => {
        // let totalCost = 0;
        // totalCost = (props.rowData.quantity * props.rowData.pricePerShare) + (props.rowData.totalBrokerage);
        // props.value = totalCost.toFixed(2);
        // props.rowData.totalCost = totalCost;
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Price/Share'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.pricePerShare)}
        </span>
      ),
    },
    // { title: 'Brokerage/share', field: 'brokeragePerShare', type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     // handleAddRowBuy();
    //     return <MTableEditField {...props} />;
    //   },
    // },
    // { title: 'Total Brokerage', field: 'totalBrokerage', type: 'numeric',
    //   editComponent: ({ ...props }) => {
    //     let totalBrokrage = 0;
    //     totalBrokrage = props.rowData.quantity * props.rowData.brokeragePerShare;
    //     props.value = totalBrokrage;
    //     props.rowData.totalBrokerage = totalBrokrage;
    //     return <MTableEditField  {...props}
    //     />
    //   },
    // },
    {
      title: 'Total Cost', field: 'totalCost', type: 'numeric',
      editComponent: ({ ...props }) => {
        let totalCost = 0;
        totalCost = (props.rowData.quantity * props.rowData.pricePerShare); // + (props.rowData.totalBrokerage);
        props.value = totalCost.toFixed(2);
        props.rowData.totalCost = Number(totalCost.toFixed(2));
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='Total Cost'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(e.target.rawValue)} />
      </div>
      </div>
        // <MTableEditField {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.totalCost)}
        </span>
      ),
    },
    {
      title: "Addtional Data", field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData;
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.dividendPerc !== undefined) {
              setDividend(t.dividendPerc);
            }
            if (t.frequencyId !== undefined) {
              setFrequencyValue(t.frequencyI);
            }
            if (t.redeemConvertible !== undefined) {
              setRedeemableorconvertible({ label: t.redeemConvertible, value: t.redeemConvertible });
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.cumulativeNonCumulative !== undefined) {
              setCumulativeType(t.cumulativeNonCumulative);
              setCumulativeornoncumulative({ label: t.cumulativeNonCumulative, value: t.cumulativeNonCumulative })
            }
            if (t.folioNumber !== undefined) {
              setCertificateorfolinumber(t.folioNumber);
            }
            if (t.maturityValue !== undefined) {
              setMaturityvalue(t.maturityValue);
            }
            if (t.domConversion !== undefined) {
              setDateofmaturityorconversion(new Date(t.domConversion));
            }
            if (t.date !== undefined) {
              setDate(t.date);
            }
            setCost(isNaN(t.totalCost) ? 0 : t.totalCost)
          }
          else {
            handleCloseModalPopUpAdd(t);
          }
          setShowlistedPref(true)
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i className="bi bi-plus ml-4 h4 text-secondary"></i></button></span>
      }
    }
  ];

  return (
    <div>
      <Container fluid >
        {/* {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null} */}
        {newFilterType === 1 ?
          <Row className="openingBalanceTbl">
            <MaterialTable
              title="Opening Balance (Stocks)"
              data={allEntry1}
              isLoading={loading}
              columns={columns}
              options={{
                actionsColumnIndex: -1,
                search: false,
                //   showTitle: false,
                addRowPosition: "first",
                paging: false
              }}
              minRows={0}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    handelRowAdd(newData, resolve, reject)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handelRowUpdate(newData, oldData, resolve, reject)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    handelRowDelete(oldData, resolve)
                    // props.parentCallback(true);
                  }),
                onRowAddCancelled: rowData => {
                  setIserror(false)
                  setErrorMessages([])
                },
                onRowUpdateCancelled: rowData => {
                  setIserror(false)
                  setErrorMessages([])
                }
              }}
            />
          </Row> :
          newFilterType === 2 ?
            <Row className="openingBalanceStockUnlTbl">
              <MaterialTable
                title="Opening Balance (Stocks)"
                data={allEntry2}
                isLoading={loading}
                columns={columns2}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                  paging: false
                }}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      handelRowAdd(newData, resolve, reject)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      handelRowUpdate(newData, oldData, resolve, reject);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      handelRowDelete(oldData, resolve, reject)
                      // props.parentCallback(true);
                    }),
                  onRowAddCancelled: rowData => {
                    setIserror(false)
                    setErrorMessages([])
                  },
                  onRowUpdateCancelled: rowData => {
                    setIserror(false)
                    setErrorMessages([])
                  }
                }}
              />
            </Row>
            : newFilterType === 3 ?
              <Row className="openingBalanceStockListPTbl">
                <Col md="12" className="BuyTbl">
                  <MaterialTable
                    title="Opening Balance (Stocks)"
                    data={allEntry3}
                    isLoading={loading}
                    columns={columns3}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false
                    }}
                    //  actions={actions}
                    components={{
                      Toolbar: props => (
                        <MTableToolbar
                          {...props}
                          actions={props.actions.filter(a => a.tooltip === "Add")}
                        />
                      )
                    }}
                    editable={{
                      onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                          handelRowAdd(newData, resolve, reject)
                        }),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handelRowUpdate(newData, oldData, resolve, reject)
                        }),
                      onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                          handelRowDelete(oldData, resolve)
                          // props.parentCallback(true);
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
              </Row>
              : newFilterType === 4 ?
                <Row className='openingBalanceStockUnlPTbl'>
                  <Col md="12">
                    <MaterialTable
                      title="Opening Balance (Stocks)"
                      data={allEntry4}
                      isLoading={loading}
                      columns={columns4}
                      options={{
                        actionsColumnIndex: -1,
                        addRowPosition: "first",
                        search: false,
                        paging: false
                      }}
                      //   actions={actions}
                      components={{
                        // Cell: (props) => (
                        //   <Tooltip title={props.value ? props.value : ''}>
                        //     <MTableCell {...props} />
                        //   </Tooltip>
                        // ),
                        Toolbar: props => (
                          <MTableToolbar
                            {...props}
                            actions={props.actions.filter(a => a.tooltip === "Add")}
                          />
                        )
                      }}
                      editable={{
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            handelRowAdd(newData, resolve, reject)
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            handelRowUpdate(newData, oldData, resolve, reject)
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            handelRowDelete(oldData, resolve)
                            // props.parentCallback(true);
                          }),
                        onRowAddCancelled: rowData => {
                          setIserror(false)
                          setErrorMessages([])
                        },
                        onRowUpdateCancelled: rowData => {
                          setIserror(false)
                          setErrorMessages([])
                        }
                      }}
                    />
                  </Col>
                </Row>
                : null
        }
        <div className="mt-5 d-flex justify-content-center">
          <Stack spacing={4} direction="row" >
            <Button variant="contained" color="primary" id="submitButton" className="btn btn-primary" onClick={handelSubmitTable} disabled={!disbtn()} style={{ width: "5rem" }}>
              Save
            </Button>
            <Button variant="contained" color="primary" className="btn btn-secondary" onClick={handelResetTable}>
              Cancel
            </Button>
          </Stack>
        </div>
        <div>
          {iserror &&
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>
              })}
            </Alert>
          }
        </div>
      </Container>
      {/* unlisted scrip jsx */}
      <Container>
        {newFilterType === 2 || newFilterType === 4 || newFilterType === 3 ?
          <Modal show={show} onHide={handleClose} animation={false} size="sm" centered>
            <Modal.Header closeButton>
              <Modal.Title>{newFilterType === 2 ? "Unlisted Scrip" : newFilterType === 4 ? "Unlisted Preference Scrip" : "Listed Preference Scrip"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form >
                <div className="form-group mt-2 mb-2">
                  <label for="inputUserName">Company Name</label>
                  <input className="form-control mt-2" placeholder={newFilterType === 2 ? "unlisted scrip" : newFilterType === 4 ? "unlisted preference scrip" : "listed preference scrip"} type="text" autoComplete="off" name="unlistedCompName" onChange={handleUnlisted} />
                  {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                Save
              </Button>
            </Modal.Footer>
          </Modal> : null}
      </Container>
      {/* additional data modal */}
      <Modal show={showlistedPref} onHide={handlecloseDescModal} animation={true} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="m-auto"><h5 className='text-center'>{newFilterType === 3 ? "Listed Preference" : "Unlisted Preference"}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >
            <Row>
              {/* {newFilterType == 4 ?
                <Col md="6" className="mb-2">
                  <>
                    <Form.Label>Certificate/Folio Number<span className="text-danger h6">*</span></Form.Label>
                    <Form.Control type="text"
                      onChange={(e) => {
                        setCertificateorfolinumber(e.target.value);
                        setFormErr({ ...formErr, certificateorfolinumber: '' })
                      }} autoComplete="off"
                      name="certificateorfolinumber" id="certificateorfolinumber" value={certificateorfolinumber} style={{ textTransform: "uppercase" }} />
                  </>
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.certificateorfolinumber}</div> : null}
                </Col>
                : null} */}
              <Col md="6" className="mb-2">
                <>
                  <Form.Label className="d-block">Lock in Period</Form.Label>
                  <Form.Control type="text"
                    onChange={(e) => setLockinperiod(e.target.value)} autoComplete="off"
                    name="lockinperiod" id="lockinperiod" value={lockinperiod} className="lockinperiodno" />
                  <Dropdown
                    value={time}
                    name='period'
                    options={periodList}
                    onChange={HandleChangeTime}
                    className="SelectClass lockinperiodlist"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                  // onInputChange={HandleInput}
                  />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                </>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Redeemable/Convertible</Form.Label>
                <Dropdown
                  value={redeemableorconvertible}
                  name='RedeemableOrConvertible'
                  options={redeemableOrConvertibleList}
                  onChange={handleChangeConvert}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                  isDisabled={headerId !== 0 ? true : false}
                // onInputChange={HandleInput}
                />

              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Cumulative/Non Cumulative</Form.Label>
                <Dropdown
                  value={cumulativeornoncumulative}
                  name='CumulativeOrNoncumulative'
                  options={cumulativeNonCumulativeList}
                  onChange={handleChangeCumulative}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                // onInputChange={HandleInput}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Dividend %<span className="text-danger h6">*</span></Form.Label>
                <Form.Control type="number"
                  onChange={(e) => {
                    setDividend(e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value)
                    setFormErr({ ...formErr, dividend: "" })
                  }} autoComplete="off"
                  name="dividend" id="dividend" value={dividend} />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.dividend}</div> : null}

              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Date of Maturity/Conversion </Form.Label>
                <DatePicker
                  className="form-control"
                  selected={dateofmaturityorconversion}
                  dateFormat="dd/MM/yyyy"
                  onChange={(dateofmaturityorconversion) => setDateofmaturityorconversion(dateofmaturityorconversion)}
                  minDate={new Date()}
                  // maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                  disabled={headerId !== 0 ? true : false}
                />
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.mdate}</div> : null}
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mb-2">
                <Form.Label>Dividend Frequency</Form.Label>
                <Dropdown
                  value={frequencyValue}
                  name='dividendFrequency'
                  options={dividendfrequency}
                  onChange={handleChangeFrequency}
                  className="SelectClass"
                  searchable={true}
                  clearable={true}
                  noResultsText={"No result"}
                />
                {/* <input type="text"
                        onChange={(e) => setDividendfrequency(e.target.value)} autoComplete="off"
                        name="dividendfrequency" id="dividendfrequency" value={dividendfrequency} /> */}
              </Col>
              <Col md="6" className="mb-2">
                <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                {
                  cumulativeornoncumulative.value === "Cumulative" ?

                    <Cleave
                      className='form-control'
                      autoComplete='off'
                      name="maturityvalue" id="maturityvalue"
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      value={maturityvalue}
                      onChange={(e) => {
                        setMaturityvalue(e.target.rawValue);
                        setFormErr({ ...formErr, maturityvalue: '' })
                      }}
                    />
                    :
                    <Cleave 
                      className='form-control'
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      onChange={(e) => {
                        setFormErr({ ...formErr, maturityvalue: '' })
                      }} 
                      name="maturityvalue" id="maturityvalue" value={cost ? cost: 0} disabled />
                }
                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityValue}</div> : null}
              </Col>


            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" className="mx-2 w100" onClick={submitPreModal} >
            Save
          </Button><Button variant="secondary" className="mx-2 w100" onClick={handleCloseModalPopUp} >
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default StocksOpenBal;