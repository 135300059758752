/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Loader from "../../components/loadingAnimation/index";
import DatePickerB from 'react-datepicker';
import MaterialTable, { MTableEditField } from "material-table";
import { Alert, AlertTitle } from '@material-ui/lab';
import Select from "react-select";
import _, { toNumber } from "lodash";
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavefocusedTxtField,cleavedisabledField } from '../StocksDetail/ClevTablCss';
import Cleave from 'cleave.js/react';
import swal from 'sweetalert';
import { bullionUnit, bullionUnit2, bullionUnit3 } from '../../modules/helpers/constant';
import { ValuationList } from '../../pages/Stocks/StocksAPI';
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import moment from 'moment';
import Swal from "sweetalert2";

import { AddBulionsDetails, UpdateBullionDetails, FetchBulionsInvestmentType, fetchSellInvestmentType, fetchSelectionData, fetchUpdatedSelectionData,DeleteBullionTransaction, DeleteInnerGridBullionTransaction } from '../../pages/Bullion/BullionApi'


function BullionDetils(props) {
    const { showB, onHide, bullionHeaderID, entityId, bullionTransaction } = props;

    const [date, setDate] = useState(new Date());
    const [narration, setNarration] = useState("");
    const [show, setShow] = useState(false);
    const [scheme, setScheme] = useState('');
    const [schemeErr, setSchemeErr] = useState('');

    const [stockValuationType, setStockValuationType] = useState([]);
    const [investmentType, setInvestmentType] = useState([]);
    //selection model states
    const [selection, setSelection] = useState(false);
    const [selectionQty, setSelectionQty] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [errorMessagesQntity, setErrorMessagesQntity] = useState([]);

    const [formErr, setFormErr] = useState({});
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [errorMessagesSell, setErrorMessagesSell] = useState([]);
    const [isError, setIsError] = useState(false);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    //buy Sell Table arrays
    const [allEntry, setAllEntry] = useState([]);
    const [data, setdata] = useState([]);
    //buy Sell Table Reference
    const tableRef = useRef();
    const tableRefSell = useRef();

    const [subAccountId, setSubAccountId] = useState(null);
    const [sellinvestmentType, setSellInvestmentType] = useState([]);//for test
    const [quantity, setQuantity] = useState(0)
    const [headerId, setHeaderId] = useState(0);
    const [availableQuantity,setAvailableQuantity] = useState([])

    const [rowLength, setRowLength] = useState(0);
    const [sellQtyData, setSellOtyData] = useState(0);

    const [columnsS, setColumnsS] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [descriptionInfo, setDescriptionInfo] = useState('');
    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }
    // shortcut key
    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
       });

    useEffect(() => {
        if (showB === true) {
            setSubAccountId(entityId == null ? subAccountId : entityId);
            getStockValuation();
            getInvestmentTypes();
        }
    }, [showB]);


    useEffect(() => {
        
        (async () => {
            await setSubAccountId(entityId == null ? subAccountId : entityId);
            if (bullionHeaderID !== 0) {
                if (Object.keys(bullionTransaction).length !== 0) {
                    setHeaderId(bullionHeaderID);
                    bullionTransaction.bullionDetails && bullionTransaction.bullionDetails.map((t) => {
                        if (bullionTransaction.instrumentTypeId === 44) {
                            if (t.transactType === "Sell") {
                                setSelectionQty(JSON.parse(t.selection))
                            }
                        }
                    })
                    setDate(new Date(bullionTransaction.date));
                    setSubAccountId(bullionTransaction.subAccountId);
                    setNarration(bullionTransaction.narration);

                    let Buy = [];
                    let Sell = [];

                    bullionTransaction.bullionDetails && bullionTransaction.bullionDetails.map((a) => {
                        a.typeOfInvestment = { 'value': a.typeOfInvestmentId, 'label': a.typeOfInvestment.investmentName }
                        a.investmentUnitName = { 'value': a.investmentUnit, 'label': a.investmentUnit }
                    })
                    let newBullionDetails = bullionTransaction.bullionDetails && bullionTransaction.bullionDetails.map((t) => {
                        return {
                            ...t,
                            stockValuation: { 'value': t.stockValuationId, 'label': t.stockValuation.stockValuationName },
                            //    typeOfInvestment: {},
                            stockValuationId: t.stockValuation.stockValuationName,
                            investmentUnit: t.investmentUnitName.label,
                            typeOfInvestmentId: t.typeOfInvestment.label,
                            //    investmentUnitId:{"label":t.investmentUnit , "value":t.investmentUnit},

                        }
                    })
                    newBullionDetails && newBullionDetails.map((s) => {
                        if (s.transactType === 'Sell') {
                            Sell.push(s);
                        }
                        if (s.transactType === 'Buy') {
                            Buy.push(s)
                        }
                    })
                    if (bullionTransaction.instrumentTypeId === 44) {
                        setdata(Sell)
                    }
                    if (bullionTransaction.instrumentTypeId === 44) {
                        setAllEntry(Buy)
                    }
                }
                else {
                    clearForm();
                }
            }
            else {
                clearForm();
            }
        })();

    }, [bullionTransaction])

    const handleCloseModel = () => {
        onHide();
        handleCancel()
    }

    const clearForm = () => {
        setDate(new Date());
        setNarration('');
        setSubAccountId(null);
        setAllEntry([]);
        setdata([]);
        setSelectionQty([]);
        setSellOtyData(0);
        setQuantity(0);
        setAvailableQuantity([])
    }

    const handleCancel = () => {
        setErrorMessagesSell([]);
        setErrorMessages([]);
        setIserror(false);
        setIsError(false);
        setFormErr({});
        clearForm();
        onHide();
        setColumnsS([]);
    }
    const handleClose = () => {
        setShow(false);
        setScheme('');
        setSchemeErr('')
    }
    const handleScheme = (e) => {
        const { value } = e.target;
        setScheme(value);
        setSchemeErr('')
    }
    const submitBullionaddCompany = () => {
        if (scheme !== '') {
            setLoading(true);
        }
    }
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    //close selection modal
    const selectionHide = () => {

          selectionQty.map((d)=>{
            setAvailableQuantity(d.AvailableQuantity);
          })

        if (selectionQty.length !== 0) {
            if ((rowLength === 0 && sellQtyData === 0) || (rowLength !== 0 && sellQtyData !== 0)) {
                setSelection(false);
            }
            if (rowLength <= 0 && sellQtyData > 0) {
                setSelection(true);
                swal("Info!", "Please select row !", "info");
            }
            if (rowLength > 0 && sellQtyData <= 0) {
                    setSelection(true);
                    swal("Info!", "Please enter sell quantity !", "info");
            }
            if(availableQuantity === 0 && sellQtyData === availableQuantity){
                // setQuantity(0)
                setSelection(false);
                swal("Info!", "Quantity is not available!", "info");
            }
        } else {
            setSelection(false);
        }

    }
    const getSelection = (row, stockValId) => {

        let BullionID = row.rowData.bullionId
        let selection = row.rowData.selection;
        let Id = entityId == null ? subAccountId : entityId;
        if (stockValId === 3 && Id != null && (row.rowData.typeOfInvestmentId !== undefined || row.rowData.investmentName !== undefined)) {
            let investmentId = row.rowData.typeOfInvestmentId.value === undefined ? row.rowData.typeOfInvestment.value : row.rowData.typeOfInvestmentId.value;
            setSelection(true);
            setSellOtyData(0);
            setRowLength(0);
            setLoading(true);
           
                if(BullionID){
                 fetchUpdatedSelectionData(BullionID).then(res =>{
                    if (res.status === 200) {
                    if (res.data._response.status === 1) {
                    let AvlQuantity = res.data._response.data.selectionDataForBulionUpdate;
                    let selectionQtys = AvlQuantity && AvlQuantity.map((r, index) => {
                       return{
                            id: index,
                            bullionId: r.bullionId,
                            TotalCost:r.amountOfInvestment,
                            availableCost:r.availableCost,
                            AvailableQuantity:r.quantity,
                            closingBalance:r.closingBalance,
                            DatePurchase:r.dateOfInvestment,
                            Decription:r.investmentDescription,
                            investmentUnit:r.investmentUnit,
                            Price:r.rate,
                            sellQuantity:0.00,
                            sellBullionId: r.sellBullionId
                        }
                    })
                    setSelectionQty(selectionQtys);
                }else{
                    setSelectionQty([]);
                    setSelectedRows([]);
                }
              }
                }).catch(e => {
                    setError(e.message)
                    setLoading(false);
                })
            }
            if (selection === undefined) {
                fetchSelectionData(Id, investmentId).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 2) {
                            setSelectionQty([]);
                            setSelectedRows([]);
                        }
                        else {
                            let AvlQuantity = res.data._response.data.fetchSelectionCompany;
                            let selectionQtys = [];
                            AvlQuantity && AvlQuantity.map((r, index) => {
                               
                                if (selectionQty.length !== 0) {
                                    selectionQty && selectionQty.map((s, i) => {
                                        if (i === index) {
                                            let totalSellQuantity = s.sellQuantity <=  s.totalsellQuantity ? (s.totalsellQuantity + s.sellQuantity) : (s.totalsellQuantity + s.sellQuantity);
                                            // Number(s.sellQuantity) <= Number(s.totalsellQuantity) ? Number(s.totalsellQuantity + Number(s.sellQuantity)) : Number(s.sellQuantity);
                                            let SellQuantitys = r.availableQuantity - (s.sellQuantity + (s.totalsellQuantity ? s.totalsellQuantity : 0.00)) 
                                            // Number(r.availableQuantity - Number(s.totalsellQuantity ? Number(s.totalsellQuantity + Number(s.sellQuantity)) : Number(s.sellQuantity)) )
                                            selectionQtys.push({
                                                id: index,
                                                bullionId: r.bullionId,
                                                TotalCost:r.amountOfInvestment,
                                                availableCost:r.availableCost,
                                                AvailableQuantity:SellQuantitys,
                                                closingBalance:r.closingBalance,
                                                DatePurchase:r.dateOfInvestment,
                                                Decription:r.investmentDescription,
                                                investmentUnit:r.investmentUnit,
                                                Price:r.rate,
                                                totalsellQuantity:totalSellQuantity,
                                                sellQuantity:0.00
                                            })
                                            setQuantity(0)
                                            
                                        }
                                    })
                                }else{
                                    selectionQtys.push({
                                        id: index,
                                        bullionId: r.bullionId,
                                        TotalCost:r.amountOfInvestment,
                                        availableCost:r.availableCost,
                                        AvailableQuantity:r.availableQuantity,
                                        closingBalance:r.closingBalance,
                                        DatePurchase:r.dateOfInvestment,
                                        Decription:r.investmentDescription,
                                        investmentUnit:r.investmentUnit,
                                        Price:r.rate,
                                        sellQuantity:0.00,
                                        totalsellQuantity:0.00,
                                    })
                                }


                                // return {
                                //     bullionId: r.bullionId,
                                //     id: index, DatePurchase: r.dateOfInvestment, AvailableQuantity: r.availableQuantity, Price: r.rate, Decription: r.investmentDescription,
                                //     TotalCost: r.amountOfInvestment, investmentUnit: r.investmentUnit, sellQuantity: 0.00
                                // }
                            })
                            setSelectionQty(selectionQtys);
                            setSelectedRows([]);
                        }
                    } else {
                        setLoading(false);
                        setError(res.data.message)
                    }
                }).catch(e => {
                    setError(e.message)
                    setLoading(false);
                })
            } else {

                // let parseData = JSON.parse(row.rowData.selectionAll);
                let parseData = JSON.parse(row.rowData.selection);
                setSelectionQty(parseData);
                setLoading(false);
            }
        }
    }
    // addBulliontransaction
    const AddBullionTransaction = async () => {
        let isValid = errHandle();
        let resultDataBuy = allEntry;
        let resultDataSell = data;
        if (isValid === true) {
            setLoading(true);

            var deepCloneBuy = _.cloneDeep(resultDataBuy);
            var resultBuy = deepCloneBuy.map(function (o) {
                o.typeOfInvestmentId = o.typeOfInvestment.value;
                o.investmentUnit = o.investmentUnit;
                return o;

            })

            var deepCloneSell = _.cloneDeep(resultDataSell);
            var resultSell = deepCloneSell.map(function (o) {
                o.typeOfInvestmentId = o.investmentName.value;
                o.stockValuationId = o.stockValuation.value;
                return o;
            })

            let BullionData = {};
            BullionData = {
                instrumentId: 9,
                instrumentTypeId: 44,
                date: fixTimezoneOffset(date),
                subAccountId: subAccountId,
                bullionDetails: [...resultBuy, ...resultSell],
                narration: narration,
            }
            await AddBulionsDetails(BullionData).then(res => {
                setLoading(false);
                if (res.data._response.status === 1) {
                    swal(res.data._response.message, "", 'success');
                    clearForm();

                    // setSuccess(true);
                    // onTransactionGridRefresh();
                } else {
                    swal("Something Went Wrong", "", "info");
                }
            }).catch(e => {
                setError(e.message)
                setLoading(false);
            })
        }
    }

    //update bullion 
    const UpdateBullionTransaction = async () => {
        let isValid = errHandle()
        if (isValid === true) {
            setLoading(true);
            let resultDataBuy = allEntry;
            let resultDataSell = data;

            let deepCloneBuy = _.cloneDeep(resultDataBuy);
            var resultBuy = deepCloneBuy.map(function (o) {
                if (o.instrumentId !== undefined) {
                    o.instrumentId = o.instrumentId.value;
                }
                if (o.typeOfInvestment !== undefined) {
                    o.typeOfInvestmentId = o.typeOfInvestment.value;
                    o.stockValuationId = o.bullionHeaderId !== undefined ? o.stockValuation.value : 4;
                }
                return o;
            })

            var deepCloneSell = _.cloneDeep(resultDataSell);
            var resultSell = deepCloneSell.map(function (o) {
                if (o.instrumentId !== undefined) {
                    o.instrumentId = o.instrumentId.value;
                }
                if (o.typeOfInvestment !== undefined) {
                    o.typeOfInvestmentId = o.typeOfInvestment.value;
                    o.stockValuationId = o.stockValuation.value;
                }

                return o;
            })

            let BullionData = {};
            BullionData = {
                bullionHeaderId: bullionHeaderID,
                instrumentId: 9,
                instrumentTypeId: 44,
                date: fixTimezoneOffset(date),
                subAccountId: subAccountId,
                // Type: show2 == true ? null : typeStock,
                bullionDetails: [...resultBuy, ...resultSell],
                narration: narration
            }
            await UpdateBullionDetails(BullionData).then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        swal("Updated successfully!", "", "success");
                    } else {
                        swal("Something Went Wrong", "", "info");
                    }
                }
            }).catch(e => {
                setError(e.message);
                setLoading(false);
            })
        }
        else {
            alert('errors');
        }
    }

    //get stock valuation data
    const getStockValuation = async () => {
        await ValuationList().then(res => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let valuation = res.data._response.data.stockvaluationtDetails;
                    let vaulationData = [];
                    valuation && valuation.map(data => {
                        if (data.stockValuationId === 3 && data.stockValuationName === "Selection") {
                            vaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
                        }
                    })
                    setStockValuationType(vaulationData);
                }
            } else {
                setError(res.data.message)
            }
        }).catch(e => {
            setError(e.message)
        })
    }

    //get stock valuation data
    const getInvestmentTypes = async () => {
        await FetchBulionsInvestmentType().then(res => {
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    let investment = res.data._response.data.investmentDetails;
                    let investmentData = [];
                    investment && investment.map(data => {
                        investmentData.push({ value: data.typeOfInvestmentId, label: data.investmentName })
                    })
                    setInvestmentType(investmentData);
                }
            } else {
                setError(res.data.message)
            }
        }).catch(e => {
            setError(e.message)
        })
    }


    useEffect(() => {

        setLoading(true);
        if (showB) {
            setSubAccountId(entityId == null ? subAccountId : entityId);
            // getSellingCompany(entityId == null ? subAccountId : entityId);
            setLoading(false);
            if (entityId !== null) {
                getSellingInvestmentType(entityId == null ? subAccountId : entityId);

            }
        }

    }, [allEntry, showB, entityId])

    useEffect(() => {
        setLoading(true);
        if (showB) {
            // if (stockHeaderId != 0 || stockHeaderId == 0) {
            setSubAccountId(entityId == null ? subAccountId : entityId);
            // getSellingCompany(entityId == null ? subAccountId : entityId);
            getSellingInvestmentType(entityId == null ? subAccountId : entityId);
            // }
        }

    }, [showB])




    //instrument type wise sell companies
    const getSellingInvestmentType = async (entityId) => {
        if (entityId != null) {

            await fetchSellInvestmentType(entityId).then(res => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data._response.status === 1) {

                        let investment = res.data._response.data.fetchSelectionCompany;
                        let investmentData = [];
                        investment && investment.map(data => {
                            investmentData.push({ value: data.typeOfInvestmentId, label: data.investmentName })
                        })
                        let resultDataBuy = allEntry;
                        let newInvestment = [];
                        resultDataBuy && resultDataBuy.map((p) => {
                            // console.log(p, "p");
                            newInvestment.push(p.typeOfInvestment);
                        })
                        let result = _.unionBy(investmentData, newInvestment, 'label');
                        setSellInvestmentType(result);
                        setLoading(false);

                    }
                } else {
                    setLoading(false);
                    setError(res.data.message)
                    setSellInvestmentType([]);
                }
            }).catch(e => {
                setLoading(false);
                setError(e.message)
                setSellInvestmentType([]);
            })
        }
        else {
            setLoading(false);
        }
    }
    const errHandle = () => {
        let formErr = {};
        let isValid = true;
        let resultDataBuy = allEntry;
        let resultDataSell = data;

        if (!date) {
            isValid = false;
            formErr["date"] = "Please select Date!";
        }
        if (resultDataBuy.length <= 0 && resultDataSell.length <= 0) {
            isValid = false;
            Swal.fire('Please fill Buy/Sell Data!!', "", "warning")
        }
        setFormErr(formErr)
        return isValid

    }



    //function for adding a new row to the table
    const handleRowAdd = async (newData, resolve, reject, a) => {
        let companyData = newData.typeOfInvestmentId;
        let unitType = newData.investmentUnit;
        let errorList = []
        if (!newData.typeOfInvestmentId) {
            errorList.push("Please Select Investment Type Field")
        }
        if (!newData.investmentUnit) {
            errorList.push("Please Select Unit Field");
        }
        if (!newData.quantity) {
            errorList.push("Please Enter Quantity Field");
        }
        if (newData.rate < 0) {
            errorList.push("Please Enter Rate Field");
        }
        // if (!newData.amountOfInvestment) {
        //     errorList.push("Please Enter Investment Amount Field");
        // }
        if (!newData.amountOfInvestment && !newData.currentMarketPrice) {
            errorList.push("Please Enter Current Market Price Field");
        }
        if (newData.typeOfInvestmentId !== undefined ? newData.typeOfInvestmentId.label === "Jwellery" && !newData.investmentDescription : null) {
            errorList.push("Please Enter Investment Description");
        }
        if (errorList.length < 1) {

            newData.typeOfInvestmentId = newData.typeOfInvestmentId.label;
            newData.typeOfInvestment = companyData;

            newData.investmentUnit = newData.investmentUnit.label;
            newData.investmentUnitName = unitType;
            newData.currentMarketPrice = newData.currentMarketPrice === undefined ? newData.amountOfInvestment : newData.currentMarketPrice;

            let newUserdata = [...allEntry];
            newData.instrumentTypeId = 44;
            newUserdata.push(newData);
            var result = newUserdata.map(function (o) {
                if (o.bullionId === undefined) {
                    o.TransactType = "Buy";
                    o.stockValuationId = 4;
                }
                return o;
            })
            setAllEntry(result);
            resolve();
            setErrorMessages([]);
            setIserror(false);
        }
        else {
            setErrorMessages(errorList);
            setIserror(true)
            reject()
        }

    }
    //function for updating the existing row details
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        let cpy = newData.typeOfInvestment.label;
        let companyData = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.typeOfInvestment : newData.typeOfInvestmentId;

        let unt = newData.investmentUnitName.label;
        let unitData = unt === newData.investmentUnit && newData.investmentUnit.label === undefined ? newData.investmentUnitName : newData.investmentUnit;

        // let cpy = newData.companyData.label;
        // let companyData = cpy === newData.investmentId && newData.investmentId.label == undefined ? newData.companyData : newData.investmentId;

        let errorList = [];
        if (!newData.typeOfInvestmentId) {
            errorList.push("Please Select Investment Type Field")
        }
        if (!newData.investmentUnit) {
            errorList.push("Please Select Unit Field");
        }
        if (!newData.quantity) {
            errorList.push("Please Enter Quantity Field");
        }
        if (newData.rate < 0) {
            errorList.push("Please Enter Rate Field");
        }
        // if (!newData.amountOfInvestment) {
        //     errorList.push("Please Enter Investment Amount Field");
        // }
        if (!newData.amountOfInvestment && !newData.currentMarketPrice) {
            errorList.push("Please Enter Current Market Price Field");
        }
        if (errorList.length < 1) {
            const updateBullion = [...allEntry]
            const index = oldData.tableData.id;

            newData.typeOfInvestmentId = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.typeOfInvestmentId : newData.typeOfInvestmentId.label !== undefined ? newData.typeOfInvestmentId.label : newData.typeOfInvestmentId.label;
            newData.typeOfInvestment = companyData;


            newData.investmentUnit = unt === newData.investmentUnit && newData.investmentUnit.label === undefined ? newData.investmentUnit : newData.investmentUnit.label !== undefined ? newData.investmentUnit.label : newData.investmentUnit.label;
            newData.investmentUnitName = unitData;
            newData.currentMarketPrice = isNaN(newData.currentMarketPrice) || newData.currentMarketPrice === 0 ? newData.amountOfInvestment : newData.currentMarketPrice;

            newData.instrumentTypeId = 44;
            updateBullion[index] = newData;
            setAllEntry([...updateBullion]);
            resolve();
            setErrorMessages([]);
            setIserror(false);
        }
        else {
            setErrorMessages(errorList);
            setIserror(true)
            reject()
        }
    }
    //function for deleting a row
    const handleRowDelete = async (oldData, resolve, reject) => {
        const dataDelete = allEntry;
        if (oldData.bullionId === undefined) {
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            await setAllEntry([...dataDelete])
            resolve()
        }
        else {
            // deleteBullion
            DeleteInnerGridBullionTransaction(oldData.bullionId).then(async (res) => {
                if (res.data._response.status === 1) {
                    swal(res.data._response.message, "", 'success');
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    await setAllEntry([...dataDelete]);
                    resolve()
                }
                else {
                    swal(res.data._response.message, "", "info");
                    reject()
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    //function for adding a new sell row to the table
    const handleRowAddSell = async (newData, resolve, reject) => {

        let companyData = newData.typeOfInvestmentId;
        let stockValuation = newData.stockValuationId;

        let errorsList = []
        if (!newData.typeOfInvestmentId) {
            errorsList.push("Please Select Investment Type Field")
        }
        if (!newData.quantity) {
            errorsList.push("Please Enter Quantity Field");
        }
        // if (!newData.rate) {
        //     errorsList.push("Please Enter Rate Field");
        // }
        if (!newData.saleValue || isNaN(newData.saleValue)) {
            errorsList.push("Please Enter sale value");
        }
        if (errorsList.length < 1) {
            let newUserdata = [...data];
            newData.selection = JSON.stringify(selectionQty);
            newData.typeOfInvestmentId = newData.typeOfInvestmentId.label;
            newData.investmentName = companyData;
            newData.typeOfInvestment = companyData;

            newData.stockValuationId = newData.stockValuationId.label;
            newData.stockValuation = stockValuation;
            newData.investmentDescription = descriptionInfo;

            newData.instrumentTypeId = 44;
            newData.TransactType = 'Sell';

            newUserdata.push(newData);

            var newResult = [...newUserdata];
            setdata(newResult)
            resolve();
            setErrorMessagesSell([]);
            setIsError(false);
        }
        else {
            setErrorMessagesSell(errorsList);
            setIsError(true)
            reject()
        }
    }
    //function for  updating the existing sell row details
    const handleRowUpdateSell = (newData, oldData, resolve, reject) => {
        let cpy = newData.typeOfInvestment.label;
        let companyData = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.typeOfInvestment : newData.typeOfInvestmentId;

        let mutualFE = newData.stockValuation.label;
        let stockValuation = mutualFE === newData.stockValuationId ? newData.stockValuation : newData.stockValuationId;

        let sumSellmFund = 0;
        for (let i = 0; i < selectionQty.length; i++) {
            sumSellmFund += selectionQty[i].sellQuantity;
        }

        let errorsList = []
        if (!newData.typeOfInvestmentId) {
            errorsList.push("Please Select Investment Type Field")
        }
        if (!newData.quantity) {
            errorsList.push("Please Enter Quantity Field");
        }
        // if (!newData.rate) {
        //     errorsList.push("Please Enter Rate Field");
        // }
        if (!newData.saleValue || isNaN(newData.saleValue)) {
            errorsList.push("Please Enter sale value");
        }
        if (errorsList.length < 1) {
            const updateStock = [...data];
            const index = oldData.tableData.id;

            newData.typeOfInvestmentId = cpy === newData.typeOfInvestmentId && newData.typeOfInvestmentId.label === undefined ? newData.typeOfInvestmentId : newData.typeOfInvestmentId.label !== undefined ? newData.typeOfInvestmentId.label : newData.typeOfInvestmentId.label;
            newData.typeOfInvestment = companyData;

            newData.stockValuationId = mutualFE === newData.stockValuationId ? newData.stockValuationId : newData.stockValuationId.label;
            newData.stockValuation = stockValuation;
            newData.investmentDescription = descriptionInfo;
            newData.selection = JSON.stringify(selectionQty);
            updateStock[index] = newData;
            setdata([...updateStock]);
            resolve()
            // let buy = show1 == true ? allEntry1 : show2 == true ? allEntry2 : show3 == true ? allEntry3 : allEntry4;
            // handleTotalAmt(buy, [...updateStock]);
            setErrorMessagesSell([])
            setIserror(false)
        } else {
            setErrorMessagesSell(errorsList)
            setIsError(true)
            reject();
        }
    }
    //function for deleting a sell row
    const handleRowDeleteSell = async (oldData, resolve, reject) => {
        const dataDelete = data;
        if (oldData.bullionId === undefined) {
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            await setdata([...dataDelete])
            resolve()
        }
        else {
            // deleteBullion
            DeleteInnerGridBullionTransaction(oldData.bullionId).then(async (res) => {
                if (res.data._response.status === 1) {
                    swal(res.data._response.message, "", 'success');
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    await setAllEntry([...dataDelete]);
                    resolve()
                }
                else {
                    swal(res.data._response.message, "", "info");
                    reject()
                }
            })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    //delete bullion transaction
    const DeleteBullionTransactions = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    DeleteBullionTransaction(bullionHeaderID).then((response) => {
                        if (response.status === 200) {

                            swal(response.data._response.message, {
                                title: "Deleted",
                                icon: "success",
                            });
                            handleCancel();
                            //   onBullionTransactionGridRefresh();
                        } else {
                            swal({
                                title: "Ooops!!",
                                text: "Somthing Wrong",
                                icon: "warning",
                            })
                        }

                    })
                        .catch((error) => {
                            console.log(error, "error in delete transaction")
                        })

                } else {
                    swal("Cancelled", "Your  data is safe ", "error");
                }

            });
    }

    const handleChangeInvestType = (selected, props) => {
        props.rowData.investmentUnit = undefined;
        props.rowData.count = 1;
        props.onChange(selected)

        let unitOpt = selected.value === 1 || selected.value === 2 || selected.value === 3 ? bullionUnit
            : selected.value === 4 ? bullionUnit2 : bullionUnit3
        setUnitOptions(unitOpt)

    }

    const handleChangeUnitType = (selected, props) => {
        props.onChange(selected)
    }

    const columnsB = [
        { title: 'Type of Investment', field: 'typeOfInvestmentId', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.rowData.tableData.editing === 'update') {
                            if (props.value.label !== undefined) {
                                if (props.value.label !== props.rowData.typeOfInvestment.label) {
                                    ddValue = props.value
                                }
                                else {
                                    ddValue = props.rowData.typeOfInvestment
                                }
                            }
                            else {
                                ddValue = props.rowData.typeOfInvestment
                            }
                        }
                    }
                }
                return (
                    <Select
                        value={ddValue || null}
                        onChange={(selected) => handleChangeInvestType(selected, props)}
                        options={investmentType}
                        name="typeOfInvestmentId"
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                        isDisabled={props.rowData.bullionId !== undefined ? true : false}
                    />
                )
            },
        },
        {
            title: "Unit", field: 'investmentUnit', width: 250,
            editComponent: (props) => {
                let ddValue = props.value;
                let investmentValueAdd = props.rowData.typeOfInvestment ? props.rowData.typeOfInvestment.value : 0;
                let investmentValueUpdate = props.rowData.typeOfInvestmentId ? props.rowData.typeOfInvestmentId.value : 0;
                if (props.rowData.tableData !== undefined) {
                    if (props.rowData.tableData.editing !== undefined) {
                        if (props.value !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.investmentUnitName.label) {
                                        ddValue = props.value
                                    }
                                    else {
                                        ddValue = props.rowData.investmentUnitName
                                    }
                                }
                                else {
                                    ddValue = props.rowData.investmentUnitName
                                }
                            }
                        }
                    }
                }
                return (
                    <Select
                        value={ddValue || null}
                        onChange={(selected) => handleChangeUnitType(selected, props)}
                        // options={unitOptions}
                        options={ investmentValueAdd === 4 || investmentValueUpdate === 4 ? bullionUnit2 : investmentValueAdd === 5 || investmentValueUpdate === 5 ? bullionUnit3 : bullionUnit }
                        name="investmentUnit"
                        onKeyDown={e => handleKey(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll="true"
                    />
                )
            }
        },
        {
            title: 'Investment Desc.', field: 'investmentDescription', width: "20%", type: 'text',
            editComponent: ({ ...props }) => {

                // handleAddRowBuy();
                return <MTableEditField {...props} />;
            },
        },
        {
            field: 'quantity', title: 'Quantity', width: 130, type: 'numeric',
            render: (rowData) => (
                <span>
                    {NumberOnlySeperator(rowData.quantity)}
                </span>
            ),
            editComponent: ({ ...props }) => {
                // handleAddRowBuy();
                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Quantity'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))}
                    />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
        },

        {
            title: 'Amount Of Investment', field: 'amountOfInvestment', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let amt = !isNaN(props.rowData.amountOfInvestment) ? props.rowData.amountOfInvestment : 0;
                props.rowData.amountOfInvestment = amt;
                props.value = amt;

                return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Amount Of Investment'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                //  <MTableEditField  {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.amountOfInvestment ? rowData.amountOfInvestment : 0)}
                </span>
            ),
        },
        {
            title: 'Rate', field: 'rate', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                let rate = 0;

                rate = ((props.rowData.amountOfInvestment ? Number(props.rowData.amountOfInvestment) : 0) / Number(props.rowData.quantity)).toFixed(2);
                props.value = rate;
                props.rowData.rate = toNumber(rate);
                return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Rate'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        disabled
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField  {...props} disabled={true} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.rate)}
                </span>
            ),
        },
        {
            title: 'Current Market Price', field: 'currentMarketPrice', width: 130, type: 'numeric',
            editComponent: ({ ...props }) => {
                // let currentMarketPrice = 0;
                // currentMarketPrice = props.rowData.amountOfInvestment;
                // // props.value = currentMarketPrice
                // props.rowData.currentMarketPrice = currentMarketPrice || props.rowData.currentMarketPrice;
                // handleAddRowBuy();
                return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                    <Cleave
                        className="MuiInputBase-input MuiInput-input"
                        placeholder='Current Market Price'
                        autoComplete='off'
                        options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                        value={props.value}
                        onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                </div>
                </div>
                // <MTableEditField {...props} />;
            },
            render: (rowData) => (
                <span>
                    {NumberSeperator(rowData.currentMarketPrice)}
                </span>
            ),
        },

    ]

    // columns for sell
    useEffect(() => {
        setColumnsS([
            {
                title: 'Type of Investment', field: 'typeOfInvestmentId', width: 250,
                editComponent: (props) => {
                    let ddValue = props.value;
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.typeOfInvestment.label) {
                                        ddValue = props.value
                                    }
                                    else {
                                        ddValue = props.rowData.typeOfInvestment
                                    }
                                }
                                else {
                                    ddValue = props.rowData.typeOfInvestment
                                }
                            }
                        }
                    }
                    return (
                        <Select
                            value={ddValue || null}
                            onChange={(selected) => props.onChange(selected)}
                            options={sellinvestmentType}
                            name="typeOfInvestmentId"
                            onKeyDown={e => handleKey(e)}
                            menuPosition="fixed"
                            menuShouldBlockScroll="true"
                            isDisabled={props.rowData.bullionId !== undefined ? true : false}
                        />
                    )
                },
            },
            {
                title: 'Stock Valuation Type', field: 'stockValuationId',
                editComponent: ({ ...props }) => {
                    let ddValue = props.value;
                    if (props.rowData.tableData !== undefined) {
                        if (props.rowData.tableData.editing !== undefined) {
                            if (props.rowData.tableData.editing === 'update') {
                                if (props.value.label !== undefined) {
                                    if (props.value.label !== props.rowData.stockValuation.label) {
                                        ddValue = props.value;
                                        console.log("Inner grid", ddValue);
                                    }
                                    else {
                                        ddValue = props.rowData.stockValuation;
                                        console.log("else grid", ddValue);
                                    }
                                }
                                else {
                                    ddValue = props.rowData.stockValuation;
                                    console.log("outer grid", props.rowData.selection);
                                    if (ddValue.value === 3) {
                                        //setSelectionQty(JSON.parse(props.rowData.selection));
                                        //getSelection(props, ddValue.value);
                                    }
                                }
                            }
                        }
                    }
                    return (
                        <Select
                            value={ddValue || null}
                            //  onFocus={() => SelectionSelect(props, ddValue)}
                            onChange={(selected) => {
                                props.onChange(selected)
                                if (selected.label === "Selection") {

                                    getSelection(props, selected.value);
                                }
                            }}
                            // isOptionDisabled={handleDisableOption}
                            options={stockValuationType}
                            name="stockValuationId"
                            onKeyDown={e => handleKey(e)}
                            menuPosition="fixed"
                            menuShouldBlockScroll="true"
                        />
                    )
                }
            },

            {
                field: 'quantity', title: 'Quantity', width: "20%", type: 'numeric',
                render: (rowData) => (
                    <span>
                        {NumberOnlySeperator(rowData.quantity)}
                    </span>
                ),
                editComponent: ({ ...props }) => {

                    props.rowData.quantity = quantity;
                    return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder='Quantity'
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled
                            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                    </div>
                    </div>
                    // <MTableEditField {...props} disabled={true} />;
                },
            },
            {
                title: 'Rate', field: 'rate', width: "20%", type: 'numeric',
                editComponent: ({ ...props }) => {
                    let rate = 0;
                    rate = (props.rowData.saleValue / props.rowData.quantity).toFixed(2);
                    props.value = rate;
                    props.rowData.rate = toNumber(rate);
                    return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder='Rate'
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            disabled
                            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                    </div>
                    </div>
                    // <MTableEditField  {...props} disabled={true} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.rate)}
                    </span>
                ),
            },
            {
                title: 'Sale Value', field: 'saleValue', type: 'numeric',
                cellStyle: {
                    minWidth: 200,
                    maxWidth: 200
                },
                editComponent: ({ ...props }) => {
                    return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                        <Cleave
                            className="MuiInputBase-input MuiInput-input"
                            placeholder='Sale Value'
                            autoComplete='off'
                            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                            value={props.value}
                            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
                    </div>
                    </div>
                    // <MTableEditField  {...props} />;
                },
                render: (rowData) => (
                    <span>
                        {NumberSeperator(rowData.saleValue)}
                    </span>
                ),
            },
        ])
    }, [quantity, sellinvestmentType, stockValuationType, subAccountId, showB])


    const handleKey = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation()
        }
    }
    const handleRadioSelect = (rowData, r) => {
        setRowLength(1);
        setDescriptionInfo(rowData.Decription)
    }
    return (
        <div>
            <div tabIndex={2} className="tblHeaderLeft">
                <Modal className="tblHeaderLeft col-12 stockdetailmodal" isOpen={showB}
                    size="xl"
                    id="modal2"
                    toggle={handleCloseModel}
                    aria-labelledby="example-modal-sizes-title-lg"
                >

                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Bullion Transaction</h5>
                    </ModalHeader>

                    <ModalBody>
                        <Container fluid>
                            <form>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                                ) : null}
                            </form>

                            <fieldset>
                                <Row>
                                    <Col md="3" className="mb-2">
                                        <>
                                            <Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                                            <DatePickerB
                                                className="form-control"
                                                selected={date}
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => setDate(date)}
                                                maxDate={new Date()}
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                            // disabled={bullionHeaderId != 0 ? true : false}
                                            />
                                        </>
                                    </Col>

                                </Row>
                            </fieldset>

                            <fieldset>
                                <Row>
                                    <Col md="12" className="BuyTbl">
                                        <MaterialTable
                                            title="Buy"
                                            data={allEntry}
                                            columns={columnsB}
                                            tableRef={tableRef}
                                            options={{
                                                actionsColumnIndex: -1,
                                                addRowPosition: "first",
                                                search: false,
                                                paging: false,
                                                tableLayout: 'auto'
                                            }}

                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowUpdate(newData, oldData, resolve, reject);
                                                    }),
                                                onRowAdd: (newData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowAdd(newData, resolve, reject)
                                                    }),
                                                onRowDelete: (oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowDelete(oldData, resolve, reject)
                                                    }),
                                                onRowAddCancelled: rowData => {
                                                    setIserror(false)
                                                    setErrorMessages([])
                                                },
                                                onRowUpdateCancelled: rowData => {
                                                    setIserror(false)
                                                    setErrorMessages([])
                                                }
                                            }}
                                        />
                                    </Col>
                                    {/* <div> */}
                                    {iserror &&
                                        <Alert severity="error">
                                            <AlertTitle>ERROR</AlertTitle>
                                            {errorMessages.map((msg, i) => {
                                                return <div key={i}>{msg}</div>
                                            })}
                                        </Alert>
                                    }
                                    {/* </div> */}
                                </Row>

                                <Row>
                                    <Col md="12" className='BuyTbl mt-3'>
                                        <MaterialTable
                                            title="Sell"
                                            data={data}
                                            columns={columnsS}
                                            tableRef={tableRefSell}
                                            editable={{
                                                // isEditable: rowData => false,
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowUpdateSell(newData, oldData, resolve, reject);
                                                    }),
                                                onRowAdd: (newData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowAddSell(newData, resolve, reject)
                                                    }),
                                                onRowDelete: (oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        handleRowDeleteSell(oldData, resolve, reject)
                                                    }),
                                                onRowAddCancelled: rowData => {
                                                    setErrorMessagesSell([])
                                                    setIsError(false);
                                                },
                                                onRowUpdateCancelled: rowData => {
                                                    setErrorMessagesSell([])
                                                    setIsError(false);
                                                }
                                            }}
                                            options={{
                                                actionsColumnIndex: -1,
                                                addRowPosition: "first",
                                                search: false,
                                                paging: false
                                            }}
                                        />
                                    </Col>
                                    <div>
                                        {isError &&
                                            <Alert severity="error">
                                                <AlertTitle>ERROR</AlertTitle>
                                                {errorMessagesSell.map((msg, i) => {
                                                    return <div key={i}>{msg}</div>
                                                })}
                                            </Alert>
                                        }
                                    </div>
                                </Row>
                            </fieldset>


                            <fieldset>
                                <Col md="3">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control as="textarea" autoComplete="off"
                                        name="narration" id="narration" value={narration} onChange={(e) => setNarration(e.target.value)} />
                                </Col>
                            </fieldset>

                            <Col md="12" className="text-center mt-3">
                                {bullionHeaderID !== 0 ? <>
                                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { UpdateBullionTransaction() }}>Update</Button>
                                    <Button variant="danger" className="mx-2 w100" onClick={() => { DeleteBullionTransactions() }}>Delete</Button>
                                </> :
                                    <>
                                        <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { AddBullionTransaction() }}>Save</Button>
                                        <Button variant="secondary" className="mx-2 w100" onClick={() => { handleCancel() }}>Cancel</Button>
                                    </>}
                            </Col>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
            {/* modal for add new scheme */}
            <Container tabIndex={4}>
                <Modal isOpen={show} toggle={handleClose} animation={false} size="sm" centered>
                    <ModalHeader charCode="Y" toggle={() => handleClose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Bullion Investment Type</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Scheme Name</label>
                                <input className="form-control mt-2" placeholder="Enter text" type="text" autoComplete="off" name="schemeName" onChange={handleScheme} />
                                {schemeErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{schemeErr}</div> : null}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitBullionaddCompany} >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>

            {/* selection modal */}
            <div tabIndex={4}>
                <Modal ClassName="custom-dialog selection"
                    isOpen={selection}
                    id="modal1"
                    size="lg"
                    backdrop="static"
                    //  backdrop={false}
                    toggle={selectionHide}
                    aria-labelledby="example-modal-sizes-title-lg"
                >

                    <ModalHeader charCode="Y" toggle={() => selectionHide()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Select Available Bullion Quantity</h5>
                    </ModalHeader>
                    <ModalBody style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                    }}>
                        <div style={{ height: 200, width: '100%' }}>
                            <MaterialTable
                                title={false}
                                //  title="Cell Editable Preview"
                                columns={[
                                    { title: "bullionId", field: "bullionId", hidden: true },
                                    { title: 'Select', editable: 'never', field: 'select', render: rowData => <input type="radio" name='one' value={rowData.id} onChange={() => handleRadioSelect(rowData)} /> },
                                    {
                                        title: 'Date', field: 'DatePurchase', width: 200, maxWidth: 200, editable: 'never',
                                        render: (rowData) =>
                                        (
                                            moment(rowData.DatePurchase).format('DD/MM/YYYY')
                                        )
                                    },
                                    { title: 'Investment Desc.', field: 'Decription', editable: 'never' },
                                    { title: 'Unit', field: 'InvestmentUnit', editable: 'never', render: (rowData) => (
                                        (rowData.investmentUnit ? rowData.investmentUnit : rowData.InvestmentUnit)
                                    ) },
                                    { title: 'Quantity', field: 'AvailableQuantity', type: 'numeric', editable: 'never' },
                                    { title: 'Amount', field: 'TotalCost', type: 'numeric', width: 200, maxWidth: 200, editable: 'never' },
                                    { title: 'Rate', field: 'Price', width: 200, type: 'numeric', maxWidth: 200, editable: 'never' },
                                    { title: 'Sell Quantity', field: 'sellQuantity', width: 200, type: 'numeric', maxWidth: 200 },
                                ]}

                                data={selectionQty}
                                options={{
                                    actionsColumnIndex: -1,
                                    addRowPosition: "first",
                                    search: false,
                                    paging: false,
                                    selection: false,

                                }}

                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {


                                        return new Promise((resolve, reject) => {
                                            let errorsList = [];
                                            let updateSelection = [...selectionQty];
                                            const index = rowData.tableData.id;
                                            if (rowData.AvailableQuantity < Number(newValue)) {
                                                errorsList.push("Sell quantity exeeds then the available quantity !");
                                            }
                                            if (errorsList.length < 1) {
                                                updateSelection[index].sellQuantity = Number(newValue);
                                                setSelectionQty([...updateSelection])
                                                setQuantity(rowData.sellQuantity)
                                                // setUnit(rowData.investmentUnit)
                                                setErrorMessagesQntity([])
                                                setSellOtyData(newValue)
                                                setTimeout(resolve, 1000);
                                            }
                                            else {
                                                setErrorMessagesQntity(errorsList);
                                                setTimeout(reject, 1000);
                                            }
                                        });
                                    }
                                }}
                            />
                            <div>
                                {errorMessagesQntity.length > 0 &&
                                    <Alert severity="error">
                                        <AlertTitle>ERROR</AlertTitle>
                                        {errorMessagesQntity.map((msg, i) => {
                                            return <div key={i}>{msg}</div>
                                        })}
                                    </Alert>
                                }
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        </div>
    )
}

export default BullionDetils;
