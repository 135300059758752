/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { Button, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import ErrorComponent from '../../../components/CommonComponent/ErrorComponet'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import Loader from '../../loadingAnimation/index'
import Swal from "sweetalert2";

function AddChannelPartner(props) {
  const { channelPartner, channelParnterDetails } = props;

  const [data, setData] = useState({
    username: '',
    address: '',
    contactPerson: '',
    email: '',
    phone: '',
    pan: '',
    tan: '',
    gst: '',
    profession: '',
    bankName: '',
    branch: '',
    AccountNumber: '',
    IFSC: '',
    Cpassword: '',
    password: ''
  });
  const [usernewId, setUsernewId] = useState([]);
  const [passVisible, setPassVisible] = useState(false);
  const [confPassVisible, setConfPassVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState({})
  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
    if (value !== null) {
      clearFormErr(name)
    }
  }

  const clearFormErr = (field) => {
    switch (field) {
      case "username": setFormErr({ ...formErr, username: '' })
        break;
      case "address": setFormErr({ ...formErr, address: '' })
        break;
      case "contactPerson": setFormErr({ ...formErr, contactPerson: '' })
        break;
      case "email": setFormErr({ ...formErr, email: '' })
        break;
      case "Cpassword": setFormErr({ ...formErr, Cpassword: '' })
        break;
      case "password": setFormErr({ ...formErr, password: '' })
        break;
      case "phone": setFormErr({ ...formErr, phone: '' })
        break;
      case "pan": setFormErr({ ...formErr, pan: '' })
        break;
      case "tan": setFormErr({ ...formErr, tan: '' })
        break;
      case "gst": setFormErr({ ...formErr, gst: '' })
        break;
      case "profession": setFormErr({ ...formErr, profession: '' })
        break;
      case "bankName": setFormErr({ ...formErr, bankName: '' })
        break;
      case "branch": setFormErr({ ...formErr, branch: '' })
        break;
      case "AccountNumber": setFormErr({ ...formErr, AccountNumber: '' })
        break;
      case "IFSC": setFormErr({ ...formErr, IFSC: '' })
        break;
      default: setFormErr({})
        break;
    }
  }
  const validation = () => {
    const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    const panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;


    let newUserErr = {};
    let isValid = true;
    if (data.username === "" || data.username === null) {
      newUserErr["username"] = "Please enter name!";
      isValid = false;
    }
    // else if (spaceAndLetter.test(data.username) === false) {
    //   newUserErr["username"] = "Please enter alphabetic characters and no spaces";
    //   isValid = false;
    // }
    if (data.email === "" || data.email === null) {
      newUserErr["email"] = "Please enter email!";
      isValid = false;
    } else if (emailregex.test(data.email) === false) {
      newUserErr["email"] = "please enter valid email"
      isValid = false;
    }
    if (data.phone === "" || data.phone === null) {
      newUserErr["phone"] = "Please enter phone!";
      isValid = false;
    } else if (phoneregex.test(data.phone) === false) {
      newUserErr["phone"] = "Please enter valid phone!";
      isValid = false;
    }

    if (data.password && data.Cpassword) {
      if (data.password !== data.Cpassword) {
        newUserErr["Cpassword"] = "Password is not matching!";
        isValid = false;
      }
    }
    if (data.password === "" || data.password === null) {
      newUserErr["password"] = "Please enter password!";
      isValid = false;
    } else if (passregex.test(data.password) === false) {
      newUserErr["password"] = "Password must combination of Number,Capital letter, special character and min length is 8 !";
      isValid = false;
    }
    if (data.Cpassword === "" || data.Cpassword === null) {
      newUserErr["Cpassword"] = "Please enter password again!";
      isValid = false;
    }
    if (data.address === "" || data.address === null) {
      newUserErr["address"] = "Please enter address!";
      isValid = false;
    }
    if (data.contactPerson === "" || data.contactPerson === null) {
      newUserErr["contactPerson"] = "Please enter contactPerson!";
      isValid = false;
    }

    if (data.pan === "" || data.pan === null) {
      newUserErr["pan"] = "Please enter PAN no!";
      isValid = false;
    }
    else if (panregex.test(data.pan.toUpperCase()) === false) {
      newUserErr['pan'] = 'Invalid Pan Number!';
      isValid = false;

    }
    if (data.tan === "" || data.tan === null) {
      newUserErr["tan"] = "Please enter TAN no!";
      isValid = false;
    }
    if (data.gst === "" || data.gst === null) {
      newUserErr["gst"] = "Please enter GST!";
      isValid = false;
    }
    if (data.profession === "" || data.profession === null) {
      newUserErr["profession"] = "Please enter profession!";
      isValid = false;
    }
    if (data.bankName === "" || data.bankName === null) {
      newUserErr["bankName"] = "Please enter bankName!";
      isValid = false;
    }
    if (data.branch === "" || data.branch === null) {
      newUserErr["branch"] = "Please enter branch name!";
      isValid = false;
    }
    if (data.AccountNumber === "" || data.AccountNumber === null) {
      newUserErr["AccountNumber"] = "Please enter AccountNumber!";
      isValid = false;
    }
    if (data.IFSC === "" || data.IFSC === null) {
      newUserErr["IFSC"] = "Please enter IFSC code!";
      isValid = false;
    }
    setFormErr(newUserErr);

    return isValid;
  }

  const handleSubmit = () => {
    // e.preventDefault();
    const isValid = validation();
    if (isValid) {
      let payLoad = {
        subcriberName: data.username,
        contactPerson: data.contactPerson,
        subcriberEmail: data.email,
        subcriberPassword: data.password,
        confirmPassword: data.Cpassword,
        subcriberPh1: data.phone,
        acceptTerms: true,
        roleId: 3,
        roleName: "rolename",
        nameOfBank: data.bankName,
        branch: data.branch,
        accountNumber: data.AccountNumber,
        ifscCode: data.IFSC,
        pan: data.pan,
        tan: data.tan,
        gst: data.gst,
        profession: data.profession,
        address: data.address
      }
      let headers = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      setLoading(true);
      axios.post(`http://5.189.157.40:10025/api/Subcriber/register`, payLoad, headers)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              Swal.fire(res.data._response.message, "Please check your email for verifying email before login", "success")
              // navigate("/")
              handleReset()
              channelParnterDetails(1, 10)
            } else {
              Swal.fire(res.data._response.message, "", "info")
            }
          } else {
            setLoading(false);
            Swal.fire(res.data._response.message, "", "info")
          }
        })
        .catch((err) => {
          setLoading(false)
          if(err.response?.data){
          if (err.response.data._response.message === "User Already Registered") {
            Swal.fire(err.response.data._response.message, "", "info");
          } else {
            Swal.fire("Invalid registration details", "", "info");
          }
         }

        })
    }
  }

  const handleReset = () => {
    setData({
      username: '',
      address: '',
      contactPerson: '',
      email: '',
      phone: '',
      pan: '',
      tan: '',
      gst: '',
      profession: '',
      bankName: '',
      AccountNumber: '',
      IFSC: '',
      branch: '',
      password: '',
      Cpassword: '',
    });
    setFormErr({});
    setConfPassVisible(false)
    setPassVisible(false)
  }
  //get channel partner data
  useEffect(() => {
    if (channelPartner !== undefined) {
      setUsernewId(channelPartner.userId)
      setData({
        username: channelPartner.channelPartnerName,
        address: channelPartner.address,
        contactPerson: channelPartner.contactPerson,
        email: channelPartner.channelPartnerEmail,
        phone: channelPartner.channelPartnerPh,
        pan: channelPartner.pan,
        tan: channelPartner.tan,
        gst: channelPartner.gst,
        profession: channelPartner.profession,
        bankName: channelPartner.nameOfBank,
        branch: channelPartner.branch,
        AccountNumber: channelPartner.accountNumber,
        IFSC: channelPartner.ifscCode,
        Cpassword: channelPartner.channelPartnerPassword,
        password: channelPartner.channelPartnerPassword
      })
    }
  }, [channelPartner])

  // const handleUpdate = (e) => {
  //   const payloadUpdate = {
  //     userId: usernewId,
  //     subcriberName: data.username,
  //     contactPerson: data.contactPerson,
  //     subcriberEmail: data.email,
  //     channelPartnerPh: data.phone,
  //     acceptTerms: true,
  //     UserTypeId: 3,
  //     roleName: "rolename",
  //     nameOfBank: data.bankName,
  //     branch: data.branch,
  //     accountNumber: data.AccountNumber,
  //     ifscCode: data.IFSC,
  //     pan: data.pan,
  //     tan: data.tan,
  //     gst: data.gst,
  //     profession: data.profession,
  //     address: data.address
  //   }
  //   setLoading(true);
  //   UpdateChannelPartner(payloadUpdate)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         if (response.data._response.status === 1) {
  //           Swal.fire("Success!", response.data._response.message, 'success')
  //           setLoading(false);
  //           handleReset()
  //           channelParnterDetails(1, 10)
  //         }
  //       } else {
  //         setLoading(false);
  //       }

  //     }).catch((err) => {
  //       setLoading(false);
  //     })

  // }


  return (

    <div style={{ width: "80%", marginLeft: "10rem" }}>
      <div className="mt-2 p-6">
      <Row>
        <h4 className='text-center mb-4'> Add Channel Partner</h4>
      </Row>
        <Form className="border border-2 rounded p-4 mt-4">
          {loading ? <Loader /> : null}
          <Row className="form-set">
            <Form.Group className="col-md-3" >
              <Form.Label> Name  </Form.Label>
              <Form.Control
                type="text"
                name="username"
                autoComplete='off'
                onChange={handleChange}
                value={data.username}
                placeholder="Enter Name" />
              {formErr ? <ErrorComponent formErr={formErr.username} /> : null}
            </Form.Group>

            <Form.Group className="col-md-3" >
              <Form.Label> Contact Person  </Form.Label>
              <Form.Control
                type="text"
                name="contactPerson"
                min="0"
                autoComplete='off'
                onChange={handleChange}
                value={data.contactPerson}
                placeholder="Enter Contact Person" />
              {formErr ? <ErrorComponent formErr={formErr.contactPerson} /> : null}
            </Form.Group>

            <Form.Group className="col-md-3" >
              <Form.Label> Mobile Number</Form.Label>
              <Form.Control
                type="Number"
                name="phone"
                min="0"
                onChange={handleChange}
                value={data.phone}
                placeholder="Enter Mobile Number"
                autoComplete='off'
              />
              {formErr ? <ErrorComponent formErr={formErr.phone} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> Email  </Form.Label>
              <Form.Control
                type="email"
                name="email"
                min="0"
                onChange={handleChange}
                value={data.email}
                placeholder="Enter Email"
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.email} /> : null}
            </Form.Group>
          </Row>

          <Row className="form-set mb-0">
            {/* {update === false ? */}
              <Form.Group className="col-md-3" >
                <Form.Label> Password  </Form.Label>
                <Form.Control
                  type={passVisible ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  placeholder="Enter password"
                  autoComplete="off"
                  // disabled = {update === false ? false :true}
                />

                <i onClick={() => setPassVisible(!passVisible)} className='passVisibility'>{passVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}</i>
                {formErr ? <ErrorComponent formErr={formErr.password} /> : null}
              </Form.Group>
               {/* : null} */}
            {/* {update === false ?  */}

              <Form.Group className="col-md-3" >
                <Form.Label>Confirm Password  </Form.Label>
                <Form.Control
                  type={confPassVisible ? "text" : "password"}
                  name="Cpassword"
                  onChange={handleChange}
                  value={data.Cpassword}
                  placeholder="Enter confirm password"
                  autoComplete="off"
                  // disabled = {update === false ? false :true}
                />
                <i onClick={() => setConfPassVisible(!confPassVisible)} className='passVisibility'>{confPassVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}</i>
                {formErr ? <ErrorComponent formErr={formErr.Cpassword} /> : null}
              </Form.Group>

              {/* : null}  */}
            {/* {update === false ?  */}
            <Form.Group className="col-md-6" >
              <Form.Label> Address  </Form.Label>
              <Form.Control
                as="textarea" rows={2}
                type="text"
                name="address"
                autoComplete='off'
                onChange={handleChange}
                value={data.address}
                placeholder="Enter Address" />
              {formErr ? <ErrorComponent formErr={formErr.address} /> : null}
            </Form.Group>
            {/* :
            <Form.Group className="col-md-12" >
              <Form.Label> Address  </Form.Label>
              <Form.Control
                as="textarea" rows={2}
                type="text"
                name="address"
                autoComplete='off'
                onChange={handleChange}
                value={data.address}
                placeholder="Enter Address" />
              {formErr ? <ErrorComponent formErr={formErr.address} /> : null}
            </Form.Group>
            } */}

          </Row>

          <Row className="form-set ">
            <Form.Group className="col-md-3" >
              <Form.Label> PAN  </Form.Label>
              <Form.Control
                type="text"
                name="pan"
                min="0"
                onChange={handleChange}
                value={data.pan}
                placeholder="Enter PAN "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.pan} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> TAN  </Form.Label>
              <Form.Control
                type="text"
                name="tan"
                min="0"
                onChange={handleChange}
                value={data.tan}
                placeholder="Enter TAN "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.tan} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> GST  </Form.Label>
              <Form.Control
                type="Number"
                name="gst"
                min="0"
                onChange={handleChange}
                value={data.gst}
                placeholder="Enter GST "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.gst} /> : null}
            </Form.Group>

            <Form.Group className="col-md-3" >
              <Form.Label> Profession  </Form.Label>
              <Form.Control
                type="text"
                name="profession"
                min="0"
                onChange={handleChange}
                value={data.profession}
                placeholder="Enter Profession "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.profession} /> : null}
            </Form.Group>
          </Row>
         
          <Row className="form-set ">

            <Form.Group className="col-md-3" >
              <Form.Label> Name of bank  </Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                min="0"
                onChange={handleChange}
                value={data.bankName}
                placeholder="Enter Bank Name "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.bankName} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> Branch Name </Form.Label>
              <Form.Control
                type="text"
                name="branch"
                min="0"
                onChange={handleChange}
                value={data.branch}
                placeholder="Enter Branch Name "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.branch} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> Account Number  </Form.Label>
              <Form.Control
                type="text"
                name="AccountNumber"
                min="0"
                onChange={handleChange}
                value={data.AccountNumber}
                placeholder="Enter Account Number "
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.AccountNumber} /> : null}
            </Form.Group>
            <Form.Group className="col-md-3" >
              <Form.Label> IFSC Code  </Form.Label>
              <Form.Control
                type="text"
                name="IFSC"
                min="0"
                onChange={handleChange}
                value={data.IFSC}
                placeholder="Enter IFSC code"
                autoComplete="off"
              />
              {formErr ? <ErrorComponent formErr={formErr.IFSC} /> : null}
            </Form.Group>
          </Row>

          <Row className="form-set text-center">


            <Form.Group className="col-md-12" >
              {/* {update === false ?
                <> */}
                  <Button variant="primary" className="mx-2 w100" id="submitButton"
                    onClick={() => { handleSubmit(); }}
                  // disabled={!disableButton()}
                  >
                    Save
                  </Button>

                  <Button
                    variant="secondary"
                    className="mx-2 w100"
                    type="reset"
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                {/* </>
                :
                <Button variant="primary" className="mx-2 w100" id="submitButton"
                  onClick={() => { handleUpdate(); }}
                // disabled={!disableButton()}
                >
                  Update
                </Button>
              } */}
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>

  )
}

export default AddChannelPartner