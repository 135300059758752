import React from 'react';
import BullionFolio from '../../components/BullionFolio/BullionFolio';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Bullion () {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return (
        <div >
                {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <BullionFolio />
                </div>
            </div>
        </div>
    );
}

export default Bullion;