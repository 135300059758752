import React from 'react'
import AddNewPlans from '../../components/Subscriptions/AddNewPlans';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const NewPlans = () => {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return (
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar/>
                <div>
                    <AddNewPlans/>
                </div>
            </div>
        </div>

    )
}

export default NewPlans;