/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Loader from "../loadingAnimation/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Cleave from 'cleave.js/react';

import { AddLoansRepay, UpdateLoansRepay, GetLoansInterestInstallmentRepayinfo, DeleteLoansRepay_Interest_Insta, GetLoanTransaction } from '../../pages/Loans_Deposits/Loans_DepoAPI'

const LoansRepay = (props) => {
    const { showLoanI, hideLoanI, LoanId, InvDate, Iname, reinvestedRadio, intrestAmount } = props;
    const [date, setDate] = useState(null);
    const [interestAmt, setInterestAmt] = useState(null);
    const [principleAmt, setPrincipleAmt] = useState(null);
    const [tds, settds] = useState(null);
    const [netamt, setnetamt] = useState(null);
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idata, setiData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [RepayId, setRepayId] = useState(null);
    const [OutStandingamt, setOutstandingAmt] = useState(null);
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;

    

    useEffect(() => {
        if (principleAmt >= 0 && interestAmt >= 0 && tds >= 0) {
            handleAmount()
        } else {
            setnetamt(0)
        }
    }, [principleAmt, interestAmt, tds]);

    useEffect(() => {
        if (LoanId != null && showLoanI === true) {
            getRepayDataList();
            getOutstandingAmt();
        }
    }, [LoanId, showLoanI]);

    const handleclear = () => {
        setInterestAmt("");
        setPrincipleAmt("");
        settds("");
    }

    const handleAmount = () => {
        if (interestAmt || tds) {
            const total = Number(principleAmt) + Number(interestAmt) - Number(tds).toFixed(2);
            setnetamt(total.toFixed(2));
            if (total > OutStandingamt) {
                alert("Amount of Repayment cannot exceed Outstanding Amount Rs." + OutStandingamt);
                handleclear();
            }
            else if (interestAmt > intrestAmount) {
                alert("Amount of Repayment cannot exceed Interest Amount Rs." + intrestAmount);
                handleclear();
            }

        } else {
            const total = Number(principleAmt)
            setnetamt(total.toFixed(2));
            if (total > OutStandingamt) {
                alert("Amount of Repayment cannot exceed Outstanding Amount Rs." + OutStandingamt);
                handleclear();
            }
        }
    }

    const getOutstandingAmt = () => {
        setLoading(true);
        GetLoanTransaction(LoanId).then((res) => {
            setLoading(false)
            if (res.data._response.status === 1) {
                let Oamount = res.data._response.data.loanDepositTransactionDetails.amtOutstanding
                setOutstandingAmt(Oamount)
            } else {
                setLoading(false)
                console.log("err in repay")
            }

        }).catch((err) => {
            setLoading(false)
            console.log("err in get out amt in repay", err)
        })
    }
    // validation
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (date === "" || date == null) {
            formErr["date"] = "Please fill date!";
            isValid = false;
        }
        if ((interestAmt === "" || interestAmt == null) && (reinvestedRadio === "yes")) {
            formErr["interestAmt"] = "Please enter interest amount!";
            isValid = false;
        }
        if (principleAmt === "" || principleAmt == null) {
            formErr["principleAmt"] = "Please enter principle amount!";
            isValid = false;
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const getRepayDataList = () => {
        setLoading(true)
        GetLoansInterestInstallmentRepayinfo(LoanId, "Repay")
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.intInsMatDetails
                        setiData(result);
                    }
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, "error in get fix income data");
            })
    }
    const handleSubmit = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let data = {
                transactStockType: "Repay",
                loanId: LoanId,
                principal: Number(principleAmt ? principleAmt : 0),
                interest: Number(interestAmt ? interestAmt : 0),
                tds: Number(tds ? tds : 0),
                amtOfRepayment: Number(netamt),
                dateOfRepayment: fixTimezoneOffset(date),
                // dateInvestment: fixTimezoneOffset(InvDate),
            };
            AddLoansRepay(data).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("Record Added Successfully!", "", "success");
                        getRepayDataList();
                        handleReset();
                        // handleCloseModal();
                        // onRefreshFD();
                    } else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const handleReset = () => {
        setDate(null);
        setInterestAmt("");
        setPrincipleAmt("");
        settds("");
        setUpdate(false)
        setFormErr([])
        // setnetamt(null);
    }
    const handleCloseModal = () => {
        handleReset();
        hideLoanI();
        setUpdate(false)
    }


    // onChange handler 
    const handleDate = (selected) => {
        setDate(selected);
        setFormErr({ ...formErr, date: "" })
    }
    const handleInterestAmt = (e) => {
        const { rawValue } = e.target;
        setInterestAmt(rawValue);
        setFormErr({ interestAmt: "" })
    }
    const handlePrincipleAmt = (e) => {
        const { rawValue } = e.target;
        setPrincipleAmt(rawValue);
        setFormErr({ principleAmt: "" })
    }
    const handleTDS = (e) => {
        const { rawValue } = e.target;
        settds(rawValue);
        setFormErr({ tds: "" });
    }
    // handle single delete
    const deleterow = (selectedRow, a) => {

        setLoading(true)
        DeleteLoansRepay_Interest_Insta(a.loanSellId).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    let del = idata.filter(i => i.loanSellId !== a.loanSellId);
                    setiData(del)
                    // getInterestDataList();
                    getOutstandingAmt();
                    Swal.fire("Record Deleted Successfully!", "", "success");
                } else {
                    Swal.fire(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                console.log(error, " : error in delete interest");
            })
    };
    // delete multiple
    const deleteMultiple = (e) => {
        setLoading(true)
        let a = JSON.stringify(selected)
        let interstpara = a.replace(/[\[\]']+/g, '');
        let ids = interstpara.replace(/,/g, '&&LoanSellId=')
        DeleteLoansRepay_Interest_Insta(ids).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    // getInterestDataList();
                    handleCloseModal();
                    setSelected([])
                    Swal.fire("Record Deleted Successfully!", "", "success");
                } else {
                    Swal.fire(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                setLoading(false)
                console.log(error, " : error in delete multiple");
            })
    };

    // handle multiple delete
    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = idata.map((n) => n.loanSellId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // handle update
    const handleUpdate = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let data = {
                transactStockType: "Repay",
                loanId: LoanId,
                loanSellId: RepayId,
                principal: Number(principleAmt ? principleAmt : 0),
                interest: Number(interestAmt ? interestAmt : 0),
                tds: Number(tds ? tds : 0),
                amtOfRepayment: Number(netamt),
                dateOfRepayment: fixTimezoneOffset(date),
                // dateInvestment: fixTimezoneOffset(InvDate),
            };
            UpdateLoansRepay(data).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        // handleCloseModal();
                        getRepayDataList()
                        handleReset()
                        // onRefreshFD(); 
                        Swal.fire("Record Updated Successfully!", "", "success");
                    } else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const updateRepay = (selectedRow, a) => {
        setUpdate(true)
        setPrincipleAmt(a.principal);
        setDate(new Date(a.dateOfRepayment));
        setInterestAmt(a.interest);
        settds(a.tds);
        setnetamt(a.amtOfRepayment);
        let amount = OutStandingamt + a.amtOfRepayment;
        setOutstandingAmt(amount);
        setRepayId(a.loanSellId);
    }
    return (
        <>
            <Modal isOpen={showLoanI} toggle={() => handleCloseModal()} size="lg" backdrop="static" centered>
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>{Iname}</h5>
                </ModalHeader>
                <ModalBody >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row>
                                <Form.Group className={reinvestedRadio === "yes" ? "col-md-3" : "col-md-6"}>
                                    <Form.Label>Date of Repayment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDate}
                                        popperPlacement="top-end"
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        minDate={new Date(InvDate)}
                                        selected={date}
                                    // maxDate={new Date()}
                                    />
                                </Form.Group>
                                <Form.Group className={reinvestedRadio === "yes" ? "col-md-3" : "col-md-6"}>
                                    <Form.Label>{reinvestedRadio === "yes" ? "Principle Amount" : "Net Principle Amount"}<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder="Enter amount of Principle"
                                        name="principleAmt"
                                        min="0"
                                        value={principleAmt}
                                        onChange={handlePrincipleAmt}
                                    /> */}
                                    <Cleave
                                        placeholder="Enter amount of Principle"
                                        className='form-control'
                                        name="principleAmt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={principleAmt}
                                        onChange={handlePrincipleAmt}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.principleAmt}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                {
                                    reinvestedRadio === "yes" ?
                                        <>
                                            <Form.Group className="col-md-3">
                                                <Form.Label>Amount of Interest<span className="text-danger h6">*</span></Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    placeholder="Enter amount of interest"
                                                    name="interestAmt"
                                                    min="0"
                                                    value={interestAmt}
                                                    onChange={handleInterestAmt}
                                                /> */}
                                                <Cleave
                                                    placeholder="Enter amount of interest"
                                                    className='form-control'
                                                    name="interestAmt"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={interestAmt}
                                                    onChange={handleInterestAmt}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.interestAmt}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="col-md-3">
                                                <Form.Label>TDS</Form.Label>
                                                {/* <Form.Control
                                                    type="Number"
                                                    placeholder='Enter TDS'
                                                    name="tds"
                                                    min="0"
                                                    value={tds}
                                                    onChange={handleTDS}
                                                /> */}

                                                <Cleave
                                                    placeholder='Enter TDS'
                                                    className='form-control'
                                                    name="tds"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={tds}
                                                    onChange={handleTDS}
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.tds}
                                                    </div>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group className="col-md-3">
                                                <Form.Label>Net amount credited</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    name="netamt"
                                                    min="0"
                                                    value={NumberSeperator(netamt)}
                                                    // onChange={handleQuantity}
                                                    disabled
                                                /> */}
                                                <Cleave
                                                    className='form-control'
                                                    name="netamt"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={netamt}
                                                    disabled
                                                />
                                                {formErr ? (
                                                    <div
                                                        className="mt-2"
                                                        style={{ fontSize: 12, color: "red" }}
                                                    >
                                                        {formErr.netamt}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                        </> : null
                                }
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {update === false ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                        //   disabled={!disableButton()}
                                        >Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                        >Update</Button>}

                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div><br />
                    {/* table design */}
                    <TableContainer component={Paper}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }),
                            }}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length} selected
                                </Typography>
                            ) : null}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <DeleteIcon onClick={(e) => deleteMultiple(e)} />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Toolbar>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableHead>
                                <TableRow>
                                    <TableCell width="5%">
                                        <Checkbox
                                            style={{ float: "left", marginLeft: "14px" }}
                                            color="primary"
                                            onClick={handleSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < idata.length}
                                            checked={idata.length > 0 && selected.length === idata.length ? "checked" : null}
                                            // onChange={onSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center" width="10%">Date</TableCell>
                                    <TableCell align="center" width="12%">Principle</TableCell>
                                    <TableCell align="center" width="15%">Amount of Interest</TableCell>
                                    <TableCell align="center" width="10%">TDS</TableCell>
                                    <TableCell align="center" width="15%">Net amount credited</TableCell>
                                    <TableCell align="center" width="10%">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idata && idata.map((index) => {
                                    const isItemSelected = isSelected(index.loanSellId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={idata.loanSellId}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{ marginLeft: "1rem" }}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, index.loanSellId)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{moment(index.dateOfRepayment).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.principal)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.interest)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.tds)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.amtOfRepayment)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <Edit fontSize="small" onClick={(e) => updateRepay(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <DeleteIcon fontSize="small" onClick={(e) => deleterow(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </Modal>
        </>
    )
}

export default LoansRepay