/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import moment from "moment";

const FD_RD_PO_Preview = (props) => {
  const { onHideFD_RD_PO, FDTransactionFD_RD_PO, FDHeaderId, showFD_RD_PO_Preview } = props;
  const [instTYID, setinstTYID] = useState(null);
  const [company, setCompany] = useState("");
  const [certificateFolioNo, setCertificateFolioNo] = useState(null);
  const [idate, setIdate] = useState(null);
  const [tenure, setTenure] = useState(null);
  const [lockinperiod, setLockinperiod] = useState(null);
  const [amount, setAmount] = useState(null);
  const [interest, setInterest] = useState(null);
  const [frequency, setFrequency] = useState(null);

  const [mDate, setmDate] = useState(null);
  const [maturityVal, setMaturityVal] = useState(null);
  const [reinvestedRadio, setreinvestedRadio] = useState(null);
  const [interestAcc, setInterestAcc] = useState(null)
  const [financialyrStartDate, setFinancialyrStartDate] = useState(null);

  const [numberOfInstallment, setNumberOfInstallment] = useState(null);

  const [typeOfInvestment, setTypeOfInvestment] = useState(null);
  const [narration, setNarration] = useState("");
  const [currentValue, setCurrentVal] = useState(null)

  useEffect(() => {
    if (FDHeaderId !== 0) {
      if (FDTransactionFD_RD_PO !== undefined) {
        setNarration(FDTransactionFD_RD_PO.narration)
        // console.log(FDTransactionFD_RD_PO, " : FDTransactionFD_RD_PO");
        FDTransactionFD_RD_PO.fdDetails && FDTransactionFD_RD_PO.fdDetails.map((data) => {

          setinstTYID(data.instrumentTypeId);
          setCompany(data.company.companyName);
          setCertificateFolioNo(data.folioNumber);
          setIdate(data.date)
          setTenure(data.tenure)
          setLockinperiod(data.lockInPeriod)
          setAmount(data.totalCost)
          setInterest(data.interestPerc)
          setFrequency(data.frequency.frequencyName)
          setmDate(data.domConversion)
          setMaturityVal(data.maturityValue)
          setreinvestedRadio(data.interestAccrued)
          setInterestAcc(data.interestReinvested)
          setFinancialyrStartDate(data.fyStartDate)
          setNumberOfInstallment(data.numberOfInstalments)
          setTypeOfInvestment(data.company.companyName)
          setCurrentVal(data.currentValue)
        })
      }
    }
  }, [FDTransactionFD_RD_PO]);

  let value = lockinperiod;
  let splitData = value ? value.split(/([0-9]+)/) : null;
  let time = splitData ? splitData[1] : "-";
  let period = splitData ? splitData[2] : "";
  let value1 = tenure;
  let splitData1 = value1 ? value1.split(/([0-9]+)/) : null;
  let time1 = splitData1 ? splitData1[1] : "-";
  let period1 = splitData1 ? splitData1[2] : "";
  return <div>
    {showFD_RD_PO_Preview === true ?
      <Modal show={showFD_RD_PO_Preview} onHide={() => onHideFD_RD_PO(1)}>
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}>
            {instTYID === 29 ? <h5>FD Details</h5> : instTYID === 30 ? <h5>RD Details</h5> : instTYID === 32 ? <h5>Post Office Details</h5> : instTYID === 31 ? <h5>PPF Details</h5> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
            {instTYID === 29 || instTYID === 30 ? <>
              <div className="transactionData "><div className="transactionDataTitle">Company/Bank : </div>
                <div className="transactionDataValue">{company}</div></div> </> : null}
            {instTYID === 32 ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Certificate/Folio No. : </div>
                <div className="transactionDataValue">{certificateFolioNo}</div></div>
            </> : null}
            {instTYID === 32 ? <>
              <div className="transactionData "><div className="transactionDataTitle">Type of Investment : </div>
                <div className="transactionDataValue">{typeOfInvestment}</div></div>
            </> : null}
            <div className="transactionData odd"><div className="transactionDataTitle">Date of Investment : </div>
              <div className="transactionDataValue">{moment(idate).format("DD/MM/YYYY")}</div></div>
            {instTYID === 29 ? <>
              <div className="transactionData "><div className="transactionDataTitle">Tenure : </div>
                <div className="transactionDataValue">{tenure === null ? "-" : `${time1 ? time1 : '-'}  ${period1 ? period1 : ""}`}</div></div>
            </> : null}
            <div className="transactionData odd"><div className="transactionDataTitle">Lock in Period : </div>
              <div className="transactionDataValue">{lockinperiod === null ? "-" : `${time ? time : '-'}  ${period ? period : ""}`}</div></div>
            <div className="transactionData "><div className="transactionDataTitle">Amount Invested : </div>
              <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount)}</div></div>
            {instTYID === 30 ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Number of Installments : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(numberOfInstallment)}</div></div></> : null}
            {instTYID === 29 || instTYID === 32 ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Interest % : </div>
                <div className="transactionDataValue">{interest}</div></div></> : null}

            <div className="transactionData "><div className="transactionDataTitle">Interest Frequency : </div>
              <div className="transactionDataValue">{frequency}</div></div>
            <div className="transactionData odd"><div className="transactionDataTitle">Date of Maturity : </div>
              <div className="transactionDataValue">{moment(mDate).format("DD/MM/YYYY")}</div></div>
            <div className="transactionData "><div className="transactionDataTitle">Maturity Value : </div>
              <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(maturityVal)}</div></div>
            {instTYID === 29 ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Current Value : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(currentValue)}</div></div>
            </> : null}
            {reinvestedRadio ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Accured Interest : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(interestAcc)}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Financial year starting with : </div>
                <div className="transactionDataValue">{moment(financialyrStartDate).format("DD/MM/YYYY")}</div></div></> : null
            }

            {instTYID === 32 ? <>
              <div className="transactionData odd"><div className="transactionDataTitle">Narration : </div>
                <div className="transactionDataValue">{narration}</div></div></> : null}
          </div>
        </Modal.Body>
      </Modal>
      : null}
  </div>;
};

export default FD_RD_PO_Preview;