/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import MaterialTable , { MTableBody } from "material-table";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

import { Col, Row } from "react-bootstrap";
import { EntityList, DashboardFamily, DashboardEntity } from '../Dashboard/RecentTranAPI/RT_API'
import _ from "lodash";
import { Link } from 'react-router-dom'
import Auth from '../../modules/Auth/index';

const userID = Auth.getsubcriberId();

function SecondDTable() {
  const [filteredFamilyData, setFilteredFamilyData] = useState([]);
  const [filterEntityData, setfilterEntityData] = useState([])


  const [script, setScript] = useState("All");
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userID) {
      getFamilyData();

      getEntityList();
    }
  }, []);

  //getEntityList
  const getEntityList = () => {

    setLoading(true)
    EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let SubscriberName = res.data._response.data.subaccountDetails;
            setEntityRadio(SubscriberName);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  const handleChangeEntity = async (e) => {
    setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      getFamilyData();
      setScript("All");
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName === e.target.value;
      });
      await getEntityData(data.subAccountId)

    }
  };

  const getEntityData = (id) => {
    DashboardEntity(id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let EntityStock = res.data._response.data.fetchMenuInvestmentGridBySubAccountId;
            setfilterEntityData(EntityStock)
            setLoading(false);

            //setLoading(false)
            setScript("All")
          } else {
            setLoading(false);
            setfilterEntityData([]);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("error");
      });

  }

  // round numbers for percentage value
  function preciseMathDotRound(value, precision = 2) {
    let valueData = isFinite(value)

    if (valueData) {

      return parseFloat(value).toFixed(precision);
    } else {
      return 0;
    }
  }

  //only two decimal places
  const convertTOdecimal = (num) => {

    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const columns = [
    {
      field: "srno", title: "Sr.No.", type: 'numeric', width: " 1%",
      render: (rowData) => (
        <span>{rowData.tableData.id + 1}</span>
      )
    },
    {
      title: "Investment Type", field: "instrumentName",
      render: (rowData) => (

        rowData.instrumentName === "Stocks" ? <Link className="instrumentLink" to="/Stock" > {rowData.instrumentName}</Link> :
          rowData.instrumentName === "Fixed Income" ? <Link className="instrumentLink" to="/fixedincome" > {rowData.instrumentName}</Link> :
            rowData.instrumentName === "Loans and Deposits" ? <Link className="instrumentLink" to="/Loans" > {rowData.instrumentName}</Link> :
              rowData.instrumentName === "Mutual Fund" ? <Link className="instrumentLink" to="/MutualFund" > {rowData.instrumentName}</Link> :
                rowData.instrumentName === "Bullion" ? <Link className="instrumentLink" to="/Bullion" > {rowData.instrumentName}</Link> :
                  rowData.instrumentName === "Property" ? <Link className="instrumentLink" to="/Property" > {rowData.instrumentName}</Link> :
                    rowData.instrumentName === "Arts" ? <Link className="instrumentLink" to="/Art" > {rowData.instrumentName}</Link> : null

      )
    },

    {
      title: "Total Investments",
      field: "totalInvestment",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.totalInvestment)}</span>
      )
    },
    {
      title: "Today's Gain", field: "todaysGain", type: "numeric",
      render: (rowData) => (

        (rowData.daysGain) >= 0 ?
          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
              convertTOdecimal(rowData.daysGain))}
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
              convertTOdecimal(rowData.daysGain))}
          </span>
      ),
    },
    {
      title: "Today's Gain %",
      field: "pTodaysGain",
      type: "numeric",
      render: (rowData) => {
        let calculationdays_gain = ((rowData.livePrice - rowData.prevLivePrice) / rowData.prevLivePrice) * 100

        return (

          (calculationdays_gain) ?

            ((calculationdays_gain) >= 0 ?




              <span style={{ color: "#008000" }}>

                {



                  new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(calculationdays_gain))}%

              </span>

              :

              <span style={{ color: "#ff0000" }}>

                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(calculationdays_gain))}%

              </span>) : <span style={{ color: "#008000" }}>0.00%</span>

        )

      }
    },
    {
      title: "Overall Gain",
      field: "overallGain",
      type: "numeric",
      render: (rowData) => (
        (rowData.overallGain) ?
          ((rowData.overallGain) >= 0 ?

            <span style={{ color: "#008000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.overallGain))}
            </span>
            : <span style={{ color: "#ff0000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.overallGain))}
            </span>) : <span style={{ color: "#008000" }}>0.00%</span>
      ),
    },
    {
      title: "Overall Gain %",
      field: "pOverallgain",
      type: "numeric",
      render: (rowData) => (
        (
          ((rowData.overallGain / rowData.totalInvestment) * 100)

        ) ?
          (
            ((rowData.overallGain / rowData.totalInvestment) * 100) >= 0 ?
              <span style={{ color: "#008000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  convertTOdecimal(((rowData.overallGain / rowData.totalInvestment) * 100)))} %
              </span> :

              <span style={{ color: "#ff0000" }}>
                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(
                  convertTOdecimal(((rowData.overallGain / rowData.totalInvestment) * 100)))} %
              </span>
          )

          :
          <span style={{ color: "#008000" }}> 0%</span>
      ),
    },
    {
      title: "Current Value",
      field: "currentValue",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.currentValue))}</span>
      ),
    },
  ];

  const getFamilyData = () => {
    DashboardFamily().then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setLoading(false);
          const familyData = res.data._response.data.fetchMenuInvestmentGridBySubscriberId
          setFilteredFamilyData(familyData)
        } else {
          setLoading(false);

        }

      } else {
        setLoading(false);

      }

    }).catch((e) => {
      setLoading(false);
      console.log(e.message);
    })
  }

  return (
    <div>
      {/* <Sidebar /> */}
      {/* <div className="contentWrap"> */}
      {/* <Navbar /> */}
      <div>
        {/* <Container Fluid> */}
        <Row>
          <Col>
            <h4 className="text-center py-2 mb-0">Investments</h4><br></br>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div style={{ maxWidth: "100%" }} className="investmentTbl">
              <MaterialTable
                title="Investments"
                data={
                  entity === "Family" ? filteredFamilyData : filterEntityData
                }
                columns={columns}
                isLoading={loading}
                options={{
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                  exportButton: false,
                  search: false,
                  title: false,
                  paging: false,
                  pageSize: 7,
                  pageSizeOptions: [10, 20, 50],
                  emptyRowsWhenPaging: false
                }}
                actions={[
                  {
                    icon: () => (
                      <FormControl className="mt-4">

                        <RadioGroup
                          row
                          color="primary"
                          defaultValue="start"
                          aria-label="Entity"
                          name="row-radio-buttons-group"
                          value={entity}
                          onChange={handleChangeEntity}
                          style={{ marginTop: "-1rem" }}
                        >
                          <FormControlLabel
                            value="Family"
                            control={<Radio />}
                            label="Family"
                          />
                          {entityRadio.map((a) => (
                            <FormControlLabel
                              value={a.subAccountName}
                              control={<Radio />}
                              label={a.subAccountName}
                              disabled={a.active === 0 ? true : false}
                            />
                          ))}

                        </RadioGroup>
                      </FormControl>
                    ),

                    isFreeAction: true,
                  },
                ]}

                components={{
                  Body: (props) => {
        
        
                    let totalData = {
                      investmentCost: 0,
                      investmentCostM: 0,
                      netWorthCost: 0,
                      netWorthCostM: 0,
                      gainNetWorth: 0,
                      currentValue: 0
                    }
        
                    props.renderData.forEach((rowData) => {
                      totalData.investmentCost += rowData.totalInvestment;
                      totalData.investmentCostM += rowData.daysGain;
                      totalData.netWorthCostM += rowData.overallGain;
                      totalData.currentValue +=  rowData.currentValue;

                    });
                    totalData.netWorthCost += (totalData.investmentCostM / totalData.investmentCost) * 100;
                    totalData.gainNetWorth = (totalData.netWorthCostM / totalData.investmentCost) * 100;
        
        
                    return (
                      <>
                        <MTableBody {...props} />
                        {
        
                          <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                            <TableRow>
                              <TableCell ></TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="left">Total</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.investmentCostM))}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.netWorthCost ? totalData.netWorthCost:0)}%</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.netWorthCostM))}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.gainNetWorth ? totalData.gainNetWorth:0))}%</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.currentValue))}</TableCell>
        
                            </TableRow>
                          </TableFooter>
        
                        }
                      </>
                    )
        
                  },
        
                }}
              />
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </div>
    // </div>
  );
}

export default SecondDTable;
