/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
import { FetchBrokerDropDownData, FetchCompanyDropDownData, CompanyList, addUnlistedScrip, EntityList, AvilableQuantity, addDemerger, updateDmerger, FetchDate } from '../../../pages/Stocks/StocksAPI';
import Loader from '../../loadingAnimation/index';
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const Demerger = (props) => {

    const { showD, onHideD, stockHeaderId, stockIdDe1, stockIdDe2, stockTransactionD, showDemergerPreview } = props;

    const [companyList, setCompanyList] = useState([]);
    const [allcompanyList, setAllCompanyList] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [error, setError] = useState('');
    const [ratio, setRatio] = useState([]);
    const [balancQnty, setBalancQnty] = useState(0);
    const [remainingcost, setreamainingCost] = useState(0)
    const [seldeCompOne, setSelDeCompOne] = useState([]);
    const [seldeCompTwo, setSelDeCompTwo] = useState([]);

    const [quantity, setQuantity] = useState([])
    const [totalCost, setTotalCost] = useState([]);

    const [broker, setbroker] = useState([]);
    const [entityValue, setEntityValue] = useState([])
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [buyDate, setBuyDate] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [unlistedComp, setUnlistedComp] = useState({ companyName: null });
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    // useEnterKeyListener({
    //     querySelectorToExecuteClick: "#submitButton"
    // });

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    // seperation by comma
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }

    // close model
    const handleClose = (val) => {
        onHideD(val);
        handleReset();
    }

    //handlechange
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: "" });
    };

    const handleDcompOne = (selected) => {
        if (seldeCompTwo !== selected) {
            setSelDeCompOne(selected)
            setFormErr({ ...formErr, seldeCompOne: "" });
        }
        else {
            setSelDeCompOne('')
            // swal("Ooops!", "same company selected", "error");
            formErr["seldeCompOne"] = "Same Company Selected....";
            setFormErr(formErr)
        }


    };

    const handleDcompTwo = (selected) => {
        if (seldeCompOne !== selected) {
            setSelDeCompTwo(selected)
            setFormErr({ ...formErr, seldeCompTwo: "" });
        }
        else {
            setSelDeCompTwo('')
            // swal("Ooops!", "same company selected", "error");
            formErr["seldeCompTwo"] = "Same Company Selected....";
            setFormErr(formErr)
        }

    };

    const handleEntity = (selected) => {
        setSelEntity(selected);
        setEntityValue(selected.value);
        setSelBroker([]);
        setSelScrip([])
        setFormErr({ ...formErr, selEntity: "" });
    };
    const handleOption = (selected) => {
        setSelBroker(selected);
        setbroker(selected.value)
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: "" });
    };

    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    }

    const handleRatio = (e) => {
        const { name, value } = e.target;
        setRatio({ ...ratio, [name]: value })
        setFormErr({ ...formErr, ratio: '' })
    }

    const handleQuantity = (e) => {
        const { name, rawValue } = e.target;
        setQuantity({ ...quantity, [name]: rawValue })
        setFormErr({ ...formErr, quantity: '' })
    }

    const handleTotalCost = (e) => {
        const { name, rawValue } = e.target;
        setTotalCost({ ...totalCost, [name]: rawValue })
        if (name === "dTotalCostOne") {
            setFormErr({ ...formErr, dTotalCostOne: '' });
        }
        setFormErr({ ...formErr, dTotalCostTwo: '' })
    }

    useEffect(() => {
        if (showD) {
            (async () => {
                if (stockHeaderId === 0 || stockHeaderId === undefined) {
                    setLoading(true)
                    await getEntityListt();
                    await getCompanyList();
                }
            })();
        }
    }, [showD, stockHeaderId]);

    useEffect(() => {
        if (showD) {
            (async () => {
                await getCompanyDropDownData()
                await getBrokerDropDownData()
            })();
        }
    }, [selEntity, showD])


    // get dropDown company Data
    const getBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchBrokerDropDownData(sub_Id)
                .then((res) => {
                    setLoading(false)
                    // console.log(res, "brokerlisy");
                    if (res.status === 200) {
                        let brokers = res.data._response.data.brokerName;
                        let brokersData = [];
                        brokers &&
                            brokers.map((data) => {
                                return (brokersData.push({
                                    value: data.brokerId,
                                    label: data.brokerName,
                                }))
                            });
                        setLoading(false);
                        setBrokerList(brokersData);
                    } else {
                        setBrokerList([]);
                        setLoading(false)
                        //setError(res.data.message);
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setBrokerList([]);
                    setLoading(false);
                    console.log(e, "brokerlist error");
                });
        }
    };


    // get dropDown Company Data
    const getCompanyDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchCompanyDropDownData(sub_Id)
                .then((res) => {
                    // console.log(res,'company list');
                    if (res.status === 200) {
                        let UnlistedCompany = [];
                        let ListedCompany = [];

                        let company = res.data._response.data.companyName;
                        company &&
                            company.filter(data => data.instrumentTypeId === 1).map(i => {
                                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });
                        company &&
                            company.filter(data => data.instrumentTypeId === 10).map(i => {
                                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });

                        const groupedOptions = [
                            {
                                label: 'Listed Companies',
                                options: ListedCompany,
                            },
                            {
                                label: 'UnListed Companies',
                                options: UnlistedCompany,
                            },
                        ];
                        setLoading(false);
                        setCompanyList(groupedOptions);
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                        // setError(res.data.message);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                    // setError(e.message);
                });
        }
    };
    //getEntityList
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                    setLoading(false)
                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                // setError(e.message);
            });


    };
    //handel disable listed companies
    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }
    //get available quantity
    const getStockDetail = async (brokerId, subAccountId, companyId) => {
        setLoading(true);
        await AvilableQuantity(brokerId, subAccountId, companyId)
            .then((res) => {

                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setBalancQnty(res.data._response.data.remainingQuantity);
                        if (stockHeaderId === 0 || stockHeaderId === undefined) {
                            setreamainingCost(res.data._response.data.remainingCost);
                        }
                        setLoading(false);
                    } else {
                        if (stockHeaderId === 0 || stockHeaderId === undefined) {
                            setreamainingCost(0);
                            setBalancQnty(0);
                        }
                    }
                } else {
                    //setError(res.data.message);
                    setBalancQnty({ balancQnty: 0 })
                    setLoading(false);
                }
            })
            .catch((e) => {
                setLoading(false);
                //setError(e.message);
            });
    };
    //getcompanylist by group
    const getCompanyList = async () => {
        setLoading(true)
        await CompanyList()
            .then((res) => {
                if (res.data._response.status === 1) {
                    //
                    setLoading(false)
                    let UnlistedCompany = [];
                    let ListedCompany = [];

                    let company = res.data._response.data.companyDetails;

                    company &&
                        company.filter(data => data.instrumentTypeId === 1).map(i => {
                            return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });
                    company &&
                        company.filter(data => data.instrumentTypeId === 10).map(i => {
                            return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });

                    const groupedOptions = [
                        {
                            label: 'Listed Companies',
                            options: ListedCompany,
                        },
                        {
                            label: 'UnListed Companies',
                            options: UnlistedCompany,
                        },
                    ];
                    setAllCompanyList(groupedOptions);
                    setLoading(false)

                } else {
                    setLoading(false)
                    setAllCompanyList([]);
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                setAllCompanyList([]);
                console.log(e, 'error in companyList');
                // setError(e.message);
            });
    };
    //reset form
    const handleReset = () => {
        setSelScrip([]);
        setSelBroker([]);
        setSelEntity([]);
        setBalancQnty([]);
        setSelDeCompOne([]);
        setSelDeCompTwo([]);
        setPDate(null);
        setQuantity({ dQuantityOne: '', dQuantityTwo: '' });
        setTotalCost({ dTotalCostOne: '', dTotalCostTwo: '' });
        setRatio({ ratio: '' })
        setFormErr({});
        setreamainingCost('')
    };

    const errHandel = () => {

        let formErr = {};
        let isValid = true;
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select Scrip!";
        }

        if (seldeCompOne.length < 1) {
            isValid = false;
            formErr["seldeCompOne"] = "Please Select First Demerger Company !";
        }
        if (seldeCompTwo.length < 1) {

            isValid = false;
            formErr["seldeCompTwo"] = "Please Select Secound Demerger Company !";
        }
        if (seldeCompTwo === seldeCompOne) {

            isValid = false;
            formErr["seldeCompTwo"] = "Please Select Secound Demerger Companyccccc !";
        }

        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }

        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }

        // if (!ratio.ratio) {
        //     isValid = false;
        //     formErr["ratio"] = "Please fill this field!";
        // }

        if (!quantity.dQuantityOne || quantity.dQuantityOne === 0) {
            isValid = false;
            formErr["dQuantityOne"] = "Please Fill This Field!";
        }
        if (quantity.dQuantityOne) {
            if (quantity.dQuantityOne < 0) {
                isValid = false;
                formErr["dQuantityOne"] = "Please Enter Positive Value !";
            }
        }
        // if (quantity.dQuantityOne) {
        //     let qvalue = parseFloat(quantity.dQuantityOne); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["dQuantityOne"] = "Please enter only integer value!";
        //     }
        //   }
        if (!quantity.dQuantityTwo || quantity.dQuantityTwo === 0) {

            isValid = false;
            formErr["dQuantityTwo"] = "Please Fill This Field!";
        }
        if (quantity.dQuantityTwo) {
            if (quantity.dQuantityTwo < 0) {
                isValid = false;
                formErr["dQuantityTwo"] = "Please Enter Positive Value !";
            }
        }
        // if (quantity.dQuantityTwo) {
        //     let qvalue = parseFloat(quantity.dQuantityTwo); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["dQuantityTwo"] = "Please enter only integer value!";
        //     }
        //   }

        if (!totalCost.dTotalCostOne || totalCost.dTotalCostOne === 0) {
            isValid = false;
            formErr["dTotalCostOne"] = "Please Fill This Field!";
        }
        if (totalCost.dTotalCostOne) {
            if (totalCost.dTotalCostOne < 0) {
                isValid = false;
                formErr["dTotalCostOne"] = "Please Enter Positive Value !";
            }
        }
        if (!totalCost.dTotalCostTwo || totalCost.dTotalCostTwo === 0) {
            isValid = false;
            formErr["dTotalCostTwo"] = "Please Fill This Field!";
        }
        if (totalCost.dTotalCostTwo) {
            if (totalCost.dTotalCostTwo < 0) {
                isValid = false;
                formErr["dTotalCostTwo"] = "Please Enter Positive Value !";
            }
        }
        if (amount > 0 && remainingcost) {

            if (parseInt(amount) !== parseInt(remainingcost)) {

                isValid = false;
                formErr["dTotalCostTwo"] = "Your Investment Amount Rs " + remainingcost + "!";
            }
        }
        setFormErr(formErr);
        return isValid;
    };

    //submit data
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                brokerId: selBroker.value,
                date: fixTimezoneOffset(pDate),
                // moment.tz(pDate, "Asia/Kolkata").format(),
                subAccountId: selEntity.value,
                companyId: selScrip.value,
                deMergerCompanyOne: seldeCompOne.value,
                deMergerCompanyTwo: seldeCompTwo.value,
                ratio: ratio.ratio,
                dQuantityOne: parseInt(quantity.dQuantityOne),
                dQuantityTwo: parseInt(quantity.dQuantityTwo),
                dTotalCostOne: parseInt(totalCost.dTotalCostOne),
                dTotalCostTwo: parseInt(totalCost.dTotalCostTwo),
                instrumentTypeId: selScrip.companyType
            };

            addDemerger(Sdata)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            setLoading(false);
                            Swal.fire("Success!", "Demerger Added Successfully", "success");
                            handleReset();
                            //onRefresh();
                        }
                    } else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in submit");
                });
        }
    }
    //update data
    const handleUpdate = () => {

        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                stockId: stockIdDe1,
                stockId2: stockIdDe2,
                stockHeaderId: stockHeaderId,
                brokerId: selBroker.value,
                date: fixTimezoneOffset(pDate),
                //moment.tz(pDate, "Asia/Kolkata").format(),
                subAccountId: selEntity.value,
                companyId: selScrip.value,
                deMergerCompanyOne: seldeCompOne.value,
                deMergerCompanyTwo: seldeCompTwo.value,
                ratio: ratio.ratio,
                dQuantityOne: parseInt(quantity.dQuantityOne),
                dQuantityTwo: parseInt(quantity.dQuantityTwo),
                dTotalCostOne: parseInt(totalCost.dTotalCostOne),
                dTotalCostTwo: parseInt(totalCost.dTotalCostTwo),
                instrumentTypeId: selScrip.companyType
            };
            updateDmerger(Sdata)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            setLoading(false);
                            Swal.fire("Success!", "Demerger Updated Successfully", "success");
                            handleReset();
                        }
                        //onRefresh();
                    } else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in submit");
                });
        }
    }
    //getbyid
    useEffect(() => {
        if (stockHeaderId !== 0) {
            setLoading(true)

            if (stockTransactionD !== undefined) {


                setSelBroker({
                    value: stockTransactionD.brokerId,
                    label: stockTransactionD.broker.brokerName,
                });
                setbroker(stockTransactionD.brokerId);

                setSelEntity({
                    value: stockTransactionD.subAccountId,
                    label: stockTransactionD.subAccount.subAccountName,
                });

                setEntityValue(stockTransactionD.subAccountId);
                setPDate(new Date(stockTransactionD.date));
                stockTransactionD.stockDetails && stockTransactionD.stockDetails.map((t) => {
                    return (setRatio({ ratio: t.ratio }))


                })
                let dData = stockTransactionD.stockDetails;
                const c = dData[0].fromCompany;
                setSelScrip({ label: c.companyName, value: dData[0].fromCompanyId, companyType: dData[0].instrumentTypeId })
                setSelDeCompOne({ value: dData[0].companyId, label: dData[0].company.companyName });

                setSelDeCompTwo({ value: dData[1].companyId, label: dData[1].company.companyName });
                setTotalCost({ dTotalCostOne: dData[0].availableCost, dTotalCostTwo: dData[1].availableCost })

                setQuantity({ dQuantityOne: dData[0].quantity, dQuantityTwo: dData[1].quantity })

                setLoading(false)
            }

        }
    }, [stockTransactionD])

    useEffect(() => {
        if (stockHeaderId !== 0) {
            if (showD || showDemergerPreview) {
                if (stockTransactionD !== undefined) {
                    let dData = stockTransactionD.stockDetails;
                    let calculatecost = dData[0].availableCost + dData[1].availableCost
                    setreamainingCost(calculatecost);
                }
            }
        }
    }, [stockTransactionD, showD, showDemergerPreview])
    useEffect(() => {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        if (showD) {
            if (companyId && brokerId && subAccountId) {
                getStockDetail(brokerId, subAccountId, companyId);
            }
        }
    }, [selScrip, selBroker, selEntity, showD]);

    // for buy date
    useEffect(() => {
        if (showD) {
            if (stockHeaderId === 0 || stockHeaderId === undefined) {
                setPDate(null)
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;

                if (companyId && brokerId && subAccountId) {
                    getBuyDate(subAccountId, companyId, brokerId);
                }
            }
            if (stockHeaderId !== 0 && stockTransactionD !== undefined) {
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;

                if (companyId && brokerId && subAccountId) {
                    getBuyDate(subAccountId, companyId, brokerId);
                }
            }
        }
    }, [selScrip, selBroker, selEntity, showD]);

    const getBuyDate = async (sub_id, co_id, bro_id) => {
        setLoading(true)
        await FetchDate(sub_id, co_id, bro_id)
            .then((res) => {

                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setLoading(false)
                        setBuyDate(res.data._response.data.fetchDate)
                    }
                    else {
                        setLoading(false)
                        setError(res.data.message);
                    }
                } else {
                    setLoading(false)
                    setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                setError(e.message);
            });
    };
    useEffect(() => {
        if (totalCost.dTotalCostOne > 0 && (totalCost.dTotalCostTwo > 0)) {
            handleAmount()
        } else {
            setAmount(0)
        }
    }, [totalCost]);

    const handleAmount = () => {

        const total = (parseInt(totalCost.dTotalCostOne) + parseInt(totalCost.dTotalCostTwo));
        setAmount(total);
    }

    const disableButton = () => {
        return selBroker && pDate && selEntity && selScrip &&
            seldeCompOne && seldeCompTwo && quantity && totalCost && ratio
    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleOpenModal = () => setShowModal(!showModal);

    const handleUnlisted = (e) => {
        const { name, value } = e.target;
        setUnlistedComp({ ...unlistedComp, [name]: value });
        setUnlistedCompErr('')
    }

    const submitUnlisted = (e) => {
        // debugger
        e.preventDefault();
        if (unlistedComp.companyName !== '' && unlistedComp.companyName !== null && unlistedComp.companyName !== undefined) {
            unlistedComp.companyName.trim()
            let result = unlistedComp.companyName.replace(/^\s+|\s+$/gm, '');
            let data = {
                "companyName": result,
                "InstrumentTypeId": 10
            }
            addUnlistedScrip(data)
                .then(res => {
                    setLoading(false)
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            const lastEle = 1
                            getCompanyList(lastEle);
                            setUnlistedComp('');
                            setUnlistedCompErr('');
                            handleCloseModal()
                            Swal.fire(res.data._response.message, "", 'success');
                        } else {
                            Swal.fire(res.data._response.message, '', 'info')
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setError(e.message)
                    setLoading(false)
                })

        } else {
            setUnlistedCompErr("Company name required!")
        }
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }
    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
                    <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
                {children}
            </components.Control>
        )
    }

    return (
        <>
            <div tabIndex={2}>
                <Modal show={showD} onHide={handleClose} size="lg" centered>
                    <Modal.Header className="headerBlue" closeButton>
                        <Modal.Title className="mx-auto"><h5>DeMerger </h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                        ) : null}
                        <div className="mt-2 p-6">
                            <Form className="border border-1 rounded p-3" >
                                <Row className="form-set">
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                            <Select
                                                value={selEntity}
                                                onChange={handleEntity}
                                                options={Entity}
                                                name="subAccountId"
                                                isOptionDisabled={(option) => option.disabled === 0}
                                            />
                                            {formErr ? (
                                                <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                    {formErr.selEntity}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        :
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                            <Select
                                                value={selEntity}
                                                onChange={handleEntity}
                                                options={Entity}
                                                name="subAccountId"
                                                isDisabled="required"
                                            />
                                            {formErr ? (
                                                <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                    {formErr.selEntity}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    }
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4" >
                                            <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                            <Select
                                                value={selBroker}
                                                onChange={handleOption}
                                                options={BrokerList}
                                                name="brokerId"
                                            />
                                            {formErr ? (
                                                <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                    {formErr.selBroker}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        :
                                        <Form.Group className="col-md-4" >
                                            <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                            <Select
                                                value={selBroker}
                                                onChange={handleOption}
                                                options={BrokerList}
                                                name="brokerId"
                                                isDisabled="required"
                                            />
                                            {formErr ? (
                                                <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                                    {formErr.selBroker}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    }
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                value={selScrip}
                                                onChange={handleInput}
                                                options={companyList}
                                                name="companyId"
                                                isOptionDisabled={handelDisableDD}
                                                styles={{
                                                    option: (data) => ({
                                                        ...data,
                                                        margin: 0,
                                                        padding: '3px 12px',
                                                    }),
                                                    groupHeading: (base) => ({
                                                        ...base,
                                                        flex: '1 1',
                                                        color: '#000',
                                                        margin: 0,
                                                        padding: '7px 2px',
                                                        // border: `2px solid `,
                                                        background: "#8bc3ff",
                                                        fontSize: 'small',
                                                        fontWeight: 500
                                                    }),
                                                }}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.selScrip}
                                                </div>
                                            ) : null}
                                        </Form.Group> :
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                value={selScrip}
                                                onChange={handleInput}
                                                options={companyList}
                                                name="companyId"
                                                isDisabled="required"
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.selScrip}
                                                </div>
                                            ) : null}
                                        </Form.Group>}
                                </Row>
                                <Row className="form-set">
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4" >
                                            <Form.Label>Total Quantity before demerger</Form.Label>
                                            <Form.Control type="text" name="BalancQnty" min="0"
                                                value={NumberOnlySeperator(balancQnty)} disabled />
                                        </Form.Group> :
                                        null
                                    }
                                    <Form.Group className="col-md-4" >
                                        <Form.Label>Total Cost before demerger</Form.Label>
                                        <Form.Control type="text" name="BalancQnty" min="0"
                                            value={NumberSeperator(remainingcost)} disabled />
                                    </Form.Group>

                                    <Form.Group className="col-md-4" >
                                        <Form.Label> Ratio </Form.Label>
                                        <Form.Control type="text" name="ratio" min="0" autoComplete="off" placeholder="Enter Ratio" className="required-form-field"
                                            value={ratio.ratio} onChange={handleRatio} />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.ratio}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <Row className="form-set">
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Demerger Company 1<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                components={{ Control }}
                                                value={seldeCompOne}
                                                onChange={handleDcompOne}
                                                options={allcompanyList}
                                                name="deMergerCompanyOne"
                                                // isOptionDisabled={handelDisableDD}
                                                styles={{
                                                    option: (data) => ({
                                                        ...data,
                                                        margin: 0,
                                                        padding: '3px 12px',
                                                    }),
                                                    groupHeading: (base) => ({
                                                        ...base,
                                                        flex: '1 1',
                                                        color: '#000',
                                                        margin: 0,
                                                        padding: '7px 2px',
                                                        // border: `2px solid `,
                                                        background: "#8bc3ff",
                                                        fontSize: 'small',
                                                        fontWeight: 500
                                                    }),
                                                }}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.seldeCompOne}
                                                </div>
                                            ) : null}
                                        </Form.Group> :
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Demerger Company 1<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                value={seldeCompOne}
                                                onChange={handleDcompOne}
                                                options={allcompanyList}
                                                name="deMergerCompanyOne"
                                                isDisabled="required"
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.seldeCompOne}
                                                </div>
                                            ) : null}
                                        </Form.Group>

                                    }
                                    {stockHeaderId === 0 || stockHeaderId === undefined ?
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Demerger Company 2<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                components={{ Control }}
                                                value={seldeCompTwo}
                                                onChange={handleDcompTwo}
                                                options={allcompanyList}
                                                name="deMergerCompanyTwo"
                                                // isOptionDisabled={handelDisableDD}
                                                styles={{
                                                    option: (data) => ({
                                                        ...data,
                                                        margin: 0,
                                                        padding: '3px 12px',
                                                    }),
                                                    groupHeading: (base) => ({
                                                        ...base,
                                                        flex: '1 1',
                                                        color: '#000',
                                                        margin: 0,
                                                        padding: '7px 2px',
                                                        // border: `2px solid `,
                                                        background: "#8bc3ff",
                                                        fontSize: 'small',
                                                        fontWeight: 500
                                                    }),
                                                }}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.seldeCompTwo}
                                                </div>
                                            ) : null}
                                        </Form.Group> :
                                        <Form.Group className="col-md-4">
                                            <Form.Label>Demerger Company 2<span className="text-danger h6">*</span></Form.Label>

                                            <Select
                                                value={seldeCompTwo}
                                                onChange={handleDcompTwo}
                                                options={allcompanyList}
                                                name="deMergerCompanyTwo"
                                                isDisabled="required"
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.seldeCompTwo}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    }
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Date of Demerger<span className="text-danger h6">*</span></Form.Label>
                                        <DatePicker
                                            className="form-control required-form-field"
                                            placeholderText="Select start Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            // maxDate={new Date()}
                                            minDate={new Date(buyDate)}
                                            onChange={handleDate}
                                            dateFormat="dd-MM-yyyy"
                                            name="date"
                                            autoComplete="off"
                                            selected={pDate}

                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.date}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <Row className="form-set">
                                    <Form.Group className="col-md-3" >
                                        <Form.Label>Shares Company 1 <span className="text-danger h6">*</span></Form.Label>
                                        {/* <Form.Control type="Number" name="dQuantityOne" min="0" placeholder="Enter Shares for Company 1"
                                            onChange={handleQuantity} value={Math.round(quantity.dQuantityOne)} /> */}
                                        <Cleave
                                            placeholder="Enter Shares for Company 1"
                                            className='form-control'
                                            autoComplete="off"
                                            name="dQuantityOne"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={(quantity.dQuantityOne)}
                                            onChange={handleQuantity}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.dQuantityOne}
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="col-md-3" >
                                        <Form.Label>Investment Company 1 <span className="text-danger h6">*</span></Form.Label>
                                        {/* <Form.Control type="Number" name="dTotalCostOne" min="0" placeholder="Enter amount"
                                            value={totalCost.dTotalCostOne} onChange={handleTotalCost} /> */}
                                        <Cleave
                                            placeholder="Enter amount"
                                            className='form-control'
                                            name="dTotalCostOne"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={totalCost.dTotalCostOne}
                                            onChange={handleTotalCost}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.dTotalCostOne}
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="col-md-3" >
                                        <Form.Label>Shares Company 2 <span className="text-danger h6">*</span></Form.Label>
                                        {/* <Form.Control type="Number" name="dQuantityTwo" min="0" placeholder="Enter Shares for Company 2"
                                            onChange={handleQuantity} value={Math.round(quantity.dQuantityTwo)} /> */}
                                        <Cleave
                                            placeholder="Enter Shares for Company 2"
                                            className='form-control'
                                            name="dQuantityTwo"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={(quantity.dQuantityTwo)}
                                            onChange={handleQuantity}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.dQuantityTwo}
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="col-md-3" >
                                        <Form.Label>Investment Company 2 <span className="text-danger h6">*</span></Form.Label>
                                        {/* <Form.Control type="Number" name="dTotalCostTwo" min="0" placeholder="Enter amount"
                                            value={totalCost.dTotalCostTwo} onChange={handleTotalCost} /> */}
                                        <Cleave
                                            placeholder="Enter amount"
                                            className='form-control'
                                            name="dTotalCostTwo"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={totalCost.dTotalCostTwo}
                                            onChange={handleTotalCost}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.dTotalCostTwo}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col md="12" className="text-center mt-3">
                                        {stockHeaderId === 0 || stockHeaderId === undefined ? (

                                            <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }}
                                                disabled={!disableButton()}
                                            >
                                                Save
                                            </Button>
                                        ) : (
                                            <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }}
                                                disabled={!disableButton()}>
                                                Update
                                            </Button>
                                        )}
                                        {stockHeaderId === 0 || stockHeaderId === undefined ?
                                            <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>
                                                clear
                                            </Button> : null}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            <>
                <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Unlisted Scrip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Company Name</label>
                                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp.companyName} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal>

            </>
            {showDemergerPreview === true ?
                <div>
                    <Modal show={showDemergerPreview} onHide={() => handleClose(1)}>
                        <Modal.Header className="headerBlue" closeButton>
                            <Modal.Title className="mx-auto"><h5>Demerger Details</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                                <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                    <div className="transactionDataValue">{selEntity.label}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                    <div className="transactionDataValue">{selBroker.label}</div></div>
                                <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                    <div className="transactionDataValue">{selScrip.label}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Total cost before demerger: </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(remainingcost)}</div></div>
                                <div className="transactionData"><div className="transactionDataTitle">Ratio : </div>
                                    <div className="transactionDataValue">{ratio.ratio == null ? "-" : ratio.ratio}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Demerger company 1 : </div>
                                    <div className="transactionDataValue">{seldeCompOne.label}</div></div>
                                <div className="transactionData"><div className="transactionDataTitle">Demerger company 2 : </div>
                                    <div className="transactionDataValue">{seldeCompTwo.label}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Date of Demerger : </div>
                                    <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
                                <div className="transactionData"><div className="transactionDataTitle">Shares Company 1 : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(quantity.dQuantityOne)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Investment Company 1 : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost.dTotalCostOne)}</div></div>
                                <div className="transactionData"><div className="transactionDataTitle">Shares Company 2 : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(quantity.dQuantityTwo)}</div></div>
                                <div className="transactionData odd"><div className="transactionDataTitle">Investment Company 2 : </div>
                                    <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCost.dTotalCostTwo)}</div></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                : null}
        </>
    )
}

export default Demerger;