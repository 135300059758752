/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Loader from "../loadingAnimation/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Cleave from 'cleave.js/react';

import { PostLoansInterest_Insta, UpdateLoansInterest_Insta, GetLoansInterestInstallmentRepayinfo, DeleteLoansRepay_Interest_Insta } from '../../pages/Loans_Deposits/Loans_DepoAPI'

const LoansInterest = (props) => {
    const { showLoanI, hideLoanI, LoanId, InvDate, onRefreshFD, Iname, disableCheck } = props;
    const [date, setDate] = useState(null);
    const [interestAmt, setInterestAmt] = useState(null);

    const [tds, settds] = useState(null);
    const [netamt, setnetamt] = useState(null);
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idata, setiData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [InterestId, setInterestId] = useState(null);
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;


    
    useEffect(() => {
        if (interestAmt >= 0 && tds >= 0) {
            handleAmount()
        } else {
            setnetamt(0)
        }
    }, [interestAmt, tds]);

    useEffect(() => {
        if (LoanId != null && showLoanI === true) {
            getInterestDataList();
        }
    }, [LoanId, showLoanI]);


    const handleAmount = () => {
        // if (interestAmt || tds) {
        const total = Number(interestAmt).toFixed(2) - Number(tds).toFixed(2);
        setnetamt(total.toFixed(2));
        // } else {
        //     const total = Number(interestAmt)
        //     setnetamt(total);
        // }
    }
    // validation
    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (date === "" || date == null) {
            formErr["date"] = "Please fill date!";
            isValid = false;
        }
        if (interestAmt === "" || interestAmt == null) {
            formErr["interestAmt"] = "Please enter interest amount!";
            isValid = false;
        }

        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const getInterestDataList = () => {
        setLoading(true)
        GetLoansInterestInstallmentRepayinfo(LoanId, "Interest")
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.intInsMatDetails
                        setiData(result);
                    }
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, "error in get fix income data");
            })
    }
    const handleSubmit = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let data = {
                transactStockType: "Interest",
                loanId: LoanId,
                interest: Number(interestAmt ? interestAmt : 0),
                tds: Number(tds ? tds : 0),
                amtCredited: Number(netamt),
                SaveDate: fixTimezoneOffset(date),
                // dateInvestment: fixTimezoneOffset(InvDate),
            };
            PostLoansInterest_Insta(data).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("Interest Added Successfully!", "", "success");
                        getInterestDataList()
                        handleReset();
                        // handleCloseModal();
                        disableCheck(1);
                        onRefreshFD();
                    } else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const handleReset = () => {
        setDate(null);
        setInterestAmt("");
        settds("");
        setUpdate(false)
        // setnetamt(null);
    }
    const handleCloseModal = () => {
        handleReset();
        hideLoanI();
        setUpdate(false)
    }
    const disableButton = () => {
        return date && interestAmt
    }

    // onChange handler 
    const handleDate = (selected) => {
        setDate(selected);
        setFormErr({ ...formErr, date: "" })
    }
    const handleInterestAmt = (e) => {
        const { rawValue } = e.target;
        setInterestAmt(rawValue);
        setFormErr({ interestAmt: "" })
    }

    const handleTDS = (e) => {
        const { rawValue } = e.target;
        settds(rawValue);
        setFormErr({ tds: "" });
    }
    // handle single delete
    const deleterow = (selectedRow, a) => {
        setLoading(true)
        DeleteLoansRepay_Interest_Insta(a.loanSellId).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    let del = idata.filter(i => i.loanSellId !== a.loanSellId);
                    setiData(del)
                    setiData((state) => {
                        if (state.length <= 0) disableCheck(0);
                        return state;
                    });
                    // getInterestDataList();
                    Swal.fire("Interest Deleted Successfully!", "", "success");
                } else {
                    Swal.fire(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                console.log(error, " : error in delete interest");
            })
    };
    // delete multiple
    const deleteMultiple = (e) => {
        setLoading(true)
        let a = JSON.stringify(selected)
        let interstpara = a.replace(/[\[\]']+/g, '');
        let ids = interstpara.replace(/,/g, '&&LoanSellId=')
        DeleteLoansRepay_Interest_Insta(ids).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    // getInterestDataList();
                    handleCloseModal();
                    setSelected([])
                    disableCheck(0);
                    Swal.fire("Interests Deleted Successfully!", "", "success");
                } else {
                    Swal.fire(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                setLoading(false)
                console.log(error, " : error in delete multiple");
            })
    };

    // handle multiple delete
    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = idata.map((n, index) => {
                if (index !== 0) {
                    return n.loanSellId;
                } else {
                    return 0;
                }
            });
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // handle update
    const handleUpdate = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let fdata = {
                transactStockType: "Interest",
                loanId: LoanId,
                loanSellId: InterestId,
                interest: Number(interestAmt ? interestAmt : 0),
                tds: Number(tds ? tds : 0),
                amtCredited: Number(netamt),
                SaveDate: fixTimezoneOffset(date),
                // dateInvestment: fixTimezoneOffset(InvDate),
            };
            UpdateLoansInterest_Insta(fdata).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        // handleCloseModal();
                        getInterestDataList();
                        handleReset();
                        onRefreshFD();
                        Swal.fire("Interest Updated Successfully!", "", "success");
                    } else {
                        Swal.fire(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const updateinterest = (selectedRow, a) => {
        setUpdate(true)
        setDate(new Date(a.saveDate));
        setInterestAmt(a.interest);
        settds(a.tds);
        setnetamt(a.amtCredited);
        setInterestId(a.loanSellId)
    }
    return (
        <>
            <Modal isOpen={showLoanI} toggle={() => handleCloseModal()} size="lg" backdrop="static" centered>
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>{Iname}</h5>
                </ModalHeader>
                <ModalBody >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Interest Credited On<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select date"
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={handleDate}
                                        popperPlacement="top-end"
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        minDate={new Date(InvDate)}
                                        selected={date}
                                    // maxDate={new Date()}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Amount of Interest<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder="Enter Amount of Interest"
                                        name="interestAmt"
                                        min="0"
                                        value={interestAmt}
                                        onChange={handleInterestAmt}
                                    /> */}

                                    <Cleave
                                        placeholder="Enter Amount of Interest"
                                        className='form-control'
                                        name="interestAmt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={interestAmt}
                                        onChange={handleInterestAmt}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.interestAmt}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <Form.Label>TDS</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder='Enter TDS'
                                        name="tds"
                                        min="0"
                                        value={tds}
                                        onChange={handleTDS}
                                    /> */}

                                    <Cleave
                                        placeholder='Enter TDS'
                                        className='form-control'
                                        name="tds"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={tds}
                                        onChange={handleTDS}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.tds}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Net Amount Credited</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="netamt"
                                        min="0"
                                        value={NumberSeperator(netamt)}
                                        // onChange={handleQuantity}
                                        disabled
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="netamt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={netamt}
                                        disabled
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.netamt}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {update === false ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                            disabled={!disableButton()}>Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                        >Update</Button>}

                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div><br />
                    {/* table design */}
                    <TableContainer component={Paper}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }),
                            }}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length - 1} selected
                                </Typography>
                            ) : null}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <DeleteIcon onClick={(e) => deleteMultiple(e)} />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Toolbar>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>

                            <TableHead>
                                <TableRow>
                                    <TableCell width="8%">
                                        <Checkbox
                                            style={{ float: "left", marginLeft: "14px" }}
                                            color="primary"
                                            onClick={handleSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < idata.length}
                                            checked={idata.length > 0 && selected.length === idata.length ? "checked" : null}
                                            // onChange={onSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center" width="20%">Interest Credited On</TableCell>
                                    <TableCell align="center" width="20%">Amount of Interest</TableCell>
                                    <TableCell align="center" width="15%">TDS</TableCell>
                                    <TableCell align="center" width="18%">Net Amount Credited</TableCell>
                                    <TableCell align="center" width="10%">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idata && idata.map((index, i) => {
                                    const isItemSelected = isSelected(index.loanSellId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={idata.loanSellId}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disabled={i === 0 ? true : false}
                                                    style={{ marginLeft: "1rem" }}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, index.loanSellId)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{moment(index.saveDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.interest)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.tds)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.amtCredited)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton disabled={i === 0 ? true : false} >
                                                        <Edit fontSize="small" onClick={(e) => updateinterest(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton disabled={i === 0 ? true : false}>
                                                        <DeleteIcon fontSize="small" onClick={(e) => deleterow(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </Modal>
        </>
    )
}

export default LoansInterest