import React from "react";

import { Navigate, Outlet } from 'react-router-dom'

import Auth from '../../modules/Auth/index'


const TwoFAPrivateRoute = () => {
    const token = Auth.getToken();
    const userRole = Auth.getUserRoleID();

    return token  && userRole === 1 ? <Outlet/>:<Navigate to="/NotFound"/>

}



export default TwoFAPrivateRoute;