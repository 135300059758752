/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Button, FormControl, TableFooter, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TableCell, TableContainer, IconButton, Table, Paper, TableHead, TableRow, TableBody,
} from "@material-ui/core";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility, Delete } from "@material-ui/icons";
import { Stack } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from "@mui/icons-material/Add";
import $ from "jquery";
import moment from "moment";
import swal from 'sweetalert';
import _ from "lodash";
import Loader from "../loadingAnimation/index";
import Auth from '../../modules/Auth'
import { EntityList } from '../../pages/Stocks/StocksAPI';
import BorrowingDetils from '../BorrowingsDetails/BorrowingsDetails';
import BorrowingsPreview from '../BorrowingsDetails/BorrowingsPreview';
import { FetchBorrowingsData, EntityWiseBorrowingsList, GetBuySellBorrowingsGrid, GetBorrowTransaction, GetBuySellGridBorrowingsEntity, DeleteBorrowTransaction } from '../../pages/Borrowings/BorrowingsAPI'

import SearchBar from "material-ui-search-bar";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

function Borrowings() {
  const [loading, setLoading] = useState(false);
  const [showBorrowings, setShowBorrowings] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [entityId, setEntityId] = useState(null);
  const [script, setScript] = useState("All");
  const [BorrowingsHeaderId, setBorrowingsHeaderId] = useState(0);
  const [BorrowingsTransaction, setBorrowingsTransaction] = useState({});
  const [subNameEnt, setSubNameEnt] = useState("");
  const [entityData, setEntityData] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [iconsTest, setIconTest] = useState(AddIcon);
  const [subId, setSubId] = useState(null);
  const [secDetailID, setSecDetailID] = useState(null);
  const [depositToName, setDepositToName] = useState('');
  const [instTYID, setinstTYID] = useState([]);
  const [subName, setSubName] = useState("");
  const [sell, setSell] = useState([]);
  const [entitySell, setEntitySell] = useState([]);
  const [gridDetail, setGridDetail] = useState({});
  const [showInner, setShowInner] = useState(false);
  const [livePrice, setLivePrice] = useState(null);
  const [BorrowingsData, setBorrowingsData] = useState([]);
  const [previewBorrowingsOpen, setPreviewBorrowingsOpen] = useState(false);
  const userID = Auth.getsubcriberId();

  const [searched, setSearched] = useState("");
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
      if(state){
          let subAccountId = state.subAccountId;
          let instrumentTypeId = state.instrumentTypeId;
          let sec_detail_Id = state.sec_detail_Id;
          let depositToName = state.depositToName;
          let entityName = state.entityName;
          setSubNameEnt(entityName)
          getBuyGetData(subAccountId,instrumentTypeId,sec_detail_Id,depositToName,entityName)
      }
  }, [state])


  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN').format(input)
  }

  useEffect(() => {

    setLoading(true);
    getBorrowingsData()

    if (userID) {
      getEntityList()

    }
  }, []);

  const columns = [
    { title: "Security", field: "securityName" },

    {
      title: "Amount of Loan Taken",
      field: "amtOfLoan",
      type: "numeric",
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.amtOfLoan)}
        </span>
      ),
    },

    {
      title: "Total EMI's of Loans",
      field: "totalEmis",
      type: "numeric",
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.totalEmis)}
        </span>
      ),
    },
    {
      title: "Outstanding Balance",
      field: "outStandingAmt",
      type: "numeric",
      render: (rowData) => (
        <span>

          {NumberSeperator(rowData.outStandingAmt)}
        </span>
      ),
    },

  ];

  //all borrowing family data
  const getBorrowingsData = () => {
    FetchBorrowingsData().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          let Borrowings = res.data._response.data.borrowingsFamilyOuterGrid;
          //setBorrowingsData(Loans);
          setBorrowingsData(Borrowings)
          GetGridData(Borrowings);
          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });
        } else {
          setFilteredData([])
          setBorrowingsData([])
          console.log('error in getLoansData api', res.data._response.message);
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  //filter family grid
  const GetGridData = (borrowingData) => {
    let check = [];
    borrowingData.forEach((item, i) => {
      if (check.indexOf(item.securityName) === -1) {
        check.push(item.securityName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amtOfLoan: 0,
        totalEmis: 0,
        outStandingAmt: 0
      };

      borrowingData.forEach((item, i) => {
        if (checkdata === item.securityName) {
          let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
          let totalEmis = mainData.totalEmis + item.totalEmis;
          let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt;
          mainData = {
            amtOfLoan: amtOfLoan,
            totalEmis: totalEmis,
            outStandingAmt: outStandingAmt,
            securityName: item.securityName,
            id: index
          };
          let normalData = {
            ...item,
            securityName: item.subAccountName,
            maturityValue: item.cost === 0 ? 0 : item.maturityValue,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,
          };
          childData.push(normalData);
        }
      })
      parentData.push(mainData);
    })
    setFilteredData([...parentData, ...childData]);
  };

  // all borrowings  entity wise data 
  const getLoanEntityData = async (entityId) => {
    await EntityWiseBorrowingsList(entityId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            //+ button code
            $(".MuiTableRow-root").each(function () {
              let val = $('.material-icons ', this).text()
              if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
            });

            let EntityStock = res.data._response.data.fetchBorrowingsIndividualOuterGrid;
            setEntityData(EntityStock)
            setLoading(false)
            GetEntityGridData(EntityStock)
            setScript("All")
            setSearched("")
          } else {
            setFilteredEntiryData([]);
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log(e, "error");
        setLoading(false)
      });
  }

  //filter entity grid
  const GetEntityGridData = (EntityStock) => {
    //    console.log(EntityStock,`filter entity grid`)
    let check = [];
    EntityStock.forEach((item, i) => {
      if (check.indexOf(item.instrumentTypeName) === -1) {
        check.push(item.instrumentTypeName);
      }
    });

    let parentData = [];
    let childData = [];

    check.forEach((checkdata, index) => {
      let mainData = {
        amtOfLoan: 0,
        totalEmis: 0,
        outStandingAmt: 0
      };

      EntityStock.forEach((item, i) => {
        if (checkdata === item.instrumentTypeName) {

          let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
          let totalEmis = mainData.totalEmis + item.totalEmis;
          let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt

          mainData = {
            amtOfLoan: amtOfLoan,
            totalEmis: totalEmis,
            outStandingAmt: outStandingAmt,
            securityName: item.instrumentTypeName,
            id: index
          };

          let normalData = {
            ...item,
            securityName: item.securityDetailName,
            outStandingAmt: item.cost === 0 ? 0 : item.outStandingAmt,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,
          };

          childData.push(normalData);

        }
      });
      parentData.push(mainData);
    });

    setFilteredEntiryData([...parentData, ...childData]);
  };

  //getEntityList
  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            setEntityRadio(entity);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //handle snapshot
  const handleView = (selectedRow, a) => {
    //  console.log(a,"list");
    GetBorrowTransaction(a.borrowingId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setBorrowingsHeaderId(a.borrowingId);
          const result = res.data._response.data.borrowingsTransactionDetails;
          setBorrowingsTransaction(result);
          setPreviewBorrowingsOpen(true)
        }
      }
    });

  }

  const handleDelete = (selectedRow, a) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setLoading(true)
          DeleteBorrowTransaction(a.borrowingId).then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                setLoading(false)
                swal(res.data._response.message, {
                  title: "Deleted",
                  icon: "success",
                });
                const del = sell.filter(sells => a.borrowingId !== sells.borrowingId)
                setSell(del)
                const delEntity = entitySell.filter(Esells => a.borrowingId !== Esells.borrowingId)
                setEntitySell(delEntity)
                // getLoansData();
                // getLoanEntityData(subId)
                handleCloseInner()
              }
              else {

                setLoading(false)
              }
            }
          })
            .catch(err => {
              console.log(err);
              setLoading(false)
            })
        } else {
          swal("Cancelled", "Your  data is safe ", "error");
        }
      });
  }
  const onRefreshBorrowings = () => {
    getBorrowingsData();
    getLoanEntityData(entityId)
    setLoading(true);
  }

  const onTransactionGridRefresh = () => {
    getBuyGetData(
      subId,
      instTYID,
      secDetailID,
      depositToName,
      subName
      // livePrice,
    );
  }

  //open BorrowingDetails Modal
  const openTransaction = async () => {
    // await setEntity('Family');
    setShowBorrowings(true)
  };

  // buysell Inner GRID
  const getBuyGetData = async (
    subAccountId,
    instrumentTypeId,
    sec_detail_Id,
    depositToName,
    entityName,
    index
  ) => {

    setSubId(subAccountId);
    setSecDetailID(sec_detail_Id);
    setDepositToName(depositToName);
    setinstTYID(instrumentTypeId);
    setSubName(entityName)
    setLoading(true);
    // family

    if (subAccountId && instrumentTypeId && state === null) {
      await GetBuySellBorrowingsGrid(subAccountId, instrumentTypeId)
        .then((res) => {
          setLoading(false);
          if (res.data._response.status === 1) {
            let data = res.data._response.data.fetchBorrowingsDepositsEntityInfo;

            setSell(data);


            setGridDetail({
              secDetailID: secDetailID,
              depositToName: depositToName,
              entityName,
              // finalQuantity: quantity.balanceQuantity,
              // balanceQuantity,
              // finalCost: buyAmt - sellAmt,
            });

            setShowInner(true);
            //+/- code
            // var arr = [];
            // $(".MuiTableRow-root").each(function () {
            //     let path = this.getAttribute("path");
            //     let a = /[,\/]/g;
            //     let b = a.test(path)
            //     if (b) {
            //         let c = path.charAt(0)
            //         if (!arr.includes(c)) {
            //             arr.push(c)
            //         }
            //     }
            // });
            // $(".MuiTableRow-root").each(function () {
            //     let path = this.getAttribute("path");
            //     if (arr.includes(path)) {
            //         let button = $("button", this);
            //         $(button).html("<span class='material-icons'>maximize</span>");
            //         $(button).css({
            //             transform: "rotate(180deg)",
            //         });
            //     }
            // });
          } else {
            setSell([]);
            setGridDetail({});
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      // entity
      let sub_id_data = subAccountId ? subAccountId : entityId
      if (sub_id_data && instrumentTypeId && sec_detail_Id) {
        await GetBuySellGridBorrowingsEntity(instrumentTypeId, sec_detail_Id, sub_id_data)
          .then(async (res) => {
            if (res.status === 200) {
              setLoading(false);
              if (res.data._response.status === 1) {
                let data = res.data._response.data.fetchBorrowingsDepositsEntityInfo;
                setEntitySell(data);
                setGridDetail({
                  sec_detail_Id: sec_detail_Id,
                  depositToName: depositToName,
                  entityName,

                });


                setShowInner(true);
              } else {
                setLoading(false);
                setEntitySell([]);
                setGridDetail({});
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          })
      }
    }
    setLoading(false);

  };
  //refresh form data
  const refreshForm = (a) => {
    if (a !== undefined || a !== null) {
      GetBorrowTransaction(a).then((res) => {
        // debugger
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            setBorrowingsHeaderId(a);
            const result = res.data._response.data.borrowingsTransactionDetails;
            setBorrowingsTransaction(result);
            //  setShowBorrowings(true);
          }
        }
      }).catch(e => {
        console.log(e, 'error in alertMyRow function');
        setLoading(false)
      });
    }
  }

  //open transactions
  const alertMyRow = (selectedRow, a) => {
    setLoading(true)
    GetBorrowTransaction(a.borrowingId).then((res) => {
      // debugger
      if (res.status === 200) {
        setLoading(false)
        if (res.data._response.status === 1) {
          setBorrowingsHeaderId(a.borrowingId);
          const result = res.data._response.data.borrowingsTransactionDetails;
          setBorrowingsTransaction(result);
          setShowBorrowings(true);
        }
      }
    }).catch(e => {
      console.log(e, 'error in alertMyRow function');
      setLoading(false)
    });
  };

  // + button code
  const PlusButton = () => {
    $(".MuiTableRow-root").each(function () {
      let val = $('.material-icons ', this).text()
      if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
    });
  }

  //filter stocks
  const handleScripData = (e) => {
    let selected = e.target.value;
    setScript(selected)
    setSearched("")
    // family data filter
    if (entity === "Family") {
      let sorted = selected === "All" ? BorrowingsData : BorrowingsData.filter((a) => a.instrumentTypeId === parseInt(selected))

      let check = [];
      sorted.forEach((item, i) => {
        if (check.indexOf(item.securityName) === -1) {
          check.push(item.securityName);
        }
      });
      let parentData = [];
      let childData = [];
      check.forEach((checkdata, index) => {
        let mainData = {
          amtOfLoan: 0,
          EmiAmt: 0,
          outStandingAmt: 0,
          name: "",
          closingBalance: 0,
          totalEmis: 0
        };
        sorted.forEach((item, i) => {
          if (checkdata === item.securityName) {

            let closingBalance = mainData.closingBalance + item.closingBalance;
            let currentValue = mainData.currentValue + item.currentValue;
            let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
            let EmiAmt = mainData.EmiAmt + item.EmiAmt;
            let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt;
            let totalEmis = mainData.totalEmis + item.totalEmis;
            mainData = {
              securityName: item.securityName,
              closingBalance: closingBalance,
              currentValue: currentValue,
              id: index,
              amtOfLoan: amtOfLoan,
              EmiAmt,
              totalEmis,
              outStandingAmt
            };

            let normalData = {
              ...item,
              securityName: item.subAccountName,
              parentId: index,
              id: index + 1,
              company: item.subAccountName,
            };
            childData.push(normalData);
          }
        });
        parentData.push(mainData);

        PlusButton()
      });
      setFilteredData([...parentData, ...childData]);
    } else {
      //entity data  filter
      let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.instrumentTypeId === parseInt(selected)))
      let checkEntity = [];
      sortedEntity.forEach((item, i) => {
        if (checkEntity.indexOf(item.instrumentTypeName) === -1) {
          checkEntity.push(item.instrumentTypeName);
        }
      });
      let parentEntityData = [];
      let childEntityData = [];
      checkEntity.forEach((checkdata, index) => {
        let mainData = {
          amtOfLoan: 0,
          EmiAmt: 0,
          quantity: 0,
          outStandingAmt: 0,
          name: "",
          closingBalance: 0,
          currentValue: 0,
          totalEmis: 0
        };
        sortedEntity.forEach((item, i) => {
          if (checkdata === item.instrumentTypeName) {
            let quantity = mainData.quantity + item.quantity;
            let closingBalance = mainData.closingBalance + item.closingBalance;
            let currentValue = mainData.currentValue + item.currentValue;
            let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
            let EmiAmt = mainData.EmiAmt + item.EmiAmt;
            let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt;
            let totalEmis = mainData.totalEmis + item.totalEmis;

            mainData = {
              quantity: quantity,
              securityName: item.instrumentTypeName,
              closingBalance: closingBalance,
              currentValue: currentValue,
              id: index,
              amtOfLoan: amtOfLoan,
              totalEmis,
              EmiAmt,
              outStandingAmt
            };

            let normalData = {
              ...item,
              securityName: item.securityDetailName,
              parentId: index,
              id: index + 1,
              company: item.SecurityName,
            };
            childEntityData.push(normalData);
          }
        });
        parentEntityData.push(mainData);
      });
      setFilteredEntiryData([...parentEntityData, ...childEntityData]);
      PlusButton()
    }
  }

  //details panel icon parent child
  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };
  const handleReset = () => {
    setSecDetailID(null);
    setDepositToName(null);
    setSubId(null);
    setLivePrice(null)
  }

  // borowings details modal close
  const handleClose = async () => {
    setShowBorrowings(false)
    setBorrowingsTransaction({});
    setBorrowingsHeaderId(0);
    // await setEntity('Family');
    // await setEntityId(null);
    await onRefreshBorrowings();
    // onTransactionGridRefresh();
  }

  const handleClosePreview = () => {
    setPreviewBorrowingsOpen(false)
  }

  const handleCloseInner = async () => {
    // await setEntity('Family');
    await onRefreshBorrowings()
    setSubName("")
    // setSubNameEnt("")
    handleReset()
    setShowInner(false)
    setLoading(false)
  }
  const handleChangeEntity = async (e) => {
    // setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      setEntityId(null);

      setScript("All");
      setSearched("")
      //+ button code
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        let button = $("button", this);
        $(button).html("<span class='material-icons'>add</span>");
      });
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName == e.target.value;
      });
      await setSubNameEnt(e.target.value);
      await setEntityId(data.subAccountId);
      await getLoanEntityData(data.subAccountId)
    }
  }

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // search function
  const requestSearch = (searchedVal) => {

    setSearched(searchedVal)
    const filteredRows = BorrowingsData.filter((row) => {
      return row.securityName.includes(searchedVal.toUpperCase()) || row.securityName.includes(searchedVal.toLowerCase()) || row.securityName.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.securityName) === -1) {
        check.push(item.securityName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amtOfLoan: 0,
        totalEmis: 0,
        outStandingAmt: 0
      };

      filteredRows.forEach((item, i) => {
        if (checkdata === item.securityName) {
          let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
          let totalEmis = mainData.totalEmis + item.totalEmis;
          let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt;
          mainData = {
            amtOfLoan: amtOfLoan,
            totalEmis: totalEmis,
            outStandingAmt: outStandingAmt,
            securityName: item.securityName,
            id: index
          };
          let normalData = {
            ...item,
            securityName: item.subAccountName,
            maturityValue: item.cost === 0 ? 0 : item.maturityValue,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,
          };
          childData.push(normalData);
        }
      })
      parentData.push(mainData);
    })
    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    let checkEntity = [];
    forentity.forEach((item, i) => {
      if (checkEntity.indexOf(item.instrumentTypeName) === -1) {
        checkEntity.push(item.instrumentTypeName);
      }
    });

    let parentDataEntity = [];
    let childDataEntity = [];

    checkEntity.forEach((checkdata, index) => {
      let mainData = {
        amtOfLoan: 0,
        totalEmis: 0,
        outStandingAmt: 0
      };

      forentity.forEach((item, i) => {
        if (checkdata === item.instrumentTypeName) {

          let amtOfLoan = mainData.amtOfLoan + item.amtOfLoan;
          let totalEmis = mainData.totalEmis + item.totalEmis;
          let outStandingAmt = mainData.outStandingAmt + item.outStandingAmt

          mainData = {
            amtOfLoan: amtOfLoan,
            totalEmis: totalEmis,
            outStandingAmt: outStandingAmt,
            securityName: item.securityName,
            id: index
          };

          let normalData = {
            ...item,
            securityName: item.securityName,
            outStandingAmt: item.cost === 0 ? 0 : item.outStandingAmt,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,
          };

          childDataEntity.push(normalData);

        }
      });
      parentDataEntity.push(mainData);
    });

    setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      getLoanEntityData(entityId)
    } else {
      getBorrowingsData()
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="text-center py-3">Borrowings Folio</h4>
          </Col>
        </Row>
        <FormControl className='mt-4'>
          <RadioGroup
            row
            color="primary"
            defaultValue="start"
            aria-label="Entity"
            name="row-radio-buttons-group"
            value={entity}
            onChange={handleChangeEntity}
            style={{ marginTop: "-3rem" }}
          >
            <FormControlLabel
              value="Family"
              control={<Radio />}
              label="Family"
            />
            {entityRadio.map((a) => (
              <FormControlLabel
                key={a.subAccountName}
                value={a.subAccountName}
                control={<Radio />}
                label={a.subAccountName}
                disabled={a.active === 0 ? true : false}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Row >
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="flex-end"
            alignItems="flex-start" mb={3} >
            {entityId == null ? null :
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openTransaction()}
                  style={{ height: "2rem" }}
                >
                  Transactions
                </Button>
              </>

            }
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={script}
              label="Type"
              onChange={handleScripData}
              style={{ minWidth: 120, paddingLeft: "12px" }}

            >
              <MenuItem disabled >
                <em>Borrowings Type</em>
              </MenuItem>
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"36"}>Secured Loan Personal </MenuItem>
              <MenuItem value={"37"}>Unsecured Loan Personal </MenuItem>
              <MenuItem value={"38"}>Home Loan</MenuItem>
              <MenuItem value={"39"}>
                Vehicle Loan
              </MenuItem>
              <MenuItem value={"40"}>
                Education Loan
              </MenuItem>

            </Select>
          </Stack>
        </Row>
        <Row>
          <Box sx={{ flexGrow: 1 }} mb={2}>
            <Grid container spacing={2} columns={16}
              direction="row"
              justifyContent="end"
              alignItems="flex-end">
              <Grid item xs="auto">
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => { requestSearch(searchVal) }}
                  onCancelSearch={() => cancelSearch()}
                  style={{
                    // margin: "0 auto",
                    maxWidth: 250,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Row>
        <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">
          {/* {loading ? (
                            <Loader />
                        ) : (
                            <> */}
          <MaterialTable
            title=""
            data={
              entity === "Family" ? filteredData : filteredEntiryData
            }
            columns={columns}

            parentChildData={(row, rows) =>
              rows.find((a) => a.id === row.parentId)
            }
            isLoading={loading}
            options={{
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
              },
              exportButton: false,
              search: false,
              toolbarButtonAlignment: "left",
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              emptyRowsWhenPaging: false,
              rowStyle: (rowData) => {
                return {
                  pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                  backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                };
              },
            }}

            onTreeExpandChange={(row, rows) => {
              handleIcon(row, rows);
            }}
            icons={{
              DetailPanel: iconsTest,
            }}
            components={{
              Body: (props) => {
                let totalData = {
                  amtOfLoan: 0,
                  outStandingAmt: 0,
                }
                props.renderData.forEach((rowData) => {
                  totalData.amtOfLoan += rowData.amtOfLoan;
                  totalData.outStandingAmt += rowData.outStandingAmt;
                })
                return (
                  <>
                    <MTableBody {...props} />
                    {
                      script === "All" ?
                        <TableFooter style={{ backgroundColor: "#D9E5F2 " }} >
                          <TableRow>
                            <TableCell ></TableCell>
                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={0} align="left">Total</TableCell>
                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={1} align="right">{NumberSeperator(totalData.amtOfLoan)}</TableCell>
                            <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={3} align="right">{NumberSeperator(totalData.outStandingAmt)}</TableCell>
                          </TableRow>
                        </TableFooter> : null
                    }
                  </>
                )
              },

              Toolbar: (props) => {
                let totalData = {
                  amtOfLoan: 0,
                  outStandingAmt: 0,
                }
                props.renderData.forEach((rowData) => {
                  totalData.amtOfLoan += rowData.amtOfLoan;
                  totalData.outStandingAmt += rowData.outStandingAmt;
                })
                return (

                  <>
                    <MTableToolbar {...props} />
                    {
                      script !== "All" || script === "All" ?


                        <div className="row mb-2 p-2">
                          <div className="col-md-2 col-xl-4">
                          </div>
                          <div className="col-md-2 col-xl-2">
                            <div className="card  order-card">
                              <div className="card-block">
                                <h3 className="text-right" style={{ fontSize: "1.5vw" }}>{NumberSeperator(totalData.amtOfLoan)}</h3>
                                <p className="m-b-0">Amount of Loan Taken</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-2 col-xl-2">
                            <div className="card order-card">
                              <div className="card-block">
                                <h3 className="text-right" style={totalData.outStandingAmt < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                  <span>{NumberSeperator(totalData.outStandingAmt)}</span></h3>
                                <p className="m-b-0" >Outstanding Balance</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null}

                  </>
                )

              },

            }}


            onRowClick={(evt, selectedRow) => {
              if (selectedRow.tableData.childRows == null) {
                getBuyGetData(
                  selectedRow.subAccountId,
                  selectedRow.instrumentTypeId,
                  selectedRow.securityDetailId,
                  selectedRow.depositWithLoanToName,
                  selectedRow.company,
                  selectedRow.tableData.path[0]
                );
              }
            }}

          />
          {/* </>
                        )} */}
        </div>
        <BorrowingDetils
          showBorrowings={showBorrowings}
          onHideBorrowings={handleClose}
          BorrowingsTransaction={BorrowingsTransaction}
          BorrowingsHeaderId={BorrowingsHeaderId}
          entityId={entityId}
          // showLoanpreview={previewLoanopen}
          onRefreshBorrowings={onRefreshBorrowings}
          onRefreshInnerGrid={onTransactionGridRefresh}
          onRefreshForm={(p) => refreshForm(p)}

        />

        <BorrowingsPreview showBorrowingsPreview={previewBorrowingsOpen} onHideBorrowingsPreview={handleClosePreview} BorrowingsTransactionPreview={BorrowingsTransaction} />
        <Container
          className="LoginContainer formWraper"
          component="main"
          maxWidth="xs"
          tabIndex={-1}
        >
          <Modal
            isOpen={showInner}
            fullscreen="xl"
            size="xl"
            className="col-12 stockdetailmodalI"
          >
            <ModalHeader charCode="Y" toggle={() => handleCloseInner()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
              <h5 className="text-center">Borrowings Details</h5>
            </ModalHeader>
            <ModalBody>

              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {loading ? (
                  <Loader />
                ) : null}

                {subId && state === null ?
                  /* family wise data */
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <p> Portfolio: {subName}</p>
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" width="4%">
                            Security
                          </TableCell>
                          <TableCell align="right" width="3%">
                            Lender
                          </TableCell>
                          <TableCell align="right" width="4%">
                            Amount Of Loan Taken
                          </TableCell>
                          <TableCell align="right" width="3%">
                            EMI
                          </TableCell>
                          <TableCell align="right" width="3%">
                            Account No.
                          </TableCell>
                          <TableCell align="right" width="3%">
                            Date Of Disbursement
                          </TableCell>
                          <TableCell align="right" width="3%">
                            Outstanding Amount
                          </TableCell>
                          <TableCell align="right" width="3%">
                            Date Of Last EMI
                          </TableCell>
                          <TableCell align="center" width="4%">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {sell.map((a) => (
                          <TableRow
                            key={a.borrowingId}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {a.securityName}
                            </TableCell>
                            <TableCell align="right">
                              {a.lender === null ? 0 : a.lender}
                            </TableCell>
                            <TableCell align="right" >
                              {(a.amtOfLoan) === null ? 0 : NumberSeperator(a.amtOfLoan)}
                            </TableCell>
                            <TableCell align="right">
                              {a.monthlyEmi === null ? 0 : NumberSeperator(a.monthlyEmi)}
                            </TableCell>
                            <TableCell align="right">
                              {a.folioNumber}
                            </TableCell>
                            <TableCell align="right">
                              {moment(a.dateOfDisbursement).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell align="right">
                              {NumberSeperator(a.outstandingAmt)}
                            </TableCell>
                            <TableCell align="right">
                              {moment(a.dateOfLastEmi).format("DD/MM/YYYY")}
                            </TableCell>

                            <TableCell align="center">
                            <Tooltip title="Preview">
                                <IconButton>
                                  <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                </IconButton>
                              </Tooltip>
                              
                              <Tooltip title="Edit">
                                <IconButton>
                                  <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete">
                                <IconButton>
                                  <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                </IconButton>
                              </Tooltip>
                              
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> :

                  /* entity wise data */
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <p> Portfolio: {subNameEnt}</p>
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right" width="11%">
                            Security
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Lender
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Amount Of Loan Taken
                          </TableCell>
                          <TableCell align="right" width="7%">
                            EMI
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Account No.
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Date Of Disbursement
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Outstanding Amount
                          </TableCell>
                          <TableCell align="right" width="7%">
                            Date Of Last EMI
                          </TableCell>
                          <TableCell align="center" width="7%">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {entitySell.map((a) => (
                          <TableRow
                            key={a.borrowingId}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {a.securityName}
                            </TableCell>
                            {/* <TableCell align="left">
                                {a.typeOfInvestment}
                              </TableCell> */}
                            <TableCell align="right">
                              {a.lender === null ? 0 : a.lender}
                            </TableCell>
                            <TableCell align="right" >
                              {a.amtOfLoan === null ? 0 :NumberSeperator(a.amtOfLoan)}
                            </TableCell>
                            <TableCell align="right">
                              {a.monthlyEmi === null ? 0 : NumberSeperator(a.monthlyEmi)}
                            </TableCell>
                            <TableCell align="right">
                              {a.folioNumber}
                            </TableCell>
                            <TableCell align="right">
                              {moment(a.dateOfDisbursement).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell align="right">
                              {a.outstandingAmt === null ? 0 : NumberSeperator(a.outstandingAmt)}
                            </TableCell>
                            <TableCell align="right">
                              {moment(a.dateOfLastEmi).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell align="center">
                            <Tooltip title="Preview">
                                <IconButton>
                                  <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Edit">
                                <IconButton>
                                  <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton>
                                  <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                </IconButton>
                              </Tooltip>
                             
                            </TableCell>

                          </TableRow>
                        ))}


                        <TableRow
                          // key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >


                        </TableRow>



                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Paper>
            </ModalBody>
          </Modal>
        </Container>

      </Container>
    </>
  )
}

export default Borrowings