/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import Loader from '../loadingAnimation/index';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from "../../assets/logo.png";
import ReCAPTCHA from "react-google-recaptcha";

import {

    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

import "./Loginstyle.css"

const theme = createTheme();

export default function LoginUser(props) {
    const TEST_RECAPTCHA_SITE_KEY = "6Ldj8MkgAAAAAFgrKReMDR9x9g8fG6AMsvrbgxOA";

    // Sign up states
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passErr, setpassErr] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [checkBoxErr, setCheckBoxErr] = useState('');

    // sign up onchange handlers
    const handleChangeLog = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            setEmail(value);
            setEmailErr("")
        }
        if (name === "pass") {
            setPass(value);
            setpassErr("")
        }
    }
    const handleClickShowPassword = () => {
        setPassword(!password);
    };
    const validation = () => {
        const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        // const passregex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/; 
        const emailErr = {};
        const passErr = {};
        const checkBoxErr = {};

        let isValid = true;
        if (email === "" || email == null) {
            emailErr.usernameerr = "Please enter username!";
            isValid = false;
        } else if (emailregex.test(email) === false) {
            emailErr.usernameerr = "please enter valid email"
            isValid = false;
        }
        if (pass === "" || pass == null || !pass) {
            passErr.passederr = "Please enter password!";
            isValid = false;
        } else if (passregex.test(pass) === false) {
            passErr.passederr = "Password must combination of Number,Capital letter, special character and min length is 8 !!";
            isValid = false;
        }
        setEmailErr(emailErr);
        setpassErr(passErr);
        setCheckBoxErr(checkBoxErr);

        return isValid;
    }
    const handleSubmitLogin = (e) => {
        e.preventDefault();
        // debugger
        const isValid = validation();
        if (isValid) {
            let body = {
                email: email,
                password: pass,
            };
            let headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            setLoading(true);
            // debugger
            axios.post(`http://5.189.157.40:10025/api/Subcriber/authenticate`, body, headers)
                // axios.post(`http://5.189.157.40:10025/api/Authorization/signin`, body, headers)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        const data = res.data._response;
                        // const data = res.data._response.data;
                        if (res.data._response.status === 1) {
                            // window.location.href = "/";
                            if (data.roleTypeName === 1) {
                                window.location.href = "/token";
                            } else if (data.isTwoFaenabled === true) {
                                window.location.href = "/Auth";
                            }
                            else {
                                window.location.href = "/Dashboard";
                            }
                            const userDetails = {
                                UserData: data.data,
                                emailId: data.email,
                                roleId: data.roleId,
                                // loginType: "1",
                                subcriberType: data.roleTypeName,
                                token: data.token,
                                twofactorEnabled: data.isTwoFaenabled,
                                subcriberId: data.id,
                                userId: data.userId,
                                userName: data.name,
                                twoFaAuth: false,
                                userRole: data.roleTypeName,
                                isPaidUser: data.roleId === 1 ? 1 : 0,
                                termsConditions: data.data.termsandCondition
                            }
                            const loginDetails = JSON.stringify(userDetails);
                            localStorage.setItem('user_data', loginDetails);
                            props.setUser(userDetails);
                            props.setIsTwoFAEnabled(data.isTwoFaenabled);

                        }
                        else {
                            setLoading(false);
                            Swal.fire(res.data._response.message, "", "info");
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response !== undefined) {
                        if (err.response.data._response.message === "Account not verified") {
                            Swal.fire(err.response.data._response.message, "Please verify your email first before login", "info");
                        } else {
                            Swal.fire(err.response.data._response.message, "", "info");
                        }
                    }
                    else {
                        console.log("err in registration", err)
                    }

                })
        }
    }
    const onForgotPass = () => {
        Swal.fire({
            title: 'Forgot Password',
            text: 'Enter Email',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                let valid = true;
                const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (login === '') {
                    Swal.showValidationMessage(`Please Enter Email`);
                    valid = false;
                } else {
                    if (!emailregex.test(login)) {
                        Swal.showValidationMessage(`Please Enter Valid Email`);
                        valid = false;
                    }
                }
                if (valid === true) {
                    let data = { email: login }
                    let headers = {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                    return axios.post('http://5.189.157.40:10025/api/Subcriber/forgot-password',
                        data, headers).then((res) => {
                            if (res.status === 200) {
                                if (res.data._response.status === 1) {
                                    return res.data._response.message
                                    // 
                                } else {
                                    throw new Error('Wrong details')
                                }
                            } else {
                                throw new Error('Wrong details')
                            }
                        })
                        .catch((e) => {
                            Swal.showValidationMessage(
                                `Request failed: Wrong details`
                            )
                            console.log(e, 'err in forgot password');
                        })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Forgot password link sent to your registered email', "", "success");
            }
        })
    }

    function onChange(value) {
        // console.log("Captcha value:", value);
        setBtnDisable(true);
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs" className='loginstyle mt-4'>
                {/* {loading ? <Loader /> : null} */}
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="sidebar__img">
                        <img src={logo} alt="logo" />
                        {/* <h1>Accompt Fintech</h1> */}
                    </div>
                    <h3 variant="h1" className='mt-1'>Sign in</h3>
                    <Box component="form" onSubmit={handleSubmitLogin} noValidate >
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        margin="normal"
                                        variant="outlined"
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete='email'
                                        onChange={handleChangeLog}
                                        value={email}
                                    />
                                    {Object.keys(emailErr).map((key) => {
                                        return <div style={{ fontSize: 12, color: "red" }}>{emailErr[key]}</div>
                                    })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>

                                    <InputLabel htmlFor="outlined-adornment-password" color="primary">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        variant="outlined"
                                        required
                                        onChange={handleChangeLog}
                                        value={pass}
                                        name='pass'
                                        color='primary'
                                        type={password ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {password ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {Object.keys(passErr).map((key) => {
                                        return <div style={{ fontSize: 12, color: "red" }}>{passErr[key]}</div>
                                    })}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <FormControl>
                                    <ReCAPTCHA
                                        sitekey={TEST_RECAPTCHA_SITE_KEY}
                                        type='image'
                                        onChange={onChange}
                                        onExpired={() => setBtnDisable(false)}
                                    />
                                    {/* <FormControlLabel
                                control={<Checkbox checked={isChecked} onChange={()=>setIsChecked(!isChecked)} color="primary" />}
                                label="I have read & accepted the T&C"
                            /> */}
                                    {Object.keys(checkBoxErr).map((key) => {
                                        return <div style={{ fontSize: 12, color: "red" }} key={key}>{checkBoxErr[key]}</div>
                                    })}
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* <Button
                            size="small"
                            variant="contained"
                            color={!btnDisable ? "secondary" : "primary"}
                            type="submit"
                            // fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!btnDisable}
                        >

                            Sign In
                        </Button> */}
                        <Box
                            m={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <Button
                                className='button-71'
                                size="medium"
                                variant="contained"
                                color={!btnDisable ? "secondary" : "primary"}
                                type="submit"
                                // fullWidth
                                sx={{ mt: 3, mb: 2, p: 2, width: 130 }}
                                disabled={!btnDisable}
                            >
                                {loading && <span style={{ marginRight: "6rem", position:'absolute'}}> <span className="spinner-border spinner-border-sm p-2 letter-spacing: 0.02857em;" role="status" aria-hidden="true" style={{ paddingLeft: 5 }}></span></span>}  Sign In

                            </Button>
                        </Box>

                        <Grid container>
                            <Grid item xs>
                                {/* <Link to="#" variant="body2">
                                    Forgot password?
                                </Link> */}
                                <Link class="text-decoration-none" onClick={onForgotPass} to="/">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link class="text-decoration-none" to="/TrialUserSign">
                                    {"Don't have an account? Sign Up"}
                                </Link>

                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}