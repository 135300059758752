import React from 'react'
import ArtFolio from '../../components/ArtFolio/Art_Folio'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { useSelector } from 'react-redux'
function Art () {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return(
        <div>
                {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <ArtFolio/>
                </div>
            </div>
        </div>
    )
}

export default Art