/* eslint-disable no-useless-escape */
/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import Loader from "../loadingAnimation/index";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Select from 'react-select'
import { payment } from '../../modules/helpers/constant'
import Cleave from 'cleave.js/react';

import { AddIntInsRepay, UpdateIntInsRepay, GetIntInsRepay, DeleteIntInsRepay, GetBorrowTransaction } from '../../pages/Borrowings/BorrowingsAPI'

const BorrowingsRepay = (props) => {
    const { showBorrowRepay, hideBorrowRepay, BorrowID, InterestPercentage } = props;
    const [date, setDate] = useState(null);
    const [interestAmt, setInterestAmt] = useState(0);
    const [principleAmt, setPrincipleAmt] = useState('');
    const [totalRepayment, setTotalRepayment] = useState('');
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idata, setiData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [RepayId, setRepayId] = useState(null);
    const [OutStandingamt, setOutstandingAmt] = useState(0);
    const [conOutamt, setconoutamt] = useState(0)
    const [selPayment, setSelPayment] = useState([]);
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;


    useEffect(() => {
        if (BorrowID != null && showBorrowRepay === true) {
            getRepayDataList();
            getOutstandingAmt();
        }
    }, [BorrowID, showBorrowRepay]);

    const handleclear = () => {
        setInterestAmt(0);
        setPrincipleAmt("");
        setTotalRepayment("");
    }

    //to check the length of decimal number
    Number.prototype.countDecimals = function () {
        if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
        return this.toString().split(".")[1].length || 0;
    }

    const handleAmount = ({ principle, totalAmt }) => {

        if (Number(totalAmt) && Number(principle)) {
            const total = Number(totalAmt) - Number(principle);
            let a = total.countDecimals();
            let b = a > 2 ? parseFloat(total).toFixed(2) : total;
            // let b = total;
            setInterestAmt(b);
        } else if (Number(totalAmt) > 0 && Number(principle) === 0) {
            setInterestAmt(Number(totalAmt))
        } else {
            setInterestAmt(0)
        }
        // if(Number(totalAmt) && Number())
    }
    const alertTotalRepayAmt = (totalAmt) => {
        if (Number(totalAmt) > OutStandingamt) {
            alert("Amount of Repayment cannot exceed Outstanding Amount Rs." + OutStandingamt);
            handleclear();
        }
    }

    const getOutstandingAmt = () => {
        setLoading(true);
        GetBorrowTransaction(BorrowID).then((res) => {
            if (res.data._response.status === 1) {
                let Oamount = res.data._response.data.borrowingsTransactionDetails.outstandingAmt;
                setOutstandingAmt(Oamount);
                setconoutamt(Oamount);
                setLoading(false)
            } else {
                setLoading(false)
                console.log("err in repay")
            }

        }).catch((err) => {
            setLoading(false)
            console.log("err in get out amt in repay", err)
        })
    }
    // validation 
    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (selPayment === "" || selPayment == null) {
            formErr["payment"] = "Please Select Payment Type!";
            isValid = false;
        }
        if (date === "" || date == null) {
            formErr["date"] = "Please fill date!";
            isValid = false;
        }

        // if (principleAmt == "" || principleAmt == null) { 
        //     formErr["principleAmt"] = "Please enter Principle Amt!";
        //     isValid = false;
        // }
        if (totalRepayment === "" || totalRepayment == null) {
            formErr["Total_Repayment"] = "Please enter Total_Repayment!";
            isValid = false;
        }
        if (selPayment && totalRepayment) {
            if (selPayment.value === "Closure" && totalRepayment < OutStandingamt) {
                formErr["Total_Repayment"] = `Please Enter Full Total_Repayment Value Rs.${OutStandingamt}!`;
                isValid = false;
            }
        }
        setFormErr(formErr);
        return isValid;
    };
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const getRepayDataList = () => {
        setLoading(true)
        GetIntInsRepay(BorrowID, "Repay")
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.intInsRepayDetails
                        setiData(result);
                    } else {
                        setiData([])
                    }
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, "error in get fix income data");
            })
    }
    const handleSubmit = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let data = {
                transactStockType: "Repay",
                borrowingId: BorrowID,
                paymentType: selPayment.value,
                principal: Number(principleAmt ? principleAmt : 0),
                interest: Number(interestAmt ? interestAmt : 0),
                totalAmount: Number(totalRepayment ? totalRepayment : 0),
                saveDate: fixTimezoneOffset(date),
            };
            AddIntInsRepay(data).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        swal("Added successfully", "", "success");
                        getRepayDataList();
                        handleReset();
                        // handleCloseModal();
                    } else {
                        swal(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const handleReset = () => {
        setDate(null);
        setInterestAmt(0);
        setPrincipleAmt("");
        setTotalRepayment("");
        setUpdate(false)
        setSelPayment([])
        setFormErr([])
        // setnetamt(null);
        getOutstandingAmt();
    }
    const handleCloseModal = () => {
        setOutstandingAmt(0);
        handleReset();
        hideBorrowRepay();
        setUpdate(false)
    }


    // onChange handler 
    const handleDate = (selected) => {
        setDate(selected);
        setFormErr({ ...formErr, date: "" })
    }

    const handlePrincipleAmt = (e) => {
        const { rawValue } = e.target;
        setPrincipleAmt(rawValue);
        setFormErr({ ...formErr, principleAmt: "" })

        if (BorrowID != null && showBorrowRepay === true) {
            handleAmount({ principle: rawValue, totalAmt: totalRepayment })
        }
    }
    const handleTotalRepayment = (e) => {
        const { rawValue } = e.target;
        setTotalRepayment(rawValue);
        setPrincipleAmt(rawValue);
        setInterestAmt(0);
        setFormErr({ ...formErr, Total_Repayment: "" });
        alertTotalRepayAmt(rawValue);
    }

    const handlePayment = (selected) => {
        setSelPayment(selected);
        setFormErr({ ...formErr, payment: "" })

    }
    const handleIntpaid = (e) => {
        const { rawValue } = e.target;
        let interest = Number(rawValue)
        const principle = Number(totalRepayment) - Number(rawValue);
        let val = principle.countDecimals();
        let val1 = val > 2 ? parseFloat(principle).toFixed(2) : principle;
        // let val1 =  principle;
        // let a = interest.countDecimals();
        // let b = a > 2 ? parseFloat(interest).toFixed(2) : interest;
        let b = interest;
        // let a = interest == 0 ? 0 : parseFloat(interest).toFixed(2);
        setPrincipleAmt(val1);
        setInterestAmt(b);
    }
    // handle single delete
    const deleterow = (selectedRow, a) => {

        setLoading(true)
        DeleteIntInsRepay(a.id).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                    let del = idata.filter(i => i.id !== a.id);
                    setiData(del)
                    // getInterestDataList();
                    getOutstandingAmt();
                    swal("Delete successfully!", "", "success");
                } else {
                    swal(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                console.log(error, " : error in delete interest");
            })
    };
    // delete multiple
    const deleteMultiple = (e) => {
        setLoading(true)
        let a = JSON.stringify(selected)
        let interstpara = a.replace(/[\[\]']+/g, '');
        let ids = interstpara.replace(/,/g, '&&Id=')
        DeleteIntInsRepay(ids).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (res.data._response.status === 1) {
                    // getInterestDataList();
                    handleCloseModal();
                    setSelected([])
                    swal("Delete successfully!", "", "success");
                } else {
                    swal(res.data._response.message, "", "info");
                }
            }
        })
            .catch((error) => {
                setLoading(false)
                console.log(error, " : error in delete multiple");
            })
    };

    // handle multiple delete
    const handleClick = (event, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = idata.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const disableButton = () => {
        return selPayment && date && totalRepayment
    }
    // handle update
    const handleUpdate = () => {
        const isValid = errHandel()
        if (isValid) {
            setLoading(true);
            let data = {
                transactStockType: "Repay",
                borrowingId: BorrowID,
                id: RepayId,
                paymentType: selPayment.value,
                principal: Number(principleAmt ? principleAmt : 0),
                interest: Number(interestAmt ? interestAmt : 0),
                totalAmount: Number(totalRepayment ? totalRepayment : 0),
                saveDate: fixTimezoneOffset(date),
            };
            UpdateIntInsRepay(data).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        handleReset();
                        // setTotalRepayment("");
                        setOutstandingAmt(0)
                        getRepayDataList();
                        // handleCloseModal();
                        swal("Updated successfully!", "", "success");
                    } else {
                        swal(res.data._response.message, "", "info");
                    }
                }
            })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, " : error");
                })
        }

    }
    const updateRepay = (selectedRow, a) => {
        setUpdate(true)
        setSelPayment({ label: a.paymentType, value: a.paymentType });
        setPrincipleAmt(a.principal);
        setDate(new Date(a.saveDate));
        setInterestAmt(a.interest);
        setTotalRepayment(a.totalAmount);
        let amount = conOutamt + a.totalAmount;
        setOutstandingAmt(amount);
        setRepayId(a.id);
    }
    return (
        <>
            <Modal isOpen={showBorrowRepay} toggle={() => handleCloseModal()} size="lg" backdrop="static" centered>
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Borrowing Repay</h5>
                </ModalHeader>
                <ModalBody >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2">
                        <Form>
                            <Row>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Payment<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        name="Payment"
                                        value={selPayment}
                                        onChange={handlePayment}
                                        options={payment}
                                        isDisabled={update === true ? true : false}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.payment}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Date of Payment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDate}
                                        popperPlacement="top-end"
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        // minDate={new Date(InvDate)}
                                        selected={date}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className={InterestPercentage !== 0 ? "col-md-4" : "col-md-6"}>
                                    <Form.Label>Total Repayment<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder='Enter Total Repayment'
                                        name="Total_Repayment"
                                        // min="0"
                                        value={totalRepayment}
                                        onChange={handleTotalRepayment}
                                    /> */}

                                    <Cleave
                                        placeholder='Enter Total Repayment'
                                        className='form-control'
                                        name="Total_Repayment"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={totalRepayment}
                                        onChange={handleTotalRepayment}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.Total_Repayment}
                                        </div>
                                    ) : null}
                                </Form.Group>

                            {InterestPercentage !== 0 ? <> 
                                <Form.Group className="col-md-4">
                                    <Form.Label>Principle Paid</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder="Enter Principle Paid"
                                        name="Principle_Paid"
                                        // min="0"
                                        value={principleAmt}
                                        onChange={handlePrincipleAmt}
                                    /> */}

                                    <Cleave
                                        placeholder="Enter Principle Paid"
                                        className='form-control'
                                        name="Principle_Paid"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={principleAmt}
                                        onChange={handlePrincipleAmt}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.principleAmt}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Interest Paid</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        placeholder="Enter amount of interest"
                                        disabled={false}
                                        name="interestAmt"
                                        // min="0"
                                        value={interestAmt}
                                        onChange={handleIntpaid}
                                    /> */}
                                    <Cleave
                                        placeholder="Enter amount of interest"
                                        className='form-control'
                                        name="interestAmt"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={interestAmt}
                                        onChange={handleIntpaid}
                                    />
                                </Form.Group>
                                </>
                                :null}
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {update === false ?
                                        <Button variant="primary" className="mx-2 w100" onClick={handleSubmit}
                                            disabled={!disableButton()}
                                        >Submit</Button> :
                                        <Button variant="primary" className="mx-2 w100" onClick={handleUpdate}
                                        >Update</Button>}

                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        type="reset"
                                        onClick={handleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div><br />
                    {/* table design */}
                    <TableContainer component={Paper}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                ...(selected.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                }),
                            }}
                        >
                            {selected.length > 0 ? (
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {selected.length} selected
                                </Typography>
                            ) : null}

                            {selected.length > 0 ? (
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <DeleteIcon onClick={(e) => deleteMultiple(e)} />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </Toolbar>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableHead>
                                <TableRow>
                                    <TableCell width="5%">
                                        <Checkbox
                                            style={{ float: "left", marginLeft: "14px" }}
                                            color="primary"
                                            onClick={handleSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < idata.length}
                                            checked={idata.length > 0 && selected.length === idata.length ? "checked" : null}
                                            // onChange={onSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center" width="12%">Payment</TableCell>
                                    <TableCell align="center" width="13%">Date of Payment</TableCell>
                                    <TableCell align="center" width="10%">Principle Paid</TableCell>
                                    <TableCell align="center" width="10%">Interest Paid</TableCell>
                                    <TableCell align="center" width="11%">Total Repayment</TableCell>
                                    <TableCell align="center" width="7%" >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idata && idata.map((index) => {
                                    const isItemSelected = isSelected(index.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={idata.id}
                                            selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{ marginLeft: "1rem" }}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, index.id)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{index.paymentType}</TableCell>
                                            <TableCell align="center">{moment(index.saveDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.principal)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.interest)}</TableCell>
                                            <TableCell align="center">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(index.totalAmount)}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <Edit fontSize="small" onClick={(e) => updateRepay(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <DeleteIcon fontSize="small" onClick={(e) => deleterow(e, index)} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BorrowingsRepay