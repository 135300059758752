import axios from '../../modules/helpers/axiosInstance';

import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index';

let MutualFundLiveUrl = "http://5.189.157.40:10027/api";



const userID = Auth.getsubcriberId();

const outerGridMutualFamily = (include, insType_id) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FetchMutualFundFamilyOuterGrid?s_id=${userID}&include=${include}&instrumentTypeId=${insType_id}`,
        // url: `https://localhost:5001/api/MutualFundManagement/FetchMutualFundFamilyOuterGrid?s_id=${userID}&include=${include}&instrumentTypeId=${insType_id}`
    });
}

const outerGridMutualEntity = (sub_id, include, insType_id) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FetchMutualFundIndividualOuterGrid?sub_id=${sub_id}&include=${include}&instrumentTypeId=${insType_id}`,
        // url: `https://localhost:5001/api/MutualFundManagement/FetchMutualFundIndividualOuterGrid?sub_id=${sub_id}&include=${include}&instrumentTypeId=${insType_id}`
    });
}

const innerGridMutualFamily = (scheme_id, sub_id, insType_id) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FetchMutualFundFamilyInnerGrid?scheme_id=${scheme_id}&sub_id=${sub_id}&insType_id=${insType_id}`,
        // url:`https://localhost:44314/api/MutualFundManagement/FetchMutualFundFamilyInnerGrid?scheme_id=${scheme_id}&sub_id=${sub_id}&insType_id=${insType_id}`
    });
}

const innerGridMutualEntity = (scheme_id, insType_id, sub_id) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FetchMutualFundIndividualInnerGrid?scheme_id=${scheme_id}&insType_id=${insType_id}&sub_id=${sub_id}`,
        // url: `http://localhost:5000/api/MutualFundManagement/FetchMutualFundIndividualInnerGrid?scheme_id=${scheme_id}&sub_id=${sub_id}&insType_id=${insType_id}`,
    });
}

const mutualFundTransaction = (headerID) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/GetMutualFundTransactionByID?mutualFundHeaderId=${headerID}`,
    });
}


const getSipLumpsumAddFundDetails = async (MFId) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/GetFundsDetails?MutualFund_id=${MFId}`,
    });
}
const submitSipLumpsumAddFund = async (data) => {
    return axios({
        method: 'POST',
        url: `${MutualFundLiveUrl}/MutualFundManagement/AddFunds`,
        data: data
    });
}

const updateSipLumpsumAddFund = async (data) => {
    return axios({
        method: 'PUT',
        url: `${MutualFundLiveUrl}/MutualFundManagement/UpdateFunds`,
        data: data
    });
}

const deleteSipLumpsumAddFund = async (mFundId) => {
    return axios({
        method: 'DELETE',
        url: `${MutualFundLiveUrl}/MutualFundManagement/DeleteFunds?mutualFund_id=${mFundId}`
    });
}

const GetMutualFundCompanies = async () => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetMutualFundCompanies`,
    });
}

const GetMutualFundMasterListCompanies = async () => {
    return axios({
        method: 'GET',
        url: `http://5.189.157.40:10023/api/UserManagment/GetMutualFundMasterListCompanies`,
    })
}

const AddMutualFundCompanies = async (data) => {
    return axios({
        method: 'POST',
        url: `http://5.189.157.40:10023/api/UserManagment/AddMutualFundCompanies`,
        data: data
    })
}
const AddMutualFundDetails = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/MutualFundManagement/AddMutualFundDetails`,
        data: data
    })
}

const removeMutualFund = async(fundid) =>{
    return axios({
        method: "DELETE",
        url: `${settings.API_URL}/MutualFundManagement/RemoveMutualFund?fundid=${fundid}`,
    })
}

const UpdateMutualFundBuySellDetails = async (data) => {
    return axios({
        method: "PUT",
        url: `${settings.API_URL}/MutualFundManagement/UpdateMutualFundBuySellDetails`,
        data: data
    })
}


const addMutualFundBonus = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/MutualFundManagement/AddMutualFundBonus`,
        data: data
    });
}

const updateMutualFundBonus = async (data) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/MutualFundManagement/UpdateMutualFundBonus`,
        data: data
    });
}

const GetMutualFundSellCompanies = async (sub_Id, bro_id, inst_id) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/FetchCompanyByInsTypeId?sub_id=${sub_Id}&bro_id=${bro_id}&insType_id=${inst_id}`,
    });
}


const deleteMutualFundOtherTransaction = async (MFHeaderId, MFId) => {
    return axios({
        method: 'DELETE',
        url: `${settings.API_URL}/MutualFundManagement/RemoveOtherTransaction?mutualfundheaderid=${MFHeaderId}&mutualfundid=${MFId}`,
    });
}

const FetchBuyDate = async (sub_id, co_id, bro_id) => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/MutualFundManagement/FetchMutualFundDetails?sub_id=${sub_id}&co_id=${co_id}&bro_id=${bro_id}`,
    });
};

const AvailableMFQuantity = async (brokerId, subAccountId, companyId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/GetBrokerQuantity?b_id=${brokerId}&sub_id=${subAccountId}&c_id=${parseInt(companyId)}`
    })
}

const AvailableMFSellQuantity = async (subAccountId, companyId, brokerId, insId, Folio_Policy_Number) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/FetchMutualFundDetails?sub_id=${subAccountId}&co_id=${companyId}&bro_id=${brokerId}&ins_id=${insId}&number=${Folio_Policy_Number}`
    })
}

const SelectionCompany = async (subAccountId, companyId, brokerId, insId, Folio_Policy_Number) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/FetchSelectionCompanyDetails?sub_id=${subAccountId}&co_id=${companyId}&bro_id=${brokerId}&ins_id=${insId}&number=${Folio_Policy_Number}`
    })
}

const SelectionMutualFundUpdate = async (mFundId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/SelectionDataForMutualFundUpdate?mf_id=${mFundId}`
    })
}
// https://localhost:44314/api/MutualFundManagement/SelectionDataForMutualFundUpdate?mf_id=1

const DeleteMFTransactions = async (headerId) => {
    return axios({
        method: "DELETE",
        url: `${settings.API_URL}/MutualFundManagement/RemoveEntireMutualFundTransactions?mutualfundheaderId=${headerId}`,
    });
}




const addMutualFundSplit = async (data) => {
    return axios({
        method: 'POST',
        url: `${MutualFundLiveUrl}/MutualFundManagement/AddMutualFundSplit`,
        data: data
    });
}

const updateMutualFundSplit = async (data) => {
    return axios({
        method: 'PUT',
        url: `${MutualFundLiveUrl}/MutualFundManagement/UpdateMutualFundSplit`,
        data: data
    });
}

const MutualFundOpeningBalance = async (data) => {
    return axios({
        method: 'POST',
        url: `${MutualFundLiveUrl}/MutualFundManagement/MutualFundOpeningBalance`,
        data: data
    });
}
const GetCompanyAndBroker = async (Sub_id) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/GetCompanyAndBroker?sub_id=${Sub_id}`
    })
}
const AddMFMerger = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/MutualFundManagement/AddMergerAPI`,
        data: data
    })
}
const UpdateMFMerger = async (data) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/MutualFundManagement/UpdateMergerShares`,
        data: data
    })
}
const AddMFDeMerger = async (data) => {
    return axios({
        method: 'POST',
        url: `${settings.API_URL}/MutualFundManagement/DeMergerAPI`,
        data: data
    })
}
const UpdateMFDeMerger = async (data) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/MutualFundManagement/UpdateDeMergerAPI`,
        data: data
    });
}

const GetMutualFundCompaniesForLive = async (sub_id) => {
    return axios({
        method: 'GET',
        // url:`${settings.API_URL}/MutualFundManagement/GetCompanyAndBroker?sub_id=${Sub_id}`
        url: `${settings.API_URL}/UserManagment/GetMutualFundCompaniesBySub?sub_id=${sub_id}`
    })
}

const UpdateMFCompanyList = async (data) => {
    return axios({
        method: 'PUT',
        url: `${settings.API_URL}/UserManagment/UpdateMutualFundCompanies`,
        data: data
    })
}

const getSingleTransactionMFPreview = async (mutualFund_id) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/FetchMutualFundsPreviewDetails?mutualFund_id=${mutualFund_id}`
    })
}

const getFamilyTotals = async (insId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/GetMutualFundsTotalCalculationBySubscriberId?subs_id=${userID}&instrumentTypeId=${insId}`
    })
}

const getEntityTotals = async (sub_id, insId) => {
    return axios({
        method: 'GET',
        url: `${settings.API_URL}/MutualFundManagement/GetMutualFundsTotalCalculationBySubAccountId?sub_id=${sub_id}&instrumentTypeId=${insId}`
    })
}

const GetMutualFundFolioNo = async (subAccountId, companyId, InstTypeID, brokerId) => {
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FetchMutualFundFolioNumbers?sub_id=${subAccountId}&co_id=${companyId}&insType_id=${InstTypeID}&bro_id=${brokerId}`
    })
}

const filterByInstFamily = (InstTypeID,include) =>{
    return axios({
        method: 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/GetMutualFundsTotalCalculationBySubAccountId?sub_id=${userID}&instrumentTypeId=${InstTypeID}&include=${include}`
        // url: `https://localhost:5001/api/MutualFundManagement/FilterByInstrumentTypeId?sub_id=${userID}&instrumentTypeId=${InstTypeID}&include=${include}`
    })
}

const filterByInstEntity = (subAccountId,InstTypeID,include) =>{
    return axios({
        method : 'GET',
        url: `${MutualFundLiveUrl}/MutualFundManagement/FilterByIntrumentTypeIdIndividual?subAcc_id=${subAccountId}&instrumentTypeId=${InstTypeID}&include=${include}`
    })
}

export {
    outerGridMutualFamily,
    outerGridMutualEntity,
    innerGridMutualFamily,
    innerGridMutualEntity,
    mutualFundTransaction,
    getSipLumpsumAddFundDetails,
    submitSipLumpsumAddFund,
    updateSipLumpsumAddFund,
    deleteSipLumpsumAddFund,
    GetMutualFundCompanies,
    AddMutualFundCompanies,
    AddMutualFundDetails,
    addMutualFundBonus,
    updateMutualFundBonus,
    deleteMutualFundOtherTransaction,
    FetchBuyDate,
    AvailableMFQuantity,
    AvailableMFSellQuantity,
    SelectionCompany,
    DeleteMFTransactions,
    addMutualFundSplit,
    updateMutualFundSplit,
    GetMutualFundSellCompanies,
    UpdateMutualFundBuySellDetails,
    MutualFundOpeningBalance,
    GetMutualFundCompaniesForLive,
    UpdateMFCompanyList,
    GetCompanyAndBroker,
    AddMFMerger,
    UpdateMFMerger,
    AddMFDeMerger,
    UpdateMFDeMerger,
    GetMutualFundMasterListCompanies,
    getSingleTransactionMFPreview,
    getFamilyTotals,
    getEntityTotals,
    GetMutualFundFolioNo,
    removeMutualFund,
    filterByInstFamily,
    filterByInstEntity,
    SelectionMutualFundUpdate
};
