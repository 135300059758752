import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from "moment";

function LoansDepoPreview(props) {
    const { showLoanPreview, onHideLoanPreview, LoanTransactionPreview } = props
    // console.log(props);
    let d = LoanTransactionPreview;
    let value = d.lockInPeriod;
    let splitData = value ? value.split(/([0-9]+)/) : null;
    let time = splitData ? splitData[1] : "-";
    let period = splitData ? splitData[2] : "";
    let value1 = d.tenure;
    let splitData1 = value1 ? value1.split(/([0-9]+)/) : null;
    let time1 = splitData1 ? splitData1[1] : "-";
    let period1 = splitData1 ? splitData1[2] : "";

    return (
        <div tabIndex={3}>

            <Modal isOpen={showLoanPreview} toggle={onHideLoanPreview} backdrop="static">
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => onHideLoanPreview()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Loans Given and Deposits Preview</h5>
                </ModalHeader>
                <ModalBody>
                    {showLoanPreview === true ?
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData odd"><div className="transactionDataTitle">Reference Number : </div>
                                <div className="transactionDataValue">{d.referenceNo === null ? '-' : d.referenceNo}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Type of Investment : </div>
                                <div className="transactionDataValue">{d.instrumentType.instrumentTypeName === null ? '-' : d.instrumentType.instrumentTypeName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Deposit with / Loan to : </div>
                                <div className="transactionDataValue">{d.depositWithLoanTo.depositWithLoanToName === null ? '-' : d.depositWithLoanTo.depositWithLoanToName}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Date of Investment : </div>
                                <div className="transactionDataValue">{d.depositDate === null ? '-' : moment(d.depositDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount Invested : </div>
                                <div className="transactionDataValue">{d.FirstAmtInvested === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.firstAmtInvested)}</div></div>
                                <div className="transactionData "><div className="transactionDataTitle">Additional Amount : </div>
                                <div className="transactionDataValue">{d.depositAmt === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.depositAmt)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Tenure : </div>
                                <div className="transactionDataValue">{d.tenure === null ? '-' : `${time1 ? time1 : '-'}  ${period1 ? period1 : ""}`}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Date of Maturity : </div>
                                <div className="transactionDataValue">{d.maturityDate === null ? '-' : moment(d.maturityDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Interest % : </div>
                                <div className="transactionDataValue">{!(d.interestPerc) ? '-' : d.interestPerc + '%'}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Interest Frequency : </div>
                                <div className="transactionDataValue">{d.frequency === null ? '-' : d.frequency.frequencyName}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount Outstanding: </div>
                                <div className="transactionDataValue">{d.amtOutstanding === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.amtOutstanding)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Narration : </div>
                                <div className="transactionDataValue">{d.narration === "" ? '-' : d.narration}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Lock in Period (if Any): </div>
                                <div className="transactionDataValue">{d.lockInPeriod === null ? '-' : `${time ? time : '-'}  ${period ? period : ""}`}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Interest Receivables on above : </div>
                                <div className="transactionDataValue">{d.reinvestmentPayout === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(d.reinvestmentPayout)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date of Interest Receivable: </div>
                                <div className="transactionDataValue">{d.interestDate === null ? '-' : moment(d.interestDate).format("DD/MM/YYYY")}</div></div>


                        </div>
                        : null}
                </ModalBody>

            </Modal>
        </div>
    )
}

export default LoansDepoPreview