class Auth {
  constructor() {
    this.user = JSON.parse(localStorage.getItem('user_data')) || null ;
  }
  getToken() {
  
    if(this.user !== null){
    return this.user.token
    }
  }
  getsubcriberId() {
    if(this.user !== null){
    return this.user.subcriberId
    }
  }
  getUserId() {
    if(this.user !== null){
    return this.user.userId
    }
  }
  getUserName() {
    if(this.user !== null){
    return this.user.userName
    }
  }
  getEmail() {
    if(this.user !== null){
    return this.user.emailId
    }
  }
  setUserToken(new_token) {
    this.user = new_token
    localStorage.setItem('auth', JSON.stringify(new_token))
  }
  getTwoFA() {
    if(this.user !== null){
    return this.user.twofactorEnabled
    }
  }
  getTwoFAAuth() {
    if(this.user !== null){
    return this.user.twoFaAuth
    }
  }

  getRegistrationDate() {
    if (this.user !== null) {
      return this.user.UserData.registrationDate
    }
  }

  getUserRole() {
    if(this.user !== null){
    return this.user.userRole
    }
  }
  getUserRoleID() {
    if (this.user !== null) {
      return this.user.roleId
    }
  }


  getUserTrialDays() {
    if (this.user !== null) {
      return this.user.UserData.days
    }
  }
  getRenewalDays() {
    if (this.user !== null) {
      return this.user.UserData.renewalDays
    }
  }

  getUserIsPaid() {
    if (this.user !== null) {
      return this.user.UserData.isPaid ? this.user.UserData.isPaid : 0
    }
  }
  getUserTermsCon() {
    if (this.user !== null) {
      return this.user.UserData.termsandCondition
    }
  }

  getMobileNumber() {
    if (this.user !== null) {
      return this.user.UserData.mobileNumber
    }
  }
  getUserIsPaidLocal() {
    if (this.user !== null) {
      return this.user.isPaidUser
    }
  }

  getUserTerms() {
    if (this.user !== null) {
      return this.user.termsConditions
    }
  }
  logout() {
    // localStorage.removeItem('token')
    localStorage.removeItem('user_data')
    localStorage.removeItem('pop_status')
    localStorage.removeItem('remainingDays_pop')
    localStorage.removeItem('Terms_consition')
    localStorage.removeItem('profile_pop')
  }
}
export default new Auth()