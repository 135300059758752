/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Loader from '../loadingAnimation/index';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from "../../assets/logo.png";
import {

    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

// import "./Loginstyle.css"

const theme = createTheme();

export default function UserRegistration(props) {

    const Navigate = useNavigate();

    // Register states
    const [newUser, setNewUser] = useState({
        username: "",
        email: "",
        phone: "",
        password: "",
        cpassword: "",
    })
    const [newUserErr, setnewUserErr] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedErr, setIsCheckedErr] = useState({});
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState(false);
    const [cpassword, setCPassword] = useState(false);

    const validation = () => {
        const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        let spaceAndLetter = /^[a-z]+$/i;

        let newUserErr = {};
        let isValid = true;
        if (newUser.username === "" || newUser.username === null) {
            newUserErr["username"] = "Please enter name!";
            isValid = false;
        } else if (spaceAndLetter.test(newUser.username) === false) {
            newUserErr["username"] = "Please enter alphabetic characters and no spaces";
            isValid = false;
        }
        if (newUser.email === "" || newUser.email === null) {
            newUserErr["email"] = "Please enter email!";
            isValid = false;
        } else if (emailregex.test(newUser.email) === false) {
            newUserErr["email"] = "please enter valid email"
            isValid = false;
        }
        if (newUser.phone === "" || newUser.phone === null) {
            newUserErr["phone"] = "Please enter phone!";
            isValid = false;
        } else if (phoneregex.test(newUser.phone) === false) {
            newUserErr["phone"] = "Please enter valid phone!";
            isValid = false;
        }
        if (newUser.password && newUser.cpassword) {
            if (newUser.password !== newUser.cpassword) {
                newUserErr["cpassword"] = "Password is not matching!";
                isValid = false;
            }
        }
        if (newUser.password === "" || newUser.password === null) {
            newUserErr["password"] = "Please enter password!";
            isValid = false;
        } else if (passregex.test(newUser.password) === false) {
            newUserErr["password"] = "Password must combination of Number,Capital letter, special character and min length is 8 !";
            isValid = false;
        }
        if (newUser.cpassword === "" || newUser.cpassword === null) {
            newUserErr["cpassword"] = "Please enter password again!";
            isValid = false;
        }
        if (isChecked !== true) {
            isCheckedErr.ischkd = "Please check in this field!";
            isValid = false;
        }
        setnewUserErr(newUserErr);
        setIsCheckedErr(isCheckedErr);
        return isValid;
    }

    // register onchange handlers
    const handleChangeReg = (e) => {
        const name = e.target.name
        const value = e.target.value
        setNewUser({ ...newUser, [name]: value });
        setIsChecked(true);
        if (name === "username" && value !== null) {
            setnewUserErr({ ...newUserErr, username: "" })
        }
        if (name === "email" && value !== null) {
            setnewUserErr({ ...newUserErr, email: "" })
        }
        if (name === "phone" && value !== null) {
            setnewUserErr({ ...newUserErr, phone: "" })
        }
        if (name === "password" && value !== null) {
            setnewUserErr({ ...newUserErr, password: "" })
        }
        if (name === "cpassword" && value !== null) {
            setnewUserErr({ ...newUserErr, cpassword: "" })
        }
        if (isChecked === true) {
            setIsCheckedErr(" ");
        }
    }
    const handleRegSubmit = (e) => {
        e.preventDefault();
        const isValid = validation();
        if (isValid) {
            let regiUser = {
                subcriberName: newUser.username,
                subcriberEmail: newUser.email,
                subcriberPh1: newUser.phone,
                subcriberPassword: newUser.password,
                confirmPassword: newUser.cpassword,
                roleId: 2,
                acceptTerms: true
            }
            let headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            setLoading(true);
            axios.post(`http://5.189.157.40:10025/api/Subcriber/register`, regiUser, headers)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            swal("Registered Succussfully", "Please check your email for verifying email before login", "success")
                            Navigate("/login")
                        } else {
                            swal("something went wrong", "", "info")
                        }
                    } else {
                        setLoading(false);
                        swal("something went wrong", "", "info")
                    }
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.data._response.message === "Already Registered") {
                        swal("Email already registered", "", "info");
                    } else {
                        swal("Invalid registration details", "", "info");
                    }
                })

        }
    }

    const handleClickShowPassword = () => {
        setPassword(!password);
    };

    const handleClickShowCPassword = () => {
        setCPassword(!cpassword)

    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" className='mt-4'>
                {loading ? <Loader /> : null}
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="sidebar__img">
                        <img src={logo} alt="logo" />
                        {/* <h1>Accompt Fintech</h1> */}
                    </div>
                    <h3 variant="h1" className='mt-1'>Sign Up</h3>
                    <Box component="form" noValidate >
                        <Grid container spacing={1} marginTop={1}>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField

                                        // margin="normal"
                                        required
                                        variant="outlined"
                                        label='Name'
                                        type="text"
                                        name='username'
                                        onChange={handleChangeReg} value={newUser.username} placeholder="Enter Your Name"
                                    />
                                    {newUserErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {newUserErr.username}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        // margin="normal"
                                        required
                                        variant="outlined"
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"

                                        onChange={handleChangeReg} value={newUser.email}
                                    />
                                    {newUserErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {newUserErr.email}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        // margin="normal"
                                        required
                                        variant="outlined"
                                        label='Phone Number'
                                        type="number"
                                        name='phone'
                                        onChange={handleChangeReg} value={newUser.phone} placeholder="Enter your phone number"
                                    />
                                    {newUserErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {newUserErr.phone}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>

                                    <InputLabel htmlFor="outlined-adornment-password" color="primary">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        variant="outlined"
                                        onChange={handleChangeReg} value={newUser.password}
                                        name='password'
                                        color='primary'
                                        type={password ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {password ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    {newUserErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {newUserErr.password}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>

                                    <InputLabel htmlFor="outlined-adornment-password" color="primary">
                                        Confirm Password
                                    </InputLabel>
                                    <OutlinedInput
                                        variant="outlined"
                                        onChange={handleChangeReg} value={newUser.cpassword} placeholder="Confirm your password"
                                        name='cpassword'
                                        color='primary'
                                        type={cpassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowCPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {cpassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Confirm Password'
                                    />
                                    {newUserErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {newUserErr.cpassword}
                                        </div>
                                    ) : null}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleRegSubmit}
                        >

                            Sign In
                        </Button>

                        <Grid container>
                            <Typography className="mt-2"> Already have an account ?
                                <Link to="/" >
                                    Sign In
                                </Link>
                            </Typography>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}