// import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';
import Auth from '../../modules/Auth';
const userID = Auth.getsubcriberId();
let InsuranceLiveUrl = "http://5.189.157.40:10026/api";

const EntityList = async () => {
    return axios({
        method: "GET",
        url: `${settings.API_URL}/UserManagment/GetSubaccountList?subId=${userID}`,
    });
}

const AddInsurance = async (body)=>{
    return axios({
        method:'POST',
        url:`${InsuranceLiveUrl}/InsuranceManagement/AddInsuranceDetails`,
        data:body
    })
}
const updateInsurance = async (body) => {
    return axios({
        method: 'PUT',
        url: `${InsuranceLiveUrl}/InsuranceManagement/UpdateInsuranceDetails`,
        data: body
    })
}

const OuterGridFamilyList = async () => {
    return axios({
        method: "GET",
        url: `${InsuranceLiveUrl}/InsuranceManagement/FetchInsuranceFamilyOuterGrid?s_id=${userID}`,
    });
}

const OuterGridEntityList = async (sub_id) => {
    return axios({
        method: "GET",
        url: `${InsuranceLiveUrl}/InsuranceManagement/FetchInsuranceIndividualOuterGrid?sub_id=${sub_id}`,
    });
}

const InnerGridFamilyList = async (po_Id, poSub_ID, sub_id) => {
    return axios({
        method: "GET",
        url: `${InsuranceLiveUrl}/InsuranceManagement/FetchInsuranceEntityInfo?policy_type_id=${po_Id}&policy_subType_id=${poSub_ID}&sub_id=${sub_id}`,
    });
}

const InnerGridEnityList = async (po_Id, poSub_ID, sub_id,com_id) => {
    return axios({
        method: "GET",
        url: `${InsuranceLiveUrl}/InsuranceManagement/FetchInsuranceIndividualEntityInfo?policy_type_id=${po_Id}&policy_subType_id=${poSub_ID}&sub_id=${sub_id}&co_id=${com_id}`,
    });
}

const InsuranceTransaction = async(Ins_ID) =>{
    return axios({
        method: "GET",
        url: `${settings.API_URL}/InsuranceManagement/GetInsuranceDetailsById?insurance_id=${Ins_ID}`
    })
}

const deleteInsurance = async(Ins_ID) =>{
    return axios({
        method: "DELETE",
        url: `${settings.API_URL}/InsuranceManagement/DeleteInsuranceDetails?insurance_id=${Ins_ID}`
    })
}

const AddPremiumMaturity = async(data) => {
    return axios({
        method:"POST",
        url:`${InsuranceLiveUrl}/InsuranceManagement/AddPremiumMaturity`,
        data:data
    })
}

const UpdatePremiumMaturity = async(data) => {
    return axios({
        method:"PUT",
        url:`${InsuranceLiveUrl}/InsuranceManagement/UpdatePremiumMaturity`,
        data:data
    })
}

const GetPremiumHistory = async(insurance_id,transactstocktype) => {
    return axios({
        method:"GET",
        url:`${InsuranceLiveUrl}/InsuranceManagement/GetPremiumHistory?insurance_id=${insurance_id}&transactstocktype=${transactstocktype}`
    })
}

const DeletePremium = async(insuranceSaleId) => {
    return axios({
        method:"DELETE",
        url:`${InsuranceLiveUrl}/InsuranceManagement/DeletePremiumDetails?insuranceSale_id=${insuranceSaleId}`
    })
}
const DeleteInsuranceMaturity = async(insurance_id) => {
    return axios({
        method:"DELETE",
        url:`${InsuranceLiveUrl}/InsuranceManagement/DeleteMaturityDetails?insurance_id=${insurance_id}`
    })
}

const AddSubPolicy = async(data) => {
    return axios({
        method:"POST",
        url:`${settings.API_URL}/UserManagment/AddPolicySubtype`,
        data:data
    })
}   

const getSubPolicy = async() =>{
    return axios({
        method:"GET",
        url:`${settings.API_URL}/UserManagment/GetAllPolicyDetails`
    })
}
export{
    EntityList,
    AddInsurance,
    updateInsurance,
    OuterGridFamilyList,
    OuterGridEntityList,
    InnerGridFamilyList,
    InnerGridEnityList,
    InsuranceTransaction,
    deleteInsurance,
    AddPremiumMaturity,
    UpdatePremiumMaturity,
    GetPremiumHistory,
    DeletePremium,
    DeleteInsuranceMaturity,
    AddSubPolicy,
    getSubPolicy 

}