//import axios from 'axios';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';

import Auth from '../../modules/Auth/index'
const userID = Auth.getsubcriberId();

let BulionLiveUrl = "http://5.189.157.40:10026/api";

const FetchBulionsFamilyData = async () => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchBullionFamilyOuterGrid?s_id=${userID}`
    })
}

const FetchBulionsEntityData = async (sub_id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchBullionIndividualOuterGrid?sub_id=${sub_id}`
    })
}

const FetchBulionsInnerGrid = async (sub_id, Inst_Id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchBullionEntityInfo?sub_id=${sub_id}&invTypeId=${Inst_Id}`
    })
}

const AddBulionsDetails = async (data) => {
    return axios({
        method: "POST",
        url: `${settings.API_URL}/BullionManagement/AddBullionDetail`,
        //url: `https://localhost:44375/api/BullionManagement/AddBullionDetail`,
        data: data
    })
}

const UpdateBullionDetails = async (data) => {
    return axios({
        method: "PUT",
        url: `${BulionLiveUrl}/BullionManagement/UpdateBullionDetails`,
        data : data
    })
}
const DeleteBullionTransaction = async (BullionHeaderId) => {
    return axios({
        method: "DELETE",
        url: `${BulionLiveUrl}/BullionManagement/RemoveEntireBullionTransactions?bullionHeaderId=${BullionHeaderId}`
    })
}
const DeleteInnerGridBullionTransaction = async (BullionHeaderId) => {
    return axios({
        method: "DELETE",
        url: `${BulionLiveUrl}/BullionManagement/RemoveSingleBullionTransaction?bullion_id=${BullionHeaderId}`
    })
}

const FetchBulionsInvestmentType = async () => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchBullionInvestmentType`
    })
}

const AddBulionsOpeningBal = async (data) => {
    return axios({
        method: "POST",
        url: `${BulionLiveUrl}/BullionManagement/BullionOpeningBalance`,
        data: data
    })
}

const FetchBulionsTransaction = async (headerId) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/GetBullionDetailByHeaderId/${headerId}`
        // url : `https://localhost:5001/api/BullionManagement/GetBullionDetailByHeaderId/${headerId}`
    })
}


const fetchSellInvestmentType = async (sub_id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchSelectionInvestmentType?sub_id=${sub_id}`
    })
}

const fetchSelectionData = async (sub_id, investment_id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/FetchSelectionInvestmentId?sub_id=${sub_id}&investment_id=${investment_id}`
        //url: `https://localhost:44375/api/BullionManagement/FetchSelectionInvestmentId?sub_id=${sub_id}&investment_id=${investment_id}`
    })
}
const fetchUpdatedSelectionData = async (bullion_id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/SelectionDataForBulionUpdate?bullion_id=${bullion_id}`
        // https://localhost:44375/api/BullionManagement/SelectionDataForBulionUpdate?bullion_id=1
    })
}
const getPreviewData = async (bullion_id) => {
    return axios({
        method: "GET",
        url: `${BulionLiveUrl}/BullionManagement/BullionDetailsPreview?bullionId=${bullion_id}`
        //url: `https://localhost:44375/api/BullionManagement/FetchSelectionInvestmentId?sub_id=${sub_id}&investment_id=${investment_id}`
    })
}
export {
    AddBulionsDetails,
    UpdateBullionDetails,
    FetchBulionsFamilyData,
    FetchBulionsEntityData,
    FetchBulionsInvestmentType,
    AddBulionsOpeningBal,
    FetchBulionsTransaction,
    FetchBulionsInnerGrid,
    fetchSellInvestmentType,
    fetchSelectionData,
    DeleteBullionTransaction,
    DeleteInnerGridBullionTransaction,
    getPreviewData,
    fetchUpdatedSelectionData
}