/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, } from "react-bootstrap";


import {
  FaMoneyBillAlt,
  FaChartBar,
  FaRupeeSign,
  FaPercent,
  FaSellsy
} from "react-icons/fa";

// import Select from "react-select";
import "../../pages/Dashboard/Dashboard.css";
import Googlecharts from "./Googlechart";
import "react-tabs/style/react-tabs.css";

import InvestmentTable from "./InvestmentTable";
import { EntityList, DashboardCardsData, DashboardFamilyPie, DashboardEntityPie } from '../Dashboard/RecentTranAPI/RT_API'
import Auth from '../../modules/Auth/index';

const userID = Auth.getsubcriberId();

const DashboardTable = () => {
  // const [investmentOption, setInvestmentOption] = useState([
  //   { label: "Investments at Cost", value: 0 },
  //   { label: "Net worth at Cost", value: 1 },
  //   { label: "% Gain Networth", value: 2 },
  // ]);

  const [donutChartData, setDonutChartData] = useState([]);
  const [donutChartMarketData, setDonutChartMarketData] = useState([]);

  const [entityRadio, setEntityRadio] = useState([]);
  // const [entity, setEntity] = useState("Family");

  const [value, setValue] = useState(0);
  const [tilesData, setTilesData] = useState({})

  // const HandleChange = (event) => {
  //   setValue(event.value);
  //   setInvestmentT(event);
  // };



  useEffect(() => {


    if (userID) {
      getEntityList();
      getTilesValue();
      handleFamilyChart()
    }
  }, [value]);


  //getEntityList
  const getEntityList = () => {

    EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let SubscriberName = res.data._response.data.subaccountDetails;
            setEntityRadio(SubscriberName);
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        // setError(e.message);
      });
  };

  //tiles data
  const getTilesValue = () => {
    DashboardCardsData().then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let TilesData = res.data._response.data;
          setTilesData(TilesData)
        } else {
          setTilesData({

          })
        }
      }

    }).catch((e) => {
      console.log(e.message);
    })
  };


  //  family pie chart
  const handleFamilyChart = () => {
    DashboardFamilyPie().then((res) => {
      let familyPiechart = res.data._response.data.queryn[0];



      setDonutChartData([
        ["Stocks", familyPiechart.stockTotalInvestmentAtCost],
        ["Mutual funds", familyPiechart.mutualFundTotalInvestmentAtCost],
        ["Bullion", familyPiechart.bullionTotalInvestmentAtCost],
        ["Fixed Income", familyPiechart.fixedIncomeTotalInvestmentAtCost],
        ["Property", familyPiechart.propertyTotalInvestmentAtCost],
        ["Art", familyPiechart.artTotalInvestmentAtCost],
        ["Loans Given/Deposits ", familyPiechart.loanTotalInvestmentAtCost],

      ])

      setDonutChartMarketData([
        ["Stocks", familyPiechart.stockTotalInvestmentAtMP],
        ["Mutual funds", familyPiechart.mutualFundTotalInvestmentAtMP],
        ["Bullion", familyPiechart.bullionTotalInvestmentAtMP],
        ["Fixed Income", familyPiechart.fixedIncomeTotalInvestmentAtMP],
        ["Property", familyPiechart.propertyTotalInvestmentAtMP],
        ["Art", familyPiechart.artTotalInvestmentAtMP],
        ["Loans Given/Deposits ", familyPiechart.loanTotalInvestmentAtMP],

      ])




    }).catch((e) => {
      console.log(e.message);

    })

  }

  //only two decimal places
  const convertTOdecimal = (num) => {

    return (Math.round(num * 100) / 100).toFixed(2);
  }


  //entity pie chart
  const handleEntityChart = (id) => {

    DashboardEntityPie(id).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          const EntityPieChart = res.data._response.data.queryn[0];


          setDonutChartData([
            ["Stocks", EntityPieChart.stockTotalInvestmentAtCost],
            ["Mutual funds", EntityPieChart.mutualFundTotalInvestmentAtCost],
            ["Bullion", EntityPieChart.bullionTotalInvestmentAtCost],
            ["Fixed Income ", EntityPieChart.fixedIncomeTotalInvestmentAtCost],
            ["Property", EntityPieChart.propertyTotalInvestmentAtCost],
            ["Art", EntityPieChart.artTotalInvestmentAtCost],
            ["Loans Given/Deposits ", EntityPieChart.loanTotalInvestmentAtCost],

          ])

          setDonutChartMarketData([
            ["Stocks", EntityPieChart.stockTotalInvestmentAtMP],
            ["Mutual funds", EntityPieChart.mutualFundTotalInvestmentAtMP],
            ["Bullion", EntityPieChart.bullionTotalInvestmentAtMP],
            ["Fixed Income ", EntityPieChart.fixedIncomeTotalInvestmentAtMP],
            ["Property", EntityPieChart.propertyTotalInvestmentAtMP],
            ["Art", EntityPieChart.artTotalInvestmentAtMP],
            ["Loans Given/Deposits ", EntityPieChart.loanTotalInvestmentAtMP],

          ])
        } else {
          setDonutChartData([])
          setDonutChartMarketData([])
        }


      }

    }).catch((e) => {
      console.log(e.message);

    })

  }
  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center mb-0">Dashboard</h4><br></br>
        </Col>
      </Row>

      {/* tiles */}
      <Row className="justify-content-md-center mt-4">
        <div className="card dashTitles costTiles">
          <div className="card-icon">
            <FaMoneyBillAlt />
          </div>
          <div className="card_inner">
            <p className="tiles-title">
              Investments at Cost
            </p>
            <hr />
            <p className="tiles-value">{tilesData.totalInvestmentAtCost ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(tilesData.totalInvestmentAtCost) : "0.00"}</p>
          </div>
        </div>

        <div className="card dashTitles mrtTiles">
          <div className="card-icon">
            <FaChartBar />
          </div>
          <div className="card_inner">
            <p className="tiles-title">
              Investments at Market Price
            </p>
            <hr />
            <p className="tiles-value">{tilesData.totalInvestmentAtMP ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(tilesData.totalInvestmentAtMP)) : "0.00"}</p>
          </div>
        </div>

        <div className="card dashTitles netTiles">
          <div className="card-icon">
            <FaRupeeSign />
          </div>
          <div className="card_inner">
            <p className="tiles-title">Networth at Cost</p>
            <hr />
            <p className="tiles-value">{tilesData.netWorthAtCost ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(tilesData.netWorthAtCost) : "0.00"}</p>
          </div>
        </div>

        <div className="card dashTitles netMTiles">
          <div className="card-icon">
            <FaPercent />
          </div>
          <div className="card_inner">
            <p className="tiles-title">Networth at Market Price</p>
            <hr />
            <p className="tiles-value">{tilesData.netWorthAtMP ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(tilesData.netWorthAtMP)) : "0.00"}</p>
          </div>
        </div>


        <div className="card dashTitles gainTiles">
          <div className="card-icon">
            <FaSellsy />
          </div>
          <div className="card_inner">
            <p className="tiles-title">
              % Gain Networth
            </p>
            <hr />
            <p className="tiles-value">{tilesData.gainNetWorth ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(tilesData.gainNetWorth)) : "0.00"}</p>
          </div>
        </div>
      </Row>

      {/* pie charts */}
      <Row className="justify-content-md-center">
        <Col md="10" className="dsh">
          <div className="card dashboardChartWrap pb-0">
            <Nav variant="pills" className="justify-content-start EntityBtn" defaultActiveKey="link-1">
              <Nav.Item>
                <Nav.Link eventKey="link-1" className="btn px-3 m-1" onClick={() => handleFamilyChart()} >Family</Nav.Link>
              </Nav.Item>
              {
                entityRadio.map((a, index) => (
                  <Nav.Item>
                    <Nav.Link eventKey={"link-" + index + 1} className="btn px-3 m-1" onClick={() => handleEntityChart(a.subAccountId)}>{a.subAccountName}</Nav.Link>
                  </Nav.Item>

                ))
              }


            </Nav>
            {/* <Select
              value={investmentT}
              name="Investment"
              options={investmentOption}
              onChange={HandleChange}
              className="SelectClass "
            /> */}

            <h5 className="text-center m0"> Cost vs. Market Investments Comparison</h5>
            <div className="row mb-2 ">
              <div className="col-md-6 col-xl-6">
                <div className="card piecard">
                  <div className="card-block">
                    <Googlecharts donutChartData={donutChartData} />
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-6">
                <div className="card piecard">
                  <div className="card-block">
                    <Googlecharts donutChartData={donutChartMarketData} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Col>
      </Row>



      {/*your investmets table  */}
      <Row className="justify-content-md-center">
        <Col md="12" className="mt-4">
          <InvestmentTable />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardTable;
