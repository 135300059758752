import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { updateTerms } from '../../pages/Subscription/SubscriptionAPI'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import sample from "./Accompt.pdf"
import './TermsStyle.css'
import Auth from '../../modules/Auth';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { ClearSidebarData } from '../../redux/action/sidebarAction';

function TermsAndCondiModal(props) {
  const { showTerms, hideTerms } = props

  const userID = Auth.getUserId();
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }



  const handleClose = () => {
    hideTerms()
  }


  const handleTerms =( ) =>{


    let TermsData = {
      userId: userID,
      termsConditions: 1

    }

  updateTerms(TermsData)
    .then((res) => {
    
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          handleClose()
          Swal.fire({
            title: 'Done!',
            text: 'Thanks for accepting Terms and Conditions',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
   
         
        }
      }
    }).catch((err) =>{
      console.log(err, "error in updating Terms And Conditions");
    })
  }

  const handleLogout = () => {
    swal("Logged out!", "", "success")
    Auth.logout();
    dispatch(ClearSidebarData());
  }
  return (
    <div>
      <Modal
        show={showTerms}
        size="lg"
        scrollable={true}
        backdrop="static"
        centered
      >
        <Modal.Header  >
          <Modal.Title className='mx-auto'> Terms & Condition's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='bg-light' style={{width:"2rem"}}>
            <Document file={sample} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>


          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="primary" onClick={handleTerms}>
            I Agree
          </Button>
          <Button variant="primary" href="/Login" onClick={handleLogout}>
          Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TermsAndCondiModal