import * as React from "react";
import { Chart } from "react-google-charts";

const CustomCharts = ({ data, chartType, options, colors }) => {
    return (
        <Chart
            width={'90%'}
            height={'218px'}
            chartType={chartType}
            loader={<div>Loading Chart</div>}
            data={data}
            options={options}
            rootProps={{ 'data-testid': '1' }}
        />

    )
}

export default CustomCharts;
