import React, { useState } from "react";
import "./Sidebar.css";

import Bonusmodal from "../MutualFundList/OtherTransaction/MFundBonus";
import Splitmodal from "../MutualFundList/OtherTransaction/MFundSplit";
import MFundMerger from "../MutualFundList/OtherTransaction/MFundMerger";
import MFundDemerger from "../MutualFundList/OtherTransaction/MFundDemerger";



const MFundDropdown = (props) => {

  const { onRefresh } = props;

  const [showdrop, setShow] = useState(false);
  const [splitshowdrop, setSplitshowdrop] = useState(false);
  const [mergershowdrop, setMergershowdrop] = useState(false);
  const [demergerShowDrop, setDemergerShowDrop] = useState(false);

  const BonusHandlechange = () => {
    setShow(true);
  }
  const SplitHandlechange = () => {
    setSplitshowdrop(true);
  }
  const FundmergerHandlechange = () => {
    setMergershowdrop(true);
  }

  const demergerHandleChange = () => {
    setDemergerShowDrop(true);
  }


  const handleclose = async () => {

    setShow(false);
    setSplitshowdrop(false);
    setMergershowdrop(false);
    setDemergerShowDrop(false);
    await onRefresh();
  }
  return (
    <>
      <div className="dropdown">
        <div className="dropbtn">Other Transactions</div>
        <div className="dropdown-content mutual-other-transaction">
          <li onClick={BonusHandlechange}>Fund Bonus</li>
          <li onClick={SplitHandlechange}>Fund Split</li>
          <li onClick={FundmergerHandlechange}>Fund Merger</li>
          <li className="mb-3" onClick={demergerHandleChange}>Fund Demerger</li>
        </div>
      </div>
      <Bonusmodal showB={showdrop} onHideB={handleclose} onRefresh={() => onRefresh()} />
      <Splitmodal showS={splitshowdrop} onHideS={handleclose} onRefresh={() => onRefresh()} />
      <MFundMerger showM={mergershowdrop} onHideM={handleclose}
        onRefresh={() => onRefresh()}
      />
      <MFundDemerger showD={demergerShowDrop} onHideD={handleclose}
        onRefresh={() => onRefresh()}
      />
    </>
  )
}
export default MFundDropdown;