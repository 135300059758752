import React from 'react';
import SubscriberTable from '../../../components/RoleMaster/ChannelMaster/AddSubscriber/SubscriberTable';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function AddSubscriber() {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

  return (
    <div>
        {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar/>
            <div>
              <SubscriberTable/>
            </div>
        </div>
    </div>
  )
}

export default AddSubscriber