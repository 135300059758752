/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState ,useEffect } from 'react';
import "./Sidebar.css";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { UserAdmin } from './SideBarRoute';
import { SidebarByRoleid } from '../../pages/Stocks/StocksAPI';
import Auth from '../../modules/Auth/index'
import { useDispatch, useSelector} from 'react-redux';
import { SidebarData, ClearSidebarData } from '../../redux/action/sidebarAction';
import { setIsAccessApiCall } from '../../redux/action/userData';

const Sidebar = ({ sidebarOpen, closeSidebar, route }) => {
    const [menuData, setMenuData] = useState([]);
    const token = Auth.getToken();
    const TwoFA = Auth.getTwoFA();
    const TwoFAAuth = Auth.getTwoFAAuth();
    const userRole = Auth.getUserRoleID();

    const selector = useSelector(res => res.sidebarReducer.sidebardata);
    const isAccessApi = useSelector((data) => data.userData.isAccessApi)
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAccessApi === true) {
            sidebar();
            dispatch(setIsAccessApiCall(false))
        }
    }, [isAccessApi])

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if ((token && TwoFA === TwoFAAuth) && selector.length === 0) {
            sidebar();
        } else {
            setMenuData(selector);
        }
    }, [userRole])

    const sidebar = async () => {
        SidebarByRoleid(userRole).then((res) => {
            if (res.data._response.status === 1) {
                let result = res.data._response.data.sidebarDetails
                setMenuData(result);
                // route(result)
                dispatch(SidebarData(result));
            }
        }).catch((err) => {
            setMenuData([]);
            // route([])
            dispatch(ClearSidebarData())
            console.log(err, "err in sidebar")
        })
    }
    return (
        <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
            <div className="sidebar__title">
                <div className="sidebar__img">
                    <img src={logo} alt="logo" />
                    {/* <h1>Accompt Fintech</h1> */}
                </div>
                <i
                    className="fa fa-times"
                    id="sidebarIcon"
                    onClick={() => closeSidebar()}
                ></i>
            </div>
            {
                <>
                    <div className="sidebar__menu">
                        {menuData && menuData.map(({ link, menuName }, i) => (
                            <div key={i}>
                                {UserAdmin && UserAdmin.map(({ routeName, icon }, index) => {
                                    if (menuName === routeName) {
                                        return (
                                            <div className="sidebar__link" key={index}>
                                                {icon}
                                                <Link to={link}>{menuName}</Link>
                                            </div>)
                                    }

                                })}
                            </div>
                        ))}
                    </div>
                </>
            }
            {/* {roleId === 1 ?
                <>
                    <div className="sidebar__menu">
                        {adminRoutes && adminRoutes.map((val, i) => (
                            <div className="sidebar__link" key={i}>
                                {val.icon}
                                <Link to={val.path}>{val.routeName}</Link>
                            </div>
                        ))}
                    </div>
                </>
                :
                <>
                    <div className="sidebar__menu">
                        {userRoutes && userRoutes.map((val, i) => (
                            <div className="sidebar__link" key={i}>
                                {val.icon}
                                <Link to={val.path}>{val.routeName}</Link>
                            </div>
                        ))}
                    </div>
                </>

            } */}
        </div>
    );
};
export default Sidebar;