/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Table, Stack } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import UserHistory from './UserHistory';
import { MdOutlineEmail } from "react-icons/md";
import { oldSubscribers } from '../ChannelMaster/channalMasterApi'
import './Channelmastercss.css'


function OldMember(props) {
  const {oldAllData} = props;

  const [userHistoryModal, setUserHistoryModal] = useState(false);
  const [rowData, setRowData] = useState([]);

  // const [oldSubcribesData, setOldSubscibersData] = useState([]);

  const columns = [
    {
      align: 'center',
      height: 40,
      colName: 'Sr No.'
    },
    {
      align: 'center',
      height: 40,
      colName: 'User code no'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Last date of payment'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Send Email'
    },
  ]

  useEffect(() => {
    getOldSubscribersList(1, 10)
   
  }, [])

  const getOldSubscribersList = (pageNumber, dataPerPage) => {
    oldSubscribers(pageNumber, dataPerPage)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err, "error while fetching Live Members");
      })
  }


  //pagination
  const handlechangePage = () => {

  }

  //handle table row click
  const handleRowClick = (rowData) => {
    setRowData(rowData);
    setUserHistoryModal(true);

  }

  const handleCloseModal = () => {
    setUserHistoryModal(false);
    setRowData([]);
  }

  const handleEmailSend = (data) => {

  }
  return (
    <div className='oldMem'>
      <Paper className='p-3 container mt-3 h-50 old-members' >
        <h4 className='text-center mb-4'>Old Members</h4>
        <TableContainer component={Paper} elevation={4} sx={{}}>
          <Table sx={{ minWidth: 150 , maxWidth: 660 }} aria-label="simple table" >
            <TableHead>
              <TableRow sx={{ fontSize: 14 }}>
                {columns.map((col, i) => (
                  <TableCell align={col.align} style={{ height: col.height }} key={i}>{col.colName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {oldAllData.map((row, i) => (
                <TableRow
                  key={i}

                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="right" onClick={() => handleRowClick(row)}>{row.userCode}</TableCell>
                  <TableCell align="right">{row.createDate}</TableCell>
                  <TableCell align="center" onClick={() => handleEmailSend(row)}><span className='emailIcon'><MdOutlineEmail size={20} /></span></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end"
          mt={1} >
          <Pagination count={10} variant="outlined" color="primary" onChange={handlechangePage} page={1} />
        </Stack>
      </Paper>
      <div>
        <UserHistory isOpenModal={userHistoryModal} onHideModal={handleCloseModal} tableData={rowData} />
      </div>

    </div>
  )
}

export default OldMember