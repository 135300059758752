import React, { useState , useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap";



import "./CDashboard.css"
import LiveMembersTable from './LiveMembersTable';
import OldMember from './OldMember';
import TrialMemberTable from './TrialMemberTable'
import { trialSubscribers } from '../ChannelMaster/channalMasterApi'


const row = [
    {
      id: 1,
      userCode: 12,
      createDate: "12/02/2001",
      nextRenewalDate: "12/02/2011",
      paymentType: 'Cheque',
      BankName: 'hdfc',
      refNo: 123443334455
  
    },
    {
      id: 2,
      userCode: 121,
      createDate: "12/02/2001",
      nextRenewalDate: "12/02/2002",
      paymentType: 'Cheque',
      BankName: 'icici',
      refNo: 123443334455
  
    },
    {
      id: 2,
      userCode: 121,
      createDate: "12/02/2001",
      nextRenewalDate: "12/02/2002",
      paymentType: 'Cheque',
      BankName: 'icici',
      refNo: 123443334455
  
    },
    {
      id: 2,
      userCode: 121,
      createDate: "12/02/2001",
      nextRenewalDate: "12/02/2002",
      paymentType: 'Cheque',
      BankName: 'icici',
      refNo: 123443334455
  
    },
  ]

const ChannelDashboard = () => {

    const [dataAllCount , setDataAllCount] = useState(0);
    const [oldAllDataCount, setOldAllDataCount] = useState(0); 

    const [trialSubcribesData, setTrialSubscibersData] = useState([]);
    // const [trialSubscriberDataCount,setTrialSubscibersDataCount] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    
    useEffect(() => {
        const result = row.length;
        setOldAllDataCount(result)
    }, [])
    
    useEffect(() => {
        getTrialSubscribersList(1, 10)
      }, [])
    
      const getTrialSubscribersList = (pageNumber, dataPerPage) => {
        trialSubscribers(pageNumber, dataPerPage)
          .then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                let userData = res.data._response.data.trialMembers
                setTrialSubscibersData(userData)
    
                // pagination
                let pageCount = Math.ceil(userData.length / dataPerPage)
                setPageCount(pageCount)

                // const trialCount = res.data._response.trialMembers;
              }
            }
            // console.log(res);
          })
          .catch((err) => {
            console.log(err, "error while fetching Live Members");
          })
      }

    const dataCount = (data) => {
        setDataAllCount(data)
    }


    const [liveMemTable, setLiveMemTable] = useState(true);
    const [OldMemTable, setOldMemTable] = useState(false);
    const [TrialMemTable, setTrialMemTable] = useState(false);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="text-center mb-0">Dashboard</h4><br></br>
                    </Col>
                </Row>

                {/* Tabs */}
                <Row className="justify-content-md-center mt-4">
                    <div className="card dashTitles costTiles" style={liveMemTable ? { backgroundColor: "#D9E5F2 " } : null} onClick={() => { setLiveMemTable(true); setOldMemTable(false); setTrialMemTable(false) }}>
                        <span className="badge rounded-pill badge-notification badge-info notification-livemember">{dataAllCount}</span>
                        <div className="card_inner">
                            <p className="tiles-title">
                                Live Members
                            </p>
                        </div>
                    </div>

                    <div className="card dashTitles mrtTiles" style={OldMemTable ? { backgroundColor: "#D9E5F2 " } : null} onClick={() => { setOldMemTable(true); setLiveMemTable(false); setTrialMemTable(false) }}>
                    <span className="badge rounded-pill badge-notification badge-info notification-livemember">{oldAllDataCount}</span>
                        <div className="card_inner">
                            <p className="tiles-title">
                                Old Members
                            </p>

                        </div>
                    </div>

                    <div className="card dashTitles netTiles" style={TrialMemTable ? { backgroundColor: "#D9E5F2 " } : null} onClick={() => { setTrialMemTable(true); setOldMemTable(false); setLiveMemTable(false); }}>
                    <span className="badge rounded-pill badge-notification badge-info notification-livemember">99</span>
                        <div className="card_inner">
                            <p className="tiles-title">Trial Members</p>

                        </div>
                    </div>


                </Row>

                {liveMemTable && <LiveMembersTable dataAllCount={dataCount} />}
                {OldMemTable && <OldMember oldAllData={row} />}
                {TrialMemTable && <TrialMemberTable trialSubcribesData={trialSubcribesData} pageCountProp={pageCount} />}

            </Container>
        </>
    )
}

export default ChannelDashboard