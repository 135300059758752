/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Dropdown from '../../CommonComponent/Dropdown';
import Loader from "../../loadingAnimation/index";
import swal from "sweetalert";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { periodList } from '../../../modules/helpers/constant'
import { AddRecurringDeposit, UpdateRecurringDeposit } from "../FDAPI";
import { GetDividendFrequency } from "../../../pages/Stocks/StocksAPI";
import Maturity from "./Maturity";
import RDInterest from "./RDInterest";
import RDInstallment from "./RDInstallment";
import Cleave from 'cleave.js/react';
import { Tooltip } from "@mui/material";

const PPFMain = (props) => {
  const { PPFentity, PPFTransaction, FDHeaderIdPPF, show8, onRefreshFD } = props;

  const [folioNumber, setFolioNumber] = useState({ folioNumber: "" });
  const [selInvestType, setSelInvestType] = useState([]);
  const [iDate, setIDate] = useState(null);
  const [investAmount, setInvestAmount] = useState({ totalCost: "" });
  const [selAmountInvestType, setSelAmountInvestType] = useState([]);
  const [interestPer, setInterestPer] = useState({ interestPerc: "" });
  const [frequencyValue, setFrequencyValue] = useState([]);
  const [mDate, setMDate] = useState(null);
  const [maturityVal, setMaturityVal] = useState({ maturityValue: "" });
  const [lockinperiod, setLockinperiod] = useState("")
  const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
  const [lockPeriodValue, setLockPeriodValue] = useState("Month");
  const [selFrequency, setSelFrequency] = useState([]);
  const [dividendFrequency, setDividendFrequency] = useState([]);
  const [narration, setNarration] = useState("")
  const [company, setCompany] = useState({ company: "" });
  const [interestAcc, setInterestAcc] = useState({ interestReinvested: "" })
  const [startDate, setStartDate] = useState(null);
  const [PPFId, setPPFId] = useState()

  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInterest, setIsInterest] = useState(false);


  const [reinvestedRadio, setReinvestedRadio] = useState("no");

  const [PPFi, setPPFi] = useState(false);
  const [PPFM, setPPFM] = useState(false);
  const [PPFInstall, setPPFInstall] = useState(false);

  

  useEffect(() => {

    getDividedFrequencyList();

  }, [])

  //getdividend list
  const getDividedFrequencyList = async () => {
    await GetDividendFrequency()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
                return (dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                }))
              });
            setDividendFrequency(dividendData);
            if (FDHeaderIdPPF === 0) {
              setSelFrequency(dividendData[1]);
            }
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        // setError(e.message);
      });
  };

  //handleRadio
  const handleRadio = (e) => {
    const { value } = e.target
    setReinvestedRadio(value);
    if (value === "no") {
      setStartDate(null);
      setInterestAcc({ ...interestAcc, interestReinvested: "" })
    }
  }

  // handle folio Number
  const handleFolio = (e) => {
    const { name, value } = e.target;
    setFolioNumber({ ...folioNumber, [name]: value })
    setFormErr({ ...formErr, folioNumber: "" })
  }

  const handleCloseMaturity = () => {
    setPPFM(false);
  }

  // handle investment date
  const handleInvDate = (iDate) => {
    setIDate(iDate);
    setFormErr({ ...formErr, idate: "" })
  }

  //handle amount invest
  const handleInvestAmount = (e) => {
    const { name, rawValue } = e.target;
    setInvestAmount({ ...investAmount, [name]: rawValue});
    setFormErr({ ...formErr, totalCost: "" })
  }


  //handle interest
  const handleInterestPer = (e) => {
    const { name, value } = e.target;
    setInterestPer({ ...interestPer, [name]: value > 100 ? 100 : value < 0 ? 0 : value });
  }

  //handle frequency
  const handleFrequency = (selected) => {
    setSelFrequency(selected);
    setFrequencyValue(selected.value)

  }
  //handle maturity date
  const handleDateM = (mDate) => {
    setMDate(mDate);
    setFormErr({ ...formErr, mdate: "" });
  }

  //handle maturity value
  const handleMaturityVal = (e) => {
    const { name, rawValue } = e.target;
    setMaturityVal({ ...maturityVal, [name]: rawValue });
  }

  //handle lock in period
  const HandleChangePeriod = (e) => {
    setLockPeriod(e);
    setLockPeriodValue(e.value);
    setFormErr({ ...formErr, lockPeriodValue: '' })
  }

  const handleNarration = (e) => {
    setNarration(e.target.value)
  }

  //handleinterstAcc
  const handleValueIntAcc = (e) => {
    const { name, rawValue } = e.target;
    setInterestAcc({ ...interestAcc, [name]: rawValue });
    setFormErr({ ...interestAcc, interestAcc: "" })
  }

  //handlestartingdate
  const handleDateStart = (startDate) => {
    setStartDate(startDate);
    setFormErr({ ...formErr, startDate: "" })
  }

  //handlecompnay/bank
  const handleCompany = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
    setFormErr({ ...formErr, company: "" })
  }

  const errHandel = () => {
    let formErr = {};
    let isValid = true;
    const reg = /\s/g;
    if (reg.test(company.company) === true || company.company === "") {
      if (company.company === "" || company.company.trim() === "") {
        isValid = false;
        formErr["company"] = "Please Enter Company!";
      }
    }
    if (!iDate) {
      isValid = false;
      formErr["idate"] = "Please select Investment Date !";
    }

    if (!investAmount.totalCost) {
      isValid = false;
      formErr["totalCost"] = "Please Enter Amount Invested !";
    }

    if (!mDate) {
      isValid = false;
      formErr["mdate"] = "Please Select Maturity Date !";
    }

    // if (!interestPer.interestPerc) {
    //   isValid = false;
    //   formErr["interestPerc"] = "Please select maturity Date !";
    // }
    if (!folioNumber.folioNumber) {
      isValid = false;
      formErr["folioNumber"] = "Please Enter Certificate/Folio number!";
    }
    if (interestAcc.interestReinvested === "" && reinvestedRadio === "yes") {
      isValid = false;
      formErr["interestAcc"] = "Please Enter Interest Accrued !";
    }
    if ((!startDate) && (reinvestedRadio === "yes")) {
      isValid = false;
      formErr["startDate"] = "Please Select Date !";
    }
    if (mDate && iDate) {
      if (fixTimezoneOffset(mDate) === fixTimezoneOffset(iDate)) {
        isValid = false;
        formErr["mdate"] = "Maturity Date Cannot Be Same As Investment Date!";
      }
    }
    setFormErr(formErr);
    return isValid;
  };
  //handle clear form
  const handleReset = () => {
    setFolioNumber({ folioNumber: "" })
    setMDate(null);
    setIDate(null);
    setInterestPer({ interestPerc: "" });
    setFrequencyValue({});
    setInvestAmount({ totalCost: "" });
    setSelAmountInvestType([]);
    setSelInvestType([]);
    setSelFrequency(dividendFrequency[1]);
    setNarration("");
    setMaturityVal({ maturityValue: "" });
    setLockinperiod([]);
    setFormErr({});
    setCompany({ company: "" })
    setReinvestedRadio('no')
    setStartDate(null);
    setInterestAcc({ interestReinvested: "" })
    setIsInterest(false)
  }

  // submit Data
  const handleSubmit = () => {
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true)
      let PPFData = {
        instrumentId: 4,
        instrumentTypeId: 31,
        subAccountId: PPFentity,
        date: fixTimezoneOffset(iDate),
        certificateFolioNo: company.company,
        narration: narration,
        fdDetails: [{
          transactStockType: "Buy",
          interestAccrued: reinvestedRadio === "yes" ? true : false,
          interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
          fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
          availableCost: Number(investAmount.totalCost),
          folioNumber: folioNumber.folioNumber,
          lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
          domConversion: fixTimezoneOffset(mDate),
          MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investAmount.totalCost),
          frequencyId: selFrequency.value,
          interestPerc: interestPer.interestPerc ? interestPer.interestPerc : 0,
          cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
        }]
      }
      AddRecurringDeposit(PPFData).then((response) => {
        if (response.status === 200) {
          setLoading(false)
          if (response.data._response.status === 1) {
            swal("PPF Added Successfully!", "", "success");
            handleReset()
          } else {
            swal(response.data._response.message, "", 'info');
          }
        } else {
          setLoading(false)
          console.log("error");
        }
      })
        .catch((error) => {
          setLoading(false)
          console.log(error, "error in submit");
        });


    }
  };

  // HANDLE UPDATE
  const handleUpdate = () => {
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true)
      let PPFData = {
        fdHeaderId: FDHeaderIdPPF,
        instrumentId: 4,
        instrumentTypeId: 31,
        subAccountId: PPFentity,
        date: fixTimezoneOffset(iDate),
        certificateFolioNo: company.company,
        narration: narration,
        fdDetails: [{
          fdId: PPFId,
          transactStockType: "Buy",
          interestAccrued: reinvestedRadio === "yes" ? true : false,
          interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
          fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
          availableCost: Number(investAmount.totalCost),
          folioNumber: folioNumber.folioNumber,
          lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
          domConversion: fixTimezoneOffset(mDate),
          MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investAmount.totalCost),
          frequencyId: selFrequency.value,
          interestPerc: interestPer.interestPerc ? interestPer.interestPerc : 0,
          cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
        }]
      }
      UpdateRecurringDeposit(PPFData).then((response) => {
        if (response.status === 200) {
          setLoading(false)
          if (response.data._response.status === 1) {
            swal("PPF Updated Successfully!", "", "success");
          } else {
            swal(response.data._response.message, "", "info");
          }
        } else {
          setLoading(false)
          console.log("error");
        }
      })
        .catch((error) => {
          setLoading(false)
          console.log(error, "error in update");
        });
    }
  };

  // get data for update
  useEffect(() => {
    if (FDHeaderIdPPF !== 0) {
      if (PPFTransaction !== undefined) {
        setIDate(new Date(PPFTransaction.date));
        setNarration(PPFTransaction.narration)
        PPFTransaction.fdDetails && PPFTransaction.fdDetails.map((t) => {
          setPPFId(t.fdId);
          setCompany({ company: t.company.companyName });
          setFolioNumber({ folioNumber: t.folioNumber });
          t.frequencyId ? setSelFrequency({ label: t.frequency.frequencyName, value: t.frequencyId }) : setSelFrequency([]);
          setMaturityVal({ maturityValue: t.maturityValue });
          setInterestPer({ interestPerc: t.interestPerc });
          setInvestAmount({ totalCost: t.availableCost });
          setMDate(new Date(t.domConversion));
          let l = t.lockInPeriod ? t.lockInPeriod : "0Month";
          let splitDatalock = l.split(/([0-9]+)/);
          setLockinperiod(splitDatalock[1]);
          setLockPeriodValue(splitDatalock[2]);
          setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });
          setReinvestedRadio(t.interestAccrued === true ? "yes" : "no");
          if (t.interestAccrued === true) {
            setInterestAcc({ interestReinvested: t.interestReinvested });
            setStartDate(new Date(t.fyStartDate))
            // setIsInterest(true)
            t.balanceQuantity > 0 ? setIsInterest(true) : setIsInterest(false);
          }
        })
      }
    }
  }, [PPFTransaction])

  // for  date fix 
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  //disable button
  const disableButton = () => {
    return iDate && mDate && investAmount

  }

  // handle buttons
  const handleInterest = () => {
    setPPFi(!PPFi)
  }
  const handleMaturity = () => {
    setPPFM(!PPFM)
  }
  const handleInstall = () => {
    setPPFInstall(true)
  }
  const handleCloseInterest = () => {
    setPPFi(false)
  }
  const handleCloseInstallMent = () => {
    setPPFInstall(false);
  }
  const handledisableCheck = (i) => {
    if (i === 1) {
      setIsInterest(true);
    } else {
      setIsInterest(false);
    }
  }
  

  return (

    <>
      {show8 === true ?
        <>
          {loading ? (
            <Loader style={{ marginLeft: "50px", marginTop: "12rem" }} />
          ) : null}
          <div className="mt-2 p-6" tabIndex={2}>
            <Form className=" rounded p-3" >
              <Row className="form-set">
                {FDHeaderIdPPF === 0 || FDHeaderIdPPF === undefined ?
                  <Form.Group className="mb-6 col-md-3">
                    <Form.Label>Company / Bank <span className="text-danger h6">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="company"
                      min="0"
                      value={company.company}
                      onChange={handleCompany}
                      autoComplete="off"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.company}
                      </div>
                    ) : null}
                  </Form.Group>
                  :

                  <Form.Group className="mb-6 col-md-3">
                    <Form.Label>Company / Bank<span className="text-danger h6">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="company"
                      min="0"
                      value={company.company}
                      onChange={handleCompany}
                      autoComplete="off"
                      disabled
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.company}
                      </div>
                    ) : null}
                  </Form.Group>
                }
                <Form.Group className="mb-6 col-md-3">
                  <Form.Label>Certificate No./Folio No.<span className="text-danger h6">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleFolio}
                    name="folioNumber"
                    min="0"
                    value={folioNumber.folioNumber}
                    style={{ textTransform: "uppercase" }}
                    autoComplete="off"
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.folioNumber}
                    </div>
                  ) : null}
                </Form.Group>


                <Form.Group className="col-md-3">
                  <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    name="idate"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    onChange={handleInvDate}
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    selected={iDate}
                    maxDate={new Date()}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.idate}
                    </div>
                  ) : null}
                </Form.Group>


                <Form.Group className="mb-6 col-md-3">
                  <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                  {/* <Form.Control
                    type="text"
                    name="totalCost"
                    min="0"
                    value={commarize(investAmount.totalCost)}
                    onChange={handleInvestAmount}
                  /> */}

                  <Cleave
                    className='form-control'
                    autoComplete="off"
                    placeholder="Enter Amount"
                    name="totalCost"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={investAmount.totalCost}
                    onChange={handleInvestAmount}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.totalCost}
                    </div>
                  ) : null}
                </Form.Group>




              </Row>


              <Row className="form-set">

                <Col md="3" className="mb-3">
                  <Form.Label className="d-block">Lock in Period</Form.Label>
                  <Form.Control type="Number"
                    onChange={(e) => setLockinperiod(e.target.value)}
                    autoComplete="off"
                    value={lockinperiod}
                    name="lockinperiod"
                    id="lockinperiod"
                    className="lockinperiodno" />
                  <Dropdown
                    value={lockPeriod}
                    name='lockinperiod'
                    options={periodList}
                    onChange={HandleChangePeriod}
                    className="SelectClass lockinperiodlist"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                    placeholder="Month"

                  />
                </Col>

                <Form.Group className="mb-6 col-md-3">
                  <Form.Label>Interest %</Form.Label>
                  <Form.Control
                    type="Number"
                    name="interestPerc"
                    min="0"
                    value={interestPer.interestPerc}
                    onChange={handleInterestPer}
                  />
                </Form.Group>

                <Form.Group className="col-md-3">
                  <Form.Label>Interest Frequency</Form.Label>
                  <Select
                    value={selFrequency}
                    onChange={handleFrequency}
                    options={dividendFrequency}
                    name="frequencyId"
                  />
                </Form.Group>

                <Form.Group className="col-md-3">
                  <Form.Label>Maturity Date<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Maturity Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    onChange={handleDateM}
                    name="mdate"
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    selected={mDate}
                    minDate={new Date(iDate)}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.mdate}
                    </div>
                  ) : null}
                </Form.Group>



              </Row>
              <Row className="form-set">


                <Form.Group className="col-md-3">
                  <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                  {
                    reinvestedRadio === "yes" ?

                      <Cleave
                        className='form-control'
                        name="maturityValue"
                        autoComplete="off"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                        value={maturityVal.maturityValue}
                        onChange={handleMaturityVal}
                      />
                      :
                      <Cleave
                        className='form-control'
                        name="MaturityValue"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                        value={investAmount.totalCost}
                        disabled
                      />
                  }


                </Form.Group>

                <Form.Group className="mb-6 col-md-3">
                  <Form.Label>Narration</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={handleNarration}
                    name="narration"
                    min="0"
                    value={narration}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="col-md-2">
                  <Form.Label>Interest reinvested</Form.Label>
                  <RadioGroup
                    row
                    color="primary"
                    defaultValue="start"
                    aria-label="Entity"
                    name="row-radio-buttons-group"
                    fontSize="100px"
                    value={reinvestedRadio}
                    onChange={handleRadio}
                  // style={{ marginTop: "-2px" }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      disabled={isInterest}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      disabled={isInterest}
                    />
                  </RadioGroup>
                </Form.Group>


                {reinvestedRadio === "yes" ?
                  <>
                    <Form.Group className="col-md-2">
                      <Form.Label>Interest Accrued till date<span className="text-danger h6">*</span></Form.Label>
                      {/* <Form.Control
                        type="Number"
                        name="interestReinvested"
                        min="0"
                        value={interestAcc.interestReinvested}
                        onChange={handleValueIntAcc}
                      /> */}
                      <Cleave
                        className='form-control'
                        autoComplete="off"
                        name="interestReinvested"
                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                        value={interestAcc.interestReinvested}
                        onChange={handleValueIntAcc}
                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.interestAcc}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="col-md-2">
                      <Form.Label>Financial Year starting with<span className="text-danger h6">*</span></Form.Label>
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Start Date"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        // maxDate={new Date()}
                        // minDate={new Date(iDate)} 
                        dateFormat="dd-MM-yyyy"
                        onChange={handleDateStart}
                        name="fyStartDate"
                        autoComplete="off"
                        selected={startDate}
                      />
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.startDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </> : null
                }
              </Row>

              <Row>
                <Col md="12" className="text-center mt-4">
                  {
                    FDHeaderIdPPF === 0 || FDHeaderIdPPF === undefined ?
                      <>
                          <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                    <Button variant="primary" type="submit"
                                      className="mx-2 w100"
                                      id="submitButton"
                                      onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                      disabled={!disableButton()}
                                    >Submit</Button>
                                </span>
                          </Tooltip>
                        <Button
                          variant="secondary"
                          className="mx-2 w100"
                          type="reset"
                          onClick={handleReset}
                        >
                          Reset
                        </Button> </>
                      :
                      <>
                        <Button variant="primary" className="mx-2 w100"
                          type="submit"
                          id="submitButton"
                          onClick={(e) => { e.preventDefault(); handleUpdate(e) }} disabled={!disableButton()}
                        >Update</Button>
                        <>
                          <Button variant="primary" className="mx-2"
                            onClick={handleInstall}>Add Installment</Button>
                          <Button variant="primary" className="mx-2 w100"
                            onClick={handleInterest} disabled={reinvestedRadio === "no"}>Add Interest</Button>
                        </>
                        <Button
                          variant="primary"
                          className="mx-2 w100"
                          type="reset"
                          onClick={handleMaturity}
                          style={{ width: '7rem', height: '2rem' }}
                        >
                          Add Maturity
                        </Button>

                      </>
                  }
                </Col>
              </Row>
            </Form>
          </div>

          <RDInstallment showRDIn={PPFInstall} hideRDIn={handleCloseInstallMent} FDHeaderId={FDHeaderIdPPF} FDiD={PPFId} InvtDate={iDate} onRefreshFD={() => onRefreshFD()} Iname={"PPF Installment"} />
          <RDInterest showRDI={PPFi} hideRDI={handleCloseInterest} FDHeaderId={FDHeaderIdPPF} FDiD={PPFId} InvDate={iDate} Iname={"PPF Interest"} onRefreshFD={() => onRefreshFD()} disableCheck={(i) => handledisableCheck(i)} />
          <Maturity showMaturity={PPFM} onHideMaturity={handleCloseMaturity} FDHeaderId={FDHeaderIdPPF} FDiD={PPFId} InvDate={mDate} Fname={"PPF Maturity"} onRefreshFD={() => onRefreshFD()} />
        </> : null}

    </>

  )
}

export default PPFMain;