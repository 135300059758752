/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { FetchBrokerDropDownData, FetchCompanyDropDownData, EntityList, addRightsStocks, updateRights, FetchDate } from '../../../pages/Stocks/StocksAPI';

import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Select from "react-select"
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from '../../loadingAnimation/index'
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const RightsShare = (props) => {

    const { showR, onHideR, stockHeaderId, stockTransactionR, stockId, showrightsharepreview } = props;

    const handleClose = (val) => {
        onHideR(val);
        handleReset();
    }

    const [companyList, setCompanyList] = useState([]);
    const [brokerData, setBrokerList] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [ratio, setRatio] = useState('');
    const [pDate, setPDate] = useState(null);
    const [amount, setAmount] = useState(0);
    const [formErr, setFormErr] = useState({});
    const [form, setForm] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [successMessage, setSuccessMessage] = useState({});
    const [Value, setValue] = useState({ Quantity: null, PricePerShare: '' })
    const [broker, setbroker] = useState([])
    const [entityValue, setEntityValue] = useState([])
    const [buyDate, setBuyDate] = useState(null)

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEffect(() => {
        if (showR) {
            (async () => {
                await getEntityListt();
            })();
        }
    }, [showR])

    useEffect(() => {
        if (showR) {
            (async () => {
                await getCompanyDropDownData()
                await getBrokerDropDownData()
            })();
        }
    }, [selEntity, showR])

    useEffect(() => {
        if (Value.Quantity > 0 && Value.PricePerShare > 0) {
            handleAmount()
        } else {
            setAmount(0)
        }
    }, [Value]);

    const handleAmount = () => {
        const total = Number(Value.Quantity) * Number(Value.PricePerShare);
        setAmount(total);
    }
    //   useEnterKeyListener  ({
    //         querySelectorToExecuteClick: "#submitButton"
    //     });

    //getbrockerlist
    const getBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchBrokerDropDownData(sub_Id)
                .then((res) => {
                    setLoading(false)
                    // console.log(res, "brokerlisy");
                    if (res.data._response.status === 1) {
                        let brokers = res.data._response.data.brokerName;
                        let brokersData = [];
                        brokers &&
                            brokers.map((data) => {
                                return (brokersData.push({
                                    value: data.brokerId,
                                    label: data.brokerName,
                                }))
                            });
                        setLoading(false);
                        setBrokerList(brokersData);
                    } else {
                        setBrokerList([]);
                        setLoading(false)
                        //setError(res.data.message);
                    }
                })
                .catch((e) => {
                    // setError(e.message);
                    setBrokerList([]);
                    setLoading(false);
                    console.log(e, "brokerlist error");
                });
        }
    };

    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }


    // get dropDown Company Data
    const getCompanyDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchCompanyDropDownData(sub_Id)
                .then((res) => {
                    // console.log(res,'company list');
                    if (res.data._response.status === 1) {
                        let UnlistedCompany = [];
                        let ListedCompany = [];

                        let company = res.data._response.data.companyName;
                        company &&
                            company.filter(data => data.instrumentTypeId === 1).map(i => {
                                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });
                        company &&
                            company.filter(data => data.instrumentTypeId === 10).map(i => {
                                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                            });

                        const groupedOptions = [
                            {
                                label: 'Listed Companies',
                                options: ListedCompany,
                            },
                            {
                                label: 'UnListed Companies',
                                options: UnlistedCompany,
                            },
                        ];
                        setLoading(false);
                        setCompanyList(groupedOptions);
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                        // setError(res.data.message);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                    // setError(e.message);
                });
        }
    };
    const getEntityListt = async () => {
        setLoading(true)
        await EntityList()
            .then((res) => {

                if (res.status === 200) {
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                    setLoading(false)
                } else {
                    // setError(res.data.message);
                    setLoading(false)
                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)
            });


    };

    const handlevalue = (e) => {
        const { name, rawValue } = e.target
        setValue({ ...Value, [name]: rawValue });
        if (name === "Quantity" && rawValue !== null) {
            setFormErr({ ...formErr, Quantity: '' })
        } else if (name === "PricePerShare" && rawValue !== null) {
            setFormErr({ ...formErr, PricePerShare: '' })

        }
    }

    const handleScrip = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: '' });
    }

    const handleFamily = (selected) => {
        setSelEntity(selected);
        setEntityValue(selected.value)
        setSelScrip([])
        setSelBroker([])
        setFormErr({ ...formErr, selEntity: '' });
    }

    const handleBroker = (selected) => {
        setSelBroker(selected);
        setbroker(selected.value)
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: '' });
    }

    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    }

    const handleRatio = (e) => {
        const { name, value } = e.target;
        setRatio({ ...ratio, [name]: value });
        setFormErr({ ...formErr, ratio: '' })
    };

    const errHandel = () => {

        let formErr = {};
        let isValid = true;
        // ;
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select Scrip!";
        }
        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }

        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }
        if (!Value.PricePerShare || Value.PricePerShare === 0) {
            isValid = false;
            formErr["PricePerShare"] = "Please Fill Price!";
        }
        if (Value.PricePerShare) {
            if (Value.PricePerShare < 0) {
                isValid = false;
                formErr["PricePerShare"] = "Please Enter Positive Value !";
            }
        }
        if (!Value.Quantity || Value.Quantity === 0) {
            isValid = false;
            formErr["Quantity"] = "Please Fill Quantity!";
        }
        if (Value.Quantity) {
            if (Value.Quantity < 0) {
                isValid = false;
                formErr["Quantity"] = "Please Enter Positive Value !";
            }
        }
        // if (Value.Quantity) {
        //     let qvalue = parseFloat(Value.Quantity); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["Quantity"] = "Please enter only integer value !";
        //     }
        //   }
        // if (!ratio.ratio) {
        //     isValid = false;
        //     formErr["ratio"] = "Please fill Ratio field!";
        //   }
        setFormErr(formErr);
        return isValid;
    }
    // edit bonus data
    useEffect(() => {

        if (stockHeaderId !== 0) {
            setLoading(true)
            if (stockTransactionR !== undefined) {
                //
                setSelBroker({
                    value: stockTransactionR.brokerId,
                    label: stockTransactionR.broker.brokerName,
                });
                setbroker(stockTransactionR.brokerId);

                setSelEntity({
                    value: stockTransactionR.subAccountId,
                    label: stockTransactionR.subAccount.subAccountName,
                });

                setEntityValue(stockTransactionR.subAccountId);
                stockTransactionR.stockDetails && stockTransactionR.stockDetails.map((t) => {
                    setValue({ Quantity: t.quantity, PricePerShare: t.pricePerShare })
                    setPDate(new Date(t.date));
                    setAmount(t.TotalCost);
                    setRatio({ ratio: t.ratio })
                    setSelScrip(t.company, { companyType: t.instrumentTypeId })
                    setLoading(false)
                })

            }

        }
    }, [stockTransactionR])

    // for buy date
    useEffect(() => {
        if (showR) {
            if (stockHeaderId === 0 || stockHeaderId === undefined) {
                setPDate(null)
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;
                if (companyId && brokerId && subAccountId) {
                    getBuyDate(subAccountId, companyId, brokerId);
                }
            }
            if (stockHeaderId !== 0 && stockTransactionR !== undefined) {
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;
                if (companyId && brokerId && subAccountId) {
                    getBuyDate(subAccountId, companyId, brokerId);
                }
            }
        }
    }, [selScrip, selBroker, selEntity, showR]);

    const getBuyDate = async (sub_id, co_id, bro_id) => {
        setLoading(true)
        setBuyDate(null)
        await FetchDate(sub_id, co_id, bro_id)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setLoading(false)
                        setBuyDate(res.data._response.data.fetchDate)
                    }
                    else {
                        setLoading(false)
                        setError(res.data.message);
                    }
                } else {
                    setLoading(false)
                    setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                setError(e.message);
            });
    };

    const handleSubmit = async (e) => {
        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let data = {
                "BrokerId": selBroker.value,
                "SubAccountId": selEntity.value,
                date: fixTimezoneOffset(pDate),
                stockDetails: [
                    {

                        companyId: selScrip.value,
                        "PricePerShare": parseInt(Value.PricePerShare),
                        "Quantity": parseInt(Value.Quantity),
                        "TotalCost": amount,
                        ratio: ratio.ratio,
                        instrumentTypeId: selScrip.companyType
                    },
                ],
            };

            await addRightsStocks(data).then(res => {

                setLoading(false)
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setSuccess(true);
                        Swal.fire("Rights Share Added successfully!", "", 'success');
                        handleReset();
                        setValue({ ...Value, Quantity: 0, PricePerShare: 0 })
                        // onRefresh();
                        // handleClose()
                    }else{
                        Swal.fire("Something went wrong", "", "warning");
                    }
                } else {
                    setLoading(false)
                }
            }).catch(e => {
                setError(e.message)
                setLoading(false)
                console.log(e);
            })
        }
    }

    const handleUpdate = (e) => {
        // e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                brokerId: selBroker.value,
                "SubAccountId": selEntity.value,
                stockHeaderId: stockHeaderId,
                date: fixTimezoneOffset(pDate),
                stockDetails: [
                    {
                        stockId: stockId,
                        quantity: parseInt(Value.Quantity),
                        companyId: selScrip.value,
                        pricePerShare: parseInt(Value.PricePerShare),
                        totalCost: amount,
                        ratio: ratio.ratio,
                        instrumentTypeId: selScrip.companyType
                    },
                ],
            };

            updateRights(Sdata)
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            //setSuccessMessage(response.data._response.message);
                            setSuccess(true);


                            Swal.fire("Rights Share Updated Successfully!", "", "success");
                            handleReset();
                            //onRefresh();
                        }else{
                            Swal.fire("Something went wrong", "", "warning");
                        }
                    } else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in update");
                });
        }

    };

    const handleReset = () => {
        setCompanyList([]);
        setBrokerList([]);
        setSelScrip([])
        setSelBroker([])
        setSelEntity([])
        setAmount(0)
        setValue({ ...Value, Quantity: null, PricePerShare: '' })
        setPDate(null)
        setForm([]);
        setFormErr({});
        setRatio({ ratio: "" });
    }

    const disableButton = () => {
        return Value && selScrip && selEntity && selBroker && pDate

    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }
    return (
        <>
            <Modal show={showR} onHide={handleClose} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Right Shares</h5></Modal.Title>
                    {/* {success? <h1>{alert.msg}</h1>:null} */}
                </Modal.Header>
                {/* {success ?    
                    <Alert className="mt-2" variant="success" onClose={() => setSuccess(false)} dismissible>
                        <p>
                            {successMessage}
                        </p>
                    </Alert> : null} */}
                <Modal.Body>
                    {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
                    <Form className="border border-1 rounded p-3" >
                        <Row>
                            {/* <Col>
                                    <h4 className="text-center py-3">Stock Rights </h4>
                                </Col> */}
                        </Row>
                        <Row className="form-set">
                            {stockHeaderId === 0 || stockHeaderId === undefined ?
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selEntity}
                                        onChange={handleFamily}
                                        options={Entity}
                                        name="SubAccountId"
                                        isOptionDisabled={(option) => option.disabled === 0}
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selEntity}</div> : null}
                                </Form.Group>
                                :
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selEntity}
                                        onChange={handleFamily}
                                        options={Entity}
                                        name="SubAccountId"
                                        isDisabled="required"
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selEntity}</div> : null}
                                </Form.Group>
                            }
                            {stockHeaderId === 0 || stockHeaderId === undefined ?
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selBroker}
                                        onChange={handleBroker}
                                        options={brokerData}
                                        name="BrokerId"
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selBroker}</div> : null}
                                </Form.Group>
                                :
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selBroker}
                                        onChange={handleBroker}
                                        options={brokerData}
                                        name="BrokerId"
                                        isDisabled="required"
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selBroker}</div> : null}
                                </Form.Group>
                            }

                            {stockHeaderId === 0 || stockHeaderId === undefined ?
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selScrip}
                                        onChange={handleScrip}
                                        options={companyList}
                                        name="companyId"
                                        isOptionDisabled={handelDisableDD}
                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selScrip}</div> : null}
                                </Form.Group>
                                :
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selScrip}
                                        onChange={handleScrip}
                                        options={companyList}
                                        name="companyId"
                                        isDisabled="required"
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selScrip}</div> : null}
                                </Form.Group>
                            }
                        </Row>
                        <Row className="form-set">
                            <Form.Group className="col-md-4">
                                <Form.Label>Date<span className="text-danger h6">*</span></Form.Label>
                                <DatePicker className="form-control"
                                    placeholderText="Select Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    // maxDate={new Date()}
                                    minDate={new Date(buyDate)}
                                    onChange={handleDate}
                                    // onChange={(date)=>{  setFormErr({ ...formErr, dateofPurchase: '' }) ; setPDate(date)}}
                                    dateFormat="dd-MM-yyyy"
                                    name="Date"
                                    autoComplete='off'
                                    selected={pDate}
                                />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.date}</div> : null
                                }

                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label> Ratio </Form.Label>
                                <Form.Control type="text" name="ratio" min="0" autoComplete='off' placeholder="Enter Ratio" onChange={handleRatio} value={ratio.ratio} />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.ratio}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-md-2" >
                                <Form.Label>Quantity<span className="text-danger h6">*</span></Form.Label>
                                {/* <Form.Control type="Number" name="Quantity" onChange={handlevalue} value={Math.round(Value.Quantity)} min="0" placeholder="Enter Quantity" /> */}
                                <Cleave
                                    className='form-control'
                                    name="Quantity"
                                    placeholder="Enter Quantity"
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    value={Math.round(Value.Quantity)}
                                    onChange={handlevalue} />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.Quantity}</div> : null}
                            </Form.Group>
                            <Form.Group className="col-2" >
                                <Form.Label>Price<span className="text-danger h6">*</span></Form.Label>
                                {/* <Form.Control type="Number" name="PricePerShare" onChange={handlevalue} value={Value.PricePerShare} min="0" placeholder="Enter Price" /> */}
                                <Cleave
                                    className='form-control'
                                    name="PricePerShare"
                                    placeholder="Enter Price"
                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                    value={Value.PricePerShare}
                                    onChange={handlevalue} />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.PricePerShare}</div> : null}
                            </Form.Group>

                            <Form.Group className="col-2" >
                                <Form.Label>Amount </Form.Label>
                                <Form.Control type="text" name="TotalCost" disabled
                                    min="0" value={NumberSeperator(amount)} />
                                {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.amount}</div> : null}
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col md="12" className="text-center mt-3">
                                {stockHeaderId === 0 || stockHeaderId === undefined ? (

                                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }}
                                        disabled={!disableButton()}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }}
                                        disabled={!disableButton()} >
                                        Update
                                    </Button>
                                )}
                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>Clear
                                    </Button> : null}
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
            </Modal>
            {showrightsharepreview === true ?
                <Modal show={showrightsharepreview} onHide={() => handleClose(1)}>
                    <Modal.Header className="headerBlue" closeButton >
                        <Modal.Title className="mx-auto"><h5>Right Shares Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date : </div>
                                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Ratio : </div>
                                <div className="transactionDataValue">{ratio.ratio}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Quantity : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(Value.Quantity)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Price : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Value.PricePerShare)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount)}</div></div>
                        </div>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}

export default RightsShare;
