import React from 'react'
import ProfitabilityFinalTable from '../../../components/Report/Profitability/ProfitabilityFinalTable';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const ProfitabilityFinalTablePage = () => {

    const sidebarOpen = useSelector(res => res.sidebarReducer.openclosesidebar);

  return (
      <div>
          {sidebarOpen && <Sidebar />}
          <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
              <Navbar />
              <div>
                  <ProfitabilityFinalTable />
              </div>
          </div>
      </div>
  )
}

export default ProfitabilityFinalTablePage