/* eslint-disable no-unused-vars */
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';


import './App.css';
import Content from './components/Content/Content';
import Auth from './modules/Auth/index';



function App() {


  let token = Auth.getsubcriberId(); //Auth.getUserId()
  let userRole = Auth.getUserRoleID();
  const selector = useSelector(res=>res.sidebarReducer.sidebardata);

  if (window.location.pathname === '/Auth' || window.location.pathname === '/ResetPassword') {
    token = '';
  }

  
  return (
    <Router>
      <div className="App wrapper">

     {/* { (token && sidebarOpen ) && TwoFA === TwoFAAuth ? <Sidebar  userRole={userRole} route={setRouteData}/> : null }
        <div className={token && sidebarOpen ? "contentWrap" : "contentWrap1" }>
          {token === '' ? null
          : (token &&  TwoFA === TwoFAAuth ) ? <Navbar  openSidebar={openSidebar} token={token}/> : null} */}
          <div>
            <Content UserRole={userRole} userRoutes={selector}/>
          </div>
        </div>
      {/* </div> */}
    </Router>
  );
}

export default App;
