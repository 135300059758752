import React from 'react';
import LoansDepoFolio from '../../components/LoansDepositsFolio/LoansDepoFolio';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function LoansFolio () {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
        return (
            <div>
                {sidebarOpen && <Sidebar />}
                <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                    <div>
                    <LoansDepoFolio/>
                    </div>
                </div>
            </div>
        );
}

export default LoansFolio;

