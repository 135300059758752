import axios from "../../../modules/helpers/axiosInstance"
import Auth from "../../../modules/Auth/index"

// let ChannelmasterapiLocal = "https://localhost:5001/api/UserManagment"
let liveChannelmasterapi = "http://5.189.157.40:10023/api/UserManagment";

const userID = Auth.getsubcriberId();

const liveSubscribers = (pageNo,pageSize) => {
    return axios({
        method: 'GET',
        url: `${liveChannelmasterapi}/FetchLiveSubscribers?CP_id=${userID}&PageNo=${pageNo}&PageSize=${pageSize}`,
    });
}

const oldSubscribers = (pageNo, pageSize) => {
    return axios({
        method: 'GET',
        url: `${liveChannelmasterapi}/FetchOldMembers?CP_id=${userID}&PageNo=${pageNo}&PageSize=${pageSize}`,
    });
}

const trialSubscribers = (pageNo, pageSize) => {
    return axios({
        method: 'GET',
        url: `${liveChannelmasterapi}/FetchTrialMembers?CP_id=${userID}&PageNo=${pageNo}&PageSize=${pageSize}`,
    });
}

//history of payment single user
const historyInfo = (userCode) =>{
    return axios({
        method:"GET",
        url: `${liveChannelmasterapi}/FetchUsersSubscriptionHistory?userId=${userCode}`

    })
}

export{
    liveSubscribers,
    oldSubscribers,
    trialSubscribers,
    historyInfo
}