/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  FetchBrokerDropDownData,
  FetchCompanyDropDownData,
  EntityList,
  addBonus,
  updateBonus,
  FetchDate,
  AvilableQuantity
} from "../../../pages/Stocks/StocksAPI";
import {
  Form,
  Button,
  Row,
  Modal,
  Col,
  Tooltip, OverlayTrigger
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const Bonusmodal = (props) => {
  const { showB, onHideB, stockHeaderIdB, stockTransactionB, stockIdB, showbonusPreview } = props;
  const [stockDataNew, setstockDataNew] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selScrip, setSelScrip] = useState([]);
  const [BrokerList, setBrokerList] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [data, setData] = useState(0);
  const [narration, setNarration] = useState({ narration: null });
  const [ratio, setRatio] = useState({ ratio: null });
  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ttqty, setttqty] = useState(0);
  const [broker, setbroker] = useState([]);
  const [entityValue, setEntityVAlue] = useState([])
  const [buyDate, setBuyDate] = useState(null)
  const [error, setError] = useState('');


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      {`The Available quantity with this broker is ${ttqty}`}
    </Tooltip>
  );
  //   available quantity
  const getStockDetail = async (brokerId, subAccountId, companyId) => {
    setLoading(true)
    await AvilableQuantity(brokerId, subAccountId, companyId)
      .then((res) => {
        if (res.status === 200) {

          setttqty(res.data._response.data.remainingQuantity);
        } else {

          setError(res.data.message);
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  useEffect(() => {
    if (showB) {
      (async () => {
        await getCompanyDropDownData()
        await getBrokerDropDownData()
      })();
    }
  }, [selEntity])

  useEffect(async () => {
    let companyId = selScrip.value;
    let brokerId = selBroker.value;
    let subAccountId = selEntity.value;
    if (showB) {
      if (companyId && brokerId && subAccountId) {
        getStockDetail(brokerId, subAccountId, companyId);
        setttqty(0)
      }
    }
  }, [selScrip, selBroker, selEntity, showB]);
  const errHandel = () => {
    let formErr = {};

    let isValid = true;
    if (selScrip.length < 1) {
      isValid = false;
      formErr["selScrip"] = "Please Select Scrip!";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker!";
    }

    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity!";
    }

    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date !";
    }

    if (!data || data === 0) {
      isValid = false;
      formErr["data"] = "Please Fill This Field!";
    }
    if (data) {
      if (data < 0) {
        isValid = false;
        formErr["data"] = "Please Enter Positive Value !";
      }
    }


    setFormErr(formErr);
    return isValid;
  };

  // close model
  const handleClose = (val) => {
    setSuccess(false);
    onHideB(val);
    handleReset();
  };
  //handlechange
  const handleInput = (selected) => {
    setSelScrip(selected);
    setFormErr({ ...formErr, selScrip: "" });
    // disableButton()
  };
  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityVAlue(selected.value)
    setSelScrip([]);
    setSelBroker([]);
    setFormErr({ ...formErr, selEntity: "" });
    //disableButton()

  };
  const handleOption = (selected) => {
    setSelBroker(selected);
    setbroker(selected.value)
    setSelScrip("")
    setFormErr({ ...formErr, selBroker: "" });
    //disableButton()

  };
  const handleDate = (pDate) => {

    setPDate(pDate);
    setFormErr({ ...formErr, date: "" });
    // disableButton()

  };
  const handleValueQ = (e) => {
    // const { name, value } = e.target;
    setData(e.target.rawValue);
    setFormErr({ ...data, data: "" });
    // disableButton()

  };
  const handleValueN = (e) => {
    const { name, value } = e.target;
    setNarration({ ...narration, [name]: value });
    setFormErr({ ...narration, narration: "" });
    // disableButton()

  };
  const handleRatio = (e) => {
    const { name, value } = e.target;
    setRatio({ ...ratio, [name]: value.replace(/[^0-9:]/ig, '') });
    setFormErr({ ...formErr, ratio: '' })
  };

  useEffect(() => {
    if (showB) {
      getEntityList();
    }

  }, [showB]);

  // edit bonus data
  useEffect(() => {
    if (stockHeaderIdB !== 0) {

      if (stockTransactionB !== undefined) {
        setstockDataNew(stockTransactionB)
        setNarration({ narration: stockTransactionB.narration })
        setSelBroker({
          value: stockTransactionB.brokerId,
          label: stockTransactionB.broker.brokerName,
        });
        setbroker(stockTransactionB.brokerId);

        setSelEntity({
          value: stockTransactionB.subAccountId,
          label: stockTransactionB.subAccount.subAccountName,
        });

        setEntityVAlue(stockTransactionB.subAccountId);

        stockTransactionB.stockDetails && stockTransactionB.stockDetails.map((t) => {
          setData(t.quantity)
          setPDate(new Date(t.date));
          setRatio({ ratio: t.ratio })
          const c = t.company = {
            value: t.companyId,
            label: t.company.companyName,
            companyType: t.instrumentTypeId
          };
          setSelScrip(c)
        })

      }

    }
  }, [stockTransactionB])

  // for buy date
  useEffect(() => {
    if (showB) {
      if (stockHeaderIdB === 0 || stockHeaderIdB === undefined) {
        setPDate(null)
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
      if (stockHeaderIdB !== 0 && stockTransactionB !== undefined) {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;

        if (companyId && brokerId && subAccountId) {
          getBuyDate(subAccountId, companyId, brokerId);
        }
      }
    }
  }, [selScrip, selBroker, selEntity, showB]);
  //getbrockerlist
  const getBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchBrokerDropDownData(sub_Id)
        .then((res) => {
          setLoading(false)
          // console.log(res, "brokerlisy");
          if (res.data._response.status === 1) {
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setLoading(false);
            setBrokerList(brokersData);
          } else {
            setBrokerList([]);
            setLoading(false)
            //setError(res.data.message);
          }
        })
        .catch((e) => {
          // setError(e.message);
          setBrokerList([]);
          setLoading(false);
          console.log(e, "brokerlist error");
        });
    }
  };
  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }
  //getcompanylist by group
  // get dropDown Company Data
  const getCompanyDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await FetchCompanyDropDownData(sub_Id)
        .then((res) => {
          // console.log(res,'company list');
          if (res.data._response.status === 1) {
            let UnlistedCompany = [];
            let ListedCompany = [];

            let company = res.data._response.data.companyName;
            company &&
              company.filter(data => data.instrumentTypeId === 1).map(i => {
                return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });
            company &&
              company.filter(data => data.instrumentTypeId === 10).map(i => {
                return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
              });

            const groupedOptions = [
              {
                label: 'Listed Companies',
                options: ListedCompany,
              },
              {
                label: 'UnListed Companies',
                options: UnlistedCompany,
              },
            ];
            setLoading(false);
            setCompanyList(groupedOptions);
          } else {
            setCompanyList([]);
            setLoading(false);
            // setError(res.data.message);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
          // setError(e.message);
        });
    }
  };



  //getEntityList
  const getEntityList = async () => {
    setLoading(true)

    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                });
              });
            setEntity(entityData);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });


  };
  // get buy date
  const getBuyDate = async (sub_id, co_id, bro_id) => {
    setLoading(true)
    await FetchDate(sub_id, co_id, bro_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false)
            setBuyDate(res.data._response.data.fetchDate)
          }
          else {
            setLoading(false)
            setError(res.data.message);
          }
        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message);
      });
  };
  //submit form
  const handleSubmit = (e) => {
    //debugger
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        brokerId: selBroker.value,
        narration: narration.narration,
        date: fixTimezoneOffset(pDate),
        // date :moment.tz(pDate,'2000', 'HHmm', 'Asia/Kolkata'),
        // date: formatDate(pDate),

        subAccountId: selEntity.value,
        stockDetails: [
          {
            quantity: parseInt(data),
            companyId: selScrip.value,
            ratio: ratio.ratio,
            instrumentTypeId: selScrip.companyType
          },
        ],
      };

      addBonus(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);
              Swal.fire("Success!", "Bonus Added Successfully", 'success');
              handleReset();
              // onRefresh();
              //handleClose();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in submit");
        });
    }
  }

  const handleUpdate = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        brokerId: selBroker.value,
        narration: narration.narration,
        date: fixTimezoneOffset(pDate),
        subAccountId: selEntity.value,
        stockHeaderId: stockHeaderIdB,
        stockDetails: [
          {
            stockId: stockIdB,
            quantity: parseInt(data),
            companyId: selScrip.value,
            ratio: ratio.ratio,
            instrumentTypeId: selScrip.companyType
          },
        ],
      };

      updateBonus(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);

              Swal.fire("Success!", "Bonus Updated Successfully", "success");

              // onRefresh();
              //handleClose();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  //reset field
  const handleReset = () => {
    setBrokerList([]);
    setCompanyList([]);
    setSelEntity([]);
    setSelScrip([]);
    setSelBroker([]);
    setData(0);
    setNarration({ narration: "" })
    setPDate(null);
    setFormErr({});
    setRatio({ ratio: "" })
    setttqty(0)
  };

  const disableButton = () => {
    return pDate && selEntity &&
      selScrip && selBroker
      && data && narration
  }

  const fixTimezoneOffset = (pDate) => {
    if (!pDate) return "";
    return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
  }



  return (
    <>
      <Modal show={showB} onHide={handleClose} size="lg" backdrop="static" centered>
        <Modal.Header className="headerBlue" closeButton >
          {stockHeaderIdB === 0 || stockHeaderIdB === undefined ? (
            <Modal.Title className="mx-auto"><h5>Add Bonus</h5></Modal.Title>
          ) : (
            <Modal.Title className="mx-auto"><h5>Update  Bonus</h5></Modal.Title>
          )}
        </Modal.Header>
        {/* {success ? (
          <Alert
            className="mt-2"
            variant="success"
            onClose={() => setSuccess(false)}
            dismissible
          >
            <p>{successMessage}</p>
          </Alert>
        ) : null} */}
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : null}

          <div className="mt-2 p-6">
            <Form
              className="border border-1 rounded p-3"

            >
              {/* <Row>
            <Col>
              <h4 className="text-center py-3">Add Bonus</h4>
            </Col>
          </Row> */}
              <Row className="form-set">
                {stockHeaderIdB === 0 || stockHeaderIdB === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isDisabled="required"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>}

                {stockHeaderIdB === 0 || stockHeaderIdB === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleOption}
                      options={BrokerList}
                      name="brokerId"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                    <Select
                      value={selBroker}
                      onChange={handleOption}
                      options={BrokerList}
                      name="brokerId"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selBroker}
                      </div>
                    ) : null}
                  </Form.Group>
                }
                {stockHeaderIdB === 0 || stockHeaderIdB === undefined ?
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                    <Select
                      value={selScrip}
                      onChange={handleInput}
                      options={companyList}
                      name="companyId"
                      isOptionDisabled={handelDisableDD}
                      styles={{
                        option: (data) => ({
                          ...data,
                          margin: 0,
                          padding: '3px 12px',
                        }),
                        groupHeading: (base) => ({
                          ...base,
                          flex: '1 1',
                          color: '#000',
                          margin: 0,
                          padding: '7px 2px',
                          // border: `2px solid `,
                          background: "#8bc3ff",
                          fontSize: 'small',
                          fontWeight: 500
                        }),
                      }}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                  :
                  <Form.Group className="col-md-4">
                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>

                    <Select
                      value={selScrip}
                      onChange={handleInput}
                      options={companyList}
                      name="companyId"
                      isDisabled="required"
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selScrip}
                      </div>
                    ) : null}
                  </Form.Group>
                }
              </Row>

              <Row className="form-set">
                <Form.Group className="mb-6 col-md-4">
                  <Form.Label>No of Shares<span className="text-danger h6">*</span></Form.Label>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip} >
                    <Cleave
                      className="form-control"
                      name="data"
                      autoComplete="off"
                      options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                      value={data}
                      onChange={handleValueQ}
                    />
                  </OverlayTrigger>
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.data}
                    </div>
                  ) : null}
                  <Tooltip title={`The Available quantity with this broker is:`} >

                  </Tooltip>

                </Form.Group>

                <Form.Group className="col-md-4">
                  <Form.Label>Date of allotment<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    maxDate={new Date()}
                    minDate={new Date(buyDate)}
                    onChange={handleDate}
                    dateFormat="dd-MM-yyyy"
                    // name="date"
                    name="dateofPurchase"
                    autoComplete="off"
                    selected={pDate}

                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.date}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4" >
                  <Form.Label> Ratio </Form.Label>
                  <Form.Control type="text" name="ratio" min="0" autoComplete="off" placeholder="Enter Ratio" onChange={handleRatio} value={ratio.ratio} />
                </Form.Group>
              </Row>
              <Form.Group
                className="col-12"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Comments/Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  name="narration"
                  value={narration.narration || ""}
                  onChange={handleValueN}
                  rows={2}
                />
                {/* {formErr ? (
                  <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                    {formErr.narration}
                  </div>
                ) : null} */}
              </Form.Group>
              <Row className="form-set">
                <Col md="12" className="text-center mt-3">
                  {stockHeaderIdB === 0 || stockHeaderIdB === undefined ? (

                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                      onClick={() => { handleSubmit(); }}
                      disabled={!disableButton()}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()} >
                      Update
                    </Button>
                  )}
                  {stockHeaderIdB === 0 || stockHeaderIdB === undefined ?
                    <Button
                      variant="secondary"
                      className="mx-2 w100"
                      type="reset"
                      onClick={handleReset}
                    >
                      Clear
                    </Button> : null}
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>

      </Modal>

      {showbonusPreview === true ?
        <Modal show={showbonusPreview} onHide={() => handleClose(1)}>
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Bonus Transaction Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                <div className="transactionDataValue">{selScrip.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">No of Shares : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(data)}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Date of Purchase : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio.ratio == null ? "-" : ratio.ratio}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Comments/Remarks : </div>
                <div className="transactionDataValue">{narration.narration == null ? "-" : narration.narration}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </>
  );
};

export default React.memo(Bonusmodal);
