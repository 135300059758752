import React from 'react';

import Select from 'react-select';


const Dropdown = (props) => {

    return (
        <Select
            value={props.value}
            name={props.name}
            options={props.options}
            onChange={props.onChange}
            className={props.className}
            searchable={props.searchable}
            clearable={props.clearable}
            noResultsText={props.noResultsText}
            onInputChange={props.onInputChange}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
            styles={props.styles}
        />
    )
}

export default Dropdown;