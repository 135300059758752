import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Button, Col, Container, Row } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import Swal from 'sweetalert2';

import Loader from '../loadingAnimation/index';
import { Tooltip } from "@material-ui/core";

import Auth from '../../modules/Auth/index';
import axios from '../../modules/helpers/axiosInstance';
import settings from '../../modules/config/settings';

import { setIsTwoFAEnabled } from '../../redux/action/userData';
const Content = (props) => {

  const [isTwoFAEnable, setIsTwoFAEnable] = useState('');
  const [code, setCode] = useState('');
  const [QRImage, setQRImage] = useState('');
  const [key, setKey] = useState('');
  const [loader, setLoader] = useState(false);
  const [formErr, setFormErr] = useState({});

  const userID = Auth.getUserId();
  // console.log(isTwoFA,"useid");

  useEffect(() => {
    getQRImg();
    props.twofactorEnabled ? setIsTwoFAEnable(true) : setIsTwoFAEnable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQRImg = () => {
    setLoader(true);

    axios.get(`${settings.API_URL}/TwoFactorAuthentication/GetQRCode/${userID}`)
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {

            let qrImg = res.data._response.data.qrCodeSetupImageUrl;
            let QRkey = res.data._response.data.manualEntryKey;
            setQRImage(qrImg);
            setKey(QRkey)
          } else {
            setQRImage('')
            setKey('')
          }
        } else {
          setQRImage('')
          setKey('')
        }
      }).catch((e) => {
        console.log(e, 'error in 2FA')
        setLoader(false);
      })
  }
  const handleChange = (e) => {
    setCode(e.target.value);
    setFormErr({})
  }
  const validation = () => {
    let isValid = true;
    let formErr = {};
    if (code.trim() === '' || !code) {
      formErr['code'] = 'Please enter the code'
      isValid = false;
    }
    setFormErr(formErr);
    return isValid;
  }

  const onSubmit = () => {
    // e.preventDefault();
    const isValid = validation();
    if (isValid) {
      setLoader(true);
      axios.get(`${settings.API_URL}/TwoFactorAuthentication/Enable/${code}/${userID}`)
        .then(async res => {
          if (res.status === 200) {
            setLoader(false);
            if (res.data._response.status === 1) {
              Swal.fire(res.data._response.message, '', 'success');
              await props.setIsTwoFAEnabled(res.data._response.data);
              await setIsTwoFAEnable(res.data._response.data);
              await setCode('');
            }
            else {
              setLoader(false);
              Swal.fire(res.data._response.message, '', 'warning');
            }
          }
        })
        .catch(err => {
          setLoader(false);
          Swal.fire("Invalid code details", "", "info");
          console.log("err in 2fa", err)
        })
    }
  }

  return (
    <Container fluid="lg" className="col-md-8 shadow p-4 mb-5 bg-white rounded">
      {loader ? <Loader /> : null}
      <div className="ms-panel">
        <div className="ms-panel-body">
          <div className=" greyHeader "><h5>Two Factor Authentication ({props.twofactorEnabled ? 'Enabled' : 'Disabled'})</h5></div><br />
          <div className="">
            <label>
              To setup two factor authentication, you first need to download
              Google Authenticator from:
            </label>
          </div>
          <div className="">
            <div>
              <br />
              <a className="text-decoration-none" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US" target="_blank" rel="noreferrer" >Google Authenticator for Android</a>
              <br />
              <a className="text-decoration-none" href="https://apps.apple.com/us/app/google-authenticator/id388497605" rel="noreferrer" target="_blank" >Google Authenticator for IOS</a>
            </div><br />
            <div className="">
              <label>Then scan the below barcode or, if you are not able to scan barcode, you can enter the "Security Key" manually. </label>
              <label>Secret Code: </label>
              <div className="p-5">
                <Row ><p> Key : &nbsp;<span className="text-danger overflow-auto"> {key}</span> &nbsp;
                  {key !== '' && key != null
                    ? <button className="btn btn-primary btn-sm "
                      onClick={() => { navigator.clipboard.writeText(key) }} title="Copy To Clipboard"><FiCopy />
                      <Tooltip title="Copy To Clipboard" aria-label="info"><i className="fas fa-clipboard fa-lg mr-1" style={{ fontSize: "16px" }}></i></Tooltip>
                    </button>
                    : null}
                </p>
                </Row>
                <br />
                <Row >
                  <Col md={6}>
                    <div className="">
                      {/* <img src="https://chart.googleapis.com/chart?cht=qr&chs=246x246&chl=otpauth%3A%2F%2Ftotp%2FExchangeApp%3Fsecret%3DIRYTKSBQFU4TGMZWGE3TQ%26issuer%3DExchange"/> */}
                      <img src={QRImage} alt="qrImg" />
                    </div>
                  </Col>
                  <Col>
                    {/* <div className="mb-2">Email <label className="text-danger">&nbsp;*</label> </div>
                    <div>
                        <input type="email" className="form-control" name="email" onChange={(e)=>handleChange(e)}></input>
                    </div>
                    <div className="pt-4">Password <label className="text-danger">&nbsp;*</label></div>
                    <div>
                        <input type="text" className="form-control" name="password" onChange={(e)=>handleChange(e)}></input>
                    </div> */}
                    <div className="mb-2">6 digits code genderated by Google Authenticator <label className="text-danger">&nbsp;*</label> </div>
                    <div>
                      <input type="text" value={code} className="form-control" onChange={(e) => handleChange(e)} name="code" min="6" max="6"></input>
                      {formErr ? (
                        <div
                          className="mt-2"
                          style={{ fontSize: 12, color: "red" }}
                        >
                          {formErr.code}
                        </div>
                      ) : null}
                    </div>
                    <div className="pt-4">
                      <Button className={isTwoFAEnable === true ? "twoFAEnable" : "btn-success"} onClick={onSubmit}>{props.twofactorEnabled === true ? "Disable" : "Enable"}</Button>
                    </div>

                  </Col>
                </Row>
              </div>
              <div className="fw-bold">Note :</div>
              <div className="">Save this secret key for future reference</div>
              <div className="">If you do not know to use Google Authenticator, please <a class="text-decoration-none" href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid" target="_blank" rel="noreferrer" > click here</a>.</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStoreToProps = (state) => {
  return {
    twofactorEnabled: state.userData.twofactorEnabled
  }
}
const mapDispatchToProps = dispatch => ({
  setIsTwoFAEnabled: (isEnable) => dispatch(setIsTwoFAEnabled(isEnable))
})
export default connect(mapStoreToProps, mapDispatchToProps)(Content);