/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { VerifyEmailData } from './LoginRegisterAPI';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';

const EmailVerification = (props) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        verifyEmail: true,
        token: "",
    });
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const user = searchParams.get("user")
        setData({ ...data, token: user })
    }, [])

    const checkEmailVerification = (e) => {
        e.preventDefault();
        // setData({...data, token: user})
        let data1 = {
            token: data.token
        }
        // debugger
        VerifyEmailData(data1).then((res) => {
            if (res.status === 200) {
                navigate('/Login')
                // console.log(res, " : Res Login")
                swal("Email Verified successfully", "", "success")
            }
            else {
                // console.log(e, " : Res Login Err")
                swal("Not verified", "", "error");
            }
        })
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Modal show={data.verifyEmail} id="verifyEmail" className="modal-min verifyEmailModal animate__animated animate__zoomIn" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    <i className="flaticon-secure-shield d-block" />
                    <h1>Verify your email</h1>
                    <form onSubmit={checkEmailVerification}>
                        <button type="submit" className="btn btn-primary shadow-none">Click here to Verify Email</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EmailVerification