/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
    brokerList,
    CompanyList,
    EntityList,
    addStocks,PostUnlistedScripBySubId
} from "../../../pages/Stocks/StocksAPI";
import {

    Form,
    Button,
    Row,
    Modal,
    Col,

} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const NewBuy = (props) => {

    const { showN, onHideN, onRefresh } = props;

    const [companyList, setCompanyList] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [pDate, setPDate] = useState(new Date());
    const [dataValues, setDataValues] = useState([]);
    const [pricepershare, setPricepershare] = useState([]);
    const [totalBrokerage, setTotalBrokerage] = useState();
    const [recomndedBy, setRecomndedBy] = useState([]);
    const [esthldp, setEsthldp] = useState({esthldp:''})
    const [unlistedComp, setUnlistedComp] = useState('');
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    const [loading, setLoading] = useState(false)

    const [formErr, setFormErr] = useState({});
    const [showModal, setShowModal] = useState(false);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    // seperation by comma
    //   const NumberOnlySeperator = (input) => {
    //     return new Intl.NumberFormat('en-IN').format(input)
    //   }


    // useEnterKeyListener({
    //     querySelectorToExecuteClick: "#submitButton"
    // });
    // const handleOpenModal = () => setShowModal(true);
    const handleOpenModal = () => setShowModal(!showModal);

    const handleCloseModal = () => {
        setShowModal(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }

    const handleClose = () => {
        onHideN();
        handleReset();
        setFormErr({});
    };

    useEffect(() => {
        if (dataValues.quantity > 0 && dataValues.totalCost > 0) {
            let totalCost = Number(dataValues.totalCost) / Number(dataValues.quantity);
            setPricepershare(totalCost.toFixed(2));
        } else {
            setPricepershare(0);
        }

        if (dataValues.amount > 0 && dataValues.quantity > 0) {
            handleBrokerage()
        } else {
            setTotalBrokerage(0);
        }

    }, [dataValues]);


    const handleBrokerage = () => {
        const total = Number(dataValues.quantity) * Number(dataValues.amount);
        setTotalBrokerage(total.toFixed(2));
    }


    useEffect(() => {
        if (showN) {
            (async () => {
                await getBrokerList();
                await getCompanyList();
                await getEntityListt();
            })();
        }
    }, [showN]);

    //getbrockerlist by entity id
    const getBrokerList = async () => {
        // let sub_id = selEntity.value;
        setLoading(true)
        await brokerList()
            .then((res) => {
                // setLoading(false)
                // console.log(res, "brokerlisy");
                if (res.status === 200) {
                    let brokers = res.data._response.data.brokerDetails;
                    let brokersData = [];
                    brokers &&
                        brokers.map((data) => {
                            return (brokersData.push({
                                value: data.brokerId,
                                label: data.brokerName,
                            }))
                        });
                    setBrokerList(brokersData);
                    setLoading(false)
                } else {
                    setLoading(false)
                    //setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false);
                console.log(e, "brokerlist error");
            });
    };



    //getcompanylist by group
    const getCompanyList = async (unListedParam) => {
        setLoading(true)
        await CompanyList()
            .then((res) => {
                if (res.status === 200) {
                    //
                    setLoading(false)
                    let UnlistedCompany = [];
                    let ListedCompany = [];

                    let company = res.data._response.data.companyDetails;
                    company &&
                        company.filter(data => data.instrumentTypeId === 1).map(i => {
                            return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });
                    company &&
                        company.filter(data => data.instrumentTypeId === 10).map(i => {
                            return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                        });
                    if (unListedParam === 1) {
                        let lastElement = ListedCompany[ListedCompany.length - 1];
                        setSelScrip(lastElement)
                    }
                    const groupedOptions = [
                        {
                            label: 'Listed Companies',
                            options: ListedCompany,
                        },
                        {
                            label: 'UnListed Companies',
                            options: UnlistedCompany,
                        },
                    ];
                    setCompanyList(groupedOptions);
                    setLoading(false)

                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log(e, 'error in companyList');
                // setError(e.message);
            });
    };

    //getEntityList
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    //
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        })
                    setEntity(entityData);
                    setLoading(false)
                } else {
                    // setError(res.data.message);
                    setLoading(false)
                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)
            });


    };

    //handel disable listed companies
    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }
    //handlechange
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: "" });
    };
    const handleFamily = (selected) => {
        setSelEntity(selected);
        // setEntityVAlue(selected.value)
        setFormErr({ ...formErr, selEntity: "" });
    };
    const handleOption = (selected) => {
        setSelBroker(selected);
        // setbroker(selected.value)
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: "" });
    };

    const handleValues = (e) => {
        const { name, rawValue } = e.target;
        setDataValues({ ...dataValues, [name]: rawValue });

        //error state clear code
        if (name === "quantity" && rawValue !== null) {
            setFormErr({ ...formErr, quantity: '' })
        } else if (name === "totalCost" && rawValue !== null) {
            setFormErr({ ...formErr, totalCost: '' })
        }
        else if (name === "amount" && rawValue !== null) {
            setFormErr({ ...formErr, amount: '' })
        }
        else if (name === "target" && rawValue !== null) {
            setFormErr({ ...formErr, target: '' })
        }
        else if (name === "stopLoss" && rawValue !== null) {
            setFormErr({ ...formErr, stopLoss: '' })
        }
        else if (name === "esthldp" && rawValue !== null) {
            setFormErr({ ...formErr, esthldp: '' })
        } else if (name === "charges" && rawValue !== null) {
            setFormErr({ ...formErr, charges: '' })
        }
    }

    const handleText = (e) => {
        const { name, value } = e.target;
        setRecomndedBy({ ...recomndedBy, [name]: value })
        setFormErr({ ...formErr, recomndedBy: '' })
    }
    const handleEstHld = (e) => {
        const { name, value } = e.target;
        setEsthldp({ ...esthldp, [name]: value })
        setFormErr({ ...formErr, esthldp: '' })
    }

    const handleReset = () => {
        setSelScrip([]);
        setSelBroker([]);
        setSelEntity([]);
        setDataValues({ quantity: "", totalCost: '', amount: '', target: '', stopLoss: '', esthldp: '', charges: '' });
        setTotalBrokerage([]);
        setPricepershare([]);
        setRecomndedBy({ recomndedBy: '' })
        setPDate(null);
        setFormErr({})
    };
    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    }

    //handling unlisted modal 
    const handleUnlisted = (e) => {
        const { value } = e.target;
        setUnlistedComp(value);
        setUnlistedCompErr('')
    }
    //submit unlisted modal 
    const submitUnlisted = (e) => {
        e.preventDefault();

        let spaces = /^ *$/;
        // const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let compStr = spaces.test(unlistedComp.trim());
        // let checkSpecialChar = specialChars.test(unlistedComp.trim());
        if (!compStr) {
            // replace the "partly paid" string in company name for backend functionality   
            let replceStr = unlistedComp.replace(/PARTLY PAID/i, 'Partly Paid');

            let data = {
                "companyName": replceStr,
                "InstrumentTypeId": 1,
                subAccountId: selEntity.value
            }
            setLoading(true);
            PostUnlistedScripBySubId(data)
                .then(res => {
                    setLoading(false)
                    if (res.status === 200) {
                        if(res.data._response.status === 1){
                            // const lastEle = 1
                            let t = res.data._response.data.companyName
                            let v = res.data._response.data.companyId
                            let comp = { label: t, value: v }
                            // companyList.unshift(comp);
                            setSelScrip(comp)
                            getCompanyList();
                            setUnlistedComp('');
                            setUnlistedCompErr('');
                            handleCloseModal()
                            // swal("Added successfully!", "", 'success');
                        }else{
                            Swal.fire("Something went wrong", "", "warning");
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setLoading(false)
                    console.log(e, "error in unlisted");
                })

        } else {
            setUnlistedCompErr("Company Name Required!")
        }
    }


    const Control = ({ children, ...props }) => (

        <components.Control {...props}>
            <span onMouseDown={handleOpenModal} className="m-0" title="Add New Partly Paid scrip">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    )

    //error handling 
    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select Scrip!";
        }
        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }
        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }
        if (!dataValues.totalCost || dataValues.totalCost === 0) {
            isValid = false;
            formErr["totalCost"] = "Please Fill TotalCost!";
        }
        if (!dataValues.quantity || dataValues.quantity === 0) {
            isValid = false;
            formErr["quantity"] = "Please Fill Quantity!";
        }
        // if (dataValues.quantity ) {
        //     let qvalue = parseFloat(dataValues.quantity ); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["quantity"] = "Please enter only integer value!";
        //     }
        //   }
        if (!dataValues.charges || dataValues.charges === 0) {
            isValid = false;
            formErr["charges"] = "Please Fill Other Charges!";
        }
        if (dataValues.charges) {
            if (dataValues.charges < 0) {
                isValid = false;
                formErr["charges"] = "Please Enter Positive Value !";
            }
        }

        setFormErr(formErr);
        return isValid;
    }

    // handling submit
    const handleSubmit = (e) => {
        // ;
        e.preventDefault();
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            let listed = {};
            let totalAmountDueFrom = parseInt(dataValues.totalCost) + parseFloat(dataValues.charges);
            listed = {
                instrumentId: 1,
                instrumentTypeId: 1,
                date: fixTimezoneOffset(pDate),
                subAccountId: selEntity.value,
                brokerId: selBroker.value,
                otherCharges: parseFloat(dataValues.charges),
                totalAmountDue: totalAmountDueFrom,
                stockDetails: [
                    {
                        transactStockType: "Buy",
                        companyId: selScrip.value,
                        quantity: parseInt(dataValues.quantity),
                        pricePerShare: parseInt(pricepershare),
                        totalBrokerage: parseInt(totalBrokerage),
                        totalCost: parseInt(dataValues.totalCost),
                        brokeragePerShare: selBroker.value === 1 ? 0 : dataValues.amount === undefined ? 0 : parseInt(dataValues.amount),
                        target: dataValues.target,
                        stopLoss: dataValues.stopLoss,
                        estHoldingPeriod: esthldp.esthldp,
                        recommendedBy: recomndedBy.recomndedBy,
                        instrumentTypeId: selScrip.companyType
                    }
                ],

            }

            addStocks(listed).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    if(res.data._response.status === 1){
                        Swal.fire("Added successfully!", "", "success");
                        handleReset();
                        onRefresh();
                        // setSuccessMessage(res.data._response.message);
                        // setSuccess(true);
                        // clearForm();
                    } else {
                        Swal.fire("Something went wrong", "", "warning");
                    }
                } else {
                    setLoading(false);
                }
            }).catch(e => {
                setLoading(false)
                console.log(e, "res ");
            })

        }
    }

    const disableButton = () => {
        return pDate && selEntity &&
            selScrip && selBroker && pricepershare

    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }

    return (
        <div>
            <Modal show={showN} onHide={handleClose} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Buy</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3" onSubmit={handleSubmit}>

                            <Row className="form-set">
                                <Form.Group className="col-md-4">
                                    <Form.Label>Entity <span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selEntity}
                                        onChange={handleFamily}
                                        options={Entity}
                                        name="subAccountId"
                                        isOptionDisabled={(option) => option.disabled === 0}
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selEntity}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Broker <span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selBroker}
                                        onChange={handleOption}
                                        options={BrokerList}
                                        name="brokerId"
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.selBroker}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Scrip <span className="text-danger h6">*</span></Form.Label>

                                    <Select
                                        components={{ Control }}
                                        value={selScrip}
                                        onChange={handleInput}
                                        options={companyList}
                                        name="companyId"
                                        isOptionDisabled={handelDisableDD}
                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selScrip}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Quantity <span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control type="Number" name="quantity" value={Math.round(dataValues.quantity)} autoComplete="off" placeholder="Enter quantity.."
                                        onChange={handleValues} /> */}
                                    <Cleave
                                        className='form-control'
                                        name="quantity"
                                        autoComplete="off"
                                        placeholder="Enter quantity.."
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={(dataValues.quantity)}
                                        onChange={handleValues} />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.quantity}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Total Cost <span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control type="Number" name="totalCost" min="0" value={dataValues.totalCost} onChange={handleValues} placeholder="Enter total cost.." /> */}
                                    <Cleave
                                        className='form-control'
                                        name="totalCost"
                                        autoComplete="off"
                                        placeholder="Enter total cost.."
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={dataValues.totalCost}
                                        onChange={handleValues} />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.totalCost}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Price </Form.Label>
                                    <Form.Control type="text" name="price" value={NumberSeperator(pricepershare)} min="0" disabled />
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Brokerage / Share </Form.Label>
                                    {/* <Form.Control type="Number" name="amount" value={selBroker.value === 1 ? 0 : dataValues.amount} min="0" autoComplete="off" placeholder="Enter amount"
                                        onChange={handleValues} disabled={selBroker.value === 1} /> */}
                                        <Cleave
                                        className='form-control'
                                        name="amount" 
                                        autoComplete="off"
                                        placeholder="Enter amount"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={selBroker.value === 1 ? 0 : dataValues.amount}
                                        onChange={handleValues}
                                        disabled={selBroker.value === 1} />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.amount}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Total Brokerage </Form.Label>
                                    <Form.Control type="text" name="totalBrokerage" min="0" value={NumberSeperator(totalBrokerage)} disabled />
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date <span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker className="form-control"
                                        placeholderText="Select start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        maxDate={new Date()}
                                        onChange={handleDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete='off'
                                        selected={pDate}
                                    />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.date}</div> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-3" >
                                    <Form.Label>Other Charges <span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control type="Number" name="charges" value={dataValues.charges} onChange={handleValues}
                                        placeholder="Enter Other Charges.." /> */}
                                         <Cleave
                                        className='form-control'
                                        name="charges"
                                        placeholder="Enter Other Charges.."
                                        options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                                        value={dataValues.charges}
                                        onChange={handleValues}/>
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.charges}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label>Target</Form.Label>
                                    {/* <Form.Control type="Number" name="target" min="0" value={dataValues.target} placeholder="Enter target.."
                                        onChange={handleValues} /> */}
                                         <Cleave
                                        className='form-control'
                                        name="target"
                                        placeholder="Enter target.."
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={dataValues.target}
                                        onChange={handleValues}/>
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.target}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label>Stop Loss</Form.Label>
                                    {/* <Form.Control type="Number" name="stopLoss" min="0" value={dataValues.stopLoss} placeholder="Enter stop loss.."
                                        onChange={handleValues} /> */}
                                        <Cleave
                                        className='form-control'
                                        name="stopLoss"
                                        placeholder="Enter stop loss.."
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={dataValues.stopLoss}
                                        onChange={handleValues}/>
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.stopLoss}</div> : null}
                                </Form.Group>
                                <Form.Group className="col-md-3" >
                                    <Form.Label>Est Holding Period </Form.Label>
                                    <Form.Control type="text" name="esthldp" value={esthldp.esthldp} autoComplete="off" placeholder="Enter Est holding period"
                                        onChange={handleEstHld} />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.esthldp}</div> : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-12" >
                                    <Form.Label>Recommended By </Form.Label>
                                    <Form.Control type="text" name="recomndedBy" value={recomndedBy.recomndedBy}
                                        onChange={handleText} autoComplete="off" />
                                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.recomndedBy}</div> : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    <Button variant="primary" className="mx-2 w100" id="submitButton" type='submit' disabled={!disableButton()}>
                                        Save
                                    </Button>
                                    <Button variant="secondary" className="mx-2 w100" id="submitButton" type="reset" onClick={handleReset}>
                                        clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
            <>
                <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" backdrop="static" centered>
                    <Modal.Header className='headerBlue' closeButton>
                        <Modal.Title className="mx-auto"><h5>New Partly Paid Scrip</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Company Name</label>
                                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )

}
export default NewBuy;