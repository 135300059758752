/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  EntityList,
} from "../../../pages/Stocks/StocksAPI";
import {
  Form,
  Button,
  Row,
  Modal,
  Col,
  Tooltip, OverlayTrigger
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
import Cleave from 'cleave.js/react';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'
import { addMutualFundBonus, updateMutualFundBonus, GetCompanyAndBroker, GetMutualFundSellCompanies, AvailableMFSellQuantity, GetMutualFundFolioNo } from '../../../pages/MutualFund/MutualFundAPI'

const Bonusmodal = (props) => {
  const { showB, onHideB, MFIdBonus, MFTransactionB, showbonusPreview } = props;
  const [stockDataNew, setstockDataNew] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selScrip, setSelScrip] = useState([]);
  const [BrokerList, setBrokerList] = useState([]);
  const [selBroker, setSelBroker] = useState([]);
  const [TypeList, setTypeList] = useState([{
    label: 'SIP', value: 41
  }, { label: 'Lumpsum', value: 42 }]);
  const [selType, setSelType] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [pDate, setPDate] = useState(null);
  const [data, setData] = useState({ units: null });
  const [narration, setNarration] = useState({ narration: null });
  const [ratio, setRatio] = useState({ ratio: null });
  const [formErr, setFormErr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ttqty, setttqty] = useState(0);
  const [broker, setbroker] = useState([]);
  const [entityValue, setEntityVAlue] = useState([])
  const [buyDate, setBuyDate] = useState(null)
  const [error, setError] = useState('');
  const [selfolioNumber, setSelFolioNumber] = useState([]);
  const [folioNumber, setFolioNumber] = useState([]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} >
      {`The Available units with this broker is ${ttqty ? ttqty.toFixed(4) : 0}`}
    </Tooltip>
  );

  useEffect(() => {
    if (showB) {
      (async () => {
        await getCompanyBrokerDropDownData()
      })();
    }
  }, [selEntity])


  const errHandel = () => {
    let formErr = {};

    let isValid = true;
    if (selScrip.length < 1) {
      isValid = false;
      formErr["selScrip"] = "Please Select Scrip";
    }
    if (selType.length < 1) {
      isValid = false;
      formErr["selType"] = "Please Select Type";
    }
    if (selBroker.length < 1) {
      isValid = false;
      formErr["selBroker"] = "Please Select Broker";
    }

    if (selEntity.length < 1) {
      isValid = false;
      formErr["selEntity"] = "Please Select Entity";
    }

    if (!pDate) {
      isValid = false;
      formErr["date"] = "Please Select Date ";
    }

    if (!data.units || data.units === 0) {
      isValid = false;
      formErr["units"] = "Please Fill This Field";
    }
    if (data.units) {
      if (data.units < 0) {
        isValid = false;
        formErr["units"] = "Please Enter Positive Value ";
      }
    }
    if (selfolioNumber.length < 1) {
      isValid = false;
      formErr["selfolioNumber"] = "Please Select Folio Number!";
    }


    setFormErr(formErr);
    return isValid;
  };

  // close model
  const handleClose = () => {
    setSuccess(false);
    onHideB();
    handleReset();
  };
  //handlechange
  const handleInput = (selected) => {
    setSelScrip(selected);
    setSelFolioNumber([]);
    setFormErr({ ...formErr, selScrip: "" });
    // disableButton()
  };
  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityVAlue(selected.value)
    setSelScrip([]);
    setSelBroker([]);
    setSelFolioNumber([]);
    setFormErr({ ...formErr, selEntity: "" });
    //disableButton()

  };
  const handleOption = (selected) => {
    setSelBroker(selected);
    setbroker(selected.value)
    setSelScrip("")
    setFormErr({ ...formErr, selBroker: "" });
    //disableButton()

  };
  const handleFolioNumber = (selected) => {
    setSelFolioNumber(selected);
    setFormErr({ ...formErr, selfolioNumber: "" });
    setttqty(0)
  };
  const handleDate = (pDate) => {
    setPDate(pDate);
    setFormErr({ ...formErr, date: "" });
    // disableButton()

  };
  const handleValueQ = (e) => {
    const { name, rawValue } = e.target;
    setData({ ...data, [name]: rawValue });
    setFormErr({ ...data, units: "" });
    // disableButton()

  };
  const handleValueN = (e) => {
    const { name, value } = e.target;
    setNarration({ ...narration, [name]: value });
    setFormErr({ ...narration, narration: "" });
    // disableButton()

  };
  const handleRatio = (e) => {
    const { name, value } = e.target;
    setRatio({ ...ratio, [name]: value.replace(/[^0-9:]/ig, '') });
    setFormErr({ ...formErr, ratio: '' })
  };

  useEffect(() => {
    if (showB) {
      if (MFIdBonus === 0 || MFIdBonus === undefined) {
        getEntityList();
      }
    }

  }, [showB]);

  // get companies list
  useEffect(() => {
    if (showB) {
      if (MFIdBonus === 0 || MFIdBonus === undefined) {
        if (selEntity.value && selBroker.value && selType.value) {
          getCompanyData(selEntity.value, selBroker.value, selType.value)
        }
      }
    }

  }, [selEntity, selBroker, selType, showB]);

  // edit bonus data
  useEffect(() => {
    if (MFIdBonus !== 0 && MFIdBonus !== undefined) {
      if (Object.keys(MFTransactionB).length !== 0) {
        setstockDataNew(MFTransactionB)
        setNarration({ narration: MFTransactionB.narration })
        setSelBroker({
          value: MFTransactionB.brokerId,
          label: MFTransactionB.broker.brokerName,
        });
        setbroker(MFTransactionB.brokerId);

        setSelEntity({
          value: MFTransactionB.subAccountId,
          label: MFTransactionB.subAccount.subAccountName,
        });

        setEntityVAlue(MFTransactionB.subAccountId);

        MFTransactionB.mutualFundDetails && MFTransactionB.mutualFundDetails.map((t) => {
          setSelFolioNumber({
            value: t.certificateFolioNo,
            label: t.certificateFolioNo,
          })

          setData({ units: t.units })
          setPDate(new Date(t.dateOfInvestment));
          setRatio({ ratio: t.ratio })
          const c = t.mutualFundCompany = {
            value: t.mutualFundCompanyId,
            label: t.mutualFundCompany.mutualFundCompanyName,
            // companyType: t.instrumentTypeId
          };

          t.instrumentTypeId === 41 ? setSelType({ value: 41, label: "SIP" }) : setSelType({ value: 42, label: "Lumpsum" })
          setSelScrip(c)
          setSelScrip(t.mutualFundCompany)
        })
      }

    }
  }, [MFTransactionB])

  // for buy date
  useEffect(() => {
    if (showB) {
      if (MFIdBonus === 0 || MFIdBonus === undefined) {
        setPDate(null)
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        let InstrTypeId = selType.value;
        let folioNumber = selfolioNumber.value;
        if (companyId && brokerId && subAccountId && InstrTypeId && folioNumber) {
          getBuyDate({ subAccountId, companyId, InstrTypeId, brokerId, folioNumber });
          setttqty(0);
        }
      }
      if (MFIdBonus !== 0 && MFTransactionB !== undefined) {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        let InstrTypeId = selType.value;
        let folioNumber = selfolioNumber.value;

        if (companyId && brokerId && subAccountId && InstrTypeId && folioNumber) {
          getBuyDate({ subAccountId, companyId, InstrTypeId, brokerId, folioNumber });
          setttqty(0);
        }
      }
    }
  }, [selScrip, selBroker, selEntity, showB, selfolioNumber]);

  //get buy folionumber
  useEffect(() => {
    if (showB) {
      let companyId = selScrip.value;
      let brokerId = selBroker.value;
      let subAccountId = selEntity.value;
      let InstrTypeId = selType.value;
      if (subAccountId && companyId && InstrTypeId && brokerId) {
        getFolioNumberDropDownData(subAccountId, companyId, InstrTypeId, brokerId)
        setttqty(0);
      }

    }
  }, [selScrip, selBroker, selEntity, showB])


  //getfolionumber
  const getFolioNumberDropDownData = async () => {
    let subAccountId = selEntity.value;
    let brokerId = selBroker.value;
    let InstTypeID = selType.value;
    let companyId = selScrip.value;
    if (subAccountId !== undefined && companyId !== undefined && brokerId !== undefined && InstTypeID !== undefined) {
      setLoading(true);
      await GetMutualFundFolioNo(subAccountId, companyId, InstTypeID, brokerId)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 1) {
              let data = res.data._response.data.folioNumbers;

              let arr = [];
              data && data.map((d, i) => {
                arr.push({ label: d, value: d });
              })
              setFolioNumber(arr);
              setttqty(res.data._response.data.available_Unit);
            } else {
              setFolioNumber([]);
            }
          }

        }).catch(e => {
          console.log(e, 'error in getFolioNo Api');
          setLoading(false);
        })
    }
  }


  // get dropDown Company Data brokerData
  const getCompanyBrokerDropDownData = async () => {
    let sub_Id = selEntity.value;
    if (sub_Id !== undefined) {
      setLoading(true);
      await GetCompanyAndBroker(sub_Id)
        .then((res) => {
          if (res.data._response.status === 1) {
            // broker
            let brokers = res.data._response.data.brokerName;
            let brokersData = [];
            brokers &&
              brokers.map((data) => {
                return (brokersData.push({
                  value: data.brokerId,
                  label: data.brokerName,
                }))
              });
            setBrokerList(brokersData);
            setLoading(false);
          } else {
            setBrokerList([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setBrokerList([]);
          setLoading(false);
        });
    }
  };
  // get dropDown Company Data
  const getCompanyData = async () => {
    let sub_Id = selEntity.value;
    let Bro_Id = selBroker.value;
    let InstTypeID = selType.value;
    if (sub_Id !== undefined && Bro_Id !== undefined && InstTypeID !== undefined) {
      setLoading(true);
      await GetMutualFundSellCompanies(sub_Id, Bro_Id, InstTypeID)
        .then((res) => {
          if (res.data._response.status === 1) {
            // company
            let Company = [];
            let company = res.data._response.data.fetchCompanyBySub;
            company && company.map((i) => {
              return (Company.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId }))
            })

            setCompanyList(Company);
            setLoading(false);
          } else {
            setCompanyList([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          setCompanyList([]);
          setLoading(false);
        });
    }
  };

  const handelDisableDD = (option) => {
    if (selBroker) {
      if (selBroker.value === 1) {
        if (option.companyType === 1) {
          return true
        }
      } else if (option.companyType === 10) {
        return true
      }
    }
  }


  //getEntityList
  const getEntityList = async () => {
    setLoading(true)

    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                });
              });
            setEntity(entityData);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });


  };
  // get buy date
  const getBuyDate = async ({ subAccountId, companyId, brokerId, InstrTypeId, folioNumber }) => {
    setLoading(true)
    await AvailableMFSellQuantity(subAccountId, companyId, brokerId, InstrTypeId, folioNumber)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(false)
            setBuyDate(res.data._response.data.minimum_Date);
            setttqty(res.data._response.data.available_Unit);
          }
          else {
            setLoading(false)
            setError(res.data.message);
          }
        } else {
          setLoading(false)
          setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false)
        setError(e.message);
      });
  };
  //submit form
  const handleSubmit = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        instrumentId: 8,
        instrumentTypeId: selType.value,
        mutualFundCompanyId: selScrip.value,
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        date: fixTimezoneOffset(pDate),
        units: Number(data.units),
        CertificateFolioNo: selfolioNumber.value,
        ratio: ratio.ratio,
        transactionType: 'Bonus',
        narration: narration.narration,
        stockValuationId: 4
      };
      addMutualFundBonus(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);
              Swal.fire("Success!", "Mutual Fund Bonus Added Successfully", "success");
              handleReset();
              // onRefresh();
              //handleClose();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in submit");
        });
    }
  }

  const handleUpdate = (e) => {
    // e.preventDefault();
    const isValid = errHandel();
    if (isValid === true) {
      setLoading(true);
      const Sdata = {
        mutualFundId: MFIdBonus,
        instrumentId: 8,
        instrumentTypeId: selType.value,
        mutualFundCompanyId: selScrip.value,
        brokerId: selBroker.value,
        subAccountId: selEntity.value,
        date: fixTimezoneOffset(pDate),
        units: Number(data.units),
        CertificateFolioNo: selfolioNumber.value,
        ratio: ratio.ratio,
        transactionType: 'Bonus',
        narration: narration.narration,
      };
      updateMutualFundBonus(Sdata)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data._response.status === 1) {
              setSuccess(true);

              Swal.fire("Success!", "Mutual Fund Bonus Updated Successfully", "success");

              // onRefresh();
              handleClose();
            }
          } else {
            setLoading(false);
            console.log("error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error in update");
        });
    }

  };

  //reset field
  const handleReset = () => {
    setBrokerList([]);
    setCompanyList([]);
    setSelEntity([]);
    setSelType([]);
    setSelScrip([]);
    setSelBroker([]);
    setSelFolioNumber([]);
    setData({ units: "" });
    setNarration({ narration: "" })
    setPDate(null);
    setFormErr({});
    setRatio({ ratio: "" })
    setttqty(0)
  };

  const disableButton = () => {
    return pDate && selEntity &&
      selScrip && selBroker
      && data && narration
  }

  const fixTimezoneOffset = (pDate) => {
    if (!pDate) return "";
    return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
  }



  return (
    <div tabIndex={0}>
      <Modal show={showB} onHide={handleClose} size="lg" backdrop="static" centered>
        <Modal.Header className="headerBlue" closeButton >
          {MFIdBonus === 0 || MFIdBonus === undefined ? (
            <Modal.Title className="mx-auto"><h5>Add Mutual Funds Bonus</h5></Modal.Title>
          ) : (
            <Modal.Title className="mx-auto"><h5>Update Mutual Funds Bonus</h5></Modal.Title>
          )}
        </Modal.Header>
        {/* {success ? (
          <Alert
            className="mt-2"
            variant="success"
            onClose={() => setSuccess(false)}
            dismissible
          >
            <p>{successMessage}</p>
          </Alert>
        ) : null} */}
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : null}

          <div className="mt-2 p-6">
            <Form
              className="border border-1 rounded p-3"

            >

              <Row className="form-set">
                <Form.Group className="mb-3 col-md-4">
                  <Form.Label>Type<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selType}
                    onChange={(sel) => setSelType(sel)}
                    options={TypeList}
                    name="InstrumentTypeID"
                    isDisabled={MFIdBonus}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.selType}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selEntity}
                    onChange={handleFamily}
                    options={Entity}
                    name="subAccountId"
                    isOptionDisabled={(option) => option.disabled === 0}
                    isDisabled={MFIdBonus}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.selEntity}
                    </div>
                  ) : null}
                </Form.Group>


                <Form.Group className="mb-3 col-md-4">
                  <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selBroker}
                    onChange={handleOption}
                    options={BrokerList}
                    name="brokerId"
                    isDisabled={MFIdBonus}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.selBroker}
                    </div>
                  ) : null}
                </Form.Group>
              </Row>

              <Row className="form-set">
                <Form.Group className="col-md-4">
                  <Form.Label>Scheme<span className="text-danger h6">*</span></Form.Label>

                  <Select
                    value={selScrip}
                    onChange={handleInput}
                    options={companyList}
                    name="companyId"
                    isOptionDisabled={handelDisableDD}
                    isDisabled={MFIdBonus}
                    styles={{
                      option: (data) => ({
                        ...data,
                        margin: 0,
                        padding: '3px 12px',
                      }),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: '#000',
                        margin: 0,
                        padding: '7px 2px',
                        // border: `2px solid `,
                        background: "#8bc3ff",
                        fontSize: 'small',
                        fontWeight: 500
                      }),
                    }}
                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.selScrip}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label>Folio Number<span className="text-danger h6">*</span></Form.Label>
                  <Select
                    value={selfolioNumber}
                    onChange={handleFolioNumber}
                    options={folioNumber}
                    name="folioNumber"
                  />
                  {formErr ? (
                    <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                      {formErr.selfolioNumber}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-6 col-md-4">
                  <Form.Label>Units<span className="text-danger h6">*</span></Form.Label>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip} > 
                    <Cleave
                      className='form-control'
                      name="units"
                      placeholder='Enter Units'
                      options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                      value={(data.units)}
                      onChange={handleValueQ} />
                    {/* <Form.Control
                      type="Number"
                      name="units"
                      min="0"
                      value={(data.units)}
                      onChange={handleValueQ}
                    /> */}
                  </OverlayTrigger>
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.units}
                    </div>
                  ) : null}
                  <Tooltip title={`The Available units with this broker is:`} >
                  </Tooltip>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-4">
                  <Form.Label>Date of allotment<span className="text-danger h6">*</span></Form.Label>
                  <DatePicker
                    className="form-control"
                    placeholderText="Select Start Date"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    minDate={new Date(buyDate)}
                    onChange={handleDate}
                    dateFormat="dd-MM-yyyy"
                    // name="date"
                    name="dateofPurchase"
                    autoComplete="off"
                    selected={pDate}

                  />
                  {formErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {formErr.date}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-md-4" >
                  <Form.Label> Ratio </Form.Label>
                  <Form.Control type="text" name="ratio" min="0" placeholder="Enter Ratio" onChange={handleRatio} value={ratio.ratio} autoComplete="off" />
                </Form.Group>
                <Form.Group
                  className="col-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Comments/Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="narration"
                    value={narration.narration || ""}
                    onChange={handleValueN}
                    rows={2}
                  />
                  {/* {formErr ? (
                  <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                    {formErr.narration}
                  </div>
                ) : null} */}
                </Form.Group>
              </Row>
              <Row className="form-set">
                <Col md="12" className="text-center mt-3">
                  {MFIdBonus === 0 || MFIdBonus === undefined ? (

                    <Button variant="primary" className="mx-2 w100" id="submitButton"
                      onClick={() => { handleSubmit(); }}
                      disabled={!disableButton()}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }} disabled={!disableButton()} >
                      Update
                    </Button>
                  )}
                  {MFIdBonus === 0 || MFIdBonus === undefined ?
                    <Button
                      variant="secondary"
                      className="mx-2 w100"
                      type="reset"
                      onClick={handleReset}
                    >
                      Clear
                    </Button> : null}
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>

      </Modal>

      {showbonusPreview === true ?
        <Modal show={showbonusPreview} onHide={handleClose}>
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Mutual Fund Bonus Transaction Details</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                <div className="transactionDataValue">{selEntity.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                <div className="transactionDataValue">{selBroker.label}</div></div>
              <div className="transactionData"><div className="transactionDataTitle">Scheme : </div>
                <div className="transactionDataValue">{selScrip.label}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Folio Number : </div>
                <div className="transactionDataValue">{selfolioNumber.label}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Units : </div>
                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(data.units)}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Date of Purchase : </div>
                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
              <div className="transactionData "><div className="transactionDataTitle">Ratio : </div>
                <div className="transactionDataValue">{ratio.ratio == null ? "-" : ratio.ratio}</div></div>
              <div className="transactionData odd"><div className="transactionDataTitle">Comments/Remarks : </div>
                <div className="transactionDataValue">{narration.narration == null ? "-" : narration.narration}</div></div>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </div>
  );
};

export default React.memo(Bonusmodal);
