import React from "react";
import AddChannelPartner from "../../../components/RoleMaster/Admin/AddChannelPartner"
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useSelector } from 'react-redux';

const Userdetail = ()=>{
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return(
  
<div>
        {sidebarOpen && <Sidebar />}
     <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar />
         <div>
         <AddChannelPartner/>
         </div>
     </div>
 </div>
   
        
            
    )
}

export default Userdetail;