import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import Auth from '../../modules/Auth/index'

const ChannelPTRoute = () => {
    const token = Auth.getToken();
    const userRole = Auth.getUserRoleID();
    return token && userRole === 3 ? <Outlet /> : <Navigate to="/Login" />
}

export default ChannelPTRoute