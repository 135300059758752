import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';

function ArtPreview(props) {
    const { artPreview, onhideArtPreview, artPreviewTransaction, } = props;
    return (
        <div tabIndex={4}>
            <Modal isOpen={artPreview} toggle={onhideArtPreview} tabIndex={2} >
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => onhideArtPreview()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5>Art Preview</h5>
                </ModalHeader>
                <ModalBody>
                    {artPreview === true ?
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData odd"><div className="transactionDataTitle">Title : </div>
                                <div className="transactionDataValue">{artPreviewTransaction.artTitle.artTitleName === null ? '-' : artPreviewTransaction.artTitle.artTitleName}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Artist : </div>
                                <div className="transactionDataValue">{artPreviewTransaction.artist === null ? '-' : artPreviewTransaction.artist}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date of Investment : </div>
                                <div className="transactionDataValue">{artPreviewTransaction.dateOfInvestment === null ? '-' : moment(artPreviewTransaction.dateOfInvestment).format("DD/MM/YYYY")}</div>
                            </div>
                            <div className="transactionData"><div className="transactionDataTitle">Amount Invested : </div>
                                <div className="transactionDataValue">{artPreviewTransaction.amountInvested === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(artPreviewTransaction.amountInvested)}</div>
                            </div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Current Market Price : </div>
                                <div className="transactionDataValue">{artPreviewTransaction.currentMarketPrice === null ? '-' : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(artPreviewTransaction.currentMarketPrice)}</div>
                            </div>
                        </div> : null}
                </ModalBody>
            </Modal>
        </div>
    )
}
export default ArtPreview