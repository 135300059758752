/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip'
import Auth from '../../modules/Auth'
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TableContainer,
  IconButton,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { Edit, Visibility } from "@material-ui/icons";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from "../loadingAnimation/index";

import SearchBar from "material-ui-search-bar";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

import {
  EntityList,

} from "../../pages/Stocks/StocksAPI";
import BullionDetils from '../BullionDetails/BullionDetails';
import { FetchBulionsFamilyData, FetchBulionsEntityData, FetchBulionsInnerGrid, FetchBulionsTransaction,getPreviewData } from '../../pages/Bullion/BullionApi'


import PreviewBullion from './PreviewBullion';

const BullionFolio = () => {

  const [script, setScript] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [showBullionDetail, setShowBullionDetail] = useState(false);
  const [bullionHeaderId, setBullionHeaderId] = useState(0);
  const [bulionsId, setBulionsId] = useState(0)
  const [entityId, setEntityId] = useState(null);
  const [bullionTransaction, setBullionTransaction] = useState({});
  const [iconsTest, setIconTest] = useState(AddIcon);
  const [loading, setLoading] = useState(false);

  const [entityData, setEntityData] = useState([]);
  const [bullionFamilyData, setBulionsFamilyData] = useState([])
  const [investmentTypeId, setInvestmentTypeId] = useState(null)
  const [subId, setSubId] = useState(null);
  const [company, setCompany] = useState(null)
  const [innerGridData, setInnerGridData] = useState([]);
  const [showInner, setShowInner] = useState(false);
  const [gridDetails, setGridDetail] = useState({})
  const [previewData, setPreviewData] = useState({});
  const [buysellpreview, setBuysellPreview] = useState(false);
  const [investmentTypeName, setInvestmentTypeName] = useState([])
  const [finalAmtBuySell, setFinalAmtBuySell] = useState({});
  const [finalAmtSell, setFinalAmtSell] = useState({});

  const userID = Auth.getsubcriberId();
  const [searched, setSearched] = useState("");

  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if(state){
        let typeOfInvestmentId = state.typeOfInvestmentId;
        let subAccountId = state.subAccountId;
        getBuyGetData(subAccountId,typeOfInvestmentId);
    }
  }, [state])

  useEffect(() => {
    getBLFamilyData();
    (
      async () => {
        if (userID) {
          getEntityList()
        }
      })();
  }, [userID]);

  //getEntityList
  const getEntityList = () => {

    setLoading(true)
    EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let SubscriberName = res.data._response.data.subaccountDetails;
            setEntityRadio(SubscriberName);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //round of percentage
  function preciseMathDotRound(value, precision = 2) {
    let valueData = isFinite(value)

    if (valueData) {

      return parseFloat(value.toFixed(precision));
    } else {
      return 0;
    }
  }

  //details pannel icon parent child
  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };


  //close bullion details transaction
  const handleClose = async (val) => {
    setBulionsId(0)
    setBullionHeaderId(0)
    setShowBullionDetail(false);
    setBullionTransaction({})
    setBuysellPreview(false);
    if (val !== 1) {
      refreshBulionInnerGrid()
      refreshBulion()
    }
  }



  // close inner grid modal
  const handleCloseModel = async () => {
    setBullionHeaderId(0)
    setBullionTransaction({})
    setShowInner(false)
    refreshBulion();
    handleReset()
  }


  const columns = [
    { title: "Type Of Investment", field: "investmentName" },
    {
      title: "Quantity",
      field: "availableQuantity",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN').format(rowData.availableQuantity)/*Tanmay-25/01/2022*/}
        </span>
      ),
    },
    {
      title: "Unit",
      field: "investmentUnit",
      type: "numeric",
      render: (rowData) => (
        <span>
          {rowData.investmentUnit ? rowData.investmentUnit : rowData.investmentName === "Jwellery" ? "Pieces" : rowData.investmentName === "Precios Stones" ? "Carat" : "Grams"}
        </span>
      )
    },

    {
      title: "Amount Invested",
      field: "closingBalance",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.closingBalance)/*Tanmay-25/01/2022*/}
        </span>
      ),
    },

    {
      title: "Overallgain",
      field: "overallgain",
      type: "numeric",
      render: (rowData) => (
        (rowData.currentMarketPrice - rowData.closingBalance) >= 0 ?

          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.closingBalance)}
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.closingBalance)}
          </span>
      ),
    },

    {
      title: "Overallgain(%)",
      field: "poverallgain",
      type: "numeric",
      render: (rowData) => (
        (((rowData.currentMarketPrice - rowData.closingBalance) / rowData.closingBalance) * 100) ?
          (((rowData.currentMarketPrice - rowData.closingBalance) / rowData.closingBalance) * 100) >= 0 ?

            <span style={{ color: "#008000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((rowData.currentMarketPrice - rowData.closingBalance) / rowData.closingBalance) * 100))} %
            </span>
            : <span style={{ color: "#ff0000" }}>
              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((rowData.currentMarketPrice - rowData.closingBalance) / rowData.closingBalance) * 100)} %

            </span> :
          <span style={{ color: "#008000" }}>0.00%</span>
      ),
    },

    {
      title: "Current Value",
      field: "currentvalue",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice)/*Tanmay-25/01/2022*/}
        </span>
      ),
    },

  ]


  //open transaction bulions details
  const openTransaction = () => {
    setBullionTransaction({});
    // setBullionHeaderId(0);
    setShowBullionDetail(true);
  };

  //all income  family data
  const getBLFamilyData = () => {
    FetchBulionsFamilyData().then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setLoading(false);
          let BulionsData = res.data._response.data.fetchBullionFamilyOuterGrid;
          setBulionsFamilyData(BulionsData);
          OuterGridFamilyData(BulionsData);
          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });
        } else {
          setBulionsFamilyData([])
          setFilteredData([])
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  //expandable family grid
  const OuterGridFamilyData = (BulionsData) => {
    let check = [];
    BulionsData.forEach((item, i) => {
      if (check.indexOf(item.investmentName) === -1) {
        check.push(item.investmentName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        availableQuantity: 0,
        availableCost: 0,
        // maturityValue: 0,
        name: "",
        closingBalance: 0,
        currentMarketPrice: 0
      };
      // console.log(BulionsData);
      BulionsData.forEach((item, i) => {
        if (checkdata === item.investmentName) {

          let availableQuantity = mainData.availableQuantity + item.availableQuantity;
          let availableCost = mainData.availableCost + item.availableCost;
          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          mainData = {

            availableQuantity: availableQuantity,
            availableCost: availableCost,
            investmentName: item.investmentName,
            closingBalance: closingBalance,
            currentMarketPrice: currentMarketPrice,
            // maturityValue: cost == 0 ? 0 : maturityValue,
            id: index,
          };

          let normalData = {
            ...item,
            investmentName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.investmentName,
            // maturityValue: item.cost == 0 ? 0 : item.maturityValue,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });

    setFilteredData([...parentData, ...childData]);
  };


  //entity chnaged
  const handleChangeEntity = async (e) => {
    setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      setEntityId(null);
      getBLFamilyData();
      setScript("All");
      setSearched("")

      //+ button code
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        let button = $("button", this);
        $(button).html("<span class='material-icons'>add</span>");
      });
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName == e.target.value;
      });
      // await setSubNameEnt(e.target.value);
      await setEntityId(data.subAccountId);
      await getBLEntityData(data.subAccountId)
    }
    // console.log({ subAccountName: e.target.value }, "handle change");
  };

  //all enity data
  const getBLEntityData = async (id) => {

    await FetchBulionsEntityData(id)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res, 'entity data');
          if (res.data._response.status === 1) {
            //+ button code
            $(".MuiTableRow-root").each(function () {
              let val = $('.material-icons ', this).text()
              if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
            });

            let BulionEntity = res.data._response.data.fetchBullionIndividualOuterGrid;
            setEntityData(BulionEntity)
            setLoading(false)
            const entityBulionList =
              BulionEntity &&
              BulionEntity.map((a, index) => {

                return { ...a, id: index + 1, company: a.investmentName };
              });
            // OuterGridEntityData(BulionEntity)
            setFilteredEntiryData(entityBulionList)
            setScript("All")
            setSearched("")
          } else {
            setFilteredEntiryData([]);
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log("error");
        setLoading(false)
      });
  }

  // //expandable enity grid
  // const OuterGridEntityData = (BulionEntity) => {
  //   let check = [];
  //   BulionEntity.forEach((item, i) => {
  //     if (check.indexOf(item.investmentName) === -1) {
  //       check.push(item.investmentName);
  //     }
  //   });
  //   let parentData = [];
  //   let childData = [];
  //   check.forEach((checkdata, index) => {
  //     let mainData = {
  //       availableQuantity: 0,
  //       availableCost: 0,
  //       // maturityValue: 0,
  //       name: "",
  //       closingBalance: 0,
  //       // currentValue: 0
  //     };
  //     // console.log(BulionEntity);
  //     BulionEntity.forEach((item, i) => {
  //       if (checkdata === item.investmentName) {

  //         let availableQuantity = mainData.availableQuantity + item.availableQuantity;
  //         let availableCost = mainData.availableCost + item.availableCost;
  //         let closingBalance = mainData.closingBalance + item.closingBalance;


  //         mainData = {

  //           availableQuantity: availableQuantity,
  //           availableCost: availableCost,
  //           investmentName: item.investmentName,
  //           closingBalance: closingBalance,
  //           id: index,
  //         };

  //         let normalData = {
  //           ...item,
  //           investmentName: item.subAccountName,
  //           parentId: index,
  //           id: index + 1,
  //           company: item.subAccountName,
  //         };
  //         childData.push(normalData);
  //       }
  //     });
  //     parentData.push(mainData);
  //   });

  //   setFilteredEntiryData([...parentData, ...childData]);

  // }

  // buysell Inner GRID

  const getBuyGetData = async (
    subAccountId,
    typeOfInvestmentId,
    company
  ) => {
    setSubId(subAccountId);
    setInvestmentTypeId(typeOfInvestmentId)
    setCompany(company);

    setLoading(true);
    // family

    if (subAccountId && typeOfInvestmentId) {
      await FetchBulionsInnerGrid(subAccountId, typeOfInvestmentId)
        .then((res) => {
          setLoading(false);
          if (res.data._response.status === 1) {

            let data = res.data._response.data.fetchLoansDepositsEntityInfo;

            setInnerGridData(data);

            let quantity = _.last(data);
            let buyAmt = 0;
            let sellAmt = 0;
            let buyUnits = 0;
            let sellUnits = 0;
            let availBuyCost = 0;
            let availSellCost = 0;
            let closingBalance = 0;

            data &&
              data.map((f) => {
                if (f.transactType !== "Sell" && f.show === 1) {
                  buyAmt += f.closingBalance;
                  buyUnits += f.availableQuantity;
                }

                if (f.transactType === "Sell" && f.show === 1) {
                  sellUnits += f.availableQuantity;
                  sellAmt += f.saleValue;

                }

                if (f.transactType === "Sell" && f.show === 0 && f.active === 1) {
                  sellUnits += f.availableQuantity;

                }

                if (f.transactType === "Sell" && f.show === 1) {
                  sellAmt += f.closingBalance;
                }

                if (f.transactType === "Buy" && f.show === 1) {
                  availBuyCost += f.amountOfInvestment
                }
                if (f.transactType === "Sell" && f.show === 0) {
                  availSellCost += f.saleValue
                }
                if(f.transactType === "Buy" && f.show !== 0){
                  closingBalance += f.closingBalance;
                }
                setFinalAmtBuySell({
                  availBuyCost: availBuyCost,
                })

                setFinalAmtSell({
                  availSellCost: availSellCost,
                })
                setInvestmentTypeName(f.investmentName)
              });
            setGridDetail({

              finalUnits: buyUnits - sellUnits, //quantity.balanceQuantity,
              finalCost: buyAmt - sellAmt,
              closingBalance
            });

            setShowInner(true);
            //+/- code
            var arr = [];
            $(".MuiTableRow-root").each(function () {
              let path = this.getAttribute("path");
              let a = /[,\/]/g;
              let b = a.test(path)
              if (b) {
                let c = path.charAt(0)
                if (!arr.includes(c)) {
                  arr.push(c)
                }
              }
            });
            $(".MuiTableRow-root").each(function () {
              let path = this.getAttribute("path");
              if (arr.includes(path)) {
                let button = $("button", this);
                $(button).html("<span class='material-icons'>maximize</span>");
                $(button).css({
                  transform: "rotate(180deg)",
                });
              }
            });
          } else {
            setInnerGridData([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }

  //edit operation
  const alertMyRow = (selectedRow, a) => {
    setLoading(true);
    FetchBulionsTransaction(a.bullionHeaderId).then((res) => {
      setBullionHeaderId(a.bullionHeaderId);

      setBulionsId(a.bullionId);
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          const result = res.data._response.data.bullionTrnsactionDetails;
          setBullionTransaction(result);

          setShowBullionDetail(true);
        }


      }
      else {
        setLoading(false);
      }
    })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  };
  const handlePreview = (selectedRow, a) => {
    setLoading(true);

    setBullionHeaderId(a.bullionHeaderId);
    getPreviewData(a.bullionId).then((res) => {
      setLoading(false);
      if (res.data._response.status === 1) {
        const result = res.data._response.data.fetchStockDetailsPreview;
        setPreviewData(result);
        setBuysellPreview(true);
      }
    }).catch(err => {
      setLoading(false);
      console.log("err in bullion preview")
    })

  }


  const refreshBulion = async () => {
    getBLFamilyData()
    if (entityId) await getBLEntityData(entityId);
  }

  const refreshBulionInnerGrid = async () => {
    setShowBullionDetail(false);
    await getBuyGetData(
      subId,
      investmentTypeId,
      company
    )
  }

  const handleReset = () => {
    setInvestmentTypeId(null);
    setCompany(null);
    setSubId(null);
  }

  const handleScripData = (e) => {
    let selected = e.target.value
    setScript(selected)
    setSearched("")

    //sordted Family
    let sortedFamily = selected === "All" ? bullionFamilyData : bullionFamilyData.filter
      ((a) => a.typeOfInvestmentId === parseInt(selected))

    let check = [];
    sortedFamily.forEach((item, i) => {
      if (check.indexOf(item.investmentName) === -1) {
        check.push(item.investmentName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        availableQuantity: 0,
        availableCost: 0,
        // maturityValue: 0,
        name: "",
        closingBalance: 0,
        currentMarketPrice: 0
      };
      // console.log(BulionsData);
      sortedFamily.forEach((item, i) => {
        if (checkdata === item.investmentName) {

          let availableQuantity = mainData.availableQuantity + item.availableQuantity;
          let availableCost = mainData.availableCost + item.availableCost;
          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          mainData = {

            availableQuantity: availableQuantity,
            availableCost: availableCost,
            investmentName: item.investmentName,
            closingBalance: closingBalance,
            currentMarketPrice: currentMarketPrice,
            // maturityValue: cost == 0 ? 0 : maturityValue,
            id: index,
          };

          let normalData = {
            ...item,
            investmentName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.investmentName,
            // maturityValue: item.cost == 0 ? 0 : item.maturityValue,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });

    setFilteredData([...parentData, ...childData]);

    //entity filter

    let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.typeOfInvestmentId === parseInt(selected)))
    const sortData = sortedEntity &&
      sortedEntity.map((a, index) => {
        return { ...a, id: index + 1, company: a.companyName };
      });
    setFilteredEntiryData(sortData);
  }

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // search function
  const requestSearch = (searchedVal) => {

    setSearched(searchedVal)
    const filteredRows = bullionFamilyData.filter((row) => {
      return row.investmentName.includes(searchedVal.toUpperCase()) || row.investmentName.includes(searchedVal.toLowerCase()) || row.investmentName.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.investmentName) === -1) {
        check.push(item.investmentName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        availableQuantity: 0,
        availableCost: 0,
        // maturityValue: 0,
        name: "",
        closingBalance: 0,
        currentMarketPrice: 0
      };
      // console.log(BulionsData);
      filteredRows.forEach((item, i) => {
        if (checkdata === item.investmentName) {

          let availableQuantity = mainData.availableQuantity + item.availableQuantity;
          let availableCost = mainData.availableCost + item.availableCost;
          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          mainData = {

            availableQuantity: availableQuantity,
            availableCost: availableCost,
            investmentName: item.investmentName,
            closingBalance: closingBalance,
            currentMarketPrice: currentMarketPrice,
            // maturityValue: cost == 0 ? 0 : maturityValue,
            id: index,
          };

          let normalData = {
            ...item,
            investmentName: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.investmentName,
            // maturityValue: item.cost == 0 ? 0 : item.maturityValue,
          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });

    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    const entityBulionList =
      forentity &&
      forentity.map((a, index) => {

        return { ...a, id: index + 1, company: a.investmentName };
      });
    setFilteredEntiryData(entityBulionList)


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      getBLEntityData(entityId)
    } else {
      getBLFamilyData()
    }
  };


  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="text-center py-3">Bullion Folio</h4>
          </Col>
        </Row>

        <FormControl className="mt-4">
          <RadioGroup
            row
            color="primary"
            defaultValue="start"
            aria-label="Entity"
            name="row-radio-buttons-group"
            value={entity}
            onChange={handleChangeEntity}
            style={{ marginTop: "-3rem" }}
          >
            <FormControlLabel
              value="Family"
              control={<Radio />}
              label="Family"
            />
            {entityRadio.map((a) => (
              <FormControlLabel
                key={a.subAccountName}
                value={a.subAccountName}
                control={<Radio />}
                label={a.subAccountName}
                disabled={a.active === 0 ? true : false}
              />
            ))}

          </RadioGroup>
        </FormControl>

        <Row>
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="flex-end"
            alignItems="flex-start" mb={3} >
            {entityId === null ? null :
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openTransaction()}
                  style={{ height: "2rem" }}
                >
                  Transactions
                </Button>
              </>

            }



            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={script}
              label="Type"
              onChange={handleScripData}
              style={{ minWidth: 120, paddingLeft: "12px" }}
            >
              <MenuItem disabled >
                <em>Bullion Type</em>
              </MenuItem>
              <MenuItem value={"All"}>All </MenuItem>
              <MenuItem value={1}> Gold</MenuItem>
              <MenuItem value={2}>Silver</MenuItem>
              <MenuItem value={3}>Platinum</MenuItem>
              <MenuItem value={4}>Precious Stones</MenuItem>
              <MenuItem value={5}>Jewellery</MenuItem>

            </Select>
          </Stack>
        </Row>
        <Row>
          <Box sx={{ flexGrow: 1 }} mb={2}>
            <Grid container spacing={2} columns={16}
              direction="row"
              justifyContent="end"
              alignItems="flex-end">
              <Grid item xs="auto">
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => { requestSearch(searchVal) }}
                  onCancelSearch={() => cancelSearch()}
                  style={{
                    // margin: "0 auto",
                    maxWidth: 250,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Row>
        <Row>
          <Col md="12">
            <div style={{ maxWidth: "100%" }} className="tblHeaderLeftB">

              <MaterialTable
                title=""
                data={
                  entity === "Family" ? filteredData : filteredEntiryData
                }
                columns={columns}

                parentChildData={(row, rows) =>
                  rows.find((a) => a.id === row.parentId)
                }
                isLoading={loading}
                options={{
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                  exportButton: false,
                  search: false,
                  toolbarButtonAlignment: "left",
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50],
                  emptyRowsWhenPaging: false,
                  rowStyle: (rowData) => {
                    return {
                      pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                      backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                    };
                  },
                }}

                components={{
                  Body: (props) => {
                    let totalObj = {
                      qtySum: 0,
                      amtInvSum: 0,
                      currValSum: 0,
                      overAllGainSum: 0,
                      overAllGainSumPer: 0
                    }


                    props.renderData.forEach((rowData) => {
                      totalObj.qtySum += rowData.availableQuantity;
                      totalObj.amtInvSum += rowData.closingBalance;
                      totalObj.currValSum += (rowData.currentMarketPrice);
                      totalObj.overAllGainSum = totalObj.currValSum - totalObj.amtInvSum
                    })

                    totalObj.overAllGainSumPer = (totalObj.overAllGainSum / totalObj.amtInvSum) * 100;

                    return (
                      <>
                        <MTableBody {...props} />
                        {
                          script !== "All" ?
                            <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                              <TableRow>
                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={2} align="right">Total</TableCell>
                                <TableCell style={{ color: "black", fontWeight: "500" }} colSpan={3} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.amtInvSum).toFixed(2))}</TableCell>
                                <TableCell style={totalObj.overAllGainSum < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.overAllGainSum).toFixed(2))}</TableCell>

                                <TableCell style={totalObj.overAllGainSumPer < 0 ?
                                  { color: "#ff0000", fontWeight: "500" } :
                                  { color: "#008000", fontWeight: "500" }} align="right">

                                  {totalObj.overAllGainSumPer && totalObj.overAllGainSumPer !== "Infinity" ?
                                    new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalObj.overAllGainSumPer).toFixed(2))
                                    : 0} %</TableCell>

                                <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.currValSum).toFixed(2))}</TableCell>

                                  
                              </TableRow>
                            </TableFooter> :
                            null
                        }
                      </>
                    )

                  },
                  // cards section
                  Toolbar: (props) => {
                    let totalObj = {
                      qtySum: 0,
                      amtInvSum: 0,
                      currValSum: 0,
                      overAllGainSum: 0,
                      overAllGainSumPer: 0
                    }
                    props.renderData.forEach((rowData) => {
                      totalObj.qtySum += rowData.availableQuantity;
                      totalObj.amtInvSum += rowData.closingBalance;
                      totalObj.currValSum += (rowData.currentMarketPrice);
                      totalObj.overAllGainSum = totalObj.currValSum - totalObj.amtInvSum
                    })
                    totalObj.overAllGainSumPer += (totalObj.overAllGainSum / totalObj.amtInvSum) * 100;
                    return (

                      <>
                        <MTableToolbar {...props} />
                        {
                          script !== "All" || script === "All" ?

                            <div className="row mb-2 p-2">
                              <div className="col-md-2 col-xl-2">
                              </div>
                              {/* <div className="col-md-2 col-xl-2">
                                <div className="card order-card">
                                  <div className="card-block">
                                    <h3 className="text-right"><span style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalObj.qtySum)}</span></h3>
                                    <p className="m-b-0">Quantity</p>
                                  </div>
                                </div>
                              </div> */}

                              <div className="col-md-2 col-xl-2">
                                <div className="card order-card">
                                  <div className="card-block">
                                    <h3 className="text-right" style={totalObj.amtInvSum < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                      <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.amtInvSum).toFixed(2))}</span></h3>
                                    <p className="m-b-0" >Invested Amount</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 col-xl-2">
                                <div className="card order-card">
                                  <div className="card-block">
                                    <h3 className="text-right" style={totalObj.overAllGainSum < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                      <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.overAllGainSum).toFixed(2))}</span></h3>
                                    <p className="m-b-0" >Overall Gain</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 col-xl-2">
                                <div className="card order-card">
                                  <div className="card-block">
                                    <h3 className="text-right" style={totalObj.overAllGainSumPer < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                      <span>{totalObj.overAllGainSumPer && totalObj.overAllGainSumPer !== 'Infinity' ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalObj.overAllGainSumPer).toFixed(2)) : 0}%</span></h3>
                                    <p className="m-b-0" >Overall Gain %</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-2 col-xl-2">
                                <div className="card order-card">
                                  <div className="card-block">
                                    <h3 className="text-right" style={totalObj.currValSum < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                      <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalObj.currValSum).toFixed(2))}</span></h3>
                                    <p className="m-b-0" >Current Value</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : null}

                      </>
                    )

                  },

                }}

                onTreeExpandChange={(row, rows) => {
                  handleIcon(row, rows);
                }}
                icons={{
                  DetailPanel: iconsTest,
                }}

                onRowClick={(evt, selectedRow) => {
                  if (selectedRow.tableData.childRows == null) {
                    getBuyGetData(
                      selectedRow.subAccountId,
                      selectedRow.typeOfInvestmentId,
                      selectedRow.company,
                      selectedRow.tableData.path[0]
                    );
                  }
                }}

              />
            </div>

            <BullionDetils
              showB={showBullionDetail}
              onHide={handleClose}
              entityId={entityId}
              bulionsId={bulionsId}
              bullionHeaderID={bullionHeaderId}
              bullionTransaction={bullionTransaction}
            />
            <PreviewBullion
              tabIndex={2}
              showPreview={buysellpreview}
              hidePreview={handleClose}
              BullionTransaction={previewData}
              bullionHeaderId={bullionHeaderId}
            />
            <Container
              className="LoginContainer formWraper"
              component="main"
              maxWidth="xs"
              tabIndex={-1}
            >
              <Modal
                isOpen={showInner}
                toggle={() => handleCloseModel()}
                size="xl"
                className="col-12 stockdetailmodalM"
              >
                <ModalHeader charCode="Y" toggle={() => handleCloseModel()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                  <h5 className="text-center">Bullion Detail</h5>
                </ModalHeader>
                <ModalBody>
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    {loading ? (
                      <Loader />
                    ) : null}
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {investmentTypeName}</p>

                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="9%">
                              Trans.
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Investment Type
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Date
                            </TableCell>
                          

                            <TableCell align="right" width="7%">
                              Quantity
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Unit
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Rate
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Sell Price
                            </TableCell>

                            <TableCell align="right" width="6%">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {innerGridData.map((a) => (
                            <TableRow
                              key={a.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}


                            >
                              <TableCell align="left">
                                {a.transactType}
                              </TableCell>
                              <TableCell align="right">
                                {a.transactType === "Sell" ?
                                  <>
                                    {`${a.investmentName}  -  ${a.investmentDescription}`/*Tanmay-25/01/2022*/}</> :
                                  <>
                                    {`${a.investmentName}  -  ${a.investmentDescription}`/*Tanmay-25/01/2022*/}
                                  </>}
                              </TableCell>
                              <TableCell align="right">
                                {moment(a.dateOfInvestment).format("DD/MM/YYYY")}
                              </TableCell>

                            

                              <TableCell align="right">

                                {new Intl.NumberFormat('en-IN').format(a.quantity)/*Tanmay-25/01/2022*/}
                              </TableCell>
                              <TableCell align="right">
                                {a.investmentUnit}
                              </TableCell>
                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.rate)/*Tanmay-25/01/2022*/}
                              </TableCell>

                              <TableCell align="right">
                                {a.transactType === "Buy" ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amountOfInvestment) :
                                  new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(0)
                                  // new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.saleValue)
                                }
                              </TableCell>

                              <TableCell align="right">
                                {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.saleValue)}
                              </TableCell>


                              <TableCell align="right">
                              <Tooltip title="View">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handlePreview(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="right" className="headerbgcolor">
                              Closing Balance
                            </TableCell>
                            <TableCell align="right" colSpan={3} className="headerbgcolor">
                              {new Intl.NumberFormat('en-IN').format(gridDetails.finalUnits)}
                            </TableCell>
                            <TableCell align="right" colSpan={3} className="headerbgcolor">
                              {/* {innerGridData.transactType === "Buy" ?
                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetails.finalCost)
                                :
                                new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(finalAmtBuySell.availBuyCost - finalAmtSell.availSellCost) //(finalAmtBuySell.availBuyCost)
                              } */}
                             { new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(gridDetails.closingBalance)}
                            </TableCell>
                            <TableCell align="right" className="headerbgcolor">
                              {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(finalAmtSell.availSellCost)}
                            </TableCell>
                            <TableCell align="right" colSpan={2} className="headerbgcolor">

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </ModalBody>
              </Modal>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default BullionFolio