import {combineReducers} from 'redux'
// import token from './tokenReducer'
// import signin from './signinReducer'
// import userProfile from './userProfileReducer'
import userData from './userDataReducer'
import sidebarReducer from './sidebarReducer'

export default combineReducers({
  userData,
  sidebarReducer,
  // token,
  // signin,
  // userProfile
})