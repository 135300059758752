import React from 'react';
import IPOAlloted from '../../../components/stocksFolio/other_transactions/IPOAlloted';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const IPOAllotedPage = () => {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
  return (
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar/>
                <div>
                    <IPOAlloted />
                </div>
            </div>
        </div>
  )
}

export default IPOAllotedPage