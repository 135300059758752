/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Dropdown from '../../CommonComponent/Dropdown';
import Loader from "../../loadingAnimation/index";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { InvestmentType, periodList } from '../../../modules/helpers/constant'
import { GetDividendFrequency } from "../../../pages/Stocks/StocksAPI";
import { AddPostOffice, UpdatePostOffice } from "../FDAPI"
import POInterest from './POIntrest';
import POInstallment from './POInstallment';
import Maturity from "./Maturity";
import swal from "sweetalert";
import Cleave from 'cleave.js/react';
import { Tooltip } from "@mui/material";

const PostOffice = (props) => {

    const { POentity, POTransaction, FDHeaderIdPO, show7, onRefreshFD } = props
    const [folioNumber, setFolioNumber] = useState({ folioNumber: "" });
    const [selInvestType, setSelInvestType] = useState(InvestmentType[0]);
    const [iDate, setIDate] = useState(null);
    const [investAmount, setInvestAmount] = useState({ totalCost: "" });
    const [selAmountInvestType, setSelAmountInvestType] = useState([]);
    const [interestPer, setInterestPer] = useState({ interestPerc: "" });
    const [frequencyValue, setFrequencyValue] = useState([]);
    const [mDate, setMDate] = useState(null);
    const [maturityVal, setMaturityVal] = useState({ maturityValue: "" });
    const [lockinperiod, setLockinperiod] = useState("")
    const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
    const [lockPeriodValue, setLockPeriodValue] = useState("Month");
    const [selFrequency, setSelFrequency] = useState([]);
    const [dividendFrequency, setDividendFrequency] = useState([]);
    const [narration, setNarration] = useState(null)
    const [reinvestedRadio, setreinvestedRadio] = useState("no");
    const [interestAcc, setInterestAcc] = useState({ interestReinvested: "" });
    const [startDate, setStartDate] = useState(null);

    const [fdId, setFdId] = useState()

    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showPOMaturity, setPOMaturity] = useState(false);
    const [POi, setPOi] = useState(false);
    const [POInstall, setPOInstall] = useState(false);
    const [isInterest, setIsInterest] = useState(false);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }

    useEffect(() => {
        getDividedFrequencyList();
    }, [])


    //getdividend list
    const getDividedFrequencyList = async () => {
        await GetDividendFrequency()
            .then((res) => {

                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let dividend = res.data._response.data.dividendfrequencyDetails;
                        let dividendData = [];
                        dividend &&
                            dividend.map((data) => {
                              return (  dividendData.push({
                                    value: data.frequencyId,
                                    label: data.frequencyName,
                                }))
                            });
                        setDividendFrequency(dividendData);
                        if (FDHeaderIdPO === 0) {
                            setSelFrequency(dividendData[1]);
                        }
                    }
                } else {
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
            });
    };

    // handle folio Number
    const handleFolio = (e) => {
        const { name, value } = e.target;
        setFolioNumber({ ...folioNumber, [name]: value })
        setFormErr({ ...formErr, folioNumber: "" })
    }

    // handle investment type
    const handleInvestType = (selected) => {
        setSelInvestType(selected);
    }

    // handle investment date
    const handleInvDate = (iDate) => {
        setIDate(iDate);
        setFormErr({ ...formErr, idate: "" })
    }



    //handle amount invest
    const handleInvestAmount = (e) => {
        const { name, rawValue } = e.target;
        setInvestAmount({ ...investAmount, [name]: rawValue });
        setFormErr({ ...formErr, totalCost: "" })
    }

    // handle amount invest Type
    // const handleAmountInvType = (selected) => {
    //     setSelAmountInvestType(selected);

    // }

    //handleradio
    const handleRadio = (e) => {
        const { value } = e.target
        setreinvestedRadio(value);
        if (value === "no") {
            setStartDate(null);
            setInterestAcc({ ...interestAcc, interestReinvested: "" })
        }
    }
    //handleinterstAcc
    const handleValueIntAcc = (e) => {
        const { name, rawValue } = e.target;
        setInterestAcc({ ...interestAcc, [name]: rawValue });
        setFormErr({ ...formErr, interestAcc: "" })
    }
    //handlestartingdate
    const handleDateStart = (startDate) => {
        setStartDate(startDate);
        setFormErr({ ...formErr, startDate: "" })
    }

    //handle interest
    const handleInterestPer = (e) => {
        const { name, value } = e.target;
        setInterestPer({ ...formErr, [name]: value > 100 ? 100 : value < 0 ? 0 : value });
    }

    //handle frequency
    const handleFrequency = (selected) => {
        setSelFrequency(selected);
        setFrequencyValue(selected.value)

    }

    //handle maturity date
    const handleDateM = (mDate) => {
        setMDate(mDate);
        setFormErr({ ...formErr, mdate: "" });
    }

    //handle maturity value
    const handleMaturityVal = (e) => {
        const { name, rawValue } = e.target;
        setMaturityVal({ ...formErr, [name]: rawValue });
        setFormErr({ ...formErr, MaturityValue: "" })
    }

    //handle lock in period
    const HandleChangePeriod = (e) => {
        setLockPeriod(e);
        setLockPeriodValue(e.value);
        setFormErr({ ...formErr, lockPeriodValue: '' })
    }

    const handleNarration = (e) => {
        setNarration(e.target.value)
    }

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    // handle errors
    const errHandel = () => {
        let formErr = {};
        let isValid = true;


        if (!iDate) {
            isValid = false;
            formErr["idate"] = "Please select Investment Date !";
        }

        if (!investAmount.totalCost) {
            isValid = false;
            formErr["totalCost"] = "Please Enter Amount Invested !";
        }

        if (!mDate) {
            isValid = false;
            formErr["mdate"] = "Please select maturity Date !";
        }
        if (!folioNumber.folioNumber) {
            isValid = false;
            formErr["folioNumber"] = "Please Enter Certificate/Folio number!";
        }
        if (mDate && iDate) {
            if (fixTimezoneOffset(mDate) === fixTimezoneOffset(iDate)) {
                isValid = false;
                formErr["mdate"] = "Maturity Date cannot be same as Investment Date!";
            }
        }
        if (interestAcc.interestReinvested === "" && reinvestedRadio === "yes") {
            isValid = false;
            formErr["interestAcc"] = "Please Enter Interest Accrued !";
        }
        if ((!startDate) && (reinvestedRadio === "yes")) {
            isValid = false;
            formErr["startDate"] = "Please Select Date !";
        }
        setFormErr(formErr);
        return isValid;
    }

    const handleReset = () => {
        setFolioNumber({ folioNumber: "" })
        setMDate(null);
        setIDate(null);
        setInterestPer({ interestPerc: "" });
        setFrequencyValue({});
        setInvestAmount({ totalCost: "" });
        setSelAmountInvestType([]);
        setSelInvestType(InvestmentType[0]);
        setSelFrequency(dividendFrequency[1]);
        setNarration("");
        setMaturityVal({ maturityValue: "" });
        setLockinperiod([]);
        setFormErr({});
        setreinvestedRadio('no')
        setStartDate(null);
        setInterestAcc({ interestReinvested: "" })
        setIsInterest(false)

    }

    // get data for update
    useEffect(() => {
        if (FDHeaderIdPO !== 0) {
            if (POTransaction !== undefined) {
                setIDate(new Date(POTransaction.date))
                setSelAmountInvestType({ label: POTransaction.type })
                setNarration(POTransaction.narration)
                POTransaction.fdDetails && POTransaction.fdDetails.map((t) => {
                    setFdId(t.fdId);
                    setSelInvestType({ label: t.company.companyName, value: t.companyId });
                    setFolioNumber({ folioNumber: t.folioNumber });
                    t.frequencyId ? setSelFrequency({ label: t.frequency.frequencyName, value: t.frequencyId }) : setSelFrequency([]);
                    setMaturityVal({ maturityValue: t.maturityValue });
                    setInvestAmount({ totalCost: t.availableCost });
                    setMDate(new Date(t.domConversion));
                    let l = t.lockInPeriod ? t.lockInPeriod : "0Month";
                    let splitDatalock = l.split(/([0-9]+)/);
                    setLockinperiod(splitDatalock[1]);
                    setLockPeriodValue(splitDatalock[2]);
                    setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });
                    setInterestPer({ interestPerc: t.interestPerc })
                    setreinvestedRadio(t.interestAccrued === true ? "yes" : "no");
                    if (t.interestAccrued === true) {
                        setInterestAcc({ interestReinvested: t.interestReinvested });
                        setStartDate(new Date(t.fyStartDate))
                        // setIsInterest(true)
                        t.balanceQuantity > 0 ? setIsInterest(true) : setIsInterest(false);
                    }
                })
            }
        }
    }, [POTransaction])

    // submit data
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const POData = {
                instrumentId: 4,
                instrumentTypeId: 32,
                subAccountId: POentity,
                date: fixTimezoneOffset(iDate),
                narration: narration,
                type: selInvestType.label,
                fdDetails: [
                    {
                        transactStockType: "Buy",
                        interestAccrued: reinvestedRadio === "yes" ? true : false,
                        interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
                        availableCost: Number(investAmount.totalCost),
                        folioNumber: folioNumber.folioNumber,
                        lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                        domConversion: fixTimezoneOffset(mDate),
                        MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investAmount.totalCost),
                        frequencyId: selFrequency.value,
                        fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                        interestPerc: interestPer.interestPerc ? interestPer.interestPerc : 0,
                        cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
                    }
                ]
            }
            AddPostOffice(POData).then((response) => {
                if (response.data._response.status === 1) {
                    setLoading(false)
                    swal("PO Added Successfully!", "", "success");
                    handleReset()
                } else {
                    swal(response.data._response.message, "", "info");
                    setLoading(false)
                    console.log("error");
                }
            }).catch((error) => {
                console.log(error, "error in submit post office");
                setLoading(false)

            })
        }

    }

    // Update data
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            const POData = {
                fdHeaderId: FDHeaderIdPO,
                instrumentId: 4,
                instrumentTypeId: 32,
                subAccountId: POentity,
                date: fixTimezoneOffset(iDate),
                narration: narration,
                type: selInvestType.label,
                fdDetails: [
                    {
                        fdId: fdId,
                        interestAccrued: reinvestedRadio === "yes" ? true : false,
                        interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
                        transactStockType: "Buy",
                        availableCost: Number(investAmount.totalCost),
                        folioNumber: folioNumber.folioNumber,
                        lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                        domConversion: fixTimezoneOffset(mDate),
                        MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investAmount.totalCost),
                        frequencyId: selFrequency.value,
                        fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                        interestPerc: interestPer.interestPerc ? interestPer.interestPerc : 0,
                        cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
                    }
                ]
            }
            UpdatePostOffice(POData).then((response) => {
                if (response.data._response.status === 1) {
                    setLoading(false)
                    swal("PO Updated Successfully!", "", "success");
                    handleReset()
                } else {
                    swal(response.data._response.message, "", "info");
                    setLoading(false)
                    console.log("error");
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error, "error in submitting error");
            })
        }

    }

    const handleMaturity = () => {
        setPOMaturity(true)
    }
    const handleCloseMaturity = () => {
        setPOMaturity(false);
    }
    const handleInterest = () => {
        setPOi(!POi)
    }
    const handleInstall = () => {
        setPOInstall(true)
    }
    const handleCloseInterest = () => {
        setPOi(false)
    }
    const handleCloseInstallMent = () => {
        setPOInstall(false);
    }
    const handledisableCheck = (i) => {
        if (i === 1) {
            setIsInterest(true);
        } else {
            setIsInterest(false);
        }
    }

    //disable button
    const disableButton = () => {
        return iDate && mDate && investAmount

    }

    return (
        <>
            {show7 === true ?
                <>
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "12rem" }} />
                    ) : null}
                    <div className="mt-2 p-6" tabIndex={2}>
                        <Form className=" rounded p-3" >
                            <Row className="form-set">

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Certificate No./Folio No.<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleFolio}
                                        name="folioNumber"
                                        min="0"
                                        value={folioNumber.folioNumber}
                                        style={{ textTransform: "uppercase" }}
                                        autoComplete="off"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.folioNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                {FDHeaderIdPO === 0 || FDHeaderIdPO === undefined ?
                                    <Form.Group className="col-md-3">
                                        <Form.Label>Type of Investment</Form.Label>
                                        <Select
                                            value={selInvestType}
                                            onChange={handleInvestType}
                                            options={InvestmentType}
                                            name="investTypeID"
                                        />
                                    </Form.Group>
                                    :
                                    <Form.Group className="col-md-3">
                                        <Form.Label>Type of Investment</Form.Label>
                                        <Select
                                            value={selInvestType}
                                            onChange={handleInvestType}
                                            options={InvestmentType}
                                            name="investTypeID"
                                            isDisabled
                                        />
                                    </Form.Group>}

                                <Form.Group className="col-md-3">
                                    <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Start Date"
                                        name="idate"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleInvDate}
                                        dateFormat="dd-MM-yyyy"
                                        autoComplete="off"
                                        selected={iDate}
                                        maxDate={new Date()}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.idate}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="totalCost"
                                        min="0"
                                        value={commarize(investAmount.totalCost)}
                                        onChange={handleInvestAmount}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        name="totalCost"
                                        placeholder="Enter Amount"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={investAmount.totalCost}
                                        onChange={handleInvestAmount}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.totalCost}
                                        </div>
                                    ) : null}
                                </Form.Group>

                            </Row>


                            <Row className="form-set">
                                {/* {FDHeaderIdPO == 0 || FDHeaderIdPO == undefined ?
                                    <Form.Group className="col-md-3">
                                        <Form.Label>Type</Form.Label>
                                        <Select
                                            value={selAmountInvestType}
                                            onChange={handleAmountInvType}
                                            options={AmountInvestedType}
                                            name="amountInvestId"
                                        />
                                    </Form.Group>
                                    :
                                    <Form.Group className="col-md-3">
                                        <Form.Label>Type</Form.Label>
                                        <Select
                                            value={selAmountInvestType}
                                            onChange={handleAmountInvType}
                                            options={AmountInvestedType}
                                            name="amountInvestId"
                                            isDisabled
                                        />
                                    </Form.Group>} */}

                                <Col md="3" className="mb-3">
                                    <Form.Label className="d-block">Lock in Period</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setLockinperiod(e.target.value)}
                                        autoComplete="off"
                                        value={lockinperiod}
                                        // name="lockinperiod"
                                        id="lockinperiod"
                                        className="lockinperiodno" />
                                    <Dropdown
                                        value={lockPeriod}
                                        name='lockinperiod'
                                        options={periodList}
                                        onChange={HandleChangePeriod}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"

                                    />
                                </Col>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Interest %</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="interestPerc"
                                        min="0"
                                        value={interestPer.interestPerc}
                                        onChange={handleInterestPer}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Interest Frequency</Form.Label>
                                    <Select
                                        value={selFrequency}
                                        onChange={handleFrequency}
                                        options={dividendFrequency}
                                        name="frequencyId"
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Maturity Date<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Maturity Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDateM}
                                        name="mdate"
                                        dateFormat="dd-MM-yyyy"
                                        autoComplete="off"
                                        selected={mDate}
                                        minDate={new Date(iDate)}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mdate}
                                        </div>
                                    ) : null}
                                </Form.Group>


                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-2">
                                    <Form.Label>Maturity Value<span className="text-danger h6">*</span></Form.Label>
                                    {
                                        reinvestedRadio === "yes" ?

                                            <Cleave
                                                className='form-control'
                                                autoComplete="off"
                                                name="maturityValue"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={maturityVal.maturityValue}
                                                onChange={handleMaturityVal}
                                            />
                                            :

                                            <Cleave
                                                className='form-control'
                                                autoComplete="off"
                                                name="MaturityValue"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={investAmount.totalCost}
                                                disabled
                                            />
                                    }
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-2">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        onChange={handleNarration}
                                        // name="folioNumber"
                                        min="0"
                                        value={narration}
                                        autoComplete="off"
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-2">
                                    <Form.Label>Interest reinvested</Form.Label>
                                    <RadioGroup
                                        row
                                        color="primary"
                                        defaultValue="start"
                                        aria-label="Entity"
                                        name="row-radio-buttons-group"
                                        fontSize="100px"
                                        value={reinvestedRadio}
                                        onChange={handleRadio}
                                        style={{ marginTop: "-2px" }}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                            disabled={isInterest}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                            disabled={isInterest}
                                        />
                                    </RadioGroup>
                                </Form.Group>


                                {reinvestedRadio === "yes" ?
                                    <>
                                        <Form.Group className="col-md-3">
                                            <Form.Label>Interest Accrued till date<span className="text-danger h6">*</span></Form.Label>
                                            {/* <Form.Control
                                                type="Number"
                                                name="interestReinvested"
                                                min="0"
                                                value={interestAcc.interestReinvested}
                                                onChange={handleValueIntAcc}
                                            /> */}
                                            <Cleave
                                                className='form-control'
                                                name="interestReinvested"
                                                autoComplete="off"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={interestAcc.interestReinvested}
                                                onChange={handleValueIntAcc}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.interestAcc}
                                                </div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-3">
                                            <Form.Label>Financial Year starting with<span className="text-danger h6">*</span></Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select Start Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                maxDate={new Date()}
                                                // minDate={new Date(buyDate)} dateFormat="dd-MM-yyyy"
                                                onChange={handleDateStart}
                                                dateFormat="dd-MM-yyyy"
                                                name="fyStartDate"
                                                autoComplete="off"
                                                selected={startDate}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.startDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </> : null
                                }
                            </Row>

                            <Row>
                                <Col md="12" className="text-center mt-4">
                                    {
                                        FDHeaderIdPO === 0 || FDHeaderIdPO === undefined ?
                                            <>
                                                <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                                    <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                                        <Button variant="primary" type="submit"
                                                            id="submitButton"
                                                            className="mx-2 w100" onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                                            disabled={!disableButton()}
                                                        >Submit</Button>
                                                    </span>
                                                </Tooltip>
                                                <Button
                                                    variant="secondary"
                                                    className="mx-2 w100"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    Reset
                                                </Button> </>
                                            :
                                            <>
                                                <Button variant="primary" className="mx-2 w100"
                                                    type="submit"
                                                    id="submitButton"
                                                    onClick={(e) => { e.preventDefault(); handleUpdate(e) }} disabled={!disableButton()}
                                                >Update</Button>
                                                {
                                                    selInvestType.label === "Recurring Deposit" || selInvestType.label === "Sukanya Samriddhi" || selInvestType.label === "PPF" ?
                                                        <>
                                                            <Button variant="primary" className="mx-2"
                                                                onClick={handleInstall} >Add Installment</Button>

                                                        </> : null
                                                }
                                                <Button variant="primary" className="mx-2 w100"
                                                    onClick={handleInterest} disabled={reinvestedRadio === "no"}>Add Interest</Button>
                                                <Button
                                                    variant="primary"
                                                    className="mx-2 w100"
                                                    type="reset"
                                                    onClick={handleMaturity}
                                                    style={{ width: '7rem', height: '2rem' }}
                                                >
                                                    Add Maturity
                                                </Button>

                                            </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <POInterest showPOI={POi} hidePOI={handleCloseInterest} POHeaderId={FDHeaderIdPO} POiD={fdId} InvDate={iDate} onRefreshFD={() => onRefreshFD()} disableCheck={(i) => handledisableCheck(i)} />
                    <POInstallment showPOIn={POInstall} hidePOIn={handleCloseInstallMent} POHeaderId={FDHeaderIdPO} POiD={fdId} InvtDate={iDate} onRefreshFD={() => onRefreshFD()} />
                    <Maturity showMaturity={showPOMaturity} onHideMaturity={handleCloseMaturity} FDHeaderId={FDHeaderIdPO} FDiD={fdId} InvDate={mDate} Fname={"PO Maturity"} onRefreshFD={() => onRefreshFD()} />
                </> : null}
        </>
    )
}

export default PostOffice