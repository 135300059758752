/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Table, Stack } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import { trialSubscribers } from '../ChannelMaster/channalMasterApi'

import UserHistory from './UserHistory';

function TrialMemberTable(props) {
  const {trialSubcribesData , pageCountProp} = props;
  // const [trialSubcribesData, setTrialSubscibersData] = useState([]);

  const [userHistoryModal, setUserHistoryModal] = useState(false);
  const [rowData, setRowData] = useState([]);

  // pagination
  const [pageCount, setPageCount] = useState(pageCountProp)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 10;

  const [userCodeId, setUserCodeId] = useState(0)

  const columns = [
    {
      align: 'center',
      height: 40,
      colName: 'Sr No.'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Trial Code'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Due Date'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Date of payment'
    },
    {
      align: 'center',
      height: 40,
      colName: 'Non converted'
    },
  ]



 


  //handle table row click
  const handleRowClick = (rowData) => {
    setRowData(rowData);
    setUserHistoryModal(true);
    setUserCodeId(rowData.userId)

  }

  //close history pop up
  const handleCloseModal = () => {
    setUserHistoryModal(false);
    setRowData([]);
  }

  const changePage = (e, a) => {
    const selectedPage = e.selected;

  }


  return (
    // <Container sx={{ marginTop: 5 }} fluid>
    <>
      <Paper className='p-3 container mt-3 h-50'>
        <h4 className='text-center mb-4'>Trial Members</h4>
        <TableContainer component={Paper} elevation={4}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
            <TableHead>
              <TableRow sx={{ fontSize: 14 }}>
                {columns.map((col, i) => (
                  <TableCell align={col.align} style={{ height: col.height }} key={i}>{col.colName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                trialSubcribesData.length === 0 ? <>
                  <TableRow>
                    <TableCell colspan="5">No data found</TableCell>
                  </TableRow>

                </> :
                trialSubcribesData.map((row, i) => (
                <TableRow
                  key={i}
                >
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="right" onClick={() => handleRowClick(row)}>{row.subscriberCodeNo}</TableCell>
                  <TableCell align="right">{row.endDate == null ? moment(row.crDate).add(8, 'days').format("DD/MM/YYYY") : moment(row.endDate).add(1, 'days').format("DD/MM/YYYY")}</TableCell>
                  <TableCell align="right">{row.nextRenewalDate}</TableCell>
                  <TableCell align="center">{row.startDate == null && row.endDate == null ? "Non-Converted" : "Converted"}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end"
          mt={1} mb={1}>
          {trialSubcribesData.length ===  0  ? null :
          <Pagination
            count={pageCount}
            page={pageNumber}
            onChange={changePage}
            variant="outlined"
            color="primary"
          />}
        </Stack>
      </Paper>

      <div>
        <UserHistory isOpenModal={userHistoryModal} onHideModal={handleCloseModal} userCodeId={userCodeId} trialMembersCode = {3} />
      </div>
    </>
    //  </Container> 
  )
}

export default TrialMemberTable