import {
	FaTachometerAlt,
	FaHome,
	FaBalanceScale,
	FaMoneyBillWave,
	FaSeedling,
	FaSitemap,
	FaSync,
	FaCog,
	FaPiggyBank,
	FaArtstation,
	FaUser,
	FaTable,
	FaUserPlus,
	FaUsersCog,
	FaRegListAlt
} from 'react-icons/fa';
import { GiClayBrick, GiMoneyStack } from 'react-icons/gi';
import { RiHandCoinFill } from 'react-icons/ri';
import { BiDesktop, BiBarChartSquare } from 'react-icons/bi';
import { MdSupervisedUserCircle } from 'react-icons/md'
import { CgFileDocument, CgUserList } from 'react-icons/cg'
import { VscDashboard } from "react-icons/vsc";
const userRoutes = [
	{
		path: '/',
		routeName: 'Dashboard',
		icon: <FaTachometerAlt />
	},
	{
		path: '/Stock',
		routeName: 'Stocks',
		icon: <FaSitemap />
	},
	{
		path: '/MutualFund',
		routeName: 'Mutual Funds',
		icon: <FaSeedling />
	},
	{
		path: '/Bullion',
		routeName: 'Bullion',
		icon: <GiClayBrick />
	},
	{
		path: '#',
		routeName: 'Futures',
		icon: <FaMoneyBillWave />
	},
	{
		path: '/fixedincome',
		routeName: 'Fixed Income',
		icon: <RiHandCoinFill />
	},
	{
		path: '/Property',
		routeName: 'Property',
		icon: <FaHome />
	},
	{
		path: '/Art',
		routeName: 'Art',
		icon: <FaArtstation />
	},
	{
		path: '/Loans',
		routeName: 'Loans / Deposits',
		icon: <GiMoneyStack />
	},
	{
		path: '/Insurance',
		routeName: 'Insurance',
		icon: <FaPiggyBank />
	},
	{
		path: '/Borrowings',
		routeName: 'Borrowings',
		icon: <FaCog />
	},
	{
		path: '/RecentTransactions',
		routeName: 'Transactions',
		icon: <FaSync />
	},
	{
		path: '/openingBalance',
		routeName: 'Opening Balance',
		icon: <FaBalanceScale />
	},
	{
		path: '/LivePrice',
		routeName: 'Live Price',
		icon: <BiBarChartSquare />
	}
];

const adminRoutes = [
	{
		path: '/token',
		routeName: 'Live Feeds',
		icon: <BiDesktop />
	},
	{
		path: '/Userdetail',
		routeName: 'User Management',
		icon: <FaUser />
	},
	{
		path: '/AccessTable',
		routeName: 'Access Table',
		icon: <FaTable />
	}
	//,
	// {
	// 	path: '/AddChannelPartner',
	// 	routeName: 'Add Channel Partner',
	// 	icon: <MdSupervisedUserCircle />
	// }
];
const UserAdmin = [
	{
		path: '/',
		routeName: 'Dashboard',
		icon: <FaTachometerAlt />
	},
	{
		path: '/Stock',
		routeName: 'Stocks',
		icon: <FaSitemap />
	},
	{
		path: '/MutualFund',
		routeName: 'Mutual Funds',
		icon: <FaSeedling />
	},
	{
		path: '/Bullion',
		routeName: 'Bullion',
		icon: <GiClayBrick />
	},
	// {
	// 	path: '#',
	// 	routeName: 'Futures',
	// 	icon: <FaMoneyBillWave />
	// },
	{
		path: '/fixedincome',
		routeName: 'Fixed Income',
		icon: <RiHandCoinFill />
	},
	{
		path: '/Property',
		routeName: 'Property',
		icon: <FaHome />
	},
	{
		path: '/Art',
		routeName: 'Art',
		icon: <FaArtstation />
	},
	{
		path: '/Loans',
		routeName: 'Loans / Deposits',
		icon: <GiMoneyStack />
	},
	{
		path: '/Insurance',
		routeName: 'Insurance',
		icon: <FaPiggyBank />
	},
	{
		path: '/Borrowings',
		routeName: 'Borrowings',
		icon: <FaCog />
	},
	{
		path: '/RecentTransactions',
		routeName: 'Transactions',
		icon: <FaSync />
	},
	{
		path: '/ReportCard',
		routeName: 'Reports',
		icon: <CgFileDocument />
	},
	{
		path: '/openingBalance',
		routeName: 'Opening Balance',
		icon: <FaBalanceScale />
	},
	{
		path: '/LivePrice',
		routeName: 'Live Price',
		icon: <BiBarChartSquare />
	},
	{
		path: '/token',
		routeName: 'Live Feeds',
		icon: <BiDesktop />
	},
	{
		path: '/Userdetail',
		routeName: 'User Management',
		icon: <FaUser />
	},
	{
		path: '/AccessTable',
		routeName: 'Access Table',
		icon: <FaTable />
	},
	{
		path: '/AddChannelPartner',
		routeName: 'Add Channel Partner',
		icon: <MdSupervisedUserCircle />
	},
	{
		path: '/addSubscriber',
		routeName: 'AddSubscriber',
		icon: <FaUserPlus />
	},
	{
		path: '/ChannelDashboard',
		routeName: 'CPDashboard',
		icon: <VscDashboard />
	},
	{
		path: '/subscriberList',
		routeName: 'CPSubscriberList',
		icon: <CgUserList />
	},
	{
		path: '/channelPartnerList',
		routeName: 'Channel Partner',
		icon: <FaUsersCog size="2rem" />
	},
	{
		path: '/addNewPlans',
		routeName: 'Plans',
		icon: <FaRegListAlt size="1rem" />
	},
];
export { userRoutes, adminRoutes, UserAdmin };
