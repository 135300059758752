import React, { useState } from "react";
import "./Sidebar.css";


import BondsConvertibles from "../FIncomeFolio/other_transactions/BondsConvertibles";
import DebenturesConvertibles from "../FIncomeFolio/other_transactions/DebenturesConvertibles";
import BondsRedeem from "../FIncomeFolio/other_transactions/BondsRedeem";
import DebenturesRedeem from "../FIncomeFolio/other_transactions/DebenturesRedeem";
import RD from "../FIncomeFolio/other_transactions/RD";
// import { useNavigate } from 'react-router-dom';


const FDotherTrsansaction = (props) => {

    const { onRefreshFD } = props;
    // const navigate = useNavigate();

    const [bondsConvertibles, setBondsConvertibles] = useState(false);
    const [debenturesConvertibles, setDebenturesConvertibles] = useState(false);
    const [bondsRedeem, setBondusRedeem] = useState(false);
    const [debenturesRedeem, setDenturesRedeem] = useState(false);
    const [RDDetails, setRDDetails] = useState(false);


    const BondsConHandleChange = () => {
        setBondsConvertibles(true);
    }
    const DebenturesConHandleChange = () => {
        setDebenturesConvertibles(true);
    }
    const BondsRedeemHandleChange = () => {
        setBondusRedeem(true)
    }
    const DebenturesRedeemHandleChange = () => {
        setDenturesRedeem(true)
    }

    // const RDHandleChange = () =>{
    //     setRDDetails(true)
    // }

    const handleclose = () => {

        setBondsConvertibles(false);
        setDebenturesConvertibles(false);
        setBondusRedeem(false);
        setDenturesRedeem(false);
        setRDDetails(false);

    }
    return (
        <>
            <div className="dropdown">
                <div className="dropbtn">Other Transactions</div>
                <div className="dropdown-content">


                    {/* <li onClick={RDHandleChange}>RD</li> */}
                    {/* <div className="dropdownFI3">
                        <div className="dropbtn" style={{ color: "black" }}>FD</div>
                        <div className="dropdown-contentFI3">
                        <li onClick={FDHandleChange}>FD Buy</li>
                        <li onClick={BondsConHandleChange}>Maturity</li>
                        <li onClick={handleChangefdi}>Interest</li>
                        </div>
                    </div> */}
                    <div className="dropdownFI1">
                        <div className="dropbtn" style={{ color: "black" }}>Bonds Shares</div>
                        <div className="dropdown-contentFI1">
                            <li onClick={BondsRedeemHandleChange}>Redeem</li>
                            <li onClick={BondsConHandleChange}>Convert</li>
                        </div>
                    </div>
                    <div className="dropdownFI2">
                        <div className="dropbtn" style={{ color: "black" }}>Debentures Shares</div>
                        <div className="dropdown-contentFI2">
                            <li onClick={DebenturesRedeemHandleChange}>Redeem</li>
                            <li onClick={DebenturesConHandleChange}>Convert</li>
                        </div>
                    </div>
                </div>
            </div>

            <BondsConvertibles showBC={bondsConvertibles} onHideBC={handleclose} onRefreshFD={() => onRefreshFD()} />
            <DebenturesConvertibles showDC={debenturesConvertibles} onHideDC={handleclose} onRefreshFD={() => onRefreshFD()} />
            <BondsRedeem showBR={bondsRedeem} onHideBR={handleclose} onRefreshFD={() => onRefreshFD()} />
            <DebenturesRedeem showDR={debenturesRedeem} onHideDR={handleclose} onRefreshFD={() => onRefreshFD()} />
            <RD showRD={RDDetails} onHideRD={handleclose} onRefreshFD={() => onRefreshFD()} />
            {/* <FDInterest showFDI={fdi} onHideFDI={handleclose} onRefresh={() => onRefresh()} /> */}
        </>
    )
}
export default FDotherTrsansaction;