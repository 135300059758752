/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import Loader from "../../components/loadingAnimation/index";
import DatePickerR from 'react-datepicker';
import Dropdown from '../CommonComponent/Dropdown';
import MaterialTable, { MTableEditField } from "material-table";
import { Alert, AlertTitle } from '@material-ui/lab';
import Select, { components } from "react-select";
import Tooltip from '@material-ui/core/Tooltip';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { brokerList, GetDividendFrequency, deleteStock, ValuationList } from '../../pages/Stocks/StocksAPI';
import {
  GetMutualFundCompanies, AddMutualFundCompanies, AddMutualFundDetails, UpdateMutualFundBuySellDetails, GetMutualFundSellCompanies,
  AvailableMFSellQuantity, SelectionCompany, DeleteMFTransactions, GetMutualFundFolioNo, removeMutualFund , SelectionMutualFundUpdate
} from '../../pages/MutualFund/MutualFundAPI'
import swal from "sweetalert";
import Swal from "sweetalert2";
import Cleave from 'cleave.js/react';
import _, { toNumber } from "lodash";
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import CleavTablFirstTxtField, { CleavTablSecondTxtField, cleavedisabledField } from '../StocksDetail/ClevTablCss';
import { periodList } from '../../modules/helpers/constant'
import moment from 'moment'


const MutualFundDetail = (props) => {
  const { showMutualFund, hideMutualFund, mutualFTransaction, mutualFHeaderId, entityId, onMFTransactionGridRefresh, handleCloseModel } = props;

  const [folioNoList, setFolioNoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [brokerOptionsListed, setBrokerOptionsListed] = useState([]);
  const [formErr, setFormErr] = useState({});
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [avilableQty, setAvilableQty] = useState(0);
  const [avilableSellQty, setAvilableSellQty] = useState(0);
  const [divFreqValue, setDivFreqValue] = useState([]);
  const [frequencyValue, setFrequencyValue] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [value, setValue] = useState(null);
  const [sellingCompanyList, setSellingCompanyList] = useState([]);
  const [call, setcall] = useState(0);
  const [Mtooltip, setMtooltip] = useState(false);
  const [enabledAdisabled, setEnabledAdisabled] = useState(false);
  const [stockValuationType, setStockValuationType] = useState([{ label: "FIFO", value: 1 }]);
  const [errorMessagesSell, setErrorMessagesSell] = useState([]);
  const [isError, setIsError] = useState(false);
  const [narration, setNarration] = useState("");
  const [show, setShow] = useState(false);
  const [scheme, setScheme] = useState('');
  const [schemeErr, setSchemeErr] = useState('');
  const [subAccountId, setSubAccountId] = useState(null);
  const [headerId, setHeaderId] = useState(0);

  const [updatedStockVaualtion,setUpdatedValuationType] = useState([])

  const [brokerValue, setBrokerValue] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [error, setError] = useState('')

  //radio button states
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  //addition modal states
  const [showUlipAdd, setShowUlipAdd] = useState(false);
  const [maturityDateUlip, setMaturityDateUlip] = useState(null);
  const [lastPremiumDate, setLastPremiumDate] = useState(null);
  const [ulipNominees, setNominees] = useState("");
  const [ulipBonus, setUlipBonus] = useState("");
  const [ulipGoal, setUlipGoal] = useState("");
  const [ulipMaturityValue, setUlipMaturityValue] = useState("");
  const [lockIn, setLockIn] = useState([{ label: "Month", value: "Month" }]);
  const [lockInValue, setLockInValue] = useState("Month");
  const [lockinperiod, setLockinperiod] = useState("");
  const [time, setTime] = useState([{ label: "Month", value: "Month" }]);
  const [timeValue, setTimeValue] = useState("Month");
  const [tenurePeriod, setTenurePeriod] = useState("");

  //buy Table arrays
  const [allEntry1, setAllEntry1] = useState([]);
  const [allEntry2, setAllEntry2] = useState([]);
  const [allEntry3, setAllEntry3] = useState([]);


  //sell Table Arrays
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  //buy Table Reference
  const tableRef1 = useRef();
  const tableRef2 = useRef();
  const tableRef3 = useRef();

  //sell Table Reference
  const tableRefSell1 = useRef();
  const tableRefSell2 = useRef();
  const tableRefSell3 = useRef();

  //selection
  const [selection, setSelection] = useState(false);
  const [selectionQty, setSelectionQty] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessagesQntity, setErrorMessagesQntity] = useState([]);

  // sip columns
  // const [columnsB1,setColumnsB1] = useState([]);
  // lumpsum columns
  // const [columnsB2,setColumnsB2] = useState([]);
  // ulip columns
  // const [columnsB3, setColumnsB3] = useState([]);

  //columns sell state
  const [columnsS1, setColumnsS1] = useState([]);
  const [columnsS2, setColumnsS2] = useState([]);
  const [columnsS3, setColumnsS3] = useState([]);

  // seperation by comma
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
  }
  const NumberOnlySeperator = (input) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(input)
  }
  // useEffect(() => {
  //   // if (show1 && companyList) {
  //   //   setColumnsB1(
  //   //     [
  //   //     {
  //   //       title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData != undefined) {
  //   //           if (props.rowData.tableData.editing != undefined) {
  //   //             if (props.rowData.tableData.editing == 'update') {
  //   //               if (props.value.label != undefined) {
  //   //                 if (props.value.label != props.rowData.mutualFundCompany.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.mutualFundCompany
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.mutualFundCompany
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             components={{ Control }}
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             options={companyList}
  //   //             name="companyId"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //             isDisabled={props.rowData.mutualFundId != undefined ? true : false}
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Units', field: 'units', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Amount Invested', field: 'investmentAmt', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'NAV / Purchase Price', field: 'nav', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let calculatedNAV = 0;
  //   //         if (props.rowData.investmentAmt && props.rowData.units) {
  //   //           calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
  //   //           props.value = calculatedNAV;
  //   //           props.rowData.nav = calculatedNAV;
  //   //         } else {
  //   //           props.value = 0;
  //   //           props.rowData.nav = 0;
  //   //         }
  //   //         return <MTableEditField {...props} disabled={true} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'SIP Date', field: 'sipDate', type: 'date',
  //   //       // editComponent: ({ ...props }) => {
  //   //       //   return <MTableEditField  {...props} />;
  //   //       // },
  //   //       editComponent: props => {
  //   //         return (
  //   //           <MuiPickersUtilsProvider utils={DateFnsUtils}
  //   //             locale={props.dateTimePickerLocalization}>
  //   //             <DatePicker
  //   //               format="dd/MM/yyyy"
  //   //               value={props.value || null}
  //   //               onChange={props.onChange}
  //   //               clearable
  //   //               // minDate={new Date(date)}
  //   //             // maxDate={Date.now()}
  //   //             />
  //   //           </MuiPickersUtilsProvider>
  //   //         )
  //   //       }
  //   //     },
  //   //     {
  //   //       title: 'SIP Amount', field: 'sipAmt', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'SIP Frequency', field: 'frequencyId', width: 130,
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData != undefined) {
  //   //           if (props.rowData.tableData.editing != undefined) {
  //   //             if (props.rowData.tableData.editing == 'update') {
  //   //               if (props.value.label != undefined) {
  //   //                 if (props.value.label != props.rowData.frequency.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.frequency
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.frequency
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             options={frequencyValue}
  //   //             name="sipFrequency"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'No. of Installments', field: 'numberOfInstalments', width: 70, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Maturity Date', field: 'maturityDate', type: 'date',
  //   //       editComponent: props => {
  //   //         return (
  //   //           <MuiPickersUtilsProvider utils={DateFnsUtils}
  //   //             locale={props.dateTimePickerLocalization}>
  //   //             <DatePicker
  //   //               format="dd/MM/yyyy"
  //   //               value={props.value || null}
  //   //               onChange={props.onChange}
  //   //               // minDate={new Date(date)}
  //   //               clearable
  //   //             // maxDate={Date.now()}
  //   //             />
  //   //           </MuiPickersUtilsProvider>
  //   //         )
  //   //       }
  //   //     },
  //   //     { title: 'Certificate / Folio No.', field: 'certificateFolioNo', width: 50 },
  //   //     { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
  //   //     { field: 'recommendedBy', title: 'Recommended by', width: 130 },
  //   //   ])
  //   // }
  //   // if (show2 && companyList) {
  //   //   setColumnsB2(
  //   //     [

  //   //     {
  //   //       title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData !== undefined) {
  //   //           if (props.rowData.tableData.editing !== undefined) {
  //   //             if (props.rowData.tableData.editing === 'update') {
  //   //               if (props.value.label !== undefined) {
  //   //                 if (props.value.label !== props.rowData.mutualFundCompany.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.mutualFundCompany
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.mutualFundCompany
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             components={{ Control }}
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             options={companyList}
  //   //             name="companyId"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //             isDisabled={props.rowData.mutualFundId != undefined ? true : false}
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'certificateFolioNo', title: 'Folio Number ', width: 70,
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'units', title: 'Units', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       field: 'investmentAmt', title: 'Amount Invested', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //     },

  //   //     {
  //   //       field: 'nav', title: 'NAV / Purchase Price', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let calculatedNAV = 0;
  //   //         if (props.rowData.investmentAmt && props.rowData.units) {
  //   //           calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
  //   //           props.value = calculatedNAV;
  //   //           props.rowData.nav = calculatedNAV;
  //   //         }
  //   //         else {
  //   //           props.value = 0;
  //   //           props.rowData.nav = 0;
  //   //         }
  //   //         return <MTableEditField {...props} disabled={true} />;
  //   //       },
  //   //     },

  //   //     { field: 'estHoldingPeriod', title: 'Estimated Holding period', width: 130 },
  //   //     { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
  //   //   ])
  //   // }
  //   // if (show3 && companyList) {
  //   //   setColumnsB3([
  //   //     {
  //   //       title: 'Scheme', field: 'mutualFundCompanyId', width: 200,
  //   //       editComponent: (props) => {
  //   //         let ddValue = props.value;
  //   //         if (props.rowData.tableData !== undefined) {
  //   //           if (props.rowData.tableData.editing !== undefined) {
  //   //             if (props.rowData.tableData.editing === 'update') {
  //   //               if (props.value.label !== undefined) {
  //   //                 if (props.value.label !== props.rowData.mutualFundCompany.label) {
  //   //                   ddValue = props.value
  //   //                 }
  //   //                 else {
  //   //                   ddValue = props.rowData.mutualFundCompany
  //   //                 }
  //   //               }
  //   //               else {
  //   //                 ddValue = props.rowData.mutualFundCompany
  //   //               }
  //   //             }
  //   //           }
  //   //         }
  //   //         return (
  //   //           <Select
  //   //             components={{ Control }}
  //   //             value={ddValue || null}
  //   //             onChange={(selected) => props.onChange(selected)}
  //   //             options={companyList}
  //   //             name="companyId"
  //   //             onKeyDown={e => handleKey(e)}
  //   //             menuPosition="fixed"
  //   //             menuShouldBlockScroll="true"
  //   //             isDisabled={props.rowData.mutualFundId != undefined ? true : false}
  //   //           />
  //   //         )
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Insurance Company', field: 'insuranceCompany', width: 190, type: 'text',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Insured Name', field: 'nameOfInsured', width: 130, type: 'text',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },

  //   //     { title: 'Policy Number', field: 'policyNumber', width: 50 },

  //   //     {
  //   //       title: 'Units', field: 'units', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         // handleAddRowBuy();
  //   //         return <MTableEditField {...props} />;
  //   //       },
  //   //     },
  //   //     {
  //   //       title: 'Sum Assured', field: 'sumAssured', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //       render: (rowData) => (
  //   //         <span>
  //   //           {NumberSeperator(rowData.sumAssured)}
  //   //         </span>
  //   //       ),
  //   //     },
  //   //     {
  //   //       title: 'Premium Amount', field: 'investmentAmt', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //       render: (rowData) => (
  //   //         <span>
  //   //           {NumberSeperator(rowData.investmentAmt)}
  //   //         </span>
  //   //       ),
  //   //     },
  //   //     {
  //   //       title: 'Current Market Value', field: 'currentMarketValue', width: 130, type: 'numeric',
  //   //       editComponent: ({ ...props }) => {
  //   //         let value = props.rowData.currentMarketValue ? props.rowData.currentMarketValue : 0
  //   //         props.rowData.currentMarketValue = value;
  //   //         return <MTableEditField  {...props} />;
  //   //       },
  //   //       render: (rowData) => (
  //   //         <span>
  //   //           {NumberSeperator(rowData.currentMarketValue)}
  //   //         </span>
  //   //       ),
  //   //     },


  //   //     {
  //   //       title: "Addtional Data",
  //   //       field: "internal_action",
  //   //       // editable: false,
  //   //       editComponent: ({ ...props }) => {
  //   //         let t = props.rowData
  //   //         return <span onClick={() => {
  //   //           // if (mutualFHeaderId == 0) {
  //   //           if (t.dateOfLastPremium !== undefined && t.dateOfLastPremium != "") {
  //   //             setLastPremiumDate(t.dateOfLastPremium ? new Date(t.dateOfLastPremium) : null);
  //   //           }
  //   //           if (t.maturityDate !== undefined && t.maturityDate != "") {
  //   //             setMaturityDateUlip(t.maturityDate ? new Date(t.maturityDate) : null);
  //   //           }
  //   //           if (t.bonusAccrued !== undefined) {
  //   //             setUlipBonus(t.bonusAccrued)
  //   //           }
  //   //           if (t.goal !== undefined) {
  //   //             setUlipGoal(t.goal);
  //   //           }
  //   //           if (t.nominees !== undefined) {
  //   //             setNominees(t.nominees);
  //   //           }
  //   //           if (t.maturityValue !== undefined) {
  //   //             setUlipMaturityValue(t.maturityValue);
  //   //           }
  //   //           if (t.lockInPeriod !== undefined) {
  //   //             let d = t.lockInPeriod
  //   //             let splitData = d.split(/([0-9]+)/);
  //   //             setLockInValue(splitData[2])
  //   //             setLockIn({ label: splitData[2], value: splitData[2] })
  //   //             setLockinperiod(splitData[1])
  //   //           }
  //   //           if (t.tenure !== undefined) {
  //   //             let d = t.tenure
  //   //             let splitData = d.split(/([0-9]+)/);
  //   //             setTimeValue(splitData[2])
  //   //             setTime({ label: splitData[2], value: splitData[2] })
  //   //             setTenurePeriod(splitData[1])
  //   //           }

  //   //           // }
  //   //           // setCost(t.totalCost)
  //   //           setShowUlipAdd(true);
  //   //         }}
  //   //           title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
  //   //       }
  //   //     }

  //   //   ]);
  //   // }
  // }, [show1, show2, show3, companyList])


  useEffect(() => {
    if (showMutualFund === true) {
      setSubAccountId(entityId == null ? subAccountId : entityId);
      setHeaderId(0);
      setLoading(true);
      getBrokerList();
      GetMFCompnies();
      // GetMutualFundMasterListCompanies();
      getDividedFrequencyList();
      getStockValuation();
    }
  }, [showMutualFund]);


  useEffect(() => {
    (async () => {
      await setShow3(false);
      await setShow2(false);
      await setShow1(true);
      await setSubAccountId(entityId == null ? subAccountId : entityId);
      if (mutualFHeaderId !== 0) {
        if (Object.keys(mutualFTransaction).length !== 0) {
          setHeaderId(mutualFHeaderId);
          if (mutualFTransaction.instrumentTypeId !== 10) {
            // setTypeStock(mutualFTransaction.type);
            // setSTT(mutualFTransaction.sst == null ? 0 : mutualFTransaction.sst);

          }


          mutualFTransaction.mutualFundDetails && mutualFTransaction.mutualFundDetails.map((t) => {

            if (mutualFTransaction.instrumentTypeId === 41 || mutualFTransaction.instrumentTypeId === 42 || mutualFTransaction.instrumentTypeId === 43) {
              if (t.transactionType !== "Sell") {
                if (mutualFTransaction.instrumentTypeId === 43) {
                  let d = t.lockInPeriod
                  let splitData = d.split(/([0-9]+)/);
                  setTimeValue(splitData[2]);
                  setTime({ label: splitData[2], value: splitData[2] });
                  setTenurePeriod(splitData[1]);

                  let c = t.tenure;
                  let splitDat = c.split(/([0-9]+)/);
                  setLockInValue(splitDat[2]);
                  setLockIn({ label: splitDat[2], value: splitDat[2] });
                  setLockinperiod(splitDat[1]);

                  setLastPremiumDate(new Date(t.dateOfLastPremium));
                  setDivFreqValue({ label: t.frequency.frequencyName, value: t.frequencyId });
                  setNominees(t.nominees);
                  setUlipBonus(t.bonusAccrued);
                  setUlipGoal(t.goal);
                  setUlipMaturityValue(t.maturityValue);
                  setMaturityDateUlip(new Date(t.maturityDate));
                }
              } else {
                setSelectionQty(JSON.parse(t.selection))

              }
            }
          })

          setBrokerValue({ value: mutualFTransaction.brokerId, label: mutualFTransaction.broker.brokerName });
          setValue(mutualFTransaction.brokerId);
          if (mutualFTransaction.instrumentTypeId === 42) {
            await setShow1(false);
            await setShow2(true);
            await setShow3(false);
          }
          else if (mutualFTransaction.instrumentTypeId === 41) {
            await setShow1(true);
            await setShow2(false);
            await setShow3(false);
          } else {
            await setShow1(false);
            await setShow2(false);
            await setShow3(true);
          }
          setDate(new Date(mutualFTransaction.date));
          // setStampDuty(mutualFTransaction.stampDuty == null ? 0 : mutualFTransaction.stampDuty);
          // setGST(mutualFTransaction.gst == null ? 0 : mutualFTransaction.gst);
          // setOtherCharges(mutualFTransaction.otherCharges == null ? 0 : mutualFTransaction.otherCharges);
          // setTotalAmountDue(mutualFTransaction.totalAmountDue == null ? 0 : mutualFTransaction.totalAmountDue);
          setSubAccountId(mutualFTransaction.subAccountId);
          setNarration(mutualFTransaction.narration);
          mutualFTransaction.mutualFundDetails && mutualFTransaction.mutualFundDetails.map((a) => {
            // a.mutualFundCompany = { 'value': a.mutualFundCompanyId, 'label': a.mutualFundCompany.mutualFundCompanyName }
            if (mutualFTransaction.instrumentTypeId === 41) {
              a.frequency = { 'value': a.frequencyId, 'label': a.frequency.frequencyName }
            }
          })
          let Buy = [];
          let Sell = [];
          let newMFDetail = mutualFTransaction.mutualFundDetails && mutualFTransaction.mutualFundDetails.map((t) => {
            return {
              ...t,
              stockValuation: { 'value': t.stockValuationId, 'label': t.stockValuation.stockValuationName },
              mutualFundCompanyId: t.mutualFundCompany.label,
              stockValuationId: t.stockValuation.stockValuationName,
              stockQty: t.units,
              frequencyId: t.frequency.label,
              unlistedFolio: { 'value': t.instrumentTypeId === 43 ? t.policyNumber : t.certificateFolioNo, 'label': t.instrumentTypeId === 43 ? t.policyNumber : t.certificateFolioNo }
            }
          })
          newMFDetail && newMFDetail.map((s) => {
            if (s.transactionType === 'Sell') {
              Sell.push(s);
            }
            if (s.transactionType === 'Buy') {
              // if (s.brokeragePerShare == null) {
              //   s.brokeragePerShare = 0;
              //   s.totalBrokerage = 0;
              // }
              Buy.push(s);
            }
          })
          if (mutualFTransaction.instrumentTypeId === 42) {
            setdata2(Sell)
          } else if (mutualFTransaction.instrumentTypeId === 41) {
            setdata1(Sell)
          } else {
            setdata3(Sell)
          }

          if (mutualFTransaction.instrumentTypeId === 42) {
            setAllEntry2(Buy)
          } else if (mutualFTransaction.instrumentTypeId === 41) {
            setAllEntry1(Buy)
          } else {
            setAllEntry3(Buy)
          }
        }
        else {
          clearForm();
        }
      }
      else {
        clearForm();
      }
    })();
  }, [mutualFTransaction])

  const clearForm = () => {
    setDate(new Date());
    setBrokerValue([]);
    setNarration('');
    setMtooltip(false)
    setSellingCompanyList([]);
    setSubAccountId(null);
    setAllEntry1([]);
    setAllEntry2([]);
    setAllEntry3([]);
    setdata1([]);
    setdata2([]);
    setdata3([]);
    setHeaderId(0);
    // setMtooltip(false);
    // setStampDuty(0);
    // setUnlistedSellingCompanyList([]);
    // setUnlistedCompanyList([]);
    setValue(null);
    setSelectionQty([]);
    setcall(0);
    setAvilableQty(0);
    setFolioNoList([]);
  }

  const GetMFCompnies = async () => {
    setLoading(true);
    await GetMutualFundCompanies().then((res) => {
      let result = res.data._response.data.mutualFundCompanies;
      let CompanyData = [];
      result && result.map((x) => {
        CompanyData.push({
          value: x.mutualFundCompanyId,
          label: x.mutualFundCompanyName,
          CompanyType: x.instrumentTypeId
        })
      })
      setCompanyList(CompanyData);
      setLoading(false);
    }).catch((err) => {
      console.log(err, "err in error");
      setLoading(false);
    })
  }


  //getdividend list
  const getDividedFrequencyList = async () => {
    setLoading(true);
    await GetDividendFrequency()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let dividend = res.data._response.data.dividendfrequencyDetails;
            let dividendData = [];
            dividend &&
              dividend.map((data) => {
                dividendData.push({
                  value: data.frequencyId,
                  label: data.frequencyName,
                });
              });
            setFrequencyValue(dividendData)
            if (mutualFHeaderId === 0) {
              setDivFreqValue(dividendData[1])
            }
          }
        } else {
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        // setError(e.message);
      });
  };
  const getBrokerList = async () => {
    let EntityID = entityId == null ? subAccountId : entityId
    setLoading(true);
    await brokerList(EntityID).then(res => {
      setLoading(false)
      if (res.status === 200) {
        let brokers = [];
        if (res.data._response.status === 1) {
          brokers = res.data._response.data.brokerDetails;
          let brokersData = [];
          let dataBrk = [];
          brokers && brokers.map(data => {
            brokersData.push({ value: data.brokerId, label: data.brokerName })
            dataBrk.push({ value: data.brokerId, label: data.brokerName })
          })
          setBrokerOptions(brokersData);
          // let dataBrker = dataBrk.shift()
          setBrokerOptionsListed(dataBrk)


        }
      } else {
        setLoading(false)
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
      setLoading(false)
    })
  }

  function replaceNonNumeric(numStr) {

    return String(numStr).replace(/[^0-9]/g, '')

  }

  function commarize(numStr) {

    return Number(replaceNonNumeric(numStr)).toLocaleString()

  }

  // Ulip buy addtional modal onChange
  const handleAddtiUlipData = (e) => {
    const { name, rawValue,value } = e.target;
    if (name === "nominees") {
      setNominees(value);
    } else if (name === "bonus") {
      setUlipBonus(rawValue);
    } else if (name === "goal") {
      setUlipGoal(rawValue)
    } else {
      setUlipMaturityValue(rawValue);
    }

  }
  const HandleChangePeriod = (e) => {
    setTime(e);
    setTimeValue(e.value);
  }

  const HandleChangeLockIn = (e) => {
    setLockIn(e);
    setLockInValue(e.value);
  }

  const handleChangeFrequency = (e) => {
    setDivFreqValue(e);
  }
  //reset ulip addition modal
  const handleResetModalPopUp = () => {
    setLastPremiumDate(null);
    setNominees("");
    setUlipBonus("");
    setUlipGoal("");
    setUlipMaturityValue("")
    setTenurePeriod("");
    setMaturityDateUlip(null);
    setLockinperiod("")
  }

  //close ulip addition modal
  const handlecloseDescModal = () => {
    setShowUlipAdd(false);
    setFormErr('')
  }

  //submit ulip addtional modal
  const submitPreModal = () => {
    handlecloseDescModal();
  }

  const handleCloseMFModal = () => {
    hideMutualFund()
    setBrokerValue([])
    setErrorMessages([]);
    setErrorMessagesSell([]);
    setIserror(false);
    setIsError(false);
    setMtooltip(false)
    setFormErr({});
  }
  const HandleChange = (event) => {
    setValue(event.value);
    setBrokerValue(event);
    setMtooltip(false)
    setSellingCompanyList([]);
    setAvilableQty(0);
    setFormErr({ ...formErr, broker: '' });
    setMtooltip(false);
  }

  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  const handleCancel = () => {
    setErrorMessagesSell([]);
    setErrorMessages([]);
    setIserror(false);
    setIsError(false)
    setFormErr({});
    setBrokerValue([]);
    setMtooltip(false);
    setErrorMessagesQntity([]);
    clearForm();
    // onHide();
    hideMutualFund();
  }
  const errHandle = () => {
    let formErr = {};
    let isValid = true;
    let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : allEntry3;
    let resultDataSell = show1 === true ? data1 : show2 === true ? data2 : data3;
    if (brokerValue.length < 1) {
      isValid = false;
      formErr["broker"] = "Please Select Broker";
    }
    if (resultDataBuy.length <= 0 && resultDataSell.length <= 0) {
      isValid = false;
      Swal.fire('Please fill Buy/Sell Data!!', "", "warning");
    }
    setFormErr(formErr)
    return isValid
  }
  // Add Stock transaction
  const AddMFtransaction = async () => {
    let isValid = errHandle();
    let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : allEntry3;
    let resultDataSell = show1 === true ? data1 : show2 === true ? data2 : data3;
    if (isValid === true) {
      setLoading(true);

      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultBuy = deepCloneBuy.map(function (o) {
        o.mutualFundCompanyId = o.mutualFundCompany.value;
        if (show1 === true) {
          o.frequencyId = o.frequency.value;
        }
        return o;

      })

      var deepCloneSell = _.cloneDeep(resultDataSell);
      var resultSell = deepCloneSell.map(function (o) {
        o.mutualFundCompanyId = o.mutualFundCompany.value;
        o.stockValuationId = o.stockValuation.value;
        return o;
      })

      let MFData = {};
      MFData = {
        instrumentId: 8,
        instrumentTypeId: show1 === true ? 41 : show2 === true ? 42 : 43,
        date: fixTimezoneOffset(date),
        subAccountId: subAccountId,
        brokerId: brokerValue.value,
        // Type: show2 == true ? null : typeStock,
        mutualFundDetails: [...resultBuy, ...resultSell],
        narration: narration,
      }
      await AddMutualFundDetails(MFData).then(res => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            Swal.fire(res.data._response.message, '', 'success');
            clearForm();
            // setSuccess(true);
            // onTransactionGridRefresh();
          } else {
            Swal.fire("Something Went Wrong", '', 'info');
          }
        }
      }).catch(e => {
        setError(e.message)
        setLoading(false);
      })
    }
  }
  const UpdateMFtransaction = async () => {
    let isValid = errHandle()
    if (isValid === true) {
      setLoading(true);
      let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : allEntry3;
      let resultDataSell = show1 === true ? data1 : show2 ? data2 : data3;
      var deepCloneBuy = _.cloneDeep(resultDataBuy);
      var resultBuy = deepCloneBuy.map(function (o) {
        if (o.mutualFundCompany !== undefined) {
          o.mutualFundCompanyId = o.mutualFundCompany.value;
          if (show1 === true) {
            o.frequencyId = o.frequency.value;
          }
          o.stockValuationId = o.mutualFundId !== undefined ? o.stockValuation.value : 4;
        }
        return o;
      })
      var deepCloneSell = _.cloneDeep(resultDataSell);
      var resultSell = deepCloneSell.map(function (o) {
        if (o.mutualFundCompany !== undefined) {
          o.mutualFundCompanyId = o.mutualFundCompany.value;
        }
        if (o.stockValuation !== undefined) {
          o.stockValuationId = o.stockValuation.value;
        }
        return o;
      })
      let listed = {};
      listed = {
        mutualFundHeaderId: mutualFHeaderId,
        instrumentId: 8,
        instrumentTypeId: show1 === true ? 41 : show2 === true ? 42 : 43,
        date: fixTimezoneOffset(date),
        subAccountId: subAccountId,

        brokerId: value,
        mutualFundDetails: [...resultBuy, ...resultSell],
        narration: narration,
      }
    
      await UpdateMutualFundBuySellDetails(listed).then(res => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            Swal.fire("Updated successfully!", "", 'success');
            onMFTransactionGridRefresh();
          } else {
            Swal.fire("Something went wrong!", "", 'info');

          }
        }
      }).catch(e => {
        setError(e.message);
        setLoading(false);
      })
    }
  }

  //get stock valuation data
  const getStockValuation = async () => {
    await ValuationList().then(res => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          let valuation = res.data._response.data.stockvaluationtDetails;
          let vaulationData = [];
          valuation && valuation.map(data => {
            if (data.stockValuationId !== 2 && data.stockValuationName !== "Average") {
              vaulationData.push({ value: data.stockValuationId, label: data.stockValuationName })
            }
          })
          let updatedValuationData =[];
          valuation && valuation.map(data =>{
            if(data.stockValuationId === 3){
              updatedValuationData.push({value:data.stockValuationId,label: data.stockValuationName})
            }

          })

          setUpdatedValuationType(updatedValuationData);
          setStockValuationType(vaulationData);
        }
      } else {
        setError(res.data.message)
      }
    }).catch(e => {
      setError(e.message)
    })
  }

  //open selection modal
  const getSelection = (row, stockValId) => {
    let mutualFID = row.rowData.mutualFundId;
    let selection = row.rowData.selection;
    let Id = entityId == null ? subAccountId : entityId;
    let emptyArr = row.rowData.selection;
    let editing = row.rowData.tableData === undefined ? undefined : row.rowData.tableData.editing;
    
    let instId = show1 ? 41 : show2 ? 42 : 43;
    if (stockValId === 3 && Id != null && (row.rowData.mutualFundCompanyId !== undefined || row.rowData.mutualFundCompany !== undefined) && (row.rowData.certificateFolioNo !== undefined || row.rowData.unlistedFolio !== undefined)) {
      let mutualFundCompanyId = row.rowData.mutualFundCompanyId.value === undefined ? row.rowData.mutualFundCompany.value : row.rowData.mutualFundCompanyId.value;
      let folioNo = row.rowData.certificateFolioNo.value === undefined ? row.rowData.unlistedFolio.value : row.rowData.certificateFolioNo.value;
      setSelection(true);
      setLoading(true);
      
      if (selection === undefined || emptyArr === [] || editing === "update") {
        SelectionCompany(Id, mutualFundCompanyId, value, instId, folioNo).then(res => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 2) {
              setSelectionQty([]);
              setSelectedRows([]);
            }
            else {
             
              let AvlQuantity = res.data._response.data.fetchSelectionCompany;
              let selectionQtys=[];
              AvlQuantity && AvlQuantity.map((r, index) => {
                // return { id: index, MutualFundId: r.mutualFundId, sellQuantity: 0.00, DateOfInvestment: r.dateOfInvestment, AvailableUnit: r.availableUnit, Nav: r.nav }
                if (selectionQty.length !== 0) {
             
                  selectionQty && selectionQty.map((s, i) => {
                    if (i === index) {
                      let a = r;
                      let totalSellQuantity = s.sellQuantity <= s.totalSellQuantity ? s.totalSellQuantity : s.sellQuantity;
                      // let totalSellQuantity = s.sellQuantity + s.totalSellQuantity;
                      let selectionminusquantity = r.availableUnit - (s.sellQuantity + (s.totalSellQuantity ? s.totalSellQuantity : 0.00))
                      // (s.sellQuantity <=  s.totalSellQuantity ? s.totalSellQuantity : s.sellQuantity);
                      selectionQtys.push({ id: index, MutualFundId: r.mutualFundId, sellQuantity: 0.00, totalSellQuantity: totalSellQuantity, DatePurchase: r.dateOfInvestment, AvailableUnit: selectionminusquantity, Nav: r.nav })
                    }
                  })
                } else {
                  // debugger
                  selectionQtys.push({ id: index, MutualFundId: r.mutualFundId, sellQuantity: 0.00, totalSellQuantity: 0.00, DateOfInvestment: r.dateOfInvestment, AvailableUnit: r.availableUnit, Nav: r.nav })
                }
              })

              setSelectionQty(selectionQtys);
              setSelectedRows([]);
            }
          } else {
            setLoading(false);
            setError(res.data.message)
          }
        }).catch(e => {
          setError(e.message)
          setLoading(false);
        })
      } else {

        // let parseData = JSON.parse(row.rowData.selectionAll);
        let parseData = JSON.parse(row.rowData.selection);
        setSelectionQty(parseData);
        setLoading(false);
      }
    }
    if(mutualFID && editing === 'update'){
      SelectionMutualFundUpdate(mutualFID).then(res => {
        if(res.status === 200){
          if (res.data._response.status === 1) {
            let AvlQuantity = res.data._response.data.selectionDataForMutualFundUpdate;
            let selectionQtys = AvlQuantity && AvlQuantity.map((r, index) => {
              return { id: index, MutualFundId: r.mutualFundId, sellQuantity: 0.00, DateOfInvestment: r.dateOfInvestment, AvailableUnit: r.units, Nav: r.nav, sellMutualFundId: r.sellMutualFundId }
            })
            setSelectionQty(selectionQtys);
           
          }
        }
      }).catch(e =>{
        setError(e.message)
        setLoading(false);
      })
    }
  }

  //close selection modal
  const selectionHide = () => setSelection(false);

  //function for adding a new row to the table
  const handleRowAdd = async (newData, resolve, reject, a) => {
    let companyData = newData.mutualFundCompanyId;
    if (show1 === true) {
      var frequencyData = newData.frequencyId;
    }
    let errorList = []
    if (show1 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Company Name Field");
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (!newData.frequencyId) {
        errorList.push("Please Select Sip Frequncy Field");
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Certificate Folio Number Field");
      }
    }
    if (show2 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Company Name Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Folio Number Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (newData.investmentAmt < 1000) {
        errorList.push("Please Enter Minimum Amount 1000 In Amount Invested")
      }
      // if (!newData.investmentAmt || newData.investmentAmt < 1000 || isNaN(newData.investmentAmt) ) {
      //     // errorList.push("Please Enter Amount Invested")
      //   // if(newData.investmentAmt < 1000){
      //   // }
      // }
    }
    if (show3 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.nameOfInsured) {
        errorList.push("Please Enter Insured Name Field")
      }
      if (!newData.policyNumber) {
        errorList.push("Please Enter Policy No. Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.sumAssured || newData.sumAssured < 0 || isNaN(newData.sumAssured)) {
        errorList.push("Please Enter Sum Assured Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Premium Amount Field")
      }

    }
    if (errorList.length < 1) {
      newData.mutualFundCompanyId = newData.mutualFundCompanyId.label;
      newData.mutualFundCompany = companyData;
      if (show1 === true) {
        newData.frequencyId = newData.frequencyId.label;
        newData.frequency = frequencyData;
        newData.maturityDate = newData.maturityDate ? newData.maturityDate : null
    
      }
      if (show3) {
        newData.nav = newData.investmentAmt / newData.units
        newData.dateOfLastPremium = lastPremiumDate ? fixTimezoneOffset(lastPremiumDate) : null;
        newData.dateOfPremium = date ? fixTimezoneOffset(date) : null;
        newData.maturityDate = maturityDateUlip ? fixTimezoneOffset(maturityDateUlip) : null;
        newData.bonusAccrued = Number(ulipBonus);
        newData.goal = ulipGoal;
        newData.nominees = ulipNominees;
        newData.maturityValue = Number(ulipMaturityValue);
        newData.tenure = tenurePeriod === "" ? "0" + timeValue : tenurePeriod + timeValue;
        newData.lockInPeriod = lockinperiod === "" ? "0" + lockInValue : lockinperiod + lockInValue;
        newData.frequencyId = divFreqValue.value;
      }
      let newUserdata = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : [...allEntry3];
      newData.instrumentTypeId = show1 === true ? 41 : show2 === true ? 42 : 43;
      newUserdata.push(newData);
      var result = newUserdata.map(function (o) {
        if (o.mutualFId === undefined) {
          o.transactionType = "Buy";
          o.stockValuationId = 4;
        }
        return o;
      })
      show1 === true ? setAllEntry1(result) : show2 === true ? setAllEntry2(result) : setAllEntry3(result);
      resolve();
      handleResetModalPopUp()
      setErrorMessages([]);
      setIserror(false);
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      reject();
    }
  }
  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    let cpy = newData.mutualFundCompany.label;
    let companyData = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompany : newData.mutualFundCompanyId;
    if (show1 === true) {
      var fpy = newData.frequency.label;
      var frequencyData = fpy === newData.frequencyId && newData.frequencyId.label === undefined ? newData.frequency : newData.frequencyId;
    }

    let errorList = [];
    if (show1 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Company Name Field");
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (!newData.frequencyId) {
        errorList.push("Please Select Sip Frequncy Field");
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Certificate Folio Number Field");
      }
    }
    if (show2 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Enter Company Name Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Folio Number Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units) ) {
        errorList.push("Please Enter Units Field ")
      }
      if (!newData.investmentAmt) {
        errorList.push("Please Enter Amount Invested Field")
      }
      if (newData.investmentAmt < 1000) {
        errorList.push("Please Enter Minimum Amount 1000 In Amount Invested")
      }
      // if (!newData.investmentAmt || newData.investmentAmt < 0 || newData.investmentAmt === NaN) {
      //   errorList.push("Please Enter Amount Invested")
      // }
    }
    if (show3 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.nameOfInsured) {
        errorList.push("Please Enter Insured Name Field")
      }
      if (!newData.policyNumber) {
        errorList.push("Please Enter Policy No. Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }
      if (!newData.sumAssured || newData.sumAssured < 0 || isNaN(newData.sumAssured)) {
        errorList.push("Please Enter Sum Assured Field")
      }
      if (!newData.investmentAmt || newData.investmentAmt < 0 || isNaN(newData.investmentAmt)) {
        errorList.push("Please Enter Premium Amount Field")
      }

    }
    if (errorList.length < 1) {
      const updateStock = show1 === true ? [...allEntry1] : show2 ? [...allEntry2] : [...allEntry3];
      const index = oldData.tableData.id;
      newData.mutualFundCompanyId = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompanyId : newData.mutualFundCompanyId.label !== undefined ? newData.mutualFundCompanyId.label : newData.mutualFundCompanyId.label;
      newData.mutualFundCompany = companyData;
      if (show1 === true) {
        newData.frequencyId = fpy === newData.frequencyId && newData.frequencyId.label === undefined ? newData.frequencyId : newData.frequencyId.label !== undefined ? newData.frequencyId.label : newData.frequencyId.label;
        newData.frequency = frequencyData;
        newData.maturityDate = newData.maturityDate ? newData.maturityDate : null
   
      }
      //check is addtional modal value is changed
      let checkIsChange = (lastPremiumDate !== null || ulipNominees !== "" || ulipBonus !== "" || ulipGoal !== "" || tenurePeriod !== "" || timeValue !== "Month" || maturityDateUlip !== null || ulipMaturityValue !== "" || lockinperiod !== "" || lockInValue !== "Month")
      if (show3 && checkIsChange) {
        newData.dateOfLastPremium = lastPremiumDate ? fixTimezoneOffset(lastPremiumDate) : null;
        newData.dateOfPremium = date ? fixTimezoneOffset(date) : null;
        newData.maturityDate = maturityDateUlip ? fixTimezoneOffset(maturityDateUlip) : null;
        newData.bonusAccrued = Number(ulipBonus);
        newData.goal = ulipGoal;
        newData.nominees = ulipNominees;
        newData.maturityValue = Number(ulipMaturityValue);
        newData.tenure = tenurePeriod === "" ? "0" + timeValue : tenurePeriod + timeValue;
        newData.lockInPeriod = lockinperiod === "" ? "0" + lockInValue : lockinperiod + lockInValue;
        newData.frequencyId = divFreqValue.value;
      }

      newData.instrumentTypeId = show1 === true ? 41 : show2 === true ? 42 : 43;

      updateStock[index] = newData;
      show1 === true ? setAllEntry1([...updateStock]) : show2 === true ? setAllEntry2([...updateStock]) : setAllEntry3([...updateStock]);
      resolve();
      handleResetModalPopUp();
      setErrorMessages([]);
      setIserror(false);
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      reject();
    }
  }
  //function for deleting a row
  const handleRowDelete = async (oldData, resolve, reject) => {
    const dataDelete = show1 === true ? [...allEntry1] : show2 === true ? [...allEntry2] : [...allEntry3];
    if (oldData.mutualFundId === undefined) {
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : setAllEntry3([...dataDelete]);
      resolve()

    }
    else {
      removeMutualFund(oldData.mutualFundId).then(async (res) => {
        if (res.data._response.status === 1) {
          Swal.fire(res.data._response.message, '', 'success');
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          await show1 === true ? setAllEntry1([...dataDelete]) : show2 === true ? setAllEntry2([...dataDelete]) : setAllEntry3([...dataDelete]);
          resolve()
          handleCloseModel()
          // onTransactionGridRefresh();
        }
        else {
          Swal.fire(res.data._response.message, '', 'info');
          reject()
        }
      })
        .catch(err => {
          console.log(err);
        })
    }
  }

  ///SELL


  useEffect(() => {
    setLoading(true);
    if (showMutualFund) {
      setSubAccountId(entityId == null ? subAccountId : entityId);
      // getSellingCompany(entityId == null ? subAccountId : entityId);
      setLoading(false);
      if (entityId !== null || value !== null) {
        getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);

      }
    }

  }, [value, allEntry1, show1, show2, showMutualFund, entityId, show3])


  //instrument type wise sell companies
  const getSellingCompanyByInsType = async (entityId) => {
    if (entityId != null && value != null) {
      let insTypId;
      // { show1 ? insTypId = 1 : show2 ? insTypId = 10 : show3 ? insTypId = 11 : insTypId = 20 }
      { show1 ? insTypId = 41 : show2 ? insTypId = 42 : insTypId = 43 }
        setLoading(true);
      await GetMutualFundSellCompanies(entityId, value, insTypId).then(res => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let company = res.data._response.data.fetchCompanyBySub;
            let companyData = [];
            company && company.map(data => {
              companyData.push({ value: data.mutualFundCompanyId, label: data.mutualFundCompanyName })
            })
            let resultDataBuy = show1 === true ? allEntry1 : show2 === true ? allEntry2 : allEntry3;
            let newCompany = [];
            resultDataBuy && resultDataBuy.map((p) => {

              newCompany.push(p.mutualFundCompany);
            })
            let result = _.unionBy(companyData, newCompany, 'label');
            setSellingCompanyList(result);

          } else {
            setSellingCompanyList([]);
          }
        } else {
          setLoading(false);
          setError(res.data.message)
          setSellingCompanyList([]);
        }
      }).catch(e => {
        setLoading(false);
        setError(e.message)
        setSellingCompanyList([]);
      })
    }
    else {
      setLoading(false);
    }
  }

  //function for adding a new sell row to the table
  const handleRowAddSell = async (newData, resolve, reject) => {
    if (!newData.stockValuationId) {
      newData.stockValuationId = { value: 1, label: 'FIFO' }
    }
    let company = newData.mutualFundCompanyId;
    let unlistedFolio = newData.certificateFolioNo;
    let stockValuation = newData.stockValuationId === undefined ? 1 : newData.stockValuationId;
    let sumSellmFund = 0;
    if (selectionQty !== null) {
      for (let i = 0; i < selectionQty.length; i++) {
        sumSellmFund += selectionQty[i].sellQuantity;
      }
    }
    let errorList = []
    if (show1 === true || show2 === true || show3 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Select Folio Number Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }

      else if (newData.units > avilableQty) {
        errorList.push("Please Enter Units In Range Of Available Units");
      }


      if (!newData.receivable) {
        errorList.push("Please Enter Actuable Recievable Field")
      }
      // if (!newData.actualReceivable) {
      //   errorList.push("Please Enter Maturity Proceeds Received Field")
      // }
      if (!show3) {
        if (!newData.nav) {
          errorList.push("Please Check Value For Nav Field")
        }
      }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorList.push('Please Select Units For Selection Calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && Number(newData.units).toFixed(4) !== sumSellmFund.toFixed(4)) {
          errorList.push('Units Of Selection Must Be Equal To Sell Units');
        }
      }

    }
    if (errorList.length < 1) {

      let newUserdata = show1 === true ? [...data1] : show2 ? [...data2] : [...data3];

      //contains all selection data
      // newData.selectionAll =  JSON.stringify(selectionQty);
      newData.selection = JSON.stringify(selectionQty);
      // newData.nav = newData.
      //contains checked selection data
      newData.policyNumber = show3 ? newData.certificateFolioNo.label : null;
      newData.certificateFolioNo = newData.certificateFolioNo.label;
      newData.unlistedFolio = unlistedFolio;
      newData.mutualFundCompanyId = newData.mutualFundCompanyId.label;
      newData.stockValuationId = newData.stockValuationId.label;
      newData.mutualFundCompany = company;
      newData.stockValuation = stockValuation;
      newData.nav = newData.actualReceivable / newData.units
      newData.availableQuantity = avilableQty;
      newData.availableSellQuantity = avilableSellQty;
      // newData.units = avilableQty;
      newData.transactionType = 'Sell';
      // newData.selection =  JSON.stringify(selectionQty);
      newUserdata.push(newData);

      var newResult = [...newUserdata];

      await show1 === true ? setdata1(newResult) : show2 === true ? setdata2(newResult) : setdata3(newResult);
      // let buy = show1 === true ? allEntry1 :  allEntry2 ;
      setErrorMessagesSell([])
      setAvilableQty(0);
      setIsError(false);
      resolve()
    } else {
      setErrorMessagesSell(errorList)
      setIsError(true)
      reject()
    }


  }
  //function for  updating the existing sell row details
  const handleRowUpdateSell = (newData, oldData, resolve, reject) => {
    let cpy = newData.mutualFundCompany.label;
    let companyData = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompany : newData.mutualFundCompanyId;
    let mutualFE = newData.stockValuation.label;
    let stockValuationData = mutualFE === newData.stockValuationId && newData.stockValuationId.label === undefined ? newData.stockValuation : newData.stockValuationId;

    let folioNo = newData.unlistedFolio.label;
    let folioData = folioNo === newData.certificateFolioNo && newData.certificateFolioNo.label === undefined ? newData.unlistedFolio : newData.certificateFolioNo;

    let sumSellmFund = 0;
    if (newData.stockValuationId.label === 'Selection') {
      for (let i = 0; i < selectionQty.length; i++) {
        sumSellmFund += selectionQty[i].sellQuantity;
      }
    }
    let errorList = []
    if (show1 === true || show2 === true || show3 === true) {
      if (!newData.mutualFundCompanyId) {
        errorList.push("Please Select Scheme Name Field")
      }
      if (!newData.certificateFolioNo) {
        errorList.push("Please Enter Folio Number Field")
      }
      if (!newData.units || newData.units < 0 || isNaN(newData.units)) {
        errorList.push("Please Enter Units Field")
      }

      else if (newData.units > avilableQty) {
        errorList.push("Please Enter Units In Range Of Avilable Units");
      }


      if (!newData.receivable) {
        errorList.push("Please Enter Actuable Recievable Field")
      }
      // if (!newData.actualReceivable) {
      //   errorList.push("Please Enter Maturity Proceeds Received Field")
      // }
      if (!show3) {
        if (!newData.nav) {
          errorList.push("Please Check Value For Nav Field")
        }
      }
      if (newData.stockValuationId !== undefined) {
        if (newData.stockValuationId.label === 'Selection' && selectionQty.length <= 0) {
          errorList.push('please select Units for selection calculation')
        }
        if (newData.stockValuationId.label === 'Selection' && newData.units !== sumSellmFund) {
          errorList.push('Units of selection must be equal to sell units');
        }
      }

    }

    if (errorList.length < 1) {
      const updateStock = show1 === true ? [...data1] : show2 === true ? [...data2] : [...data3];
      const index = oldData.tableData.id;
      newData.mutualFundCompanyId = cpy === newData.mutualFundCompanyId && newData.mutualFundCompanyId.label === undefined ? newData.mutualFundCompanyId : newData.mutualFundCompanyId.label !== undefined ? newData.mutualFundCompanyId.label : newData.mutualFundCompanyId.label;
      newData.mutualFundCompany = companyData;

      newData.stockValuationId = mutualFE === newData.stockValuationId && newData.stockValuationId.label === undefined ? newData.stockValuationId : newData.stockValuationId.label !== undefined ? newData.stockValuationId.label : newData.stockValuationId.label;
      newData.stockValuation = stockValuationData;

      newData.certificateFolioNo = folioNo === newData.certificateFolioNo && newData.certificateFolioNo.label === undefined ? newData.certificateFolioNo : newData.certificateFolioNo.label !== undefined ? newData.certificateFolioNo.label : newData.certificateFolioNo.label;
      newData.unlistedFolio = folioData;

      newData.nav = newData.actualReceivable / newData.units
      //for ulip policy number used
      newData.policyNumber = show3 ? folioNo : null;

      // let arrayById = selectionQty.filter(obj => obj.tableData.checked === true).map(i => {
      //   return { ...i };
      // })

      newData.selection = JSON.stringify(selectionQty);
      updateStock[index] = newData;
      show1 === true ? setdata1([...updateStock]) : show2 === true ? setdata2([...updateStock]) : setdata3([...updateStock]);
      resolve()
      // let buy = show1 == true ? allEntry1 : show2 == true ? allEntry2 : show3 == true ? allEntry3 : allEntry4;
      // handleTotalAmt(buy, [...updateStock]);
      setErrorMessagesSell([])
      setIserror(false)
      setAvilableQty(0);
    } else {
      setErrorMessagesSell(errorList)
      setIsError(true)
      reject();
    }
  }
  //function for deleting a sell row
  const handleRowDeleteSell = async (oldData, resolve, reject) => {
    const dataDelete = show1 === true ? [...data1] : [...data2];
    if (oldData.mutualFundId === undefined) {
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      await show1 === true ? setdata1([...dataDelete]) : show2 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
      // let buy = show1 == true ? allEntry1 :  allEntry2 ;
      resolve()
    }
    else {
      removeMutualFund(oldData.mutualFundId).then(async (res) => {
        if (res.data._response.status === 1) {
          Swal.fire(res.data._response.message, '', 'success');
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          await show1 === true ? setdata1([...dataDelete]) : show2 === true ? setdata2([...dataDelete]) : setdata3([...dataDelete]);
          // let buy = show1 == true ? allEntry1 :  allEntry2 ;
          resolve();
          onMFTransactionGridRefresh();
        }
        else {
          Swal.fire(res.data._response.message, '', 'info');
          reject()
        }
      })
        .catch(err => {
          console.log(err);
        })
    }
  }

  //delete stocks transaction
  const DeleteMFtransaction = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          DeleteMFTransactions(mutualFHeaderId).then((response) => {
            if (response.status === 200) {

              swal(response.data._response.message, {
                title: "Deleted",
                icon: "success",
              });
              onMFTransactionGridRefresh();
              handleCloseMFModal();
              handleCloseModel()
            } else {
              swal({
                title: "Ooops!!",
                text: "Somthing Wrong",
                icon: "warning",
              })
            }

          })
            .catch((error) => {
              console.log(error, "error in delete transaction")
            })

        } else {
          Swal.fire("Cancelled", "Your  data is safe ", "error");
        }

      });
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }
  // const handleDisableOption = (option) => {
  //   if (show3 || show4) {
  //     if (option.value == 1 || option.value == 2) {
  //       return true
  //     }
  //   }
  // }

  const handleShow = () => setShow(!show);
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <span onMouseDown={handleShow} className="m-0" title="Add unlisted scrip">
        <button style={{ backgroundColor: "transparent", border: "none" }}>  <i className="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      {children}
    </components.Control>
  )

  //sumbit new mutual fund company
  const submitMFaddCompany = () => {
    if (scheme !== '' && scheme !== null && scheme !== undefined) {
      scheme.trim()
      let result = scheme.replace(/^\s+|\s+$/gm, '');
      let comp = result.replace(/[^a-zA-Z0-9\-\_ ]/g, "")

      if (comp !== "") {
        let Id = entityId == null ? subAccountId : entityId;
        let comId = show1 ? 41 : show2 ? 42 : 43;
        let data = {
          mutualFundCompanyName: comp,
          instrumentTypeId: comId,
          subAccountId: Id
        }
        setLoading(true);
        AddMutualFundCompanies(data).then((res) => {
          setLoading(false);
          if (res.status === 200) {
            if (res.data._response.status === 1) {
              const x = res.data._response.data.newCompanyDetail
              companyList.unshift({
                value: x.mutualFundCompanyId,
                label: x.mutualFundCompanyName,
              })
              handleClose();
              // GetMFCompnies();
              Swal.fire("Scheme added Successfully", "", "success");
            } else {
              Swal.fire(res.data._response.message, "", "info");
            }
          }
        }).catch((err) => {
          setLoading(false);
          console.log(err, "err in add company submit")
        })
      } else {
        setSchemeErr("Please check scheme name !")
      }
    } else {
      setSchemeErr("Scheme name required!")
    }
  }

  const ListedCompanySelect = async (ddValue, qty, MFID) => {
    if (brokerValue.length < 1) {
      setMtooltip(true)
    }
    else {
      setMtooltip(false);
      if (ddValue !== undefined) {

        let comapnyId = ddValue.value;


        let Id = entityId == null ? subAccountId : entityId;
        let instId = show1 ? 41 : show2 ? 42 : 43;

        if ((!MFID) && Id && comapnyId && value && instId) {

          await GetMutualFundFolioNo(Id, comapnyId, instId, value)
            .then(res => {
              if (res.status === 200) {
                setLoading(false);
                if (res.data._response.status === 1) {
                  let data = res.data._response.data.folioNumbers;
                  let arr = [];

                  data && data.map((d, i) => {
                    arr.push({ label: d, value: d });
                  });
                  setFolioNoList(arr);
                  // setPremiumNoList(arr);
                } else {
                  setFolioNoList([]);
                }
              }
            })
            .catch(e => {
              console.log(e, 'error in getFolioNo Api ');
              setLoading(false);
            })


        }
      }
    }
  }

  const AvailableSellingQty = (ddvalue, rowData) => {
    let quantity = 0;
    let editQuantity = 0;

    if (ddvalue !== undefined) {
      let comapnyId = rowData.mutualFundCompanyId.value === undefined ? rowData.mutualFundCompany.value : rowData.mutualFundCompanyId.value;
      let FolioNo = ddvalue.value;
      let Id = entityId == null ? subAccountId : entityId;
      let instId = show1 ? 41 : show2 ? 42 : 43;

      let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : allEntry3;
      let resultSell = show1 ? data1 : show2 ? data2 : data3;

      resultBuy && resultBuy.map((r) => {
        if (r.mutualFundCompany.value === comapnyId && r.mutualFundId === undefined) {
          quantity += r.units;
        }
      })
      resultSell && resultSell.map((r) => {
        if (r.mutualFundCompany.value === comapnyId && r.mutualFundId === undefined) {
          quantity -= r.units;
        }
      })
      // handle changed qty of entry with stockId , @stockQty -old qty , @units- new changed qty
      resultSell && resultSell.map((r) => {
        if (r.mutualFundCompany.value === comapnyId && r.units !== r.stockQty && r.mutualFundId !== undefined) {
          editQuantity = r.units > r.stockQty ? r.units - r.stockQty : -(r.stockQty - r.units)
        }
      })

      if (Id && comapnyId && value && instId && FolioNo) {
        AvailableMFSellQuantity(Id, comapnyId, value, instId, FolioNo).then(res => {
          if (res.status === 200) {
            setLoading(false);
            if (res.data._response.status === 2) {
              setAvilableQty(0 + quantity);
            }
            else {
              // let AvlQuantity = res.data._response.data.remainingQuantity;
              let overAllQuantity = res.data._response.data.available_Unit;
              setAvilableSellQty(overAllQuantity);
              setAvilableQty(overAllQuantity + quantity - editQuantity);
            }
          } else {
            setError(res.data.message)
            setAvilableQty(0 + quantity);
            setLoading(false);
          }
        }).catch(e => {
          // setError(e.message)
          setLoading(false);
        })
      }
    }
  }



  const AvaliableSellQty = (d) => {
    let brkId = d.brokerId;
    let compId = d.mutualFundCompany.value
    let Id = entityId == null ? subAccountId : entityId;
    let instId = show1 ? 41 : show2 ? 42 : 43;
    let FolioNo = d.certificateFolioNo;
    //current transaction quantity
    let curreQty = d.stockQty;

    let quantity = 0;
    let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : allEntry3;
    let resultSell = show1 ? data1 : show2 ? data2 : data3;

    resultBuy && resultBuy.map((r) => {
      if (r.mutualFundCompany.value === compId && r.mutualFundId === undefined) {
        quantity += r.units;
      }
    })
    resultSell && resultSell.map((r) => {
      if (r.mutualFundCompany.value === compId && r.mutualFundId === undefined) {
        quantity -= r.units;
      }
    })
    if (Id && compId && value && instId && show1 || show2 || show3 && FolioNo) {
      AvailableMFSellQuantity(Id, compId, brkId, instId, FolioNo).then(res => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data._response.status === 2) {
            setAvilableQty(0 + curreQty + quantity);
          }
          else {
            let AvlQuantity = res.data._response.data.available_Unit;
            setAvilableQty(AvlQuantity + curreQty + quantity);
          }
        } else {
          setError(res.data.message)
          setAvilableQty(0 + curreQty + quantity);
          setLoading(false);
        }
      }).catch(e => {
        setError(e.message)
        setLoading(false);
      })
    }
  }
  const AvailableSellingQtyForUpdate = (d) => {
    let compId = d.mutualFundCompany.value;
    let rowId = d.tableData.id;
    let overallUnit = d.availableSellQuantity;

    let quantity = 0;
    let editQuantity = 0;
    let resultBuy = show1 ? allEntry1 : show2 ? allEntry2 : allEntry3;
    let resultSell = show1 ? data1 : show2 ? data2 : data3;

    resultBuy && resultBuy.map((r) => {
      if (r.mutualFundCompany.value === compId && r.mutualFundId === undefined) {
        quantity += r.units;
      }
    })
    resultSell && resultSell.map((r) => {
      if (r.mutualFundCompany.value === compId && r.mutualFundId === undefined && r.tableData.id !== rowId) {
        quantity -= r.units;
      }
    })
    // handle changed qty of entry with stockId , @stockQty -old qty , @units- new changed qty
    resultSell && resultSell.map((r) => {
      if (r.mutualFundCompany.value === compId && r.units !== r.stockQty && r.mutualFundId !== undefined) {
        editQuantity = r.units > r.stockQty ? r.units - r.stockQty : -(r.stockQty - r.units)
      }
    })

    setAvilableQty(overallUnit + quantity - editQuantity);

  }

  const handleClose = () => {
    setShow(false);
    setScheme('');
    setSchemeErr('')
  }
  const handleScheme = (e) => {
    const { value } = e.target;
    // console.log("value",value);
    // var nospecial=/^[^*|\":<>[\]{}`\\()';@&$]+$/;
    // const regex = /^[A-Za-z0-9 ]+$/

    // const restrict = value.replace(/[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '') 
    // const isValid = regex.test(value);
    // if (isValid || value === "") {
      // setScheme(value);
    // }
    // else{
    // setScheme(value); 
      
    // }
    setScheme(value);
    setSchemeErr('')
  }
  //Broker Validation on selling company selection
  const brokerSelect = () => {
    setMtooltip(false)
    if (brokerValue.length < 1) {
      setMtooltip(true)
      setEnabledAdisabled(true);
    }
    if (sellingCompanyList.length < 1) {
      setEnabledAdisabled(true);
    }
    else {
      setEnabledAdisabled(false);
    }
  } 

  useEffect(() => {
    if (showMutualFund) {
      setSubAccountId(entityId == null ? subAccountId : entityId);
      setLoading(true);
      getSellingCompanyByInsType(entityId == null ? subAccountId : entityId);
    }

  }, [call, showMutualFund])

  // columns sell 
  useEffect(() => {
    //SIP sell 
    setColumnsS1([
      {
        title: 'Scheme', field: 'mutualFundCompanyId',
        width: 250,
        editComponent: (props) => {
          let ddValue = props.value;
          if (value === null) {
            ddValue = null;
          }
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.mutualFundCompany.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.mutualFundCompany;
                  }
                }
                else {
                  ddValue = props.rowData.mutualFundCompany;
                  //ListedCompanySelect(ddValue);
                }
              }
            }
          }
          return (
            <>
              <Select
                value={sellingCompanyList.length === 0 ? null : ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected)
                  ListedCompanySelect(selected, 0)
                }}
                onFocus={() => { setcall(1); brokerSelect() }}
                options={sellingCompanyList}
                name="mutualFundCompanyId"
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select Company.."
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              // isDisabled={mutualFHeaderId != 0 ? true : false}
              />{Mtooltip === true ? <span style={{ color: "red" }}>Please Select Broker</span>
                : brokerValue.value !== undefined && sellingCompanyList.length === 0 ? <span style={{ color: "red" }}>There are no companies under this broker!</span> : null}
            </>
          )
        },
      },
      {
        field: 'certificateFolioNo', title: 'Folio Number ', width: '10%',
        editComponent: (props) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.unlistedFolio.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.unlistedFolio;
                  }
                }
                else {
                  ddValue = props.rowData.unlistedFolio;
                  //  CompanySelect(ddValue)
                }
              }
            }
          }
          return (
            <>
              <Select
                value={ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected);
                  AvailableSellingQty(selected, props.rowData);
                }}
                // onFocus={() => setcall(1)}
                options={folioNoList}
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select folio.."
                name="folio"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              />
            </>
          )
        },
      },
      {
        title: 'Units', field: 'units', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberOnlySeperator(rowData.units)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.rowData.mutualFundId !== undefined) {
                  AvaliableSellQty(props.rowData)
                } else {
                  // setAvilableQty(props.rowData.availableQuantity)

                  AvailableSellingQtyForUpdate(props.rowData);
                }
              }
            }
          }
          return (
            <Tooltip title={`The Available Units With This Folio no. is:${NumberOnlySeperator(avilableQty)}`} arrow open={avilableQty >= 0 && props.rowData.mutualFundCompanyId !== undefined ? true : avilableQty === undefined ? false : false}>
              {/* <MTableEditField {...props} placeholder={'units'} disabled={enabledAdisabled} /> */}
              <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                <Cleave
                  className="MuiInputBase-input MuiInput-input"
                  placeholder='Units'
                  autoComplete='off'
                  options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                  value={props.value}
                  disabled={enabledAdisabled}
                  onChange={e => props.onChange(toNumber(e.target.rawValue))} />
              </div>
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'NAV', field: 'nav', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.nav)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          let calculatedNAV = 0;
          if (props.rowData.actualReceivable && props.rowData.units) {
            calculatedNAV = (props.rowData.actualReceivable / props.rowData.units).toFixed(2);
            props.value = calculatedNAV;
            props.rowData.nav = toNumber(calculatedNAV);
          } else {
            props.value = 0;
            props.rowData.nav = 0;
          }
          return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder="NAV"
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh'}}
            value={props.value}
            disabled
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
          // <MTableEditField {...props} disabled={true} />;
        },
      },
      {
        title: 'Receivable', field: 'receivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.receivable)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='Receivable'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh' }}
              value={props.value}
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
        }
      },
      {
        title: 'TDS', field: 'tds', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.tds)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='TDS'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh' }}
              value={props.value}
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
          // <MTableEditField {...props} />;
        }
      },
      {
        title: 'Maturity Proceeds received', field: 'actualReceivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.actualReceivable)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          let actualReceivable = 0;
          actualReceivable = props.rowData.tds === 0 ? props.rowData.receivable : props.rowData.receivable - props.rowData.tds;
          props.value = actualReceivable.toFixed(2);
          props.rowData.actualReceivable = Number(actualReceivable.toFixed(2));
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='Maturity Proceeds received'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh' }}
              value={props.value}
              disabled
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
          // <MTableEditField {...props} />;
        },
      },
      {
        title: 'Stock Valuation Type', field: 'stockValuationId',
        editComponent: ({ ...props }) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.stockValuation.label) {
                    ddValue = props.value;
                    console.log("Inner grid", ddValue);
                  }
                  else {
                    ddValue = props.rowData.stockValuation;
                    console.log("else grid", ddValue);
                  }
                }
                else {
                  ddValue = props.rowData.stockValuation;
                  console.log("outer grid", props.rowData.selection);
                  if (ddValue.value === 3) {
                    //setSelectionQty(JSON.parse(props.rowData.selection));
                    //getSelection(props, ddValue.value);
                  }
                }
              }
            }
          }
          return (
            <Select
              value={ddValue ? ddValue : [{ value: 1, label: "FIFO" }]}
              //  onFocus={() => SelectionSelect(props, ddValue)}
              onChange={(selected) => {
                props.onChange(selected)
                if (selected.label === "Selection") {
                  getSelection(props, selected.value);
                }
              }}

              options={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 3) ? updatedStockVaualtion : stockValuationType}
              name="stockValuationId"
              onKeyDown={e => handleKey(e)}
              menuPosition="fixed"
              menuShouldBlockScroll="true"
              isDisabled={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value === 1  || props.rowData.stockValuation.value !== 3) ? true : props.rowData.mutualFundCompanyId === undefined ? true : false}
            />
          )
        }
      }
    ])

    //lumpsum
    setColumnsS2([
      {
        title: 'Scheme', field: 'mutualFundCompanyId',
        width: 250,
        editComponent: (props) => {
          let ddValue = props.value;
          if (value === null) {
            ddValue = null;
          }
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.mutualFundCompany.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.mutualFundCompany;
                  }
                }
                else {
                  ddValue = props.rowData.mutualFundCompany;
                  //ListedCompanySelect(ddValue);
                }
              }
            }
          }
          return (
            <>
              <Select
                value={sellingCompanyList.length === 0 ? null : ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected)
                  ListedCompanySelect(selected, 0)
                }}
                options={sellingCompanyList}
                name="mutualFundCompanyId"
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select Company.."
                onFocus={() => { setcall(1); brokerSelect() }}
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              />{Mtooltip === true ? <span style={{ color: "red" }}>Please Select Broker</span>
                : brokerValue.value !== undefined && sellingCompanyList.length === 0 ? <span style={{ color: "red" }}>There are no companies under this broker!</span> : null}
            </>
          )
        },
      },
      {
        field: 'certificateFolioNo', title: 'Folio Number ',
        editComponent: (props) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.unlistedFolio.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.unlistedFolio;
                  }
                }
                else {
                  ddValue = props.rowData.unlistedFolio;
                  //  CompanySelect(ddValue)
                }
              }
            }
          }
          return (
            <>
              <Select
                value={ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected);
                  AvailableSellingQty(selected, props.rowData);
                }}
                // onFocus={() => setcall(1)}
                options={folioNoList}
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select folio.."
                name="folio"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              />
            </>
          )
        },
      },
      {
        title: 'Units', field: 'units', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberOnlySeperator(rowData.units)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.rowData.mutualFundId !== undefined) {
                  AvaliableSellQty(props.rowData)
                } else {
                  AvailableSellingQtyForUpdate(props.rowData);
                  // setAvilableQty(props.rowData.availableQuantity)
                }
              }
            }
          }
          return (
            <Tooltip title={`The Available Units With This Folio no. is:${NumberOnlySeperator(avilableQty)}`} arrow open={avilableQty >= 0 && props.rowData.mutualFundCompanyId !== undefined ? true : avilableQty === undefined ? false : false}>
              {/* <MTableEditField {...props} placeholder={'units'} disabled={enabledAdisabled} /> */}
              <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                <Cleave
                  className="MuiInputBase-input MuiInput-input"
                  placeholder= 'Units'
                  autoComplete='off'
                  options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                  value={props.value}
                  disabled={enabledAdisabled}
                  onChange={e => props.onChange(toNumber(e.target.rawValue))} />
              </div> 
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'NAV', field: 'nav', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.nav)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          let calculatedNAV = 0;
          if (props.rowData.actualReceivable && props.rowData.units) {
            calculatedNAV = (props.rowData.actualReceivable / props.rowData.units).toFixed(2);
            props.value = calculatedNAV;
            props.rowData.nav = calculatedNAV;
          } else {
            props.value = 0;
            props.rowData.nav = 0;
          }
          return  <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='NAV'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
          //  <MTableEditField {...props} disabled={true} />;
        },
      },
      {
        title: 'Receivable', field: 'receivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.receivable)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='Receivable'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
              value={props.value}
              disabled={enabledAdisabled}
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
        }
      },
      {
        title: 'TDS', field: 'tds', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.tds)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='TDS'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
              value={props.value}
              disabled={enabledAdisabled}
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
          // <MTableEditField {...props} />;
        }
      },
      {
        title: 'Maturity Proceeds received', field: 'actualReceivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.actualReceivable)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          let actualReceivable = 0;
          actualReceivable = props.rowData.tds === 0 ? props.rowData.receivable : props.rowData.receivable - props.rowData.tds;
          props.value = actualReceivable.toFixed(2);
          props.rowData.actualReceivable = Number(actualReceivable.toFixed(2));
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
            <Cleave
              className="MuiInputBase-input MuiInput-input"
              placeholder='Maturity Proceeds received'
              autoComplete='off'
              options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
              value={props.value}
              disabled={true}
              onChange={e => props.onChange(toNumber(e.target.rawValue))} />
          </div>
          </div>
          // <MTableEditField {...props} />;
        },
      },
      {
        title: 'Stock Valuation Type', field: 'stockValuationId',
        editComponent: ({ ...props }) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.stockValuation.label) {
                    ddValue = props.value;
                    console.log("Inner grid", ddValue);
                  }
                  else {
                    ddValue = props.rowData.stockValuation;
                    console.log("else grid", ddValue);
                  }
                }
                else {
                  ddValue = props.rowData.stockValuation;
                  console.log("outer grid", props.rowData.selection);
                  if (ddValue.value === 3) {
                    //setSelectionQty(JSON.parse(props.rowData.selection));
                    //getSelection(props, ddValue.value);
                  }
                }
              }
            }
          }
          return (
            <Select
              value={ddValue ? ddValue : [{ value: 1, label: "FIFO" }]}
              //  onFocus={() => SelectionSelect(props, ddValue)}
              onChange={(selected) => {
                props.onChange(selected)
                if (selected.label === "Selection") {
                  getSelection(props, selected.value);
                }
              }}

              options={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 3) ? updatedStockVaualtion : stockValuationType}
              name="stockValuationId"
              onKeyDown={e => handleKey(e)}
              menuPosition="fixed"
              menuShouldBlockScroll="true"
              // isDisabled={isNaN(props.rowData.totalCost) ? true : props.rowData.mutualFId != undefined ? true : false}
              //isDisabled={props.rowData.mutualFundCompanyId === undefined ? true : false}
              isDisabled={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value === 1  || props.rowData.stockValuation.value !== 3)  ? true : props.rowData.mutualFundCompanyId === undefined ? true : false}
            />
          )
        }
      },
    ])

    //ULIP
    setColumnsS3([
      {
        title: 'Scheme Name', field: 'mutualFundCompanyId',
        width: 250,
        editComponent: (props) => {

          let ddValue = props.value;
          if (value === null) {
            ddValue = null;
          }
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.mutualFundCompany.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.mutualFundCompany;
                  }
                }
                else {
                  ddValue = props.rowData.mutualFundCompany;
                  //ListedCompanySelect(ddValue);
                }
              }
            }
          }
          return (
            <>
              <Select
                value={sellingCompanyList.length === 0 ? null : ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected)
                  ListedCompanySelect(selected, 0)
                }}
                options={sellingCompanyList}
                name="mutualFundCompanyId"
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select Company.."
                onKeyDown={e => handleKey(e)}
                onFocus={() => { setcall(1); brokerSelect() }}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              />{Mtooltip === true ? <span style={{ color: "red" }}>Please Select Broker</span>
                : brokerValue.value !== undefined && sellingCompanyList.length === 0 ? <span style={{ color: "red" }}>There are no companies under this broker!</span> : null}
            </>
          )
        },
      },
      {
        field: 'certificateFolioNo', title: 'Policy Number ', width: 70,
        editComponent: (props) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.unlistedFolio.label) {
                    ddValue = props.value;
                  }
                  else {
                    ddValue = props.rowData.unlistedFolio;
                  }
                }
                else {
                  ddValue = props.rowData.unlistedFolio;
                  //  CompanySelect(ddValue)
                }
              }
            }
          }
          return (
            <>
              <Select
                value={ddValue || null}
                onChange={(selected) => {
                  props.onChange(selected);
                  AvailableSellingQty(selected, props.rowData);
                }}
                // onFocus={() => setcall(1)}
                options={folioNoList}
                searchable={true}
                clearable={true}
                noResultsText={"No result"}
                placeholder="Select Number.."
                name="folio"
                onKeyDown={e => handleKey(e)}
                menuPosition="fixed"
                menuShouldBlockScroll="true"
                isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
              />
            </>
          )
        },
      },
      {
        title: 'Units', field: 'units', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberOnlySeperator(rowData.units)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.rowData.mutualFundId !== undefined) {
                  AvaliableSellQty(props.rowData)
                } else {
                  // setAvilableQty(props.rowData.availableQuantity)
                  AvailableSellingQtyForUpdate(props.rowData);
                }
              }
            }
          }
          return (
            <Tooltip title={`The Available Units With This Folio no.is:${avilableQty}`} arrow open={avilableQty >= 0 && props.rowData.mutualFundCompanyId !== undefined ? true : avilableQty === undefined ? false : false}>
              {/* <MTableEditField {...props} placeholder={'units'} disabled={enabledAdisabled} /> */}
              <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
                <Cleave
                  className="MuiInputBase-input MuiInput-input"
                  placeholder='Units'
                  autoComplete='off'
                  options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
                  value={props.value}
                  onChange={e => props.onChange(toNumber(e.target.rawValue))} />
              </div>
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'Receivable', field: 'receivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.receivable)}
          </span>
        ),
        editComponent:({...props})=>{
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Receivable'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        }
      },
      {
        title: 'TDS', field: 'tds', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.tds ?rowData.tds:0)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          return  <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='TDS'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
          // <MTableEditField {...props} />;
        }
      },
      {
        title: 'Maturity Proceeds received', field: 'actualReceivable', width: 130, type: 'numeric',
        render: (rowData) => (
          <span>
            {NumberSeperator(rowData.actualReceivable ? rowData.actualReceivable : 0)}
          </span>
        ),
        editComponent: ({ ...props }) => {
          let actualReceivable = 0;
          actualReceivable = Number(props.rowData.tds) === 0 ? Number(props.rowData.receivable) : Number(props.rowData.receivable) - Number(props.rowData.tds);
          props.value = actualReceivable.toFixed(2);
          props.rowData.actualReceivable = Number(actualReceivable.toFixed(2));
          return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Maturity Proceeds received'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            disabled
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
          // <MTableEditField {...props} />;
        },
      },
      {
        title: 'Stock Valuation Type', field: 'stockValuationId',
        editComponent: ({ ...props }) => {
          let ddValue = props.value;
          if (props.rowData.tableData !== undefined) {
            if (props.rowData.tableData.editing !== undefined) {
              if (props.rowData.tableData.editing === 'update') {
                if (props.value.label !== undefined) {
                  if (props.value.label !== props.rowData.stockValuation.label) {
                    ddValue = props.value;
                    console.log("Inner grid", ddValue);
                  }
                  else {
                    ddValue = props.rowData.stockValuation;
                    console.log("else grid", ddValue);
                  }
                }
                else {
                  ddValue = props.rowData.stockValuation;
                  console.log("outer grid", props.rowData.selection);
                  if (ddValue.value === 3) {
                    //setSelectionQty(JSON.parse(props.rowData.selection));
                    //getSelection(props, ddValue.value);
                  }
                }
              }
            }
          }
          return (
            <Select
              value={ddValue ? ddValue : [{ value: 1, label: "FIFO" }]}
              //  onFocus={() => SelectionSelect(props, ddValue)}
              onChange={(selected) => {
                props.onChange(selected)
                if (selected.label === "Selection") {
                  getSelection(props, selected.value);
                }
              }}

              options={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value !== 1 || props.rowData.stockValuation.value !== 2 || props.rowData.stockValuation.value !== 3) ? updatedStockVaualtion : stockValuationType}
              name="stockValuationId"
              onKeyDown={e => handleKey(e)}
              menuPosition="fixed"
              menuShouldBlockScroll="true"
              isDisabled={props.rowData.mutualFundId !== undefined && (props.rowData.stockValuation.value === 1  || props.rowData.stockValuation.value !== 3) ? true : props.rowData.mutualFundCompanyId === undefined ? true : false}
            // isDisabled={isNaN(props.rowData.totalCost) ? true : props.rowData.mutualFId != undefined ? true : false}
            // isDisabled={props.rowData.mutualFundCompanyId === undefined ? true : false}
            />
          )
        }
      },
    ])
  }, [sellingCompanyList, show, show1, show2, show3, avilableQty,call,Mtooltip,stockValuationType, value, folioNoList, data1, data2, data3])

  //shortcut key 
  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });
  // handle sip radio
  const handleSIPRadio = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
    setAllEntry1([]);
    setBrokerValue([]);
    setIserror(false);
    setErrorMessages([]);
    setNarration("");
    setdata1([]);
    setErrorMessagesQntity([])
    setMtooltip(false)
    setIsError(false)
    setSellingCompanyList([]);

  }

  // handle lumpsum radio
  const handleLumpsumRadio = () => {
    setShow1(false);
    setShow2(true);
    setShow3(false);
    setAllEntry2([]);
    setErrorMessages([]);
    setIserror(false);
    setBrokerValue([]);
    setNarration("");
    setdata2([]);
    setErrorMessagesQntity([])
    setMtooltip(false);
    setIsError(false);
    setSellingCompanyList([]);
  }

  //handle Ulip radio
  const handleUlipRadio = () => {
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setAllEntry3([]);
    setBrokerValue([]);
    setIserror(false);
    setErrorMessages([]);
    setNarration("");
    setdata3([]);
    setErrorMessagesQntity([])
    setMtooltip(false)
    setIsError(false)
    setSellingCompanyList([]);
    handleResetModalPopUp()
  }

  const columnsB1 = [
    {
      title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
          />
        )
      },
    },
    {
      title: 'Units', field: 'units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Units'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Amount Invested', field: 'investmentAmt', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Amount Invested'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      title: 'NAV / Purchase Price', field: 'nav', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.nav)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let calculatedNAV = 0;
        if (props.rowData.investmentAmt && props.rowData.units) {
          calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
          props.value = calculatedNAV;
          props.rowData.nav = toNumber(calculatedNAV);
        } else {
          props.value = 0;
          props.rowData.nav = 0;
        }
        return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='NAV / Purchase Price'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(toNumber(e.target.rawValue))} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={true} />;
      },
    },
    {
      title: 'SIP Date', field: 'sipDate', type: 'date',
      render: (rowData) =>
      (
        rowData.sipDate == null ? '-' : moment(rowData.sipDate).format('DD/MM/YYYY') 
      ),
      // editComponent: ({ ...props }) => {
      //   return <MTableEditField  {...props} />;
      // },
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <DatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              placeholder="Enter Date" 
              clearable
            // minDate={new Date(date)}
            // maxDate={Date.now()}
            />
            {/* <KeyboardDatePicker
              autoOk
              variant="inline"
              onKeyDown={e => handleKey(e)}
              format="dd/MM/yyyy"
              value={props.value}
              onChange={props.onChange}
              clearable
            /> */}
          </MuiPickersUtilsProvider>
        )
      }
    },
    {
      title: 'SIP Amount', field: 'sipAmt', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.sipAmt}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='SIP Amount'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      title: 'SIP Frequency', field: 'frequencyId', width: 130,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.frequency.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.frequency
                }
              }
              else {
                ddValue = props.rowData.frequency
              }
            }
          }
        }
        return (
          <Select
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={frequencyValue}
            name="sipFrequency"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
          />
        )
      },
    },
    {
      title: 'No. of Installments', field: 'numberOfInstalments', width: 70, type: 'numeric',
      render: (rowData) => (
        <span>
          {rowData.numberOfInstalments}
        </span>
      ),

      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='No. of Installments'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
    },
    {
      title: 'Maturity Date', field: 'maturityDate', type: 'date',
      render: (rowData) =>
      (

        rowData.maturityDate == null  ? '-' : moment(rowData.maturityDate).format('DD/MM/YYYY') 
      
      ), 
      editComponent: props => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <DatePicker
              format="dd/MM/yyyy"
              value={props.value || null}
              onChange={props.onChange}
              placeholder="Enter Date"
              // minDate={new Date(date)}
              clearable
            // maxDate={Date.now()}
            />

            {/* <KeyboardDatePicker
              autoOk
              variant="inline"
              onKeyDown={e => handleKey(e)}
              format="dd/MM/yyyy"
              value={props.value}
              onChange={props.onChange}
              clearable
            /> */}
          </MuiPickersUtilsProvider>
        )
      }
    },
    {
      title: 'Certificate / Folio No.', field: 'certificateFolioNo', width: 50,
      // editComponent:(props) =>{
      //   return <MTableEditField {...props} disabled={props.rowData.mutualFundId != undefined ? true : false} />;

      // }
    },
    { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
    { field: 'recommendedBy', title: 'Recommended by', width: 130 },
  ];

  const columnsB2 = [

    {
      title: 'Scheme', field: 'mutualFundCompanyId', width: 250,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
          />
        )
      },
    },
    {
      field: 'certificateFolioNo', title: 'Folio Number ', width: 70,
      editComponent: ({ ...props }) => {
        return <MTableEditField {...props} />;
      },
    },
    {
      field: 'units', title: 'Units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Units'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        //  <MTableEditField {...props} />;
      },
    },
    {
      field: 'investmentAmt', title: 'Amount Invested', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Amount Invested'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
    },

    {
      field: 'nav', title: 'NAV / Purchase Price', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.nav)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        let calculatedNAV = 0;
        if (props.rowData.investmentAmt && props.rowData.units) {
          calculatedNAV = (props.rowData.investmentAmt / props.rowData.units).toFixed(2);
          props.value = calculatedNAV;
          props.rowData.nav = toNumber(calculatedNAV);
        }
        else {
          props.value = 0;
          props.rowData.nav = 0;
        }
        return <div className={CleavTablFirstTxtField}><div className={cleavedisabledField}>
        <Cleave
          className="MuiInputBase-input MuiInput-input"
          placeholder='NAV / Purchase Price'
          autoComplete='off'
          options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
          value={props.value}
          disabled
          onChange={e => props.onChange(toNumber(e.target.rawValue))} />
      </div>
      </div>
        // <MTableEditField {...props} disabled={true} />;
      },
    },

    // { field: 'estHoldingPeriod', title: 'Estimated Holding period', width: 130 },
    { field: 'lockInPeriod', title: 'Lock in Period', width: 130 },
  ];

  const columnsB3 = [
    {
      title: 'Scheme', field: 'mutualFundCompanyId', width: 200,
      editComponent: (props) => {
        let ddValue = props.value;
        if (props.rowData.tableData !== undefined) {
          if (props.rowData.tableData.editing !== undefined) {
            if (props.rowData.tableData.editing === 'update') {
              if (props.value.label !== undefined) {
                if (props.value.label !== props.rowData.mutualFundCompany.label) {
                  ddValue = props.value
                }
                else {
                  ddValue = props.rowData.mutualFundCompany
                }
              }
              else {
                ddValue = props.rowData.mutualFundCompany
              }
            }
          }
        }
        return (
          <Select
            components={{ Control }}
            value={ddValue || null}
            onChange={(selected) => props.onChange(selected)}
            options={companyList}
            name="companyId"
            onKeyDown={e => handleKey(e)}
            menuPosition="fixed"
            menuShouldBlockScroll="true"
            isDisabled={props.rowData.mutualFundId !== undefined ? true : false}
          />
        )
      },
    },
    {
      title: 'Insurance Company', field: 'insuranceCompany', width: 190, type: 'text',
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <MTableEditField {...props} />;
      },
    },
    {
      title: 'Insured Name', field: 'nameOfInsured', width: 130, type: 'text',
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <MTableEditField {...props} />;
      },
    },

    { title: 'Policy Number', field: 'policyNumber', width: 50 },

    {
      title: 'Units', field: 'units', width: 130, type: 'numeric',
      render: (rowData) => (
        <span>
          {NumberOnlySeperator(rowData.units)}
        </span>
      ),
      editComponent: ({ ...props }) => {
        // handleAddRowBuy();
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Units'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField {...props} />;
      },
    },
    {
      title: 'Sum Assured', field: 'sumAssured', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Sum Assured'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.sumAssured)}
        </span>
      ),
    },
    {
      title: 'Premium Amount', field: 'investmentAmt', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Premium Amount'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        // <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.investmentAmt)}
        </span>
      ),
    },
    {
      title: 'Current Market Value', field: 'currentMarketValue', width: 130, type: 'numeric',
      editComponent: ({ ...props }) => {
        let value = props.rowData.currentMarketValue ? props.rowData.currentMarketValue : 0
        props.rowData.currentMarketValue = value;
        return <div className={CleavTablFirstTxtField}><div className={CleavTablSecondTxtField}>
          <Cleave
            className="MuiInputBase-input MuiInput-input"
            placeholder='Current Market Value'
            autoComplete='off'
            options={{ numeral: true, numeralDecimalScale: 2, numeralThousandsGroupStyle: 'lakh', numeralPositiveOnly: true }}
            value={props.value}
            onChange={e => props.onChange(toNumber(e.target.rawValue))} />
        </div>
        </div>
        //  <MTableEditField  {...props} />;
      },
      render: (rowData) => (
        <span>
          {NumberSeperator(rowData.currentMarketValue)}
        </span>
      ),
    },


    {
      title: "Addtional Data",
      field: "internal_action",
      // editable: false,
      editComponent: ({ ...props }) => {
        let t = props.rowData
        return <span onClick={() => {
          if (t.tableData !== undefined) {
            if (t.dateOfLastPremium !== undefined && t.dateOfLastPremium !== "") {
              setLastPremiumDate(t.dateOfLastPremium ? new Date(t.dateOfLastPremium) : null);
            }
            if (t.maturityDate !== undefined && t.maturityDate !== "") {
              setMaturityDateUlip(t.maturityDate ? new Date(t.maturityDate) : null);
            }
            if (t.bonusAccrued !== undefined) {
              setUlipBonus(t.bonusAccrued)
            }
            if (t.goal !== undefined) {
              setUlipGoal(t.goal);
            }
            if (t.nominees !== undefined) {
              setNominees(t.nominees);
            }
            if (t.maturityValue !== undefined) {
              setUlipMaturityValue(t.maturityValue);
            }
            if (t.lockInPeriod !== undefined) {
              let d = t.lockInPeriod
              let splitData = d.split(/([0-9]+)/);
              setLockInValue(splitData[2])
              setLockIn({ label: splitData[2], value: splitData[2] })
              setLockinperiod(splitData[1])
            }
            if (t.tenure !== undefined) {
              let d = t.tenure
              let splitData = d.split(/([0-9]+)/);
              setTimeValue(splitData[2])
              setTime({ label: splitData[2], value: splitData[2] })
              setTenurePeriod(splitData[1])
            }
          } else {
            handleResetModalPopUp()
          }
          setShowUlipAdd(true);
        }}
          title='Add More'><button style={{ backgroundColor: "transparent", border: "none" }}><i class="bi bi-plus ml-4 h4 text-secondary" ></i></button></span>
      }
    }

  ]

  return (
    <div tabIndex={2}>
      <Modal isOpen={showMutualFund} className="custom-dialog addStock"
        size="lg"
        id="modal2"
        toggle={handleCloseMFModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <ModalHeader charCode="Y" toggle={() => handleCloseMFModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
          <h5 className="text-center mb-0">Mutual Fund Transaction</h5>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
          ) : null}
          <fieldset>
            <Row>
              <Col md="12" className="mb-2">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Listed' checked={show1}
                    onClick={handleSIPRadio} disabled={mutualFHeaderId !== 0}
                  />
                  <Form.Label className="form-check-label" htmlFor="inlineRadio1">SIP</Form.Label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='Lumpsum' checked={show2}
                    onClick={handleLumpsumRadio} disabled={mutualFHeaderId !== 0} />
                  <Form.Label className="form-check-label" htmlFor="inlineRadio2">Lumpsum</Form.Label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value='ULIP' checked={show3}
                    onClick={handleUlipRadio} disabled={mutualFHeaderId !== 0} />
                  <Form.Label className="form-check-label" htmlFor="inlineRadio3">ULIP</Form.Label>
                </div>
              </Col>
            </Row>
            <Row>
              {
                show1 || show2 || show3 ?
                  <Col md="3" className="mb-2">
                    <><Form.Label>{show3 ? "Date of premium" : "Date"}<span className="text-danger h6">*</span></Form.Label> :
                      <DatePickerR
                        className="form-control"
                        selected={date}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => setDate(date)}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        disabled={mutualFHeaderId !== 0 ? true : false}
                      />
                    </>
                  </Col>
                  : null
              }
              {
                <Col md="3" className="mb-2">
                  <>
                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label> :
                    <Dropdown
                      value={brokerValue}
                      name='Broker'
                      options={brokerOptions}
                      onChange={HandleChange}
                      className="SelectClass"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                      // onInputChange={HandleInput}
                      placeholder="Select broker.."
                      isDisabled={mutualFHeaderId !== 0 ? true : false}
                    // isDisabled={headerId != 0 ? true : show2 || show4 ? true : false}
                    />
                  </>
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.broker}</div> : null}
                </Col>
              }
            </Row>

          </fieldset>
          <fieldset>
            {show1 === true ?
              <Row>
                <Col md="12" className="BuyTbl">
                  <MaterialTable
                    title="Buy"
                    data={allEntry1}
                    columns={columnsB1}
                    tableRef={tableRef1}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false,
                      tableLayout: 'auto'
                    }}

                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdate(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAdd(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDelete(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
                {/* <div> */}
                {iserror &&
                  <Alert severity="error">
                    <AlertTitle>ERROR</AlertTitle>
                    {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                    })}
                  </Alert>
                }
                {/* </div> */}
              </Row>
              : null
            }
            {show1 === true ?
              <Row>
                <Col md="12" className="BuyTbl mt-3">
                  <MaterialTable
                    title="Sell"
                    data={data1}
                    columns={columnsS1}
                    tableRef={tableRefSell1}
                    editable={{
                      // isEditable: rowData => false,
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdateSell(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAddSell(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDeleteSell(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setErrorMessagesSell([])
                        setIsError(false);
                      },
                      onRowUpdateCancelled: rowData => {
                        setErrorMessagesSell([])
                        setIsError(false);
                      }
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false
                    }}

                  />
                </Col>
                <div>
                  {isError &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessagesSell.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </Row>
              : null}

            {/* lumpsum........ */}
            {show2 === true ?
              <Row>
                <Col md="12" className="BuyTbl">
                  <MaterialTable
                    title="Buy"
                    data={allEntry2}
                    columns={columnsB2}
                    tableRef={tableRef2}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false,
                      tableLayout: 'auto'
                    }}

                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdate(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAdd(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDelete(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
                <div>
                  {iserror &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessages.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </Row>
              : null
            }
            {show2 === true ?
              <Row>
                <Col md="12" className="BuyTbl mt-3">
                  <MaterialTable
                    title="Sell"
                    data={data2}
                    columns={columnsS2}
                    tableRef={tableRefSell2}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false,
                      tableLayout: 'auto'
                    }}

                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdateSell(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAddSell(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDeleteSell(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
                <div>
                  {isError &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessagesSell.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </Row>
              : null
            }
            {show3 === true ?
              <Row>
                <Col md="12" className="BuyTbl">
                  <MaterialTable
                    title="ULIP Buy"
                    data={allEntry3}
                    columns={columnsB3}
                    tableRef={tableRef3}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false,
                      tableLayout: 'auto'
                    }}

                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdate(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAdd(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDelete(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
                <div>
                  {iserror &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessages.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </Row>
              : null}

            {show3 === true ?
              <Row>
                <Col md="12" className="BuyTbl mt-3">
                  <MaterialTable
                    title="ULIP Sell"
                    data={data3}
                    columns={columnsS3}
                    tableRef={tableRefSell3}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      paging: false,
                      tableLayout: 'auto'
                    }}

                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowUpdateSell(newData, oldData, resolve, reject);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          handleRowAddSell(newData, resolve, reject)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          handleRowDeleteSell(oldData, resolve, reject)
                        }),
                      onRowAddCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      },
                      onRowUpdateCancelled: rowData => {
                        setIserror(false)
                        setErrorMessages([])
                      }
                    }}
                  />
                </Col>
                <div>
                  {isError &&
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      {errorMessagesSell.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                      })}
                    </Alert>
                  }
                </div>
              </Row>

              : null}
          </fieldset>
          {/* <fieldset>
            <Col md="3">
              <Form.Label>Narration</Form.Label>
              <Form.Control as="textarea" autoComplete="off"
                name="narration" id="narration" value={narration} onChange={(e) => setNarration(e.target.value)} />
            </Col>
          </fieldset> */}
          <Col md="12" className="text-center mt-3">
            {mutualFHeaderId !== 0 ?
              <>
                <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { UpdateMFtransaction() }}>Update</Button>
                <Button variant="danger" className="mx-2 w100" onClick={() => { DeleteMFtransaction() }}>Delete</Button>
              </> :
              <>
                <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { AddMFtransaction() }}>Save</Button>
                <Button variant="secondary" className="mx-2 w100" onClick={() => { handleCancel() }}>Cancel</Button>
              </>
            }
          </Col>

        </ModalBody>
      </Modal>

      {/* selection modal */}
      <div tabIndex={2}>
        <Modal className="custom-dialog selection" isOpen={selection}
          id="modal1"
          size="lg"
          backdrop="static"
          //  backdrop={false}
          toggle={selectionHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <ModalHeader charCode="Y" toggle={() => selectionHide()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
            <h5 className="text-center">{show1 ? "Select Available SIP Mutual Fund": show2 ?"Select Available Lumpsum Mutual Fund" : show3 ? "Select Available ULIP Mutual Fund": null}</h5>
          </ModalHeader>
          <ModalBody style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
          }}>
            <div style={{ height: 200, width: '100%' }}>
              <MaterialTable
                title={false}
                //  title="Cell Editable Preview"
                columns={[
                  { title: "mutualFundId", field: "MutualFundId", hidden: true },
                  {
                    title: 'Date', field: 'DateOfInvestment', type: 'date', editable: 'never',
                    render: (rowData) =>
                    (
                      moment(rowData.DateOfInvestment).format('DD/MM/YYYY')
                    )
                  },
                  { title: 'Units', field: 'AvailableUnit', type: 'numeric', editable: 'never' },
                  { title: 'Nav', field: 'Nav', type: 'numeric', editable: 'never' },
                  {
                    title: 'Sell Units', field: 'sellQuantity', type: 'numeric', width: 200, maxwidth: 200, editComponent: ({ ...props }) => {
                      return <MTableEditField {...props} />;
                    },
                  },
                ]}
                data={selectionQty}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: "first",
                  search: false,
                  paging: false,
                  selection: true,
                  showTextRowsSelected: false,
                }}
                cellEditable={{
                  onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                      let errorsList = [];
                      let updateSelection = [...selectionQty];
                      const index = rowData.tableData.id;
                      if (rowData.availableUnit < newValue) {
                        errorsList.push("Units exceed then avilable Units !");
                      }
                      if (errorsList.length < 1) {
                        updateSelection[index].sellQuantity = newValue;
                        setSelectionQty([...updateSelection])
                        setErrorMessagesQntity([])
                        setTimeout(resolve, 1000);
                      }
                      else {
                        setErrorMessagesQntity(errorsList);
                        setTimeout(reject, 1000);
                      }
                    });
                  }
                }}
              />
              <div>
                {errorMessagesQntity.length > 0 &&
                  <Alert severity="error">
                    <AlertTitle>ERROR</AlertTitle>
                    {errorMessagesQntity.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                    })}
                  </Alert>
                }
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      {/* additional infomative Ulip */}
      <div tabIndex={3}>
        <Modal isOpen={showUlipAdd} toggle={handlecloseDescModal} animation={true} size="lg" backdrop="static" >
          <ModalHeader charCode="Y" toggle={() => handlecloseDescModal()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
            <h5 className='text-center'>ULIP Addtional Data</h5>
          </ModalHeader>
          <ModalBody>
            <form >
              <Row>
                <Col md="4" className="mb-2">
                  <><Form.Label>Date of Last Premium</Form.Label>
                    <DatePickerR
                      className="form-control"
                      selected={lastPremiumDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => setLastPremiumDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      placeholderText='Enter last permium date'
                      minDate={date}
                    // disabled={mutualFHeaderId != 0 ? true : false}
                    />
                  </>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Label>Nominees</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleAddtiUlipData}
                    autoComplete="off"
                    name="nominees"
                    id="nominees"
                    value={ulipNominees}
                    placeholder="Enter nominees"
                  />
                </Col>

                <Col md="4" className="mb-2">
                  <Form.Label>Bonus Accrued</Form.Label>
                  {/* <Form.Control type="text"
                    onChange={handleAddtiUlipData} autoComplete="off"
                    name="bonus"
                    id="bonus"
                    value={ulipBonus}
                    placeholder="Enter bonus accured"
                  /> */}
                  <Cleave
                    placeholder="Enter bonus accured"
                    className='form-control'
                    name="bonus"
                    id="bonus"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={ulipBonus}
                    onChange={handleAddtiUlipData}
                    autoComplete="off"
                  />
                </Col>

              </Row>
              <Row>
                <Col md="4" className="mb-2">
                  <Form.Label>Premium Frequency</Form.Label>
                  <Dropdown
                    value={divFreqValue}
                    name='dividendFrequency'
                    options={frequencyValue}
                    onChange={handleChangeFrequency}
                    className="SelectClass"
                    searchable={true}
                    clearable={true}
                    noResultsText={"No result"}
                  />
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Label>Goal</Form.Label>
                  {/* <Form.Control
                    type="text"
                    onChange={handleAddtiUlipData} autoComplete="off"
                    name="goal"
                    id="goal"
                    value={ulipGoal}
                    placeholder="Enter goal"

                  /> */}
                  <Cleave
                    placeholder="Enter goal"
                    className='form-control'
                    name="goal"
                    id="goal"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={ulipGoal}
                    onChange={handleAddtiUlipData}
                    autoComplete="off"
                  />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.goal}</div> : null}
                </Col>


                <Col md="4" className="mb-2">
                  <>
                    <Form.Label className="d-block">Tenure</Form.Label>
                    <Form.Control type="number"
                      onChange={(e) => setTenurePeriod(e.target.value)}
                      autoComplete="off"
                      value={tenurePeriod}
                      name="tenure" id="tenure" className="lockinperiodno" />
                    <Dropdown
                      value={time}
                      name='tenure'
                      options={periodList}
                      onChange={HandleChangePeriod}
                      className="SelectClass lockinperiodlist"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                    />
                  </>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-2">
                  <><Form.Label>Maturity Date</Form.Label>
                    <DatePickerR
                      className="form-control"
                      selected={maturityDateUlip}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => setMaturityDateUlip(date)}
                      showMonthDropdown
                      minDate={date}
                      name='maturityDate'
                      placeholderText='Enter maturity date'
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                    // disabled={mutualFHeaderId !== 0 ? true : false}
                    />
                  </>
                </Col>
                <Col md="4" className="mb-2">
                  <Form.Label>Maturity Value</Form.Label>
                  {/* <Form.Control
                    type="text"
                    onChange={handleAddtiUlipData} autoComplete="off"
                    name="maturityValue"
                    id="maturityValue"
                    value={ulipMaturityValue}
                    placeholder="Enter maturity value"
                  /> */}
                  <Cleave
                    placeholder="Enter maturity value"
                    className='form-control'
                    name="maturityValue"
                    id="maturityValue"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                    value={ulipMaturityValue}
                    onChange={handleAddtiUlipData}
                    autoComplete="off"
                  />
                  {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.maturityVal}</div> : null}

                </Col>
                <Col md="4" className="mb-2">
                  <>
                    <Form.Label className="d-block">Lock in Period</Form.Label>
                    <Form.Control type="number"
                      onChange={(e) => setLockinperiod(e.target.value)}
                      autoComplete="off"
                      name="lockinperiod"
                      id="lockinperiod"
                      value={lockinperiod}
                      className="lockinperiodno" />
                    <Dropdown
                      value={lockIn}
                      name='period'
                      options={periodList}
                      onChange={HandleChangeLockIn}
                      className="SelectClass lockinperiodlist"
                      searchable={true}
                      clearable={true}
                      noResultsText={"No result"}
                    />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.lockinperiod}</div> : null}
                  </>
                </Col>

              </Row>
            </form>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100"
              onClick={submitPreModal}
            >
              Save
            </Button><Button variant="secondary" className="mx-2 w100"
              onClick={handleResetModalPopUp}
            >
              Clear
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {/* modal for add new scheme */}
      <Container tabIndex={3}>
        <Modal isOpen={show} toggle={handleClose} animation={false} size="sm" centered backdrop="static">
          <ModalHeader charCode="Y" toggle={() => handleClose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
            <h5>{show3 ? "ULIP Scheme" : "Mutual Fund Scheme"}</h5>
          </ModalHeader>
          <ModalBody>
            {loading ? (
              <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
            ) : null}
            <form >
              <div className="form-group mt-2 mb-2">
                <label for="inputUserName">Scheme Name</label>
                <input className="form-control mt-2" placeholder="Enter text" type="text" autoComplete="off" name="schemeName" onChange={handleScheme} value={scheme} />
                {schemeErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{schemeErr}</div> : null}
              </div>
            </form>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2 w100" onClick={submitMFaddCompany} >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  )
}

export default MutualFundDetail