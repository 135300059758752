/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Loader from "../../components/loadingAnimation/index";
import Insurances from '../InsuranceFolio/other_transactions/Insurances'

function InsuranceDetail(props) {
    const { showInsurance, onHideInsurance, insuranceId, insuranceTransaction, entityId, onInsTransactionGridRefresh } = props;
    const [show1, setShow1] = useState(true);


    const [iserror, setIserror] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [formErr, setFormErr] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleCloseDetails = () => {
        onHideInsurance();
        onInsTransactionGridRefresh()
    }

    return (
        <>
            <div tabIndex={1}>
                <Modal className="custom-dialog addStock insurnacedetails"
                    isOpen={showInsurance}
                    toggle={() => handleCloseDetails()}
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <ModalHeader charCode="Y" toggle={() => handleCloseDetails()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Insurance Transaction</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Container fluid>
                            <form>
                                {loading ? (
                                    <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                                ) : null}
                                <fieldset>
                                    <Row>
                                        <Col md="12" className="mb-2">
                                            {/* insurance */}
                                            <div className="form-check form-check-inline">
                                                {/* {InsuranceHeaderId != 0 ? 
                                                <>
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Insurance' checked={show1}
                                                        // onClick={() => { setShow1(true); setShow2(false); setShow3(false); setShow4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); }}
                                                        disabled
                                                    />
                                                    <Form.Label className="form-check-label" htmlFor="inlineRadio1">Insurance</Form.Label>
                                                </> 
                                                :  */}
                                                <>
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='Insurance' checked={show1}
                                                        onClick={() => { setShow1(true); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); }}

                                                    />
                                                    <Form.Label className="form-check-label" htmlFor="inlineRadio1">Insurance</Form.Label>

                                                </>
                                                {/* } */}
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {/* ULIP */}
                                                {/* {InsuranceHeaderId != 0 ? 
                                                <>
                                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='ULIP' checked={show2}
                                                        // onClick={() => { setShow1(false); setShow2(true); setShow3(false); setShow4(false); setFormErr({}); setIserror(false); setIsError(false); setErrorMessages([]); }}
                                                        disabled
                                                    />
                                                    <Form.Label className="form-check-label" htmlFor="inlineRadio2">ULIP</Form.Label>
                                                </> 
                                                :  */}
                                                {/* } */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* Insurance */}
                                        {show1 === true ?
                                            <Insurances show1={show1} entityId={entityId} insuranceTransaction={insuranceTransaction} insuranceId={insuranceId} onInsuranceRefresh={onInsTransactionGridRefresh} /> : null}



                                    </Row>
                                </fieldset>
                            </form>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )

}

export default InsuranceDetail
