/* eslint-disable array-callback-return */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Row, Col, Container, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from "react-select";
import swal from "sweetalert";
import Loader from "../loadingAnimation/index";
import { periodList } from '../../modules/helpers/constant'
import Dropdown from '../CommonComponent/Dropdown';
import { GetDividendFrequency } from '../../pages/Stocks/StocksAPI';
import { securitytype } from '../../modules/helpers/constant'
import { AddBorrowings, updateBorrowings } from '../../pages/Borrowings/BorrowingsAPI';
import BorrowingsInstallment from './BorrowingsInstallment'
import BorrowingsIntrest from './BorrowingsIntrest'
import BorrowingsRepay from './BorrowingsRepay';
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import Cleave from 'cleave.js/react';

function BorrowingDetils(props) {
    const { showBorrowings, onHideBorrowings, onRefreshInnerGrid, BorrowingsHeaderId, entityId, BorrowingsTransaction, onRefreshForm } = props;

    const [selSecurity, setSelSecurity] = useState([]);
    const [security, setSecurity] = useState(securitytype);

    const [securityDet, setSecurityDet] = useState({ securitydet: "" });
    const [folioNumber, setFolioNumber] = useState({ folioNumber: null });
    const [Lender, setLender] = useState({ Lender: null });
    const [sanamount, setSanAmount] = useState({ sanamount: null });
    const [disamount, setDisAmount] = useState({ disamount: null });
    const [disDate, setDisDate] = useState(null);
    const [openingBal, setOpeningBal] = useState({ openingBal: "" });
    const [openingDate, setOpeningDate] = useState(null);

    const [interestRate, setInterestRate] = useState({ interestRate: null });
    const [monthEMI, setMonthEMI] = useState({ monthEMI: null })
    const [EMINumber, setEMINumber] = useState([]);
    const [LastEMIDate, setLastEMIDate] = useState(null)
    const [narration, setNarration] = useState({ narration: "" });
    const [subAccountId, setSubAccountId] = useState(0)

    const [selEMIFrequency, setSelEMIFrequency] = useState([]);
    const [EMIFrequency, setEMIFrequency] = useState([]);
    const [EMIFrequencyValue, setEMIFrequencyValue] = useState([])

    const [tenurePeriod, setTenurePeriod] = useState("");
    const [tenure, setTenure] = useState([{ label: "Month", value: "Month" }]);
    const [tenureValue, setTenureValue] = useState("Month");

    const [outstandingAmt, setOutstandingAmt] = useState({ outstandingAmt: '' })
    const [securityDetailsId, setsecurityDetailsId] = useState(null)
    const [loading, setLoading] = useState(false);
    const [formErr, setFormErr] = useState([]);
    const [showRepay, setShowRepay] = useState(false);
    const [showBorroInte, setShowBorroInte] = useState(false);
    const [showInstallment, setShowInstallment] = useState(false);

    

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} >
            {`The sanctioned amount is ${sanamount.sanamount}`}
        </Tooltip>
    );

    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
       });

    useEffect(() => {
        getDividedFrequencyList();
    }, [])

    useEffect(() => {
        if (BorrowingsHeaderId !== 0 && Number(openingBal.openingBal) === BorrowingsTransaction.openingBalance) {
            setOutstandingAmt({ outstandingAmt: BorrowingsTransaction.outstandingAmt })
        }
    }, [BorrowingsTransaction, outstandingAmt.outstandingAmt])

    // useEffect(() => {
    //     if (showBorrowings === true) {
    //         handleAmounts()
    //     }
    // }, [disamount.disamount, monthEMI.monthEMI, outstandingAmt.outstandingAmt, openingBal.openingBal])

    // const handleAmounts = () => {
    //     if (disamount.disamount || monthEMI.monthEMI) {
    //         let NoOfEMI = (disamount.disamount ? Number(disamount.disamount) : 0) / (monthEMI.monthEMI ? Number(monthEMI.monthEMI) : 0)
    //         setEMINumber(Math.round(NoOfEMI))
    //     }
    //     // if(BorrowingsHeaderId != 0 && Number(openingBal.openingBal) !== BorrowingsTransaction.openingBalance){
    //     //     let Outstanding = (disamount.disamount ? Number(disamount.disamount) : 0) + (openingBal.openingBal ? Number(openingBal.openingBal) : 0)
    //     //     setOutstandingAmt({outstandingAmt:Outstanding})
    //     // }
    // }
    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    //hanlesecurity
    const handleSecurity = (selected) => {
        setSelSecurity(selected);
        setFormErr({ ...formErr, selSecurity: "" })
    }
    //handlesecuritydetails
    const handleSecurityDet = (e) => {
        const { name, value } = e.target;
        setSecurityDet({ ...securityDet, [name]: value })
        setFormErr({ ...formErr, securitydet: "" })
    }
    //handlefolionumber
    const handleFolioNo = (e) => {
        const { name, value } = e.target;
        setFolioNumber({ ...folioNumber, [name]: value });
        setFormErr({ ...formErr, folioNumber: "" })

    }
    //handlelender
    const handleLender = (e) => {
        const { name, value } = e.target;
        setLender({ ...Lender, [name]: value })
        setFormErr({ ...formErr, Lender: "" })
    }
    //handlesanamount
    const handleSanAmount = (e) => {
        const { name, rawValue } = e.target;
        setSanAmount({ ...sanamount, [name]: rawValue })
        setFormErr({ ...formErr, sanamount: "" })
    }
    //handledisamount
    const handleDisAmount = (e) => {
        const { name, rawValue } = e.target;
        setDisAmount({ ...disamount, [name]: rawValue })
        setOpeningBal({ ...openingBal, ["openingBal"]: rawValue })
        setFormErr({ ...formErr, disamount: "" })
    }
    //handledisdate
    const handledisDate = (selected) => {
        setDisDate(selected);
        setFormErr({ ...formErr, disDate: "" })
    }
    //handleopeningbalance
    const handleOpeningBal = (e) => {
        const { name, rawValue } = e.target;
        setOpeningBal({ ...openingBal, [name]: rawValue })
        setFormErr({ ...formErr, openingBal: "" })
    }
    //handleopeningDate
    const handleopeningDate = (selected) => {
        setOpeningDate(selected);
        setFormErr({ ...formErr, openingDate: "" })
    }

    //handleInterestRate
    const handleInterestRate = (e) => {
        const { name, value } = e.target;
        setInterestRate({ ...interestRate, [name]: value > 100 ? 100 : value < 0 ? 0 : value });
        setFormErr({ ...formErr, interestRate: "" })
    }
    //handle lock in period
    const HandleChangePeriod = (e) => {
        setTenure(e);
        setTenureValue(e.value)
    }
    //handleemimonthly
    const handleMonthEMI = (e) => {
        const { name, rawValue } = e.target;
        setMonthEMI({ ...monthEMI, [name]: rawValue });
        setOpeningBal({ ...openingBal, ["openingBal"]: disamount.disamount })
        setFormErr({ ...formErr, monthEMI: "" })
    }
    //handlenumberofemi
    const handleEMINo = (e) => {
        setEMINumber(e.target.value);
    }
    //handleEMIfrequency
    const handleEMIFrequency = (selected) => {
        setSelEMIFrequency(selected);
        setEMIFrequencyValue(selected.value);
        setFormErr({ ...formErr, selEMIFrequency: "" })
    }
    //handlelastemi
    const handleLastEMIDate = (selected) => {
        setLastEMIDate(selected)
        setFormErr({ ...formErr, LastEMIDate: "" })
    }
    //handlenarration
    const handleNarration = (e) => {
        const { name, value } = e.target;
        setNarration({ ...narration, [name]: value })
    }

    const errHandler = () => {
        let formErr = {};
        let isValid = true;

        if (selSecurity.length < 1) {
            isValid = false;
            formErr["selSecurity"] = "Please Select Security";
        }

        if (!securityDet.securitydet) {
            isValid = false;
            formErr["securitydet"] = "Please Enter Security Details"
        }

        if (!folioNumber.folioNumber) {
            isValid = false;
            formErr["folioNumber"] = "Please Enter Folio Number";
        }
        if (!Lender.Lender) {
            isValid = false;
            formErr["Lender"] = "Please Enter Lender";
        }
        if (!sanamount.sanamount) {
            isValid = false;
            formErr["sanamount"] = "Please Enter Sanamount";
        }
        if (!disamount.disamount) {
            isValid = false;
            formErr["disamount"] = "Please Enter Disamount";
        }
        if (disamount.disamount) {
            let a = Number(disamount.disamount);
            let b = Number(sanamount.sanamount);
            if (a > b) {
                isValid = false;
                formErr["disamount"] = "Please Enter Valid Amount"
            }
        }
        if (!disDate) {
            isValid = false;
            formErr["disDate"] = "Please Select Date";
        }
        if (!openingBal.openingBal && selSecurity.label !== "Unsecured Loan Personal") {
            isValid = false;
            formErr["openingBal"] = "Please Enter Opening Balance"
        }
        if (!openingDate && selSecurity.label !== "Unsecured Loan Personal") {
            isValid = false;
            formErr["openingDate"] = "Please Select Opening Date"
        }
        // if (!interestRate.interestRate) {
        //     isValid = false;
        //     formErr["interestRate"] = "Please Enter Interest Rate"
        // }
        if (!monthEMI.monthEMI) {
            isValid = false;
            formErr["monthEMI"] = "Please Enter Monthly EMI"
        }

        if (!LastEMIDate) {
            isValid = false;
            formErr["LastEMIDate"] = "Please Select Date Of Last EMI"
        }

        if (selEMIFrequency.length < 1) {
            isValid = false;
            formErr["selEMIFrequency"] = "Please Select Frequency Of EMI"
        }

        setFormErr(formErr);
        return isValid;
    }


    //getdividend list
    const getDividedFrequencyList = async () => {
        setLoading(true)
        await GetDividendFrequency()
            .then((res) => {

                if (res.status === 200) {
                    let dividend = res.data._response.data.dividendfrequencyDetails;
                    let dividendData = [];
                    dividend &&
                        dividend.map((data) => {
                            dividendData.push({
                                value: data.frequencyId,
                                label: data.frequencyName,
                            });
                        });
                    setEMIFrequency(dividendData);
                    setLoading(false)
                } else {
                    // setError(res.data.message);
                    setLoading(false)

                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)
            });
    };

    const handleCloseDetails = () => {
        onHideBorrowings();
        handleReset();
        onRefreshInnerGrid()
    }
    // interest open modal
    const handleInterest = () => {
        setShowBorroInte(!showBorroInte)
    }
    const handleInstallment = () => {
        setShowInstallment(!showInstallment)
    }
    const handleShowRepay = () => {
        setShowRepay(true)
    }
    const handleHideRepay = () => {
        onRefreshForm(BorrowingsHeaderId);
        setShowRepay(false)
    }

    const handleHideInterest = () => {
        onRefreshForm(BorrowingsHeaderId);
        setShowBorroInte(false);
    }
    const handleHideInstall = () => {
        onRefreshForm(BorrowingsHeaderId);
        setShowInstallment(false);
    }
    const disableButton = () => {
        return selSecurity && folioNumber && Lender && sanamount && disamount
            && disDate && monthEMI
    }
    const handleReset = () => {
        setSelSecurity([]);
        setSecurityDet({ securitydet: "" });
        setFolioNumber({ folioNumber: "" });
        setLender({ Lender: "" });
        setSanAmount({ sanamount: "" });
        setDisAmount({ disamount: "" });
        setDisDate(null);
        setOpeningBal({ openingBal: "" });
        setOpeningDate(null);
        setInterestRate({ interestRate: "" });
        setTenurePeriod("");
        setMonthEMI({ monthEMI: "" });
        setEMINumber([]);
        setSelEMIFrequency([])
        setLastEMIDate(null)
        setNarration({ narration: "" })
        setFormErr([]);
        setOutstandingAmt({ outstandingAmt: "" })

    }
    const handleSubmit = () => {
        const isValid = errHandler();
        if (isValid === true) {
            setLoading(true)
            const borrowingsData = {
                subAccountId: entityId,
                securityId: 0,
                instrumentTypeId: selSecurity.value,
                transactStockType: "Buy",
                securityName: selSecurity.label,
                securityDetailName: securityDet.securitydet,
                folioNumber: folioNumber.folioNumber,
                lender: Lender.Lender,
                sanctionedAmt: Number(sanamount.sanamount),
                disbursedAmt: Number(disamount.disamount),
                dateOfDisbursement: fixTimezoneOffset(disDate),
                openingBalance: Number(openingBal.openingBal),
                dateOfOpeningBalance: fixTimezoneOffset(openingDate),
                interestPerc: Number(interestRate.interestRate),
                tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
                monthlyEmi: Number(monthEMI.monthEMI),
                numberOfEmi: Number(EMINumber),
                frequencyId: selEMIFrequency.value,
                dateOfLastEmi: fixTimezoneOffset(LastEMIDate),
                narration: narration.narration
            }
            AddBorrowings(borrowingsData).then((res) => {

                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        swal("Success!", "Added Successfully", "success");
                        handleReset();
                    } else {
                        swal(res.data._response.message, "", "error");
                    }
                } else {
                    setLoading(false);
                    console.log("error")
                }
            })
                .catch((err) => {
                    console.log(err);
                    setLoading(false)
                })

        }

    }

    const handleUpdate = () => {
        const isValid = errHandler();
        if (isValid === true) {
            setLoading(true)
            const borrowingsData = {
                borrowingId: BorrowingsHeaderId,
                subAccountId: subAccountId,
                securityId: 0,
                instrumentTypeId: selSecurity.value,
                transactStockType: "Buy",
                securityName: selSecurity.label,
                securityDetailId: securityDetailsId,
                securityDetailName: securityDet.securitydet,
                folioNumber: folioNumber.folioNumber,
                lender: Lender.Lender,
                sanctionedAmt: Number(sanamount.sanamount),
                disbursedAmt: Number(disamount.disamount),
                dateOfDisbursement: fixTimezoneOffset(disDate),
                openingBalance: Number(openingBal.openingBal),
                dateOfOpeningBalance: fixTimezoneOffset(openingDate),
                interestPerc: Number(interestRate.interestRate),
                tenure: tenurePeriod === "" ? "0" + tenureValue : tenurePeriod + tenureValue,
                monthlyEmi: Number(monthEMI.monthEMI),
                numberOfEmi: Number(EMINumber),
                frequencyId: selEMIFrequency.value,
                dateOfLastEmi: fixTimezoneOffset(LastEMIDate),
                narration: narration.narration,
                outstandingAmt: outstandingAmt.outstandingAmt

            }
            updateBorrowings(borrowingsData).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        onRefreshForm(BorrowingsHeaderId);
                        swal("Success!", "Updated Successfully", "success");
                    }
                } else {
                    setLoading(false);
                    console.log("error");
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
    }

    //get borrowings data
    useEffect(() => {
        if (BorrowingsHeaderId !== 0) {
            if (BorrowingsTransaction !== undefined) {
                setSelSecurity({ label: BorrowingsTransaction.securityName, value: BorrowingsTransaction.instrumentType.instrumentTypeId });
                setNarration({ narration: BorrowingsTransaction.narration })
                setSubAccountId(BorrowingsTransaction.subAccountId)
                let d = BorrowingsTransaction.tenure

                let splitData = d.split(/([0-9]+)/);
                setTenurePeriod(splitData[1]);
                setTenureValue(splitData[2]);
                setTenure({ label: splitData[2], value: splitData[2] });
                setsecurityDetailsId(BorrowingsTransaction.securityDetail.securityDetailId)
                setSecurityDet({ securitydet: BorrowingsTransaction.securityDetail.securityDetailName });
                setFolioNumber({ folioNumber: BorrowingsTransaction.folioNumber })
                setLender({ Lender: BorrowingsTransaction.lender })
                setSanAmount({ sanamount: BorrowingsTransaction.sanctionedAmt })
                setDisAmount({ disamount: BorrowingsTransaction.disbursedAmt });
                setDisDate(new Date(BorrowingsTransaction.dateOfDisbursement));
                setOpeningBal({ openingBal: BorrowingsTransaction.openingBalance });
                setOpeningDate(new Date(BorrowingsTransaction.dateOfOpeningBalance));
                setInterestRate({ interestRate: BorrowingsTransaction.interestPerc });
                setMonthEMI({ monthEMI: BorrowingsTransaction.monthlyEmi });
                setEMINumber(BorrowingsTransaction.numberOfEmi);
                setSelEMIFrequency({ label: BorrowingsTransaction.frequency.frequencyName, value: BorrowingsTransaction.frequency.frequencyId });
                setLastEMIDate(new Date(BorrowingsTransaction.dateOfLastEmi));
                setOutstandingAmt({ outstandingAmt: BorrowingsTransaction.outstandingAmt })
            }
        }
    }, [BorrowingsTransaction])

    return (
        <>
            <div className='mt-2 p-6' tabIndex={1}>
                <Modal ClassName='custom-dialog'
                    isOpen={showBorrowings}
                    toggle={handleCloseDetails}
                    size="xl"
                    aria-labelledby="example-modal-sizes-title-lg">
                    <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleCloseDetails()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        {
                            BorrowingsHeaderId === 0 ?
                                <h5 className="text-center">Add Borrowings</h5> :
                                <h5 className="text-center">Update Borrowings</h5>
                        }
                    </ModalHeader>

                    <ModalBody>
                        <Container fluid>
                            {loading ? (
                                <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                            ) : null}

                            <div className='mt-2 p-6'>
                                <Form className='border border-1 rounded p-3'>
                                    <Row className='form-set'>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Types of Loan<span className='text-danger h6'>*</span></Form.Label>
                                            <Select
                                                name="security"
                                                value={selSecurity}
                                                onChange={handleSecurity}
                                                options={security}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.selSecurity}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Security Details<span className='text-danger h6'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="securitydet"
                                                autoComplete='off'
                                                value={securityDet.securitydet}
                                                onChange={handleSecurityDet}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.securitydet}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Folio No/Account No<span className='text-danger h6'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete='off'
                                                min="0"
                                                value={folioNumber.folioNumber}
                                                onChange={handleFolioNo}
                                                name="folioNumber"
                                                style={{ textTransform: "uppercase" }}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.folioNumber}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Lender<span className='text-danger h6'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete='off'
                                                min="0"
                                                value={Lender.Lender}
                                                onChange={handleLender}
                                                name="Lender"
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.Lender}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row className='form-set'>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Sanctioned Amount<span className='text-danger h6'>*</span></Form.Label>
                                            {/* <Form.Control
                                                type="number"
                                                autoComplete='off'
                                                min="0"
                                                value={sanamount.sanamount}
                                                onChange={handleSanAmount}
                                                name="sanamount"
                                            /> */}

                                            <Cleave
                                                className='form-control'
                                                name="sanamount"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={sanamount.sanamount}
                                                onChange={handleSanAmount}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.sanamount}
                                                </div>
                                            ) : null}
                                        </Form.Group>


                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Initial Disbursed Amount<span className='text-danger h6'>*</span></Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip} >
                                                {/* <Form.Control
                                                    type="number"
                                                    autoComplete='off'
                                                    min="0"
                                                    value={disamount.disamount}
                                                    onChange={handleDisAmount}
                                                    name="disamount"
                                                /> */}

                                                <Cleave
                                                    className='form-control'
                                                    name="disamount"
                                                    options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                    value={disamount.disamount}
                                                    onChange={handleDisAmount}
                                                />
                                            </OverlayTrigger>
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.disamount}
                                                </div>
                                            ) : null}
                                            <Tooltip title={`The Sanctioned amount is:`} ></Tooltip>
                                        </Form.Group>

                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Date of Initial Disbursement<span className='text-danger h6'>*</span></Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select Disbursement Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                dateFormat="dd-MM-yyyy"
                                                name="DisDate"
                                                autoComplete="off"
                                                selected={disDate}
                                                onChange={handledisDate}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.disDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Opening Balance {selSecurity.label !== "Unsecured Loan Personal" ?
                                                <span className='text-danger h6'>*</span> : null}</Form.Label>
                                            {/* <Form.Control
                                                type="number"
                                                autoComplete='off'
                                                min="0"
                                                value={openingBal.openingBal}
                                                onChange={handleOpeningBal}
                                                name="openingBal"
                                            /> */}

                                            <Cleave
                                                className='form-control'
                                                name="openingBal"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={openingBal.openingBal}
                                                onChange={handleOpeningBal}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.openingBal}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Date of Opening Balance{selSecurity.label !== "Unsecured Loan Personal" ? <span className='text-danger h6'>*</span> : null}</Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select start Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                dateFormat="dd-MM-yyyy"
                                                name="OpeBalDate"
                                                autoComplete="off"
                                                onChange={handleopeningDate}
                                                selected={openingDate}
                                                minDate={disDate}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.openingDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Interest Rate %</Form.Label>
                                            <Form.Control
                                                type="number"
                                                autoComplete='off'
                                                placeholder='%'
                                                min="0"
                                                value={interestRate.interestRate}
                                                onChange={handleInterestRate}
                                                name="interestRate"
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.interestRate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Col md="3" className="mb-3">

                                            <Form.Label className="d-block">Tenure</Form.Label>
                                            <Form.Control type="text"
                                                onChange={(e) => setTenurePeriod(e.target.value)}
                                                autoComplete="off"
                                                value={tenurePeriod}
                                                name="tenure" id="tenure" className="lockinperiodno" />
                                            <Dropdown
                                                value={tenure}
                                                name='tenure'
                                                options={periodList}
                                                onChange={HandleChangePeriod}
                                                className="SelectClass lockinperiodlist"
                                                searchable={true}
                                                clearable={true}
                                                noResultsText={"No result"}
                                                placeholder="Month"

                                            />
                                        </Col>

                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Monthly EMI<span className='text-danger h6'>*</span></Form.Label>
                                            {/* <Form.Control
                                                type="number"
                                                autoComplete='off'
                                                name="monthEMI"
                                                min="0"
                                                value={monthEMI.monthEMI}
                                                onChange={handleMonthEMI}
                                            /> */}
                                            <Cleave
                                                className='form-control'
                                                name="monthEMI"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={monthEMI.monthEMI}
                                                onChange={handleMonthEMI}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.monthEMI}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>No.of EMIs</Form.Label>
                                            {/* <Form.Control
                                                type="number"
                                                autoComplete='off'
                                                min="0"
                                                value={EMINumber}
                                                onChange={handleEMINo}

                                            /> */}
                                            <Cleave
                                                className='form-control'
                                                autoComplete='off'
                                                options={{ numeral: true, numeralDecimalScale: 0, numeralThousandsGroupStyle: 'lakh' }}
                                                value={EMINumber}
                                                onChange={handleEMINo}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Frequency of EMI<span className='text-danger h6'>*</span></Form.Label>
                                            <Select
                                                name="emifrequency"
                                                value={selEMIFrequency}
                                                onChange={handleEMIFrequency}
                                                options={EMIFrequency}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.selEMIFrequency}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className='mb-6 col-md-3'>
                                            <Form.Label>Date of Last EMI<span className='text-danger h6'>*</span></Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select Last EMI Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                dateFormat="dd-MM-yyyy"
                                                name="lastEMIDate"
                                                autoComplete="off"
                                                onChange={handleLastEMIDate}
                                                selected={LastEMIDate}
                                                minDate={new Date(disDate)}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.LastEMIDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        {
                                            BorrowingsHeaderId === 0 ? null :
                                                <Form.Group className='mb-6 col-md-3'>
                                                    <Form.Label>Outstanding Amount</Form.Label>
                                                    {/* <Form.Control
                                                        type="text"
                                                        autoComplete='off'
                                                        min="0"
                                                        value={NumberSeperator(outstandingAmt.outstandingAmt)}
                                                        // onChange={handleoutstandingamt}
                                                        // name="outstandingamt"
                                                        disabled
                                                    /> */}
                                                    <Cleave
                                                        className='form-control'
                                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                        value={outstandingAmt.outstandingAmt}
                                                        disabled
                                                    />
                                                </Form.Group>
                                        }
                                        <Form.Group className="mb-6 col-md-3">
                                            <Form.Label>Narration</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="narration"
                                                autoComplete="off"
                                                rows={2}
                                                value={narration.narration}
                                                onChange={handleNarration}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="form-set">
                                        <Col md="12" className="text-center mt-3">
                                            {BorrowingsHeaderId === 0 ?
                                                <>
                                                    <Button variant="primary" className="mx-2 w100"
                                                        id="submitButton"
                                                        onClick={handleSubmit}
                                                        disabled={!disableButton()}
                                                    >Submit</Button>
                                                    <Button
                                                        variant="secondary"
                                                        className="mx-2 w100"
                                                        type="reset"
                                                        onClick={handleReset}
                                                    >
                                                        Clear
                                                    </Button>
                                                </> :
                                                <>
                                                    <Button variant="primary" className="mx-2 w100"
                                                        onClick={handleUpdate}
                                                        id="submitButton"
                                                        disabled={!disableButton()}
                                                    >Update</Button>
                                                    <Button variant="primary" className="mx-2  w100"
                                                        onClick={handleInstallment} >Topup</Button>
                                                    <Button variant="primary" className="mx-2 w100"
                                                        onClick={handleInterest} disabled={BorrowingsTransaction && BorrowingsTransaction.interestPerc === 0}>Add Interest</Button>
                                                    <Button variant="primary" className="mx-2 w100"
                                                        onClick={handleShowRepay}
                                                    >Repay</Button>
                                                </>
                                            }
                                        </Col>
                                    </Row>

                                </Form>

                            </div>
                        </Container>
                    </ModalBody>

                </Modal>

            </div>
            <BorrowingsInstallment showBorrowingsIn={showInstallment}
                hideBorrowingsIn={handleHideInstall}
                borrowingId={BorrowingsHeaderId}
                InvtDate={disDate}
                onRefreshBorrowings={() => onRefreshInnerGrid()}
                sanamounttop={sanamount.sanamount}
                disamounttop={disamount.disamount}
            />
            <BorrowingsIntrest showBorrowInterest={showBorroInte} hideBorrowInterest={handleHideInterest} Iname={"Borrowings Interest"} BorrowId={BorrowingsHeaderId} />
            <BorrowingsRepay showBorrowRepay={showRepay} hideBorrowRepay={handleHideRepay} BorrowID={BorrowingsHeaderId} InterestPercentage={BorrowingsTransaction.interestPerc} />
        </>
    )
}

export default BorrowingDetils