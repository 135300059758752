/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { GetDividendFrequency } from "../../../pages/Stocks/StocksAPI";
import { AddRecurringDeposit, UpdateRecurringDeposit } from "../FDAPI";
import { Form, Button, Row, Col } from "react-bootstrap";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Select from "react-select";
import swal from "sweetalert";
import Loader from "../../loadingAnimation/index";
import Dropdown from '../../CommonComponent/Dropdown';
import Maturity from './Maturity'
import { periodList } from '../../../modules/helpers/constant'
import RDInterest from "./RDInterest";
import RDInstallment from "./RDInstallment";
import Cleave from 'cleave.js/react';
import { Tooltip } from "@material-ui/core";

const RD = (props) => {

    const { FDHeaderId, RDTransaction, show6, Rdentity, onRefreshFD } = props;

    const [showRDMaturity, setRDMaturity] = useState(false);
    const [company, setCompany] = useState({ company: "" });
    const [folioNumber, setFolioNumber] = useState({ folioNumber: "" });
    const [iDate, setIDate] = useState(null);
    const [investamount, setInvestAmount] = useState({ totalCost: "" });
    const [installments, setInstallments] = useState({ numberOfInstalments: "" });
    const [frequencyValue, setFrequencyValue] = useState([]);
    const [mDate, setMDate] = useState(null);
    const [maturityVal, setMaturityVal] = useState({ maturityValue: "" });
    const [lockinperiod, setLockinperiod] = useState("")
    const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
    const [lockPeriodValue, setLockPeriodValue] = useState("Month");
    const [selFrequency, setSelFrequency] = useState([]);
    const [dividendfrequency, setDividendfrequency] = useState([]);
    const [rateOfInterest, setRateOfInterest] = useState({ rateInterest: "" });
    const [reinvestedRadio, setreinvestedRadio] = useState("no");
    const [interestAcc, setInterestAcc] = useState({ interestReinvested: "" });
    const [startDate, setStartDate] = useState(null);


    const [fdId, setFdId] = useState()

    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isInterest, setIsInterest] = useState(false);

    const [Rdi, setRdi] = useState(false);
    const [RDInstall, setRDInstall] = useState(false);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }


    useEffect(() => {

        getDividedFrequencyList();

    }, [])

    const errHandel = () => {
        let formErr = {};
        let isValid = true;

        if (!company.company) {
            isValid = false;
            formErr["company"] = "Please Enter Company Or Bank !";
        }
        if (!iDate) {
            isValid = false;
            formErr["idate"] = "Please Select Investment Date !";
        }

        if (!investamount.totalCost) {
            isValid = false;
            formErr["totalCost"] = "Please Enter Amount Invested !";
        }

        if (!mDate) {
            isValid = false;
            formErr["mdate"] = "Please Select Maturity Date !";
        }
        if (!folioNumber.folioNumber) {
            isValid = false;
            formErr["folioNumber"] = "Please Enter Certificate/Folio number!";
        }
        if (mDate && iDate) {
            if (fixTimezoneOffset(mDate) === fixTimezoneOffset(iDate)) {
                isValid = false;
                formErr["mdate"] = "Maturity Date Cannot Be Same As Investment Date!";
            }
        }
        // if (!rateOfInterest.rateInterest) {
        //     isValid = false;
        // }

        if (interestAcc.interestReinvested === "" && reinvestedRadio === "yes") {
            isValid = false;
            formErr["interestAcc"] = "Please Enter Interest Accrued !";
        }
        if ((!startDate) && (reinvestedRadio === "yes")) {
            isValid = false;
            formErr["startDate"] = "Please Select Date !";
        }
        setFormErr(formErr);
        return isValid;
    };

    //getdividend list
    const getDividedFrequencyList = async () => {
        await GetDividendFrequency()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let dividend = res.data._response.data.dividendfrequencyDetails;
                        let dividendData = [];
                        dividend &&
                            dividend.map((data) => {
                                dividendData.push({
                                    value: data.frequencyId,
                                    label: data.frequencyName,
                                });
                            });
                        setDividendfrequency(dividendData);
                        if (FDHeaderId === 0) {
                            setSelFrequency(dividendData[1]);
                        }
                    }

                } else {
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
            });
    };

    function replaceNonNumeric(numStr) {

        return String(numStr).replace(/[^0-9]/g, '')

    }

    function commarize(numStr) {

        return Number(replaceNonNumeric(numStr)).toLocaleString()

    }

    //handlecompnay/bank
    const handleCompany = (e) => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
        setFormErr({ ...formErr, company: "" })
    }


    //handle foliono
    const handleFolio = (e) => {
        const { name, value } = e.target;
        setFolioNumber({ ...folioNumber, [name]: value });
        setFormErr({ ...formErr, folioNumber: "" })
    }

    //handleInvestmentDate
    const handleInvDate = (iDate) => {
        setIDate(iDate);
        setFormErr({ ...formErr, idate: "" })
    }
    //handleradio
    const handleRadio = (e) => {
        const { name, value } = e.target
        setreinvestedRadio(value);
        if (value === "no") {
            setStartDate(null);
            setInterestAcc({ ...interestAcc, interestReinvested: "" })
        }
    }
    //handleinterstAcc
    const handleValueIntAcc = (e) => {
        const { name, rawValue } = e.target;
        setInterestAcc({ ...interestAcc, [name]: rawValue });
        setFormErr({ ...formErr, interestAcc: "" })
    }



    //handlestartingdate
    const handleDateStart = (startDate) => {
        setStartDate(startDate);
        setFormErr({ ...formErr, startDate: "" })
    }
    //handle amount invest
    const handleInvestAmount = (e) => {
        const { name, rawValue } = e.target;
        setInvestAmount({ ...investamount, [name]: rawValue });
        setFormErr({ ...formErr, totalCost: "" })
    }

    //handle installments
    const handleInstallment = (e) => {
        const { name, rawValue } = e.target;
        setInstallments({ ...installments, [name]: rawValue });
        setFormErr({ ...formErr, numberOfInstalments: "" })
    }

    //handle frequency
    const handleFrequency = (selected) => {
        setSelFrequency(selected);
        setFrequencyValue(selected.value)
        setFormErr({ ...formErr, selFrequency: "" })
    }

    //handle maturity date
    const handleDateM = (mDate) => {
        setMDate(mDate);
        setFormErr({ ...formErr, mdate: "" });
    }

    //handle maturity value
    const handleMaturityVal = (e) => {
        const { name, rawValue } = e.target;
        setMaturityVal({ ...maturityVal, [name]: rawValue });
        setFormErr({ ...formErr, maturityValue: "" })
    }

    //handle maturity value
    const handleRateofintrest = (e) => {
        const { name, value } = e.target;
        setRateOfInterest({ ...rateOfInterest, [name]: value > 100 ? 100 : value < 0 ? 0 : value });
        setFormErr({ ...formErr, rateInterest: "" })
    }
    //handle lock in period
    const HandleChangePeriod = (e) => {
        setLockPeriod(e);
        setLockPeriodValue(e.value);
        setFormErr({ ...formErr, lockPeriodValue: '' })
    }

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    // get data for update
    useEffect(() => {
        if (FDHeaderId !== 0) {
            if (RDTransaction !== undefined) {
                setIDate(new Date(RDTransaction.date))
                RDTransaction.fdDetails && RDTransaction.fdDetails.map((t) => {
                    setFdId(t.fdId);
                    setCompany({ company: t.company.companyName });
                    setFolioNumber({ folioNumber: t.folioNumber });
                    t.frequencyId ? setSelFrequency({ label: t.frequency.frequencyName, value: t.frequencyId }) : setSelFrequency([]);
                    setMaturityVal({ maturityValue: t.maturityValue });
                    setInvestAmount({ totalCost: t.availableCost });
                    setInstallments({ numberOfInstalments: t.numberOfInstalments })
                    setRateOfInterest({ rateInterest: t.interestPerc })
                    setMDate(new Date(t.domConversion));
                    let l = t.lockInPeriod ? t.lockInPeriod : "0Month";
                    let splitDatalock = l.split(/([0-9]+)/);
                    setLockinperiod(splitDatalock[1]);
                    setLockPeriodValue(splitDatalock[2]);
                    setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });
                    setreinvestedRadio(t.interestAccrued === true ? "yes" : "no");
                    if (t.interestAccrued === true) {
                        setInterestAcc({ interestReinvested: t.interestReinvested });
                        setStartDate(new Date(t.fyStartDate))
                        // setIsInterest(true)
                        t.balanceQuantity > 0 ? setIsInterest(true) : setIsInterest(false);
                    }
                })
            }
        }
    }, [RDTransaction])

    // submit Data
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let RDData = {
                instrumentId: 4,
                instrumentTypeId: 30,
                subAccountId: Rdentity,
                date: fixTimezoneOffset(iDate),
                certificateFolioNo: company.company,
                fdDetails: [{
                    transactStockType: "Buy",
                    interestAccrued: reinvestedRadio === "yes" ? true : false,
                    interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
                    availableCost: investamount.totalCost,
                    folioNumber: folioNumber.folioNumber,
                    lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                    domConversion: fixTimezoneOffset(mDate),
                    MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investamount.totalCost),
                    frequencyId: selFrequency.value,
                    fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                    interestPerc: rateOfInterest.rateInterest ? rateOfInterest.rateInterest : 0,
                    numberOfInstalments: installments.numberOfInstalments ? installments.numberOfInstalments : 0,
                    cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"
                }]
            }
            AddRecurringDeposit(RDData).then((response) => {

                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        swal("RD Added Successfully!", "", "success");
                    } else {
                        swal("Something went wrong!", "", "info");
                    }
                    handleReset()
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });


        }
    };

    // update Data
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let RDData = {
                fdHeaderId: FDHeaderId,
                instrumentId: 4,
                instrumentTypeId: 30,
                subAccountId: Rdentity,
                date: fixTimezoneOffset(iDate),
                certificateFolioNo: company.company,
                fdDetails: [
                    {
                        fdId: fdId,
                        transactStockType: "Buy",
                        interestAccrued: reinvestedRadio === "yes" ? true : false,
                        interestReinvested: reinvestedRadio === "yes" ? Number(interestAcc.interestReinvested) : 0,
                        availableCost: Number(investamount.totalCost),
                        folioNumber: folioNumber.folioNumber,
                        lockInPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                        domConversion: fixTimezoneOffset(mDate),
                        interestPerc: rateOfInterest.rateInterest ? rateOfInterest.rateInterest : 0,
                        MaturityValue: reinvestedRadio === "yes" ? maturityVal.maturityValue ? Number(maturityVal.maturityValue) : 0 : Number(investamount.totalCost),
                        frequencyId: selFrequency.value,
                        fyStartDate: reinvestedRadio === "yes" ? fixTimezoneOffset(startDate) : null,
                        numberOfInstalments: installments.numberOfInstalments ? installments.numberOfInstalments : 0,
                        cumulativeNonCumulative: reinvestedRadio === "yes" ? "Cumulative" : "Non Cumulative"

                    }
                ]
            };
            UpdateRecurringDeposit(RDData).then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    if (response.data._response.status === 1) {
                        swal("RD Updated Successfully!", "", "success");
                    } else {
                        swal("Something went wrong!", "", "info");
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };

    //handleReset
    const handleReset = () => {
        setFolioNumber({ folioNumber: "" });
        setCompany({ company: "" });
        setIDate(null);
        setInvestAmount({ totalCost: "" });
        setInstallments({ numberOfInstalments: "" });
        setSelFrequency(dividendfrequency[1]);
        setMDate(null);
        setMaturityVal({ maturityValue: "" })
        setLockinperiod("")
        setLockPeriod([{ label: "Month", value: "Month" }]);
        setIsInterest(false)
        setRateOfInterest({ rateInterest: "" });
        setInterestAcc({ interestReinvested: "" });
        setStartDate(null)
        setreinvestedRadio('no')
        setFormErr({});
    }

    const handleMaturity = () => {
        setRDMaturity(true)
    }
    const handleCloseMaturity = () => {
        setRDMaturity(false);
    }

    // handle buttons
    const handleInterest = () => {
        setRdi(!Rdi)
    }
    const handleInstall = () => {
        setRDInstall(true)
    }
    const handleCloseInterest = () => {
        setRdi(false)
    }
    const handleCloseInstallMent = () => {
        setRDInstall(false);
    }

    //disable button
    const disableButton = () => {
        return iDate && mDate && company && investamount

    }
    const handledisableCheck = (i) => {
        if (i === 1) {
            setIsInterest(true);
        } else {
            setIsInterest(false);
        }
    }
    return (
        <>
            {show6 === true ?
                <>

                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "12rem" }} />
                    ) : null}
                    <div className="mt-2 p-6" tabIndex={2}>
                        <Form className=" rounded p-3" >
                            <Row className="form-set">

                                {FDHeaderId === 0 || FDHeaderId === undefined ?
                                    <Form.Group className="mb-6 col-md-3">
                                        <Form.Label>Company / Bank / Post office<span className="text-danger h6">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company"
                                            min="0"
                                            value={company.company}
                                            onChange={handleCompany}
                                            autoComplete="off"
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.company}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    :

                                    <Form.Group className="mb-6 col-md-3">
                                        <Form.Label>Company / Bank / Post office<span className="text-danger h6">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company"
                                            min="0"
                                            value={company.company}
                                            onChange={handleCompany}
                                            autoComplete="off"
                                            disabled
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.company}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                }

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Certificate No./Folio No.<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleFolio}
                                        name="folioNumber"
                                        min="0"
                                        value={folioNumber.folioNumber}
                                        style={{ textTransform: "uppercase" }}
                                        autoComplete="off"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.folioNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>


                                <Form.Group className="col-md-3">
                                    <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Start Date"
                                        name="idate"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleInvDate}
                                        dateFormat="dd-MM-yyyy"
                                        autoComplete="off"
                                        selected={iDate}
                                        maxDate={new Date()}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.idate}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        name="totalCost"
                                        min="0"
                                        value={investamount.totalCost}
                                        onChange={handleInvestAmount}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        placeholder="Enter Amount"
                                        name="totalCost"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={investamount.totalCost}
                                        onChange={handleInvestAmount} />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.totalCost}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">


                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Number of Installments</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="numberOfInstalments"
                                        min="0"
                                        value={installments.numberOfInstalments}
                                        onChange={handleInstallment}
                                    /> */}
                                    <Cleave
                                        className='form-control'
                                        autoComplete="off"
                                        name="numberOfInstalments"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={installments.numberOfInstalments}
                                        onChange={handleInstallment} />

                                </Form.Group>

                                <Col md="3" className="mb-3">
                                    <Form.Label className="d-block">Lock in Period</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setLockinperiod(e.target.value)}
                                        autoComplete="off"
                                        value={lockinperiod}
                                        name="lockinperiod" id="lockinperiod"
                                        className="lockinperiodno" />
                                    <Dropdown
                                        value={lockPeriod}
                                        name='lockinperiod'
                                        options={periodList}
                                        onChange={HandleChangePeriod}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"
                                    // onInputChange={HandleInput}
                                    />
                                </Col>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Interest %</Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="rateInterest"
                                        min="0"
                                        value={rateOfInterest.rateInterest}
                                        onChange={handleRateofintrest}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.rateInterest}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Interest Frequency</Form.Label>
                                    <Select
                                        value={selFrequency}
                                        onChange={handleFrequency}
                                        options={dividendfrequency}
                                        name="frequency"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>


                                <Form.Group className="col-md-2">
                                    <Form.Label>Maturity Date<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Maturity Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleDateM}
                                        name="mdate"
                                        dateFormat="dd-MM-yyyy"
                                        autoComplete="off"
                                        selected={mDate}
                                        minDate={new Date(iDate)}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mdate}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Maturity Value</Form.Label>
                                    {
                                        reinvestedRadio === "yes" ?
                                            // <Form.Control
                                            //     type="text"
                                            //     name="maturityValue"
                                            //     min="0"
                                            //     value={maturityVal.maturityValue}
                                            //     onChange={handleMaturityVal}
                                            // />
                                            <Cleave
                                                className='form-control'
                                                name="maturityValue"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={maturityVal.maturityValue}
                                                onChange={handleMaturityVal} />
                                            :
                                            <Form.Control
                                                type="text"
                                                name="maturityValue"
                                                min="0"
                                                value={NumberSeperator(isNaN(investamount.totalCost)? 0.00 : investamount.totalCost)}
                                                // onChange={handleMaturityVal}
                                                disabled
                                            />
                                    }
                                </Form.Group>

                                <Form.Group className="col-md-2">
                                    <Form.Label>Interest reinvested</Form.Label>
                                    <RadioGroup
                                        row
                                        color="primary"
                                        defaultValue="start"
                                        aria-label="Entity"
                                        name="row-radio-buttons-group"
                                        fontSize="100px"
                                        value={reinvestedRadio}
                                        onChange={handleRadio}
                                        style={{ marginTop: "-2px" }}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                            disabled={isInterest}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                            disabled={isInterest}
                                        />
                                    </RadioGroup>
                                </Form.Group>


                                {reinvestedRadio === "yes" ?
                                    <>
                                        <Form.Group className="col-md-3">
                                            <Form.Label>Interest Accrued till date<span className="text-danger h6">*</span></Form.Label>
                                            {/* <Form.Control
                                                type="Number"
                                                name="interestReinvested"
                                                min="0"
                                                value={interestAcc.interestReinvested}
                                                onChange={handleValueIntAcc}
                                            /> */}
                                            <Cleave
                                                className='form-control'
                                                autoComplete="off"
                                                name="interestReinvested"
                                                options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                                value={interestAcc.interestReinvested}
                                                onChange={handleValueIntAcc} />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.interestAcc}
                                                </div>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group className="col-md-3">
                                            <Form.Label>Financial Year starting with<span className="text-danger h6">*</span></Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                placeholderText="Select Start Date"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={50}
                                                maxDate={new Date()}
                                                // minDate={new Date(buyDate)} dateFormat="dd-MM-yyyy"
                                                onChange={handleDateStart}
                                                dateFormat="dd-MM-yyyy"
                                                name="fyStartDate"
                                                autoComplete="off"
                                                selected={startDate}
                                            />
                                            {formErr ? (
                                                <div
                                                    className="mt-2"
                                                    style={{ fontSize: 12, color: "red" }}
                                                >
                                                    {formErr.startDate}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </> : null
                                }
                            </Row>
                            <Row>

                                <Col md="12" className="text-center mt-4">
                                    {
                                        FDHeaderId === 0 || FDHeaderId === undefined ?
                                            <>
                                                <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                                    <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                                        <Button variant="primary" type="submit"
                                                            id="submitButton"
                                                            className="mx-2 w100" onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                                            disabled={!disableButton()}
                                                        >Submit</Button>
                                                    </span>
                                                </Tooltip>
                                                <Button
                                                    variant="secondary"
                                                    className="mx-2 w100"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    Reset
                                                </Button> </>
                                            :
                                            <>
                                                <Button variant="primary" className="mx-2 w100"
                                                    type="submit"
                                                    id="submitButton"
                                                    onClick={(e) => { e.preventDefault(); handleUpdate(e) }} disabled={!disableButton()}
                                                >Update</Button>
                                                <Button variant="primary" className="mx-2"
                                                    onClick={handleInstall}>Add Installment</Button>
                                                <Button variant="primary" className="mx-2 w100"
                                                    onClick={handleInterest} disabled={reinvestedRadio === "no"}>Add Interest</Button>
                                                <Button
                                                    variant="primary"
                                                    className="mx-2 w100"
                                                    type="reset"
                                                    onClick={handleMaturity}
                                                    style={{ width: '7rem', height: '2rem' }}
                                                >
                                                    Add Maturity
                                                </Button>

                                            </>
                                    }


                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <RDInterest showRDI={Rdi} hideRDI={handleCloseInterest} FDHeaderId={FDHeaderId} FDiD={fdId} InvDate={iDate} Iname={"RD Interest"} disableCheck={(i) => handledisableCheck(i)} />
                    <RDInstallment showRDIn={RDInstall} hideRDIn={handleCloseInstallMent} FDHeaderId={FDHeaderId} FDiD={fdId} InvtDate={iDate} Iname={"RD Installment"} />
                    <Maturity showMaturity={showRDMaturity} onHideMaturity={handleCloseMaturity} FDHeaderId={FDHeaderId} FDiD={fdId} InvDate={mDate} Fname={"RD Maturity"} onRefreshFD={() => onRefreshFD()} />
                </>
                : null}
        </>

    )

}

export default RD