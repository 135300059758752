/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { EntityList, AddRedeem, FetchBrokerDropDownData, FetchCompanyDropDownData, FetchPreferanceDetails } from "../../../pages/Stocks/StocksAPI";
import { Form, Button, Row, Modal, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import moment from 'moment-timezone';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const Reedemable = (props) => {
    const { showUPR, onHideUPR, stockHeaderId, stockTransactionUPR, onRefresh, showredeempreview } = props;
    const [selScrip, setSelScrip] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [mDate, setMDate] = useState(null);
    const [rDate, setRDate] = useState(null);
    const [amount, setAmount] = useState({ amount: "" });
    const [price, setPrice] = useState({ price: "" });
    const [quantity, setQuantity] = useState({ quantity: "" });
    const [formErr, setFormErr] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buyDate, setBuyDate] = useState(null)
    // const [error, setError] = useState('');
    const [sid, setSid] = useState(null);
    const [sHeaderId, setSHeaderId] = useState(null);

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    // seperation by comma
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN').format(input)
    }

    useEffect(() => {
        (async () => {
            if (showUPR) {

                await getEntityListt();
                await getCompanyDropDownData();
            }
        })();
    }, [showUPR]);

    useEffect(() => {
        if (showUPR) {
            (async () => {
                await getCompanyDropDownData()
                await getBrokerDropDownData()
            })();
        }
    }, [selEntity, showUPR]);

    //    useEnterKeyListener ({
    //         querySelectorToExecuteClick: "#submitButton"
    //     });

    // for get data
    useEffect(() => {
        let sub_id = selEntity.value;
        let bro_id = selBroker.value;
        let co_id = selScrip.value;
        if (showUPR) {
            if (co_id && bro_id && sub_id) {
                if (stockHeaderId === 0 || stockHeaderId === undefined) {
                    setRDate(null);
                }
                setLoading(true);
                getPreferanceDetails(sub_id, co_id, bro_id);
            }
        }
    }, [selScrip, selBroker, selEntity, showUPR]);


    const getPreferanceDetails = async (sub_id, co_id, bro_id) => {
        await FetchPreferanceDetails(sub_id, co_id, bro_id, "Redeemable")
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let result = res.data._response.data.fetchPreferanceDetails;

                        result && result.map((data) => {
                            if (data.redeemConvertible === "Redeemable") {
                                setPDate(new Date(data.date));
                                setMDate(new Date(data.domConversion));
                                setAmount({ amount: data.availableCost })
                                setQuantity({ quantity: data.availableQuantity })
                                setPrice({ price: data.pricePerShare })
                                setSid(data.stockId)
                                setSHeaderId(data.stockHeaderId)
                            } else {
                                Swal.fire("This Company and Broker not available for redeem!", "", "warning");
                            }

                        });
                        setLoading(false)
                    } else {
                        Swal.fire("This Company and Broker not available for redeem!", "", "warning");
                        setPDate();
                        setMDate();
                        setAmount({ amount: '' })
                        setQuantity({ quantity: '' })
                        setPrice({ price: '' })
                        setSid()
                        setSHeaderId()
                        setLoading(false)
                    }
                } else {
                    setPDate();
                    setMDate();
                    setAmount({ amount: '' })
                    setQuantity({ quantity: '' })
                    setPrice({ price: '' })
                    setSid()
                    setSHeaderId()
                    setLoading(false)
                    // setError(res.data.message);
                }
            }).catch((e) => {
                setPDate();
                setMDate();
                setAmount({ amount: '' })
                setQuantity({ quantity: '' })
                setPrice({ price: '' })
                setSid()
                setSHeaderId()
                setLoading(false)
                // setError(e.message);
            });
    }

    //getEntityList
    const getEntityListt = async () => {
        setLoading(true)
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let entity = res.data._response.data.subaccountDetails;
                        let entityData = [];
                        entity &&
                            entity.map((data) => {
                                return (entityData.push({
                                    value: data.subAccountId,
                                    label: data.subAccountName,
                                    disabled: data.active
                                }))
                            });
                        setEntity(entityData);
                        setLoading(false)
                    }
                } else {
                    // setError(res.data.message);
                    setLoading(false)
                }
            })
            .catch((e) => {
                // setError(e.message);
                setLoading(false)
            });



    };

    // get dropDown Company Data
    const getCompanyDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchCompanyDropDownData(sub_Id)
                .then((res) => {
                    // console.log(res,'company list');
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            let UnlistedCompany = [];
                            let ListedCompany = [];
                            //
                            let company = res.data._response.data.companyName;
                            company &&
                                company.filter(data => data.instrumentTypeId === 1 || data.instrumentTypeId === 11).map(i => {
                                    return (ListedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                                });
                            company &&
                                company.filter(data => data.instrumentTypeId === 20).map(i => {
                                    return (UnlistedCompany.push({ label: i.companyName, value: i.companyId, companyType: i.instrumentTypeId }))
                                });

                            const groupedOptions = [
                                {
                                    label: 'Listed Companies',
                                    options: ListedCompany,
                                },
                                {
                                    label: 'UnListed Companies',
                                    options: UnlistedCompany,
                                },
                            ];
                            setLoading(false);
                            setCompanyList(groupedOptions);
                        }
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                        // setError(res.data.message);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                    // setError(e.message);
                });
        }
    };

    // get dropDown company Data
    const getBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await FetchBrokerDropDownData(sub_Id)
                .then((res) => {
                    setLoading(false)
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            let brokers = res.data._response.data.brokerName;
                            let brokersData = [];
                            brokers &&
                                brokers.map((data) => {
                                    brokersData.push({
                                        value: data.brokerId,
                                        label: data.brokerName,
                                    });
                                });
                            setLoading(false);
                            setBrokerList(brokersData);
                        }
                    } else {
                        setBrokerList([]);
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    setBrokerList([]);
                    setLoading(false);
                    console.log(e, "brokerlist error");
                });
        }
    };

    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please select scrip!";
        }
        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please select Entity!";
        }
        if (!rDate) {
            isValid = false;
            formErr["rdate"] = "Please select Date !";
        }


        setFormErr(formErr);
        return isValid;
    };

    //handleScrip
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: '' });
    };

    // handleEntity
    const handleFamily = (selected) => {
        setSelEntity(selected);
        setSelBroker([])
        setFormErr({ ...formErr, selEntity: "" });
    };

    // handlebroker
    const handleOption = (selected) => {
        setSelBroker(selected);
        setSelScrip([]);
        setSelScrip([]);
        setFormErr({ ...formErr, selBroker: "" });
    };

    // handle redeemDAte
    const handleRDate = (rDate) => {
        setRDate(rDate);
        setFormErr({ ...formErr, rdate: '' })
    };

    const handleCloseModal = (val) => {
        handleReset();
        onHideUPR(val);
    }
    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 20) {
                return true
            }
        }
    }
    // for  redeem date fix 
    const fixTimezonerOffset = (rDate) => {
        if (!rDate) return "";
        return new Date(rDate.getTime() - (rDate.getTimezoneOffset() * 60000)).toJSON();
    }
    // for  mature date fix 
    const fixTimezonemOffset = (mDate) => {
        if (!mDate) return "";
        return new Date(rDate.getTime() - (mDate.getTimezoneOffset() * 60000)).toJSON();
    }
    //submit form
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            let RedeemData = {
                subAccountId: selEntity.value,
                brokerId: selBroker.value,
                companyId: selScrip.value,
                domConversion: fixTimezonemOffset(mDate),
                quantity: parseInt(quantity.quantity),
                pricePerShare: parseInt(price.price),
                totalCost: parseInt(amount.amount),
                date: fixTimezonerOffset(rDate),
                checkstockId: sid,
                checkstockHeaderId: sHeaderId
            };
            AddRedeem(RedeemData).then((response) => {
                if (response.status === 200) {
                    if (response.data._response.status === 1) {
                        setLoading(false)
                        //setSuccessMessage(response.data._response.message);
                        setSuccess(true);
                        Swal.fire("Redeem successfully!", "", "success");
                        onRefresh();
                        handleReset();
                    }
                } else {
                    setLoading(false)
                    console.log("error");
                }
            })
                .catch((error) => {
                    setLoading(false)
                    console.log(error, "error in submit");
                });
        }
    };



    //reset field
    const handleReset = () => {
        setSelScrip([]);
        setSelEntity([]);
        setSelBroker([]);
        setPDate(null);
        setMDate(null);
        setRDate(null)
        setQuantity({ quantity: '' });
        setPrice({ price: '' });
        setAmount({ amount: '' });
        setFormErr([]);
    }

    //get reddemed data by id
    useEffect(() => {
        if (stockHeaderId !== 0) {
            if (stockTransactionUPR !== undefined) {
                setSelBroker({
                    value: stockTransactionUPR.brokerId,
                    label: stockTransactionUPR.broker.brokerName,
                });
                setBrokerList(stockTransactionUPR.brokerId);
                setSelEntity({
                    value: stockTransactionUPR.subAccountId,
                    label: stockTransactionUPR.subAccount.subAccountName,
                });
                setEntity(stockTransactionUPR.subAccountId);
                stockTransactionUPR.stockDetails && stockTransactionUPR.stockDetails.map((t) => {
                    const f = t.fromCompany
                    setSelScrip({ label: f.companyName, value: f.CompanyId })
                    setSelScrip(t.company)
                    setRDate(new Date(t.date))
                    setMDate(new Date(t.domConversion))
                    setQuantity({ quantity: t.quantity })
                    setAmount({ amount: t.totalCost })
                    setPrice({ price: t.pricePerShare })
                })
            }
        }
    }, [stockTransactionUPR])

    const disableButton = () => {
        return pDate && selEntity && rDate && mDate &&
            selScrip && selBroker && price && quantity
            && amount
    }

    return (
        <>
            <Modal show={showUPR} onHide={() => handleCloseModal()} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Redeem</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3" >
                            <Row className="form-set">
                                <Form.Group className="col-md-4">
                                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selEntity}
                                        onChange={handleFamily}
                                        options={Entity}
                                        name="subAccountId"
                                        isOptionDisabled={(option) => option.disabled === 0}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selEntity}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selBroker}
                                        onChange={handleOption}
                                        options={BrokerList}
                                        name="brokerId"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selBroker}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selScrip}
                                        onChange={handleInput}
                                        options={companyList}
                                        name="companyId"
                                        isOptionDisabled={handelDisableDD}
                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selScrip}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                {stockHeaderId !== 0 ? null :
                                    <Form.Group className="col-md-4">
                                        <Form.Label>Date of Purchase</Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            placeholderText="Select start Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            dateFormat="dd-MM-yyyy"
                                            autoComplete="off"
                                            selected={pDate}
                                            disabled
                                        />
                                    </Form.Group>}
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Maturity</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Maturity Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(buyDate)} dateFormat="dd-MM-yyyy"
                                        autoComplete="off"
                                        selected={mDate}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label> Redeem on<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(mDate)}
                                        onChange={handleRDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={rDate}

                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.rdate}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-4">
                                    <Form.Label>No.of Shares Purchased</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="quantity"
                                        min="0"
                                        value={NumberOnlySeperator(quantity.quantity)}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Price per share</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        min="0"
                                        value={NumberSeperator(price.price)}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        min="0"
                                        value={NumberSeperator(amount.amount)}
                                        disabled
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                {stockHeaderId === 0 || stockHeaderId === undefined ?
                                    <Col md="12" className="text-center mt-3">
                                        <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={handleSubmit}
                                            disabled={!disableButton()}>Redeem</Button>


                                        <Button
                                            variant="secondary"
                                            className="mx-2 w100"
                                            type="reset"
                                            onClick={handleReset}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                    : null}
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            {showredeempreview === true ?
                <Modal show={showredeempreview} onHide={() => handleCloseModal(1)}>
                    <Modal.Header closeButton className="headerBlue" >
                        <Modal.Title className=" text-center loginTitle" style={{ margin: '0 auto' }}><h5>Redeem Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Date of Maturity : </div>
                                <div className="transactionDataValue">{moment(mDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Redeem on : </div>
                                <div className="transactionDataValue">{moment(rDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">No. of Shares Purchased : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN').format(quantity.quantity)}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Price per Share : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(price.price)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Amount : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amount.amount)}</div></div>
                        </div>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}
export default Reedemable;

