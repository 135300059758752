import React from 'react'
import SubscriberUserDetails from '../../../components/RoleMaster/ChannelMaster/getSubscriber/SubscriberUserDetails';
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const GetSubscriberPage = () => {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);


  return (
    <div>
          {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar/>
            <div>
              <SubscriberUserDetails/>
            </div>
        </div>
    </div>
  )
}

export default GetSubscriberPage