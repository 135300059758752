/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Dropdown from '../CommonComponent/Dropdown'
import MaterialTable from "material-table";
import { Form, Button } from "react-bootstrap";
import swal from 'sweetalert2';
import $ from "jquery";
import { EntityList, updateCompanyList } from '../../pages/Stocks/StocksAPI'
import { GetMutualFundCompaniesForLive, UpdateMFCompanyList } from '../../pages/MutualFund/MutualFundAPI';
import { LivePriceType } from '../../modules/helpers/constant';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Auth from '../../modules/Auth'
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import './listedCss.css'

function ListedStocksLivePrice() {

    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [formErr, setFormErr] = useState({});
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [livePriceOption, setLivePriceOption] = useState([]);
    const [liveValue, setLiveValue] = useState([]);

    const userIDs = Auth.getsubcriberId();
    useEffect(() => {
        if (userIDs) {
            getEntityList()
            handleColumn()
        }
    }, [userIDs]);

    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
    });
    useEffect(() => {
        // for partly paid companies
        // if (selEntity && liveValue.value === 1 || liveValue.value === 3) {
        //     getCompanyList()
        // }
        if (selEntity && liveValue.value === 2) {
            GetMutualFundCompanies()
        }
        livePriceTypes();
        handleColumn()
    }, [selEntity, liveValue]);

    //get types
    const livePriceTypes = () => {
        if (LivePriceType) {
            let livetype = [];
            LivePriceType && LivePriceType.map((s) => {
                return (livetype.push({
                    value: s.value,
                    label: s.label,
                }))
            })
            setLivePriceOption(livetype)
        }
    }

    //getEntityList
    const getEntityList = async () => {
        setLoading(true)
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        let entity = res.data._response.data.subaccountDetails;
                        let entityData = [];
                        entity &&
                            entity.filter(i => i.active === 1).map((data) => {
                                entityData.push({
                                    value: data.subAccountId,
                                    label: data.subAccountName,
                                    disabled: data.active
                                });
                            });
                        setEntity(entityData);
                    }
                } else {
                    setLoading(false)
                    console.log("error in entity");
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false)
            });
    };

    // for partly paid companies
    // const getCompanyList = async () => {
    //     let subId = selEntity.value;
    //     if (subId !== null && subId !== undefined) {
    //         setLoading(true);
    //         await CompanyListBySubId(subId).then(res => {
    //             if (res.status === 200) {
    //                 if (res.data._response.status === 1) {
    //                     let CompanyData = [];
    //                     let company = res.data._response.data.fetchCompanies;

    //                     let dataCompany = []
    //                     // for partly paid , preference , bonds , debentures
    //                     let partlyPaid = company &&
    //                         company.filter(data => liveValue.value === 1 ? data.instrumentTypeId === 1 || data.instrumentTypeId === 11 : data.instrumentTypeId === 12 || data.instrumentTypeId === 13).map((i) => dataCompany.push(i))

    //                     dataCompany.map(i => {
    //                         let a = moment(i.upDate).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY");

    //                         return (CompanyData.push({
    //                             companyName: i.companyName,
    //                             companyId: i.companyId,
    //                             instrumentTypeId: i.instrumentTypeId,
    //                             prevLivePrice: i.prevLivePrice !== null && a ? i.prevLivePrice : i.prevLivePrice !== null && !a ? i.livePrice : 0,
    //                             livePrice: i.livePrice !== null && a ? i.livePrice : 0,
    //                             subAccountId: i.subAccountId
    //                         }))
    //                     })
    //                     setTableData(CompanyData);
    //                     setLoading(false);
    //                 } else {
    //                     setTableData([]);
    //                     setLoading(false);
    //                 }
    //             } else {
    //                 setLoading(false);
    //                 console.log("error in company");
    //             }
    //         }).catch(e => {
    //             console.log(e, "error in company");
    //             setLoading(false);
    //         })
    //     }
    // }


    
    //get MF Unlisted Companies
    const GetMutualFundCompanies = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await GetMutualFundCompaniesForLive(sub_Id)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            let MFCompany = [];
                            // let company = res.data._response.data.companyName;
                            let company = res.data._response.data.mutualFundCompaniesBySub;

                            let dataCompany = []
                            company && company.filter(data => data.instrumentTypeId === 41 || data.instrumentTypeId === 42 || data.instrumentTypeId === 43).map(i => dataCompany.push(i));

                            dataCompany.map(i => {
                                let a = moment(i.upDate).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY");

                                return (MFCompany.push({
                                    companyName: i.mutualFundCompanyName,
                                    companyId: i.mutualFundCompanyId,
                                    instrumentTypeId: i.instrumentTypeId,
                                    prevLivePrice: i.prevLastPrice !== null && a ? i.prevLastPrice : i.prevLastPrice !== null && !a ? i.prevLastPrice : 0,
                                    livePrice: i.lastPrice !== null && a ? i.lastPrice : 0,
                                    subAccountId: i.subAccountId
                                }))
                            })
                            setTableData(MFCompany)
                            setLoading(false);
                        } else {
                            setLoading(false);
                            setTableData([]);
                        }
                    } else {
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log('err in mutual fund companies', e)
                });
        }
    };

    const handleColumn = () => {
        $('th:nth-child(1)').html('')
    }

    const handleEntity = (selected) => {
        setTableData([]);
        setSelEntity(selected);
        setFormErr({});
        handleColumn()
    };

    const handleType = (selected) => {
        setTableData([]);
        setLiveValue(selected)
        setFormErr({});
        handleColumn();
    }

    const handleRowUpdate = (resolve, reject, data) => {
        const updateUser = [...tableData];
        Object.entries(data).map(([k, v]) => {
            // console.log(k, v.newData.livePrice);
            if (isNaN(v.newData.livePrice)) {
                v.newData.livePrice = 0
            }
            updateUser[k] = v.newData;
        })
        setTableData([...updateUser]);

        resolve()
    }

    const columns = [

        {
            title: 'Scrip Name', field: 'companyName', editable: 'never', cellStyle: {
                textAlign: 'center'
            }, headerStyle: {
                textAlign: 'center'
            }
        },
        {
            title: "Yesterday's Closing Price", field: 'prevLivePrice', type: 'numeric', editable: 'never', cellStyle: {
                textAlign: 'center'
            }, headerStyle: {
                textAlign: 'center'
            }
        },
        {
            title: 'Current Live Price', field: 'livePrice', type: 'numeric', cellStyle: {
                textAlign: 'center'
            }, headerStyle: {
                textAlign: 'center'
            },
        },
    ]

    const errHandle = () => {
        let isValid = true;
        let formErr = {};
        if (!liveValue) {
            formErr.push("please select type!")
        }
        if (tableData.length < 1) {
            // formErr['err'] = 'Please fill data'
            isValid = false;
        }
        tableData.map((d) => {
            if (d.livePrice === '') {
                // formErr['err'] = "Please fill all the fields of current live price"
                isValid = false;
            }
        })
        // if (isValid == false) {
        //     // setErr(true);
        //     // setFormErr(formErr)
        // }
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = errHandle();
        if (isValid === true) {
            setLoading(true);
            if (liveValue.value === 2) {
                tableData.map((q) => {
                    q.mutualFundCompanyId = q.companyId
                    q.mutualFundCompanyName = q.companyName
                    q.lastPrice = q.livePrice;
                    q.prevLastPrice = q.prevLivePrice
                    q.livePriceDate = q.lastPriceDate

                });
            };
            {
                let APICall = liveValue.value === 1 || liveValue.value === 3 ? updateCompanyList(tableData) : UpdateMFCompanyList(tableData);

                APICall.then(res => {
                    setLoading(false)
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            // handleReset();
                            swal.fire('Live price updated successfully', "", 'success');
                        } else {
                            swal.fire("Something went wrong", '', 'info')
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                    .catch(e => {
                        console.log(e, 'error in submit');
                        setLoading(false)
                    })
            }
        } else {
            setLoading(false);
            if (tableData.length < 1) {
                swal.fire('Oops..!', "Please fill the data", 'info');

            } else {
                swal.fire('Oops..!', "Please fill all the fields of current live price", 'info');
            }
        }
        setLoading(false);
    }

    const handleReset = () => {
        setFormErr({});
        setSelEntity([]);
        setLiveValue([])
        setLoading(false);
        setTableData([])
    }

    return (
        <>
            <div className="mt-2 pt-2 d-flex justify-content-center">
                <h4>Live price </h4>
            </div>
            <div className="p-5 pt-2 pb-1 d-flex justify-content-end">
                <div className="col-md-2 ml-auto" style={{ padding: "10px" }}>
                    <Form.Label className='mb-2'>Select Type <span className="text-danger h6">*</span></Form.Label><br></br>
                    <Dropdown
                        placeholder={'Select Type'}
                        options={livePriceOption}
                        onChange={handleType}
                        value={liveValue}
                    />
                    {formErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{formErr.liveValue}</div> : null}
                </div>
                <div className="col-md-2 ml-auto" style={{ padding: "10px" }}>
                    <Form.Label className='mb-2'>Select Entity <span className="text-danger h6">*</span></Form.Label><br></br>
                    <Dropdown
                        placeholder={'Select Entity'}
                        options={Entity}
                        onChange={handleEntity}
                        value={selEntity}
                        isDisable
                    />
                </div>
            </div>
            <div className="mt-2 p-5 pt-1  d-flex justify-content-center listedstockliveprice">
                <Paper sx={{ width: '100%', overflow: 'hidden' }} className="p-3 pb-0">
                    <MaterialTable
                        title="Live price table"
                        columns={columns}
                        data={tableData}
                        editable={{
                            onBulkUpdate: changes =>
                                new Promise((resolve, reject) => {
                                    handleRowUpdate(resolve, reject, changes,)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    resolve();
                                }),
                            isDeleteHidden: rowData => rowData.companyId,
                        }}
                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        isLoading={loading}
                    />
                    <div>

                    </div>
                </Paper>
            </div>
            <div className=" p-5 pt-2 d-flex justify-content-center">
                <Button variant="primary" className='mx-2 w100' id="submitButton" onClick={handleSubmit}>
                    Submit
                </Button>
                <Button variant="secondary" className='mx-2 w100' onClick={handleReset}>
                    Clear
                </Button>
            </div>
        </>
    )
}

export default ListedStocksLivePrice