/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./Sidebar.css";
import { useNavigate } from 'react-router-dom'
import Bonusmodal from "../stocksFolio/other_transactions/Bonusmodal";
import Splitmodal from "../stocksFolio/other_transactions/Splitmodal";
import RightsShare from "../stocksFolio/other_transactions/RightsShare";
import IPOShare from "../stocksFolio/other_transactions/IPOShare";
import Partlypaid from "../stocksFolio/other_transactions/partlypaid";
import BuyBack from "../stocksFolio/other_transactions/BuyBack";
import Demerger from "../stocksFolio/other_transactions/Demerger"
import NewBuy from "../stocksFolio/other_transactions/NewBuy";
import StockMergermodal from "../stocksFolio/other_transactions/StockMergermodal";
import Convertible from "../stocksFolio/other_transactions/Convertible";
import Reedemable from "../stocksFolio/other_transactions/Reedemable";

// import { useNavigate } from 'react-router-dom';


const Stockdropdown = (props) => {

  const { onRefresh, liveApiActive, liveApiDeActive } = props;
  // const navigate = useNavigate();
  const [showdrop, setShow] = useState(false);
  const [splitshowdrop, setSplitshowdrop] = useState(false);
  const [mergershowdrop, setMergershowdrop] = useState(false);
  const [rightsShowdrop, setRightsShowdrop] = useState(false);
  const [IPOShowdrop, setIPOShowdrop] = useState(false);
  const [partShowdrop, setPartShowdrop] = useState(false);
  const [demergerShowDrop, setDemergerShowDrop] = useState(false);
  const [buyBackdrop, setBuybackShowdrop] = useState(false);
  const [newBuydrop, setNewBuydrop] = useState(false);
  const [convertible, setConvertible] = useState(false);
  const [Redeemable, setRedeemable] = useState(false);
  const [IPOAlShowdrop, setIPOAlShowdrop] = useState(false);

  const navigate = useNavigate()
  const BonusHandlechange = () => {
    setShow(true);
    liveApiActive()
  }
  const SplitHandlechange = () => {
    setSplitshowdrop(true);
    liveApiActive()
  }
  const StockmergerHandlechange = () => {
    setMergershowdrop(true);
    liveApiActive();
  }
  const rightHandlechange = () => {
    setRightsShowdrop(true);
    liveApiActive();
  }
  const IPOHandlechange = () => {
    setIPOShowdrop(true);
    liveApiActive();
  }

  // const IPOAlHandleChange = () => {
  //   setIPOAlShowdrop(true)
  // }

  const partHandlechange = () => {
    setPartShowdrop(true);
    liveApiActive();
  }

  const demergerHandleChange = () => {
    setDemergerShowDrop(true);
    liveApiActive();
  }

  const newBUyHandleChange = () => {
    setNewBuydrop(true);
    liveApiActive();
  }
  const buyBackHandleChange = () => {
    setBuybackShowdrop(true)
    liveApiActive();
  }

  const ConvHandlechange = () => {
    setConvertible(true);
    liveApiActive();
  }
  const RedeemHandlechange = () => {
    setRedeemable(true);
    liveApiActive();
  }



  const handleclose = async () => {
    //debugger
    setShow(false);
    setSplitshowdrop(false);
    setMergershowdrop(false);
    setRightsShowdrop(false);
    setIPOShowdrop(false);
    setPartShowdrop(false);
    setDemergerShowDrop(false);
    setNewBuydrop(false);
    setBuybackShowdrop(false);
    setConvertible(false);
    setRedeemable(false);
    setIPOAlShowdrop(false);
    await onRefresh();
    liveApiDeActive()
  }
  return (
    <>
      <div className="dropdown">
        <div className="dropbtn">Other Transactions</div>
        <div className="dropdown-content other-trans">
          <li onClick={BonusHandlechange}>Bonus</li>
          <li onClick={SplitHandlechange}>Split</li>
          <li onClick={StockmergerHandlechange}>Stock Merger</li>
          <li onClick={rightHandlechange}>Right Share</li>

          <div className="dropdownss2 mt-3">
            <div className="dropbtn" style={{ color: "black" }}>IPO</div>
            <div className="dropdown-contentss2 ">
              <li onClick={IPOHandlechange}>Add IPO</li>
              <li onClick={() => navigate("/AllotedIPOs")}>Alloted IPOs</li>
            </div>
          </div>

          <li onClick={buyBackHandleChange}>Buyback</li>
          <li onClick={demergerHandleChange}>Demerger</li>
          <div className="dropdownss">
            <div className="dropbtn mt-3" style={{ color: "black" }}>Partly Paid</div>
            <div className="dropdown-contentss">
              <li onClick={newBUyHandleChange}>Buy</li>
              <li onClick={partHandlechange}>Installment/Final</li>
            </div>
          </div>
          <div className="dropdownss1">
            <div className="dropbtn" style={{ color: "black" }}>Preference Share</div>
            <div className="dropdown-contentss1">
              <li onClick={RedeemHandlechange}>Redeem</li>
              <li onClick={ConvHandlechange}>Convert</li>
            </div>
          </div>

        </div>
      </div>
      <Bonusmodal showB={showdrop} onHideB={handleclose} onRefresh={() => onRefresh()} />
      <Splitmodal showS={splitshowdrop} onHideS={handleclose} onRefresh={() => onRefresh()} />
      <RightsShare showR={rightsShowdrop} onHideR={handleclose} onRefresh={() => onRefresh()} />
      <StockMergermodal showM={mergershowdrop} onHideM={handleclose} onRefresh={() => onRefresh()} />
      <IPOShare showI={IPOShowdrop} onHideI={handleclose} onRefresh={() => onRefresh()} />
      {/* <IPOAlloted showIA={IPOAlShowdrop} onHideIA={handleclose} onRefresh={() => onRefresh()} /> */}
      <BuyBack showBuy={buyBackdrop} onHideBuy={handleclose} onRefresh={() => onRefresh()} />
      <Partlypaid showP={partShowdrop} onHideP={handleclose} onRefresh={() => onRefresh()} />
      <Demerger showD={demergerShowDrop} onHideD={handleclose} onRefresh={() => onRefresh()} />
      <NewBuy showN={newBuydrop} onHideN={handleclose} onRefresh={() => onRefresh()} />
      <Convertible showUPC={convertible} onHideUPC={handleclose} onRefresh={() => onRefresh()} />
      <Reedemable showUPR={Redeemable} onHideUPR={handleclose} onRefresh={() => onRefresh()} />

    </>
  )
}
export default Stockdropdown;