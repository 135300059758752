/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { GetDividendFrequency } from "../../../pages/Stocks/StocksAPI";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import Loader from "../../loadingAnimation/index";
import Dropdown from '../../CommonComponent/Dropdown';
import { AddInsurance, updateInsurance, AddSubPolicy, getSubPolicy } from "../../../pages/Insurance/InsuranceAPI"
import { PolicyType, periodList } from '../../../modules/helpers/constant'
import InsuranceMaturity from './InsuranceMaturity';
import InsurancePremium from "./InsurancePremium";
import useEnterKeyListener from "../../ShortcutKey/useKeyListener";
import Cleave from 'cleave.js/react';
import { Tooltip } from "@mui/material";

const Insurances = (props) => {
    const { insuranceId, insuranceTransaction, show1, entityId } = props;

    const [company, setCompany] = useState({ company: "" });
    const [selPolicyType, setSelPolicyType] = useState([]);
    const [subPolicyList, setsubPolicyList] = useState([]);
    const [selsubPolicyType, setSubPolicyType] = useState([]);

    const [scheme, setScheme] = useState({ scheme: "" });
    const [policyNumber, setPolicyNumber] = useState({ policyNumber: "" });
    const [insuredName, setInsuredName] = useState({ insuredName: "" });
    const [sumAssured, setSumAssured] = useState({ sumAssured: "" });
    const [premium, setPremium] = useState({ premium: "" });

    const [selFrequency, setSelFrequency] = useState([]);
    const [dividendfrequency, setDividendfrequency] = useState([]);
    const [frequencyValue, setFrequencyValue] = useState([]);

    const [tenurePeriod, setTenurePeriod] = useState("");
    const [tenure, setTenure] = useState([{ label: "Month", value: "Month" }]);
    const [tenureValue, setTenureValue] = useState("Month");

    const [sDate, setSDate] = useState(null);
    const [totalpremium, setTotalPremium] = useState({ totalpremium: "" })
    const [bonusAcc, setBonusAcc] = useState({ bonusAcc: "" })
    const [surreValue, setSurreValue] = useState({ surreValue: "" })
    const [pDate, setPDate] = useState(null);
    const [mDate, setMDate] = useState(null);
    const [maturityVal, setMaturityVal] = useState({ MaturityValue: "" });

    const [lockinperiod, setLockinperiod] = useState("")
    const [lockPeriod, setLockPeriod] = useState([{ label: "Month", value: "Month" }]);
    const [lockPeriodValue, setLockPeriodValue] = useState("Month");

    const [nominees, setNominees] = useState({ nominees: "" })
    const [narration, setNarration] = useState(null)

    const [loading, setLoading] = useState(false);
    const [formErr, setFormErr] = useState([]);
    const [subAccountId, setSubAccountId] = useState(0)
    const [companyId, setCompanyId] = useState(0);
    const [IShowMaturity, setIShowMaturity] = useState(false);
    const [IShowPremium, setIShowPremium] = useState(false);
    const [showSubtype, setshowSubtype] = useState(false);
    const [AselPolicyType, setASelPolicyType] = useState([]);
    const [AsubtypePolicy, setAsubtypePolicy] = useState('');
    const [AformErr, setAFormErr] = useState([]);

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return null;
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    useEnterKeyListener({
        querySelectorToExecuteClick: "#submitButton"
       });
    

    useEffect(() => {
        getDividedFrequencyList();
    }, [])

    const getSubpolicyList = (id) => {
        setLoading(true)
        getSubPolicy().then((res) => {
            setLoading(false);
            if (res.data._response.status === 1) {
                let result = res.data._response.data.policyTypesDetailsDetails;
                let data = [];

                result && result.map((x) => {
                    if (x.parentId === id) {
                        data.push({ label: x.policyTypeName, value: x.policyId })
                    }
                    setsubPolicyList(data);
                })
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err, "err in get")
        })
    }
    //getdividend list
    const getDividedFrequencyList = async () => {
        await GetDividendFrequency()
            .then((res) => {

                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        let dividend = res.data._response.data.dividendfrequencyDetails;
                        let dividendData = [];
                        dividend &&
                            dividend.map((data) => {
                                dividendData.push({
                                    value: data.frequencyId,
                                    label: data.frequencyName,
                                });
                            });
                        setDividendfrequency(dividendData);
                        if (insuranceId === 0) {
                            setSelFrequency(dividendData[1]);
                        }
                    }
                } else {
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                // setError(e.message);
            });
    };

    //handlecompnay/bank
    const handleValueC = (e) => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
        setFormErr({ ...formErr, company: "" })
    }

    //handle policy type
    const handlePolicyType = (selected) => {
        setsubPolicyList([]);
        setSubPolicyType([]);
        setSelPolicyType(selected);
        getSubpolicyList(selected.value);
        setFormErr({ ...formErr, selPolicyType: "" })
    }
    //handle subpolicytype
    const handlesubPolicyType = (selected) => {
        setSubPolicyType(selected);
        setFormErr({ ...formErr, selsubPolicyType: "" })
    }
    //handle scheme
    const handleValueScheme = (e) => {
        const { name, value } = e.target;
        setScheme({ ...scheme, [name]: value });
    }
    //handle policynumber
    const handleValuePolicyNo = (e) => {
        const { name, value } = e.target;
        setPolicyNumber({ ...policyNumber, [name]: value });
        setFormErr({ ...formErr, policyNumber: "" })
    }
    //handle insured name
    const handleValueInsured = (e) => {
        const { name, value } = e.target;
        setInsuredName({ ...insuredName, [name]: value });
        setFormErr({ ...formErr, insuredName: "" })
    }
    //handle sum assured
    const handleValueSumA = (e) => {
        const { name, rawValue } = e.target;
        setSumAssured({ ...sumAssured, [name]: rawValue });
        setFormErr({ ...formErr, sumAssured: '' })
    }
    //handlepremium
    const handleValuepremium = (e) => {
        const { name, rawValue } = e.target;
        setPremium({ ...premium, [name]: rawValue });
        setFormErr({ ...formErr, premium: '' })
    }
    //handlepremiumfrequency
    const handleFrequency = (selected) => {
        setSelFrequency(selected);
        setFrequencyValue(selected.value)
        setFormErr({ ...formErr, selFrequency: "" })
    }
    //handletenure
    const HandleChangeTenure = (e) => {
        setTenure(e);
        setTenureValue(e.value);
        setFormErr({ ...formErr, tenureValue: '' })
    }
    //handlestartdate
    const handleStartDate = (sDate) => {
        setSDate(sDate);
        setFormErr({ ...formErr, sDate: '' })
    }
    //handletotalpremium
    const handleValuetotalpremium = (e) => {
        const { name, rawValue } = e.target;
        setTotalPremium({ ...premium, [name]: rawValue });
    }
    //handlebonusAcc
    const handleValuebonusAcc = (e) => {
        const { name, rawValue } = e.target;
        setBonusAcc({ ...bonusAcc, [name]: rawValue });
    }
    const handleValueSurreValue = (e) => {
        const { name, rawValue } = e.target;
        setSurreValue({ ...surreValue, [name]: rawValue });
    }
    //handlelastpremiumdate
    const handleLastPremDate = (pDate) => {
        setPDate(pDate);
    }
    //handlematurityDate
    const handleDateM = (mDate) => {
        setMDate(mDate);
        setFormErr({ ...formErr, date: "" });
    }
    //handlematurityvalue
    const handleValueM = (e) => {
        const { name, rawValue } = e.target;
        setMaturityVal({ ...maturityVal, [name]: rawValue });
        setFormErr({ ...formErr, MaturityValue: "" })
    }
    //handlelockinperiod
    const HandleChangePeriod = (e) => {
        setLockPeriod(e);
        setLockPeriodValue(e.value);
        setFormErr({ ...formErr, lockPeriodValue: '' })
    }
    //handlenominees
    const handleValueNominees = (e) => {
        const { name, value } = e.target;
        setNominees({ ...nominees, [name]: value });
    }
    //handlenarration
    const handleNarration = (e) => {
        setNarration(e.target.value)
    }
    //errorhandling
    const errHandel = () => {
        let formErr = {};
        let isValid = true;
        if (company.company === "" || company.company.trim() === "") {
            isValid = false;
            formErr["company"] = "Please enter company!"
        }
        if (selPolicyType.length < 1) {
            isValid = false;
            formErr["selPolicyType"] = "Please select policy type!"
        }
        if (selsubPolicyType.length < 1) {
            isValid = false;
            formErr["selsubPolicyType"] = "Please select policy sub type!"
        }
        if (policyNumber.policyNumber === "" || policyNumber.policyNumber.trim() === "") {
            isValid = false;
            formErr["policyNumber"] = "Please enter policy number!"
        }
        if (insuredName.insuredName === "" || insuredName.insuredName.trim() === "") {
            isValid = false;
            formErr["insuredName"] = "Please enter insured name!"
        }
        if (!sumAssured.sumAssured) {
            isValid = false;
            formErr["sumAssured"] = "Please enter sumAssured!"
        }
        if (!premium.premium) {
            isValid = false;
            formErr["premium"] = "Please enter premium!"
        }
        if (selFrequency.length < 1) {
            isValid = false;
            formErr["selFrequency"] = "Please select premium frequency!"
        }
        if (!sDate) {
            isValid = false;
            formErr["sDate"] = "Please select start date!"
        }
        setFormErr(formErr);
        return isValid;
    }
    // error handling for Add subpolicy
    const AerrHandel = () => {
        let formErr = {};
        let isValid = true;
        if (AselPolicyType.length < 1) {
            isValid = false;
            formErr["AselPolicyType"] = "Please select policy type!"
        }
        if (AsubtypePolicy === "" || AsubtypePolicy.trim() === "") {
            isValid = false;
            formErr["AsubtypePolicy"] = "Please Enter Sub-Policy Name!"
        }
        setAFormErr(formErr);
        return isValid;
    }
    const handleCloseSubtype = () => {
        setshowSubtype(false);
        AhandleReset();
    };
    const handleshowSubtype = () => setshowSubtype(!showSubtype);
    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <span className="m-0" title="Add Sub Type" onMouseDown={handleshowSubtype}>
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    );
    const AhandleReset = () => {
        setSelPolicyType([]);
        setsubPolicyList([]);
        setSubPolicyType([]);
        setASelPolicyType([]);
        setAsubtypePolicy('');
        setAFormErr([]);
    }
    const handleAPolicyType = (selected) => {
        setASelPolicyType(selected);
        setAFormErr({ ...AformErr, AselPolicyType: "" })
    }
    const handleAsubtypePolicy = (e) => {
        setAsubtypePolicy(e.target.value);
        setAFormErr({ ...AformErr, AsubtypePolicy: "" })
    }
    const handleSubPolicySubmit = () => {
        const isValid = AerrHandel();
        if (isValid === true) {
            setLoading(true);
            let data = {
                parentId: AselPolicyType.value,
                policyTypeName: AsubtypePolicy
            }
            AddSubPolicy(data).then((res) => {
                setLoading(false);
                if (res.data._response.status === 1) {
                    Swal.fire("Policy SubType Added Successfully.", "", "success")
                    AhandleReset();
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err, "err in submit subpolicy")
            })
        }
    }
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const InsuranceData = {
                subAccountId: entityId,
                // instrumentTypeId:0,
                transactStockType: "Insurance",
                companyName: company.company,
                policyTypeId: selPolicyType.value,
                policySubtypeId: selsubPolicyType.value,
                scheme: scheme.scheme,
                policyNumber: policyNumber.policyNumber,
                nameOfInsured: insuredName.insuredName,
                sumAssured: sumAssured.sumAssured,
                premiumAmount: premium.premium,
                frequencyId: selFrequency.value,
                tenure: tenurePeriod ? tenurePeriod + tenureValue : "0" + tenureValue,
                startDate: fixTimezoneOffset(sDate),
                totalPremiumPaid: totalpremium.totalpremium ? totalpremium.totalpremium : "0",
                bonusAccrued: bonusAcc.bonusAcc ? bonusAcc.bonusAcc : "0",
                surrenderValue: surreValue.surreValue ? surreValue.surreValue : "0",
                lastPremiumDate: fixTimezoneOffset(pDate),
                maturityDate: fixTimezoneOffset(mDate),
                maturityValue: maturityVal.MaturityValue ? maturityVal.MaturityValue : "0",
                lockinPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                nominees: nominees.nominees,
                annualPremium: selFrequency.value === 1 ? 12 * premium.premium : selFrequency.value === 2 ? 4 * premium.premium :
                    selFrequency.value === 3 ? 2 * premium.premium : selFrequency.value === 4 ? 1 * premium.premium : null,
                narration: narration
            }
            AddInsurance(InsuranceData).then((res) => {

                if (res.status === 200) {
                    setLoading(false)
                    if (res.data._response.status === 1) {
                        Swal.fire("Insurance Added Successfully.", "", "success");
                        handleReset();
                    } else {
                        Swal.fire(res.data._response.message, "", "error")
                    }
                } else {
                    setLoading(false);
                    console.log("error")
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })

        }
    }

    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true)
            const InsuranceData = {
                insuranceId: insuranceId,
                subAccountId: subAccountId,
                companyId: companyId,
                transactStockType: "Insurance",
                policyTypeId: selPolicyType.value,
                policySubtypeId: selsubPolicyType.value,
                scheme: scheme.scheme,
                policyNumber: policyNumber.policyNumber,
                nameOfInsured: insuredName.insuredName,
                sumAssured: sumAssured.sumAssured,
                premiumAmount: premium.premium,
                frequencyId: selFrequency.value,
                tenure: tenurePeriod ? tenurePeriod + tenureValue : "0" + tenureValue,
                startDate: fixTimezoneOffset(sDate),
                totalPremiumPaid: totalpremium.totalpremium,
                bonusAccrued: bonusAcc.bonusAcc,
                surrenderValue: surreValue.surreValue,
                lastPremiumDate: fixTimezoneOffset(pDate),
                maturityDate: fixTimezoneOffset(mDate),
                maturityValue: maturityVal.MaturityValue,
                lockinPeriod: lockinperiod === "" ? "0" + lockPeriodValue : lockinperiod + lockPeriodValue,
                nominees: nominees.nominees,
                annualPremium: selFrequency.value === 1 ? 12 * premium.premium : selFrequency.value === 2 ? 4 * premium.premium :
                    selFrequency.value === 3 ? 2 * premium.premium : selFrequency.value === 4 ? 1 * premium.premium : null,
                narration: narration
            }

            updateInsurance(InsuranceData).then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("Success", "Insurance Updated Successfully", "success");
                    }
                } else {
                    setLoading(false);
                    console.log("error");
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
    }
    //get insurance data
    useEffect(() => {
        if (insuranceId !== 0) {
            if (insuranceTransaction !== undefined) {
                setCompanyId(insuranceTransaction.companyId)
                setSubAccountId(insuranceTransaction.subAccountId)
                setCompany({ company: insuranceTransaction.company.companyName })
                setSelPolicyType({ label: insuranceTransaction.policyType.policyTypeName, value: insuranceTransaction.policyTypeId })
                setSubPolicyType({ label: insuranceTransaction.policySubtype.policyTypeName, value: insuranceTransaction.policySubtypeId })
                setScheme({ scheme: insuranceTransaction.scheme })
                setPolicyNumber({ policyNumber: insuranceTransaction.policyNumber })
                setInsuredName({ insuredName: insuranceTransaction.nameOfInsured })
                setSumAssured({ sumAssured: insuranceTransaction.sumAssured })
                setPremium({ premium: insuranceTransaction.premiumAmount })
                setSelFrequency({ label: insuranceTransaction.frequency.frequencyName, value: insuranceTransaction.frequencyId })
                let d = insuranceTransaction.tenure
                let splitData = d.split(/([0-9]+)/);
                setTenurePeriod(splitData[1]);
                setTenureValue(splitData[2]);
                setTenure({ label: splitData[2], value: splitData[2] });
                setSDate(new Date(insuranceTransaction.startDate));
                setTotalPremium({ totalpremium: insuranceTransaction.totalPremiumPaid })
                setBonusAcc({ bonusAcc: insuranceTransaction.bonusAccrued })
                setSurreValue({ surreValue: insuranceTransaction.surrenderValue })
                setPDate(insuranceTransaction.lastPremiumDate ? new Date(insuranceTransaction.lastPremiumDate) : null)
                setMDate(new Date(insuranceTransaction.maturityDate))
                setMaturityVal({ MaturityValue: insuranceTransaction.maturityValue })
                let l = insuranceTransaction.lockinPeriod
                let splitDatalock = l.split(/([0-9]+)/);
                setLockinperiod(splitDatalock[1]);
                setLockPeriodValue(splitDatalock[2]);
                setLockPeriod({ label: splitDatalock[2], value: splitDatalock[2] });
                setNominees({ nominees: insuranceTransaction.nominees });
                setNarration(insuranceTransaction.narration)
            }
        }
    }, [insuranceTransaction])

    const handleShowmaturity = () => {
        setIShowMaturity(true);
    }
    const handleCloseMaturity = () => {
        setIShowMaturity(false);
    }
    const handleShowPremium = () => {
        setIShowPremium(true);
    }
    const handleClosePremium = () => {
        setIShowPremium(false);
    }
    const disableButton = () => {
        return company && selPolicyType && policyNumber && insuredName &&
            sumAssured && premium && selFrequency && sDate && mDate
    }
    const handleReset = () => {
        setCompany({ company: "" });
        setSelPolicyType([]);
        setsubPolicyList([]);
        setSubPolicyType([]);
        setScheme({ scheme: "" });
        setPolicyNumber({ policyNumber: "" });
        setInsuredName({ insuredName: "" });
        setSumAssured({ sumAssured: "" });
        setPremium({ premium: "" });
        setSelFrequency([]);
        setTenurePeriod("")
        setSDate(null);
        setTotalPremium({ totalpremium: "" })
        setBonusAcc({ bonusAcc: "" })
        setSurreValue({ surreValue: "" })
        setPDate(null)
        setMDate(null);
        setMaturityVal({ MaturityValue: "" })
        setLockinperiod("")
        setLockPeriod("");
        setNominees({ nominees: "" })
        setNarration("")
        setFormErr([]);
    }
    return (
        <>
            {show1 === true ?
                <>
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="p-3">
                            <Row className="form-set">
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Insurance Company<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        min="0"
                                        value={company.company}
                                        onChange={handleValueC}
                                        autoComplete="off"
                                        disabled={insuranceId === 0 ? false : true}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.company}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Type of Policy<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selPolicyType}
                                        onChange={handlePolicyType}
                                        options={PolicyType}
                                        name="policyTypeId"
                                        placeholderText="Select Policy Type"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selPolicyType}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Sub Type of Policy<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selsubPolicyType}
                                        components={{ Control }}
                                        onChange={handlesubPolicyType}
                                        options={subPolicyList}
                                        name="subpolicyTypeID"
                                        placeholderText="Select Policy Sub Type"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selsubPolicyType}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Scheme</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="scheme"
                                        min="0"
                                        value={scheme.scheme}
                                        onChange={handleValueScheme}
                                        autoComplete="off"

                                    />
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Policy Number<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleValuePolicyNo}
                                        name="policyNumber"
                                        min="0"
                                        value={policyNumber.policyNumber}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.policyNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Name of the Insured<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="insuredName"
                                        min="0"
                                        value={insuredName.insuredName}
                                        onChange={handleValueInsured}
                                        autoComplete="off"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.insuredName}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Sum Assured<span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="sumAssured"
                                        min="0"
                                        value={sumAssured.sumAssured}
                                        onChange={handleValueSumA}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="sumAssured"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={sumAssured.sumAssured}
                                        onChange={handleValueSumA}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.sumAssured}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Premium Amount <span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="premium"
                                        min="0"
                                        value={premium.premium}
                                        onChange={handleValuepremium}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="premium"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={premium.premium}
                                        onChange={handleValuepremium}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.premium}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Premium Frequency<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selFrequency}
                                        onChange={handleFrequency}
                                        options={dividendfrequency}
                                        name="frequencyId"
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selFrequency}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Col md="3" className="mb-3">
                                    <Form.Label className="d-block">Tenure</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setTenurePeriod(e.target.value)}
                                        autoComplete="off"
                                        value={tenurePeriod}
                                        name="tenure" id="tenure" className="lockinperiodno" />
                                    <Dropdown
                                        value={tenure}
                                        name='tenure'
                                        options={periodList}
                                        onChange={HandleChangeTenure}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"
                                    />
                                </Col>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Start Date<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        onChange={handleStartDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={sDate}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.sDate}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Total Premium Paid</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="totalpremium"
                                        min="0"
                                        value={totalpremium.totalpremium}
                                        onChange={handleValuetotalpremium}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="totalpremium"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={totalpremium.totalpremium}
                                        onChange={handleValuetotalpremium}
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="form-set">
                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Bonus Accrued</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="bonusAcc"
                                        min="0"
                                        value={bonusAcc.bonusAcc}
                                        onChange={handleValuebonusAcc}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="bonusAcc"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={bonusAcc.bonusAcc}
                                        onChange={handleValuebonusAcc}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Surrender Value</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="surreValue"
                                        min="0"
                                        value={surreValue.surreValue}
                                        onChange={handleValueSurreValue}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="surreValue"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={surreValue.surreValue}
                                        onChange={handleValueSurreValue}
                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Date of Last Premium</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Last Premium Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(sDate)}
                                        onChange={handleLastPremDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={pDate}

                                    />
                                </Form.Group>

                                <Form.Group className="col-md-3">
                                    <Form.Label>Maturity Date<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Select Maturity Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        minDate={new Date(sDate)}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={handleDateM}
                                        name="domConversion"
                                        autoComplete="off"
                                        selected={mDate}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.mDate}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group className="col-md-3">
                                    <Form.Label>Maturity Value</Form.Label>
                                    {/* <Form.Control
                                        type="Number"
                                        name="MaturityValue"
                                        min="0"
                                        value={maturityVal.MaturityValue}
                                        onChange={handleValueM}
                                    /> */}

                                    <Cleave
                                        className='form-control'
                                        name="MaturityValue"
                                        autoComplete="off"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                        value={maturityVal.MaturityValue}
                                        onChange={handleValueM}
                                    />
                                </Form.Group>

                                <Col md="3" className="mb-3">
                                    <Form.Label className="d-block">Lock in Period</Form.Label>
                                    <Form.Control type="Number"
                                        onChange={(e) => setLockinperiod(e.target.value)}
                                        autoComplete="off"
                                        value={lockinperiod}
                                        name="lockinperiod" id="lockinperiod" className="lockinperiodno" />
                                    <Dropdown
                                        value={lockPeriod}
                                        name='lockinperiod'
                                        options={periodList}
                                        onChange={HandleChangePeriod}
                                        className="SelectClass lockinperiodlist"
                                        searchable={true}
                                        clearable={true}
                                        noResultsText={"No result"}
                                        placeholder="Month"
                                    />
                                </Col>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Nominees</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nominees"
                                        min="0"
                                        value={nominees.nominees}
                                        onChange={handleValueNominees}
                                        autoComplete="off"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-6 col-md-3">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        onChange={handleNarration}
                                        min="0"
                                        value={narration}
                                        autoComplete="off"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {
                                        insuranceId === 0 ?

                                            <>
                                                <Tooltip className = "btnTooltip" title={ disableButton() ? "" : <h6>Please fill all mandatory fields.</h6>} placement="bottom" aria-label="info" arrow>
                                                    <span style={{ cursor: !disableButton() ? 'not-allowed' : 'pointer' }}>
                                                        <Button variant="primary" className="mx-2 w100"
                                                            id = "submitButton"
                                                            onClick={(e) => { e.preventDefault(); handleSubmit(e) }}
                                                            disabled={!disableButton()}
                                                        >Submit</Button>
                                                    </span>
                                                </Tooltip>
                                                <Button
                                                    variant="secondary"
                                                    className="mx-2 w100"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    Clear
                                                </Button>
                                            </> :
                                            <>
                                                <Button variant="primary" className="mx-2 w100"
                                                    onClick={handleUpdate}
                                                    id = "submitButton"
                                                    disabled={!disableButton()}
                                                >Update</Button>
                                                <Button variant="primary" className="mx-2 w100"
                                                    onClick={handleShowPremium}
                                                >Premium</Button>
                                                <Button variant="primary" className="mx-2 w100"
                                                    onClick={handleShowmaturity}
                                                >Maturity</Button>
                                            </>
                                    }

                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <InsuranceMaturity tabIndex={2} showIMaturity={IShowMaturity} OnhideImaturity={handleCloseMaturity} Fname={"Insurance Maturity"} InsuranceId={insuranceId} matDate={mDate} />
                    <InsurancePremium tabIndex={2} showIPremium={IShowPremium} OnhideIpremium={handleClosePremium} Fname={"Insurance Premium"} InvestDate={sDate} InsuranceId={insuranceId} />
                </>
                : null}
            <Modal show={showSubtype} onHide={handleCloseSubtype} size="sm" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title>Add Sub-Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-2 p-6">
                        <Form>
                            <Row className="form-set">

                                <Form.Group className="col-md-12">
                                    <Form.Label>Type of Policy<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={AselPolicyType}
                                        onChange={handleAPolicyType}
                                        options={PolicyType}
                                        name="ApolicyType"
                                        placeholderText="Select Policy Type"
                                    />
                                    {AformErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {AformErr.AselPolicyType}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-12">
                                    <Form.Label>Sub-Type of Policy<span className="text-danger h6">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sub-policy"
                                        value={AsubtypePolicy}
                                        autoComplete="off"
                                        onChange={handleAsubtypePolicy}
                                    />
                                    {AformErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {AformErr.AsubtypePolicy}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    <Button
                                        variant="primary"
                                        className="mx-2 w100"
                                        onClick={handleSubPolicySubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        className="mx-2 w100"
                                        onClick={AhandleReset}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Insurances;