import React, { useState } from 'react';
import { Form, Button, Row  , Col} from "react-bootstrap";
import Swal from "sweetalert2";
import axios from '../../modules/helpers/axiosInstance';



const LivePriceLogin = () => {
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false)

    const handletoken = (e) => {
        const { value } = e.target;
        setToken(value);
    }
    const handleSubmit = () => {
        setLoading(true)
        axios.get(`http://5.189.157.40:10021/api/KiteConnect/FetchRequestToken?requestToken=${token}`)
            .then(res => {
                setLoading(false)
                setToken('');
                Swal.fire("Token Added Successfully!", "", "success");

            })
            .catch(ex => {
                setLoading(false)
                Swal.fire("Please Try Again!", "", "info");
            })
    }
    return (
        <>
            <div className="mt-8 p-5 ">
                <h4 className="text-center mb-0">Live Price</h4><br></br>
                <Form className="border border-1 rounded p-4" id="tokenborder">
                    <Form.Group className="col-md-12 mb-3">
                        <Form.Label>Token</Form.Label><br></br>
                        <Form.Control
                            type="text"
                            placeholder="Enter your token..."
                            name="token"
                            onChange={handletoken}
                            value={token}
                            autoComplete='off' />
                    </Form.Group>
                    {/* <Button variant="primary" className="button"  onClick={handleSubmit} disabled={loading}>
                        {!loading && (
                            <CircularProgress className="circularProgress" size={20} />
                        )}
                        Submit
                    </Button> */}
                    <Row >
                        <Col md="12" className="text-center mt-2">
                            <Button variant="primary" style={{ width: "8rem" }} className="button" onClick={handleSubmit} disabled={loading}>
                                {loading && <span style={{ marginLeft: "-2rem", position: 'absolute' }}> <span className="spinner-border spinner-border-sm p-2 letter-spacing: 0.02857em;" role="status" aria-hidden="true" style={{ paddingLeft: 5 }}></span></span>}
                                Submit
                            </Button>
                        </Col>
                        </Row>
                 

                </Form>
            </div>

        </>
    )
}


export default LivePriceLogin