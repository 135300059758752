import React from "react";
import { useState, useEffect } from "react";
import MaterialTable, { MTableBody } from "material-table";
// import SecondDTable from "./SecondDTable";
import { DashboardInvestments } from '../Dashboard/RecentTranAPI/RT_API'

import {
  TableCell,
  TableRow,
  TableFooter,
} from "@material-ui/core";

function InvestmentTable(props) {

  const [investmenstList, setInvestmenstList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInvestmentTable();
  }, [])

  // function preciseMathDotRound(value, precision = 2) {
  //   let valueData = isFinite(value)

  //   if (valueData) {

  //     return parseFloat(value.toFixed(precision));
  //   } else {
  //     return 0;
  //   }
  // }

  const getInvestmentTable = () => {
    setLoading(true)
    DashboardInvestments().then(res => {
      if (res.status === 200) {
        let investmentData = res.data._response.data.fetchYourInvestmentGridBySubscriberId;
        setInvestmenstList(investmentData)
        setLoading(false)
      }
    }).catch((e) => {
      console.log(e.message);
      setLoading(false)
    })
  };

  //only two decimal places
  const convertTOdecimal = (num) => {

    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const columns = [
    {
      field: "srno", title: "Sr.No.", type: "numeric", width: " 1%",
      render: (rowData) => (
        <span>{rowData.tableData.id + 1}</span>
      )
    },
    { title: "Particulars", field: "subAccountName", },

    {
      title: "Investments at Cost",
      field: "totalInvestmentAtCost",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.totalInvestmentAtCost)}</span>
      )
    },
    {
      title: "Investments at Market Price", field: "totalInvestmentAtMP", type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.totalInvestmentAtMP))}</span>
      )
    },
    {
      title: "Networth at Cost",
      field: "netWorthAtCost",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.netWorthAtCost)}</span>
      )
    },
    {
      title: "Networth at Market Price",
      field: "netWorthAtMP",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.netWorthAtMP))}</span>
      )
    },
    {
      title: "% Gain Networth",
      field: "gainNetWorth",
      type: "numeric",
      render: (rowData) => (
        <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(rowData.gainNetWorth))}</span>
      )
    },
  ];


  return (
    <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">

      <MaterialTable
        title="Your Investments"
        data={investmenstList}
        columns={columns}
        options={{

          exportButton: false,
          search: false,
          title: false,
          paging: false,
          pageSize: 7,
        }}
        isLoading={loading}
        components={{
          Body: (props) => {


            let totalData = {
              investmentCost: 0,
              investmentCostM: 0,
              netWorthCost: 0,
              netWorthCostM: 0,
              gainNetWorth: 0
            }

            props.renderData.forEach((rowData) => {
              totalData.investmentCost += rowData.totalInvestmentAtCost;
              totalData.investmentCostM += rowData.totalInvestmentAtMP;
              totalData.netWorthCost += rowData.netWorthAtCost;
              totalData.netWorthCostM += rowData.netWorthAtMP;
              totalData.gainNetWorth = ((totalData.investmentCostM - totalData.investmentCost) / totalData.investmentCost) * 100;
            });


            return (
              <>
                <MTableBody {...props} />
                {

                  <TableFooter style={{ backgroundColor: "#D9E5F2 " }}>
                    <TableRow>
                      <TableCell ></TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="left">Total</TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.investmentCostM))}</TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.netWorthCost)}</TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.netWorthCostM))}</TableCell>
                      <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(convertTOdecimal(totalData.gainNetWorth))}</TableCell>

                    </TableRow>
                  </TableFooter>

                }
              </>
            )

          },

        }}
      />
    </div>
  );
}

export default InvestmentTable;
