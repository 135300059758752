import axios from '../../modules/helpers/axiosInstance';
import Auth from '../../modules/Auth/index';
const userID = Auth.getUserId();

// let localSubscriptionUser = "https://localhost:5001/api/UserManagment";
let liveSubscriptionUser = "http://5.189.157.40:10023/api/UserManagment";

let subscriptionsUrlLive = "http://5.189.157.40:10030" ;
// let subscriptionsUrlLocal = "https://localhost:5004" ;


/* admin page api started  */
// to add new plan
const addnewPlans = (data) => {
    return axios({
        method: 'POST',
        url: `${liveSubscriptionUser}/AddPlans`,
        data: data
    })
};

// update and edit plans
const updatePlans = async (data) => {
    return axios({
        method: "PUT",
        url: `${liveSubscriptionUser}/UpdatePlans`,
        // url: `${settings.API_URL}/UserManagment/UpdateCompaniesList`,
        data: data,
    });
};

// delete plan from list
const deletePlan = async (planId) => {
    return axios({
        method: 'DELETE',
        url: `${liveSubscriptionUser}/DeleteSubscriptionPlansById?id=${planId}`,
    })
};
// list of features
const getFeatures = () => {
    return axios({
        method: 'GET',
        url: `${liveSubscriptionUser}/FetchFeatures`,
    })
};

// get plans by Id
const getPlans = (planId) => {
    return axios({
        method: 'GET',
        url: `${liveSubscriptionUser}/fetchSubscriptionPlansById?id=${planId}`,
    })
};


/* admin page api ended  */


/* payment api started */
// subscriptions page
const addPayment = async (body) => {
    return axios({
        method: 'POST',
        url: `${subscriptionsUrlLive}/Payment`,
        data: body
    })
};

// post subscrption call
const addPlans = async (body) => {
    return axios({
        method: 'POST',
        url: `${subscriptionsUrlLive}/AddSubscriptionDetails`,
        data: body
    })
};

// handler call razor pay
const afterPay = async (body) => {
    return axios({
        method: 'POST',
        url: `${subscriptionsUrlLive}/AfterPayment`,
        data: body
    })
};

/* payment api started */


// list all plans
const getAllPlans = () => {
    return axios({
        method: 'GET',
        url:`${liveSubscriptionUser}/fetchSubscriptionPlans`,
    })
};

// subscribe user plans
const userPlans = () =>{
    return axios({
        method: 'GET',
        url: `${liveSubscriptionUser}/FetchPlansByUser?userId=${userID}`,
    })
}

// termsNcondition update
const updateTerms = (data) =>{
    return axios({
        method:'PUT',
        url: `http://5.189.157.40:10025/api/Subcriber/UpdateTermsandConditions`,
        data:data
    })
}


export { addnewPlans, getFeatures, updatePlans, getPlans, getAllPlans, deletePlan, addPayment, addPlans, afterPay, userPlans, updateTerms }
