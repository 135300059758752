/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Loader from "../loadingAnimation/index";
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import DatePicker from "react-datepicker";
import { AddArtTransaction, getArtTitle, addArtTitle, updateArtTransaction } from '../../pages/Art/ArtAPI';
import swal from 'sweetalert2';
import Select, { components } from "react-select";
import Cleave from 'cleave.js/react';

const Art_Details = (props) => {
    const { showArt, onHideArt, artHeaderId, entityId, artTransaction } = props;
    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState([]);
    const [titleOption, setTitleOption] = useState([]);
    const [artist, setArtist] = useState('');
    const [date, setDate] = useState(new Date());
    const [investedAmt, setInvestedAmt] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);

    const [formErr, setFormErr] = useState([]);
    const [artModal, setArtModal] = useState(false);
    const [titleErr, setTitleErr] = useState('');
    const [freshTitle, setFreshTitle] = useState('');

  

    useEffect(() => {
        if (showArt && entityId) {
            GetArtTitles();
        }
    }, [showArt])
    useEffect(() => {
        // setSubAccountId(entityId == null ? subAccountId : entityId);
    }, [showArt]);
    useEffect(() => {
        if (artHeaderId !== 0) {
            if (Object.keys(artTransaction).length !== 0) {
                setTitle({ value: artTransaction.artTitleId, label: artTransaction.artTitle.artTitleName });
                setArtist(artTransaction.artist);
                setDate(new Date(artTransaction.dateOfInvestment));
                setInvestedAmt(artTransaction.amountInvested);
                setCurrentPrice(artTransaction.currentMarketPrice);
            }
        }
    }, [artTransaction])

    // for  date fix 
    const fixTimezoneOffset = (date) => {
        if (!date) return "";
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleInvestAmt = (e) => {
        setInvestedAmt(e.target.rawValue)
        setCurrentPrice(e.target.rawValue)
        setFormErr([])
    }
    const handleNewTitle = (e) => {
        const { value } = e.target;
        setFreshTitle(value.trimStart().replace(/[^\a-zA-Z0-9" "]/ig, ''))
        setTitleErr('');
    }

    const GetArtTitles = () => {
        setLoading(true)
        getArtTitle().then((res) => {
            setLoading(false);
            if (res.status === 200) {
                if (res.data._response.status === 1) {
                    const art = res.data._response.data.artTitleList;
                    let artarr = [];
                    art && art.map((a) => {
                      return ( artarr.push({ value: a.artTitleId, label: a.artTitleName }))
                    })
                    setTitleOption(artarr)
                } else {
                    setTitleOption([]);
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                console.log(e, "error in get title option")
            })
    }

    const handleShow = () => setArtModal(!artModal);
    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <span onMouseDownCapture={handleShow} className="m-0" title="Add unlisted scrip">
                <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
            {children}
        </components.Control>
    )

    const submitNewTitle = () => {
        if (!freshTitle || freshTitle === undefined) {
            setTitleErr("Please enter title name !")
        } else {
            let data1 = {
                artTitleName: freshTitle,
                subAccountId: entityId
            }
            setLoading(true);
            addArtTitle(data1).then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        const data = res.data._response.data
                        let options = { label: data.artTitleName, value: data.artTitleId }
                        titleOption.unshift(options)
                        // setAssetOption(data)
                        swal.fire("Success!", "New Title Added Successfully", "success");
                        setFreshTitle('')
                        setTitleErr('')
                        setLoading(false);
                    } else {
                        swal.fire("Info!", "Title already exists", "info");
                        setLoading(false);
                    }
                }
            }).catch((e) => {
                setLoading(false);
                console.log(e, 'error in get title api art');
            })

        }
    }
    const errHandel = () => {
        let formErr = {};

        let isValid = true;
        if (title.value == null || title.value === undefined || title.label === '') {
            isValid = false;
            formErr["title"] = "Please select title !";
        }
        if (!date) {
            isValid = false;
            formErr["dateofinv"] = "Please select date !";
        }
        if (!investedAmt) {
            isValid = false;
            formErr["investedAmt"] = "Please enter amount of invest !";
        }
        if (!currentPrice) {
            isValid = false;
            formErr["currentPrice"] = "Please enter current price !";
        }
        setFormErr(formErr);
        return isValid;
    };
    const handleSubmit = () => {
        let isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const data = {
                artTitleId: title.value,
                artist: artist,
                dateOfInvestment: fixTimezoneOffset(date),
                amountInvested: Number(investedAmt),
                currentMarketPrice: Number(currentPrice),
                subAccountId: entityId,
                transactType: "Buy",
            }
            AddArtTransaction(data)
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false);
                        if (response.data._response.status === 1) {
                            // setSuccess(true);
                            swal.fire("Success!", "Added Successfully", "success");
                            handleClear();
                            // onRefresh();
                            // handleClose();
                        }
                    }
                })
                .catch((error) => {
                    // setLoading(false);
                    swal.fire("Something went wrong !", "", 'error');
                    console.log(error, "error in submit");
                });
        } else {
            console.log('err')
        }
    }
    const handleUpdate = () => {
        let isValid = errHandel();
        setLoading(true)
        if (isValid === true) {
            const data = {
                artId: artTransaction.artId,
                artTitleId: title.value,
                artist: artist,
                dateOfInvestment: fixTimezoneOffset(date),
                amountInvested: Number(investedAmt),
                currentMarketPrice: Number(currentPrice),
                subAccountId: artTransaction.subAccountId,
            }
            updateArtTransaction(data)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data._response.status === 1) {
                            // setSuccess(true);
                            swal.fire("Success!", "Updated Successfully", "success");
                            // handleClear();
                            setLoading(false);
                            // onRefresh();
                            //handleClose();
                        }
                    }
                })
                .catch((error) => {
                    // setLoading(false);
                    swal.fire("Something went wrong !", "", 'error');
                    console.log(error, "error in update");
                    setLoading(false);
                });
        }
    }

    const handleClear = () => {
        setTitle([]);
        setArtist('');
        setDate(new Date());
        setInvestedAmt(0);
        setCurrentPrice(0);
        setFormErr([])
    }
    const handleClose = () => {
        setArtModal(false);
        setTitleErr('');
        handleClear();
        // setpropertyID(0)
    }
    const handleClearData = () => {
        onHideArt();
        handleClear();
    }
   
    // const disableButton = () => {
    //     return title && date && investedAmt && currentPrice
    // }
    return (
        <div tabIndex={0}>
            <Modal className="custom-dialog"
                isOpen={showArt}
                toggle={handleClearData}
                size="xl"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <ModalHeader className="headerBlue" charCode="Y" toggle={() => handleClearData()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                    <h5 className="text-center">Art Transaction</h5>
                </ModalHeader>
                <ModalBody>
                    {/* <Container fluid> */}
                    <div>
                        <form>
                            {loading ? (
                                <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                            ) : null}
                        </form>
                        <fieldset>
                            {/* <div className='mt-2 p-6' > */}
                            <Form className='p-3'>
                                <Row className='form-set'>
                                    <Form.Group className='mb-6 col-md-4'>
                                        <Form.Label>Title<span className="text-danger h6">*</span></Form.Label>
                                        <Select
                                            components={{ Control }}
                                            name="titlename"
                                            value={title}
                                            options={titleOption}
                                            onChange={(selected) => setTitle(selected)}
                                            placeholder="select title"
                                            isDisabled={artHeaderId !== 0}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.title}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group className='mb-6 col-md-4'>
                                        <Form.Label>Artist</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="artist"
                                            placeholder="enter artist name"
                                            value={artist}
                                            onChange={(e) => setArtist(e.target.value)}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-6 col-md-4">
                                        <Form.Label>Date of Investment<span className="text-danger h6">*</span></Form.Label>
                                        <DatePicker
                                            className="form-control"
                                            placeholderText="Select Date"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            // maxDate={new Date()}
                                            popperPlacement="bottom"
                                            // minDate={}
                                            onChange={(date) => setDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            name="dateofinv"
                                            autoComplete="off"
                                            selected={date}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.date}
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                </Row>
                                <Row className="form-set">
                                    <Form.Group className="mb-6 col-md-4">
                                        <Form.Label>Amount Invested<span className="text-danger h6">*</span></Form.Label>
                                        {/* <NumberFormat
                                            // type='text'
                                            className="form-control"
                                            thousandSeparator={","}
                                            thousandsGroupStyle="lakh"
                                            // decimalScale={2}
                                            isNumericString ={true}
                                            // fixedDecimalScale={true}
                                            name="investedAmt"
                                            // min="0"
                                            placeholder='enter amount of invest'
                                            value={investedAmt}
                                            onChange={handleInvestAmt}
                                            autoComplete="off"
                                        /> */}

                                        <Cleave
                                            placeholder='enter amount of invest'
                                            className='form-control'
                                            name="investedAmt"
                                            autoComplete='off'
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={investedAmt}
                                            onChange={handleInvestAmt}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.investedAmt}
                                            </div>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="mb-6 col-md-4">
                                        <Form.Label>Current Market Price<span className="text-danger h6">*</span></Form.Label>
                                        {/* <NumberFormat
                                            // type='text'
                                            className="form-control"
                                            thousandSeparator={","}
                                            thousandsGroupStyle="lakh"
                                            // decimalScale={2}
                                            isNumericString ={true}
                                            name="currentPrice"
                                            // min="0"
                                            placeholder='enter current market price'
                                            value={currentPrice}
                                            onChange={(e) => setCurrentPrice(e.target.value)}
                                            autoComplete="off"
                                        /> */}

                                        <Cleave
                                            placeholder='enter current market price'
                                            className='form-control'
                                            name="currentPrice"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'lakh' }}
                                            value={currentPrice}
                                            onChange={(e) => setCurrentPrice(e.target.rawValue)}
                                        />
                                        {formErr ? (
                                            <div
                                                className="mt-2"
                                                style={{ fontSize: 12, color: "red" }}
                                            >
                                                {formErr.currentPrice}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Row>
                                <Row className="form-set">
                                    <Col md="12" className="text-center mt-4">
                                        {artHeaderId === 0 ?
                                            <Button variant="primary" className="mx-2 w100"
                                               id = "submitButton"
                                                onClick={handleSubmit}
                                            //   disabled={!disableButton()}
                                            >Submit</Button>
                                            :
                                            <Button variant="primary" className="mx-2 w100"
                                            id ="submitButton"
                                                onClick={handleUpdate}
                                            //   disabled={!disableButton()}
                                            >Update</Button>}
                                        <Button variant="secondary" className="mx-2 w100"
                                            onClick={handleClear}
                                        //   disabled={!disableButton()}
                                        >Clear</Button>
                                    </Col>
                                </Row>
                            </Form>

                            {/* </div> */}
                        </fieldset>
                        {/* </Container> */}
                    </div>
                </ModalBody>
            </Modal>

            <div tabIndex={4}>
                <Modal isOpen={artModal} toggle={() => handleClose()} animation={false} size="sm" centered>
                    <ModalHeader className='headerBlue' charCode="Y" toggle={() => handleClose()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                        <h5 className="text-center">Title Description</h5>
                    </ModalHeader>
                    <ModalBody>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Title</label>
                                <input className="form-control mt-2" placeholder='enter title name' type="text" autoComplete="off" name="freshTitle" onChange={handleNewTitle} value={freshTitle} />
                                {titleErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{titleErr}</div> : null}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitNewTitle} >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}
export default Art_Details