import React from 'react';
import InsuranceFolio from '../../components/InsuranceFolio/InsuranceFolio';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function Insurance () {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return (
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>
                    <InsuranceFolio />
                </div>
            </div>
        </div>
    );
}

export default Insurance;


