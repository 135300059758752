import React from 'react'
import PropertyFolio from '../../components/PropertyFolio/Property_Folio'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { useSelector } from 'react-redux';
function Property () {
  const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return (
        <div>
          {sidebarOpen && <Sidebar />}
        <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
            <Navbar />
            <div>
                <PropertyFolio />
            </div>
        </div>
    </div>
    )
}

export default Property