/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { Button, Row, Form, Container } from 'react-bootstrap';
import ErrorComponent from '../../../CommonComponent/ErrorComponet'
import {AiFillEye , AiFillEyeInvisible} from 'react-icons/ai'
import Loader from '../../../loadingAnimation/index'
import Swal from "sweetalert2";
import { addSubscriber } from '../../../../pages/RoleMasterPages/ChannelPartnerPages/ChannelPartnerApi';

const SubscriberTable = () => {

    const childFunc = React.useRef(null)

    const [data, setData] = useState({
        username: '',
        contactPerson: '',
        phone: '',
        email: '',
        address: '',
        pincode:'',
        password: '',
        Cpassword: '',

    });
  const [formErr, setFormErr] = useState({})
  const [confPassVisible,setConfPassVisible] = useState(false);
  const [passVisible,setPassVisible] = useState(false);
  const [loading,setLoading] = useState(false);
  const [userId , setUserId] = useState('')

    useEffect(()=>{
        const channelPartnerId = JSON.parse(localStorage.getItem("user_data"));
        setUserId(channelPartnerId.userId)
    },[userId])
    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
        if (value !== null) {
            clearFormErr(name)
        }
    } 

    const clearFormErr = (field) => {
        switch (field) {
            case "username": setFormErr({ ...formErr, username: '' })
                break;
            case "address": setFormErr({ ...formErr, address: '' })
                break;
            case "contactPerson": setFormErr({ ...formErr, contactPerson: '' })
                break;
            case "email": setFormErr({ ...formErr, email: '' })
                break;
            case "Cpassword": setFormErr({ ...formErr, Cpassword: '' })
                break;
            case "password": setFormErr({ ...formErr, password: '' })
                break;
            case "phone": setFormErr({ ...formErr, phone: '' })
                break;
            case "pincode": setFormErr({ ...formErr, pincode: '' })
                break;
            default: setFormErr({})
                break;
        }
    }

    const validation = () => {
        const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        const phoneregex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const pincoderegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
        // const panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
        // let spaceAndLetter = /^[a-z]+$/i;

        let newUserErr = {};
        let isValid = true;
        if (data.username === "" || data.username === null) {
            newUserErr["username"] = "Please enter name!";
            isValid = false;
        }
        // else if (spaceAndLetter.test(data.username) === false) {
        //   newUserErr["username"] = "Please enter alphabetic characters and no spaces";
        //   isValid = false;
        // }
        if (data.email === "" || data.email === null) {
            newUserErr["email"] = "Please enter email!";
            isValid = false;
        } else if (emailregex.test(data.email) === false) {
            newUserErr["email"] = "please enter valid email"
            isValid = false;
        }
        if (data.phone === "" || data.phone === null) {
            newUserErr["phone"] = "Please enter phone!";
            isValid = false;
        } else if (phoneregex.test(data.phone) === false) {
            newUserErr["phone"] = "Please enter valid phone!";
            isValid = false;
        }

        if (data.password && data.Cpassword) {
            if (data.password !== data.Cpassword) {
                newUserErr["Cpassword"] = "Password is not matching!";
                isValid = false;
            }
        }
        if (data.password === "" || data.password === null) {
            newUserErr["password"] = "Please enter password!";
            isValid = false;
        } else if (passregex.test(data.password) === false) {
            newUserErr["password"] = "Password must combination of Number,Capital letter, special character and min length is 8 !";
            isValid = false;
        }
        if (data.Cpassword === "" || data.Cpassword === null) {
            newUserErr["Cpassword"] = "Please enter password again!";
            isValid = false;
        }
        if (data.address === "" || data.address === null) {
            newUserErr["address"] = "Please enter address!";
            isValid = false;
        }
        if (data.pincode === "" || data.pincode === null) {
            newUserErr["pincode"] = "Please enter address!";
            isValid = false;
        } else if (pincoderegex.test(data.pincode) === false) {
            formErr["pincode"] = "Please enter valid pincode";
            isValid = false;
        }
        if (data.contactPerson === "" || data.contactPerson === null) {
            newUserErr["contactPerson"] = "Please enter contactPerson!";
            isValid = false;
        }
        setFormErr(newUserErr);
        return isValid;
    }

    const handleSubmit = () => {
        // e.preventDefault();
        const isValid = validation();

        const channelPartnerId = JSON.parse(localStorage.getItem("user_data"));
        if (isValid) {
        let payLoad = {
            subcriberName: data.username,
            subcriberEmail: data.email,
            subcriberPassword: data.password,
            confirmPassword:data.Cpassword,
            subcriberPh1: data.phone,
            acceptTerms: true,
            roleId: 2,
            contactPerson: data.contactPerson,
            address:data.address,
            channelPartnerId: channelPartnerId.userId,
            pinCode:data.pincode,

          
        }
        
        //http://5.189.157.40:10025/api/Subcriber/register
        setLoading(true);
        addSubscriber(payLoad).then((res) => {
              if (res.status === 200) {
                  setLoading(false);
                  if (res.data._response.status === 1) {
                      Swal.fire(res.data._response.message, "Please check your email for verifying email before login", "success")
                    //   navigate("/")
                      handleReset()
                  }else{
                      Swal.fire(res.data._response.message, "", "info")
                  }
                    // myRef.current.getAllSubscriber()
                    childFunc.current();
              } else {
                  setLoading(false);
                  Swal.fire(res.data._response.message, "", "info")
              }
          })
          .catch((err) => {
            setLoading(false)
            if(err.response){
            if(err.response.data._response.message === "User Already Registered"){  
              Swal.fire(err.response.data._response.message, "", "info");
            } else {
              Swal.fire("Invalid registration details", "", "info");
            }
        }
          })
        }
    
      }

      const handleReset = () => {
        setData({
          username: '',
          address:'',
          contactPerson: '',
          email: '',
          phone: '',
          pincode:'',
          password:'',
          Cpassword:'',
        });
        setFormErr({});
        setConfPassVisible(false)
        setPassVisible(false)
      }


    return (
        <div>
            <Container className='d-flex justify-content-center' style={{height: '70vh'}}>
                <div className="mt-5 p-6 col-md-6">
                    {loading ? <Loader /> : null}
                    <Form className="border border-2 rounded p-4 mt-4">
                        <h4 className='text-center mb-4'> Add Subscriber</h4>
                        <Row className="form-set d-flex justify-content-center">
                            <Form.Group className="col-md-6" >
                                <Form.Label> Name  </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    autoComplete='off'
                                    onChange={handleChange}
                                    value={data.username}
                                    placeholder="Enter Name" />
                                {formErr ? <ErrorComponent formErr={formErr.username} /> : null}
                            </Form.Group>

                            <Form.Group className="col-md-6" >
                                <Form.Label> Contact Person  </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contactPerson"
                                    min="0"
                                    autoComplete='off'
                                    onChange={handleChange}
                                    value={data.contactPerson}
                                    placeholder="Enter Contact Person" />
                                {formErr ? <ErrorComponent formErr={formErr.contactPerson} /> : null}
                            </Form.Group>
                        </Row>
                        <Row className="form-set ">

                            <Form.Group className="col-md-6" >
                                <Form.Label> Mobile Number</Form.Label>
                                <Form.Control
                                    type="Number"
                                    name="phone"
                                    min="0"
                                    onChange={handleChange}
                                    value={data.phone}
                                    placeholder="Enter Mobile Number"
                                    autoComplete='off'
                                />
                                {formErr ? <ErrorComponent formErr={formErr.phone} /> : null}
                            </Form.Group>
                            <Form.Group className="col-md-6" >
                                <Form.Label> Email  </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    min="0"
                                    onChange={handleChange}
                                    value={data.email}
                                    placeholder="Enter Email"
                                    autoComplete="off"
                                />
                                {formErr ? <ErrorComponent formErr={formErr.email} /> : null}
                            </Form.Group>
                        </Row>

                        <Row className="form-set mb-0">
                            <Form.Group className="col-md-6" >
                                <Form.Label> Password  </Form.Label>
                                <Form.Control
                                    type={passVisible ? "text" :"password"}
                                    name="password"
                                    onChange={handleChange}
                                    value={data.password}
                                    placeholder="Enter password"
                                    autoComplete="off"
                                />
                                <i onClick={()=>setPassVisible(!passVisible)} className='passVisibility'>{passVisible ? <AiFillEyeInvisible size={20}/> :<AiFillEye size={20}/>}</i>
                                {formErr ? <ErrorComponent formErr={formErr.password} /> : null}
                            </Form.Group>
                            <Form.Group className="col-md-6" >
                                <Form.Label>Confirm Password  </Form.Label>
                                <Form.Control
                                    type={confPassVisible ? "text" :"password"}
                                    name="Cpassword"
                                    onChange={handleChange}
                                    value={data.Cpassword}
                                    placeholder="Enter confirm password"
                                    autoComplete="off"
                                />
                                <i onClick={()=>setConfPassVisible(!confPassVisible)} className='passVisibility'>{confPassVisible ? <AiFillEyeInvisible size={20}/> :<AiFillEye size={20}/>}</i>
                                {formErr ? <ErrorComponent formErr={formErr.Cpassword} /> : null}
                            </Form.Group>
                        </Row>

                        <Row className="form-set mb-0">
                            <Form.Group className="col-md-6" >
                                <Form.Label> Pincode  </Form.Label>
                                <Form.Control
                                    type= "text"
                                    name="pincode"
                                    onChange={handleChange}
                                    value={data.pincode}
                                    placeholder="Enter pincode"
                                    autoComplete="off"
                                />
                                {formErr ? <ErrorComponent formErr={formErr.pincode} /> : null}
                            </Form.Group>
                           
                        </Row>
                        <Row className="form-set ">


                            <Form.Group className="col-md-12" >
                                <Form.Label> Address  </Form.Label>
                                <Form.Control
                                    as="textarea" rows={2}
                                    type="text"
                                    name="address"
                                    autoComplete='off'
                                    onChange={handleChange}
                                    value={data.address}
                                    placeholder="Enter Address" />
                                {formErr ? <ErrorComponent formErr={formErr.address} /> : null}
                            </Form.Group>
                        </Row>
                        <Row className="form-set text-center">


                            <Form.Group className="col-md-12" >

                                <Button variant="primary" className="mx-2 w100" id="submitButton"
                                onClick={handleSubmit}
                                // disabled={!disableButton()}
                                >
                                    Save
                                </Button>

                                <Button
                                    variant="secondary"
                                    className="mx-2 w100"
                                    type="reset"
                                onClick={handleReset}    
                                >
                                    Clear
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </Container>
            {/* <Container>
                <SubscriberUserDetails childFunc={childFunc} />
            </Container> */}
        </div>
    )
}

export default SubscriberTable