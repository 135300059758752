import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form } from "react-bootstrap";
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import FormErrComponet from '../../CommonComponent/ErrorComponet';
import Loader from '../../loadingAnimation/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateReport, getCompanyListBySubscriberId, EntityList } from '../../../pages/Report/ReportApi'
import Auth from '../../../modules/Auth';
import Swal from 'sweetalert2';
import Select from "react-select";
import DatePicker from 'react-datepicker';


const HoldingReports = () => {
  const userID = Auth.getsubcriberId();


  const navigate = useNavigate();
  const location = useLocation();
  const reportValue = location.state;

  const [loading, setLoading] = useState(true);
  const [formErr, setFormErr] = useState({});

  const [Entity, setEntity] = useState([]);
  const [selEntity, setSelEntity] = useState([]);
  const [entityValue, setEntityVAlue] = useState([])

  const [companySel, setCompanySel] = useState([]);
  const [company, setCompany] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  useEffect(() => {

    (async () => {
      await getCompanyDropDownData()
      await getEntityList()
      //   reportValue === 1 ? getBrokerDropDownData() : getEntityList();
    })();
  }, [])

  const handleFamily = (selected) => {
    setSelEntity(selected);
    setEntityVAlue(selected.value)

    setFormErr({ ...formErr, selEntity: "" });
    //disableButton()

  };

  //getEntityList
  const getEntityList = async () => {
    setLoading(true)

    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            let entityData = [];
            entity &&
              entity.map((data) => {
                entityData.push({
                  value: data.subAccountId,
                  label: data.subAccountName,
                  disabled: data.active
                });
              });
            setEntity(entityData);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });


  };



  const getCompanyDropDownData = async () => {
    setLoading(true);
    await getCompanyListBySubscriberId()
      .then((res) => {
        setLoading(false)
        if (res.data._response.status === 1) {
          let company = res.data._response.data.companyName;
          let companyData = [];
          company &&
            company.map((data) => {
              // check duplicate companies in companyData array
              let checkPresent = companyData.some(el => el.value === data.companyId)
              if (!checkPresent) {
                return (companyData.push({
                  value: data.companyId,
                  label: data.companyName,
                }))
              }
            });
          setCompany(companyData);
          setCompanySel(companyData)
          setLoading(false);
        } else {
          setCompany([]);
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "company list error");
      });
  };

  const errHandel = () => {
    let formErr = {};
    let isValid = true;

    if (companySel.length <= 0) {
      isValid = false;
      formErr["scrip"] = "Please select company!";
    }
    // if (reportValue === 1 && brokerSel.length <= 0) {
    //     isValid = false;
    //     formErr["Broker"] = "Please select broker!";
    // }
    if ((reportValue === 5 || reportValue === 6) && selEntity.length <= 0) {
      isValid = false;
      formErr["selEntity"] = "Please select entity!";
    }
    if (!toDate) {
      isValid = false;
      formErr["date"] = "Please select Date!";
    }

    setFormErr(formErr);
    return isValid;
  }

  // for  date fix 
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }

  const handleSubmit = () => {
    let startYear = new Date("01/01/2000")
    let validate = errHandel();
    if (validate) {
      // const brokerId = [];
      const companyId = [];
      // const entityId = [];

     
      companySel.length > 0 && companySel.forEach((data, index) => {
        companyId.push(data.value);
      });

      const data = {
        // brokerId: reportValue === 1 ? brokerId : undefined,
        SubAccountId: reportValue === 5 || reportValue === 6 ? selEntity.value : undefined,
        companyId: companyId,
        reportId: reportValue,
        instrumentId: 1,
        subscriberId: userID,
        startDate:  fixTimezoneOffset(startYear),
        endDate: fixTimezoneOffset(toDate)

      }

      setLoading(true);
      generateReport(data).then(res => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            const report = reportValue === 4 ? res.data._response.data.holdingReport
                                  : reportValue === 5 ? res.data._response.data.subAccountWiseHoldingReport
                                  : res.data._response.data.brokerSubAccountWiseHoldingReport
                  
            let Reports = {
              report, reportValue, fromDate, toDate
            }

            reportValue === 6 ? navigate('/HoldReportsTables', { state: Reports }) :  navigate('/ReportTable', { state: Reports })
          } else {
            Swal.fire("There is no data to display", "", 'info');
          }
        }
      }).catch(e => {
        setLoading(false);
        console.log(e, 'error in genrate report api');
      })

    }
  }

  const handleClear = () => {
    setFormErr({});
    // setEntitySel([]);
    setCompanySel([]);
    // setBrokerSel([]);
    setFromDate(null)
    setToDate(null)
  }

  return (
    <div className='mt-5 pt-3'>


      <div className='container border p-5 shadow mt-0 mb-0 bg-white rounded col-md-5 col-sm-12 h-50'>
        <div className='mb-3 p-1'>
          <h3 className='text-center'>{reportValue === 4 ? "Brokerwise  scrip holding report" :
                                                          reportValue === 5 ? "Entitywise scrip holding report" :
                                                                                          'Brokerwise - Entitywise holding scrip report'}</h3>
        </div>
        {loading ? <Loader style={{ marginLeft: '27%', marginTop: '20rem' }} /> : null}
        <Form className='mt-2'>
          <Row className="form-set row justify-content-md-center">
            <Form.Group className={reportValue === 4 ? "mt-4 mb-2 col-md-8 col-sm-10" : "mt-4 mb-2 col-md-6 col-sm-10"}>

              <Form.Label>Company Name</Form.Label>
              <MultiSelect options={company} value={companySel} onChange={setCompanySel} />
              {formErr ? <FormErrComponet formErr={formErr.scrip} /> : null}
            </Form.Group>
            {
              reportValue !== 4 ?


                <>
                  <Form.Group className="mt-4 mb-2 col-md-6 col-sm-10">
                    <Form.Label>Entity</Form.Label>
                    <Select
                      value={selEntity}
                      onChange={handleFamily}
                      options={Entity}
                      name="subAccountId"
                      isOptionDisabled={(option) => option.disabled === 0}
                    />
                    {formErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {formErr.selEntity}
                      </div>
                    ) : null}
                  </Form.Group>
                 
                </>


                : null
               
            }



            {/*stating of date filter  */}
            {
            
                
                  <Form.Group className="mt-4  col-md-4 col-sm-4">
                    <Form.Label>As on</Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholderText="Select Date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      maxDate={new Date()}
                      popperPlacement="bottom"
                      // minDate={}
                      onChange={(date) => setToDate(date)}
                      dateFormat="dd-MM-yyyy"
                      name="dateofinv"
                      autoComplete="off"
                      selected={toDate}

                    />
                    {formErr ? <FormErrComponet formErr={formErr.date} /> : null}
                  </Form.Group>
                
               
            }



          </Row>
          <Row >
            <Col md="12" className="text-center mt-2">
              <Button variant="primary" className='btn btn-sm' id="submitButton" onClick={() => { handleSubmit(); }}>Generate Report</Button>
              <Button variant="secondary" className='btn btn-sm w100 mx-2' id="submitButton" onClick={() => { handleClear(); }}>Clear</Button>
            </Col>
          </Row>
        </Form>
      </div>




    </div>
  )
}

export default HoldingReports