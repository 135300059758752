/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Form, Button, Row, Modal, Col } from 'react-bootstrap'
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select"
import { addUnlistedScrip, EntityList } from '../../../pages/Stocks/StocksAPI';
import Loader from '../../loadingAnimation/index';
import { AddMFDeMerger, UpdateMFDeMerger, GetCompanyAndBroker, GetMutualFundCompanies, GetMutualFundSellCompanies, AvailableMFSellQuantity, GetMutualFundFolioNo } from '../../../pages/MutualFund/MutualFundAPI';
import Swal from "sweetalert2";
import Cleave from 'cleave.js/react';
import moment from 'moment-timezone';
// import useEnterKeyListener from '../../ShortcutKey/useKeyListener'

const MFundDemerger = (props) => {

    const { showD, onHideD, mutualFHeaderIdDM, mutualFIdDe1, mutualFIdDe2, mutualFTransactionDM, showdemergerPreview } = props;

    const [companyList, setCompanyList] = useState([]);
    const [allcompanyList, setAllCompanyList] = useState([]);
    const [selScrip, setSelScrip] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [selBroker, setSelBroker] = useState([]);
    const [Entity, setEntity] = useState([]);
    const [selEntity, setSelEntity] = useState([]);
    const [pDate, setPDate] = useState(null);
    const [error, setError] = useState('');
    const [ratio, setRatio] = useState({});
    const [balancQnty, setBalancQnty] = useState(0);
    const [remainingcost, setreamainingCost] = useState(0)
    const [seldeCompOne, setSelDeCompOne] = useState([]);
    const [seldeCompTwo, setSelDeCompTwo] = useState([]);
    const [selfolioNumber, setSelFolioNumber] = useState([]);
    const [folioNumber, setFolioNumber] = useState([]);

    const [quantity, setQuantity] = useState({});
    const [totalCost, setTotalCost] = useState({});

    const [TypeList, setTypeList] = useState([{
        label: 'SIP', value: 41
    }, { label: 'Lumpsum', value: 42 }]);
    const [selType, setSelType] = useState([]);

    const [broker, setbroker] = useState([]);
    const [entityValue, setEntityValue] = useState([])
    const [formErr, setFormErr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [buyDate, setBuyDate] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [unlistedComp, setUnlistedComp] = useState({ companyName: null });
    const [unlistedCompErr, setUnlistedCompErr] = useState('');
    // useEnterKeyListener({
    //     querySelectorToExecuteClick: "#submitButton"
    // });

    // seperation by comma
    const NumberSeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(input)
    }
    // seperation by comma
    const NumberOnlySeperator = (input) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(input)
    }

    // close model
    const handleClose = () => {
        onHideD();
        handleReset();
    }

    //handlechange
    const handleInput = (selected) => {
        setSelScrip(selected);
        setFormErr({ ...formErr, selScrip: "" });
    };

    const handleDcompOne = (selected) => {
        if (seldeCompTwo !== selected) {
            setSelDeCompOne(selected)
            setFormErr({ ...formErr, seldeCompOne: "" });
        }
        else {
            setSelDeCompOne('')
            // swal("Ooops!", "same company selected", "error");
            formErr["seldeCompOne"] = "Same Company Selected....";
            setFormErr(formErr)
        }


    };

    const handleDcompTwo = (selected) => {
        if (seldeCompOne !== selected) {
            setSelDeCompTwo(selected)
            setFormErr({ ...formErr, seldeCompTwo: "" });
        }
        else {
            setSelDeCompTwo('')
            // swal("Ooops!", "same company selected", "error");
            formErr["seldeCompTwo"] = "Same Company Selected....";
            setFormErr(formErr)
        }

    };

    const handleEntity = (selected) => {
        setSelEntity(selected);
        setEntityValue(selected.value);
        setSelBroker([]);
        setSelScrip([])
        setFormErr({ ...formErr, selEntity: "" });
    };
    const handleOption = (selected) => {
        setSelBroker(selected);
        setbroker(selected.value)
        setSelScrip([])
        setFormErr({ ...formErr, selBroker: "" });
    };

    const handleDate = (pDate) => {
        setPDate(pDate);
        setFormErr({ ...formErr, date: '' })
    }

    const handleRatio = (e) => {
        const { name, value } = e.target;
        setRatio({ ...ratio, [name]: value })
        setFormErr({ ...formErr, ratio: '' })
    }

    const handleQuantity = (e) => {
        const { name, rawValue } = e.target;
        setQuantity({ ...quantity, [name]: rawValue })
        setFormErr({ ...formErr, quantity: '' })
    }
    const handleFolioNumber = (selected) => {
        setSelFolioNumber(selected);
        setFormErr({ ...formErr, selfolioNumber: "" });
    };

    const handleTotalCost = (e) => {
        const { name, rawValue } = e.target;
        setTotalCost({ ...totalCost, [name]: rawValue })
        if (name === "dTotalCostOne") {
            setFormErr({ ...formErr, dTotalCostOne: '' });
        }
        setFormErr({ ...formErr, dTotalCostTwo: '' })
    }

    useEffect(() => {
        if (showD) {
            (async () => {
                if (mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined) {
                    setLoading(true)
                    await getEntityListt();
                    await getCompanyList();
                }
            })();
        }
    }, [showD]);

    useEffect(() => {
        if (showD) {
            (async () => {
                await getCompanyBrokerDropDownData();
            })();
        }
    }, [selEntity, showD])

    // get companies list
    useEffect(() => {
        if (showD) {
            if (mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined) {
                if (selEntity.value && selBroker.value && selType.value) {
                    getCompanyData(selEntity.value, selBroker.value, selType.value)
                }
            }
        }

    }, [selEntity, selBroker, selType, showD]);

    // get dropDown Company Data
    const getCompanyData = async () => {
        let sub_Id = selEntity.value;
        let Bro_Id = selBroker.value;
        let InstTypeID = selType.value;
        if (sub_Id !== undefined && Bro_Id !== undefined && InstTypeID !== undefined) {
            setLoading(true);
            await GetMutualFundSellCompanies(sub_Id, Bro_Id, InstTypeID)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        // company
                        let Company = [];
                        let company = res.data._response.data.fetchCompanyBySub;
                        company && company.map((i) => {
                            return (Company.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId }))
                        })

                        setCompanyList(Company);
                        setLoading(false);
                    } else {
                        setCompanyList([]);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setCompanyList([]);
                    setLoading(false);
                });
        }
    };

    // get dropDown Company Data
    const getCompanyBrokerDropDownData = async () => {
        let sub_Id = selEntity.value;
        if (sub_Id !== undefined) {
            setLoading(true);
            await GetCompanyAndBroker(sub_Id)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        // broker
                        let brokers = res.data._response.data.brokerName;
                        let brokersData = [];
                        brokers &&
                            brokers.map((data) => {
                                return (brokersData.push({
                                    value: data.brokerId,
                                    label: data.brokerName,
                                }))
                            });
                        setBrokerList(brokersData);
                        setLoading(false);
                    } else {
                        setBrokerList([]);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setBrokerList([]);
                    setLoading(false);
                });
        }
    };
    //getEntityList
    const getEntityListt = async () => {

        setLoading(true)
        await EntityList()
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    let entity = res.data._response.data.subaccountDetails;
                    let entityData = [];
                    entity &&
                        entity.map((data) => {
                            return (entityData.push({
                                value: data.subAccountId,
                                label: data.subAccountName,
                                disabled: data.active
                            }))
                        });
                    setEntity(entityData);
                    setLoading(false)
                } else {
                    setLoading(false)
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                // setError(e.message);
            });


    };
    //handel disable listed companies
    const handelDisableDD = (option) => {
        if (selBroker) {
            if (selBroker.value === 1) {
                if (option.companyType === 1) {
                    return true
                }
            } else if (option.companyType === 10) {
                return true
            }
        }
    }

    const getCompanyList = async (ListedCompanyParams) => {
        setLoading(true)
        await GetMutualFundCompanies()
            .then((res) => {
                if (res.data._response.status === 1) {
                    //
                    setLoading(false)
                    let SipCompany = [];

                    let company = res.data._response.data.mutualFundCompanies;

                    company &&
                        company.map(i => {
                            return (SipCompany.push({ label: i.mutualFundCompanyName, value: i.mutualFundCompanyId, companyType: i.instrumentTypeId }))
                        });
                    // if (ListedCompanyParams === 1) {

                    //   let lastElement = UnlistedCompany[UnlistedCompany.length - 1];

                    //   setSelScripMerge(lastElement)

                    // }
                    setAllCompanyList(SipCompany);
                    setLoading(false)

                } else {
                    setLoading(false)
                    setAllCompanyList([]);
                    // setError(res.data.message);
                }
            })
            .catch((e) => {
                setLoading(false)
                setAllCompanyList([]);
                console.log(e, 'error in companyList');
                // setError(e.message);
            });
    };
    //reset form
    const handleReset = () => {
        setSelScrip([]);
        setSelBroker([]);
        setSelEntity([]);
        setBalancQnty(0);
        setSelDeCompOne([]);
        setSelDeCompTwo([]);
        setSelFolioNumber([]);
        setPDate(null);
        setQuantity({ dQuantityOne: '', dQuantityTwo: '' });
        setTotalCost({ dTotalCostOne: '', dTotalCostTwo: '' });
        setRatio({ ratio: '' })
        setFormErr({});
        setreamainingCost('');
        setSelType([]);
    };

    const errHandel = () => {

        let formErr = {};
        let isValid = true;
        if (selScrip.length < 1) {
            isValid = false;
            formErr["selScrip"] = "Please Select scrip!";
        }

        if (seldeCompOne.length < 1) {
            isValid = false;
            formErr["seldeCompOne"] = "Please Select First Demerger Company !";
        }
        if (seldeCompTwo.length < 1) {

            isValid = false;
            formErr["seldeCompTwo"] = "Please Select Secound Demerger Company !";
        }
        if (seldeCompTwo === seldeCompOne) {

            isValid = false;
            formErr["seldeCompTwo"] = "Please Select Secound Demerger Company !";
        }

        if (selBroker.length < 1) {
            isValid = false;
            formErr["selBroker"] = "Please Select Broker!";
        }
        if (selEntity.length < 1) {
            isValid = false;
            formErr["selEntity"] = "Please Select Entity!";
        }

        if (!pDate) {
            isValid = false;
            formErr["date"] = "Please Select Date !";
        }

        // if (!ratio.ratio) {
        //     isValid = false;
        //     formErr["ratio"] = "Please fill this field!";
        // }

        if (!quantity.dQuantityOne || quantity.dQuantityOne === 0) {
            isValid = false;
            formErr["dQuantityOne"] = "Please Fill This Field!";
        }
        if (quantity.dQuantityOne) {
            if (quantity.dQuantityOne < 0) {
                isValid = false;
                formErr["dQuantityOne"] = "Please Enter Positive Value !";
            }
        }
        // if (quantity.dQuantityOne) {
        //     let qvalue = parseFloat(quantity.dQuantityOne); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["dQuantityOne"] = "Please enter only integer value!";
        //     }
        //   }
        if (!quantity.dQuantityTwo || quantity.dQuantityTwo === 0) {

            isValid = false;
            formErr["dQuantityTwo"] = "Please Fill This Field!";
        }
        if (quantity.dQuantityTwo) {
            if (quantity.dQuantityTwo < 0) {
                isValid = false;
                formErr["dQuantityTwo"] = "Please Enter Positive Value !";
            }
        }
        if (selfolioNumber.length < 1) {
            isValid = false;
            formErr["selfolioNumber"] = "Please Select Folio Number!";
        }
        // if (quantity.dQuantityTwo) {
        //     let qvalue = parseFloat(quantity.dQuantityTwo); 
        //     if (Number.isInteger(qvalue) == false) {
        //       isValid = false;
        //       formErr["dQuantityTwo"] = "Please enter only integer value!";
        //     }
        //   }

        if (!totalCost.dTotalCostOne || totalCost.dTotalCostOne === 0) {
            isValid = false;
            formErr["dTotalCostOne"] = "Please Fill This Field!";
        }
        if (totalCost.dTotalCostOne) {
            if (totalCost.dTotalCostOne < 0) {
                isValid = false;
                formErr["dTotalCostOne"] = "Please Enter Positive Value !";
            }
        }
        if (!totalCost.dTotalCostTwo || totalCost.dTotalCostTwo === 0) {
            isValid = false;
            formErr["dTotalCostTwo"] = "Please Fill This Field!";
        }
        if (totalCost.dTotalCostTwo) {
            if (totalCost.dTotalCostTwo < 0) {
                isValid = false;
                formErr["dTotalCostTwo"] = "Please Enter Positive Value !";
            }
        }
        if (amount > 0 && remainingcost) {

            if (parseInt(amount) !== parseInt(remainingcost)) {

                isValid = false;
                formErr["dTotalCostTwo"] = "Your Investment Amount Rs " + remainingcost + "!";
            }
        }
        setFormErr(formErr);
        return isValid;
    };

    //submit data
    const handleSubmit = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                subAccountId: selEntity.value,
                mutualFundCompanyId: selScrip.value,
                brokerId: selBroker.value,
                deMergerCompanyOne: seldeCompOne.value,
                deMergerCompanyTwo: seldeCompTwo.value,
                dQuantityOne: parseInt(quantity.dQuantityOne),
                dQuantityTwo: parseInt(quantity.dQuantityTwo),
                dTotalCostOne: Number(totalCost.dTotalCostOne),
                certificateFolioNo: selfolioNumber.value,
                dTotalCostTwo: Number(totalCost.dTotalCostTwo),
                date: fixTimezoneOffset(pDate),
                ratio: ratio.ratio,
                instrumentTypeId: selType.value
            };

            AddMFDeMerger(Sdata)
                .then((res) => {
                    setLoading(false);
                    if (res.data._response.status === 1) {
                        Swal.fire("DeMerger!", "Added Successfully", "success");
                        handleReset();
                    }
                    else {
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in submit");
                });
        }
    }
    //update data
    const handleUpdate = () => {
        const isValid = errHandel();
        if (isValid === true) {
            setLoading(true);
            const Sdata = {
                mutualFundId: mutualFIdDe1,
                mutualFundId2: mutualFIdDe2,
                mutualFundHeaderId: mutualFHeaderIdDM,
                subAccountId: selEntity.value,
                mutualFundCompanyId: selScrip.value,
                brokerId: selBroker.value,
                deMergerCompanyOne: seldeCompOne.value,
                deMergerCompanyTwo: seldeCompTwo.value,
                certificateFolioNo: selfolioNumber.value,
                dQuantityOne: parseInt(quantity.dQuantityOne),
                dQuantityTwo: parseInt(quantity.dQuantityTwo),
                dTotalCostOne: Number(totalCost.dTotalCostOne),
                dTotalCostTwo: Number(totalCost.dTotalCostTwo),
                date: fixTimezoneOffset(pDate),
                ratio: ratio.ratio,
            };
            UpdateMFDeMerger(Sdata)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        setLoading(false);
                        Swal.fire("Success!", "Updated Successfully", "success");
                    }
                    else {
                        setLoading(false);
                        console.log("error");
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error, "error in submit");
                });
        }
    }
    //getbyid
    useEffect(() => {
        if (mutualFHeaderIdDM !== 0 && mutualFHeaderIdDM !== undefined) {
            setLoading(true)
            if (Object.keys(mutualFTransactionDM).length !== 0) {

                setSelBroker({
                    value: mutualFTransactionDM.brokerId,
                    label: mutualFTransactionDM.broker.brokerName,
                });
                setbroker(mutualFTransactionDM.brokerId);

                setSelEntity({
                    value: mutualFTransactionDM.subAccountId,
                    label: mutualFTransactionDM.subAccount.subAccountName,
                });
                setSelFolioNumber({
                    value: mutualFTransactionDM.certificateFolioNo,
                    label: mutualFTransactionDM.certificateFolioNo,
                })

                setEntityValue(mutualFTransactionDM.subAccountId);
                setPDate(new Date(mutualFTransactionDM.date));
                mutualFTransactionDM.mutualFundDetails && mutualFTransactionDM.mutualFundDetails.map((t) => {
                    t.instrumentTypeId === 41 ? setSelType({ value: 41, label: "SIP" }) : setSelType({ value: 42, label: "Lumpsum" })
                    return (setRatio({ ratio: t.ratio }))

                })
                let dData = mutualFTransactionDM.mutualFundDetails;
                const c = dData[0].fromCompany;
                setSelScrip({ label: c.mutualFundCompanyName, value: dData[0].fromCompanyId, companyType: dData[0].instrumentTypeId })
                setSelDeCompOne({ value: dData[0].mutualFundCompanyId, label: dData[0].mutualFundCompany.label });

                setSelDeCompTwo({ value: dData[1].mutualFundCompanyId, label: dData[1].mutualFundCompany.label });
                setTotalCost({ dTotalCostOne: dData[0].investmentAmt, dTotalCostTwo: dData[1].investmentAmt })

                setQuantity({ dQuantityOne: dData[0].units, dQuantityTwo: dData[1].units })

                setLoading(false)
            }

        }
    }, [mutualFTransactionDM])

    useEffect(() => {
        if (mutualFHeaderIdDM !== 0 && mutualFHeaderIdDM !== undefined) {
            if (showD || showdemergerPreview) {
                if (Object.keys(mutualFTransactionDM).length !== 0) {
                    let dData = mutualFTransactionDM.mutualFundDetails;
                    let calculatecost = dData[0].investmentAmt + dData[1].investmentAmt
                    setreamainingCost(calculatecost);
                }
            }
        }
    }, [mutualFTransactionDM, showD, showdemergerPreview])

    //get buy folionumber
    useEffect(() => {
        if (showD) {
            let companyId = selScrip.value;
            let brokerId = selBroker.value;
            let subAccountId = selEntity.value;
            let InsType = selType.value;
            if (companyId && brokerId && subAccountId && InsType) {
                getFolioNumberDropDownData(subAccountId, companyId, brokerId, InsType)
            }

        }
    }, [selScrip, selBroker, selEntity, showD])
    // for buy date
    useEffect(() => {
        if (showD) {
            if (mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined) {
                setPDate(null)
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;
                let InsType = selType.value;
                let folioNumber = selfolioNumber.value;

                if (companyId && brokerId && subAccountId && InsType && folioNumber) {
                    getBuyDate(subAccountId, companyId, brokerId, InsType, folioNumber);
                }
            }
            if (mutualFHeaderIdDM !== 0 && mutualFHeaderIdDM !== undefined) {
                let companyId = selScrip.value;
                let brokerId = selBroker.value;
                let subAccountId = selEntity.value;
                let InsType = selType.value;
                let folioNumber = selfolioNumber.value;

                if (companyId && brokerId && subAccountId && InsType && folioNumber) {
                    getBuyDate(subAccountId, companyId, brokerId, InsType, folioNumber);
                }
            }
        }
    }, [selScrip, selBroker, selEntity, showD, selfolioNumber]);

    //getfolionumber
    const getFolioNumberDropDownData = async () => {
        let companyId = selScrip.value;
        let brokerId = selBroker.value;
        let subAccountId = selEntity.value;
        let InsType = selType.value;
        if (companyId !== undefined && brokerId !== undefined && subAccountId !== undefined && InsType !== undefined) {
            setLoading(true);
            await GetMutualFundFolioNo(subAccountId, companyId, InsType, brokerId)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            let data = res.data._response.data.folioNumbers;

                            let arr = [];
                            data && data.map((d, i) => {
                                arr.push({ label: d, value: d });
                            })
                            setFolioNumber(arr);
                        } else {
                            setFolioNumber([]);
                        }
                    }

                }).catch(e => {
                    console.log(e, 'error in getFolioNo Api');
                    setLoading(false);
                })
        }
    }

    const getBuyDate = async (sub_id, co_id, bro_id, InsType, folioNumber) => {
        setLoading(true)
        await AvailableMFSellQuantity(sub_id, co_id, bro_id, InsType, folioNumber)
            .then((res) => {
                setLoading(false)
                if (res.data._response.status === 1) {
                    setBuyDate(res.data._response.data.minimum_Date);
                    setBalancQnty(res.data._response.data.available_Unit);
                    if (mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined) {
                        setreamainingCost(res.data._response.data.available_Cost);
                    }
                } else {
                    setBalancQnty(0);
                }
            })
            .catch((e) => {
                setBalancQnty(0);
                setLoading(false)
            });
    };
    useEffect(() => {
        if (totalCost.dTotalCostOne > 0 && (totalCost.dTotalCostTwo > 0)) {
            handleAmount()
        } else {
            setAmount(0)
        }
    }, [totalCost]);

    const handleAmount = () => {

        const total = (parseInt(totalCost.dTotalCostOne) + parseInt(totalCost.dTotalCostTwo));
        setAmount(total);
    }

    const disableButton = () => {
        return selBroker && pDate && selEntity && selScrip &&
            seldeCompOne && seldeCompTwo && quantity && totalCost && ratio
    }

    const fixTimezoneOffset = (pDate) => {
        if (!pDate) return "";
        return new Date(pDate.getTime() - (pDate.getTimezoneOffset() * 60000)).toJSON();
    }

    const handleOpenModal = () => setShowModal(!showModal);

    const handleUnlisted = (e) => {
        const { name, value } = e.target;
        setUnlistedComp({ ...unlistedComp, [name]: value });
        setUnlistedCompErr('')
    }

    const submitUnlisted = (e) => {
        // debugger
        e.preventDefault();
        if (unlistedComp.companyName !== '' && unlistedComp.companyName !== null && unlistedComp.companyName !== undefined) {
            unlistedComp.companyName.trim()
            let result = unlistedComp.companyName.replace(/^\s+|\s+$/gm, '');
            let data = {
                "companyName": result,
                "InstrumentTypeId": 10
            }
            addUnlistedScrip(data)
                .then(res => {
                    setLoading(false)
                    if (res.status === 200) {
                        if (res.data._response.status === 1) {
                            const lastEle = 1
                            getCompanyList(lastEle);
                            setUnlistedComp('');
                            setUnlistedCompErr('');
                            handleCloseModal()
                            Swal.fire(res.data._response.message, "", 'success');
                        } else {
                            Swal.fire(res.data._response.message, '', 'info')
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch(e => {
                    setError(e.message)
                    setLoading(false)
                })

        } else {
            setUnlistedCompErr("Company name required!")
        }
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setUnlistedComp('');
        setUnlistedCompErr('')
    }
    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                <span onMouseDown={handleOpenModal} className="m-0" title="Add unlisted scrip">
                    <i className="bi bi-plus ml-4 h4 text-secondary" ></i></span>
                {children}
            </components.Control>
        )
    }

    return (
        <>
            <Modal show={showD} onHide={handleClose} size="lg" centered>
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto"><h5>Mutual Funds - DeMerger</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Loader style={{ marginLeft: "50px", marginTop: "20rem" }} />
                    ) : null}
                    <div className="mt-2 p-6">
                        <Form className="border border-1 rounded p-3" >
                            <Row className="form-set">
                                <Form.Group className="col-md-4">
                                    <Form.Label>Type<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selType}
                                        onChange={(sel) => setSelType(sel)}
                                        options={TypeList}
                                        name="InstrumentTypeID"
                                        isDisabled={mutualFHeaderIdDM}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selType}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Entity<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selEntity}
                                        onChange={handleEntity}
                                        options={Entity}
                                        name="subAccountId"
                                        isOptionDisabled={(option) => option.disabled === 0}
                                        isDisabled={mutualFIdDe1 !== 0 && mutualFIdDe1 !== undefined}
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selEntity}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-4" >
                                    <Form.Label>Broker<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selBroker}
                                        onChange={handleOption}
                                        options={BrokerList}
                                        name="brokerId"
                                        isDisabled={mutualFIdDe1 !== 0 && mutualFIdDe1 !== undefined}

                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selBroker}
                                        </div>
                                    ) : null}
                                </Form.Group>


                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-4">
                                    <Form.Label>Scrip<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selScrip}
                                        onChange={handleInput}
                                        options={companyList}
                                        name="companyId"
                                        isOptionDisabled={handelDisableDD}
                                        isDisabled={mutualFIdDe1 !== 0 && mutualFIdDe1 !== undefined}

                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.selScrip}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Folio Number<span className="text-danger h6">*</span></Form.Label>
                                    <Select
                                        value={selfolioNumber}
                                        onChange={handleFolioNumber}
                                        options={folioNumber}
                                        name="folioNumber"
                                    />
                                    {formErr ? (
                                        <div className="mt-2" style={{ fontSize: 12, color: "red" }}>
                                            {formErr.selfolioNumber}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-4">
                                    <Form.Label>Date of Demerger<span className="text-danger h6">*</span></Form.Label>
                                    <DatePicker
                                        className="form-control required-form-field"
                                        placeholderText="Select Start Date"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={50}
                                        // maxDate={new Date()}
                                        minDate={new Date(buyDate)}
                                        onChange={handleDate}
                                        dateFormat="dd-MM-yyyy"
                                        name="date"
                                        autoComplete="off"
                                        selected={pDate}

                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.date}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row className="form-set">
                                {mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined ?
                                    <Form.Group className="col-md-4" >
                                        <Form.Label>Total Quantity before demerger</Form.Label>
                                        <Form.Control type="text" name="BalancQnty" min="0"
                                            value={balancQnty === 0  ? "0" :NumberOnlySeperator(balancQnty)} disabled />
                                    </Form.Group> :
                                    null
                                }
                                <Form.Group className="col-md-4" >
                                    <Form.Label>Total Cost before demerger</Form.Label>
                                    <Form.Control type="text" name="BalancQnty" min="0"
                                        value={NumberSeperator(remainingcost)} disabled />
                                </Form.Group>

                                <Form.Group className="col-md-4" >
                                    <Form.Label> Ratio </Form.Label>
                                    <Form.Control type="text" name="ratio" min="0" placeholder="Enter Ratio" className="required-form-field" autoComplete="off"
                                        value={ratio.ratio} onChange={handleRatio} />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.ratio}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>

                            <Row className="form-set">
                                <Form.Group className="col-md-6">
                                    <Form.Label>Demerger Company 1<span className="text-danger h6">*</span></Form.Label>

                                    <Select
                                        // components={{Control}}
                                        value={seldeCompOne}
                                        onChange={handleDcompOne}
                                        options={allcompanyList}
                                        name="deMergerCompanyOne"
                                        // isOptionDisabled={handelDisableDD}
                                        isDisabled={mutualFIdDe1 !== 0 && mutualFIdDe1 !== undefined}

                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.seldeCompOne}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group className="col-md-6">
                                    <Form.Label>Demerger Company 2<span className="text-danger h6">*</span></Form.Label>

                                    <Select
                                        // components={{Control}}
                                        value={seldeCompTwo}
                                        onChange={handleDcompTwo}
                                        options={allcompanyList}
                                        name="deMergerCompanyTwo"
                                        // isOptionDisabled={handelDisableDD}
                                        isDisabled={mutualFIdDe1 !== 0 && mutualFIdDe1 !== undefined}

                                        styles={{
                                            option: (data) => ({
                                                ...data,
                                                margin: 0,
                                                padding: '3px 12px',
                                            }),
                                            groupHeading: (base) => ({
                                                ...base,
                                                flex: '1 1',
                                                color: '#000',
                                                margin: 0,
                                                padding: '7px 2px',
                                                // border: `2px solid `,
                                                background: "#8bc3ff",
                                                fontSize: 'small',
                                                fontWeight: 500
                                            }),
                                        }}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.seldeCompTwo}
                                        </div>
                                    ) : null}
                                </Form.Group>

                            </Row>
                            <Row className="form-set">
                                <Form.Group className="col-md-3" >
                                    <Form.Label>Shares Company 1 <span className="text-danger h6">*</span></Form.Label>
                                    {/* <Form.Control type="Number" name="dQuantityOne" min="0" placeholder="Enter Shares for Company 1"
                                        onChange={handleQuantity} value={Math.round(quantity.dQuantityOne)} /> */}
                                    <Cleave
                                       autoComplete="off"
                                       placeholder="Enter Shares for Company 1"
                                        className='form-control'
                                        name="dQuantityOne"
                                        options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                                        value={quantity.dQuantityOne}
                                        onChange={handleQuantity}
                                    />
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.dQuantityOne}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-3" >
                                    <Form.Label>Investment Company 1 <span className="text-danger h6">*</span></Form.Label>
                                    <Cleave
                                        placeholder="Enter Amount"
                                        className='form-control'
                                        name="dTotalCostOne"
                                        options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                                        value={totalCost.dTotalCostOne === 0  ? "0" :totalCost.dTotalCostOne}
                                        onChange={handleTotalCost}
                                    />
                                    {/* <Form.Control type="Number" name="dTotalCostOne" min="0" placeholder="Enter amount"
                                        value={totalCost.dTotalCostOne} onChange={handleTotalCost} /> */}
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.dTotalCostOne}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-3" >
                                    <Form.Label>Shares Company 2 <span className="text-danger h6">*</span></Form.Label>
                                    <Cleave
                                        placeholder="Enter Shares for Company 2"
                                        className='form-control'
                                        name="dQuantityTwo"
                                        options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                                        value={quantity.dQuantityTwo}
                                        onChange={handleQuantity}
                                    />
                                    {/* <Form.Control type="Number" name="dQuantityTwo" min="0" placeholder="Enter Shares for Company 2"
                                        onChange={handleQuantity} value={Math.round(quantity.dQuantityTwo)} /> */}
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.dQuantityTwo}
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group className="col-md-3" >
                                    <Form.Label>Investment Company 2 <span className="text-danger h6">*</span></Form.Label>
                                    <Cleave
                                       placeholder="Enter Amount"
                                        className='form-control'
                                        name="dTotalCostTwo"
                                        options={{ numeral: true,numeralDecimalScale: 4, numeralThousandsGroupStyle: 'lakh' }}
                                        value={totalCost.dTotalCostTwo}
                                        onChange={handleTotalCost}
                                    />
                                    {/* <Form.Control type="Number" name="dTotalCostTwo" min="0" placeholder="Enter amount"
                                        value={totalCost.dTotalCostTwo} onChange={handleTotalCost} /> */}
                                    {formErr ? (
                                        <div
                                            className="mt-2"
                                            style={{ fontSize: 12, color: "red" }}
                                        >
                                            {formErr.dTotalCostTwo}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            </Row>
                            <Row>

                            </Row>
                            <Row>
                                <Col md="12" className="text-center mt-3">
                                    {mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined ? (

                                        <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleSubmit(); }}
                                            disabled={!disableButton()}
                                        >
                                            Save
                                        </Button>
                                    ) : (
                                        <Button variant="primary" className="mx-2 w100" id="submitButton" onClick={() => { handleUpdate(); }}
                                            disabled={!disableButton()}>
                                            Update
                                        </Button>
                                    )}
                                    {mutualFHeaderIdDM === 0 || mutualFHeaderIdDM === undefined ?
                                        <Button variant="secondary" className="mx-2 w100" type="reset" onClick={handleReset}>
                                            clear
                                        </Button> : null}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
            <>
                <Modal show={showModal} onHide={handleCloseModal} animation={false} size="sm" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Unlisted Scrip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="form-group mt-2 mb-2">
                                <label for="inputUserName">Company Name</label>
                                <input className="form-control mt-2" placeholder="enter scrip name.." type="text" autoComplete="off" name="companyName" onChange={handleUnlisted} value={unlistedComp.companyName} />
                                {unlistedCompErr ? <div className="mt-2" style={{ fontSize: 12, color: "red" }}>{unlistedCompErr}</div> : null}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="mx-2 w100" onClick={submitUnlisted} >
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
            {showdemergerPreview === true ?
                <Modal show={showdemergerPreview} onHide={handleClose}>
                    <Modal.Header className="headerBlue" closeButton>
                        <Modal.Title className="mx-auto"><h5>Demerger Details</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
                            <div className="transactionData"><div className="transactionDataTitle">Entity : </div>
                                <div className="transactionDataValue">{selEntity.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Broker : </div>
                                <div className="transactionDataValue">{selBroker.label}</div></div>
                            <div className="transactionData"><div className="transactionDataTitle">Scrip : </div>
                                <div className="transactionDataValue">{selScrip.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Folio Number : </div>
                                <div className="transactionDataValue">{selfolioNumber.label}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Total cost before demerger: </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(remainingcost)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Ratio : </div>
                                <div className="transactionDataValue">{ratio.ratio == null ? "-" : ratio.ratio}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Demerger company 1 : </div>
                                <div className="transactionDataValue">{seldeCompOne.label}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Demerger company 2 : </div>
                                <div className="transactionDataValue">{seldeCompTwo.label}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Date of Demerger : </div>
                                <div className="transactionDataValue">{moment(pDate).format("DD/MM/YYYY")}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Shares Company 1 : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(quantity.dQuantityOne)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Investment Company 1 : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(totalCost.dTotalCostOne)}</div></div>
                            <div className="transactionData odd"><div className="transactionDataTitle">Shares Company 2 : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4 }).format(quantity.dQuantityTwo)}</div></div>
                            <div className="transactionData "><div className="transactionDataTitle">Investment Company 2 : </div>
                                <div className="transactionDataValue">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 4}).format(totalCost.dTotalCostTwo)}</div></div>
                        </div>
                    </Modal.Body>
                </Modal>
                : null}
        </>
    )
}

export default MFundDemerger;