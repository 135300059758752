import React from 'react';
import MutualFundTable from '../../components/MutualFundList/MutualFundTable';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

function MutualFund () {
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);
    return (
        <div >
                {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                    <Navbar />
                <div>

                    <MutualFundTable />
                </div>
            </div>
        </div>
    );
}

export default MutualFund;


