/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import "./Navbar.css";
import { FaBars, FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import swal from "sweetalert";
import Auth from "../../modules/Auth";
import ManageFamily from '../FamilyManager/ManageFamily';
import MyAcoount from '../FamilyManager/MyAcoount';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip'
import { openCloseSidebar, ClearSidebarData } from '../../redux/action/sidebarAction';


import LoginPopup from '../Login/LoginPopup';
import { ProfileGetData, UserGetData } from '../../pages/RoleMasterPages/AdminsPages/UserdetailsApi';
import RenewSubscription from '../Login/RenewSubscription';
import moment from 'moment';
import TermsAndCondiModal from '../UserRegistration/TermsAndCondiModal';
import ProfileCompletion from '../Login/profileCompletionpopup';

const Navbar = () => {

    // local storage data
    const isPaid = Auth.getUserIsPaid();
    const isTermsCon = Auth.getUserTermsCon();
    const isTrialDays = Auth.getUserTrialDays();
    const isPaidUserLocal = Auth.getUserIsPaidLocal();
    const TwoFA = Auth.getTwoFA();
    const TwoFAAuth = Auth.getTwoFAAuth();
    const userId = Auth.getUserId();
    const roleId = Auth.getUserRoleID();
    const remainingDays = Auth.getRenewalDays();
    const UsersTermsNCond = Auth.getUserTerms()

    const userName = Auth.getUserName();
    const userRole = Auth.getUserRoleID();
    const token = Auth.getToken();


    // states
    const [showFamily, setShowFamily] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const dispatch = useDispatch();
    const selector = useSelector(res => res.sidebarReducer.openclosesidebar);


    const [showloginPopup, setShowloginPopup] = useState(false);
    const [showProfileComp, setShowProfileComp] = useState(false);
    const [showRenewModel, setShowRenewModel] = useState(false);
    const [profileValue, setProfileValue] = useState([]);

    // terms and condition pop up
    const [showTermsPage, setShowTerms] = useState(false)


    var Fifth = new Date(new Date().getFullYear() + 1, 2, 25);
    var Sixth = new Date(new Date().getFullYear() + 1, 2, 26);
    var Seventh = new Date(new Date().getFullYear() + 1, 2, 27);
    var Eighth = new Date(new Date().getFullYear() + 1, 2, 28);
    var Ninth = new Date(new Date().getFullYear() + 1, 2, 29);
    var Thirtys = new Date(new Date().getFullYear() + 1, 2, 30);
    var ThirtyFirsts = new Date(new Date().getFullYear() + 1, 2, 31);

    const TwentyFifth = moment(Fifth).format('DD/MM/YYYY');
    const TwentySixth = moment(Sixth).format('DD/MM/YYYY');
    const TwentySeventh = moment(Seventh).format('DD/MM/YYYY');
    const TwentyEighth = moment(Eighth).format('DD/MM/YYYY');
    const TwentyNinth = moment(Ninth).format('DD/MM/YYYY');
    const Thirty = moment(Thirtys).format('DD/MM/YYYY');
    const ThirtyFirst = moment(ThirtyFirsts).format('DD/MM/YYYY');

    const d = new Date();
    let currDate = moment(d).format("DD/MM/YYYY");

    const Navigate = useNavigate();
    const handleLogout = () => {
        swal("Logged out!", "", "success")
        Auth.logout();
        dispatch(ClearSidebarData())
    }

    //let userName = localStorage.getItem('userName');
   
    // manage family
    const openFamily = () => {
        setShowFamily(true)
    }

    const openUser = () => {
        setShowUser(true)
    }

    const handleClose = () => {
        setShowFamily(false);
        setShowAccount(false);
        setShowUser(false);
    }

    // manage account
    const openAccount = () => {
        setShowAccount(true)
    }

    const openSidebar = () => {
        // setSidebarOpen(!sidebarOpen);
        dispatch(openCloseSidebar())
    };

    //condition to check trial user and remaining days pop ups
    useEffect(() => {
        // debugger;
        let pop_status = localStorage.getItem('pop_status');
        let remainingDays_pop = localStorage.getItem('remainingDays_pop');
        let profile_pop = localStorage.getItem('profile_pop');
        if (roleId === 2) {
            if (!pop_status && isPaid === isPaidUserLocal) {
                if (isTrialDays > 7 && isPaid === 0) {
                    Navigate("/plans")
                } else if (isPaid === 0 || isPaid === null) {
                    setShowloginPopup(true)
                    localStorage.setItem('pop_status', 1);
                }
            }
        }
        if (profile_pop === null || Number(profile_pop) === 0) {
            if (profileValue !== 100 && isPaid === 1 && isTermsCon === 1 && roleId === 2) {
                userprofile()
            }
            // localStorage.setItem('profile_pop', 1);
        }

        if (!remainingDays_pop) {
            if (remainingDays >= -7 && TwentyFifth === currDate || TwentySixth === currDate || TwentySeventh === currDate || TwentyEighth === currDate || TwentyNinth === currDate || Thirty === currDate || ThirtyFirst === currDate) {
                setShowRenewModel(true);
            }
            localStorage.setItem('remainingDays_pop', 1);
        }
        // setShowProfileComp(true);

    }, [isPaid, isTrialDays, remainingDays, isTermsCon, roleId])



    //terms and consition pop up show
    useEffect(() => {
        let TermsPop = localStorage.getItem('Terms_consition');
        if (TermsPop == null && UsersTermsNCond === 0 && isPaid === 1 && roleId === 2 ) {
            setShowTerms(true)
            localStorage.setItem('Terms_consition', 1);
        } else {
            setShowTerms(false)
        }
    }, [])




    //profile page prohress bar pecentage
    const userprofile = () => {
        UserGetData(userId).then((res) => {
            if (res.data._response.status === 1) {
                let data = res.data._response.data.subcriberDetails
                ProfileGetData(userId, roleId).then((res) => {
                    if (res.data._response.status === 1) {
                        let profileresult = res.data._response.data.isProfileComplete;
                        data.map((result)=>{
                        if (result.subcriberName !== undefined && result.subcriberName !== '' && result.subcriberName !== null) {
                            profileresult += 5
                        }
                        if (result.subcriberAdd1 !== undefined && result.subcriberAdd1 !== '' && result.subcriberAdd1 !== null) {
                            profileresult += 10
                        }
                        if (result.subcriberCity !== undefined && result.subcriberCity !== '' && result.subcriberCity != null) {
                            profileresult += 10
                        }
                        if (result.subcriberState !== undefined && result.subcriberState !== '' && result.subcriberState != null) {
                            profileresult += 10
                        }
                        if (result.subcriberCountry !== undefined && result.subcriberCountry !== '' && result.subcriberCountry != null) {
                            profileresult += 10
                        }
                        if (result.pinCode !== undefined && result.pinCode !== '' && result.pinCode != null) {
                            profileresult += 5
                        }
                        if (result.subcriberEmail !== undefined && result.subcriberEmail !== '' && result.subcriberEmail != null) {
                            profileresult += 10
                        }
                        if (result.subcriberPh1 !== undefined && result.subcriberPh1 !== '' && result.subcriberPh1 != null) {
                            profileresult += 10
                        }
                        if (result.profession !== undefined && result.profession !== '' && result.profession != null) {
                            profileresult += 10
                        }
                        if (result.pan !== undefined && result.pan !== '' && result.pan != null) {
                            profileresult += 5
                        }
                        if (result.gst !== undefined && result.gst !== '' && result.gst != null) {
                            profileresult += 5
                        }
                        if (result.contact !== undefined && result.contact !== '' && result.contact != null) {
                            profileresult += 10
                        }
                        })
                        setProfileValue(profileresult)
                        if (profileresult >= 100) {
                            setShowProfileComp(false)
                            Navigate("/dashboard");
                            localStorage.setItem('profile_pop', 1);
                        } else {
                            setShowProfileComp(true)
                           
                        }
                    }
                })
            }
        })
    }


    const closepopup = () => {
        setShowloginPopup(false);
        setShowProfileComp(false);
        setShowRenewModel(false);
    }


    //close terms and condition 
    const handleCloseTerms = () => {
        setShowTerms(false)
    }


    return (
        <>
            <nav className="navbar">
                <Tooltip title="Sidebar">
                    <div className="nav_icon" onClick={() => openSidebar(selector)}>
                        {token ? <FaBars /> : null}
                    </div>
                </Tooltip>

                {/* <div className="navbar__left">
                <a className="active_link" href="#">Dashboard</a>
            </div> */}
                <div className="navbar__right">
                    {!token ? <Link to="/Registration"><Button>Register</Button></Link> : null}
                    {!token ? <Link to="/Login"><Button>Login</Button></Link> : null}
                    {token ? <><Dropdown>
                        <Tooltip title="Profile">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <FaUserCircle size='2em' />
                                {/* <span> {userName}</span> */}
                            </Dropdown.Toggle>
                        </Tooltip>
                        <Dropdown.Menu>
                            <span className="dropdown-item text-center h6" >Hello, {userName}</span>
                            <Dropdown.Item onClick={openAccount}>My Account</Dropdown.Item>
                            {/* {userRole === 1 ? <Dropdown.Item onClick={openUser}>User Management</Dropdown.Item> : null} */}
                            {(userRole !== 1) && (userRole !== 3) ?
                                <>
                                    {isPaid === 1 ?
                                        <>
                                            <Dropdown.Item onClick={() => Navigate('/2FA')}>2FA</Dropdown.Item>
                                        </> : null
                                    }
                                    {isPaid === 0 && isTrialDays > 7 ? null :
                                        <> <Dropdown.Item onClick={openFamily}>Mange Family</Dropdown.Item> </>
                                    }
                                    <Dropdown.Item onClick={() => Navigate('/plans')}>Plans</Dropdown.Item>
                                    {isPaid === 1 ? <Dropdown.Item onClick={() => Navigate('/ReportCard')}>Report</Dropdown.Item> : null}
                                </>
                                : null}

                            <Dropdown.Item onClick={handleLogout} href="/Login">Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown></> : null}
                </div>
            </nav>

            {/* family manager modal */}
            <ManageFamily showFamily={showFamily} hideFamily={handleClose} />
            <MyAcoount showAccount={showAccount} hideAccount={handleClose} />
            {/* <UserDetails showUser={showUser} hideUser={handleClose} /> */}


            <LoginPopup
                show={showloginPopup}
                onhide={closepopup}
            />

            <ProfileCompletion
                showprofile={showProfileComp}
                onhide={closepopup}
                profileValue={profileValue}
                setProfileValue={setProfileValue}
            />

            <RenewSubscription
                show={showRenewModel}
                hide={closepopup}
            />

            <TermsAndCondiModal
                showTerms={showTermsPage}
                hideTerms={handleCloseTerms}
            />
        </>

    )
}

export default Navbar;