/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TableCell, TableContainer, IconButton, Table, Paper, TableHead, TableRow, TableBody, TableFooter
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility, Delete } from "@material-ui/icons";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import { Stack } from "@mui/material";
import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from "@mui/icons-material/Add";
import useEnterKeyListener from '../ShortcutKey/useKeyListener';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import $ from "jquery";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';

import _ from "lodash";
import Loader from "../loadingAnimation/index";
import Auth from '../../modules/Auth'
import { EntityList } from '../../pages/Stocks/StocksAPI'
import PropertyBuyPreview from '../PropertyDetails/PropertyBuyPreview';
import { outerGridPropertyFamily, outerGridPropertyIndividual, innerGridPropertyFamily, innerGridPropertyIndividual, getPropertyTransaction, DeletePropertyBuyTrans } from '../../pages/Property/PropertyAPI'
import Property_Details from '../PropertyDetails/Property_Details';
import Property_Sell from '../PropertyDetails/Property_Sell';

import SearchBar from "material-ui-search-bar";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';


const Property_Folio = () => {
  const [loading, setLoading] = useState(false);
  const [showProperty, setShowProperty] = useState(false);
  const [entityRadio, setEntityRadio] = useState([]);
  const [entity, setEntity] = useState("Family");
  const [entityId, setEntityId] = useState(null);
  const [script, setScript] = useState("All");
  const [propertyHeaderId, setPropertyHeaderId] = useState(0);
  const [PropertyTransaction, setPropertyTransaction] = useState({});
  const [previewTransaction, setPreviewTransaction] = useState({});
  const [PropertySellTransaction, setPropertySellTransaction] = useState({});
  const [previewPropertyopen, setpreviewPropertyopen] = useState(false);
  const [typeOfInvestId, setTypeOfInvestId] = useState([])
  const [showInner, setShowInner] = useState(false);
  const [sell, setSell] = useState([]);
  const [entitySell, setEntitySell] = useState([]);
  const [subId, setSubId] = useState(null);
  const [subName, setSubName] = useState("");
  const [subNameEnt, setSubNameEnt] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [proArtData, setProArtData] = useState([]);
  const [entityData, setEntityData] = useState([])
  const [filteredEntiryData, setFilteredEntiryData] = useState([]);
  const [propAssetId, setPropAssetId] = useState(null);
  const [depositToName, setDepositToName] = useState('');
  const [showSell, setShowSell] = useState(false);


  const [iconsTest, setIconTest] = useState(AddIcon);
  const [searched, setSearched] = useState("");

  const userID = Auth.getsubcriberId();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if(state){
        setEntityId(state.subAccountId);
        setSubNameEnt(state.entityName);
        let entityId = state.subAccountId;
        let typeOfInvestmentId = state.typeOfInvestmentId;
        let propertyAssetId = state.propertyAssetId;
        let depositToName = state.depositToName;
        let entityName = state.entityName;
        getBuyGetData(entityId, typeOfInvestmentId, propertyAssetId, depositToName, entityName);
      }
  }, [state, entityId])

  useEffect(() => {

    setLoading(true);
    getPropertyFamilyOuterData()

    if (userID) {
      getEntityList()
    }
  }, [userID]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#submitButton"
   });

  const columns = [
    { title: "Type of Property", field: "typeOfProperty" },

    {
      title: "Amount Invested",
      field: "amountInvested",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.amountInvested)}
        </span>
      ),
    },

    {
      title: "Current Market Price",
      field: "currentMarketPrice",
      type: "numeric",
      render: (rowData) => (
        <span>
          {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice)}
        </span>
      ),
    },
    {
      title: "Overall Gain",
      field: "overallGain",
      type: "numeric",
      render: (rowData) => (

        (rowData.currentMarketPrice - rowData.amountInvested) >= 0 ?

          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.amountInvested)}
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(rowData.currentMarketPrice - rowData.amountInvested)}
          </span>
      ),
    },
    {
      title: "Overall Gain %",
      field: "overallGainPer",
      type: "numeric",
      render: (rowData) => (
        (((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100) >= 0 ?

          <span style={{ color: "#008000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100))} %
          </span>
          : <span style={{ color: "#ff0000" }}>
            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound((rowData.currentMarketPrice - rowData.amountInvested) / rowData.amountInvested) * 100)} %

          </span>
      ),
    },

  ];

  //round of percentage
  function preciseMathDotRound(value, precision = 2) {
    let valueData = isFinite(value)

    if (valueData) {

      return parseFloat(value.toFixed(precision));
    } else {
      return 0;
    }
  }

  //all property family data
  const getPropertyFamilyOuterData = () => {
    outerGridPropertyFamily().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        if (res.data._response.status === 1) {
          let FamilyProperty = res.data._response.data.propertyOuterGrid;
          setProArtData(FamilyProperty);
          GetGridData(FamilyProperty);
          $(".MuiTableRow-root").each(function () {
            let val = $('.material-icons ', this).text()
            if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
          });
        } else {
          setFilteredData([]);
          setProArtData([]);
          console.log('errror in getPropertyFamilyOuterData api', res.data._response.message);
        }
      } else {
        setLoading(false);
      }
    })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  };

  // all fixed income entity wise data 
  const getPropertyEntityOuterData = async (entityId) => {
    await outerGridPropertyIndividual(entityId)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res, 'entity data');
          if (res.data._response.status === 1) {
            //+ button code
            $(".MuiTableRow-root").each(function () {
              let val = $('.material-icons ', this).text()
              if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
            });

            let EntityData = res.data._response.data.propertyEntityWiseOuterGrid;
            setEntityData(EntityData)
            setLoading(false)

            GetEntityGridData(EntityData)

            setScript("All")
            setSearched("")
          } else {
            setFilteredEntiryData([]);
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log("error");
        setLoading(false)
      });
  }
  //getEntityList
  const getEntityList = async () => {
    setLoading(true)
    await EntityList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            let entity = res.data._response.data.subaccountDetails;
            setEntityRadio(entity);
            setLoading(false)
          }
        } else {
          setLoading(false)
          // setError(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)
        // setError(e.message);
      });
  };

  //handle snapshot
  const handleView = (selectedRow, a) => {
    //  console.log(a,"liste");
    getPropertyTransaction(a.propertyId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setPropertyHeaderId(a.propertyId);
          const result = res.data._response.data.propertyTransactionDetails;
          setPreviewTransaction(result);
          setpreviewPropertyopen(true);
        }
      }
    });

  }

  const handleDelete = (selectedRow, a) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
     
      .then((willDelete) => {
        if (willDelete) {
          setLoading(true)
          DeletePropertyBuyTrans(a.propertyId).then((res) => {
            if (res.status === 200) {
              if (res.data._response.status === 1) {
                setLoading(false)
                swal(res.data._response.message, {
                  title: "Deleted",
                  icon: "success",
                });

                const del = sell.filter(sells => a.propertyId !== sells.propertyId)
                setSell(del)
                const delEntity = entitySell.filter(Esells => a.propertyId !== Esells.propertyId)
                setEntitySell(delEntity)
                handleCloseInner();
              }
              else {

                setLoading(false)
              }
            }
          })
            .catch(err => {
              console.log(err);
              setLoading(false)
            })
        } else {
          swal("Cancelled", "Your  Data is safe ", "error");
          setLoading(false)
        }
      });
  }


  const onRefreshProperty = () => {

    getPropertyFamilyOuterData();
    // getPropertyEntityOuterData(subId)
    if (entityId) getPropertyEntityOuterData(entityId);
    // setLoading(true);
  }

  const onTransactionGridRefresh = () => {
    getBuyGetData(
      subId,
      typeOfInvestId,
      propAssetId,
      depositToName,
      subName
    );
  }
  //open LoanDetils Modal
  const openTransaction = async () => {
    // await setEntity('Family');
    setShowProperty(true)
  };

  // buysell Inner GRID
  const getBuyGetData = async (
    subAccountId,
    typeOfInvestmentId,
    propertyAssetId,
    depositToName,
    entityName,
    index
  ) => {

    // console.log(entityName,"buysell");
    setSubId(subAccountId);
    setPropAssetId(propertyAssetId);
    setDepositToName(depositToName);
    setTypeOfInvestId(typeOfInvestmentId);
    setSubName(entityName)
    setLoading(true);
    // family
    if (subAccountId && typeOfInvestmentId && state === null) {
      await innerGridPropertyFamily(subAccountId, typeOfInvestmentId)
        .then((res) => {
          setLoading(false);
          if (res.data._response.status === 1) {
            let data = res.data._response.data.propertyEntityInfo;

            setSell(data);
            setShowInner(true);
          } else {
            setSell([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      // entity
      if (entityId && typeOfInvestmentId && propertyAssetId) {
        await innerGridPropertyIndividual(entityId, typeOfInvestmentId, propertyAssetId)
          .then(async (res) => {
            if (res.status === 200) {
              setLoading(false);
              if (res.data._response.status === 1) {
                let data = res.data._response.data.propertyIndividualEntityInfo;
                setEntitySell(data);
                setShowInner(true);
              } else {
                setLoading(false);
                setEntitySell([]);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          })
      }
    }
    setLoading(false);

  };
  //refresh form data
  const refreshForm = (a) => {
    if (a !== undefined || a !== null) {
      getPropertyTransaction(a).then((res) => {
        // debugger
        if (res.status === 200) {
          setLoading(false)
          if (res.data._response.status === 1) {
            //  setShowBorrowings(true);
            setPropertyHeaderId(a);
            const result = res.data._response.data.propertyTransactionDetails;
            setPropertyTransaction(result);
          }
        }
      }).catch(e => {
        console.log(e, 'error in alertMyRow function');
        setLoading(false)
      });
    }
  }
  //open transactions
  const alertMyRow = (selectedRow, a) => {
    setLoading(true)
    getPropertyTransaction(a.propertyId).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        if (res.data._response.status === 1) {
          setPropertyHeaderId(a.propertyId);
          const result = res.data._response.data.propertyTransactionDetails;
          setPropertyTransaction(result);
          setShowProperty(true);
        }
      }
    }).catch(e => {
      console.log(e, 'error in alerMyRow function');
      setLoading(false)
    });
  };

  // + button code
  const PlusButton = () => {
    $(".MuiTableRow-root").each(function () {
      let val = $('.material-icons ', this).text()
      if (val === "maximize") $("button", this).html("<span class='material-icons'>add</span>");
    });
  }
  //filter property
  const handleScripData = (e) => {
    let selected = e.target.value;
    setScript(selected)
    setSearched("")
    // family data filter
    if (entity === "Family") {
      let sorted = selected === "All" ? proArtData : proArtData.filter((a) => a.typeOfInvestmentId === Number(selected))

      let check = [];
      sorted.forEach((item, i) => {
        if (check.indexOf(item.investmentName) === -1) {
          check.push(item.investmentName);
        }
      });
      let parentData = [];
      let childData = [];
      check.forEach((checkdata, index) => {
        let mainData = {
          amountInvested: 0,
          currentMarketPrice: 0
        };
        sorted.forEach((item, i) => {
          if (checkdata === item.investmentName) {
            let amountInvested = mainData.amountInvested + item.amountInvested;

            let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

            mainData = {
              typeOfProperty: item.investmentName,
              id: index,
              amountInvested: amountInvested,
              currentMarketPrice

            };

            let normalData = {
              ...item,
              typeOfProperty: item.subAccountName,
              parentId: index,
              id: index + 1,
              company: item.subAccountName,
            };
            childData.push(normalData);
          }
        });
        parentData.push(mainData);

        PlusButton()
      });
      setFilteredData([...parentData, ...childData]);
    } else {
      //entity data  filter
      let sortedEntity = selected === "All" ? entityData : entityData.filter((a) => (a.typeOfInvestmentId === Number(selected)))
      let checkEntity = [];
      sortedEntity.forEach((item, i) => {
        if (checkEntity.indexOf(item.typeOfInvestmentId) === -1) {
          checkEntity.push(item.typeOfInvestmentId);
        }
      });
      let parentEntityData = [];
      let childEntityData = [];
      checkEntity.forEach((checkdata, index) => {
        let mainData = {
          amountInvested: 0,
          currentMarketPrice: 0,
        };
        sortedEntity.forEach((item, i) => {
          if (checkdata === item.typeOfInvestmentId) {
            let amountInvested = mainData.amountInvested + item.amountInvested;
            let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

            mainData = {
              amountInvested: amountInvested,
              currentMarketPrice: currentMarketPrice,
              typeOfProperty: item.investmentName,
              id: index,
            };

            let normalData = {
              ...item,
              typeOfProperty: item.propertyAssetName,
              parentId: index,
              id: index + 1,
            };
            childEntityData.push(normalData);
          }
        });
        parentEntityData.push(mainData);
      });
      setFilteredEntiryData([...parentEntityData, ...childEntityData]);
      PlusButton()
    }
  }

  //details pannel icon parent child
  const handleIcon = (row, rows) => {
    let index = row.tableData.path[0];
    if (rows) {
      // setIconTest(RemoveIcon);
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>maximize</span>");
          $(button).css({
            transform: "rotate(180deg)",
          });
        }
      });
    } else {
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        if (path == index) {
          let button = $("button", this);
          $(button).html("<span class='material-icons'>add</span>");
        }
      });
    }
  };

  const handleReset = () => {
    setPropAssetId(null);
    setDepositToName(null);
    setSubId(null);
  }
  //modal close
  const handleClose = async () => {
    setShowProperty(false)
    onTransactionGridRefresh();
    setPropertyTransaction({});
    setPropertyHeaderId(0);

    await onRefreshProperty();
    // await setEntity('Family');
    // await setEntityId(null);
  }

  const handleClosePropSell = () => {
    setPropertySellTransaction({});
    setShowSell(false);
    setPropertyHeaderId(0);

  }
  const handleClosePreview = () => {
    setpreviewPropertyopen(false);
    setPreviewTransaction({});
  }
  const handleCloseInner = async () => {
    // await setEntity('Family');
    await onRefreshProperty();
    setSubName("");
    handleReset();
    setShowInner(false);
  }

  //filter family grid
  const GetGridData = (propFamilyData) => {
    let check = [];
    propFamilyData.forEach((item, i) => {
      if (check.indexOf(item.investmentName) === -1) {
        check.push(item.investmentName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(propFamilyData);
      propFamilyData.forEach((item, i) => {
        if (checkdata === item.investmentName) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfProperty: item.investmentName,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amountInvested: amountInvested,
            currentMarketPrice,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfProperty: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,


          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);
  };

  //filter entity outer grid 
  const GetEntityGridData = (EntityStock) => {

    let check = [];
    EntityStock.forEach((item, i) => {
      if (check.indexOf(item.typeOfInvestmentId) === -1) {
        check.push(item.typeOfInvestmentId);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,

      };

      EntityStock.forEach((item, i) => {
        if (checkdata === item.typeOfInvestmentId) {

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;
          mainData = {
            amountInvested: amountInvested,
            currentMarketPrice: currentMarketPrice,
            typeOfProperty: item.investmentName,
            id: index,

          };

          let normalData = {
            ...item,
            typeOfProperty: item.propertyAssetName,
            parentId: index,
            id: index + 1,
          };

          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredEntiryData([...parentData, ...childData]);
  };

  //entity chnaged
  const handleChangeEntity = async (e) => {
    // setLoading(true)
    await setEntity(e.target.value);
    if (e.target.value === "Family") {
      setEntityId(null);
      getPropertyFamilyOuterData();
      setScript("All");

      //+ button code
      $(".MuiTableRow-root").each(function () {
        let path = this.getAttribute("path");
        let button = $("button", this);
        $(button).html("<span class='material-icons'>add</span>");
      });
    } else {
      let data = _.find(entityRadio, function (o) {
        return o.subAccountName == e.target.value;
      });
      await setSubNameEnt(e.target.value);
      await setEntityId(data.subAccountId);
      await getPropertyEntityOuterData(data.subAccountId)
    }
    // console.log({ subAccountName: e.target.value }, "handle change");
  };
  const handleSell = (e, a) => {
    getPropertyTransaction(a.propertyId).then((res) => {
      if (res.status === 200) {
        if (res.data._response.status === 1) {
          setPropertyHeaderId(a.propertyId);
          const result = res.data._response.data.propertyTransactionDetails;
          setPropertySellTransaction(result);
          setShowSell(true);
        }
      }
    });
  }

  // function for 1st captilized letter
  const UpperFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // search function
  const requestSearch = (searchedVal) => {

    setSearched(searchedVal)
    const filteredRows = proArtData.filter((row) => {
      return row.investmentName.includes(searchedVal.toUpperCase()) || row.investmentName.includes(searchedVal.toLowerCase()) || row.investmentName.includes(UpperFirst(searchedVal));
    });


    //family wise
    let check = [];
    filteredRows.forEach((item, i) => {
      if (check.indexOf(item.investmentName) === -1) {
        check.push(item.investmentName);
      }
    });
    let parentData = [];
    let childData = [];
    check.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,
        amtOutstanding: 0,
        name: "",
        closingBalance: 0,
        currentValue: 0
      };
      // console.log(propFamilyData);
      filteredRows.forEach((item, i) => {
        if (checkdata === item.investmentName) {

          let closingBalance = mainData.closingBalance + item.closingBalance;
          let currentValue = mainData.currentValue + item.currentValue;

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;

          let amtOutstanding = mainData.amtOutstanding + item.amtOutstanding;

          mainData = {
            typeOfProperty: item.investmentName,
            closingBalance: closingBalance,
            currentValue: currentValue,
            id: index,
            amountInvested: amountInvested,
            currentMarketPrice,
            amtOutstanding
          };

          let normalData = {
            ...item,
            typeOfProperty: item.subAccountName,
            parentId: index,
            id: index + 1,
            company: item.subAccountName,


          };
          childData.push(normalData);
        }
      });
      parentData.push(mainData);
    });
    setFilteredData([...parentData, ...childData]);

    // entitywise
    const forentity = filteredRows.filter(a => a.subAccountId === entityId)

    let checkEntity = [];
    forentity.forEach((item, i) => {
      if (checkEntity.indexOf(item.typeOfInvestmentId) === -1) {
        checkEntity.push(item.typeOfInvestmentId);
      }
    });
    let parentDataEntity = [];
    let childDataEntity = [];
    checkEntity.forEach((checkdata, index) => {
      let mainData = {
        amountInvested: 0,
        currentMarketPrice: 0,

      };

      forentity.forEach((item, i) => {
        if (checkdata === item.typeOfInvestmentId) {

          let amountInvested = mainData.amountInvested + item.amountInvested;
          let currentMarketPrice = mainData.currentMarketPrice + item.currentMarketPrice;
          mainData = {
            amountInvested: amountInvested,
            currentMarketPrice: currentMarketPrice,
            typeOfProperty: item.investmentName,
            id: index,

          };

          let normalData = {
            ...item,
            typeOfProperty: item.propertyAssetName,
            parentId: index,
            id: index + 1,
          };

          childDataEntity.push(normalData);
        }
      });
      parentDataEntity.push(mainData);
    });
    setFilteredEntiryData([...parentDataEntity, ...childDataEntity]);


  };

  // cancle search
  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
    if (entityId) {
      getPropertyEntityOuterData(entityId)
    } else {
      getPropertyFamilyOuterData()
    }
  };


  return (
    <Container fluid>
      <Row>
        <Col>
          <h4 className="text-center py-3">Property Folio</h4>
        </Col>
      </Row>
      <FormControl className="mt-4 ">

        <RadioGroup
          row
          color="primary"
          defaultValue="start"
          aria-label="Entity"
          name="row-radio-buttons-group"
          value={entity}
          onChange={handleChangeEntity}
          style={{ marginTop: "-3rem" }}


        >
          <FormControlLabel
            value="Family"
            control={<Radio />}
            label="Family"
          />
          {entityRadio.map((a) => (
            <FormControlLabel
              key={a.subAccountName}
              value={a.subAccountName}
              control={<Radio />}
              label={a.subAccountName}
              disabled={a.active === 0 ? true : false}
            />
          ))}

        </RadioGroup>
      </FormControl>
      <Row >
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="flex-end"
          alignItems="flex-start" mb={3} >
          {entityId == null ? null :
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openTransaction()}
                style={{ height: "2rem" }}
              >
                Transactions
              </Button>
            </>

          }

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={script}
            label="Type"
            onChange={handleScripData}
            style={{ minWidth: 160, paddingLeft: "12px" }}

          >
            <MenuItem disabled >
              <em>Property Type</em>
            </MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"6"} style={{ marginLeft: "16px" }}>Residential</MenuItem>
            <MenuItem value={"7"} style={{ marginLeft: "16px" }}>Commercial</MenuItem>
            <MenuItem value={"8"} style={{ marginLeft: "16px" }}>Land</MenuItem>
            <MenuItem value={"9"} style={{ marginLeft: "16px" }}>Others</MenuItem>

          </Select>
        </Stack>
      </Row>
      <Row>
        <Box sx={{ flexGrow: 1 }} mb={2}>
          <Grid container spacing={2} columns={16}
            direction="row"
            justifyContent="end"
            alignItems="flex-end">
            <Grid item xs="auto">
              <SearchBar
                value={searched}
                onChange={(searchVal) => { requestSearch(searchVal) }}
                onCancelSearch={() => cancelSearch()}
                style={{
                  // margin: "0 auto",
                  maxWidth: 250,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Row>
      <Row>
        <Col md="12">
          <div style={{ maxWidth: "100%" }} className="tblHeaderLeft">
            {/* {loading ? (
                            <Loader />
                        ) : (
                            <> */}
            <MaterialTable
              title=""
              data={
                entity === "Family" ? filteredData : filteredEntiryData
              }
              columns={columns}

              parentChildData={(row, rows) =>
                rows.find((a) => a.id === row.parentId)
              }
              isLoading={loading}
              options={{
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                },
                exportButton: false,
                search: false,
                toolbarButtonAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                emptyRowsWhenPaging: false,
                rowStyle: (rowData) => {
                  return {
                    pointerEvents: rowData.tableData.childRows === null && rowData.active === 0 ? "none" : "cursor",
                    backgroundColor: rowData.tableData.childRows === null && rowData.active === 0 ? " #ced4da" : null
                  };
                },
              }}

              onTreeExpandChange={(row, rows) => {
                handleIcon(row, rows);
              }}
              icons={{
                DetailPanel: iconsTest,
              }}

              onRowClick={(evt, selectedRow) => {
                if (selectedRow.tableData.childRows == null) {
                  // console.log(selectedRow)
                  getBuyGetData(
                    selectedRow.subAccountId,
                    selectedRow.typeOfInvestmentId,
                    selectedRow.propertyAssetId,
                    selectedRow.typeOfProperty,
                    selectedRow.company,
                    selectedRow.tableData.path[0]
                  );
                }
              }}

              components={{
                Body: (props) => {

                  let totalData = {
                    investmentCost: 0,
                    marketPrice: 0,
                    overallGain: 0,
                    overallGainP: 0,
                  }

                  props.renderData.forEach((rowData) => {
                    totalData.investmentCost += rowData.amountInvested;
                    totalData.marketPrice += rowData.currentMarketPrice;
                    totalData.overallGain = totalData.marketPrice - totalData.investmentCost;
                    totalData.overallGainP = ((totalData.marketPrice - totalData.investmentCost) / totalData.investmentCost) * 100;
                  })

                  return (
                    <>
                      <MTableBody {...props} />
                      {
                        script === "All" ?
                          <TableFooter style={{ backgroundColor: "#D9E5F2 " }} >
                            <TableRow>
                              <TableCell ></TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="left">Total</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</TableCell>
                              <TableCell style={{ color: "black", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.marketPrice)}</TableCell>
                              <TableCell style={totalData.overallGain < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.overallGain)}</TableCell>
                              <TableCell style={totalData.overallGainP < 0 ? { color: "#ff0000", fontWeight: "500" } : { color: "#008000", fontWeight: "500" }} align="right">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(preciseMathDotRound(totalData.overallGainP))} %</TableCell>

                            </TableRow>
                          </TableFooter> : null

                      }
                    </>
                  )

                },
                Toolbar: (props) => {
                  let totalData = {
                    investmentCost: 0,
                    marketPrice: 0,
                    overallGain: 0,
                    overallGainP: 0,

                  }
                  props.renderData.forEach((rowData) => {
                    totalData.investmentCost += rowData.amountInvested;
                    totalData.marketPrice += rowData.currentMarketPrice;
                    totalData.overallGain = totalData.marketPrice - totalData.investmentCost;
                    totalData.overallGainP = ((totalData.marketPrice - totalData.investmentCost) / totalData.investmentCost) * 100;
                  })
                  return (

                    <>
                      <MTableToolbar {...props} />
                      {
                        script !== "All" || script === "All" ?

                          <div className="row mb-2 p-2">
                            <div className="col-md-2 col-xl-2">
                            </div>
                            <div className="col-md-2 col-xl-2">
                              <div className="card  order-card">
                                <div className="card-block">
                                  <h3 className="text-right"><span style={{ fontSize: "1.5vw" }}>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalData.investmentCost)}</span></h3>
                                  <p className="m-b-0">Invested Amount</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.marketPrice < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.marketPrice).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Current Market Price</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.overallGain < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.overallGain).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Overall Gain</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 col-xl-2">
                              <div className="card order-card">
                                <div className="card-block">
                                  <h3 className="text-right" style={totalData.overallGainP < 0 ? { color: "#ff0000", fontSize: "1.5vw" } : { color: "#008000", fontSize: "1.5vw" }}>
                                    <span>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format((totalData.overallGainP).toFixed(2))}</span></h3>
                                  <p className="m-b-0" >Overall Gain %</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          : null}

                    </>
                  )

                },

              }}

            />
            {/* </>
                        )} */}
          </div>

          <Property_Details
            showProperty={showProperty}
            onHideProperty={handleClose}
            PropertyTransaction={PropertyTransaction}
            propertyHeaderId={propertyHeaderId}
            entityId={entityId}
            // showLoanpreview={previewPropertyopen}
            onRefreshForm={(p) => refreshForm(p)}
          />

          {/* sell */}
          <Property_Sell
            show1={showSell}
            onHideSell={handleClosePropSell}
            propertyHeaderId={propertyHeaderId}
            propertySellTransaction={PropertySellTransaction}
            handleCloseInner={handleCloseInner}
          />

          <PropertyBuyPreview showPreview={previewPropertyopen} onHidePreview={handleClosePreview} propertyTransactionPreview={previewTransaction} />
          <Container
            className="LoginContainer formWraper"
            component="main"
            maxWidth="xs"
            tabIndex={-1}
          >
            <Modal
              isOpen={showInner}
              toggle={() => handleCloseInner()}
              size="xl"
              className="col-12 stockdetailmodal"
            >
              <ModalHeader charCode="Y" toggle={() => handleCloseInner()} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                <h5 className="text-center">Property Details</h5>
              </ModalHeader>
              <ModalBody>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  {loading ? (
                    <Loader />
                  ) : null}

                  {subId && state === null?
                    /* family wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subName}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Location
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Share in Property
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Current Market Price
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain %
                            </TableCell>

                            <TableCell align="center" width="7%" style={{ textAlign: "center" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {sell.filter((a) => a.show !== 0).map((a) => (
                            <TableRow
                              key={a.propertyId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.location}
                              </TableCell>
                              <TableCell align="right">
                                {a.shareInProperty === null ? 0 : a.shareInProperty + "%"}
                              </TableCell>
                              <TableCell align="right">
                                {a.amountInvestedPerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amountInvestedPerShare)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.currentMarketPricePerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare)}
                              </TableCell>
                              <TableCell align="right">
                                {(a.currentMarketPricePerShare - a.amountInvestedPerShare) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare - a.amountInvestedPerShare)}
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare - a.amountInvestedPerShare)}
                                  </span>
                                }
                              </TableCell>

                              <TableCell align="right">
                                {(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {preciseMathDotRound(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100)} %
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100)} %

                                  </span>}
                              </TableCell>


                              <TableCell align="center">
                              <Tooltip title="Sell">
                                  <IconButton>
                                    <HorizontalRuleIcon fontSize="small" onClick={(e) => handleSell(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                
                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>
                               
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer> :

                    /* entity wise data */
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <p> Portfolio: {subNameEnt}</p>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right" width="11%">
                              Location
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Share in Property
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Amount Invested
                            </TableCell>

                            <TableCell align="right" width="7%">
                              Current Market Price
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain
                            </TableCell>
                            <TableCell align="right" width="7%">
                              Overall Gain %
                            </TableCell>

                            <TableCell align="center" width="7%">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "auto" }}>
                          {entitySell.filter((a) => a.show !== 0).map((a) => (
                            <TableRow
                              key={a.propertyId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {a.location}
                              </TableCell>
                              {/* <TableCell align="left">
                                {a.typeOfInvestment}
                              </TableCell> */}
                              <TableCell align="right">
                                {a.shareInProperty === null ? 0 : a.shareInProperty + "%"}
                              </TableCell>
                              <TableCell align="right">
                                {a.amountInvestedPerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.amountInvestedPerShare)}
                              </TableCell>
                              <TableCell align="right" >
                                {a.currentMarketPricePerShare === null ? 0 : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare)}
                              </TableCell>
                              <TableCell align="right">
                                {(a.currentMarketPricePerShare - a.amountInvestedPerShare) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare - a.amountInvestedPerShare)}
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(a.currentMarketPricePerShare - a.amountInvestedPerShare)}
                                  </span>
                                }
                              </TableCell>

                              <TableCell align="right">
                                {(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100) >= 0 ?

                                  <span style={{ color: "#008000" }}>
                                    {preciseMathDotRound(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100)} %
                                  </span>
                                  : <span style={{ color: "#ff0000" }}>
                                    {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(((a.currentMarketPricePerShare - a.amountInvestedPerShare) / a.amountInvestedPerShare) * 100)} %

                                  </span>}
                              </TableCell>


                              <TableCell align="center">
                              <Tooltip title="Sell">
                                  <IconButton>
                                    <HorizontalRuleIcon fontSize="small" onClick={(e) => handleSell(e, a)} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Preview">
                                  <IconButton>
                                    <Visibility fontSize="small" onClick={(e) => handleView(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton>
                                    <Edit fontSize="small" onClick={(e) => alertMyRow(e, a)} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton>
                                    <Delete fontSize="small" onClick={(e) => handleDelete(e, a)} />
                                  </IconButton>
                                </Tooltip>                         
                              </TableCell>

                            </TableRow>
                          ))}


                          <TableRow
                            // key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >


                          </TableRow>


                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Paper>
              </ModalBody>
            </Modal>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default Property_Folio