import React from 'react';
import ChannelPartnerList from '../../../components/RoleMaster/Admin/ChannelPartnerList'
import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useSelector } from 'react-redux';

const ChannelPartList = () =>{
    const sidebarOpen = useSelector(res=>res.sidebarReducer.openclosesidebar);

    return(
        <>
        <div>
            {sidebarOpen && <Sidebar />}
            <div className={sidebarOpen ? "contentWrap" : "contentWrap1"}>
                <Navbar />
                <div>
                    <ChannelPartnerList/>
                </div>
            </div>
        </div>
        </>
    )
}

export default ChannelPartList;